// Setup
import React from 'react'

const withNotifications = (WrappedComponent) =>
  class WithNotifications extends React.Component {
    state = { notification: '' }

    displayNotification = (notification) => this.setState({ notification })

    render = () => (
      <WrappedComponent
        {...this.props}
        displayNotification={this.displayNotification}
        notification={this.state.notification}
      />
    )
  }

export default withNotifications
