// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import BurgerAnimatedIcon from './animated_icon'
import IconButton from '@material-ui/core/IconButton'
import ScreenReader from 'components/ui/screen_reader_text'

/** Animated burger icon button. Extends <IconButton>. */
const BurgerIconButton = ({
  colorClassName,
  'data-test': dataTest,
  isActive,
  onClick,
  ...props
}) => (
  <IconButton
    {...props}
    aria-expanded={isActive}
    data-test={dataTest}
    onClick={onClick}
  >
    <BurgerAnimatedIcon
      aria-hidden
      colorClassName={colorClassName}
      isActive={isActive}
    />
    <ScreenReader text="Menu" />
  </IconButton>
)

BurgerIconButton.propTypes = {
  /** Provide a bg-* class to change the icon color */
  colorClassName: PropTypes.string,
  /** Provide a data-test-* attribute for testing */
  'data-test': PropTypes.string,
  /** Animates between burger to x icon */
  isActive: PropTypes.bool.isRequired,
  /** Can be used to toggle isActive state */
  onClick: PropTypes.func.isRequired,
}

BurgerIconButton.defaultProps = {
  colorClassName: 'bg-black-3',
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'burger-icon-button',
}

export default BurgerIconButton
