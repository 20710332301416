// Vendor
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapse, RootRef } from '@material-ui/core'
import { duration } from '@material-ui/core/styles/transitions'
import { connect } from 'react-redux'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { Button } from 'components/ui/button'
import CenteredContent from 'components/application/centered_content'
import { hideBanner } from 'redux/dispatchers'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  dismissButtonText: {
    defaultMessage: 'Dismiss',
    description: 'Label for a button that hides the banner',
    id: 'banner.dismissButtonText',
  },
})

const { formatMessage } = intl

const BANNER_DISMISS_TIMEOUT = duration.standard

/**
 * Based on https://material.io/design/components/banners.html
 */
class Banner extends Component {
  static propTypes = {
    /** component wrapping the main content */
    as: PropTypes.any,
    /** classes spread on parent node */
    className: PropTypes.string,
    /** main banner contents wrapped in a paragraph tag by default (see 'as' prop) */
    content: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    /** boolean to prevent banner from focusing on mount */
    dontFocus: PropTypes.bool,
    /** boolean to make banner full width instead of centered with max width */
    fullWidth: PropTypes.bool,
    /** boolean to force banner to display on mount */
    isOpen: PropTypes.bool,
    /** function run when banner dismissed */
    onDismiss: PropTypes.func,
    /** props spread to MUI Collapse component */
    transitionProps: PropTypes.object,
    /** choose from pre-selected style variation */
    variant: PropTypes.oneOf(['default', 'error', 'info', 'success']),
  }

  static defaultProps = {
    as: 'p',
    className: null,
    content: null,
    dontFocus: false,
    fullWidth: false,
    isOpen: false,
    onDismiss: () => {},
    transitionProps: {
      classes: {
        entered: null,
        wrapper: null,
      },
    },
    variant: 'default',
  }

  constructor(props) {
    super(props)
    this.bannerRef = React.createRef()
  }

  componentDidMount() {
    const { dontFocus, isOpen } = this.props

    isOpen && !dontFocus && this.bannerRef.current.focus()
  }

  componentDidUpdate() {
    const { dontFocus, isOpen } = this.props
    isOpen && !dontFocus && this.bannerRef.current.focus()
  }

  handleDismiss = () => {
    hideBanner()
    this.props.onDismiss()
  }

  render() {
    const {
      as,
      className,
      content,
      fullWidth,
      isOpen,
      transitionProps,
      variant,
    } = this.props
    const Component = as
    const WrapperComponent = fullWidth ? 'div' : CenteredContent

    const variants = {
      default: {
        className: 'bg-white',
      },
      error: {
        className: 'bg-red-2 border-left-thick-red',
        icon: 'warning',
        iconClassName: 'text-red',
      },
      info: {
        className: 'bg-blue-2 border-left-thick-blue',
        icon: 'info',
        iconClassName: 'text-blue',
      },
      success: {
        className: 'bg-green-2 border-left-thick-green',
        icon: 'check',
        iconClassName: 'text-green',
      },
    }

    return (
      <Collapse
        {...transitionProps}
        classes={{
          wrapper: cx(
            'border-bottom',
            variants[variant].className,
            transitionProps.classes.wrapper
          ),
          wrapperInner: cx('p-1', transitionProps.classes.wrapperInner),
        }}
        className={className}
        data-test="banner"
        in={isOpen}
        timeout={BANNER_DISMISS_TIMEOUT}
      >
        <RootRef rootRef={this.bannerRef}>
          <div tabIndex={-1}>
            <WrapperComponent className="d-flex align-items-md-center flex-column flex-md-row justify-content-between | p-3 pb-2 pb-md-3">
              <div className="d-flex align-items-center">
                {variants[variant].icon && (
                  <Icon
                    className={cx(
                      'fs-5 ml-1 mr-3',
                      variants[variant].iconClassName
                    )}
                    iconName={variants[variant].icon}
                    title=""
                  />
                )}
                <Component className="fs-3 my-0">{content}</Component>
              </div>

              <div className="[ d-flex flex-shrink-none justify-content-end ] [ mt-2 | ml-md-3 mt-md-0">
                <Button
                  data-test="banner-dismiss"
                  onClick={this.handleDismiss}
                  variant="neutral"
                >
                  {formatMessage(messages.dismissButtonText)}
                </Button>
              </div>
            </WrapperComponent>
          </div>
        </RootRef>
      </Collapse>
    )
  }
}

export const BannerWithoutStore = Banner

export default connect((state) => state.banner.options)(Banner)
