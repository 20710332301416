// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { client } from 'utilities/we_apollo'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// Wespire
import { intl } from 'utilities/localization'
import { useIsAuthenticated } from 'utilities/hooks/use_is_authenticated'

const messages = defineMessages({
  allTimePoints: {
    defaultMessage: '{score, number} all time {wordForScorePlural}',
    description: 'Message for All time score point label',
    id: 'userScore.allTimePoints',
  },
  pointsThisYear: {
    defaultMessage: '{score, number} {wordForScorePlural} this year',
    description: 'Message for This year score point label',
    id: 'userScore.pointsThisYear',
  },
})

export const UserScoreContext = React.createContext()
export const UserScoreConsumer = UserScoreContext.Consumer

const userScoreGql = gql`
  query userScore {
    brandConfig {
      id
      primaryNavLimitToPointsThisYear
      wordForScorePlural
    }
    currentUser {
      id
      score
      scoreAllTime
      scoreThisYear
    }
  }
`

const UserScoreProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  const [queryRes, setQueryRes] = useState(null)
  const [scoreLabel, setScoreLabel] = useState(null)

  useEffect(() => {
    if (queryRes) {
      const { score, wordForScorePlural, isPointsThisYear } = queryRes
      setScoreLabel(
        intl.formatMessage(
          isPointsThisYear ? messages.pointsThisYear : messages.allTimePoints,
          {
            score,
            wordForScorePlural,
          }
        )
      )
    }
  }, [queryRes])

  const incrementUserScore = (scoreToAdd) =>
    setQueryRes({
      ...queryRes,
      score: queryRes?.score + scoreToAdd,
      scoreAllTime: queryRes?.scoreAllTime + scoreToAdd,
      scoreThisYear: queryRes?.scoreThisYear + scoreToAdd,
    })
  const reduceUserScore = (scoreToAdd) => incrementUserScore(-scoreToAdd)

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: {
            brandConfig: {
              primaryNavLimitToPointsThisYear: isPointsThisYear,
              wordForScorePlural,
            },
            currentUser: { score, scoreAllTime, scoreThisYear },
          },
        } = await client.query({
          query: userScoreGql,
        })

        setQueryRes({
          isPointsThisYear,
          score,
          scoreAllTime,
          scoreThisYear,
          wordForScorePlural,
        })
      } catch {
        return
      }
    }

    if (isAuthenticated) fetch()
  }, [isAuthenticated])

  return (
    <UserScoreContext.Provider
      value={{
        incrementUserScore,
        reduceUserScore,
        score: queryRes?.score,
        scoreAllTime: queryRes?.scoreAllTime,
        scoreLabel,
        scoreThisYear: queryRes?.scoreThisYear,
      }}
    >
      {children}
    </UserScoreContext.Provider>
  )
}

UserScoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export { UserScoreProvider }
export default UserScoreContext
