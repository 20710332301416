// Setup
import React from 'react'
import { intl } from 'utilities/localization'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

// WeSpire
import { AdditionalBroadcastsList } from './components/additional_broadcasts_list'
import { BroadcastContent } from './components/broadcast_content'
import { BROADCASTQUERY } from './utilities/queries'
import CenteredContent from 'components/application/centered_content'
import { DocumentTitle } from 'components/shared/document_title'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { messages } from './utilities/messages'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'

const { formatMessage } = intl
const Broadcast = ({
  match: {
    params: { id },
  },
}) => {
  const { data, error, loading } = useQuery(BROADCASTQUERY, {
    variables: { id },
  })

  if (error) {
    return <MissingResourceSection />
  }

  if (loading) {
    return <SkeletonLoading className="px-4" height={300} />
  }
  return (
    <DocumentTitle title={data.broadcast.headline}>
      {maybeDisplayUserTour('broadcastPage')}
      <CenteredContent className="my-5">
        <Stack space={3}>
          <BroadcastContent
            broadcast={data.broadcast}
            className="[ my-3 p-3 ] [ d-flex ]"
          />
          <AdditionalBroadcastsList />
          <div>
            <TextLink
              className="fs-2"
              data-test="broadcasts-link"
              to={'/blogs'}
            >
              <>
                <span aria-hidden>
                  {'< '}
                  {formatMessage(messages.viewArchive)}
                </span>
              </>
            </TextLink>
          </div>
        </Stack>
      </CenteredContent>
    </DocumentTitle>
  )
}

Broadcast.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
}

export { Broadcast }
