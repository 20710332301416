// Setup
import React, { useRef } from 'react'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

// WeSpire
import { Button as BaseButton } from 'components/ui/button'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'
import WeSpireAPI from 'utilities/wespire_api'

const { formatMessage } = intl

const messages = defineMessages({
  downloadTemplate: {
    defaultMessage: 'Download Template',
    id: 'groupMembersUpload.downloadTemplate',
  },
  fileUploadOperation: {
    defaultMessage: 'Upload this File',
    id: 'groupMembersUpload.fileUploadOperation',
  },
  header: {
    defaultMessage: 'Upload a members list',
    id: 'groupMembersUpload.header',
  },
  instructions: {
    defaultMessage:
      'Bulk add Members via a CSV file with email addresses, under the column header Email.',
    id: 'groupMembersUpload.instructions',
  },
  processingUpload: {
    defaultMessage:
      'We are processing your file upload and will send you a confirmation email once the process has completed.',
    id: 'groupMembersUpload.processingUpload',
  },
  uploadMembers: {
    defaultMessage: 'Upload Member List',
    id: 'groupMembersUpload.uploadMembers',
  },
})

export const GroupMembersUpload = ({ groupId }) => {
  const membersFileRef = useRef(null)

  const handleFileUpload = () => {
    membersFileRef.current.click()
  }
  const uploadMembersFile = (e) => {
    e.preventDefault()
    const file = membersFileRef.current.files[0]
    const formData = new FormData()

    formData.append('group_members_file', file)
    formData.append('group_id', groupId)

    WeSpireAPI()
      .post('/group_members/upload', formData)
      .then((response) => {
        membersFileRef.current.value = ''
        if (response.status === 200) {
          displayBanner({
            content: formatMessage(messages.processingUpload),
            variant: 'success',
          })
        }
      })
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(messages.fileUploadOperation),
        })
      })
  }

  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase"
          data-test="upload-members-header"
          level={3}
        >
          {formatMessage(messages.header)}
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack className="d-flex align-items-center" space={0}>
          <p>{formatMessage(messages.instructions)}</p>
          <LinkButton
            className="text-blue fs-2 ml-auto"
            to={`/documents/group_members_upload.csv`}
            variant="text"
          >
            <Icon
              className="fs-4 mr-1"
              iconName="export"
              title={formatMessage(messages.downloadTemplate)}
            />
            {formatMessage(messages.downloadTemplate)}
          </LinkButton>
          <BaseButton
            className="text-blue fs-2"
            data-test="group-members-upload-btn"
            onClick={handleFileUpload}
            variant="text"
          >
            <input
              accept=".csv"
              data-test="group-members-upload-input"
              hidden
              name="group_members_file"
              onChange={uploadMembersFile}
              ref={membersFileRef}
              type="file"
            />
            <Icon
              className="fs-4 mr-1"
              iconName="upload"
              title={formatMessage(messages.uploadMembers)}
            />
            {formatMessage(messages.uploadMembers)}
          </BaseButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

GroupMembersUpload.propTypes = {
  groupId: PropTypes.string.isRequired,
}
