// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { EventCategory } from 'components/setup/event_categories/event_category'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import useUpdatePosition from 'utilities/hooks/use_update_position'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl

const messages = defineMessages({
  missingResource: {
    defaultMessage: 'Volunteer Event Categories',
    id: 'eventCategoryList.missingResource',
  },
})

const eventCategoryListQuery = gql`
  query eventCategoryListQuery($brandId: ID!) {
    brand(id: $brandId) {
      id
      eventCategories {
        id
        name
        orderNumber
      }
    }
  }
`

/**
 * Renders a list of <EventCategory> components. Powered by a GraphQL query to
 * fetch a brand's event categories. Will announce order changes to the list
 * using aria-live.
 */
const EventCategoryList = ({ brandId }) => {
  const [ariaLiveText, executeMutation] = useUpdatePosition()

  const handleUpdatePosition = ({ operator, recordId, recordName }) => {
    const refetchQueries = [
      {
        query: eventCategoryListQuery,
        variables: { brandId: brandId },
      },
    ]
    const variables = {
      klass: 'EventCategory',
      operator,
      recordId,
    }

    executeMutation(recordName, refetchQueries, variables)
  }

  return (
    <WeQuery
      error={
        <MissingResourcePage
          resourceName={formatMessage(messages.missingResource)}
        />
      }
      loader={<CenteredPaddedLoadingIndicator color="primary" />}
      query={eventCategoryListQuery}
      variables={{ brandId: brandId }}
    >
      {({
        data: {
          brand: { eventCategories },
        },
      }) => (
        <>
          <Stack as="ol" className="p-0" divider="default" space={1}>
            {eventCategories.map(({ id, name }) => (
              <EventCategory
                categoryId={id}
                categoryName={name}
                key={id}
                onUpdatePositionClick={handleUpdatePosition}
              />
            ))}
          </Stack>
          <ScreenReader aria-live="polite" text={ariaLiveText} />
        </>
      )}
    </WeQuery>
  )
}

EventCategoryList.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export { EventCategoryList, eventCategoryListQuery }
