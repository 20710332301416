// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// Sub-Components
import { ChannelHeroOverlaidLayout } from './overlaid_layout'
import { ChannelHeroStackedLayout } from './stacked_layout'

/**
 * Hero intended to render Channel details. Hero w/ text overlay rendered on
 * desktop and hero with stacked content rendered everywhere else.
 */
const ChannelHero = ({
  description,
  imgUrl,
  imgUrlAltText,
  name,
  secondaryLogoUrl,
  secondaryLogoUrlAltText,
  themeColor,
  ...other
}) => {
  const shared = {
    description: description,
    imgUrl: imgUrl,
    imgUrlAltText: imgUrlAltText,
    name: name,
    secondaryLogoUrl: secondaryLogoUrl,
    secondaryLogoUrlAltText: secondaryLogoUrlAltText,
    themeColor: themeColor,
  }

  return (
    <div data-test="channel-hero">
      <ChannelHeroOverlaidLayout
        {...other}
        className={cx('show-lg-up', other.className)}
        {...shared}
      />
      <ChannelHeroStackedLayout
        {...other}
        className={cx('hide-lg-up', other.className)}
        {...shared}
      />
    </div>
  )
}

ChannelHero.propTypes = {
  /** class attribute applied to parent node */
  className: PropTypes.string,
  /** channel description */
  description: PropTypes.string,
  /** channel hero image url */
  imgUrl: PropTypes.string,
  /** channel hero image alt text */
  imgUrlAltText: PropTypes.string,
  /** channel name */
  name: PropTypes.string.isRequired,
  /** channel secondary logo url */
  secondaryLogoUrl: PropTypes.string,
  /** channel secondary logo alt text */
  secondaryLogoUrlAltText: PropTypes.string,
  /** optional admin-configured CSS color value */
  themeColor: PropTypes.string,
}

ChannelHero.defaultProps = {
  className: null,
  description: null,
  imgUrl: null,
  imgUrlAltText: '',
  secondaryLogoUrl: null,
  secondaryLogoUrlAltText: '',
  themeColor: null,
}

export { ChannelHero, ChannelHeroOverlaidLayout, ChannelHeroStackedLayout }
