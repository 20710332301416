// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import ProgressBar from 'components/ui/progress_bar'
import ProgressBarTop from 'components/ui/progress_bar/progress_bar_top'
import { sharedTranslations } from 'components/shared/translations'
import { userProgressPercent } from './utils'

const ActionProgressBar = ({ progressTargetValue, userProgress }) => {
  return (
    <ProgressBar
      progressPercent={userProgressPercent(userProgress, progressTargetValue)}
      top={
        <ProgressBarTop
          goal={progressTargetValue}
          progress={userProgress}
          title={sharedTranslations.yourProgress}
        />
      }
    />
  )
}

ActionProgressBar.propTypes = {
  /** Target value to complete action with condition */
  progressTargetValue: PropTypes.number,
  /** Total progress gained by user towards progressTargetValue */
  userProgress: PropTypes.number,
}

ActionProgressBar.defaultProps = {
  progressTargetValue: 0,
  userProgress: 0,
}

export default ActionProgressBar
