// Setup
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { sharedTranslations } from '../../shared/translations'
import { TextLink } from 'components/shared/text_link'

const messages = defineMessages({
  disabledAction: {
    defaultMessage: 'Disabled',
    description: 'Indicates that something in our user interface is disabled',
    id: 'secondaryLocaleList.disabledAction',
  },
  enabledAction: {
    defaultMessage: 'Enabled',
    description: 'Indicates that something in our user interface is enabled',
    id: 'secondaryLocaleList.enabledAction',
  },
  noSecondaryLocaleText: {
    defaultMessage: 'There are no additional locales available at this time.',
    description: 'Text to show when no secondary locales are available',
    id: 'secondaryLocaleList.noSecondaryLocaleText',
  },
  secondaryLocaleHeader: {
    defaultMessage: 'Additional Locales',
    description: 'Heading for secondary locale',
    id: 'secondaryLocalesList.secondaryLocaleHeader',
  },
  secondaryLocaleInfoText: {
    defaultMessage:
      'You can edit and enable additional locales once this Channel has been saved.',
    description: 'Texts describing secondary locale',
    id: 'secondaryLocaleList.secondaryLocaleInfoText',
  },
})

const SecondaryLocaleList = ({
  brandConfigLocales,
  channelDefaultLocale,
  channelEnabledLocales,
  channelUrl,
}) => {
  const { formatMessage } = intl
  const channelEnabledLocalesLabels = channelEnabledLocales
    ? channelEnabledLocales.map((locale) => locale.label)
    : []

  const secondaryLocales = brandConfigLocales.filter(
    (locale) => channelDefaultLocale !== locale.value
  )

  return (
    <>
      <Heading
        className="bg-black-6 fs-1 ls-2 px-4 py-3 text-uppercase"
        level={2}
      >
        {formatMessage(messages.secondaryLocaleHeader)}
      </Heading>
      <div className="px-3">
        <Stack className="py-3">
          {secondaryLocales.length > 0 ? (
            <Stack divider="default" space={3}>
              {!channelDefaultLocale && (
                <p
                  className="pb-3 px-2"
                  data-test="additional-locales-unsaved-channel-text"
                >
                  {formatMessage(messages.secondaryLocaleInfoText)}
                </p>
              )}
              <Stack as="ul" className="my-0 px-0" divider="default" space={3}>
                {secondaryLocales.map((locale) => (
                  <li
                    className="[ d-flex align-items-center justify-content-between ] [ px-2 ]"
                    key={locale.value}
                  >
                    <div>
                      <span className="fs-2 fw-semi-bold">{locale.label}</span>
                      <div className="mt-2">
                        <p
                          className="fs-1 mt-2 my-0 text-black-3"
                          data-test={`${locale.value}-status`}
                        >
                          <span
                            className={cx(
                              'd-inline-block mr-1 p-1 rounded-circle',
                              {
                                'bg-green-1':
                                  channelEnabledLocalesLabels.includes(
                                    locale.label
                                  ),
                                'bg-orange-1':
                                  !channelEnabledLocalesLabels.includes(
                                    locale.label
                                  ),
                              }
                            )}
                          />
                          {channelEnabledLocalesLabels.includes(locale.label)
                            ? formatMessage(messages.enabledAction)
                            : formatMessage(messages.disabledAction)}
                        </p>
                      </div>
                    </div>
                    {channelDefaultLocale &&
                      (channelEnabledLocalesLabels.includes(locale.label) ? (
                        <div>
                          <Link
                            className="pr-3"
                            data-test={`${locale.value}-preview`}
                            to={channelUrl(locale.value)}
                          >
                            <Icon
                              className="fs-4 text-black-3"
                              iconName="visibility"
                              title={sharedTranslations.view}
                            />
                          </Link>
                          <Link
                            data-test={`${locale.value}-edit`}
                            to={`secondary_locales/${locale.value}/edit`}
                          >
                            <Icon
                              className="fs-4 text-black-3"
                              iconName="edit"
                              title={sharedTranslations.edit}
                            />
                          </Link>
                        </div>
                      ) : (
                        <TextLink
                          className="[ align-self-center ] [ fs-2 text-link--black-1 ]"
                          data-test={`${locale.value}-edit`}
                          to={`secondary_locales/${locale.value}/edit`}
                        >
                          {sharedTranslations.enable}
                        </TextLink>
                      ))}
                  </li>
                ))}
              </Stack>
            </Stack>
          ) : (
            <p>{formatMessage(messages.noSecondaryLocaleText)}</p>
          )}
        </Stack>
      </div>
    </>
  )
}

SecondaryLocaleList.propTypes = {
  brandConfigLocales: PropTypes.array,
  channelDefaultLocale: PropTypes.string,
  channelEnabledLocales: PropTypes.array,
  channelUrl: PropTypes.func,
}

SecondaryLocaleList.defaultProps = {
  brandConfigLocales: [],
  channelDefaultLocale: null,
  channelEnabledLocales: [],
  channelUrl: () => {},
}

export { SecondaryLocaleList }
