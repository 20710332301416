// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { ContentModuleList } from '../content_module_list'
import MgmtPanelPage from 'setup/application/page'
import Stack from 'components/ui/stack'

/**
 * The index page for displaying all of a Brand's Content Modules.
 */
const ContentModulesIndexPage = ({ brandId }) => (
  <MgmtPanelPage title="Modules">
    {() => (
      <Stack as={Paper} className="p-5 m-5" space={5}>
        <Stack space={3}>
          <h2>Enabled Modules</h2>
          <p>
            This is a list of of all the Modules that are currently enabled.
            Please contact your administrator to enable additional Modules.
          </p>
        </Stack>
        <ContentModuleList brandId={brandId} />
      </Stack>
    )}
  </MgmtPanelPage>
)

ContentModulesIndexPage.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export default ContentModulesIndexPage
