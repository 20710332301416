// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { FormDetail } from 'components/form/form_details'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'
import { intl } from 'utilities/localization'
import { PvhDetails } from './pvh_details'
import { sharedMessages } from 'components/shared/translations'
import {
  currentUserPropType,
  withCurrentUser,
} from 'components/helpers/with_current_user'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'

const messages = defineMessages({
  detail: {
    defaultMessage: 'your available Paid Volunteer Hours this year',
    id: 'pvhAvailableHours.detail',
  },
  helpIconModalTitle: {
    defaultMessage: "{brand}'s Paid Volunteer Hours",
    id: 'pvhAvailableHours.helpIconModalTitle',
  },
})

const PvhAvailableHoursBase = ({
  balance,
  currentUser: {
    brand: { name },
  },
}) => (
  <div className="bg-blue-2 p-3" data-test="pvh-available-hours">
    <FormDetail label={intl.formatMessage(messages.detail)}>
      <TextWithHelpIconModal
        data-test="pvh-balance"
        helpIconModalProps={{
          'data-test': 'pvh-details-modal-trigger',
        }}
        modalContent={<PvhDetails brandName={name} />}
        text={intl.formatMessage(sharedMessages.hoursWithCount, {
          count: balance,
        })}
        title={intl.formatMessage(messages.helpIconModalTitle, {
          brand: name,
        })}
      />
    </FormDetail>
  </div>
)

PvhAvailableHoursBase.propTypes = {
  balance: PropTypes.number.isRequired,
  currentUser: currentUserPropType.isRequired,
}

export const PvhAvailableHours = withCurrentUser(
  PvhAvailableHoursBase,
  <CenteredPaddedLoadingIndicator />
)
