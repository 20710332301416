// Setup
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// WeSpire
import ImageDialog from 'components/ui/image_dialog'

const withImageDialog = (WrappedComponent) =>
  class Wrapped extends Component {
    static propTypes = {
      imageUrl: PropTypes.string,
    }

    static defaultProps = {
      imageUrl: '',
    }

    state = { imageDialogIsOpen: false }

    handleClose = () => this.setState({ imageDialogIsOpen: false })
    toggleImageDialog = () => {
      this.setState((prevState) => ({
        imageDialogIsOpen: !prevState.imageDialogIsOpen,
      }))
    }

    render() {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            handleImageClick={this.toggleImageDialog}
          />
          <ImageDialog
            imageUrl={this.props.imageUrl}
            isOpen={this.state.imageDialogIsOpen}
            onClose={this.handleClose}
          />
        </Fragment>
      )
    }
  }

export default withImageDialog
