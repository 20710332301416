// Vendor
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'

const Surface = ({ children, className, dataTest, ...other }) => {
  return (
    <Paper
      {...other}
      className={cx('my-6 px-8 py-4', className)}
      data-test={dataTest}
      elevation={2}
    >
      {children}
    </Paper>
  )
}
Surface.propTypes = {
  /** render drawer custom content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** for identification of draw in testing */
  dataTest: PropTypes.string,
}
Surface.defaultProps = {
  className: null,
  dataTest: 'drawer-data-test',
}

export { Surface }
