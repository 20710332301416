// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'
import { GroupMembersTable } from './table'
import { GroupMembersAdd } from './add_member'
import { GroupMembersUpload } from './upload_members'

export const GroupMembers = ({ brandId, groupId, groupName }) => {
  return (
    <Stack space={4}>
      <GroupMembersTable groupId={groupId} />
      <GroupMembersAdd
        brandId={brandId}
        groupId={groupId}
        groupName={groupName}
      />
      <GroupMembersUpload groupId={groupId} />
    </Stack>
  )
}

GroupMembers.propTypes = {
  brandId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
}
