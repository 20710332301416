import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

// westyle
import Numbers from 'src/common/Numbers'

const StatBlock = ({ value, message }) => (
  <div className="border-l-2 border-grayscale-3 flex flex-col mb-6 lg:my-0 pl-4">
    <Numbers className="headline-1">{value}</Numbers>
    <span className="label-xsmall-caps text-black-1">{message}</span>
  </div>
)

StatBlock.propTypes = {
  message: PropTypes.node,
  value: PropTypes.number,
}

const YourImpact = ({ grantDonationsEnabled, stats, isStrict }) => {
  return (
    <div
      className={`${grantDonationsEnabled ? 'xl:col-span-2' : 'xl:col-span-3'}`}
    >
      <div className="flex content-center items-center">
        <h2 className="pr-4 px-2">
          <FormattedMessage
            id="volunteering.yourImpact"
            defaultMessage={'Your Impact'}
          />
        </h2>
      </div>
      <div
        className={`rounded w-4xl bg-white border-[1px] border-grayscale-3 mt-4 p-6 flex flex-col justify-between ${
          isStrict ? 'xl:min-h-[11rem]' : 'xl:min-h-[19rem]'
        }`}
      >
        <div className="lg:grid grid-cols-4 gap-x-6">
          <StatBlock
            value={stats.totalVolunteerHours}
            message={
              <FormattedMessage
                id="shared.hoursVolunteered"
                defaultMessage={'Hours Volunteered'}
              />
            }
          />
          <StatBlock
            value={stats.charityCount}
            message={
              <FormattedMessage
                id="volunteering.charitiesImpacted"
                defaultMessage={'Charities Impacted'}
              />
            }
          />
        </div>
        <div>
          <span className="body-medium-regular mt-4">
            <FormattedMessage
              id="volunteering.youWillSeeYourVolunteerHoursFromAttending"
              defaultMessage="PLEASE NOTE: You will see your volunteer hours from attending company events 24 hours after the event has ended."
            />
          </span>
        </div>
      </div>
    </div>
  )
}

YourImpact.propTypes = {
  grantDonationsEnabled: PropTypes.bool,
  stats: PropTypes.shape({
    totalVolunteerHours: PropTypes.number,
    charityCount: PropTypes.number,
  }),
  isStrict: PropTypes.bool,
}

export default YourImpact
