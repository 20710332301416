/*
 ******************************************************************************
 * CAUTION: The order in which routes are defined matters! Do not alpha them.
 ******************************************************************************
 */

// WeSpire
import { ActivitySeries } from 'components/activity_series'
import { Broadcast } from 'components/broadcast'
import { Broadcasts } from 'components/broadcasts'
import Campaign from 'components/campaign'
import CampaignPreview from 'components/setup/campaign/campaign_preview'
import CampaignTemplatePreview from 'components/setup/campaign/campaign_template_preview'
import { CarbonCalculatorPage } from '../carbon_calculator'
import { CarbonFootprintPage } from '../carbon_footprint'
import CharitySearch from 'src/pages/CharitySearch'
import ConnectedDevices from 'components/user/pages/connected_devices'
import GivingForm from 'src/pages/GivingForm'
import DollarsForDoersForm from 'src/pages/DollarsForDoersForm'
import { DonationForm } from 'components/donations/donation_form'
import { DonationHistory } from 'components/donation_history'
import DonationHistoryPage from 'src/pages/DonationHistoryPage'
import DonationSuccess from 'components/donations/donation_success'
import DonationSuccessPage from 'src/pages/DonationSuccessPage'
import DonationTerms from 'components/donations/donation_terms'
import { EmailAuth } from 'components/login/email_auth'
import { EventRegistrationForm } from 'components/events/registration/event_registration_form'
import { EventConfirmationForm } from 'components/events/registration/event_confirmation_form'
import { EventWaiver } from 'components/events/event_waiver'
import { JoinEventWaitlistPage } from 'components/events/registration/join_event_waitlist_page'
import GivingActivity from 'components/giving_activity'
import GoogleAuth from 'components/login/google_auth'
import { Group } from 'components/group'
import { Groups } from 'components/groups'
import GrantDonationForm from 'components/donations/grant_donation_form'
import { GuestRegistrationPage } from 'components/events/registration/guest_registration_page'
import { IdeaFormPage } from 'components/idea_board/pages/idea_form'
import { KudoFormPage } from 'components/kudos'
import { Landing as OldLanding } from 'components/Landing'
import Landing from 'src/pages/Landing'
import LoadableActivities from 'components/activities/loadable_activities/'
import LoadableIdeaBoard from 'components/idea_board/loadable_idea_board'
import Login from 'src/pages/Login'
import { OrganizationForm } from 'components/organization_form'
import { OurImpact } from 'components/our_impact'
import { PointsDashboard } from 'components/points_dashboard'
import { PrivacyPolicy } from 'components/PrivacyPolicy'
import RequestDonationMatchForm from 'src/pages/RequestDonationMatchForm'
import SessionDebug from 'src/pages/SessionDebug'
import SudoPage from 'src/pages/SudoPage'
import ResetPasswordForm from 'src/pages/ResetPasswordForm'
import { TermsOfService } from 'components/TermsOfService'
import { UserProfile } from 'components/user_profile'
import { VolunteerHistory } from 'components/volunteer_history'
import { VolunteerHoursForm } from 'components/volunteer_hours_form'
import VolunteeringHistory from 'src/pages/VolunteeringHistory'
import VolunteeringForm from 'src/pages/VolunteeringForm'
import paths from './paths'
import SsoAuthForm from 'src/pages/Landing/components/SsoAuthForm'
import EmailAuthForm from 'src/pages/Landing/components/EmailAuthForm'
import MultiAuthForm from 'src/pages/Landing/components/MultiAuthForm'
import ResetPassword from 'src/pages/admin/ResetPassword'
import MagicLinkForm from 'src/pages/Landing/components/MagicLinkForm'
import EmailActivateLanding from 'src/pages/Landing/components/EmailActivateLanding'

// Sub-Components
import {
  editGuestPath,
  eventPath,
  joinWaitlistPath,
  newGuestPath,
  selfRegistrationPath,
  waiverPath,
} from 'components/events/routes'
import { getPointsDashboardLink } from 'components/shared/navigator/utils'
import RetroactiveMatchForm from '../../src/pages/RetroactiveMatchForm'

const groupSubPageParam =
  ':sub_page(discussions|events_and_activities|newsfeed|photos_and_videos|resources|members|impact)?'

const nativeOnlyRoutes = [
  // Our native app will match against this route even though the initial path
  // of the webview doesn't match against any of our other routes. This ensures
  // that we will successfully render the login screen on initial app boot.
  { component: LoadableActivities },
]

const sharedAuthenticatedRoutes = [
  {
    component: ActivitySeries,
    exact: true,
    path: '/activity_series/:id',
  },
  {
    component: Broadcast,
    exact: true,
    path: '/blogs/:id',
  },
  {
    component: Broadcasts,
    exact: true,
    path: '/blogs',
  },
  {
    component: Campaign,
    exact: true,
    path: '/campaigns/:id',
  },
  {
    component: CampaignPreview,
    exact: true,
    path: '/campaigns/:id/preview',
  },
  {
    component: CampaignTemplatePreview,
    exact: true,
    path: '/campaign_templates/:id',
  },
  {
    component: CarbonCalculatorPage,
    exact: true,
    path: '/app/carbon_calculator',
  },
  {
    component: CarbonFootprintPage,
    exact: true,
    path: '/carbon_footprint',
  },
  {
    component: CharitySearch,
    exact: true,
    path: '/app/charity_search/:source',
  },
  {
    component: EventRegistrationForm,
    exact: true,
    path: eventPath(':event_id'),
  },
  {
    component: GuestRegistrationPage,
    path: newGuestPath({
      eventId: ':event_id',
      shiftId: ':timeframe_id',
    }),
  },
  {
    component: GuestRegistrationPage,
    path: editGuestPath({
      eventId: ':event_id',
      guestId: ':guest_id',
      shiftId: ':timeframe_id',
    }),
  },
  {
    component: JoinEventWaitlistPage,
    exact: true,
    path: joinWaitlistPath({
      eventId: ':eventId',
      shiftId: ':shiftId',
    }),
  },
  {
    component: EventConfirmationForm,
    path: selfRegistrationPath({
      eventId: ':event_id',
      shiftId: ':timeframe_id',
    }),
  },
  {
    component: EventWaiver,
    path: waiverPath(':id'),
  },
  { component: LoadableActivities, exact: true, path: '/activities' },
  { component: LoadableActivities, path: '/activities/:channel' },
  { component: LoadableActivities, exact: true, path: '/take_action' },
  { component: LoadableActivities, path: '/take_action/:channel' },
  { component: LoadableIdeaBoard, exact: true, path: '/idea_boards/:id' },
  {
    component: LoadableIdeaBoard,
    isPreview: true,
    path: '/management_panel/idea_boards/:id/preview',
  },
  {
    component: IdeaFormPage,
    path: '/idea_boards/:id/ideas/new',
  },
  {
    component: IdeaFormPage,
    isPreview: true,
    path: '/management_panel/idea_boards/:id/ideas/new/preview',
  },
  {
    component: IdeaFormPage,
    path: '/idea_boards/:id/ideas/:idea_id',
  },
  { component: DonationHistory, path: '/users/me/donation_history' },
  { component: GivingActivity, path: '/giving_activities/:id' },
  {
    component: GivingActivity,
    isPreview: true,
    path: '/management_panel/giving_activities/:id/preview',
  },
  { component: DonationForm, path: '/donations/new/:ein' },
  { component: DonationSuccess, path: '/donations/success' },
  { component: DonationTerms, path: '/donations/terms_and_conditions' },
  { component: GrantDonationForm, path: '/grant_donations/new/:ein' },
  {
    component: Group,
    path: `/groups/:group_id/chapters/:id/${groupSubPageParam}`,
  },
  {
    component: Group,
    path: `/groups/:id/${groupSubPageParam}`,
  },
  { component: Groups, path: '/groups' },
  { component: KudoFormPage, path: '/recognize' },
  { component: OrganizationForm, path: '/organizations/new' },
  { component: OurImpact, exact: true, path: '/our_impact' },
  { component: ConnectedDevices, exact: true, path: '/connected_devices' },
  {
    component: UserProfile,
    path: '/profile/:sub_page(overview|my_activities|my_impact)?',
  },
  { component: VolunteerHistory, path: '/users/me/volunteer_history' },
  { component: VolunteerHoursForm, path: '/users/me/volunteer_hours_form' },
  {
    component: VolunteerHoursForm,
    exact: true,
    path: '/users/me/volunteer_hours_form/:id',
  },
  {
    component: PointsDashboard,
    exact: true,
    path: getPointsDashboardLink.href,
  },
  {
    component: GivingForm,
    exact: true,
    path: '/app/donation_form/:ein',
  },
  {
    component: DonationHistoryPage,
    exact: true,
    path: '/app/donation_history_page',
  },
  {
    component: DonationSuccessPage,
    exact: true,
    path: '/app/donation_success_page/:id',
  },
  {
    component: DollarsForDoersForm,
    exact: true,
    path: '/app/dollars_for_doers_form/:ein',
  },
  {
    component: RetroactiveMatchForm,
    exact: true,
    path: '/app/retroactive_match_form/:ein/:charity_name',
  },
  {
    component: RequestDonationMatchForm,
    exact: true,
    path: '/app/request_donation_match',
  },
  {
    component: SudoPage,
    exact: true,
    path: '/app/sudo',
  },
  {
    component: SessionDebug,
    exact: true,
    path: '/app/session',
  },
  {
    component: SessionDebug,
    exact: true,
    path: '/app/config/users/:user_id/session',
  },
  {
    component: ResetPassword,
    exact: true,
    path: '/app/config/users/:user_id/reset_password',
  },
  {
    component: VolunteeringHistory,
    exact: true,
    path: '/app/volunteering',
  },
  {
    component: VolunteeringForm,
    exact: true,
    path: '/app/volunteering_form',
  },
]

const authenticatedRoutesNative =
  sharedAuthenticatedRoutes.concat(nativeOnlyRoutes)

const publicRoutes = [
  {
    component: EmailActivateLanding,
    exact: true,
    path: '/app/landing/email_activation',
  },
  { component: GoogleAuth, exact: true, path: paths.googleLogin },
  { component: EmailAuth, exact: true, path: paths.emailLogin },
  { component: OldLanding, exact: true, path: paths.landing },
  { component: Landing, exact: true, path: '/app/landing' },
  { component: EmailAuthForm, exact: true, path: '/app/landing/email' },
  { component: MagicLinkForm, exact: true, path: '/app/landing/magic_link' },
  { component: MultiAuthForm, exact: true, path: '/app/landing/multiAuth' },
  { component: SsoAuthForm, exact: true, path: '/app/landing/sso' },
  { component: ResetPasswordForm, exact: true, path: '/app/reset_password' },
  { component: PrivacyPolicy, path: '/privacy' },
  { component: Login, exact: true, path: '/app/login' },
  { component: TermsOfService, path: '/terms_of_use' },
]

export const nativeRoutes = {
  authenticated: authenticatedRoutesNative,
  public: publicRoutes,
}
export const webRoutes = {
  authenticated: sharedAuthenticatedRoutes,
  public: publicRoutes,
}
