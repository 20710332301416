import gql from 'graphql-tag'

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation createOrganization($name: String!) {
    createOrganization(name: $name) {
      errors
      organization {
        id
        name
      }
    }
  }
`
