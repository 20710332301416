// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { intl, pluralTranslations } from 'utilities/localization'

// Includes messages that use the plural format and take in *only* a count
// argument. Knowing that they take in a count argument, we can for convenience
// create "singular" and "many plural" translations of these messages for
// ourselves ahead of time, by calling formatMessage and passing the correct
// arguments (see bottom of this file).
//
// We cannot do this for messages with a plural template that take in other
// arguments; those have to be added to messagesWithArgs below.
const pluralMessages = defineMessages({
  activities: {
    defaultMessage:
      '{count, plural, zero {Activities} one {Activity} two {Activities} few {Activities} many {Activities} other {Activities}}',
    id: 'shared.activities',
  },
  activitySeries: {
    defaultMessage:
      '{count, plural, zero {Activity Series} one {Activity Series} two {Activity Series} few {Activity Series} many {Activity Series} other {Activity Series}}',
    description: 'An activity series is a collection of ordered activities',
    id: 'shared.activitySeries',
  },
  campaigns: {
    defaultMessage:
      '{count, plural, zero {Campaigns} one {Campaign} two {Campaigns} few {Campaigns} many {Campaigns} other {Campaigns}}',
    description:
      'A Campaign is a series of actions positioned around a central theme intended to drive behavior change',
    id: 'shared.campaigns',
  },
  campaignsAndCompetitions: {
    defaultMessage:
      '{count, plural, zero {Campaigns and Competitions} one {Campaign or Competition} two {Campaigns and Competitions} few {Campaigns and Competitions} many {Campaigns and Competitions} other {Campaigns and Competitions}}',
    id: 'shared.campaignsAndCompetitions',
  },
  carbonFootprint: {
    defaultMessage: 'Carbon Footprint',
    description: 'Placeholder for carbonfootprint page',
    id: 'shared.carbonFootprint',
  },
  chapters: {
    defaultMessage:
      // eslint-disable-next-line formatjs/enforce-plural-rules
      '{count, plural, one {Chapter} other {Chapters}}',
    id: 'shared.chapters',
  },
  comments: {
    defaultMessage:
      '{count, plural, zero {comments} one {comment} two {comments} few {comments} many {comments} other {comments}}',
    id: 'shared.comments',
  },
  competitions: {
    defaultMessage:
      '{count, plural, zero {Competitions} one {Competition} two {Competitions} few {Competitions} many {Competitions} other {Competitions}}',
    description:
      'Competitions use the same main elements as Campaigns (actions and points) but with the additional elements of a Registration Timeframe and Team Leaderboards',
    id: 'shared.competitions',
  },
  completedActions: {
    defaultMessage:
      '{count, plural, zero {Completed Actions} one {Completed Action} two {Completed Actions} few {Completed Actions} many {Completed Actions} other {Completed Actions}}',
    id: 'shared.completedActions',
  },
  donations: {
    defaultMessage:
      '{count, plural, zero {Donations} one {Donation} two {Donations} few {Donations} many {Donations} other {Donations}}',
    id: 'shared.donations',
  },
  events: {
    defaultMessage:
      '{count, plural, zero {Events} one {Event} two {Events} few {Events} many {Events} other {Events}}',
    id: 'shared.events',
  },
  givingActivities: {
    defaultMessage:
      '{count, plural, zero {Giving Activities} one {Giving Activity} two {Giving Activities} few {Giving Activities} many {Giving Activities} other {Giving Activities}}',
    id: 'shared.givingActivities',
  },
  hours: {
    defaultMessage:
      '{count, plural, zero {hours} one {hour} two {hours} few {hours} many {hours} other {hours}}',
    id: 'shared.hours',
  },
  ideaBoards: {
    defaultMessage:
      '{count, plural, zero {Idea Boards} one {Idea Board} two {Idea Boards} few {Idea Boards} many {Idea Boards} other {Idea Boards}}',
    id: 'shared.ideaBoards',
  },
  ideas: {
    defaultMessage:
      '{count, plural, zero {Ideas} one {Idea} two {Ideas} few {Ideas} many {Ideas} other {Ideas}}',
    id: 'shared.ideas',
  },
  ideasSubmitted: {
    defaultMessage:
      '{count, plural, zero {Ideas} one {Idea} two {Ideas} few {Ideas} many {Ideas} other {Ideas}} Submitted',
    id: 'shared.ideasSubmitted',
  },
  instances: {
    defaultMessage:
      '{count, plural, zero {instances} one {instance} two {instances} few {instances} many {instances} other {instances}}',
    description: `Used to measure progress related to user activity recorded manually or by API-connected fitness trackers like a Fitbit, e.g.'I logged 3 {instances} of walking this week'.`,
    id: 'shared.instances',
  },
  locales: {
    defaultMessage:
      '{count, plural, zero {Locales} one {Locale} two {Locales} few {Locales} many {Locales} other {Locales}}',
    id: 'shared.locales',
  },
  organizations: {
    defaultMessage:
      '{count, plural, zero {Organizations} one {Organization} two {Organizations} few {Organizations} many {Organizations} other {Organizations}}',
    id: 'shared.organizations',
  },
  photos: {
    defaultMessage:
      '{count, plural, zero {Photos} one {Photo} two {Photos} few {Photos} many {Photos} other {Photos}}',
    id: 'shared.photos',
  },
  posts: {
    defaultMessage:
      '{count, plural, zero {Posts} one {Post} two {Posts} few {Posts} many {Posts} other {Posts}}',
    id: 'shared.posts',
  },
  steps: {
    defaultMessage:
      '{count, plural, zero {steps} one {step} two {steps} few {steps} many {steps} other {steps}}',
    description: `Used to measure progress related to user activity recorded manually or by API-connected fitness trackers like a Fitbit, e.g.'Went for a walk and took 1000 {steps}'.`,
    id: 'shared.steps',
  },
  tags: {
    defaultMessage:
      '{count, plural, zero {Tags} one {Tag} two {Tags} few {Tags} many {Tags} other {Tags}}',
    description: `List of platform users that an author can "tag" in their social feed post, which associates those users to the post and notifies them.`,
    id: 'shared.tags',
  },
  users: {
    defaultMessage:
      '{count, plural, zero {Users} one {User} two {Users} few {Users} many {Users} other {Users}}',
    id: 'shared.users',
  },
  volunteerHours: {
    defaultMessage:
      '{count, plural, zero {Volunteer Hours} one {Volunteer Hour} two {Volunteer Hours} few {Volunteer Hours} many {Volunteer Hours} other {Volunteer Hours}}',
    id: 'shared.volunteerHours',
  },
  votes: {
    defaultMessage:
      '{count, plural, zero {Votes} one {Vote} two {Votes} few {Votes} many {Votes} other {Votes}}',
    id: 'shared.votes',
  },
  yourDonations: {
    defaultMessage:
      'Your {count, plural, zero {Donations} one {Donation} two {Donations} few {Donations} many {Donations} other {Donations}}',
    description: 'a collection of gifts to a charity',
    id: 'shared.yourDonations',
  },
})

// Includes messages that take in arguments that we can never try to assume
// ahead of time, so therefore we always have to use them by passing them to
// formatMessage() with their args supplied. This includes messages that use
// the plural message format, but take in other arguments, as well.
const messagesWithArgs = defineMessages({
  activitiesWithCount: {
    defaultMessage:
      '{count, plural, zero {# Activities} one {# Activity} two {# Activities} few {# Activities} many {# Activities} other {# Activities}}',
    id: 'shared.activitiesWithCount',
  },
  campaignsAndCompetitionsWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Campaigns and Competitions} one {Campaign or Competition} two {Campaigns and Competitions} few {Campaigns and Competitions} many {Campaigns and Competitions} other {Campaigns and Competitions}}',
    id: 'shared.campaignsAndCompetitionsWithCount',
  },
  changePosition: {
    defaultMessage: 'change the position of {recordName}',
    id: 'shared.changePosition',
  },
  completedActionsWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Completed Actions} one {Completed Action} two {Completed Actions} few {Completed Actions} many {Completed Actions} other {Completed Actions}}',
    id: 'shared.completedActionsWithCount',
  },
  completedCampaignsAndCompetitionsWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Completed Campaigns and Competitions} one {Completed Campaign or Competition} two {Completed Campaigns and Competitions} few {Completed Campaigns and Competitions} many {Completed Campaigns and Competitions} other {Completed Campaigns and Competitions}}',
    id: 'shared.completedCampaignsAndCompetitionsWithCount',
  },
  contactSupportDonationAccount: {
    defaultMessage:
      '<link>Contact support</link> to enable your donation account.',
    description:
      'Sentence explaining that you can contact our support team to enable your donation account',
    id: 'shared.contactSupportDonationAccount',
  },
  eligibleHoursWithCount: {
    defaultMessage:
      '{count, plural, zero {# eligible volunteer hours} one {# eligible volunteer hour} two {# eligible volunteer hours} few {# eligible volunteer hours} many {# eligible volunteer hours} other {# eligible volunteer hours}}',
    id: 'shared.eligibleHoursWithCount',
  },
  eventsWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Events} one {Event} two {Events} few {Events} many {Events} other {Events}}',
    id: 'shared.eventsWithCount',
  },
  hoursWithCount: {
    defaultMessage:
      '{count, plural, zero {# hours} one {# hour} two {# hours} few {# hours} many {# hours} other {# hours}}',
    id: 'shared.hoursWithCount',
  },
  ideaBoardsWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Idea Boards} one {Idea Board} two {Idea Boards} few {Idea Boards} many {Idea Boards} other {Idea Boards}}',
    id: 'shared.ideaBoardsWithCount',
  },
  ideasWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Ideas} one {Idea} two {Ideas} few {Ideas} many {Ideas} other {Ideas}}',
    id: 'shared.ideasWithCount',
  },
  instancesWithCount: {
    defaultMessage:
      '{count, plural, zero {# instances} one {# instance} two {# instances} few {# instances} many {# instances} other {# instances}}',
    description: `Used to measure progress related to user activity recorded manually or by API-connected fitness trackers like a Fitbit, e.g.'I logged 3 {instances} of walking this week'.`,
    id: 'shared.instancesWithCount',
  },
  loadMore: {
    defaultMessage: 'Load {number} More {post}',
    description:
      'Indicates that users can load a number of more posts on a page',
    id: 'shared.loadMore',
  },
  localesWithCount: {
    defaultMessage:
      '{count, plural, zero {# Locales} one {# Locale} two {# Locales} few {# Locales} many {# Locales} other {# Locales}}',
    id: 'shared.localesWithCount',
  },
  minutesWithCount: {
    defaultMessage:
      '{count, plural, zero {# active minutes} one {# active minute} two {# active minutes} few {# active minutes} many {# active minutes} other {# active minutes}}',
    description: `Used to measure progress related to user activity measured by duration recorded manually or by API-connected fitness trackers like a Fitbit, e.g.'Went for a walk and had 1000 {active minutes}'.`,
    id: 'shared.minutesWithCount',
  },
  moveItemDown: {
    defaultMessage: 'Move {title} Down',
    id: 'shared.moveItemDown',
  },
  moveItemUp: {
    defaultMessage: 'Move {title} Up',
    id: 'shared.moveItemUp',
  },
  newsfeedError: {
    defaultMessage:
      'There was a problem loading the recent activity for this {type}.',
    id: 'shared.newsfeedError',
  },
  objectDescriptionChange: {
    defaultMessage: '{recordName} has {actionPerformed}',
    id: 'shared.objectDescriptionChange',
  },
  operationCreate: {
    defaultMessage: 'create {className}',
    id: 'shared.operationCreate',
  },
  operationUpdate: {
    defaultMessage: 'update {className}',
    id: 'shared.operationUpdate',
  },
  publishTeams: {
    defaultMessage:
      '{count, plural, =0 {All Teams} zero {All Teams} one {# Team} two {# Teams} few {# Teams} many {# Teams} other {# Teams}}',
    id: 'shared.publishTeams',
  },
  recordSaved: {
    defaultMessage: '{className} was successfully {action}.',
    id: 'shared.recordSaved',
  },
  resourceNotFound: {
    defaultMessage: '{resource} not found',
    id: 'shared.resourceNotFound',
  },
  spotsLeft: {
    defaultMessage:
      '{count, plural, zero {# spots} one {# spot} two {# spots} few {# spots} many {# spots} other {# spots}} left',
    id: 'shared.spotsLeft',
  },
  spotsLeftBolded: {
    defaultMessage:
      '<bold>{count, number}</bold> {count, plural, zero {spots} one {spot} two {spots} few {spots} many {spots} other {spots}} left',
    id: 'shared.spotsLeftBolded',
  },
  stepsWithCount: {
    defaultMessage:
      '{count, plural, zero {# steps} one {# step} two {# steps} few {# steps} many {# steps} other {# steps}}',
    description: `Used to measure progress related to user activity recorded manually or by API-connected fitness trackers like a Fitbit, e.g.'Went for a walk and took 1000 {steps}'.`,
    id: 'shared.stepsWithCount',
  },
  volunteerHoursWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Volunteer Hours} one {Volunteer Hour} two {Volunteer Hours} few {Volunteer Hours} many {Volunteer Hours} other {Volunteer Hours}}',
    id: 'shared.volunteerHoursWithCount',
  },
  votesWithCount: {
    defaultMessage:
      '<countStyle>{count, number}</countStyle> {count, plural, zero {Votes} one {Vote} two {Votes} few {Votes} many {Votes} other {Votes}}',
    id: 'shared.votesWithCount',
  },
})

// Includes messages that we can always translate ahead of time by passing them
// to `formatMessage()` with no arguments, so that we don't have to pass them
// to formatMessage() when we need to use them in our components and utils.
const messagesWithNoArgs = defineMessages({
  about: {
    defaultMessage: 'About',
    id: 'shared.about',
  },
  achievements: {
    defaultMessage: 'Achievements',
    id: 'shared.achievements',
  },
  actions: {
    defaultMessage: 'Actions',
    description:
      'Micro-learning opportunities, to engage employees around a particular topic',
    id: 'shared.actions',
  },
  active: {
    defaultMessage: 'active',
    id: 'shared.active',
  },
  admin: {
    defaultMessage: 'Admin',
    id: 'shared.admin',
  },
  ago: {
    defaultMessage: 'ago',
    description: 'how far in the past the activity occurred',
    id: 'shared.ago',
  },
  all: {
    defaultMessage: 'All',
    id: 'shared.all',
  },
  announcement: {
    defaultMessage: 'Announcement',
    id: 'shared.announcement',
  },
  announcements: {
    defaultMessage: 'Announcements',
    id: 'shared.announcements',
  },
  approved: {
    defaultMessage: 'Approved',
    id: 'shared.approved',
  },
  archive: {
    defaultMessage: 'Archive',
    id: 'shared.archive',
  },
  awaitingPayment: {
    defaultMessage: 'Awaiting payment',
    id: 'shared.awaitingPayment',
  },
  awaitingProcessing: {
    defaultMessage: 'Awaiting processing',
    id: 'shared.awaitingProcessing',
  },
  back: {
    defaultMessage: 'Back',
    id: 'shared.back',
  },
  backTo: {
    defaultMessage: 'Back to',
    id: 'shared.backTo',
  },
  beTheFirstToParticipate: {
    defaultMessage: 'Be the first to participate!',
    description: 'Message for when no one has participated as of yet',
    id: 'shared.beTheFirstToParticipate',
  },
  broadcasts: {
    defaultMessage: 'Broadcasts',
    id: 'shared.broadcasts',
  },
  cancel: {
    defaultMessage: 'cancel',
    id: 'shared.cancel',
  },
  carbonImpact: {
    defaultMessage: 'Carbon Impact',
    id: 'shared.carbonImpact',
  },
  category: {
    defaultMessage: 'Category',
    id: 'shared.category',
  },
  channelLabel: {
    defaultMessage: 'Channel',
    id: 'shared.channelLabel',
  },
  chapter: {
    defaultMessage: 'Chapter',
    description: 'A Group within a Group',
    id: 'shared.chapter',
  },
  charity: {
    defaultMessage: 'Charity',
    id: 'shared.charity',
  },
  charityName: {
    defaultMessage: 'Charity Name',
    id: 'shared.charityName',
  },
  check: {
    defaultMessage: 'Check',
    description: 'Mode of payment to donation',
    id: 'shared.check',
  },
  checkout: {
    defaultMessage: 'Checkout',
    id: 'shared.checkout',
  },
  choosePeopleToTag: {
    defaultMessage: 'Choose people to tag',
    id: 'shared.choosePeopleToTag',
  },
  cityAndState: {
    defaultMessage: 'City and State',
    id: 'shared.cityAndState',
  },
  company: {
    defaultMessage: 'Company',
    id: 'shared.company',
  },
  competitionPeriod: {
    defaultMessage: 'Competition period',
    description: 'Term of the competition',
    id: 'shared.competitionPeriod',
  },
  complete: {
    defaultMessage: 'Complete',
    id: 'shared.complete',
  },
  completed: {
    defaultMessage: 'Completed',
    id: 'shared.completed',
  },
  completeThisAction: {
    defaultMessage: 'complete this Action',
    description: 'Complete the action text',
    id: 'shared.completeThisAction',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: 'shared.contactSupport',
  },
  create: {
    defaultMessage: 'Create',
    id: 'shared.create',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
    id: 'shared.dashboard',
  },
  date: {
    defaultMessage: 'Date',
    description:
      'A specific calendar-based time, usually represented as some combination of day, month, and year',
    id: 'shared.date',
  },
  denied: {
    defaultMessage: 'Denied',
    id: 'shared.denied',
  },
  description: {
    defaultMessage: 'Description',
    description: 'Text indicating that what follows is a description',
    id: 'shared.description',
  },
  descriptionTranslation: {
    defaultMessage: 'Description Translation',
    id: 'shared.descriptionTranslation',
  },
  details: {
    defaultMessage: 'Details',
    id: 'shared.details',
  },
  donate: {
    defaultMessage: 'Donate',
    id: 'shared.donate',
  },
  donationHistory: {
    defaultMessage: 'Donation History',
    description: 'A record of a users donation activities',
    id: 'shared.donationHistory',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'shared.edit',
  },
  editYourRegistration: {
    defaultMessage: 'Edit Your Registration',
    id: 'shared.editYourRegistration',
  },
  electronic: {
    defaultMessage: 'Electronic',
    description: 'An electronic payment.',
    id: 'shared.electronic',
  },
  email: {
    defaultMessage: 'Email',
    id: 'shared.email',
  },
  emojiSelector: {
    defaultMessage: 'Emoji Selector',
    id: 'shared.emojiSelector',
  },
  enable: {
    defaultMessage: 'Enable',
    description:
      'Indicates that something in our user interface can be enabled',
    id: 'shared.enable',
  },
  ended: {
    defaultMessage: 'ended',
    id: 'shared.ended',
  },
  export: {
    defaultMessage: 'Export',
    id: 'shared.export',
  },
  featureDisabledPageBody: {
    defaultMessage:
      'Your administrator has not enabled this feature. Please contact your administrator with any questions.',
    id: 'shared.featureDisabledPageBody',
  },
  filterButtonLabel: {
    defaultMessage: 'Filter',
    id: 'shared.filterButtonLabel',
  },
  formErrorValidationText: {
    defaultMessage: 'Please add either a comment, photo, or tag other people.',
    id: 'shared.formErrorValidationText',
  },
  formFieldShareWithOthersHelperText: {
    defaultMessage:
      'Write about your experience with this or leave advice for others.',
    description:
      'Helper text encouraging users to share their experiences with the action',
    id: 'shared.formFieldShareWithOthersHelperText',
  },
  giving: {
    defaultMessage: 'Giving',
    id: 'shared.giving',
  },
  goBack: {
    defaultMessage: 'Go back',
    id: 'shared.goBack',
  },
  group: {
    defaultMessage: 'Group',
    description: 'An employee resource group',
    id: 'shared.group',
  },
  groups: {
    defaultMessage: 'Groups',
    description: 'A page which contains a list of groups',
    id: 'shared.groups',
  },
  invite: {
    defaultMessage: 'Invite',
    id: 'shared.invite',
  },
  lastUpdated: {
    defaultMessage: 'Last Updated',
    id: 'shared.lastUpdated',
  },
  loading: {
    defaultMessage: 'Loading',
    description: 'Indicates that something in our user interface is loading',
    id: 'shared.loading',
  },
  logYourVolunteerHours: {
    defaultMessage: 'Log Your Volunteer Hours',
    id: 'shared.logYourVolunteerHours',
  },
  managementPanel: {
    defaultMessage: 'Management Panel',
    description: 'Where admins manage content for their brands',
    id: 'shared.managementPanel',
  },
  module: {
    defaultMessage: 'Module',
    id: 'shared.module',
  },
  movedDown: {
    defaultMessage: 'moved down',
    id: 'shared.movedDown',
  },
  movedUp: {
    defaultMessage: 'moved up',
    id: 'shared.movedUp',
  },
  myActivities: {
    defaultMessage: 'My Activities',
    description:
      'Describes the activities that a user is currently participating in',
    id: 'shared.myActivities',
  },
  name: {
    defaultMessage: 'Name',
    id: 'shared.name',
  },
  nameTranslation: {
    defaultMessage: 'Name Translation',
    id: 'shared.nameTranslation',
  },
  new: {
    defaultMessage: 'New',
    id: 'shared.new',
  },
  newsfeed: {
    defaultMessage: 'Newsfeed',
    description: 'A newsfeed of user activity',
    id: 'shared.newsfeed',
  },
  newsfeedAndAnnouncements: {
    defaultMessage: 'Announcements',
    description: 'A newsfeed of user activity with group announcements',
    id: 'shared.newsfeedAndAnnouncements',
  },
  newsfeedLoading: {
    defaultMessage: 'Loading more posts',
    id: 'shared.newsfeedLoading',
  },
  next: {
    defaultMessage: 'Next',
    id: 'shared.next',
  },
  no: {
    defaultMessage: 'No',
    id: 'shared.no',
  },
  noCommentsMessage: {
    defaultMessage: 'Be the first to comment!',
    description: 'Message displayed when no users have commented yet',
    id: 'shared.noCommentsMessage',
  },
  notApplicableAbbreviation: {
    defaultMessage: 'N/A',
    description: 'Abbreviated form of the phrase "not applicable"',
    id: 'shared.notApplicableAbbreviation',
  },
  notEligible: {
    defaultMessage: 'not eligible',
    id: 'shared.notEligible',
  },
  offline: {
    defaultMessage: 'Offline',
    id: 'shared.offline',
  },
  ongoing: {
    defaultMessage: 'Ongoing',
    description:
      'Used to indicate when something will be available forever, such as in a date range: April 19, 2020 - Ongoing',
    id: 'shared.ongoing',
  },
  organizationName: {
    defaultMessage: 'Organization Name',
    id: 'shared.organizationName',
  },
  ourImpact: {
    defaultMessage: 'Our Impact',
    description:
      'Heading of page showing different company wide impact statistics on health, sustainability, environment, etc.',
    id: 'shared.ourImpact',
  },
  overview: {
    defaultMessage: 'Overview',
    id: 'shared.overview',
  },
  paid: {
    defaultMessage: 'Paid',
    description: 'A fulfilled transaction.',
    id: 'shared.paid',
  },
  paidVolunteerHours: {
    defaultMessage: 'Paid Volunteer Hours',
    id: 'shared.paidVolunteerHours',
  },
  participants: {
    defaultMessage: 'participants',
    id: 'shared.participants',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'shared.Pending',
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'shared.previous',
  },
  previousPage: {
    defaultMessage: 'Previous Page',
    id: 'shared.previousPage',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    id: 'shared.privacyPolicy',
  },
  profile: {
    defaultMessage: 'Profile',
    id: 'shared.profile',
  },
  publish: {
    defaultMessage: 'publish',
    id: 'shared.publish',
  },
  readLess: {
    defaultMessage: 'Read less',
    id: 'shared.readLess',
  },
  readMore: {
    defaultMessage: 'Read more',
    id: 'shared.readMore',
  },
  receivedByCharity: {
    defaultMessage: 'Received by charity',
    id: 'shared.receivedByCharity',
  },
  recognition: {
    defaultMessage: 'Recognition',
    id: 'shared.recognition',
  },
  remaining: {
    defaultMessage: 'remaining',
    description: 'amount of time left',
    id: 'shared.remaining',
  },
  required: {
    defaultMessage: 'required',
    id: 'shared.required',
  },
  running: {
    defaultMessage: 'running',
    description: `Category of user activity recorded manually or by API-connected fitness trackers like a Fitbit.`,
    id: 'shared.running',
  },
  save: {
    defaultMessage: 'Save',
    id: 'shared.save',
  },
  scheduled: {
    defaultMessage: 'scheduled',
    id: 'shared.scheduled',
  },
  scheduledForPayment: {
    defaultMessage: 'Scheduled for payment',
    id: 'shared.scheduledForPayment',
  },
  search: {
    defaultMessage: 'Search',
    id: 'shared.search',
  },
  settings: {
    defaultMessage: 'Settings',
    id: 'shared.settings',
  },
  shareWithOthers: {
    defaultMessage: 'Share With Others',
    id: 'shared.shareWithOthers',
  },
  shareYourStoryWithOthers: {
    defaultMessage: 'Share your story with others.',
    id: 'shared.shareYourStoryWithOthers',
  },
  sort: {
    defaultMessage: 'Sort',
    id: 'shared.sort',
  },
  statusLabel: {
    defaultMessage: 'Status',
    id: 'shared.status',
  },
  support: {
    defaultMessage: 'Support',
    id: 'shared.support',
  },
  tagOtherPeople: {
    defaultMessage: 'Tag other people.',
    id: 'shared.tagOtherPeople',
  },
  takeAction: {
    defaultMessage: 'Take Action',
    description: 'A page that displays the available content to the user',
    id: 'shared.takeAction',
  },
  teams: {
    defaultMessage: 'Teams',
    description:
      'A page which shared stats on the performance of teams, individuals and the company on the platform',
    id: 'shared.teams',
  },
  termsOfService: {
    defaultMessage: 'Terms of Service',
    id: 'shared.termsOfService',
  },
  title: {
    defaultMessage: 'Title',
    id: 'shared.title',
  },
  toggleDetails: {
    defaultMessage: 'Toggle details',
    id: 'shared.toggleDetails',
  },
  tonnes: {
    defaultMessage: 'tonnes CO²e',
    id: 'shared.tonnes',
  },
  total: {
    defaultMessage: 'total',
    id: 'shared.total',
  },
  totalActionsCompleted: {
    defaultMessage: 'total Actions completed',
    description: 'Label for statistic on how many actions have been completed.',
    id: 'shared.totalActionsCompleted',
  },
  totalActivities: {
    defaultMessage: 'Total Activities',
    id: 'shared.totalActivities',
  },
  totalHoursVolunteered: {
    defaultMessage: 'total hours volunteered',
    description:
      'Label for statistic on how many hours employees have volunteered.',
    id: 'shared.totalHoursVolunteered',
  },
  translate: {
    defaultMessage: 'Translate',
    id: 'shared.translate',
  },
  translateConfirmationQuestion: {
    defaultMessage:
      'Are you sure you want to create automated transations? All filled translations would be overwritten and saved with results from generated translation',
    id: 'shared.translateConfirmationQuestion',
  },
  unknown: {
    defaultMessage: 'Unknown',
    id: 'shared.unknown',
  },
  user: {
    defaultMessage: 'User',
    id: 'shared.user',
  },
  view: {
    defaultMessage: 'View',
    description:
      'Used in buttons and labels to indicate to the user that they can see or trigger the display of content',
    id: 'shared.view',
  },
  viewChannel: {
    defaultMessage: 'View Channel',
    description:
      'Used in buttons and label to indicate to the user that they can see or trigger the display of content',
    id: 'shared.viewChannel',
  },
  visibility: {
    defaultMessage: 'Visibility',
    id: 'shared.visibility',
  },
  volunteerHistory: {
    defaultMessage: 'Volunteer History',
    description: 'A record of a users volunteering activities',
    id: 'shared.volunteerHistory',
  },
  voted: {
    defaultMessage: 'Voted',
    id: 'shared.voted',
  },
  walking: {
    defaultMessage: 'walking',
    description: `Category of user activity recorded manually or by API-connected API-connected fitness trackers like a Fitbit.`,
    id: 'shared.walking',
  },
  yearLabel: {
    defaultMessage: 'Year',
    id: 'shared.yearLabel',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'shared.yes',
  },
  yourImpact: {
    defaultMessage: 'your impact',
    description:
      "Refers to the current user's impact and contributions tracked on the platform",
    id: 'shared.yourImpact',
  },
  yourOrder: {
    defaultMessage: 'your order',
    id: 'shared.yourOrder',
  },
  yourProgress: {
    defaultMessage: 'your progress',
    description:
      "Refers to the current user's progress towards some goal, likely as a percent",
    id: 'shared.yourProgress',
  },
  yourVolunteerHours: {
    defaultMessage: 'Your Volunteer Hours',
    description:
      "Refers to the current user's volunteering efforts tracked on the platform",
    id: 'shared.yourVolunteerHours',
  },
})

// Helper that will take defined messages (WITHOUT VARIABLES!) and format
// all of them.
function formatMessages(messages) {
  let translations = {}
  Object.entries(messages).forEach(([key, message]) => {
    translations[key] = intl.formatMessage(message)
  })
  return translations
}

const translations = formatMessages(messagesWithNoArgs)
const sharedMessages = {
  ...messagesWithArgs,
  ...messagesWithNoArgs,
  ...pluralMessages,
}

// Singularize a few of our plural messages for convenience.
// This way places that don't ever have a count can just use the singular version.
function singular(pluralMessage) {
  return intl.formatMessage(pluralMessage, { count: 1 })
}

translations.activity = singular(pluralMessages.activities)
translations.activityseries = singular(pluralMessages.activitySeries)
translations.campaign = singular(pluralMessages.campaigns)
translations.comment = singular(pluralMessages.comments)
translations.competition = singular(pluralMessages.competitions)
translations.event = singular(pluralMessages.events)
translations.givingactivity = singular(pluralMessages.givingActivities)
translations.ideaboard = singular(pluralMessages.ideaBoards)
translations.organization = singular(pluralMessages.organizations)
translations.photo = singular(pluralMessages.photos)
translations.user = singular(pluralMessages.users)
translations.vote = singular(pluralMessages.votes)
translations.yourDonation = singular(pluralMessages.yourDonations)

const sharedTranslations = {
  ...translations,
  ...pluralTranslations(pluralMessages),
}

export { formatMessages, sharedMessages, sharedTranslations }
