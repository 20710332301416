// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const CenteredContent = ({
  as,
  children,
  fullWidth,
  pull,
  pullDesktop,
  ...other
}) => {
  const Component = as

  return (
    <Component
      {...other}
      className={cx(
        'centered',
        { 'centered--pull z-1': pull },
        { 'centered--pull-desktop z-1': pullDesktop },
        { 'centered--xl': !fullWidth },
        other.className
      )}
    >
      {children}
    </Component>
  )
}

CenteredContent.propTypes = {
  as: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fullWidth: PropTypes.bool,
  pull: PropTypes.bool,
  pullDesktop: PropTypes.bool,
}

CenteredContent.defaultProps = {
  as: 'div',
  fullWidth: false,
  pull: false,
  pullDesktop: false,
}

export default CenteredContent
