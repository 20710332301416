// Vendor// WeSpire
import { displayExceptionBanner } from 'redux/dispatchers'
import { getCookie } from 'utilities/cookies'

/*
 * Here we can define different JS initializations we want to take place once
 * and only once.
 */
const miscInits = () => {
  initButtonFocusFix()
  initSessionTimeOutBanner()
}

// Fix Safari and Firefox to behave like Chrome where clicking on a button
// will give the button focus!
// We search for the closest "button" to handle the case where the event originates
// on a child of button, like an SVG.
// See https://zellwk.com/blog/inconsistent-button-behavior/
const initButtonFocusFix = () => {
  document.addEventListener('click', function (event) {
    // Only use closest if it exists (no IE).
    const button = event.target.closest
      ? event.target.closest('button')
      : event.target
    if (button) {
      button.focus()
    }
  })
}

// Display error banner when session times out in middle of a React page session
const initSessionTimeOutBanner = () => {
  const timeoutInMinutes = getCookie('session_length_minutes') || 0

  if (timeoutInMinutes === 0) return

  const timeOutInMilliseconds = timeoutInMinutes * 60 * 1000

  setTimeout(() => {
    window.sessionExpired = true
    displayExceptionBanner()
  }, timeOutInMilliseconds)
}

export { miscInits }
