import gql from 'graphql-tag'

export const CREATE_VOLUNTEER_LOG_MUTATION = gql`
  mutation createVolunteerLog(
    $address: Address!
    $addressLabel: String!
    $category: String!
    $date: String!
    $description: String!
    $ein: String
    $hours: Float!
    $image: String
    $organizationId: String
    $pvhHours: Int
    $taggedUserIds: [ID!]
  ) {
    createVolunteerLog(
      address: $address
      addressLabel: $addressLabel
      category: $category
      date: $date
      description: $description
      ein: $ein
      hours: $hours
      image: $image
      organizationId: $organizationId
      pvhHours: $pvhHours
      taggedUserIds: $taggedUserIds
    ) {
      errors
    }
  }
`

export const UPDATE_VOLUNTEER_LOG_MUTATION = gql`
  mutation updateVolunteerLog(
    $address: Address!
    $addressLabel: String!
    $category: String!
    $date: String!
    $description: String!
    $ein: String
    $hours: Float!
    $image: String
    $organizationId: String
    $pvhHours: Int
    $taggedUserIds: [ID!]
    $logId: ID!
  ) {
    updateVolunteerLog(
      id: $logId
      address: $address
      addressLabel: $addressLabel
      category: $category
      date: $date
      description: $description
      ein: $ein
      hours: $hours
      image: $image
      organizationId: $organizationId
      pvhHours: $pvhHours
      taggedUserIds: $taggedUserIds
    ) {
      errors
    }
  }
`

export const DELETE_VOLUNTEER_LOG_MUTATION = gql`
  mutation deleteVolunteerLog($id: ID!) {
    deleteVolunteerLog(id: $id) {
      errors
    }
  }
`
