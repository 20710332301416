import { defineMessages } from 'react-intl'

export const donationFormMessages = defineMessages({
  aboveMaxMatchedDonation: {
    defaultMessage:
      'Your personal donation amount must be less than or equal to your available matching gift funds.',
    id: 'donationForm.aboveMaxMatchedDonation',
  },
  amountValidation: {
    defaultMessage: 'Must be an amount of money in {currency}, e.g. {amount}',
    id: 'donationForm.amountValidation',
  },
  anonymousDonation: {
    defaultMessage: 'Donate anonymously',
    id: 'donationForm.anonymousDonation',
  },
  anonymousDonationHelpText: {
    defaultMessage:
      'If checked, your name and email address will be withheld from the charity.',
    id: 'donationForm.anonymousDonationHelpText',
  },
  availableBalance: {
    defaultMessage: 'Your Available Personal Grant Account Balance',
    id: 'donationForm.availableBalance',
  },
  availableMatchingGiftFunds: {
    defaultMessage: 'Available Matching Gift Funds',
    id: 'donationForm.availableMatchingGiftFunds',
  },
  balanceInformation: {
    defaultMessage:
      'Your Personal Grant Account may include balances from payroll deductions or funds made available to you by <bold>{brandName}</bold>.',
    id: 'donationForm.balanceInformation',
  },
  card: {
    defaultMessage: 'Credit/Debit Card',
    id: 'donationForm.card',
  },
  cardHelpText: {
    defaultMessage: 'Donate using your credit/debit card.',
    id: 'donationForm.cardHelpText',
  },
  charityMatchingGift: {
    defaultMessage: 'charity qualifies for a matching gift',
    id: 'donationForm.charityMatchingGift',
  },
  contactSupport: {
    defaultMessage:
      'This charity is not eligible for matching. Please <link>Contact Support</link> if you have any questions.',
    id: 'donationForm.contactSupport',
  },
  continueDonate: {
    defaultMessage: 'Continue to Donate',
    id: 'donationForm.continueDonate',
  },
  coveringAllFees: {
    defaultMessage:
      'Please note that credit card transaction fees will be deducted from your donation, and fulfillment fees of 3% will be added to your transaction amount.',
    id: 'donationForm.coveringAllFees',
  },
  coveringCreditFee: {
    defaultMessage:
      'Please note that transaction fees of approximately 3% will be deducted from your donation.',
    id: 'donationForm.coveringCreditFee',
  },
  coveringProcessingFee: {
    defaultMessage:
      'Please note fulfillment fees of 3% will be added to your transaction amount.',
    id: 'donationForm.coveringProcessingFee',
  },
  details: {
    defaultMessage: 'Donation details',
    id: 'donationForm.details',
  },
  donationAccount: {
    defaultMessage: 'Personal Grant Account',
    id: 'donationForm.donationAccount',
  },
  donationAccountHelpText: {
    defaultMessage: 'Donate using your employer funded Personal Grant Account.',
    id: 'donationForm.donationAccountHelpText',
  },
  donationAmount: {
    defaultMessage: 'Donation Amount',
    id: 'donationForm.donationAmount',
  },
  donationNote: {
    defaultMessage: 'Share an optional note with the charity',
    id: 'donationForm.donationNote',
  },
  donationNoteHelpText: {
    // eslint-disable-next-line formatjs/no-multiple-whitespaces
    defaultMessage: `Use this section to share additional information, such as an honoree, purpose,
    or another friendly note why you're donating that will be visible to the employees of the charity who receive your donation.`,
    id: 'donationForm.donationNoteHelpText',
  },
  footer: {
    defaultMessage:
      'All donation transactions will appear on your bank/credit card statements as <span>GIVINGA FOUNDATION</span>, our nonprofit 501(c)(3) partner. By making this donation, you are agreeing to the <link>Terms & Conditions</link>.',
    id: 'donationForm.footer',
  },
  fundsInformation: {
    defaultMessage:
      'You have <bold>{availableDonationFunds}</bold> remaining of the <bold>{donationFundsCap}</bold> annual limit per employee.',
    id: 'donationForm.fundsInformation',
  },
  fundsInformationUsd: {
    defaultMessage:
      'You have <bold>{availableDonationFunds}</bold> remaining of the <bold>{donationFundsCap}</bold> annual limit per employee.',
    id: 'donationForm.fundsInformationUsd',
  },
  helperText: {
    defaultMessage: 'Minimum donation amount is {minimumAmount}.',
    id: 'donationForm.helperText',
  },
  maximumValidation: {
    defaultMessage: 'Must be {maximumAmount} or less',
    id: 'donationForm.maximumValidation',
  },
  minimumValidation: {
    defaultMessage: 'Must be {minimumAmount} or more',
    id: 'donationForm.minimumValidation',
  },
  monthly: {
    defaultMessage: 'Monthly',
    id: 'donationForm.monthly',
  },
  myDonations: {
    defaultMessage: 'My Donations',
    id: 'donationForm.myDonations',
  },
  oneTime: {
    defaultMessage: 'One time',
    id: 'donationForm.oneTime',
  },
  operation: {
    defaultMessage: 'continue this donation',
    id: 'donationForm.operation',
  },
  pageTitle: {
    defaultMessage: 'Make a donation',
    id: 'donationForm.pageTitle',
  },
  paymentMethod: {
    defaultMessage: 'Payment Method',
    id: 'donationForm.paymentMethod',
  },
  paymentMethodHelpText: {
    defaultMessage: 'Choose the payment method you want to use to donate.',
    id: 'donationForm.paymentMethodHelpText',
  },
  projectName: {
    defaultMessage: 'Project Name',
    id: 'donationForm.projectName',
  },
  projectNameHelper: {
    defaultMessage: 'What project do you want to support? You can search by name or project number.',
    id: 'donationForm.projectNameHelper',
  },
  projectNameNotBlank: {
    defaultMessage: 'Please make sure to select a project before submitting the form',
    id: 'donationForm.projectNameNotBlank',
  },
  recurringDonationsHelperText: {
    defaultMessage: 'Choose how often your donation will occur.',
    id: 'donationForm.recurringDonationsHelperText',
  },
  recurringDonationsLabel: {
    defaultMessage: 'How often would you like to donate?',
    id: 'donationForm.recurringDonationsLabel',
  },
  requestMatchingGift: {
    defaultMessage: 'Request a Matching Gift',
    id: 'donationForm.requestMatchingGift',
  },
  requestSuccess: {
    defaultMessage: 'Your Funded Donation request was submitted.',
    id: 'donationForm.requestSuccess',
  },
  sufficientFunds: {
    defaultMessage: 'You must have sufficient Matching Gift funds available.',
    id: 'donationForm.sufficientFunds',
  },
  yourMatchingGiftFunds: {
    defaultMessage: 'your available matching gift funds',
    id: 'donationForm.yourMatchingGiftFunds',
  },
  yourPersonalDonation: {
    defaultMessage: 'Your personal donation amount',
    id: 'donationForm.yourPersonalDonation',
  },
  yourRecurringDonations: {
    defaultMessage:
      'You will receive an email 5 days prior to each monthly donation and can choose to cancel the transaction. You can manage and stop your monthly donations at any time from your {donationHistory} page. YOUR NEXT MONTHLY DONATION WILL OCCUR ON {nextMonthDate}.',
    id: 'donationForm.yourRecurringDonations',
  },
})
