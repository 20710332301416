/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Input from 'src/common/Input'
import Button from 'src/common/button/Button'
import { useParams } from 'react-router-dom'
import uploadToCloudinary from 'utilities/upload_to_cloudinary'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Modal from 'src/common/Modal'

const GET_CLOUDINARY_CONFIG = gql`
  query cloudinaryConfig {
    cloudinaryConfig {
      cloudName
      uploadPreset
    }
  }
`

const GET_AVAILABLE_MATCHING_FUNDS = gql`
  query currentUserAvailableMatchingFunds {
    currentUser {
      name
      givingaUser {
        availableDonationFunds
      }
    }
  }
`

const RETROACTIVE_MATCH_REQUEST = gql`
  mutation createRetroactiveMatchRequestMutation(
    $ein: String!
    $charityName: String!
    $donationDate: String!
    $matchAmount: Float!
    $filePath: String!
    $designation: String!
  ) {
    createRetroactiveMatchRequest(
      ein: $ein
      charityName: $charityName
      donationDate: $donationDate
      matchAmount: $matchAmount
      filePath: $filePath
      designation: $designation
    ) {
      errors
    }
  }
`

const DonationMatchForm = () => {
  const { ein, charity_name } = useParams()
  const history = useHistory()
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const maxDonationDate = new Date().toISOString().split("T")[0]
  const { data: { cloudinaryConfig: { cloudName, uploadPreset } = {} } = {} } =
    useQuery(GET_CLOUDINARY_CONFIG)

  const {
    data: {
      currentUser: {
        givingaUser: { availableDonationFunds} = {}, } = {}, } = {},
  } = useQuery(GET_AVAILABLE_MATCHING_FUNDS)

  const [sendRetroactiveMatchRequest] = useMutation(RETROACTIVE_MATCH_REQUEST)

  const handleSubmit = async (values) => {
    const fileExtension = values.receipt.name.split('.').pop().toLowerCase()
    const allowedFileTypes = ['jpeg', 'jpg', 'pdf', 'png']

    if (fileExtension === 'ai' || !allowedFileTypes.includes(fileExtension)) {
      alert("Receipt file type must be a .jpeg, .jpg, .pdf, or .png")
      console.log(fileExtension)
      return
    }

    if (availableDonationFunds < values.matchAmount) {
      alert("Match amount cannot exceed available match funds.")
      return
    }
    if (values.receipt) {
      const cloudinarySettings = {
        cloudName,
        uploadPreset,
      }

      try {
        const response = await uploadToCloudinary(
          values.receipt,
          cloudinarySettings
        )
        setIsSubmitting(true)
        const retroResponse = await sendRetroactiveMatchRequest({
          variables: {
            ein: ein,
            charityName: charity_name,
            donationDate: new Date(values.date).toLocaleDateString('en-US'),
            matchAmount: values.matchAmount,
            filePath: response.secureUrl,
            designation: values.designation,
          },
        })
        if(retroResponse.data.createRetroactiveMatchRequest.errors){
          setErrorMessages(retroResponse.data.createRetroactiveMatchRequest.errors)
          setErrorModalVisible(true)
        } else {
          history.push('/users/me/donation_history')
          return retroResponse
        }
      } catch (error) {
        setIsSubmitting(false)
        console.error('Error:', error)
      }
    }
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-2">Request A Match</h1>
      <p className="space-y-4 max-w-[37.5rem] w-11/12">
        To request a match, enter the details of your donation below and upload your donation receipt. Requests will be reviewed by an administrator and you will be notified when your request has been approved or denied.
      </p>
      <Formik
        initialValues={{
          ein: '',
          company: '',
          name: '',
          charity: '',
          date: '',
          matchAmount: '',
          designation: '',
          receipt: null,
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ setFieldValue }) => (
          <Form className="space-y-4 max-w-[37.5rem] w-11/12 px-4">
            <section className="flex flex-col mt-10">
              <div className="flex flex-col">

                <Input
                  label="Charity EIN*"
                  name="ein"
                  placeholder="Enter the EIN"
                  required
                  disabled
                  value={ein}
                  className="mb-5"
                />

                <Input
                  label="Charity Name*"
                  name="charity"
                  placeholder="Enter the charity's name"
                  required
                  className="mb-5"
                  disabled
                  value={charity_name}
                />

                <Input
                  label="Donation Date*"
                  name="date"
                  type="date"
                  max={maxDonationDate}
                  required
                  className="mb-5"
                />

                { availableDonationFunds > 0 &&
                  <p className="-mb-5 text-success-green md:text-right">You have ${availableDonationFunds} availabe in match funds</p>
                }

                <Input
                  label="Match Amount*"
                  name="matchAmount"
                  type="number"
                  min="10"
                  placeholder="Enter the match amount"
                  required
                  className="mb-5"
                  onWheel={ event => event.currentTarget.blur() }
                />

                <Input
                  label="Designation (optional - to show on the check)"
                  name="designation"
                  placeholder="Enter a designation"
                  className="mb-5"
                />

                <div className="mb-5">
                  <label className="block text-sm font-medium">
                    Donation Receipt*
                  </label>
                  <input
                    name="receipt"
                    type="file"
                    required
                    onChange={(event) => {
                      setFieldValue('receipt', event.currentTarget.files[0])
                    }}
                    className="mt-1 block w-full text-base font-normal rounded-lg border border-gray-300"
                  />
                  <label className="block text-sm font-small">Accepted file types: .jpeg, jpg, .pdf, .png</label>
                </div>

                <Button
                  type="submit"
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  disabled={isSubmitting}
                >
                  Submit Request
                </Button>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      {errorModalVisible &&
        <Modal
          body={errorMessages}
          setShowModal={() => setErrorModalVisible(false)}
          title={"Error"}
        />
      }
    </div>
  )
}

export default DonationMatchForm
