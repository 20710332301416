// Vendor
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import { Menu, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { Button } from 'components/ui/button'
import { LeaveGroupDialog } from './leave_group_dialog'
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  manageMembership: {
    defaultMessage: 'Manage Membership',
    id: 'membershipManagementMenu.manageMembership',
  },
  updateYourMembership: {
    defaultMessage: 'Update Your Membership',
    id: 'membershipMangementMenu.updateYourMembership',
  },
})

const MembershipManagementMenu = ({ className, group }) => {
  const [showLeaveGroupModal, setshowLeaveGroupModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelection = () => {
    setAnchorEl(null)
    setshowLeaveGroupModal(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack className={className} data-test="manage-membership">
      <LeaveGroupDialog
        group={group}
        setshowLeaveGroupModal={setshowLeaveGroupModal}
        showLeaveGroupModal={showLeaveGroupModal}
      />
      <div>
        <Button
          aria-controls="manage-membership"
          aria-haspopup="true"
          className="[ border-blue-2 ] [ fs-3 ] [ mnx-3 ]"
          data-test="manager-membership"
          onClick={handleClick}
          variant="neutral"
        >
          {formatMessage(messages.manageMembership)}
        </Button>
        <Menu
          anchorEl={anchorEl}
          classes={{ paper: 'ml-1 mt-6' }}
          id="manage-membership"
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuItem data-test="select-leave" onClick={handleSelection}>
            {formatMessage(messages.updateYourMembership)}
          </MenuItem>
        </Menu>
      </div>
    </Stack>
  )
}

MembershipManagementMenu.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isChapter: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    parentGroup: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

MembershipManagementMenu.defaultProps = {
  className: null,
}

export { MembershipManagementMenu }
