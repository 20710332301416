// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import EventCategoryForm from 'setup/event_categories/event_category_form'
import MgmtPanelFormPage from 'setup/application/form_page'

/**
 * The new page containing the form for creating an event category.
 */
const EventCategoriesNewPage = ({ brandId }) => (
  <MgmtPanelFormPage resourceName="Volunteer Event Category">
    {() => <EventCategoryForm brandId={brandId} />}
  </MgmtPanelFormPage>
)

EventCategoriesNewPage.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export default EventCategoriesNewPage
