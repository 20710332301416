import WeSpireAPI from 'utilities/wespire_api'

const defaultFunction = () => {}

const sendLike = ({
  likableId,
  likableType,
  onError = defaultFunction,
  onSuccess = defaultFunction,
}) => {
  if (!likableId || !likableType) return

  WeSpireAPI()
    .post('/likes/', {
      likable_id: likableId,
      likable_type: likableType,
    })
    .then(
      ({
        data: {
          like: { id },
        },
      }) => onSuccess({ likeId: id })
    )
    .catch(() => onError())
}

const sendUnlike = ({
  likeId,
  onError = defaultFunction,
  onSuccess = defaultFunction,
}) => {
  if (!likeId) return

  WeSpireAPI()
    .delete('/likes/' + likeId)
    .then(() => onSuccess())
    .catch(() => onError())
}

export { sendLike, sendUnlike }
