// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// Vendor
import { connect } from 'react-redux'
import Formsy from 'formsy-react'
import { setToken } from 'redux/reducers/session'

// WeSpire
import { displayBanner } from 'redux/dispatchers'
import InputPasswordToggleButton from 'components/form/input_password_toggle_button'
import { FormSubmitButton } from 'components/ui/Form/FormSubmitButton'
import TextField from 'components/ui/Form/FormTextField/FormTextField'
import Link from 'components/shared/link'
import { messages } from '../utilities/messages'
import { Paragraph } from 'components/ui/Typography/Paragraph'
import WeSpireAPI from 'utilities/wespire_api'
import { intl } from 'utilities/localization'

class LoginForm extends React.Component {
  static propTypes = {
    multiAuth: PropTypes.bool.isRequired,
    setToken: PropTypes.func,
    signUpPath: PropTypes.string.isRequired,
    ssoUrl: PropTypes.string,
  }

  static defaultProps = {
    setToken: () => {},
    ssoUrl: null,
  }

  state = {
    isPasswordVisible: false,
    isSubmitting: false,
  }

  handleClickShowPasssword = (event) => {
    event.preventDefault()
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible })
  }

  handleSubmit = (values) => {
    const { setToken } = this.props
    const { email, password } = values

    this.setState({ isSubmitting: true })

    WeSpireAPI()
      .post('/session', {
        session: {
          email,
          password,
        },
      })
      .then((response) => {
        setToken(response.data.token)
        this.setState({ isSubmitting: false })

        if (response.data.sso) {
          this.ssoformElement.submit()
        } else {
          window.location = response.data.redirect_path
        }
      })
      .catch((error) => {
        const errors = error.response.data.errors

        this.setState({ isSubmitting: false })

        displayBanner({
          content: errors.base,
          variant: 'error',
        })
      })
  }

  setErrorSummaryRef = (errorSummary) => (this.errorSummary = errorSummary)
  setFormRef = (formElement) => (this.formElement = formElement)
  setSSOFormRef = (ssoFormElement) => (this.ssoformElement = ssoFormElement)

  render() {
    const csrftParamEl = document.querySelector("meta[name='csrf-param']")
    const csrfTokenEl = document.querySelector("meta[name='csrf-token']")
    const tokenName = csrftParamEl && csrftParamEl.getAttribute('content')
    const tokenValue = csrfTokenEl && csrfTokenEl.getAttribute('content')

    const { isPasswordVisible, isSubmitting } = this.state
    const { multiAuth, ssoUrl, signUpPath } = this.props

    const info = localStorage.getItem('info')
    const enteredEmail = localStorage.getItem('email')
    const varient = localStorage.getItem('varient') || 'info'

    if (info) {
      displayBanner({
        content: info,
        variant: varient,
      })
    }

    window.onbeforeunload = () => {
      localStorage.removeItem('info')
      localStorage.removeItem('email')
      localStorage.removeItem('varient')
    }

    return (
      <Formsy
        noValidate
        onValidSubmit={this.handleSubmit}
        ref={this.setFormRef}
      >
        <TextField
          className="mt-4"
          name="email"
          required
          textFieldProps={{
            label: intl.formatMessage(messages.emailLabel),
          }}
          validationErrors={{
            isEmail: intl.formatMessage(messages.validEmail),
          }}
          validations={{ isEmail: true }}
          value={enteredEmail}
        />
        <TextField
          className="mt-4"
          name="password"
          required
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <InputPasswordToggleButton
                  isPasswordVisible={isPasswordVisible}
                  onClick={this.handleClickShowPasssword}
                />
              ),
            },
            label: intl.formatMessage(messages.passwordLabel),
            type: isPasswordVisible ? 'text' : 'password',
          }}
        />

        <FormSubmitButton
          className="fs-3 p-3 w-100 mt-4"
          color="secondary"
          dataTest="login-button"
          isSubmitting={isSubmitting}
          variant="contained"
        >
          {intl.formatMessage(messages.signInButton)}
        </FormSubmitButton>

        <div className="m-3">
          <Paragraph className="text-left">
            <Link data-test="forget-password-link" to="/passwords/new">
              {intl.formatMessage(messages.forgotPassword)}
            </Link>
          </Paragraph>

          {multiAuth ? (
            <form action={ssoUrl} method="POST" ref={this.setSSOFormRef}>
              <input name={tokenName} type="hidden" value={tokenValue} />
              <Paragraph className="text-left">
                <Link
                  data-test="sso-sign-in-link"
                  onClick={() => this.ssoformElement.submit()}
                >
                  {intl.formatMessage(messages.signInWithSSO)}
                </Link>
              </Paragraph>
            </form>
          ) : (
            <Paragraph className="text-left">
              <Link data-test="create-account-link" to={signUpPath}>
                {intl.formatMessage(messages.needAccount)}
              </Link>
            </Paragraph>
          )}
        </div>
      </Formsy>
    )
  }
}

export { LoginForm }
export default connect(null, { setToken })(withRouter(LoginForm))
