import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { CalculatorContext } from 'components/CarbonCalculator/contexts/CalculatorContext'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import {
  VEHICLE_FUEL_EFFICIENCY,
  VEHICLE_TYPE,
} from 'components/CarbonCalculator/contexts/constants'

const ToggleButtonGroupComponent = ({ answers, questionKey }) => {
  const { dispatchAnswer } = useContext(CalculatorContext)
  const [selectedPill, setSelectedPill] = useState(
    questionKey === VEHICLE_TYPE ? 1 : ''
  )

  const handleSelectionChange = (event, newSelection) => {
    setSelectedPill(newSelection)
    // if electric pass 150 for mpg and set to (1) in main calc component
    if (newSelection === 3) {
      dispatchAnswer({
        type: VEHICLE_FUEL_EFFICIENCY,
        payload: { questionKey: VEHICLE_FUEL_EFFICIENCY, answer: 150 },
      })
    }
    dispatchAnswer({
      type: questionKey,
      payload: { questionKey, answer: newSelection },
    })
  }

  const pillStyle = {
    borderColor: '#CCCFD6!important',
    borderRadius: '9999px!important',
    marginRight: '0.75rem!important',
    marginBottom: '0.4rem!important',
    padding: '10px 20px',
    fontSize: '0.7rem',
  }

  const pillSelectedStyle = {
    ...pillStyle,
    backgroundColor: '#1772DC!important',
    color: '#FFFFFF!important',
  }

  return (
    <ToggleButtonGroup
      key={questionKey}
      aria-label="text selectedPill"
      className="flex flex-wrap"
      data-test="toggle-button-group-test"
      exclusive
      onChange={handleSelectionChange}
      value={selectedPill}
    >
      {answers.map((answer) => (
        <ToggleButton
          aria-label={answer.label}
          data-test="toggle-button-test"
          key={answer.value}
          sx={selectedPill === answer.value ? pillSelectedStyle : pillStyle}
          value={answer.value}
        >
          {answer.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

ToggleButtonGroupComponent.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  questionKey: PropTypes.string.isRequired,
}

export default ToggleButtonGroupComponent
