import gql from 'graphql-tag'

export const BROADCASTSQUERY = gql`
  query BroadcastsQuery {
    broadcasts {
      headline
      id
      image
      publishedAt
    }
  }
`
