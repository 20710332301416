// Vendor
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// WeSpire
import { Heading } from 'components/ui/heading'
import { GET_GROUP_NEWSFEED_ITEMS } from './utilities/queries'
import { GroupNewsfeed } from './components/group_newsfeed_posts'
import { GroupNewsfeedForm } from './components/group_newsfeed_form'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { GroupAnnouncements } from './components/group_announcements'

const GroupNewsfeedAndAnnouncementsLayout = ({
  currentUserIsMember,
  enableNewsfeedColumnInGroupView,
  groupAnnouncementsEnabled,
  groupId,
  isMemberOfParentGroup,
}) => {
  let announcementLimitedWidth =
    isMemberOfParentGroup &&
    enableNewsfeedColumnInGroupView &&
    currentUserIsMember

  const newsfeedSectionStyle = cx({
    'col-md-8': groupAnnouncementsEnabled,
    'col-xs-12': true,
  })

  const announcementsSectionStyle = cx({
    'col-md-4': announcementLimitedWidth,
    'col-xs-12': true,
  })

  return (
    <Stack space={3}>
      <div className="row">
        {groupAnnouncementsEnabled && (
          <div className={announcementsSectionStyle}>
            <Heading className="fw-semi-bold px-3 py-2" level={2}>
              {sharedTranslations.announcements}
            </Heading>
            <GroupAnnouncements groupId={groupId} />
          </div>
        )}
        {enableNewsfeedColumnInGroupView && currentUserIsMember && (
          <div className={newsfeedSectionStyle}>
            <Heading className="fw-semi-bold px-3 py-2" level={2}>
              {sharedTranslations.newsfeed}
            </Heading>

            <Stack space={3}>
              <GroupNewsfeedForm
                feedQuery={GET_GROUP_NEWSFEED_ITEMS}
                groupId={groupId}
              />
              <GroupNewsfeed
                feedQuery={GET_GROUP_NEWSFEED_ITEMS}
                groupId={groupId}
              />
            </Stack>
          </div>
        )}
      </div>
    </Stack>
  )
}

GroupNewsfeedAndAnnouncementsLayout.propTypes = {
  currentUserIsMember: PropTypes.bool,
  enableNewsfeedColumnInGroupView: PropTypes.bool,
  groupAnnouncementsEnabled: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  isMemberOfParentGroup: PropTypes.bool.isRequired,
}

GroupNewsfeedAndAnnouncementsLayout.defaultProps = {
  currentUserIsMember: false,
  enableNewsfeedColumnInGroupView: false,
}

export { GroupNewsfeedAndAnnouncementsLayout }
