// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const HeroDescription = ({ text, ...other }) => (
  <p
    {...other}
    className={cx('fs-2 fs-sm-3 fs-lg-5 my-0 text-white', other.className)}
  >
    {text}
  </p>
)

HeroDescription.propTypes = {
  text: PropTypes.string.isRequired,
}

export default HeroDescription
