// Vendor
import React from 'react'
import { Card, CardActions, CardContent } from '@material-ui/core'
import cx from 'classnames'
import { defineMessages, FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { getGroupUrl } from 'components/group/utils'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import LinkButton from 'components/ui/link_button'
import ParticipantsSummary from 'components/application/participants_summary'
import ScreenReader from 'components/ui/screen_reader_text'
import { sharedTranslations } from 'components/shared/translations'

const GroupCard = ({ chapter, className, group }) => {
  const { formatMessage } = intl
  const description =
    chapter && chapter.descriptionPlainText
      ? chapter.descriptionPlainText
      : group.descriptionPlainText
  const chaptersCount = group.groupChapters?.length ?? 0
  // todo: bring back members
  // const members = chapter ? chapter.members.nodes : group.members.nodes
  // const membersCount = chapter ? chapter.membersCount : group.membersCount

  const link = getGroupUrl({
    chapterIdentifier: chapter?.slug,
    groupIdentifier: group.slug,
  })

  const messages = defineMessages({
    chaptersCount: {
      defaultMessage:
        '{stringifiedCount} {count, plural, zero {Chapters} one {Chapter} two {Chapters} few {Chapters} many {Chapters} other {Chapters}}',
      id: 'groupCard.chaptersCount',
    },
  })

  return (
    <li className={cx('[ col-md-4 d-flex ]', className)} data-test="group-card">
      <Card className="[ card ] [ d-flex flex-column ] [ h-100 p-0 w-100 ]">
        <Link className="d-block position-relative" tabIndex={-1} to={link}>
          <div className="aspect-ratio aspect-ratio--16-9 | bg-black-2">
            <Image
              alt=""
              aspectRatio="16:9"
              className="absolute-top-left"
              responsive
              src={group.heroImage}
            />
          </div>
        </Link>
        <CardContent className="card__content | p-md-3">
          <Link
            className="card__title fs-4 group__card lh-md text-inherit-color"
            data-test="group-name"
            tabIndex={-1}
            to={link}
          >
            {group.name}
          </Link>
          {(chapter || chaptersCount > 0) && (
            <div
              className="[ card__label group__card | fs-1 m-0 mt-md-2 ] [ text-black-2  ]"
              data-test="chapter-details"
            >
              {chapter
                ? chapter.name
                : formatMessage(messages.chaptersCount, {
                    count: chaptersCount,
                    stringifiedCount: <FormattedNumber value={chaptersCount} />,
                  })}
            </div>
          )}
          <p
            className={cx(
              '[ card__description ] [ text-black-1 ] [ fs-2 lh-md ]',
              {
                'm-0 | mt-md-2': chapter,
                'py-2': !chapter,
              }
            )}
            data-test="group-description"
          >
            {description}
          </p>
        </CardContent>
        <CardActions className="[ align-items-center ] [ mt-3 | pt-3 px-md-3 ]">
          {
            // todo: bring back members
            // <ParticipantsSummary
            //   avatarImages={members.map((user) => user.avatarUrl)}
            //   avatarSize="sm"
            //   className="show-md-up"
            //   participantsCount={membersCount}
            //   type="member"
            // />
          }
          <LinkButton
            className="ml-auto"
            data-test="view-link"
            to={link}
            variant="text"
          >
            {sharedTranslations.view}
            <ScreenReader
              text={
                chapter
                  ? `Group: ${group.name} - ${chapter.name}`
                  : `Group: ${group.name}`
              }
            />
          </LinkButton>
        </CardActions>
      </Card>
    </li>
  )
}

GroupCard.propTypes = {
  chapter: PropTypes.shape({
    descriptionPlainText: PropTypes.string,
    id: PropTypes.string.isRequired,
    // todo: bring back members
    // members: PropTypes.shape({
    //   nodes: PropTypes.array.isRequired,
    // }).isRequired,
    // membersCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  group: PropTypes.shape({
    descriptionPlainText: PropTypes.string.isRequired,
    groupChapters: PropTypes.array,
    heroImage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    // todo: bring back members
    // members: PropTypes.shape({
    //   nodes: PropTypes.array.isRequired,
    // }).isRequired,
    // membersCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

GroupCard.defaultProps = {
  chapter: null,
  className: null,
}

export { GroupCard }
