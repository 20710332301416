// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/ui/button'
import cx from 'classnames'

// WeSpire
import LinkButton from 'components/ui/link_button'
import FormActionBarSubmitButton from './submit_button'
import { sharedTranslations } from 'components/shared/translations'

/**
 * Default action bar for use at the bottom of all forms. Contains a top
 * border, Back button, and Submit button by default. Submit button can be
 * overridden by any children passed in.
 */
const FormActionBar = ({
  backPath,
  backText,
  children,
  className,
  disabled,
  hideBack,
  space,
  submitText,
  ...other
}) => {
  const buttonProps = {
    className: 'mr-auto',
    'data-test': 'go-back',
    variant: 'text',
  }
  const goBack = () => history.back()
  const renderBackButton = () =>
    backPath ? (
      <LinkButton {...buttonProps} to={backPath}>
        {backText}
      </LinkButton>
    ) : (
      <Button {...buttonProps} onClick={goBack}>
        {backText}
      </Button>
    )

  return (
    <div className={cx(`d-flex | border-top pt-${space} w-100`, className)}>
      {!hideBack && renderBackButton()}

      {children ? (
        children
      ) : (
        <FormActionBarSubmitButton
          className="ml-auto"
          data-test="submit"
          disabled={disabled}
          isSubmitting={other.isSubmitting}
        >
          {submitText}
        </FormActionBarSubmitButton>
      )}
    </div>
  )
}

FormActionBar.propTypes = {
  /** link to previous/parent page, usually an index */
  backPath: PropTypes.string,
  /** text for the back button */
  backText: PropTypes.string,
  /** pass in custom or additional form action buttons */
  children: PropTypes.node,
  /** classes for the main container */
  className: PropTypes.string,
  /** disable submit button */
  disabled: PropTypes.bool,
  /** hide back button */
  hideBack: PropTypes.bool,
  /** specify vertical space before top border */
  space: PropTypes.number,
  /** text for the submit button */
  submitText: PropTypes.string,
}

FormActionBar.defaultProps = {
  backPath: null,
  backText: sharedTranslations.back,
  children: null,
  className: null,
  disabled: false,
  hideBack: false,
  space: 5,
  submitText: undefined,
}

export default FormActionBar
