// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import v from 'voca'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'

// WeSpire
import { Button } from 'components/ui/button'
import { Heading } from 'components/ui/heading'
import Idea from './idea'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import ScreenReader from 'components/ui/screen_reader_text'
import { sortByMostVotes } from './utils'
import { sortByNewest, sortByOldest } from 'utilities/sort'
import { RadioPill } from 'components/form/radio_pill'
import { ScoreBadge } from 'components/shared/score_badge'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'

const allFilterName = sharedTranslations.all

const messages = defineMessages({
  announceFilter: {
    defaultMessage: 'Ideas now filtered by {filter}',
    description:
      'Message for screen readers to announce what the filter has been set to',
    id: 'ideaList.announceFilter',
  },
  announceSort: {
    defaultMessage: 'Ideas now sorted by {order}',
    description:
      'Message for screen readers to announce what the sort order has been set to',
    id: 'ideaList.announceSort',
  },
  filterLabel: {
    defaultMessage: 'filter Ideas by',
    description:
      'Label above radio buttons allowing user to choose how to filter Ideas',
    id: 'ideaList.filterLabel',
  },
  filterLabelHint: {
    defaultMessage:
      'Choose an option to update how the list of submitted Ideas are filtered',
    description: 'A hint for screen readers about the filter by radio options',
    id: 'ideaList.filterLabelHint',
  },
  mostVotes: {
    defaultMessage: 'Most Votes',
    description: 'Radio option to sort Ideas, most votes first',
    id: 'ideaList.sortByMostVotes',
  },
  newest: {
    defaultMessage: 'Newest',
    description: 'Radio option to sort Ideas, newest first',
    id: 'ideaList.sortByNewest',
  },
  oldest: {
    defaultMessage: 'Oldest',
    description: 'Radio option to sort Ideas, oldest first',
    id: 'ideaList.sortByOldest',
  },
  sortLabel: {
    defaultMessage: 'sort Ideas by',
    description:
      'Label above radio buttons allowing user to choose how to sort Ideas',
    id: 'ideaList.sortLabel',
  },
  sortLabelHint: {
    defaultMessage:
      'Choose an option to update how the list of submitted Ideas are sorted',
    description: 'A hint for screen readers about the sort by radio options',
    id: 'ideaList.sortLabelHint',
  },
  submissionsClosed: {
    defaultMessage: 'Submissions Closed',
    description:
      'Button to allow user to submit an Idea, but submissions are closed',
    id: 'ideaList.submissionsClosed',
  },
  submitIdea: {
    defaultMessage: 'Contribute to {name}',
    description: 'Button to allow user to submit an Idea',
    id: 'ideaList.submitIdea',
  },
  submitIdeaButton: {
    defaultMessage: 'Submit Idea',
    id: 'ideaList.submitIdeaButton',
  },
  submitIdeaHeader: {
    defaultMessage: 'Submit an Idea',
    description: 'Header for the submit idea button',
    id: 'ideaList.submitIdeaHeader',
  },
})

const IdeaList = ({
  categories,
  currentUser,
  displayNotification,
  ended,
  formRequirements,
  ideaBoardID,
  ideaBoardName,
  ideas: ideasFromProps,
  isPreview,
  pointsPerIdeaBoardSubmission,
}) => {
  const { formatMessage } = intl
  const [filterIdeasBy, setFilterIdeasBy] = useState(allFilterName)
  const [ideas, setIdeas] = useState(ideasFromProps)
  const [sortIdeasBy, setSortIdeasBy] = useState('newest')

  const liveIdeaFormUrl = `/idea_boards/${ideaBoardID}/ideas/new`
  const previewIdeaFormUrl = `/management_panel${liveIdeaFormUrl}/preview`
  const ideaFormUrl = isPreview ? previewIdeaFormUrl : liveIdeaFormUrl
  const categoryOptions = [{ id: null, name: allFilterName }, ...categories]
  const selectedFilter = categoryOptions.find(
    (category) => category.name === filterIdeasBy
  )

  const handleChangeIdeaSort = (event) => {
    setSortIdeasBy(event.target.value)
  }

  const handleChangeFilter = (event) => {
    setFilterIdeasBy(event.target.value)
  }

  const handleIdeaVote = (ideaId, voteCountChange) => {
    // Update attributes of voted ideas
    setIdeas((prevIdeas) => {
      const votedIdeas = prevIdeas.map((idea) => {
        const ideaVoted = idea.id === ideaId
        const upVoted = voteCountChange === 1

        if (ideaVoted) {
          const nextIdea = {
            ...idea,
            voteCount: idea.voteCount + voteCountChange,
          }

          if (upVoted) {
            return {
              ...nextIdea,
              currentVoteCreatedAt: Date.now(),
              previousVoteCreatedAt: idea.currentVoteCreatedAt,
            }
          } else {
            return {
              ...nextIdea,
              currentVoteCreatedAt: idea.previousVoteCreatedAt,
            }
          }
        } else {
          return idea
        }
      })

      return votedIdeas
    })
  }

  const sortedAndFilteredIdeas = () => {
    let filteredIdeas = [...ideas]
    if (filterIdeasBy !== allFilterName) {
      filteredIdeas = filteredIdeas.filter(
        (idea) => idea.category && idea.category.name === filterIdeasBy
      )
    }

    switch (sortIdeasBy) {
      case IdeaList.SORT_BY.MOST_VOTES:
        return sortByMostVotes(filteredIdeas)
      case IdeaList.SORT_BY.NEWEST:
        return sortByNewest(filteredIdeas, 'createdAt')
      case IdeaList.SORT_BY.OLDEST:
        return sortByOldest(filteredIdeas, 'createdAt')
      default:
        return filteredIdeas
    }
  }

  const sortByToLabel = (sortBy) => {
    const { formatMessage } = intl

    switch (sortBy) {
      case IdeaList.SORT_BY.MOST_VOTES:
        return formatMessage(messages.mostVotes)
      case IdeaList.SORT_BY.NEWEST:
        return formatMessage(messages.newest)
      case IdeaList.SORT_BY.OLDEST:
        return formatMessage(messages.oldest)
    }
  }

  const visibleIdeas = sortedAndFilteredIdeas()

  return (
    <>
      <div className="row-md flex-nowrap">
        <Stack
          className="[ col-md-3 order-md-2 ] [ mt-3 px-4 | mt-md-0 px-0 ]"
          space={4}
        >
          <FormControl aria-describedby="sort-ideas-hint" component="fieldset">
            <FormLabel
              className="fs-1 fw-semi-bold ls-2 mb-0 mt-3 text-black-1 text-uppercase"
              component="legend"
            >
              {formatMessage(messages.sortLabel)}
            </FormLabel>
            <ScreenReader
              id="sort-ideas-hint"
              text={formatMessage(messages.sortLabelHint)}
            />
            <RadioGroup
              className="[ flex-row | flex-md-column ]"
              name="sort-ideas"
              onChange={handleChangeIdeaSort}
              value={sortIdeasBy}
            >
              {Object.values(IdeaList.SORT_BY).map((sort) => (
                <RadioPill
                  data-test={`sort-by-${v.kebabCase(sort)}`}
                  key={sort}
                  label={sortByToLabel(sort)}
                  selected={sortIdeasBy === sort}
                  value={sort}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {categories.length > 0 && (
            <FormControl
              aria-describedby="filter-ideas-hint"
              className="word-break-word"
              component="fieldset"
              data-test="filter-ideas-label"
            >
              <FormLabel
                className="fs-1 fw-semi-bold ls-2 mb-0 mt-3 text-black-1 text-uppercase"
                component="legend"
              >
                {formatMessage(messages.filterLabel)}
              </FormLabel>
              <ScreenReader
                id="filter-ideas-hint"
                text={formatMessage(messages.filterLabelHint)}
              />
              <RadioGroup
                className="[ flex-row | flex-md-column ]"
                name="filter-ideas"
                onChange={handleChangeFilter}
                value={selectedFilter.name}
              >
                {categoryOptions.map(({ id, name }) => (
                  <RadioPill
                    data-test={`filter-by-${v.kebabCase(name)}`}
                    key={id}
                    label={name}
                    selected={selectedFilter.id === id}
                    value={name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Stack>

        <div className="[ col-md-9 order-md-1 ] [ mt-3 | mt-md-0 ]">
          <Heading className="[ col ] [ mt-2 p-3 ]" level={2}>
            {formatMessage(messages.submitIdeaHeader)}
          </Heading>
          <Paper className="[ mb-3 ]">
            <LinkButton
              className=" [ bg-transparent p-4 ] [ d-flex align-items-center justify-content-between ] [ fs-3 lh-md fw-semi-bold text-blue ]"
              disabled={ended}
              to={ideaFormUrl}
            >
              {ended && (
                <h3 data-test="idea-board-closed">
                  {formatMessage(messages.submissionsClosed)}
                </h3>
              )}

              {!ended && (
                <>
                  <h3 data-test="open-idea-form">
                    {formatMessage(messages.submitIdea, {
                      name: ideaBoardName,
                    })}
                  </h3>

                  <Button
                    className="ml-auto text-blue border-blue fs-3 mr-2 py-1"
                    disableRipple
                    variant="neutral"
                  >
                    {formatMessage(messages.submitIdeaButton)}
                  </Button>
                </>
              )}

              {pointsPerIdeaBoardSubmission && (
                <ScoreBadge
                  isCompleted={false}
                  points={pointsPerIdeaBoardSubmission}
                />
              )}
            </LinkButton>
          </Paper>

          <Heading className="[ col ] [ mt-2 p-3 ]" level={2}>
            {sharedTranslations.ideasSubmitted}
          </Heading>
          <Stack space={2}>
            {visibleIdeas.map((idea) => (
              <Idea
                amOwner={currentUser.id === idea.user.id}
                displayNotification={displayNotification}
                formRequirements={formRequirements}
                idea={idea}
                ideaBoardID={ideaBoardID}
                isPreview={isPreview}
                key={idea.id}
                onVote={handleIdeaVote}
                points={pointsPerIdeaBoardSubmission}
                voteDisabled={ended}
              />
            ))}
          </Stack>
        </div>
      </div>

      <ScreenReader
        aria-live="polite"
        text={formatMessage(messages.announceSort, {
          order: sortByToLabel(sortIdeasBy),
        })}
      />
      <ScreenReader
        aria-live="polite"
        text={formatMessage(messages.announceFilter, {
          filter: selectedFilter.name,
        })}
      />
    </>
  )
}

IdeaList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  displayNotification: PropTypes.func.isRequired,
  ended: PropTypes.bool.isRequired,
  formRequirements: PropTypes.object.isRequired,
  ideaBoardID: PropTypes.string.isRequired,
  ideaBoardName: PropTypes.string.isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      createdAt: PropTypes.string.isRequired,
      currentVoteCreatedAt: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      previousVoteCreatedAt: PropTypes.number.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      voteCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  isPreview: PropTypes.bool,
  pointsPerIdeaBoardSubmission: PropTypes.number,
}

IdeaList.defaultProps = {
  isPreview: false,
  pointsPerIdeaBoardSubmission: null,
}

// eslint-disable-next-line react/sort-comp
IdeaList.SORT_BY = {
  MOST_VOTES: 'most votes',
  NEWEST: 'newest',
  OLDEST: 'oldest',
}

export default IdeaList
