import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'

// westyle
import DonationsTable from 'src/pages/DonationHistoryPage/components/DonationsTable'
import useGivingaUserQuery from 'src/pages/DonationHistoryPage/queries/useGivingaUserQuery'
import YourDonationCredit from 'src/pages/DonationHistoryPage/components/YourDonationCredit'
import YourImpact from 'src/pages/DonationHistoryPage/components/YourImpact'
import InfoContainer from 'src/common/InfoContainer'
import InternalLink from 'src/common/InternalLink'
import EmptyStateBox from 'src/common/EmptyStateBox'
import PageLoading from 'src/common/PageLoading'

const DonationHistoryPage = () => {
  const { enableDonationHistory } = useFlags()

  const { data, loading, error = false } = useGivingaUserQuery()

    const {
      brandConfig: { enablePaidVolunteerHours } = {},
      currentUser: { givingaUser } = {},
      recurringDonationsEnabled,
    } = data ?? {}

  if (!data || loading) return <PageLoading />
  if (error || !givingaUser || !enableDonationHistory)
    return (
      <p>
        <FormattedMessage
          id="shared.youDoNotHaveAccessToThisPage"
          defaultMessage="You do not have access to this page."
        />
      </p>
    )

    return (
    <main className="grid gap-y-10 my-10 mx-2 lg:mx-4 xl:mx-0">
      <h1 className="px-2">Donation history</h1>
      <div className="grid xl:grid-cols-3 xl:gap-x-6 gap-y-10">
        {givingaUser.allDonationsToDate && (
          <YourImpact
            allDonationsToDate={givingaUser.allDonationsToDate}
            companyDonationsToDate={givingaUser.companyDonationsToDate}
            donatedOrganizationsCountThisYear={
              givingaUser.donatedOrganizationsCountThisYear
            }
            totalDonationTransactions={givingaUser.totalDonationTransactions}
            totalDonationTransactionsThisYear={
              givingaUser.totalDonationTransactionsThisYear
            }
          />
        )}
        <YourDonationCredit
          availableDonationFunds={givingaUser.availableDonationFunds}
          enablePaidVolunteerHours={enablePaidVolunteerHours}
        />
      </div>
      <InfoContainer title="Quisque semper accumsan dapibus? Request a match.">
        <span className="leading-6">
          You can request a match here. Nunc nisl diam, suscipit sollicitudin
          euismod tincidunt, pellentesque aliquet ante.
        </span>
        <div className="pt-2">
          <InternalLink className="link-text-underlined">
            <FormattedMessage
              id="donationHistory.submitAMatchRequest"
              defaultMessage="Submit a match request"
            />
          </InternalLink>
        </div>
      </InfoContainer>
      {givingaUser && (
        <>
          {recurringDonationsEnabled &&
            givingaUser.scheduledTransactions > 0 && (
              <div>
                <h2 className="px-2 mb-4">
                  <FormattedMessage
                    id="donationHistory.scheduledDonations"
                    defaultMessage="Scheduled donations"
                  />
                </h2>
                <DonationsTable
                  status="scheduled"
                  transactions={
                    givingaUser.scheduledTransactions
                  }
                />
              </div>
            )}
          {givingaUser.pendingTransactions && (
            <div>
              <h2 className="px-2 mb-4">
                <FormattedMessage
                  id="donationHistory.pendingDonation"
                  defaultMessage="Pending donations"
                />
              </h2>
              <DonationsTable
                status="processing"
                transactions={givingaUser.pendingTransactions}
              />
            </div>
          )}
          {recurringDonationsEnabled && (
            <div>
              <h2 className="px-2 mb-4">
                <FormattedMessage
                  id="donationHistory.pastDonations"
                  defaultMessage="Past donations"
                />
              </h2>
              {givingaUser.completedTransactions.length > 0 ? (
                <DonationsTable
                  status="complete"
                  transactions={givingaUser.transactions}
                />
              ) : (
                <EmptyStateBox title="No past donations to display">
                  <p className="text-practical-gray-2 mt-2 mb-6">
                    <FormattedMessage
                      id="volunteering.youCanFindGivingAndVolunteeringOpportunities"
                      defaultMessage="You can find giving and volunteering opportunities by visiting our Take Action page."
                    />
                  </p>
                  <InternalLink
                    className="link-text-underlined"
                    href="/take_action"
                  >
                    <FormattedMessage
                      id="donationHistory.exploreGivingOpportunities"
                      defaultMessage="Explore giving opportunities"
                    />
                  </InternalLink>
                </EmptyStateBox>
              )}
            </div>
          )}
        </>
      )}
    </main>
  )
}

export default DonationHistoryPage
