import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// vendor
import v from 'voca'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import omit from 'lodash/omit'

// WeSpire
import AddressAutocomplete, {
  getLocationProvider,
} from 'components/form/address_autocomplete'
import { CharityOrAddedOrganization } from 'components/form/charity_or_added_organization'
import CloudinaryQuery from 'components/queries/cloudinary_query'
import {
  displayBanner,
  displayExceptionBanner,
  maybeDisplayUserTour,
} from 'redux/dispatchers'
import EditableImageAttachment from 'components/application/editable_image_attachment'
import FormActionBar from 'components/form/action_bar'
import FormErrorSummary from 'components/form/error_summary'
import FormFieldset from 'components/form/fieldset'
import { FormPage } from 'components/form/form_page'
import FormSelect from 'components/form/select'
import FormTextField from 'components/form/text_field'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import LinkButton from 'components/ui/link_button'
import { FormDateField } from 'components/form/date_field'
import { selectionValues } from 'components/form/query_autocomplete'
import { sharedTranslations } from 'components/shared/translations'
import UserAutocomplete from 'components/form/user_autocomplete'
import { DocumentTitle } from 'components/shared/document_title'
import { IneligibleForPvh, PvhFields } from 'components/pvh'
import { messages } from './utilities/messages'
import { GET_VOLUNTEER_HISTORY } from 'components/volunteer_history/utilities/queries'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  GET_CATEGORIES_AND_PVH_BALANCE,
  GET_VOLUNTEER_LOG,
} from './utilities/queries'
import {
  CREATE_VOLUNTEER_LOG_MUTATION,
  DELETE_VOLUNTEER_LOG_MUTATION,
  UPDATE_VOLUNTEER_LOG_MUTATION,
} from './utilities/mutations'

export const VolunteerHoursForm = () => {
  const { formatMessage } = intl
  const { push } = useHistory()
  const res = window.location.pathname.match(
    /^\/users\/me\/volunteer_hours_form\/(\d+)/
  )
  const [ran, setRan] = useState(false)
  const [hours, setHours] = useState(0)
  const [isClaiming, setIsClaiming] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [savedImage, setSavedImage] = useState(null)
  const [usingAddedOrganization, setUsingAddedOrganization] = useState(false)
  const [pvhHours, setPvhHours] = useState(null)
  const { enableGooglePlacesApiVolunteerHistory } = useFlags()

  function categoriesToOptions(categories) {
    return categories.map((category) => {
      const value = category.category
      const label = category.name
      return { label, value }
    })
  }
  const { data } = useQuery(GET_VOLUNTEER_LOG, {
    fetchPolicy: 'network-only',
    skip: !res,
    variables: {
      id: res && res[1],
    },
  })

  const { data: pvhBalanceData, loading } = useQuery(
    GET_CATEGORIES_AND_PVH_BALANCE
  )

  const [createVolunteerLog] = useMutation(CREATE_VOLUNTEER_LOG_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CATEGORIES_AND_PVH_BALANCE },
      { query: GET_VOLUNTEER_HISTORY },
    ],
  })

  const [updateVolunteerLog] = useMutation(UPDATE_VOLUNTEER_LOG_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CATEGORIES_AND_PVH_BALANCE },
      { query: GET_VOLUNTEER_HISTORY },
    ],
  })

  const [deleteVolunteerLog] = useMutation(DELETE_VOLUNTEER_LOG_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CATEGORIES_AND_PVH_BALANCE },
      { query: GET_VOLUNTEER_HISTORY },
    ],
  })

  const setupForm = (form) => {
    if (data && data.volunteerLog && form && res && ran === false) {
      const {
        volunteerLog: {
          address,
          addressLabel,
          category,
          date,
          description,
          hours,
          organizationName,
          organizationId,
          image,
          taggedUsers,
        },
      } = data
      // removing this key value pair from address for use in mutation later
      // errors out if __typename VolunteerLogAddressObject
      const removedTypenameAddress = omit(address, '__typename')
      const existingData = {
        category,
        charityAddress: {
          address: removedTypenameAddress,
          addressLabel,
        },
        charityOrAddedOrganization: 'added',
        date: moment(date).format('MM/DD/YYYY'),
        description,
        hours,
        organizationSelections: [
          {
            label: organizationName,
            value: organizationId,
          },
        ],
        taggedUserSelections: taggedUsers.map((user) => ({
          label: user.name,
          value: parseInt(user.id),
        })),
      }

      setSavedImage(image)
      form.reset(existingData)
      setRan(true)
    }
  }

  const handleFormChange = (model) => {
    setHours(model.hours)
    setIsClaiming(model.pvhClaim)
    setPvhHours(model.pvhHours)
    setUsingAddedOrganization(model.charityOrAddedOrganization === 'added')
  }

  function displayBannerAndSubmit(errors, message = '') {
    if (errors) {
      displayBanner({
        as: 'div',
        content: <FormErrorSummary errors={errors} />,
        variant: 'error',
      })
      setIsSubmitting(false)
    } else {
      displayBanner({
        content: message,
        variant: 'success',
      })
      return push('/users/me/volunteer_history')
    }
  }

  const handleSubmit = async (model) => {
    setIsSubmitting(true)

    let ein, organizationId
    const useAdded = model.charityOrAddedOrganization === 'added'
    if (useAdded && model.organizationSelections) {
      organizationId = selectionValues(model.organizationSelections)[0]
    } else if (!useAdded && model.charitySelections) {
      ein = selectionValues(model.charitySelections)[0]
    }
    const mutationVariables = {
      address: model.charityAddress.address,
      addressLabel: model.charityAddress.addressLabel,
      category: model.category,
      date: model.date,
      description: model.description,
      ein: ein,
      hours: parseFloat(model.hours),
      image: model.image,
      logId: +res?.[1] ?? null,
      organizationId: organizationId,
      pvhHours: model.pvhClaim ? parseInt(model.pvhHours) : null,
      taggedUserIds: selectionValues(model.taggedUserSelections),
    }

    if (res && res[1]) {
      try {
        const { data } = await updateVolunteerLog({
          variables: mutationVariables,
        })
        displayBannerAndSubmit(
          data.errors,
          formatMessage(messages.hoursLoggedSuccessfully)
        )
      } catch {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: sharedTranslations.logYourVolunteerHours,
        })
      }
    } else if (!res) {
      try {
        const {
          data: {
            createVolunteerLog: { errors },
          },
        } = await createVolunteerLog({
          variables: mutationVariables,
        })
        displayBannerAndSubmit(
          errors,
          formatMessage(messages.hoursLoggedSuccessfully)
        )
      } catch {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: sharedTranslations.logYourVolunteerHours,
        })
      }
    }
  }

  const handleDeleteLog = async () => {
    try {
      if (confirm(formatMessage(messages.confirmDelete))) {
        const { errors } = await deleteVolunteerLog({
          variables: { id: res[1] },
        })
        displayBannerAndSubmit(
          errors,
          formatMessage(messages.volunteeringLogDeleted)
        )
        return push('/users/me/volunteer_history')
      }
    } catch {
      displayExceptionBanner({
        operation: sharedTranslations.logYourVolunteerHours,
      })
    }
  }

  return (
    !loading && (
      <GraphQLFeatureFlag featureName="volunteerHistory">
        <DocumentTitle title={sharedTranslations.logYourVolunteerHours}>
          {maybeDisplayUserTour('volunteerHoursFormPage')}
          <div className="mt-5 mx-3 mxw-6 | mt-sm-6 mx-sm-auto">
            <FormPage
              description={formatMessage(
                messages.logVolunteeringFromYourOwnTime,
                {
                  volunteerHistoryLink: (
                    <Link
                      key="volunteerHistoryLink"
                      to="/users/me/volunteer_history"
                    >
                      {sharedTranslations.volunteerHistory}
                    </Link>
                  ),
                }
              )}
              heading={sharedTranslations.logYourVolunteerHours}
              onChange={(model) => handleFormChange(model)}
              onValidSubmit={(model) => handleSubmit(model)}
              ref={setupForm}
            >
              <FormFieldset label={sharedTranslations.yourVolunteerHours}>
                <FormTextField
                  name="hours"
                  required
                  textFieldProps={{
                    helperText: formatMessage(messages.howManyHoursVolunteered),
                    label: formatMessage(messages.numberOfHours),
                    type: 'number',
                  }}
                  validationErrors={{
                    fixed: formatMessage(messages.fixedIncrement),
                    maxValue: formatMessage(messages.maxValue),
                    minValue: formatMessage(messages.minValue),
                  }}
                  validations="fixed:2,minValue:0.25,maxValue:24"
                />
              </FormFieldset>

              {pvhBalanceData?.brandConfig?.enablePaidVolunteerHours && (
                <FormFieldset label={sharedTranslations.paidVolunteerHours}>
                  {usingAddedOrganization ? (
                    <div className="[ d-flex align-items-center ] [ bg-blue-2 p-3 ]">
                      <IneligibleForPvh orgAssociatedToVolunteerEvent />
                    </div>
                  ) : (
                    <PvhFields
                      balance={
                        pvhBalanceData?.currentUser?.paidVolunteerHoursBalance
                      }
                      hoursVolunteered={hours}
                      isClaiming={isClaiming}
                      name="pvhFields"
                      pvhHours={pvhHours}
                    />
                  )}
                </FormFieldset>
              )}

              <FormFieldset
                label={formatMessage(messages.organizationsYouVolunteeredWith)}
              >
                <CharityOrAddedOrganization
                  charityInputName="charitySelections"
                  label={sharedTranslations.organization}
                  name="charityOrAddedOrganization"
                  organizationInputName="organizationSelections"
                />
                <AddressAutocomplete
                  autocompletionRequest={{ types: ['(cities)'] }}
                  name="charityAddress"
                  required
                  textFieldProps={{
                    helperText: formatMessage(messages.whereDidYouVolunteer),
                    label: sharedTranslations.cityAndState,
                  }}
                  type={'google'}
                  validateAddressFields={['city', 'country']}
                />
                <FormSelect
                  name="category"
                  options={categoriesToOptions(
                    pvhBalanceData?.brand?.eventCategories
                  )}
                  required
                  textFieldProps={{
                    helperText: formatMessage(messages.whatTypeOfVolunteering),
                    label: formatMessage(messages.volunteerEventType),
                  }}
                />
                <FormDateField
                  helperText={formatMessage(messages.whatDateYouVolunteered)}
                  label={formatMessage(messages.date)}
                  name="date"
                  required
                />
              </FormFieldset>

              <FormFieldset
                hint={formatMessage(messages.describeImpact)}
                label={formatMessage(messages.shareYourExperience)}
              >
                <FormTextField
                  name="description"
                  required
                  textFieldProps={{
                    helperText: formatMessage(messages.includeDetails),
                    label: v.capitalize(sharedTranslations.comment),
                    multiline: true,
                    rows: 3,
                  }}
                  validationErrors={{
                    isNotBlank: formatMessage(messages.commentCannotBeBlank),
                  }}
                  validations="isNotBlank"
                />
                <UserAutocomplete
                  name="taggedUserSelections"
                  textFieldProps={{
                    helperText: formatMessage(
                      messages.tagOthersWhoParticipated
                    ),
                    label: sharedTranslations.tags,
                  }}
                />
                <CloudinaryQuery>
                  {(cloudinaryConfig) => (
                    <EditableImageAttachment
                      bottomHelperText={formatMessage(
                        messages.logFormPhotoSize
                      )}
                      cloudinaryConfig={cloudinaryConfig}
                      helperText={formatMessage(
                        messages.uploadSnapshotsFromEvent
                      )}
                      imageClassName="w-100"
                      imageHeight="288"
                      imageWidth="512"
                      label={sharedTranslations.photo}
                      name="image"
                      // TODO figure out a better way to deal with async fields.
                      // Need to pass in savedImage explicitly to deal with
                      // this component getting rendered asynchronously due
                      // to the CloudinaryQuery wrapper.
                      shape="rectangle"
                      value={savedImage}
                    />
                  )}
                </CloudinaryQuery>
              </FormFieldset>
              <FormActionBar hideBack isSubmitting={isSubmitting} />
            </FormPage>
            <div className="content-start">
              {res && res[1] && (
                <LinkButton
                  className="fs-2 | text-black-1 text-underline"
                  data-test="volunteer-log-delete"
                  onClick={handleDeleteLog}
                  variant="text"
                >
                  {formatMessage(messages.deleteVolunteerLog)}
                </LinkButton>
              )}
            </div>
          </div>
        </DocumentTitle>
      </GraphQLFeatureFlag>
    )
  )
}
