// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { client } from 'utilities/we_apollo'
import { Paper } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { intl } from 'utilities/localization'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { NEWSFEED_ITEM_FRAGMENT } from 'graphql/fragments'
import { NewsFeedHelpBlurb } from 'components/news_feed/help_blurb'
import { NewsFeedItems } from 'components/news_feed/items'
import { NewsFeedItemSkeleton } from 'components/news_feed/item'
import { pageSize } from 'components/news_feed/utils'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { times } from 'utilities/times'

const EVENT_NEWS_FEED_FETCH_LIMIT = 10

export const GET_EVENT_NEWSFEED_ITEMS = gql`
  query getEventNewsfeedItems($id: ID!, $after: String, $first: Int) {
    participationEvent(id: $id) {
      id
      newsfeed(after: $after, first: $first) @connection(key: "eventNewsfeed") {
        nodes {
          ...NewsfeedItem
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
  }
  ${NEWSFEED_ITEM_FRAGMENT}
`

const EventNewsFeed = ({ eventId }) => {
  const { formatMessage } = intl
  const { loading, error, data, fetchMore } = useQuery(
    GET_EVENT_NEWSFEED_ITEMS,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: { first: EVENT_NEWS_FEED_FETCH_LIMIT, id: eventId },
    }
  )

  const loadNextPage = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldNodes = previousResult.participationEvent.newsfeed.nodes
        const newNodes = fetchMoreResult.participationEvent.newsfeed.nodes

        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.participationEvent.newsfeed.nodes = [...oldNodes, ...newNodes]

        return result
      },
      variables: {
        after: data.participationEvent.newsfeed.pageInfo.endCursor,
        first: EVENT_NEWS_FEED_FETCH_LIMIT,
      },
    })
  }

  if (loading && !data) {
    return (
      <ul className="list-none pl-0">
        {times(pageSize, (index) => (
          <NewsFeedItemSkeleton as="li" key={index} />
        ))}
      </ul>
    )
  }

  if (error) {
    return (
      <>
        <Stack as={Paper} className="p-4">
          <p>
            {formatMessage(sharedMessages.newsfeedError, {
              type: sharedTranslations.event,
            })}
          </p>
        </Stack>
        <ScreenReader
          role="status"
          text={formatMessage(sharedMessages.newsfeedError, {
            type: sharedTranslations.event,
          })}
        />
      </>
    )
  }

  const totalCount = data.participationEvent.newsfeed.totalCount
  const newsfeedItems = data.participationEvent.newsfeed.nodes
  // We are not using hasNextPage from graphql connections because there is a bug
  // in graphql spec that returns false when first variable is not used
  const hasNextPage = totalCount > newsfeedItems.length

  if (newsfeedItems.length === 0) {
    return (
      <NewsFeedHelpBlurb dataTest="no-comments-message">
        {sharedTranslations.noCommentsMessage}
      </NewsFeedHelpBlurb>
    )
  }

  return (
    <div data-test="event-newsfeed-items">
      <NewsFeedItems className="mt-0" items={newsfeedItems} />
      {hasNextPage && (
        <div className="d-flex">
          <LoadMoreButton
            loading={loading}
            newsfeedItemsCount={newsfeedItems.length}
            onClick={loadNextPage}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        </div>
      )}
      {loading && (
        <ScreenReader role="status" text={sharedTranslations.newsfeedLoading} />
      )}
    </div>
  )
}

EventNewsFeed.propTypes = {
  eventId: PropTypes.string.isRequired,
}

export { EventNewsFeed, EVENT_NEWS_FEED_FETCH_LIMIT }
