// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { defineMessages } from 'react-intl'
import cx from 'classnames'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

export const AddToCalendar = ({ className, googleUrl, icsUrl }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const messages = defineMessages({
    add: {
      defaultMessage: 'Add to calendar',
      id: 'addToCalendar.add',
    },
    apple: {
      defaultMessage: 'Apple',
      id: 'addToCalendar.apple',
    },
    google: {
      defaultMessage: 'Google',
      id: 'addToCalendar.google',
    },
    outlook: {
      defaultMessage: 'Outlook',
      id: 'addToCalendar.outlook',
    },
  })

  const openGoogleUrl = () => {
    handleClose()
    window.open(googleUrl, '_blank')
  }

  const openIcsUrl = () => {
    handleClose()
    window.location = icsUrl
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={cx(
          className,
          'bg-white fs-2 text-black-3 text-transform-none'
        )}
        onClick={handleClick}
        size="small"
        variant="outlined"
      >
        <Icon className="fs-3 mr-1" iconName="calendar_plus" title="" />
        {intl.formatMessage(messages.add)}
        <Icon className="fs-4 ml-3" iconName="chevron_down" title="" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: 'ml-1 mt-5' }}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem dense onClick={openIcsUrl}>
          {intl.formatMessage(messages.apple)}
        </MenuItem>
        <MenuItem dense onClick={openGoogleUrl}>
          {intl.formatMessage(messages.google)}
        </MenuItem>
        <MenuItem dense onClick={openIcsUrl}>
          {intl.formatMessage(messages.outlook)}
        </MenuItem>
      </Menu>
    </>
  )
}

AddToCalendar.propTypes = {
  className: PropTypes.string,
  googleUrl: PropTypes.string.isRequired,
  icsUrl: PropTypes.string.isRequired,
}

AddToCalendar.defaultProps = {
  className: null,
}
