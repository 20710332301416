// Vendor
import PropTypes from 'prop-types'
import React from 'react'
import { RawIntlProvider } from 'react-intl'
import { intl, locale } from 'utilities/localization'

const IntlPropType = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired,
})

/**
 * This component sets up the react-intl IntlProvider with the correct locale
 * from the browser and the corresponding set of translations for that locale.
 * In the future, this component would be the place to make a GraphQL query for
 * the user's preferred locale and use it instead of browser locale if
 * specified.
 *
 * We include a `key` prop because, as of react-intl v4, you have to specify
 * this prop in order for the React app to update based on a change in locale
 * (such as if a user manually specifies a preferred locale on a settings
 * page). We don't currently allow users to do that, but we certainly could in
 * the future.
 */
const LocalizationProvider = ({ children }) => (
  <RawIntlProvider key={locale} value={intl}>
    {children}
  </RawIntlProvider>
)

LocalizationProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export { intl, IntlPropType, LocalizationProvider }
