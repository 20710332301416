// Vendor
import React, { useEffect, useState } from 'react'
import { number } from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'
import cx from 'classnames'

// WeSpire
import { withStyles } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { WordForScore } from 'components/queries/word_for_score'
import { useUserScore } from 'utilities/hooks/useUserScore'

const { formatMessage, formatNumber } = intl

const StatsLinearProgress = withStyles({
  bar1Determinate: {
    backgroundColor: '#fa6400',
  },
  determinate: {
    backgroundColor: 'rgba(0,0,0,0.075)',
  },
  root: {
    borderRadius: '1rem',
    height: 24,
  },
})(LinearProgress)

const messages = defineMessages({
  allTime: {
    defaultMessage: 'All Time',
    id: 'pointsStats.allTime',
  },
  annualGoal: {
    defaultMessage: 'Annual Goal',
    id: 'pointsStats.annualGoal',
  },
  currentProgress: {
    defaultMessage: '{ currentPoints } / { targetPoints } { wordForScore }',
    id: 'pointsStats.currentProgress',
  },
  goalAchieved: {
    defaultMessage: 'You have achieved your annual goal for the year.',
    id: 'pointsStats.goalAchieved',
  },
  pointsEarned: {
    defaultMessage: '{ wordForScore } Earned',
    id: 'pointsStats.pointsEarned',
  },
  thisYear: {
    defaultMessage: 'This Year',
    id: 'pointsStats.thisYear',
  },
  yourProgress: {
    defaultMessage: 'Your Progress',
    id: 'pointsStats.yourProgress',
  },
})

export const PointsStats = ({ pointsObjective }) => {
  const { scoreAllTime, scoreThisYear } = useUserScore()
  const [stats, setStats] = useState(null)

  useEffect(() => {
    setStats({
      allTime: scoreAllTime,
      targetPoints: pointsObjective || scoreThisYear + 50,
      thisYear: scoreThisYear,
    })
  }, [scoreAllTime, scoreThisYear, pointsObjective])

  const statMessages = {
    thisYear: formatMessage(messages.thisYear),
    // eslint-disable-next-line sort-keys
    allTime: formatMessage(messages.allTime),
  }

  if (!stats) {
    return (
      <MissingResourceSection
        errorDetails={formatMessage(messages.yourProgress)}
      />
    )
  }

  const goalAchieved = stats.thisYear >= stats.targetPoints

  const computeProgress = () =>
    goalAchieved ? 100 : Math.round((100 * stats.thisYear) / stats.targetPoints)

  return (
    <Stack as="section" className="row" space={0}>
      <Stack
        className="d-flex flex-column col-md-4"
        data-test="points-earned-section"
      >
        <Heading className="px-3 mb-2" level={2}>
          {formatMessage(messages.pointsEarned, {
            wordForScore: <WordForScore score={2} wordOnly />,
          })}
        </Heading>

        <Paper className="flex-grow">
          <Stack className="p-4 row" space={0}>
            {Object.entries(statMessages).map(
              ([stat, label], index) =>
                stats[stat] !== null && (
                  <Stat
                    amount={stats[stat]}
                    className={cx({ 'mr-4': index === 0 })}
                    key={stat}
                    label={label}
                    size={7}
                  />
                )
            )}
          </Stack>
        </Paper>
      </Stack>

      <Stack
        className="d-flex flex-column col-md-8"
        data-test="annual-goal-section"
      >
        <Heading className="px-3 mb-2 [ mt-2 mt-md-0 ]" level={2}>
          {formatMessage(messages.annualGoal)}
        </Heading>

        <Paper className="flex-grow [ d-flex flex-column justify-content-center ]">
          <Stack
            className="[ d-flex flex-column | flex-md-row ] [ p-4 row ]"
            space={{ _: 4, md: 0 }}
          >
            {goalAchieved ? (
              <span className="fs-1 fw-bold ls-2 text-black-3 text-uppercase [ mb-2 ]">
                {formatMessage(messages.goalAchieved)}
              </span>
            ) : (
              <span className="fs-1 fw-bold ls-2 text-black-3 text-uppercase [ mb-2 ]">
                {formatMessage(messages.yourProgress)}
              </span>
            )}

            <StatsLinearProgress
              aria-label="stats progressbar"
              aria-valuemax="100"
              aria-valuemin="0"
              className="w-100"
              data-test="points-dashboard-progress-bar"
              value={computeProgress()}
              variant="determinate"
            />

            <div className="d-flex w-100 ">
              <span className="d-inline-block fs-1 fw-semi-bold text-black-3 text-lowercase [ ml-auto mt-2 ]">
                {formatMessage(messages.currentProgress, {
                  currentPoints: formatNumber(stats.thisYear),
                  targetPoints: formatNumber(stats.targetPoints),
                  wordForScore: <WordForScore wordsOnly />,
                })}
              </span>
            </div>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}

PointsStats.propTypes = {
  pointsObjective: number,
}

PointsStats.defaultProps = {
  pointsObjective: null,
}
