// Setup
import React from 'react'
import { intl } from 'utilities/localization'
import { List, Paper } from '@material-ui/core'
import { useQuery } from '@apollo/client'

// WeSpire
import { BroadcastListItem } from 'components/broadcasts/components/broadcast_list_item'
import { BROADCASTSQUERY } from '../utilities/queries'
import { Heading } from 'components/ui/heading'
import { messages } from '../utilities/messages'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const AdditionalBroadcastsList = () => {
  const {
    data = { broadcasts: [] },
    error,
    loading,
  } = useQuery(BROADCASTSQUERY)

  if (error) {
    return <MissingResourceSection />
  }

  if (loading) {
    return <SkeletonLoading height={400} />
  }
  return (
    <>
      <Heading className="px-3" level={2} variant={2}>
        {formatMessage(messages.olderPosts)}
      </Heading>
      <Stack as={Paper} space={3}>
        {data.broadcasts.length > 0 ? (
          <List className="p-0">
            {data.broadcasts.map((broadcast) => (
              <BroadcastListItem
                broadcast={broadcast}
                className="p-3"
                key={broadcast.id}
                urlPath={broadcast.id}
              />
            ))}
          </List>
        ) : (
          <p className="p-3">{formatMessage(messages.errorMessage)}</p>
        )}
      </Stack>
    </>
  )
}

export { AdditionalBroadcastsList }
