import gql from 'graphql-tag'

export const REQUEST_DOLLARS_FOR_DOERS_DONATION = gql`
  mutation requestDollarsForDoersDonation(
    $amountCents: Int!
    $anonymity: String!
    $ein: String!
    $givingaUserId: ID!
    $matched: Boolean!
    $notes: String
  ) {
    requestDollarsForDoersDonation(
      amountCents: $amountCents
      anonymity: $anonymity
      ein: $ein
      givingaUserId: $givingaUserId
      matched: $matched
      notes: $notes
    ) {
      errors
      transaction
    }
  }
`
