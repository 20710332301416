// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import FormCheckbox from 'components/form/checkbox'

// Sub-components
import { agreeToWaiverMessage } from 'components/events/utils'

export const EventWaiverCheckbox = ({ event, waiverHelperText, ...other }) => (
  <FormCheckbox
    helperText={waiverHelperText}
    labelProps={{
      label: agreeToWaiverMessage(event),
    }}
    name="agreeToWaiver"
    required="isFalse"
    value={false}
    {...other}
  />
)

EventWaiverCheckbox.propTypes = {
  event: PropTypes.object.isRequired,
  waiverHelperText: PropTypes.string.isRequired,
}
