import gql from 'graphql-tag'

export const PUBLISH_CAMPAIGN_MUTATION = gql`
  mutation publishCampaign($campaignId: ID!, $redirectTarget: String) {
    publishCampaign(campaignId: $campaignId, redirectTarget: $redirectTarget) {
      message
      errors
    }
  }
`
export const SAVE_CAMPAIGN_MUTATION = gql`
  mutation saveCampaign($campaignId: ID!, $redirectTarget: String) {
    saveCampaign(campaignId: $campaignId, redirectTarget: $redirectTarget) {
      message
      errors
    }
  }
`
