// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'

const FormDetails = ({ children, ...other }) => (
  <Stack {...other} as="dl" space={{ _: 3, sm: 2 }}>
    {children}
  </Stack>
)

FormDetails.propTypes = {
  children: PropTypes.node.isRequired,
}

export { FormDetails }
