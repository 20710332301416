import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  customizeCampaign: {
    defaultMessage: 'Customize Campaign',
    id: 'campaignManagementMessages.customizeCampaign',
  },
  previewLabel: {
    defaultMessage:
      'This is a draft preview, all actions are disabled for this page.',
    id: 'campaignTemplatePreviewMessages.previewLabel',
  },
  reviewStepButtonLabel: {
    defaultMessage: 'Review & Publish',
    id: 'campaignTemplatePreviewMessages.reviewStepButtonLabel',
  },
})

export const campaignTemplatePreviewMessages = formatMessages(messages)
