// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'

/**
 * Used to wrap groups of <FormFieldset> components in order to apply consistent
 * vertical spacing.
 */
const FormFieldsetGroup = ({ children, ...other }) => (
  <Stack {...other}>{children}</Stack>
)

FormFieldsetGroup.propTypes = {
  /** direct children should be <FormFieldset> components */
  children: PropTypes.node.isRequired,
  /** specify vertical spacing between <FormFieldset> components */
  space: PropTypes.number,
}

FormFieldsetGroup.defaultProps = {
  space: 6,
}

export default FormFieldsetGroup
