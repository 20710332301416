// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import store from 'redux/store'

// Vendor
import cookie from 'react-cookie'
import fetch from 'unfetch'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client'
import fragmentTypes from '../../../fragmentTypes.json'

// extracts possible types from fragmentTypes.json after migration to apollo 3 to use in inMemoryCache as possibleTypes
const introspectionToPossibleTypes = (introspectionQueryResultData) => {
  const possibleTypes = {}

  introspectionQueryResultData.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        (subtype) => subtype.name
      )
    }
  })

  return possibleTypes
}

const httpLink = new HttpLink({
  credentials: 'same-origin',
  // eslint-disable-next-line compat/compat
  fetch: window.fetch || fetch,
  uri: (window.BASE_API_URL || '/') + 'graphql',
  useGETForQueries: true,
})
const apolloLink = new ApolloLink((operation, forward) => {
  const baseHeaders = {
    'X-CSRF-Token': cookie.load('X-CSRF-Token'),
  }
  const sessionState = store.getState().session
  const headers = sessionState.isNative
    ? {
        ...baseHeaders,
        'Api-Token': window.NATIVE_API_TOKEN,
        Authorization: sessionState.token,
      }
    : baseHeaders

  operation.setContext({
    headers: headers,
  })

  return forward(operation)
})
const link = apolloLink.concat(httpLink)

export const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'CampaignTeamScore':
        case 'CampaignUserScore':
          // CampaignUserScores currently have a dynamic rank field which may
          // change, despite the object having the same id.
          // We append the rank to the dataId used by the cache to include this rank.
          return `${defaultDataIdFromObject(object)}_${object.rank}`
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    },
    possibleTypes: introspectionToPossibleTypes(fragmentTypes),
  }),
  link: link,
})

// Returns object without "__typename" key.
// This is useful when trying to save via a mutation an object that was
// retrieved from GraphQL. Without this, the mutation would complain about
// unknown "__typename" argument on the object being saved.
export const removeTypename = (object) => {
  // eslint-disable-next-line no-unused-vars
  let { __typename, ...rest } = object
  return rest
}

const WeApollo = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

WeApollo.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WeApollo
