import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Collapse from '@material-ui/core/Collapse'
import Icon from 'components/ui/icon'
import Stack from 'components/ui/stack'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { defineMessages } from 'react-intl'

// WeSpire
import ActivitiesCardList from './card_list'
import isDesktop from 'components/helpers/is_desktop'
import EventRegistrationOpportunities from 'components/events/registration_opportunities/loadable'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  allActivities: {
    defaultMessage: 'all activities',
    description:
      'label for a tab that, when clicked, displays activities users can take part in',
    id: 'activitiesCollection.allActivities',
  },
  eventsList: {
    defaultMessage: 'events list',
    description:
      'label for a tab that, when clicked, displays a list of events that users can register for',
    id: 'activitiesCollection.eventsList',
  },
  noEventsMessage: {
    defaultMessage: 'There are currently no Events available in this Channel.',
    id: 'activitiesCollection.noEventsMessage',
  },
})

// We cannot show the 'Events List' on the 'My Activities' tab because
// it's a separate collection, not a legitimate Channel. So the query
// does not work and we guard against using it in a few places.
const ActivitiesCollection = ({
  activities,
  className,
  currentChannelId,
  channelHasEvents,
  isDesktop,
  isEventsView,
  isMyActivitiesTab,
  onActivitiesViewChange,
  onActivityClick,
  viewType,
}) => {
  const hasViewType = viewType !== undefined
  const isViewAll = viewType === 'all'
  const isViewEvents = viewType === 'events-list'

  const hasTabs = !isMyActivitiesTab
  const hasActivitiesCards = isMyActivitiesTab || !hasViewType || isViewAll
  const hasEventsList = !isMyActivitiesTab && isViewEvents

  useEffect(() => {
    if (isEventsView && !channelHasEvents) {
      onActivitiesViewChange(() => {}, 'all')
    }
  }, [channelHasEvents, isEventsView, onActivitiesViewChange])

  const { formatMessage } = intl

  return (
    <div className={className}>
      {hasTabs && (
        <Collapse in>
          <Tabs
            className="px-2 px-sm-3 px-lg-0 py-2"
            onChange={onActivitiesViewChange}
            textColor="secondary"
            value={viewType || 'all'}
            variant={isDesktop ? undefined : 'fullWidth'}
          >
            <Tab
              className="fs-2"
              label={formatMessage(messages.allActivities)}
              value="all"
            />
            {channelHasEvents && (
              <Tab
                className="fs-2"
                data-test="events-list-tab"
                label={formatMessage(messages.eventsList)}
                value="events-list"
              />
            )}
          </Tabs>
        </Collapse>
      )}
      {!channelHasEvents && isEventsView && (
        <Stack className="[ d-flex align-items-center ] [ bg-blue-2 border-left-thick-blue p-3 ]">
          <Icon className="fs-5 mr-3 text-blue" iconName="info" title="" />
          <p data-test="no-events-message">
            {formatMessage(messages.noEventsMessage)}
          </p>
        </Stack>
      )}

      {hasActivitiesCards && (
        <div className="mt-2 mt-sm-3 px-sm-3 px-lg-0">
          <ActivitiesCardList
            activities={activities}
            fromChannelId={currentChannelId}
            onActivityClick={onActivityClick}
          />
        </div>
      )}

      {hasEventsList && (
        <div className="px-2">
          <EventRegistrationOpportunities channelId={currentChannelId} />
        </div>
      )}
    </div>
  )
}

ActivitiesCollection.propTypes = {
  activities: PropTypes.array.isRequired,
  channelHasEvents: PropTypes.bool.isRequired,
  className: PropTypes.string,
  currentChannelId: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isEventsView: PropTypes.bool.isRequired,
  isMyActivitiesTab: PropTypes.bool.isRequired,
  onActivitiesViewChange: PropTypes.func.isRequired,
  onActivityClick: PropTypes.func.isRequired,
  viewType: PropTypes.string,
}

ActivitiesCollection.defaultProps = {
  className: null,
  viewType: undefined,
}

export default isDesktop(ActivitiesCollection)
