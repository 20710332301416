// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import Formsy from 'formsy-react'
import { defineMessages } from 'react-intl'
import { Paper } from '@material-ui/core'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import {
  customFieldFormsyName,
  getCustomFieldResponsesAttributes,
} from 'components/form/custom_field'
import { ErrorList } from 'components/form/error_list'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { EventWaiverCheckbox } from './event_waiver_checkbox'
import FormActionBar from 'components/form/action_bar'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { removeTypename } from 'utilities/we_apollo'
import { eventsTranslations } from 'components/events/shared_translations'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'
import { AdditionalFormFields } from './additional_form_fields'
import { EVENT_QUERY } from './event_registration_form'
import { EVENT_REGISTRATION_FRAGMENT } from './fragments'
import { RoleSelection } from './role_selection'
import { showRegistrationClosedNotice } from 'components/events/utils'
import FormFieldset from 'components/form/fieldset'
import { EventPvhFields, eventPvhFieldsQuery } from './event_pvh_fields'
import { IneligibleForPvh } from 'components/pvh'
import { sharedTranslations } from 'components/shared/translations'
import { useUserScore } from 'utilities/hooks/useUserScore'

const messages = defineMessages({
  operation: {
    defaultMessage: 'register for this Event',
    id: 'selfRegistrationForm.operation',
  },
  registrationUpdated: {
    defaultMessage: 'Your registration has been updated.',
    id: 'selfRegistrationForm.registrationUpdated',
  },
  successfullyRegistered: {
    defaultMessage: 'You have been successfully registered.',
    id: 'selfRegistrationForm.successfullyRegistered',
  },
  successfullyRegisteredPoints: {
    defaultMessage:
      'Your registration is complete. Below are your registration details. Please note, you will see your earned points for attending this event 24 hours after the event has ended.',
    id: 'selfRegistrationForm.successfullyRegisteredPoints',
  },
  updateRegistration: {
    defaultMessage: 'Update your registration',
    id: 'selfRegistrationForm.updateRegistration',
  },
})

const SHIFT_TIMEFRAME_PARTICIPATION_EVENT = gql`
  ${EVENT_REGISTRATION_FRAGMENT}

  query shiftTimeframe($id: ID!) {
    brandConfig {
      id
      enablePaidVolunteerHours
    }

    shiftTimeframe(id: $id) {
      icsCalendarUrl
      id
      googleCalendarUrl
      registrationEnded

      participationEvent {
        ein
        id
        isVolunteering
        name
        organizationName

        customFields {
          id
          isRequired
          name
          options
          responseType
        }

        eventRegistration {
          ...EventRegistrationAttributes
        }

        guestsAllowed
        requireAddress
        requirePhoneNumber
        requireWaiver
      }

      registeredShift {
        id
      }

      shifts {
        id
        availableSlotsCount
        shiftFunction
      }
    }
  }
`

const REGISTER = gql`
  mutation register(
    $address: Address
    $agreeToWaiver: Boolean
    $customFieldResponses: [CustomFieldResponseAttributes!]
    $phoneNumber: String
    $pvhHours: Int
    $registrationId: ID!
  ) {
    register(
      address: $address
      agreeToWaiver: $agreeToWaiver
      customFieldResponses: $customFieldResponses
      phoneNumber: $phoneNumber
      pvhHours: $pvhHours
      registrationId: $registrationId
    ) {
      errors
    }
  }
`

export const SelfRegistrationForm = ({ pointsForRegistering, shiftId }) => {
  const { formatMessage } = intl
  const { incrementUserScore } = useUserScore()

  const [formSetup, setFormSetup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleRegister = (model, registeredShift, eventId) => {
    const { formatMessage } = intl
    setIsSubmitting(true)

    client
      .mutate({
        awaitRefetchQueries: true,
        mutation: REGISTER,
        refetchQueries: [
          // Refresh index page's participants, shift counts, and registeredShift.
          { query: EVENT_QUERY, variables: { id: eventId } },
          // Refetch our query to get updated EventRegistration and shift counts.
          {
            query: SHIFT_TIMEFRAME_PARTICIPATION_EVENT,
            variables: { id: shiftId },
          },
          // Refetch our query for user's PVH information
          {
            query: eventPvhFieldsQuery,
            variables: { shiftId: shiftId },
          },
        ],
        variables: {
          address: model?.address?.address || null,
          agreeToWaiver: model.agreeToWaiver,
          customFieldResponses: getCustomFieldResponsesAttributes(model),
          phoneNumber: model.telephone,
          pvhHours: model.pvhClaim ? parseInt(model.pvhHours) : null,
          registrationId: model.shiftId,
        },
      })
      .then(
        ({
          data: {
            register: { errors },
          },
        }) => {
          setIsSubmitting(false)
          if (errors) {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          } else {
            const message = registeredShift ? (
              formatMessage(messages.registrationUpdated)
            ) : (
              <>
                <h4 className="m-0">
                  {formatMessage(messages.successfullyRegistered)}
                </h4>
                <p className="m-0">
                  {formatMessage(messages.successfullyRegisteredPoints)}
                </p>
              </>
            )
            displayBanner({ content: message, variant: 'success' })
            if (!registeredShift) {
              // only increase score on register not update
              incrementUserScore(pointsForRegistering)
            }
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: formatMessage(messages.operation),
        })
      })
  }

  const setupForm = ({ form, registeredShift, registration }) => {
    // Only reset if we haven't already AND we have already registered for a shift.
    if (form && !formSetup) {
      if (!registeredShift) {
        // Prevent resetting form if we have not yet registered. This fixes
        // issue where some fields (pvhHours) would get reset after registration
        // and thus not show the actual value the user set.
        setFormSetup(true)
        return
      }

      let existingData = { shiftId: `${registeredShift.id}` }

      // It's possible we don't have a registration object yet if user was
      // added in MGMT panel via an admin.
      if (registration) {
        existingData.agreeToWaiver = registration.agreeToWaiver
        existingData.telephone = registration.phoneNumber
        if (registration.address) {
          existingData.address = {
            address: registration.address
              ? removeTypename(registration.address)
              : null,
            addressLabel: '', // Not shown but needed to prevent JS errors.
          }
        }
        registration.customFieldResponses.forEach(
          ({ customFieldId, name, response }) => {
            existingData[customFieldFormsyName(customFieldId, name)] = response
          }
        )
      }

      form.reset(existingData)
      // We need to prevent the form from getting setup a second time as that
      // would cause the changed input values to get reset.
      // This happens due to us updating isSubmitting causing another render.
      setFormSetup(true)
    }
  }

  return (
    <Paper className="mt-4 pb-5 pt-4 px-3 | px-sm-4">
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={eventsTranslations.yourRegistration}
          />
        }
        loader={<CenteredPaddedLoadingIndicator />}
        query={SHIFT_TIMEFRAME_PARTICIPATION_EVENT}
        variables={{ id: shiftId }}
      >
        {({
          data: {
            brandConfig: { enablePaidVolunteerHours },
            shiftTimeframe,
          },
        }) => {
          const event = shiftTimeframe.participationEvent
          const registeredShift = shiftTimeframe.registeredShift
          const baseText = eventsTranslations.acceptWaiver
          const guestText = eventsTranslations.acceptGuestsWaiver
          const waiverHelperText = event.guestsAllowed
            ? `${baseText} ${guestText}`
            : baseText

          if (shiftTimeframe.registrationEnded) {
            showRegistrationClosedNotice(event)
          }

          return (
            <>
              <Formsy
                noValidate
                onValidSubmit={(model) =>
                  handleRegister(model, registeredShift, event.id)
                }
                ref={(form) =>
                  setupForm({
                    form,
                    registeredShift,
                    registration: event.eventRegistration,
                  })
                }
              >
                <Stack space={5}>
                  <FormFieldset
                    headingProps={{
                      className: 'text-capitalize',
                    }}
                    label={eventsTranslations.yourRegistration}
                  >
                    <RoleSelection shifts={shiftTimeframe.shifts} />

                    <AdditionalFormFields
                      address={event.requireAddress}
                      customFields={event.customFields}
                      telephone={event.requirePhoneNumber}
                    />

                    {event.requireWaiver && (
                      <EventWaiverCheckbox
                        event={event}
                        waiverHelperText={waiverHelperText}
                      />
                    )}
                  </FormFieldset>

                  {enablePaidVolunteerHours && (
                    <FormFieldset label={sharedTranslations.paidVolunteerHours}>
                      {event.isVolunteering && event.ein ? (
                        <EventPvhFields shiftId={shiftId} />
                      ) : (
                        <div className="[ d-flex align-items-center ] [ bg-blue-2 p-3 ]">
                          <IneligibleForPvh
                            organizationName={event.organizationName}
                            orgAssociatedToVolunteerEvent={event.isVolunteering}
                          />
                        </div>
                      )}
                    </FormFieldset>
                  )}

                  <FormActionBar
                    disabled={shiftTimeframe.registrationEnded}
                    hideBack={!!registeredShift}
                    isSubmitting={isSubmitting}
                    submitText={
                      registeredShift
                        ? formatMessage(messages.updateRegistration)
                        : eventsTranslations.register
                    }
                  />
                </Stack>
              </Formsy>
            </>
          )
        }}
      </WeQuery>
    </Paper>
  )
}

SelfRegistrationForm.propTypes = {
  pointsForRegistering: PropTypes.number,
  shiftId: PropTypes.string.isRequired,
}

SelfRegistrationForm.defaultProps = {
  pointsForRegistering: null,
}
