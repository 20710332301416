// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// WeSpire
import EventRegistrationDayGroup from 'components/events/registration_opportunities/day_group'

const EventRegistrationMonthGroup = ({ dateTime, days, label }) => (
  <Fragment>
    <h3 className="fs-4 mb-0 px-3">
      <time dateTime={dateTime}>{label}</time>
    </h3>

    {days.map(({ dateTime, label, opportunities }) => (
      <EventRegistrationDayGroup
        dateTime={dateTime}
        key={dateTime}
        label={label}
        opportunities={opportunities}
      />
    ))}
  </Fragment>
)

EventRegistrationMonthGroup.propTypes = {
  dateTime: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(
    PropTypes.shape({
      dateTime: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      opportunities: PropTypes.array.isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
}

export default EventRegistrationMonthGroup
