export const setupCampaignsPath = '/management_panel/campaigns'

export const campaignActionsPath = ({ campaignId }) =>
  `${setupCampaignsPath}/${campaignId}/edit?step=acts`

export const editCampaignPath = ({ campaignId }) =>
  `${setupCampaignsPath}/${campaignId}/edit`

export const campaignLocalesPath = (campaignId) =>
  `${setupCampaignsPath}/${campaignId}/locales`

export const editCampaignLocalePath = ({ campaignId, code }) =>
  `${setupCampaignsPath}/${campaignId}/locales/${code}/edit`

export const showCampaignPath = ({ campaignId }) => `/campaigns/${campaignId}`
