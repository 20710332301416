// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import { ChapterRow } from 'components/setup/chapters/chapter_row'
import ScreenReader from 'components/ui/screen_reader_text'
import { sortByNumber } from 'utilities/sort'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const messages = defineMessages({
  chapterName: {
    defaultMessage: 'Name',
    description: 'The name of the chapter',
    id: 'chaptersList.chapterName',
  },
  leadershipRolesTitle: {
    defaultMessage: 'Leadership Roles',
    description: 'Button to add Leaderships',
    id: 'chaptersList.leadershipRolesTitle',
  },
  noChapters: {
    defaultMessage: 'There are no chapters yet.',
    id: 'chaptersList.noChapters',
  },
})

const ChaptersList = ({
  ariaLiveText,
  groupChapters,
  groupSlug,
  onUpdatePositionClick,
}) => {
  const sortedChapters = sortByNumber(groupChapters, 'position')
  return (
    <>
      {sortedChapters.length > 0 ? (
        <div>
          <div className="bg-black-6 fs-1 fw-semi-bold ls-3 py-3 text-uppercase">
            <div className="row">
              <span className="col-2" />
              <span className="col-6">
                {formatMessage(messages.chapterName)}
              </span>
              <span className="col-2">
                {formatMessage(messages.leadershipRolesTitle)}
              </span>
            </div>
          </div>
          <Stack as="ol" className="p-0" divider="default" space={1}>
            {sortedChapters.map((chapter) => (
              <ChapterRow
                chapterId={chapter.id}
                chapterSlug={chapter.slug}
                groupSlug={groupSlug}
                key={chapter.id}
                name={chapter.name}
                onUpdatePositionClick={onUpdatePositionClick}
              />
            ))}
          </Stack>
          <ScreenReader aria-live="polite" text={ariaLiveText} />
        </div>
      ) : (
        <p className="fw-semi-bold" data-test="empty-state-message">
          {formatMessage(messages.noChapters)}
        </p>
      )}
    </>
  )
}

ChaptersList.propTypes = {
  ariaLiveText: PropTypes.string.isRequired,
  groupChapters: PropTypes.array.isRequired,
  groupSlug: PropTypes.string.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
}

export { ChaptersList }
