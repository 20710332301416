// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const PageSectionHeader = ({ label, ...other }) => (
  <h2
    {...other}
    className={cx(
      'fs-1 fw-regular pb-3 pl-3 my-0 text-black-3 text-uppercase',
      other.className
    )}
  >
    {label}
  </h2>
)

PageSectionHeader.propTypes = {
  label: PropTypes.string.isRequired,
}

export default PageSectionHeader
