// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// Wespire
import { client } from 'utilities/we_apollo'

const UserAuthenticatedContext = React.createContext()

const UserAuthenticatedQuery = gql`
  query userAuthenticated {
    currentUser {
      id
    }
  }
`

const UserAuthenticatedProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [init, setInit] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: { currentUser },
        } = await client.query({
          query: UserAuthenticatedQuery,
        })

        if (currentUser) setIsAuthenticated(true)
      } catch {
        // We don't have to execute something here, the finally works even if it fails the try
      } finally {
        setInit(true)
      }
    }

    fetch()
  }, [])

  return (
    <UserAuthenticatedContext.Provider value={isAuthenticated}>
      {init ? children : null}
    </UserAuthenticatedContext.Provider>
  )
}

UserAuthenticatedProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export { UserAuthenticatedContext, UserAuthenticatedProvider }
