import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, Form } from 'formik'
import { currencyDonationAmount } from 'utilities/currency_utils'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

// westyle
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import Divider from 'src/common/Divider'
import DocumentTitle from 'src/common/DocumentTitle/DocumentTitle'
import DonationAmountSection from 'src/pages/GivingForm/components/DonationAmountSection'
import DonationFrequency from 'src/pages/GivingForm/components/DonationFrequency'
import { ErrorList } from 'components/form/error_list'
import { getUrlParams } from 'utilities/get_url_params'
import MessageSection from 'src/pages/GivingForm/components/MessageSection'
import PaymentMethodSection from 'src/pages/GivingForm/components/PaymentMethodSection'
import Pill from 'src/common/Pill'
import SkeletonLoading from 'src/common/SkeletonLoading/SkeletonLoading'
import SubmissionSection from 'src/pages/GivingForm/components/SubmissionSection'
import PageContainer from 'src/common/PageContainer'
import {
  DONATE_MUTATION,
  REQUEST_FUNDED_DONATION_MUTATION,
} from './mutations/mutations'
import {
  CHARITY_QUERY,
  DONATION_FORM_BALANCES_QUERY,
  RECURRING_DONATIONS_ENABLED,
} from './queries/queries'
import { validateValues } from './utils/utils'

const GivingForm = () => {
  const { enableDonationForm, enableDonationHistory } = useFlags()
  const intl = useIntl()
  const { push } = useHistory()
  const { ein } = useParams()
  const activityId = () => getUrlParams().activity_id
  const donationHistoryLink = enableDonationHistory
    ? '/app/donation_history_page'
    : '/users/me/donation_history'

  const [createDonation] = useMutation(DONATE_MUTATION)
  const [requestFundedDonationMutation] = useMutation(
    REQUEST_FUNDED_DONATION_MUTATION,
    {
      refetchQueries: [{ query: DONATION_FORM_BALANCES_QUERY }],
    }
  )
  const {
    loading,
    data: {
      brandConfig: {
        donorCoveringCreditCardFee,
        donorCoveringProcessingFee,
      } = {},
      charity,
      currentUser: { brand, currency, givingaUser, donationNotesLength } = {},
      donationMatchingEnabled,
      donationEmployeeAccountEnabled,
    } = {},
  } = useQuery(CHARITY_QUERY, {
    variables: { ein },
  })
  const { data: { recurringDonationsEnabled } = {} } = useQuery(
    RECURRING_DONATIONS_ENABLED
  )

  const [donationAmount, setDonationAmount] = useState('')
  const [donationNote, setNote] = useState('')
  const [anonymousDonation, setAnonymousDonation] = useState(false)
  const [isRecurring, setIsRecurring] = useState('day')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [match, setMatched] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('direct')
  const [selectedCurrency, setSelectedCurrency] = useState({
    id: 9,
    isZeroDecimal: false,
    name: 'Dollars',
    symbol: '$',
    value: 'USD',
  })
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const { refetch } = useQuery(DONATION_FORM_BALANCES_QUERY, {
    skip: !selectedCurrency,
  })

  const handleDonationCurrency = async (selectedCurrency) => {
    setLoadingCurrencyConversion(true)
    const {
      data: {
        currentUser: {
          givingaUser: {
            availableDonationFunds,
            availableDonationCurrency,
            donationFundsCap,
          },
        },
      },
    } = await refetch({
      currencyId: selectedCurrency.id,
    })
    setSelectedCurrency(selectedCurrency)
    setSelectedCurrencyFundsCap(donationFundsCap)
    setSeletectedCurrencyIsoCode(availableDonationCurrency)
    setSelectedCurrencyMatchingFunds(availableDonationFunds)
    setLoadingCurrencyConversion(false)
  }

  const handleDonationAmount = (event) => {
    setDonationAmount(event.target.value)
  }

  if (!charity || !enableDonationForm) {
    return loading ? <SkeletonLoading header height={15} /> : <p>Error</p>
  }

  const cardDonation = async ({
    anonymousDonation,
    charityId,
    charityName,
    donationCurrency,
    donationInterval,
    donationNote,
    isZeroDecimalCurrency,
    matched,
  }) => {
    const mutationVariables = {
      activityId: activityId(),
      amount: currencyDonationAmount(donationAmount, isZeroDecimalCurrency),
      anonymity: anonymousDonation ? 'Anonymous' : 'Full Transparency',
      charityId: charityId,
      charityName: charityName,
      currencyId: donationCurrency.id,
      ein,
      interval: donationInterval,
      matched: matched || false,
      notes: donationNote || null,
    }
    try {
      const {
        data: {
          donate: {
            errors,
            stripeAccount,
            stripeSessionId,
            stripePublishableKey,
          },
        },
      } = await createDonation({
        variables: mutationVariables,
      })
      if (stripeSessionId) {
        // eslint-disable-next-line no-undef
        Stripe(stripePublishableKey, {
          stripeAccount: stripeAccount,
        })
          .redirectToCheckout({
            sessionId: stripeSessionId,
          })
          .then(function () {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            displayBanner({
              content: intl.formatMessage({
                defaultMessage: 'Your donation was submitted.',
                id: 'donationForm.yourDonationWasSubmitted',
              }),
              variant: 'success',
            })
            push(donationHistoryLink)
          })
      } else {
        let show_this_error_now = errors.join('. ')
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: show_this_error_now,
        })
      }
    } catch {
      setIsSubmitting(false)
    }
  }

  const fundedAccountDonation = async ({
    anonymousDonation,
    donationNote,
    matched,
  }) => {
    try {
      const {
        data: {
          requestFundedDonation: { transaction, errors },
        },
      } = await requestFundedDonationMutation({
        variables: {
          activityId: activityId(),
          amount: parseFloat(donationAmount),
          anonymity: anonymousDonation ? 'Anonymous' : 'Full Transparency',
          ein,
          matched: matched || false,
          notes: donationNote || '',
        },
      })

      setIsSubmitting(false)
      if (errors.length === 0) {
        displayBanner({
          content: intl.formatMessage({
            defaultMessage: 'Your Funded Donation request was submitted.',
            id: 'donationForm.requestSuccess',
          }),
          variant: 'success',
        })
        push(donationHistoryLink)
      } else {
        displayBanner({
          as: 'div',
          content: <ErrorList errors={errors} />,
          variant: 'error',
        })
      }
    } catch {
      setIsSubmitting(false)
      displayExceptionBanner({
        operation: 'intl.formatMessage(messages.operation)',
      })
    }
  }

  return (
    <DocumentTitle
      title={intl.formatMessage({
        defaultMessage: 'Make a donation',
        id: 'dollarsForDoersForm.makeADonation',
      })}
    >
      <PageContainer
        title={intl.formatMessage({
          defaultMessage: 'Make a donation',
          id: 'dollarsForDoersForm.makeADonation',
        })}
      >
        <Formik
          initialValues={{
            anonymous: false,
            donationAmount: '',
            donationFrequency: 'day',
            ein: charity.id,
            isValid: false,
            match: false,
            note: '',
            paymentMethod: 'direct',
          }}
          validate={(values) => {
            const errors = validateValues(values, setIsValid, givingaUser)
            setIsValid(Object.keys(errors).length === 0)
            return errors
          }}
          onSubmit={(values) => {
            setIsSubmitting(true)
            const charityId = values.ein
            const charityName = charity.name
            const donationInterval = values.donationFrequency
            const matched = values.matched
            const donationNote = values.note
            const paymentMethod = values.paymentMethod
            const isZeroDecimalCurrency = selectedCurrency?.isZeroDecimal
            const donationCurrency = selectedCurrency

            if (paymentMethod === 'grant_account') {
              fundedAccountDonation({
                anonymousDonation,
                donationNote,
              })
            } else {
              cardDonation({
                anonymousDonation,
                charityId,
                charityName,
                donationCurrency,
                donationInterval,
                donationNote,
                isZeroDecimalCurrency,
                matched,
              })
            }
          }}
        >
          {({ isValid }) => (
            <Form>
              <h2 className="text-2xl pb-3 font-medium">{charity.name}</h2>
              {charity.matching && (
                <Pill>
                  <FormattedMessage
                    defaultMessage="Matching gift available"
                    id="donationForm.oneToOneMatchGift"
                  ></FormattedMessage>
                </Pill>
              )}
              <Divider className="mt-7 mb-1 border-grayscale-2" />
              <PaymentMethodSection
                availableDonationFunds={givingaUser.availableDonationFunds}
                donationEmployeeAccountEnabled={donationEmployeeAccountEnabled}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                userCurrency={selectedCurrency}
              />
              {recurringDonationsEnabled && (
                <DonationFrequency
                  isRecurring={isRecurring}
                  setIsRecurring={setIsRecurring}
                />
              )}
              <div>
                <DonationAmountSection
                  availableDonationFunds={givingaUser.availableDonationFunds}
                  canMatch={charity.matched}
                  donationAmount={donationAmount}
                  donationFundsCap={givingaUser.donationFundsCap}
                  donationMatchingEnabled={donationMatchingEnabled}
                  enabledCurrencies={brand.enabledCurrencies}
                  handleDonationAmount={handleDonationAmount}
                  isEmployerFunded={paymentMethod === 'grant_account'}
                  match={match}
                  matchRatio={charity.matchRatio}
                  selectedCurrency={selectedCurrency}
                  setDonationAmount={setDonationAmount}
                  setMatched={setMatched}
                  setSeletectedCurrency={setSelectedCurrency}
                  userCurrency={currency}
                />
                <MessageSection
                  note={donationNote}
                  setNote={setNote}
                  anonymousDonation={anonymousDonation}
                  setAnonymousDonation={setAnonymousDonation}
                />
              </div>
              <Divider className="mt-10 border-grayscale-2" />
              <SubmissionSection
                disabled={submitDisabled}
                donationAmount={donationAmount}
                isSubmitting={isSubmitting}
                isValid={isValid}
                selectedCurrency={selectedCurrency}
              />
            </Form>
          )}
        </Formik>
      </PageContainer>
    </DocumentTitle>
  )
}

export default GivingForm
