// Setup
import PropTypes from 'prop-types'
import React from 'react'

// Vendor
import Button from '@material-ui/core/Button'
import cx from 'classnames'

// WeSpire
import { Image } from 'components/ui/image'
import ScreenReader from 'components/ui/screen_reader_text'

/** Button-like clickable image. Extends MUI <Button/>. */
const ImageButton = ({
  alt,
  aspectRatio,
  crop,
  customImage,
  onClick,
  shape,
  src,
  srText,
  ...props
}) => {
  // flex-shrink-none is used to keep the image from overflowing on IE 11
  const flexShrinkNone = 'flex-shrink-none'
  const imgProps = {
    alt,
    className: cx(
      flexShrinkNone,
      { rounded: shape === 'rounded' },
      { 'rounded-0': shape === 'square' }
    ),
    src,
  }

  return (
    <Button
      {...props}
      className={cx('op-hover p-0', props.className)}
      onClick={onClick}
      variant="contained"
    >
      {customImage ? (
        <Image {...imgProps} aspectRatio={aspectRatio} crop={crop} />
      ) : (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img {...imgProps} />
      )}
      {srText && <ScreenReader text={srText} />}
    </Button>
  )
}

ImageButton.propTypes = {
  /** Description of image for screen readers */
  alt: PropTypes.string,
  aspectRatio: PropTypes.oneOf(['1:1', '1:5', '16:9']),
  className: PropTypes.string,
  crop: PropTypes.oneOf(['fill', 'fit', 'scale']),
  customImage: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  shape: PropTypes.oneOf(['rounded', 'square']),
  src: PropTypes.string.isRequired,
  /** Additional text only available to screen readers */
  srText: PropTypes.string,
}

ImageButton.defaultProps = {
  alt: '',
  aspectRatio: null,
  className: null,
  crop: null,
  customImage: false,
  shape: 'rounded',
  srText: null,
}

export default ImageButton
