// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Icon from 'components/ui/icon'

/** Icon for use in <Accordion expandIcon={}>. */
const ExpandIcon = ({ title, ...other }) => (
  <Icon {...other} iconName="chevron_down" title={title} />
)

ExpandIcon.propTypes = {
  /** props applied to <Icon> node */
  className: PropTypes.string,
  /** a11y enhancement */
  title: PropTypes.string,
}

ExpandIcon.defaultProps = {
  className: 'fs-5 text-black-3',
  title: 'Toggle details',
}

export default ExpandIcon
