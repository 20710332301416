import React from 'react'
import cx from 'classnames'

const ButtonLink = ({
  children,
  className,
  disabled,
  onClick,
  size,
  text,
  variant = 'solid',
}) => {
  const variants = {
    neutral: 'bg-gallery border-[0.063rem] border-gray-2',
    solid: 'p-3 text-white bg-smart-blue inline-block h-14',
  }

  return (
    <button
      className={cx(
        `font-medium leading-6 text-sm underline hover:text-smart-blue`,
        { '': disabled },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default ButtonLink
