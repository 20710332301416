// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

export const FormDetail = ({ children, label, ...other }) => (
  <div {...other} className={cx('fw-semi-bold', other.className)}>
    <dt className="[ d-sm-inline ] [ fs-1 text-black-3 text-uppercase ]">
      {label}:
    </dt>
    <dd className="[ d-sm-inline ] [ fs-2 m-0 mt-1 text-capitalize | m-sm-0 ml-sm-1 ]">
      {children}
    </dd>
  </div>
)

FormDetail.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}
