import React from 'react'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import { Paper, Tabs, Tab } from '@material-ui/core'
import PropTypes from 'prop-types'
import v from 'voca'

// WeSpire
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  discussions: {
    defaultMessage: 'Discussions',
    id: 'groupSubnav.discussions',
  },
  eventsAndActivities: {
    defaultMessage: 'Events & Activities',
    id: 'groupSubnav.eventsAndActivities',
  },
  impact: {
    defaultMessage: 'Impact',
    description: 'Details on what has been accomplished for a Group',
    id: 'groupSubnav.impact',
  },
  members: {
    defaultMessage: 'Members',
    description: 'People who are part of a Group',
    id: 'groupSubnav.members',
  },
  photosAndVideos: {
    defaultMessage: 'Photos & Videos',
    id: 'groupSubnav.photosAndVideos',
  },
  resources: {
    defaultMessage: 'Resources',
    id: 'groupSubnav.resources',
  },
})

const { formatMessage } = intl

function getTabs({
  enableNewsfeedColumnInGroupView = null,
  groupAnnouncementsEnabled = null,
  showDiscussions = null,
  showImpact = null,
  showNewsfeed = null,
  showPhotosAndVideos = null,
  showResources = null,
}) {
  const defineNewsfeedAndAnnouncementsTabText = () => {
    if (!groupAnnouncementsEnabled && !enableNewsfeedColumnInGroupView) {
      return ''
    } else if (groupAnnouncementsEnabled && enableNewsfeedColumnInGroupView) {
      return sharedTranslations.newsfeedAndAnnouncements
    } else if (!enableNewsfeedColumnInGroupView) {
      return sharedTranslations.announcements
    }
    return sharedTranslations.newsfeed
  }

  let tabArray = [
    {
      id: 'events_and_activities',
      name: formatMessage(messages.eventsAndActivities),
      visible: true,
    },
    {
      id: 'newsfeed',
      name: defineNewsfeedAndAnnouncementsTabText(),
      visible: showNewsfeed,
    },
    {
      id: 'discussions',
      name: formatMessage(messages.discussions),
      visible: showDiscussions,
    },
    {
      id: 'photos_and_videos',
      name: formatMessage(messages.photosAndVideos),
      visible: showPhotosAndVideos,
    },
    {
      id: 'resources',
      name: formatMessage(messages.resources),
      visible: showResources,
    },
    {
      id: 'members',
      name: formatMessage(messages.members),
      visible: true,
    },
    {
      id: 'impact',
      name: formatMessage(messages.impact),
      visible: showImpact,
    },
  ]

  if (!groupAnnouncementsEnabled && !enableNewsfeedColumnInGroupView) {
    tabArray[tabArray.findIndex((tab) => tab.id === 'newsfeed')].visible = false
  }

  return tabArray
}

const filterTabs = (tabs) => {
  return tabs.filter((tab) => tab.visible)
}

const GroupSubnav = ({
  className,
  currentUserIsMember,
  enableNewsfeedColumnInGroupView,
  groupAnnouncementsEnabled,
  group,
  history,
  showDiscussions,
  isChapter,
  isMemberOfParentGroup,
  showImpact,
  showNewsfeed,
  showPhotosAndVideos,
  showResources,
  subpage,
}) => {
  const tabs = getTabs({
    currentUserIsMember,
    enableNewsfeedColumnInGroupView,
    groupAnnouncementsEnabled,
    isChapter,
    isMemberOfParentGroup,
    showDiscussions,
    showImpact,
    showNewsfeed,
    showPhotosAndVideos,
    showResources,
  })

  const filteredTabs = filterTabs(tabs)

  const getSelectedTabSlug = (tabs) => {
    if (subpage) {
      // Guard against the initial tab from the URL being wrong.
      // In that case, we set our tab to the first channel and call
      // handleTabChange to update the URL.
      // Also, return slug version of the url if the user provides a valid slug
      if (tabs.some((tab) => tab.id === subpage)) {
        return subpage
      }
    }
    return tabs[0].id
  }

  const handleTabChange = (_event, tabSlug) => {
    let subpath = group.isChapter
      ? `${group.parentGroup.slug}/chapters/${group.slug}`
      : `${group.slug}`

    if (tabSlug !== subpage) {
      history.push({
        pathname: `/groups/${subpath}/${tabSlug}`,
        search: history.location.search,
      })
    }
  }

  return (
    <Stack as="section" className={className} space={3}>
      <Paper square>
        <Tabs
          data-test="group-subnav"
          onChange={handleTabChange}
          scrollButtons="on"
          textColor="secondary"
          value={getSelectedTabSlug(tabs)}
          variant="scrollable"
        >
          {filteredTabs.map((tab) => {
            const dataTest = `${v.kebabCase(tab.id)}-tab`

            return (
              <Tab
                classes={{
                  root: cx(
                    'fs-3 mnw-0 px-4 py-3 text-transform-none col-auto border-bottom mxw-none',
                    { 'col-md-3': tabs.length < 5 }
                  ),
                  wrapper: 'flex-row',
                }}
                data-test={dataTest}
                key={tab.id}
                label={tab.name}
                value={tab.id}
              />
            )
          })}
        </Tabs>
      </Paper>
    </Stack>
  )
}

GroupSubnav.propTypes = {
  className: PropTypes.string,
  currentUserIsMember: PropTypes.bool,
  enableNewsfeedColumnInGroupView: PropTypes.bool,
  group: PropTypes.shape({
    isChapter: PropTypes.bool,
    parentGroup: PropTypes.shape({
      slug: PropTypes.string,
    }),
    slug: PropTypes.string,
  }).isRequired,
  groupAnnouncementsEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  isChapter: PropTypes.bool,
  isMemberOfParentGroup: PropTypes.bool.isRequired,
  showDiscussions: PropTypes.bool.isRequired,
  showImpact: PropTypes.bool.isRequired,
  showNewsfeed: PropTypes.bool.isRequired,
  showPhotosAndVideos: PropTypes.bool.isRequired,
  showResources: PropTypes.bool.isRequired,
  subpage: PropTypes.string,
}

GroupSubnav.defaultProps = {
  className: null,
  currentUserIsMember: false,
  enableNewsfeedColumnInGroupView: false,
  isChapter: false,
  subpage: 'events_and_activities',
}

const tabs = getTabs({})

export { GroupSubnav, tabs }
