import React from 'react'
import { List, Paper } from '@material-ui/core'

// WeSpire
import { BroadcastListItem } from './components/broadcast_list_item'
import { BROADCASTSQUERY } from './utilities/queries'
import CenteredContent from 'components/application/centered_content'
import { monthYear } from 'utilities/date_formatter'
import { DocumentTitle } from 'components/shared/document_title'
import { groupByMonth } from 'utilities/group'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { messages } from './utilities/messages'
import { PageQuery } from 'components/queries/page_query'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const Broadcasts = () => {
  return (
    <DocumentTitle title={sharedTranslations.broadcasts}>
      {maybeDisplayUserTour('broadcastsIndex')}
      <CenteredContent className="my-5">
        <PageQuery query={BROADCASTSQUERY}>
          {({ data: { broadcasts } }) => {
            const groupedBroadcasts = groupByMonth(broadcasts, 'publishedAt')
            return (
              <>
                <Heading data-test="heading" level={1}>
                  {sharedTranslations.broadcasts}
                </Heading>
                {broadcasts.length > 0 ? (
                  <>
                    {groupedBroadcasts.map((broadcasts) => (
                      <Stack
                        as="section"
                        className="mt-5"
                        data-test="month-year-divided-section"
                        key={broadcasts.year + broadcasts.month}
                        space={3}
                      >
                        <div className="with-separator with-separator--centered">
                          <Heading
                            className="text-center ws-nowrap"
                            level={2}
                            variant={2}
                          >
                            {monthYear(broadcasts.items[0].publishedAt)}
                          </Heading>
                        </div>
                        <Stack as={Paper} space={3}>
                          <List
                            className="p-0"
                            key={broadcasts.year + broadcasts.month}
                          >
                            {broadcasts.items.map((broadcast) => (
                              <BroadcastListItem
                                broadcast={broadcast}
                                className="p-3"
                                key={broadcast.id}
                                urlPath={`blogs/${broadcast.id}`}
                              />
                            ))}
                          </List>
                        </Stack>
                      </Stack>
                    ))}
                  </>
                ) : (
                  <p>{formatMessage(messages.emptyState)}</p>
                )}
              </>
            )
          }}
        </PageQuery>
      </CenteredContent>
    </DocumentTitle>
  )
}

export { Broadcasts }
