// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/**
 * Our version of a <dt> + <dd> element, for displaying a term and its value or
 * definition. Used frequently in our forms to display relevant information
 * about the resource being created in the form and in the descriptions of
 * Activity pages like <IdeaBoard>.
 */
const DescriptionListItem = ({
  children,
  emphasizeDetail,
  label,
  ...other
}) => (
  <div
    data-test="description-list-item"
    {...other}
    className={cx('fs-2 fw-semi-bold text-sentence', other.className)}
  >
    <dt
      className={cx('[ d-sm-inline ] [ text-black-3 ]', {
        'fw-semi-bold': !emphasizeDetail,
      })}
    >
      {label}:
    </dt>
    <dd
      className={cx('[ d-sm-inline ] [ m-0 mt-1 | m-sm-0 ml-sm-1 ]', {
        'fw-semi-bold': emphasizeDetail,
      })}
    >
      {children}
    </dd>
  </div>
)

DescriptionListItem.propTypes = {
  /** Content to be rendered in the dd */
  children: PropTypes.node.isRequired,
  /** if true the <dd> is bold, otherwise the <dt> is bold by default */
  emphasizeDetail: PropTypes.bool,
  /** Text rendered in the dt */
  label: PropTypes.string.isRequired,
}

DescriptionListItem.defaultProps = {
  emphasizeDetail: null,
}

export { DescriptionListItem }
