// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import Link from 'components/shared/link'

const BannerLink = ({ className, children, to, ...other }) => (
  <Link
    {...other}
    className={cx('text-link text-link--black-1', className)}
    to={to}
  >
    {children}
  </Link>
)

BannerLink.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
}

BannerLink.defaultProps = {
  className: '',
}

export default BannerLink
