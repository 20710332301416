/* eslint-disable sort-keys */

/*
 *
 * WeSpire Design System Breakpoints
 * ----------------------------------
 * Copied from variables.scss
 *
 */

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

/*
 *
 * WeSpire Design System Colors
 * ----------------------------------
 * Copied from variables.scss
 *
 */

export const color = {
  'black-1': 'rgba(0, 0, 0, 0.87)',
  'black-2': 'rgba(0, 0, 0, 0.62)',
  'black-3': 'rgba(0, 0, 0, 0.54)',
  'black-4': 'rgba(0, 0, 0, 0.38)',
  'black-5': 'rgba(0, 0, 0, 0.15)',
  'black-6': 'rgba(0, 0, 0, 0.05)',
  blue: '#1772DC',
  'blue-1': '#82B3EC',
  'blue-2': '#E8F1FC',
  green: '#3A863A',
  'green-0': '#2E6B2E',
  'green-1': '#95BE95',
  'green-2': '#EBF3EB',
  'off-white': '#FBFBFB',
  orange: '#FA6400',
  'orange-1': '#FCAB75',
  'orange-2': '#FFF0E6',
  red: '#ED0D0D',
  'red-1': '#F57C7C',
  'red-2': '#FDE7E7',
  white: 'rgba(255, 255, 255, 1)',
  yellow: '#FFDB80',
  'yellow-1': '#FFECBA',
  'yellow-2': '#FFFBF2',
}

/*
 *
 * WeSpire Design System Duration
 * This is for durations that are non-animations(for that Mui should be used)
 *
 */

export const borderRadius = '0.5rem'

export const duration = {
  long: 4000,
  short: 2000,
}

export const fontSize = {
  1: '0.75rem', // 12px
  2: '0.875rem', // 14px
  3: '1rem', // 16px
  4: '1.1875rem', // 19px (equivalent to AA "large" text, when bold)
  5: '1.5rem', // 24px
  6: '2rem', // 32px
  7: '3rem', // 48px
}

export const lineHeight = {
  xs: 1,
  sm: 1.25,
  md: 1.5,
  lg: 1.75,
  xl: 2,
}

export const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

export const spacing = {
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '1rem',
  4: '1.5rem',
  5: '2rem',
  6: '3rem',
  7: '4rem',
  auto: ' auto',
}

export const scrollDurations = {
  EXPAND: 200,
  FLASH_HIGHLIGHT: 2000, // defined in .flash-highlight class
  OFFSET: -65, // To account for fixed nav bar
  SCROLL_DELAY: 1000,
}
