import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import MgmtPanelPage from 'setup/application/page'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { GroupChapterSelector } from 'components/setup/groups_management/chapter-selector'

import GroupAnnouncements from './group_announcements'
import { GroupActivities } from './activities'
import { GroupChapters } from './chapters'
import { GroupMembers } from './members'
import { GroupResources } from './resources'
import { groupOrChapterLabel } from 'components/setup/groups_management/utils'

const { formatMessage } = intl

const messages = defineMessages({
  backTo: {
    defaultMessage: 'Back to {type} Summary',
    id: 'groupTabPage.backTo',
  },
})

const QUERY = gql`
  query groupTabQuery($groupId: ID!) {
    group(id: $groupId) {
      id
      isChapter
      name
      slug
    }
  }
`

const titleMessages = defineMessages({
  activitiesTitle: {
    defaultMessage: 'Activities - { groupName }',
    id: 'groupTabTitles.activitiesTitle',
  },
  announcementsTitle: {
    defaultMessage: 'Announcements - { groupName }',
    id: 'groupTabTitles.announcementsTitle',
  },
  chaptersTitle: {
    defaultMessage: 'Chapters - { groupName }',
    id: 'groupTabTitles.chaptersTitle',
  },
  membersTitle: {
    defaultMessage: 'Members - { groupName }',
    id: 'groupTabTitles.memberTitle',
  },
  resourcesTitle: {
    defaultMessage: 'Resources & Files - { groupName }',
    id: 'groupTabTitles.resourcesTitle',
  },
})

const tabPages = {
  activities: { Component: GroupActivities },
  announcements: { Component: GroupAnnouncements },
  chapters: { Component: GroupChapters, hideChapterSelector: true },
  members: { Component: GroupMembers },
  resources: { Component: GroupResources },
}

const GroupTabPage = ({ brandId, id: groupId, tab }) => {
  const { data, error, loading } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      groupId: groupId,
    },
  })
  const group = data?.group || null

  if (error) {
    return <MissingResourcePage />
  }

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  const currentTab = tabPages[tab]
  const Component = currentTab.Component

  return (
    group && (
      <MgmtPanelPage
        data-test="group-management-index-heading"
        title={formatMessage(titleMessages[`${tab}Title`], {
          groupName: group.name,
        })}
      >
        {() => (
          <Stack className="p-3" space={0}>
            <Link
              className="d-flex align-items-center mt-2 mb-4 text-black-3 fw-semi-bold"
              to={`/management_panel/groups_management/${groupId}`}
            >
              <Icon className="fs-4 ml-1" iconName="arrow_left" title="Back" />
              <span className="fs-2">
                {formatMessage(messages.backTo, {
                  type: groupOrChapterLabel(group),
                })}
              </span>
            </Link>

            {!tabPages[tab]?.hideChapterSelector && (
              <GroupChapterSelector groupId={groupId} />
            )}

            <Component
              brandId={brandId}
              groupId={groupId}
              groupName={group?.name}
            />
          </Stack>
        )}
      </MgmtPanelPage>
    )
  )
}

GroupTabPage.propTypes = {
  brandId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
}

export default GroupTabPage
