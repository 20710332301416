// Setup
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'

/**
 * Button meant to be used for async actions, i.e. those with loading states.
 * Commonly used in forms or for any button that needs to make a server request.
 * While the button is in the loading state its text content is hidden, the
 * button is disabled in an accessible way (without losing keyboard focus), and
 * a circular loading symbol is displayed.
 */

// eslint-disable-next-line
const DisableButton = forwardRef(
  (
    { as: Component, children, disabled, isLoading, textCapitalized, ...props },
    ref
  ) => (
    <Component
      {...props}
      className={cx('position-relative', props.className, {
        'cursor-not-allowed pointer-events-none': isLoading,
      })}
      disabled={disabled}
      ref={ref}
    >
      <span
        className={cx(
          { invisible: isLoading },
          { 'text-capitalize': textCapitalized }
        )}
      >
        {children}
      </span>
      {isLoading && (
        <span
          className="d-flex align-items-center justify-content-center | absolute-top-left h-100 w-100"
          data-test="disable-button-loader"
        >
          <CircularProgress className="text-inherit-color" size={20} />
        </span>
      )}
    </Component>
  )
)

DisableButton.propTypes = {
  /** render the `<Button>` as a custom component type - use with caution! Likely the only times you would want to do this is if you are using our custom Button component */
  as: PropTypes.any,
  /** render button content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** disable the button */
  disabled: PropTypes.bool,
  /** indicate that the button is loading to users */
  isLoading: PropTypes.bool,
  /** capitalize all letters */
  textCapitalized: PropTypes.bool,
}

DisableButton.defaultProps = {
  as: Button,
  className: null,
  disabled: false,
  isLoading: false,
  textCapitalized: false,
}

export { DisableButton }
