// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

// WeSpire
import ScreenReader from 'components/ui/screen_reader_text'
import { donationHistoryMessages } from 'components/donation_history/utilities/messages'

/**
 * Displays the amount of a particular donation stat.
 */
export const DonationHistoryStatAmount = ({ amount, size }) => {
  const Component = {
    lg: Fragment,
    sm: 'strong',
  }[size]

  return amount || amount === 0 ? (
    <Component>
      <FormattedNumber currency="USD" style="currency" value={amount} />
    </Component>
  ) : (
    <Fragment>
      <span aria-hidden>—</span>
      <ScreenReader text={donationHistoryMessages.loadFailed} />
    </Fragment>
  )
}

DonationHistoryStatAmount.propTypes = {
  /** Amount to display */
  amount: PropTypes.number.isRequired,
  /** Size for the statistic. Small size gets <strong> wrapper. */
  size: PropTypes.oneOf(['sm', 'lg']).isRequired,
}
