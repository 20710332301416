// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Button from '@material-ui/core/Button'

const ChannelManagerListDefaultActions = ({
  newChannelPath,
  onChangeLayout,
}) => (
  <div>
    <Button color="primary" href={newChannelPath}>
      New Channel
    </Button>
    <Button
      className="ml-2"
      color="primary"
      onClick={onChangeLayout}
      variant="contained"
    >
      Change Layout
    </Button>
  </div>
)

ChannelManagerListDefaultActions.propTypes = {
  newChannelPath: PropTypes.string.isRequired,
  onChangeLayout: PropTypes.func.isRequired,
}

export default ChannelManagerListDefaultActions
