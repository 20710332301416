// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { Browser } from 'react-kawaii'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { color } from 'styles/variables'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'

/** A component informing the user that they were unable to find what they were looking for. Designed to be rendered as a full page beneath a header. */
export const MissingResourcePage = ({
  browserProps,
  children,
  'data-test': dataTest,
  resourceName,
  title,
  titleProps,
  ...other
}) => {
  const { formatMessage } = intl

  const messages = defineMessages({
    errorHeading: {
      defaultMessage: "We're Sorry",
      description: 'Heading of source not found error page.',
      id: 'missingResourcePage.errorHeading',
    },
    genericResource: {
      defaultMessage: 'resource',
      description: 'Generic resource name when no specific name is provided.',
      id: 'missingResourcePage.genericResource',
    },
    resourceNotFound: {
      defaultMessage:
        'We could not find the {resourceName} that you were looking for.',
      description:
        'Error message when we fail to load a resource for the page.',
      id: 'missingResourcePage.resourceNotFound',
    },
  })

  resourceName = resourceName || formatMessage(messages.genericResource)
  title = title || formatMessage(messages.errorHeading)

  return (
    <div
      {...other}
      className={cx(
        'content-center content-center--y | px-3 py-5',
        other.className
      )}
      data-test={dataTest}
    >
      <Heading
        {...titleProps}
        className={cx('text-center', titleProps.className)}
        level={1}
      >
        {title}
      </Heading>
      {children ? (
        children
      ) : (
        <p className="mxw-measure text-center">
          {formatMessage(messages.resourceNotFound, { resourceName })}
        </p>
      )}
      <Browser
        {...browserProps}
        aria-hidden
        className="pt-5"
        color={color['blue-1']}
        mood="shocked"
        size={200}
      />
    </div>
  )
}

MissingResourcePage.propTypes = {
  /** Additional props to pass to the react-kawaii browser node */
  browserProps: PropTypes.object,
  /** The DOM elements containing a message and any other content for a specifc
   * instance of this page. */
  children: PropTypes.node,
  'data-test': PropTypes.string,
  /** The name of the missing resource, rendered inside the error message */
  resourceName: PropTypes.string,
  /** The content of the header element of the page */
  title: PropTypes.string,
  /** Additional props to pass to the title node */
  titleProps: PropTypes.object,
}

MissingResourcePage.defaultProps = {
  browserProps: {},
  children: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'missing-resource-page',
  resourceName: null,
  title: null,
  titleProps: {},
}
