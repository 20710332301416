import React from 'react'
import PropTypes from 'prop-types'

const MenuToggleButton = ({ onDrawerOpen }) => (
  <span
    className="material-symbols-rounded border-[0.06rem] bg-gallery border-grayscale-2 mr-2 px-2 pb-1 pt-2 rounded-full text-2xl w-10"
    onClick={onDrawerOpen}
  >
    menu
  </span>
)

MenuToggleButton.propTypes = {
  onDrawerOpen: PropTypes.func.isRequired,
}

export default MenuToggleButton
