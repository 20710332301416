// Setup
import React from 'react'
import PropTypes from 'prop-types'

const DataItem = ({ className = '', label, value }) => {
  return (
    <div className={`text-black-3 ${className}`}>{`${label}: ${value}`}</div>
  )
}

DataItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

DataItem.defaultProps = {
  className: '',
}

export default DataItem
