// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages, FormattedNumber } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  votesWithCount: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {votes} one {vote} two {votes} few {votes} many {votes} other {votes}}',
    id: 'voteButtonIcon.votesWithCount',
  },
})

/**
 * Thumbs up icon that indicates if the current user has liked it by turning orange and
 * changing the accessible title attribute.
 */
const VoteButtonIcon = ({ isVoted, voteCount, ...other }) => (
  <Icon
    {...other}
    className={cx({ 'text-orange': isVoted }, other.className)}
    iconName="like"
    title={formatMessage(messages.votesWithCount, {
      count: voteCount,
      stringifiedCount: <FormattedNumber value={voteCount} />,
    })}
  />
)

VoteButtonIcon.propTypes = {
  /** boolean determining if likable is currently liked */
  isVoted: PropTypes.bool.isRequired,
  /** total number of likes for likable record */
  voteCount: PropTypes.number.isRequired,
}

export { VoteButtonIcon }
