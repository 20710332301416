// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Formsy from 'formsy-react'
import { Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import {
  customFieldFormsyName,
  getCustomFieldResponsesAttributes,
} from 'components/form/custom_field'
import { ErrorList } from 'components/form/error_list'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { eventsTranslations } from 'components/events/shared_translations'
import { EventWaiverCheckbox } from './event_waiver_checkbox'
import { intl } from 'utilities/localization'
import FormActionBar from 'components/form/action_bar'
import { FormDetail } from 'components/form/form_details/form_detail'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { removeTypename } from 'utilities/we_apollo'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'

// Sub-Components
import { AdditionalFormFields } from './additional_form_fields'
import { eventPath } from 'components/events/routes'
import { EVENT_QUERY } from './event_registration_form'
import { EVENT_REGISTRATION_FRAGMENT } from './fragments'

const JOIN_WAITLIST_QUERY = gql`
  ${EVENT_REGISTRATION_FRAGMENT}

  query registrationInfoForWaitlistForm($id: ID!) {
    participationEvent(id: $id) {
      id

      customFields {
        id
        isRequired
        name
        options
        responseType
      }

      eventRegistration {
        ...EventRegistrationAttributes
      }

      guestsAllowed
      requireAddress
      requirePhoneNumber
      requireWaiver
    }
  }
`

const JOIN_WAITLIST = gql`
  mutation joinShiftWaitlist(
    $address: Address
    $agreeToWaiver: Boolean
    $customFieldResponses: [CustomFieldResponseAttributes!]
    $phoneNumber: String
    $shiftId: ID!
  ) {
    joinShiftWaitlist(
      address: $address
      agreeToWaiver: $agreeToWaiver
      customFieldResponses: $customFieldResponses
      phoneNumber: $phoneNumber
      shiftId: $shiftId
    ) {
      errors
    }
  }
`

const messages = defineMessages({
  errorDetails: {
    defaultMessage: 'the waitlist form',
    id: 'joinWaitlistFormComponent.errorDetails',
  },
  heading: {
    defaultMessage: 'your waitlist entry',
    id: 'joinWaitlistFormComponent.heading',
  },
  operation: {
    defaultMessage: 'join the waitlist for <bold>{eventRole}</bold>',
    id: 'joinWaitlistFormComponent.operation',
  },
  selectedRole: {
    defaultMessage: 'selected Role',
    id: 'joinWaitlistFormComponent.selectedRole',
  },
  submit: {
    defaultMessage: 'join waitlist',
    id: 'joinWaitlistFormComponent.submit',
  },
  successfullyAdded: {
    defaultMessage:
      'You have been successfully added to the waitlist for <bold>{eventRole}</bold>.',
    id: 'joinWaitlistFormComponent.successfullyAdded',
  },
})

export const JoinWaitlistForm = ({ eventId, shiftFunction, shiftId }) => {
  const { formatMessage } = intl
  const history = useHistory()

  const [formSetup, setFormSetup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleJoinWaitlist = (model, eventId) => {
    setIsSubmitting(true)

    client
      .mutate({
        awaitRefetchQueries: true, // Don't redirect until new data is ready.
        mutation: JOIN_WAITLIST,
        refetchQueries: [
          // Refresh index page so we can display waitlisted status
          { query: EVENT_QUERY, variables: { id: eventId } },
          // Refetch our query to get updated EventRegistration
          {
            query: JOIN_WAITLIST_QUERY,
            variables: { id: eventId },
          },
        ],
        variables: {
          address: model.address ? model.address.address : null,
          agreeToWaiver: model.agreeToWaiver,
          customFieldResponses: getCustomFieldResponsesAttributes(model),
          phoneNumber: model.telephone,
          shiftId: shiftId,
        },
      })
      .then(
        ({
          data: {
            joinShiftWaitlist: { errors },
          },
        }) => {
          setIsSubmitting(false)
          if (errors) {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          } else {
            displayBanner({
              content: (
                <>
                  {formatMessage(messages.successfullyAdded, {
                    bold: (str) => <b>{str}</b>,
                    eventRole: shiftFunction,
                  })}
                </>
              ),
              variant: 'success',
            })
            history.push(eventPath(eventId))
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: (
            <>
              {formatMessage(messages.operation, {
                bold: (str) => <b>{str}</b>,
                eventRole: shiftFunction,
              })}
            </>
          ),
        })
      })
  }

  const setupForm = ({ form, registration }) => {
    if (form && registration && !formSetup) {
      let existingData = {
        address: {
          address: registration.address
            ? removeTypename(registration.address)
            : null,
          addressLabel: '', // Not shown but needed to prevent JS errors.
        },
        agreeToWaiver: registration.agreeToWaiver,
        telephone: registration.phoneNumber,
      }
      registration.customFieldResponses.forEach(
        ({ customFieldId, name, response }) => {
          existingData[customFieldFormsyName(customFieldId, name)] = response
        }
      )
      form.reset(existingData)
      // We need to prevent the form from getting setup a second time as that
      // would cause the changed input values to get reset.
      // This happens due to us updating isSubmitting causing another render.
      setFormSetup(true)
    }
  }

  return (
    <Paper className="mt-4 pb-5 pt-4 px-3 | px-sm-4">
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={formatMessage(messages.errorDetails)}
          />
        }
        loader={<CenteredPaddedLoadingIndicator />}
        query={JOIN_WAITLIST_QUERY}
        variables={{ id: eventId }}
      >
        {({ data: { participationEvent } }) => {
          const event = participationEvent

          const baseText = eventsTranslations.acceptWaiver
          const guestText = eventsTranslations.acceptGuestsWaiver
          const waiverHelperText = event.guestsAllowed
            ? `${baseText} ${guestText}`
            : baseText

          return (
            <>
              <h3 className="text-sentence">
                {formatMessage(messages.heading)}
              </h3>
              <Formsy
                noValidate
                onValidSubmit={(model) => handleJoinWaitlist(model, event.id)}
                ref={(form) =>
                  setupForm({
                    form,
                    registration: event.eventRegistration,
                  })
                }
              >
                <Stack space={5}>
                  <FormDetail
                    data-test="selected-role"
                    label={formatMessage(messages.selectedRole)}
                  >
                    {shiftFunction}
                  </FormDetail>

                  <AdditionalFormFields
                    address={event.requireAddress}
                    customFields={event.customFields}
                    telephone={event.requirePhoneNumber}
                  />

                  {event.requireWaiver && (
                    <EventWaiverCheckbox
                      event={event}
                      waiverHelperText={waiverHelperText}
                    />
                  )}

                  <FormActionBar
                    disabled={false}
                    isSubmitting={isSubmitting}
                    submitText={formatMessage(messages.submit)}
                  />
                </Stack>
              </Formsy>
            </>
          )
        }}
      </WeQuery>
    </Paper>
  )
}

JoinWaitlistForm.propTypes = {
  eventId: PropTypes.string.isRequired,
  shiftFunction: PropTypes.string.isRequired,
  shiftId: PropTypes.string.isRequired,
}
