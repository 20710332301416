// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import Icon from 'components/ui/icon'

/** Circle outline variation of icon. Extends <Icon>. */
const CircleIcon = ({ color, iconName, iconProps, title, ...other }) => (
  <span
    {...other}
    className={cx(
      `d-inline-flex | border border-${color} rounded-circle text-${color}`,
      other.className
    )}
  >
    <Icon {...iconProps} iconName={iconName} title={title} />
  </span>
)

CircleIcon.propTypes = {
  /** colors both icon and circle outline; global wespire colors are valid */
  color: PropTypes.string,
  /** key used to choose icon */
  iconName: PropTypes.string.isRequired,
  /** props applied to the <Icon> node */
  iconProps: PropTypes.object,
  /** a11y enhancement, provide empty string if icon is decorative */
  title: PropTypes.string.isRequired,
}

CircleIcon.defaultProps = {
  color: 'black-3',
  iconProps: {},
}

export default CircleIcon
