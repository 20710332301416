// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { Button, Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import cx from 'classnames'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import Divider from 'components/ui/divider'
import {
  getDonationHistoryLink,
  getCarbonFootprintLink,
  getPrimaryNavLinks,
  getSecondaryNavLinks,
  getVolunteerHistoryLink,
  getPointsDashboardLink,
  getUserProfileLink,
} from 'components/shared/navigator/utils'
import PrimaryItem from './primary_item'
import SecondaryItem from './secondary_item'
import { SignOutButton } from 'components/application/sign_out_button'
import { useIsPointsDashboardEnabled } from 'components/points_dashboard/utils'
import WeQuery from 'components/application/we_query'

export const userSideNavGql = gql`
  query userSideNav {
    carbonFootprintEnabled
    ergsEnabled
    givingEnabled
    ourImpactEnabled
    volunteerHistoryEnabled
  }
`

const SideNav = ({ closeSideNav, isOpen, linkPaths, location }) => {
  const { isPointsDashboardEnabled } = useIsPointsDashboardEnabled()

  return (
    <WeQuery
      error={
        <p className="fs-2 fw-semi-bold mt-0 mt-sm-4 text-black-3">
          We are unable to load the navigation at this time.
        </p>
      }
      loader={<CenteredPaddedLoadingIndicator padding="py-1" />}
      query={userSideNavGql}
    >
      {({
        data: {
          carbonFootprintEnabled,
          ergsEnabled,
          givingEnabled,
          ourImpactEnabled,
          volunteerHistoryEnabled,
        },
      }) => {
        const currentUrl = location.pathname
        const primaryNav = getPrimaryNavLinks(
          ergsEnabled,
          linkPaths,
          ourImpactEnabled
        )

        return (
          <Drawer
            data-test="side-nav"
            ModalProps={{
              BackdropProps: {
                classes: {
                  root: 'op-60',
                },
              },
            }}
            onClose={closeSideNav}
            open={isOpen}
          >
            <div className="side-nav | py-2">
              <Divider className="mt-6" />
              <List component="nav">
                {primaryNav.map((link) => (
                  <Fragment key={link.id}>
                    {link.shouldDisplay && (
                      <PrimaryItem
                        isActive={currentUrl.includes(link.href)}
                        link={link}
                        onClick={closeSideNav}
                      />
                    )}
                  </Fragment>
                ))}
              </List>
              <Divider />
              <List component="nav" dense>
                {carbonFootprintEnabled && (
                  <SecondaryItem
                    isActive={currentUrl.includes(getCarbonFootprintLink.href)}
                    link={getCarbonFootprintLink}
                    onClick={closeSideNav}
                  />
                )}
                {givingEnabled && (
                  <SecondaryItem
                    isActive={currentUrl.includes(getDonationHistoryLink.href)}
                    link={getDonationHistoryLink}
                    onClick={closeSideNav}
                  />
                )}
                {volunteerHistoryEnabled && (
                  <SecondaryItem
                    isActive={currentUrl.includes(getVolunteerHistoryLink.href)}
                    link={getVolunteerHistoryLink}
                    onClick={closeSideNav}
                  />
                )}
                {isPointsDashboardEnabled && (
                  <SecondaryItem
                    isActive={currentUrl.includes(getPointsDashboardLink.href)}
                    link={getPointsDashboardLink}
                    onClick={closeSideNav}
                  />
                )}
                {/** This is a temporary setup for the link to My Profile until the
              old user profile is retired. With the standard SecondaryItem
              component we use client side routing to direct users to pages
              however this bypasses the checks we have on the user controller to
              ensure that users without access to the new user profile do not
              land on the new page. Instead, for the time being with this code,
              we force our link to use traditional server-side navigation, so it
              hits the users controller that contains the new user profile
              redirect logic. **/}
                <ListItem
                  component={Button}
                  href={getUserProfileLink.href}
                  onClick={closeSideNav}
                >
                  <ListItemText
                    primary={
                      <span
                        className={cx('fs-1 text-capitalize text-black-2', {
                          'text-blue': currentUrl.includes(
                            getUserProfileLink.href
                          ),
                        })}
                      >
                        {getUserProfileLink.id}
                      </span>
                    }
                  />
                </ListItem>
                {getSecondaryNavLinks(linkPaths).map((link) => (
                  <SecondaryItem
                    isActive={currentUrl.includes(link.href)}
                    key={link.id}
                    link={link}
                    onClick={closeSideNav}
                  />
                ))}
                <SignOutButton
                  as={ListItem}
                  className="fs-1 fw-light text-black-3 text-transform-none"
                />
              </List>
            </div>
          </Drawer>
        )
      }}
    </WeQuery>
  )
}

SideNav.propTypes = {
  closeSideNav: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  linkPaths: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export const undecorated = SideNav

export default withRouter(SideNav)
