const isHtml = (string) => {
  if (typeof string === 'string') {
    return /<[a-z/][\s\S]*?>/i.test(string)
  } else {
    return false
  }
}

const isInputValueEmpty = (string) => {
  if (string) {
    return string.replace(/(<([^>]+)>)/gi, '').trim() === ''
  } else {
    return true
  }
}

export { isInputValueEmpty, isHtml }
