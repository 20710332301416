// Vendor
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import { DocumentTitle } from 'components/shared/document_title'
import { FacepileSizes } from 'components/application/avatar'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { GroupChapterSelector } from './group_chapter_selector'
import { GroupDetails } from './group_details'
import { GroupSubnav, tabs } from './group_subnav'
import { GroupSubpage } from './group_subpage'
import { GroupModal } from './group_modal'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'
import { sharedMessages } from 'components/shared/translations'
import { getGroupUrl } from './utils'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'

const NON_MEMBERS_ALLOWED_TABS = [
  'members',
  'events_and_activities',
  'resources',
]

const GET_BRAND_CONFIG_AND_FLAGS = gql`
  query brandConfigQuery {
    brandConfig {
      id
      friendlyErgNameWithDefault
      friendlyErgNameSingularWithDefault
    }
    ergGroupAnnouncementsColumnEnabled
    ergImpactTabEnabled
    ergNewsfeedTabEnabled
    ergDiscussionsTabEnabled
    ergPhotosAndVideosTabEnabled
    ergResourcesTabEnabled
  }
`

const GET_NEWSFEED_AND_ANNOUNCEMENTS_QUERY = gql`
  query getNewsfeedAndAnnouncementsQuery($id: ID!, $limit: Int!) {
    group(id: $id) {
      currentUserIsMember
      currentUserMembershipVisible
      description
      groupChapters {
        id
        name
        slug
      }
      heroImage
      id
      isChapter
      logoImage
      name
      members(first: $limit, noHiddenMembers: true) {
        nodes {
          avatarUrl
          id
        }
      }
      membersCount
      parentId
      slug
      userGroupChapter {
        id
        slug
      }
      ... on GroupChapter {
        parentGroup {
          currentUserIsMember
          id
          name
          groupChapters {
            id
            name
            slug
          }
          userGroupChapter {
            id
            slug
          }
          slug
        }
      }
    }
  }
`

const { formatMessage } = intl

const Group = ({ history, match }) => {
  const [activitySelected, setActivitySelected] = useState(null)
  const [isOpen, showModal] = useState(false)
  const { enableNewsfeedColumnInGroupView } = useFlags()
  const {
    data: {
      brandConfig: {
        friendlyErgNameWithDefault,
        friendlyErgNameSingularWithDefault,
      } = {},
      ergGroupAnnouncementsColumnEnabled,
      ergImpactTabEnabled,
      ergNewsfeedTabEnabled,
      ergDiscussionsTabEnabled,
      ergPhotosAndVideosTabEnabled,
      ergResourcesTabEnabled,
    } = {},
    loading: brandConfigLoading,
  } = useQuery(GET_BRAND_CONFIG_AND_FLAGS)

  const { data: { group } = {}, loading } = useQuery(
    GET_NEWSFEED_AND_ANNOUNCEMENTS_QUERY,
    {
      variables: {
        id: match.params.id,
        limit: FacepileSizes.large,
      },
    }
  )

  if (loading || brandConfigLoading) {
    return <CenteredPaddedLoadingIndicator />
  }

  if (group && friendlyErgNameWithDefault) {
    const subpage = match.params.sub_page
    const groupName = group.name
    const currentTab = tabs.find((tab) => tab.id === subpage)
    const tabName = currentTab ? currentTab.name : tabs[0].name
    const documentTitle = `${tabName} • ${groupName}`
    let currentUserChapter = { id: '', slug: '' }

    const isMemberOfParentGroup = !!(
      (!group.isChapter && group.currentUserIsMember) ||
      group.parentGroup?.currentUserIsMember
    )

    if (group?.parentGroup?.userGroupChapter || group.userGroupChapter)
      currentUserChapter = group.isChapter
        ? group.parentGroup.userGroupChapter
        : group.userGroupChapter

    const currentUserGroup = group.isChapter
      ? group.parentGroup.name
      : group.name

    const links = [{ href: '/groups', text: friendlyErgNameWithDefault }]

    if (group.isChapter) {
      links.push({
        href: getGroupUrl({ groupIdentifier: group.parentGroup.slug }),
        text: group.parentGroup.name,
      })
    }

    links.push({
      href: history.location.pathname,
      text: group.name,
    })

    return (
      <GraphQLFeatureFlag featureName="ergs">
        <DocumentTitle title={documentTitle}>
          <>
            <NavBreadcrumb links={links} />
            <GroupModal
              activity={activitySelected}
              group={group.isChapter ? group.parentGroup : group}
              initialGroup={group.isChapter ? group.id : ''}
              isOpen={isOpen}
              setActivity={setActivitySelected}
              showModal={showModal}
            />
            <GroupDetails group={group} showModal={showModal} />
            <CenteredContent className="px-3 px-sm-0">
              {(!!group.groupChapters.length || group.isChapter) && (
                <GroupChapterSelector
                  chapters={
                    group.isChapter
                      ? group.parentGroup.groupChapters
                      : group.groupChapters
                  }
                  className="[ col-md-5 ] [ mt-5 ]"
                  currentUserChapter={currentUserChapter}
                  currentUserGroup={currentUserGroup}
                />
              )}
              <GroupSubnav
                className="mt-4"
                currentUserIsMember={group.currentUserIsMember}
                enableNewsfeedColumnInGroupView={
                  enableNewsfeedColumnInGroupView
                }
                group={group}
                groupAnnouncementsEnabled={ergGroupAnnouncementsColumnEnabled}
                history={history}
                isChapter={group.isChapter}
                isMemberOfParentGroup={isMemberOfParentGroup}
                showDiscussions={ergDiscussionsTabEnabled}
                showImpact={ergImpactTabEnabled}
                showNewsfeed={ergNewsfeedTabEnabled}
                showPhotosAndVideos={ergPhotosAndVideosTabEnabled}
                showResources={ergResourcesTabEnabled}
                subpage={subpage}
              />
              <GroupSubpage
                currentUserIsMember={group.currentUserIsMember}
                enableNewsfeedColumnInGroupView={
                  enableNewsfeedColumnInGroupView
                }
                groupAnnouncementsEnabled={ergGroupAnnouncementsColumnEnabled}
                groupId={group.id}
                groupName={group.name}
                isChapter={group.isChapter}
                isMemberOfParentGroup={isMemberOfParentGroup}
                nonMembersAllowedPages={NON_MEMBERS_ALLOWED_TABS}
                setActivity={setActivitySelected}
                showModal={showModal}
                subpage={subpage}
              />
            </CenteredContent>
          </>
        </DocumentTitle>
      </GraphQLFeatureFlag>
    )
  } else {
    return (
      <GraphQLFeatureFlag featureName="ergs">
        <DocumentTitle
          title={formatMessage(sharedMessages.resourceNotFound, {
            resource: friendlyErgNameSingularWithDefault,
          })}
        >
          <MissingResourcePage
            resourceName={friendlyErgNameSingularWithDefault}
          />
        </DocumentTitle>
      </GraphQLFeatureFlag>
    )
  }
}

Group.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sub_page: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export { Group }
