import React from 'react'

const withRouteProps = (Component, props) => {
  const WithRouteProps = (routeProps) => (
    <Component {...routeProps} {...props} />
  )

  return WithRouteProps
}

export default withRouteProps
