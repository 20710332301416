// Setup
import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

// WeSpire
import { actionsCsvData } from 'utilities/csv_data/actions_data'
import { ActionsFilters } from './components/actions_filters'
import ResultManagementDescription from '../utils/results_management_description'
import withNotifications from 'components/helpers/with_notifications'
import ContentHeader from 'components/setup/application/content_header'
import { dateTimeDate } from 'utilities/date_formatter'
import { ExportButton } from '../utils/export_button'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import Layout from 'setup/application/layout'
import { intl } from 'utilities/localization'
import { client } from 'utilities/we_apollo'
import { sharedTranslations } from 'components/shared/translations'
import { ACTIONS_LIST_QUERY } from './utilities/queries'
import { indexMessages as messages } from './utilities/messages'

const { formatMessage } = intl

function filtersReducer(state, action) {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const initialFilters = {
  campaignIds: [],
  channelIds: [],
  statuses: [],
  year: 'all',
}

export function ActionsPageIndex({
  brandId,
  brandName,
  displayNotification,
  notification,
}) {
  const [{ campaignIds, channelIds, statuses, year }, changeFilters] =
    useReducer(filtersReducer, initialFilters)

  const { loading, error, data } = useQuery(ACTIONS_LIST_QUERY, {
    client,
    variables: {
      brandId,
      campaignIds,
      channelIds,
      statuses,
      year: year !== 'all' ? parseInt(year) : null,
    },
  })

  const fileName = `${brandName} ${sharedTranslations.actions} ${dateTimeDate(
    new Date()
  )}`

  const handleFiltersChange = (values) => {
    const { year, channelIds, statuses, campaignIds } = values

    changeFilters({
      payload: {
        campaignIds: campaignIds || [],
        channelIds: channelIds || [],
        statuses: statuses || [],
        year,
      },
      type: 'update',
    })
  }

  if (error) {
    return <MissingResourcePage />
  }

  if (loading && !data) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  const { actions, brandConfig } = data

  const dataDictionaryArray = [
    formatMessage(messages.dataDictionaryA),
    formatMessage(messages.dataDictionaryB),
    formatMessage(messages.dataDictionaryC),
    formatMessage(messages.dataDictionaryD, {
      wordForScore: brandConfig.wordForScorePlural,
    }),
    formatMessage(messages.dataDictionaryE),
    formatMessage(messages.dataDictionaryF),
    formatMessage(messages.dataDictionaryG),
    formatMessage(messages.dataDictionaryH),
    formatMessage(messages.dataDictionaryI),
    formatMessage(messages.dataDictionaryJ),
    formatMessage(messages.dataDictionaryK),
    formatMessage(messages.dataDictionaryL),
    formatMessage(messages.dataDictionaryM),
    formatMessage(messages.dataDictionaryN),
    formatMessage(messages.dataDictionaryO),
    formatMessage(messages.dataDictionaryP),
  ]

  return (
    <Layout
      displayNotification={displayNotification}
      notification={notification}
    >
      <div>
        <ContentHeader text={formatMessage(messages.exportActions)} />
        <Stack className="p-4" space={3}>
          <p className="fs-3 text-black-2 text-left text-uppercase">
            {formatMessage(messages.availableActions, {
              count: actions.length,
            })}
          </p>
          <ResultManagementDescription
            arrayHeaderText={formatMessage(messages.dataDictionary)}
            descriptionsArray={dataDictionaryArray}
            descriptionText={formatMessage(messages.description)}
            instructionsText={formatMessage(messages.instructions)}
          />
          <Stack space={6}>
            <ActionsFilters
              actions={actions}
              campaignIds={campaignIds}
              channelIds={channelIds}
              onFiltersChange={handleFiltersChange}
              statuses={statuses}
              year={year}
            />
            <div className="d-flex flex-row-reverse mt-2">
              <ExportButton
                csvData={actionsCsvData(actions)}
                dataTest="export-actions-button"
                fileName={fileName}
                loading={loading}
              />
            </div>
          </Stack>
        </Stack>
      </div>
    </Layout>
  )
}

ActionsPageIndex.propTypes = {
  brandId: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  displayNotification: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired,
}

export default withNotifications(ActionsPageIndex)
