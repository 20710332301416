export const updateModalAction = 'wespire/SET_MODAL_STATE'

const defaultState = {
  options: {
    open: false,
  },
  refreshCounter: 0,
  renderContent: () => null,
}

// Reducer
const modal = (state = defaultState, action) => {
  switch (action.type) {
    case updateModalAction:
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default modal
