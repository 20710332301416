// Setup
import React from 'react'

// WeSpire
import MgmtPanelPage from 'setup/application/page'
import { RetroactiveMatchRequestList } from './retroactive_match_request_list/index'

const RetroactiveMatchRequests = () => {
  return (
    <MgmtPanelPage
      contentHeaderProps={{
        'data-test': 'retroactive-match-requests-index-title',
      }}
      title='Review Match Requests'
    >
      {() => (
        <RetroactiveMatchRequestList />
      )}
    </MgmtPanelPage>
  )
}

export default RetroactiveMatchRequests
