// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import MgmtPanelPage from 'setup/application/page'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { GroupChapterSelector } from 'components/setup/groups_management/chapter-selector'

import { GroupSummaryNav } from './nav'
import { GroupSummary } from './group_summary'

const { formatMessage } = intl

const messages = defineMessages({
  backTo: {
    defaultMessage: 'Back to all Groups',
    id: 'groupDetails.backTo',
  },
  heading: {
    defaultMessage: 'Manage { groupName }',
    id: 'groupDetails.heading',
  },
})

const QUERY = gql`
  query groupsListQuery($groupId: ID!) {
    currentUser {
      can {
        manageGroups
      }
    }
    group(id: $groupId) {
      id
      name
      isChapter
      parentGroup {
        id
        slug
      }
      slug
    }
  }
`

const GroupsManagement = ({ id: groupId }) => {
  const { data, error, loading } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      groupId: groupId,
    },
  })
  const group = data?.group

  if (error) {
    return <MissingResourcePage />
  }

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  return (
    group && (
      <MgmtPanelPage
        data-test="group-management-index-heading"
        title={formatMessage(messages.heading, {
          groupName: group.name,
        })}
      >
        {() => (
          <Stack className="p-3" space={0}>
            <Link
              className="d-flex align-items-center mt-2 mb-4 text-black-3 fw-semi-bold"
              to="/management_panel/groups_management"
            >
              <Icon className="fs-4 ml-1" iconName="arrow_left" title="Back" />
              <span className="fs-2">{formatMessage(messages.backTo)}</span>
            </Link>

            <GroupChapterSelector groupId={groupId} />

            <GroupSummaryNav group={group} />

            <GroupSummary groupId={groupId} groupName={group?.name} />
          </Stack>
        )}
      </MgmtPanelPage>
    )
  )
}

GroupsManagement.propTypes = {
  id: PropTypes.string.isRequired,
}

const host = window.location.host
const subdomain = host.split('.')[0]

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  user: {
    key: subdomain,
    name: subdomain,
  },
})(GroupsManagement)
