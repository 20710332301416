// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import { activityGalleryFetchLimit } from 'components/shared/activity_gallery'
import { PARTICIPATION_EVENT_ACTIVITY_GALLERY_QUERY } from 'graphql/queries'
import { NewsfeedForm } from 'components/form/newsfeed_form'
import { GET_EVENT_NEWSFEED_ITEMS } from 'components/events/registration/event_news_feed'
import { NewsFeedAnalytics } from 'utilities/analytics'

const CREATE_EVENT_STORY = gql`
  mutation createEventStory($id: ID!, $storyAttributes: StoryAttributes!) {
    createEventStory(id: $id, storyAttributes: $storyAttributes) {
      errors
    }
  }
`

export const EventNewsFeedForm = ({ className, eventId }) => {
  const handleCommentFocus = () => {
    NewsFeedAnalytics.commentBoxFocused(eventId, 'event')
  }

  const handleOpenImage = () => {
    NewsFeedAnalytics.addPhotoClicked(eventId, 'event')
  }

  const handleOpenMentions = () => {
    NewsFeedAnalytics.addTagsClicked(eventId, 'event')
  }

  const refetchQueries = [
    { query: GET_EVENT_NEWSFEED_ITEMS, variables: { id: eventId } },
    {
      query: PARTICIPATION_EVENT_ACTIVITY_GALLERY_QUERY,
      variables: { activityId: eventId, first: activityGalleryFetchLimit },
    },
  ]

  return (
    <NewsfeedForm
      className={className}
      createStoryMutation={CREATE_EVENT_STORY}
      handleCommentFocus={handleCommentFocus}
      handleOpenImage={handleOpenImage}
      handleOpenMentions={handleOpenMentions}
      mutationName="createEventStory"
      refetchQueries={refetchQueries}
      storyableId={eventId}
    />
  )
}

EventNewsFeedForm.propTypes = {
  className: PropTypes.string,
  eventId: PropTypes.string.isRequired,
}

EventNewsFeedForm.defaultProps = {
  className: '',
}
