// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import FormBooleanRadioReveal from 'components/form/boolean_radio_reveal'
import SpecificCharitySelect from 'components/setup/giving_activities/specific_charity_select'

const FormCharityCollectionField = ({ name, value, ...other }) => (
  <FormBooleanRadioReveal
    {...other}
    emptyStates={[{ name: name, value: [] }]}
    label="Charities*"
    radioHideLabel="All Charities"
    radioRevealLabel="Only specific Charities"
    shouldReveal={value.length > 0}
  >
    <SpecificCharitySelect name={name} value={value} />
  </FormBooleanRadioReveal>
)

FormCharityCollectionField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
}

FormCharityCollectionField.defaultProps = {
  name: 'eins',
  value: [],
}

export default FormCharityCollectionField
