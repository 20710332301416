// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

export const ColumnHeader = ({ title, ...other }) => (
  <h2
    {...other}
    className={cx(
      'fs-1 fw-semi-bold lh-sm ls-2 ml-2 text-uppercase',
      other.className
    )}
  >
    {title}
  </h2>
)

ColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
}
