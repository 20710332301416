// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import ChannelManagerList from 'components/setup/channel_manager/list/'
import Layout from 'setup/application/layout'
import withNotifications from 'components/helpers/with_notifications'

const ChannelsIndexPage = ({
  banner,
  brandID,
  channels,
  displayNotification,
  notification,
}) => (
  <Layout displayNotification={displayNotification} notification={notification}>
    <ChannelManagerList
      banner={banner}
      brandID={brandID}
      channels={channels}
      displayNotification={displayNotification}
    />
  </Layout>
)

ChannelsIndexPage.propTypes = {
  banner: PropTypes.shape({
    message: PropTypes.string,
    variant: PropTypes.string,
  }),
  brandID: PropTypes.string.isRequired,
  channels: PropTypes.object.isRequired,
  displayNotification: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired,
}

ChannelsIndexPage.defaultProps = {
  banner: {
    message: null,
    variant: null,
  },
}
export default withNotifications(ChannelsIndexPage)
