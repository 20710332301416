import { LIST_TYPES } from '../constants'

/**
 * Common
 */
export const _moveInArray = (array, from, to) => {
  const newArray = [...array]
  newArray.splice(to, 0, newArray.splice(from, 1)[0])

  return newArray
}

/**
 * API utils
 */
export const channelLayoutParams = (brandID, layoutState) => {
  const allChannels = [
    ...layoutState[LIST_TYPES.published],
    ...layoutState[LIST_TYPES.unpublished],
  ]
  const publishedChannels = layoutState[LIST_TYPES.published].map(
    (channel, index) => ({
      id: channel.id,
      order_number: index,
      published: true,
    })
  )
  const unpublishedChannels = layoutState[LIST_TYPES.unpublished].map(
    (channel, index) => ({
      id: channel.id,
      order_number: publishedChannels.length + index,
      published: false,
    })
  )
  const channels = [...publishedChannels, ...unpublishedChannels]

  const activities = allChannels.map((channel) =>
    channel.activities.map((activity, index) => ({
      channel_id: channel.id,
      id: activity.id,
      order_number: index,
    }))
  )
  const activitiesFlattened = activities.reduce(
    (memo, val) => memo.concat(val),
    []
  )

  return {
    activities: { list: activitiesFlattened },
    brand: {
      channels_attributes: channels,
      id: brandID,
    },
  }
}

/**
 * Activity utils
 */
export const moveActivity =
  (dragIndex, hoverIndex, channelIndex, listId) => (prevChannels) => {
    const channels = { ...prevChannels }
    channels[listId][channelIndex].activities = _moveInArray(
      channels[listId][channelIndex].activities,
      dragIndex,
      hoverIndex
    )

    return channels
  }

export const pushActivity =
  (actSpec, nextChanIndex, nextChanListId) => (prevChannels) => {
    const channels = { ...prevChannels }
    const activity =
      channels[actSpec.listId][actSpec.channelIndex].activities[actSpec.index]
    channels[nextChanListId][nextChanIndex].activities.push(activity)

    return channels
  }

export const removeActivity =
  (activityIndex, channelIndex, ChannelListId) => (prevChannels) => {
    const channels = { ...prevChannels }
    channels[ChannelListId][channelIndex].activities.splice(activityIndex, 1)

    return channels
  }

/**
 * Return a channels array with the activities of the channel with [channelId] updated given the [updateFunction]
 *
 * @param {Number} channelId
 * @param {Array} channels
 * @param {Function} updateFunction
 * @return {Array}
 */
export const updateChannelActivities = (
  channelId,
  channels,
  updateFunction
) => {
  const updateActivitiesForChannel = (channels, channelId) =>
    // For each Channel in a collection...
    channels.map((channel) => {
      // ... if the Channel id matches...
      if (channel.id === channelId) {
        // ...update its Activities with our given update function.
        return {
          activities: updateFunction(channel.activities),
          ...channel,
        }
      }

      // ... otherwise, return the Channel as is.
      return channel
    })

  // Return all the Channel collections with updated Activities.
  return {
    published: updateActivitiesForChannel(channels.published, channelId),
    unpublished: updateActivitiesForChannel(channels.unpublished, channelId),
  }
}

/**
 * Channel utils
 */
export const compositeChannelIndex = (listId, channelIndex) =>
  `${listId}-${channelIndex}`

export const moveChannel =
  (dragIndex, hoverIndex, listId) => (prevChannels) => {
    const channels = { ...prevChannels }
    channels[listId] = _moveInArray(channels[listId], dragIndex, hoverIndex)

    return channels
  }

export const pushChannel = (channel, targetListId) => (prevChannels) => ({
  ...prevChannels,
  [targetListId]: prevChannels[targetListId].concat(channel),
})

export const removeChannel = (index, sourceListId) => (prevChannels) => ({
  ...prevChannels,
  [sourceListId]: prevChannels[sourceListId].filter(
    (_, chIndex) => index !== chIndex
  ),
})

/**
 * Drag and Drop utils
 */
export const draggingOutsideTarget = (
  component,
  clientOffset,
  dragIndex,
  hoverIndex
) => {
  // Determine rectangle on screen
  const hoverBoundingRect = component.getBoundingClientRect()

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top

  // Is dragging vertically but not meeting the middle Y of the target
  const draggingUpwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY
  const draggingDownwards =
    dragIndex > hoverIndex && hoverClientY > hoverMiddleY

  return draggingUpwards || draggingDownwards
}

export const enhanceDnDTarget = (callback) => (props, monitor, component) => {
  const dragIndex = monitor.getItem().index
  const hoverIndex = props.index
  const mousePosition = monitor.getClientOffset()
  const componentInstance = component.decoratedComponentInstance.node

  // Don't replace items with themselves
  if (dragIndex === hoverIndex) {
    return
  }

  if (
    draggingOutsideTarget(
      componentInstance,
      mousePosition,
      dragIndex,
      hoverIndex
    )
  ) {
    return
  }

  callback({ component, dragIndex, hoverIndex, monitor, props })
}
