import gql from 'graphql-tag'

import { ACTIVITY_CARD_FRAGMENT } from 'components/activities/fragments'

export const USERCARBONFOOTPRINTSQUERY = gql`
  query userCarbonFootprintsQuery {
    userCarbonFootprints {
      id
      personalHousingTotal
      personalFoodTotal
      personalGoodsTotal
      personalServicesTotal
      transportTotal
      grandTotal
    }
    currentUser {
      id
      brand {
        id
        contentModules(filter: "carbonfootprint") {
          id
          whyItMatters
        }
      }
    }
  }
`

export const CARBONIMPACAMPAIGNSQUERY = gql`
  query carbonImpactCampaignsQuery {
    carbonImpactCampaigns {
      nodes {
        ...ActivityAttributes
      }
    }
  }
  ${ACTIVITY_CARD_FRAGMENT}
`

export const carbonImpactQuery = gql`
  query carbonImpactQuery($filter: SustainabilitySlug!) {
    currentUser {
      id
      sustainabilityImpacts {
        equivalent
        impactValue
        name
        slug
        units
      }
    }
    brand {
      id
      sustainabilityImpacts {
        equivalent
        impactValue
        name
        slug
        units
        whyItMatters
      }
      totalActionsCompleted(sustainabilitySlug: $filter)
    }
    userImpactStats {
      id
      totalActionsCompleted(sustainabilitySlug: $filter)
    }
  }
`
