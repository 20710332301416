// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// Vendor
import cx from 'classnames'
import { intl } from 'utilities/localization'
import ListItem from '@material-ui/core/ListItem'

// WeSpire
import Link from 'components/shared/link'
import { sharedMessages } from 'components/shared/translations'

const messages = defineMessages({
  spots: {
    defaultMessage:
      '{count} {count, plural, zero {spots} one {spot} two {spots} few {spots} many {spots} other {spots}}',
    id: 'eventRegistrationOpportunity.spots',
  },
})

const EventRegistrationOpportunity = ({
  availableSpotsCount,
  className,
  endsAt,
  eventName,
  eventUrl,
  startsAt,
}) => {
  const showAvailableSpotsCount = availableSpotsCount !== null

  return (
    <ListItem
      className={cx('align-items-start flex-wrap | lh-lg', className)}
      data-test="registration-opportunity"
      disableGutters
    >
      <span className="col-8 col-sm-4 col-md-3 col-lg-2 | fw-bold px-1">
        <time dateTime={startsAt}>{startsAt}</time>
        {` – `}
        <time dateTime={endsAt}>{endsAt}</time>
      </span>
      {showAvailableSpotsCount && (
        <span className="col-4 | px-1 text-right | hide-sm-up">
          {intl.formatMessage(messages.spots, {
            count: availableSpotsCount,
          })}
        </span>
      )}
      <span className="col-12 col-sm-8 col-md-9 col-lg-10 | px-1">
        <Link
          className={cx('text-link', {
            'pr-2': showAvailableSpotsCount,
          })}
          to={eventUrl}
        >
          {eventName}
        </Link>
        {showAvailableSpotsCount && (
          <span className="show-sm-up">
            (
            {intl.formatMessage(sharedMessages.spotsLeftBolded, {
              bold: (str) => <b>{str}</b>,
              count: availableSpotsCount,
            })}
            )
          </span>
        )}
      </span>
    </ListItem>
  )
}

EventRegistrationOpportunity.propTypes = {
  availableSpotsCount: PropTypes.number,
  className: PropTypes.string,
  endsAt: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  eventUrl: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
}

EventRegistrationOpportunity.defaultProps = {
  availableSpotsCount: null,
  className: null,
}

export default EventRegistrationOpportunity
