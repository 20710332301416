// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Icon from 'components/ui/icon'

// Vendor
import cx from 'classnames'

/** Icon + Label. Common pattern for using decorative icons alongside text. */
const LabeledIcon = ({ iconName, iconProps, label, labelProps, ...other }) => (
  <span {...other} className={cx('d-flex align-items-center', other.className)}>
    <Icon {...iconProps} iconName={iconName} title="" />
    <span {...labelProps} className={cx('ml-1', labelProps.className)}>
      {label}
    </span>
  </span>
)

LabeledIcon.propTypes = {
  /** key used to choose icon */
  iconName: PropTypes.string.isRequired,
  /** props applied to <Icon> node */
  iconProps: PropTypes.object,
  /** primary text label */
  label: PropTypes.string.isRequired,
  /** props applied to label text node> */
  labelProps: PropTypes.object,
}

LabeledIcon.defaultProps = {
  iconProps: {},
  labelProps: {},
}

export default LabeledIcon
