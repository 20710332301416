// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import Autocomplete from 'components/form/autocomplete'
import FormBooleanRadioReveal from 'components/form/boolean_radio_reveal'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  allTeamsButtonLabel: {
    defaultMessage: 'All Teams',
    id: 'additionalFormFields.allTeamsButtonLabel',
  },
  specificTeamsButtonLabel: {
    defaultMessage: 'Only specific Teams',
    id: 'additionalFormFields.specificTeamsButtonLabel',
  },
  teamSelecLabel: {
    defaultMessage: 'Teams with Access',
    id: 'additionalFormFields.teamSelectLabel',
  },
  teamSelectHelperText: {
    defaultMessage: 'Select the Teams that should have access.',
    id: 'additionalFormFields.teamSelectHelperText',
  },
  teamVisibilityLabel: {
    defaultMessage: 'Team Visibilty*',
    id: 'additionalFormFields.teamVisibilityLabel',
  },
})

const { formatMessage } = intl

const FormTeamVisibilityField = ({
  allTeams,
  name,
  selectedTeams,
  hideLabel,
  ...other
}) => (
  <FormBooleanRadioReveal
    {...other}
    emptyStates={[{ name: name, value: [] }]}
    hideLabel={hideLabel}
    label={formatMessage(messages.teamVisibilityLabel)}
    radioHideLabel={formatMessage(messages.allTeamsButtonLabel)}
    radioRevealLabel={formatMessage(messages.specificTeamsButtonLabel)}
    shouldReveal={selectedTeams.length > 0}
  >
    <Autocomplete
      multiple
      name={name}
      required
      selections={selectedTeams}
      suggestions={allTeams}
      textFieldProps={{
        helperText: formatMessage(messages.teamSelectHelperText),
        label: formatMessage(messages.teamSelecLabel),
      }}
    />
  </FormBooleanRadioReveal>
)

FormTeamVisibilityField.propTypes = {
  allTeams: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  selectedTeams: PropTypes.array,
}

FormTeamVisibilityField.defaultProps = {
  hideLabel: false,
  name: 'teamIds',
  selectedTeams: [],
}

export default FormTeamVisibilityField
