import React, { useState } from 'react'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import { useFormikContext } from 'formik'

// westyle
import ButtonLink from 'src/common/button/ButtonLink'
import CurrencyDropdown from 'src/pages/GivingForm/components/CurrencyDropdown'
import Divider from 'src/common/Divider'
import InfoContainer from 'src/common/InfoContainer'
import Input from 'src/common/Input'
import InputChoice from 'src/common/InputChoice'
import SectionContainer from 'src/common/SectionContainer'

const DonationAmountSection = ({
  availableDonationFunds,
  canMatch,
  donationAmount,
  donationFundsCap,
  donationMatchingEnabled,
  enabledCurrencies,
  match,
  matchRatio,
  selectedCurrency,
  setDonationAmount,
  setMatched,
  setSeletectedCurrency,
}) => {
  const formik = useFormikContext()
  const { errors } = formik
  const intl = useIntl()
  const [showCurrencySelection, setShowCurrencySelection] = useState(false)
  const canNotMatch = !canMatch || availableDonationFunds < 10
  const { setFieldValue } = useFormikContext()
  const handleDonationAmount = (event) => {
    const { value } = event.target
    setDonationAmount(value)
    setFieldValue('donationAmount', value)
  }

  const handleMatched = (event) => {
    const isChecked = event.target.checked
    setMatched(isChecked)
    setFieldValue('matched', isChecked)
  }

  const updateShowCurrencySelection = () => {
    setShowCurrencySelection(
      (prevShowCurrencySelection) => !prevShowCurrencySelection
    )
  }

  return (
    <SectionContainer
      additionalDetails={
        enabledCurrencies.length > 300 && (
          <ButtonLink
            className="inline-flex"
            onClick={updateShowCurrencySelection}
          >
            <FormattedMessage
              defaultMessage="Change currency"
              id="donationForm.changeCurrency"
            />
          </ButtonLink>
        )
      }
      title={intl.formatMessage({
        defaultMessage: 'Dollars for Doers Donation Amount',
        id: 'donationForm.donationAmount',
      })}
    >
      <div className="px-4">
        <div className="flex flex-col md:flex-row justify-between">
          {showCurrencySelection && (
            <CurrencyDropdown
              enabledCurrencies={enabledCurrencies}
              setSeletectedCurrency={setSeletectedCurrency}
            />
          )}
          <div className={cx('w-full', { 'md:w-fit': showCurrencySelection })}>
            <Input
              className={cx('w-full', {
                'flex-grow md:w-80 mt-4 md:mt-0': showCurrencySelection,
              })}
              explainer={intl.formatMessage({
                defaultMessage: 'Minimum must equal to $10.00 USD',
                id: 'donationForm.minimumMustEqual',
              })}
              min={10.0}
              name="donationAmount"
              onChange={handleDonationAmount}
              placeholder={intl.formatMessage(
                {
                  defaultMessage: 'Enter donation amount in {currency}',
                  id: 'donationForm.enterDonationAmount',
                },
                {
                  currency: selectedCurrency.name,
                }
              )}
              type="number"
              value={donationAmount}
            />
            {errors.donationAmount && donationAmount !== '' && (
              <div className="pl-2 text-error-red">{errors.donationAmount}</div>
            )}
          </div>
        </div>
        {!donationMatchingEnabled && (
          <>
            <Divider className="my-5 border-grayscale-2" />
            <h4 className="text-base font-semibold mb-4">
              <FormattedMessage
                defaultMessage="Matching Gift"
                id="donationForm.matchGift"
              />
            </h4>
            <InputChoice
              disabled={canNotMatch}
              label={intl.formatMessage({
                defaultMessage: 'Match my donation',
                id: 'shared.matchMyDonation',
              })}
              onChange={handleMatched}
              name="matched"
              type="checkbox"
              value={match}
            />
            <InfoContainer className="mt-4">
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage="You have {matchAccount} remaining of your company's {matchAccountLimit} annual limit per employee"
                    id="donationForm.remainingAnnualLimitPerEmployee"
                    values={{
                      matchAccount: (
                        <CurrencyFormat
                          decimalScale={2}
                          className="font-semibold"
                          displayType={'text'}
                          fixedDecimalScale
                          prefix={'$'}
                          value={availableDonationFunds}
                        />
                      ),
                      matchAccountLimit: (
                        <CurrencyFormat
                          decimalScale={2}
                          displayType={'text'}
                          fixedDecimalScale
                          prefix={'$'}
                          value={donationFundsCap}
                        />
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="The minimum match amount is {amount}"
                    id="donationForm.minMatchAmount"
                    values={{
                      amount: (
                        <CurrencyFormat
                          decimalScale={2}
                          displayType={'text'}
                          fixedDecimalScale
                          prefix={'$'}
                          value={10}
                        />
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="The maximum match amount is {amount}"
                    id="donationForm.maxMatchAmount"
                    values={{
                      amount: (
                        <CurrencyFormat
                          decimalScale={2}
                          displayType={'text'}
                          fixedDecimalScale
                          prefix={'$'}
                          value={donationFundsCap}
                        />
                      ),
                    }}
                  />
                </li>
                {matchRatio && (
                  <li>
                    <FormattedMessage
                      defaultMessage="The match ratio for this charity is {ratio}:1"
                      id="donationForm.theCharityMatchRatio"
                      values={{
                        ratio: matchRatio,
                      }}
                    />
                  </li>
                )}
              </ul>
            </InfoContainer>
          </>
        )}
      </div>
    </SectionContainer>
  )
}

export default DonationAmountSection
