// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { formatMessages } from 'components/shared/translations'

const messages = defineMessages({
  acceptGuestsWaiver: {
    defaultMessage:
      'If you register any Guests for this Event, you are then also accepting this Waiver on their behalf.',
    description:
      'Help text explaining the user is required to accept the Waiver on behalf of guests',
    id: 'eventsTranslations.acceptGuestsWaiver',
  },
  acceptWaiver: {
    defaultMessage:
      'You are required to accept the Waiver in order to register for this Event.',
    description:
      'Help text explaining the user is required to accept the Waiver',
    id: 'eventsTranslations.acceptWaiver',
  },
  back: {
    defaultMessage: 'Back to Event',
    id: 'eventsTranslations.back',
  },
  dateAndTime: {
    defaultMessage: 'date & time',
    id: 'eventsTranslations.dateAndTime',
  },
  guestsWelcome: {
    defaultMessage: 'guests welcome',
    id: 'eventsTranslations.guestsWelcome',
  },
  joinVirtually: {
    defaultMessage: 'join virtually',
    id: 'eventsTranslations.joinVirtually',
  },
  location: {
    defaultMessage: 'location',
    id: 'eventsTranslations.location',
  },
  register: {
    defaultMessage: 'Register',
    id: 'eventsTranslations.register',
  },
  yourRegistration: {
    defaultMessage: 'Your registration',
    id: 'eventsTranslations.yourRegistration',
  },
})

export const eventsTranslations = formatMessages(messages)
