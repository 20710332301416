import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const GraphLegend = ({ graphData }) => {
  const defineLegendArray = () => {
    const legendArrayTemp = []
    graphData.data.forEach((item, index) => {
      legendArrayTemp.push({
        color: graphData.colorScale[index],
        name: item.name,
      })
    })

    return legendArrayTemp
  }

  const [legendArray] = useState(defineLegendArray)

  return (
    <div
      className="[ d-flex justify-content-center ] [ mx-4 ]"
      data-test="graph-legend-test"
    >
      {legendArray.map((item) => (
        <div className="d-flex mr-2" key={item.name}>
          <div
            className="mr-1"
            style={{
              background: item.color,
              borderRadius: '50%',
              height: '10px',
              marginTop: '3px',
              width: '10px',
            }}
          />
          <span className="fs-2">{item.name}</span>
        </div>
      ))}
    </div>
  )
}

GraphLegend.propTypes = {
  graphData: PropTypes.shape({
    colorScale: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        y: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
}
