// Setup
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

// WeSpire
import { client } from 'utilities/we_apollo'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import FormErrorSummary from 'components/form/error_summary'
import { getUrlParams } from 'utilities/get_url_params'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { sharedMessages } from 'components/shared/translations'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import WeApollo from 'utilities/we_apollo'
import WeQuery from 'components/application/we_query'

// sub-components and utils
import { groupAnnouncementsMessages as messages } from './utilities/messages'
import { GroupAnnouncementsListing } from './components/listing'
import {
  GET_GROUPS_ANNOUNCEMENTS_DATA,
  UPDATE_GROUP_ANNOUNCEMENT,
} from './utilities/queries'
import { groupByAnnouncementsData } from './utilities/utils'
import { newGroupAnnouncementRoute } from './routes'

const { formatMessage } = intl

export default function GroupAnnouncements({ groupId }) {
  const executeMutation = (announcementHtml, id, status, title) => {
    const refetchQueries = [
      {
        query: GET_GROUPS_ANNOUNCEMENTS_DATA,
        variables: { groupId },
      },
    ]

    const variables = {
      announcementHtml,
      groupAnnouncementId: id,
      status,
      title,
    }

    client
      .mutate({
        awaitRefetchQueries: true,
        mutation: UPDATE_GROUP_ANNOUNCEMENT,
        refetchQueries,
        variables: variables,
      })
      .then(({ data: { errors } }) => {
        if (!errors) {
          displayBanner({
            content: formatMessage(sharedMessages.recordSaved, {
              action: 'updated',
              className: formatMessage(sharedMessages.announcement),
            }),
            variant: 'success',
          })
        } else {
          displayBanner({
            as: 'div',
            content: <FormErrorSummary errors={errors} />,
            fullWidth: true,
            variant: 'error',
          })
        }
      })
      .catch(() => {
        const operation = formatMessage(sharedMessages.operationUpdate, {
          className: formatMessage(sharedMessages.announcement),
        })

        displayExceptionBanner({
          fullWidth: true,
          operation: operation,
        })
      })
  }

  useEffect(() => {
    if (getUrlParams().created || getUrlParams().updated) {
      displayBanner({
        content: formatMessage(sharedMessages.recordSaved, {
          action: getUrlParams().updated ? 'updated' : 'created',
          className: formatMessage(sharedMessages.announcement),
        }),
        variant: 'success',
      })
      history.replaceState(
        {},
        formatMessage(messages.groupAnnouncements),
        window.location.pathname
      )
    }
  })

  return (
    <WeApollo>
      <WeQuery
        error={<MissingResourcePage />}
        loader={
          <Stack space={4}>
            <SkeletonLoading height={75} />
            <SkeletonLoading className="px-4" height={300} />
          </Stack>
        }
        query={GET_GROUPS_ANNOUNCEMENTS_DATA}
        variables={{ groupId }}
      >
        {({
          data: {
            group: { isChapter, announcementsForAdmin },
          },
        }) => {
          const sortedData = groupByAnnouncementsData(announcementsForAdmin)
          return (
            <Stack className="mb-5" space={3}>
              <Button
                className="flex-shrink-none ml-2"
                color="primary"
                data-test="create-announcements-button"
                href={newGroupAnnouncementRoute({
                  groupId,
                })}
                variant="contained"
              >
                {formatMessage(messages.createButton, {
                  groupAnnouncements: formatMessage(
                    messages.groupAnnouncements
                  ),
                  type: isChapter ? 'chapter' : 'group',
                })}
              </Button>
              {announcementsForAdmin.length === 0 && (
                <div className="mx-3" data-test="nothing-to-display">
                  {formatMessage(messages.nothingToDisplay)}
                </div>
              )}
              {sortedData.published && sortedData.published.length > 0 && (
                <GroupAnnouncementsListing
                  announcements={sortedData.published}
                  executeMutation={executeMutation}
                  groupId={groupId}
                  type="published"
                />
              )}
              {sortedData.drafted && sortedData.drafted.length > 0 && (
                <GroupAnnouncementsListing
                  announcements={sortedData.drafted}
                  executeMutation={executeMutation}
                  groupId={groupId}
                  type="drafted"
                />
              )}
              {sortedData.archived && sortedData.archived.length > 0 && (
                <GroupAnnouncementsListing
                  announcements={sortedData.archived}
                  executeMutation={executeMutation}
                  groupId={groupId}
                  type="archived"
                />
              )}
            </Stack>
          )
        }}
      </WeQuery>
    </WeApollo>
  )
}

GroupAnnouncements.propTypes = {
  groupId: PropTypes.string.isRequired,
}
