// Setup
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Formsy from 'formsy-react'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { Heading } from 'components/ui/heading'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'

/**
 * Page-level component intended to wrap pages primarily containing Formsy-based
 * forms. Styled as a 6-col max-width Paper container horizontally centered on
 * the page. Renders a heading, and optional back link, optional description
 * under the heading, and optional footer below the Paper container. Also
 * includes Formsy wrapper as a convenience, with all other props being spread
 * to the Formsy component. If all you want to render is a Formsy form on its
 * own page, this is a good choice.
 */
export const FormPage = forwardRef(
  (
    {
      backLinkSubject,
      backLinkTo,
      children,
      description,
      footer,
      heading,
      infoBox,
      topInfoBox,
      ...other
    },
    ref
  ) => {
    return (
      <div className="mt-5 mx-3 mxw-6 | mt-sm-6 mx-sm-auto">
        <Stack className="[ d-flex align-items-center flex-wrap justify-content-between ] [ lh-md px-3 | px-sm-4 ]">
          <Heading level={1}>{heading}</Heading>
          {backLinkTo && (
            <TextLink
              className="fs-2"
              data-test="form-wrapper-back-link"
              to={backLinkTo}
            >
              <>
                <span aria-hidden>
                  {'< '}
                  {sharedTranslations.backTo}
                </span>{' '}
                {backLinkSubject}
              </>
            </TextLink>
          )}
        </Stack>
        {topInfoBox && <div className="mt-3 bg-blue-2 p-3">{topInfoBox}</div>}
        {description && <p className="my-3 px-3 | px-sm-4">{description}</p>}
        {infoBox && <div className="bg-blue-2 p-4">{infoBox}</div>}
        <Paper className="mt-4 pb-5 pt-4 px-3 | px-sm-4">
          {/* Note: We need to call Formsy out separately from the Stack */}
          {/* in order to pass the ref correctly. */}
          <Formsy {...other} noValidate ref={ref}>
            <Stack space={5}>{children}</Stack>
          </Formsy>
        </Paper>
        {footer && <p className="fs-1 my-4 px-3 | px-sm-4">{footer}</p>}
      </div>
    )
  }
)

FormPage.propTypes = {
  /** text rendered in "back" link */
  backLinkSubject: PropTypes.string,
  /** URL destination of "back" link */
  backLinkTo: PropTypes.string,
  /** children rendered within the paper section */
  children: PropTypes.node.isRequired,
  /** an optional description rendered above the form (below the heading) */
  description: PropTypes.node,
  /** an optional footer below the form */
  footer: PropTypes.node,
  /** the h1 heading of this page */
  heading: PropTypes.node.isRequired,
  /** an optional info box rendered above the form (below the description) */
  infoBox: PropTypes.node,
  /** an optional info box rendered above the form (above the description) */
  topInfoBox: PropTypes.node,
}

FormPage.defaultProps = {
  backLinkSubject: null,
  backLinkTo: null,
  description: null,
  footer: null,
  infoBox: null,
  topInfoBox: null,
}
