// Vendor
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import { AnalyticsManager } from 'utilities/analytics'
import formatNotificationsCount from 'utilities/format_notifications_count'
import { useIsAuthenticated } from 'utilities/hooks/use_is_authenticated'

const currentUserFragement = gql`
  fragment CURRENT_USER on Query {
    currentUser {
      id
      unreadNotificationsCount
    }
  }
`

const documentTitleQuery = (isAuthenticated) => gql`
  query documentTitleQuery {
    brand {
      id
      name
    }
    ${isAuthenticated ? '...CURRENT_USER' : ''}
  }
  ${isAuthenticated ? currentUserFragement : ''}
`

/**
 * Sets the browser document title. Takes in a React component's specific page
 * title as a prop and requests the name of the brand with GraphQL so that it
 * can render the name alongside the title.
 **/
const DocumentTitle = ({
  children,
  suppressPageViewEvent,
  title,
  unauthorizedPage,
}) => {
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (unauthorizedPage) {
      document.title = title
      return
    }

    async function fetchTitleData() {
      try {
        const {
          data: {
            brand: { name },
            currentUser,
          },
        } = await client.query({
          query: documentTitleQuery(isAuthenticated),
        })
        let documentTitle = `${title} | ${name}`

        if (currentUser && currentUser.unreadNotificationsCount > 0) {
          documentTitle = `(${formatNotificationsCount(
            currentUser.unreadNotificationsCount
          )}) ${documentTitle}`
        }
        document.title = documentTitle
      } catch {
        document.title = title
      } finally {
        !suppressPageViewEvent && !unauthorizedPage && AnalyticsManager.page()
      }
    }

    fetchTitleData()
  }, [title, unauthorizedPage])

  return children
}

DocumentTitle.propTypes = {
  children: PropTypes.any.isRequired,
  /* Indicates whether we should send a `pageview` analytics event to Segment */
  suppressPageViewEvent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  /**
   * Indicates if the page rendering DocumentTitle is at an unauthorized route. If this is true, we can't make
   * a GraphQL request for the current user's info, so we just render a simple document title using the title prop.
   **/
  unauthorizedPage: PropTypes.bool,
}

DocumentTitle.defaultProps = {
  suppressPageViewEvent: false,
  unauthorizedPage: false,
}

export { DocumentTitle }
