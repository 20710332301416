// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import { RoleRow } from 'components/setup/group_leadership_roles/role_row'
import ScreenReader from 'components/ui/screen_reader_text'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { sortByNumber } from 'utilities/sort'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl

const messages = defineMessages({
  assignedTo: {
    defaultMessage: 'Assigned to',
    id: 'roleList.assignedTo',
  },
  noRoles: {
    defaultMessage: 'There are no leadership roles yet.',
    id: 'roleList.noRoles',
  },
  title: {
    defaultMessage: 'Title',
    description: 'The name of the role in a group',
    id: 'roleList.title',
  },
})

const RoleList = ({
  ariaLiveText,
  fromManagementPanel,
  groupId,
  isChapter,
  onUpdatePositionClick,
  parentGroupId,
  roleListQuery,
}) => {
  return (
    <WeQuery
      loader={<SkeletonLoading height={50} />}
      query={roleListQuery}
      variables={{ groupId }}
    >
      {({
        data: {
          group: { groupLeadershipRoles },
        },
      }) => {
        const sortedRoles = sortByNumber(groupLeadershipRoles, 'position')

        return (
          <>
            {sortedRoles.length > 0 ? (
              <div>
                <div className="bg-black-6 fs-1 fw-semi-bold ls-3 py-3 text-uppercase">
                  <div className="row">
                    <span className="col-2" />
                    <span className="col-3">
                      {formatMessage(messages.title)}
                    </span>
                    <span className="col-7">
                      {formatMessage(messages.assignedTo)}
                    </span>
                  </div>
                </div>
                <Stack as="ol" className="p-0" divider="default" space={1}>
                  {sortedRoles.map((role) => (
                    <RoleRow
                      fromManagementPanel={fromManagementPanel}
                      groupId={groupId}
                      isChapter={isChapter}
                      key={role.id}
                      leaders={role.leaders}
                      onUpdatePositionClick={onUpdatePositionClick}
                      parentGroupId={parentGroupId}
                      roleId={role.id}
                      title={role.title}
                    />
                  ))}
                </Stack>
                <ScreenReader aria-live="polite" text={ariaLiveText} />
              </div>
            ) : (
              <p className="fw-semi-bold" data-test="empty-state-message">
                {formatMessage(messages.noRoles)}
              </p>
            )}
          </>
        )
      }}
    </WeQuery>
  )
}

RoleList.propTypes = {
  ariaLiveText: PropTypes.string.isRequired,
  fromManagementPanel: PropTypes.any,
  groupId: PropTypes.string.isRequired,
  isChapter: PropTypes.bool.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
  parentGroupId: PropTypes.string,
  roleListQuery: PropTypes.object.isRequired,
}

RoleList.defaultProps = {
  fromManagementPanel: undefined,
  parentGroupId: null,
}

export { RoleList }
