// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import HelpIconModal from 'components/ui/help_icon_modal'

/**
 * Extends HelpIconButton by rendering text alongside it. Ensures the help icon
 * always appears to the right of the text, center aligned with it, and with
 * enough space that it stays as close to the text as possible without
 * overlapping it.
 */
const TextWithHelpIconModal = ({
  helpIconModalProps,
  modalContent,
  text,
  title,
  ...other
}) => (
  <span
    {...other}
    className={cx(
      '[ d-inline-flex align-items-center ] [ position-relative pr-6 ]',
      other.className
    )}
  >
    {text}

    <HelpIconModal
      {...helpIconModalProps}
      className={cx('position-absolute', helpIconModalProps.className)}
      style={{ right: 0, top: '50%', transform: 'translateY(-50%)' }}
      title={title || text}
    >
      {modalContent}
    </HelpIconModal>
  </span>
)

TextWithHelpIconModal.propTypes = {
  /** props applied to <HelpIconModal> */
  helpIconModalProps: PropTypes.object,
  /** optional additional content presented via help icon modal */
  modalContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  /** text rendered alongside the help icon */
  text: PropTypes.node.isRequired,
  /** title rendered in the modal */
  title: PropTypes.string,
}

TextWithHelpIconModal.defaultProps = {
  helpIconModalProps: {
    className: null,
  },
  title: null,
}

export default TextWithHelpIconModal
