// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import {
  editGroupRoute,
  indexChaptersRoute,
  indexLeadershipRolesRoute,
} from './routes'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { ListOrdering } from 'components/shared/list_ordering/list_ordering'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  chapters: {
    defaultMessage: 'Chapters',
    description: 'Button to view group chapters',
    id: 'groupRow.chapters',
  },
  leadershipRoles: {
    defaultMessage: 'Leadership Roles',
    description: 'Button to view group Leaderships',
    id: 'groupRow.leadershipRoles',
  },
})

const { formatMessage } = intl

const GroupRow = ({ groupId, groupName, onUpdatePositionClick, groupSlug }) => {
  return (
    <li className="list-none lh-md" data-test="group-row">
      <div className="row align-items-center">
        <div className="d-inline-flex col-2 justify-content-center">
          <ListOrdering
            onUpdatePositionClick={onUpdatePositionClick}
            recordId={groupId}
            recordName={groupName}
          />
        </div>
        <span
          className={'[ fs-3 fw-semi-bold ] [ col-5 ]'}
          data-test="group-name"
        >
          {groupName}
        </span>
        <span className="[ col-2 ] [ fs-3 ]">
          <LinkButton
            aria-label="view group chapters"
            className="fs-3"
            data-test="group-index-chapters"
            to={indexChaptersRoute({
              groupSlug,
            })}
            variant="text"
          >
            <Icon
              className="fs-6"
              iconName="eye"
              title={formatMessage(messages.chapters)}
            />
          </LinkButton>
        </span>
        <span className="[ col-2 ] [ fs-3 ]" data-test="group-leaders">
          <LinkButton
            aria-label="view group leadership roles"
            className="fs-3"
            data-test="group-index-roles"
            to={indexLeadershipRolesRoute({
              groupSlug,
            })}
            variant="text"
          >
            <Icon
              className="fs-6"
              iconName="account"
              title={formatMessage(messages.leadershipRoles)}
            />
          </LinkButton>
        </span>
        <div className="d-inline-flex col-1 justify-content-center">
          <LinkButton
            aria-label="edit group"
            className="fs-3"
            data-test="group-edit"
            to={editGroupRoute({
              groupSlug,
            })}
            variant="text"
          >
            {sharedTranslations.edit}
          </LinkButton>
        </div>
      </div>
    </li>
  )
}

GroupRow.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  groupSlug: PropTypes.string.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
}

export { GroupRow }
