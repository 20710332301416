export const updateUserTourAction = 'wespire/SET_USER_TOUR_STATE'

const defaultState = {
  forceOpen: false,
  maybeOpen: false,
  messageKey: undefined,
}

// Reducer
const userTour = (state = defaultState, action) => {
  switch (action.type) {
    case updateUserTourAction:
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default userTour
