// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import { WordForScore } from 'components/queries/word_for_score'
import ScreenReader from 'components/ui/screen_reader_text'
import { leaderBoardTranslations } from '../utils/shared_translations'
import Stack from 'components/ui/stack'

const YourRankingListItem = ({
  avatarUrl,
  className,
  forTeams,
  highlightAsYou,
  name,
  score,
  rank,
}) => (
  <li
    className={cx(
      '[ d-flex align-items-center flex-grow ] [ py-2 px-3 ]',
      className,
      {
        'bg-blue-2': highlightAsYou,
        'bg-white': !highlightAsYou,
      }
    )}
    data-test="your-ranking-item"
  >
    <Avatar
      className="border border-orange border-width-2 rounded-circle"
      image={avatarUrl}
      size={AvatarSizes.small}
    />
    <Stack className="fs-1 fw-semi-bold lh-md ml-2" space={1}>
      <div data-test="competitor-name">{name}</div>
      {highlightAsYou && forTeams && (
        <div className="text-black-2" data-test="competitor-your-team">
          {leaderBoardTranslations.yourTeam}
        </div>
      )}
      {highlightAsYou && !forTeams && (
        <ScreenReader text={` (${leaderBoardTranslations.yourRank})`} />
      )}

      <div data-test="competitor-points">
        <WordForScore score={score} />
      </div>
    </Stack>
    <b
      className={cx('fs-4 ml-auto', {
        'text-black-1': highlightAsYou,
        'text-orange': !highlightAsYou,
      })}
      data-test="competitor-rank"
    >
      <FormattedNumber value={rank} />
    </b>
  </li>
)

YourRankingListItem.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  forTeams: PropTypes.bool,
  highlightAsYou: PropTypes.bool,
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
}

YourRankingListItem.defaultProps = {
  className: null,
  forTeams: false,
  highlightAsYou: false,
}

export { YourRankingListItem }
