// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'

// WeSpire
import { ErrorPage } from 'components/shared/pages/error'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  noDonationBody: {
    defaultMessage:
      'You cannot complete your donation at this time because we could not find your donation account. <link>Contact support</link> to enable your donation account.',
    id: 'donationAccountNotFoundPage.noDonationBody',
  },
})

const body = (
  <>
    {formatMessage(messages.noDonationBody, {
      link: (...linkText) => (
        <a href="mailto:support@wespire.com?subject=Giving%20Account%20Error">
          {linkText}
        </a>
      ),
    })}
  </>
)

/**
 * This page should be displayed when we cannot find the Givinga Account for a
 * user trying to make a donation (i.e. a GraphQL request for givingaUser
 * returned with no user). It takes in as a prop the type of donation the user
 * was trying to make.
 **/
const DonationAccountNotFoundPage = () => (
  <ErrorPage body={body} data-test="contact-support" />
)

export { DonationAccountNotFoundPage }
