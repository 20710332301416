import React from 'react'
import { FormattedMessage } from 'react-intl'

export const validateValues = (values, setIsValid, availableD4dFunds) => {
  const errors = {}
  if (!values.donationAmount) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Value is required"
        id="donationForm.valueIsRequired"
      />
    )
  } else if (parseInt(values.donationAmount) < 10) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Value must be greater than 10"
        id="donationForm.valueMustBeGreaterThan"
      />
    )
  } else if (values.donationAmount.toString().split('.')[1]?.length > 2) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Value must have at most two decimal places"
        id="donationForm.valueMustHaveAtMostTwo"
      />
    )
  } else if (values.donationAmount > availableD4dFunds) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Enter a number {amount} or less."
        id="donationForm.enterANumberOrLess"
        values={{
          amount: availableD4dFunds,
        }}
      />
    )
  } else if (values.donationAmount * 2 > availableD4dFunds && values.matched) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="If you wish to match your donation, based on your available funds the largest value you can enter is {amount}"
        id="donationForm.ifYouWishtoMatchYourDonation"
        values={{
          amount: availableD4dFunds / 2,
        }}
      />
    )
  }

  setIsValid(Object.keys(errors).length === 0)

  return errors
}
