// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sharedTranslations } from 'components/shared/translations'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl
const messages = defineMessages({
  logVolunteerHours: {
    defaultMessage: 'Log Your Volunteer Hours',
    id: 'UserProfileVolunteerStat.logVolunteerHours',
  },
  viewVolunteerHistory: {
    defaultMessage: 'View volunteer history',
    id: 'UserProfileVolunteerStat.viewVolunteerHistory',
  },
  yourVolunteerStats: {
    defaultMessage: 'your volunteer history stats',
    id: 'UserProfileVolunteerStat.yourVolunteerStats',
  },
})

export const UserProfileVolunteerStat = ({ className, userId }) => (
  <WeQuery
    error={
      <MissingResourceSection
        errorDetails={formatMessage(messages.yourVolunteerStats)}
      />
    }
    loader={<SkeletonLoading height={50} />}
    query={gql`
      query userVolunteeringQuery($id: ID!) {
        userImpactStats(userId: $id) {
          id
          totalHoursVolunteered
        }
      }
    `}
    variables={{ id: userId }}
  >
    {({
      data: {
        userImpactStats: { totalHoursVolunteered },
      },
    }) => {
      return (
        <Stack className={className} space={3}>
          <Stat
            amount={totalHoursVolunteered}
            dataTest="hours-volunteered-count"
            label={sharedTranslations.totalHoursVolunteered}
          />
          <p>
            <Link
              className="fs-2"
              data-test="volunteering-history-url"
              to="/users/me/volunteer_history"
            >
              {formatMessage(messages.viewVolunteerHistory)}
            </Link>
          </p>
          <p>
            <Link
              className="fs-2"
              data-test="volunteering-log-url"
              to="/users/me/volunteer_hours_form"
            >
              {formatMessage(messages.logVolunteerHours)}
            </Link>
          </p>
        </Stack>
      )
    }}
  </WeQuery>
)

UserProfileVolunteerStat.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
}

UserProfileVolunteerStat.defaultProps = {
  className: null,
}
