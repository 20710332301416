// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import ActivitiesCard from 'components/activities/card'
import { ACTIVITY_CARD_FRAGMENT } from 'components/activities/fragments'
import { Heading } from 'components/ui/heading'
import { intl } from 'components/localization_provider'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'

const featuredActivitiesQuery = gql`
  query featuredActivitiesQuery {
    brand {
      id
      featuredActivities {
        ...ActivityAttributes
      }
    }
  }
  ${ACTIVITY_CARD_FRAGMENT}
`

const messages = defineMessages({
  emptyState: {
    defaultMessage: 'There are no featured Activities.',
    id: 'featuredActivities.emptyState',
  },
  errorDetails: {
    defaultMessage: 'featured Activities for this Brand',
    description:
      'Name of resource to be displayed in an error message when failing to fetch the data',
    id: 'featuredActivities.errorDetails',
  },
  heading: {
    defaultMessage: 'featured Activities',
    id: 'featuredActivities.heading',
  },
})

export const FeaturedActivities = () => {
  const { formatMessage } = intl

  return (
    <Stack as="section" space={3}>
      <Heading className="px-3" level={2}>
        {formatMessage(messages.heading)}
      </Heading>
      <div>
        <WeQuery
          error={
            <MissingResourceSection
              errorDetails={formatMessage(messages.errorDetails)}
            />
          }
          loader={<SkeletonLoading height={330} />}
          query={featuredActivitiesQuery}
        >
          {({
            data: {
              brand: { featuredActivities },
            },
          }) => {
            if (featuredActivities.length > 0) {
              return (
                <div className="[ row ] [ pl-2 ]">
                  {featuredActivities.map((activity) => (
                    <ActivitiesCard
                      activity={activity}
                      key={activity.type + activity.id}
                    />
                  ))}
                </div>
              )
            } else {
              return <p>{formatMessage(messages.emptyState)}</p>
            }
          }}
        </WeQuery>
      </div>
    </Stack>
  )
}
