// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { QueryParamProvider } from 'use-query-params'

// WeSpire
import { ContentModuleForm } from '../content_module_form'
import MgmtPanelFormPage from 'setup/application/form_page'

/**
 * The edit page containing the form for editing an brand content module.
 */
const ContentModulesEditPage = ({
  carbonImpactMarketplaceToggle,
  contentModule,
  channelId,
  channelList,
  hasWhyItMatters,
  skipAssociatedChannel,
  sustainabilityImpactSettings,
  whyItMatters,
  whyItMattersIsAUrl,
  whyItMattersIsOptional,
}) => (
  <MgmtPanelFormPage isEdit resourceName="Module">
    {() => (
      <QueryParamProvider>
        <ContentModuleForm
          carbonImpactMarketplaceToggle={carbonImpactMarketplaceToggle}
          channelId={channelId}
          channelList={channelList}
          contentModule={contentModule}
          hasWhyItMatters={hasWhyItMatters}
          skipAssociatedChannel={skipAssociatedChannel}
          sustainabilityImpactSettings={sustainabilityImpactSettings}
          whyItMatters={whyItMatters}
          whyItMattersIsAUrl={whyItMattersIsAUrl}
          whyItMattersIsOptional={whyItMattersIsOptional}
        />
      </QueryParamProvider>
    )}
  </MgmtPanelFormPage>
)

ContentModulesEditPage.propTypes = {
  carbonImpactMarketplaceToggle: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }),
  channelId: PropTypes.string,
  channelList: PropTypes.array.isRequired,
  contentModule: PropTypes.object.isRequired,
  hasWhyItMatters: PropTypes.bool,
  skipAssociatedChannel: PropTypes.bool,
  sustainabilityImpactSettings: PropTypes.array,
  whyItMatters: PropTypes.string,
  whyItMattersIsAUrl: PropTypes.bool,
  whyItMattersIsOptional: PropTypes.bool,
}

ContentModulesEditPage.defaultProps = {
  carbonImpactMarketplaceToggle: null,
  channelId: null,
  hasWhyItMatters: false,
  skipAssociatedChannel: false,
  sustainabilityImpactSettings: null,
  whyItMatters: null,
  whyItMattersIsAUrl: false,
  whyItMattersIsOptional: false,
}

export default ContentModulesEditPage
