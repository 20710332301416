// Setup
import Paper from '@material-ui/core/Paper'
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { withRouter } from 'react-router-dom'

// Vendor
import { connect } from 'react-redux'
import Formsy from 'formsy-react'
import { setToken } from 'redux/reducers/session'

// WeSpire
import { displayBanner } from 'redux/dispatchers'
import InputPasswordToggleButton from 'components/form/input_password_toggle_button'
import SubmitButton from 'components/ui/submit_button'
import TextField from 'components/form/text_field'
import Link from 'components/shared/link'
import WeSpireAPI from 'utilities/wespire_api'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  emailLabel: {
    defaultMessage: 'email',
    id: 'emailAuthForm.emailLabel',
  },
  forgotPassword: {
    defaultMessage: "Forgot or don't have a password?",
    id: 'emailAuthForm.forgotPassword',
  },
  logInButton: {
    defaultMessage: 'Log in',
    id: 'emailAuthForm.loginInButton',
  },
  loginWithSSO: {
    defaultMessage: 'Login with SSO',
    id: 'emailAuthForm.loginWithSSO',
  },
  needAccount: {
    defaultMessage: 'Need to create an account?',
    id: 'emailAuthForm.needAccount',
  },
  passwordLabel: {
    defaultMessage: 'password',
    id: 'emailAuthForm.passwordLabel',
  },
  pleaseSignIn: {
    defaultMessage: 'Please sign in',
    id: 'emailAuthForm.pleaseSignIn',
  },
  validEmail: {
    defaultMessage: 'Must be a valid email',
    id: 'emailAuthForm.validEmail',
  },
})

class EmailAuthForm extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    multiAuth: PropTypes.bool.isRequired,
    setToken: PropTypes.func,
    signUpPath: PropTypes.string.isRequired,
    ssoUrl: PropTypes.string,
  }

  static defaultProps = {
    location: {},
    setToken: () => {},
    ssoUrl: null,
  }

  state = {
    isPasswordVisible: false,
    isSubmitting: false,
  }

  handleClickShowPasssword = (event) => {
    event.preventDefault()
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible })
  }

  handleSubmit = (values) => {
    const { setToken } = this.props
    const { email, password } = values

    this.setState({ isSubmitting: true })

    WeSpireAPI()
      .post('/session', {
        session: {
          email,
          password,
        },
      })
      .then((response) => {
        setToken(response.data.token)
        this.setState({ isSubmitting: false })

        if (response.data.sso) {
          this.ssoformElement.submit()
        } else {
          window.location = response.data.redirect_path
        }
      })
      .catch((error) => {
        const errors = error.response.data.errors

        this.setState({ isSubmitting: false })

        displayBanner({
          content: errors.base,
          variant: 'error',
        })
      })
  }

  setErrorSummaryRef = (errorSummary) => (this.errorSummary = errorSummary)
  setFormRef = (formElement) => (this.formElement = formElement)
  setSSOFormRef = (ssoFormElement) => (this.ssoformElement = ssoFormElement)

  render() {
    const csrftParamEl = document.querySelector("meta[name='csrf-param']")
    const csrfTokenEl = document.querySelector("meta[name='csrf-token']")
    const tokenName = csrftParamEl && csrftParamEl.getAttribute('content')
    const tokenValue = csrfTokenEl && csrfTokenEl.getAttribute('content')

    const { isPasswordVisible, isSubmitting } = this.state
    const { location, multiAuth, ssoUrl, signUpPath } = this.props

    const propState = location?.state ?? {}

    if (propState?.info) {
      displayBanner({
        content: propState.info,
        variant: 'info',
      })
    }

    return (
      <Paper
        className="[ d-flex flex-column ] [ login-auth-panel ]"
        component="section"
        elevation={8}
      >
        <Formsy
          noValidate
          onValidSubmit={this.handleSubmit}
          ref={this.setFormRef}
        >
          <h1 className="text-center">
            {intl.formatMessage(messages.pleaseSignIn)}
          </h1>
          <TextField
            className="mt-4"
            name="email"
            required
            textFieldProps={{
              label: intl.formatMessage(messages.emailLabel),
            }}
            validationErrors={{
              isEmail: intl.formatMessage(messages.validEmail),
            }}
            validations={{ isEmail: true }}
            value={propState?.email ?? ''}
          />

          <TextField
            className="mt-4"
            name="password"
            required
            textFieldProps={{
              InputProps: {
                endAdornment: (
                  <InputPasswordToggleButton
                    isPasswordVisible={isPasswordVisible}
                    onClick={this.handleClickShowPasssword}
                  />
                ),
              },
              label: intl.formatMessage(messages.passwordLabel),
              type: isPasswordVisible ? 'text' : 'password',
            }}
          />

          <p className="text-left">
            <Link to="/passwords/new">
              {intl.formatMessage(messages.forgotPassword)}
            </Link>
          </p>

          {!multiAuth && (
            <p className="text-left">
              <Link to={signUpPath}>
                {intl.formatMessage(messages.needAccount)}
              </Link>
            </p>
          )}

          {multiAuth && (
            <form action={ssoUrl} method="POST" ref={this.setSSOFormRef}>
              <input name={tokenName} type="hidden" value={tokenValue} />
              <p className="text-left">
                <Link onClick={() => this.ssoformElement.submit()}>
                  {intl.formatMessage(messages.loginWithSSO)}
                </Link>
              </p>
            </form>
          )}

          <SubmitButton
            className="fs-3 p-3 w-100 text-black-1 mt-4"
            color="primary"
            data-test="login"
            isSubmitting={isSubmitting}
            variant="outlined"
          >
            {intl.formatMessage(messages.logInButton)}
          </SubmitButton>
        </Formsy>
      </Paper>
    )
  }
}

export { EmailAuthForm }
export default connect(null, { setToken })(withRouter(EmailAuthForm))
