import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

// westyle
import Numbers from 'src/common/Numbers'

const D4dEarnedMarker = ({ earnedThisYear, className }) => {
  return earnedThisYear ? (
    <div className={cx('flex', className)}>
      <span className="material-symbols-rounded text-thriving-green text-base">
        task_alt
      </span>
      <span className="font-medium text-thriving-green text-base">
        <FormattedMessage
          id="volunteering.dollarsForDoersEarned"
          defaultMessage="You have earned {amount} for your organization"
          values={{
            amount: (
              <Numbers
                className="font-medium align-middle text-thriving-green text-base"
                currency="USD"
                style="currency"
              >
                {earnedThisYear}
              </Numbers>
            ),
          }}
        />
      </span>
    </div>
  ) : null
}

D4dEarnedMarker.propTypes = {
  earnedThisYear: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default D4dEarnedMarker
