// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

// WeSpire
import { displayModal, hideModal } from 'redux/dispatchers'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Icon from 'components/ui/icon'

const { formatMessage } = intl
const messages = defineMessages({
  cancel: {
    defaultMessage: 'Close',
    id: 'simpleConfirmModal.cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'simpleConfirmModal.confirm',
  },
  title: {
    defaultMessage: 'Confirmation',
    id: 'simpleConfirmModal.title',
  },
})

export const displaySimpleConfirmModal = ({ body, onCancel, onConfirm }) => {
  displayModal({
    options: {
      'data-test': 'simple-confirm-modal',
      fullWidth: true,
      maxWidth: 'sm',
    },
    renderContent: () => (
      <SimpleConfirmModal
        body={body}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    ),
  })
}

export const SimpleConfirmModal = ({ body, onCancel, onConfirm }) => {
  return (
    <>
      <DialogTitle
        className="d-flex align-items-center justify-content-between"
        disableTypography
      >
        <Heading level={1} variant={2}>
          {formatMessage(messages.title)}
        </Heading>
        <IconButton
          onClick={() => {
            hideModal()
            onCancel()
          }}
        >
          <Icon
            className="fs-4 text-black-3"
            data-test="close-help-dialog"
            iconName="close"
            title={formatMessage(messages.cancel)}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent data-test="simple-confirm-modal">{body}</DialogContent>
      <DialogActions>
        <Button onClick={hideModal}>{formatMessage(messages.cancel)}</Button>
        <Button
          color="primary"
          data-test="simple-confirm-modal-button"
          onClick={() => {
            hideModal()
            onConfirm()
          }}
        >
          {formatMessage(messages.confirm)}
        </Button>
      </DialogActions>
    </>
  )
}

SimpleConfirmModal.propTypes = {
  body: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

SimpleConfirmModal.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
}
