// This extracts the equivalent of the #full_public_id method on the image object.
// For example:
// "https://res.cloudinary.com/hauyptxwi/image/upload/v1570573127/v0dpopvat0ppspydpxqr.jpg"
// => "v1570573127/v0dpopvat0ppspydpxqr"
export const cloudinaryIdFromUrl = (url) => {
  const isCloudinaryUrl = /cloudinary/.test(url)

  if (isCloudinaryUrl) {
    const matchData = url.match(/\/(v[^.]*)/)
    return matchData && matchData[1]
  }
}
