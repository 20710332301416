/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

const HeaderRow = ({ status }) => {
  return (
    <div className="grid grid-cols-8 gap-1 hidden md:grid px-4 py-2 border-[1px] border-grayscale-3">
      <div className="col-span-6 ml-15 pl-10">
        <span className="label-all-caps text-black-1">
          <FormattedMessage
            id="shared.charity"
            defaultMessage="Charity"
          />
        </span>
      </div>
      <div className="col-span-2 text-end">
        <span className="label-all-caps text-black-1">
          {status === 'scheduled' ? 'Scheduled hours' : 'Hours Volunteered'}
        </span>
      </div>
    </div>
  )
}

export default HeaderRow
