// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Paper from '@material-ui/core/Paper'

// WeSpire
import ScreenReader from 'components/ui/screen_reader_text'
import { sharedTranslations } from 'components/shared/translations'

// Sub-Components
import { CampaignModel } from './campaign_model'
import { CampaignDetails } from './details'
import { CampaignParticipation } from './participation'

/** Header with a brief description of a campaign */
const CampaignAbout = ({ campaign }) => (
  <Paper
    className="[ d-flex flex-column | flex-md-row ] [ p-4 ]"
    component="section"
  >
    <ScreenReader as="h2" text={sharedTranslations.details} />
    <CampaignDetails campaign={campaign} className="w-100 mxw-8" />
    <div className="[ border-left mx-4 ] [ show-md-up ]" />
    <CampaignParticipation
      avatars={campaign.participants.map(
        (participant) => participant.avatarUrl
      )}
      className="[ w-100 mxw-3 ] [ mt-4 mt-md-0 ]"
      hasStarted={campaign.hasStarted}
      participantsCount={campaign.participantsCount}
      totalActionsTaken={campaign.totalParticipationIndicators}
    />
  </Paper>
)

CampaignAbout.propTypes = {
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
}

export default CampaignAbout
