import gql from 'graphql-tag'

export const CHANNEL_BREADCRUMB_QUERY = gql`
  query channel($id: ID!) {
    brandConfig {
      channelNavbarEnabled
      id
    }
    channel(id: $id) {
      id
      name
    }
  }
`

export const GROUP_BREADCRUMB_QUERY = gql`
  query group($id: ID!) {
    brandConfig {
      id
    }
    group(id: $id) {
      id
      name
    }
  }
`
