// Vendor
import gql from 'graphql-tag'

export const ANNOUNCEMENT_FRAGMENT = gql`
  fragment AnnouncementAttributes on GroupAnnouncement {
    author {
      avatarUrl
      id
      name
    }
    announcementHtml
    id
    publishedAt
    selectedLeaderTitle
  }
`
