// Setup
import React from 'react'
import { defineMessages } from 'react-intl'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import GoogleAuthForm from './form'
import { DocumentTitle } from 'components/shared/document_title'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  pageTitle: {
    defaultMessage: 'Log in with Google',
    id: 'googleAuth.pageTitle',
  },
})

const GoogleAuth = () => (
  <DocumentTitle
    title={intl.formatMessage(messages.pageTitle)}
    unauthorizedPage
  >
    <CenteredContent className="p-4">
      <GoogleAuthForm />
    </CenteredContent>
  </DocumentTitle>
)

export default GoogleAuth
