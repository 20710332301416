import v from 'voca'

export class CloudinaryImageThumbnailUrl {
  constructor(cloudinaryImageUrl, dimensions = { height: 100, width: 100 }) {
    if (typeof dimensions !== 'object') {
      throw new TypeError('Dimensions must be an object.')
    }

    this.startingUrl = cloudinaryImageUrl
    this.height = dimensions.height || 100
    this.width = dimensions.width || 100
  }

  create() {
    const searchTerm = 'upload/'
    const index = v.indexOf(this.startingUrl, searchTerm)
    const insertionPoint = index + searchTerm.length

    return v.insert(
      this.startingUrl,
      `c_fill,dpr_2.0,w_${this.width},h_${this.height}/`,
      insertionPoint
    )
  }
}

// Converts the slug to a full "secure" URL.
// E.g. "image/upload/version_number/image_id.jpg#signature_data"
//  -> "https://res.cloudinary.com/hauyptxwi/image/upload/version_number/image_id.jpg"
export function slugToSecureUrl(cloudinaryConfig, slug) {
  return `https://res.cloudinary.com/${cloudinaryConfig.cloudName}/${
    slug.split('#')[0]
  }`
}
