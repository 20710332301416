// Setup
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// WeStyle
import Header from 'src/common/Header'
import Footer from 'src/common/Footer'

const Layout = (props) => {
  const {
    children,
    displayNotification,
    location,
    notification,
    showFooter,
    showNavigator,
  } = props

  useEffect(() => {
    document.body.parentElement.classList.remove('legacyStyles')
    import(/* webpackChunkName: "westyle-dynamic" */ 'src/css/westyle.css')
  }, [])

  return (
    <div className="w-full min-h-screen grid grid-cols-1 justify-items-center bg-gallery">
      <Header />
      <div className="xl:w-[76.5rem] w-full">{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  displayNotification: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }),
  notification: PropTypes.string,
  showFooter: PropTypes.bool,
  westyle: PropTypes.bool,
  showNavigator: PropTypes.bool.isRequired,
}

Layout.defaultProps = {
  location: { pathname: '' },
  notification: '',
  showFooter: true,
}

export default Layout
