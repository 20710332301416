import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'

// WeSpire
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { groupAnnouncementsRoute } from '../routes'
import { sharedMessages } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { groupOrChapterLabel } from 'components/setup/groups_management/utils'

const { formatMessage } = intl

const messages = defineMessages({
  emails: {
    defaultMessage: 'Emails & Notifications',
    id: 'groupSummaryNav.emails',
  },
  groupLeaders: {
    defaultMessage: 'Leaders',
    id: 'groupSummaryNav.groupLeaders',
  },
  members: {
    defaultMessage: 'Members',
    id: 'groupSummaryNav.members',
  },
  resources: {
    defaultMessage: 'Resources & Files',
    id: 'groupSummaryNav.resources',
  },
  viewGroup: {
    defaultMessage: 'View {type}',
    id: 'groupSummaryNav.viewGroup',
  },
})

export const GroupSummaryNav = ({ group }) => {
  const {
    enableGroupAnnouncementsList,
    enableGroupManagementGroupDetailsTab,
    enableGroupManagementGroupActivitiesTab,
    enableGroupManagementMembersTab,
    enableGroupManagementEmailsTab,
    enableGroupManagementResourcesTab,
    enableGroupManagementGroupLeadersTab,
    enableGroupManagementChaptersTab,
  } = useFlags()

  const links = [
    {
      enabled: true,
      external: true,
      id: 'viewGroup',
      link: group.isChapter
        ? `/groups/${group.parentGroup.slug}/chapters/${group.slug}`
        : `/groups/${group.slug}`,
      name: formatMessage(messages.viewGroup, {
        type: groupOrChapterLabel(group),
      }),
    },
    {
      enabled: enableGroupManagementGroupDetailsTab,
      id: 'groupDetails',
      link: `/management_panel/groups/${group.id}/edit?with_redirect_back=true`,
      name: formatMessage(sharedMessages.details),
    },
    {
      enabled: enableGroupManagementChaptersTab && !group.isChapter,
      id: 'groupChapters',
      link: `${group.id}/chapters`,
      name: formatMessage(sharedMessages.chapters, { count: 2 }),
    },
    {
      enabled: enableGroupManagementGroupLeadersTab,
      id: 'groupLeaders',
      link: group.isChapter
        ? `/management_panel/groups/${group.parentGroup.slug}/chapters/${group.slug}/roles?fromManagementPanel=true`
        : `/management_panel/groups/${group.slug}/roles?fromManagementPanel=true`,
      name: formatMessage(messages.groupLeaders),
    },
    {
      enabled: enableGroupManagementGroupActivitiesTab,
      id: 'groupActivities',
      link: `${group.id}/activities`,
      name: formatMessage(sharedMessages.activities, { count: 2 }),
    },
    {
      enabled: enableGroupManagementMembersTab,
      id: 'members',
      link: `${group.id}/members`,
      name: formatMessage(messages.members),
    },
    {
      enabled: enableGroupManagementEmailsTab,
      id: 'emails',
      link: `${group.id}/emails-notifications`,
      name: formatMessage(messages.emails),
    },
    {
      enabled: enableGroupManagementResourcesTab,
      id: 'resources',
      link: `${group.id}/resources`,
      name: formatMessage(messages.resources),
    },
    {
      enabled: enableGroupAnnouncementsList,
      id: 'announcements',
      link: groupAnnouncementsRoute({ groupId: group.id }),
      name: formatMessage(sharedMessages.announcements),
    },
  ].filter((tabDetails) => tabDetails.enabled)

  return (
    <Stack
      as="nav"
      className="d-flex mb-5"
      data-test="group-summary-nav"
      space={0}
    >
      {links.map(({ id, link, name, external }, index) => {
        return (
          <LinkButton
            className={cx('fs-2 text-black-2 fw-bold rounded-1', {
              'ml-2': index > 0,
            })}
            data-test={`group-nav-${id}-link`}
            key={id}
            target={external ? '_blank' : '_self'}
            to={link}
            variant="neutral"
          >
            {name}
          </LinkButton>
        )
      })}
    </Stack>
  )
}

GroupSummaryNav.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isChapter: PropTypes.bool.isRequired,
    parentGroup: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    slug: PropTypes.string.isRequired,
  }).isRequired,
}
