import gql from 'graphql-tag'

export const privacyPolicyQuery = gql`
  query privacyPolicyQuery {
    brand {
      name
      id
    }
    currentPrivacyPolicy {
      createdAt
      id
      text
    }
  }
`
