// Setup
import React from 'react'

/**
 A helper component that wraps our giving badge SVG into a react component.
 This allows us to inline the SVG.

 This SVG originates from zeplin, was optimized via svgo, and was
 modified so that the outer most circle uses a solid fill instead of one with
 opacity. This last step was done in order to make the SVG not transparent
 and thus work correctly with drop-shadows.
*/
const GivingBadge = (props) => (
  <svg aria-hidden height={160} width={160} {...props}>
    <g>
      <circle cx={80} cy={80} fill="#fff0e5" r={80} />
      <circle cx={80} cy={80} fill="#FA6400" fillOpacity={0.2} r={60} />
      <path
        d="M56.726 77.28l-1.34-.085a9.738 9.738 0 00-3.265.46c-3.377 1.005-5.721 3.49-7.033 7.452-.446 1.284-.725 2.902-.837 4.856-.223 3.851.46 7.465 2.051 10.842l5.651 14.274 14.861-3.851c3.628-.698 6.949-2.275 9.963-4.73 1.507-1.228 2.65-2.4 3.432-3.517 2.428-3.404 3.056-6.753 1.884-10.046a9.208 9.208 0 00-1.507-2.888l-.963-1.089c-2.874-2.484-6.042-3.405-9.502-2.763a13.916 13.916 0 00-3.181 1.13l-1.214.67-.126-1.381a16.005 16.005 0 00-1.005-3.223c-1.507-3.182-4.13-5.219-7.87-6.112zm59.148-3.35c-.055-1.953-.265-3.586-.627-4.897-1.228-4.019-3.489-6.586-6.782-7.703a9.653 9.653 0 00-3.223-.544l-1.423.042c-3.768.725-6.447 2.665-8.038 5.819a12.61 12.61 0 00-1.088 3.18l-.251 1.34-1.13-.711a12.81 12.81 0 00-3.182-1.172c-3.432-.81-6.642-.014-9.628 2.386l-.92 1.046a8.601 8.601 0 00-1.633 2.805c-1.284 3.265-.782 6.642 1.507 10.13.753 1.144 1.856 2.358 3.307 3.642 2.93 2.567 6.195 4.256 9.795 5.065l14.693 4.354 6.196-14.065c1.702-3.294 2.511-6.866 2.427-10.717zM79.665 45.256l-1.298-.544c-3.711-.921-6.962-.293-9.753 1.883a12.086 12.086 0 00-2.344 2.428l-.754 1.13-.753-1.13a13.83 13.83 0 00-2.344-2.428c-2.791-2.176-6.042-2.804-9.754-1.883l-1.298.544a9.491 9.491 0 00-2.679 1.884c-2.54 2.427-3.502 5.707-2.888 9.837.195 1.34.684 2.902 1.465 4.688 1.563 3.544 3.81 6.46 6.74 8.749l11.511 10.13 11.512-10.13c2.93-2.288 5.177-5.205 6.74-8.749.78-1.786 1.27-3.349 1.465-4.688.614-4.13-.35-7.41-2.889-9.837a9.491 9.491 0 00-2.679-1.884z"
        fill="#FA6400"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default GivingBadge
