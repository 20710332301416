// Vendor
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { Heading } from 'components/ui/heading'
import { SharedNavBreadcrumb } from 'components/shared/nav_breadcrumb'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { ActivityHero } from 'components/shared/activity_page/hero'

import ActivitySeriesAbout from './about'
import { client } from 'utilities/we_apollo'
import { GET_ACTIVITY_SERIES_BY_ID } from './queries'
import { FETCH_STATUS } from '../../utilities/fetch_statuses'

import ActivitySeriesCard from './card'

export const ActivitySeries = () => {
  const { formatMessage } = intl
  const { id: activitySeriesId } = useParams()
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.PENDING)
  const [activitySeries, setActivitySeries] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      setFetchStatus(FETCH_STATUS.LOADING)

      try {
        const {
          data: { activitySeries },
        } = await client.query({
          query: GET_ACTIVITY_SERIES_BY_ID,
          variables: {
            activitySeriesId,
          },
        })
        setActivitySeries(activitySeries)
        setFetchStatus(FETCH_STATUS.SUCCESS)
      } catch {
        setFetchStatus(FETCH_STATUS.ERROR)
      }
    }
    if (activitySeriesId) {
      fetch()
    }
  }, [activitySeriesId])

  if ([FETCH_STATUS.ERROR].includes(fetchStatus)) {
    return (
      <DocumentTitle
        title={formatMessage(sharedMessages.resourceNotFound, {
          resource: sharedTranslations.activitySeries,
        })}
      >
        <MissingResourcePage resourceName={sharedTranslations.activitySeries} />
      </DocumentTitle>
    )
  }

  if ([FETCH_STATUS.PENDING, FETCH_STATUS.LOADING].includes(fetchStatus)) {
    return <CenteredPaddedLoadingIndicator />
  }

  if (fetchStatus === FETCH_STATUS.SUCCESS) {
    const { name, campaigns, imageUrl } = activitySeries

    return (
      <DocumentTitle title={`${name}`}>
        <SharedNavBreadcrumb currentLinkName={name} />

        <div className="[ pb-7 ]">
          <ActivityHero
            imageUrl={imageUrl}
            label={sharedTranslations.activitySeries}
            title={name}
          />

          <CenteredContent className="position-relative" pull>
            <ActivitySeriesAbout activitySeries={activitySeries} />

            <Heading className="p-3" level={2}>
              Activities
            </Heading>

            <Stack divider="default" space={3}>
              {campaigns.map((activity, i) => (
                <ActivitySeriesCard
                  activity={activity}
                  isLocked={
                    !(i === 0 || campaigns[i - 1]?.achievement.awardedTo)
                  }
                  key={activity.id}
                />
              ))}
            </Stack>
          </CenteredContent>
        </div>
      </DocumentTitle>
    )
  }
}
