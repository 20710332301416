// Setup
import PropTypes from 'prop-types'
import React from 'react'

// Vendor
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import v from 'voca'

// WeSpire
import paths from './paths'

const mapStateToProps = (state) => ({
  isAuthenticated: !v.isEmpty(state.session.token),
})

export const AuthenticatedRoute = ({
  render,
  isAuthenticated,
  isNative,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isNative) {
        if (isAuthenticated) {
          return render(props)
        } else {
          return <Redirect to={paths.googleLogin} />
        }
      } else {
        return render(props)
      }
    }}
  />
)

AuthenticatedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isNative: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(AuthenticatedRoute)
