import { defineMessages } from 'react-intl'

export const groupAnnouncementsMessages = defineMessages({
  loadingError: {
    defaultMessage: 'There was a problem loading the announcements',
    id: 'groupAnnouncements.loadingError',
  },
  noAnnouncements: {
    defaultMessage: 'There are no Announcements yet.',
    id: 'groupAnnouncements.noAnnouncements',
  },
})
