import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cooking from '../../styles/assets/Cooking-pana.png'
import subway from '../../styles/assets/Subway-pana.png'
import switches from '../../styles/assets/Switches-pana.png'

const Fact = ({ factHeader, factImage, factText }) => {
  const [image, setImage] = useState('')
  const [imageAlt, setImageAlt] = useState('')

  useEffect(() => {
    if (factImage === 'housing') {
      setImage(switches)
      setImageAlt('image of women using electricity')
    }
    if (factImage === 'consumption') {
      setImage(cooking)
      setImageAlt('image of someone eating')
    }
    if (factImage === 'transportation') {
      setImage(subway)
      setImageAlt('image of public transit')
    }
  }, [factImage])

  return (
    <div className="my-8 px-1 md:px-4 border border-green rounded-sm">
      <div className="flex flex-col justify-center md:flex-row-reverse mb-4 mt-3">
        <div className="md:w-3/4 ml-2 flex flex-col justify-center">
          <h2
            className="text-green md:text-xl my-2"
            data-test="fact-heading-test"
          >
            {factHeader}
          </h2>
          <p className="text-gray md:text-lg" data-test="fact-description-test">
            {factText}
          </p>
        </div>
        {factImage && (
          <div className="md:w-1/5 ">
            <img
              alt={imageAlt}
              data-test="fact-image-test"
              height="150"
              src={image}
              width="150"
            />
          </div>
        )}
      </div>
    </div>
  )
}

Fact.propTypes = {
  factHeader: PropTypes.string,
  factImage: PropTypes.string,
  factText: PropTypes.string,
}

Fact.defaultProps = {
  factHeader: '',
  factImage: '',
  factText: '',
}

export default Fact
