// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import Paper from '@material-ui/core/Paper'

// WeSpire
import PageSectionHeader from 'components/ui/page_section/header'

/** Container for major page sections. Includes top margin and
    label via <PageSectionHeading>. Content area has white background with
    elevation. */
const PageSection = ({
  children,
  headerProps,
  label,
  paperProps,
  ...other
}) => (
  <div {...other} className={cx('mt-4', other.className)}>
    <PageSectionHeader {...headerProps} label={label} />
    <Paper {...paperProps}>{children}</Paper>
  </div>
)

PageSection.propTypes = {
  /** everything rendered inside <Paper> node */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** props applied to <PageSectionHeader> node */
  headerProps: PropTypes.object,
  /** text rendered in <PageSectionHeader> */
  label: PropTypes.string.isRequired,
  /** props applied to <Paper> node */
  paperProps: PropTypes.object,
}

PageSection.defaultProps = {
  headerProps: {},
  paperProps: {
    elevation: 1,
  },
}

export default PageSection
