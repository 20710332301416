import React from 'react'
import Select from 'react-select'

const CurrencyDropdown = ({ enabledCurrencies, setSeletectedCurrency }) => {

  const handleCurrencyChange = (selectedOption) => {
    setSeletectedCurrency(selectedOption)
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '0.0625rem solid #cacdd4',
      borderRadius: '0.30rem',
      color: 'black',
      fontFamily: 'Poppins',
      fontWeight: '200',
      minHeight: '3.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      paddingTop: '0.5rem',
      placeholder: '#9E9E9E',
      width: '100%',
      '@media (min-width: 50.5rem)': {
        width: '20rem',
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0rem',
      '@media (min-width: 50.5rem)': {
        marginTop: '-2rem',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#e2e8f0' : 'white',
      color: 'black',
      ':hover': {
        backgroundColor: '#e2e8f0',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
  }

  const desktopMediaQuery = '@media (min-width: 50.5rem)'
  if (customStyles.control) {
    customStyles.control[desktopMediaQuery] = {
      width: '6rem', 
    }
  }
  if (customStyles.menu) {
    customStyles.menu[desktopMediaQuery] = {
      marginTop: '-2rem', 
    }
  }

  return (
    <Select
      options={enabledCurrencies}
      getOptionLabel={(option) => option.name}
      onChange={handleCurrencyChange}
      placeholder="Choose your currency"
      styles={customStyles}
    />
  )
}

export default CurrencyDropdown
