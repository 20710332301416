// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import Banner from 'components/application/banner'
import ContentHeader from 'components/setup/application/content_header'
import DefaultActions from './default_actions'
import { getTimeZoneLabel } from 'utilities/date_formatter'
import { intl } from 'utilities/localization'
import SortableActions from './sortable_actions'
import SortableCollection from './sortable_collection'
import Stack from 'components/ui/stack'
import { LIST_TYPES } from '../constants'
import {
  channelLayoutParams,
  updateChannelActivities as updateChannelActivitiesUtils,
} from './utils'
import WeSpireAPI from 'utilities/wespire_api'

const { formatMessage } = intl

const messages = defineMessages({
  layoutSaved: {
    defaultMessage: 'Layout successfully saved.',
    id: 'channelManagerList.layoutSaved',
  },
  saveError: {
    defaultMessage: 'There was a problem saving the layout.',
    id: 'channelManagerList.saveError',
  },
  zeroChannels: {
    defaultMessage: 'Invalid layout: at least one Channel must be live.',
    id: 'channelManagerList.zeroChannels',
  },
})

class ChannelManagerList extends Component {
  static propTypes = {
    banner: PropTypes.shape({
      message: PropTypes.string,
      variant: PropTypes.string,
    }),
    brandID: PropTypes.string.isRequired,
    channels: PropTypes.object.isRequired,
    className: PropTypes.string,
    displayNotification: PropTypes.func.isRequired,
  }

  static defaultProps = {
    banner: {
      message: null,
      variant: null,
    },
    className: '',
  }

  state = {
    channels: this.props.channels,
    initialChannels: this.props.channels, // eslint-disable-line react/no-unused-state
    isSortable: false,
    isSubmitting: false,
  }

  componentDidMount() {
    const { banner, displayNotification } = this.props

    // If the there is a banner message to display on page load...
    if (banner.message) {
      // TO-DO: change to displayBanner()
      displayNotification(banner.message)
      // Only display the banner once, not on subsequent historical visits
      // (e.g. back button).
      history.replaceState({}, 'Channel Manager', window.location.pathname)
    }
  }

  handleCancelChanges = () => {
    this.setState((prevState) => ({
      channels: prevState.initialChannels,
      isSortable: false,
    }))
  }

  handleChangeLayout = () => this.setState({ isSortable: true })

  handleChannelExpansion = (channelId) => {
    this.setState((prevState) => ({
      channels: {
        [LIST_TYPES.published]: this.toggleChannelExpanded(
          prevState.channels[LIST_TYPES.published],
          channelId
        ),
        [LIST_TYPES.unpublished]: this.toggleChannelExpanded(
          prevState.channels[LIST_TYPES.unpublished],
          channelId
        ),
      },
      initialChannels: {
        [LIST_TYPES.published]: this.toggleChannelExpanded(
          prevState.initialChannels[LIST_TYPES.published],
          channelId
        ),
        [LIST_TYPES.unpublished]: this.toggleChannelExpanded(
          prevState.initialChannels[LIST_TYPES.unpublished],
          channelId
        ),
      },
    }))
  }

  handleSaveChanges = () => {
    if (this.state.channels.published.length === 0) {
      this.props.displayNotification(formatMessage(messages.zeroChannels))
      return
    }

    const params = channelLayoutParams(this.props.brandID, this.state.channels)

    this.setState({ isSubmitting: true })

    WeSpireAPI()
      .patch('/channel_layout', params)
      .then(() => {
        this.setState((prevState) => ({
          initialChannels: prevState.channels,
          isSortable: false,
          isSubmitting: false,
        }))
        this.props.displayNotification(formatMessage(messages.layoutSaved))
      })
      .catch(() => {
        this.setState({ isSubmitting: false })
        this.props.displayNotification(formatMessage(messages.saveError))
      })
  }

  toggleChannelExpanded = (channels, channelId) => {
    const decoratedChannels = channels.map((channel) => {
      if (channel.id === channelId) {
        return { ...channel, expanded: !channel.expanded }
      }
      return channel
    })

    return decoratedChannels
  }

  updateChannelActivities = (channelId, updateFunction) => {
    const channels = updateChannelActivitiesUtils(
      channelId,
      cloneDeep(this.state.channels),
      updateFunction
    )

    this.setState({ channels })
  }

  updateChannels = (updater) => {
    this.setState((prevState) => ({
      ...prevState,
      channels: updater(prevState.channels),
    }))
  }

  render() {
    const { className, displayNotification } = this.props
    const { channels, isSortable, isSubmitting } = this.state

    return (
      <div className={className}>
        <ContentHeader
          helpIcon={{
            content: `
              After clicking 'Change Layout', drag and drop Channels and Activities to change the
              order in which they are displayed. Move Channels between lists to toggle them as live
              or hidden. Move Activities between Channels to toggle which Channel they belong to.
            `,
            title: 'Manage the visual layout of the Take Action page',
          }}
          text="Channel Manager"
        >
          {isSortable ? (
            <SortableActions
              isSubmitting={isSubmitting}
              onCancel={this.handleCancelChanges}
              onSave={this.handleSaveChanges}
            />
          ) : (
            <DefaultActions
              newChannelPath="/management_panel/channels/new"
              onChangeLayout={this.handleChangeLayout}
            />
          )}
        </ContentHeader>
        <Banner />

        <Stack className="p-4" space={3}>
          <p className="fs-1 px-3 text-black-2 text-right">
            All times displayed in {getTimeZoneLabel()}
          </p>

          <Stack space={6}>
            <SortableCollection
              channels={channels.published}
              displayNotification={displayNotification}
              id={LIST_TYPES.published}
              isSortable={isSortable}
              onChannelExpand={this.handleChannelExpansion}
              updateChannelActivities={this.updateChannelActivities}
              updateChannels={this.updateChannels}
            />

            <SortableCollection
              channels={channels.unpublished}
              displayNotification={displayNotification}
              id={LIST_TYPES.unpublished}
              isSortable={isSortable}
              onChannelExpand={this.handleChannelExpansion}
              updateChannelActivities={this.updateChannelActivities}
              updateChannels={this.updateChannels}
            />

            {isSortable && (
              <div className="d-flex justify-content-end p-4">
                <SortableActions
                  isSubmitting={isSubmitting}
                  onCancel={this.handleCancelChanges}
                  onSave={this.handleSaveChanges}
                />
              </div>
            )}
          </Stack>
        </Stack>
      </div>
    )
  }
}

export const undecorated = ChannelManagerList

export default DragDropContext(HTML5Backend)(ChannelManagerList)
