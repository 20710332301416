// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import { DescriptionListItem } from './description_list_item'
import Stack from 'components/ui/stack'

/**
 * <dl> element that uses <Stack> to automatically create consistent vertical
 * rhythm between children <dt> and <dd> elements. Designed to receive
 * <DescriptionListItem> components as children.
 */
const DescriptionList = ({ children, ...other }) => (
  <Stack
    {...other}
    as="dl"
    className={cx(other.className)}
    space={{ _: 3, sm: 2 }}
  >
    {children}
  </Stack>
)

DescriptionList.propTypes = {
  children: PropTypes.node.isRequired,
}

export { DescriptionList, DescriptionListItem }
