// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// Vendor
import Formsy from 'formsy-react'

// WeSpire
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'
import EditableImageAttachment from 'components/application/editable_image_attachment'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormActionBarSecondaryButton from 'components/form/action_bar/secondary_button'
import FormFieldset from 'components/form/fieldset'
import FormFieldsetGroup from 'components/form/fieldset_group'
import FormLimitedTextField from 'components/form/limited_text_field'
import FormTeamVisibilityField from 'components/form/team_visibility_field'
import FormTextField from 'components/form/text_field'
import FormSelect from 'components/form/select'
import Link from 'components/shared/link'
import { getUrlParams } from 'utilities/get_url_params'
import { suggestionPropType } from 'components/form/autocomplete_utils'
import WeSpireAPI from 'utilities/wespire_api'
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { ErrorList } from 'components/form/error_list'

const backPath = '/management_panel/channels'

const messages = defineMessages({
  bannerImageHelperText: {
    defaultMessage:
      'This image will appear below the Activities page Channel navigation when this Channel is selected.',
    id: 'channelForm.bannerImageHelperText',
  },
  bannerImageLabel: {
    defaultMessage: 'Banner',
    id: 'channelForm.bannerImageLabel',
  },
  bannerRecommendationText: {
    defaultMessage: 'Recommended minimum image size: 1600x900 pixels.',
    id: 'channelForm.bannerRecommendationText',
  },
  channelDetailsDescriptionHelperText: {
    defaultMessage:
      'A short description that will explain what this Channel is about.',
    id: 'channelForm.channelDetailsDescriptionHelperText',
  },
  channelDetailsHeader: {
    defaultMessage: 'Details',
    id: 'channelForm.channelDetailsHeader',
  },
  channelDetailsNameHelperText: {
    defaultMessage:
      'The recommended length of a Channel name is 30 characters.',
    id: 'channelForm.channelDetailsNameHelperText',
  },
  channelImagesHeaderText: {
    defaultMessage: 'Images',
    id: 'channelForm.channelImagesHeaderText',
  },
  channelNameField: {
    defaultMessage: 'Name',
    id: 'channelForm.channelNameField',
  },
  createANewChannelLink: {
    defaultMessage:
      'For a new default locale, please <link>create a new Channel</link>.',
    id: 'channelForm.createANewChannelLink',
  },
  createdSuccessNotification: {
    defaultMessage: '<bold>{name}</bold> was successfully created.',
    id: 'channelForm.createdSuccessNotification',
  },
  deleteButton: {
    defaultMessage: 'Delete',
    id: 'channelForm.deleteButton',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete "{name}"?',
    id: 'channelForm.deleteConfirmation',
  },
  editChannelPageTitle: {
    defaultMessage: 'Edit Channel',
    id: 'channelForm.editChannelPageTitle',
  },
  errorNotification: {
    defaultMessage: 'Please fix the errors in the form.',
    id: 'channelForm.errorNotification',
  },
  exceptionNotification: {
    defaultMessage: '{verb} this Channel',
    id: 'channelForm.exceptionNotification',
  },
  logoImageHelperText: {
    defaultMessage:
      'This image will appear next to the Channel name on the Take Action page.',
    id: 'channelForm.logoImageHelperText',
  },
  logoImageLabel: {
    defaultMessage: 'Logo',
    id: 'channelForm.logoImageLabel',
  },
  logoImageRecommendationText: {
    defaultMessage: 'Recommended minimum image size: 120x120 pixels.',
    id: 'channelForm.logoImageRecommendationText',
  },
  primaryHeader: {
    defaultMessage: 'Default Locale',
    id: 'channelForm.primaryHeader',
  },
  primaryLocaleAction: {
    defaultMessage:
      'Once saved, the default locale <bold>cannot be changed</bold>.',
    description: 'Helper text on default locale',
    id: 'channelForm.primaryLocaleAction',
  },
  primaryLocaleInfoText: {
    defaultMessage:
      'This is the default language used for editing this Channel. Additional locales will be translated from this default locale.',
    description: 'Helper text on implication of selecting a default locale',
    id: 'channelForm.primaryLocaleInfoText',
  },
  selectPrimaryLocale: {
    defaultMessage: "Select the Channel's default locale",
    id: 'channelForm.selectPrimaryLocale',
  },
  successNotification: {
    defaultMessage: 'Channel successfully updated.',
    id: 'channelForm.successNotification',
  },
  teamFiltersHelperText: {
    defaultMessage:
      'Specify which participants should have access to this Channel.',
    id: 'channelForm.teamFiltersHelperText',
  },
  visibilityHeaderText: {
    defaultMessage: 'Visibility',
    id: 'channelForm.visibilityHeaderText',
  },
})

const ChannelForm = ({
  brandConfig,
  brandId,
  channel,
  displayNotification,
  formLocale: formLocaleFromProps,
  formRequirements,
  handleUpdatePublish,
  isEdit,
  teams,
}) => {
  const { formatMessage } = intl

  const [deleteHero, setDeleteHero] = useState(false)
  const [deleteLogo, setDeleteLogo] = useState(false)
  const [formLocale, setFormLocale] = useState(formLocaleFromProps)
  const [imageUploading, setImageUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const cloudinaryConfig = formRequirements.cloudinary
  const submitDisabled = isDeleting || isSubmitting || imageUploading
  const defaultValues = {
    desc: channel?.defaultDescription,
    name: channel?.defaultName,
    teamFilters: channel?.teamFilters || [],
  }

  useEffect(() => {
    // If the Channel was just created...
    if (isEdit && getUrlParams().created) {
      displayBanner({
        content: (
          <>
            {formatMessage(messages.createdSuccessNotification, {
              bold: (str) => (
                <strong key="createdSuccessNotification">{str}</strong>
              ),
              name: channel.defaultName,
            })}
          </>
        ),
        fullWidth: true,
        variant: 'success',
      })
      // Only display the banner once, not on subsequent historical visits
      // (e.g. back button).
      history.replaceState(
        {},
        formatMessage(messages.editChannelPageTitle),
        window.location.pathname
      )
    }
  }, [channel, isEdit])

  const handleSubmit = (values) => {
    setIsSubmitting(true)

    var channelProps = {
      ...values,
      brand_id: brandId,
      delete_hero_image: deleteHero,
      delete_logo: deleteLogo,
    }

    return WeSpireAPI()({
      data: {
        channel: channelProps,
      },
      method: isEdit ? 'patch' : 'post',
      url: `/channels/${isEdit ? channel.id : ''}`,
    })
      .then((data) => {
        if (isEdit) {
          displayNotification(formatMessage(messages.successNotification))
          const { channel } = data.data
          handleUpdatePublish(channel.teamFilters, channel.updatedAt)

          // Remove banner if it was displayed after successful create. It's no
          // longer relevant and this prevents us from focusing it.
          hideBanner()
        } else {
          const {
            data: {
              channel: { id },
            },
          } = data

          // Redirect to /edit view and display successful create banner.
          window.location.href = `${backPath}/${id}/edit?created`
        }
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        if (errors) {
          displayBanner({
            as: 'div',
            content: <ErrorList errors={errors} />,
            fullWidth: true,
            variant: 'error',
          })
        } else {
          setIsSubmitting(false)
          handleException('save')
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handleDelete = () => {
    const { id, defaultName } = channel

    const deleteMessage = formatMessage(messages.deleteConfirmation, {
      name: `${defaultName}`,
    })
    const didNotConfirmDelete = !window.confirm(deleteMessage)

    if (didNotConfirmDelete) {
      return
    }

    setIsDeleting(true)

    return WeSpireAPI()
      .delete(`/channels/${id}`)
      .then(() => {
        setIsDeleting(false)

        // Redirect to index view and display successful delete banner.
        window.location.href = `${backPath}?deleted_id=${id}`
      })
      .catch(({ response }) => {
        setIsDeleting(false)

        const errors = response.data.errors
        if (errors) {
          displayBanner({ content: errors, fullWidth: true, variant: 'error' })
        } else {
          setIsDeleting(false)
          handleException('delete')
        }
      })
  }

  const handleException = (verb) => {
    const { formatMessage } = intl
    displayExceptionBanner({
      fullWidth: true,
      operation: formatMessage(messages.exceptionNotification, {
        verb,
      }),
    })
  }

  const handleImageUploading = (isUploading) => {
    setImageUploading(isUploading)
  }

  const handleInvalidSubmit = () => {
    const { formatMessage } = intl

    displayBanner({
      content: formatMessage(messages.errorNotification),
      fullWidth: true,
      variant: 'error',
    })
  }

  const handleRemoveHero = () => {
    setDeleteHero(true)
  }

  const handleRemoveLogo = () => {
    setDeleteLogo(true)
  }

  const handleUploadHero = () => {
    setDeleteHero(false)
  }

  const handleUploadLogo = () => {
    setDeleteLogo(false)
  }

  const handleChangeValue = (value) => {
    setFormLocale(value)
  }

  return (
    <Formsy
      noValidate
      onInvalidSubmit={handleInvalidSubmit}
      onValidSubmit={handleSubmit}
    >
      <FormFieldsetGroup>
        <GraphQLFeatureFlag
          error={null}
          featureDisabledError={null}
          featureName="translation"
          loader={null}
        >
          <FormFieldset
            label={formatMessage(messages.primaryHeader)}
            variant="shaded"
          >
            <FormTextField
              className="d-none"
              name="form_locale"
              value={formLocale}
            />
            <div className="row">
              {isEdit ? (
                <div className="col">
                  <span className="fw-bold" data-test="default_locale_label">
                    {channel.defaultLocaleLabel}
                  </span>
                </div>
              ) : (
                <div className="col">
                  <FormSelect
                    hideBlankOption
                    hideLabel
                    name="default_locale"
                    onChange={handleChangeValue}
                    options={brandConfig.enabledLocales}
                    required
                    selectProps={{
                      'aria-describedby': 'default_locale-helper',
                    }}
                    textFieldProps={{
                      label: formatMessage(messages.primaryHeader),
                    }}
                    value={brandConfig.defaultLocale}
                  />
                </div>
              )}
              <Stack className="col">
                <p id="default_locale-helper">
                  {formatMessage(messages.primaryLocaleInfoText)}{' '}
                  {isEdit ? (
                    <>
                      {formatMessage(messages.createANewChannelLink, {
                        link: (str) => (
                          <Link
                            key="createANewChannelLink"
                            to={'/management_panel/channels/new'}
                          >
                            {str}
                          </Link>
                        ),
                      })}{' '}
                    </>
                  ) : (
                    formatMessage(messages.primaryLocaleAction, {
                      bold: (str) => (
                        <strong key="primaryLocaleAction">{str}</strong>
                      ),
                    })
                  )}
                </p>
              </Stack>
            </div>
          </FormFieldset>
        </GraphQLFeatureFlag>

        <FormFieldset
          label={formatMessage(messages.channelDetailsHeader)}
          variant="shaded"
        >
          <div className="row">
            <div className="col">
              <FormLimitedTextField
                label={formatMessage(messages.channelNameField)}
                maxLength={40}
                name="name"
                required
                textFieldProps={{
                  inputProps: {
                    'aria-describedby': 'name-helper',
                  },
                }}
                value={defaultValues.name}
              />
            </div>
            <Stack className="col">
              <p id="name-helper">
                {formatMessage(messages.channelDetailsNameHelperText)}
              </p>
            </Stack>
          </div>
          <div className="row">
            <div className="col">
              <FormLimitedTextField
                label={intl.formatMessage(sharedMessages.description)}
                maxLength={200}
                multiline
                name="description"
                textFieldProps={{
                  inputProps: {
                    'aria-describedby': 'description-helper',
                  },
                }}
                value={defaultValues.desc}
              />
            </div>
            <Stack className="col">
              <p id="description-helper">
                {formatMessage(messages.channelDetailsDescriptionHelperText)}
              </p>
            </Stack>
          </div>
        </FormFieldset>

        <FormFieldset
          label={formatMessage(messages.channelImagesHeaderText)}
          variant="shaded"
        >
          <Stack space={2}>
            <label className="fs-2 fw-semi-bold" id="hero_image-label">
              {formatMessage(messages.bannerImageLabel)}
            </label>
            <div className="row">
              <div className="col">
                <EditableImageAttachment
                  cloudinaryConfig={cloudinaryConfig}
                  customHeight="66px"
                  imageClassName="w-100"
                  imageHeight="66"
                  imageWidth="400"
                  name="hero_image"
                  onImageUpload={handleUploadHero}
                  onImageUploading={handleImageUploading}
                  onRemoveImage={handleRemoveHero}
                  value={channel && channel.heroImage}
                />
              </div>
              <Stack className="col" id="hero_image-helper" space={1}>
                <p>{formatMessage(messages.bannerImageHelperText)}</p>
                <p className="fs-1 text-black-2">
                  {formatMessage(messages.bannerRecommendationText)}
                </p>
              </Stack>
            </div>
          </Stack>

          <Stack space={2}>
            <label className="fs-2 fw-semi-bold" id="logo-label">
              {formatMessage(messages.logoImageLabel)}
            </label>
            <div className="row">
              <div className="col">
                <EditableImageAttachment
                  cloudinaryConfig={cloudinaryConfig}
                  imageHeight="120"
                  imageWidth="120"
                  name="logo"
                  onImageUpload={handleUploadLogo}
                  onImageUploading={handleImageUploading}
                  onRemoveImage={handleRemoveLogo}
                  shape="circle"
                  value={channel && channel.logo}
                />
              </div>
              <Stack className="col" id="logo-helper" space={1}>
                <p>{formatMessage(messages.logoImageHelperText)}</p>
                <p className="fs-1 text-black-2">
                  {formatMessage(messages.logoImageRecommendationText)}
                </p>
              </Stack>
            </div>
          </Stack>
        </FormFieldset>

        <FormFieldset
          label={formatMessage(messages.visibilityHeaderText)}
          variant="shaded"
        >
          {teams.length > 0 && (
            <div className="[ row ] [ position-relative ]">
              <div className="col-12">
                <FormTeamVisibilityField
                  allTeams={teams}
                  aria-describedby="team_ids-helper"
                  className="w-100"
                  hideLabel
                  name="team_ids"
                  selectedTeams={defaultValues.teamFilters}
                />
              </div>
              <Stack className="[ col-6 ] [ absolute-top-right ]">
                <p id="team_ids-helper">
                  {formatMessage(messages.teamFiltersHelperText)}
                </p>
              </Stack>
            </div>
          )}
        </FormFieldset>

        <FormActionBar backPath={backPath} className="pt-5 pb-5">
          {isEdit && (
            <FormActionBarSecondaryButton
              disabled={submitDisabled}
              onClick={handleDelete}
            >
              {formatMessage(messages.deleteButton)}
            </FormActionBarSecondaryButton>
          )}
          <FormActionBarSubmitButton
            className="mr-5"
            disabled={submitDisabled}
            isSubmitting={isSubmitting}
          >
            {isEdit
              ? intl.formatMessage(sharedMessages.save)
              : intl.formatMessage(sharedMessages.create)}
          </FormActionBarSubmitButton>
        </FormActionBar>
      </FormFieldsetGroup>
    </Formsy>
  )
}

ChannelForm.propTypes = {
  brandConfig: PropTypes.shape({
    defaultLocale: PropTypes.string,
    enabledLocales: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  brandId: PropTypes.string.isRequired,
  channel: PropTypes.shape({
    defaultDescription: PropTypes.string,
    defaultLocale: PropTypes.string,
    defaultLocaleLabel: PropTypes.string,
    defaultName: PropTypes.string,
    description: PropTypes.string,
    heroImage: PropTypes.string,
    id: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
    teamFilters: PropTypes.arrayOf(suggestionPropType.isRequired),
    updatedAt: PropTypes.string.isRequired,
  }),
  displayNotification: PropTypes.func.isRequired,
  formLocale: PropTypes.string.isRequired,
  formRequirements: PropTypes.shape({
    cloudinary: PropTypes.shape({
      cloudName: PropTypes.string.isRequired,
      uploadPreset: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleUpdatePublish: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  teams: PropTypes.arrayOf(suggestionPropType.isRequired),
}

ChannelForm.defaultProps = {
  channel: null,
  isEdit: false,
  teams: [],
}

export default ChannelForm
