// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

/**
 * Container that (only on desktop) scrolls vertically when its content exceeds
 * 200px in height. Setting the tabIndex allows navigation of overflowing
 * content to keyboard users that would otherwise be inaccessible.
 */
const VerticalScrollBox = ({ ariaLabel, as, ...other }) => {
  const Component = as

  return (
    <Component
      {...other}
      aria-label={ariaLabel}
      className={cx('vertical-scrollbar', other.className)}
      tabIndex={0}
    />
  )
}

VerticalScrollBox.propTypes = {
  /** label content for screen readers  */
  ariaLabel: PropTypes.string.isRequired,
  /** renders component as custom element  */
  as: PropTypes.any,
}

VerticalScrollBox.defaultProps = {
  as: 'div',
}

export default VerticalScrollBox
