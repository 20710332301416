/* eslint-disable react/prop-types */
import React from 'react'

// westyle
import VolunteerPreview from 'src/pages/VolunteeringHistory/components/Tables/VolunteerPreview'
import HeaderRow from 'src/pages/VolunteeringHistory/components/Tables/HeaderRow'

const VolunteerTable = ({
  d4dConfiguration,
  data,
  enableMultiCharitiesVolunteer,
  isFlexible,
  isAllTime,
  status,
  sortedVolunteerLogs,
}) => {
  return (
    <div
      className="grid w-full rounded bg-white border-[1px] border-grayscale-3 mb-2"
      key={status}
    >
      {sortedVolunteerLogs &&
        sortedVolunteerLogs[status] &&
        sortedVolunteerLogs[status].length > 0 && (
          <>
            <HeaderRow status={status} />
            {sortedVolunteerLogs[status].map((volunteerLogs, index) => {
              return (
                <VolunteerPreview
                  data={data}
                  isAllTime={isAllTime}
                  d4dConfiguration={d4dConfiguration}
                  isFlexible={isFlexible}
                  key={index}
                  enableMultiCharitiesVolunteer={enableMultiCharitiesVolunteer}
                  status={status}
                  volunteerLogs={volunteerLogs}
                />
              )
            })}
          </>
        )}
    </div>
  )
}

export default VolunteerTable
