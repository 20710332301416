// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Chip from '@material-ui/core/Chip'
import cx from 'classnames'
import Downshift from 'downshift'
import keycode from 'keycode'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import v from 'voca'

// WeSpire
import AutocompleteSuggestion from 'components/form/autocomplete_suggestion'
import { suggestionPropType } from 'components/form/autocomplete_utils'

const itemToString = (item) => (item ? item.label : '')

class AutocompleteTextField extends Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.any,
    inputValue: PropTypes.string.isRequired,
    label: PropTypes.any,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBackspace: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSelection: PropTypes.func.isRequired,
    renderSuggestions: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(suggestionPropType.isRequired),
    setInputRef: PropTypes.func,
    textFieldProps: PropTypes.object,
  }

  static defaultProps = {
    autoComplete: 'off',
    className: null,
    error: false,
    helperText: null,
    label: null,
    multiple: false,
    selectedItems: [],
    setInputRef: () => {},
    textFieldProps: {},
  }

  state = { menuOpen: false }

  canMakeSelection = () =>
    this.props.multiple || this.props.selectedItems.length === 0

  handleKeyDown = (event) => {
    const { inputValue, onBackspace, selectedItems } = this.props
    const openKeys = ['space']
    const closeKeys = ['esc']
    const pressedKey = keycode(event)

    // Prevent typing additional entries when there's already a selection
    // and this isn't a multi-select.
    // We still allow backspace for deleting ant tab for navigating.
    const allowedKeys = ['backspace', 'tab']
    if (!this.canMakeSelection() && !allowedKeys.includes(pressedKey)) {
      event.preventDefault()
    }

    if (openKeys.includes(pressedKey)) {
      this.setState({ menuOpen: true })
    } else if (closeKeys.includes(pressedKey)) {
      this.setState({ menuOpen: false })
    } else if (
      selectedItems.length &&
      v.isBlank(inputValue) &&
      pressedKey === 'backspace'
    ) {
      onBackspace()
    }
  }

  render() {
    const {
      autoComplete,
      className,
      error,
      helperText,
      inputValue,
      label,
      name,
      onDelete,
      onInputChange,
      onSelection,
      renderSuggestions,
      selectedItems,
      setInputRef,
      textFieldProps,
    } = this.props
    let value = inputValue

    if (v.isBlank(inputValue)) {
      // The reason we're forcing the input value to be a string with a space
      // in it when there are selected items is so that the <TextField>
      // believes that it has a value, and therefore floats the label above the
      // selections instead of as an input placeholder. So it's a visual trick.
      // We're basically saying, "The presence of selections is the input
      // having value, even though that value is manifested as adornments
      // instead of as a literal input value."
      value = selectedItems.length > 0 ? ' ' : ''
    }

    // We do not want to open our menu when the user is not allowed
    // to make another selection.
    const isOpen = this.state.menuOpen && this.canMakeSelection()
    const uniqueId = `${name}-${Math.random()}`

    return (
      <Downshift
        inputValue={value}
        isOpen={isOpen}
        itemToString={itemToString}
        onChange={onSelection}
        selectedItem={selectedItems}
      >
        {({ getInputProps, getItemProps, isOpen, highlightedIndex }) => (
          <div className={cx('position-relative', className)}>
            <TextField
              {...textFieldProps}
              autoComplete={autoComplete}
              error={error}
              fullWidth
              helperText={helperText}
              id={uniqueId}
              InputLabelProps={{
                ...textFieldProps.InputLabelProps,
                className: 'text-sentence',
                htmlFor: uniqueId,
                id: `${name}-label`,
              }}
              inputProps={{
                ...textFieldProps.inputProps,
                className: 'd-inline-flex col',
                'data-test': name,
                id: uniqueId,
              }}
              InputProps={getInputProps({
                ...textFieldProps.InputProps,
                autoComplete: 'off',
                className: cx('flex-wrap', {
                  'p-2': selectedItems.length > 0,
                }),
                onBlur: () => this.setState({ menuOpen: false }),
                onChange: onInputChange,
                onFocus: () => this.setState({ menuOpen: true }),
                onKeyDown: this.handleKeyDown,
                startAdornment:
                  selectedItems.length > 0
                    ? selectedItems.map((item) => (
                        <Chip
                          className="m-1"
                          data-test="autocomplete-selection"
                          key={item.id}
                          label={item.selectionLabel || item.suggestionLabel}
                          onDelete={onDelete(item)}
                          onMouseDown={(event) => event.preventDefault()}
                          tabIndex={-1}
                        />
                      ))
                    : undefined,
              })}
              inputRef={setInputRef}
              label={label}
              variant="outlined"
            />
            {isOpen && (
              <Paper className="vertical-scrollbar vertical-scrollbar--md | position-absolute mt-2 w-100 z-3">
                <List component="nav" dense disablePadding>
                  {renderSuggestions((index, suggestion) => (
                    <AutocompleteSuggestion
                      imageUrl={suggestion.suggestionImageUrl}
                      isSelected={highlightedIndex === index}
                      itemProps={getItemProps({ item: suggestion })}
                      key={suggestion.id}
                      primaryText={suggestion.suggestionLabel}
                      secondaryText={suggestion.suggestionSecondaryLabel}
                    />
                  ))}
                </List>
              </Paper>
            )}
          </div>
        )}
      </Downshift>
    )
  }
}

export default AutocompleteTextField
