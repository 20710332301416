// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

// WeSpire
import DisableButton from 'components/ui/disable_button'
import ScreenReader from 'components/ui/screen_reader_text'
import { LikeButtonIcon } from './like_button_icon'
import { LikeButtonManager } from './like_button_manager'
import { LikeButtonWrapper } from './like_button_wrapper'

/**
 * Small text button that creates or destroys a like on a given likable for the
 * current user. Includes counter that indicates total likes for likable and an
 * icon that indicates if the current user has liked it.
 */
const LikeTextButton = ({
  likableId,
  likableType,
  likeCount,
  likeId,
  ...other
}) => {
  const likesCountId = `${likableType}-${likableId}-likes-count`

  return (
    <LikeButtonManager
      likableId={likableId}
      likableType={likableType}
      likeCount={likeCount}
      likeId={likeId}
    >
      {({ buttonLabel, handleToggleLike, isLiked, isLoading, likeCount }) => (
        <LikeButtonWrapper {...other}>
          <DisableButton
            aria-describedby={likesCountId}
            aria-pressed={isLiked}
            className="fs-1 mnw-0 px-2 text-blue"
            isLoading={isLoading}
            onClick={handleToggleLike}
            size="small"
          >
            <span aria-hidden>{buttonLabel}</span>
            <ScreenReader text="like" />
          </DisableButton>

          <span aria-hidden className="fs-1 ml-1" id={likesCountId}>
            <FormattedNumber value={likeCount} />
          </span>
          <LikeButtonIcon
            className="fs-2 ml-1"
            isLiked={isLiked}
            likeCount={likeCount}
          />
        </LikeButtonWrapper>
      )}
    </LikeButtonManager>
  )
}

LikeTextButton.propTypes = {
  /** id of likable record */
  likableId: PropTypes.string.isRequired,
  /** type of likable record */
  likableType: PropTypes.string.isRequired,
  /** total number of likes for likable record */
  likeCount: PropTypes.number.isRequired,
  /** id of current user like record, if one exists (for deleting it) */
  likeId: PropTypes.string,
}

LikeTextButton.defaultProps = {
  likeId: null,
}

export { LikeTextButton }
