import gql from 'graphql-tag'

export const GET_VOLUNTEER_HISTORY = gql`
  query volunteerHistory {
    brandConfig {
      id
      enablePaidVolunteerHours
    }
    currentUser {
      id
      paidVolunteerHoursBalance
      volunteeredIndividualHoursAllTime
      volunteeredOrganizationsAllTime
      volunteerLogs {
        claimedPaidVolunteerHours
        date
        ein
        eventName
        eventUrl
        hours
        id
        isComplete
        isForPlatformEvent
        organizationName
      }
    }
  }
`

export const GET_GRANT_ELIGIBILITY = gql`
  query grantEligibility($ein: String!) {
    charity(ein: $ein) {
      grantEligible
      id
    }
    currentUser {
      charityEligibleForGrant(ein: $ein)
      grantEligibleVolunteerHours(ein: $ein)
      id
      volunteerHoursThisYear(ein: $ein)
      givingaUser {
        availableDonationFundsFormatted
        donationFundsCapFormatted
        id
      }
    }
  }
`
