import React from 'react'
import { UserDetails, OtherMenu } from 'src/common/Header/Menus'

const ProfileDropdown = ({
  dropdownVisibleRef,
  dropdownOpen,
  setDropdownOpen,
  carbonFootprintEnabled,
  givingEnabled,
  isPointsDashboardEnabled,
  managementDropdownNavigationLinks,
  volunteerHistoryEnabled,
  otherLinks,
  setMobileNavigation,
  avatarUrl,
  firstName,
}) => (
  <div className="items-center">
    <div className="hidden sm:flex" ref={dropdownVisibleRef}>
      <button
        className="flex flex-row focus:scale-100 hover:scale-100 items-center"
        onClick={() => setDropdownOpen((prevDropdownOpen) => !prevDropdownOpen)}
      >
        <UserDetails avatarUrl={avatarUrl} firstName={firstName} />
        <span className="material-symbols-rounded hover:cursor-pointer text-2xl text-black-3">
          expand_more
        </span>
      </button>
      {dropdownOpen && (
        <div className="flex flex-row-reverse mt-10">
          <div className="absolute sm:w-64 float-right z-10 drop-shadow-md shadow-[0px_1px_2px_#C4D1DB]">
            <div className="absolute bg-white sm:w-64 drop-shadow-md w-screen">
              <OtherMenu
                carbonFootprintEnabled={carbonFootprintEnabled}
                givingEnabled={givingEnabled}
                isPointsDashboardEnabled={isPointsDashboardEnabled}
                managementDropdownNavigationLinks={
                  managementDropdownNavigationLinks
                }
                volunteerHistoryEnabled={volunteerHistoryEnabled}
                otherLinks={otherLinks}
                setMobileNavigation={setMobileNavigation}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
)

export default ProfileDropdown
