import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  activityEmptyStateManagement: {
    defaultMessage:
      'There are currently no activities available to reduce carbon impact emissions',
    id: 'manageCarbonFootprint.activityEmptyStateManagement',
  },
  addNewCampaignForCarbonFootprint: {
    defaultMessage: 'Add New Campaign',
    id: 'manageCarbonFootprint.addNewCampaignForCarbonFootprint',
  },
  carbonManagement: {
    defaultMessage: 'Carbon Management',
    id: 'shared.carbonManagement',
  },
  editSettings: {
    defaultMessage: 'Edit settings',
    id: 'manageCarbonFootprint.editSettings',
  },
  manageCarbonFootprint: {
    defaultMessage: 'Manage Carbon Footprint',
    id: 'manageCarbonFootprint.manageCarbonFootprint',
  },
})

export const carbonFootprintMessages = formatMessages(messages)
