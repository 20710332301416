// Setup
import React from 'react'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

// WeSpire
import { campaignActionsPath } from './routes'
import FormTextField from 'components/form/text_field'
import FormLimitedTextField from 'components/form/limited_text_field'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { WysiwygEditor } from 'components/ui/wysiwyg_editor'

const messages = defineMessages({
  actionsPath: {
    defaultMessage: 'the Actions configuration page',
    id: 'campaignActFields.actionsPath',
  },
  noActionsPresent: {
    defaultMessage:
      'There are no Actions in this {campaignType}. Visit {actionsPath} to add Actions in {defaultLocale} in order to enable localized Actions in {additionalLocale}.',
    id: 'campaignActFields.noActionsPresent',
  },
})

export const ActFields = ({
  acts,
  additionalLocale,
  campaignId,
  campaignType,
  className,
  defaultLocale,
}) => {
  const { formatMessage } = intl

  const actionsPath = (
    <Link to={campaignActionsPath({ campaignId })}>
      {formatMessage(messages.actionsPath)}
    </Link>
  )

  return (
    <>
      {acts.length > 0 ? (
        <Stack
          as={Paper}
          className={cx('py-4', className)}
          divider="default"
          space={4}
        >
          {acts.map((act) => (
            <div key={act.id}>
              <div className="row px-4">
                <div className="col">
                  <FormTextField
                    name={`actionDefaultName-${act.id}`}
                    textFieldProps={{
                      disabled: true,
                      inputProps: {
                        className: 'bg-black-6 text-black-2',
                      },
                      label: sharedTranslations.name,
                    }}
                    value={act.defaultName}
                  />
                </div>
                <div className="col">
                  <FormLimitedTextField
                    label={sharedTranslations.nameTranslation}
                    maxLength={90}
                    name={`actionTranslationName-${act.id}`}
                    value={act.nameTranslation}
                  />
                </div>
              </div>
              <div className="row px-4">
                <div className="col">
                  <WysiwygEditor
                    data-test={`action-default-description-${act.id}`}
                    label={sharedTranslations.description}
                    name={`actionDefaultDescription-${act.id}`}
                    readOnly
                    value={act.defaultDescription}
                  />
                </div>
                <div className="col">
                  <WysiwygEditor
                    data-test={`action-translation-description-${act.id}`}
                    label={sharedTranslations.descriptionTranslation}
                    name={`actionTranslationDescription-${act.id}`}
                    value={act.descriptionTranslation}
                  />
                </div>
              </div>
            </div>
          ))}
        </Stack>
      ) : (
        <Paper className={className}>
          <p className="p-4" data-test="no-actions-text">
            {formatMessage(messages.noActionsPresent, {
              actionsPath,
              additionalLocale,
              campaignType,
              defaultLocale,
            })}
          </p>
        </Paper>
      )}
    </>
  )
}

ActFields.propTypes = {
  acts: PropTypes.array.isRequired,
  additionalLocale: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignType: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultLocale: PropTypes.string.isRequired,
}

ActFields.defaultProps = {
  className: null,
}
