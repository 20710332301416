import React, { createContext, useEffect, useState } from 'react'
import getCurrentUser from 'src/queries/getCurrentUser'

const BreadcrumbContext = createContext({
  crumbs: [],
  setCrumbs: null,
})

const BreadcrumbContextProvider = (props) => {
  const { children, mobile, hotlinked, ios } = props

  const [crumbs, setCrumbs] = useState([])

  return (
    <BreadcrumbContext.Provider
      value={{
        crumbs,
        setCrumbs,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
}

export { BreadcrumbContextProvider }
export default BreadcrumbContext
