// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { actionUrlText } from './utils'
import { ActivityCard } from './activity_card'

const CurrentActivityCard = ({
  achievement,
  actionUrl,
  endsAt,
  name,
  progressPercent,
  startsAt,
  type,
  ...other
}) => (
  <ActivityCard
    {...other}
    achievement={achievement}
    actionUrl={actionUrl}
    data-test="current-activity"
    endsAt={endsAt}
    name={name}
    progressPercent={progressPercent}
    startsAt={startsAt}
    type={type}
  >
    <p className="my-0 text-link" id={actionUrl}>
      {actionUrlText(type)}
    </p>
  </ActivityCard>
)

CurrentActivityCard.propTypes = {
  achievement: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  endsAt: PropTypes.string,
  name: PropTypes.string.isRequired,
  progressPercent: PropTypes.number,
  startsAt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

CurrentActivityCard.defaultProps = {
  achievement: null,
  className: null,
  endsAt: null,
  progressPercent: null,
}

export { CurrentActivityCard }
