// Vendor
import axios from 'axios';

// WeSpire
import store from 'redux/store';

const createSharedOptions = (baseUrl) => ({
  baseURL: baseUrl || (window.BASE_API_URL || '/') + 'api',
  xsrfCookieName: 'X-CSRF-Token',
  xsrfHeaderName: 'X-CSRF-Token',
});

const desktopAPI = (baseUrl) => axios.create(createSharedOptions(baseUrl));

const nativeAPI = (baseUrl) =>
  axios.create({
    ...createSharedOptions(baseUrl),
    headers: {
      'Api-Token': window.NATIVE_API_TOKEN,
      Authorization: store.getState().session.token,
    },
  });

const WeSpireAPI = ({ baseUrl } = {}) =>
  store.getState().session.isNative ? nativeAPI(baseUrl) : desktopAPI(baseUrl);

export default WeSpireAPI;
