// Setup
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// Sub-Components
import { editCampaignLocalePath } from './routes'

// WeSpire
import { intl } from 'utilities/localization'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'

const messages = defineMessages({
  disabled: {
    defaultMessage: 'Disabled',
    id: 'campaignAdditionalLocalesList.disabled',
  },
  enableAction: {
    defaultMessage: 'Enable',
    id: 'campaignAdditionalLocalesList.enable',
  },
  enabled: {
    defaultMessage: 'Enabled',
    id: 'campaignAdditionalLocalesList.enabled',
  },
})

const CampaignAdditionalLocalesList = ({ additionalLocales, campaignId }) => {
  const { formatMessage } = intl

  return (
    <div className="px-3">
      <Stack className="py-3">
        <Stack as="ul" className="m-0 p-1" divider="default" space={3}>
          {additionalLocales.map(({ code, enabled, label }) => (
            <Stack
              as="li"
              className="[ d-flex justify-content-between ] [ px-2 ]"
              data-test="additional-locale"
              key={code}
              space={2}
            >
              <div>
                <div className="fs-2 fw-semi-bold">{label}</div>
                <Stack>
                  <p className="fs-1 mt-2 text-black-3">
                    <span
                      className={cx('d-inline-block mr-1 p-1 rounded-circle', {
                        'bg-green-1': enabled,
                        'bg-orange-1': !enabled,
                      })}
                    />
                    {enabled
                      ? formatMessage(messages.enabled)
                      : formatMessage(messages.disabled)}
                  </p>
                </Stack>
              </div>
              {enabled ? (
                <Link to={editCampaignLocalePath({ campaignId, code })}>
                  <Icon
                    className="fs-4 text-black-1"
                    iconName="edit"
                    title={sharedTranslations.edit}
                  />
                </Link>
              ) : (
                <TextLink
                  className="[ align-self-center ] [ [ fs-2 text-link--black-1 ]"
                  to={editCampaignLocalePath({ campaignId, code })}
                >
                  {formatMessage(messages.enableAction)}
                </TextLink>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </div>
  )
}

CampaignAdditionalLocalesList.propTypes = {
  additionalLocales: PropTypes.array.isRequired,
  campaignId: PropTypes.string.isRequired,
}

export { CampaignAdditionalLocalesList }
