// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'
import { GroupActivitiesList } from './list'

export const GroupActivities = ({ groupId }) => {
  return (
    <Stack space={4}>
      <GroupActivitiesList groupId={groupId} />
    </Stack>
  )
}

GroupActivities.propTypes = {
  groupId: PropTypes.string.isRequired,
}
