// Setup
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

// WeSpire
import { carbonFootprintMessages } from 'components/setup/carbon_footprints/utilities/messages'
import { editContentModulePath } from 'components/setup/content_modules/routes'

const DefaultActions = ({ moduleId }) => {
  return (
    <div>
      <Button
        color="primary"
        data-test="edit-content-module-link"
        href={`${editContentModulePath({
          id: moduleId,
        })}?redirect_to=/management_panel/carbon_footprints`}
      >
        {carbonFootprintMessages.editSettings}
      </Button>
      <Button
        className="ml-2"
        color="primary"
        data-test="campaign-library-link"
        href="/management_panel/campaign_library?content_modules[]=8"
        variant="contained"
      >
        {carbonFootprintMessages.addNewCampaignForCarbonFootprint}
      </Button>
    </div>
  )
}

DefaultActions.propTypes = {
  moduleId: PropTypes.string.isRequired,
}

export default DefaultActions
