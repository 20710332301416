import { sharedTranslations } from 'components/shared/translations'

export const paymentStatus = (status) => {
  if (!status) {
    return sharedTranslations.pending
  }

  switch (status.toLowerCase()) {
    case 'unpaid':
      return sharedTranslations.awaitingProcessing
    case 'processing':
      return sharedTranslations.awaitingPayment
    case 'scheduled':
      return sharedTranslations.scheduledForPayment
    case 'paid':
      return sharedTranslations.paid
    case 'received':
      return sharedTranslations.receivedByCharity
    default:
      // this covers for the pending status and any other unprecedented status
      return sharedTranslations.pending
  }
}

export const matchStatus = (status) => {
  if (!status) {
    return sharedTranslations.unknown
  }

  switch (status.toLowerCase()) {
    case 'approved':
      return sharedTranslations.approved
    case 'denied':
      return sharedTranslations.denied
    case 'pending':
      return sharedTranslations.pending
    default:
      return sharedTranslations.unknown
  }
}
