// Setup
import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import { parse } from 'query-string'
import { useHistory } from 'react-router-dom'

// Vendor
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'
import v from 'voca'

// WeSpire
import BannerLink from 'components/application/banner/link'
import { Button } from 'components/ui/button'
import { client } from 'utilities/we_apollo'
import DisableButton from 'components/ui/disable_button'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { LOG_VALIDIC_DEVICE_LINKING_FLOW } from 'graphql/mutations'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { formartListAsSentence } from 'utilities/array_formatter'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl

const messages = defineMessages({
  appleHealth: {
    defaultMessage: 'Apple Health',
    id: 'linkedDevices.appleHealth',
  },
  connect: {
    defaultMessage: 'You have successfully connected your {device} device.',
    id: 'linkedDevices.connect',
  },
  connectDevice: {
    defaultMessage: 'Connect Devices',
    id: 'linkedDevices.connectDevice',
  },
  disconnect: {
    defaultMessage:
      'You have successfully disconnected your { device } device.',
    id: 'linkedDevices.disconnect',
  },
  googleFit: {
    defaultMessage: 'Google Fit',
    id: 'linkedDevices.googleFit',
  },
  linkedDevicesHeading: {
    defaultMessage: 'Connect a Tracking Device',
    id: 'linkedDevices.linkedDevicesHeading',
  },
  linkedDevicesHeadingNoManualLogging: {
    defaultMessage:
      'To fully participate in this { campaignType } you will need to connect a device.',
    id: 'linkedDevices.linkedDevicesHeadingNoManualLogging',
  },
  noDeviceLinked: {
    defaultMessage:
      'Please link a connected device to log your progress in this { campaignType }.',
    id: 'linkedDevices.noDeviceLinked',
  },
  problemLoadingValidicUser: {
    defaultMessage: 'There was a problem loading the device info',
    id: 'linkedDevices.problemLoadingValidicUser',
  },
  problemProvisioningValidicUser: {
    defaultMessage:
      'There was an unexpected error accessing the Device Marketplace, please <link>contact support</link>.',
    id: 'linkedDevices.problemProvisioningValidicUser',
  },
  updateDevice: {
    defaultMessage: 'Update Devices',
    id: 'linkedDevices.updateDevice',
  },
  withLinkedDevices: {
    defaultMessage:
      'Your connected <bold>{deviceList}</bold> will be used to track your progress in this { campaignType }.',
    id: 'linkedDevices.withLinkedDevices',
  },
})

const PROVISION_VALIDIC_USER = gql`
  mutation provisionValidicUser {
    provisionValidicUser {
      errors
    }
  }
`

const VALIDIC_DEVICE_INFO = gql`
  query validicUserDeviceInfo {
    currentUser {
      id
      validicUser {
        id
        linkedDevices {
          type
        }
      }
    }
  }
`

const LinkedDevices = ({
  campaignType,
  hasActionWithDisabledManualLogging,
}) => {
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()

  const getMutation = (query, variables) => ({
    awaitRefetchQueries: true,
    mutation: query,
    refetchQueries: [{ query: VALIDIC_DEVICE_INFO }],
    variables,
  })

  const handleConnections = (device, flow) => {
    const logData = {
      attempt: false,
      device,
      flow,
    }
    client.mutate(getMutation(LOG_VALIDIC_DEVICE_LINKING_FLOW, logData))
  }

  const provisionUser = () => {
    setLoading(true)
    client
      .mutate(getMutation(PROVISION_VALIDIC_USER))
      .then(
        ({
          data: {
            provisionValidicUser: { errors },
          },
        }) => {
          if (errors.length === 0) {
            // redirect to market place to connect device
            history.push(
              `/connected_devices?return_url=${window.location.href}`
            )
          } else {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(messages.problemProvisioningValidicUser, {
            link: (str) => (
              <BannerLink
                key="contactSupport"
                to="mailto:support@wespire.com?subject=Device%20Marketplace%Connection%20Error"
              >
                {str}
              </BannerLink>
            ),
          }),
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deviceNameMap = (name) => {
    const customDeviceNames = {
      apple_health: formatMessage(messages.appleHealth),
      google_fit_sdk: formatMessage(messages.googleFit),
    }
    return customDeviceNames[name] || name
  }

  const displayLinkedDevices = (devices) => {
    const deviceList = formartListAsSentence(
      devices.map(({ type }) => v.capitalize(deviceNameMap(type)))
    )

    return (
      <span>
        {formatMessage(messages.withLinkedDevices, {
          bold: (str) => <b>{str}</b>,
          campaignType: sharedTranslations[campaignType],
          deviceList: deviceList,
        })}
      </span>
    )
  }
  const HelperText = ({ message }) => (
    <span className="d-flex align-items-center text-black-2">
      <Icon
        className="mr-2 fs-4 text-black-3"
        iconName="help_outline"
        title=""
      />
      <p className="fs-1 text-black-1 lh-md">{message}</p>
    </span>
  )

  HelperText.propTypes = {
    message: string.isRequired,
  }

  return (
    <GraphQLFeatureFlag
      error={null}
      featureDisabledError={null}
      featureName="healthDataIntegrations"
      loader={null}
    >
      <WeQuery
        error={
          <MissingResourceSection
            data-test="missing-linked-device-info"
            errorDetails={formatMessage(messages.problemLoadingValidicUser)}
          />
        }
        loader={<SkeletonLoading className="mt-4" height={140} />}
        query={VALIDIC_DEVICE_INFO}
      >
        {({
          data: {
            currentUser: { validicUser },
          },
        }) => {
          const hasLinkedDevices = validicUser?.linkedDevices.length > 0
          const { device, connection_result } = parse(window.location.search)

          if (connection_result && device) {
            displayBanner({
              content: intl.formatMessage(messages[connection_result], {
                device,
              }),
              variant: 'success',
            })
            handleConnections(device, connection_result)
          }
          return (
            <Stack
              className="[ align-items-center flex-column ] [ mt-4 bg-blue-2 p-3 ]"
              data-test="linked-devices-panel"
            >
              {!hasLinkedDevices && (
                <p
                  className="align-self-start fs-3 fw-bold lh-lg text-black-1"
                  data-test="linked-device-heading"
                >
                  {formatMessage(
                    hasActionWithDisabledManualLogging
                      ? messages.linkedDevicesHeadingNoManualLogging
                      : messages.linkedDevicesHeading,
                    {
                      campaignType: sharedTranslations[campaignType],
                    }
                  )}
                </p>
              )}

              {hasLinkedDevices ? (
                <>
                  <p className="mb-2 fs-2" data-test="linked-device-message">
                    {displayLinkedDevices(validicUser.linkedDevices)}
                  </p>

                  <div className="[ d-flex align-self-end flex-shrink-none justify-content-end my-3 ]">
                    <LinkButton
                      className="fs-1 mr-2"
                      data-test="update-devices-btn"
                      to={`/connected_devices?return_url=${window.location.href}`}
                      variant="neutral"
                    >
                      {formatMessage(messages.updateDevice)}
                    </LinkButton>
                  </div>
                </>
              ) : (
                <>
                  <p className="mb-2 fs-2" data-test="no-device-linked-message">
                    {formatMessage(messages.noDeviceLinked, {
                      campaignType: sharedTranslations[campaignType],
                    })}
                  </p>
                  <div className="[ d-flex align-self-end flex-shrink-none justify-content-end my-3 ]">
                    {validicUser ? (
                      <LinkButton
                        className="fs-1 mr-2"
                        data-test="connect-devices-btn"
                        to={`/connected_devices?return_url=${window.location.href}`}
                        variant="neutral"
                      >
                        {formatMessage(messages.connectDevice)}
                      </LinkButton>
                    ) : (
                      <DisableButton
                        as={Button}
                        className="fs-1 mr-2"
                        data-test="provision-device-btn"
                        isLoading={isLoading}
                        onClick={provisionUser}
                        variant="neutral"
                      >
                        {formatMessage(messages.connectDevice)}
                      </DisableButton>
                    )}
                  </div>
                </>
              )}
            </Stack>
          )
        }}
      </WeQuery>
    </GraphQLFeatureFlag>
  )
}

LinkedDevices.propTypes = {
  campaignType: string.isRequired,
  hasActionWithDisabledManualLogging: bool.isRequired,
}

export default LinkedDevices
