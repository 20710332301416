// Setup
import React, { useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'

// WeSpire
import CampaignLayoutDesktop from 'components/campaign/layout_desktop'
import { CampaignModel } from 'components/campaign/campaign_model'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { campaignTemplatePreviewMessages } from './utilities/messages'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { PREVIEW_CAMPAIGN_TEMPLATE_QUERY } from './utilities/queries'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import WeQuery from 'components/application/we_query'

// Utilities
import { reviewPath, achievementsPath } from './utilities/utils'

const CampaignTemplatePreview = () => {
  const { formatMessage } = intl
  const { id } = useParams()
  const { search } = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(search), [search]) // eslint-disable-line compat/compat
  const preview = queryParams.get('preview') || ''

  return (
    <div className="campaign-preview-page pb-6">
      <WeQuery
        campaignId={id}
        error={
          <DocumentTitle
            title={formatMessage(sharedMessages.resourceNotFound, {
              resource: sharedTranslations.campaign,
            })}
          >
            <MissingResourcePage resourceName={sharedTranslations.campaign} />
          </DocumentTitle>
        }
        loader={<CenteredPaddedLoadingIndicator />}
        query={PREVIEW_CAMPAIGN_TEMPLATE_QUERY}
        variables={{ campaignId: id }}
      >
        {(campaignData) => {
          // set default values for campaign template preview
          campaignData = {
            ...campaignData.data.campaignTemplate,
            completionPoints: 0,
            imageUrl: campaignData?.data.campaignTemplate.image,
            participants: [],
            participantsCount: 0,
            progressPercentForUser: 0,
            progressPointsForUser: 0,
            totalParticipationIndicators: 0,
            totalParticipationIndicatorsForUser: 0,
            leaderboardTeamSets: [],
            startsAt: new Date().toISOString(),
            endsAt: new Date().toISOString(),
            registrationEnd: new Date().toISOString(),
            registrationStart: new Date().toISOString(),
          }

          const campaign = new CampaignModel(campaignData)

          return (
            <DocumentTitle title={`${campaign.name} • ${campaign.type}`}>
              <CampaignLayoutDesktop
                campaign={campaign}
                isPreview
                onAchievementEarn={() => {}}
                previewWithNoData
              />
            </DocumentTitle>
          )
        }}
      </WeQuery>
      {!preview ? (
        <div className="[ px-4 py-3 ] [ bg-white ] [ fixed-bottom border ] [ d-flex justify-content-end ]">
          <LinkButton
            className="mx-2"
            data-test="previous-button"
            to={achievementsPath(id)}
          >
            {sharedTranslations.previous}
          </LinkButton>
          <LinkButton
            className="mx-2"
            color="secondary"
            data-test="edit-template-link"
            to={reviewPath(id)}
          >
            {campaignTemplatePreviewMessages.reviewStepButtonLabel}
          </LinkButton>
        </div>
      ) : (
        <div className="[ px-4 py-3 ] [ bg-white ] [ fixed-bottom border ] [ d-flex justify-content-center ]">
          <p className="fs-3 text-center fw-semi-bold text-black-3">
            {campaignTemplatePreviewMessages.previewLabel}
          </p>
        </div>
      )}
    </div>
  )
}

export default CampaignTemplatePreview
