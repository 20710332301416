import React from 'react'
import SkeletonLoading from './SkeletonLoading'

export default function Loading() {
  return (
    <div className="grid gap-y-10 my-10 mx-2 lg:mx-4 xl:mx-0">
      <SkeletonLoading header component="h1" height={2} />
      <SkeletonLoading height={10} />
      <SkeletonLoading height={10} />
      <SkeletonLoading height={10} />
      <SkeletonLoading height={10} />
    </div>
  )
}
