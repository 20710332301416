import React from 'react'
import PropTypes from 'prop-types'

const QuestionSubHeader = ({ subHeader }) => (
  <h2
    className="flex font-bold my-5 text-sm md:text-md text-gray uppercase"
    data-test="sub-question-test"
  >
    {subHeader}
  </h2>
)

QuestionSubHeader.propTypes = {
  subHeader: PropTypes.string.isRequired,
}

export default QuestionSubHeader
