// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import WeQuery from 'components/application/we_query'
import { USER_FRAGMENT } from 'graphql/fragments'

const query = gql`
  query getCurrentUser {
    currentUser {
      ...UserAttributes
      brand {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`

const withCurrentUser = (WrappedComponent, loader) =>
  function WithCurrentUser(props) {
    return (
      <WeQuery loader={loader} query={query}>
        {({ data }) => (
          <WrappedComponent {...props} currentUser={data.currentUser} />
        )}
      </WeQuery>
    )
  }

const currentUserPropType = PropTypes.shape({
  avatarUrl: PropTypes.string.isRequired,
  brand: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profilePath: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
})

export { currentUserPropType, withCurrentUser }
