// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Hero from 'components/ui/hero'
import HeroIcon from 'components/ui/hero/icon'
import { sharedTranslations } from 'components/shared/translations'

const GivingActivityHero = ({ imageUrl, name }) => (
  <Hero
    icon={<HeroIcon iconName="activity_giving" />}
    imgUrl={imageUrl}
    label={sharedTranslations.giving}
    title={name}
    titleProps={{
      className: 'mb-5',
    }}
  />
)

GivingActivityHero.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default GivingActivityHero
