// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'

// WeSpire
import MgmtPanelFormPage from 'setup/application/form_page'
import { channelSlugUrlForLocale } from 'components/activities/utils'
import ChannelsForm from './form'
import { Publish } from './publish'
import { SecondaryLocaleList } from './secondary_locales_list'
import { suggestionPropType } from 'components/form/autocomplete_utils'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'

const ChannelsFormPage = (props) => {
  const [selectedTeams, setSelectedTeams] = useState([])
  const [updatedAt, setUpdatedAt] = useState('')

  const handleUpdatePublish = (teams, updatedAt) => {
    setSelectedTeams(teams)
    setUpdatedAt(updatedAt)
  }

  useEffect(() => {
    setSelectedTeams((channel && channel.teamFilters) || [])
    setUpdatedAt((channel && channel.updatedAt) || Date.now())
  }, [props])

  const { brandConfig, channel, isEdit } = props

  let resourceName = 'Channel'
  if (isEdit) {
    resourceName += ` - ${channel.defaultName}`
  }

  return (
    <MgmtPanelFormPage
      {...props}
      paperProps={{
        classes: { root: 'bg-transparent' },
        elevation: 0,
      }}
      resourceName={resourceName}
      space={0}
    >
      {({ displayNotification }) => (
        <div className="p-4">
          <div className="row vertical-gutters">
            <div className="col-7">
              <Paper>
                <ChannelsForm
                  {...props}
                  displayNotification={displayNotification}
                  handleUpdatePublish={handleUpdatePublish}
                />
              </Paper>
            </div>
            <div className="col-4">
              <Paper>
                <Publish
                  activitiesCount={(channel && channel.activitiesCount) || 0}
                  channelSlugUrl={
                    channel &&
                    channelSlugUrlForLocale(channel, channel.defaultLocale)
                  }
                  data-test="channel-activity-count"
                  isEdit={isEdit}
                  lastUpdated={updatedAt}
                  teams={selectedTeams.length}
                />
              </Paper>
              <GraphQLFeatureFlag
                error={null}
                featureDisabledError={null}
                featureName="translation"
                loader={null}
              >
                <Paper className="mt-3">
                  <SecondaryLocaleList
                    brandConfigLocales={brandConfig.enabledLocales}
                    channelDefaultLocale={channel && channel.defaultLocale}
                    channelEnabledLocales={channel && channel.enabledLocales}
                    channelUrl={(locale) =>
                      channelSlugUrlForLocale(channel, locale)
                    }
                  />
                </Paper>
              </GraphQLFeatureFlag>
            </div>
          </div>
        </div>
      )}
    </MgmtPanelFormPage>
  )
}

ChannelsFormPage.propTypes = {
  brandConfig: PropTypes.shape({
    enabledLocales: PropTypes.array,
  }).isRequired,
  channel: PropTypes.shape({
    activitiesCount: PropTypes.number.isRequired,
    defaultLocale: PropTypes.string,
    defaultName: PropTypes.string.isRequired,
    enabledLocales: PropTypes.array.isRequired,
    teamFilters: PropTypes.arrayOf(suggestionPropType.isRequired),
    updatedAt: PropTypes.string,
  }),
  isEdit: PropTypes.bool.isRequired,
}

ChannelsFormPage.defaultProps = {
  channel: null,
}

export default ChannelsFormPage
