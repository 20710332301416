// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import { MembersSection } from './members_section'

const messages = defineMessages({
  commonTeamsMembersHeading: {
    defaultMessage: 'Members on your teams',
    id: 'commonTeamsMembersSection.commonTeamsMembersHeading',
  },
})

const CommonTeamsMembersSection = ({ groupId }) => {
  const { formatMessage } = intl

  return (
    <MembersSection
      excludeUser
      groupId={groupId}
      headingDataTest="common-teams-members"
      headingMessage={formatMessage(messages.commonTeamsMembersHeading)}
      memberDataTest="common-team-member"
      noHiddenMembers
      noLeaders
      queryKey="commonTeamsMembers"
      withTeamsInCommon
    />
  )
}

CommonTeamsMembersSection.propTypes = {
  groupId: PropTypes.string.isRequired,
}

export { CommonTeamsMembersSection }
