import React, { createContext, useEffect, useState } from 'react'
import getCurrentBrand from 'src/queries/getCurrentBrand'
import getCurrentUser from 'src/queries/getCurrentUser'

const SessionContext = createContext({
  user: {},
  brand: {},
  ios: false,
  mobile: null,
  refreshUser: null,
  setUser: null,
})

const SessionContextProvider = (props) => {
  const { children, mobile, hotlinked, ios } = props

  const [brand, setBrand] = useState(JSON.parse(props.brand || 'null'))
  const [user, setUser] = useState(JSON.parse(props.user || 'null'))
  const [signedIn, setSignedIn] = useState()
  const [manager, setManager] = useState()
  const [staff, setStaff] = useState()

  useEffect(() => {
    if (!brand) refreshBrand()
    if (!user) refreshUser()
  }, [])

  useEffect(() => {
    setSignedIn(!!user?.id)
    setManager(['platform_manager', 'admin'].includes(user?.roleId))
    setStaff(user?.roleId === 'admin')
  }, [user?.roleId, user?.id])

  const refreshBrand = () => {
    getCurrentBrand(setBrand)
  }

  const refreshUser = () => {
    getCurrentUser(setUser)
  }

  return (
    <>
      <SessionContext.Provider
        value={{
          brand,
          user,
          ios,
          mobile,
          signedIn,
          manager,
          staff,
          refreshUser,
          setUser,
          refreshBrand,
          setBrand,
        }}
      >
        {children}
      </SessionContext.Provider>
    </>
  )
}

export { SessionContextProvider }
export default SessionContext
