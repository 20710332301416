// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { useQueryParams, StringParam } from 'use-query-params'

// Wespire
import { ActivityGalleryModalContent } from './content'
import { ActivityGalleryModalNav } from './nav'
import { activityGalleryModalCurrentItemLabel } from './utils'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { ActivityGalleryAnalytics } from 'utilities/analytics'

const useActivityGalleryModal = () => {
  const setCurrentStoryParams = useQueryParams({
    story: StringParam,
    view: StringParam,
  })[1]

  const closeModal = () => {
    setCurrentStoryParams({
      story: undefined,
      view: undefined,
    })
  }

  const openModal = (storyId) => {
    setCurrentStoryParams({
      story: storyId,
      view: 'gallery',
    })
  }

  return { closeModal, openModal }
}

const ActivityGalleryModal = ({ loadMore, newsfeedItems, totalCount }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { openModal, closeModal } = useActivityGalleryModal()

  const [currentStoryParams] = useQueryParams({
    story: StringParam,
    view: StringParam,
  })

  const { story: storyId, view } = currentStoryParams
  const isOpen = view === 'gallery'

  // If there are no stories with images on this page
  const length = newsfeedItems.length

  const index = newsfeedItems.findIndex((item) => item.storyId === storyId)
  // I don't want to render any story if the modal is close
  // If I do, the closing animation will display a flicker image of the first
  // story
  const storyIndex = isOpen ? (index > -1 ? index : 0) : -1
  const currentStoryId =
    newsfeedItems[storyIndex]?.storyId || newsfeedItems[0]?.storyId

  const onModalNavClick = (direction) => () => {
    const nextIndex = storyIndex + 1

    // Pagination logic, here we know we need to call load more
    if (direction === 'next' && nextIndex + 1 > length && totalCount > length) {
      return loadMore({
        end: (newNodes) => {
          openModal(newNodes[0].storyId)
          setIsLoadingMore(false)
        },
        start: () => setIsLoadingMore(true),
      })
    }

    // Get the next/prev item in the array or loop back around once the
    // end/beginning of the array is reached. Assign the correct story id
    // based on the given direction.
    const nextItemId = newsfeedItems[nextIndex % length].storyId

    const previousItemId =
      newsfeedItems[(storyIndex + length - 1) % length].storyId

    const adjacentStoryId = {
      next: nextItemId,
      previous: previousItemId,
    }[direction]

    // Display the gallery modal image and track the modal nav click.
    openModal(adjacentStoryId)
    ActivityGalleryAnalytics.galleryModalNavItemClicked({
      direction,
      storyId: adjacentStoryId,
    })
  }

  const ariaLiveText = activityGalleryModalCurrentItemLabel({
    imageIndex: storyIndex,
    imagesCount: length,
  })

  if (length === 0) {
    return null // TODO: Kawaii error content
  }

  return (
    <Dialog
      aria-labelledby={`story-${storyId}`}
      classes={{ paper: 'bg-transparent' }}
      fullScreen
      onClose={closeModal}
      open={isOpen}
      PaperProps={{
        elevation: 0,
        square: true,
      }}
    >
      <Stack className="h-100 position-relative" data-test="gallery-modal">
        {!!length && (
          <ActivityGalleryModalNav
            hasPrevious={storyIndex !== 0 || totalCount === length}
            onModalNavClick={onModalNavClick}
          />
        )}
        {storyIndex > -1 && (
          <ActivityGalleryModalContent
            galleryNewsfeed={newsfeedItems}
            isLoadingMore={isLoadingMore}
            onClose={closeModal}
            storyId={currentStoryId}
            storyIndex={storyIndex}
            totalCount={totalCount}
          />
        )}
        <ScreenReader aria-live="polite" text={ariaLiveText} />
      </Stack>
    </Dialog>
  )
}

ActivityGalleryModal.propTypes = {
  loadMore: PropTypes.func.isRequired,
  newsfeedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      message: PropTypes.string,
      storyId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    })
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
}

export { ActivityGalleryModal, useActivityGalleryModal }
