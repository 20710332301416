// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import IdeaBoardSubmissionDetailsQuery from 'components/idea_board/idea/details_query'
import IdeaDetails from 'components/idea_board/idea/details'

export const IdeaDetailsContainer = ({
  displayNotification,
  formRequirements,
  ideaId,
  ideaTitle,
  onIdeaDetailsMount,
  setCommentFormRef,
}) => (
  <IdeaBoardSubmissionDetailsQuery submissionId={ideaId}>
    {({
      commentsList,
      customFieldResponses,
      description,
      ideaUrl,
      imageUrl,
    }) => (
      <IdeaDetails
        commentsList={commentsList}
        customFieldResponses={customFieldResponses}
        description={description}
        displayNotification={displayNotification}
        formRequirements={formRequirements}
        id={ideaId}
        ideaUrl={ideaUrl}
        imageUrl={imageUrl}
        onIdeaDetailsMount={onIdeaDetailsMount}
        setCommentFormRef={setCommentFormRef}
        title={ideaTitle}
      />
    )}
  </IdeaBoardSubmissionDetailsQuery>
)

IdeaDetailsContainer.propTypes = {
  displayNotification: PropTypes.func.isRequired,
  formRequirements: PropTypes.shape({
    currentUser: PropTypes.shape({
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  ideaId: PropTypes.string.isRequired,
  ideaTitle: PropTypes.string.isRequired,
  onIdeaDetailsMount: PropTypes.func.isRequired,
  setCommentFormRef: PropTypes.func,
}

IdeaDetailsContainer.defaultProps = {
  setCommentFormRef: null,
}

export default IdeaDetailsContainer
