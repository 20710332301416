// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { defineMessages } from 'react-intl'

// WeSpire
import About from 'components/idea_board/about'
import CenteredContent from 'components/application/centered_content'
import { displayBanner } from 'redux/dispatchers'
import Hero from 'components/ui/hero'
import HeroIcon from 'components/ui/hero/icon'
import { IdeaBoardAnalytics } from 'utilities/analytics/'
import IdeaList from './idea_list'
import { useIsMobile } from 'utilities/hooks/is_mobile'
import { TakeActionLink } from 'components/activities/link'
import { RelativeTime } from 'components/shared/relative_time'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  endedInfoBanner: {
    defaultMessage:
      'This Idea Board {relativeEndedTime}. You can comment on ' +
      'submitted Ideas or visit the {takeActionLink} to find ' +
      'more opportunities to contribute!',
    description:
      'An info banner informing the user that Idea submissions are closed',
    id: 'ideaBoard.endedInfoBanner',
  },
})

const IdeaBoard = ({
  categories,
  currentUser,
  customFields,
  description,
  displayNotification,
  ended,
  endsAt,
  id,
  ideaDescriptionMax,
  ideas,
  ideaTitleMax,
  imageUrl,
  isPreview,
  name,
  participants,
  participantsCount,
  pointsPerIdeaBoardSubmission,
  startsAt,
  totalParticipationIndicators,
  totalVotes,
}) => {
  const { formatMessage } = intl
  const isMobile = useIsMobile()
  const [activeTab, setActiveTab] = useState(IdeaBoard.TABS.IDEAS)

  useEffect(() => {
    IdeaBoardAnalytics.setIdeaBoardId(id)

    ended &&
      displayBanner({
        content: formatMessage(messages.endedInfoBanner, {
          relativeEndedTime: (
            <b>
              <RelativeTime date={endsAt} showPrefix />
            </b>
          ),
          takeActionLink: <TakeActionLink isBannerLink />,
        }),
        variant: 'info',
      })
  }, [])

  const handleChangeTab = (_event, activeTab) => {
    setActiveTab(activeTab)
  }

  function showAbout() {
    return activeTab === IdeaBoard.TABS.ABOUT || !isMobile
  }

  function showIdeas() {
    return activeTab === IdeaBoard.TABS.IDEAS || !isMobile
  }

  return (
    <>
      <Tabs
        className="bg-white elevation-3 position-relative | hide-md-up"
        onChange={handleChangeTab}
        textColor="secondary"
        value={activeTab}
        variant="fullWidth"
      >
        <Tab
          data-test="idea-tab"
          label={sharedTranslations.ideas}
          value={IdeaBoard.TABS.IDEAS}
        />
        <Tab
          data-test="idea-tab"
          label={sharedTranslations.about}
          value={IdeaBoard.TABS.ABOUT}
        />
      </Tabs>

      {showIdeas() && (
        <Hero
          icon={<HeroIcon iconName="activity_idea_board" />}
          imgUrl={imageUrl}
          label={sharedTranslations.ideaboard}
          title={name}
          titleProps={{
            className: 'mb-lg-5',
          }}
        />
      )}

      <CenteredContent className="position-relative" pullDesktop>
        {showAbout() && (
          <About
            avatars={participants.map((participant) => participant.avatarUrl)}
            description={description}
            endsAt={endsAt}
            name={name}
            participantsCount={participantsCount}
            startsAt={startsAt}
            totalIdeasSubmitted={totalParticipationIndicators}
            totalVotes={totalVotes}
          />
        )}

        {showIdeas() && (
          <div className="mt-md-3">
            <IdeaList
              categories={categories}
              currentUser={currentUser}
              customFields={customFields}
              displayNotification={displayNotification}
              ended={ended}
              formRequirements={{
                currentUser,
                validations: {
                  idea: {
                    description: { maximum: ideaDescriptionMax },
                    title: { maximum: ideaTitleMax },
                  },
                },
              }}
              ideaBoardID={id}
              ideaBoardName={name}
              ideas={ideas}
              isPreview={isPreview}
              pointsPerIdeaBoardSubmission={pointsPerIdeaBoardSubmission}
            />
          </div>
        )}
      </CenteredContent>
    </>
  )
}

IdeaBoard.propTypes = {
  categories: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  displayNotification: PropTypes.func.isRequired,
  ended: PropTypes.bool.isRequired,
  endsAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  ideaDescriptionMax: PropTypes.number.isRequired,
  ideas: PropTypes.array.isRequired,
  ideaTitleMax: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  isPreview: PropTypes.bool,
  name: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({ avatarUrl: PropTypes.string.isRequired }).isRequired
  ).isRequired,
  participantsCount: PropTypes.number.isRequired,
  pointsPerIdeaBoardSubmission: PropTypes.number,
  startsAt: PropTypes.string.isRequired,
  totalParticipationIndicators: PropTypes.number.isRequired,
  totalVotes: PropTypes.number.isRequired,
}

IdeaBoard.defaultProps = {
  imageUrl: null, // not required for preview
  isPreview: false,
  pointsPerIdeaBoardSubmission: null,
}

IdeaBoard.TABS = {
  ABOUT: 'About',
  IDEAS: 'Ideas',
}

export const undecorated = IdeaBoard
export default IdeaBoard
