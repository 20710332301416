// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'

// WeSpire
import { displayModal, hideModal } from 'redux/dispatchers'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  closeHelpDialog: {
    defaultMessage: 'Close Help Dialog',
    id: 'helpIconModal.closeHelpDialog',
  },
  toggleHelpDialog: {
    defaultMessage: 'Toggle Help Dialog',
    id: 'helpIconModal.toggleHelpDialog',
  },
})

/** "Help" icon button that triggers a modal on click. Intended to provide
 * additional context that would otherwise not fit in a given space. Modal
 * content should be concise and can contain either a plain string (which
 * will be wrapped in a paragraph) or any type of children content (which will
 * be rendered as is). */
const HelpIconModal = ({
  children,
  'data-test': dataTest,
  iconProps,
  modalContentProps,
  modalTitleProps,
  modalWrapperProps,
  size,
  title,
  ...other
}) => {
  const handleClose = () => {
    hideModal()
  }

  const handleOpen = () => {
    displayModal({
      options: {
        ...modalWrapperProps,
        'aria-labelledby': modalId,
        fullWidth: true,
        maxWidth: 'sm',
        onClose: handleClose,
      },
      renderContent: modalContent,
    })
  }

  const { formatMessage } = intl

  const isString = (val) =>
    Object.prototype.toString.call(val) === '[object String]'

  const modalId = `help-modal-${title}`

  const modalContent = () => (
    <div className="py-3">
      <DialogTitle
        className="[ d-flex align-items-center justify-content-between ] [ py-0 ]"
        data-test="help-icon-modal-title"
        disableTypography
      >
        <Heading {...modalTitleProps} id={modalId} level={1} variant={2}>
          {title}
        </Heading>
        <IconButton onClick={handleClose}>
          <Icon
            className="fs-4 text-black-3"
            data-test="close-help-dialog"
            iconName="close"
            title={formatMessage(messages.closeHelpDialog)}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent data-test="help-icon-modal-content">
        {isString(children) ? (
          <p
            {...modalContentProps}
            className={cx('fs-2 fs-sm-3 mt-0', modalContentProps.className)}
          >
            {children}
          </p>
        ) : (
          children
        )}
      </DialogContent>
    </div>
  )

  return (
    <Fragment>
      <IconButton {...other} onClick={handleOpen} size={size}>
        <Icon
          {...iconProps}
          className={cx(
            { medium: 'fs-4', small: 'fs-2' }[size],
            'text-black-3',
            iconProps.className
          )}
          data-test={dataTest}
          iconName="help"
          title={formatMessage(messages.toggleHelpDialog)}
        />
      </IconButton>
    </Fragment>
  )
}

HelpIconModal.propTypes = {
  /** content rendered in the body of the modal */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** attribute used for feature tests */
  'data-test': PropTypes.string,
  /** props applied to <Icon> */
  iconProps: PropTypes.object,
  /** props applied to <DialogContent> */
  modalContentProps: PropTypes.object,
  /** props applied to <Heading> */
  modalTitleProps: PropTypes.object,
  /** props applied to <Dialog> */
  modalWrapperProps: PropTypes.object,
  /** size of the button and icon */
  size: PropTypes.oneOf(['small', 'medium']),
  /** title rendered in the modal */
  title: PropTypes.string.isRequired,
}

HelpIconModal.defaultProps = {
  'data-test': 'help-icon-modal-button',
  iconProps: {
    className: null,
  },
  modalContentProps: {
    className: null,
  },
  modalTitleProps: {
    className: null,
  },
  modalWrapperProps: {},
  size: 'medium',
}

export default HelpIconModal
