// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import FormSelect from 'components/form/select'
import FormTextField from 'components/form/text_field'

const CUSTOM_NAME_PREFIX = 'customField'
const DOT_ENCODE = '&#456;'
const LEFT_BRACKET_ENCODE = '&%5B;'
const RIGHT_BRACKET_ENCODE = '&%5D;'

export const customFieldFormsyName = (id, name) =>
  `${CUSTOM_NAME_PREFIX}-${id}-${name}`

export const getCustomFieldResponsesAttributes = (values) => {
  const customFieldsValues = Object.entries(values).filter((value) => {
    const formsyName = value[0]

    return formsyName.includes(CUSTOM_NAME_PREFIX)
  })

  return customFieldsValues.map((value) => {
    const formsyName = value[0]
    const [, customFieldId, ...rest] = formsyName.split('-')

    let customFieldName = rest.join('-') // Fixes bug where name contains a '-'
    customFieldName = customFieldName.replace(DOT_ENCODE, '.') // restores all '.' to field name.
    customFieldName = customFieldName.replace(LEFT_BRACKET_ENCODE, '[') // restores all '[' to field name.
    customFieldName = customFieldName.replace(RIGHT_BRACKET_ENCODE, ']') // restores all ']' to field name.

    const customFieldResponse = value[1] // This does not work for object for two-level object

    return {
      customFieldId: parseInt(customFieldId),
      name: customFieldName,
      response: customFieldResponse,
    }
  })
}

/**
 * CustomField Component
 * it takes into account special characters like '.' and '[]'
 * should be the only special characters we need to encode since '.'
 * would make a nested object in form
 * and '[]' should be trying to make an array so it's encoded as well.
 */
export const CustomField = ({ disabled, field }) => {
  const { id, isRequired, name, options, responseType } = field
  let formsyName = customFieldFormsyName(id, name)
  formsyName = formsyName.replace(/\./g, DOT_ENCODE) // encodes all '.' in name
  formsyName = formsyName.replace(/\[/g, LEFT_BRACKET_ENCODE) // encodes all '[' in name
  formsyName = formsyName.replace(/\]/g, RIGHT_BRACKET_ENCODE) // encodes all ']' in name
  return responseType === 'dropdown' ? (
    <FormSelect
      formControlProps={{
        disabled: disabled,
      }}
      name={formsyName}
      options={options.map((option) => ({ label: option }))}
      required={isRequired}
      textFieldProps={{
        label: name,
      }}
      value={field.response}
    />
  ) : (
    <FormTextField
      name={formsyName}
      required={isRequired}
      textFieldProps={{
        disabled: disabled,
        label: name,
        multiline: responseType !== 'integer',
        type: responseType === 'integer' ? 'number' : 'text',
      }}
      value={field.response}
    />
  )
}

CustomField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    response: PropTypes.string,
    responseType: PropTypes.string.isRequired,
  }).isRequired,
}

CustomField.defaultProps = {
  disabled: false,
}
