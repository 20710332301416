// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import {
  AvatarDimensions,
  AvatarSizes,
  getAvatarSrc,
} from 'components/application/avatar'
import { Image } from 'components/ui/image'
import Stack from 'components/ui/stack'
import { useUserScore } from 'utilities/hooks/useUserScore'

export const MobileAvatar = ({ avatarUrl, className }) => {
  const { score } = useUserScore()

  return (
    <Stack className={cx('[ d-flex align-items-center mnw-0 ]', className)}>
      <div className="[ d-flex align-items-center ] [ px-2 ]">
        <span className="[ fs-3 fw-bold ]" data-test="nav-score-label">
          {score}
        </span>
      </div>
      <Image
        alt="Avatar"
        className="flex-shrink-none"
        crop="fill"
        dpr="2.0"
        gravity="face:auto"
        height={AvatarDimensions[AvatarSizes.small]}
        src={getAvatarSrc(avatarUrl)}
        width={AvatarDimensions[AvatarSizes.small]}
      />
    </Stack>
  )
}

MobileAvatar.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
}

MobileAvatar.defaultProps = {
  className: null,
}
