// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Collapse from '@material-ui/core/Collapse'
import { defineMessages } from 'react-intl'
import Formsy from 'formsy-react'
import IconButton from '@material-ui/core/IconButton'

// WeSpire
import CloudinaryQuery from 'components/queries/cloudinary_query'
import { CustomField } from 'components/form/custom_field'
import EditableImageAttachment from 'components/application/editable_image_attachment'
import FormFieldset from 'components/form/fieldset'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import SubmitButton from 'components/ui/submit_button'
import TextField from 'components/form/text_field'
import UserAutocomplete from 'components/form/user_autocomplete'

export default class ActionCompletionForm extends Component {
  state = {
    hasMentionsField: this.props.requirement.tag,
    isImageUploading: false,
  }

  handleAutocompleteFocus = () => this.autocomplete.focus()

  handleImageUploading = (isUploading) =>
    this.setState({ isImageUploading: isUploading })

  handleToggleMentions = () => this.setState({ hasMentionsField: true })

  messages = defineMessages({
    formFieldCommentCannotBeBlank: {
      defaultMessage: 'Comment cannot be blank.',
      description:
        'Helper text validating that a required comment field cannot be left blank.',
      id: 'actionCompletionForm.formFieldCommentCannotBeBlank',
    },
    formFieldRequiredQuestionHelperText: {
      // eslint-disable-next-line formatjs/no-multiple-whitespaces
      defaultMessage: `Please answer the following questions to complete this Action.
        Your answers will only be visible to program administrators.`,
      description: 'Helper text indicating that the questions are required',
      id: 'actionCompletionForm.formFieldRequiredQuestionHelperText',
    },
    formFieldRequiredQuestionLabel: {
      defaultMessage: 'Required Questions',
      description: 'Label indicating that the questions are required',
      id: 'actionCompletionForm.formFieldRequiredQuestionLabel',
    },
  })

  render() {
    const {
      customFields,
      isSubmitting,
      onValidSubmit,
      requirement,
      isSubmitDisabled,
    } = this.props
    const { hasMentionsField, isImageUploading } = this.state
    const { formatMessage } = intl

    return (
      <Formsy
        className="w-100"
        data-test="action-completion-form"
        noValidate
        onValidSubmit={onValidSubmit}
      >
        <Stack space={3}>
          {customFields.length > 0 && (
            <FormFieldset
              hint={formatMessage(
                this.messages.formFieldRequiredQuestionHelperText
              )}
              label={formatMessage(
                this.messages.formFieldRequiredQuestionLabel
              )}
            >
              {customFields.map((field) => (
                <CustomField field={field} key={field.id} />
              ))}
            </FormFieldset>
          )}

          <FormFieldset
            hint={formatMessage(
              sharedMessages.formFieldShareWithOthersHelperText
            )}
            label={sharedTranslations.shareWithOthers}
          >
            <TextField
              name="message"
              required={requirement.comment}
              textFieldProps={{
                helperText: sharedTranslations.shareYourStoryWithOthers,
                label: sharedTranslations.comment,
                multiline: true,
                rows: 3,
              }}
              validationErrors={{
                isNotBlank: formatMessage(
                  this.messages.formFieldCommentCannotBeBlank
                ),
              }}
              validations={requirement.comment ? 'isNotBlank' : undefined}
            />

            <Collapse
              classes={{ hidden: 'sr-only' }}
              in={hasMentionsField}
              onEntered={this.handleAutocompleteFocus}
            >
              <UserAutocomplete
                name="taggedUserSelections"
                required={requirement.tag}
                setInputRef={(input) => (this.autocomplete = input)}
                textFieldProps={{
                  helperText: sharedTranslations.tagOtherPeople,
                  label: sharedTranslations.tags,
                }}
              />
            </Collapse>

            <div className="d-flex align-items-center">
              <CloudinaryQuery>
                {(cloudinaryConfig) => (
                  <EditableImageAttachment
                    cloudinaryConfig={cloudinaryConfig}
                    name="image"
                    onImageUploading={this.handleImageUploading}
                    required={requirement.photo}
                    shape="square"
                  />
                )}
              </CloudinaryQuery>

              <IconButton
                className="ml-3"
                data-test="tag-users-button"
                onClick={this.handleToggleMentions}
              >
                <Icon
                  iconName="at_symbol"
                  title={sharedTranslations.choosePeopleToTag}
                />
              </IconButton>
              <SubmitButton
                className="ml-auto mt-auto"
                color="primary"
                data-test="action-completion-button"
                disabled={isImageUploading || isSubmitDisabled}
                isSubmitting={isSubmitting}
                variant="contained"
              >
                {sharedTranslations.complete}
              </SubmitButton>
            </div>
          </FormFieldset>
        </Stack>
      </Formsy>
    )
  }
}

ActionCompletionForm.propTypes = {
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isRequired: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string).isRequired,
      responseType: PropTypes.string.isRequired,
    })
  ).isRequired,
  isSubmitDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onValidSubmit: PropTypes.func.isRequired,
  requirement: PropTypes.shape({
    comment: PropTypes.bool,
    photo: PropTypes.bool,
    tag: PropTypes.bool,
  }).isRequired,
}

ActionCompletionForm.defaultProps = {
  isSubmitDisabled: false,
  isSubmitting: false,
}
