// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedNumber } from 'react-intl'
import store from 'redux/store'

// WeSpire
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import { getCustomFieldResponsesAttributes } from 'components/form/custom_field'
import { intl } from 'utilities/localization'
import { selectionValues } from 'components/form/query_autocomplete'
import { setNotification } from 'redux/reducers/notification'
import { sharedTranslations } from 'components/shared/translations'
import { UserScoreContext } from 'utilities/context/UserScoreContext'
import { WordForScore } from 'components/queries/word_for_score'
import { completeActionMutation } from 'graphql/mutations/completeActionMutation'

// Sub-Components
import { CampaignModel } from './campaign_model'
import { LEADERBOARD_QUERY_NAME } from 'components/campaign/leaderboard/utils/queries'

const messages = defineMessages({
  earned: {
    defaultMessage: 'Earned {stringifiedCount} {wordForScore}',
    id: 'withActionMutation.earned',
  },
})

export const withActionMutation = (WrappedComponent) =>
  class WithActionMutation extends React.Component {
    static propTypes = {
      /** action props object */
      action: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      campaign: PropTypes.instanceOf(CampaignModel).isRequired,
      onAchievementEarn: PropTypes.func.isRequired,
      onActionComplete: PropTypes.func,
    }

    static defaultProps = { onActionComplete: null }

    state = {
      isSubmitting: false,
    }

    static contextType = UserScoreContext

    handleSubmit = (values) => {
      const { image, message, taggedUserSelections, ...customFieldResponses } =
        values
      const customFieldResponsesAttributes =
        getCustomFieldResponsesAttributes(customFieldResponses)
      const {
        campaign: { id: campaignId, isCompetition },
        onAchievementEarn,
        onActionComplete,
      } = this.props

      let refetchQueries = []

      if (isCompetition) {
        // Keep leaderboard synced when points change.
        refetchQueries.push(LEADERBOARD_QUERY_NAME)
      }

      this.setState({ isSubmitting: true })

      completeActionMutation({
        campaignId,
        isCompetition,
        refetchQueries,
        variables: {
          actionId: this.props.action.id,
          customFieldResponsesAttributes,
          image,
          message,
          taggedUserIds: selectionValues(taggedUserSelections),
        },
      })
        .then(
          ({
            data: {
              completeAction: { errors, score, totalPointsEarned },
            },
          }) => {
            this.setState({ isSubmitting: false })

            if (errors) {
              displayBanner({
                as: 'div',
                content: <ErrorList errors={errors} />,
                variant: 'error',
              })
            } else {
              const notification = (
                <>
                  {intl.formatMessage(messages.earned, {
                    stringifiedCount: (
                      <FormattedNumber value={totalPointsEarned} />
                    ),
                    wordForScore: (
                      <WordForScore score={totalPointsEarned} wordOnly />
                    ),
                  })}
                </>
              )
              store.dispatch(setNotification(notification))
              onActionComplete && onActionComplete()
              if (score.isForCampaignAchievement) {
                onAchievementEarn()
              }
              this.context?.incrementUserScore?.(totalPointsEarned)
            }
          }
        )
        .catch(() => {
          this.setState({ isSubmitting: false })
          displayExceptionBanner({
            operation: sharedTranslations.completeThisAction,
          })
        })
    }

    render = () => (
      <WrappedComponent
        {...this.props}
        action={this.props.action}
        isSubmitting={this.state.isSubmitting}
        onActionSubmit={this.handleSubmit}
      />
    )
  }
