import React from 'react'
import PropTypes from 'prop-types'
import { donationFormMessages as messages } from '../utilities/messages'
import { intl } from 'utilities/localization'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormFieldset from 'components/form/fieldset'

export const PaymentSection = ({
  balance,
  donationEmployeeAccountEnabled,
  donorCoveringCreditCardFee,
  donorCoveringProcessingFee,
  handleEmployerFunded,
  isEmployerFunded,
  isRecurring,
  getDonorFeeHelpText,
}) => (
  <FormFieldset
    hint={intl.formatMessage(messages.paymentMethodHelpText)}
    label={intl.formatMessage(messages.paymentMethod)}
  >
    <RadioGroup
      aria-label="paymentMethod"
      data-test="payment-method"
      onChange={handleEmployerFunded}
      required
      value={isEmployerFunded.toString()}
    >
      <FormControlLabel
        control={
          <Radio
            color="primary"
            inputProps={{
              'data-test': 'payment-method-card',
            }}
            name="card"
          />
        }
        label={intl.formatMessage(messages.card)}
        value="false"
      />
      <FormHelperText className="ml-5">
        {intl.formatMessage(messages.cardHelpText) +
          getDonorFeeHelpText(
            donorCoveringCreditCardFee,
            donorCoveringProcessingFee
          )}
      </FormHelperText>
      {donationEmployeeAccountEnabled && !isRecurring && (
        <FormControlLabel
          control={
            <Radio
              color="primary"
              inputProps={{
                'data-test': 'payment-method-account',
              }}
              name="donationAccount"
            />
          }
          disabled={balance <= 0}
          label={intl.formatMessage(messages.donationAccount)}
          value="true"
        />
      )}
      {donationEmployeeAccountEnabled && !isRecurring && (
        <FormHelperText className="ml-5">
          {intl.formatMessage(messages.donationAccountHelpText)}
        </FormHelperText>
      )}
    </RadioGroup>
  </FormFieldset>
)

PaymentSection.propTypes = {
  balance: PropTypes.number.isRequired,
  donationEmployeeAccountEnabled: PropTypes.bool.isRequired,
  donorCoveringCreditCardFee: PropTypes.bool.isRequired,
  donorCoveringProcessingFee: PropTypes.bool.isRequired,
  getDonorFeeHelpText: PropTypes.func.isRequired,
  handleEmployerFunded: PropTypes.func.isRequired,
  isEmployerFunded: PropTypes.bool.isRequired,
  isRecurring: PropTypes.bool.isRequired,
}
