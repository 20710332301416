// Returns true if the users match and createdAt is less than 2 seconds.
function isNewForUser(currentUserId, userId, createdAt) {
  const createdTime = new Date(createdAt).getTime()
  const currentTime = Date.now()
  const recent = currentTime - createdTime < 2000 // less than 2 seconds
  return currentUserId === userId && recent
}

const pageSize = 10

export { isNewForUser, pageSize }
