import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

export const DONATION_MATCHING_ENABLED_QUERY = gql`
  query donationMatchingEnabled {
    donationMatchingEnabled
  }
`

export const GLOBAL_GIVING_ENABLED_QUERY = gql`
  query brandConfig {
    brandConfig {
      globalGivingEnabled
    }
  }
`

export default function useGlobalGivingEnabled() {
  const { data } = useQuery(GLOBAL_GIVING_ENABLED_QUERY, {})
  const globalGivingEnabled = data?.brandConfig?.globalGivingEnabled || false

  return globalGivingEnabled
}