// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

const Counter = ({ className, maxValue, value }) => (
  <span className={className}>
    <FormattedNumber value={value} />/<FormattedNumber value={maxValue} />
  </span>
)

Counter.propTypes = {
  className: PropTypes.string,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

Counter.defaultProps = {
  className: null,
}

export default Counter
