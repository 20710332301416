import React from 'react'
import PropTypes from 'prop-types'

// westyle
import InternalLink from 'src/common/InternalLink'
import Numbers from 'src/common/Numbers'
import { FormattedMessage } from 'react-intl'

const VolunteerLog = ({ status, log }) => {
  const dateTime = new Date(log?.date + 'T00:00').toLocaleDateString('en-US')

  return (
    <>
      <div className="gap-y-0.5 grid mt-1 gri grid-cols-[1fr,1fr] sm:grid-cols-[1fr,2fr] md:grid-cols-[1fr,3fr] lg:grid-cols-[1fr,5fr] xl:grid-cols-[1fr,6fr]">
        <div className="inline">
          <span className="body-medium-semibold text-black-3">
            {status === 'completed' ? (
              <FormattedMessage
                id="volunteerHistory.dateVolunteered"
                defaultMessage='Date volunteered'
              />
            ) : (
              <FormattedMessage id="shared.date" defaultMessage={`Date`} />
            )}
            :
          </span>
        </div>
        <div>
          <span className="body-medium-semibold leading-4">{dateTime}</span>
        </div>
        <div>
          <span className="body-medium-semibold text-black-3">
            <FormattedMessage
              id="volunteerHistoryForm.numberOfHours"
              defaultMessage={`Number of hours`}
            />
            :
          </span>
        </div>
        <div className="flex-inline flex-col xl:flex-row content-center">
          <Numbers className="body-medium-semibold pr-2 align-middle">
            {log.hours}
          </Numbers>
          {!log.eventName && (
            <>
              <InternalLink
                className="link-text-small-underlined"
                href={`/users/me/volunteer_hours_form/${log.id}`}
                react
              >
                <FormattedMessage
                  id="volunteerHistory.editVolunteerLog"
                  defaultMessage={`Edit volunteer log`}
                />
              </InternalLink>
            </>
          )}
        </div>
        {log.eventName && (
          <>
            <div className="inline">
              <span className="link-text-small text-black-3">
                <FormattedMessage
                  id="volunteering.volunteerEvent"
                  defaultMessage={`Volunteer event:`}
                />
              </span>
            </div>
            <div>
              <InternalLink
                className="link-text-small-underlined"
                href={log.eventUrl}
              >
                {log.eventName}
              </InternalLink>
            </div>
          </>
        )}
      </div>
    </>
  )
}

VolunteerLog.propTypes = {
  log: PropTypes.object,
  status: PropTypes.string,
}

VolunteerLog.defaultProps = {
  log: {},
  status: '',
}

export default VolunteerLog
