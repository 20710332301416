import { SHARE_PROGRESS_ON_ACCUMULATION_ACTION } from 'graphql/mutations'
import { updateCacheActivityGalleryQueryWithEvents } from 'graphql/utils/campaigns/updateCacheActivityGalleryQueryWithEvents'
import { updateCacheActivityNewsfeedWithEvents } from 'graphql/utils/campaigns/updateCacheActivityNewsfeedWithEvents'
import { client } from 'utilities/we_apollo'

export const shareProgressOnAccumulationActionMutation = ({
  campaignId,
  refetchQueries,
  variables,
}) => {
  return client.mutate({
    awaitRefetchQueries: true,
    mutation: SHARE_PROGRESS_ON_ACCUMULATION_ACTION,
    refetchQueries,
    update(_, { data }) {
      updateCacheActivityGalleryQueryWithEvents(
        campaignId,
        data.shareProgressOnAccumulationAction.newsfeedItems
      )
      updateCacheActivityNewsfeedWithEvents(
        campaignId,
        data.shareProgressOnAccumulationAction.newsfeedItems
      )
    },
    variables,
  })
}
