// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { formatMessages } from 'components/shared/translations'

const messages = defineMessages({
  competitionLeaderboard: {
    defaultMessage: 'Leaderboard',
    description: 'Label for the Competition Leaderboard section',
    id: 'campaignSharedTranslations.competitionLeaderboard',
  },
  deleteYourRegistrationError: {
    defaultMessage: 'delete your registration',
    description:
      'Message indicating that there was a problem deleting your registration',
    id: 'campaignTranslations.deleteYourRegistrationError',
  },
  deleteYourRegistrationSuccess: {
    defaultMessage: 'Your registration was deleted successfully.',
    description:
      'Message indicating that there your registration was successfully deleted',
    id: 'campaignSharedTranslations.deleteYourRegistrationSuccess',
  },
  gallery: {
    defaultMessage: 'Gallery',
    id: 'campaignSharedTranslations.gallery',
  },
  recentActivities: {
    defaultMessage: 'recent Activities',
    description: 'Label for the recent Activities section',
    id: 'campaignSharedTranslations.recentActivities',
  },
  registrationSuccessful: {
    defaultMessage: 'You have been successfully registered.',
    description: 'Registration successful',
    id: 'campaignSharedTranslations.registrationSuccessfullyDeleted',
  },
  saveYourRegistration: {
    defaultMessage: 'save your registration',
    description: 'Save your registration',
    id: 'campaignSharedTranslations.saveYourRegistration',
  },
})

// We export the formatted messages, this way consumers of campaignTranslations
// wont need to call formatMessage themselves which would trigger the translation
// appearing in their localization component json.
export const campaignTranslations = formatMessages(messages)
