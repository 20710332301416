import React from 'react'
import PropTypes from 'prop-types'
import { intl } from 'utilities/localization'
import { donationFormMessages as messages } from '../utilities/messages'
import { FormattedNumber } from 'react-intl'
import { sharedTranslations } from 'components/shared/translations'
import { FormDetail, FormDetails } from 'components/form/form_details'
import FormFieldset from 'components/form/fieldset'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'
import Skeleton from '@material-ui/lab/Skeleton'

export const DetailsSection = ({
  brandName,
  charity,
  donationMatchingEnabled,
  givingaUser,
  donationEmployeeAccountEnabled,
  loadingCurrencyConversion,
  selectedCurrencyIsoCode,
  selectedCurrencyFundsCap,
  selectedCurrencyMatchingFunds,
}) => {
  const { availableDonationFunds, donationFundsCap, employeeAccountBalance } =
    givingaUser
  return (
    <FormFieldset label={intl.formatMessage(messages.details)}>
      <FormDetails>
        <FormDetail
          data-test="charity-name"
          label={sharedTranslations.charityName}
        >
          {charity.name}
        </FormDetail>
        {donationMatchingEnabled && availableDonationFunds > 0 && (
          <>
            <FormDetail
              data-test="qualifies-for-match"
              label={intl.formatMessage(messages.charityMatchingGift)}
            >
              {charity.matched ? sharedTranslations.yes : sharedTranslations.no}
            </FormDetail>
            <FormDetail
              data-test="available-funds"
              label={intl.formatMessage(messages.yourMatchingGiftFunds)}
            >
              {selectedCurrencyIsoCode === 'USD' ? (
                <TextWithHelpIconModal
                  modalContent={
                    <p>
                      {intl.formatMessage(messages.fundsInformationUsd, {
                        availableDonationFunds: (
                          <FormattedNumber
                            currency={selectedCurrencyIsoCode}
                            style="currency"
                            value={
                              selectedCurrencyMatchingFunds ||
                              availableDonationFunds
                            }
                          />
                        ),
                        bold: (str) => <b>{str}</b>,
                        donationFundsCap: (
                          <FormattedNumber
                            currency={selectedCurrencyIsoCode}
                            style="currency"
                            value={selectedCurrencyFundsCap || donationFundsCap}
                          />
                        ),
                      })}
                    </p>
                  }
                  text={
                    loadingCurrencyConversion === true ? (
                      <Skeleton animation="wave" width={40} />
                    ) : (
                      <FormattedNumber
                        currency={selectedCurrencyIsoCode}
                        style="currency"
                        value={
                          selectedCurrencyMatchingFunds ||
                          availableDonationFunds
                        }
                      />
                    )
                  }
                  title={intl.formatMessage(
                    messages.availableMatchingGiftFunds
                  )}
                />
              ) : (
                <TextWithHelpIconModal
                  modalContent={
                    <p>
                      {intl.formatMessage(messages.fundsInformation, {
                        availableDonationFunds: (
                          <FormattedNumber
                            currency={selectedCurrencyIsoCode}
                            style="currency"
                            value={
                              selectedCurrencyMatchingFunds ||
                              availableDonationFunds
                            }
                          />
                        ),
                        bold: (str) => <b>{str}</b>,
                        donationFundsCap: (
                          <FormattedNumber
                            currency={selectedCurrencyIsoCode}
                            style="currency"
                            value={selectedCurrencyFundsCap || donationFundsCap}
                          />
                        ),
                        donationFundsCapForceUsd: (
                          <FormattedNumber
                            currency="USD"
                            style="currency"
                            value={donationFundsCap}
                          />
                        ),
                        donationFundsCapForceUsdISO: 'USD',
                      })}
                    </p>
                  }
                  text={
                    loadingCurrencyConversion === true ? (
                      <Skeleton animation="wave" width={40} />
                    ) : (
                      <FormattedNumber
                        currency={selectedCurrencyIsoCode}
                        style="currency"
                        value={
                          selectedCurrencyMatchingFunds ||
                          availableDonationFunds
                        }
                      />
                    )
                  }
                  title={intl.formatMessage(
                    messages.availableMatchingGiftFunds
                  )}
                />
              )}
            </FormDetail>
          </>
        )}
        {donationEmployeeAccountEnabled && (
          <FormDetail
            data-test="available-balance"
            label={intl.formatMessage(messages.availableBalance)}
          >
            {
              <TextWithHelpIconModal
                modalContent={
                  <p>
                    {intl.formatMessage(messages.balanceInformation, {
                      balance: (
                        <FormattedNumber
                          currency="USD"
                          style="currency"
                          value={employeeAccountBalance}
                        />
                      ),
                      bold: (str) => <b>{str}</b>,
                      brandName,
                    })}
                  </p>
                }
                text={
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={employeeAccountBalance}
                  />
                }
                title={intl.formatMessage(messages.availableBalance)}
              />
            }
          </FormDetail>
        )}
      </FormDetails>
    </FormFieldset>
  )
}

DetailsSection.propTypes = {
  brandName: PropTypes.string.isRequired,
  charity: PropTypes.shape({ matched: PropTypes.bool, name: PropTypes.string })
    .isRequired,
  donationEmployeeAccountEnabled: PropTypes.bool.isRequired,
  donationMatchingEnabled: PropTypes.bool.isRequired,
  givingaUser: PropTypes.shape({
    availableDonationFunds: PropTypes.number.isRequired,
    donationFundsCap: PropTypes.number.isRequired,
    employeeAccountBalance: PropTypes.number.isRequired,
  }).isRequired,
  loadingCurrencyConversion: PropTypes.bool.isRequired,
  selectedCurrencyFundsCap: PropTypes.number.isRequired,
  selectedCurrencyIsoCode: PropTypes.string.isRequired,
  selectedCurrencyMatchingFunds: PropTypes.number.isRequired,
}
