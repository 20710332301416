import React from 'react'
import PropTypes from 'prop-types'

// westyle
import Numbers from 'src/common/Numbers'
import Tooltip from 'src/common/Tooltip'

const NumberWithTooltip = ({ title, tooltip, value }) => {
  return (
    <div className="flex">
      <span className="label-xsmall-caps self-center">{title}</span>
      <Numbers className="numbers-small px-1" currency="USD" style="currency">
        {value}
      </Numbers>
      <Tooltip>{tooltip}</Tooltip>
    </div>
  )
}

NumberWithTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.number.isRequired,
}

export default NumberWithTooltip
