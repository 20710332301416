// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer, FormControlLabel, IconButton, Switch } from '@material-ui/core'
import { defineMessages } from 'react-intl'

// WeSpire
import Divider from 'components/ui/divider'
import Icon from 'components/ui/icon'
import { Heading } from 'components/ui/heading'
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  closeButton: {
    defaultMessage: 'hide page hints for this page',
    description:
      'label presented to assistive technology describing the button that closes the user tour',
    id: 'userTourBase.closeButton',
  },
  heading: {
    defaultMessage: 'tips for getting started',
    description:
      'heading the describes the purpose or context of the user tour message',
    id: 'userTourBase.heading',
  },
  helpText: {
    defaultMessage:
      'You can always reveal getting started tips again by clicking the help icon at the top of the screen.',
    id: 'userTourBase.helpText',
  },
  pageHintsDisabled: {
    defaultMessage: 'Page Hints <bold>Disabled</bold>',
    id: 'userTourBase.pageHintsDisabled',
  },
  pageHintsEnabled: {
    defaultMessage: 'Page Hints <bold>Enabled</bold>',
    id: 'userTourBase.pageHintsEnabled',
  },
})

const UserTourBase = ({
  children,
  invisibleOnLoad,
  isOpen,
  messageKey,
  onDismiss,
  onGlobalDismiss,
  showUserTour,
}) => {
  const [isDrawerOpen, toggleDrawer] = useState(isOpen)

  useEffect(() => {
    toggleDrawer(isOpen)
    document.addEventListener('click', function (event) {
      if (event.target.matches('.js-user-tour-toggle')) {
        const drawer = document.querySelector('.MuiDrawer-root')
        drawer.style.display = 'block'
      }
    })
  }, [isOpen])

  const handleDismissTour = () => {
    if (invisibleOnLoad) {
      const drawer = document.querySelector('.MuiDrawer-root')
      drawer.style.display = 'none'
    } else {
      toggleDrawer(false)
    }
    onDismiss()
  }

  const { formatMessage } = intl

  return (
    <Drawer
      anchor="bottom"
      classes={{
        paper: 'bg-blue-2 px-4 py-5',
        root: 'react',
      }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: 'op-60',
          },
        },
      }}
      onClose={handleDismissTour}
      open={invisibleOnLoad ? true : isDrawerOpen}
      PaperProps={{
        'data-test': 'user-tour-drawer',
      }}
      style={{
        display: isDrawerOpen ? 'block' : 'none',
      }}
    >
      <Stack
        className="m-auto mxw-12 w-100"
        data-test="user-tour-content"
        space={2}
      >
        <div className="d-flex align-items-center justify-content-between">
          <Heading className="fs-1 ls-1 text-black text-uppercase" level={1}>
            {formatMessage(messages.heading)}
          </Heading>
          <IconButton className="align-self-start" onClick={handleDismissTour}>
            <Icon
              className="fs-4 text-black-2"
              data-test="dismiss-message"
              iconName="close"
              title={formatMessage(messages.closeButton)}
            />
          </IconButton>
        </div>
        <Stack>
          <p className="fs-2 lh-md" data-test={`${messageKey}-message`}>
            {children}
          </p>
          <Divider className="my-3" />
          <div className="d-flex align-items-center flex-wrap">
            <Stack className="d-flex align-items-center text-black-2">
              <Icon
                className="mr-2 fs-4 text-black-3"
                iconName="help_outline"
                title=""
              />
              <p className="fs-1 text-black-1 lh-md">
                {formatMessage(messages.helpText)}
              </p>
            </Stack>
            <FormControlLabel
              classes={{
                label: 'fs-1 text-black-1 user-tour-global-dismiss-label',
              }}
              className="ml-auto"
              control={
                <Switch
                  checked={showUserTour}
                  color="secondary"
                  data-test="user-tour-global-dismiss"
                  onChange={() => onGlobalDismiss(!showUserTour)}
                  size="small"
                />
              }
              data-test={`user-tour-global-dismiss-content-${
                showUserTour ? 'enabled' : 'disabled'
              }`}
              label={
                showUserTour
                  ? formatMessage(messages.pageHintsEnabled, {
                      bold: (str) => <b key="pageHintsEnabled">{str}</b>,
                    })
                  : formatMessage(messages.pageHintsDisabled, {
                      bold: (str) => <b key="pageHintsDisabled">{str}</b>,
                    })
              }
            />
          </div>
        </Stack>
      </Stack>
    </Drawer>
  )
}

UserTourBase.propTypes = {
  children: PropTypes.any,
  invisibleOnLoad: PropTypes.bool,
  isOpen: PropTypes.bool,
  messageKey: PropTypes.string,
  onDismiss: PropTypes.func,
  onGlobalDismiss: PropTypes.func,
  showUserTour: PropTypes.bool,
}

UserTourBase.defaultProps = {
  children: null,
  invisibleOnLoad: false,
  isOpen: false,
  messageKey: 'defaultMessage',
  onDismiss: () => {},
  onGlobalDismiss: () => {},
  showUserTour: true,
}

export { UserTourBase }
