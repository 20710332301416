// Setup
import React from 'react'

// WeSpire
import { ErrorPage } from 'components/shared/pages/error'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import WeQuery from 'components/application/we_query'

/**
 * A query to render an entire page using GraphQL data. It defaults to
 * displaying our generic <ErrorPage> if there is an error with the GraphQL
 * query and displaying our <CenteredPaddedLoadingIndicator> while loading.
 * These defaults can be overriden, but should cover the vast majority of our
 * pages.
 **/
const PageQuery = (props) => (
  <WeQuery
    error={<ErrorPage />}
    loader={<CenteredPaddedLoadingIndicator />}
    {...props}
  />
)

export { PageQuery }
