import { COMPLETE_ACTION } from 'graphql/mutations'
import { updateCacheActivityGalleryQueryWithEvents } from 'graphql/utils/campaigns/updateCacheActivityGalleryQueryWithEvents'
import { updateCacheActivityNewsfeedWithEvents } from 'graphql/utils/campaigns/updateCacheActivityNewsfeedWithEvents'
import { client } from 'utilities/we_apollo'

export const completeActionMutation = ({
  campaignId,
  refetchQueries,
  variables,
}) => {
  return client.mutate({
    mutation: COMPLETE_ACTION,
    refetchQueries,
    update(_, { data }) {
      updateCacheActivityNewsfeedWithEvents(
        campaignId,
        data.completeAction.newsfeedItems
      )
      updateCacheActivityGalleryQueryWithEvents(
        campaignId,
        data.completeAction.newsfeedItems
      )
    },
    variables,
  })
}
