import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import { useFormikContext } from 'formik'

// westyle
import Divider from 'src/common/Divider'
import InfoContainer from 'src/common/InfoContainer'
import InputChoice from 'src/common/InputChoice'
import SectionContainer from 'src/common/SectionContainer'

const PaymentMethodSection = ({
  availableDonationFunds,
  paymentMethod,
  setPaymentMethod,
  userCurrency,
}) => {
  const intl = useIntl()

    const { setFieldValue } = useFormikContext()

  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value)
    setFieldValue('paymentMethod', event.target.value)
  }


  return (
    <SectionContainer
      title={intl.formatMessage({
        defaultMessage: 'Payment method',
        id: 'dollarsForDoersForm.paymentMethod',
      })}
    >
      {availableDonationFunds > 10 && (
        <>
          <div className="px-4">
            <InputChoice
              checked={paymentMethod === 'grant_account'}
              explainer={intl.formatMessage({
                defaultMessage:
                  'Donate using your employer funded Personal Grant Account.',
                id: 'donationForm.donationAccountHelpText',
              })}
              label={intl.formatMessage({
                defaultMessage: 'Pay with your Personal Grant Account',
                id: 'donationForm.payWithYourPersonalGrant',
              })}
              name="paymentMethod"
              onChange={handlePaymentMethod}
              type="radio"
              value="grant_account"
            />
            <InfoContainer className="mt-4">
              <span>
                <FormattedMessage
                  defaultMessage="You have {amount} available in your Personal Grant Account"
                  id="donationForm.youHaveAvailableInYourGrantAccount"
                  values={{
                    amount: (
                      <CurrencyFormat
                        className="font-semibold"
                        decimalScale={2}
                        displayType={'text'}
                        fixedDecimalScale
                        prefix={userCurrency.symbol}
                        value={availableDonationFunds}
                      />
                    ),
                  }}
                />
              </span>
            </InfoContainer>
          </div>
          <Divider className="my-5 border-grayscale-2" />
        </>
      )}
      <div className="px-4">
        <InputChoice
          checked={paymentMethod === 'direct'}
          label={intl.formatMessage({
            defaultMessage: 'Pay directly with Credit / Debit / Apple Pay / Google Pay',
            id: 'donationForm.payDirect',
          })}
          name="paymentMethod"
          onChange={handlePaymentMethod}
          type="radio"
          value="direct"
        />
      </div>
    </SectionContainer>
  )
}

export default PaymentMethodSection
