// Vendor
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { withFormsy } from 'formsy-react'
import moment from 'moment'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  defaultHelperText: {
    defaultMessage: 'Format: MM/DD/YYYY',
    id: 'dateField.defaultHelperText',
  },
  isFormattedDate: {
    defaultMessage: 'Must be formatted like 01/31/2020',
    id: 'dateField.isFormattedDate',
  },
  label: {
    defaultMessage: 'Change date',
    id: 'dateField.label',
  },
  // TODO: Place it  into sharedTranslations
  required: {
    defaultMessage: 'This field is required',
    id: 'dateField.required',
  },
})

const dateValue = (value) => {
  // If date is falsy return as-is (don't attempt to format it)
  if (!value) return null
  // If date is specified, format it.
  return moment(value).format('MM/DD/YYYY')
}

const { formatMessage } = intl

const withDateField = (Component) => {
  const Wrapped = ({ validationErrors, validations, value, ...props }) => {
    const valErrors = {
      ...validationErrors,
      matchRegexp: formatMessage(messages.isFormattedDate),
    }
    // It only accepts validations as an object, ie: {myValidation: true}
    const validationList = {
      ...validations,
      matchRegexp:
        /(((0[13578]|1[02])\/(0[1-9]|[12]\d|3[0-1]))|(02\/(0[1-9]|[12]\d))|((0[469]|11)\/(0[1-9]|[12]\d|30)))\/\d{4}$/,
    }

    return (
      <Component
        {...props}
        validationErrors={valErrors}
        validations={validationList}
        value={dateValue(value)}
      />
    )
  }

  Wrapped.propTypes = {
    validationErrors: PropTypes.object,
    validations: PropTypes.object,
    value: PropTypes.string,
  }

  Wrapped.defaultProps = {
    validationErrors: {},
    validations: {},
    value: moment().format('MM/DD/YYYY'),
  }

  return Wrapped
}

withDateField.propTypes = {
  Component: PropTypes.element,
}

const HelperTextComponent = ({ error, fieldHelperText }) => (
  <span className="d-flex | lh-md">
    {error && (
      <Icon
        className="fs-1 mr-1"
        data-test="text-field-validation-error-icon"
        iconName="warning"
        style={{ marginTop: '3px' }}
        title=""
      />
    )}
    {fieldHelperText}
  </span>
)

HelperTextComponent.propTypes = {
  error: PropTypes.bool,
  fieldHelperText: PropTypes.string,
}

HelperTextComponent.defaultProps = {
  error: null,
  fieldHelperText: null,
}

const FormDateFieldBase = ({
  datePickerProps,
  helperText,
  name,
  errorMessage,
  isFormSubmitted,
  isPristine,
  isValid,
  label,
  locale,
  setValue,
  showRequired,
  value,
}) => {
  const error = (!isPristine || isFormSubmitted) && !isValid
  if (showRequired) {
    errorMessage = formatMessage(messages.required)
  }
  const fieldHelperText = error ? errorMessage : helperText
  const fieldLabel = (
    <>
      {label}
      {showRequired && <span aria-hidden>*</span>}
    </>
  )

  const handleChange = (value) => {
    setValue(value?.format('MM/DD/YYYY'))
  }

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      locale={locale}
      utils={MomentUtils}
    >
      <KeyboardDatePicker
        {...datePickerProps}
        error={error}
        // TODO: Change format based on locale.
        format="MM/DD/yyyy"
        fullWidth
        helperText={HelperTextComponent(error, fieldHelperText)}
        inputProps={{
          ...datePickerProps.inputProps,
          className: 'fs-2 lh-md',
          'data-test': name,
        }}
        InputProps={{
          ...datePickerProps.InputProps,
          classes: { adornedEnd: 'pr-0' },
        }}
        inputVariant="outlined"
        KeyboardButtonProps={{
          ...datePickerProps.KeyboardButtonProps,
          'aria-label': formatMessage(messages.label),
        }}
        label={fieldLabel}
        name={name}
        onChange={handleChange}
        value={value}
      />
    </MuiPickersUtilsProvider>
  )
}

FormDateFieldBase.propTypes = {
  datePickerProps: PropTypes.object,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  isFormSubmitted: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  locale: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  showRequired: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

FormDateFieldBase.defaultProps = {
  datePickerProps: {},
  errorMessage: null,
  helperText: formatMessage(messages.defaultHelperText),
  locale: 'en',
  value: null,
}

const FormsyDateField = withFormsy(FormDateFieldBase)
const FormDateField = withDateField(FormsyDateField)

export { FormDateField }
