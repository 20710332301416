// Setup
import React, { Component } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import { withFormsy } from 'formsy-react'

// WeSpire
import CharityAutocomplete from 'components/form/charity_autocomplete'
import { FormRadioGroup } from 'components/form/radio_group'
import { intl } from 'utilities/localization'
import { linkToOrganizationForm } from 'components/organization_form'
import Stack from 'components/ui/stack'
import { sharedTranslations } from 'components/shared/translations'

// Sub-Components
import { BrandOrganizationAutocomplete } from './brand_organization_autocomplete'

const messages = defineMessages({
  addANewOrganization: {
    defaultMessage: 'Add a new Organization',
    id: 'charityOrAddedOrganization.addANewOrganization',
  },
  searchAddedOrganizations: {
    defaultMessage: 'Search Added Organizations',
    id: 'charityOrAddedOrganization.searchAddedOrganizations',
  },
  searchByOrganizationsAdded: {
    defaultMessage: `You can search by name for the Organizations added by you and your colleagues. Can't find your Organization? {organizationFormLink}.`,
    id: 'charityOrAddedOrganization.searchByOrganizationsAdded',
  },
  searchCriteria: {
    defaultMessage:
      'You can search by name, location, or EIN from a US registry of Charitable Organizations.',
    id: 'charityOrAddedOrganization.searchCriteria',
  },
  searchFromRegisterOrCustomList: {
    defaultMessage: `You can search from a US registry of Charitable Organizations or from a list of Organizations added by you and your colleagues. Can't find your Organization? {organizationFormLink}.`,
    id: 'charityOrAddedOrganization.searchFromRegisterOrCustomList',
  },
  searchRegisteredOrganizations: {
    defaultMessage: 'Search Registered Organizations',
    id: 'charityOrAddedOrganization.searchRegisteredOrganizations',
  },
})

/**
 * CharityOrAddedOrganization allows for selecting either a charity or an
 * added organization.
 * CharityOrAddedOrganization will show/hide the right autocomplete field
 * depending on the selection of a radio button.
 * This component uses the following props to set values on the formsy model:
 *    name -- The radio button selection, either 'true' or 'false'.
 *    charityInputName -- The value from the charity autocomplete field.
 *    organizationInputName -- The value from the added organization
 *                                   autocomplete field.
 */
class CharityOrAddedOrganizationUnwrapped extends Component {
  handleChange = (value) => {
    this.props.setValue(value)
  }

  render() {
    const { charityInputName, label, organizationInputName, name, value } =
      this.props
    const { formatMessage } = intl
    const showAdded = value === 'added'
    const organizationFormLink = linkToOrganizationForm(
      formatMessage(messages.addANewOrganization),
      organizationInputName
    )

    return (
      <Stack as={FormControl} component="fieldset" space={1}>
        <FormRadioGroup
          helperText={
            <span>
              {formatMessage(messages.searchFromRegisterOrCustomList, {
                organizationFormLink,
              })}
            </span>
          }
          label={label}
          name={name}
          onChange={this.handleChange}
          options={[
            {
              label: formatMessage(messages.searchRegisteredOrganizations),
              value: 'charity',
            },
            {
              label: formatMessage(messages.searchAddedOrganizations),
              value: 'added',
            },
          ]}
          value={value}
        />
        {/* We hide these autocompletes using classname so that formsy.reset()
            can always target their values. */}
        <BrandOrganizationAutocomplete
          className={cx({ 'd-none': !showAdded })}
          name={organizationInputName}
          required={showAdded}
          searchOnFocus
          textFieldProps={{
            helperText: (
              <span>
                {formatMessage(messages.searchByOrganizationsAdded, {
                  organizationFormLink,
                })}
              </span>
            ),
            label: sharedTranslations.organizationName,
          }}
        />
        <CharityAutocomplete
          className={cx({ 'd-none': showAdded })}
          name={charityInputName}
          queryName="volunteerCharities"
          required={!showAdded}
          secondaryLabel="cityAndState"
          textFieldProps={{
            helperText: formatMessage(messages.searchCriteria),
            label: sharedTranslations.organizationName,
          }}
        />
      </Stack>
    )
  }
}

CharityOrAddedOrganizationUnwrapped.propTypes = {
  charityInputName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organizationInputName: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
}

CharityOrAddedOrganizationUnwrapped.defaultProps = {
  value: 'charity',
}

export const CharityOrAddedOrganization = withFormsy(
  CharityOrAddedOrganizationUnwrapped
)
