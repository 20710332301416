// Vendor
import React from 'react'
import { Accordion, AccordionSummary } from '@material-ui/core'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import Stack from 'components/ui/stack'
import ExpandIcon from 'components/ui/expand_icon'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Heading } from 'components/ui/heading'
import { intl } from 'components/localization_provider'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { ourImpactTranslations } from 'components/our_impact/shared_translations'
import { sharedTranslations } from 'components/shared/translations'
import { SustainabilityImpactStat } from 'components/shared/sustainability_impact_stat'
import WeQuery from 'components/application/we_query'
import WysiwygContent from 'components/ui/wysiwyg_content'

const messages = defineMessages({
  errorDetails: {
    defaultMessage: 'sustainability impact for this brand',
    description:
      'Name of resource to be displayed in an error message when failing to fetch the data',
    id: 'sustainabilityImpact.errorDetails',
  },
  heading: {
    defaultMessage: 'Our collective actions taken have saved',
    description: 'Heading of area showing company-wide impact',
    id: 'sustainabilityImpact.heading',
  },
})

export const SustainabilityImpact = () => {
  const { formatMessage } = intl

  return (
    <>
      <Heading className="px-3" level={3}>
        {formatMessage(messages.heading)}
      </Heading>
      <div>
        <WeQuery
          error={
            <MissingResourceSection
              errorDetails={formatMessage(messages.errorDetails)}
            />
          }
          loader={<SkeletonLoading height={330} />}
          query={gql`
            query brandSustainabilityImpacts {
              brand {
                id
                sustainabilityImpacts {
                  equivalent
                  impactValue
                  name
                  units
                  whyItMatters
                }
              }
            }
          `}
        >
          {({
            data: {
              brand: { sustainabilityImpacts },
            },
          }) =>
            sustainabilityImpacts.map(
              ({ equivalent, name, units, impactValue, whyItMatters }) => (
                <Accordion data-test="sustainability-impact-panel" key={name}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandIcon title={sharedTranslations.toggleDetails} />
                    }
                  >
                    <Stack className="fw-semi-bold" space={2}>
                      <SustainabilityImpactStat
                        equivalent={equivalent}
                        impactValue={impactValue}
                        key={name}
                        name={name}
                        units={units}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack className="fs-1" space={2}>
                      <div className="fw-semi-bold text-uppercase">
                        {ourImpactTranslations.whyItMatters}
                      </div>

                      <WysiwygContent>{whyItMatters}</WysiwygContent>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )
            )
          }
        </WeQuery>
      </div>
    </>
  )
}
