// Setup
import React, { useEffect } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { displayBanner } from 'redux/dispatchers'

// WeSpire
import Stack from 'components/ui/stack'
import { GroupResourcesPanels } from './panels'
import { getUrlParams } from 'utilities/get_url_params'
import { intl } from 'utilities/localization'

import { getBannerMessage } from './utils'

const { formatMessage } = intl

const messages = defineMessages({
  groupResources: {
    defaultMessage: '{ groupName } Resources',
    id: 'groupResources.groupResources',
  },
})

export const GroupResources = ({ groupId, groupName }) => {
  const action = getUrlParams()?.action
  useEffect(() => {
    if (action) {
      displayBanner({
        content: getBannerMessage(action, groupName),
        variant: 'success',
      })
      history.replaceState(
        {},
        formatMessage(messages.groupResources, { groupName }),
        window.location.pathname
      )
    }
  }, [action])

  return (
    <Stack space={4}>
      <GroupResourcesPanels groupId={groupId} groupName={groupName} />
    </Stack>
  )
}

GroupResources.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
}
