// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import { FormattedNumber } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import ScreenReader from 'components/ui/screen_reader_text'
import { WordForScore } from 'components/queries/word_for_score'

const Badge = (props) => (
  <Chip
    {...props}
    className="[ flex-shrink-none ] [ cursor-pointer fw-bold h-auto mw-5ch rounded-circle ]"
  />
)

export const ScoreBadge = ({ iconMargin, iconSize, isCompleted, points }) => {
  if (!points) {
    return null
  }
  return isCompleted ? (
    <Badge
      classes={{ label: 'p-0', root: 'bg-transparent' }}
      data-test="checked-score-badge"
      label={
        <Icon
          className="text-green"
          iconName="checked_circle"
          style={{
            height: iconSize,
            marginRight: iconMargin,
            width: iconSize,
          }}
          title={
            <>
              <WordForScore score={points} />
            </>
          }
        />
      }
    />
  ) : (
    <>
      <Badge
        aria-hidden
        classes={{ label: 'lh-lg p-2' }}
        data-test="unchecked-score-badge"
        label={<FormattedNumber value={points} />}
      />
      <ScreenReader>
        <WordForScore score={points} />
      </ScreenReader>
    </>
  )
}

ScoreBadge.propTypes = {
  iconMargin: PropTypes.string,
  iconSize: PropTypes.string,
  isCompleted: PropTypes.bool,
  points: PropTypes.number.isRequired,
}

ScoreBadge.defaultProps = {
  iconMargin: '-0.0625rem',
  iconSize: '2.375rem',
  isCompleted: false,
}
