// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Wespire
import { IdeaBoardAnalytics } from 'utilities/analytics/'
import WeSpireAPI from 'utilities/wespire_api'

// Sub-Components
import { CommentForm } from './comment_form'

const CommentCreator = ({
  commentable,
  currentUser,
  onCommentSave,
  setCommentFormRef,
  ...other
}) => {
  const { id: commentableId } = commentable
  const [disabled, setDisabled] = useState(false)
  const [value, setValue] = useState('')

  const handleOnChange = (changeEvent) => setValue(changeEvent.target.value)

  function saveNewComment() {
    setDisabled(true)
    WeSpireAPI()
      .post('/comments', {
        comment: {
          commentable_id: commentable.id,
          commentable_type: commentable.type,
          message: value,
        },
      })
      .then((response) => {
        setDisabled(false)
        setValue('')

        onCommentSave?.(response.data.comment)

        IdeaBoardAnalytics.ideaCommentPosted(
          response.data.comment.id,
          commentable.id
        )
      })
      .catch(() => {
        setDisabled(false)
      })
  }

  const handleOnSubmit = (submitEvent) => {
    submitEvent.preventDefault()
    saveNewComment()
  }

  // 1. Don't render these props on <form> element created by <CommentForm>
  delete other.commentable // 1
  delete other.onCommentSave // 1

  return (
    <CommentForm
      {...other}
      commentableId={commentableId}
      disabled={disabled}
      onChange={handleOnChange}
      onSubmit={handleOnSubmit}
      setCommentFormRef={setCommentFormRef}
      setValue={setValue}
      user={currentUser}
      value={value}
    />
  )
}

CommentCreator.propTypes = {
  commentable: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  currentUser: PropTypes.object.isRequired,
  onCommentSave: PropTypes.func,
  setCommentFormRef: PropTypes.func.isRequired,
}

CommentCreator.defaultProps = {
  onCommentSave: () => {},
}

export const undecorated = CommentCreator

export default CommentCreator
