// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { color as systemColor } from 'styles/variables'
import { Heading } from 'components/ui/heading'
import { Image } from 'components/ui/image'
import Stack from 'components/ui/stack'

/**
 * Hero intended to render Channel details. Minimum height container with a
 * text container left-aligned overlaying a wide aspect ratio background image.
 */
const ChannelHeroOverlaidLayout = ({
  description,
  imgUrl,
  imgUrlAltText,
  name,
  secondaryLogoUrl,
  secondaryLogoUrlAltText,
  themeColor,
  ...other
}) => {
  const backgroundColor = themeColor || systemColor['black-2']
  const textColor = themeColor || systemColor['black-1']

  return (
    <div
      {...other}
      className={cx(
        '[ hero ] [ d-flex ] [ position-relative ]',
        other.className
      )}
      data-test="channel-hero-overlaid"
    >
      {imgUrl ? (
        <Image
          alt={imgUrlAltText}
          className="absolute-top-left"
          crop="fill"
          data-object-fit
          dpr="2.0"
          height="288"
          onImageError={(img) => (img.style.backgroundColor = backgroundColor)}
          src={imgUrl}
          width="1080"
        />
      ) : (
        <div
          className="absolute-top-left h-100 w-100"
          style={{ backgroundColor: backgroundColor }}
        />
      )}
      <div className="[ flex-grow ] [ mxw-5 position-relative ]">
        <div className="absolute-top-left bg-white h-100 op-90 w-100" />
        <Stack
          className="[ d-flex flex-column justify-content-between ] [ h-100 p-4 position-relative ]"
          space={3}
        >
          <Stack space={3}>
            <Heading
              className="fs-6 ff-serif ls-3"
              data-test="channel-name"
              level={1}
              style={{ color: textColor }}
            >
              {name}
            </Heading>
            {description && (
              <p className="fs-4 pb-1" data-test="channel-description">
                {description}
              </p>
            )}
          </Stack>
          {secondaryLogoUrl && (
            <Image
              alt={secondaryLogoUrlAltText}
              className="align-self-start"
              data-test="channel-secondary-logo"
              height="32"
              src={secondaryLogoUrl}
            />
          )}
        </Stack>
      </div>
    </div>
  )
}

ChannelHeroOverlaidLayout.propTypes = {
  /** class attribute applied to parent node */
  className: PropTypes.string,
  /** channel description */
  description: PropTypes.string,
  /** channel hero image url */
  imgUrl: PropTypes.string,
  /** channel hero image alt text */
  imgUrlAltText: PropTypes.string,
  /** channel name */
  name: PropTypes.string.isRequired,
  /** channel secondary logo url */
  secondaryLogoUrl: PropTypes.string,
  /** channel secondary logo alt text */
  secondaryLogoUrlAltText: PropTypes.string,
  /** optional admin-configured CSS color value */
  themeColor: PropTypes.string,
}

ChannelHeroOverlaidLayout.defaultProps = {
  className: null,
  description: null,
  imgUrl: null,
  imgUrlAltText: '',
  secondaryLogoUrl: null,
  secondaryLogoUrlAltText: '',
  themeColor: null,
}

export { ChannelHeroOverlaidLayout }
