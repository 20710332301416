// Setup
import PropTypes from 'prop-types'

import iconList from './list'

const SvgPath = ({ iconName }) => iconList[iconName]

SvgPath.propTypes = {
  iconName: PropTypes.oneOf(Object.keys(iconList)).isRequired,
}

export default SvgPath
