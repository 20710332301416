import { stringify } from 'query-string'

const addUrlParams = ({ fromManagementPanel }) => {
  const urlParams = stringify({
    ...urlParams,
    fromManagementPanel,
  })

  if (!urlParams) {
    return ''
  }
  return `?${urlParams}`
}

const editRoleRoute = ({
  isChapter,
  groupId,
  parentGroupId,
  roleId,
  urlParams = {},
}) =>
  isChapter
    ? `/management_panel/groups/${parentGroupId}/chapters/${groupId}/roles/${roleId}/edit${addUrlParams(
        urlParams
      )}`
    : `/management_panel/groups/${groupId}/roles/${roleId}/edit${addUrlParams(
        urlParams
      )}`

const newRoleRoute = ({ isChapter, groupId, parentGroupId, urlParams = {} }) =>
  isChapter
    ? `/management_panel/groups/${parentGroupId}/chapters/${groupId}/roles/new${addUrlParams(
        urlParams
      )}`
    : `/management_panel/groups/${groupId}/roles/new${addUrlParams(urlParams)}`

export { editRoleRoute, newRoleRoute }
