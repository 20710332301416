// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { CurrentUserSection } from './current_user_section'
import { GroupNotJoinedMessage } from '../group_not_joined_message'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import { LeadersSection } from './leaders_section'
import { OtherMembersSection } from './other_members_section'
import { CommonTeamsMembersSection } from './common_teams_members_section'
import { PageQuery } from 'components/queries/page_query'
import Stack from 'components/ui/stack'

const membersQuery = gql`
  query group($id: ID!) {
    group(id: $id) {
      id
      currentUserIsGroupMember
      currentUserIsMember
      currentUserMembershipVisible
      totalMembersCount: membersCount
      name
      roles {
        id
      }
    }
  }
`

const messages = defineMessages({
  groupMembersHeading: {
    defaultMessage: 'Members ({membersCount})',
    id: 'groupMembers.groupMembersHeading',
  },
})

const GroupMembers = ({ groupId }) => {
  const { formatMessage } = intl

  return (
    <PageQuery query={membersQuery} variables={{ id: groupId }}>
      {({ data: { group } }) => {
        const isGroupMember = group.currentUserIsGroupMember
        const isMember = group.currentUserIsMember
        const userIsAllowedToSeeContent =
          isGroupMember || group.roles.length > 0

        if (!userIsAllowedToSeeContent) {
          return <GroupNotJoinedMessage groupName={group.name} />
        }

        return (
          <>
            <Heading
              className="fw-semi-bold px-3 py-2"
              data-test="group-memberships"
              level={2}
            >
              {formatMessage(messages.groupMembersHeading, {
                membersCount: group.totalMembersCount,
              })}
            </Heading>
            <Stack as={Paper}>
              {isMember && (
                <CurrentUserSection
                  visibleUser={group.currentUserMembershipVisible}
                />
              )}
              <LeadersSection groupId={groupId} />
              {isGroupMember && <CommonTeamsMembersSection groupId={groupId} />}
              {isGroupMember && <OtherMembersSection groupId={groupId} />}
            </Stack>
          </>
        )
      }}
    </PageQuery>
  )
}

GroupMembers.propTypes = {
  groupId: PropTypes.string.isRequired,
}

export { GroupMembers }
