// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { Paper } from '@material-ui/core'

// WeSpire
import Divider from 'components/ui/divider'
import LinkButton from 'components/ui/link_button'
import ParticipantsSummary from 'components/application/participants_summary'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'
import { eventsTranslations } from 'components/events/shared_translations'
import { intl } from 'utilities/localization'

// Sub-Components
import {
  joinWaitlistPath,
  selfRegistrationPath,
} from 'components/events/routes'
import { ShiftTimeframePoints } from './shift_timeframe_points'
import { ShiftTimeframeTime } from './shift_timeframe_time'
import { isAfter } from 'utilities/date_utils'
import { ScoreBadge } from 'components/shared/score_badge'

const messages = defineMessages({
  eventEnded: {
    defaultMessage: 'Event ended',
    id: 'shiftTimeframe.eventEnded',
  },
  joinWaitlist: {
    defaultMessage: 'Join waitlist',
    id: 'shiftTimeframe.joinWaitlist',
  },
  onWaitlist: {
    defaultMessage: 'on waitlist',
    id: 'shiftTimeframe.onWaitlist',
  },
  registered: {
    defaultMessage: 'registered',
    id: 'shiftTimeframe.registered',
  },
  registrationEnded: {
    defaultMessage: 'registration ended',
    id: 'shiftTimeframe.registrationEnded',
  },
  registrationFull: {
    defaultMessage: 'registration full',
    id: 'shiftTimeframe.registrationFull',
  },
  userDidNotShow: {
    defaultMessage: 'We missed you! Email {email} with any questions.',
    id: 'shiftTimeframe.userDidNotShow',
  },
})

export const ShiftTimeframe = ({
  canEarnPointsOnEvents,
  contactEmail,
  contactName,
  eventId,
  shiftTimeframe,
  waitlistsEnabled,
}) => {
  const avatarImages = shiftTimeframe.participants.map((user) => user.avatarUrl)
  const registeredShiftId =
    shiftTimeframe.registeredShift && shiftTimeframe.registeredShift.id
  const waitlistedShiftId =
    shiftTimeframe.waitlistedShift && shiftTimeframe.waitlistedShift.id
  const timeframeFull =
    !registeredShiftId &&
    shiftTimeframe.shifts.every((shift) => shift.availableSlotsCount === 0)
  const userDidNotShow = shiftTimeframe?.registeredShiftParticipant?.didNotShow

  const points = shiftTimeframe?.pointsForAttendance

  let unavailableStatus = null
  if (isAfter(shiftTimeframe.end)) {
    unavailableStatus = intl.formatMessage(messages.eventEnded)
  } else if (shiftTimeframe.registrationEnded) {
    unavailableStatus = intl.formatMessage(messages.registrationEnded)
  } else if (timeframeFull) {
    unavailableStatus = intl.formatMessage(messages.registrationFull)
  }

  return (
    <Paper
      className="d-flex justify-content-center flex-column"
      data-test="shift-timeframe"
    >
      <Stack className="p-3" space={3}>
        <Stack className="d-flex align-items-center justify-content-between | lh-md pr-0 w-100">
          <Stack space={1}>
            <ShiftTimeframeTime
              className="fs-2 fw-semi-bold text-uppercase"
              end={shiftTimeframe.end}
              start={shiftTimeframe.start}
            />

            {canEarnPointsOnEvents &&
              (userDidNotShow ? (
                <p className="m-0 fs-1 fw-semi-bold text-black-2">
                  {intl.formatMessage(messages.userDidNotShow, {
                    email: <a href={`mailto:${contactEmail}`}>{contactName}</a>,
                  })}
                </p>
              ) : (
                <ShiftTimeframePoints
                  points={points}
                  pointsAwarded={shiftTimeframe?.pointsAwarded}
                />
              ))}
          </Stack>

          {canEarnPointsOnEvents && (
            <ScoreBadge
              isCompleted={Boolean(
                registeredShiftId || waitlistedShiftId // not sure if this is the same as register?
              )}
              points={points}
            />
          )}
        </Stack>

        <Stack as="ul" space={1}>
          {shiftTimeframe.shifts.map((shift) => {
            const slots = shift.availableSlotsCount

            return (
              <li className="fs-2 lh-lg" data-test="shift" key={shift.id}>
                <span className="fw-semi-bold">{shift.shiftFunction}</span>
                {slots !== null && (
                  <span>
                    {' '}
                    (
                    {intl.formatMessage(sharedMessages.spotsLeft, {
                      count: slots,
                    })}
                    )
                    {waitlistsEnabled &&
                      shift.id !== waitlistedShiftId &&
                      shift.id !== registeredShiftId &&
                      slots === 0 && (
                        <TextLink
                          className="fs-1 ml-2"
                          data-test="join-waitlist-link"
                          to={joinWaitlistPath({
                            eventId: eventId,
                            shiftId: shift.id,
                          })}
                        >
                          {intl.formatMessage(messages.joinWaitlist)}
                        </TextLink>
                      )}
                  </span>
                )}

                {(shift.id === registeredShiftId ||
                  shift.id === waitlistedShiftId) && (
                  <span
                    className="bg-green-2 fs-1 ml-2 p-1 text-uppercase"
                    data-test={
                      shift.id === registeredShiftId
                        ? 'registered-badge'
                        : 'waitlisted-badge'
                    }
                  >
                    {shift.id === registeredShiftId
                      ? intl.formatMessage(messages.registered)
                      : intl.formatMessage(messages.onWaitlist)}
                  </span>
                )}
              </li>
            )
          })}
        </Stack>

        <Divider style={{ margin: '0 -1rem' }} />

        <div className="[ d-flex flex-column justify-content-between | align-items-sm-center flex-sm-row ]">
          <ParticipantsSummary
            avatarImages={avatarImages}
            canParticipate={!shiftTimeframe.registrationEnded}
            participantsCount={shiftTimeframe.participantsCount}
          />
          <div className="[ align-self-end | align-self-sm-center ] [ mt-3 | mt-sm-0 ]">
            {unavailableStatus ? (
              <span className="bg-red-2 fs-1 ml-2 p-1 text-uppercase">
                {unavailableStatus}
              </span>
            ) : (
              <LinkButton
                to={selfRegistrationPath({
                  eventId: eventId,
                  shiftId: shiftTimeframe.id,
                })}
                variant="primary"
              >
                {registeredShiftId
                  ? sharedTranslations.editYourRegistration
                  : eventsTranslations.register}
              </LinkButton>
            )}
          </div>
        </div>
      </Stack>
    </Paper>
  )
}

ShiftTimeframe.propTypes = {
  canEarnPointsOnEvents: PropTypes.bool,
  contactEmail: PropTypes.bool,
  contactName: PropTypes.bool,
  eventId: PropTypes.string.isRequired,
  shiftTimeframe: PropTypes.object.isRequired,
  waitlistsEnabled: PropTypes.bool.isRequired,
}

ShiftTimeframe.defaultProps = {
  canEarnPointsOnEvents: false,
  contactEmail: false,
  contactName: null,
}
