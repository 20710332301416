// Vendor
import React from 'react'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { GET_GROUP_ANNOUNCEMENTS } from '../utilities/queries'
import { GroupAnnouncement } from './group_announcement'
import { groupAnnouncementsMessages as messages } from '../utilities/messages'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'

const GroupAnnouncements = ({ groupId }) => {
  const { formatMessage } = intl

  return (
    <WeQuery
      error={
        <MissingResourceSection
          as={Paper}
          errorDetails={formatMessage(messages.loadingError)}
        />
      }
      loader={<SkeletonLoading height={400} />}
      query={GET_GROUP_ANNOUNCEMENTS}
      variables={{ id: groupId }}
    >
      {({
        data: {
          group: { announcements },
        },
      }) => {
        return (
          <>
            {announcements.length ? (
              announcements.map((announcement) => (
                <GroupAnnouncement
                  author={announcement.author.name}
                  avatarUrl={announcement.author.avatarUrl}
                  dataTest="announcement"
                  date={announcement.publishedAt}
                  groupRole={announcement.selectedLeaderTitle}
                  key={announcement.id}
                  text={announcement.announcementHtml}
                />
              ))
            ) : (
              <Paper>
                <p className="m-0 p-3">
                  {formatMessage(messages.noAnnouncements)}
                </p>
              </Paper>
            )}
          </>
        )
      }}
    </WeQuery>
  )
}

GroupAnnouncements.propTypes = {
  groupId: PropTypes.string.isRequired,
}

export { GroupAnnouncements }
