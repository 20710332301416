// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { Image } from 'components/ui/image'

const LoginLayout = (props) => {
  const { heroTitle, heroSubtitle, logoUrl } = props
  return (
    <div className="col h-100">
      <div className="row h-100">
        <div className="[ d-flex flex-column ] [ col ]">
          <div className="row session-icon justify-content-center">
            <Image
              alt={heroTitle}
              className="logo logo--sm"
              crop="fit"
              dpr="2.0" // TODO: Figure out why this image doesn't auto-detect DPR
              height="40"
              src={logoUrl}
              width="120"
            />
          </div>
          <div className="row session-top-banner">
            <div className="col">
              <h1 className="text-center">{heroTitle}</h1>
              <h3 className="text-center session-subtitle">{heroSubtitle}</h3>
            </div>
          </div>
          <div className="row flex-grow py-3">{props.children}</div>
        </div>
      </div>
    </div>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.any.isRequired,
  heroSubtitle: PropTypes.string.isRequired,
  heroTitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
}

export default LoginLayout
