// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { client } from 'utilities/we_apollo'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { useHistory, useParams } from 'react-router-dom'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import DisableButton from 'components/ui/disable_button'
import { DocumentTitle } from 'components/shared/document_title'
import {
  displayBanner,
  displayExceptionBanner,
  maybeDisplayUserTour,
} from 'redux/dispatchers'
import { EventMissingResourcePage } from 'components/events/event_missing_resource_page'
import { eventsTranslations } from 'components/events/shared_translations'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'
import { FormWrapper } from './form_wrapper'
import { ErrorList } from 'components/form/error_list'
import { EVENT_REGISTRATION_FRAGMENT } from './fragments'
import { EVENT_QUERY } from './event_registration_form'
import { eventPath } from 'components/events/routes'
import { EventGuestBox } from './event_guest_box'
import { EventFormDetails } from './event_form_details'
import { PARTICIPATION_GUESTS_QUERY } from './event_guest_box'
import { SelfRegistrationForm } from './self_registration_form'
import { eventPvhFieldsQuery } from './event_pvh_fields'
import { useUserScore } from 'utilities/hooks/useUserScore'

const EVENT_REGISTRATION = gql`
  ${EVENT_REGISTRATION_FRAGMENT}

  query participationEvent($id: ID!) {
    participationEvent(id: $id) {
      id
      eventRegistration {
        ...EventRegistrationAttributes
      }
    }
  }
`

const SHIFT_TIMEFRAME_PARTICIPATION_EVENT = gql`
  query shiftTimeframe($id: ID!) {
    brandConfig {
      id
      canEarnPointsOnEvents
    }
    shiftTimeframe(id: $id) {
      id

      participationEvent {
        id
        guestsAllowed
        name
      }

      points
      pointsAwarded
      pointsForAttendance

      registeredShift {
        id
      }
    }
  }
`

const DELETE_REGISTRATION = gql`
  mutation deleteRegistration($shiftTimeframeId: ID!) {
    deleteRegistration(shiftTimeframeId: $shiftTimeframeId) {
      errors
      pointsForRegistering
    }
  }
`

const messages = defineMessages({
  dataMigrationBody: {
    defaultMessage:
      'We are currently performing a data migration. Please come back in a few minutes to register for this Event.',
    id: 'eventConfirmationFormComponent.dataMigrationBody',
  },
  dataMigrationTitle: {
    defaultMessage: 'Event registration is temporarily disabled',
    id: 'eventConfirmationFormComponent.dataMigrationTitle',
  },
  deletedSuccesfully: {
    defaultMessage: 'Your registration was deleted successfully.',
    id: 'eventConfirmationFormComponent.deletedSuccesfully',
  },
  deleteRegistration: {
    defaultMessage: 'delete registration',
    id: 'eventConfirmationFormComponent.deleteRegistration',
  },
  formDescription: {
    defaultMessage:
      'Please complete your Event registration using the form below.',
    id: 'eventConfirmationFormComponent.formDescription',
  },
  heading: {
    defaultMessage: '{action} Registration',
    description:
      'Heading for a form to register to an event. The word "action" will be something a user can perform on the registration. It can appear with a word like "complete" or "edit".',
    id: 'eventConfirmationFormComponent.heading',
  },
  operation: {
    defaultMessage: 'delete this Event registration',
    id: 'eventConfirmationFormComponent.operation',
  },
  pageTitle: {
    defaultMessage: '{name} • {action} Registration',
    description:
      'The title of the page. The word "action" will be something a user can perform on the registration. It can appear with a word like "complete" or "edit".',
    id: 'eventConfirmationFormComponent.pageTitle',
  },
  registerGuests: {
    defaultMessage:
      'You can register your guests after completing your own registration.',
    id: 'eventConfirmationFormComponent.registerGuests',
  },
  unregister: {
    defaultMessage: 'This will unregister you for this Event.',
    id: 'eventConfirmationFormComponent.unregister',
  },
  unregisterGuests: {
    defaultMessage: 'This will unregister you and any guests you signed up.',
    id: 'eventConfirmationFormComponent.unregisterGuests',
  },
})

export const EventConfirmationForm = () => {
  const { formatMessage } = intl
  const history = useHistory()
  const { event_id: eventId, timeframe_id: shiftId } = useParams()
  const [isDeleting, setIsDeleting] = useState(false)
  const { reduceUserScore } = useUserScore()

  const handleUnregister = (guestsAllowed) => {
    const { formatMessage } = intl
    const confirmMessage = guestsAllowed
      ? formatMessage(messages.unregisterGuests)
      : formatMessage(messages.unregister)

    if (confirm(confirmMessage)) {
      setIsDeleting(true)
      client
        .mutate({
          awaitRefetchQueries: true, // Don't redirect until new data is ready.
          mutation: DELETE_REGISTRATION,
          refetchQueries: [
            // Refresh index page's participants, shift counts, and registeredShift.
            { query: EVENT_QUERY, variables: { id: eventId } },
            // Clear the eventRegistration object associated with the event.
            // This fixes issue where re-registering would have old data.
            {
              query: EVENT_REGISTRATION,
              variables: { id: eventId },
            },
            // Clear out guest list.
            {
              query: PARTICIPATION_GUESTS_QUERY,
              variables: { id: shiftId },
            },
            // Refetch our query for user's PVH information
            {
              query: eventPvhFieldsQuery,
              variables: { shiftId: shiftId },
            },
          ],
          variables: {
            shiftTimeframeId: shiftId,
          },
        })
        .then(
          ({
            data: {
              deleteRegistration: { errors, pointsForRegistering },
            },
          }) => {
            setIsDeleting(false)
            if (errors) {
              displayBanner({
                as: 'div',
                content: <ErrorList errors={errors} />,
                variant: 'error',
              })
            } else {
              displayBanner({
                content: formatMessage(messages.deletedSuccesfully),
                variant: 'success',
              })
              reduceUserScore(pointsForRegistering)
              history.push(eventPath(eventId))
            }
          }
        )
        .catch(() => {
          setIsDeleting(false)
          displayExceptionBanner({
            operation: formatMessage(messages.operation),
          })
        })
    }
  }

  return (
    <WeQuery
      error={<EventMissingResourcePage />}
      loader={<CenteredPaddedLoadingIndicator />}
      query={SHIFT_TIMEFRAME_PARTICIPATION_EVENT}
      variables={{ id: shiftId }}
    >
      {({
        data: {
          brandConfig: { canEarnPointsOnEvents },
          shiftTimeframe: {
            participationEvent: { id: eventId, guestsAllowed, name },
            points: pointsForRegistering,
            pointsAwarded,
            pointsForAttendance,
            registeredShift,
          },
        },
      }) => {
        const guestMessage = guestsAllowed
          ? ' ' + formatMessage(messages.registerGuests)
          : ''
        const description =
          formatMessage(messages.formDescription) + guestMessage

        const action = registeredShift
          ? sharedTranslations.edit
          : sharedTranslations.complete

        return (
          <DocumentTitle
            title={formatMessage(messages.pageTitle, {
              action: action,
              name: name,
            })}
          >
            {maybeDisplayUserTour('eventRegistrationFormPage')}

            <FormWrapper
              backLinkText={'< ' + eventsTranslations.back}
              backLinkTo={eventPath(eventId)}
              description={registeredShift ? null : description}
              heading={formatMessage(messages.heading, {
                action: action,
              })}
            >
              <Stack space={3}>
                <EventFormDetails
                  canEarnPointsOnEvents={canEarnPointsOnEvents}
                  points={pointsForAttendance}
                  pointsAwarded={pointsAwarded}
                  shiftId={shiftId}
                  showCalendar={!!registeredShift}
                />

                <SelfRegistrationForm
                  pointsForRegistering={pointsForRegistering}
                  shiftId={shiftId}
                />

                {registeredShift && guestsAllowed && (
                  <EventGuestBox eventId={eventId} shiftId={shiftId} />
                )}

                {registeredShift && (
                  <DisableButton
                    isLoading={isDeleting}
                    onClick={() => handleUnregister(guestsAllowed)}
                    size="small"
                  >
                    {formatMessage(messages.deleteRegistration)}
                  </DisableButton>
                )}
              </Stack>
            </FormWrapper>
          </DocumentTitle>
        )
      }}
    </WeQuery>
  )
}

EventConfirmationForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      event_id: PropTypes.string,
      timeframe_id: PropTypes.string,
    }).isRequired,
  }).isRequired,
}
