// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'

// WeSpire
import { PvhFields } from 'components/pvh'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { intl } from 'utilities/localization'
import { maximumPvhClaim } from 'components/pvh'

const eventPvhFieldsQuery = gql`
  query eventPvhFields($shiftId: ID!) {
    currentUser {
      id
      paidVolunteerHoursBalance
    }

    shiftTimeframe(id: $shiftId) {
      hours
      id
      registeredShiftParticipant {
        id
        volunteerLog {
          id
          claimedPaidVolunteerHours
        }
      }
    }
  }
`

const messages = defineMessages({
  errorDetails: {
    defaultMessage: 'Paid Volunteer Hour submission information',
    id: 'eventPvhFields.errorDetails',
  },
})

const EventPvhFields = ({ shiftId }) => (
  <WeQuery
    error={
      <MissingResourceSection
        errorDetails={intl.formatMessage(messages.errorDetails)}
      />
    }
    loader={<CenteredPaddedLoadingIndicator />}
    query={eventPvhFieldsQuery}
    variables={{ shiftId }}
  >
    {({
      data: {
        currentUser: { paidVolunteerHoursBalance },
        shiftTimeframe: { hours, registeredShiftParticipant },
      },
    }) => {
      const claimedPaidVolunteerHours =
        registeredShiftParticipant &&
        registeredShiftParticipant.volunteerLog &&
        registeredShiftParticipant.volunteerLog.claimedPaidVolunteerHours
      const isClaiming = registeredShiftParticipant
        ? !!claimedPaidVolunteerHours
        : null
      const pvhHours =
        claimedPaidVolunteerHours ||
        maximumPvhClaim({
          balance: paidVolunteerHoursBalance,
          hoursVolunteered: hours,
        })

      return (
        <PvhFields
          balance={paidVolunteerHoursBalance}
          hoursVolunteered={hours}
          isClaiming={isClaiming}
          name="pvhFields"
          pvhHours={pvhHours}
        />
      )
    }}
  </WeQuery>
)

EventPvhFields.propTypes = {
  shiftId: PropTypes.string.isRequired,
}

export { EventPvhFields, eventPvhFieldsQuery }
