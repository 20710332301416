// Vendor
import React, { useState } from 'react'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import FormTextField from 'components/form/text_field'
import { GET_CAMPAIGN_BY_ID } from 'graphql/queries'
import { intl } from 'utilities/localization'
import { setNotification } from 'redux/reducers/notification'
import SubmitButton from 'components/ui/submit_button'
import store from 'redux/store'

const messages = defineMessages({
  goalLabel: {
    defaultMessage: 'Your Personal Goal',
    description:
      'Label for a text input field where users can configure a personal metric goal.',
    id: 'actionUserTargetForm.goalLabel',
  },
  operation: {
    defaultMessage: 'save your {metricName} goal',
    id: 'actionUserTargetForm.operation',
  },
  saveButton: {
    defaultMessage: 'Save',
    id: 'actionUserTargetForm.saveButton',
  },
  successMessage: {
    defaultMessage: 'Your {metricName} goal has been successfully saved.',
    description:
      'Text for the success notification displayed when saving custom metric goals',
    id: 'actionUserTargetForm.successMessage',
  },
  userTargetHelperText: {
    defaultMessage: 'Enter your {metricName} goal for this action',
    description: 'Helper text for the user target amount field.',
    id: 'actionUserTargetForm.userTargetHelperText',
  },
  valueValidationError: {
    defaultMessage:
      'Value must be a whole number greater than {minValue} and lower than {maxValue}',
    description:
      'Inline error message displayed when the form value is outside of allowed range or fractional value',
    id: 'actionUserTargetForm.valueValidationError',
  },
})

const SAVE_OVERRIDE_TARGET_VALUE = gql`
  mutation saveOverrideTargetValue($actionId: ID!, $value: Int!) {
    saveOverrideTargetValue(actionId: $actionId, value: $value) {
      errors
    }
  }
`

const { formatMessage } = intl

const ActionUserTargetForm = ({
  actionId,
  campaignId,
  className,
  metricName,
  userOverrideTargetMaxAmount,
  userOverrideTargetMinAmount,
  userProgress,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onComplete = (errors, successMessage) => {
    if (errors) {
      displayBanner({
        as: 'div',
        content: <ErrorList errors={errors} />,
        variant: 'error',
      })
    } else {
      store.dispatch(setNotification(successMessage))
    }
  }

  const handleSubmit = ({ userTargetAmount }) => {
    setIsSubmitting(true)
    client
      .mutate({
        awaitRefetchQueries: true,
        mutation: SAVE_OVERRIDE_TARGET_VALUE,
        refetchQueries: [
          { query: GET_CAMPAIGN_BY_ID, variables: { campaignId } },
        ],
        variables: { actionId, value: parseInt(userTargetAmount) },
      })
      .then(
        ({
          data: {
            saveOverrideTargetValue: { errors },
          },
        }) => {
          onComplete(
            errors,
            formatMessage(messages.successMessage, { metricName })
          )
        }
      )
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(messages.operation, { metricName }),
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const minValue = Math.max(userOverrideTargetMinAmount, userProgress + 1)
  const maxValue = userOverrideTargetMaxAmount
  const validationErrorMessage = formatMessage(messages.valueValidationError, {
    maxValue,
    minValue,
  })

  return (
    <Formsy className={className} onValidSubmit={handleSubmit}>
      <FormTextField
        name="userTargetAmount"
        textFieldProps={{
          helperText: formatMessage(messages.userTargetHelperText, {
            metricName,
          }),
          InputProps: {
            endAdornment: (
              <SubmitButton
                color="secondary"
                data-test="userTargetSubmit"
                isSubmitting={isSubmitting}
              >
                {formatMessage(messages.saveButton)}
              </SubmitButton>
            ),
          },
          label: formatMessage(messages.goalLabel),
          type: 'number',
        }}
        validationErrors={{
          isInt: validationErrorMessage,
          maxValue: validationErrorMessage,
          minValue: validationErrorMessage,
        }}
        validations={`isInt,minValue:${minValue},maxValue:${maxValue}`}
      />
    </Formsy>
  )
}

ActionUserTargetForm.propTypes = {
  actionId: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  className: PropTypes.string,
  metricName: PropTypes.string.isRequired,
  userOverrideTargetMaxAmount: PropTypes.number.isRequired,
  userOverrideTargetMinAmount: PropTypes.number.isRequired,
  userProgress: PropTypes.number,
}

ActionUserTargetForm.defaultProps = {
  className: null,
  userProgress: 0,
}

export { ActionUserTargetForm }
