import gql from 'graphql-tag'

export const impactStatsQuery = gql`
  query donationImpactStats {
    currentUser {
      givingaUser {
        allDonationsToDate
        availableDonationFunds
        companyDonationsToDate
        donationFundsCap
        id
        personalDonationsToDate
      }
      id
    }
    donationMatchingEnabled
    brandConfig {
      retroactiveMatchEnabled
    }
  }
`
export const donationHistoryTransactions = gql`
  query donationHistoryTransactions {
    currentUser {
      givingaUser {
        availableDonationFunds
        id
        transactions {
          accountType
          activityId
          charityName
          donationAmount
          donationDate
          ein
          id
          organizationType
          matchedTransaction {
            matchedAmount
            requestedAmount
            status
          }
          offline
          paymentStatus
        }
      }
      id
    }
    donationMatchingEnabled
    recurringDonationsEnabled
  }
`
export const givingActivity = gql`
  query givingActivity($id: ID!) {
    givingActivity(id: $id) {
      id
      name
    }
  }
`
export const upcomingScheduledDonations = gql`
  query upcomingScheduledDonations {
    upcomingScheduledDonations {
      id
      amount
      charityName
      scheduledDate
      givingaSubscriptionId
      paymentMethod
    }
    recurringDonationsEnabled
  }
`
