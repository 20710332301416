import { sortByAlpha } from 'utilities/sort'

// If a Channel other than the currently selected one already has a Module
// associated to it, indicate that in the Channel option label.
export const channelOptions = (channels, moduleName) =>
  sortByAlpha(channels, 'name').map((channel) => {
    const value = channel.id
    let label = channel.name

    const channelModule = channel.channelModule
    if (channelModule && channelModule !== moduleName) {
      label += ` (associated to ${channelModule})`
    }

    return {
      label: label,
      value: value,
    }
  })
