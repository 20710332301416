import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import cx from 'classnames'
import { useQuery } from '@apollo/client'

import { translations } from 'components/carbon_footprint/utilities/messages'
import { FootprintVisual } from 'components/carbon_footprint/components/FootprintVisual'
import { Heading } from 'components/ui/heading'
import { Links } from 'components/carbon_footprint/components/Links'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import { USERCARBONFOOTPRINTSQUERY } from '../utilities/queries'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { MissingResourceSection } from 'components/ui/missing_resource_section'

export const Footprint = ({ className }) => {
  const { data, error, loading } = useQuery(USERCARBONFOOTPRINTSQUERY)

  if (error) {
    return <MissingResourceSection />
  }

  if (loading) {
    return <SkeletonLoading height={200} />
  }

  const {
    grandTotal,
    personalGoodsTotal: goods,
    personalFoodTotal: food,
    personalHousingTotal: housing,
    transportTotal: transport,
  } = data.userCarbonFootprints[0] ?? {}
  const whyItMatters = data.currentUser.brand.contentModules[0]?.whyItMatters

  return (
    <Paper
      className={cx(
        '[ d-flex justify-content-between flex-column | flex-md-row ] [ px-3 ]',
        className
      )}
    >
      <Stack className="my-3 w-100" space={2}>
        <Heading level={2}>{translations.yourResults}</Heading>
        {grandTotal ? (
          <div className="d-flex flex-column | justify-content-md-between flex-md-row">
            <div className="w-100">
              <Stack
                className="[ d-flex flex-column ] [ my-3 ] [ w-100 ]"
                space={2}
              >
                <Stat
                  amount={grandTotal}
                  key="CO2 per year"
                  label={translations.tonsCO2PerYear}
                  size={7}
                />
                <Links grandTotal={grandTotal} whyItMatters={whyItMatters} />
              </Stack>
            </div>
            <div className="[ my-5 ] [ mt-md-0 | w-md-50 ]">
              <FootprintVisual
                food={food}
                goods={goods}
                housing={housing}
                transport={transport}
              />
            </div>
          </div>
        ) : (
          <>
            <span className="d-flex flex-column" data-test="take-quiz-message">
              {translations.takeQuiz}
            </span>
            <Links grandTotal={grandTotal} whyItMatters={whyItMatters} />
          </>
        )}
      </Stack>
    </Paper>
  )
}

Footprint.propTypes = {
  className: PropTypes.string,
}

Footprint.defaultProps = {
  className: null,
}
