// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Paper } from '@material-ui/core'
import { FormattedNumber } from 'react-intl'

// WeSpire
import { AvatarDimensions } from 'components/application/avatar'
import Stack from 'components/ui/stack'
import Icon from 'components/ui/icon'
import { leaderBoardTranslations } from '../utils/shared_translations'

const LeaderCardPlaceholder = ({ rank, ...other }) => (
  <li
    {...other}
    className={cx('[ col-4 | col-md ] [ d-flex ]', other.className)}
    data-test="leader-card-placeholder"
  >
    <Stack
      as={Paper}
      className="[ d-inline-flex flex-column justify-content-between ] [ p-3 text-center w-100 ]"
      space={3}
      square
    >
      <Stack className="w-100" space={3}>
        <Icon
          className="bg-blue-2 border-decorative border-black-5 border-width-2 rounded-circle text-blue-1"
          iconName="default_avatar"
          style={{
            fontSize: `${AvatarDimensions.xl}px`,
            height: 'unset',
            maxWidth: '100%',
          }}
          title="default profile photo"
        />
        <Stack>
          <div
            className="fs-4 fw-bold lh-md text-black-3"
            data-test="placeholder-rank"
          >
            <FormattedNumber value={rank} />
          </div>
          <p className="fs-1 fw-semi-bold text-black-3">
            {leaderBoardTranslations.participantEmptyState}
          </p>
        </Stack>
      </Stack>
      <div />
    </Stack>
  </li>
)

LeaderCardPlaceholder.propTypes = {
  rank: PropTypes.number.isRequired,
}

export { LeaderCardPlaceholder }
