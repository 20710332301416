export const isIOSDevice = () =>
  ['iPhone Simulator', 'iPhone'].includes(navigator.platform)

export const isAndroidDevice = () =>
  // Hard-coded userAgent string used by Android app with dummy chrome version
  navigator.userAgent.toLowerCase().indexOf('android') > -1 ||
  navigator.userAgent.toLowerCase().indexOf('chrome/95.7.7777.77') > -1

export const isConnected = (linkedDevices = [], validicName) =>
  !!linkedDevices.find((device) => device.type === validicName)
