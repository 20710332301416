// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import store from 'redux/store'
import v from 'voca'

// WeSpire
import { duration } from 'styles/variables'
import { setNotification } from 'redux/reducers/notification'

const clearNotification = (displayNotification) => {
  displayNotification('')
  store.dispatch(setNotification(''))
}

const mapStateToProps = (state) => ({
  reduxNotification: state.notification.text,
})

const AppNotification = ({
  displayNotification,
  notification,
  reduxNotification,
}) => {
  const hasNotification =
    !v.isBlank(notification) || !v.isBlank(reduxNotification)
  const message = notification || reduxNotification

  return (
    <Snackbar
      autoHideDuration={duration.long}
      ContentProps={{
        'aria-describedby': 'app-notification',
      }}
      data-test="notification"
      message={<span id="app-notification">{message}</span>}
      onClose={() => clearNotification(displayNotification)}
      open={hasNotification}
    />
  )
}

AppNotification.propTypes = {
  displayNotification: PropTypes.func.isRequired,
  notification: PropTypes.node,
  reduxNotification: PropTypes.node,
}

AppNotification.defaultProps = {
  notification: '',
  reduxNotification: '',
}

export const undecorated = AppNotification
export default connect(mapStateToProps)(AppNotification)
