// Setup
import React from 'react'
import { defineMessages, FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'

//Vendor
import cx from 'classnames'

// WeSpire
import { AchievementImage } from 'components/achievement'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { intl } from 'utilities/localization'
import ProgressBar from 'components/ui/progress_bar'
import { WordForScore } from 'components/queries/word_for_score'

const messages = defineMessages({
  actions: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {actions} one {action} two {actions} few {actions} many {actions} other {actions}} taken',
    id: 'activityProgressBarDetails.actions',
  },
  complete: {
    defaultMessage: '{stringifiedCount}% complete',
    id: 'activityProgressBarDetails.complete',
  },
  earned: {
    defaultMessage: '{stringifiedCount} {wordForScore} earned',
    description: 'Number of points earned text',
    id: 'activityProgressBarDetails.earned',
  },
  needed: {
    defaultMessage: '{stringifiedCount} {wordForScore} needed',
    description: 'Number of points needed text',
    id: 'activityProgressBarDetails.needed',
  },
})

export const ProgressBarDetails = ({
  achievement,
  className,
  completionPoints,
  progressPercent,
  progressPointsForUser,
  totalParticipationIndicatorsForUser,
}) => {
  const { formatMessage } = intl

  return (
    <ProgressBar
      bottom={
        <span className="d-inline-block fs-1 fw-semi-bold pr-6 text-black-3 text-lowercase">
          {formatMessage(messages.complete, {
            stringifiedCount: <FormattedNumber value={progressPercent} />,
          })}
          <BulletSpacer />
          {formatMessage(messages.actions, {
            count: totalParticipationIndicatorsForUser,
            stringifiedCount: (
              <FormattedNumber value={totalParticipationIndicatorsForUser} />
            ),
          })}
          <BulletSpacer />
          {formatMessage(messages.earned, {
            stringifiedCount: <FormattedNumber value={progressPointsForUser} />,
            wordForScore: (
              <WordForScore score={progressPointsForUser} wordOnly />
            ),
          })}
          {' / '}
          {formatMessage(messages.needed, {
            stringifiedCount: <FormattedNumber value={completionPoints} />,
            wordForScore: <WordForScore score={completionPoints} wordOnly />,
          })}
        </span>
      }
      center={
        achievement && (
          <div className="absolute-right w-100">
            <AchievementImage
              achievementName={achievement.name}
              className={cx(
                'absolute-top-right elevation-1 mr-2 translate-up-50',
                {
                  'op-80': !achievement.awardedTo,
                }
              )}
              data-test={`achievement-${
                achievement.awardedTo ? 'earned' : 'unearned'
              }`}
              effect={!achievement.awardedTo ? 'grayscale' : undefined}
              imageSrc={achievement.imageUrl}
            />
          </div>
        )
      }
      className={className}
      data-test="activity-progress-bar"
      progressPercent={progressPercent}
    />
  )
}

ProgressBarDetails.propTypes = {
  achievement: PropTypes.any.isRequired,
  className: PropTypes.string,
  completionPoints: PropTypes.number.isRequired,
  progressPercent: PropTypes.number.isRequired,
  progressPointsForUser: PropTypes.number.isRequired,
  totalParticipationIndicatorsForUser: PropTypes.number.isRequired,
}

ProgressBarDetails.defaultProps = {
  className: '',
}
