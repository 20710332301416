// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Wespire
import { LikeTextButton } from 'components/application/likes/like_text_button'
import Stack from 'components/ui/stack'
import { UserAttribution } from 'components/user/user_attribution'
import WysiwygContent from 'components/ui/wysiwyg_content'

const Comment = ({
  createdAt,
  currentUserLikeId,
  id,
  likeCount,
  message,
  user,
}) => (
  <div className="d-flex align-items-start" data-test="comment">
    <Stack className="w-100">
      <UserAttribution
        avatarUrl={user.avatarUrl}
        createdAt={createdAt}
        profilePath={user.profilePath}
        user={user}
        userId={user.id}
      />
      <Stack className="ml-6" space={1}>
        <WysiwygContent className="bg-black-6 px-3 py-2 rounded-2">
          {message}
        </WysiwygContent>
        <div className="d-flex align-items-center">
          <LikeTextButton
            className="ml-2"
            likableId={id}
            likableType="Comment"
            likeCount={likeCount}
            likeId={currentUserLikeId}
          />
        </div>
      </Stack>
    </Stack>
  </div>
)

Comment.propTypes = {
  createdAt: PropTypes.string.isRequired,
  currentUserLikeId: PropTypes.string,
  id: PropTypes.string.isRequired,
  likeCount: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
    id: PropTypes.string.isRequired,
    profilePath: PropTypes.string.isRequired,
  }).isRequired,
}

Comment.defaultProps = {
  currentUserLikeId: null,
}

export default Comment
