// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { RelativeTime } from 'components/shared/relative_time'
import Stack from 'components/ui/stack'
import WysiwygContent from 'components/ui/wysiwyg_content'

const GroupAnnouncement = ({
  author,
  avatarUrl,
  dataTest,
  date,
  groupRole,
  text,
}) => {
  return (
    <Paper className="mb-3" data-test={dataTest}>
      <Stack className="p-3" space={3}>
        <div className="d-flex align-items-center">
          <Avatar className="mr-2" image={avatarUrl} size={AvatarSizes.small} />
          <Stack className="d-flex flex-column" space={1}>
            <span className="fs-3 fw-semi-bold" data-test="announcement-author">
              {author}
            </span>
            <div>
              {groupRole && (
                <>
                  <span className="fs-2 mt-1" data-test="author-role">
                    {groupRole}
                  </span>
                  <BulletSpacer />
                </>
              )}
              <RelativeTime
                className="fs-2"
                data-test="announcement-date"
                date={date}
              />
            </div>
          </Stack>
        </div>
        <div className="[ d-flex ] [ border-top ] [ mt-2 ]">
          <WysiwygContent
            className="fs-2 m-0 mt-2"
            data-test="announcement-text"
          >
            {text}
          </WysiwygContent>
        </div>
      </Stack>
    </Paper>
  )
}

GroupAnnouncement.propTypes = {
  author: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  date: PropTypes.string.isRequired,
  groupRole: PropTypes.string,
  text: PropTypes.string.isRequired,
}

GroupAnnouncement.defaultProps = {
  dataTest: null,
  groupRole: null,
}

export { GroupAnnouncement }
