// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'
import { EventMissingResourcePage } from 'components/events/event_missing_resource_page'
import { eventsTranslations } from 'components/events/shared_translations'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'
import { FormWrapper } from './form_wrapper'
import { eventPath } from 'components/events/routes'
import { EventFormDetails } from './event_form_details'
import { JoinWaitlistForm } from './join_waitlist_form'
import { maybeDisplayUserTour } from 'redux/dispatchers'

const messages = defineMessages({
  description: {
    defaultMessage:
      'Please join the waitlist for this Role by using the form below.',
    id: 'joinEventWaitlistPage.description',
  },
  pageTitle: {
    defaultMessage: 'Join the waitlist',
    id: 'joinEventWaitlistPage.pageTitle',
  },
})

const SHIFT_QUERY = gql`
  query shiftForWaitlistForm($id: ID!) {
    shift(id: $id) {
      id
      shiftFunction
    }
  }
`

export const JoinEventWaitlistPage = ({ match }) => {
  const { eventId, shiftId } = match.params
  const { formatMessage } = intl

  return (
    <WeQuery
      error={<EventMissingResourcePage />}
      loader={<CenteredPaddedLoadingIndicator />}
      query={SHIFT_QUERY}
      variables={{ id: shiftId }}
    >
      {({
        data: {
          shift: { shiftFunction },
        },
      }) => {
        const description = formatMessage(messages.description)
        // TODO: add registrationEnded to ShiftObject
        // if (shiftTimeframe.registrationEnded) {
        //   showRegistrationClosedNotice(event)
        // }

        return (
          <DocumentTitle title={formatMessage(messages.pageTitle)}>
            {maybeDisplayUserTour('eventRegistrationWaitlistFormPage')}

            <FormWrapper
              backLinkText={'< ' + eventsTranslations.back}
              backLinkTo={eventPath(eventId)}
              description={description}
              heading={formatMessage(messages.pageTitle)}
            >
              <Stack space={3}>
                <EventFormDetails shiftId={shiftId} />

                <JoinWaitlistForm
                  eventId={eventId}
                  shiftFunction={shiftFunction}
                  shiftId={shiftId}
                />
              </Stack>
            </FormWrapper>
          </DocumentTitle>
        )
      }}
    </WeQuery>
  )
}

JoinEventWaitlistPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string,
      shiftId: PropTypes.string,
    }).isRequired,
  }).isRequired,
}
