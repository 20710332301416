// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const EditableImageAttachmentHelperText = ({ children, ...other }) => (
  <span className={cx('d-flex flex-column | fs-1 lh-md', other.className)}>
    {children}
  </span>
)

EditableImageAttachmentHelperText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EditableImageAttachmentHelperText
