// Setup
import React from 'react'

// WeSpire
import SubmitButton from 'components/ui/submit_button'

/**
 * Submit button intended for use in <FormActionBar>. A form should contain
 * only one of these button types used for the primary cta.
 */
const FormAtionBarSubmitButton = (props) => (
  <SubmitButton {...props} color="primary" variant="contained" />
)

export default FormAtionBarSubmitButton
