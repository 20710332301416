import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { sharedTranslations } from 'components/shared/translations'
import { ErrorPage } from 'components/shared/pages/error'

const OnDemandReportsShow = ({ brandUuid, errors, path, token }) => {
  const width =
    document.getElementsByClassName('setup-content-col')[0].offsetWidth

  if (errors) {
    return <ErrorPage body={sharedTranslations.featureDisabledPageBody} />
  } else {
    return (
      <>
        <Helmet>
          <script
            src="https://prod-useast-a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"
            type="module"
          ></script>
        </Helmet>

        <tableau-viz
          hide-tabs
          id="tableauViz"
          src={`https://prod-useast-a.online.tableau.com/t/wespire/views/${path}`}
          token={token}
          toolbar="hidden"
          width={width}
        >
          <viz-filter field="UUID" value={brandUuid}></viz-filter>
        </tableau-viz>
      </>
    )
  }
}

OnDemandReportsShow.propTypes = {
  brandUuid: PropTypes.string,
  errors: PropTypes.bool,
  path: PropTypes.string,
  token: PropTypes.string,
}

OnDemandReportsShow.defaultProps = {
  brandUuid: null,
  errors: null,
  path: null,
  token: null,
}

export default OnDemandReportsShow
