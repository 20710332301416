// Setup
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Button from '@material-ui/core/Button'

// WeSpire
import { EventCategoryList } from 'setup/event_categories/event_category_list'
import { displayBanner } from 'redux/dispatchers'
import { getUrlParams } from 'utilities/get_url_params'
import { Heading } from 'components/ui/heading'
import MgmtPanelFormPage from 'setup/application/form_page'
import { newEventCategoryPath } from 'setup/event_categories/routes'
import Stack from 'components/ui/stack'

/**
 * The index page for displaying all of a Brand's Event Categories. Currently,
 * we title the page "Volunteer Event Categories" because those are the only
 * types of Event Categories there are. In the future, we expect to render a
 * list of volunteering categories and a list of non-volunteering categories on
 * this page.
 */
const EventCategoriesIndexPage = ({ brandId }) => {
  useEffect(() => {
    // If an Event Category was just created...
    if (getUrlParams().created) {
      displayBanner({
        content: <p>Your Event Category was successfully added.</p>,
        fullWidth: true,
        variant: 'success',
      })
      // Only display the banner once, not on subsequent historical visits
      // (e.g. back button).
      history.replaceState({}, 'Event Category Index', window.location.pathname)
    }
  })

  return (
    <MgmtPanelFormPage
      contentHeaderChildren={
        <Button color="primary" href={newEventCategoryPath} variant="contained">
          New Category
        </Button>
      }
      isEdit
      resourceName="Volunteer Event Categories"
    >
      {() => (
        <Stack space={3}>
          <Heading level={2} variant={2}>
            Ordered Categories
          </Heading>
          <p>
            This is the list of categories in the order that they will appear
            when users need to select one. You can change the order of the list
            using the ordering buttons on each category.
          </p>
          <EventCategoryList brandId={brandId} />
        </Stack>
      )}
    </MgmtPanelFormPage>
  )
}

EventCategoriesIndexPage.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export default EventCategoriesIndexPage
