// vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'

// Wespire
import { ACTIVITY_CARD_FRAGMENT } from 'components/activities/fragments'
import { PageQuery } from '/components/queries/page_query'
import ActivitiesCardList from '/components/activities/card_list'
import ActivitiesEmptyState from '/components/activities/empty_state'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'

const groupActivitiesQuery = gql`
  query groupActivities($id: ID!) {
    group(id: $id) {
      id
      activities {
        ...ActivityAttributes
        visible
      }
    }
  }
  ${ACTIVITY_CARD_FRAGMENT}
`

const messages = defineMessages({
  activitiesTitle: {
    defaultMessage: 'Events & Activities',
    id: 'groupActivities.activitiesTitle',
  },
  groupActivitiesEmpty: {
    defaultMessage:
      'There are currently no Activities available in this Group.',
    id: 'groupActivities.groupActivitiesEmpty',
  },
})

const { formatMessage } = intl

const GroupActivities = ({ groupId, setActivity, showModal }) => {
  return (
    <PageQuery query={groupActivitiesQuery} variables={{ id: groupId }}>
      {({
        data: {
          group: { activities },
        },
      }) => {
        return activities.length ? (
          <>
            <Heading
              className="fw-semi-bold px-3 py-2"
              data-test="group-activities"
              level={2}
            >
              {formatMessage(messages.activitiesTitle)}
            </Heading>
            <ActivitiesCardList
              activities={activities}
              fromGroupId={groupId}
              onActivityClick={(_, activity) => {
                // If activity is not visible, prompt the user to join the group in order to grant them visibility.
                if (!activity.visible) {
                  setActivity(activity)
                  showModal(true)
                }
              }}
            />
          </>
        ) : (
          <ActivitiesEmptyState
            testName="activity-cards-empty-state"
            text={formatMessage(messages.groupActivitiesEmpty)}
          />
        )
      }}
    </PageQuery>
  )
}

GroupActivities.propTypes = {
  groupId: PropTypes.string.isRequired,
  setActivity: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
}

export { GroupActivities, groupActivitiesQuery }
