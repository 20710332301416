// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'

// WeSpire
import Link from 'components/shared/link'

// Disabled to prevent es-lint error. For more info visit: https://github.com/yannickcr/eslint-plugin-react/issues/597
// eslint-disable-next-line
export const DropdownLink = React.forwardRef(
  ({ children, onClick, to, ...other }, ref) => {
    const isActive = window.location.pathname.includes(to)

    return (
      <MenuItem
        {...other}
        className={cx(
          'text-capitalize',
          { 'text-black-1': !isActive },
          { 'text-blue': isActive }
        )}
        component={Link}
        dense
        onClick={onClick}
        ref={ref}
        to={to}
      >
        {children}
      </MenuItem>
    )
  }
)

DropdownLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
}
