// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Hero from 'components/ui/hero'
import HeroIcon from 'components/ui/hero/icon'

export const ActivityHero = ({ imageUrl, label, title, iconName }) => (
  <Hero
    icon={iconName && <HeroIcon iconName={iconName} />}
    imgUrl={imageUrl}
    label={label}
    title={title}
    titleProps={{
      className: 'mb-5',
    }}
  />
)

ActivityHero.propTypes = {
  iconName: PropTypes.string,
  imageUrl: PropTypes.string,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

ActivityHero.defaultProps = {
  iconName: null,
  imageUrl: null,
}
