// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const HeroLabel = ({ text, ...other }) => (
  <div
    {...other}
    className={cx(
      'fs-2 fs-sm-3 fw-semi-bold lh-sm ls-2 my-0 text-white text-uppercase',
      other.className
    )}
    data-test="hero-label"
    id="hero-label"
  >
    {text}
  </div>
)

HeroLabel.propTypes = {
  text: PropTypes.string.isRequired,
}

export default HeroLabel
