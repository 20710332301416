import {
  ANIMAL_PRODUCTS_CONSUMED,
  APPROXIMATE_DISTANCE_TRAVELED_CAR,
  APPROXIMATE_DISTANCE_TRAVELED_EACH_YEAR,
  COUNTRY,
  EXTENDED_BUSINESS_FLIGHT,
  HOUSEHOLD_SIZE,
  // HOUSING_TYPE,
  LONG_BUSINESS_FLIGHT,
  LONG_PERSONAL_FLIGHT,
  MEDIUM_BUSINESS_FLIGHT,
  MEDIUM_PERSONAL_FLIGHT,
  PERCENTAGE_RENEWABLE_ENERGY,
  POSTAL_CODE,
  REMOTE_NUMBER_OF_DAYS,
  ROUND_TRIP_DISTANCE,
  SHORT_BUSINESS_FLIGHT,
  SHORT_PERSONAL_FLIGHT,
  TRANSPORT_TYPE,
  VEHICLE_FUEL_EFFICIENCY,
  VEHICLE_TYPE,
} from './constants'
import countries from './countries'

const flightOptions = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
]

export const calculatorQuestionsAnswersData = [
  {
    question: 'Where do you live?',
    questionGroup: 'household',
    factHeader: 'Your home contributes to your carbon footprint',
    factImage: 'housing',
    factText:
      'Your location has an impact in estimating your carbon footprint, such as potential commuting patterns and home energy usage.',
    answerOptions: [
      {
        subheader: '',
        questionKey: COUNTRY,
        answers: countries,
        componentSelector: 'Autocomplete',
      },
      {
        subheader: '',
        questionKey: POSTAL_CODE,
        answers: [],
        componentSelector: 'LocationCode',
      },
    ],
  },
  {
    question: 'How many people live in your household?',
    questionGroup: 'household',
    factHeader: 'Your home contributes to your carbon footprint.',
    factImage: 'housing',
    factText:
      'We’ll divide some of your answers by the number of people in your household to better estimate your individual carbon footprint.',
    answerOptions: [
      {
        subheader: '',
        questionKey: HOUSEHOLD_SIZE,
        answers: [
          {
            label: '1',
            value: 1,
            // descriptionText: '1 descriptionText',
          },
          {
            label: '2',
            value: 2,
            // descriptionText: '2 descriptionText',
          },
          {
            label: '3',
            value: 3,
            // descriptionText: '3 descriptionText',
          },
          {
            label: '4',
            value: 4,
            // descriptionText: '4 descriptionText',
          },
          {
            label: '5 or more',
            value: 5,
            // descriptionText: '5 descriptionText',
          },
        ],
        componentSelector: 'Slider',
        max: 5,
        min: 1,
      },
    ],
  },
  // {
  //   question: 'Which housing type best describes your home?',
  //   questionGroup: 'household',
  //   factHeader: 'Your home contributes to your carbon footprint.,
  //   factImage:
  //   factText: 'The choices in heating and cooling your home can have a big impact on the carbon footprint of your home. Proper insulation can reduce heating and cooling costs in addition to reducing carbon emissions for your home.',
  //   answerOptions: [
  //     {
  //       subheader: '',
  //       questionKey: HOUSING_TYPE,
  //       answers: [
  //         {
  //           label: 'Apartment',
  //           value: 1,
  //           descriptionText: '(100m2 / 200 sq ft)',
  //         },
  //         {
  //           label: 'Tiny',
  //           value: 2,
  //           descriptionText: '(200m2 / 1,500 sq ft)',
  //         },
  //         {
  //           label: 'Small',
  //           value: 4,
  //           descriptionText: '(300m2 / 3,000 sq ft)',
  //         },
  //         {
  //           label: 'Medium',
  //           value: 6,
  //           descriptionText: '(600m2 / 4,500 sq ft)',
  //         },
  //         {
  //           label: 'Large',
  //           value: 8,
  //           descriptionText: '(800m2 / 6,000 sq ft)',
  //         },
  //       ],
  //       componentSelector: 'Slider',
  //       max: 8,
  //       min: 1,
  //     },
  //   ],
  // },
  {
    question: 'How much of your home electricity comes from renewable sources?',
    questionGroup: 'household',
    factHeader: 'Your home contributes to your carbon footprint.',
    factImage: 'housing',
    factText:
      'Choosing renewable energy sources such as solar and wind power are a great way to reduce the carbon impact of your home.',
    answerOptions: [
      {
        subheader: 'PERCENTAGE OF RENEWABLE ENERGY (SOLAR, WIND, OR SIMILAR)',
        questionKey: PERCENTAGE_RENEWABLE_ENERGY,
        answers: [
          {
            value: 0,
            label: '0%',
            descriptionText: 'If you are unsure, please choose 0%.',
          },
          { value: 10, label: '10%' },
          { value: 20, label: '20%' },
          { value: 30, label: '30%' },
          { value: 40, label: '40%' },
          { value: 50, label: '50%' },
          { value: 60, label: '60%' },
          { value: 70, label: '70%' },
          { value: 80, label: '80%' },
          { value: 90, label: '90%' },
          { value: 100, label: '100%' },
        ],
        componentSelector: 'Slider',
        min: 0,
        max: 100,
      },
    ],
  },
  {
    question:
      'How often do you eat animal-based products? (meat, fish, eggs, and dairy)',
    questionGroup: 'food',
    factHeader: 'Your nutrition choices impact your carbon footprint.',
    factImage: 'consumption',
    factText:
      'Reducing meat consumption, especially beef and pork, and purchasing locally sourced foods can reduce your carbon footprint.',
    answerOptions: [
      {
        subheader: 'Frequency',
        questionKey: ANIMAL_PRODUCTS_CONSUMED,
        answers: [
          {
            value: 0,
            label: 'Never',
            descriptionText: 'Vegan: no eggs, animal-sourced dairy or meat',
          },
          {
            value: 1,
            label: 'Infrequently',
            descriptionText: 'Vegetarian: some eggs or dairy, but no meat',
          },
          {
            value: 2,
            label: 'Often',
            descriptionText:
              'Eggs or dairy on most days, meat a few times a week',
          },
          {
            value: 3,
            label: 'Very Often',
            descriptionText:
              'Eggs, dairy, and/or meat in most meals on most days',
          },
        ],
        componentSelector: 'Slider',
        min: 0,
        max: 3,
      },
    ],
  },
  {
    question:
      'How many one-way <span class="uppercase">business</span> flights do you take in a typical year?',
    questionGroup: 'travel',
    factHeader: 'How you travel impacts your carbon footprint.',
    factImage: 'transportation',
    factText:
      'Some airlines allow you to purchase a carbon credit when booking to help reduce the impact of your travel.',
    answerOptions: [
      {
        subheader: 'SHORT FLIGHT: (UNDER 400 MILES)',
        questionKey: SHORT_BUSINESS_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
      {
        subheader: 'MEDIUM FLIGHT: (400-1,500 MILES)',
        questionKey: MEDIUM_BUSINESS_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
      {
        subheader: 'LONG FLIGHT: (1,500-3,000 MILES)',
        questionKey: LONG_BUSINESS_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
      {
        subheader: 'EXTENDED FLIGHT: (3,000+ MILES)',
        questionKey: EXTENDED_BUSINESS_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
    ],
  },
  {
    question:
      'How many one-way <span class="uppercase">personal</span> flights do you take in a typical year?',
    questionGroup: 'travel',
    factHeader: 'How you travel impacts your carbon footprint.',
    factImage: 'transportation',
    factText:
      'Some airlines allow you to purchase a carbon credit when booking to help reduce the impact of your travel.',
    answerOptions: [
      {
        subheader: 'SHORT FLIGHT: (UNDER 400 MILES)',
        questionKey: SHORT_PERSONAL_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
      {
        subheader: 'MEDIUM FLIGHT: (400-1,500 MILES)',
        questionKey: MEDIUM_PERSONAL_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
      {
        subheader: 'LONG FLIGHT: (1,500-3,000 MILES)',
        questionKey: LONG_PERSONAL_FLIGHT,
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
      {
        subheader: 'EXTENDED FLIGHT: (3,000+ MILES)',
        questionKey: 'extendedPersonalFlight',
        answers: flightOptions,
        componentSelector: 'Slider',
        helperDescriptionText: 'flights',
        min: 0,
        max: 10,
        steps: 1,
      },
    ],
  },
  {
    question:
      'What best describes the vehicle you travel in most often as a driver or passenger?',
    questionGroup: 'travel',
    factHeader: 'How you travel impacts your carbon footprint.',
    factImage: 'transportation',
    factText:
      'Selecting an electric vehicle, or more fuel efficient car, can reduce carbon emissions when driving or travelling via a ride sharing service.',
    answerOptions: [
      {
        subheader: 'vehicle type',
        questionKey: VEHICLE_TYPE,
        answers: [
          { value: 0, label: 'None - I do not travel by car' },
          { value: 1, label: 'Gas or Hybrid' },
          { value: 2, label: 'Diesel' },
          { value: 3, label: 'Electric' },
        ],
        min: 0,
        max: 2,
        componentSelector: 'ToggleButtonGroup',
      },
      {
        subheader: 'Approximate distance traveled with vehicle each year',
        questionKey: APPROXIMATE_DISTANCE_TRAVELED_CAR,
        answers: [
          { value: 0, label: '0', descriptionText: '' },
          { value: 50000, label: '50000', descriptionText: '' },
        ],
        helperDescriptionText: 'miles',
        componentSelector: 'Slider',
        max: 50000,
        steps: 1000,
      },
      {
        subheader: `vehicle's fuel efficiency`,
        questionKey: VEHICLE_FUEL_EFFICIENCY,
        answers: [
          { value: 0, label: '0' },
          { value: 150, label: '150' },
        ],
        helperDescriptionText: 'MPG',
        min: 0,
        max: 150,
        steps: 10,
        componentSelector: 'Slider',
      },
    ],
  },
  {
    question:
      'Approximately how many miles do you travel using public transportation every year?',
    questionGroup: 'travel',
    factHeader: 'How you travel impacts your carbon footprint.',
    factImage: 'transportation',
    factText:
      'Selecting public transportation options such as bus, subway, or commuter rail instead of travelling by car can reduce your carbon emissions.',
    answerOptions: [
      {
        subheader: 'approximate distance traveled each year',
        questionKey: APPROXIMATE_DISTANCE_TRAVELED_EACH_YEAR,
        answers: [
          { value: 0, label: '0', descriptionText: '' },
          { value: 50000, label: '50000', descriptionText: '' },
        ],
        helperDescriptionText: 'miles',
        componentSelector: 'Slider',
        max: 50000,
        steps: 1000,
      },
    ],
  },
  {
    question: 'How would you best describe your commute to work?',
    questionGroup: 'Commuting',
    factHeader: 'How you travel impacts your carbon footprint.',
    factImage: 'transportation',
    factText:
      'If available to you, working from home can reduce your travel-related carbon emissions.',
    answerOptions: [
      {
        subheader: 'transport type',
        questionKey: TRANSPORT_TYPE,
        answers: [
          { value: 'Car', label: 'Car' },
          { value: 'Motorbike', label: 'Motorbike' },
          { value: 'Commuter rail', label: 'Subway Train' },
          { value: 'Transit rails', label: 'Regional Train' },
          { value: 'Bus', label: 'Bus' },
          { value: 'Carpool', label: 'Carpool' },
          { value: 'Bike or walk', label: 'Bike or walk' },
          { value: 'None', label: 'None - I work remotely' },
        ],
        helperDescriptionText: 'miles',
        componentSelector: 'ToggleButtonGroup',
        max: 7,
      },
      {
        subheader: 'round trip distance',
        questionKey: ROUND_TRIP_DISTANCE,
        answers: [
          { value: 0, label: '0', descriptionText: '' },
          { value: 300, label: '300', descriptionText: '' },
        ],
        helperDescriptionText: 'miles',
        componentSelector: 'Slider',
        max: 300,
        steps: 10,
      },
    ],
  },
  {
    question: 'How many days a week do you typically work from home?',
    questionGroup: 'Commuting',
    factHeader: 'How you often you commute impacts your carbon footprint.',
    factImage: 'transportation',
    factText:
      'If available to you, working from home can reduce your travel-related carbon emissions.',
    answerOptions: [
      {
        subheader: 'Number of Days',
        questionKey: REMOTE_NUMBER_OF_DAYS,
        answers: [
          { value: 0, label: 'none', descriptionText: '' },
          { value: 5, label: '5', descriptionText: '' },
        ],
        helperDescriptionText: 'days a week',
        componentSelector: 'Slider',
        max: 5,
        steps: 1,
      },
    ],
  },
]

export default calculatorQuestionsAnswersData
