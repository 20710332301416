// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import MgmtPanelFormPage from 'setup/application/form_page'
import { SecondaryLocaleForm } from './secondary_locale_form'
import { sharedTranslations } from 'components/shared/translations'

const EditSecondaryLocale = (props) => {
  const { channel, secondaryLocale, secondaryLocaleKey } = props
  const { defaultName } = channel

  return (
    <MgmtPanelFormPage
      {...props}
      isEdit
      paperProps={{
        classes: { root: 'bg-transparent' },
        elevation: 0,
      }}
      resourceName={`${sharedTranslations.channelLabel} - ${defaultName} - ${secondaryLocale}`}
      space={0}
    >
      {({ displayNotification }) => (
        <SecondaryLocaleForm
          channel={channel}
          className="m-4"
          displayNotification={displayNotification}
          secondaryLocaleKey={secondaryLocaleKey}
        />
      )}
    </MgmtPanelFormPage>
  )
}
EditSecondaryLocale.propTypes = {
  channel: PropTypes.shape({
    defaultName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  secondaryLocale: PropTypes.string.isRequired,
  secondaryLocaleKey: PropTypes.string.isRequired,
}
export default EditSecondaryLocale
