// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import ScreenReader from 'components/ui/screen_reader_text'

/**
 * Intended to wrap a like button. Styles and aligns its contents, and provides
 * ability to make screen reader announcements.
 */
const LikeButtonWrapper = ({
  ariaLiveText,
  children: likeButton,
  ...other
}) => (
  <>
    <span
      {...other}
      className={cx(
        '[ d-inline-flex align-items-center ] [ text-black-3 ]',
        other.className
      )}
    >
      {likeButton}
    </span>
    <ScreenReader aria-live="polite" text={ariaLiveText} />
  </>
)

LikeButtonWrapper.propTypes = {
  /** text announced to screen readers on like/unlike  */
  ariaLiveText: PropTypes.node,
  /** the like button  */
  children: PropTypes.node.isRequired,
}

LikeButtonWrapper.defaultProps = {
  ariaLiveText: null,
}

export { LikeButtonWrapper }
