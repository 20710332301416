import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const ProgressBar = ({ className, progress, children }) => {
  return (
    <div className={cx('grid', className)}>
      <div
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
        className="w-full bg-grayscale-2 rounded-full h-1.5 mb-2"
      >
        <div
          data-test="progress-bar-fill"
          className="bg-thriving-green h-1.5 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
      {children && (
        <span className="body-medium-regular text-black-3">{children}</span>
      )}
    </div>
  )
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number.isRequired,
  children: PropTypes.node,
}

export default ProgressBar
