import { sharedTranslations } from 'components/shared/translations'
import { userProfileTranslations } from './shared_translations'

const actionUrlText = (type) =>
  ({
    campaign: userProfileTranslations.takeActionToComplete,
    competition: userProfileTranslations.keepOnCompeting,
    event: sharedTranslations.editYourRegistration,
    givingactivity: userProfileTranslations.donationToOtherOrganizations,
    ideaboard: userProfileTranslations.voteOnNewIdeas,
  }[type])

const activitiesByType = (activities, key) => {
  return activities.reduce((activitiesByType, activity) => {
    if (key === 'campaign') {
      if (activity.type === key || activity.type === 'competition') {
        activitiesByType.push(activity)
      }
    } else if (activity.type === key) {
      activitiesByType.push(activity)
    }
    return activitiesByType
  }, [])
}

const activityCardSkeletonsHeight = 150

const participationIndicatorText = (type) =>
  ({
    campaign: userProfileTranslations.actionsTaken,
    competition: userProfileTranslations.actionsTaken,
    event: userProfileTranslations.hoursParticipated,
    givingactivity: null,
    ideaboard: userProfileTranslations.ideasSubmitted,
  }[type])

const orderedTypeLabels = {
  campaign: userProfileTranslations.campaignsAndCompetitions,
  givingactivity: userProfileTranslations.givingActivities,
  // eslint-disable-next-line sort-keys
  event: sharedTranslations.events,
  ideaboard: sharedTranslations.ideaBoards,
}

export {
  actionUrlText,
  activitiesByType,
  activityCardSkeletonsHeight,
  participationIndicatorText,
  orderedTypeLabels,
}
