// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import gql from 'graphql-tag'

// WeSpire
import WeQuery from 'components/application/we_query' // This import must be before the 'utilities/user' in order to build storybook.
import { abbreviatedName, fullName } from 'utilities/user'

export const userQuery = gql`
  query getUser($userId: ID!) {
    user(id: $userId) {
      firstName
      id
      lastName
    }
  }
`
export const currentUserQuery = gql`
  query getCurrentUser {
    currentUser {
      firstName
      id
      lastName
    }
  }
`

const UserNameQuery = ({ format, user, userId }) => {
  return user && user.firstName && user.lastName ? (
    format === 'abbreviated' ? (
      abbreviatedName(user)
    ) : (
      fullName(user)
    )
  ) : (
    <WeQuery
      error={<>User Name</>}
      loader={null}
      query={userId ? userQuery : currentUserQuery}
      variables={{ userId: userId }}
    >
      {({ data }) => {
        const userObject = userId ? data.user : data.currentUser
        return format === 'abbreviated'
          ? abbreviatedName(userObject)
          : fullName(userObject)
      }}
    </WeQuery>
  )
}

UserNameQuery.propTypes = {
  format: PropTypes.oneOf(['abbreviated', 'full']),
  user: PropTypes.object,
  userId: PropTypes.string,
}

UserNameQuery.defaultProps = {
  format: 'full',
  user: null,
  userId: null,
}

export { UserNameQuery }
