// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import Icon from 'components/ui/icon'
import ScreenReader from 'components/ui/screen_reader_text'

/** Renders a link with the proper setup for opening in a new window:
 *    - target blank to trigger the new window
 *    - noopener noreferrer for proper security
 *    - open in new window icon for visual users + title for a11y
 */

const messages = defineMessages({
  iconLabel: {
    defaultMessage: 'opens external site in a new window',
    id: 'externalLink.iconLabel',
  },
})

const { formatMessage } = intl

const ScreenReaderPause = () => <ScreenReader text="," />

const ExternalLink = ({
  children,
  text,
  to,
  className,
  hideIcon,
  ...props
}) => (
  <a
    className={cx(className, 'text-link')}
    href={to}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
  >
    {children || text || to}
    <ScreenReaderPause />
    {!hideIcon && (
      <Icon iconName="open_in_new" title={formatMessage(messages.iconLabel)} />
    )}
  </a>
)

ExternalLink.propTypes = {
  children: PropTypes.node,
  /** The class names the <a> link should receive. */
  className: PropTypes.string,
  /** flag to hide the icon */
  hideIcon: PropTypes.bool,
  /** The text to display for the link, defaults to url ('to' property). */
  text: PropTypes.string,
  /** The url target for the link. */
  to: PropTypes.string.isRequired,
}

ExternalLink.defaultProps = {
  children: null,
  className: null,
  hideIcon: false,
  text: null,
}

export default ExternalLink
