import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

// westyle
import Numbers from 'src/common/Numbers'
import NumberWithTooltip from 'src/common/NumberWithTooltip'

const payload = {
  matchFundAvailable: 340.0,
  personalGrantAvailable: 100.0,
}

const YourDonationCredit = ({
  availableDonationFunds, enablePaidVolunteerHours,
}) => {
  const intl = useIntl()
  const {  matchFundAvailable, personalGrantAvailable } =
    payload

  return (
    <div>
      <h2 className="px-2">
        <FormattedMessage
          id="donationHistory.yourDonationCredit"
          defaultMessage="Your donation credit"
        />
      </h2>
      <div className="rounded w-4xl bg-white border-[1px] border-grayscale-3 mt-4 sm:px-10 sm:pt-10 sm:pb-16 px-8 py-4">
        <div className="grid col-span-2">
          <Numbers className="headline-1" currency="USD" style="currency">
            {availableDonationFunds}
          </Numbers>
          <span className="label-xsmall-caps text-black-1">
            <FormattedMessage
              id="donationHistory.availableToDonate"
              defaultMessage="AVAILABLE to donate"
            />
          </span>
        </div>
        <div className="mb-4 mt-2">
          <NumberWithTooltip
            title={intl.formatMessage({
              defaultMessage: 'Personal grant',
              id: 'shared.personalGrant',
            })}
            tooltip="This is a tooltip. This copy explains what this item is."
            value={personalGrantAvailable}
          />
          {enablePaidVolunteerHours && (
            <NumberWithTooltip
              title={intl.formatMessage({
                defaultMessage: 'Dollars for Doers',
                id: 'shared.dollarsForDoers',
              })}
              tooltip="Hello"
              value={personalGrantAvailable}
            />
          )}
          <NumberWithTooltip
            title={intl.formatMessage({
                defaultMessage: 'Matching funds',
                id: 'shared.matchingFunds',
              })}
            tooltip="This is a tooltip. This copy explains what this item is."
            value={matchFundAvailable}
          />
        </div>
      </div>
    </div>
  )
}

export default YourDonationCredit
