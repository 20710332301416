// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import FormTextField from 'components/form/text_field'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  maxLength: {
    defaultMessage: `Must be {maxLength} characters or less`,
    id: 'formLimitedTextField.maxLength',
  },
})

const { formatMessage } = intl

/**
 * Text field that only allows a limited amount of characters. Users can
 * continue typing past the limit but the input will display a validation error
 * message and prevent form submission. A visual counter e.g. 12/50 is anchored
 * to the right side of the helper text.
 */
const FormLimitedTextField = ({
  helperText,
  label,
  maxLength,
  multiline,
  textFieldProps,
  ...other
}) => (
  <FormTextField
    {...other}
    textFieldProps={{
      ...textFieldProps,
      helperText,
      label,
      maxLength,
      multiline,
    }}
    validationErrors={{
      maxLength: formatMessage(messages.maxLength, { maxLength }),
    }}
    validations={{ maxLength }}
  />
)

FormLimitedTextField.propTypes = {
  /** default text rendered below the field, overridden by error text */
  helperText: PropTypes.string,
  /** text rendered as a floating label on the field */
  label: PropTypes.string.isRequired,
  /** total number of characters allowed in this field */
  maxLength: PropTypes.number.isRequired,
  /** if true the field will render as an expandable textarea */
  multiline: PropTypes.bool,
  /** MUI API used by helper text and label */
  textFieldProps: PropTypes.object,
}

FormLimitedTextField.defaultProps = {
  helperText: null,
  multiline: false,
  textFieldProps: {},
}

export default FormLimitedTextField
