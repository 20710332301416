// Setup
import PropTypes from 'prop-types'
import React from 'react'

const HiddenFileInput = ({ onChange, customAccept, setRef }) => {
  const handleOnChange = (event) => {
    // We only support uploading of one file right now
    const file = event.target.files[0]

    if (file !== undefined) {
      onChange(file)
    }
  }

  return (
    <input
      accept={customAccept}
      hidden
      name="image"
      onChange={handleOnChange}
      ref={setRef}
      type="file"
    />
  )
}

HiddenFileInput.propTypes = {
  customAccept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
}

HiddenFileInput.defaultProps = {
  customAccept: '.jpg,.png',
}

export default HiddenFileInput
