// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { GroupMembers } from './members'
import { GroupActivities } from './activities'
import { GroupResources } from './resources'
import { GroupNewsfeedAndAnnouncementsLayout } from './newsfeed_and_announcements'
import { GroupNotJoinedMessage } from './group_not_joined_message'
import Stack from 'components/ui/stack'

const GroupSubpage = ({
  currentUserIsMember,
  enableNewsfeedColumnInGroupView,
  groupAnnouncementsEnabled,
  groupId,
  groupName,
  isMemberOfParentGroup,
  nonMembersAllowedPages,
  setActivity,
  subpage,
  showModal,
}) => {
  let currentSubpage = <></>
  const canSeePage =
    currentUserIsMember ||
    isMemberOfParentGroup ||
    nonMembersAllowedPages.includes(subpage)
  switch (subpage) {
    case 'resources':
      currentSubpage = <GroupResources groupId={groupId} />
      break
    case 'members':
      currentSubpage = <GroupMembers groupId={groupId} />
      break
    case 'newsfeed':
      currentSubpage = (
        <GroupNewsfeedAndAnnouncementsLayout
          currentUserIsMember={currentUserIsMember}
          enableNewsfeedColumnInGroupView={enableNewsfeedColumnInGroupView}
          groupAnnouncementsEnabled={groupAnnouncementsEnabled}
          groupId={groupId}
          isMemberOfParentGroup={isMemberOfParentGroup}
        />
      )
      break
    case 'events_and_activities':
      currentSubpage = (
        <GroupActivities
          groupId={groupId}
          setActivity={setActivity}
          showModal={showModal}
        />
      )
      break
  }

  return (
    <Stack className="pt-4" space={2}>
      {canSeePage ? (
        currentSubpage
      ) : (
        <GroupNotJoinedMessage groupName={groupName} />
      )}
    </Stack>
  )
}

GroupSubpage.propTypes = {
  currentUserIsMember: PropTypes.bool.isRequired,
  enableNewsfeedColumnInGroupView: PropTypes.bool,
  groupAnnouncementsEnabled: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  isMemberOfParentGroup: PropTypes.bool.isRequired,
  nonMembersAllowedPages: PropTypes.array.isRequired,
  setActivity: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  subpage: PropTypes.string,
}

GroupSubpage.defaultProps = {
  enableNewsfeedColumnInGroupView: false,
  subpage: 'events_and_activities',
}

export { GroupSubpage }
