// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import Link from 'components/shared/link'
import BannerLink from 'components/application/banner/link'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  takeAction: {
    defaultMessage: 'Take Action page',
    description: 'Text link to a page where users can take different actions',
    id: 'takeActionLink.takeAction',
  },
})

/**
 * Client-side router link to the Take Action page.
 */
const TakeActionLink = ({ children, isBannerLink, ...other }) => {
  const Component = isBannerLink ? BannerLink : Link

  return (
    <Component {...other} to="/take_action">
      {children}
    </Component>
  )
}

TakeActionLink.propTypes = {
  /** render content */
  children: PropTypes.node,
  /** set to true if used inside a <Banner> */
  isBannerLink: PropTypes.bool,
}

TakeActionLink.defaultProps = {
  children: intl.formatMessage(messages.takeAction),
  isBannerLink: false,
}

export { TakeActionLink }
