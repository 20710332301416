// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { groupBy } from 'utilities/group'
import { intl } from 'utilities/localization'
import {
  donationHistoryMessages,
  messages,
} from 'components/donation_history/utilities/messages'
import { DonationHistoryStatAmount } from 'components/donation_history/components/stat_amount'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'
import { DonationHistoryTransaction } from 'components/donation_history/components/transaction'
import { paymentStatus } from 'components/donation_history/utilities/transaction_utils'

export const TransactionsGroupedByCharity = ({ transactions }) => {
  const { formatMessage } = intl

  return (
    <Stack space={4}>
      {groupBy(transactions, 'charityName').map((charityTransactions) => (
        <Paper
          data-test="donation-history-charity-donation-group"
          key={charityTransactions.charityName}
        >
          <div className="[ d-flex align-items-center justify-content-between ] [ bg-blue-2 p-3 ]">
            <h3 className="fs-3 fw-semi-bold lh-sm my-0">
              {charityTransactions.charityName}
              <p
                className="fs-1 m-0 mt-2 text-black-3 text-uppercase"
                data-test="charity-total-donations"
              >
                {formatMessage(messages.donatedToDate, {
                  amount: (
                    <DonationHistoryStatAmount
                      amount={charityTransactions.items.reduce(
                        (previousValue, donation) =>
                          previousValue + donation.donationAmount,
                        0
                      )}
                      size="sm"
                    />
                  ),
                })}
              </p>
            </h3>
            <LinkButton
              className="col-4 col-md-auto fs-1 fs-md-2 lh-xs"
              data-test="donation-history-give-again"
              to={`/donations/new/${charityTransactions.items[0].ein}?search_type=${charityTransactions.items[0].organizationType}`}
              variant="neutral"
            >
              {donationHistoryMessages.giveAgain}
            </LinkButton>
          </div>
          <Stack className="p-3" divider="decorative" space={3}>
            {charityTransactions.items.map((transaction) => (
              <DonationHistoryTransaction
                accountType={transaction.accountType}
                activityId={transaction.activityId}
                charityName={transaction.charityName}
                donationAmount={transaction.donationAmount}
                donationDate={transaction.donationDate}
                ein={transaction.ein}
                key={transaction.id}
                matchedTransaction={transaction.matchedTransaction}
                offline={transaction.offline}
                paymentStatus={paymentStatus(transaction.paymentStatus)}
                organizationType={transaction.organizationType}
                recurringEnabled
              />
            ))}
          </Stack>
        </Paper>
      ))}
    </Stack>
  )
}

TransactionsGroupedByCharity.propTypes = {
  /** whether we display matching data in transaction */
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      /** type of givinga account this transaction is associated with */
      accountType: PropTypes.oneOf(['direct', 'funded', 'grant']).isRequired,
      /** the activity id associated with the donation */
      activityId: PropTypes.string,
      /** name of charity associated with donation */
      charityName: PropTypes.string.isRequired,
      /** amount donated to associated charity */
      donationAmount: PropTypes.number.isRequired,
      /** date donation was made */
      donationDate: PropTypes.string.isRequired,
      /** ein of the charity */
      ein: PropTypes.string.isRequired,
      /** for eligible charities, matching data for the transaction */
      matchedTransaction: PropTypes.shape({
        /** amount disbursed to charity if approved */
        matchedAmount: PropTypes.number.isRequired,
        /** Requested donation matching amount */
        requestedAmount: PropTypes.number.isRequired,
        /** State of matching request */
        status: PropTypes.string.isRequired,
      }),
      /** transaction was done through givinga or not */
      offline: PropTypes.bool.isRequired,
      /** payment status of the donation*/
      paymentStatus: PropTypes.string,
    })
  ),
}

TransactionsGroupedByCharity.defaultProps = {
  transactions: {
    activityId: null,
    matchedTransaction: null,
    paymentStatus: null,
  },
}
