// Setup
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { withRouter } from 'react-router-dom'

// WeSpire
import AppNotification from 'components/application/app_notification'
import Banner from 'components/application/banner'
import Modal from 'components/application/modal'
import Navigator from 'components/shared/navigator'
import { UserTour } from 'components/shared/user_tour'
import { Footer as LegacyFooter } from '../../shared/footer'

// WeStyle
import App from 'src/common/App'

const LegacyApp = ({
  children,
  displayNotification,
  location,
  notification,
  showFooter,
  showNavigator,
  westyle,
}) => {
  useEffect(() => {
    import(/* webpackChunkName: "react-dynamic" */ 'styles/react.sass')
    document.body.parentElement.classList.add('legacyStyles')
  }, [])

  return (
    <div className="d-flex">
      <div className="d-flex flex-column min-vh-100 w-100">
        <div className="flex-grow">
          <a
            className="bg-white fw-semi-bold m-1 p-3 text-uppercase z-6"
            href="#main"
          >
            Skip to main content
          </a>
          {showNavigator && <Navigator location={location} />}
          <UserTour />
          {/* Adjust spacing between sticky header and content */}
          {showNavigator && <div className="pt-7" />}
          <Banner />
          <Modal />
          <main className="pb-2 overflow-hidden h-100" id="main" role="main">
            {children}
          </main>
          <AppNotification
            displayNotification={displayNotification}
            notification={notification}
          />
        </div>
        {showFooter && (
          <LegacyFooter
            className={cx(showNavigator ? 'mt-6' : 'mt-0', 'legacyStyles')}
          />
        )}
      </div>
    </div>
  )
}

const AppWrapper = (props) => {
  return <>{props.westyle ? <App {...props} /> : <LegacyApp {...props} />}</>
}

AppWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  displayNotification: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }),
  notification: PropTypes.string,
  showFooter: PropTypes.bool,
  westyle: PropTypes.bool,
  showNavigator: PropTypes.bool.isRequired,
}

AppWrapper.defaultProps = {
  location: { pathname: '' },
  notification: '',
  showFooter: true,
}

export default withRouter(AppWrapper)
