// Vendor
import React from 'react'
import Paper from '@material-ui/core/Paper'

// WeSpire
import GivingBadge from 'components/donations/giving_badge'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { legacyDonationSuccess as messages } from './utilities/messages'

const LegacyDonationSuccess = () => {
  return (
    <GraphQLFeatureFlag featureName="giving">
      <DocumentTitle title={intl.formatMessage(messages.pageTitle)}>
        {maybeDisplayUserTour('donationSuccessPage')}

        <Paper
          className="mxw-6 | mt-6 mx-3 mx-sm-auto py-6 px-3 px-sm-6 text-center"
          data-test="donation-success"
        >
          <GivingBadge className="filter-elevation" />
          <Heading
            className="ff-serif fs-sm-6 mt-6 mt-sm-5 text-black-3"
            level={1}
          >
            {intl.formatMessage(messages.pageTitle)}
          </Heading>
          <p className="fs-2 fs-sm-3">
            {intl.formatMessage(messages.donationHistory, {
              link: (...linkText) => (
                <Link to="/users/me/donation_history">{linkText}</Link>
              ),
            })}
          </p>
        </Paper>
      </DocumentTitle>
    </GraphQLFeatureFlag>
  )
}

export default LegacyDonationSuccess
