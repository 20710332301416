// Vendor
import React, { useEffect, useState } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { useDebounce } from 'utilities/hooks/useDebounce'
import { FilterSelect } from 'components/form/filter_select'

import { GET_SCORE_TYPES } from './queries'

const messages = defineMessages({
  sortLabel: {
    defaultMessage: 'Sort by: ',
    id: 'scoreLogsFilters.sortLabel',
  },
  sortOptionEarliest: {
    defaultMessage: 'Earliest',
    id: 'scoreLogsFilters.sortOptionEarliest',
  },
  sortOptionLatest: {
    defaultMessage: 'Latest',
    id: 'scoreLogsFilters.sortOptionLatest',
  },
  typeLabel: {
    defaultMessage: 'Earned from: ',
    id: 'scoreLogsFilters.typeLabel',
  },
  yearBlankLabel: {
    defaultMessage: 'All Years',
    id: 'scoreLogsFilters.yearBlankLabel',
  },
  yearLabel: {
    defaultMessage: 'Year: ',
    id: 'scoreLogsFilters.yearLabel',
  },
})

const getYearItems = () => {
  const currentYear = new Date().getFullYear()
  const items = new Array(currentYear - 2010).fill(undefined)

  return items.map((v, index) => {
    const year = currentYear - index
    return (
      <option key={year} value={`${year}`}>
        {year}
      </option>
    )
  })
}

export const ScoreLogsFilters = ({ onFilterParamsChange }) => {
  const formatMessage = intl.formatMessage

  const { data } = useQuery(GET_SCORE_TYPES, {
    client,
    notifyOnNetworkStatusChange: true,
  })
  const scoreTypes = data?.pointsDashboard?.scoreTypes || []

  const [filterParams, setFilterParams] = useState({
    sort: 'desc',
    type: '',
    year: '',
  })
  const debouncedFilterParams = useDebounce(filterParams, 32)

  useEffect(() => {
    onFilterParamsChange(debouncedFilterParams)
  }, [debouncedFilterParams])

  const onSelectChange = (id) => (ev) => {
    setFilterParams({
      ...filterParams,
      [id]: ev.target.value,
    })
  }

  return (
    <div
      className="bg-blue-2 p-4 [ d-flex flex-wrap align-center | d-md-block ]"
      data-test="logs-filters-panel"
    >
      <FilterSelect
        id="type"
        label={formatMessage(messages.typeLabel)}
        onChange={onSelectChange('type')}
        value={filterParams.type}
      >
        {scoreTypes.map(({ label, value }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          )
        })}
      </FilterSelect>

      <FilterSelect
        id="sort"
        label={formatMessage(messages.sortLabel)}
        onChange={onSelectChange('sort')}
        value={filterParams.sort}
      >
        <option value="asc">
          {formatMessage(messages.sortOptionEarliest)}
        </option>
        <option value="desc">{formatMessage(messages.sortOptionLatest)}</option>
      </FilterSelect>

      <FilterSelect
        blankLabel={formatMessage(messages.yearBlankLabel)}
        id="year"
        label={formatMessage(messages.yearLabel)}
        onChange={onSelectChange('year')}
        value={filterParams.year}
      >
        {getYearItems()}
      </FilterSelect>
    </div>
  )
}

ScoreLogsFilters.propTypes = {
  onFilterParamsChange: PropTypes.func.isRequired,
}
