// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import v from 'voca'

// WeSpire
import iconList from 'components/ui/icon/list'
import SvgPath from './svg_path'
import { muiSvgIconTheme } from './utils'

/** Base icon element. Extends MUI <SvgIcon> with extra a11y enhancements. */
const Icon = ({ iconName, title, ...other }) => {
  if (!Object.keys(iconList).includes(iconName)) {
    // eslint-disable-next-line no-console
    console.error(
      `<Icon iconName="${iconName}" /> is not in the icon list, so no icon ` +
        `was rendered. See 'components/ui/icon/list' for available icons.`
    )
    return null
  }

  const titleId = `${v.slugify(title)}-${Math.random().toString()}`

  return v.isBlank(title) ? (
    <MuiThemeProvider theme={muiSvgIconTheme}>
      <SvgIcon {...other} aria-hidden>
        <SvgPath iconName={iconName} />
      </SvgIcon>
    </MuiThemeProvider>
  ) : (
    <MuiThemeProvider theme={muiSvgIconTheme}>
      <SvgIcon {...other} aria-hidden={false} aria-labelledby={titleId}>
        <title id={titleId}>{title}</title>
        <SvgPath iconName={iconName} />
      </SvgIcon>
    </MuiThemeProvider>
  )
}

Icon.propTypes = {
  /** key used to choose icon (from icon/list.js) */
  iconName: PropTypes.string.isRequired,
  /** a11y enhancement, provide empty string if icon is decorative */
  title: PropTypes.node.isRequired,
}

export default Icon
