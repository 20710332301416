import React, { useState } from 'react'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import InternalLink from 'src/common/InternalLink'

// Vendor
import cx from 'classnames'

const FileUploadContainer = ({ className, title }) => {
  return (
    <div
      className={cx(
        `bg-gallery rounded-lg flex flex-col mx-4 border-2 border-dashed border-gray-2`,
        className
      )}
    >
      <div className="py-4">
        <InternalLink
          className="font-medium font-sans text-xs text-black-body hover:no-underline shadow-[0_1px_0px_#C4D1DB]"
          href="#"
          key={title}
        >
          <div className="flex flex-row justify-center text-2xl">
            <FileUploadOutlinedIcon />
          </div>
          <div className="flex flex-row w-full justify-center mt-3">
            {title}
          </div>
        </InternalLink>
      </div>
    </div>
  )
}

export default FileUploadContainer
