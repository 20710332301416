// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { Heading } from 'components/ui/Typography/Heading'
import { Image } from 'components/ui/image'
import { Paragraph } from 'components/ui/Typography/Paragraph'

const LoggedOutLayout = (props) => {
  const { heroSubtitle, logoUrl, title } = props

  return (
    <div className="h-100">
      <div className="h-100">
        <div className="[ d-flex flex-column ]">
          <div className="row session-icon justify-content-center">
            <Image
              alt={title}
              className="logo logo-react--sm"
              crop="fit"
              dpr="2.0" // TODO: Figure out why this image doesn't auto-detect DPR
              height="40"
              src={logoUrl}
              width="120"
            />
          </div>
          <div className="flex-grow py-3">
            <Heading className="text-center" data-test="page-title" level={1}>
              {title}
            </Heading>
            {heroSubtitle && (
              <Paragraph className="text-center session-subtitle" size="large">
                {heroSubtitle}
              </Paragraph>
            )}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

LoggedOutLayout.propTypes = {
  children: PropTypes.any.isRequired,
  heroSubtitle: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

LoggedOutLayout.defaultProps = {
  heroSubtitle: '',
}

export default LoggedOutLayout
