const paths = {
  emailLogin: '/sign_in',
  googleLogin: '/google_login',
  landing: '/',
}

export const pathsWithoutNavigation = [
  paths.emailLogin,
  paths.googleLogin,
  paths.landing,
]
export default paths
