// Setup
import React from 'react'
import PropTypes from 'prop-types'

//Vendor
import { hideBanner } from 'redux/dispatchers'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { groupAnnouncementsDropdownMessages as messages } from '../utilities/messages'
import { intl } from 'utilities/localization'

import { editGroupAnnouncementRoute } from '../routes'

const { formatMessage } = intl

const dropdownOptions = {
  archivedItems: [
    formatMessage(messages.editText),
    formatMessage(messages.republishText),
  ],
  draftedItems: [
    formatMessage(messages.editText),
    formatMessage(messages.publishText),
  ],
  publishedItems: [
    formatMessage(messages.editText),
    formatMessage(messages.archiveText),
  ],
}

export const GroupAnnouncementsDropdown = ({
  announcement,
  executeMutation,
  groupId,
  type,
}) => {
  let options = dropdownOptions[`${type}Items`]
  const { announcementHtml, id, title } = announcement

  const handleChange = (value) => {
    hideBanner()
    switch (value) {
      case formatMessage(messages.editText):
        return redirectToEditPage()
      case formatMessage(messages.archiveText):
        return executeMutation(announcementHtml, id, 'archived', title)
      case formatMessage(messages.publishText):
      case formatMessage(messages.republishText):
        return executeMutation(announcementHtml, id, 'published', title)
    }
  }

  const redirectToEditPage = () => {
    const path = editGroupAnnouncementRoute({
      groupId,
      id,
    })
    window.location = path
  }

  return (
    <FormControl
      className="[ d-flex flex-column ] [ lh-xs ] [ pt-4 pb-2 ]"
      data-test={`group-announcement-dropdown-${type}`}
    >
      <Select
        id={`group-announcements-select-${id}`}
        label="group-announcements"
        labelId="demo-simple-select-outlined-label"
        onChange={(option) => handleChange(option.target.value)}
        value="manage"
      >
        <MenuItem key="manage" value="manage">
          {formatMessage(messages.manageText)}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            data-test={`group-announcement-dropdown-${option}-${type}`}
            key={index}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

GroupAnnouncementsDropdown.propTypes = {
  announcement: PropTypes.shape({
    announcementHtml: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  executeMutation: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

GroupAnnouncementsDropdown.defaultProps = {
  announcement: {
    announcementHtml: '',
    id: '',
    title: '',
  },
}
