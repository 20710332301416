// Setup
import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { ChaptersList } from 'components/setup/chapters/chapters_list'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import MgmtPanelFormPage from 'setup/application/form_page'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { newChapterRoute } from './routes'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import useUpdatePosition from 'utilities/hooks/use_update_position'
import WeApollo from 'utilities/we_apollo'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl

const messages = defineMessages({
  chaptersHeading: {
    defaultMessage: '{groupName} Chapters',
    id: 'chapters.chaptersHeading',
  },
  chaptersTitle: {
    defaultMessage: 'Ordered Chapters',
    id: 'chapters.chaptersTitle',
  },
  newChapter: {
    defaultMessage: 'New Chapter',
    id: 'chapters.newChapter',
  },
  orderingDescription: {
    defaultMessage:
      'This is the list of chapters in the order that they will appear on employee-facing Group pages. You can change the order of the list using the ordering buttons on each chapter.',
    id: 'chapters.orderingDescription',
  },
})

const chaptersListQuery = gql`
  query chaptersListQuery($objectId: ID!) {
    currentUser {
      can {
        manageGroups
      }
    }
    group(id: $objectId) {
      id
      name
      slug
      groupChapters {
        id
        name
        position
        slug
      }
    }
  }
`

const Chapters = ({ objectId }) => {
  const [ariaLiveText, executeMutation] = useUpdatePosition()

  const handleUpdatePosition = ({ operator, recordId, recordName }) => {
    const refetchQueries = [
      {
        query: chaptersListQuery,
        variables: { objectId },
      },
    ]
    const variables = {
      klass: 'Group',
      operator,
      recordId,
    }

    executeMutation(recordName, refetchQueries, variables)
  }

  return (
    <WeApollo>
      <WeQuery
        error={<MissingResourcePage />}
        loader={
          <Stack space={4}>
            <SkeletonLoading height={75} />
            <SkeletonLoading className="px-4" height={300} />
          </Stack>
        }
        query={chaptersListQuery}
        variables={{ objectId }}
      >
        {({
          data: {
            currentUser: { can },
            group: { groupChapters, name, slug },
          },
        }) => {
          return (
            <MgmtPanelFormPage
              contentHeaderChildren={
                can.manageGroups && (
                  <Button
                    className="flex-shrink-none ml-2"
                    color="primary"
                    data-test="create-chapter"
                    href={newChapterRoute({
                      groupSlug: slug,
                    })}
                    variant="contained"
                  >
                    {formatMessage(messages.newChapter)}
                  </Button>
                )
              }
              data-test="chapter-index-heading"
              isEdit
              resourceName={formatMessage(messages.chaptersHeading, {
                groupName: name,
              })}
            >
              {() => (
                <Stack space={3}>
                  <Heading level={2} variant={2}>
                    {formatMessage(messages.chaptersTitle)}
                  </Heading>
                  <p>{formatMessage(messages.orderingDescription)}</p>
                  <ChaptersList
                    ariaLiveText={ariaLiveText}
                    groupChapters={groupChapters}
                    groupSlug={slug}
                    onUpdatePositionClick={handleUpdatePosition}
                  />
                </Stack>
              )}
            </MgmtPanelFormPage>
          )
        }}
      </WeQuery>
    </WeApollo>
  )
}

Chapters.propTypes = {
  objectId: PropTypes.string.isRequired,
}

export default Chapters
