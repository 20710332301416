import React from 'react'
import { FormattedMessage } from 'react-intl'

// westyle
import InternalLink from 'src/common/InternalLink'
import PaymentSource from 'src/pages/DonationHistoryPage/components/PaymentSource'

const ScheduledPayment = ({ transaction }) => {
  return (
    <>
      {transaction.paymentMethod !== 'unknown' && (
        <div className="gap-y-0.5 grid mt-1 grid-cols-[1fr,1fr] sm:grid-cols-[1fr,2fr] md:grid-cols-[1fr,3fr] lg:grid-cols-[1fr,5fr] xl:grid-cols-[1fr,6fr]">
          <PaymentSource transaction={transaction} />
        </div>
      )}
      <InternalLink
        className="font-medium hover:text-smart-blue leading-6 text-sm underline w-full inline"
        href="#"
      >
        <FormattedMessage
          id="donationHistory.stopPayments"
          defaultMessage="Stop payments"
        />
      </InternalLink>
      <p className="body-medium-regular py-4">
        <FormattedMessage
          id="donationHistory.yourAmountDonatedMightBeDifferent"
          defaultMessage="PLEASE NOTE: Your amount donated might be different from amount paid to charity due to transaction fees."
        />
      </p>
    </>
  )
}

export default ScheduledPayment
