// Setup
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

// WeSpire
import Avatar from 'components/application/avatar'
import CenteredContent from 'components/application/centered_content'
import { muiClasses } from './mui_classes'
import { sharedTranslations } from 'components/shared/translations'

const ActivitiesChannelNav = ({
  channels,
  currentChannelId,
  onChannelChange,
}) => {
  useEffect(() => {
    /**
     * small trick to force a mini scroll because there are issues with initial scroll and length calculations it seems
     * forcing the channel bar to scroll a non noticeable amount so it triggers the scroll back bug, it won't happen after that
     * not able to find the cause of this bug probably related to material-ui version?
     */
    document
      .querySelector('.MuiTabs-scroller.MuiTabs-scrollable')
      .scrollTo(2, 0)
  }, [])
  return (
    <div
      className="bg-white border-top elevation-3 position-relative z-1"
      data-test="channel-nav"
    >
      <CenteredContent>
        <Tabs
          classes={muiClasses.Tabs}
          onChange={onChannelChange}
          scrollButtons="auto"
          TabScrollButtonProps={{
            className: 'text-blue fs-5',
          }}
          textColor="secondary"
          value={currentChannelId}
          variant="scrollable"
        >
          {channels.map((channel) => (
            <Tab
              classes={muiClasses.Tab}
              icon={
                !!channel.logoImageUrl && (
                  <Avatar
                    className="elevation-1 mr-2"
                    image={channel.logoImageUrl}
                  />
                )
              }
              key={channel.id}
              label={
                channel.isMyActivities
                  ? sharedTranslations.myActivities
                  : channel.name
              }
              value={channel.id}
            />
          ))}
        </Tabs>
      </CenteredContent>
    </div>
  )
}

ActivitiesChannelNav.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logoImageUrl: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  currentChannelId: PropTypes.string.isRequired,
  onChannelChange: PropTypes.func.isRequired,
}

export default ActivitiesChannelNav
