import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  alreadyHaveAnAccount: {
    defaultMessage: 'Already have an account?',
    id: 'landingMessages.alreadyHaveAnAccount',
  },
  continue: {
    defaultMessage: 'Continue',
    id: 'landingMessages.continue',
  },
  continueWithEmail: {
    defaultMessage: 'Continue with Email',
    id: 'landingMessages.continueWithEmail',
  },
  continueWithWorkCredentials: {
    defaultMessage: 'Continue with your Work Credentials',
    id: 'landingMessages.continueWithWorkCredentials',
  },
  emailLabel: {
    defaultMessage: 'email',
    id: 'landingMessages.email',
  },
  enterYourEmailAddress: {
    defaultMessage: 'Enter your email address',
    id: 'landingMessages.enterYourEmailAddress',
  },
  or: {
    defaultMessage: 'or',
    description:
      'Text indicating that the user can choose between two login options',
    id: 'landingMessages.or',
  },
  personalInformationRegistration: {
    defaultMessage:
      'You must have registered your personal email address with HR in order to continue.',
    id: 'landingMessages.personalInformationRegistration',
  },
  signIn: {
    defaultMessage: 'Sign In',
    id: 'landingMessages.signIn',
  },
  validEmail: {
    defaultMessage: 'Must be a valid email',
    id: 'landingMessages.validEmail',
  },
})

export const landingMessages = formatMessages(messages)
