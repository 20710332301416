import React from 'react'

const SectionContainer = ({ additionalDetails, title, children }) => (
  <>
    <div className="mt-6 pb-2 flex flex-col md:flex-row justify-between">
      <h3 className="text-lg leading-6 font-medium">{title}</h3>
      {additionalDetails && additionalDetails}
    </div>
    <div className="rounded w-full py-6 border-grayscale-2 border-[1px]">
      {children}
    </div>
  </>
)

export default SectionContainer
