import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Stack from 'components/ui/stack'
import Icon from 'components/ui/icon'

const InfoCallout = ({ children, 'data-test': dataTest }) => {
  return (
    <Stack className="[ d-flex align-items-center ] [ bg-blue-2 border-left-thick-blue p-3 ]">
      <Icon className="fs-5 mr-3 text-blue" iconName="info" title="" />
      <p data-test={dataTest}>{children}</p>
    </Stack>
  )
}

InfoCallout.propTypes = {
  children: PropTypes.node.isRequired,
  'data-test': PropTypes.string,
}

InfoCallout.defaultProps = {
  'data-test': 'info-callout',
}

export { InfoCallout }
