// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'

// WeSpire
import {
  activitiesByType,
  activityCardSkeletonsHeight,
  orderedTypeLabels,
} from './utils'
import CenteredContent from 'components/application/centered_content'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { sortByOldest } from 'utilities/sort'
import Stack from 'components/ui/stack'
import { TakeActionLink } from 'components/activities/link'
import { CurrentActivityCard } from './current_activity_card'
import WeQuery from 'components/application/we_query'

const messages = defineMessages({
  inProgressActivities: {
    defaultMessage: 'In Progress Activities',
    id: 'currentActivities.inProgressActivities',
  },
  loadingError: {
    defaultMessage: 'your In Current Activities',
    id: 'currentActivities.loadingError',
  },
  noCurrentActivities: {
    defaultMessage:
      "You're not participating in any Activities right now. Visit the {takeActionLink} to take part!",
    id: 'currentActivities.noCurrentActivities',
  },
})

const CurrentActivities = () => {
  const { formatMessage } = intl

  return (
    <section className="px-3">
      <CenteredContent className="my-5">
        <Stack space={5}>
          <Heading className="px-2" level={3} variant={1}>
            {formatMessage(messages.inProgressActivities)}
          </Heading>
          <WeQuery
            error={
              <MissingResourceSection
                as={Paper}
                errorDetails={formatMessage(messages.loadingError)}
              />
            }
            loader={
              <SkeletonLoading header height={activityCardSkeletonsHeight} />
            }
            query={gql`
              query userCurrentActivitiesQuery {
                currentUser {
                  currentActivities {
                    actionUrl
                    endsAt
                    id
                    name
                    startsAt
                    type
                    unlisted
                    ... on Campaign {
                      achievement {
                        awardedTo
                        id
                        imageUrl
                        name
                      }
                      progressPercentForUser
                    }
                  }
                  id
                }
              }
            `}
          >
            {({
              data: {
                currentUser: { currentActivities },
              },
            }) => {
              let activities = sortByOldest(currentActivities, 'endsAt')

              const emptyStateText = formatMessage(
                messages.noCurrentActivities,
                {
                  takeActionLink: <TakeActionLink />,
                }
              )

              return activities.length > 0 ? (
                <Stack divider="default" space={3}>
                  {Object.entries(orderedTypeLabels).map(([key, value]) => {
                    const groupedActivities = activitiesByType(activities, key)
                    return (
                      groupedActivities.length > 0 && (
                        <Stack data-test="current-activities-by-type" key={key}>
                          <Heading className="mt-2 px-2" level={4} variant={2}>
                            {value}
                          </Heading>
                          <ul className="[ row vertical-gutters ] [ pb-3 px-0 ]">
                            {groupedActivities.map((a) => (
                              <li
                                className="[ col-md-4 d-flex ]"
                                key={a.actionUrl}
                              >
                                <CurrentActivityCard
                                  achievement={a.achievement}
                                  actionUrl={a.actionUrl}
                                  endsAt={a.endsAt}
                                  name={a.name}
                                  progressPercent={a.progressPercentForUser}
                                  startsAt={a.startsAt}
                                  type={a.type}
                                />
                              </li>
                            ))}
                          </ul>
                        </Stack>
                      )
                    )
                  })}
                </Stack>
              ) : (
                <Paper className="d-flex flex-column mt-3 p-3">
                  <p className="my-0">{emptyStateText}</p>
                </Paper>
              )
            }}
          </WeQuery>
        </Stack>
      </CenteredContent>
    </section>
  )
}

export { CurrentActivities }
