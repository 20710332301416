// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { abbreviatedDateOrRange } from 'utilities/date_formatter'
import Stack from 'components/ui/stack'
import ParticipantsSummary from 'components/application/participants_summary'
import WysiwygContent from 'components/ui/wysiwyg_content'

const GivingActivityDetails = ({
  avatars,
  description,
  endsAt,
  participantsCount,
  startsAt,
}) => (
  <Stack data-test="giving-details" space={4}>
    <span
      className="fs-2 fw-semi-bold text-capitalize text-black-3"
      data-test="dates"
    >
      {abbreviatedDateOrRange(startsAt, endsAt)}
    </span>
    <WysiwygContent ariaLabel="details" className="fs-2 fs-sm-3">
      {description}
    </WysiwygContent>
    {!!avatars.length && (
      <Stack space={3}>
        <ParticipantsSummary
          avatarImages={avatars}
          participantsCount={participantsCount}
          variant="stacked"
        />
      </Stack>
    )}
  </Stack>
)

GivingActivityDetails.propTypes = {
  avatars: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string.isRequired,
  endsAt: PropTypes.string,
  participantsCount: PropTypes.number.isRequired,
  startsAt: PropTypes.string.isRequired,
}

GivingActivityDetails.defaultProps = {
  avatars: [],
  endsAt: null,
}

export default GivingActivityDetails
