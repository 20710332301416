import {
  COUNTRY,
  HOUSEHOLD_SIZE,
  HOUSING_TYPE,
  PERCENTAGE_RENEWABLE_ENERGY,
  POSTAL_CODE,
  SHORT_BUSINESS_FLIGHT,
  MEDIUM_BUSINESS_FLIGHT,
  LONG_BUSINESS_FLIGHT,
  EXTENDED_BUSINESS_FLIGHT,
  SHORT_PERSONAL_FLIGHT,
  MEDIUM_PERSONAL_FLIGHT,
  LONG_PERSONAL_FLIGHT,
  EXTENDED_PERSONAL_FLIGHT,
  VEHICLE_TYPE,
  APPROXIMATE_DISTANCE_TRAVELED_CAR,
  VEHICLE_FUEL_EFFICIENCY,
  APPROXIMATE_DISTANCE_TRAVELED_EACH_YEAR,
  TRANSPORT_TYPE,
  ROUND_TRIP_DISTANCE,
  REMOTE_NUMBER_OF_DAYS,
  ANIMAL_PRODUCTS_CONSUMED,
} from 'components/CarbonCalculator/contexts/constants'

export const initialAnswerState = {
  [ANIMAL_PRODUCTS_CONSUMED]: 0,
  [COUNTRY]: '',
  [POSTAL_CODE]: '',
  [HOUSEHOLD_SIZE]: 1,
  [HOUSING_TYPE]: '',
  [PERCENTAGE_RENEWABLE_ENERGY]: 0,
  [SHORT_BUSINESS_FLIGHT]: 0,
  [MEDIUM_BUSINESS_FLIGHT]: 0,
  [LONG_BUSINESS_FLIGHT]: 0,
  [EXTENDED_BUSINESS_FLIGHT]: 0,
  [SHORT_PERSONAL_FLIGHT]: 0,
  [MEDIUM_PERSONAL_FLIGHT]: 0,
  [LONG_PERSONAL_FLIGHT]: 0,
  [EXTENDED_PERSONAL_FLIGHT]: 0,
  [VEHICLE_TYPE]: 0,
  [APPROXIMATE_DISTANCE_TRAVELED_CAR]: 0,
  [VEHICLE_FUEL_EFFICIENCY]: 0,
  [APPROXIMATE_DISTANCE_TRAVELED_EACH_YEAR]: 0,
  [TRANSPORT_TYPE]: '',
  [ROUND_TRIP_DISTANCE]: 0,
  [REMOTE_NUMBER_OF_DAYS]: 0,
}

export const answerReducer = (state, action) => {
  switch (action.type) {
    case action.payload.questionKey:
      return { ...state, [action.payload.questionKey]: action.payload.answer }
    case 'reset':
      return initialAnswerState
    default:
      return state
  }
}
