// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const BurgerAnimatedIcon = ({ colorClassName, isActive, ...rootProps }) => (
  <div {...rootProps} className={cx('burger', { 'burger--active': isActive })}>
    <div className={cx('burger__line burger__line--first', colorClassName)} />
    <div
      className={cx(
        'burger__line burger__line--second burger__line--spaced',
        colorClassName
      )}
    />
    <div
      className={cx(
        'burger__line burger__line--third burger__line--spaced',
        colorClassName
      )}
    />
  </div>
)

BurgerAnimatedIcon.propTypes = {
  colorClassName: PropTypes.string,
  isActive: PropTypes.bool,
}

BurgerAnimatedIcon.defaultProps = {
  colorClassName: 'bg-black-3',
  isActive: false,
}

export default BurgerAnimatedIcon
