// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Paper from '@material-ui/core/Paper'

// WeSpire
import ScreenReader from 'components/ui/screen_reader_text'
import { sharedTranslations } from 'components/shared/translations'
import { ActivityDetails } from 'components/shared/activity_page/details'
import { ActivityParticipation } from 'components/shared/activity_page/participation'

// Sub-Components
import { ActivitySeriesProgressBarDetails } from './progress_bar_details'

/** Header with a brief description of a activitySeries */
const ActivitySeriesAbout = ({ activitySeries }) => (
  <Paper
    className="[ d-flex flex-column | flex-md-row ] [ p-4 ]"
    component="section"
  >
    <ScreenReader as="h2" text={sharedTranslations.details} />

    <ActivityDetails
      className="w-100 mxw-8"
      progressBarDetailsComponent={
        <ActivitySeriesProgressBarDetails
          achievement={activitySeries.achievement}
          completionPoints={activitySeries.campaigns.length}
          progressPercent={activitySeries.progressPercentForUser}
          progressPointsForUser={activitySeries.completedCampaigns}
        />
      }
      {...activitySeries}
    />

    <div className="[ border-left mx-4 ] [ show-md-up ]" />

    {activitySeries.participantsCount > 0 && (
      <ActivityParticipation
        avatars={activitySeries.participants.map(
          (participant) => participant.avatarUrl
        )}
        className="[ w-100 mxw-3 ] [ mt-4 mt-md-0 ]"
        hasStarted={activitySeries.hasStarted}
        participantsCount={activitySeries.participantsCount}
        totalActionsTaken={activitySeries.totalParticipationIndicators}
      />
    )}
  </Paper>
)

ActivitySeriesAbout.propTypes = {
  activitySeries: PropTypes.object.isRequired,
}

export default ActivitySeriesAbout
