import React from 'react'
import gql from 'graphql-tag'

import { client } from 'utilities/we_apollo'

const CurrentBrandQuery = gql`
  query currentBrand {
    brand {
      id
      name
      authScheme
      ssoDescription
      logoUrl
      passwordDescription
      ssoUrl
      heroTitle
      heroSubtitle
      multiAuth
      signUpPath
      reactLoginEnabled
      brandValidatesEmployeeNumber
    }
  }
`

const getCurrentBrand = async (setBrand) => {
  const response = await client.query({
    query: CurrentBrandQuery,
  })
  setBrand(response.data.brand)
}

export default getCurrentBrand
