import { desktopBreakpoint } from 'components/helpers/is_desktop'

export default class AnalyticsManager {
  isInitialized() {
    return Boolean(window && window.analytics)
  }

  track(eventName, properties) {
    if (!this.isInitialized()) {
      return this.handleUninitialized()
    }

    window.analytics.track(eventName, {
      ...properties,
      mobile_layout: window.innerWidth <= desktopBreakpoint,
    })
  }

  page() {
    if (!this.isInitialized()) {
      return this.handleUninitialized()
    }

    window.analytics.page()
  }

  handleUninitialized = () =>
    // eslint-disable-next-line no-console
    !window.testEnv && console.log('Analytics script has not been initialized')

  /**
   * General events
   */
  scrolledToTheBottom = (prefix) =>
    this.track(`${prefix ? prefix + ' - ' : ''}Scrolled to the bottom`)
}

export const instance = new AnalyticsManager()
