// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'

// WeSpire
import Activity from 'components/setup/shared/activities/list/activity'
import { compositeChannelIndex, enhanceDnDTarget } from './utils'
import { ITEM_TYPES } from '../constants'

class DraggableActivity extends React.Component {
  render() {
    const { connectDragSource, connectDropTarget, ...props } = this.props
    const showHighlight = props.isOver && props.canDrop && props.isDragging

    return connectDragSource(
      connectDropTarget(
        <div
          className={cx({ 'bg-black-5': showHighlight })}
          ref={(ref) => (this.node = ref)}
        >
          <Activity {...props} />
        </div>
      )
    )
  }
}

DraggableActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  id: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  listId: PropTypes.string.isRequired,
  moveActivity: PropTypes.func.isRequired,
}

const sourceSpec = {
  beginDrag: (props) => ({
    channelIndex: props.channelIndex,
    id: props.id,
    index: props.index,
    listId: props.listId,
  }),

  endDrag: (props, monitor) => {
    const dropRes = monitor.getDropResult()
    const activity = monitor.getItem()
    const sourceListId = props.listId
    const sourceCompositeIndex = compositeChannelIndex(
      activity.listId,
      activity.channelIndex
    )
    const nextCompositeIndex = compositeChannelIndex(
      dropRes.listId,
      dropRes.channelIndex
    )

    // If activity is dropped to different channel, remove it from the former one
    if (dropRes && sourceCompositeIndex !== nextCompositeIndex) {
      props.removeActivity(activity.index, activity.channelIndex, sourceListId)
    }
  },
}

const targetSpec = {
  hover: enhanceDnDTarget(({ props, monitor, dragIndex, hoverIndex }) => {
    const channelIndex = monitor.getItem().channelIndex
    const hoverChannelIndex = props.channelIndex
    const listId = props.listId

    if (channelIndex === hoverChannelIndex) {
      // Time to actually perform the action
      props.moveActivity(dragIndex, hoverIndex, channelIndex, listId)

      monitor.getItem().index = hoverIndex
    }
  }),
}

const dropTarget = DropTarget(
  ITEM_TYPES.activity,
  targetSpec,
  (connect, monitor) => ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    // Returns true when cursor dragging an Activity is 'draggingOutsideTarget'
    // eslint-disable-next-line no-unused-vars
    isOver: enhanceDnDTarget(({ props, monitor, dragIndex, hoverIndex }) => {}),
  })
)

const dragSource = DragSource(
  ITEM_TYPES.activity,
  sourceSpec,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)

export const undecorated = DraggableActivity

export default dropTarget(dragSource(DraggableActivity))
