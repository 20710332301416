import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { postcodeValidator } from 'postcode-validator'
import AnswerComponentSelector from 'components/CarbonCalculator/components/AnswerComponentSelector/AnswerComponentSelector'
import BigQuestionText from 'components/CarbonCalculator/components/BigQuestionText'
import Fact from 'components/CarbonCalculator/components/Fact'
import Progress from 'components/CarbonCalculator/components/Progress'
import NextButton from 'components/CarbonCalculator/components/NextButton'
import BackButton from 'components/CarbonCalculator/components/BackButton'
import { CalculatorContext } from 'components/CarbonCalculator/contexts/CalculatorContext'
import { calculatorQuestionsAnswersData } from 'components/CarbonCalculator/contexts/calculatorQuestionsAnswersData'
import FootprintResults from 'components/CarbonCalculator/pages/FootprintResults'
import { VEHICLE_TYPE } from 'components/CarbonCalculator/contexts/constants'
import pageIndexZeroValidation from './utilities/utils'

const Calculator = ({ getFootprint }) => {
  const {
    currentQuestionIndex,
    footprintState,
    setCurrentQuestionIndex,
    answerState,
    dispatchFootprint,
  } = useContext(CalculatorContext)
  const [nextButtonText, setNextButtonText] = useState('next question')
  const [showResults, setShowResults] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const calculatorElem = document.querySelector('#calculator-holder')
    let calculatorOffsetTop = 0
    if (calculatorElem) {
      calculatorOffsetTop = calculatorElem.offsetTop
    }
    window.scrollTo({
      top: calculatorOffsetTop,
      behavior: 'smooth',
    })
  }, [currentQuestionIndex])

  useEffect(() => {
    const { countryCode, postalCode } = answerState
    if (
      (countryCode === 'US' && !postcodeValidator(postalCode, 'US')) ||
      (countryCode === 'CA' && !postcodeValidator(postalCode, 'CA')) ||
      countryCode === ''
    ) {
      return setIsDisabled(true)
    }
    return setIsDisabled(false)
  }, [answerState.countryCode, answerState.postalCode])

  const handleNextQuestion = async () => {
    let proceed = true
    if (currentQuestionIndex === 0) {
      proceed = pageIndexZeroValidation(answerState)
    }

    if (proceed) {
      if (calculatorQuestionsAnswersData.length === currentQuestionIndex + 1) {
        const newAnswerState = answerState
        // if electric update engine type to gas (1) and pass 150 mpg in toggle btn group component
        if (answerState[VEHICLE_TYPE] === 3) {
          newAnswerState[VEHICLE_TYPE] = 1
        }
        const footprintResponse = await getFootprint(newAnswerState)

        dispatchFootprint({
          type: 'calculate-footprint',
          payload: footprintResponse ? footprintResponse.result : {},
        })
        setShowResults(true)
      }

      if (currentQuestionIndex < calculatorQuestionsAnswersData.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1)
      }

      if (calculatorQuestionsAnswersData.length === currentQuestionIndex + 2) {
        setNextButtonText('get your results')
      }
    }
  }

  const handleBackQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
      setNextButtonText('next question')
    }
  }

  const currentData = calculatorQuestionsAnswersData[currentQuestionIndex]

  return showResults ? (
    <FootprintResults
      food={footprintState.food}
      goods={footprintState.goods}
      grandTotal={footprintState.grandTotal}
      housing={footprintState.housing}
      transport={footprintState.transport}
    />
  ) : (
    <div data-test="calculator-holder-test" id="calculator-holder">
      <Progress
        location={currentQuestionIndex + 1}
        currentDataQuestionGroup={currentData.questionGroup}
        calculatorQuestionsAnswersData={calculatorQuestionsAnswersData}
      />
      <div
        data-test={`calculator-group-questions-test-${currentQuestionIndex}`}
      >
        <BigQuestionText question={currentData.question} />
        {currentData.answerOptions.map((item, qidx) => (
          <div
            data-test={`calculator-question-test-${qidx}`}
            key={`question-${qidx.toString()}`}
          >
            <div>
              <AnswerComponentSelector
                subheader={item.subheader}
                answers={item.answers}
                min={item.min}
                max={item.max}
                steps={item.steps}
                questionKey={item.questionKey}
                componentSelector={item.componentSelector}
                helperDescriptionText={item.helperDescriptionText}
              />
            </div>
          </div>
        ))}
        <div
          className={`mt-10 flex
            ${currentQuestionIndex === 0 ? 'justify-end' : 'justify-between'}
          `}
        >
          {currentQuestionIndex !== 0 && (
            <BackButton handleBackQuestion={handleBackQuestion} />
          )}
          <NextButton
            isDisabled={isDisabled}
            handleNextQuestion={handleNextQuestion}
            textString={nextButtonText}
          />
        </div>
        {currentData.factHeader && (
          <Fact
            factHeader={currentData.factHeader}
            factImage={currentData.factImage}
            factText={currentData.factText}
          />
        )}
      </div>
    </div>
  )
}

Calculator.propTypes = {
  getFootprint: PropTypes.func,
}

Calculator.defaultProps = {
  getFootprint: () => {},
}

export default Calculator
