// Vendor
import React, { useState } from 'react'
import { ListItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { Button } from 'components/ui/button'
import ExternalLink from 'components/ui/external_link'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { sharedMessages } from 'components/shared/translations'

const messages = defineMessages({
  createdBy: {
    defaultMessage: 'by { name }',
    id: 'groupResource.createdBy',
  },
  dateAdded: {
    defaultMessage: '{ date } at { time }',
    id: 'groupResource.dateAdded',
  },
})

const { formatMessage } = intl

export const GroupResource = ({ resource }) => {
  const [isDescriptionToggled, setIsDescriptionToggled] = useState(false)
  return (
    <ListItem
      className="px-3 py-0 row align-items-start"
      data-test="group-resource-item"
    >
      <Stack className="col-sm-8 [ py-3 m-0 ]" space={1}>
        <ExternalLink
          className="text-decoration-none text-blue d-flex align-items-center"
          hideIcon
          to={resource.resourceUrl}
        >
          <Icon
            className="fs-5 text-blue mr-1"
            iconName={
              resource.resourceType === 'document'
                ? 'file_document'
                : 'open_in_new'
            }
            title={resource.title}
          />
          <span className="text-truncate lh-sm">{resource.title}</span>
        </ExternalLink>

        {resource.description && (
          <div className="d-flex text-black-2 py-1">
            {isDescriptionToggled || resource.description.length < 40 ? (
              <p className="m-0 fs-2 lh-sm">{resource.description}</p>
            ) : (
              <>
                <p className="text-truncate m-0 fs-2 lh-sm mr-1">
                  {resource.description}
                </p>
                <Button
                  className="p-0 m-0 fs-1 text-blue text-lowercase mnw-2 justify-content-start"
                  onClick={() => setIsDescriptionToggled(!isDescriptionToggled)}
                  variant="text"
                >
                  {formatMessage(sharedMessages.readMore)}
                  <Icon
                    className="fs-2"
                    iconName="chevron_down"
                    title={formatMessage(sharedMessages.readMore)}
                  />
                </Button>
              </>
            )}
          </div>
        )}
      </Stack>
      <Stack
        className="d-flex flex-column fw-semi-bold col-sm-4 [ py-3 m-0]"
        space={0}
      >
        <p className="fs-1">
          {formatMessage(messages.dateAdded, {
            date: intl.formatDate(resource.updatedAt || resource.createdAt, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }),
            time: intl.formatDate(resource.updatedAt || resource.createdAt, {
              hour: 'numeric',
              minute: 'numeric',
            }),
          })}
        </p>
        <p className="fs-1">
          {formatMessage(messages.createdBy, {
            name: resource.createdBy?.name,
          })}
        </p>
      </Stack>
    </ListItem>
  )
}
GroupResource.propTypes = {
  resource: PropTypes.shape({
    createdAt: PropTypes.string,
    createdBy: PropTypes.object,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    resourceType: PropTypes.string,
    resourceUrl: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.object,
  }).isRequired,
}
