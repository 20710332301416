// Vendor
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Formsy from 'formsy-react'
import { addValidationRule } from 'formsy-react'
import { defineMessages } from 'react-intl'
import cx from 'classnames'
import v from 'voca'

// Wespire
import SubmitButton from 'components/ui/submit_button'
import TextField from 'components/form/text_field'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  add_guest_leader: {
    defaultMessage: 'Add Guest Leader',
    id: 'GuestLeader.add_guest_leader',
  },
  duplicated_email_error: {
    defaultMessage: 'There is already a leader with this email',
    id: 'GuestLeader.duplicated_email_error',
  },
  email_label: {
    defaultMessage: 'Email',
    id: 'GuestLeader.email_label',
  },
  first_name_label: {
    defaultMessage: 'First Name',
    id: 'GuestLeader.first_name_label',
  },
  guest_label: {
    defaultMessage: 'Guest',
    id: 'GuestLeader.guest_label',
  },
  invalid_email_message: {
    defaultMessage: 'Pleaser enter a valid email',
    id: 'GuestLeader.invalid_email_message',
  },
  last_name_label: {
    defaultMessage: 'Last Name',
    id: 'GuestLeader.last_name_label',
  },
  not_blank_message: {
    defaultMessage: "Can't be blank",
    id: 'GuestLeader.not_blank_message',
  },
})

const { formatMessage } = intl

const buildItemforAutocompleteValue = (values) => {
  const { first_name, last_name, email } = values
  return {
    email,
    first_name,
    label: `${first_name} ${last_name} (${formatMessage(
      messages.guest_label
    )})`,
    last_name,
    value: 'N_' + Date.now().toString(), // A random value so autocomplete component doesn't brake
  }
}

const GuestLeaderForm = ({ autoCompleteRef, className, onHandleSelection }) => {
  const formRef = useRef()

  addValidationRule('isNotACurrentLeader', (_, value) => {
    if (autoCompleteRef.props) {
      const currentLeaders = autoCompleteRef.props.value
      return v.isBlank(currentLeaders.find((leader) => leader.email === value))
    }

    return true
  })

  const addGuestLeaderToAutoComplete = (values) => {
    const currentValue = autoCompleteRef.props.value
    const newItem = buildItemforAutocompleteValue(values)
    const newValue = currentValue ? currentValue.concat(newItem) : [newItem]

    autoCompleteRef.props.setValue(newValue)
    onHandleSelection({ newValue })

    formRef.current.reset()
  }

  return (
    <Formsy
      className={cx('row', className)}
      onValidSubmit={addGuestLeaderToAutoComplete}
      ref={formRef}
    >
      <div className="col-xs-3">
        <TextField
          name="first_name"
          required
          textFieldProps={{ label: formatMessage(messages.first_name_label) }}
          validationErrors={{
            isNotBlank: formatMessage(messages.not_blank_message),
          }}
          validations="isNotBlank"
        />
      </div>
      <div className="col-xs-3">
        <TextField
          name="last_name"
          required
          textFieldProps={{ label: formatMessage(messages.last_name_label) }}
          validationErrors={{
            isNotBlank: formatMessage(messages.not_blank_message),
          }}
        />
      </div>
      <div className="col-xs-3">
        <TextField
          name="email"
          required
          textFieldProps={{ label: formatMessage(messages.email_label) }}
          validationErrors={{
            isEmail: formatMessage(messages.invalid_email_message),
            isNotACurrentLeader: formatMessage(messages.duplicated_email_error),
            isNotBlank: formatMessage(messages.not_blank_message),
          }}
          validations={{
            isEmail: true,
            isNotACurrentLeader: true,
            isNotBlank: true,
          }}
        />
      </div>
      <div className="col-xs-3">
        <SubmitButton data-test="add-guest-leader" variant="contained">
          {formatMessage(messages.add_guest_leader)}
        </SubmitButton>
      </div>
    </Formsy>
  )
}

GuestLeaderForm.propTypes = {
  autoCompleteRef: PropTypes.object.isRequired,
  className: PropTypes.string,
  onHandleSelection: PropTypes.func.isRequired,
}

GuestLeaderForm.defaultProps = {
  className: '',
}

export default GuestLeaderForm
