// Vendor
import React from 'react'
import { intl } from 'utilities/localization'
import PropTypes from 'prop-types'

// WeSpire
import { AdditionalInformationCard } from 'components/ui/AdditionalInformationCard'
import { messages } from '../utilities/messages'
import Stack from 'components/ui/stack'
import { FormSubmitButton } from 'components/ui/Form/FormSubmitButton'

const { formatMessage } = intl

const EmployeeCredentials = ({ className, ssoDescription, url }) => {
  const csrftParamEl = document.querySelector("meta[name='csrf-param']")

  const csrfTokenEl = document.querySelector("meta[name='csrf-token']")
  const tokenName = csrftParamEl && csrftParamEl.getAttribute('content')
  const tokenValue = csrfTokenEl && csrfTokenEl.getAttribute('content')

  return (
    <Stack className={className} space={3}>
      <form
        action={url}
        className="[ d-flex flex-column ] [ w-100 ]"
        method="POST"
      >
        <div className="flex-grow login-auth-panel-main-react">
          <input name={tokenName} type="hidden" value={tokenValue} />
        </div>
        <div className="login-auth-panel-footer">
          <FormSubmitButton
            className="[ fs-2 px-4 py-3 text-uppercase w-100 ] [ login-button ]"
            color="secondary"
            dataTest="login-sso"
            variant="contained"
          >
            {formatMessage(messages.continueWithWorkCredentials)}
          </FormSubmitButton>
        </div>
      </form>
      {ssoDescription && (
        <AdditionalInformationCard className="w-100" dataTest="sso-description">
          <div
            className="login-auth-title"
            dangerouslySetInnerHTML={{
              __html: ssoDescription,
            }}
          />
        </AdditionalInformationCard>
      )}
    </Stack>
  )
}

EmployeeCredentials.propTypes = {
  className: PropTypes.string,
  ssoDescription: PropTypes.string,
  url: PropTypes.string.isRequired,
}

EmployeeCredentials.defaultProps = {
  className: null,
  ssoDescription: null,
}

export { EmployeeCredentials }
