// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import Icon from 'components/ui/icon'

// WeSpire
import { abbreviatedDateWithDay } from 'utilities/date_formatter'
import { ACTION_TRACKING_TYPE_DAILY, activityGoalText } from './utils'
import { intl } from 'utilities/localization'
import { parseDate } from 'utilities/date_utils'

const messages = defineMessages({
  dailyMaxSevenDayLogsDisclaimer: {
    defaultMessage: 'counted from {totalText} logged',
    description:
      'Optional suffix appended to an entry on the last seven days of activity, when the user has logged over the daily limit',
    id: 'userActivityLog.dailyMaxSevenDayLogsDisclaimer',
  },
})

const { formatMessage } = intl

const UserActivityLog = ({
  accumulationActionTrackingType,
  userActivityLog,
}) => {
  const { dailyMax, count } = userActivityLog
  const displayDailyMax = dailyMax && count > dailyMax
  // const extraProgress = displayDailyMax && count - dailyMax

  const progressCount = (ual) => {
    if (ual.dailyMax) return Math.min(ual.count, ual.dailyMax)
    return ual.count
  }

  const countText = activityGoalText({
    conditionMetricName: userActivityLog.conditionMetricName,
    count: progressCount(userActivityLog),
    progressCategory: userActivityLog.category,
    progressMeasuredAs: userActivityLog.measuredAs,
  })

  const totalText = activityGoalText({
    conditionMetricName: userActivityLog.conditionMetricName,
    count,
    progressCategory: userActivityLog.category,
    progressMeasuredAs: userActivityLog.measuredAs,
  })

  return (
    <li className="activity-log fs-1 lh-md" data-test="activity-log-in-day">
      <span
        className="align-items-center d-flex flex-nowrap"
        data-test="activity-count"
      >
        <span className="date-column">
          {abbreviatedDateWithDay(parseDate(userActivityLog.date))}
        </span>
        <span className="flex-grow">
          {countText}
          &nbsp;
          {displayDailyMax && (
            <span data-test="daily-max-exceeded">
              {formatMessage(messages.dailyMaxSevenDayLogsDisclaimer, {
                totalText,
              })}
            </span>
          )}
        </span>

        {accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY &&
          userActivityLog.dailyGoalMet && (
            <span
              className="d-inline-flex align-items-center ml-auto mr-md-2"
              data-test="daily-goal-completed"
            >
              <Icon className="fs-4 text-green" iconName="star" title="star" />
            </span>
          )}
      </span>
    </li>
  )
}

UserActivityLog.propTypes = {
  accumulationActionTrackingType: PropTypes.string,
  /** Activity log from current User */
  userActivityLog: PropTypes.shape({
    category: PropTypes.string,
    conditionMetricName: PropTypes.string,
    count: PropTypes.number.isRequired,
    dailyGoalMet: PropTypes.bool,
    dailyMax: PropTypes.number,
    date: PropTypes.string.isRequired,
    measuredAs: PropTypes.string,
  }).isRequired,
}

UserActivityLog.defaultProps = {
  accumulationActionTrackingType: null,
}

export default UserActivityLog
