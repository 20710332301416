// Vendor
import React from 'react'

// WeSpire
import { Surface } from 'components/ui/Surface'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'this is a Surface component',
    },
  },
  component: Surface,
  title: 'UI/Surface',
}

const Template = (args) => <Surface {...args} />

export const Surface1 = Template.bind({})
Surface1.args = {
  dataTest: 'test-surface',
}
