import gql from 'graphql-tag'

export const CREATE_GROUP_ANNOUNCEMENT = gql`
  mutation createGroupAnnouncementMutation(
    $announcementHtml: String!
    $groupId: ID!
    $status: GroupAnnouncementStatus!
    $title: String!
  ) {
    createGroupAnnouncement(
      announcementHtml: $announcementHtml
      groupId: $groupId
      status: $status
      title: $title
    ) {
      errors
    }
  }
`

export const UPDATE_GROUP_ANNOUNCEMENT = gql`
  mutation updateGroupAnnouncementMutation(
    $announcementHtml: String
    $groupAnnouncementId: ID!
    $title: String!
    $status: GroupAnnouncementStatus
  ) {
    updateGroupAnnouncement(
      announcementHtml: $announcementHtml
      groupAnnouncementId: $groupAnnouncementId
      title: $title
      status: $status
    ) {
      errors
    }
  }
`
