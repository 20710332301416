import React from 'react'

// Vendor
import cx from 'classnames'

const EmptyStateBox = ({ title, children, className }) => {
  return (
    <div
      className={cx(
        'w-full bg-white border-[1px] border-grayscale-3 text-center px-4 py-10',
        className
      )}
    >
      <h3 className="text-practical-gray-1">{title}</h3>
      {children}
    </div>
  )
}

export default EmptyStateBox
