// Setup
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

// Vendor
import Formsy from 'formsy-react'

// WeSpire
import { displayExceptionBanner } from 'redux/dispatchers'
import FormFieldset from 'components/form/fieldset'
import FormTextField from 'components/form/text_field'
import FormFieldsetGroup from 'components/form/fieldset_group'
import FormLimitedTextField from 'components/form/limited_text_field'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import { Heading } from 'components/ui/heading'
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import WeSpireAPI from 'utilities/wespire_api'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  backLinkText: {
    defaultMessage: 'Back to Channel Details',
    description:
      'Text to display which guides the user back to the previous page',
    id: 'secondaryLocaleForm.backLinkText',
  },
  channelDetails: {
    defaultMessage: 'Channel Details',
    id: 'secondaryLocaleForm.channelDetails',
  },
  descriptionInputHelperText: {
    defaultMessage:
      'A short description that will explain what this Channel is about.',
    description:
      'Text to give clarity to the user what is expected for the field',
    id: 'secondaryLocaleForm.descriptonInputHelperText',
  },
  errorNotification: {
    defaultMessage: 'Please fix the errors in the form.',
    id: 'secondaryLocaleForm.errorNotification',
  },
  exceptionNotification: {
    defaultMessage: 'save this form',
    id: 'secondaryLocaleForm.exceptionNotification',
  },
  nameAndDescription: {
    defaultMessage: 'Name & Description',
    id: 'secondaryLocaleForm.nameAndDescription',
  },
  nameInputHelperText: {
    defaultMessage:
      'The recommended length for a Channel name is 30 characters.',
    description:
      'Text to give clarity to the user what is expected for the field',
    id: 'secondaryLocaleForm.nameInputHelperText',
  },
  saveButtonText: {
    defaultMessage: 'Save Locale',
    id: 'secondaryLocaleForm.saveButtonText',
  },
  successNotification: {
    defaultMessage: 'Secondary locale form successfully updated.',
    id: 'secondaryLocaleForm.successNotification',
  },
})

const { formatMessage } = intl

const SecondaryLocaleForm = ({
  className,
  channel,
  displayNotification,
  secondaryLocaleKey,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = ({ description_translation, name_translation }) => {
    setIsSubmitting(true)

    return WeSpireAPI()({
      data: {
        channel: {
          description: description_translation,
          form_locale: secondaryLocaleKey,
          name: name_translation,
        },
      },
      method: 'patch',
      url: `/channels/${channel.id}`,
    })
      .then(() => {
        displayNotification(formatMessage(messages.successNotification))
      })
      .catch(() => {
        displayExceptionBanner({
          fullWidth: true,
          operation: formatMessage(messages.exceptionNotification),
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleTranslate = (e) => {
    const translateConfirmationQuestion =
      sharedTranslations.translateConfirmationQuestion
    if (confirm(translateConfirmationQuestion)) {
      return true
    } else {
      e.preventDefault()
    }
  }

  return (
    <Paper className={className}>
      <div className="d-flex px-4 mt-2 mb-2 pt-2">
        <Heading className="text-uppercase" level={2}>
          {formatMessage(messages.nameAndDescription)}
        </Heading>
        <Link
          className="d-flex mt-1 MuiButton-containedPrimary MuiButton-root MuiButtonBase-root ml-auto text-white"
          onClick={handleTranslate}
          to={`/management_panel/channels/${channel.id}/secondary_locales/${secondaryLocaleKey}/translate`}
        >
          {sharedTranslations.translate}
        </Link>
      </div>
      <Formsy noValidate onValidSubmit={handleSubmit}>
        <FormFieldsetGroup>
          <FormFieldset
            label={formatMessage(messages.channelDetails)}
            variant="shaded"
          >
            <div className="row">
              <div className="col">
                <FormTextField
                  name="name"
                  textFieldProps={{
                    disabled: true,
                    InputProps: {
                      className: 'bg-black-6 text-black-2',
                    },
                    label: sharedTranslations.name,
                  }}
                  value={channel.defaultName}
                />
              </div>

              <div className="col">
                <FormLimitedTextField
                  label={sharedTranslations.nameTranslation}
                  maxLength={40}
                  name="name_translation"
                  textFieldProps={{
                    inputProps: {
                      'aria-describedby': 'name-translation-helper',
                    },
                  }}
                  value={channel.localeName}
                />
              </div>
              <Stack className="col">
                <p id="name-translation-helper">
                  {formatMessage(messages.nameInputHelperText)}
                </p>
              </Stack>
            </div>
            <div className="row">
              <div className="col">
                <FormTextField
                  name="description"
                  textFieldProps={{
                    disabled: true,
                    InputProps: {
                      className: 'bg-black-6 text-black-2',
                    },
                    label: sharedTranslations.description,
                    multiline: true,
                  }}
                  value={channel.defaultDescription}
                />
              </div>
              <div className="col">
                <FormLimitedTextField
                  label={sharedTranslations.descriptionTranslation}
                  maxLength={200}
                  multiline
                  name="description_translation"
                  textFieldProps={{
                    inputProps: {
                      'aria-describedby': 'description-translation-helper',
                    },
                  }}
                  value={channel.localeDescription}
                />
              </div>
              <Stack className="col">
                <p id="description-translation-helper">
                  {formatMessage(messages.descriptionInputHelperText)}
                </p>
              </Stack>
            </div>
          </FormFieldset>

          <FormActionBar
            backPath={`/management_panel/channels/${channel.id}/edit`}
            backText={formatMessage(messages.backLinkText)}
            className="p-3"
          >
            <FormActionBarSubmitButton
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
            >
              {formatMessage(messages.saveButtonText)}
            </FormActionBarSubmitButton>
          </FormActionBar>
        </FormFieldsetGroup>
      </Formsy>
    </Paper>
  )
}

SecondaryLocaleForm.propTypes = {
  channel: PropTypes.shape({
    defaultDescription: PropTypes.string,
    defaultName: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    localeDescription: PropTypes.string.isRequired,
    localeName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  displayNotification: PropTypes.func.isRequired,
  secondaryLocaleKey: PropTypes.string.isRequired,
}

SecondaryLocaleForm.defaultProps = {
  channel: {},
  className: null,
}

export { SecondaryLocaleForm }
