// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

// WeSpire
import { GroupCard } from './group_card'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { sortByNumber } from 'utilities/sort'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'

const messages = defineMessages({
  moreGroups: {
    defaultMessage: 'More {friendlyErgName}',
    id: 'moreGroups.moreGroups',
  },
  noMoreGroups: {
    defaultMessage:
      'It looks like you joined all the {friendlyErgName} available.',
    id: 'moreGroups.noMoreGroups',
  },
})
const { formatMessage } = intl

const moreGroupsQuery = gql`
  query MoreGroupsQuery($groupFilter: String) {
    groups(groupFilter: $groupFilter) {
      heroImage
      id
      descriptionPlainText
      groupChapters {
        id
      }
      name
      position
      slug
    }
  }
`

const MoreGroups = ({ friendlyErgName }) => {
  return (
    <Stack as="section">
      <Heading className="px-2" data-test="more-groups-heading" level={2}>
        {formatMessage(messages.moreGroups, {
          friendlyErgName,
        })}
      </Heading>
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={formatMessage(messages.moreGroups, {
              friendlyErgName,
            })}
          />
        }
        loader={<SkeletonLoading height={245} />}
        query={moreGroupsQuery}
        variables={{ groupFilter: 'noUserGroups' }}
      >
        {({ data: { groups } }) => {
          const sortedGroups = sortByNumber(groups, 'position')

          return (
            <>
              {sortedGroups.length ? (
                <ul
                  className="[ row vertical-gutters ] [ pb-3 px-0 ]"
                  data-test="more-groups"
                >
                  {sortedGroups.map((group) => (
                    <GroupCard group={group} key={group.id} />
                  ))}
                </ul>
              ) : (
                <p className="py-3" data-test="more-groups-empty-state-message">
                  {formatMessage(messages.noMoreGroups, {
                    friendlyErgName,
                  })}
                </p>
              )}
            </>
          )
        }}
      </WeQuery>
    </Stack>
  )
}

MoreGroups.propTypes = {
  friendlyErgName: PropTypes.string.isRequired,
}

export { MoreGroups, moreGroupsQuery }
