import React from 'react'
import cx from 'classnames'

const Pill = ({ children, className }) => (
  <div
    className={cx('bg-thriving-green-4 inline-block px-2 rounded', className)}
  >
    <span className="font-sans leading-4 text-sm font-medium">{children}</span>
  </div>
)

export default Pill
