// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'

import { GroupRow } from './row'

const { formatMessage } = intl

const messages = defineMessages({
  noGroups: {
    defaultMessage: 'No Groups available.',
    id: 'groupManagementList.noGroups',
  },
})

const QUERY = gql`
  query groupListQuery {
    groups(forManagementPanel: true) {
      id
      name
      labelName
    }
  }
`

const GroupList = () => {
  const { data, error, loading } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
  })
  const groups = data?.groups

  if (error) {
    return <MissingResourcePage />
  }

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }
  return (
    groups && (
      <Stack
        as="ul"
        className="p-0"
        data-test="groups-list"
        divider="default"
        space={3}
      >
        {groups.length > 0 ? (
          groups.map((group) => <GroupRow group={group} key={group.id} />)
        ) : (
          <p className="fw-semi-bold text-black-3">
            {formatMessage(messages.noGroups)}
          </p>
        )}
      </Stack>
    )
  )
}

export { GroupList }
