// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { RelativeTime } from 'components/shared/relative_time'
import ScreenReader from 'components/ui/screen_reader_text'
import { TextLink } from 'components/shared/text_link'
import { UserNameQuery } from 'components/queries/username_query'

const UserAttribution = ({
  avatarUrl,
  createdAt,
  profilePath,
  userId,
  user,
  ...other
}) => (
  <div
    className={cx('[ d-flex align-items-center ] [ fs-1 ]', other.className)}
  >
    <TextLink className="[ flex-shrink-none ] [ mr-2 ]" to={profilePath}>
      <Avatar image={avatarUrl} size={AvatarSizes.small} />
      <ScreenReader>
        <UserNameQuery user={user} userId={userId} />
      </ScreenReader>
    </TextLink>
    <div className="[ d-flex align-items-center ] [ lh-md ]">
      <TextLink to={profilePath}>
        <span className="fw-semi-bold" data-test="user-attribution-name">
          <UserNameQuery user={user} userId={userId} />
        </span>
      </TextLink>
      <BulletSpacer />
      <RelativeTime
        className="flex-shrink-none"
        data-test="user-attribution-created-at"
        date={createdAt}
      />
    </div>
  </div>
)

UserAttribution.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  profilePath: PropTypes.string.isRequired,
  user: PropTypes.object,
  userId: PropTypes.string.isRequired,
}

UserAttribution.defaultProps = {
  user: null,
}

export { UserAttribution }
