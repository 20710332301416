// Vendor
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { defineMessages } from 'react-intl'

// Wespire
import { duration } from 'styles/variables'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  linkCopied: {
    defaultMessage: 'Link Copied',
    description:
      'Button text temporarily displayed after the button is pressed ' +
      `and share link URL is copied to the current user's clipboard`,
    id: 'shareLink.linkCopied',
  },
})

const { formatMessage } = intl

class ShareLink extends Component {
  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    buttonProps: PropTypes.object,
    className: PropTypes.string,
    displayNotification: PropTypes.func.isRequired,
    notificationMessage: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }
  static defaultProps = {
    buttonProps: {},
    className: null,
  }

  state = {
    shareButtonSelected: false,
  }

  handleClick = () => {
    this.setState({ shareButtonSelected: true })
    setTimeout(this.afterClick, duration.long)
  }

  afterClick = () => {
    this.setState({ shareButtonSelected: false })
  }

  handleOnCopy = () => {
    const { displayNotification, notificationMessage } = this.props
    displayNotification(notificationMessage)
  }

  render() {
    const { shareButtonSelected } = this.state
    const { buttonProps, className, buttonLabel, url } = this.props
    const shareText = shareButtonSelected
      ? formatMessage(messages.linkCopied)
      : buttonLabel

    return (
      <CopyToClipboard
        className={className}
        onCopy={this.handleOnCopy}
        text={url}
      >
        <Button
          {...buttonProps}
          data-test="shareable-link"
          onClick={this.handleClick}
        >
          {shareText}
        </Button>
      </CopyToClipboard>
    )
  }
}

export default ShareLink
