// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedRelativeTime } from 'react-intl'
import { selectUnit } from '@formatjs/intl-utils'
import { isAfter } from 'utilities/date_utils'

// WeSpire
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'

/**
 * This component renders a description of how long ago the present time is
 * from a provided date. This description is formatted based on the browser's
 * locale using react-intl.
 * Some output examples include:
 *   - "now"
 *   - "in 5 hours"
 *   - "10 minutes ago"
 *   - "last year"
 */
const RelativeTime = ({ date, showPrefix, ...other }) => {
  const { value, unit } = selectUnit(new Date(date))
  const prefixes = defineMessages({
    futurePrefix: {
      defaultMessage: 'ends',
      description:
        'Joining word before relative time in the future, e.g. "ends in 5 hours"',
      id: 'relativeTime.futurePrefix',
    },
  })

  return (
    <>
      {showPrefix &&
        (isAfter(date)
          ? sharedTranslations.ended
          : intl.formatMessage(prefixes.futurePrefix)) + ' '}
      <time {...other} dateTime={date}>
        <FormattedRelativeTime numeric="auto" unit={unit} value={value} />
      </time>
    </>
  )
}

RelativeTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
  showPrefix: PropTypes.bool,
}

RelativeTime.defaultProps = {
  showPrefix: false,
}

export { RelativeTime }
