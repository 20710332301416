import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const customStyles = {
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '1rem',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: 'error-red',
      color: 'smart-blue',
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: 'rgba(0, 0, 0, 0.25)',
      fontSize: '1rem',
      fontWeight: 300,
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
  }),
}

const AutoComplete = ({
  options,
  name,
  placeholder,
  value,
  onChange,
  ...other
}) => {
  return (
    <Select
      classNames={{
        control: () =>
          'w-full bg-white border border-practical-gray rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-smart-blue focus:border-transparent',
      }}
      styles={customStyles}
      defaultValue={[]}
      isMulti
      name={name}
      options={options}
      classNamePrefix="select"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      height="42px"
      {...other}
    />
  )
}

AutoComplete.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      alpha3: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
}

AutoComplete.defaultProps = {
  countries: [],
  name: '',
  onChange: () => {},
  options: [],
  placeholder: '',
  value: [],
}

export default AutoComplete
