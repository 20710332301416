import React, { useState, useEffect } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import WeSpireApi from 'utilities/wespire_api';
import Button from 'src/common/button/Button';


const ResetUserPasswordForm = ({ userId }) => {
  const [user, setUser] = useState(null);
  const [initialValues, setInitialValues] = useState({ password: '' });
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await WeSpireApi({ baseUrl: '/' }).get(`/admin/users/${userId}/edit.json`);
        const user = response.data.user;
        setUser(user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = async (values) => {
    try {
      const response = await WeSpireApi({ baseUrl: '/' }).patch(`/admin/users/${userId}.json`, { user: values });
      if (response.status === 200) {
        setMessage({ type: 'success', text: 'User password updated successfully' });
      } else {
        setMessage({ type: 'error', text: 'Error updating user password' });
      }
    } catch (error) {
      setMessage({ type: 'error', text: 'Error updating user password' });
      console.error('Error updating user password:', error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-[800px] min-height=[60vh] mx-auto">
      <h1 className="text-2xl font-semibold mb-4">Reset User Password</h1>
      <h2 className="text-xl mb-4">
        {user.first_name} {user.last_name} ({user.email})
      </h2>
      {message && (
        <div
          className={`text-sm mb-4 ${
            message.type === 'success' ? 'text-success-green' : 'text-error-red'
          }`}
        >
          {message.text}
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form className="space-y-4">
          <div>
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <Field
              name="password"
              type="password"
              className="mt-1 block w-full border-gray-300 rounded-md"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const ResetUserPassword = ({ match }) => {
  const userId = match.params.user_id;
  return (
    <>
      <ResetUserPasswordForm userId={userId} />
    </>
  );
};

export default ResetUserPassword;

