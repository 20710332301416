import React, { useContext } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { CalculatorContext } from 'components/CarbonCalculator/contexts/CalculatorContext'
import PropTypes from 'prop-types'

const AutocompleteComponent = ({ answers: questionAnswers, questionKey }) => {
  const { dispatchAnswer } = useContext(CalculatorContext)
  const handleOnChange = (e, newValue) => {
    dispatchAnswer({
      type: questionKey,
      payload: { questionKey, answer: newValue && newValue.value },
    })
  }

  return (
    <Autocomplete
      key={questionKey}
      onChange={(e, newValue) => handleOnChange(e, newValue)}
      data-test="autocomplete-test"
      options={questionAnswers}
      renderInput={(params) => <TextField {...params} label="Select Option" />}
      disablePortal
      freeSolo
    />
  )
}

AutocompleteComponent.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      descriptionText: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  questionKey: PropTypes.string.isRequired,
}

export default AutocompleteComponent
