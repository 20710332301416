// Vendor
import PropTypes from 'prop-types'
import React from 'react'
import Button from '@material-ui/core/Button'
import cx from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  editIconLabel: {
    defaultMessage: 'Edit { fileType }',
    id: 'editableImageButton.editIconLabel',
  },
  removeIconLabel: {
    defaultMessage: 'Remove { fileType }',
    id: 'editableImageButton.removeIconLabel',
  },
})

// 1. Allow the <Button> shadow to be visible instead of the <Paper> shadow
const showButtonShadow = 'overflow-visible' // 1

const EditableImageButton = ({
  customHeight,
  customWidth,
  fileName,
  imageURL,
  isDocument,
  name,
  onClick,
  onClickDelete,
  shape,
}) => (
  <div className="position-relative">
    <Paper
      className={cx(showButtonShadow, {
        'aspect-ratio': !!shape,
        'aspect-ratio--16-9 rounded': shape === 'rectangle',
      })}
      elevation={0} // 1
    >
      <Button
        aria-describedby={`${name}-helper`}
        aria-labelledby={`${name}-label`}
        className={cx('bg-img bg-img--cover p-0', {
          'aspect-ratio__content position-absolute': !!shape,
          'rounded-circle': shape === 'circle',
        })}
        onClick={onClick}
        style={{
          backgroundImage: `url(${imageURL})`,
          height: customHeight,
          width: customWidth,
        }}
        variant="contained"
      >
        <span
          className={cx(
            'd-inline-flex | bg-black-3 fs-5 p-2 rounded-circle text-white z-2',
            isDocument ? 'bg-blue' : 'bg-black-3'
          )}
        >
          <Icon
            iconName="edit"
            title={formatMessage(messages.editIconLabel, {
              fileType: isDocument ? 'File' : 'Image',
            })}
          />
        </span>
      </Button>
    </Paper>

    <IconButton
      className="absolute-top-right bg-black-3 fs-4 p-1 text-white translate-center-to-top-right z-2"
      onClick={onClickDelete}
    >
      <Icon
        iconName="close"
        title={formatMessage(messages.removeIconLabel, {
          fileType: isDocument ? 'File' : 'Image',
        })}
      />
    </IconButton>

    {isDocument && (
      <p className="fs-1 text-black-2 text-truncate mxw-4">{fileName}</p>
    )}
  </div>
)

EditableImageButton.propTypes = {
  customHeight: PropTypes.string,
  customWidth: PropTypes.string,
  fileName: PropTypes.string,
  imageURL: PropTypes.string.isRequired,
  isDocument: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  shape: PropTypes.oneOf(['circle', 'rectangle', 'square']),
}

EditableImageButton.defaultProps = {
  customHeight: undefined,
  customWidth: '100%',
  fileName: '',
  isDocument: false,
  shape: null,
}

export default EditableImageButton
