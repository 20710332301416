// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'

// WeSpire
import { ACTIVITY_CARD_FRAGMENT } from 'components/activities/fragments'
import CenteredContent from 'components/application/centered_content'
import { removeData } from 'utilities/local_storage'
import { client } from 'utilities/we_apollo'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { DocumentTitle } from 'components/shared/document_title'
import { getUrlParams } from 'utilities/get_url_params'
import Hero from 'components/ui/hero'
import { MultiverseAnalytics } from 'utilities/analytics/'
import scrollTracker from 'components/helpers/scroll_tracker'
import { PageQuery } from 'components/queries/page_query'
import ActivitiesChannelNav from '../channel_nav'
import ActivitiesCollection from '../collection'
import ActivitiesEmptyState from '../empty_state'
import { ChannelHero } from '../channel/hero'
import {
  channelUrl,
  getAvailableActivitiesCount,
  getChannel,
  getCurrentChannelId,
} from '../utils'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'

const activeChannelsForUserFragment = `
  activeChannelsForUser {
    description
    id
    imageUrl
    logoImageUrl
    name
    slug
    themeColor
    isMyActivities
  }
`
const GET_ACTIVE_CHANNELS = gql`
  query activeChannelsForUser {
    ${activeChannelsForUserFragment}
  }
`

const messages = defineMessages({
  channelEmptyMessage: {
    defaultMessage:
      'There are currently no Activities available in this Channel.',
    id: 'loadableActivities.channelEmptyMessage',
  },
  myActivitiesEmptyMessage: {
    defaultMessage:
      "When you're participating in Activities, they will appear here.",
    id: 'loadableActivities.myActivitiesEmptyMessage',
  },
})

class LoadableActivities extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }).isRequired,
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        channel: PropTypes.string,
      }),
    }).isRequired,
  }

  state = { eventsView: false }

  componentDidMount() {
    const { history } = this.props

    // remove email from localstorage since we are still setting it for rails.
    removeData('email')

    // Redirect users visiting the legacy URL '/activities'
    const { pathname, search } = history.location
    if (pathname.includes('/activities')) {
      const newPathName = pathname.replace('/activities', '/take_action')

      history.replace({
        pathname: newPathName,
        search: history.location.search,
      })
    }

    if (search === '?view=events-list') {
      this.setState({ eventsView: true })
    }
  }

  handleActivityClick = (id, isFeatured = false) => {
    MultiverseAnalytics.activityClicked(id, isFeatured)
  }

  handleChannelChange = (_event, id) => {
    this.setState({ eventsView: false })
    client
      .query({ query: GET_ACTIVE_CHANNELS })
      .then(({ data: { activeChannelsForUser } }) => {
        const { history } = this.props
        const channel = getChannel(activeChannelsForUser, id)
        const index = activeChannelsForUser.findIndex((item) => item.id === id)

        history.push({
          pathname: channelUrl(channel),
          search: '?view=all',
        })

        MultiverseAnalytics.channelClicked(id, index, channel.name)
      })
  }

  handleToggleActivitiesView = (_event, viewType) => {
    const { history } = this.props

    if (history.location.search !== `?view=${viewType}`) {
      history.push({
        search: `?view=${viewType}`,
      })
    }
  }

  render = () => {
    const { match } = this.props

    const CHANNEL_ACTIVITIES = gql`
      query channelActivitiesQuery($channelId: ID!) {
        brandConfig {
          channelNavbarEnabled
          enableChannelHero
          id
          secondaryLogoImageUrl
        }
        channelActivities(channelId: $channelId) {
          ...ActivityAttributes
        }
      }
      ${ACTIVITY_CARD_FRAGMENT}
    `

    return (
      <PageQuery query={GET_ACTIVE_CHANNELS}>
        {({ data: { activeChannelsForUser } }) => {
          const currentChannelId = getCurrentChannelId(
            match.params.channel,
            activeChannelsForUser
          )
          const currentChannel = getChannel(
            activeChannelsForUser,
            currentChannelId
          )

          return (
            <PageQuery
              query={CHANNEL_ACTIVITIES}
              variables={{
                channelId: currentChannelId,
              }}
            >
              {({ data: { brandConfig, channelActivities } }) => {
                // Collection Data
                const allActivities = currentChannel && channelActivities
                const activities = allActivities
                const channelHasEvents =
                  allActivities.filter((a) => a.type === 'event').length > 0

                // Render Conditions
                const isMyActivitiesTab = currentChannel.isMyActivities
                const hasActivities = allActivities.length > 0

                // Content
                const currentChannelName = isMyActivitiesTab
                  ? sharedTranslations.myActivities
                  : currentChannel.name
                const currentChannelEmptyMessage = isMyActivitiesTab
                  ? intl.formatMessage(messages.myActivitiesEmptyMessage)
                  : intl.formatMessage(messages.channelEmptyMessage)

                return (
                  <DocumentTitle
                    title={`${currentChannelName} • ${sharedTranslations.takeAction}`}
                  >
                    {maybeDisplayUserTour('takeActionPage')}

                    {/* Channel Nav */}
                    {brandConfig.channelNavbarEnabled && (
                      <ActivitiesChannelNav
                        channels={activeChannelsForUser}
                        currentChannelId={currentChannelId}
                        onChannelChange={this.handleChannelChange}
                      />
                    )}

                    {/* Hero */}
                    {!isMyActivitiesTab &&
                      (brandConfig.enableChannelHero ? (
                        <CenteredContent>
                          <ChannelHero
                            description={currentChannel.description}
                            imgUrl={currentChannel.imageUrl}
                            name={currentChannel.name}
                            secondaryLogoUrl={brandConfig.secondaryLogoImageUrl}
                            themeColor={currentChannel.themeColor}
                          />
                        </CenteredContent>
                      ) : (
                        <Hero
                          description={currentChannel.description}
                          imgUrl={currentChannel.imageUrl}
                          title={currentChannel.name}
                        />
                      ))}

                    {/* Collections */}
                    <CenteredContent>
                      {hasActivities ? (
                        <ActivitiesCollection
                          activities={activities}
                          channelHasEvents={channelHasEvents}
                          className="pt-2 pt-sm-3 px-sm-3 px-lg-0"
                          currentChannelId={currentChannelId}
                          isEventsView={this.state.eventsView}
                          isMyActivitiesTab={isMyActivitiesTab}
                          onActivitiesViewChange={
                            this.handleToggleActivitiesView
                          }
                          onActivityClick={this.handleActivityClick}
                          viewType={getUrlParams().view}
                        />
                      ) : (
                        <ActivitiesEmptyState
                          testName="activity-cards-empty-state"
                          text={currentChannelEmptyMessage}
                        />
                      )}
                    </CenteredContent>
                  </DocumentTitle>
                )
              }}
            </PageQuery>
          )
        }}
      </PageQuery>
    )
  }
}

export const onFetchSuccess = (channels) => {
  const availableActivitiesCount = getAvailableActivitiesCount(channels)
  MultiverseAnalytics.availableActivitiesCount(availableActivitiesCount)

  const currentChannel = channels[0]
  if (currentChannel) {
    MultiverseAnalytics.channelActivitiesCount(
      currentChannel.activities.length,
      currentChannel.id
    )
  }
}

export const undecorated = LoadableActivities

export default compose(scrollTracker({ eventPrefix: 'Multiverse Activities' }))(
  LoadableActivities
)
