// Vendor
import gql from 'graphql-tag'

// WeSpire
import { FacepileSizes } from 'components/application/avatar'

export const GET_ACTIVITY_SERIES_BY_ID = gql`
  query getActivitySeriesById($activitySeriesId: ID!) {
    activitySeries(id: $activitySeriesId) {
      achievement {
        awardedTo
        description
        id
        imageUrl
        name
      }
      campaigns {
        achievement {
          awardedTo
          imageUrl
        }
        actionUrl
        description
        id
        imageUrl
        isParticipating
        name
        participants(limit: 6) {
          avatarUrl
        }
        participantsCount
        progressPercentForUser
        startsAt
        type
      }
      completedCampaigns
      description
      endsAt
      hasStarted
      id
      imageUrl
      name
      startsAt
      participants(limit: ${FacepileSizes.large}) {
        avatarUrl
        id
      }
      participantsCount
      progressPercentForUser
      totalParticipationIndicators
      userIsParticipating
    }
  }
`
