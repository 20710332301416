// Default State
const defaultState = []

// Action Creator
export function setRoutes(routes) {
  return {
    routes,
    type: 'wespire/SET_ROUTES',
  }
}

// Reducer
const routes = (state = defaultState, action) => {
  switch (action.type) {
    case 'wespire/SET_ROUTES':
      return action.routes.authenticated.concat(action.routes.public)
    default:
      return state
  }
}

export default routes
