// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import Stack from 'components/ui/stack'

/**
 * Renders a "stat" related to Donation History with accompanying label and help
 * text to explain its purpose.
 */
export const DonationHistoryStat = ({
  amount,
  currencyLabel,
  'data-test': dataTest,
  helpText,
  label,
  usdLabel,
  columnWidth,
  ...other
}) => (
  <Stack
    {...other}
    className={cx(`col-sm-${columnWidth} d-flex flex-column`, other.className)}
    data-test={dataTest}
    space={2}
  >
    <Stack as="p" className="d-flex flex-column lh-xs" space={2}>
      <span className="d-inline-block fs-6 fs-sm-7 text-green">
        {amount}
        <span className="fs-2 fw-semi-bold lh-sm ls-2 text-green">
          {usdLabel}
        </span>
      </span>
      <span className="d-inline-block fs-2 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase">
        {label}
      </span>
    </Stack>
    <p className="fs-1 order-3 text-black-3">{helpText}</p>
    <p className="fs-1 order-4 text-black-3">{currencyLabel}</p>
  </Stack>
)

DonationHistoryStat.propTypes = {
  /** primary number displayed at the top of stat */
  amount: PropTypes.element.isRequired,
  currencyLabel: PropTypes.string,
  columnWidth: PropTypes.number,
  'data-test': PropTypes.string,
  /** supporting explanatory info displayed under label */
  helpText: PropTypes.any.isRequired,
  /** text rendered in a heading that describes main purpose of stat */
  label: PropTypes.string.isRequired,
  usdLabel: PropTypes.string,
}

DonationHistoryStat.defaultProps = {
  columnWidth: 6,
  currencyLabel: '',
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'donation-history-stat',
  usdLabel: '',
}
