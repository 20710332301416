// Setup
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

/**
 * Container that automatically creates consistent vertical rhythm between
 * direct child items, based on design system spacing values.
 * Inspired by https://every-layout.dev/layouts/stack.
 */
const Stack = forwardRef(({ as, children, divider, space, ...other }, ref) => {
  const Component = as
  let classList = []

  const addResponsiveClasses = (size, space) => {
    let baseClass = size === '_' ? 'stack' : `stack-${size}`
    classList.push(baseClass)
    classList.push(`${baseClass}--${space}`)
    if (divider === 'default') {
      classList.push(`${baseClass}--with-divider-${space}`)
    } else if (divider === 'decorative') {
      classList.push(`${baseClass}--with-decorative-divider-${space}`)
    }
  }

  if (typeof space === 'number') {
    addResponsiveClasses('_', space)
  } else {
    Object.entries(space).forEach(([key, value]) =>
      addResponsiveClasses(key, value)
    )
  }

  return (
    // Add our classes and organize stack classes within brackets.
    <Component
      {...other}
      className={cx('[', classList, ']', other.className)}
      ref={ref}
    >
      {children}
    </Component>
  )
})

const SpacePropValues = PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7])

Stack.propTypes = {
  /** renders component as custom element  */
  as: PropTypes.any,
  /** renders children */
  children: PropTypes.node.isRequired,
  /** applies visual border between stacked elements */
  divider: PropTypes.oneOf(['decorative', 'default']),
  /** Vertical spacing value based on $spacings scale in variables.scss.
   * Can either be value from 0 to 7 OR an object with breakpoint mappings.
   * Use "_" key for mobile first (non-responsive) value. E.g. {_: 3, md: 0} */
  space: PropTypes.oneOfType([
    SpacePropValues,
    PropTypes.shape({
      _: SpacePropValues,
      lg: SpacePropValues,
      md: SpacePropValues,
      sm: SpacePropValues,
      xl: SpacePropValues,
    }),
  ]),
}

Stack.defaultProps = {
  as: 'div',
  divider: null,
  space: 0,
}

export default Stack
