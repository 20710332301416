// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { RelativeTime } from 'components/shared/relative_time'

const messages = defineMessages({
  availableAgainAnyTime: {
    defaultMessage: 'Complete again <bold>anytime</bold>',
    description:
      'Indicates repeatable action can be completed again at any time.',
    id: 'actionCompletedMessage.availableAgainAnyTime',
  },
  availableAgainAtTimeFromNow: {
    defaultMessage: 'Complete again {timeFromNow}',
    description:
      'Indicates repeatable action can be completed again at a specific time distance from now.',
    id: 'actionCompletedMessage.availableAgainAtTimeFromNow',
  },
  noLongerAvailable: {
    defaultMessage: 'Cannot complete again',
    description: 'Indicates one-time action can no longer be completed.',
    id: 'actionCompletedMessage.noLongerAvailable',
  },
})

const ActionCompletedMessage = ({ className, cooldownEndsAt, status }) => {
  // eslint-disable-next-line no-unused-vars
  const [cooldownEnded, setCooldownEnded] = useState(false)

  useEffect(() => {
    if (cooldownEndsAt) {
      const localizedEndTime = localizeCooldownEndTime(cooldownEndsAt)
      const timeUntilCooldownEnd = localizedEndTime - new Date().getTime()
      const cooldownEnded = timeUntilCooldownEnd < 0
      const oneDayInMs = 24 * 60 * 60 * 1000
      // return if timeUntilCooldownEnd is greater than 1 day OR if the cooldown has ended
      if (timeUntilCooldownEnd > oneDayInMs || cooldownEnded) return

      setTimeout(() => {
        setCooldownEnded(true)
      }, timeUntilCooldownEnd)
    }
  }, [cooldownEndsAt])

  const localizeCooldownEndTime = (cooldownEndsAt) => {
    let localCooldownEndTime = new Date(cooldownEndsAt)
    localCooldownEndTime.setMinutes(
      localCooldownEndTime.getMinutes() +
        localCooldownEndTime.getTimezoneOffset()
    )
    return localCooldownEndTime
  }

  const { formatMessage } = intl
  let localCooldownTime
  if (cooldownEndsAt) {
    localCooldownTime = new Date(cooldownEndsAt)
    localCooldownTime
      .setMinutes(
        localCooldownTime.getMinutes() + localCooldownTime.getTimezoneOffset()
      )
      .toString()
  }

  const cooldownMessages = {
    AVAILABLE: formatMessage(messages.availableAgainAnyTime, {
      bold: (str) => <b key="availableAgainAnyTime">&nbsp;{str}</b>,
    }),
    COOLDOWN: formatMessage(messages.availableAgainAtTimeFromNow, {
      timeFromNow: cooldownEndsAt && (
        <b key="availableAgainAtTimeFromNow">
          &nbsp;
          <RelativeTime date={localCooldownTime} />
        </b>
      ),
    }),
    FINISHED: formatMessage(messages.noLongerAvailable),
    UNAVAILABLE: formatMessage(messages.noLongerAvailable),
  }

  return (
    <p
      className={cx('d-flex align-items-center flex-wrap', className)}
      data-test="cooldown-message"
    >
      <Icon className="mr-1" iconName="clock" title="" />
      {cooldownMessages[status]}
    </p>
  )
}

ActionCompletedMessage.propTypes = {
  className: PropTypes.string,
  /** datetime when the action is no longer on cooldown */
  cooldownEndsAt: PropTypes.string,
  /** the completable status of an action */
  status: PropTypes.oneOf(['AVAILABLE', 'COOLDOWN', 'FINISHED', 'UNAVAILABLE'])
    .isRequired,
}

ActionCompletedMessage.defaultProps = {
  className: null,
  cooldownEndsAt: null,
}

export { ActionCompletedMessage }
