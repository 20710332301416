// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'

const Modal = ({ options, renderContent }) => (
  <Dialog {...options}>{options.open && renderContent()}</Dialog>
)

Modal.propTypes = {
  /** Props to forward to the Dialog component */
  options: PropTypes.object.isRequired,
  /** Work around for triggering the modal to re-render. */
  refreshCounter: PropTypes.number.isRequired,
  /** function which retuns the JSX content of the modal */
  renderContent: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  options: state.modal.options,
  refreshCounter: state.modal.refreshCounter,
  renderContent: state.modal.renderContent,
})

export const ModalWithoutStore = Modal

export default connect(mapStateToProps)(Modal)
