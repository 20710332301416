// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import Formsy from 'formsy-react'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import {
  customFieldFormsyName,
  getCustomFieldResponsesAttributes,
} from 'components/form/custom_field'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import FormTextField from 'components/form/text_field'
import FormActionBar from 'components/form/action_bar'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { removeTypename } from 'utilities/we_apollo'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'

// Sub-Components
import { AdditionalFormFields } from './additional_form_fields'
import { EVENT_REGISTRATION_FRAGMENT, SHIFT_AVAILABLE_SLOTS } from './fragments'
import { PARTICIPATION_GUESTS_QUERY } from './event_guest_box'
import { RoleSelection } from './role_selection'
import { selfRegistrationPath } from 'components/events/routes'
import { showRegistrationClosedNotice } from 'components/events/utils'

const GUEST_SHIFT_TIMEFRAME_PARTICIPATION_EVENT = gql`
  ${EVENT_REGISTRATION_FRAGMENT}

  query shiftTimeframe($id: ID!, $guestId: ID) {
    shiftTimeframe(id: $id) {
      id
      registrationEnded

      participationEvent {
        id
        name

        customFields {
          id
          isRequired
          name
          options
          responseType
        }

        requireAddress
        requirePhoneNumber
        requireWaiver
      }

      participationGuest(id: $guestId) {
        id
        firstName
        lastName
        email

        eventRegistration {
          ...EventRegistrationAttributes
        }

        shift {
          id
        }
      }

      shifts {
        id
        availableSlotsCount
        shiftFunction
      }
    }
  }
`

const REGISTER_GUEST = gql`
  ${EVENT_REGISTRATION_FRAGMENT}

  mutation registerGuest(
    $address: Address
    $customFieldResponses: [CustomFieldResponseAttributes!]
    $email: String!
    $firstName: String!
    $lastName: String!
    $participationGuestId: ID
    $phoneNumber: String
    $shiftId: ID!
  ) {
    registerGuest(
      address: $address
      customFieldResponses: $customFieldResponses
      email: $email
      firstName: $firstName
      lastName: $lastName
      participationGuestId: $participationGuestId
      phoneNumber: $phoneNumber
      shiftId: $shiftId
    ) {
      errors

      guest {
        id
        firstName
        lastName
        email

        eventRegistration {
          ...EventRegistrationAttributes
        }

        shift {
          id
        }
      }
    }
  }
`

const messages = defineMessages({
  email: {
    defaultMessage: 'email',
    id: 'guestRegistrationFormComponent.email',
  },
  emailError: {
    defaultMessage: 'Must be a valid email',
    id: 'guestRegistrationFormComponent.emailError',
  },
  errorDetails: {
    defaultMessage: 'the guest registration form',
    id: 'guestRegistrationFormComponent.errorDetails',
  },
  firstName: {
    defaultMessage: 'first name',
    id: 'guestRegistrationFormComponent.firstName',
  },
  guestDetails: {
    defaultMessage: 'guest details',
    id: 'guestRegistrationFormComponent.guestDetails',
  },
  lastName: {
    defaultMessage: 'last name',
    id: 'guestRegistrationFormComponent.lastName',
  },
  operation: {
    defaultMessage: 'save this Event guest registration',
    id: 'guestRegistrationFormComponent.operation',
  },
  registerGuest: {
    defaultMessage: 'register guest',
    id: 'guestRegistrationFormComponent.registerGuest',
  },
  registrationCreated: {
    defaultMessage: 'Your guest has been successfully registered.',
    id: 'guestRegistrationFormComponent.registrationCreated',
  },
  registrationUpdated: {
    defaultMessage: "Your guest's registration has been updated.",
    id: 'guestRegistrationFormComponent.registrationUpdated',
  },
  updateGuestRegistration: {
    defaultMessage: 'update guest registration',
    id: 'guestRegistrationFormComponent.updateGuestRegistration',
  },
})

export const GuestRegistrationForm = ({ eventId, guestId, shiftId }) => {
  const { formatMessage } = intl
  const history = useHistory()
  const [formSetup, setFormSetup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleRegister = (model, guest) => {
    setIsSubmitting(true)

    client
      .mutate({
        awaitRefetchQueries: true, // Don't redirect until new data is ready.
        mutation: REGISTER_GUEST,
        refetchQueries: [
          {
            query: PARTICIPATION_GUESTS_QUERY,
            variables: { id: shiftId },
          },
          {
            query: SHIFT_AVAILABLE_SLOTS,
            variables: { id: shiftId },
          },
        ],
        variables: {
          address: model.address ? model.address.address : null,
          agreeToWaiver: model.agreeToWaiver,
          customFieldResponses: getCustomFieldResponsesAttributes(model),
          email: model.email,
          firstName: model.first_name,
          lastName: model.last_name,
          participationGuestId: guestId,
          phoneNumber: model.telephone,
          shiftId: model.shiftId,
        },
      })
      .then(
        ({
          data: {
            registerGuest: { errors },
          },
        }) => {
          setIsSubmitting(false)

          if (errors) {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          } else {
            const message = guest
              ? formatMessage(messages.registrationUpdated)
              : formatMessage(messages.registrationCreated)
            displayBanner({ content: message, variant: 'success' })

            history.push(selfRegistrationPath({ eventId, shiftId }))
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)

        displayExceptionBanner({
          operation: formatMessage(messages.operation),
        })
      })
  }

  const setupForm = (form, guest) => {
    if (form && guest && !formSetup) {
      const registration = guest.eventRegistration
      let existingData = {
        email: guest.email,
        first_name: guest.firstName,
        last_name: guest.lastName,
        shiftId: `${guest.shift.id}`,
        telephone: registration.phoneNumber,
      }
      if (registration.address) {
        existingData.address = {
          address: registration.address
            ? removeTypename(registration.address)
            : null,
          addressLabel: '', // Not shown but needed to prevent JS errors.
        }
      }
      registration.customFieldResponses.forEach(
        ({ customFieldId, name, response }) => {
          existingData[customFieldFormsyName(customFieldId, name)] = response
        }
      )
      form.reset(existingData)
      // We need to prevent the form from getting setup a second time as that
      // would cause the changed input values to get reset.
      // This happens due to us updating isSubmitting causing another render.
      setFormSetup(true)
    }
  }

  return (
    <Paper className="mt-4 pb-5 pt-4 px-3 | px-sm-4">
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={formatMessage(messages.errorDetails)}
          />
        }
        loader={<CenteredPaddedLoadingIndicator />}
        query={GUEST_SHIFT_TIMEFRAME_PARTICIPATION_EVENT}
        variables={{ guestId: guestId, id: shiftId }}
      >
        {({ data: { shiftTimeframe } }) => {
          const event = shiftTimeframe.participationEvent
          const guest = shiftTimeframe.participationGuest
          if (shiftTimeframe.registrationEnded) {
            showRegistrationClosedNotice(event)
          }

          return (
            <>
              <h3 className="text-sentence">
                {formatMessage(messages.guestDetails)}
              </h3>
              <Formsy
                noValidate
                onValidSubmit={(model) => handleRegister(model, guest)}
                ref={(form) => setupForm(form, guest)}
              >
                <Stack space={5}>
                  <Stack space={1}>
                    <div className="d-flex row">
                      <div className="col-6">
                        <FormTextField
                          name="first_name"
                          required
                          textFieldProps={{
                            label: formatMessage(messages.firstName),
                            type: 'string',
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormTextField
                          name="last_name"
                          required
                          textFieldProps={{
                            label: formatMessage(messages.lastName),
                            type: 'string',
                          }}
                        />
                      </div>
                    </div>
                    <FormTextField
                      name="email"
                      required
                      textFieldProps={{
                        label: formatMessage(messages.email),
                        type: 'string',
                      }}
                      validationErrors={{
                        isEmail: formatMessage(messages.emailError),
                      }}
                      validations={{ isEmail: true }}
                    />
                  </Stack>

                  <RoleSelection shifts={shiftTimeframe.shifts} />

                  <AdditionalFormFields
                    address={event.requireAddress}
                    customFields={event.customFields}
                    telephone={event.requirePhoneNumber}
                  />

                  <FormActionBar
                    disabled={shiftTimeframe.registrationEnded}
                    isSubmitting={isSubmitting}
                    submitText={
                      guest
                        ? formatMessage(messages.updateGuestRegistration)
                        : formatMessage(messages.registerGuest)
                    }
                  />
                </Stack>
              </Formsy>
            </>
          )
        }}
      </WeQuery>
    </Paper>
  )
}

GuestRegistrationForm.propTypes = {
  eventId: PropTypes.string.isRequired,
  guestId: PropTypes.string,
  shiftId: PropTypes.string.isRequired,
}

GuestRegistrationForm.defaultProps = {
  guestId: null,
}
