import gql from 'graphql-tag'

export const EVENT_REGISTRATION_FRAGMENT = gql`
  fragment EventRegistrationAttributes on EventRegistration {
    id
    address {
      city
      country
      houseNumber
      state
      street
      postalCode
      countryAlpha3
    }
    agreeToWaiver
    customFieldResponses {
      id
      customFieldId
      name
      response
    }
    phoneNumber
  }
`

export const SHIFT_AVAILABLE_SLOTS = gql`
  query shiftTimeframe($id: ID!) {
    shiftTimeframe(id: $id) {
      id

      shifts {
        id
        availableSlotsCount
      }
    }
  }
`
