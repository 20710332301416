// Setup
import React from 'react'
import cx from 'classnames'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

// WeSpire
import CommentsSummary from 'components/application/comments/comments_summary'
import DisableButton from 'components/ui/disable_button'
import { VoteButtonIcon } from 'components/application/likes/vote_button_icon'
import { sharedTranslations } from 'components/shared/translations'
import { EditButton } from 'components/idea_board/idea/edit_button'

export const IdeaFooter = ({
  className,
  currentUserId,
  onCommentButtonClick,
  onVoteButtonClick,
  userHasVoted,
  voteCount,
  votingDisabled,
  ideaBoardId,
  idea,
}) => (
  <Paper className={className} square>
    <DisableButton
      className={cx(
        'fs-1 mnw-0 px-2',
        { 'text-black-4': votingDisabled },
        { 'text-blue': !votingDisabled }
      )}
      data-test="vote-button"
      disabled={votingDisabled}
      onClick={onVoteButtonClick}
      size="small"
    >
      {userHasVoted ? sharedTranslations.voted : sharedTranslations.vote}
    </DisableButton>
    <span className="fs-1 ml-1">{<FormattedNumber value={voteCount} />}</span>
    <VoteButtonIcon
      className="fs-2 ml-1 text-black-3"
      isVoted={userHasVoted}
      voteCount={voteCount}
    />
    <CommentsSummary
      className="px-3"
      data-test="comment-button"
      onSummaryClick={onCommentButtonClick}
    />
    {idea.user.id === currentUserId && (
      <EditButton
        buttonText={sharedTranslations.edit}
        className="ml-auto"
        ideaBoardId={ideaBoardId}
        ideaId={idea.id}
      />
    )}
  </Paper>
)

IdeaFooter.propTypes = {
  className: PropTypes.string,
  currentUserId: PropTypes.string.isRequired,
  idea: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  ideaBoardId: PropTypes.string.isRequired,
  onCommentButtonClick: PropTypes.func.isRequired,
  onVoteButtonClick: PropTypes.func.isRequired,
  userHasVoted: PropTypes.bool,
  voteCount: PropTypes.number.isRequired,
  votingDisabled: PropTypes.bool,
}

IdeaFooter.defaultProps = {
  className: null,
  userHasVoted: false,
  votingDisabled: false,
}
