// Vendor
import gql from 'graphql-tag'

// WeSpire
import { ANNOUNCEMENT_FRAGMENT } from './fragments'
import { NEWSFEED_ITEM_FRAGMENT } from 'graphql/fragments'

export const GET_GROUP_ANNOUNCEMENTS = gql`
  query GET_GROUP_ANNOUNCEMENTS($id: ID!) {
    group(id: $id) {
      id
      announcements {
        ...AnnouncementAttributes
      }
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`

export const GET_GROUP_NEWSFEED_ITEMS = gql`
  query GET_GROUP_NEWSFEED_ITEMS($id: ID!, $after: String, $first: Int) {
    group(id: $id) {
      id
      newsfeed(after: $after, first: $first) @connection(key: "groupNewsfeed") {
        nodes {
          ...NewsfeedItem
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
  }
  ${NEWSFEED_ITEM_FRAGMENT}
`
