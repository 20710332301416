import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@mui/material'
import Breadcrumbs from 'src/common/Breadcrumbs'
import { CalculatorContextProvider } from 'components/CarbonCalculator/contexts/CalculatorContext'
import Calculator from 'components/CarbonCalculator/pages/Calculator'
import 'components/CarbonCalculator/index.css'
import theme from './styles'
import { useCrumbs } from 'src/hooks/useCrumbs'

const App = ({ getFootprint }) => {
  useCrumbs([
    {
      href: '/carbon_footprint',
      text: 'Carbon Footprint',
    },
    {
      href: '/app/carbon_calculator',
      text: 'Carbon Calculator',
    },
  ])

  return (
    <div className="App bg-backgroundGray">
      <div className="flex">
        <ThemeProvider theme={theme}>
          <CalculatorContextProvider>
            <main id="main" className="flex h-full w-screen my-10 lg:py-0">
              <div className="m-auto bg-white pt-10 pb-10 filter drop-shadow px-5">
                <Calculator getFootprint={getFootprint} />
              </div>
            </main>
          </CalculatorContextProvider>
        </ThemeProvider>
      </div>
    </div>
  )
}

App.propTypes = {
  getFootprint: PropTypes.func,
}
App.defaultProps = {
  getFootprint: () => {},
}
export default App
