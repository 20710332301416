import AnalyticsManager from './analytics_manager'

export default class ActivityGalleryAnalytics extends AnalyticsManager {
  // Gallery button is pressed
  galleryButtonClicked = ({ storyId }) =>
    this.track('View gallery button clicked', { storyId })

  // Gallery thumbnail clicked to view the gallery modal.
  galleryThumbnailClicked = ({ storyId }) =>
    this.track('Gallery thumbnail clicked', { storyId })

  // Gallery modal nav item clicked to view an adjacent image.
  galleryModalNavItemClicked = ({ direction, storyId }) =>
    this.track('Gallery modal nav item clicked', { direction, storyId })
}

export const instance = new ActivityGalleryAnalytics()
