export const TOKEN_SLUGS = {
  EMISSIONS: 'EMISSIONS',
  ENERGY: 'ENERGY',
  FUEL: 'FUEL',
  TREES: 'TREES',
  WASTE: 'WASTE',
  WATER: 'WATER',
}

export const TOKEN_SLUG_IMPACT = {
  EMISSIONS: 'emissions-impact',
  ENERGY: 'energy-impact',
  FUEL: 'fuel-impact',
  TREES: 'trees-impact',
  WASTE: 'waste-impact',
  WATER: 'water-impact',
}
