import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'

const Button = ({
  id = null,
  children,
  className,
  disabled,
  onClick,
  text,
  type = 'button',
  variant = 'solid',
  to = null,
}) => {
  const variants = {
    neutral: 'p-3 bg-gallery border-[0.063rem] border-grayscale-2',
    outlined:
      'p-3 bg-white border-[0.063rem] border-smart-blue text-smart-blue',
    solid: 'p-3 text-white bg-smart-blue inline-block h-14',
  }

  const buttonClasses = cx(
    `rounded inline-block ${variants[variant]}`,
    { 'bg-opacity-40': disabled },
    className
  )

  const buttonContent = (
    <button
      id={id}
      className={buttonClasses}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children || text}
    </button>
  )

  return to ? <Link to={to}>{buttonContent}</Link> : buttonContent
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
}

export default Button
