// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import v from 'voca'
import { withFormsy } from 'formsy-react'

// WeSpire
import AutocompleteTextField from 'components/form/autocomplete_text_field'
import {
  emptyValue,
  suggestionPropType,
} from 'components/form/autocomplete_utils'
import TextFieldValidations from 'components/form/text_field_validations'

// Heavily inspired by https://material-ui.com/demos/autocomplete/#downshift
class Autocomplete extends Component {
  static propTypes = {
    className: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(suggestionPropType.isRequired),
    setInputRef: PropTypes.func,
    setValue: PropTypes.func,
    suggestions: PropTypes.arrayOf(suggestionPropType.isRequired).isRequired,
    textFieldProps: PropTypes.object,
    value: PropTypes.any,
  }

  static defaultProps = {
    className: '',
    multiple: false,
    selections: [],
    setInputRef: () => {},
    setValue: () => {},
    textFieldProps: {},
    value: '',
  }

  constructor(props) {
    super(props)

    // Only set selected items if they exist, since invoking setValue()
    // automatically prevents an input from being isPristine() == true
    if (props.selections.length > 0) {
      this.setSelected(props.selections)
    }
  }

  state = { inputValue: '' }

  getSuggestions = (inputValue) => {
    const { suggestions } = this.props
    const nonSelectedSuggestions = suggestions.filter(
      (suggestion) => !this.selectedItems().includes(suggestion)
    )

    return nonSelectedSuggestions.filter(
      (suggestion) =>
        v.isBlank(inputValue) ||
        suggestion.suggestionLabel
          .toLowerCase()
          .includes(inputValue.toLowerCase().trim())
    )
  }

  handleBackspace = () => {
    const selectedItems = this.selectedItems()

    this.setSelected(selectedItems.slice(0, selectedItems.length - 1))
  }

  handleDelete = (item) => () => {
    const selectedItems = this.selectedItems()

    selectedItems.splice(selectedItems.indexOf(item), 1)

    this.setSelected(selectedItems)
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value })
  }

  handleSelection = (item) => {
    let selectedItems = this.selectedItems()

    if (!selectedItems.includes(item)) {
      selectedItems = [...selectedItems, item]
    }

    this.setState({ inputValue: '' })
    this.setSelected(selectedItems)
  }

  idsPresent = (ids) => ids && ids !== emptyValue && ids.length > 0

  itemToString = (item) => (item ? item.label : '')

  selectedItems = () => {
    const { value, suggestions } = this.props
    const itemIds = value

    if (this.idsPresent(itemIds)) {
      return suggestions.filter((suggestion) =>
        itemIds.includes(`${suggestion.id}`)
      )
    } else {
      return []
    }
  }

  setSelected = (selectedItems) => {
    const { setValue } = this.props

    if (selectedItems.length > 0) {
      setValue(selectedItems.map((item) => `${item.id}`))
    } else {
      setValue(emptyValue)
    }
  }

  render() {
    const { inputValue } = this.state
    const { className, multiple, name, setInputRef, textFieldProps } =
      this.props
    const selectedItems = this.selectedItems()

    return (
      <TextFieldValidations {...this.props}>
        {(error, helperText, label) => (
          <AutocompleteTextField
            className={className}
            error={error}
            helperText={helperText}
            inputValue={inputValue}
            label={label}
            multiple={multiple}
            name={name}
            onBackspace={this.handleBackspace}
            onDelete={this.handleDelete}
            onInputChange={this.handleInputChange}
            onSelection={this.handleSelection}
            renderSuggestions={(renderSuggestion) =>
              this.getSuggestions(inputValue).map((suggestion, index) =>
                renderSuggestion(index, suggestion)
              )
            }
            selectedItems={selectedItems}
            setInputRef={setInputRef}
            textFieldProps={textFieldProps}
          />
        )}
      </TextFieldValidations>
    )
  }
}

export { Autocomplete }
export default withFormsy(Autocomplete)
