// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

// WeSpire
import { Button } from 'components/ui/button'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { WordForScore } from 'components/queries/word_for_score'

import { ScoreLog } from './score_log'
import { ScoreLogsFilters } from './filters'

const messages = defineMessages({
  emptyState: {
    defaultMessage:
      "No { wordForScore } activity matches the filter criteria you've applied. Please visit { takeActionLink } to join Activities to earn more { wordForScore }.",
    id: 'pointsStats.emptyState',
  },
  loadMore: {
    defaultMessage: 'See more history',
    id: 'pointsStats.loadMore',
  },
  logsLoading: {
    defaultMessage: 'Loading more score logs',
    id: 'pointsStats.logsLoading',
  },
  pointsHistory: {
    defaultMessage: '{ wordForScore } History',
    id: 'pointsStats.pointsHistory',
  },
})

export const PointsHistory = ({
  loading,
  hasNextPage,
  onFilterParamsChange,
  onLoadMoreClick,
  refetching,
  userScoreLogs,
}) => {
  const formatMessage = intl.formatMessage

  return (
    <Stack as="section" space={0}>
      <Heading className="px-3 mb-2" level={2}>
        {formatMessage(messages.pointsHistory, {
          wordForScore: <WordForScore score={2} wordOnly />,
        })}
      </Heading>

      <ScoreLogsFilters onFilterParamsChange={onFilterParamsChange} />

      <Paper>
        <Stack
          as="ol"
          className="list-none pl-0 w-100"
          divider="decorative"
          space={0}
        >
          {!refetching &&
            userScoreLogs.map((userScoreLog, index) => (
              <ScoreLog key={index} userScoreLog={userScoreLog} />
            ))}
          {userScoreLogs?.length === 0 && !refetching && (
            <p className="p-4" data-test="score-logs-empty-state">
              {intl.formatMessage(messages.emptyState, {
                takeActionLink: (
                  <Link className="text-blue" to="/take_action">
                    Take Action
                  </Link>
                ),
                wordForScore: <WordForScore score={2} wordOnly />,
              })}
            </p>
          )}
          {loading && (
            <CenteredPaddedLoadingIndicator
              padding="py-2"
              size={LoadingIndicatorSize.SMALL}
            />
          )}
        </Stack>
      </Paper>

      {hasNextPage && (
        <div className="d-flex">
          <Button
            className="flex-grow fs-2 text-black-2"
            data-test="load-more-score-logs"
            disabledWithFocus={loading}
            onClick={onLoadMoreClick}
            variant="neutral"
          >
            {formatMessage(messages.loadMore)}
          </Button>
        </div>
      )}
      {loading && (
        <ScreenReader
          role="status"
          text={formatMessage(messages.logsLoading)}
        />
      )}
    </Stack>
  )
}

PointsHistory.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onFilterParamsChange: PropTypes.func.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
  refetching: PropTypes.bool,
  userScoreLogs: PropTypes.array.isRequired,
}

PointsHistory.defaultProps = { refetching: false }
