import React, { useState, useEffect, useMemo } from 'react'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'

import DocumentTitle from 'src/common/DocumentTitle/DocumentTitle'
import LogYourHours from 'src/pages/VolunteeringHistory/components/LogYourHours'
import Tables from 'src/pages/VolunteeringHistory/components/Tables/Tables'
import DollarsForDoers from 'src/pages/VolunteeringHistory/components/DollarsForDoers'
import Heading from 'src/pages/VolunteeringHistory/components/Heading'
import YourImpact from 'src/pages/VolunteeringHistory/components/YourImpact'
import useVolunteeringQuery from './queries/useVolunteeringQuery'
import PageLoading from 'src/common/PageLoading'
import EmptyStateBox from 'src/common/EmptyStateBox'

const VolunteeringHistory = () => {
  const {
    data,
    loading,
    sortedVolunteerLogsThisYear,
    sortedVolunteerLogsAllTime,
    error = false,
  } = useVolunteeringQuery()
  const intl = useIntl()

  const {
    brandConfig: {
      d4dConfiguration: {
        grantDonationsEnabled,
        d4dProgramType = 'strict',
        d4dGrantAmount,
        d4dHoursUntilDonationEligible,
        d4dMaxDonationAmountPerYear,
      } = {},
    } = {},
    currentUser: {
      givingaUser = {},
      volunteeredIndividualHoursThisYear,
      volunteeredOrganizationsThisYear,
      volunteeredIndividualHoursAllTime,
      volunteeredOrganizationsAllTime,
    } = {},
  } = data ?? {}

  const allTime = useMemo(
    () => ({
      allTime: true,
      charityCount: volunteeredOrganizationsAllTime,
      totalVolunteerHours: volunteeredIndividualHoursAllTime,
    }),
    [data, volunteeredOrganizationsAllTime, volunteeredIndividualHoursAllTime]
  )

  const aYear = useMemo(
    () => ({
      allTime: false,
      charityCount: volunteeredOrganizationsThisYear || 0,
      totalVolunteerHours: volunteeredIndividualHoursThisYear || 0,
    }),
    [volunteeredIndividualHoursThisYear, volunteeredOrganizationsThisYear]
  )
  const [stats, setStats] = useState(aYear)

  useEffect(() => {
    if (!loading) {
      setStats(aYear)
    }
  }, [aYear, loading])

  const handleStatsChange = (newSelection) => {
    if (newSelection[0] === 'aYear') {
      setStats(aYear)
    } else {
      setStats(allTime)
    }
  }
  if (error) {
    console.log(error)
    return (
      <EmptyStateBox title="Content not available">
        <p className="text-practical-gray-2 mt-2 mb-6">
          <FormattedMessage
            id="errorPage.pageBody"
            defaultMessage="We encountered an unexpected error and could not load the content for this page."
          />
        </p>
      </EmptyStateBox>
    )
  }
  if (loading) return <PageLoading />

  return (
    <DocumentTitle
      title={intl.formatMessage({
        id: 'volunteering.volunteering',
        defaultMessage: 'Volunteering',
      })}
    >
      <main className="grid gap-y-6 mx-2 lg:mx-4 xl:mx-0 my-10">
        <Heading handleStatsChange={handleStatsChange} />
        <div
          className={cx('grid gap-y-10', {
            'xl:grid-cols-3 xl:gap-x-6': grantDonationsEnabled,
          })}
        >
          <YourImpact
            isStrict={d4dProgramType === 'strict'}
            allTimeHours={volunteeredIndividualHoursAllTime}
            allTimeOrganizations={volunteeredOrganizationsAllTime}
            grantDonationsEnabled={grantDonationsEnabled}
            setStats={setStats}
            stats={stats}
            thisYearHours={volunteeredIndividualHoursThisYear}
            thisYearOrganizations={volunteeredOrganizationsThisYear}
          />
          {grantDonationsEnabled && givingaUser && (
            <DollarsForDoers
              availableToDonate={givingaUser?.availableDonationFunds ?? 0}
              d4dGrantAmount={d4dGrantAmount}
              d4dHoursUntilDonationEligible={d4dHoursUntilDonationEligible}
              d4dMaxDonationAmountPerYear={d4dMaxDonationAmountPerYear}
              isFlexible={d4dProgramType === 'flexible'}
              grantDollarsEarned={givingaUser?.grantDollarsEarnedThisYear}
              grantDollarsDonated={givingaUser?.grantDollarsDonatedThisYear}
              remainingFundsThisYear={givingaUser?.remainingFundsThisYear}
              volunteerHoursThisYear={volunteeredIndividualHoursThisYear}
            />
          )}
        </div>
        <LogYourHours />
        <Tables
          d4dProgramType={d4dProgramType}
          data={data}
          sortedVolunteerLogsAllTime={sortedVolunteerLogsAllTime}
          sortedVolunteerLogsThisYear={sortedVolunteerLogsThisYear}
          stats={stats}
        />
      </main>
    </DocumentTitle>
  )
}

export default VolunteeringHistory
