// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'components/localization_provider'

const messages = defineMessages({
  currentItemLabel: {
    defaultMessage: 'Image {imageIndex} of {imagesCount}',
    description: `Label used in the gallery heading and in screen reader
      announcements to describe the total number of images in the gallery and
      which image is currently being viewed, e.g. 'Image 2 of 5'`,
    id: 'activityGalleryModalSharedTranslations.currentItemLabel',
  },
})

export const activityGalleryModalCurrentItemLabel = ({
  imageIndex,
  imagesCount,
}) =>
  intl.formatMessage(messages.currentItemLabel, {
    imageIndex: imageIndex + 1,
    imagesCount,
  })
