// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { AchievementImage } from 'components/achievement'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import Link from 'components/shared/link'
import WeQuery from 'components/application/we_query'

export const UserProfileAchievements = ({ userId }) => {
  const achievementsPath = '/achievements'

  const messages = defineMessages({
    achievementsEarned: {
      defaultMessage: 'Achievements Earned',
      id: 'userAchievements.achievementsEarned',
    },
    achievementsPathLink: {
      defaultMessage: 'Achievements page',
      id: 'userAchievements.achievementsPathLink',
    },
    noAchievementsDetails: {
      defaultMessage:
        'Visit the {achievementsLink} to find out more about how to complete one.',
      id: 'userAchievements.noAchievementsDetails',
    },
    noAchievementsMessage: {
      defaultMessage: "You haven't completed any Achievements",
      id: 'userAchievements.noAchievementsMessage',
    },
    queryErrorMessage: {
      defaultMessage: 'your Achievements',
      id: 'userAchievements.queryErrorMessage',
    },
    seeAllAchievements: {
      defaultMessage: 'See All Achievements',
      id: 'userAchievements.seeAllAchievements',
    },
  })
  const { formatMessage } = intl

  const emptyStateText = (
    <Stack className="text-center" data-test="no-achievements-message">
      <p className="fw-semi-bold lh-xl">
        {formatMessage(messages.noAchievementsMessage)}
      </p>
      <p className="lh-xl">
        {formatMessage(messages.noAchievementsDetails, {
          achievementsLink: (
            <Link
              className="text-link"
              data-test="no-achievements-link"
              key={achievementsPath}
              to={achievementsPath}
            >
              {formatMessage(messages.achievementsPathLink)}
            </Link>
          ),
        })}
      </p>
    </Stack>
  )
  const errorMessage = (
    <MissingResourceSection
      as={Paper}
      errorDetails={formatMessage(messages.queryErrorMessage)}
    />
  )
  const userAchievements = gql`
    query userAchievements($id: ID!) {
      user(id: $id) {
        achievements(orderByEarned: true) {
          name
          id
          imageUrl
        }
        id
      }
    }
  `

  return (
    <Stack as="section" space={3}>
      <Heading className="px-3" level={3} variant={2}>
        {formatMessage(messages.achievementsEarned)}
      </Heading>
      <WeQuery
        error={errorMessage}
        loader={<SkeletonLoading />}
        query={userAchievements}
        variables={{ id: userId }}
      >
        {({ data: { user } }) => {
          return (
            <Paper>
              <div
                className="[ d-flex align-items-center flex-wrap ] [ p-3 ]"
                data-test="achievements"
              >
                {user.achievements.length > 0 ? (
                  <div style={{ margin: '-0.25rem' }}>
                    {user.achievements.map((achievement) => (
                      <Link
                        aria-label={achievement.name}
                        data-test="achievement-link"
                        key={achievement.id}
                        to={`/achievements/${achievement.id}`}
                      >
                        <AchievementImage
                          achievementName={achievement.name}
                          className="m-1"
                          imageSrc={achievement.imageUrl}
                          key={achievement.id}
                        />
                      </Link>
                    ))}
                  </div>
                ) : (
                  emptyStateText
                )}
              </div>
              <Stack className="border-top p-3">
                <p>
                  <Link data-test="achievements-path" to={achievementsPath}>
                    {formatMessage(messages.seeAllAchievements)}
                  </Link>
                </p>
              </Stack>
            </Paper>
          )
        }}
      </WeQuery>
    </Stack>
  )
}

UserProfileAchievements.propTypes = {
  userId: PropTypes.string.isRequired,
}
