// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import PageSection from 'components/ui/page_section'

/** Extends <PageSection>. Content has transparent background, no elevation. */
const TransparentPageSection = (props) => (
  <PageSection
    {...props}
    paperProps={{
      ...props.paperProps,
      className: cx('bg-transparent', props.paperProps.className),
      elevation: 0,
    }}
  />
)

TransparentPageSection.propTypes = {
  /** props applied to <Paper> node */
  paperProps: PropTypes.object,
}

TransparentPageSection.defaultProps = {
  paperProps: {},
}

export default TransparentPageSection
