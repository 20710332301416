// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

// WeSpire
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { SustainabilityImpactStat } from 'components/shared/sustainability_impact_stat'
import { TakeActionLink } from 'components/activities/link'
import { UserNameQuery } from 'components/queries/username_query'
import WeQuery from 'components/application/we_query'

const messages = defineMessages({
  errorMessage: {
    defaultMessage: 'Sustainability Impact for you',
    id: 'userProfileSustainabilityImpact.errorMessage',
  },
  noSustainabilityStats: {
    defaultMessage:
      'You have not completed any Actions with a sustainability impact yet. Visit the {takeActionLink} to find some!',
    id: 'userProfileSustainabilityImpact.noSustainabilityStats',
  },
  otherUserErrorMessage: {
    defaultMessage:
      '{userName} has not completed any Actions with a sustainability impact yet.',
    id: 'userProfileSustainabilityImpact.otherUserErrorMessage',
  },
  sustainabilityImpact: {
    defaultMessage: 'Sustainability Impact',
    id: 'userProfileSustainabilityImpact.sustainabilityImpact',
  },
  yourActionsHaveBeenSaved: {
    defaultMessage: 'Your actions have saved',
    id: 'userProfileSustainabilityImpact.yourActionsHaveBeenSaved',
  },
})

export const UserProfileSustainabilityImpact = ({
  isCurrentUser,
  userId,
  ...other
}) => {
  const { formatMessage } = intl

  const errorMessage = (
    <MissingResourceSection
      as={Paper}
      errorDetails={formatMessage(messages.errorMessage)}
    />
  )

  return (
    <Stack {...other} as="section" data-test="sustainability-impact" space={3}>
      <Heading className="px-3" level={3} variant={2}>
        {formatMessage(messages.sustainabilityImpact)}
      </Heading>
      <WeQuery
        error={errorMessage}
        loader={<SkeletonLoading />}
        query={gql`
          query userSustainabilityImpacts($userId: ID!) {
            user(id: $userId) {
              id
              sustainabilityImpacts {
                equivalent
                impactValue
                name
                units
              }
            }
          }
        `}
        variables={{ userId: userId }}
      >
        {({ data: { user } }) => {
          if (!user) return errorMessage

          const impacts = user.sustainabilityImpacts

          const emptyStateText = isCurrentUser ? (
            <>
              {formatMessage(messages.noSustainabilityStats, {
                takeActionLink: <TakeActionLink data-test="take-action-link" />,
              })}
            </>
          ) : (
            <>
              {formatMessage(messages.otherUserErrorMessage, {
                userName: <UserNameQuery userId={user.id} />,
              })}
            </>
          )

          return (
            <Stack as={Paper} className="px-3 py-4" space={3}>
              {impacts.length > 0 ? (
                <>
                  <p className="fs-1 fw-semi-bold lh-xs ls-2 text-black-3 text-uppercase">
                    {formatMessage(messages.yourActionsHaveBeenSaved)}
                  </p>

                  <Stack divider="default" space={3}>
                    {impacts.map((impact) => (
                      <SustainabilityImpactStat
                        equivalent={impact.equivalent}
                        impactValue={impact.impactValue}
                        key={impact.name}
                        name={impact.name}
                        units={impact.units}
                      />
                    ))}
                  </Stack>
                </>
              ) : (
                <p data-test="no-sustainability-message">{emptyStateText}</p>
              )}
            </Stack>
          )
        }}
      </WeQuery>
    </Stack>
  )
}

UserProfileSustainabilityImpact.propTypes = {
  className: PropTypes.string,
  isCurrentUser: PropTypes.bool,
  userId: PropTypes.string.isRequired,
}

UserProfileSustainabilityImpact.defaultProps = {
  className: null,
  /** This is being passed as true until we setup the entire profile to be setup to view other users. **/
  isCurrentUser: true,
}
