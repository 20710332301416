import React from 'react'

import Layout from 'src/common/Layout'
import { SessionContextProvider } from 'src/contexts/SessionContext'
import { BreadcrumbContextProvider } from 'src/contexts/BreadcrumbContext'
import { ApolloProvider } from '@apollo/client'
import { client } from '../../utilities/we_apollo'
import { useHistory } from 'react-router-dom'

const App = ({ children, ...props }) => {
  const history = useHistory()

  React.useEffect(() => {
    if (history.location.pathname === '/app/undefined') {
      history.push('/take_action')
      window.location.reload()
    }
  }, [])
  return (
    <>
      <ApolloProvider client={client}>
        <SessionContextProvider>
          <BreadcrumbContextProvider>
            <Layout {...props}>{children}</Layout>
          </BreadcrumbContextProvider>
        </SessionContextProvider>
      </ApolloProvider>
    </>
  )
}

export default App
