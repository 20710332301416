import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  accept: {
    defaultMessage: 'Accept',
    id: 'termsOfServiceMessages.accept',
  },
  message: {
    defaultMessage:
      'I have read the above Terms of Service of the {brand} platform and acknowledge that I am either a U.S. resident who is at least 13 years old or a non-U.S. resident who is at least 16 years old. By clicking accept, I am also agreeing to the {privacy}.',
    id: 'termsOfServiceMessages.message',
  },
  termsAndPrivacyUpdated: {
    defaultMessage:
      'We have updated our Terms of Service and {privacy}. Please review and accept before continuing to use the {brand} platform.',
    id: 'termsOfServiceMessages.termsAndPrivacyUpdated',
  },
  termsUpdated: {
    defaultMessage:
      'We have updated our Terms of Service. Please review and accept before continuing to use the {brand} platform.',
    id: 'termsOfServiceMessages.termsUpdated',
  },
})

export const termsOfServiceMessages = formatMessages(messages)
