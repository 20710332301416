// Vendor
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

// WeSpire
import { hideModal } from 'redux/dispatchers'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Icon from 'components/ui/icon'

const { formatMessage } = intl
const messages = defineMessages({
  agree: {
    defaultMessage: 'Confirm',
    id: 'groupResourceFormConfirmationModal.agree',
  },
  checkboxLabel: {
    defaultMessage: 'Confirm Upload',
    id: 'groupResourceFormConfirmationModal.checkboxLabel',
  },
  closeDialog: {
    defaultMessage: 'Close',
    id: 'groupResourceFormConfirmationModal.closeDialog',
  },
  disclaimer: {
    defaultMessage:
      'I confirm that the file to upload does not contain confidential information.',
    id: 'groupResourceFormConfirmationModal.disclaimer',
  },
  title: {
    defaultMessage: 'Please confirm your file upload',
    id: 'groupResourceFormConfirmationModal.title',
  },
})

export const ConfirmationModal = ({ onConfirm }) => {
  const [checked, setChecked] = useState(false)
  return (
    <>
      <DialogTitle
        className="d-flex align-items-center justify-content-between"
        disableTypography
      >
        <Heading level={1} variant={2}>
          {formatMessage(messages.title)}
        </Heading>
        <IconButton onClick={hideModal}>
          <Icon
            className="fs-4 text-black-3"
            data-test="close-help-dialog"
            iconName="close"
            title={formatMessage(messages.closeDialog)}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent data-test="resource-confirmation-modal">
        {formatMessage(messages.disclaimer)}

        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              checkedIcon={<Icon iconName="checkbox_filled" title="Checked" />}
              color="primary"
              icon={<Icon iconName="checkbox_blank" title="Not Checked" />}
              inputProps={{ 'data-test': 'confirmation-checkbox' }}
              onChange={() => {
                setChecked(!checked)
              }}
            />
          }
          label={formatMessage(messages.checkboxLabel)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal}>
          {formatMessage(messages.closeDialog)}
        </Button>
        <Button
          color="primary"
          data-test="resource-confirmation-modal-button"
          disabled={!checked}
          onClick={() => {
            hideModal()
            onConfirm?.()
          }}
        >
          {formatMessage(messages.agree)}
        </Button>
      </DialogActions>
    </>
  )
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
}
