import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Field } from 'formik'

const Input = ({
  className,
  explainer,
  label,
  name,
  placeholder,
  type = 'text',
  ...other
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium">
          {label}
        </label>
      )}
      <Field
        id={name}
        className={cx(
          className,
          'h-14 text-black-1 placeholder-grayscale-1 border-[0.0625rem] border-grayscale-2 px-4 py-3 font-normal rounded-lg text-base font-light font-body mt-0'
        )}
        name={name}
        type={type}
        placeholder={placeholder}
        {...other}
      />
      {explainer && (
        <p className="ml-2 mt-2 text-sm leading-4 text-black-3 font-extralight">
          {explainer}
        </p>
      )}
    </>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  explainer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

Input.defaultProps = {
  className: '',
  explainer: null,
  label: null,
  placeholder: '',
  type: 'text',
}

export default Input
