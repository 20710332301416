// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  notJoinedMessage: {
    defaultMessage: 'Please join {groupName} to view this content',
    id: 'groupNotJoinedMessage.notJoinedMessage',
  },
})

const GroupNotJoinedMessage = ({ groupName }) => {
  return (
    <Paper className="[ text-center ] [ p-5 ]">
      <p data-test="not-joined">
        {formatMessage(messages.notJoinedMessage, {
          groupName: groupName,
        })}
      </p>
    </Paper>
  )
}

GroupNotJoinedMessage.propTypes = {
  groupName: PropTypes.string.isRequired,
}

export { GroupNotJoinedMessage }
