import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'

import Divider from 'src/common/Divider'
import InternalLink from 'src/common/InternalLink'
import WeSpireAPI from 'utilities/wespire_api'

const BackButton = ({ setMobileNavigation }) => (
  <button
    className="flex font-medium font-sans sm:hidden flex-row bg-gallery items-center justify-start leading-6 px-4 py-3 rounded-none shadow-[0_1px_0px#C4D1DB] text-sm"
    onClick={() => setMobileNavigation('mainLinks')}
  >
    <span className="material-symbols-rounded text-2xl">chevron_left</span>
    <span className="font-medium font-sans">Back</span>
  </button>
)

const SignOutButton = ({ className }) => {
  const onSignOut = () => {
    WeSpireAPI()
      .delete('/session')
      .then(({ data: { redirectUrl } }) => (window.location = redirectUrl))
      .catch(() => {
        displayExceptionBanner({ operation: 'sign out' })
      })
  }

  return (
    <Link
      className={cx(
        className,
        'font-medium hover:no-underline hover:text-smart-blue'
      )}
      key="signout"
      onClick={onSignOut}
    >
      Sign Out
    </Link>
  )
}

const DisconnectButton = ({ className }) => {
  const onDisconnect = () => {
    WeSpireAPI()
      .put('/session/disconnect')
      .then(({ data: { redirectUrl } }) => (window.location = redirectUrl))
      .catch(() => {
        displayExceptionBanner({ operation: 'disconnect' })
      })
  }

  return (
    <Link
      className={cx(
        className,
        'font-medium hover:no-underline hover:text-smart-blue'
      )}
      key="disconnect"
      onClick={onDisconnect}
    >
      Disconnect
    </Link>
  )
}

export const UserDetails = ({ avatarUrl, firstName }) => {
  return (
    <div className="flex flex-row items-center">
      <img
        className="border-2 border-helpful-orange-3 drop-shadow-[0px_1px_2px_#C4D1DB] h-10 w-10 hover:cursor-pointer mr-1 rounded-full"
        src={avatarUrl}
      />
      <span className="font-medium font-sans text-sm hover:cursor-pointer leading-6">
        {firstName}
      </span>
    </div>
  )
}

export const MainMobileMenu = ({
  avatarUrl,
  ergsEnabled,
  firstName,
  mainLinks,
  ourImpactEnabled,
  setMobileNavigation,
}) => {
  return (
    <>
      <button
        className="flex flex-row bg-gallery items-center justify-between px-4 py-3 rounded-none shadow-[0_1px_0px_#C4D1DB]"
        onClick={() => setMobileNavigation('subLinks')}
      >
        <UserDetails avatarUrl={avatarUrl} firstName={firstName} />
        <span className="material-symbols-rounded text-2xl">chevron_right</span>
      </button>
      {mainLinks(ergsEnabled, ourImpactEnabled).map((link) => (
        <>
          {link.href === '/take_action' ? (
            <button
              className="flex text-base justify-between px-4 py-8 rounded-none shadow-[0_1px_0px_#C4D1DB]"
              href={link.href}
              key={link.title}
              onClick={() => setMobileNavigation('channelLinks')}
            >
              <span className="self-center font-sans">{link.title}</span>
              <span className="material-symbols-rounded text-2xl">
                chevron_right
              </span>
            </button>
          ) : (
            <InternalLink
              className="font-sans hover:no-underline px-4 py-8 shadow-[0_1px_0px_#C4D1DB]"
              href={link.href}
              key={link.title}
              react={link.react}
            >
              {link.title}
            </InternalLink>
          )}
        </>
      ))}
    </>
  )
}

export const MobileChannelMenu = ({
  avatarUrl,
  channels,
  setMobileNavigation,
}) => {
  let linkStyles =
    'flex font-medium hover:no-underline items-center leading-6 px-4 py-6 shadow-[0_1px_0px_#C4D1DB]'
  let iconStyles = 'h-10 rounded-full w-10'
  let textStyles = 'font-medium font-sans leading-6 px-2 text-sm'

  return (
    <div className="flex flex-col text-base">
      <BackButton setMobileNavigation={setMobileNavigation} />
      {channels.map((channel) => (
        <InternalLink
          className={linkStyles}
          href={`/take_action/${channel.id}`}
          key={channel.id}
        >
          <img className={iconStyles} src={channel.logoImageUrl} />
          <span className={textStyles}>{channel.name}</span>
        </InternalLink>
      ))}
      <InternalLink
        className={linkStyles}
        href={`/take_action/currentUser?view=all`}
      >
        <img className={iconStyles} src={avatarUrl} />
        <span className={textStyles}>My Activities</span>
      </InternalLink>
    </div>
  )
}

export const OtherMenu = ({
  carbonFootprintEnabled,
  givingEnabled,
  isPointsDashboardEnabled,
  managementDropdownNavigationLinks,
  volunteerHistoryEnabled,
  otherLinks,
  setMobileNavigation,
}) => {
  return (
    <>
      <BackButton
        className="hidden sm:flex"
        setMobileNavigation={setMobileNavigation}
      />
      <div className="flex flex-col pb-4 px-4">
        {managementDropdownNavigationLinks.length > 0 && (
          <>
            {managementDropdownNavigationLinks.map((link) => (
              <InternalLink
                className="hidden sm:block font-medium hover:no-underline leading-6 pt-4 text-sm"
                href={link.href}
                key={link.title}
                react={false}
              >
                {link.title}
              </InternalLink>
            ))}
            <Divider className="hidden sm:block mb-0 mt-4 text-gray-2" />
          </>
        )}
        {otherLinks(
          carbonFootprintEnabled,
          givingEnabled,
          isPointsDashboardEnabled,
          volunteerHistoryEnabled
        ).map((link) => (
          <InternalLink
            className="font-medium leading-6 hover:no-underline pt-4 text-sm"
            href={link.href}
            key={link.title}
            react={link.react}
          >
            {link.title}
          </InternalLink>
        ))}
        <a
          className="font-medium leading-6 hover:no-underline pt-4 text-sm"
          href="mailto:support@wespire.com"
        >
          Support
        </a>
        <Divider className="mb-0 mt-4 text-gray-2" />
        {Cookies.get('masquerade') && (
          <DisconnectButton className="font-medium leading-6 pt-4 text-sm" />
        )}
        <SignOutButton className="font-medium leading-6 pt-4 text-sm" />
      </div>
    </>
  )
}
