import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { GRANT_DONATION_FORM_QUERY } from 'src/pages/DollarsForDoersForm/queries/queries'
import { REQUEST_DOLLARS_FOR_DOERS_DONATION } from 'src/pages/DollarsForDoersForm/mutations/mutations'
import DocumentTitle from 'src/common/DocumentTitle/DocumentTitle'
import DonationForm from './components/DonationForm'
import SkeletonLoading from 'src/common/SkeletonLoading'
import PageContainer from 'src/common/PageContainer'

const DollarsForDoersForm = () => {
  const { ein } = useParams()
  const [availableD4dFunds, setAvailableD4dFunds] = useState(null)
  const [donationNote, setNote] = useState('')
  const [anonymousDonation, setAnonymousDonation] = useState(false)
  const [errorsList, setErrorsList] = useState([])
  const [matching, setMatching] = useState(false)
  const intl = useIntl()
  const [inputValue, setInputValue] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [, setIsValid] = useState(false)
  const [submissionError, setSubmissionError] = useState(null)

  const { loading, error, data } = useQuery(GRANT_DONATION_FORM_QUERY, {
    variables: { ein },
  })

  const {
    brandConfig: {
      d4dConfiguration: {
        d4dHoursUntilDonationEligible,
        d4dGrantAmount,
        d4dProgramType,
      } = {},
    } = {},
    currentUser: { volunteerHoursThisYear } = {},
    charity,
    currentUser: {
      givingaUser: {
        grantDollarsDonatedThisYearFor,
        grantDollarsDonatedThisYear,
        grantDollarsEarnedThisYear,
        remainingFundsThisYear,
      } = {},
    } = {},
  } = data ?? {}
  const givingaUser = data?.currentUser?.givingaUser
  const [requestDollarsForDoersDonation] = useMutation(
    REQUEST_DOLLARS_FOR_DOERS_DONATION
  )
  /** Set when data loads-takes the hours the use has earned to calculate how many
   * grants have been completed and then calculates how much for grants are available
   * based on that **/

  const history = useHistory()

  useEffect(() => {
    if (!loading && data) {
      const overallFundsLeft =
        grantDollarsEarnedThisYear - grantDollarsDonatedThisYear
      const fundsLeft =
        overallFundsLeft < remainingFundsThisYear
          ? overallFundsLeft
          : remainingFundsThisYear
      // if it's strict we need to calculate the funds left specifically for that charity with which the user volunteered
      if (d4dProgramType === 'strict') {
        const fundCheck =
          Math.floor(volunteerHoursThisYear / d4dHoursUntilDonationEligible) *
          d4dGrantAmount
        let calculatedFunds =
          fundCheck < remainingFundsThisYear
            ? fundCheck
            : remainingFundsThisYear
        calculatedFunds = calculatedFunds - grantDollarsDonatedThisYearFor
        if (calculatedFunds < 0) {
          calculatedFunds = 0
        }
        setAvailableD4dFunds(calculatedFunds)
        setInputValue(availableD4dFunds || 0)
      }
      if (d4dProgramType === 'flexible') {
        fundsLeft < 0 || !fundsLeft
          ? setAvailableD4dFunds(0)
          : setAvailableD4dFunds(fundsLeft)
        setInputValue(availableD4dFunds || 0)
      }
    }
  }, [
    loading,
    data,
    availableD4dFunds,
    volunteerHoursThisYear,
    d4dHoursUntilDonationEligible,
    d4dGrantAmount,
  ])

  const handleSubmit = ({
    anonymousDonation,
    donationAmount,
    donationNote,
    matched,
  }) => {
    setIsSubmitting(true)

    requestDollarsForDoersDonation({
      variables: {
        amountCents: donationAmount * 100,
        anonymity: anonymousDonation ? 'Anonymous' : 'Full Transparency',
        ein: ein,
        givingaUserId: givingaUser.id,
        matched: matched,
        notes: donationNote || '',
      },
    })
      .then(
        ({
          data: {
            requestDollarsForDoersDonation: { errors, transaction },
          },
        }) => {
          setIsSubmitting(false)
          setErrorsList(errors)
          if (errors.length === 0) {
            console.log('success')
            setSubmissionError(false)
            history.push(`/app/donation_success_page/${transaction}`)
          } else {
            console.log('error')
            setSubmissionError(true)
          }
        }
      )
      .catch((e) => {
        setIsSubmitting(false)
        setSubmissionError(true)
      })
  }

  useEffect(() => {
    setErrorsList([])
  }, [])

  if (loading) return <SkeletonLoading />

  if (error) return <div>Error: {error.message}</div>

  if (!data?.brandConfig?.d4dConfiguration || inputValue === null) {
    return null
  }
  return (
    <DocumentTitle
      title={intl.formatMessage({
        defaultMessage: 'Make a donation',
        id: 'dollarsForDoersForm.makeADonation',
      })}
    >
      <PageContainer
        title={intl.formatMessage({
          defaultMessage: 'Make a donation',
          id: 'dollarsForDoersForm.makeADonation',
        })}
      >
        {availableD4dFunds !== null && (
          <DonationForm
            anonymousDonation={anonymousDonation}
            availableD4dFunds={availableD4dFunds}
            d4dConfiguration={data?.brandConfig?.d4dConfiguration}
            donationNote={donationNote}
            errorsList={errorsList}
            inputValue={inputValue}
            matching={matching}
            setMatching={setMatching}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            charity={charity}
            givingaUser={givingaUser}
            setAnonymousDonation={setAnonymousDonation}
            setNote={setNote}
            setInputValue={setInputValue}
            setIsValid={setIsValid}
            submissionError={submissionError}
          />
        )}
      </PageContainer>
    </DocumentTitle>
  )
}

export default DollarsForDoersForm
