// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

// Vendor
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import store from 'redux/store'
import v from 'voca'

// WeSpire
import { color, duration, fontWeight } from 'styles/variables'
import { LocalizationProvider } from 'components/localization_provider'
import Modal from 'components/application/modal'
import WeApollo from 'utilities/we_apollo'

const muiTheme = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: color.white,
      },
      containedSecondary: {
        color: color.white,
      },
      textPrimary: {
        color: color.blue,
      },
      textSecondary: {
        color: color.orange,
      },
    },
    MuiInputLabel: {
      outlined: {
        fontWeight: fontWeight.semiBold,
      },
      // When outlined label is focused or filled.
      shrink: {
        color: color['black-1'],
      },
    },
  },
  palette: {
    primary: {
      main: color.blue, // color="primary" (other variations calculated based on 'main')
    },
    secondary: {
      main: color.orange, // color="secondary" (other variations calculated based on 'main')
    },
  },
  typography: {
    fontFamily: '"Open Sans","Helvetica Neue",Arial,sans-serif',
    fontWeightBold: fontWeight.bold,
    fontWeightLight: fontWeight.light,
    fontWeightMedium: fontWeight.semiBold,
    fontWeightRegular: fontWeight.normal,
    useNextVariants: true,
  },
})

const SetupLayout = ({ children, displayNotification, notification }) => (
  <WeApollo>
    <LocalizationProvider>
      <Provider store={store}>
        <MuiThemeProvider theme={muiTheme}>
          <>
            <main id="main" role="main">
              {children}
            </main>
            <Snackbar
              autoHideDuration={duration.long}
              ContentProps={{
                'aria-describedby': 'app-notification',
              }}
              data-test="notification"
              message={<span id="app-notification">{notification}</span>}
              onClose={() => displayNotification('')}
              open={!v.isBlank(notification)}
            />
            <Modal />
          </>
        </MuiThemeProvider>
      </Provider>
    </LocalizationProvider>
  </WeApollo>
)

SetupLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  displayNotification: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired,
}

export default SetupLayout
