import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { Redirect } from 'react-router'

import TextHero from 'src/common/TextHero'
import Button from 'src/common/button/Button'
import Input from 'src/common/Input'
import Modal from 'src/common/Modal'
import SessionContext from 'src/contexts/SessionContext'

const ResetPasswordForm = () => {
  const [showModal, setShowModal] = useState(false)
  const { brand } = useContext(SessionContext)

  const handleSubmit = async (values) => {
    try {
      await axios.post('/passwords', { password: { email: values.email } })
      setShowModal(true)
    } catch (error) {
      console.error(error)
      alert('Error occurred while sending password reset email.')
    }
  }

  const intl = useIntl()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'loginMessages.invalidEmail',
          defaultMessage: 'Invalid email',
        })
      )
      .required(
        intl.formatMessage({
          id: 'loginMessages.emailRequired',
          defaultMessage: 'Email is required',
        })
      ),
  })
  if (brand?.authSchema === 'sso') {
    return <Redirect to="/app/landing/sso" />
  }
  return (
    <main className="grid justify-items-center content-start mt-20 pb-8">
      <TextHero
        subtitle={
          <FormattedMessage
            id="landingMessages.resetPasswordFormSubtitle"
            defaultMessage="Reset your password"
          />
        }
        title={
          <FormattedMessage
            id="landingMessages.resetPasswordFormTitle"
            defaultMessage={'Reset Password'}
          />
        }
      />
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, values }) => (
          <Form className="max-w-[37.5rem] w-11/12 px-4">
            <section className="flex flex-col mt-20">
              <div className="flex flex-col">
                <Input
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter your email"
                  value={values.email}
                />
                <ErrorMessage name="email">
                  {(msg) => <div className="text-error-red">{msg}</div>}
                </ErrorMessage>
                <Button className="mt-4 rounded-lg" type="submit">
                  <FormattedMessage
                    id="landingMessages.resetPassword"
                    defaultMessage={'Reset Password'}
                  />
                </Button>
                <span className="text-center text-base">
                  <FormattedMessage
                    id="loginMessages.needAccount"
                    defaultMessage={'Need to create an account?'}
                  />{' '}
                  <Link
                    className="text-smart-blue font-sans text-sm leading-6"
                    to="/app/landing"
                  >
                    <FormattedMessage
                      id="loginMessages.createAccount"
                      defaultMessage={'Create Account'}
                    />
                  </Link>
                </span>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      {showModal && (
        <Modal
          body={intl.formatMessage({
            id: 'landingMessages.resetPasswordConfirmation',
            defaultMessage:
              "We have sent an email to your inbox. Please click on the link in the email to reset your password. If you don't see the email, please check your spam folder.",
          })}
          setShowModal={() => setShowModal(false)}
          title="Check Your Email"
        />
      )}
    </main>
  )
}

export default ResetPasswordForm
