// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import Icon from 'components/ui/icon'
import sanitizeHtml from 'sanitize-html-react'

// WeSpire
import { intl } from 'utilities/localization'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'

const useStyles = makeStyles(() => ({
  accordionDetailsRoot: {
    padding: '0px',
  },
  accordionExpanded: {
    margin: '0px !important',
  },
  accordionRoot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
    boxShadow: 'none',
    marginBottom: '-15px',
  },
  accordionSummaryRoot: {
    padding: '0px',
  },
}))

const messages = defineMessages({
  hideCharityMission: {
    defaultMessage: 'Hide Charity Mission',
    id: 'charity.hideCharityMission',
  },
  learnMore: {
    defaultMessage: 'View Charity Mission',
    id: 'charity.learnMore',
  },
  matchingGiftAvailable: {
    defaultMessage: 'Matching Gift Available',
    id: 'charity.matchingGiftAvailable',
  },
})

const Charity = ({
  charity: {
    address: { city, country, state },
    ein,
    matched,
    name,
    tagLine,
    mission,
  },
  renderAction,
}) => {
  const [chevronDirection, setChevronDirection] = useState('chevron_down')
  const [accordionButtonText, setAccordionButtonText] = useState(
    intl.formatMessage(messages.learnMore)
  )
  const classes = useStyles()
  const setChevron = (chevron) => {
    if (chevron === 'chevron_down') {
      setAccordionButtonText(intl.formatMessage(messages.hideCharityMission))
      return setChevronDirection('chevron_up')
    }
    setAccordionButtonText(intl.formatMessage(messages.learnMore))
    setChevronDirection('chevron_down')
  }
  const strippedHtml = sanitizeHtml(mission, {
    allowedAttributes: {},
    allowedTags: [],
  })
  return (
    <li
      className="d-flex flex-column flex-sm-row justify-content-between"
      data-test="charity"
    >
      <div className="pr-3 py-3">
        <h3 className="fs-2 my-0 lh-sm">{name}</h3>
        {ein && <span className="mt-1 fs-1 text-black-3">{`${ein}`}</span>}
        {(city || state || country) && (
          <span className="mt-1 fs-1 text-black-3">
            {city ? `, ${city}` : ''}
            {state ? `, ${state}` : ''}
            {country ? `, ${country.displayName}` : ''}
          </span>
        )}
        {tagLine && <p className="fs-1 mt-2 my-0 text-italic">{tagLine}</p>}
        <GraphQLFeatureFlag
          error={null}
          featureDisabledError={null}
          featureName="donationMatching"
          loader={null}
        >
          {matched && (
            <p className="fs-1 mt-2 my-0 text-green">
              <span className="bg-green-1 d-inline-block p-1 rounded-circle " />{' '}
              {intl.formatMessage(messages.matchingGiftAvailable)}
            </p>
          )}
        </GraphQLFeatureFlag>

        {/* You can search for the "Fontenelle Forest" charity in order to see
        a charity with a mission. Many charities do not have one.*/}
        {mission && (
          <Accordion
            classes={{
              expanded: classes.accordionExpanded,
              root: classes.accordionRoot,
            }}
          >
            <AccordionSummary
              classes={{ root: classes.accordionSummaryRoot }}
              onClick={() => setChevron(chevronDirection)}
            >
              <div className="fs-1 text-blue">
                {accordionButtonText}
                <span>
                  <Icon
                    className="fs-2 position-absolute"
                    iconName={chevronDirection}
                    title=""
                  />
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <div className="fs-1 lh-lg">{strippedHtml}</div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div className="[ align-self-center ]">{renderAction}</div>
    </li>
  )
}

Charity.propTypes = {
  charity: PropTypes.object.isRequired,
  renderAction: PropTypes.object,
}

Charity.defaultProps = {
  renderAction: null,
}

export default Charity
