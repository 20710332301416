// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { defineMessages } from 'react-intl'

// WeSpire
import { activityGalleryModalCurrentItemLabel } from './utils'
import CenteredContent from 'components/application/centered_content'
import { UserNameQuery } from 'components/queries/username_query'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import {
  LoadingIndicator,
  LoadingIndicatorSize,
} from 'components/ui/loading_indicator'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  close: {
    defaultMessage: 'close gallery image',
    id: 'activityGalleryModalContent.close',
  },
  postedBy: {
    defaultMessage: 'Posted by {userName}',
    id: 'activityGalleryModalContent.postedBy',
  },
})

const ActivityGalleryModalContent = ({
  isLoadingMore,
  storyId,
  storyIndex,
  galleryNewsfeed,
  onClose,
  totalCount,
}) => {
  const { formatMessage } = intl

  const { imageUrl, message, userId } = galleryNewsfeed[storyIndex]
  const currentItemLabel = activityGalleryModalCurrentItemLabel({
    imageIndex: isLoadingMore ? storyIndex + 1 : storyIndex,
    imagesCount: totalCount,
  })

  return (
    <figure className="[ d-flex flex-column ] [ h-100 mx-0 position-relative ]">
      {/* Heading */}
      <div className="bg-black-1">
        <Stack
          as={CenteredContent}
          className="[ d-flex align-items-center ] [ px-3 py-2 ]"
        >
          <Heading
            className="fs-3 text-white"
            data-test="gallery-modal-heading"
            id={`story-${storyId}`}
            level={1}
          >
            {currentItemLabel}
            {!isLoadingMore && (
              <>
                <BulletSpacer />
                {formatMessage(messages.postedBy, {
                  userName: (
                    <b key={userId}>
                      <UserNameQuery
                        format="abbreviated"
                        userId={parseInt(userId)}
                      />
                    </b>
                  ),
                })}
              </>
            )}
          </Heading>

          <Button className="ml-auto text-white" onClick={onClose}>
            <Icon
              className="fs-6 text-white"
              data-test="close"
              iconName="close"
              title={formatMessage(messages.close)}
            />
          </Button>
        </Stack>
      </div>

      {isLoadingMore && (
        <div className="[ gallery-image ] [ text-white ]">
          <LoadingIndicator color="inherit" size={LoadingIndicatorSize.LARGE} />
        </div>
      )}

      {!isLoadingMore && (
        <>
          {/* Image */}
          <div className="gallery-image">
            <img
              alt=""
              className="gallery-image__img"
              data-test={`gallery-image-for-story-${storyId}`}
              src={imageUrl}
            />
          </div>

          {/* Caption */}
          {message ? (
            <Stack className="bg-black-1">
              <CenteredContent
                as="figcaption"
                className="fs-3 lh-md px-3 py-4 text-white"
                data-test="image-caption"
              >
                {message}
              </CenteredContent>
            </Stack>
          ) : (
            <div
              aria-hidden
              className="invisible py-4"
              data-test="no-image-caption"
            >
              empty space
            </div>
          )}
        </>
      )}
    </figure>
  )
}

ActivityGalleryModalContent.propTypes = {
  galleryNewsfeed: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      message: PropTypes.string,
      userId: PropTypes.string.isRequired,
    })
  ).isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  storyId: PropTypes.string,
  storyIndex: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
}

ActivityGalleryModalContent.defaultProps = {
  storyId: null,
  storyIndex: null,
}

export { ActivityGalleryModalContent }
