// Vendor
import React from 'react'
import cx from 'classnames'
import { useQueryParams, BooleanParam } from 'use-query-params'

// Wespire
import CenteredContent from 'components/application/centered_content'
import WeQuery from 'components/application/we_query'
import { DocumentTitle } from 'components/shared/document_title'
import { sharedTranslations } from 'components/shared/translations'
import { Heading } from 'components/ui/Typography/Heading'
import { Label } from 'components/ui/Typography/Label'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { Surface } from 'components/ui/Surface'
import { abbreviatedDate } from 'utilities/date_formatter'
import { AcceptTermsOfService } from './components/AcceptTermsOfService'
import { termsOfServiceQuery } from './utilities/queries'
import { MarkdownText } from 'components/ui/Typography/MarkdownText'

const TermsOfService = () => {
  const [{ onboarding }] = useQueryParams({ onboarding: BooleanParam })
  return (
    <DocumentTitle title={sharedTranslations.termsOfService}>
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={sharedTranslations.termsOfService}
          />
        }
        loader={<SkeletonLoading className="my-2" height={245} />}
        query={termsOfServiceQuery}
      >
        {({
          data: {
            brand: { name },
            currentPrivacyPolicy,
            currentTermsOfService,
            currentUser,
          },
        }) => {
          return (
            <>
              <CenteredContent
                className={cx({
                  'pb-8': currentUser?.missingTermsOfServiceAcceptance,
                })}
              >
                <Surface
                  className={cx('my-4', {
                    'mb-6': currentUser?.missingTermsOfServiceAcceptance,
                  })}
                  dataTest="terms-of-service-details"
                >
                  <Heading data-test="page-name" level={1}>
                    {`${name} ${sharedTranslations.termsOfService}`}
                  </Heading>
                  <Label
                    className="text-black-1"
                    data-test="last-update"
                    uppercase
                  >
                    {`${sharedTranslations.lastUpdated} ${abbreviatedDate(
                      currentTermsOfService.createdAt
                    )}`}
                  </Label>
                  <MarkdownText className="py-4">
                    {currentTermsOfService.text}
                  </MarkdownText>
                </Surface>
              </CenteredContent>
              {!onboarding && (
                <AcceptTermsOfService
                  brand={name}
                  hasAcceptedPreviousDocuments={
                    currentUser?.brandLegalAcceptances.totalCount > 0
                  }
                  privacyPolicyId={
                    currentUser?.missingPrivacyPolicyAcceptance
                      ? currentPrivacyPolicy.id
                      : null
                  }
                  termsOfServiceId={
                    currentUser?.missingTermsOfServiceAcceptance
                      ? currentTermsOfService.id
                      : null
                  }
                />
              )}
            </>
          )
        }}
      </WeQuery>
    </DocumentTitle>
  )
}

export { TermsOfService }
