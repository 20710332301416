import React from 'react'
import { FormattedMessage } from 'react-intl'

// westyle
import InternalLink from 'src/common/InternalLink'
import Numbers from 'src/common/Numbers'
import ProgressVisual from 'src/pages/DonationHistoryPage/components/ProgressVisual'

const MatchTransactionDetails = ({ transaction }) => {
  const renderMatchStatus = () => {
    if (transaction.matchedTransaction.status === 'declined') {
      return (
        <span>
          <FormattedMessage
            id="donationHistory.'matchDeclined"
            defaultMessage="Match declined {link}"
            values={{
              link: (
                <InternalLink className="link-text-small-underlined" href="#">
                  <FormattedMessage
                    id="shared.learnedMore"
                    defaultMessage="Learn more"
                  />
                </InternalLink>
              ),
            }}
          />
        </span>
      )
    }

    if (transaction.matchedTransaction.status === 'complete') {
      const formattedDate = new Date(
        transaction.matchedTransaction.clearedDate
      ).toLocaleDateString('en-US')
      return (
        <span className="body-medium-semibold">
          <FormattedMessage
            id="donationHistory.paidToCharityOn"
            defaultMessage="paid to charity on {date}"
            values={{
              date: formattedDate,
            }}
          />
        </span>
      )
    }

    return (
      <ProgressVisual match status={transaction.matchedTransaction.status} />
    )
  }

  return (
    <>
      <div>
        <span className="body-medium-semibold text-black-3">Match status:</span>
      </div>
      <div className="flex flex-col xl:flex-row content-center">
        <div className="inline content-center">
          <Numbers
            className="body-medium-semibold pr-2 align-middle"
            currency="USD"
            style="currency"
          >
            {transaction.matchedTransaction.matchedAmount}
          </Numbers>
        </div>
        <div className="inline flex flex-col md:flex-row content-center">
          {renderMatchStatus()}
        </div>
      </div>
    </>
  )
}

export default MatchTransactionDetails
