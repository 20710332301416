// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedNumber } from 'react-intl'
import gql from 'graphql-tag'
import InputAdornment from '@material-ui/core/InputAdornment'

// WeSpire
import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  maybeDisplayUserTour,
} from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import FormActionBar from 'components/form/action_bar'
import FormCheckbox from 'components/form/checkbox'
import { FormDetail, FormDetails } from 'components/form/form_details'
import FormLimitedTextField from 'components/form/limited_text_field'
import FormFieldset from 'components/form/fieldset'
import { FormPage } from 'components/form/form_page'
import FormTextField from 'components/form/text_field'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { intl } from 'utilities/localization'
import ScreenReaderText from 'components/ui/screen_reader_text'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'
import { DocumentTitle } from 'components/shared/document_title'
import { CharityNotFoundPage } from 'components/donations/pages/charity_not_found'
import { DonationAccountNotFoundPage } from 'components/donations/pages/donation_account_not_found'
import { PageQuery } from 'components/queries/page_query'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'

const messages = defineMessages({
  anonymousDonation: {
    defaultMessage: 'Donate anonymously',
    id: 'grantDonationForm.anonymousDonation',
  },
  anonymousDonationHelperText: {
    defaultMessage:
      'If checked, your name and email address will be withheld from the charity.',
    id: 'grantDonationForm.anonymousDonationHelperText',
  },
  availableDonationFunds: {
    defaultMessage: 'Available Donations Funds',
    id: 'grantDonationForm.availableDonationFunds',
  },
  details: {
    defaultMessage: 'details',
    id: 'grantDonationForm.details',
  },
  donationAmount: {
    defaultMessage: 'Dollars for Doers Donation Amount',
    id: 'grantDonationForm.donationAmount',
  },
  donationNoteHelperText: {
    defaultMessage:
      "Use this section to share additional information with the charity, such as an honoree, purpose, or another friendly note why you're donating.",
    id: 'grantDonationForm.donationNoteHelperText',
  },
  donationNoteLabel: {
    defaultMessage: 'Share a note with the charity',
    id: 'grantDonationForm.donationNoteLabel',
  },
  eligibleVolunteerHours: {
    defaultMessage: 'Eligible Volunteer Hours',
    id: 'grantDonationForm.eligibleVolunteerHours',
  },
  fundsInformation: {
    defaultMessage:
      'You have <bold>{availableDonationFunds}</bold> remaining of the <bold>{donationFundsCap}</bold> annual limit per employee.',
    id: 'grantDonationForm.fundsInformation',
  },
  helperText: {
    defaultMessage:
      'YYou must have at least {hours} and {minimumAmount} in available donation funds in order to request a Dollars for Doers donation.',
    id: 'grantDonationForm.helperText',
  },
  operation: {
    defaultMessage: 'continue this donation',
    id: 'grantDonationForm.operation',
  },
  pageTitle: {
    defaultMessage: 'Request a Dollars for Doers donation',
    id: 'grantDonationForm.pageTitle',
  },
  requestSuccess: {
    defaultMessage: 'Your Dollars for Doers donation request was submitted.',
    id: 'grantDonationForm.requestSuccess',
  },
  volunteerInformation: {
    defaultMessage:
      'You have volunteered a total of <bold>{totalHours}</bold> this year for {charityName} and we have already used <bold>{usedHours}</bold> to fulfill previous Dollars for Doers donations. Therefore, you have <bold>{eligibleHours}</bold> for this donation.',
    id: 'grantDonationForm.volunteerInformation',
  },
  yourDonation: {
    defaultMessage: 'your donation',
    id: 'grantDonationForm.yourDonation',
  },
  yourDonationFunds: {
    defaultMessage: 'Your Available Donations Funds',
    id: 'grantDonationForm.yourDonationFunds',
  },
  yourEligibleHours: {
    defaultMessage: 'your eligible volunteer hours',
    id: 'grantDonationForm.yourEligibleHours',
  },
})

const grantDonationFormQuery = gql`
  query grantDonationFormQuery($ein: String!) {
    charity(ein: $ein) {
      id
      matched
      name
    }

    currentUser {
      givingaUser {
        availableDonationFunds
        donationFundsCap
        id
      }
      grantEligibleVolunteerHours(ein: $ein)
      id
      volunteerHoursThisYear(ein: $ein)
      donationNotesLength
    }
  }
`
const requestGrantDonation = gql`
  mutation requestGrantDonation(
    $anonymity: String!
    $ein: String!
    $notes: String
  ) {
    requestGrantDonation(
      anonymity: $anonymity
      ein: $ein
      notes: $notes
    ) {
      errors
    }
  }
`

const GrantDonationForm = ({ history, match }) => {
  const { ein } = match.params
  const { formatMessage } = intl
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = ({ anonymousDonation, donationNote }) => {
    setIsSubmitting(true)
    client
      .mutate({
        mutation: requestGrantDonation,
        variables: {
          anonymity: anonymousDonation ? 'Anonymous' : 'Full Transparency',
          ein: ein,
          notes: donationNote || '',
        },
      })
      .then(
        ({
          data: {
            requestGrantDonation: { errors },
          },
        }) => {
          setIsSubmitting(false)
          if (errors.length === 0) {
            displayBanner({
              content: intl.formatMessage(messages.requestSuccess),
              variant: 'success',
            })
            history.push('/users/me/donation_history')
          } else {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: intl.formatMessage(messages.operation),
        })
      })
  }

  const renderDetailsSection = (
    charity,
    availableDonationFunds,
    donationFundsCap,
    eligibleHours,
    totalHours
  ) => (
    <FormFieldset label={intl.formatMessage(messages.details)}>
      <FormDetails>
        <FormDetail label={sharedTranslations.charityName}>
          {charity.name}
        </FormDetail>
        <FormDetail label={intl.formatMessage(messages.availableDonationFunds)}>
          <TextWithHelpIconModal
            modalContent={
              <p>
                {intl.formatMessage(messages.fundsInformation, {
                  availableDonationFunds: (
                    <FormattedNumber
                      currency="USD"
                      style="currency"
                      value={availableDonationFunds}
                    />
                  ),
                  bold: (str) => <b>{str}</b>,
                  donationFundsCap: (
                    <FormattedNumber
                      currency="USD"
                      style="currency"
                      value={donationFundsCap}
                    />
                  ),
                })}
              </p>
            }
            text={
              <FormattedNumber
                currency="USD"
                style="currency"
                value={availableDonationFunds}
              />
            }
            title={intl.formatMessage(messages.yourDonationFunds)}
          />
        </FormDetail>
        <FormDetail label={intl.formatMessage(messages.yourEligibleHours)}>
          <TextWithHelpIconModal
            modalContent={
              <p>
                {intl.formatMessage(messages.volunteerInformation, {
                  bold: (str) => <b>{str}</b>,
                  charityName: charity.name,
                  eligibleHours: intl.formatMessage(
                    sharedMessages.eligibleHoursWithCount,
                    { count: eligibleHours }
                  ),
                  totalHours: intl.formatMessage(
                    sharedMessages.hoursWithCount,
                    { count: totalHours }
                  ),
                  usedHours: intl.formatMessage(sharedMessages.hoursWithCount, {
                    count: totalHours - eligibleHours,
                  }),
                })}
              </p>
            }
            text={<FormattedNumber value={eligibleHours} />}
            title={intl.formatMessage(messages.eligibleVolunteerHours)}
          />
        </FormDetail>
      </FormDetails>
    </FormFieldset>
  )

  const renderYourDonationSection = (
    availableDonationFunds,
    eligibleHours,
    donationNotesLength
  ) => (
    <FormFieldset label={intl.formatMessage(messages.yourDonation)}>
      {/**
       * TODO: we would like to make this readonly, not disabled, but could
       * not get MUI to do it at the time.
       */}
      <FormTextField
        name="donationAmount"
        required
        textFieldProps={{
          disabled: true,
          helperText: intl.formatMessage(messages.helperText, {
            hours: intl.formatMessage(sharedMessages.eligibleHoursWithCount, {
              count: 25,
            }),
            minimumAmount: (
              <FormattedNumber currency="USD" style="currency" value={250} />
            ),
          }),
          InputProps: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          },
          label: (
            <>
              {intl.formatMessage(messages.donationAmount)}
              <ScreenReaderText text=" in U.S. dollars" />
            </>
          ),
          type: 'number',
        }}
        validations={{
          sufficientFunds: ({ donationAmount }, value) => {
            if (
              value &&
              donationAmount &&
              parseFloat(donationAmount) > availableDonationFunds
            ) {
              return intl.formatMessage(messages.helperText, {
                hours: intl.formatMessage(
                  sharedMessages.eligibleHoursWithCount,
                  { count: 25 }
                ),
                minimumAmount: (
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={250}
                  />
                ),
              })
            } else {
              return true
            }
          },
          sufficientHours: () => {
            if (eligibleHours < 25) {
              return intl.formatMessage(messages.helperText, {
                hours: intl.formatMessage(
                  sharedMessages.eligibleHoursWithCount,
                  { count: 25 }
                ),
                minimumAmount: (
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={250}
                  />
                ),
              })
            } else {
              return true
            }
          },
        }}
        value={250}
      />
      <FormLimitedTextField
        helperText={intl.formatMessage(messages.donationNoteHelperText)}
        label={intl.formatMessage(messages.donationNoteLabel)}
        maxLength={donationNotesLength}
        multiline
        name="donationNote"
        textFieldProps={{
          inputProps: {
            'aria-describedby': 'notes-helper',
          },
        }}
      />
      <FormCheckbox
        helperText={intl.formatMessage(messages.anonymousDonationHelperText)}
        labelProps={{
          label: intl.formatMessage(messages.anonymousDonation),
        }}
        name="anonymousDonation"
      />
    </FormFieldset>
  )

  return (
    <GraphQLFeatureFlag featureName="grantDonation">
      <PageQuery query={grantDonationFormQuery} variables={{ ein }}>
        {({
          data: {
            charity,
            currentUser: {
              givingaUser,
              grantEligibleVolunteerHours,
              volunteerHoursThisYear,
              donationNotesLength,
            },
          },
        }) => {
          if (!givingaUser) {
            return <DonationAccountNotFoundPage />
          }

          if (!charity) {
            return <CharityNotFoundPage />
          }

          return (
            <DocumentTitle title={formatMessage(messages.pageTitle)}>
              {maybeDisplayUserTour('grantDonationFormPage')}

              <FormPage
                heading={formatMessage(messages.pageTitle)}
                onValidSubmit={(model) => handleSubmit(model)}
              >
                {renderDetailsSection(
                  charity,
                  givingaUser.availableDonationFunds,
                  givingaUser.donationFundsCap,
                  grantEligibleVolunteerHours,
                  volunteerHoursThisYear
                )}
                {renderYourDonationSection(
                  givingaUser.availableDonationFunds,
                  grantEligibleVolunteerHours,
                  donationNotesLength
                )}
                <FormActionBar hideBack isSubmitting={isSubmitting} />
              </FormPage>
            </DocumentTitle>
          )
        }}
      </PageQuery>
    </GraphQLFeatureFlag>
  )
}

GrantDonationForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ ein: PropTypes.string }).isRequired,
  }).isRequired,
}

export default GrantDonationForm
