// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { intl } from 'utilities/localization'
import QueryAutocomplete from '/components/form/query_autocomplete'

const organizationsQuery = gql`
  query brandOrganizations($filter: String) {
    brandOrganizations(filter: $filter) {
      id
      name
    }
  }
`

const messages = defineMessages({
  name: {
    defaultMessage: 'name',
    id: 'brandOrganizationAutocomplete.name',
  },
})

/**
 * An autocomplete field for selecting a "custom" organization.
 * A custom organization is a user created organization and not an
 * IRS charitable organization.
 */
export const BrandOrganizationAutocomplete = ({ ...other }) => {
  const { formatMessage } = intl

  return (
    <QueryAutocomplete
      {...other}
      query={organizationsQuery}
      queryName="brandOrganizations"
      queryVariableMapping={{
        query: 'filter',
      }}
      suggestionMapping={{
        id: 'id',
        label: formatMessage(messages.name),
      }}
    />
  )
}
