export const mainLinks = (ergsEnabled, ourImpactEnabled) => {
  let links = [
    {
      href: '/dashboard',
      react: false,
      title: 'Dashboard',
    },
    {
      href: '/take_action',
      react: true,
      title: 'Take Action',
    },
  ]
  ourImpactEnabled &&
    links.push({ href: '/our_impact', react: true, title: 'Our Impact' })
  ergsEnabled
    ? links.push({ href: '/groups', react: true, title: 'Groups' })
    : links.push({ href: '/teams', react: true, title: 'Teams' })

  return links
}

export const otherLinks = (
  carbonFootprintEnabled,
  givingEnabled,
  isPointsDashboardEnabled,
  volunteerHistoryEnabled
) => {
  let links = []

  {
    carbonFootprintEnabled &&
      links.push({
        href: '/carbon_footprint',
        react: true,
        title: 'Carbon Footprint',
      })
  }
  {
    givingEnabled &&
      links.push({
        href: '/users/me/donation_history',
        react: true,
        title: 'Donation History',
      })
  }
  {
    volunteerHistoryEnabled &&
      links.push({
        href: '/users/me/volunteer_history',
        react: true,
        title: 'Volunteer History',
      })
  }
  {
    isPointsDashboardEnabled &&
      links.push({
        href: '/users/me/points_history',
        react: true,
        title: 'Points History',
      })
  }

  links.push(
    {
      href: '/profile',
      react: true,
      title: 'My Profile',
    },
    {
      href: '/achievements',
      react: false,
      title: 'Achievements',
    },
    {
      href: '/blogs',
      react: false,
      title: 'Broadcasts',
    },
    {
      href: '/invites/new',
      react: false,
      title: 'Invite',
    },
    {
      href: '/user/edit',
      react: false,
      title: 'Settings',
    }
  )

  return links
}
