// Setup
import React from 'react'

// WeSpire
import { AnalyticsManager } from 'utilities/analytics/'

// If `target` parameter is passed, it will be used instead of `window`
// Example usage:
// @scrollTracker({ eventPrefix: 'Comments' })
// class IdeaBoard extends React.Component {
//   ...
// OR
//   ...
// export default scrollTracker({
//   eventPrefix: 'Comments'
// })(IdeaBoard)
//

const scrollTracker =
  ({ eventPrefix, target }) =>
  (WrappedComponent) =>
    class ScrollTracker extends React.Component {
      // eslint-disable-next-line react/sort-comp
      emitted = false
      target = null

      componentDidMount() {
        this.target = target ? document.querySelector(target) : window
        this.target.addEventListener('scroll', this.onScroll)
      }

      componentWillUnmount() {
        this.target.removeEventListener('scroll', this.onScroll)
      }

      onScroll = () => {
        if (this.emitted === true) {
          return
        }

        let scrolledToBottom

        // If target is specified meaning it's not a `window`
        if (target) {
          const {
            offsetHeight, // Height of the target
            scrollHeight, // Total height of the scrollable target's inner content
            scrollTop, // Current scroll position within an overflowable target
          } = this.target

          // When user scrolls down the target, the top edge of the target
          // is the total scrollable height - the height of the target
          const topBoundaryOfScrolledTarget = scrollHeight - offsetHeight

          scrolledToBottom = scrollTop === topBoundaryOfScrolledTarget
        } else {
          // Because of Mac behaviour - https://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
          const tolerance = 10

          // Height of the window + current vertical scroll position has to
          // be same or bigger than the total scrollable content height of body
          scrolledToBottom =
            window.innerHeight + window.scrollY >=
            document.body.scrollHeight - tolerance
        }

        if (scrolledToBottom) {
          AnalyticsManager.scrolledToTheBottom(eventPrefix)

          this.emitted = true
        }
      }

      render = () => <WrappedComponent {...this.props} />
    }

export default scrollTracker
