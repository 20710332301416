// Vendor
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import {
  LoadingIndicator,
  LoadingIndicatorSize,
} from 'components/ui/loading_indicator'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'

const { formatMessage } = intl

const messages = defineMessages({
  imageUploadIconLabel: {
    defaultMessage: 'Upload { fileType }',
    id: 'editableImageIcon.imageUploadIconLabel',
  },
  imageUploading: {
    defaultMessage: '{ fileType } Uploading',
    id: 'editableImageIcon.imageUploading',
  },
})

// 1. Allow the <Button> shadow to be visible instead of the <Paper> shadow
const showButtonShadow = 'overflow-visible' // 1

const EditableImageIcon = ({
  customHeight,
  customWidth,
  disabled,
  isDocument,
  isLoading,
  isPristine,
  customIconName,
  name,
  onClick,
  required,
  shape,
}) => (
  <Paper
    className={cx(showButtonShadow, {
      'aspect-ratio': !!shape,
      'aspect-ratio--16-9 rounded': shape === 'rectangle',
    })}
    elevation={0} // 1
  >
    <Button
      aria-describedby={`${name}-helper`}
      aria-labelledby={`${name}-label`}
      className={cx('content-center p-0', {
        'aspect-ratio__content position-absolute': !!shape,
        'cursor-no-drop': disabled,
        'cursor-pointer': !disabled,
        'rounded-circle': shape === 'circle',
      })}
      onClick={onClick}
      style={{ height: customHeight, width: customWidth }}
      variant="outlined"
    >
      {isLoading ? (
        <LoadingIndicator
          size={LoadingIndicatorSize.SMALL}
          title={formatMessage(messages.imageUploading, {
            fileType: isDocument ? 'File' : 'Image',
          })}
        />
      ) : (
        <span
          className={cx(
            'd-inline-flex | bg-black-3 fs-5 p-2 rounded-circle text-white z-2',
            { 'bg-red': required && !isPristine }
          )}
        >
          <Icon
            iconName={customIconName}
            title={formatMessage(messages.imageUploadIconLabel, {
              fileType: isDocument ? 'File' : 'Image',
            })}
          />
        </span>
      )}
      {required && (
        <Fragment>
          <span
            className={cx('absolute-top-right pr-1 text-black-3', {
              'text-red': !isPristine,
            })}
            data-test="asterisk"
          >
            *
          </span>
          {!isPristine && (
            <div
              className="d-flex align-items-center justify-content-center | absolute-bottom-left fs-1 mb-1 text-lowercase text-red w-100"
              data-test="required"
            >
              <Icon className="fs-1 mr-1" iconName="warning" title="" />
              {sharedTranslations.required}
            </div>
          )}
        </Fragment>
      )}
    </Button>
  </Paper>
)

EditableImageIcon.propTypes = {
  customHeight: PropTypes.string,
  customIconName: PropTypes.string.isRequired,
  customWidth: PropTypes.string,
  disabled: PropTypes.bool,
  isDocument: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPristine: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  required: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'rectangle', 'square']),
}

EditableImageIcon.defaultProps = {
  customHeight: undefined,
  customWidth: '100%',
  disabled: false,
  isDocument: false,
  isLoading: false,
  isPristine: true,
  required: false,
  shape: null,
}

export default EditableImageIcon
