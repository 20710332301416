// Vendor
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import FormActionBar from 'components/form/action_bar'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import LinkButton from 'components/ui/link_button'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'
import { Heading } from 'components/ui/heading'
import { editGuestPath, newGuestPath } from 'components/events/routes'

const messages = defineMessages({
  another: {
    defaultMessage: 'another',
    id: 'eventGuestBox.another',
  },
  description: {
    defaultMessage:
      'Register your family and friends for this Event. If your guest is an employee, please send them an invite instead.',
    id: 'eventGuestBox.description',
  },
  editGuestRegistration: {
    defaultMessage: 'Edit guest registration',
    id: 'eventGuestBox.editGuestRegistration',
  },
  errorDetails: {
    defaultMessage: 'your guest registration',
    id: 'eventGuestBox.errorDetails',
  },
  header: {
    defaultMessage: 'Guest Registration',
    id: 'eventGuestBox.header',
  },
  registerGuest: {
    defaultMessage: 'Register {another} Guest',
    description:
      "Text for button that lets users register their first guest to an event, or an additional guest, if they've already registered a guest. If they've already registered a guest, the word 'another' will be displayed in the English translation.",
    id: 'eventGuestBox.registerGuest',
  },
  yourGuests: {
    defaultMessage: 'your guests',
    id: 'eventGuestBox.yourGuests',
  },
})

export const PARTICIPATION_GUESTS_QUERY = gql`
  query shiftTimeframe($id: ID!) {
    shiftTimeframe(id: $id) {
      id
      registrationEnded

      participationGuests {
        id
        firstName
        lastName
        email

        shift {
          id
          shiftFunction
        }
      }
    }
  }
`

export const EventGuestBox = ({ eventId, shiftId }) => (
  <Paper className="mt-4 pb-5 pt-4 px-3 | px-sm-4">
    <WeQuery
      error={
        <MissingResourceSection
          errorDetails={intl.formatMessage(messages.errorDetails)}
        />
      }
      loader={<CenteredPaddedLoadingIndicator />}
      query={PARTICIPATION_GUESTS_QUERY}
      variables={{ id: shiftId }}
    >
      {({ data: { shiftTimeframe } }) => {
        const anotherGuest = hasGuests
          ? intl.formatMessage(messages.another)
          : ''
        const guests = shiftTimeframe.participationGuests
        const hasGuests = guests.length > 0

        return (
          <Fragment>
            <Heading level={2}>{intl.formatMessage(messages.header)}</Heading>

            <p className="my-3">{intl.formatMessage(messages.description)}</p>

            {hasGuests && (
              <Stack className="mb-3" space={2}>
                <p className="fs-1 text-black-3 text-uppercase">
                  {intl.formatMessage(messages.yourGuests)}
                </p>
                {guests.map((guest) => (
                  <p data-test="guest" key={guest.id}>
                    • {guest.firstName} {guest.lastName} —{' '}
                    {guest.shift.shiftFunction} —{' '}
                    <Link
                      data-test="edit-guest-link"
                      to={editGuestPath({
                        eventId,
                        guestId: guest.id,
                        shiftId,
                      })}
                    >
                      {intl.formatMessage(messages.editGuestRegistration)}
                    </Link>
                  </p>
                ))}
              </Stack>
            )}

            {/*TODO Remove use of form action bar in this case*/}
            {/*Maybe extract UI piece into ActionBar */}
            <FormActionBar hideBack>
              <LinkButton
                className="ml-auto"
                data-test="register-guest-button"
                disabled={shiftTimeframe.registrationEnded}
                to={newGuestPath({ eventId, shiftId })}
                variant="primary"
              >
                {intl.formatMessage(messages.registerGuest, {
                  another: anotherGuest,
                })}
              </LinkButton>
            </FormActionBar>
          </Fragment>
        )
      }}
    </WeQuery>
  </Paper>
)

EventGuestBox.propTypes = {
  eventId: PropTypes.string.isRequired,
  shiftId: PropTypes.string.isRequired,
}
