// Setup
import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { defineMessages } from 'react-intl'
import PullToRefresh from 'pulltorefreshjs'
import { MuiThemeProvider } from '@material-ui/core/styles'

// Vendor
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { muiTheme } from 'components/application/layout/mui_themes'

export const messages = defineMessages({
  instructionsPullToRefresh: {
    defaultMessage: 'Pull down to refresh',
    id: 'pullToRefresh.instructionsPullToRefresh',
  },
  instructionsRefreshing: {
    defaultMessage: 'Refreshing',
    id: 'pullToRefresh.instructionsRefreshing',
  },
  instructionsReleaseToRefresh: {
    defaultMessage: 'Release to refresh',
    id: 'pullToRefresh.instructionsReleaseToRefresh',
  },
})

export const makePullToRefreshInstance = (opts = {}) => {
  return PullToRefresh.init({
    iconArrow: ReactDOMServer.renderToString(
      <Icon className="fs-5 mr-3 text-blue" iconName="refresh" title="" />
    ),
    iconRefreshing: ReactDOMServer.renderToString(
      <MuiThemeProvider theme={muiTheme}>
        <CenteredPaddedLoadingIndicator />
      </MuiThemeProvider>
    ),
    instructionsPullToRefresh: intl.formatMessage(
      messages.instructionsPullToRefresh
    ),
    instructionsRefreshing: intl.formatMessage(messages.instructionsRefreshing),
    instructionsReleaseToRefresh: intl.formatMessage(
      messages.instructionsReleaseToRefresh
    ),
    mainElement: 'body',
    onRefresh: () => window.location.reload(),
    shouldPullToRefresh: () => {
      // if action dialog is open check its scroll to try to ptr
      const actionDialog = document.querySelector('#action-dialog-content')
      if (actionDialog) {
        return actionDialog?.scrollTop === 0
      }

      // trying to check if a sidemenu or modal is open ignore ptr then
      if (document.querySelector('.MuiBackdrop-root')) {
        return false
      }

      // default behaviour
      return !window.scrollY
    },
    ...opts,
  })
}

// if the ontouchstart event, maxTouchPoint or msMaxTouchPoints are available in the browser, then it’s probably a mobile device since most mobile devices have touch screens.
export const usePullToRefresh = () => {
  const [pullToRefresh, setPullToRefresh] = useState(null)
  useEffect(() => {
    const isMobile =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    if (isMobile) {
      setPullToRefresh(makePullToRefreshInstance())
    }

    return () => {
      pullToRefresh.destroy()
    }
  }, [])

  return pullToRefresh
}
