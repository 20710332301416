// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import LinearProgress from '@material-ui/core/LinearProgress'

// WeSpire
import { AchievementImage } from 'components/achievement'
import Stack from 'components/ui/stack'

const CampaignProgressBar = ({
  achievement,
  children,
  className,
  progressPercent,
  ...other
}) => (
  <Stack {...other} className={className} space={2}>
    <div className="[ d-flex align-items-center ] [ position-relative pt-4 ]">
      <LinearProgress
        aria-label={`progressbar ${achievement.name} ${progressPercent}`}
        aria-valuemax="100"
        aria-valuemin="0"
        classes={{
          bar1Determinate: 'bg-green',
          determinate: 'bg-green-2 rounded',
        }}
        className="w-100 mb-1"
        value={progressPercent}
        variant="determinate"
      />
      {achievement && (
        <div className="absolute-right w-100">
          <AchievementImage
            achievementName={achievement.name}
            className={cx(
              'absolute-top-right elevation-1 mr-2 translate-up-50',
              {
                'op-80': !achievement.awardedTo,
              }
            )}
            data-test={`achievement-${
              achievement.awardedTo ? 'earned' : 'unearned'
            }`}
            effect={!achievement.awardedTo ? 'grayscale' : undefined}
            imageSrc={achievement.imageUrl}
          />
        </div>
      )}
    </div>
    {children}
  </Stack>
)

CampaignProgressBar.propTypes = {
  achievement: PropTypes.shape({
    awardedTo: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  progressPercent: PropTypes.number.isRequired,
}

CampaignProgressBar.defaultProps = {
  className: '',
}

export { CampaignProgressBar }
