// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { useLocation, useRouteMatch } from 'react-router-dom'

// WeSpire
import { muiTheme } from './mui_themes'
import { pathsWithoutNavigation } from 'components/router/paths'
import { usePullToRefresh } from 'utilities/hooks/usePullToRefresh'

import App from './App'

export const Layout = ({ children, displayNotification, notification }) => {
  usePullToRefresh()
  const location = useLocation()

  // pathsWithoutnavigation are no good for routes with ids on them
  const isCampaignPreviewRoute = useRouteMatch('/campaigns/:id/preview')
  const isCampaignTemplatePreviewRoute = useRouteMatch(
    '/campaign_templates/:id'
  )
  const isPreviewRoute =
    isCampaignPreviewRoute || isCampaignTemplatePreviewRoute

  const showNavigator =
    !isPreviewRoute && !pathsWithoutNavigation.includes(location.pathname)

  const isWestyle = !!useRouteMatch('/app')

  const showFooter = !isCampaignPreviewRoute

  return (
    <MuiThemeProvider theme={muiTheme}>
      <App
        displayNotification={displayNotification}
        location={location}
        notification={notification}
        showFooter={showFooter}
        showNavigator={showNavigator}
        westyle={isWestyle}
      >
        {children}
      </App>
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  displayNotification: PropTypes.func.isRequired,
  notification: PropTypes.string,
}

Layout.defaultProps = {
  notification: '',
}

export default Layout
