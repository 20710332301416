import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  activityEmptyState: {
    defaultMessage:
      'There are currently no activities available to reduce your carbon impact.',
    id: 'carbonFootprint.activityEmptyState',
  },
  calculate: {
    defaultMessage: 'Take the carbon footprint quiz',
    id: 'carbonFootprint.calculate',
  },
  carbonFootprint: {
    defaultMessage: 'Carbon Footprint',
    id: 'carbonFootprint.carbonFootprint',
  },
  carbonProjects: {
    defaultMessage: 'Take Action to Reduce Your Footprint',
    id: 'carbonFootprint.carbonProjects',
  },
  companyImpact: {
    defaultMessage: 'Company Impact',
    id: 'carbonFootprint.companyImpact',
  },
  completedActions: {
    defaultMessage:
      '{count, plural, zero {Carbon actions completed} one {Carbon action completed} two {Carbon actions completed} few {Carbon actions completed} many {Carbon actions completed} other {Carbon actions completed}}',
    id: 'carbonFootprint.completedActions',
  },
  food: {
    defaultMessage: 'Food',
    id: 'carbonFootprint.food',
  },
  goods: {
    defaultMessage: 'Goods',
    id: 'carbonFootprint.goods',
  },
  housing: {
    defaultMessage: 'Housing',
    id: 'carbonFootprint.housing',
  },
  impact: {
    defaultMessage: 'Impact',
    id: 'carbonFootprint.impact',
  },
  impactMetrics: {
    defaultMessage: 'impact metrics',
    id: 'carbonFootprint.impactMetrics',
  },
  ourActions: {
    defaultMessage: 'Our actions have reduced',
    id: 'carbonFootprint.ourActions',
  },
  recalculate: {
    defaultMessage: 'Recalculate',
    id: 'carbonFootprint.recalculate',
  },
  reduseEmissionImpact: {
    defaultMessage: 'Reduce the impact of your emissions',
    id: 'carbonFootprint.reduseEmissionImpact',
  },
  takeQuiz: {
    defaultMessage: 'Take the carbon quiz to calculate your score.',
    id: 'carbonFootprint.takeQuiz',
  },
  tonsCO2PerYear: {
    defaultMessage: 'Metric tons CO2 per year',
    id: 'carbonFootprint.tonsCO2PerYear',
  },
  travel: {
    defaultMessage: 'Travel',
    id: 'carbonFootprint.travel',
  },
  userImpact: {
    defaultMessage: 'Your Impact',
    id: 'carbonFootprint.userImpact',
  },
  whyMatters: {
    defaultMessage: 'Why this matters',
    id: 'carbonFootprint.whyMatters',
  },
  yourActions: {
    defaultMessage: 'Your actions have reduced',
    id: 'carbonFootprint.yourActions',
  },
  yourResults: {
    defaultMessage: 'Your Estimated Carbon Footprint',
    id: 'carbonFootprint.yourResults',
  },
})

export const translations = formatMessages(messages)
