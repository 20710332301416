// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'
import { Paper } from '@material-ui/core'

// WeSpire
import Stack from 'components/ui/stack'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { Stat } from 'components/ui/stat'
import { ourImpactTranslations } from 'components/our_impact/shared_translations'
import { intl } from 'utilities/localization'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { Heading } from 'components/ui/heading'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { TextLink } from 'components/shared/text_link'
import {
  DescriptionList,
  DescriptionListItem,
} from 'components/ui/description_list'

const channelImpactStats = gql`
  query channelStats($givingEnabled: Boolean!, $id: ID!) {
    channel(id: $id) {
      id
      brandContentModule {
        id
        whyItMatters
      }
      highestImpactCampaign {
        id
        actionUrl
        name
        visible
      }
      highestImpactCompetition {
        id
        actionUrl
        name
        visible
      }
      highestImpactEvent {
        id
        actionUrl
        name
        visible
      }
      highestImpactGivingActivity @include(if: $givingEnabled) {
        id
        actionUrl
        name
        visible
      }
      highestImpactIdeaBoard {
        id
        actionUrl
        name
        visible
      }
      totalCampaigns
      totalCompetitions
      totalEvents
      totalGivingActivities
      totalIdeaBoards
    }
  }
`

export const ActivityImpact = ({ channelId }) => {
  const { formatMessage } = intl
  const messages = defineMessages({
    heading: {
      defaultMessage: 'our collective actions taken have impacted',
      id: 'activityImpact.heading',
    },
    highestImpact: {
      defaultMessage: 'highest impact {activityType}',
      id: 'activityImpact.highestImpact',
    },
    noParticipation: {
      defaultMessage: 'No participation yet',
      id: 'activityImpact.noParticipation',
    },
  })

  const renderStats = (channel, activityType) => {
    const { count, highestImpact, labelMessage, typeTranslation } = {
      campaign: {
        count: channel.totalCampaigns,
        highestImpact: channel.highestImpactCampaign,
        labelMessage: sharedMessages.campaigns,
        typeTranslation: sharedTranslations.campaign,
      },
      competition: {
        count: channel.totalCompetitions,
        highestImpact: channel.highestImpactCompetition,
        labelMessage: sharedMessages.competitions,
        typeTranslation: sharedTranslations.competition,
      },
      event: {
        count: channel.totalEvents,
        highestImpact: channel.highestImpactEvent,
        labelMessage: sharedMessages.events,
        typeTranslation: sharedTranslations.event,
      },
      givingActivity: {
        count: channel.totalGivingActivities,
        highestImpact: channel.highestImpactGivingActivity,
        labelMessage: sharedMessages.givingActivities,
        typeTranslation: sharedTranslations.givingactivity,
      },
      ideaBoard: {
        count: channel.totalIdeaBoards,
        highestImpact: channel.highestImpactIdeaBoard,
        labelMessage: sharedMessages.ideaBoards,
        typeTranslation: sharedTranslations.ideaboard,
      },
    }[activityType]

    if (count === 0) {
      return null
    }

    let impactValue = formatMessage(messages.noParticipation)
    if (highestImpact) {
      impactValue = highestImpact.visible ? (
        <TextLink to={highestImpact.actionUrl}>{highestImpact.name}</TextLink>
      ) : (
        highestImpact.name
      )
    }

    return (
      <Stack as="li" data-test={`stats-${activityType}`} space={2}>
        <Stat amount={count} label={formatMessage(labelMessage, { count })} />
        <DescriptionList>
          <DescriptionListItem
            data-test={`highest-impact-${activityType}`}
            emphasizeDetail
            label={formatMessage(messages.highestImpact, {
              activityType: typeTranslation,
            })}
          >
            {impactValue}
          </DescriptionListItem>
        </DescriptionList>
      </Stack>
    )
  }

  return (
    <>
      <Heading className="px-3" level={3}>
        {formatMessage(messages.heading)}
      </Heading>
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={ourImpactTranslations.errorDetails}
          />
        }
        loader={<SkeletonLoading height={330} />}
        query={gql`
          query givingFeature {
            givingEnabled
          }
        `}
      >
        {({ data: { givingEnabled } }) => (
          <WeQuery
            error={
              <MissingResourceSection
                errorDetails={ourImpactTranslations.errorDetails}
              />
            }
            loader={<SkeletonLoading height={330} />}
            query={channelImpactStats}
            variables={{ givingEnabled, id: channelId }}
          >
            {({ data: { channel } }) => {
              const showStats =
                channel.totalCampaigns > 0 ||
                channel.totalCompetitions > 0 ||
                channel.totalEvents > 0 ||
                channel.totalGivingActivities > 0 ||
                channel.totalIdeaBoards > 0

              return (
                <Paper className="px-4 py-3">
                  <Stack space={5}>
                    {showStats && (
                      <Stack as="ul" className="list-none p-0" space={4}>
                        {renderStats(channel, 'campaign')}
                        {renderStats(channel, 'competition')}
                        {renderStats(channel, 'event')}
                        {givingEnabled &&
                          renderStats(channel, 'givingActivity')}
                        {renderStats(channel, 'ideaBoard')}
                      </Stack>
                    )}
                    <Stack
                      className="fs-1"
                      data-test="why-it-matters"
                      space={2}
                    >
                      <div className="fw-semi-bold text-uppercase">
                        {ourImpactTranslations.whyItMatters}
                      </div>
                      {channel.brandContentModule && (
                        <WysiwygContent>
                          {channel.brandContentModule.whyItMatters}
                        </WysiwygContent>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              )
            }}
          </WeQuery>
        )}
      </WeQuery>
    </>
  )
}

ActivityImpact.propTypes = {
  channelId: PropTypes.string.isRequired,
}
