import { endOfDay, startOfDay } from 'utilities/date_utils'
import { selectUnit } from '@formatjs/intl-utils'
export class ActionTimeframeModel {
  constructor({ endsAt, gracePeriodEndsAt, startsAt }) {
    this.endsAt = endsAt
    this.gracePeriodEndsAt = gracePeriodEndsAt
    this.startsAt = startsAt

    const today = startOfDay()
    const parsedStart = (this.parsedStart = startOfDay(this.startsAt))
    const parsedEnd = (this.parsedEnd = endOfDay(this.endsAt))

    this.ended = false
    this.notStarted = false
    this.running = false

    if (today > parsedEnd) {
      if (gracePeriodEndsAt) {
        const parsedGracePeriodEndsAt = endOfDay(gracePeriodEndsAt)
        if (today < parsedGracePeriodEndsAt) {
          this.running = true
          this.onGracePeriod = true
        } else {
          this.ended = true
          this.relativeTimeParams = selectUnit(parsedEnd, endOfDay())
        }
      } else {
        this.ended = true
        this.relativeTimeParams = selectUnit(parsedEnd, endOfDay())
      }
    } else if (today < parsedStart) {
      this.notStarted = true
      this.relativeTimeParams = selectUnit(parsedStart, startOfDay())
    } else {
      this.running = true
    }
  }
}
