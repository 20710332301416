// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import ParticipantsSummary from 'components/application/participants_summary'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  actions: {
    defaultMessage:
      '{count, plural, zero {actions} one {action} two {actions} few {actions} many {actions} other {actions}} taken',
    id: 'activityParticipation.actions',
  },
})

/** To show the number of participants and the taken action of an activity */
const ActivityParticipation = ({
  avatars,
  participantsCount,
  hasStarted,
  totalActionsTaken,
  ...other
}) => (
  <Stack {...other} space={3}>
    <ParticipantsSummary
      avatarImages={avatars}
      participantsCount={participantsCount}
      variant="stacked"
    />
    {hasStarted && (
      <Stat
        amount={totalActionsTaken}
        label={intl.formatMessage(messages.actions, {
          count: totalActionsTaken,
        })}
        size={7}
      />
    )}
  </Stack>
)

ActivityParticipation.propTypes = {
  avatars: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  hasStarted: PropTypes.bool.isRequired,
  participantsCount: PropTypes.number.isRequired,
  totalActionsTaken: PropTypes.number.isRequired,
}

export { ActivityParticipation }
