// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'
import { GroupChaptersTable } from './table'

export const GroupChapters = ({ groupId }) => {
  return (
    <Stack space={4}>
      <GroupChaptersTable groupId={groupId} />
    </Stack>
  )
}

GroupChapters.propTypes = {
  groupId: PropTypes.string.isRequired,
}
