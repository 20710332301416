// Setup
import React from 'react'

// WeSpire
import MgmtPanelPage from 'setup/application/page'
import { UserChangesList } from './user_changes_list/index'

const UserChanges = () => {
  return (
    <MgmtPanelPage
      contentHeaderProps={{
        'data-test': 'user-changes-index-title',
      }}
      title='Users Changelog'
    >
      {() => (
        <UserChangesList />
      )}
    </MgmtPanelPage>
  )
}

export default UserChanges
