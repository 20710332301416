// Setup
import React from 'react'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import v from 'voca'

// WeSpire
import Autocomplete from 'components/form/autocomplete'
import FormFieldGroup from 'components/form/field_group'
import FormFieldsetGroup from 'components/form/fieldset_group'
import ResultsSelect from '../../utils/results_select'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'
import { useQuery } from '@apollo/client'
import { client } from 'utilities/we_apollo'
import { yearFilterOptions } from 'utilities/year_filter_options'
import { getAutocompleteSelectionsFromIds } from 'utilities/get_autocomplete_selections_from_ids'
import { SUGGESTIONS_LIST_QUERY } from '../utilities/queries'
import { actionsFiltersMessages as messages } from '../utilities/messages'

const { formatMessage } = intl

const getSuggestions = (records) =>
  records.map(({ id, name }) => ({
    id: id,
    suggestionLabel: name,
  }))

const ActionsFilters = ({
  campaignIds,
  channelIds,
  onFiltersChange,
  statuses,
  year,
}) => {
  const { data: suggestionsListData } = useQuery(SUGGESTIONS_LIST_QUERY, {
    client,
  })

  const channelSuggestions = getSuggestions(suggestionsListData?.channels || [])
  const campaignsSuggestions = getSuggestions(
    suggestionsListData?.campaigns.nodes || []
  )
  const statusesSuggestion = (suggestionsListData?.campaignStatuses || []).map(
    (status) => ({
      id: status,
      suggestionLabel: v.capitalize(sharedTranslations[status.toLowerCase()]),
    })
  )

  return (
    <Formsy noValidate onChange={onFiltersChange}>
      <FormFieldsetGroup>
        <FormFieldGroup
          id="actions-filters-label"
          label={`${formatMessage(messages.filtersLabel)}:`}
        >
          <div className="row">
            <div className="col">
              <ResultsSelect
                className="w-100"
                hideBlankOption
                name="year"
                optionProps={{ className: 'fw-semi-bold text-black-3' }}
                options={yearFilterOptions().map(({ label, id }) => ({
                  label: label,
                  value: id,
                }))}
                textFieldProps={{
                  label: sharedTranslations.yearLabel,
                }}
                value={year}
              />
            </div>
            <div className="col">
              <Autocomplete
                className="w-100 selected-channels"
                multiple
                name="channelIds"
                selections={getAutocompleteSelectionsFromIds(
                  channelSuggestions,
                  channelIds
                )}
                suggestions={channelSuggestions}
                textFieldProps={{
                  label: sharedTranslations.channelLabel,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Autocomplete
                className="w-100 selected-campaigns"
                multiple
                name="campaignIds"
                selections={getAutocompleteSelectionsFromIds(
                  campaignsSuggestions,
                  campaignIds
                )}
                suggestions={campaignsSuggestions}
                textFieldProps={{
                  label: formatMessage(messages.campaignLabel),
                }}
              />
            </div>
            <div className="col">
              <Autocomplete
                className="w-100 selected-statuses"
                multiple
                name="statuses"
                selections={getAutocompleteSelectionsFromIds(
                  statusesSuggestion,
                  statuses
                )}
                suggestions={statusesSuggestion}
                textFieldProps={{
                  label: sharedTranslations.statusLabel,
                }}
              />
            </div>
          </div>
        </FormFieldGroup>
      </FormFieldsetGroup>
    </Formsy>
  )
}

ActionsFilters.propTypes = {
  campaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  channelIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  year: PropTypes.string.isRequired,
}

export { ActionsFilters }
