// Vendor
import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

const InternalLink = ({ children, className, href, react = true, other }) => {
  return (
    <Link
      {...other}
      className={cx(className, 'hover:text-smart-blue')}
      onClick={() => (react ? null : (window.location = href))}
      to={react ? href : '#'}
    >
      {children}
    </Link>
  )
}

export default InternalLink
