// Setup
import React from 'react'
import { parse } from 'query-string'

// Vendor
import { defineMessages } from 'react-intl'
import filter from 'lodash/filter'
import reject from 'lodash/reject'

// WeSpire
import { DocumentTitle } from 'components/shared/document_title'
import CenteredContent from 'components/application/centered_content'
import ConnectionInfo from '../connection_info'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import {
  isIOSDevice,
  isAndroidDevice,
  isConnected,
} from 'utilities/device_linking_helpers'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { PageQuery } from 'components/queries/page_query'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'
import { CONNECTED_DEVICES_GQL } from 'graphql/queries'

const { formatMessage } = intl

const messages = defineMessages({
  androidAppName: {
    defaultMessage: 'Google Fit',
    id: 'connectedDevices.androidAppName',
  },
  androidDeviceStore: {
    defaultMessage: 'Google Play store',
    id: 'connectedDevices.androidDeviceStore',
  },
  connectionInfo: {
    defaultMessage: `Please allow all data when connecting your device. Tracking may not function as intended if only some data is allowed.`,
    id: 'connectedDevices.connectionInfo',
  },
  connectMessageDisclaimer: {
    defaultMessage:
      'Share your steps and active minutes data via { deviceApp }',
    id: 'connectedDevices.connectMessageDisclaimer',
  },
  connectTrackingDevices: {
    defaultMessage: 'Connect a Tracking Device',
    id: 'connectedDevices.connectTrackingDevices',
  },
  exisitingConnectedDevice: {
    defaultMessage:
      'Your connected { deviceName } will be used to track your progress in Campaigns.',
    id: 'connectedDevices.exisitingConnectedDevice',
  },
  iosAppName: {
    defaultMessage: 'Apple Health',
    id: 'connectedDevices.iosAppName',
  },
  iosDeviceStore: {
    defaultMessage: 'an iPhone',
    id: 'connectedDevices.iosDeviceStore',
  },
  missingDevice: {
    defaultMessage: 'No Device info',
    id: 'connectedDevices.missingDevice',
  },
  missingDeviceDescription: {
    defaultMessage: 'No device info for this user.',
    id: 'connectedDevices.missingDeviceDescription',
  },
  multipleConnectedDevices: {
    defaultMessage:
      "It's recommended to maintain a single device account, please disable any extra device connections.",
    id: 'connectedDevices.multipleConnectedDevices',
  },
  notMatchingDeviceMessage: {
    defaultMessage:
      'Please download WeSpire app on { deviceStore } to sync { appName } data.',
    id: 'connectedDevices.notMatchingDeviceMessage',
  },
  returnToActivity: {
    defaultMessage: '<bold>{ returnLink }</bold> to participate.',
    id: 'connectedDevices.returnToActivity',
  },
  successfullyConnected: {
    defaultMessage:
      'You have successfully connected your <bold>{ deviceList }</bold>.',
    id: 'connectedDevices.successfullyConnected ',
  },
  switchCustomDeviceInstructions: {
    defaultMessage:
      'Please disconnect your { deviceName } account by logging into the WeSpire app on your mobile device ' +
      "if you'd like to switch to a different tracking device",
    id: 'connectedDevices.switchCustomDeviceInstructions',
  },
  switchDeviceInstructions: {
    defaultMessage:
      "Please disconnect your { deviceName } account if you'd like to switch to a different tracking device.",
    id: 'connectedDevices.switchDeviceInstructions',
  },
})

const handleSwitchDeviceMessage = (connectedDevices, firstConnectedDevice) => {
  const deviceType = firstConnectedDevice['type']

  if (connectedDevices.length > 1) {
    return formatMessage(messages.multipleConnectedDevices)
  }

  if (deviceType === 'google_fit_sdk' || deviceType === 'apple_health') {
    return formatMessage(messages.switchCustomDeviceInstructions, {
      deviceName: firstConnectedDevice['displayName'],
    })
  }

  return formatMessage(messages.switchDeviceInstructions, {
    deviceName: firstConnectedDevice['displayName'],
  })
}

const ConnectedDevicesPage = () => {
  return (
    <GraphQLFeatureFlag
      error={null}
      featureDisabledError={null}
      featureName="healthDataIntegrations"
      loader={null}
    >
      <PageQuery query={CONNECTED_DEVICES_GQL}>
        {({
          data: {
            currentUser: { validicUser },
            brand: { enableMobileApp },
            iosAppLink,
            androidAppLink,
            androidAppDisconnectLink,
            iosAppDisconnectLink,
            deviceHelperLinks,
          },
        }) => {
          if (!validicUser) {
            return (
              <DocumentTitle
                title={intl.formatMessage(sharedMessages.resourceNotFound, {
                  resource: intl.formatMessage(messages.connectTrackingDevices),
                })}
              >
                <MissingResourcePage
                  data-test="connected-devices-zero-state"
                  title={intl.formatMessage(messages.missingDevice)}
                >
                  <p>{intl.formatMessage(messages.missingDeviceDescription)}</p>
                </MissingResourcePage>
              </DocumentTitle>
            )
          }
          const { marketplaceInfo } = validicUser
          const { return_url } = parse(window.location.search)

          const deviceHelperLinkMap = {
            'Apple Health': 'appleHealthHelperLink',
            Fitbit: 'fitbitHelperLink',
            'Garmin Connect': 'garminHelperLink',
            'Google Fit': 'googleFitHelperLink',
            Misfit: 'misfitHelperLink',
            'Under Armour': 'underArmourHelperLink',
            Withings: 'withingsHelperLink',
          }

          const CUSTOM_DEVICES = [
            {
              cloudName: 'hyetmyubn',
              connected: isConnected(validicUser.linkedDevices, 'apple_health'),
              connectMessageDisclaimer: formatMessage(
                messages.connectMessageDisclaimer,
                {
                  deviceApp: <span className="d-block">Apple Health</span>,
                }
              ),
              connectUrl: isIOSDevice() ? iosAppLink : null,
              disconnectUrl: isIOSDevice() ? iosAppDisconnectLink : null,
              displayName: 'Apple Health',
              enabled: null,
              isCustomDevice: true,
              logoUrl:
                '//res.cloudinary.com/hyetmyubn/image/upload/v1616423251/static/apple-health-logo.png',
              noDeviceMessage: formatMessage(
                messages.notMatchingDeviceMessage,
                {
                  appName: formatMessage(messages.iosAppName),
                  deviceStore: formatMessage(messages.iosDeviceStore),
                }
              ),
              type: 'apple_health',
            },
            {
              cloudName: 'hyetmyubn',
              connected: isConnected(
                validicUser.linkedDevices,
                'google_fit_sdk'
              ),
              connectMessageDisclaimer: formatMessage(
                messages.connectMessageDisclaimer,
                {
                  deviceApp: <span className="d-block">Google Fit</span>,
                }
              ),
              connectUrl: isAndroidDevice() ? androidAppLink : null,
              disconnectUrl: isAndroidDevice()
                ? androidAppDisconnectLink
                : null,
              displayName: 'Google Fit',
              enabled: null,
              isCustomDevice: true,
              logoUrl:
                '//res.cloudinary.com/hyetmyubn/image/upload/v1617282376/static/google-fit-logo.png',
              noDeviceMessage: formatMessage(
                messages.notMatchingDeviceMessage,
                {
                  appName: formatMessage(messages.androidAppName),
                  deviceStore: formatMessage(messages.androidDeviceStore),
                }
              ),
              type: 'google_fit_sdk',
            },
          ]

          const mobileDevices = isAndroidDevice()
            ? CUSTOM_DEVICES.reverse()
            : CUSTOM_DEVICES

          const unsortedMarketplaceDevices = [
            ...(enableMobileApp ? mobileDevices : []),
            ...marketplaceInfo,
          ]

          const connectedDevices = filter(
            unsortedMarketplaceDevices,
            'connected'
          )

          const firstConnectedDevice = connectedDevices[0]

          const marketplaceDevices = firstConnectedDevice
            ? [
                firstConnectedDevice,
                ...reject(unsortedMarketplaceDevices, firstConnectedDevice),
              ]
            : unsortedMarketplaceDevices

          return (
            <DocumentTitle
              title={intl.formatMessage(messages.connectTrackingDevices)}
            >
              {maybeDisplayUserTour('connectedDevicesPage')}
              <CenteredContent className="mxw-measure">
                <Stack
                  className="align-items-center flex-column my-0 mt-5 px-3"
                  space={3}
                >
                  {return_url && (
                    <TextLink
                      className="fs-2"
                      data-test="back-to-btn"
                      to={return_url}
                    >
                      <>
                        <span aria-hidden>
                          {'< '}
                          {sharedTranslations.backTo}
                        </span>{' '}
                        {sharedTranslations.previousPage}
                      </>
                    </TextLink>
                  )}
                  <Heading className="px-3" level={1}>
                    {formatMessage(messages.connectTrackingDevices)}
                  </Heading>
                  <p className="mt-2 bg-blue-2 p-3">
                    {formatMessage(messages.connectionInfo)}
                  </p>
                  {!!connectedDevices.length && (
                    <>
                      <p
                        className="mt-2 bg-blue-2 p-3"
                        data-test="single-connected-device-reminder"
                      >
                        <span className="d-block">
                          {formatMessage(messages.exisitingConnectedDevice, {
                            deviceName: firstConnectedDevice['displayName'],
                          })}
                        </span>
                        <span className="d-block mt-2">
                          {handleSwitchDeviceMessage(
                            connectedDevices,
                            firstConnectedDevice
                          )}
                        </span>
                      </p>
                    </>
                  )}

                  <Stack
                    as="ul"
                    className="list-none p-0"
                    data-test="connection-info-list"
                    space={4}
                  >
                    {marketplaceDevices.map((info, index) => (
                      <ConnectionInfo
                        cloudName={info?.cloudName}
                        connected={info.connected}
                        connectMessageDisclaimer={
                          info?.connectMessageDisclaimer
                        }
                        connectUrl={info.connectUrl}
                        disconnectUrl={info.disconnectUrl}
                        displayName={info.displayName}
                        enabled={info.enabled}
                        hasOtherConnectedDevices={!!firstConnectedDevice}
                        infoLink={
                          deviceHelperLinks[
                            deviceHelperLinkMap[info.displayName]
                          ] || null
                        }
                        isCustomDevice={info?.isCustomDevice || false}
                        key={index}
                        logoUrl={info.logoUrl}
                        noDeviceMessage={info?.noDeviceMessage}
                        returnUrl={return_url}
                        type={info.type}
                      />
                    ))}
                  </Stack>
                </Stack>
              </CenteredContent>
            </DocumentTitle>
          )
        }}
      </PageQuery>
    </GraphQLFeatureFlag>
  )
}

export default ConnectedDevicesPage
