// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import FormTextField from 'components/form/text_field'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  isFormattedTime: {
    defaultMessage: 'Must be formatted like 1:30 PM',
    id: 'formTimeField.isFormattedTime',
  },
})

const { formatMessage } = intl

const TIME_REGEX = /^(1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm])$/

const formattedValue = (value) => {
  // If value is falsy or if formatted as HH:MM am/pm, return value as-is.
  if (!value || TIME_REGEX.test(value)) return value
  // If value is an ISO string format it.
  return new Date(value).toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })
}

/**
 * Text field used to specify a time inside a form. Includes an icon
 * rendered at the end of the input. Validates the text value is formatted like
 * 1:30 PM to match the expectation of the Rails TimeSettable module.
 */
const FormTimeField = ({
  helperText,
  label,
  InputProps,
  textFieldProps,
  value,
  ...other
}) => {
  return (
    <FormTextField
      {...other}
      textFieldProps={{
        ...textFieldProps,
        helperText,
        InputProps: {
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <Icon
                className="[ fs-5 text-black-3 ] [ pointer-events-none ]"
                iconName="clock"
                title=""
              />
            </InputAdornment>
          ),
        },
        label,
      }}
      validationErrors={{
        matchRegexp: formatMessage(messages.isFormattedTime),
      }}
      validations={{
        matchRegexp: TIME_REGEX,
      }}
      value={formattedValue(value)}
    />
  )
}

FormTimeField.propTypes = {
  /** default text rendered below the field, overriden by error text */
  helperText: PropTypes.string,
  /** MUI API used by icon */
  InputProps: PropTypes.object,
  /** text rendered as a floating label on the field */
  label: PropTypes.string.isRequired,
  /** MUI API used by helper text and label */
  textFieldProps: PropTypes.object,
  /** default date value in ISO format */
  value: PropTypes.string,
}

FormTimeField.defaultProps = {
  helperText: 'Format: HH:MM 12-hour',
  InputProps: {},
  textFieldProps: {},
  value: null,
}

export default FormTimeField
