// Vendor
import React, { useState, useEffect } from 'react'
import { intl } from 'utilities/localization'

// Wespire
import { AdditionalInformationCard } from 'components/ui/AdditionalInformationCard'
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { displayBanner } from 'redux/dispatchers'
import { DividerWithLabel } from 'components/ui/DividerWithLabel'
import { EmployeeCredentials } from './components/EmployeeCredentials'
import EmployeeEmail from './components/EmployeeEmail'
import LoggedOutLayout from '/components/application/layout/LoggedOutLayout'
import { landingMessages, messages } from './utilities/messages'
import { Paragraph } from 'components/ui/Typography/Paragraph'
import WeSpireAPI from 'utilities/wespire_api'

const { formatMessage } = intl

const Landing = () => {
  const [heroSubtitle, setHeroSubtitle] = useState('')
  const [heroTitle, setHeroTitle] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [reactLogin, setReactLogin] = useState(false)
  const [signUpPath, setSignUpPath] = useState('')
  const [ssoDescription, setSsoDescription] = useState('')
  const [multiAuth, setMultiAuth] = useState(false)
  const [passwordDescription, setPasswordDescription] = useState('')
  const [ssoUrl, setSsoUrl] = useState('')

  useEffect(() => {
    WeSpireAPI()
      .get('brands/landing')
      .then((response) => {
        const {
          sign_up_path,
          sso_description,
          sso_url,
          hero_title,
          hero_subtitle,
          logo_url,
          multi_auth,
          password_description,
          react_login_enabled,
        } = response.data

        setHeroSubtitle(hero_subtitle)
        setHeroTitle(hero_title)
        setLogoUrl(logo_url)
        setMultiAuth(multi_auth)
        setPasswordDescription(
          password_description ||
            formatMessage(messages.personalInformationRegistration)
        )
        setReactLogin(react_login_enabled)
        setSignUpPath(sign_up_path)
        setSsoDescription(sso_description)
        setSsoUrl(sso_url)
      })
  })

  const pageContent = () => {
    if (multiAuth && ssoUrl) {
      return (
        <>
          <EmployeeCredentials
            className="[ align-items-center d-flex flex-column justify-content-center ] [ mb-3 ][ max-w-xl w-100 ]"
            ssoDescription={ssoDescription}
            url={ssoUrl}
          />
          <DividerWithLabel className="my-3">
            {formatMessage(messages.or)}
          </DividerWithLabel>
          <EmployeeEmail
            buttonText={landingMessages.continueWithEmail}
            className="[ align-items-center d-flex flex-column justify-content-center ] [ max-w-xl w-100 ]"
            reactLogin={reactLogin}
            ssoUrl={ssoUrl}
          />
          <AdditionalInformationCard className="max-w-xl w-100">
            <div
              className="login-auth-title"
              dangerouslySetInnerHTML={{
                __html: passwordDescription,
              }}
            />
          </AdditionalInformationCard>
        </>
      )
    } else if (signUpPath && signUpPath === ssoUrl) {
      return (
        <>
          <EmployeeEmail
            buttonText={landingMessages.continue}
            reactLogin={reactLogin}
            ssoUrl={ssoUrl}
          />
          <div className="[ d-flex justify-content-center ] [ py-4 ]">
            <Paragraph size="large">
              {`${formatMessage(messages.alreadyHaveAnAccount)} `}
              <a data-test="sign-in-link" href="/sign_in">
                {formatMessage(messages.signIn)}
              </a>
            </Paragraph>
          </div>
          <AdditionalInformationCard className="max-w-xl w-100">
            <div
              className="login-auth-title"
              dangerouslySetInnerHTML={{
                __html: passwordDescription,
              }}
            />
          </AdditionalInformationCard>
        </>
      )
    } else if (ssoUrl) {
      return (
        <EmployeeCredentials
          className="[ align-items-center d-flex flex-column justify-content-center ] [ mb-3 ][ max-w-xl w-100 ]"
          ssoDescription={ssoDescription}
          title={ssoDescription}
          url={ssoUrl}
        />
      )
    } else {
      return <CenteredPaddedLoadingIndicator />
    }
  }

  const info = location.state?.info
  if (info) {
    displayBanner({
      content: info,
      variant: 'info',
    })
  }

  const errors = location.state?.errors
  if (errors) {
    displayBanner({
      content: errors,
      variant: 'error',
    })
  }

  return (
    <LoggedOutLayout
      heroSubtitle={heroSubtitle}
      logoUrl={logoUrl}
      title={heroTitle}
    >
      <div className="flex-grow">
        <CenteredContent className="mxw-6">
          <div className="d-flex flex-column align-items-stretch">
            <div className="[ h-100 ] [ mb-7 mx-3 | mb-0 mx-sm-0 ]">
              <div className="[ align-items-center d-flex flex-column justify-content-center ] [ max-w-xl ]">
                {pageContent()}
                <form action={ssoUrl} style={{ display: 'none' }}>
                  <button className="class-for-ios-sign-in-get-path" />
                </form>
              </div>
            </div>
          </div>
        </CenteredContent>
      </div>
    </LoggedOutLayout>
  )
}

export { Landing }
