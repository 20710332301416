// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { DocumentTitle } from 'components/shared/document_title'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  pageBody: {
    defaultMessage:
      'We encountered an unexpected error and could not load the content for this page.',
    id: 'errorPage.pageBody',
  },
  title: {
    defaultMessage: 'Content not available',
    description:
      'Informs the user that we could not load content on a web page',
    id: 'errorPage.title',
  },
})

/**
 * This page should be used when we cannot display content to a user on a page
 * and need to explain to users why that's the case. It can optionally receive
 * text to render in the body of the page to explain a specific error scenario.
 **/
const ErrorPage = ({ body, 'data-test': dataTest, title }) => {
  const { formatMessage } = intl
  const pageBody = body || formatMessage(messages.pageBody)
  const pageTitle = title || formatMessage(messages.title)

  return (
    <DocumentTitle title={pageTitle}>
      <MissingResourcePage data-test={dataTest} title={pageTitle}>
        <p className="mxw-measure text-center">{pageBody}</p>
      </MissingResourcePage>
    </DocumentTitle>
  )
}

ErrorPage.propTypes = {
  /** The optional body text for the ErrorPage default <p> tag. Intended to be
   * a message explaining to the user in more detail why they encountered an
   * error.
   **/
  body: PropTypes.string,
  'data-test': PropTypes.string,
  title: PropTypes.string,
}

ErrorPage.defaultProps = {
  body: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'error-page',
  title: null,
}

export { ErrorPage }
