// Setup
import React, { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useLocation } from 'react-router-dom'

// WeSpire
import { Button } from 'components/ui/button'
import Campaign from 'components/campaign'
import { sharedTranslations } from 'components/shared/translations'
import SubmitButton from 'components/ui/submit_button'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'

// Utilities
import {
  PUBLISH_CAMPAIGN_MUTATION,
  SAVE_CAMPAIGN_MUTATION,
} from './utilities/mutations'
import { PREVIEW_CAMPAIGN_QUERY } from './utilities/queries'

const CampaignPreview = () => {
  const { id } = useParams()
  const { search } = useLocation()
  const [handlePublishCampaign] = useMutation(PUBLISH_CAMPAIGN_MUTATION)
  const [handleSaveCampaign] = useMutation(SAVE_CAMPAIGN_MUTATION)
  const queryParams = useMemo(() => new URLSearchParams(search), [search]) // eslint-disable-line compat/compat
  const { data, error, loading } = useQuery(PREVIEW_CAMPAIGN_QUERY, {
    variables: { campaignId: id },
  })

  const {
    previousStepUrl = '',
    progressionState = 'save',
    progressionButtonText = sharedTranslations.save,
  } = data?.campaignPreview || {}

  const handleRedirect = useCallback(() => {
    if (previousStepUrl) {
      window.location.href = previousStepUrl
    }
  }, [previousStepUrl])

  const handleSaveOrPublishCampaign = useCallback(async () => {
    const redirectBackTarget = queryParams.get('redirect_back_target') || ''
    let data = {}
    let message = ''

    try {
      if (progressionState === 'save') {
        data = await handleSaveCampaignMutation(id, redirectBackTarget)
      } else {
        data = await handlePublishCampaignMutation(id, redirectBackTarget)
      }

      if (data?.message) {
        message = <p dangerouslySetInnerHTML={{ __html: data.message }} />
      } else {
        message = data.error
      }

      displayBanner({
        as: 'div',
        content: message,
        fullWidth: true,
        variant: data?.message ? 'success' : 'error',
      })
    } catch (error) {
      displayExceptionBanner({
        fullWidth: true,
        operation: `${progressionState} Campaign`,
      })
    }
  }, [data])

  const handlePublishCampaignMutation = useCallback(
    async (id, redirectBackTarget) => {
      const { data } = await handlePublishCampaign({
        variables: {
          campaignId: id,
          redirectTarget: redirectBackTarget,
        },
      })

      return {
        error: data?.publishCampaign?.errors || '',
        message: data?.publishCampaign?.message || '',
      }
    },
    []
  )

  const handleSaveCampaignMutation = useCallback(
    async (id, redirectBackTarget) => {
      const { data } = await handleSaveCampaign({
        variables: {
          campaignId: id,
          redirectTarget: redirectBackTarget,
        },
      })

      return {
        error: data?.saveCampaign?.errors || '',
        message: data?.saveCampaign?.message || '',
      }
    },
    []
  )

  return (
    <div className="campaign-preview-page pb-6">
      <Campaign isPreview />
      <div className="[ px-4 py-3 ] [ bg-white ] [ fixed-bottom border ] [ d-flex justify-content-end ]">
        <Button className="mx-2" onClick={handleRedirect}>
          {sharedTranslations.previous}
        </Button>
        <SubmitButton
          className="mx-2"
          color="secondary"
          disabled={error || loading}
          onClick={handleSaveOrPublishCampaign}
          variant="contained"
        >
          {progressionButtonText}
        </SubmitButton>
      </div>
    </div>
  )
}

export default CampaignPreview
