// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

export const FilterSelect = ({ blankLabel, children, id, label, ...props }) => {
  return (
    <FormControl
      className="mnw-2 ml-md-2 [ flex-grow mb-2 | mb-md-0 ]"
      variant="outlined"
    >
      <label className="fs-2 fw-bold text-black pl-2 mb-2" htmlFor={id}>
        {label}
      </label>
      <Select
        classes={{
          root: 'py-2 text-black-2',
        }}
        displayEmpty
        id={id}
        inputProps={{
          'data-test': `filter-${id}`,
          id: id,
        }}
        native
        {...props}
      >
        {blankLabel && <option value="">{blankLabel}</option>}
        {children}
      </Select>
    </FormControl>
  )
}

FilterSelect.propTypes = {
  blankLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

FilterSelect.defaultProps = {
  blankLabel: null,
  children: null,
}
