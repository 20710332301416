// Vendor
import React from 'react'

// WeSpire
import { DividerWithLabel } from 'components/ui/DividerWithLabel'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'this is a DividerWithLabel component',
    },
  },
  component: DividerWithLabel,
  title: 'UI/DividerWithLabel',
}

const Template = (args) => <DividerWithLabel {...args} />

export const DividerWithLabel1 = Template.bind({})
DividerWithLabel1.args = {
  dataTest: 'test-divider-with-label',
}
