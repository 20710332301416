import React from 'react'
import cx from 'classnames'

const Drawer = ({ children, className, drawerOpen, setDrawerOpen }) => {
  return drawerOpen ? (
    <div
      className={cx(
        'fixed md:w-[18.75rem] mt-[65px] w-screen h-screen left-0 z-10',
        className
      )}
    >
      <div className="absolute overflow-scroll bg-white md:w-[18.75rem] w-screen h-screen flex flex-col pb-[65px]">
        {children}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Drawer
