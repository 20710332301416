// Vendor
import gql from 'graphql-tag'

// WeSpire
import { FacepileSizes } from 'components/application/avatar'

// NOTE: These constants cannot be sorted alphabetically because you need to
// define first any fragments used by other fragments.

export const USER_FRAGMENT = gql`
  fragment UserAttributes on User {
    avatarUrl
    email
    id
    name
    profilePath
    profileUrl
  }
`

export const ACTION_FRAGMENT = gql`
  fragment ActionAttributes on Action {
    accumulationActionDailyTrackingTarget
    accumulationActionTrackingType
    allowUserToOverrideTargetAmount
    availableCategories {
      label
      value
    }
    conditionMetricName
    cooldownEndsAt
    customFields {
      id
      isRequired
      name
      options
      responseType
    }
    dailyMaxLogging
    description
    endsAt
    gracePeriodEndsAt
    hasCarbonImpact
    hasCustomTimeframe
    hasOverrideTargetAmount
    id
    isAccumulationAction
    isManualLoggingDisabled
    name
    points
    progressCategory
    progressMeasuredAs
    progressTargetValue
    required
    requirement {
      comment
      photo
      tag
    }
    startsAt
    status
    userCompleted
    userOverrideTargetMaxAmount
    userOverrideTargetMinAmount
    userProgress
    userProgressActivityLogs {
      category
      conditionMetricName
      count
      dailyMax
      dailyGoalMet
      date
      id
      measuredAs
    }
  }
`

export const IDEA_BOARD_SUBMISSION_FRAGMENT = gql`
  ${USER_FRAGMENT}

  fragment IdeaBoardSubmissionAttributes on IdeaBoardSubmission {
    category {
      id
      name
    }
    commentsList {
      createdAt
      currentUserLikeId
      id
      likeCount
      message
      user {
        ...UserAttributes
      }
    }
    createdAt
    currentUserVoteId
    currentVoteCreatedAt
    customFieldResponses {
      id
      name
      response
    }
    description
    id
    ideaUrl
    imageUrl
    points
    previousVoteCreatedAt
    scored
    title
    user {
      ...UserAttributes
    }
    voteCount
  }
`

export const NEWSFEED_ITEM_FRAGMENT = gql`
  fragment NewsfeedItem on NewsfeedItem {
    commentable {
      id
      type
    }
    comments {
      createdAt
      currentUserLikeId
      id
      likeCount
      message
      user {
        avatarUrl
        id
        firstName
        lastName
        profilePath
      }
    }
    createdAt
    currentUserLikeId
    endsAt
    id
    imageUrl
    likableId
    likableType
    likeCount
    message
    orgName
    startsAt
    storyId
    taggedUsers {
      id
      firstName
      lastName
      profilePath
    }
    targetId
    thumbnailUrl
    title
    type
    user {
      avatarUrl
      id
      firstName
      lastName
      profilePath
    }
    userId
    verb
  }
`

export const CAMPAIGN_PARTICIPATION_FRAGMENT = gql`
  fragment CampaignParticipationAttributes on Campaign {
    achievement {
      awardedTo
      id
      points
    }
    participants(limit: ${FacepileSizes.large}) {
      avatarUrl
      id
    }
    completionPoints
    participantsCount
    progressPercentForUser
    progressPointsForUser
    totalParticipationIndicators
    totalParticipationIndicatorsForUser
  }
`
export const CAMPAIGN_DETAILS_FRAGMENT = gql`
  fragment CampaignDetailsAttributes on Campaign {
    id
    description
    ended
    endsAt
    hasActionWithDisabledManualLogging
    isDeviceCompatible
    imageUrl
    lastDeviceLogSyncedAt
    name
    isParticipating
    registrationEnd
    registrationStart
    startsAt
    type
    achievement {
      awardedTo
      description
      id
      imageUrl
      name
    }
    ...CampaignParticipationAttributes
    acts {
      ...ActionAttributes
    }
    leaderboardTeamSets {
      id
      name
    }
  }
  ${ACTION_FRAGMENT}
  ${CAMPAIGN_PARTICIPATION_FRAGMENT}
`
