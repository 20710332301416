import React from 'react'
import PropTypes from 'prop-types'

const SkeletonLoading = ({
  component,
  header,
  height,
  'data-test': dataTest,
  unit,
  ...other
}) => {
  const Component = component

  return (
    <Component data-test={dataTest} {...other}>
      {header ? <div className="skeleton-text" /> : null}
      <div
        className="skeleton-rect"
        style={{
          height: `${height}${unit}`,
          width: '100%'
        }}
      />
    </Component>
  )
}

SkeletonLoading.propTypes = {
  component: PropTypes.string,
  'data-test': PropTypes.string,
  header: PropTypes.bool,
  height: PropTypes.number,
  unit: PropTypes.string
}

SkeletonLoading.defaultProps = {
  component: 'div',
  'data-test': 'skeleton-loading',
  header: false,
  height: 7,
  unit: 'rem'
}

export default SkeletonLoading