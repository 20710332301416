// Vendor
import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import PropTypes from 'prop-types'

// Wespire
import ExpandIcon from 'components/ui/expand_icon'
import Link from 'components/shared/link'
import Stack from 'components/ui/stack'

const UserAccordionInfo = ({ dataTestPrefix, expandTitle, title, links }) => {
  return (
    <Accordion
      className="bg-transparent border-top m-0"
      data-test={`${dataTestPrefix}-panel`}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          content: 'fs-1 fw-semi-bold ls-2 text-black-3 text-uppercase',
        }}
        className="px-4"
        expandIcon={<ExpandIcon title={expandTitle} />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails classes={{ root: 'p-0' }}>
        <Stack as="ul" className="list-none mt-0 px-4" space={3}>
          {links.map((link) => (
            <li data-test={`${dataTestPrefix}-name`} key={link.id}>
              <Link className="fs-2 pb-3 text-lititlenk" to={link.url}>
                {link.label}
              </Link>
            </li>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
UserAccordionInfo.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  expandTitle: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
}

export { UserAccordionInfo }
