// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import Paper from '@material-ui/core/Paper'

// WeSpire
import CommentsManager from 'components/application/comments/comments_manager'
import { useActivityGalleryModal } from 'components/shared/activity_gallery'
import { LikeTextButton } from 'components/application/likes/like_text_button'
import Divider from 'components/ui/divider'
import ImageButton from 'components/ui/image_button'
import { isNewForUser } from './utils'
import Link from 'components/shared/link'
import ScreenReader from 'components/ui/screen_reader_text'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import {
  currentUserPropType,
  withCurrentUser,
} from 'components/helpers/with_current_user'
import { UserNameQuery } from 'components/queries/username_query'
import { UserAttribution } from 'components/user/user_attribution'
import WysiwygContent from 'components/ui/wysiwyg_content'

// Sub-Components
import { NewsFeedItemAction } from './item_action'
import { NewsFeedAnalytics } from 'utilities/analytics'

const NewsFeedItemBase = ({
  actionTitle,
  as,
  className,
  commentable,
  comments,
  createdAt,
  currentUser,
  currentUserLikeId,
  endsAt,
  imageUrl,
  likableId,
  likableType,
  likeCount,
  message,
  orgName,
  showCommentContext,
  startsAt,
  storyId,
  taggedUsers,
  thumbnailUrl,
  type,
  user,
  verb,
}) => {
  const { openModal } = useActivityGalleryModal()

  const handleImageClickAndTrack = () => {
    NewsFeedAnalytics.storyPhotoClicked(storyId)
    openModal(storyId)
  }

  return (
    <Paper
      className={cx(className, {
        'flash-highlight': isNewForUser(currentUser.id, user.id, createdAt),
      })}
      component={as}
      data-test={`news-feed-item-${verb}`}
    >
      <UserAttribution
        avatarUrl={user.avatarUrl}
        className="px-3 py-3"
        createdAt={createdAt}
        profilePath={user.profilePath}
        user={user}
        userId={user.id}
      />

      {/* On some activity pages users can leave a comment which creates it's own story, we don't need to show the context in this case. EG: On the activity page we don't need to say "Commented on [ACTIVITY NAME]".
      We DO want this on other pages where the context is not obvious.
      */}
      {(verb !== 'commented' ||
        (verb === 'commented' && showCommentContext)) && (
        <NewsFeedItemAction
          actionTitle={actionTitle}
          className="mb-3 mt-0 px-3"
          endsAt={endsAt}
          orgName={orgName}
          startsAt={startsAt}
          type={type}
          verb={verb}
        />
      )}

      {message && (
        <WysiwygContent className="bg-black-6 m-0 mb-3 mx-3 px-3 py-2 rounded-2">
          {message}
        </WysiwygContent>
      )}

      {!!taggedUsers.length && (
        <p className="my-0 px-3 pb-2">
          {taggedUsers.map((user) => (
            <Link
              className="d-inline-block fs-2 mr-2"
              key={user.id}
              to={user.profilePath}
            >
              <span aria-hidden>
                @<UserNameQuery user={user} userId={user.id} />
              </span>
              <ScreenReader>
                <>
                  <UserNameQuery user={user} userId={user.id} />
                  {`'s profile`}
                </>
              </ScreenReader>
            </Link>
          ))}
        </p>
      )}

      {thumbnailUrl && imageUrl && (
        <ImageButton
          alt=""
          className="mnh-0 w-100"
          onClick={handleImageClickAndTrack}
          shape="square"
          src={thumbnailUrl}
          srText="Click to view fullscreen"
        />
      )}

      <Divider />

      <LikeTextButton
        className="ml-3"
        color="primary"
        likableId={likableId}
        likableType={likableType}
        likeCount={likeCount}
        likeId={currentUserLikeId}
      />

      <CommentsManager
        commentable={commentable}
        commentCreatorProps={{
          className: 'border-top p-3',
        }}
        comments={comments}
        commentsDisplayProps={{
          commentsListProps: {
            className: 'p-3 pb-2',
          },
          commentsSummaryProps: {
            className: 'd-inline-flex px-3 py-2',
          },
          shouldAlwaysRender: true,
        }}
        formRequirements={{ currentUser }}
      />
    </Paper>
  )
}

NewsFeedItemBase.defaultProps = {
  as: null,
  className: '',
  currentUserLikeId: null,
  endsAt: null,
  imageUrl: '',
  message: '',
  orgName: null,
  showCommentContext: false,
  startsAt: null,
  storyId: null,
  taggedUsers: [],
  thumbnailUrl: '',
}

NewsFeedItemBase.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  commentable: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  createdAt: PropTypes.string.isRequired,
  currentUser: currentUserPropType.isRequired,
  currentUserLikeId: PropTypes.string,
  endsAt: PropTypes.string,
  imageUrl: PropTypes.string,
  likableId: PropTypes.string.isRequired,
  likableType: PropTypes.string.isRequired,
  likeCount: PropTypes.number.isRequired,
  message: PropTypes.string,
  orgName: PropTypes.string,
  showCommentContext: PropTypes.bool,
  startsAt: PropTypes.string,
  storyId: PropTypes.string,
  taggedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profilePath: PropTypes.string.isRequired,
    })
  ),
  thumbnailUrl: PropTypes.string,
  type: PropTypes.string.isRequired,
  user: PropTypes.shape({
    avatarUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    profilePath: PropTypes.string.isRequired,
  }).isRequired,
  verb: PropTypes.string.isRequired,
}

const NewsFeedItemSkeleton = ({ as, ...other }) => (
  <SkeletonLoading as={as} header {...other} />
)

NewsFeedItemSkeleton.propTypes = {
  as: PropTypes.string,
}
NewsFeedItemSkeleton.defaultProps = {
  as: 'li',
}

const NewsFeedItem = withCurrentUser(NewsFeedItemBase, <NewsFeedItemSkeleton />)

export { NewsFeedItemBase, NewsFeedItem, NewsFeedItemSkeleton }
