// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@material-ui/core'

// WeSpire
import { UserProfileAchievements as Achievements } from './achievements'
import { UserProfileBasicInfo as BasicInfo } from './basic_info'
import { CurrentActivities } from './current_activities'
import CenteredContent from 'components/application/centered_content'
import { DocumentTitle } from 'components/shared/document_title'
import { EndedActivities } from './ended_activities'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { OverviewNewsFeed } from './overview_news_feed'
import { PageQuery } from 'components/queries/page_query'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { UserProfileSustainabilityImpact as SustainabilityImpact } from './sustainability_impact'
import { UserProfileStatsSummary as StatsSummary } from './stats_summary'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { UserProfileImpactByProgramThisYear as ImpactByProgramThisYear } from './impact_by_program_this_year'

const messages = defineMessages({
  allTimeImpactSummary: {
    defaultMessage: 'All Time Impact Summary',
    id: 'userProfile.allTimeImpactSummary',
  },
  myImpact: {
    defaultMessage: 'My Impact',
    id: 'userProfile.myImpact',
  },
  overview: {
    defaultMessage: 'Overview',
    id: 'userProfile.overview',
  },
  personalImpact: {
    defaultMessage: 'All Time Personal Impact',
    id: 'userProfile.personalImpact',
  },
})

const currentUserQuery = gql`
  query currentUserQuery {
    currentUser {
      id
      newsfeedItemCount
      visible
    }
  }
`
export const UserProfile = ({ history, match }) => {
  const { formatMessage } = intl
  const subPage = match.params.sub_page

  return (
    <PageQuery query={currentUserQuery}>
      {({ data: { currentUser } }) => {
        const pageName = sharedTranslations.profile

        const profileTabs = [
          { id: 1, name: formatMessage(messages.overview), slug: 'overview' },
          {
            id: 2,
            name: sharedTranslations.myActivities,
            slug: 'my_activities',
          },
          { id: 3, name: formatMessage(messages.myImpact), slug: 'my_impact' },
        ]

        const getSelectedTabSlug = (tab) => {
          // Guard against the initial tab from the URL being wrong.
          // In that case, we set our tab to the first tab and call
          // handleTabChange to update the URL.
          if (tab.some((tab) => tab.slug === subPage)) {
            return subPage
          } else {
            handleTabChange(null, tab[0].slug)
          }
        }

        const handleTabChange = (_event, tabSlug) => {
          if (tabSlug !== subPage) {
            history.push({
              pathname: `/profile/${tabSlug}`,
              search: history.location.search,
            })
          }
        }

        const currentTab =
          profileTabs.find(
            (tab) => tab.slug === getSelectedTabSlug(profileTabs)
          ) || profileTabs[0]

        return (
          <DocumentTitle title={`${currentTab.name} • ${pageName}`}>
            {maybeDisplayUserTour('userProfilePageCurrentUser')}
            <div className="bg-white border-bottom border-top position-relative z-1">
              <CenteredContent>
                <Tabs
                  onChange={handleTabChange}
                  scrollButtons="on"
                  textColor="secondary"
                  value={getSelectedTabSlug(profileTabs)}
                  variant="scrollable"
                >
                  {profileTabs.map((tab) => (
                    <Tab
                      classes={{
                        root: 'col-6 fs-3 px-2 py-3 text-transform-none mxw-none | col-md-3',
                        wrapper: 'flex-row',
                      }}
                      data-test="profile-tab"
                      key={tab.id}
                      label={tab.name}
                      value={tab.slug}
                    />
                  ))}
                </Tabs>
              </CenteredContent>
            </div>
            <Heading className="sr-only" level={1}>
              {sharedTranslations.profile}
            </Heading>
            <Heading className="sr-only" level={2}>
              {currentTab.name}
            </Heading>
            {subPage === 'overview' && (
              <div className="mt-5 px-3">
                <CenteredContent>
                  <Stack
                    className="row"
                    data-test="overview-content"
                    space={{ _: 5, lg: 0 }}
                  >
                    <Stack className="col-lg-4" space={5}>
                      <BasicInfo userId={currentUser.id} />
                      <StatsSummary
                        heading={formatMessage(messages.allTimeImpactSummary)}
                        layout="rows"
                        userId={currentUser.id}
                      />
                      <GraphQLFeatureFlag
                        error={null}
                        featureDisabledError={null}
                        featureName="sustainability"
                        loader={null}
                      >
                        <SustainabilityImpact userId={currentUser.id} />
                      </GraphQLFeatureFlag>
                      <Achievements userId={currentUser.id} />
                    </Stack>
                    <Stack className="col-lg-8" space={5}>
                      <OverviewNewsFeed
                        totalNewsfeedItemCount={currentUser.newsfeedItemCount}
                        visible={currentUser.visible}
                      />
                    </Stack>
                  </Stack>
                </CenteredContent>
              </div>
            )}
            {subPage === 'my_activities' && (
              <div data-test="my-activities-content">
                <CurrentActivities />
                <EndedActivities />
              </div>
            )}
            {subPage === 'my_impact' && (
              <div className="px-3" data-test="my-impact-content">
                <Stack as={CenteredContent} className="mt-5" space={5}>
                  <StatsSummary
                    heading={formatMessage(messages.personalImpact)}
                    layout="columns"
                    userId={currentUser.id}
                  />
                  <ImpactByProgramThisYear />
                </Stack>
              </div>
            )}
          </DocumentTitle>
        )
      }}
    </PageQuery>
  )
}

UserProfile.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sub_page: PropTypes.string,
    }),
  }).isRequired,
}
