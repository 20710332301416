import React from 'react'
import PropTypes from 'prop-types'

import { translations } from 'components/carbon_footprint/utilities/messages'
import Link from 'components/shared/link'
import { useBrandConfig } from 'utilities/hooks/useBrandConfig'

export const Links = ({ grandTotal, whyItMatters }) => {
  const { isConfigEnabled: isCarbonReductionMarketplaceEnabled } =
    useBrandConfig({
      feature: 'carbonReductionMarketplace',
      useNestedQuery: true,
    })

  return (
    <div className="d-flex flex-column">
      <Link
        className="[ text-link ] [ lh-lg ]"
        data-test="calculator-link"
        to="/carbon_calculator"
      >
        {grandTotal ? translations.recalculate : translations.calculate}
      </Link>
      {isCarbonReductionMarketplaceEnabled && (
        <Link
          className="[ text-link ] [ lh-lg ]"
          data-test="carbon-reduction-link"
          to="https://shop.southpole.com"
          target="_blank"
        >
          {translations.reduseEmissionImpact}
        </Link>
      )}
      {whyItMatters && (
        <Link
          className="[ text-link ] [ lh-lg ]"
          data-test="why-it-matters-link"
          target="_blank"
          to={whyItMatters}
        >
          {translations.whyMatters}
        </Link>
      )}
    </div>
  )
}

Links.propTypes = {
  grandTotal: PropTypes.number,
  whyItMatters: PropTypes.string,
}

Links.defaultProps = {
  grandTotal: 0,
  whyItMatters: null,
}
