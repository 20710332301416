// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { connect } from 'react-redux'
import { setToken } from 'redux/reducers/session'

// WeSpire
import { Redirect } from 'react-router'

class GoogleAuthForm extends React.Component {
  static propTypes = {
    setToken: PropTypes.func,
  }

  static defaultProps = {
    setToken: () => {},
  }

  state = {
    tokenSet: false,
  }

  handleChange = () => {
    const { setToken } = this.props

    let value
    value = this.tokenField.value

    if (value) {
      setToken(value)
      this.setState({ tokenSet: true })
    }
  }

  render() {
    const {
      state: { tokenSet },
      handleChange,
    } = this

    return tokenSet ? (
      <Redirect to="/take_action" />
    ) : (
      <div className="d-none">
        <input
          id="token_field"
          name="token_field"
          ref={(tokenField) => (this.tokenField = tokenField)}
          type="hidden"
        />

        <button id="trigger_button" onClick={handleChange} />
      </div>
    )
  }
}

export { GoogleAuthForm }
export default connect(null, { setToken })(GoogleAuthForm)
