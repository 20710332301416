// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { UserTourWithoutStore } from 'components/shared/user_tour'

// Small wrapper that makes this easier to use in rails.
// We default maybeOpen to true and export default to support the
// rails react_component helper.
const UserTourRails = ({ brandName, messageKey }) => {
  return (
    <UserTourWithoutStore
      brandName={brandName}
      forceOpen={false}
      invisibleOnLoad
      maybeOpen
      messageKey={messageKey}
    />
  )
}

UserTourRails.propTypes = {
  brandName: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
}

export default UserTourRails
