// Setup
import PropTypes from 'prop-types'
import React from 'react'

// Vendor
import { LoadingIndicator } from 'components/ui/loading_indicator'
import { Query } from '@apollo/client/react/components'

const WeQuery = ({
  children,
  error,
  fetchPolicy,
  loader,
  query,
  variables,
}) => (
  <Query fetchPolicy={fetchPolicy} query={query} variables={variables}>
    {(response) => {
      const data = response.data
      const fetchMore = response.fetchMore
      const hasError = response.error
      const isLoading = response.loading

      if (hasError) {
        // eslint-disable-next-line no-console
        console.error(response.error.message)
        return error
      }
      if (isLoading || !data) return loader

      return children({ data, fetchMore })
    }}
  </Query>
)

WeQuery.propTypes = {
  children: PropTypes.func.isRequired,
  error: PropTypes.element,
  fetchPolicy: PropTypes.oneOf([
    'cache-first',
    'network-only',
    'cache-only',
    'no-cache',
    'standby',
    'cache-and-network',
  ]),
  loader: PropTypes.element,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
}

WeQuery.defaultProps = {
  error: <p>Error...</p>,
  fetchPolicy: 'cache-first',
  loader: <LoadingIndicator />,
  variables: {},
}

export default WeQuery
