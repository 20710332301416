// Vendor
import { matchPath } from 'react-router-dom'

// WeSpire
import { sharedTranslations } from 'components/shared/translations'
import { getPointsDashboardTitle } from '../../points_dashboard/translations'
import { translations as carbonFootprintTranslations } from 'components/carbon_footprint/utilities/messages'

export const getPrimaryNavLinks = (
  ergsEnabled = false,
  {
    activitiesPath,
    dashboardPath,
    groupsPath,
    invitePath,
    ourImpactPath,
    teamsPath,
    donatePath,
  },
  ourImpactEnabled = false
) => [
  {
    href: dashboardPath,
    icon: 'home',
    id: sharedTranslations.dashboard,
    shouldDisplay: true,
  },
  {
    href: activitiesPath,
    icon: 'dashboard',
    id: sharedTranslations.takeAction,
    shouldDisplay: true,
  },
  {
    href: ourImpactPath,
    icon: 'bar_chart',
    id: sharedTranslations.ourImpact,
    shouldDisplay: ourImpactEnabled,
  },
  {
    href: ergsEnabled ? groupsPath : teamsPath,
    icon: 'people',
    id: ergsEnabled ? sharedTranslations.groups : sharedTranslations.teams,
    shouldDisplay: true,
  },
  {
    href: invitePath,
    icon: 'mail',
    id: sharedTranslations.invite,
    shouldDisplay: true,
  },
]

export const getSecondaryNavLinks = ({
  achievementsPath,
  recognizePath,
  searchPath,
  settingsPath,
  supportPath,
}) => [
  {
    href: searchPath,
    id: sharedTranslations.search,
  },
  {
    href: recognizePath,
    id: sharedTranslations.recognition,
  },
  {
    href: achievementsPath,
    id: sharedTranslations.achievements,
  },
  {
    href: settingsPath,
    id: sharedTranslations.settings,
  },
  {
    href: supportPath,
    id: sharedTranslations.support,
  },
]

export const getDonationHistoryLink = {
  href: '/users/me/donation_history',
  id: sharedTranslations.donationHistory,
}

export const getVolunteerHistoryLink = {
  href: '/users/me/volunteer_history',
  id: sharedTranslations.volunteerHistory,
}

export const getPointsDashboardLink = {
  href: '/users/me/points_history',
  id: getPointsDashboardTitle(),
}

export const getCarbonFootprintLink = {
  href: '/carbon_footprint',
  id: carbonFootprintTranslations.carbonFootprint,
}

export const getUserProfileLink = {
  href: '/profile',
  id: sharedTranslations.profile,
}

export const isRoutePreview = (url, routes) =>
  routes.some((route) => {
    const match = matchPath(url, {
      exact: route.exact,
      path: route.path,
    })

    return match && match.isExact && route.isPreview
  })
