// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { Image } from 'components/ui/image'

/* eslint-disable sort-keys */
const AvatarDimensions = {
  sm: '32',
  md: '48',
  lg: '64',
  xl: '128',
}
const AvatarSizes = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xlarge: 'xl',
}
const FacepileSizes = {
  small: 3,
  large: 5,
}
const getAvatarSrc = (image, sizeValue) => image
/* eslint-enable sort-keys */

const Avatar = ({ className, image, size, withBorder, ...other }) => {
  const sizeValue = AvatarDimensions[size]
  const src = getAvatarSrc(image, sizeValue)

  return (
    <Image
      {...other}
      className={cx('flex-shrink-none rounded-circle', className, {
        'border border-white': withBorder,
      })}
      crop="fill"
      dpr="2.0" // TODO: Figure out why this image doesn't auto-detect DPR
      gravity="face:auto"
      height={sizeValue}
      src={src}
      width={sizeValue}
    />
  )
}

Avatar.defaultProps = {
  alt: '',
  className: '',
  size: 'md',
  withBorder: false,
}

Avatar.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(AvatarSizes)),
  withBorder: PropTypes.bool,
}

export { Avatar, AvatarDimensions, AvatarSizes, FacepileSizes, getAvatarSrc }
export default Avatar
