import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  nameCannotBeBlank: {
    defaultMessage: 'Name cannot be blank.',
    id: 'organizationFormUnwrapped.nameCannotBeBlank',
  },
  nameOfOrganizationHelpText: {
    defaultMessage: 'Name of the Organization you would like to add.',
    id: 'organizationFormUnwrapped.nameOfOrganizationHelpText',
  },
  newOrganizationWasSuccessfullyAdded: {
    defaultMessage: 'New organization was successfully created.',
    id: 'organizationFormUnwrapped.newOrganizationWasSuccessfullyAdded',
  },
  pageTitle: {
    defaultMessage: 'Add new Organization',
    id: 'organizationFormUnwrapped.pageTitle',
  },
  saveThisOrganization: {
    defaultMessage: 'save this Organization',
    id: 'organizationFormUnwrapped.saveThisOrganization',
  },
  volunteerHistoryForm: {
    defaultMessage: 'Volunteer History Form',
    id: 'organizationFormUnwrapped.volunteerHistoryForm',
  },
})
