// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { LoadingIndicator } from 'components/ui/loading_indicator'

/** Extends <LoadingIndicator/>. Horizontally centered with extra vertical space
 * wrapping the indicator. */
const CenteredPaddedLoadingIndicator = ({
  'data-test': dataTest,
  padding,
  wrapperProps,
  ...other
}) => (
  <div
    {...wrapperProps}
    className={cx(`content-center | ${padding}`, wrapperProps.className)}
  >
    <LoadingIndicator data-test={dataTest} {...other} />
  </div>
)

CenteredPaddedLoadingIndicator.propTypes = {
  className: PropTypes.string,
  'data-test': PropTypes.string,
  padding: PropTypes.string,
  wrapperProps: PropTypes.object,
}

CenteredPaddedLoadingIndicator.defaultProps = {
  className: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'centered-loading-indicator',
  padding: 'py-6',
  wrapperProps: {
    className: null,
  },
}

export default CenteredPaddedLoadingIndicator
