import { activityGalleryFetchLimit } from 'components/shared/activity_gallery'
import { CAMPAIGN_ACTIVITY_GALLERY_QUERY } from 'graphql/queries'
import { getNewEndCursor } from 'utilities/get_new_end_cursor'
import { sortByNewest } from 'utilities/sort'
import { client } from 'utilities/we_apollo'

// Vendor
import cloneDeep from 'lodash/cloneDeep'

export const updateCacheActivityGalleryQueryWithEvents = (
  activityId,
  newsfeedItems = [],
  before = true
) => {
  try {
    if (newsfeedItems.length === 0) return true

    const campaignConfig = {
      query: CAMPAIGN_ACTIVITY_GALLERY_QUERY,
      variables: {
        activityId,
        first: activityGalleryFetchLimit,
      },
    }

    const { campaign } = client.readQuery(campaignConfig)

    const oldNodes = campaign.newsfeed.nodes

    const newItemsWithImage = sortByNewest(
      newsfeedItems.filter(
        (event) =>
          event.imageUrl &&
          event.storyId &&
          !oldNodes.find((node) => node.id === event.id)
      ),
      'createdAt'
    )

    let newNodes = []
    let newPageInfo = cloneDeep(campaign.newsfeed.pageInfo)
    let totalCount = campaign.newsfeed.totalCount

    if (before) {
      newNodes = [...newItemsWithImage, ...oldNodes]
      totalCount += newItemsWithImage.length
    } else {
      newPageInfo.endCursor = getNewEndCursor(
        newPageInfo.endCursor,
        newItemsWithImage.length
      )
      newNodes = [...oldNodes, ...newItemsWithImage]
    }

    const data = {
      campaign: {
        ...campaign,
        newsfeed: {
          ...campaign.newsfeed,
          nodes: newNodes,
          pageInfo: newPageInfo,
          totalCount,
        },
      },
    }

    client.writeQuery({
      data,
      ...campaignConfig,
    })

    return true
  } catch (error) {
    // No event to add
    return false
  }
}
