// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { Skeleton } from '@material-ui/lab'

export const SkeletonLoading = ({
  as,
  header,
  height,
  'data-test': dataTest,
  ...other
}) => {
  const Component = as

  return (
    <Component data-test={dataTest} {...other}>
      {header ? <Skeleton variant="text" /> : null}
      <Skeleton height={height} variant="rect" />
    </Component>
  )
}

SkeletonLoading.propTypes = {
  as: PropTypes.string,
  'data-test': PropTypes.string,
  header: PropTypes.bool,
  height: PropTypes.number,
}

SkeletonLoading.defaultProps = {
  as: 'div',
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'skeleton-loading',
  header: false,
  height: 100,
}
