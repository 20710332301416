import React from 'react'
import { ListItem, ListItemText } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { abbreviatedDate } from 'utilities/date_formatter'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { messages } from '../utilities/messages'

const { formatMessage } = intl

const BroadcastListItem = ({ broadcast, className, urlPath }) => {
  return (
    <ListItem
      className={className}
      data-test="broadcast-item"
      divider
      key={broadcast.id}
    >
      <Link to={urlPath}>
        <Image
          alt={broadcast.headline}
          aspectRatio="1:1"
          className="[ border rounded-0 ] [ text-blue ]"
          crop="fill"
          height="100"
          shape="square"
          src={broadcast.image}
          width="100"
        />
      </Link>
      <ListItemText
        className="px-3"
        primary={
          <Link className="[ fs-4 ] [ text-blue ]" to={urlPath}>
            {broadcast.headline}
          </Link>
        }
        secondary={
          <>
            <span className="[ fs-2 ] [ fw-semi-bold ]">{`${formatMessage(
              messages.published
            )}: `}</span>
            <span className="fs-2">
              {abbreviatedDate(broadcast.publishedAt)}
            </span>
          </>
        }
      />
    </ListItem>
  )
}

BroadcastListItem.propTypes = {
  broadcast: PropTypes.shape({
    headline: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    publishedAt: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  urlPath: PropTypes.string.isRequired,
}

BroadcastListItem.defaultProps = {
  className: null,
}

export { BroadcastListItem }
