// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import DisableButton from 'components/ui/disable_button'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  buttonText: {
    defaultMessage: 'Submit',
    description: 'Default label for a button that submits a form',
    id: 'submitButton.buttonText',
  },
})

const { formatMessage } = intl

const SubmitButton = ({ children, disabled, isSubmitting, ...props }) => (
  <DisableButton
    {...props}
    disabled={disabled}
    isLoading={isSubmitting}
    type="submit"
  >
    {children}
  </DisableButton>
)

SubmitButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}

SubmitButton.defaultProps = {
  children: formatMessage(messages.buttonText),
  disabled: false,
  isSubmitting: false,
}

export default SubmitButton
