// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import ParticipantsSummary from 'components/application/participants_summary'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import { sharedMessages } from 'components/shared/translations'

const messages = defineMessages({
  activityOverview: {
    defaultMessage: 'Activity Overview',
    id: 'ideaBoardParticipation.activityOverview',
  },
})

/** To show the number of participants and stats of an Idea Board */
const IdeaBoardParticipation = ({
  avatars,
  participantsCount,
  totalIdeasSubmitted,
  totalVotes,
  ...other
}) => (
  <>
    <ScreenReader
      as="h2"
      text={intl.formatMessage(messages.activityOverview)}
    />
    <Stack {...other} space={3}>
      <ParticipantsSummary
        avatarImages={avatars}
        participantsCount={participantsCount}
        variant="stacked"
      />
      <div className="row vertical-gutters">
        <Stat
          amount={totalIdeasSubmitted}
          className="col-auto"
          dataTest="stat-ideas-submitted"
          label={intl.formatMessage(sharedMessages.ideasSubmitted, {
            count: totalIdeasSubmitted,
          })}
          size={7}
        />
        <Stat
          amount={totalVotes}
          className="col-auto"
          label={intl.formatMessage(sharedMessages.votes, {
            count: totalVotes,
          })}
          size={7}
        />
      </div>
    </Stack>
  </>
)

IdeaBoardParticipation.propTypes = {
  avatars: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  participantsCount: PropTypes.number.isRequired,
  totalIdeasSubmitted: PropTypes.number.isRequired,
  totalVotes: PropTypes.number.isRequired,
}

export { IdeaBoardParticipation }
