import gql from 'graphql-tag'

export const BROADCASTSQUERY = gql`
  query BroadcastsQuery {
    broadcasts(broadcastLimit: "10") {
      headline
      id
      image
      publishedAt
    }
  }
`

export const BROADCASTQUERY = gql`
  query BroadcastQuery($id: ID!) {
    broadcast(id: $id) {
      headline
      id
      image
      message
      publishedAt
    }
  }
`
