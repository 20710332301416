// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import HeroDescription from './description'
import HeroLabel from './label'
import HeroTitle from './title'
import { Image } from 'components/ui/image'
import Stack from 'components/ui/stack'

/** Full-width container with overlay-darkened background-image and vertically
    centered text. Height starts at 18rem (288px) on all devices. Icon is hidden
    on xs devices. Label, icon, and description are optional. */
const Hero = ({
  description,
  descriptionProps,
  icon,
  imgUrl,
  label,
  labelProps,
  title,
  titleProps,
  ...other
}) => (
  <figure
    {...other}
    className={cx('hero hero--centered-content', other.className)}
    data-test="hero"
  >
    {imgUrl && (
      <Image
        alt=""
        className="hero__image"
        data-object-fit
        height="288"
        responsive
        src={imgUrl}
      />
    )}
    <figcaption className="hero__content">
      <Stack
        as={CenteredContent}
        className={cx('px-5 py-3 px-xl-0 w-100', {
          'text-center': !description,
        })}
        space={2}
      >
        {icon}
        {label && <HeroLabel {...labelProps} text={label} />}
        <HeroTitle {...titleProps} text={title} />
        {description && (
          <HeroDescription {...descriptionProps} text={description} />
        )}
      </Stack>
    </figcaption>
  </figure>
)

Hero.propTypes = {
  /** sentence-case secondary text */
  description: PropTypes.string,
  /** props applied <HeroDescription> */
  descriptionProps: PropTypes.object,
  /** consumer-provided icon */
  icon: PropTypes.node,
  /** background image URL */
  imgUrl: PropTypes.string,
  /** brief uppercase text */
  label: PropTypes.string,
  /** props applied to the <HeroLabel> */
  labelProps: PropTypes.object,
  /** title-case primary text */
  title: PropTypes.string.isRequired,
  /** props applied to the <HeroTitle> */
  titleProps: PropTypes.object,
}

Hero.defaultProps = {
  description: null,
  descriptionProps: {},
  icon: null,
  imgUrl: null,
  label: null,
  labelProps: {},
  titleProps: {},
}

export default Hero
