import React from 'react'

import Headline from 'src/common/Headline'

const TextHero = ({ subtitle, title, ...other }) => {
  return (
    <>
      <Headline className="max-w-[63.5rem] text-center" element="h1">
        {title}
      </Headline>
      {subtitle && (
        <span className="subtitle black-2 max-w-3xl mt-4 px-4 text-center">
          {subtitle}
        </span>
      )}
    </>
  )
}

export default TextHero
