// Setup
import React, { useRef, useState } from 'react'
import Formsy from 'formsy-react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { ActFields } from './act_fields'
import { client } from 'utilities/we_apollo'
import { displayBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormFieldsetGroup from 'components/form/fieldset_group'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { campaignLocalesPath } from './routes'
import { sharedTranslations } from 'components/shared/translations'
import Link from 'components/shared/link'
import { NameAndDescriptionFields } from './name_and_description_fields'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  campaignActions: {
    defaultMessage: 'Campaign Actions',
    id: 'campaignAdditionalLocalesForm.campaignActions',
  },
  nameAndDescription: {
    defaultMessage: 'Name & Description',
    id: 'campaignAdditionalLocalesForm.nameAndDescription',
  },
  operation: {
    defaultMessage: 'You have successfully saved your changes to the Locale.',
    id: 'campaignAdditionalLocalesForm.operation',
  },
  saveLocale: {
    defaultMessage: 'Save Locale',
    id: 'campaignAdditionalLocalesForm.saveLocale',
  },
})

const CampaignAdditionalLocalesForm = ({
  achievementDescription,
  achievementName,
  acts,
  additionalLocale,
  campaignDefaultName,
  campaignDescription,
  campaignId,
  campaignType,
  className,
  defaultLocale,
  locale,
  localeAchievementDescription,
  localeAchievementName,
  localeCampaignDescription,
  localeName,
}) => {
  const { formatMessage } = intl
  const [formErrors, setFormErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const campaignForm = useRef()

  const getActsAttributes = (values) => {
    const actsValues = Object.entries(values).filter((value) => {
      const formsyName = value[0]
      return formsyName.includes('actionTranslation')
    })

    const sortedResponses = Object.values(actsValues).reduce(
      (results, item) => {
        // Get act id from field name
        const key = item[0].split('-')[1]
        // If act id has not been added yet, add to the object
        if (!results[key]) {
          results[key] = [key]
        }

        results[key].push(item[1])

        return results
      },
      {}
    )

    return Object.values(sortedResponses).map((value) => {
      const id = parseInt(value[0])
      const nameTranslation = value[1]
      const descriptionTranslation = value[2]
      return {
        description: descriptionTranslation,
        id: id,
        name: nameTranslation,
      }
    })
  }

  const UPDATE_LOCALE_VALUES = gql`
    mutation updateCampaignAdditionalLocale(
      $achievementDescription: String
      $achievementName: String
      $acts: [ActAttributes!]
      $campaignDescription: String
      $campaignId: ID!
      $campaignName: String
      $locale: String!
    ) {
      updateCampaignAdditionalLocale(
        achievementDescription: $achievementDescription
        achievementName: $achievementName
        acts: $acts
        campaignDescription: $campaignDescription
        campaignId: $campaignId
        campaignName: $campaignName
        locale: $locale
      ) {
        errors
      }
    }
  `

  const handleSubmit = ({
    achievement_description_translation,
    achievement_translation,
    description_translation,
    name_translation,
    ...model
  }) => {
    setIsSubmitting(true)
    getActsAttributes(model)

    client
      .mutate({
        mutation: UPDATE_LOCALE_VALUES,
        variables: {
          achievementDescription: achievement_description_translation,
          achievementName: achievement_translation,
          acts: getActsAttributes(model),
          campaignDescription: description_translation,
          campaignId: campaignId,
          campaignName: name_translation,
          locale: locale,
        },
      })
      .then(
        ({
          data: {
            updateCampaignAdditionalLocale: { errors },
          },
        }) => {
          if (errors) {
            setIsSubmitting(false)
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          } else {
            setIsSubmitting(false)
            displayBanner({
              content: formatMessage(messages.operation),
              variant: 'success',
            })
          }
        }
      )
  }

  const handleTranslate = (e) => {
    const translateConfirmationQuestion =
      sharedTranslations.translateConfirmationQuestion
    if (confirm(translateConfirmationQuestion)) {
      return true
    } else {
      e.preventDefault()
    }
  }

  const handleInvalidSubmit = () => {
    const errors = getFieldsWithErrors()
    displayBanner({
      as: 'div',
      content: <ErrorList errors={errors} />,
      variant: 'error',
    })
  }
  const getFieldsWithErrors = () => {
    const inputs = campaignForm.current.inputs
    const haveErrors = ({ isValid }) => !isValid()
    return inputs
      .filter(haveErrors)
      .map(({ props }) => [
        props.name + ' ',
        Object.values(props.validationErrors).join(','),
      ])
  }

  return (
    <Formsy
      className={className}
      noValidate
      onInvalidSubmit={() => handleInvalidSubmit()}
      onValidSubmit={(model) => handleSubmit(model)}
      ref={campaignForm}
    >
      <Stack className="mt-4" space={4}>
        <FormFieldsetGroup>
          <div className="d-flex px-4 mt-2 mb-2">
            <Heading className="text-uppercase" level={2}>
              {formatMessage(messages.nameAndDescription)}
            </Heading>
            <Link
              className="MuiButton-containedPrimary MuiButton-root MuiButtonBase-root ml-auto text-white"
              onClick={handleTranslate}
              to={`/management_panel/campaigns/${campaignId}/locales/${locale}/translate`}
            >
              {sharedTranslations.translate}
            </Link>
          </div>
          <NameAndDescriptionFields
            achievementDescription={achievementDescription}
            achievementName={achievementName}
            campaignDefaultName={campaignDefaultName}
            campaignDescription={campaignDescription}
            campaignType={campaignType}
            className="m-4 mt-0"
            localeAchievementDescription={localeAchievementDescription}
            localeAchievementName={localeAchievementName}
            localeCampaignDescription={localeCampaignDescription}
            localeName={localeName}
          />
          <Heading className="mt-2 px-4 text-uppercase" level={2}>
            {formatMessage(messages.campaignActions)}
          </Heading>
          <ActFields
            acts={acts}
            additionalLocale={additionalLocale}
            campaignId={campaignId}
            campaignType={campaignType}
            className="m-4"
            defaultLocale={defaultLocale}
          />
        </FormFieldsetGroup>
        <Paper className="m-4">
          <FormActionBar
            backPath={campaignLocalesPath(campaignId)}
            backText={sharedTranslations.back}
            className="p-3 pt-3"
            space={0}
          >
            <FormActionBarSubmitButton
              data-test="save"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
            >
              {formatMessage(messages.saveLocale)}
            </FormActionBarSubmitButton>
          </FormActionBar>
        </Paper>
      </Stack>
    </Formsy>
  )
}

CampaignAdditionalLocalesForm.propTypes = {
  achievementDescription: PropTypes.string,
  achievementName: PropTypes.string,
  acts: PropTypes.array.isRequired,
  additionalLocale: PropTypes.string.isRequired,
  campaignDefaultName: PropTypes.string,
  campaignDescription: PropTypes.string,
  campaignId: PropTypes.string.isRequired,
  campaignType: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultLocale: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  localeAchievementDescription: PropTypes.string,
  localeAchievementName: PropTypes.string,
  localeCampaignDescription: PropTypes.string,
  localeName: PropTypes.string,
}

CampaignAdditionalLocalesForm.defaultProps = {
  achievementDescription: null,
  achievementName: null,
  campaignDefaultName: null,
  campaignDescription: null,
  className: null,
  localeAchievementDescription: null,
  localeAchievementName: null,
  localeCampaignDescription: null,
  localeName: '',
}

export { CampaignAdditionalLocalesForm }
