// Vendor
import React from 'react'
import cx from 'classnames'
import { Field } from 'formik'

const InputChoice = ({ checked, className, explainer, label, name, type, value, ...other }) => {
  return (
    <div className={cx(className, 'flex gap-3')}>
      <Field
        {...other}
        checked={checked}
        className="border-[0.0625rem] border-grayscale-2 px-4 py-3 h-5 mt-1"
        name={name}
        type={type}
        value={value}
      />
      {label && (
        <label>
          <span className="text-black-1 text-lg font-light">{label}</span>
          {explainer && (
            <p className="text-sm leading-4 text-black-3 font-extralight">
              {explainer}
            </p>
          )}
        </label>
      )}
    </div>
  )
}

export default InputChoice
