// Converts a single flat array of objects into separate arrays of objects
// based on the value of each object's specified key.
export var groupBy = (items, key) =>
  items.reduce((groups, item) => {
    const val = item[key]
    const group = groups.find((group) => group[key] === val)

    if (group) {
      group.items.push(item)
    } else {
      groups.push({
        items: [item],
        [key]: val,
      })
    }

    return groups
  }, [])

// Converts a single flat array of objects into separate arrays of objects
// based on the year of each object's specified date.
export var groupByYear = (items, dateKey) =>
  items.reduce((groups, item) => {
    const dateString = item[dateKey]
    let year = null
    let yearGroup = null

    if (dateString) {
      year = new Date(dateString).getFullYear()
      yearGroup = groups.find((group) => group.year === year)
    }

    if (yearGroup) {
      yearGroup.items.push(item)
    } else {
      groups.push({
        items: [item],
        year: year,
      })
    }

    return groups
  }, [])

// Converts a single flat array of objects into separate arrays of objects
// based on the year of each object's specified date.
export var groupByMonth = (items, dateKey) => {
  return items.reduce((groups, item) => {
    const dateString = item[dateKey]
    let month = null
    let year = null
    let dateGroup = null

    if (dateString) {
      let date = new Date(dateString)
      month = date.getMonth()
      year = date.getFullYear()
      dateGroup = groups.find(
        (group) => group.month === month && group.year === year
      )
    }

    if (dateGroup) {
      dateGroup.items.push(item)
    } else {
      groups.push({
        items: [item],
        month: month,
        year: year,
      })
    }

    return groups
  }, [])
}
