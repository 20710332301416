// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { CloudinaryImageThumbnailUrl } from 'utilities/cloudinary_utils'
import ImageButton from 'components/ui/image_button'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  altText: {
    defaultMessage: 'Attachment for {title}',
    id: 'ideaImageButton.altText',
  },
  fullscreen: {
    defaultMessage: 'Click to view fullscreen',
    id: 'ideaImageButton.fullscreen',
  },
})

const IdeaImageButton = ({ onClick, url, title }) => {
  const src = new CloudinaryImageThumbnailUrl(url).create()
  const { formatMessage } = intl

  return (
    <ImageButton
      alt={formatMessage(messages.altText, { title })}
      onClick={onClick}
      src={src}
      srText={formatMessage(messages.fullscreen)}
    />
  )
}

IdeaImageButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default IdeaImageButton
