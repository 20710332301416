// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'

const ProgressBarTop = ({ title, progress, goal }) => (
  <Stack className="[ d-flex justify-content-between ] [ fw-semi-bold ls-2 text-black-3 text-uppercase ]">
    <p className="fs-1" data-test="progress-bar-title">
      {title}
    </p>
    <p className="fs-1" data-test="progress-bar-ratio">
      {progress} / {goal}
    </p>
  </Stack>
)

ProgressBarTop.propTypes = {
  goal: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  title: PropTypes.string,
}

ProgressBarTop.defaultProps = {
  title: 'Your progress',
}

export { ProgressBarTop }
