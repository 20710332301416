import { isAfter, isBetween } from 'utilities/date_utils'

/* eslint-disable sort-keys */
// This reflects the combined status of the user and campaign, taking in
// to account, type, registration open/closed, started or not, and registered or not.
// See below for how each of these is defined.
export const CampaignUserStatus = Object.freeze({
  NOT_REGISTERED: 0,
  WAITING_TO_COMPETE: 1,
  PARTICIPATING: 2,
  CAN_STILL_REGISTER: 3,
  MISSED_REGISTRATION: 4,
})
/* eslint-enable sort-keys */

export class CampaignModel {
  constructor(campaignData) {
    // Copy data returned from GET_CAMPAIGN_BY_ID
    this.achievement = campaignData.achievement
    this.acts = campaignData.acts
    this.completionPoints = campaignData.completionPoints
    this.description = campaignData.description
    this.ended = campaignData.ended
    this.endsAt = campaignData.endsAt
    this.hasActionWithDisabledManualLogging =
      campaignData.hasActionWithDisabledManualLogging
    this.isDeviceCompatible = campaignData.isDeviceCompatible
    this.id = campaignData.id
    this.imageUrl = campaignData.imageUrl
    this.lastDeviceLogSyncedAt = campaignData.lastDeviceLogSyncedAt
    this.leaderboardTeamSets = campaignData.leaderboardTeamSets
    this.name = campaignData.name
    this.participants = campaignData.participants
    this.participantsCount = campaignData.participantsCount
    this.progressPercentForUser = campaignData.progressPercentForUser
    this.progressPointsForUser = campaignData.progressPointsForUser
    this.registrationEnd = campaignData.registrationEnd
    this.registrationStart = campaignData.registrationStart
    this.startsAt = campaignData.startsAt
    this.totalParticipationIndicators =
      campaignData.totalParticipationIndicators
    this.totalParticipationIndicatorsForUser =
      campaignData.totalParticipationIndicatorsForUser
    this.type = campaignData.type

    // IS helpers.
    this.isCampaign = this.type === 'campaign'
    this.isCompetition = !this.isCampaign
    this.isRegistrationOpen = isBetween(
      this.registrationStart,
      this.registrationEnd
    )

    // HAS helpers.
    this.hasEnded = campaignData.ended
    this.hasStarted = isAfter(this.startsAt)

    // Calculate our campaign user status.
    const registered = campaignData.isParticipating
    if (this.isCampaign || (this.hasStarted && registered)) {
      // Campaigns (not competitions) have either started, in which case we consider
      // all users as participating (can take actions normally) OR it has ended
      // which is guarded elsewhere.
      this.status = CampaignUserStatus.PARTICIPATING
    } else if (this.isRegistrationOpen && !this.hasStarted && !registered) {
      this.status = CampaignUserStatus.NOT_REGISTERED
    } else if (!this.hasStarted && registered) {
      this.status = CampaignUserStatus.WAITING_TO_COMPETE
    } else if (this.isRegistrationOpen && this.hasStarted && !registered) {
      this.status = CampaignUserStatus.CAN_STILL_REGISTER
    } else if (!this.isRegistrationOpen && !registered) {
      this.status = CampaignUserStatus.MISSED_REGISTRATION
    }

    // Determine whether or not actions can be completed by this user.
    this.canTakeActions =
      !this.hasEnded &&
      [
        CampaignUserStatus.PARTICIPATING,
        CampaignUserStatus.CAN_STILL_REGISTER,
      ].includes(this.status)
  }
}
