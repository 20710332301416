// Vendor
import React, { useRef } from 'react'
import Button from '@material-ui/core/Button'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { ActivityGalleryItem } from './item'
import { ActivityGalleryModal, useActivityGalleryModal } from './modal'
import { useQuery } from '@apollo/client'
import Stack from 'components/ui/stack'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { CAMPAIGN_ACTIVITY_GALLERY_QUERY } from 'graphql/queries'
import { PARTICIPATION_EVENT_ACTIVITY_GALLERY_QUERY } from 'graphql/queries'
import { ActivityGalleryAnalytics } from 'utilities/analytics'
import { intl } from 'utilities/localization'
import { client } from 'utilities/we_apollo'

const ACTIVITIES = {
  campaign: 'campaign',
  participationEvent: 'participationEvent',
}

const ACTIVITY_QUERIES = {
  campaign: CAMPAIGN_ACTIVITY_GALLERY_QUERY,
  participationEvent: PARTICIPATION_EVENT_ACTIVITY_GALLERY_QUERY,
}

const activityGalleryFetchLimit = 10
const activityGalleryThumbnailLimit = 4

const messages = defineMessages({
  galleryErrorMessage: {
    defaultMessage: 'the gallery',
    id: 'activityGallery.galleryErrorMessage',
  },
  shareYourPhoto: {
    defaultMessage: 'No photos have been shared yet',
    id: 'activityGallery.shareYourPhoto',
  },
  viewGallery: {
    defaultMessage: 'View {imageCount} Images in the Gallery',
    id: 'activityGallery.viewGallery',
  },
})

const ActivityGallery = ({
  activityId,
  activityFieldName,
  previewWithNoData,
}) => {
  const { formatMessage } = intl
  const isLoading = useRef(false)
  const variables = { activityId, first: activityGalleryFetchLimit }
  const { openModal } = useActivityGalleryModal()

  const { data, loading, error, fetchMore } = useQuery(
    ACTIVITY_QUERIES[activityFieldName],
    {
      client,
      fetchPolicy: 'cache-and-network',
      variables,
    }
  )

  const handleImageClickAndTrack = (storyId) => () => {
    ActivityGalleryAnalytics.galleryButtonClicked({ storyId })
    openModal(storyId)
  }

  const loadMore =
    (after) =>
    ({ start, end }) => {
      if (isLoading.current) return

      isLoading.current = true
      start()

      let newNodes = []

      return fetchMore({
        updateQuery: (previousResult, { fetchMoreResult }) => {
          let result = previousResult
          const oldNodes = previousResult[activityFieldName].newsfeed.nodes
          newNodes = fetchMoreResult[activityFieldName].newsfeed.nodes

          if (newNodes.length) {
            result = cloneDeep(fetchMoreResult)
            result[activityFieldName].newsfeed.nodes = [
              ...oldNodes,
              ...newNodes,
            ]
          }

          return result
        },
        variables: {
          ...variables,
          cursor: after,
        },
      }).then(() => {
        end(newNodes)
        isLoading.current = false
      })
    }

  if (error && previewWithNoData) {
    return (
      <Stack as={Paper} data-test="gallery">
        <p className="p-3">{formatMessage(messages.shareYourPhoto)}</p>
      </Stack>
    )
  } else if (error) {
    return (
      <MissingResourceSection
        as={Paper}
        errorDetails={formatMessage(messages.galleryErrorMessage)}
      />
    )
  }

  if (loading && !data) {
    return <SkeletonLoading />
  }

  const galleryNewsfeed = data[activityFieldName].newsfeed.nodes
  const imageCount = data[activityFieldName].newsfeed.totalCount
  const endCursor = data[activityFieldName].newsfeed.pageInfo.endCursor

  return (
    <>
      <Stack as={Paper} data-test="gallery">
        {imageCount === 0 ? (
          <p className="p-3">{formatMessage(messages.shareYourPhoto)}</p>
        ) : (
          <Stack divider="default">
            <div className="p-3 pb-0">
              <ul className="[ row ] [ my-0 p-0 ]">
                {galleryNewsfeed
                  .slice(0, activityGalleryThumbnailLimit)
                  .map((image) => (
                    <ActivityGalleryItem
                      imageUrl={image.imageUrl}
                      key={image.id}
                      onClick={handleImageClickAndTrack(image.storyId)}
                    />
                  ))}
              </ul>
            </div>
            <p className="p-2 text-center">
              <Button
                classes={{
                  label: 'text-link text-transform-none',
                  root: 'my-1 px-2 py-1',
                }}
                data-test="view-gallery-modal"
                onClick={handleImageClickAndTrack(galleryNewsfeed[0].storyId)}
              >
                {formatMessage(messages.viewGallery, {
                  imageCount,
                })}
              </Button>
            </p>
          </Stack>
        )}
      </Stack>
      <ActivityGalleryModal
        loadMore={loadMore(endCursor)}
        newsfeedItems={galleryNewsfeed}
        totalCount={imageCount}
      />
    </>
  )
}

ActivityGallery.propTypes = {
  activityFieldName: PropTypes.oneOf(Object.values(ACTIVITIES)).isRequired,
  activityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  previewWithNoData: PropTypes.bool,
}

ActivityGallery.defaultProps = {
  previewWithNoData: false,
}

export {
  ACTIVITIES,
  ActivityGallery,
  activityGalleryFetchLimit,
  ActivityGalleryModal,
  useActivityGalleryModal,
}
