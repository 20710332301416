// Setup
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Scroll from 'react-scroll'

// Sub-Components
import CommentsDisplay from './comments_display'
import CommentCreator from './comment_creator'

const CommentsManager = ({
  comments: commentsFromProps,
  commentable,
  commentCreatorProps,
  commentsDisplayProps,
  displayCommentSummary,
  formRequirements,
  setCommentFormRef,
}) => {
  const [comments, setComments] = useState(commentsFromProps)
  const commentFormRef = useRef(null)
  const _setCommentFormRef = (form) => {
    commentFormRef.current = form
  }

  const _scrollContainerId = () => {
    return `commentable-${commentable.id}`
  }

  function _scrollToLastComment() {
    Scroll.animateScroll.scrollToBottom({
      containerId: _scrollContainerId(),
      duration: 300,
      smooth: 'easeInOutCubic',
    })
  }

  const handleAddComment = (newComment) => {
    setComments(comments.concat([newComment]))
    _scrollToLastComment()
  }

  const handleFocusCommentForm = () => {
    commentFormRef.current?.focus()
  }

  return (
    <>
      <CommentsDisplay
        {...commentsDisplayProps}
        comments={comments}
        displayCommentSummary={displayCommentSummary}
        onSummaryClick={handleFocusCommentForm}
        scrollContainerId={_scrollContainerId()}
      />
      <CommentCreator
        {...commentCreatorProps}
        commentable={commentable}
        currentUser={formRequirements.currentUser}
        onCommentSave={handleAddComment}
        setCommentFormRef={setCommentFormRef || _setCommentFormRef}
      />
    </>
  )
}

CommentsManager.propTypes = {
  commentable: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  commentCreatorProps: PropTypes.object,
  comments: PropTypes.array.isRequired,
  commentsDisplayProps: PropTypes.object,
  displayCommentSummary: PropTypes.bool,
  formRequirements: PropTypes.object.isRequired,
  setCommentFormRef: PropTypes.func,
}

CommentsManager.defaultProps = {
  commentCreatorProps: {},
  commentsDisplayProps: {},
  displayCommentSummary: true,
  setCommentFormRef: null,
}

export default CommentsManager
