// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// Wespire
import Stack from 'components/ui/stack'
import VerticalScrollBox from 'components/ui/vertical_scroll_box'

// Sub-Components
import Comment from './comment'

const CommentsList = ({ comments, scrollContainerId, ...other }) => (
  <Stack
    {...other}
    ariaLabel={`${comments.length} comments`}
    as={VerticalScrollBox}
    className={cx('border-top', other.className)}
    id={scrollContainerId}
    space={3}
  >
    {comments.map((comment) => (
      <Comment
        createdAt={comment.createdAt}
        currentUserLikeId={comment.currentUserLikeId}
        id={comment.id}
        key={comment.id}
        likeCount={comment.likeCount}
        message={comment.message}
        user={comment.user}
      />
    ))}
  </Stack>
)

CommentsList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      currentUserLikeId: PropTypes.string,
      id: PropTypes.string.isRequired,
      likeCount: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      user: PropTypes.object.isRequired,
    })
  ).isRequired,
  scrollContainerId: PropTypes.string.isRequired,
}

export default CommentsList
