import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'

const PageContainer = ({ title, children }) => {
  const history = useHistory()

  const handleBackLinkClick = () => history.goBack()

  return (
    <div className="flex justify-center min-h-screen">
      <section className="flex flex-col max-w-[50.5rem] lg:w-full w-11/12 mb-28">
        <>
          <div className="flex justify-between pt-8 pb-6 px-1 items-center flex-wrap">
            <h1 className="w-full sm:text-start sm:w-auto pb-4 md:pb-0">
              {title}
            </h1>
            <button
              className="text-black-2 font-medium"
              onClick={handleBackLinkClick}
            >
              <ArrowBackIcon className="text-sm" />
              Back
            </button>
          </div>
          <div className="rounded-2xl w-4xl bg-white border-[1px] border-grayscale-2 lg:p-10 p-6">
            {children}
          </div>
        </>
      </section>
    </div>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default PageContainer
