// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { Button as BaseButton } from 'components/ui/button'
// WeSpire
import Link from 'components/shared/link'

/**
 A Button wrapped in our Link component, which will render a client-side route
 or server-side route depending on what is passed to the `to` prop. If you just
 want to render a server-side link with MUI button styling, do not use this
 component. Instead, pass the `href` prop directly to Button. This component
 is intended to be used with client-side routes, or when you don't know whether
 the URL you are passing to `to` is a client-side or server-side route.
*/
// 1. This component will only ever render as an <a> unless disabled, and links
// shouldn't have default MUI prop of role="button" unless they are meant to
// behave like real buttons. These are legit links that only *look* like
// buttons, so role should be omitted.
const LinkButton = ({ children, 'data-test': dataTest, to, ...other }) => (
  <BaseButton
    {...other}
    as={other.disabled ? 'button' : Link} // 1
    data-test={dataTest}
    role={undefined} // 1
    to={other.disabled ? undefined : to}
  >
    {children}
  </BaseButton>
)

LinkButton.propTypes = {
  /** Any children that should be rendered within the Button element, such as its label */
  children: PropTypes.node.isRequired,
  /** A way to specify a selector for finding this element within a test */
  'data-test': PropTypes.string,
  /** The route this element should navigate to on click */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

LinkButton.defaultProps = {
  'data-test': 'link-button',
}

export default LinkButton
