// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import Charities from 'components/charities'
import {
  filterAllCharities,
  filteredCharities,
} from 'components/charities/utils'
import { intl } from 'utilities/localization'
import WeQuery from 'components/application/we_query'

const givingActivityCharities = gql`
  query givingActivityCharities($id: ID!) {
    givingActivity(id: $id) {
      charities {
        address {
          city
          state
        }
        id
        ein
        matched
        name
        tagLine
        mission
      }
      id
    }
  }
`

const messages = defineMessages({
  charitiesUnavailable: {
    defaultMessage: 'There are no charities available in this Giving Activity.',
    id: 'givingActivityCharities.charitiesUnavailable',
  },
})

const GivingActivityCharities = ({
  disableDonation,
  givingActivityId,
  searchAllCharities,
}) =>
  searchAllCharities ? (
    <Charities
      disableDonation={disableDonation}
      filterCharities={filterAllCharities}
    />
  ) : (
    <WeQuery
      loader={<CenteredPaddedLoadingIndicator />}
      query={givingActivityCharities}
      variables={{ id: givingActivityId }}
    >
      {({
        data: {
          givingActivity: { charities },
        },
      }) =>
        charities ? (
          <Charities
            charities={charities}
            disableDonation={disableDonation}
            filterCharities={(search) => filteredCharities(charities, search)}
            hideCharitySearch
          />
        ) : (
          <p>{intl.formatMessage(messages.charitiesUnavailable)}</p> // Only possible for drafts.
        )
      }
    </WeQuery>
  )

GivingActivityCharities.propTypes = {
  disableDonation: PropTypes.bool,
  givingActivityId: PropTypes.string.isRequired,
  searchAllCharities: PropTypes.bool.isRequired,
}

GivingActivityCharities.defaultProps = {
  disableDonation: false,
}

export default GivingActivityCharities
