// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { formatMessages } from 'components/shared/translations'

const messages = defineMessages({
  leaders: {
    defaultMessage: 'leaders',
    description:
      'Heading for section that shows the leaders of the competition.',
    id: 'leaderBoardSharedTranslations.leaders',
  },
  participantEmptyState: {
    defaultMessage: 'Waiting for other participants...',
    description:
      'indicates this leader item is a placeholder and will be replaced with an actual participant when more join the competition',
    id: 'leaderBoardSharedTranslations.participantEmptyState',
  },
  problemLoadingLeaderboard: {
    defaultMessage: 'There was a problem loading the leaderboard.',
    description: 'The leaderboard was not able to load message',
    id: 'leaderBoardSharedTranslations.problemLoadingLeaderboard',
  },
  problemLoadingTeamFilters: {
    defaultMessage: 'There was a problem loading the team filters.',
    id: 'leaderBoardSharedTranslations.problemLoadingTeamFilters',
  },
  yourRank: {
    defaultMessage: 'your rank',
    description:
      'Helper text for screen readers to let them know a certain entry is for them.',
    id: 'leaderBoardSharedTranslations.yourRank',
  },
  yourRanking: {
    defaultMessage: 'your ranking',
    description:
      "Heading for section that shows current user's ranking in a competition.",
    id: 'leaderBoardSharedTranslations.yourRanking',
  },
  yourTeam: {
    defaultMessage: 'You are a member of this team.',
    description:
      'Helper text for all users to let them know which team they belong to.',
    id: 'leaderBoardSharedTranslations.yourTeam',
  },
})

export const leaderBoardTranslations = formatMessages(messages)
