const getCookie = (name) => {
  if (!document.cookie) return null

  const cookies = document.cookie.split('; ')
  const cookie = cookies.find((row) => row.startsWith(name)) || ''
  const value = cookie.split('=')[1] || null

  return value
}

export { getCookie }
