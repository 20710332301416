// Vendor
import React, { useState } from 'react'
import CarbonCalculator from 'components/CarbonCalculator/App'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

// WeSpire
import { Redirect } from 'react-router'
import { USERCARBONFOOTPRINTSQUERY } from 'components/carbon_footprint/utilities/queries'

export const CALCULATE_CARBON_FOOTPRINT = gql`
  mutation calculateCarbonFootprint(
    $countryCode: String
    $postalCode: String!
    $householdSize: Int!
    $percentRenewableEnergy: Int
    $shortBusinessFlightCount: Int
    $mediumBusinessFlightCount: Int
    $longBusinessFlightCount: Int
    $extendedBusinessFlightCount: Int
    $shortPersonalFlightCount: Int
    $mediumPersonalFlightCount: Int
    $longPersonalFlightCount: Int
    $extendedPersonalFlightCount: Int
    $vehicleFuelType1: Int
    $vehicleDistanceTotal1: Int
    $vehicleFuelEfficiency1: Int
    $publicTransportationDistanceTotal: Int
    $animalProductsConsumed: Int
    $commuteRoundTripDistanceDaily: Int
    $remoteDaysWeekly: Int
    $commuteTransportType: String
    $housingType: String
  ) {
    calculateCarbonFootprint(
      countryCode: $countryCode
      postalCode: $postalCode
      householdSize: $householdSize
      percentRenewableEnergy: $percentRenewableEnergy
      shortBusinessFlightCount: $shortBusinessFlightCount
      mediumBusinessFlightCount: $mediumBusinessFlightCount
      longBusinessFlightCount: $longBusinessFlightCount
      extendedBusinessFlightCount: $extendedBusinessFlightCount
      shortPersonalFlightCount: $shortPersonalFlightCount
      mediumPersonalFlightCount: $mediumPersonalFlightCount
      longPersonalFlightCount: $longPersonalFlightCount
      extendedPersonalFlightCount: $extendedPersonalFlightCount
      vehicleFuelType1: $vehicleFuelType1
      vehicleDistanceTotal1: $vehicleDistanceTotal1
      vehicleFuelEfficiency1: $vehicleFuelEfficiency1
      publicTransportationDistanceTotal: $publicTransportationDistanceTotal
      animalProductsConsumed: $animalProductsConsumed
      commuteRoundtripDistanceDaily: $commuteRoundTripDistanceDaily
      remoteDaysWeekly: $remoteDaysWeekly
      commuteTransportType: $commuteTransportType
      housingType: $housingType
    ) {
      errors
    }
  }
`

export const CarbonCalculatorPage = () => {
  const [calculateFootPrint] = useMutation(CALCULATE_CARBON_FOOTPRINT, {})
  const [isFootprintCalculated, setIsFootprintCalculated] = useState(false)

  const getFootprint = async (params) => {
    const {
      data: {
        calculateCarbonFootprint: { errors },
      },
    } = await calculateFootPrint({
      refetchQueries: [{ query: USERCARBONFOOTPRINTSQUERY }],
      variables: params,
    })

    if (errors) {
      return { errors }
    } else {
      setIsFootprintCalculated(true)
    }
  }

  if (isFootprintCalculated) {
    return <Redirect to="/carbon_footprint" />
  }

  return <CarbonCalculator getFootprint={getFootprint} />
}
