import React from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'

import Input from 'src/common/Input'
import InputChoice from 'src/common/InputChoice'
import SectionContainer from 'src/common/SectionContainer'

const DonationMessageSection = ({
  anonymousDonation, 
  note,
  setAnonymousDonation,
  setNote,
}) => {
  const { setFieldValue } = useFormikContext()
  const handleNoteChange = (event) => {
    setNote(event.target.value)
    setFieldValue('note', event.target.value)
  }

  const handleAnonymousChange = (event) => {
    setAnonymousDonation(event.target.checked)
    setFieldValue('anonymous', event.target.checked)
  }
  const intl = useIntl()
  return (
    <SectionContainer
      title={intl.formatMessage({
        defaultMessage: 'Your message to the charity (optional)',
        id: 'dollarsForDoersForm.yourMessageToTheCharity',
      })}
    >
      <div className="px-4">
        <Input
          className="w-full"
          explainer={intl.formatMessage({
            defaultMessage:
              'Use this section to share additional information, such as an honoree, purpose, or a friendly note that will be visible to the charity.',
            id: 'dollarsForDoersForm.messageAdditionalInformation',
          })}
          name="donationNote"
          onChange={handleNoteChange}
          placeholder={intl.formatMessage({
            defaultMessage: 'Share an optional note with the charity',
            id: 'donationForm.donationNote',
          })}
          value={note}
        />
        <InputChoice
          checked={anonymousDonation}
          className="mt-4"
          explainer={intl.formatMessage({
            defaultMessage:
              'If checked, your name and email address will be withheld from the charity.',
            id: 'grantDonationForm.anonymousDonationHelperText',
          })}
          label={intl.formatMessage({
            defaultMessage: 'Donate anonymously',
            id: 'grantDonationForm.anonymousDonation',
          })}
          name="anonymousDonation"
          onChange={handleAnonymousChange}
          type="checkbox"
        />
      </div>
    </SectionContainer>
  )
}

export default DonationMessageSection