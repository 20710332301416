// Vendor
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { Redirect, useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import Stack from 'components/ui/stack'
import { DonationAccountNotFoundPage } from 'components/donations/pages/donation_account_not_found'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import {
  DonationHistoryStat,
  DonationHistoryStatAmount,
  DonationHistoryTransactions,
  ScheduledDonations,
} from './components'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { displayBanner } from 'redux/dispatchers'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import {
  donationHistoryMessages,
  messages,
} from 'components/donation_history/utilities/messages'
import BannerLink from 'components/application/banner/link'
import {
  donationHistoryTransactions,
  impactStatsQuery,
  upcomingScheduledDonations,
} from 'components/donation_history/utilities/queries'

/**
 * Top-level page that displays a user's donation history stats and
 * transactions.
 */
export const DonationHistory = () => {
  const { enableDonationHistory } = useFlags()
  const { push } = useHistory()

  if (enableDonationHistory === true) {
    return <Redirect to="/app/donation_history_page" />
  }

  return (
    <GraphQLFeatureFlag
      featureDisabledError={<DonationAccountNotFoundPage />}
      featureName="giving"
    >
      <DocumentTitle title={sharedTranslations.donationHistory}>
        {maybeDisplayUserTour('donationHistoryPage')}

        <CenteredContent
          className="px-3 | px-sm-5 | px-xl-0"
          data-test="donation-history"
        >
          {/* Page Title */}
          <Heading className="mt-5 px-3" level={1}>
            {sharedTranslations.donationHistory}
          </Heading>
          {/* Impact Stats */}
          <Heading className="mb-3 mt-5 px-3 text-capitalize" level={2}>
            {sharedTranslations.yourImpact}
          </Heading>
          <Paper className="mt-3 px-3 py-4">
            <WeQuery
              error={
                <MissingResourceSection
                  data-test="missing-donation-impact-stats"
                  errorDetails={sharedTranslations.yourImpact}
                />
              }
              loader={<CenteredPaddedLoadingIndicator />}
              query={impactStatsQuery}
            >
              {({
                data: {
                  currentUser: { givingaUser },
                  donationMatchingEnabled,
                  brandConfig: { retroactiveMatchEnabled },
                },
              }) => {
                if (!givingaUser) {
                  displayBanner({
                    content: (
                      <>
                        {donationHistoryMessages.missingDonationAccount}{' '}
                        {intl.formatMessage(
                          sharedMessages.contactSupportDonationAccount,
                          {
                            link: (...linkText) => (
                              <BannerLink
                                key="contactSupport"
                                to="mailto:support@wespire.com?subject=Giving%20Account%20Error"
                              >
                                {linkText}
                              </BannerLink>
                            ),
                          }
                        )}
                      </>
                    ),
                    variant: 'info',
                  })

                  return (
                    <MissingResourceSection
                      data-test="missing-donation-impact-stats"
                      errorDetails={sharedTranslations.yourImpact}
                    />
                  )
                }
                const {
                  allDonationsToDate,
                  availableDonationFunds,
                  companyDonationsToDate,
                  donationFundsCap,
                  personalDonationsToDate,
                } = givingaUser

                return (
                  <div className="row flex-column align-items-start justify-content-between | flex-sm-row">
                    {/* Your Donations */}
                    <DonationHistoryStat
                      amount={
                        <DonationHistoryStatAmount
                          amount={allDonationsToDate}
                          key="allDonationsToDate"
                          size="lg"
                        />
                      }
                      currencyLabel={donationHistoryMessages.currencyShownInUsd}
                      columnWidth={retroactiveMatchEnabled ? 4 : 6}
                      helpText={
                        availableDonationFunds > 0 && (
                          <span data-test="all-donations-to-date-helptext">
                            {intl.formatMessage(
                              messages.personalContributions,
                              {
                                amount: (
                                  <DonationHistoryStatAmount
                                    amount={personalDonationsToDate}
                                    key="personalDonationsToDate"
                                    size="sm"
                                  />
                                ),
                              }
                            )}
                            <>
                              <span className="[ mx-2 ] [ show-lg-up ]">|</span>
                              <span className="d-block d-lg-inline-block">
                                {intl.formatMessage(
                                  messages.companyContributions,
                                  {
                                    amount: (
                                      <DonationHistoryStatAmount
                                        amount={companyDonationsToDate}
                                        key="companyDonationsToDate"
                                        size="sm"
                                      />
                                    ),
                                  }
                                )}
                              </span>
                            </>
                          </span>
                        )
                      }
                      label={donationHistoryMessages.totalAmountDonated}
                      usdLabel={donationHistoryMessages.usdCurrency}
                    />

                    {/* Available Funds */}
                    {donationMatchingEnabled && (
                      <DonationHistoryStat
                        amount={
                          <DonationHistoryStatAmount
                            amount={
                              availableDonationFunds < 0
                                ? 0
                                : availableDonationFunds
                            }
                            key="availableDonationFunds"
                            size="lg"
                          />
                        }
                        className="mt-3 | mt-sm-0"
                        data-test="available-funds-stat"
                        columnWidth={retroactiveMatchEnabled ? 4 : 6}
                        helpText={
                          <>
                            {intl.formatMessage(messages.annualBudget, {
                              amount: (
                                <DonationHistoryStatAmount
                                  amount={donationFundsCap}
                                  key="donationFundsCap"
                                  size="sm"
                                />
                              ),
                            })}
                          </>
                        }
                        label={donationHistoryMessages.availableDonationFunds}
                        usdLabel={donationHistoryMessages.usdCurrency}
                      />
                    )}
                    {retroactiveMatchEnabled && (
                      <div className="col-sm-4">
                        <p>
                          Do you need to request a match for a donation you made off of this platform? Use this button to complete a form and request your company match.
                        </p>
                        <p>
                          Please Note: <i>To submit the form, you’ll need to upload a donation receipt.</i>
                        </p>
                        <Button
                          className="mt-1 mr-2"
                          variant="contained"
                          color="primary"
                          onClick={() => push('/app/charity_search/donation_history')}
                        >
                          Retroactive Match Request
                        </Button>
                      </div>
                    )}
                  </div>
                )
              }}
            </WeQuery>
          </Paper>
          <WeQuery
            loader={<CenteredPaddedLoadingIndicator />}
            query={upcomingScheduledDonations}
          >
            {({
              data: { recurringDonationsEnabled, upcomingScheduledDonations },
            }) => {
              return (
                recurringDonationsEnabled &&
                upcomingScheduledDonations.length !== 0 && (
                  <>
                    <Heading
                      className="mb-3 mt-5 px-3 text-capitalize"
                      level={2}
                    >
                      {donationHistoryMessages.yourUpcomingDonations}
                    </Heading>

                    {/* Upcoming scheduled donations */}
                    <ScheduledDonations
                      donations={upcomingScheduledDonations}
                    />
                  </>
                )
              )
            }}
          </WeQuery>
          {/* Donation history transactions */}
          <WeQuery
            error={
              <MissingResourceSection
                data-test="missing-donation-transactions"
                errorDetails={sharedTranslations.yourDonations}
              />
            }
            loader={<CenteredPaddedLoadingIndicator />}
            query={donationHistoryTransactions}
          >
            {({ data: { currentUser } }) => {
              const account = currentUser.givingaUser
              const transactions = account && account.transactions
              return (
                <>
                  <Heading
                    className="mb-3 mt-5 px-3 text-capitalize"
                    level={2}
                  >
                    {donationHistoryMessages.yourPastDonations}
                  </Heading>
                  <DonationHistoryTransactions
                    account={account}
                    transactions={transactions}
                  />
                </>
              )
            }}
          </WeQuery>
        </CenteredContent>
      </DocumentTitle>
    </GraphQLFeatureFlag>
  )
}
