// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

const CountIndicator = ({ icon, isNumberAriaHidden, number }) => (
  <div className="align-items-center d-flex" data-test="comment-count">
    <span aria-hidden={isNumberAriaHidden} className="fs-1 mr-2">
      <FormattedNumber value={number} />
    </span>
    {icon}
  </div>
)

// PropType definitions
CountIndicator.propTypes = {
  icon: PropTypes.node.isRequired,
  isNumberAriaHidden: PropTypes.bool,
  number: PropTypes.number.isRequired,
}

CountIndicator.defaultProps = {
  isNumberAriaHidden: false,
}

export default CountIndicator
