// Vendor
import React from 'react'
import cx from 'classnames'

const DividerCaption = ({ children, className, ...other }) => {
  return (
    <div {...other} className={cx('relative flex items-center', className)}>
      <div className="flex-grow border-t border-grayscale-2"></div>
      <span className="flex-shrink mx-2 text-grayscale-400">{children}</span>
      <div className="flex-grow border-t border-grayscale-2"></div>
    </div>
  )
}

export default DividerCaption
