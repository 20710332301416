import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  all: {
    defaultMessage: 'All',
    id: 'retroactiveMatchRequestsUtils.all',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'retroactiveMatchRequestsUtils.pending',
  },
  rejected: {
    defaultMessage: 'Rejected',
    id: 'retroactiveMatchRequestsUtils.rejected',
  },
  approved: {
    defaultMessage: 'Approved',
    id: 'retroactiveMatchRequestsUtils.approved',
  },
  ascending: {
    defaultMessage: 'Oldest to Newest',
    id: 'retroactiveMatchRequestsUtils.ascending',
  },
  descending: {
    defaultMessage: 'Newest to Oldest',
    id: 'retroactiveMatchRequestsUtils.descending',
  },
})

export const statusFilterOptions = [
  { label: formatMessage(messages.pending), value: 'pending' },
  { label: formatMessage(messages.rejected), value: 'rejected' },
  { label: formatMessage(messages.approved), value: 'approved' },
]

export const orderFilterLabelOptions = [
  { label: formatMessage(messages.ascending), value: 'asc' },
  { label: formatMessage(messages.descending), value: 'desc' },
]
