import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// westyle
import Divider from 'src/common/Divider'
import VolunteerLog from './VolunteerLog'

const VolunteerDetails = ({ expandRow, status, charity }) => {
  return (
    <div className={cx('gap-y-4 grid pb-4', { hidden: !expandRow })}>
      <div className="grid px-4 md:px-0">
        {charity.logs.map((log, id) => (
          <div key={id}>
            <Divider className="mb-3 mt-4" />
            <VolunteerLog status={status} log={log} />
          </div>
        ))}
      </div>
    </div>
  )
}

VolunteerDetails.propTypes = {
  charity: PropTypes.object,
  expandRow: PropTypes.bool,
  status: PropTypes.string,
}

VolunteerDetails.defaultProps = {
  charity: {},
  expandRow: false,
  status: '',
}

export default VolunteerDetails
