import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/system/Box'

const Progress = ({
  location,
  currentDataQuestionGroup,
  calculatorQuestionsAnswersData,
}) => {
  const [pills, setPills] = useState([])
  const [questionGroupsArray, setQuestionGroupsArray] = useState([])
  const [currentGroup, setCurrentGroup] = useState({})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const pillStyle = {
    display: 'inline-block',
    height: '8px',
    width: '40px',
    borderRadius: '8px',
    marginRight: '5px',
    backgroundColor: '#E5E7EB',
  }

  const darkPillStyle = { ...pillStyle, backgroundColor: '#1772DC' }

  const setGroupsData = () => {
    const tempQuestionGroupsObj = {}
    const tempQuestionGroupsArray = []
    calculatorQuestionsAnswersData.forEach((groupItem) => {
      if (!tempQuestionGroupsObj[groupItem.questionGroup]) {
        tempQuestionGroupsObj[groupItem.questionGroup] = {
          questionGroup: groupItem.questionGroup,
          occurrences: 1,
        }
        tempQuestionGroupsArray.push({
          questionGroup: groupItem.questionGroup,
        })
      } else {
        tempQuestionGroupsObj[groupItem.questionGroup].occurrences += 1
      }
    })

    tempQuestionGroupsArray.forEach((item) => {
      /* eslint-disable no-param-reassign */
      if (
        item.questionGroup ===
        tempQuestionGroupsObj[item.questionGroup].questionGroup
      ) {
        item.totalOccurrences =
          tempQuestionGroupsObj[item.questionGroup].occurrences
      }
    })
    setQuestionGroupsArray([...tempQuestionGroupsArray])
  }

  const setPaginationPillsData = () => {
    const groupsIndex = questionGroupsArray
      .map((e) => e.questionGroup)
      .indexOf(currentDataQuestionGroup)

    setCurrentGroup(questionGroupsArray[groupsIndex])

    let runningTotal = 0

    questionGroupsArray.forEach((group, idx) => {
      if (groupsIndex >= idx) runningTotal += group.totalOccurrences
    })

    const currentSectionPage =
      questionGroupsArray[groupsIndex].totalOccurrences +
      location -
      runningTotal
    setCurrentQuestionIndex(currentSectionPage)

    const pillsTemp = []
    questionGroupsArray.forEach((group, idx) => {
      pillsTemp.push(
        <Box
          data-test="progress-pill"
          key={group.questionGroup}
          sx={groupsIndex >= idx ? darkPillStyle : pillStyle}
        />
      )
    })

    setPills([...pillsTemp])
  }

  useEffect(() => {
    setGroupsData()
  }, [])

  useEffect(() => {
    if (questionGroupsArray.length > 0) {
      setPaginationPillsData()
    }
  }, [questionGroupsArray, currentDataQuestionGroup, location])

  return (
    <div data-test="progress-test">
      <div>
        <h3
          className="mb-3 uppercase font-bold text-sm md:text-lg"
          data-test="question-group"
        >
          {currentGroup && currentGroup.questionGroup}
        </h3>
      </div>
      <div data-test="progress-holder" className="box flex">
        {pills}
      </div>
      <div className="mx-0.5 my-2">
        <span
          data-test="progress-count-test"
          className="mt-2 mb-3 text-xs md:text-sm uppercase"
        >
          {`Question ${currentQuestionIndex}/${
            currentGroup && currentGroup.totalOccurrences
          }`}
        </span>
      </div>
    </div>
  )
}

Progress.propTypes = {
  location: PropTypes.number.isRequired,
  currentDataQuestionGroup: PropTypes.string.isRequired,
  /* eslint-disable */
  calculatorQuestionsAnswersData: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
}

export default Progress
