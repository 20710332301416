// Vendor
import PropTypes from 'prop-types'
import React from 'react'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { ActionTimeframeModel } from './action_timeframe_model'
import { endOfDay, startOfDay } from 'utilities/date_utils'
import { TimeRange } from 'components/ui/time'
import { RelativeTime } from 'components/shared/relative_time'

export const ActionTimeframe = ({ actionTimeframe, isAccumulationAction }) => {
  return (
    <p
      className="lh-md fs-1 pr-3 fw-semi-bold ls-2 text-uppercase text-black-1"
      data-test="action-timeframe"
    >
      <TimeRange
        endsAt={endOfDay(actionTimeframe.endsAt)}
        startsAt={startOfDay(actionTimeframe.startsAt)}
      />
      {!isAccumulationAction && <BulletSpacer className="fs-1 px-0" />}
      {!isAccumulationAction && (
        <RelativeTime date={endOfDay(actionTimeframe.endsAt)} showPrefix />
      )}
    </p>
  )
}

ActionTimeframe.propTypes = {
  actionTimeframe: PropTypes.instanceOf(ActionTimeframeModel).isRequired,
  isAccumulationAction: PropTypes.bool.isRequired,
}
