// Vendor
import { useState, useEffect } from 'react'

// WeSpire
import { breakpoints } from 'styles/variables'

export function useIsMobile() {
  const isMobileSize = () => window.innerWidth < breakpoints.md
  const [isMobile, setIsMobile] = useState(isMobileSize())

  useEffect(() => {
    const handleWindowSizeChange = () => setIsMobile(isMobileSize())

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return isMobile
}
