import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { client } from '../we_apollo'

export const currentUserAndBrandQuery = gql`
  query currentUserAndBrandQuery {
    currentUser {
      id
      email
      name
    }
    brand {
      id
    }
  }
`

export default function usePendo() {
  const { data: { currentUser, brand } = {}, loading } = useQuery(
    currentUserAndBrandQuery,
    {
      client,
    }
  )

  const activatePendo = ({ currentUserId, brandId, userEmail, userName }) => {
    ;(function (apiKey) {
      ;(function (p, e, n, d, o) {
        var v, w, x, y, z
        o = p[d] = p[d] || {}
        o._q = o._q || []
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                )
              }
          })(v[w])
        y = e.createElement(n)
        y.async = !0
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
        z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, 'script', 'pendo')

      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          email: userEmail,
          full_name: userName, // Recommended if using Pendo Feedback, or NPS Email
          id: currentUserId, // Required if user is logged in
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        // eslint-disable-next-line sort-keys
        account: {
          id: brandId, // Required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      })
    })('ed27e46d-8d6a-4f44-7027-4634f76ae57a')
  }

  useEffect(() => {
    /** Pass either brand or user as currentUser is not defined for users
        not logged in
    **/
    if (!loading && (currentUser || brand)) {
      activatePendo({
        brandId: brand?.id,
        currentUserId: currentUser?.id,
        userEmail: currentUser?.email,
        userName: currentUser?.name,
      })
    }
  })
}
