// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { useQuery, NetworkStatus } from '@apollo/client'
import gql from 'graphql-tag'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import Activity from 'components/setup/shared/activities/list/activity'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import Stack from 'components/ui/stack'
import { undecorated as Select } from 'components/form/select'
import { translateActivityType } from 'components/setup/shared/activities/list/utils'
import { sharedMessages } from 'components/shared/translations'

import { ACTIVITY_STATUS } from 'components/setup/shared/activities/list/utils'
import { CreateActivitiesMenu } from 'components/setup/groups_management/activities/components/create_activities_menu'
import { statusFilterOptions } from 'components/setup/groups_management/activities/utils'

const { formatMessage } = intl

const messages = defineMessages({
  allActivities: {
    defaultMessage: 'All Activities ({ totalCount })',
    id: 'channelActivitiesList.allActivities',
  },
  emptyState: {
    defaultMessage: 'No Activities found with current parameters.',
    id: 'channelActivitiesList.emptyState',
  },
  helperTextForSearch: {
    defaultMessage: 'Filter by name or email address',
    id: 'channelActivitiesList.helperTextForSearch',
  },
  statusFilterLabel: {
    defaultMessage: 'Filter by Activity status',
    id: 'channelActivitiesList.statusFilterLabel',
  },
})

const QUERY = gql`
  query groupActivitiesQuery(
    $groupId: ID!
    $first: Int
    $after: String
    $status: String
  ) {
    group(id: $groupId) {
      id
      name
      isChapter
      allActivities(first: $first, after: $after, status: $status) {
        nodes {
          actionUrl
          activityId
          name
          propsForManagement
          status
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`
const PAGE_SIZE = 10
export const GroupActivitiesList = ({ groupId }) => {
  const { data, error, fetchMore, loading, networkStatus, refetch, variables } =
    useQuery(QUERY, {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_SIZE,
        groupId: groupId,
        status: ACTIVITY_STATUS.active,
      },
    })

  if (error) {
    return <MissingResourcePage />
  }

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.group.allActivities.nodes = [
          ...previousResult?.group?.allActivities?.nodes,
          ...fetchMoreResult?.group?.allActivities?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: data?.group?.allActivities?.pageInfo?.endCursor,
        first: PAGE_SIZE,
      },
    })
  }

  const onStatusFilterChange = (status) => {
    refetch({ ...variables, status })
  }

  const groupData = data?.group

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)

  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase"
          data-test="all-activities-label"
          level={3}
        >
          {formatMessage(messages.allActivities, {
            totalCount: groupData?.allActivities?.totalCount,
          })}
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack className="d-flex row">
          <Stack className="col-3" data-test="status-filter" space={1}>
            <InputLabel
              className="fw-semi-bold text-black-1 text-uppercase fs-1"
              htmlFor="statusFilter"
            >
              {formatMessage(messages.statusFilterLabel)}
            </InputLabel>
            <Select
              hideBlankOption
              name="statusFilter"
              optionProps={{ 'data-test': 'status-filter-option' }}
              options={statusFilterOptions}
              selectProps={{ inputProps: { className: 'p-2 text-capitalize' } }}
              setValue={onStatusFilterChange}
              value={variables.status}
            />
          </Stack>

          <CreateActivitiesMenu className="ml-auto mr-4" />
        </Stack>

        <Stack className="[ flex-column ] [ bg-off-white pt-2 ]" space={2}>
          {!refetching && (
            <>
              {groupData?.allActivities?.nodes?.map((activity) => {
                return (
                  <Activity
                    activity={{
                      ...activity,
                      ...activity.propsForManagement,
                      id: activity.activityId,
                      status: activity.status,
                    }}
                    className="border"
                    data-test="activity-item"
                    elevation={0}
                    hideFeaturedToggle
                    hideStatusLabel
                    key={`${translateActivityType(
                      activity.propsForManagement.type
                    )}-${activity.activityId}`}
                  />
                )
              })}
              {groupData?.allActivities?.totalCount === 0 && (
                <p
                  className="fs-2 fw-semi-bold d-flex align-items-center justify-content-center"
                  data-test="group-activities-empty-state"
                >
                  {formatMessage(messages.emptyState)}
                </p>
              )}
            </>
          )}

          {loading && (
            <CenteredPaddedLoadingIndicator
              padding={null}
              size={LoadingIndicatorSize.LARGE}
            />
          )}
        </Stack>

        {groupData?.allActivities?.pageInfo.hasNextPage && (
          <div className="[ d-flex justify-content-center ] [ pt-2 ]">
            <LoadMoreButton
              className="flex-grow mxw-4"
              loading={loading}
              newsfeedItemsCount={groupData?.allActivities?.nodes?.length}
              onClick={onLoadMore}
              pageSize={PAGE_SIZE}
              totalCount={groupData?.allActivities?.totalCount}
              type={sharedMessages.activities}
            />
          </div>
        )}
      </Stack>
    </Stack>
  )
}

GroupActivitiesList.propTypes = {
  groupId: PropTypes.string.isRequired,
}
