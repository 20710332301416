import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'

function findAddressPart(address, partTypes, partText) {
  let part = null
  partTypes.forEach((partType) => {
    if (part) {
      return
    }
    part = address[0]['address_components'].find((el) =>
      el['types'].includes(partType)
    )
  })

  if (part) {
    return part[partText]
  } else {
    return ''
  }
}

const GoogleAutocompleteAddressField = ({
  autocompletionRequest,
  onSelection,
  debounce,
  minLengthAutocomplete,
  className,
  name,
  label,
  selectedItem,
}) => {
  const handleSelection = (address) => {
    const place = geocodeByPlaceId(address['value']['place_id'])

    place.then((response) => {
      const addressType = {
        city: findAddressPart(
          response,
          ['locality', 'neighborhood', 'sublocality_level_1'],
          'long_name'
        ),
        country: findAddressPart(response, ['country'], 'long_name'),
        county: findAddressPart(
          response,
          ['administrative_area_level_2', 'sublocality_level_1'],
          'long_name'
        ),
        houseNumber: findAddressPart(response, ['street_number'], 'long_name'),
        postalCode: findAddressPart(response, ['postal_code'], 'long_name'),
        state: findAddressPart(
          response,
          ['administrative_area_level_1'],
          'short_name'
        ),
        street: findAddressPart(response, ['route'], 'long_name'),
      }
      address.parts = addressType
      address.formatted_address = response[0]['formatted_address']
      onSelection(address)
    })
  }

  return (
    <div className={cx('position-relative', className)} data-test={name}>
      <GooglePlacesAutocomplete
        apiKey={process.env.GOOGLE_PLACES_API_KEY}
        apiOptions={{ language: 'en' }}
        autocompletionRequest={autocompletionRequest}
        debounce={debounce}
        minLengthAutocomplete={minLengthAutocomplete}
        selectProps={{
          classNamePrefix: 'We',
          onChange: handleSelection,
          placeholder: label,
          selectedItem,
          styles: {
            input: (provided) => ({
              ...provided,
              padding: '12.5px 14px',
            }),
          },
        }}
      />
    </div>
  )
}

GoogleAutocompleteAddressField.defaultProps = {
  autocompletionRequest: null,
  className: null,
}

GoogleAutocompleteAddressField.propTypes = {
  autocompletionRequest: PropTypes.shape({
    types: PropTypes.array.isRequired,
  }),
  className: PropTypes.string,
  debounce: PropTypes.number.isRequired,
  label: PropTypes.any.isRequired,
  minLengthAutocomplete: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onSelection: PropTypes.func.isRequired,
  selectedItem: PropTypes.any.isRequired,
}

export default GoogleAutocompleteAddressField
