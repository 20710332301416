// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import Charity from 'components/charities/charity'
import LabeledIcon from 'components/ui/labeled_icon'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  errorDetails: {
    defaultMessage: 'There was a problem loading the charities.',
    id: 'charitySearchResults.errorDetails',
  },
  noCharities: {
    defaultMessage: 'No charities match that search.',
    id: 'charitySearchResults.noCharities',
  },
  pending: {
    defaultMessage: 'Please enter criteria to search for charities.',
    id: 'charitySearchResults.pending',
  },
})

const CharitySearchResults = ({
  charities,
  noCharitiesMessage,
  renderCharity,
  status,
}) => {
  if (status === 'error') {
    return (
      <LabeledIcon
        iconName="warning"
        iconProps={{ className: 'fs-4 text-red' }}
        label={intl.formatMessage(messages.errorDetails)}
      />
    )
  } else if (status === 'loading') {
    return <CenteredPaddedLoadingIndicator />
  } else if (charities?.length > 0) {
    return charities?.map((charity) =>
      renderCharity({ charity, key: charity?.ein })
    )
  } else if (status === 'pending') {
    return <p>{intl.formatMessage(messages.pending)}</p>
  } else {
    return (
      <p>{noCharitiesMessage ?? intl.formatMessage(messages.noCharities)}</p>
    )
  }
}

CharitySearchResults.propTypes = {
  charities: PropTypes.array.isRequired,
  noCharitiesMessage: PropTypes.string,
  renderCharity: PropTypes.func,
  status: PropTypes.string,
}

CharitySearchResults.defaultProps = {
  noCharitiesMessage: null,
  renderCharity: (props) => <Charity {...props} />,
  status: null,
}

export default CharitySearchResults
