import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const GraphLegend = ({ graphData }) => {
  const [legendArray, setLegendArray] = useState([])

  const defineLegendArray = () => {
    const legendArrayTemp = []
    graphData.data.forEach((item, index) => {
      legendArrayTemp.push({
        name: item.name,
        color: graphData.colorScale[index],
      })
    })
    setLegendArray(legendArrayTemp)
  }

  useEffect(() => {
    defineLegendArray()
  }, [])

  return (
    <div className="flex justify-center" data-test="graph-legend-test">
      {legendArray.map((item) => (
        <div key={item.name} className="flex mr-2">
          <div
            style={{
              width: '10px',
              height: '10px',
              background: item.color,
              borderRadius: '50%',
              marginTop: '3px',
            }}
            className="mr-1"
          />
          <span className="text-xs">{item.name}</span>
        </div>
      ))}
    </div>
  )
}

GraphLegend.propTypes = {
  graphData: PropTypes.shape({
    colorScale: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        y: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
}

export default GraphLegend
