// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'

// WeSpire
import {
  activitiesByType,
  activityCardSkeletonsHeight,
  orderedTypeLabels,
} from './utils'
import CenteredContent from 'components/application/centered_content'
import { groupByYear } from 'utilities/group'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sortByNewest } from 'utilities/sort'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { EndedActivityCard } from './ended_activity_card'
import WeQuery from 'components/application/we_query'

const messages = defineMessages({
  endedActivities: {
    defaultMessage: 'Ended Activities',
    id: 'endedActivities.endedActivities',
  },
  noEndedActivities: {
    defaultMessage: 'your Ended Activities',
    id: 'endedActivities.noEndedActivities',
  },
})

const EndedActivities = () => {
  const { formatDate, formatMessage } = intl

  return (
    <WeQuery
      error={
        <section className="bg-black-6 p-3">
          <CenteredContent className="mt-5">
            <Stack space={5}>
              <Heading className="px-3" level={3} variant={1}>
                {formatMessage(messages.endedActivities)}
              </Heading>
              <MissingResourceSection
                as={Paper}
                errorDetails={formatMessage(messages.noEndedActivities)}
              />
            </Stack>
          </CenteredContent>
        </section>
      }
      loader={
        <CenteredContent>
          <SkeletonLoading header height={activityCardSkeletonsHeight} />
        </CenteredContent>
      }
      query={gql`
        query userEndedActivitiesQuery {
          currentUser {
            endedActivities {
              actionUrl
              endsAt
              id
              name
              startsAt
              totalParticipationIndicators
              totalParticipationIndicatorsForUser
              type
              unlisted
              ... on Campaign {
                achievement {
                  awardedTo
                  id
                  imageUrl
                  name
                }
                progressPercentForUser
              }
            }
            id
          }
        }
      `}
    >
      {({
        data: {
          currentUser: { endedActivities },
        },
      }) => {
        const sortedActivities = sortByNewest(endedActivities, 'endsAt')
        const groupedActivities = groupByYear(sortedActivities, 'endsAt')

        return (
          endedActivities.length > 0 && (
            <section className="bg-black-6 p-3" data-test="ended-activities">
              <CenteredContent className="mt-5">
                <Stack space={5}>
                  <Heading className="px-2" level={3} variant={1}>
                    {formatMessage(messages.endedActivities)}
                  </Heading>

                  {groupedActivities.map((group) => (
                    <Stack as="section" key={group.year} space={3}>
                      <Heading
                        className="with-separator with-separator--centered"
                        level={4}
                        variant={1}
                      >
                        {formatDate(group.items[0].endsAt, {
                          year: 'numeric',
                        })}
                      </Heading>
                      <Stack
                        data-test="activities-by-year"
                        divider="default"
                        space={3}
                      >
                        {Object.entries(orderedTypeLabels).map(
                          ([key, value]) => {
                            const groupedActivities = activitiesByType(
                              group.items,
                              key
                            )

                            return (
                              groupedActivities.length > 0 && (
                                <Stack
                                  data-test="ended-activities-by-type"
                                  key={key}
                                >
                                  <Heading
                                    className="px-2"
                                    level={5}
                                    variant={2}
                                  >
                                    {value}
                                  </Heading>
                                  <ul
                                    className="[ row vertical-gutters ] [ px-0 ]"
                                    data-test="activities-by-type"
                                  >
                                    {groupedActivities.map((a) => (
                                      <li
                                        className="col-md-4 d-flex"
                                        key={a.actionUrl}
                                      >
                                        <EndedActivityCard
                                          achievement={a.achievement}
                                          actionUrl={a.actionUrl}
                                          className="mt-3"
                                          endsAt={a.endsAt}
                                          name={a.name}
                                          progressPercent={
                                            a.progressPercentForUser
                                          }
                                          startsAt={a.startsAt}
                                          totalParticipationIndicators={
                                            a.totalParticipationIndicators
                                          }
                                          totalParticipationIndicatorsForUser={
                                            a.totalParticipationIndicatorsForUser
                                          }
                                          type={a.type}
                                        />
                                      </li>
                                    ))}
                                  </ul>
                                </Stack>
                              )
                            )
                          }
                        )}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </CenteredContent>
            </section>
          )
        )
      }}
    </WeQuery>
  )
}

export { EndedActivities }
