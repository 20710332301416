// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import Formsy from 'formsy-react'
import { withRouter } from 'react-router-dom'
import { setToken } from 'redux/reducers/session'

// Wespire
import { displayBanner } from 'redux/dispatchers'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { messages } from '../utilities/messages'
import TextField from 'components/ui/Form/FormTextField/FormTextField'
import SubmitButton from 'components/ui/submit_button'
import WeSpireAPI from 'utilities/wespire_api'

class EmployeeEmail extends React.Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string,
    ssoUrl: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: null,
  }

  state = {
    formError: undefined,
    isSubmitting: false,
  }

  handleSubmit = (values) => {
    const { email } = values

    this.setState({ isSubmitting: true })

    WeSpireAPI()
      .post('/registration', {
        session: {
          email,
        },
      })
      .then((response) => {
        this.setState({
          isSubmitting: false,
        })

        const path = response.data.redirect_to
        // actual 422 but looks ok because of redirect
        if (response.data?.confirm_email) {
          displayBanner({
            content: response.data.info,
            variant: 'info',
          })
        } else if(response.data?.sso) {
          this.ssoformElement.submit()
        } else {
          localStorage.setItem('email', email)
          // TODO add this logic to new login pages
          if (response.data?.errors?.base){
            localStorage.setItem('info', response.data.errors.base)
            localStorage.setItem('varient', 'error')
          } else {
            localStorage.setItem('info', response.data?.info)
          }
          window.location = path
        }
      })
      .catch((error) => {
        const errors = error.response.data.errors

        this.setState({ isSubmitting: false })

        displayBanner({
          content: errors.base,
          variant: 'error',
        })
      })
  }

  setSSOFormRef = (ssoFormElement) => (this.ssoformElement = ssoFormElement)

  render() {
    const csrftParamEl = document.querySelector("meta[name='csrf-param']")
    const csrfTokenEl = document.querySelector("meta[name='csrf-token']")
    const tokenName = csrftParamEl && csrftParamEl.getAttribute('content')
    const tokenValue = csrfTokenEl && csrfTokenEl.getAttribute('content')

    const { buttonText, className, ssoUrl } = this.props
    const { formError, isSubmitting } = this.state

    return (
      <div className={className}>
        <Formsy
          className="[ d-flex flex-column ] [ w-100 ]"
          noValidate
          onValidSubmit={this.handleSubmit}
          ref={this.setFormRef}
        >
          <div className="login-auth-panel-main-react flex-grow">
            <div className="login-auth-title" />
            <div
              className={cx('d-flex align-items-center | text-red', {
                invisible: !formError,
              })}
              ref={this.setErrorSummaryRef}
              tabIndex={-1}
            >
              <Icon className="fs-1 mr-1" iconName="warning" title="" />
              <span>{formError}</span>
            </div>
            <TextField
              className="mt-1"
              data-test="email"
              name="email"
              required
              textFieldProps={{
                label: intl.formatMessage(messages.emailLabel),
              }}
              validationErrors={{
                isEmail: intl.formatMessage(messages.validEmail),
              }}
              validations={{ isEmail: true }}
            />
          </div>
          <div className="login-auth-panel-footer">
            <SubmitButton
              className="[ my-3 fs-2 text-uppercase px-4 py-3 w-100 ] [ login-button ]"
              color="secondary"
              data-test="login"
              isSubmitting={isSubmitting}
              variant="contained"
            >
              {buttonText}
            </SubmitButton>
          </div>
        </Formsy>
        <form action={ssoUrl} method="POST" ref={this.setSSOFormRef}>
          <input name={tokenName} type="hidden" value={tokenValue} />
        </form>
      </div>
    )
  }
}
export default connect(null, { setToken })(withRouter(EmployeeEmail))
