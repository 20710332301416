// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'

// WeSpire
import { intl } from 'utilities/localization'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { NewsFeedHelpBlurb } from 'components/news_feed/help_blurb'
import { NewsFeedItems } from 'components/news_feed/items'
import { NewsFeedItemSkeleton } from 'components/news_feed/item'
import { NEWSFEED_ITEM_FRAGMENT } from 'graphql/fragments'
import { TakeActionLink } from 'components/activities/link'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { client } from 'utilities/we_apollo'
import ScreenReader from 'components/ui/screen_reader_text'
import { pageSize } from 'components/news_feed/utils'
import { times } from 'utilities/times'

const messages = defineMessages({
  noPosts: {
    defaultMessage:
      'You currently have no posts. Visit the {takeAction} and participate in an activity to begin posting!',
    id: 'overviewNewsFeed.noPosts',
  },
  posts: {
    defaultMessage: 'Posts',
    description: 'Collection of posts to display in a social feed',
    id: 'overviewNewsFeed.posts',
  },
  privateModeAlert: {
    defaultMessage:
      'You are currently in <bold>Private Mode</bold>, so all of your social posts throughout the platform are only visible to you. Visit the <link>Settings page</link> to change your privacy settings.',
    description:
      'Alert the user that they are set to private and their posts will not be visible to other users.',
    id: 'overviewNewsFeed.privateModeAlert',
  },
  querySucceeded: {
    defaultMessage: 'Now displaying {number} posts',
    id: 'overviewNewsFeed.querySucceeded',
  },
})

const GET_USER_NEWSFEED_ITEMS = gql`
  query getUserNewsfeedItems($after: String, $first: Int) {
    currentUser {
      id
      newsfeed(after: $after, first: $first) {
        nodes {
          ...NewsfeedItem
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
  ${NEWSFEED_ITEM_FRAGMENT}
`

const OverviewNewsFeed = ({ totalNewsfeedItemCount, visible }) => {
  const { formatMessage } = intl
  const [after, setAfter] = useState(null)
  const [endCursor, setEndCursor] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [newsfeedItems, setNewsfeedItems] = useState([])
  const [queryFailed, setQueryFailed] = useState(false)

  useEffect(() => {
    fetchNewsfeedItems()
  }, [after])

  const allItemsLoaded = newsfeedItems.length === totalNewsfeedItemCount

  const screenReaderText = () => {
    // No matter how hard we tried, we could not get voice over technology to
    // announce anything but messages.querying. We believe that this is a
    // problem with React re-rendering elements enough times quickly in
    // succession that subsequent voice over messages are cleared from the
    // queue. We will investigate fixing this soon.
    if (queryFailed)
      return formatMessage(sharedMessages.newsfeedError, {
        type: sharedTranslations.user,
      })
    if (isLoading) return sharedTranslations.newsfeedLoading
    return formatMessage(messages.querySucceeded, {
      number: newsfeedItems.length,
    })
  }

  const fetchNewsfeedItems = async () => {
    if (totalNewsfeedItemCount === 0) return

    setIsLoading(true)
    try {
      const {
        data: {
          currentUser: {
            newsfeed: {
              nodes,
              pageInfo: { endCursor },
            },
          },
        },
      } = await client.query({
        query: GET_USER_NEWSFEED_ITEMS,
        variables: { after },
      })
      setNewsfeedItems((oldItems) => [...oldItems, ...nodes])
      setEndCursor(endCursor)
    } catch (error) {
      setQueryFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  const loadMore = () => {
    setAfter(endCursor)
  }

  return (
    <Stack data-test="user-profile-overview-newsfeed" space={3}>
      <Heading className="px-3" level={3} variant={2}>
        {formatMessage(messages.posts)}
      </Heading>
      <>
        {!visible && (
          <Stack className="[ d-flex align-items-center ] [ bg-blue-2 border-left-thick-blue p-3 ]">
            <Icon className="fs-5 mr-3 text-blue" iconName="info" title="" />
            <p data-test="private-mode-message">
              {formatMessage(messages.privateModeAlert, {
                bold: (str) => <b>{str}</b>,
                link: (str) => (
                  <Link
                    className="text-link text-link--black-1"
                    to="/user/edit"
                  >
                    {str}
                  </Link>
                ),
              })}
            </p>
          </Stack>
        )}

        {totalNewsfeedItemCount === 0 ? (
          <NewsFeedHelpBlurb dataTest="no-comments-message">
            {formatMessage(messages.noPosts, {
              takeAction: <TakeActionLink />,
            })}
          </NewsFeedHelpBlurb>
        ) : queryFailed ? (
          <Stack as={Paper} className="p-4">
            <p>
              {formatMessage(sharedMessages.newsfeedError, {
                type: sharedTranslations.user,
              })}
            </p>
          </Stack>
        ) : newsfeedItems.length === 0 ? (
          <ul className="list-none pl-0">
            {times(pageSize, (index) => (
              <NewsFeedItemSkeleton as="li" key={index} />
            ))}
          </ul>
        ) : (
          <NewsFeedItems items={newsfeedItems} showCommentContext />
        )}
      </>

      {!allItemsLoaded && (
        <div className="d-flex">
          <LoadMoreButton
            loading={isLoading}
            newsfeedItemsCount={newsfeedItems.length}
            onClick={loadMore}
            pageSize={pageSize}
            totalCount={totalNewsfeedItemCount}
          />
        </div>
      )}

      <ScreenReader role="status" text={screenReaderText()} />
    </Stack>
  )
}

OverviewNewsFeed.propTypes = {
  totalNewsfeedItemCount: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
}

export { OverviewNewsFeed }
