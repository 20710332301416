import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { InputLabel } from '@material-ui/core'
import { undecorated as Select } from 'components/form/select'
import { groupTranslations } from 'components/group/shared_translations'

const { formatMessage } = intl

const messages = defineMessages({
  label: {
    defaultMessage: 'Chapter Selector',
    id: 'chapterSelector.label',
  },
})

const QUERY = gql`
  query groupChaptersQuery($groupId: ID!) {
    group(id: $groupId) {
      id
      manageableGroups {
        id
        name
        isChapter
      }
    }
  }
`

const getGroupLabel = (groupName, isChapter) => {
  if (isChapter) {
    return formatMessage(groupTranslations.chapterSelectorChapter, {
      groupName,
    })
  }
  return formatMessage(groupTranslations.chapterSelectorHomepage, { groupName })
}

export const GroupChapterSelector = ({ groupId }) => {
  const { data, error } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      groupId: groupId,
    },
  })
  const group = data?.group || null

  if (error || !group) {
    return null
  }

  // if manageableGroups length is 1, it means that only group is there, no chapters available
  if (group.manageableGroups?.length <= 1) {
    return null
  }

  const onChapterChange = (val) => {
    const urlRegex = /(\/management_panel\/groups_management\/)(\d+)/gm
    window.location = window.location.pathname.replace(urlRegex, `$1${val}`)
  }

  return (
    <Stack className="row pb-3" space={0}>
      <div className="col-sm-6 col-md-4">
        <InputLabel
          className="fw-semi-bold pb-2 pl-2 text-black-1 text-uppercase"
          htmlFor="chapterSelector"
        >
          {formatMessage(messages.label)}
        </InputLabel>
        <Select
          hideBlankOption
          name="chapterSelector"
          optionProps={{ 'data-test': 'group-chapter-option' }}
          options={group.manageableGroups.map(({ id, isChapter, name }) => ({
            label: getGroupLabel(name, isChapter),
            value: id,
          }))}
          setValue={onChapterChange}
          value={groupId}
        />
      </div>
    </Stack>
  )
}

GroupChapterSelector.propTypes = {
  groupId: PropTypes.string.isRequired,
}
