// A mask consists of a sequence of characters where "9", "a", "*" represent
// input placeholders (See https://github.com/sanniassin/react-input-mask#mask).
// Unmask will parse the mask and only grab the placeholder values from maskedValue.
// E.g. A mask of "(999) 999-9999", with a maskedValue of "(555) 123-4___" will
//      result in clean value of "5551234".
export const unmask = function (mask, maskedValue) {
  let cleanValue = ''
  for (let i = 0; i < mask.length; i++) {
    if (
      ['9', 'a', '*'].includes(mask[i]) &&
      maskedValue[i] &&
      maskedValue[i] !== '_'
    ) {
      cleanValue += maskedValue[i]
    }
  }
  return cleanValue
}
