// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import NavigatorProps from 'components/helpers/navigator_props'
import HelpIconModal from 'components/ui/help_icon_modal'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'

const UPDATE_FEATURED_ACTIVITY = gql`
  mutation updateActivity($activityId: ID!, $featured: Boolean) {
    updateActivity(id: $activityId, featured: $featured) {
      errors
    }
  }
`

export function FeatureActivityToggle({
  activityId,
  featured: initialFeatured,
}) {
  const [featured, setFeatured] = useState(initialFeatured)

  const handleToggleFeatured = () => {
    const previousFeatured = featured
    setFeatured(!featured) // Provide user feedback right away!

    client
      .mutate({
        mutation: UPDATE_FEATURED_ACTIVITY,
        variables: { activityId: activityId, featured: !featured },
      })
      .then(
        ({
          data: {
            updateActivity: { errors },
          },
        }) => {
          if (errors) {
            setFeatured(previousFeatured) // Undo the toggle as there was a problem.
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              fullWidth: true,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        displayExceptionBanner({
          fullWidth: true,
          operation: `toggle the featured Activity`,
        })
      })
  }

  return (
    <GraphQLFeatureFlag
      error={null}
      featureDisabledError={null}
      featureName="ourImpact"
      loader={null}
    >
      <span className="d-flex text-black-3">
        <FormControlLabel
          classes={{ label: 'fs-1 pl-1' }}
          className="ml-0 mr-1"
          control={
            <Checkbox
              checked={featured}
              checkedIcon={<Icon iconName="checkbox_filled" title="Checked" />}
              className="fs-3 p-0"
              color="primary"
              icon={<Icon iconName="checkbox_blank" title="Not Checked" />}
              inputProps={{ 'data-test': 'featured-checkbox' }}
              onChange={handleToggleFeatured}
            />
          }
          label="Feature this Activity"
        />
        <HelpIconModal size="small" title="Featured Activity">
          <p className="m-0">
            Users can see Featured Activities on the{' '}
            <Link to={NavigatorProps.linkPaths.ourImpactPath}>
              Our Impact page
            </Link>
            . Three activities are shown and are sorted by when they end first.
            Note that different activities will be viewable by different users
            depending on their team.
          </p>
        </HelpIconModal>
      </span>
    </GraphQLFeatureFlag>
  )
}

FeatureActivityToggle.propTypes = {
  activityId: PropTypes.number.isRequired,
  featured: PropTypes.bool.isRequired,
}
