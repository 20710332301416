import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
// @TODO: Remove this icon when we have Material Symbols before we migrate to Next
import Icon from 'components/ui/icon'
import sanitizeHtml from 'sanitize-html-react'

export const CharityCardContainer = ({ children }) => (
  <li className="flex flex-col sm:flex-row justify-between border-b pb-4 mb-4">
    <div className="flex flex-col w-full">{children}</div>
  </li>
)

CharityCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export const CharityCardMission = ({ mission }) => {
  const intl = useIntl()
  const [chevronDirection, setChevronDirection] = useState('chevron_down')
  const [accordionButtonText, setAccordionButtonText] = useState(
    intl.formatMessage({ id: 'charity.learnMore' })
  )

  const setChevron = (chevron) => {
    if (chevron === 'chevron_down') {
      setAccordionButtonText(
        intl.formatMessage({ id: 'charity.hideCharityMission' })
      )
      return setChevronDirection('chevron_up')
    }
    setAccordionButtonText(intl.formatMessage({ id: 'charity.learnMore' }))
    setChevronDirection('chevron_down')
  }
  const strippedHtml = sanitizeHtml(mission, {
    allowedAttributes: {},
    allowedTags: [],
  })

  return (
    mission && (
      <div className="mt-4">
        <button
          className="text-smart-blue text-sm"
          onClick={() => setChevron(chevronDirection)}
        >
          {accordionButtonText}
          <span>
            <Icon
              className="text-xl ml-2 inline-block align-text-bottom"
              iconName={chevronDirection}
              title=""
            />
          </span>
        </button>
        {chevronDirection === 'chevron_up' && (
          <div className="text-sm mt-2 leading-relaxed">{strippedHtml}</div>
        )}
      </div>
    )
  )
}

CharityCardMission.propTypes = {
  mission: PropTypes.string,
}

CharityCardMission.defaultProps = {
  mission: null,
}

export const formatAddress = (address) => {
  const { city, state, country } = address
  return `${city ? `${city}` : ''}${state ? `, ${state}` : ''}${
    country ? `, ${country.displayName}` : ''
  }`
}
