// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import {
  LoadingIndicator,
  LoadingIndicatorSize,
} from 'components/ui/loading_indicator'
import LinkButton from 'components/ui/link_button'
import { sharedTranslations } from 'components/shared/translations'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'
import WeQuery from 'components/application/we_query'
import { sharedMessages } from '../../shared/translations'
import { grantEligibilityCheckerMessages as messages } from '../utilities/messages'
import { GET_GRANT_ELIGIBILITY } from '../utilities/queries'

export const GrantEligibilityChecker = ({ ein, organizationName }) => {
  const { formatMessage } = intl
  const charityisIneligibleModal = (
    <TextWithHelpIconModal
      className="fs-1"
      helpIconModalProps={{
        'data-test': 'grant-eligibility-charity-ineligible',
      }}
      modalContent={
        <p>
          {formatMessage(messages.orgIsNotEligibleContact, {
            contactSupport: (
              <a href="mailto:support@wespire.com?subject=Grant%20Donation%20Eligibility%20Request">
                {sharedTranslations.contactSupport}
              </a>
            ),
          })}
        </p>
      }
      text={formatMessage(messages.ineligibleForGrants)}
      title={formatMessage(messages.orgIsNotEligible, {
        organizationName,
      })}
    />
  )
  const noDonationAccountModal = (
    <TextWithHelpIconModal
      className="fs-1"
      helpIconModalProps={{
        'data-test': 'grant-eligibility-no-givinga-user',
      }}
      modalContent={
        <p>
          {formatMessage(messages.notEligibleWithNoDonationAccount, {
            contactSupport: (
              <a
                className="text-sentence"
                href="mailto:support@wespire.com?subject=Giving%20Account%20Error"
              >
                {sharedTranslations.contactSupport}
              </a>
            ),
          })}
        </p>
      }
      text={formatMessage(messages.ineligibleForGrants)}
      title={formatMessage(messages.noDonationAccount)}
    />
  )

  return ein ? (
    <WeQuery
      error={null}
      loader={
        <LoadingIndicator color="inherit" size={LoadingIndicatorSize.SMALL} />
      }
      query={GET_GRANT_ELIGIBILITY}
      variables={{ ein }}
    >
      {({
        data: {
          charity,
          currentUser: {
            charityEligibleForGrant,
            givingaUser,
            grantEligibleVolunteerHours,
            volunteerHoursThisYear,
          },
        },
      }) => {
        if (!givingaUser) {
          return noDonationAccountModal
        }

        if (!charity || !charity.grantEligible) {
          return charityisIneligibleModal
        }
        const { availableDonationFundsFormatted, donationFundsCapFormatted } =
          givingaUser
        const alreadyUsedHours =
          volunteerHoursThisYear - grantEligibleVolunteerHours

        return charityEligibleForGrant ? (
          <LinkButton
            className="[ text-link text-link--black-1 ] [ fs-1 ]"
            data-test="grant-donation-link"
            to={`/grant_donations/new/${ein}`}
            variant="neutral"
          >
            {formatMessage(messages.requestAGrantDonation)}
          </LinkButton>
        ) : (
          <TextWithHelpIconModal
            className="fs-1"
            helpIconModalProps={{
              'data-test': 'grant-eligibility-user-ineligible',
            }}
            modalContent={
              <Fragment>
                <p>
                  {formatMessage(messages.willBeEligibleModel, {
                    organizationName,
                  })}
                  :
                  <ol>
                    <li>
                      {formatMessage(messages.hoursLoggedForOrgModel, {
                        organizationName: organizationName,
                        strong: (str) => <strong>{str}</strong>,
                      })}
                    </li>
                    <li>
                      {formatMessage(messages.availableFundsModel, {
                        organizationName: organizationName,
                        strong: (str) => <strong>{str}</strong>,
                      })}
                    </li>
                  </ol>
                </p>
                <p>
                  {formatMessage(messages.hoursVolunteeredAndTheirUseModel, {
                    alreadyUsedHours: intl.formatMessage(
                      sharedMessages.hoursWithCount,
                      { count: alreadyUsedHours }
                    ),
                    grantEligibleVolunteerHours: intl.formatMessage(
                      sharedMessages.hoursWithCount,
                      { count: grantEligibleVolunteerHours }
                    ),
                    organizationName,
                    volunteerHoursThisYear: intl.formatMessage(
                      sharedMessages.hoursWithCount,
                      { count: volunteerHoursThisYear }
                    ),
                  })}
                </p>

                <p>
                  {formatMessage(messages.availableFunds, {
                    availableDonationFunds: availableDonationFundsFormatted,
                    donationFundsCap: donationFundsCapFormatted,
                  })}
                </p>
              </Fragment>
            }
            text={formatMessage(messages.ineligibleForGrants)}
            title={formatMessage(messages.cannotRequestGrantsForOrg, {
              organizationName,
            })}
          />
        )
      }}
    </WeQuery>
  ) : (
    charityisIneligibleModal
  )
}

GrantEligibilityChecker.propTypes = {
  /** ein of organization for which we are checking grant elibility */
  ein: PropTypes.string,
  /** name of organization for which we are checking grant eligibility */
  organizationName: PropTypes.string.isRequired,
}

GrantEligibilityChecker.defaultProps = {
  ein: null,
}
