// Vendor
import React from 'react'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { NewsFeedHelpBlurb } from 'components/news_feed/help_blurb'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { NewsFeedItems } from 'components/news_feed/items'
import { NewsFeedItemSkeleton } from 'components/news_feed/item'
import { pageSize } from 'components/news_feed/utils'
import ScreenReader from 'components/ui/screen_reader_text'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { times } from 'utilities/times'

const GroupNewsfeed = ({ feedQuery, groupId }) => {
  const { formatMessage } = intl
  const { loading, error, data, fetchMore } = useQuery(feedQuery, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: { id: groupId },
  })

  const loadNextPage = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldNodes = previousResult.group.newsfeed.nodes
        const newNodes = fetchMoreResult.group.newsfeed.nodes

        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.group.newsfeed.nodes = [...oldNodes, ...newNodes]

        return result
      },
      variables: {
        after: data.group.newsfeed.pageInfo.endCursor,
      },
    })
  }

  if (loading && !data) {
    return (
      <ul className="list-none pl-0">
        {times(pageSize, (index) => (
          <NewsFeedItemSkeleton as="li" key={index} />
        ))}
      </ul>
    )
  }

  if (error) {
    return (
      <>
        <Stack as={Paper} className="p-4">
          <p>
            {formatMessage(sharedMessages.newsfeedError, {
              type: sharedTranslations.group,
            })}
          </p>
        </Stack>
        <ScreenReader
          role="status"
          text={formatMessage(sharedMessages.newsfeedError, {
            type: sharedTranslations.group,
          })}
        />
      </>
    )
  }
  const newsfeedLengh = data.group.newsfeed.nodes.length
  const newsfeedItems = data.group.newsfeed.nodes
  const totalCount = data.group.newsfeed.totalCount
  const hasNextPage = totalCount > newsfeedLengh

  if (newsfeedItems.length === 0) {
    return (
      <NewsFeedHelpBlurb dataTest="no-comments-message">
        {sharedTranslations.noCommentsMessage}
      </NewsFeedHelpBlurb>
    )
  }

  return (
    <>
      <NewsFeedItems items={newsfeedItems} showCommentContext />
      {hasNextPage && (
        <div className="d-flex">
          <LoadMoreButton
            loading={loading}
            newsfeedItemsCount={newsfeedItems.length}
            onClick={loadNextPage}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        </div>
      )}
      {loading && (
        <ScreenReader role="status" text={sharedTranslations.newsfeedLoading} />
      )}
    </>
  )
}

GroupNewsfeed.propTypes = {
  feedQuery: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
}

export { GroupNewsfeed }
