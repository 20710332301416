// Setup
import React, { useEffect, useState } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { withFormsy } from 'formsy-react'

// WeSpire
import { Button as BaseButton } from 'components/ui/button'
import Charity from 'components/charities/charity'
import CharitySearch from 'components/charities/charity_search'
import CharitySearchResults from 'components/charities/search_results'
import FormFieldset from 'components/form/fieldset'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import VerticalScrollBox from 'components/ui/vertical_scroll_box'

const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'List of Charities found',
    id: 'specificCharitySearch.ariaLabel',
  },
  enableCharity: {
    defaultMessage: 'Enable',
    id: 'specificCharitySearch.enableCharity',
  },
  enabledCharities: {
    defaultMessage: 'Charities Enabled for this Giving Activity',
    id: 'specificCharitySearch.enabledCharities',
  },
  noEnabledCharities: {
    defaultMessage: 'No charities enabled for this giving activity.',
    id: 'specificCharitySearch.noEnabledCharities',
  },
  removeCharity: {
    defaultMessage: 'Remove',
    id: 'specificCharitySearch.removeCharity',
  },
  searchResults: {
    defaultMessage: 'Search Results',
    id: 'specificCharitySearch.searchResults',
  },
})

const SpecificCharitySelect = ({ value, setValue }) => {
  const [charities, setCharities] = useState([])
  const [charitiesFetchStatus, setCharitiesFetchStatus] = useState('pending')
  const [enabledCharities, setEnabledCharities] = useState(value)

  useEffect(() => {
    setValue(enabledCharities)
  }, [enabledCharities])

  const enableCharity = (charity) => () => {
    setEnabledCharities([...enabledCharities, charity])
  }
  const disableCharity = (charity) => () => {
    setEnabledCharities(enabledCharities.filter(({ id }) => charity.id !== id))
  }

  const handleCharitySearch = ({ charities, status }) => {
    setCharities(charities)
    setCharitiesFetchStatus(status)
  }

  return (
    <>
      <CharitySearch managementPanel onCharitySearch={handleCharitySearch} />

      <FormFieldset data-test="search-results" label="" space={0}>
        <VerticalScrollBox ariaLabel={intl.formatMessage(messages.ariaLabel)}>
          <Stack
            as="ol"
            className="p-0 mt-0"
            divider="default"
            id="search-results"
          >
            <CharitySearchResults
              charities={charities}
              renderCharity={({ charity, ...props }) =>
                // eslint-disable-next-line eqeqeq
                enabledCharities.some(({ id }) => charity.id == id) ? null : (
                  <Charity
                    {...props}
                    charity={charity}
                    renderAction={
                      <BaseButton
                        className="flex-shrink-none align-self-end | m-2 fs-4"
                        data-test="enable-charity"
                        onClick={enableCharity(charity)}
                        variant="text"
                      >
                        {intl.formatMessage(messages.enableCharity)}
                      </BaseButton>
                    }
                  />
                )
              }
              status={charitiesFetchStatus}
            />
          </Stack>
        </VerticalScrollBox>
      </FormFieldset>

      <Stack data-test="enabled-charities" space={0}>
        <Heading level={2}>
          {intl.formatMessage(messages.enabledCharities)}
        </Heading>
        <Stack as="ol" className="p-0" divider="default" id="search-results">
          <CharitySearchResults
            charities={enabledCharities}
            noCharitiesMessage={intl.formatMessage(messages.noEnabledCharities)}
            renderCharity={({ charity, ...props }) => (
              <Charity
                {...props}
                charity={charity}
                renderAction={
                  <BaseButton
                    className="flex-shrink-none align-self-end | m-2 fs-4"
                    data-test="remove-charity"
                    onClick={disableCharity(charity)}
                    variant="text"
                  >
                    {intl.formatMessage(messages.removeCharity)}
                  </BaseButton>
                }
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  )
}

SpecificCharitySelect.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.array,
}

SpecificCharitySelect.defaultProps = {
  value: [],
}

export default withFormsy(SpecificCharitySelect)
