// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { ShiftTimeframeTime } from 'components/events/registration/shift_timeframe_time'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  achievementEarned: {
    defaultMessage: 'Earned an achievement: {subject}',
    description:
      'message automatically generated when a user earns an achievement',
    id: 'newsFeedItemAction.achievementEarned',
  },
  actionCompleted: {
    defaultMessage: 'Completed {subject}',
    description:
      'message automatically generated when a user completes an action',
    id: 'newsFeedItemAction.actionCompleted',
  },
  actionProgress: {
    defaultMessage: 'Logged progress on {subject}',
    description:
      'message automatically generated when a user logs progress on an accumulation action',
    id: 'newsFeedItemAction.actionProgress',
  },
  activityCommented: {
    defaultMessage: 'Commented on {subject}',
    description:
      'message automatically generated when a user leaves a comment on an activity',
    id: 'newsFeedItemAction.activityCommented',
  },
  eventRegistered: {
    defaultMessage: 'Registered for {subject}',
    description:
      'message automatically generated when a user leaves a comment while registering for an event',
    id: 'newsFeedItemAction.eventRegistered',
  },
  hoursVolunteered: {
    defaultMessage: 'Logged volunteer hours for {subject}',
    description:
      'message automatically generated when a user logs volunteer hours',
    id: 'newsFeedItemAction.hoursVolunteered',
  },
  ideaSubmitted: {
    defaultMessage: 'Submitted an idea: {subject}',
    description: 'message automatically generated when a user submits an idea',
    id: 'newsFeedItemAction.ideaSubmitted',
  },
  shiftRegistered: {
    defaultMessage: 'Registered to participate: {subject}',
    description:
      'message automatically generated when a user registers for an event shift',
    id: 'newsFeedItemAction.shiftRegistered',
  },
})

const { formatMessage } = intl

export const NewsFeedItemAction = ({
  actionTitle,
  className,
  endsAt,
  orgName,
  startsAt,
  type,
  verb,
}) => {
  const translationKey = () => {
    if (verb === 'commented' || verb === 'participated') {
      return 'activityCommented'
    }

    switch (type) {
      case 'Achievement':
        return 'achievementEarned'
      case 'Act':
        if (verb === 'shared_news') {
          return 'activityCommented'
        }
        return verb === 'logged_progress' ? 'actionProgress' : 'actionCompleted'
      case 'VolunteerLog':
        return 'hoursVolunteered'
      case 'IdeaBoard::Submission':
        return 'ideaSubmitted'
      case 'ParticipationEvent':
        return 'eventRegistered'
      case 'Shift':
        return 'shiftRegistered'
      default:
        return 'activityCommented'
    }
  }
  const messageKey = translationKey()
  const dataTestKey = type === 'Group' ? 'groupCommented' : messageKey

  let subject
  if (messageKey === 'shiftRegistered') {
    subject = <ShiftTimeframeTime end={endsAt} start={startsAt} />
  } else if (messageKey === 'hoursVolunteered') {
    subject = orgName
  } else {
    subject = actionTitle
  }

  return (
    <p className={className} data-test={`item-action-${dataTestKey}`}>
      {formatMessage(messages[messageKey], {
        subject: <b key={messageKey}>{subject}</b>,
      })}
    </p>
  )
}

NewsFeedItemAction.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  endsAt: PropTypes.string,
  orgName: PropTypes.string,
  startsAt: PropTypes.string,
  type: PropTypes.oneOf([
    'Achievement',
    'Act',
    'Group',
    'ParticipationEvent',
    'Shift',
    'VolunteerLog',
    'IdeaBoard::Submission',
  ]).isRequired,
  verb: PropTypes.oneOf([
    'earned',
    'commented',
    'completed',
    'logged_progress',
    'participated',
    'registered',
    'shared_news',
    'volunteered',
    'submitted',
  ]).isRequired,
}

NewsFeedItemAction.defaultProps = {
  className: null,
  endsAt: null,
  orgName: null,
  startsAt: null,
}
