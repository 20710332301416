// Setup
import React from 'react'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import Avatar from 'components/application/avatar'
import { getGroupUrl } from 'components/group/utils'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { UserNameQuery } from 'components/queries/username_query'
import WeQuery from 'components/application/we_query'
import { Heading } from 'components/ui/heading'
import { UserAccordionInfo } from './user_accordion_info'

const { formatMessage } = intl
const messages = defineMessages({
  basicInfo: {
    defaultMessage: 'Basic Info',
    id: 'userProfileBasicInfo.basicInfo',
  },
  toggleGroupDetails: {
    defaultMessage: 'Toggle group details',
    id: 'userProfileBasicInfo.toggleGroupDetails',
  },
  toggleTeamDetails: {
    defaultMessage: 'Toggle team details',
    id: 'userProfileBasicInfo.toggleTeamDetails',
  },
  updateYourProfilePicture: {
    defaultMessage: 'Update your profile picture',
    id: 'userProfileBasicInfo.updateYourProfilePicture',
  },
  yourBrandInsterestGroups: {
    defaultMessage: 'Your {brandErgName}',
    id: 'userProfileBasicInfo.yourBrandInsterestGroups',
  },
  yourCompanyTeams: {
    defaultMessage: 'Your Company Teams',
    id: 'userProfileBasicInfo.yourCompanyTeams',
  },
  yourDetails: {
    defaultMessage: 'your details',
    id: 'userProfileBasicInfo.yourDetails',
  },
})

export const UserProfileBasicInfo = ({ userId }) => {
  return (
    <section>
      <Heading className="sr-only" level={3}>
        {formatMessage(messages.basicInfo)}
      </Heading>
      <WeQuery
        error={
          <MissingResourceSection
            as={Paper}
            errorDetails={formatMessage(messages.yourDetails)}
          />
        }
        loader={<SkeletonLoading height={200} />}
        query={gql`
          query userBasicInfoQuery($id: ID!) {
            ergsEnabled
            brandConfig {
              id
              friendlyErgNameWithDefault
            }
            groups(groupFilter: "userGroups") {
              id
              name
              slug
              userGroupChapter {
                id
                name
                slug
              }
            }
            user(id: $id) {
              avatarUrl
              id
              teams {
                id
                name
                pagePath
              }
            }
          }
        `}
        variables={{ id: userId }}
      >
        {({ data: { ergsEnabled, groups, user, brandConfig } }) => {
          return (
            <Stack
              as={Paper}
              className={cx('pt-4', {
                'pb-4': user.teams.length === 0,
              })}
              space={4}
            >
              <Avatar
                aria-label="avatar"
                className="border border-blue-2 d-block m-auto rounded-circle"
                data-test="user-icon"
                image={user.avatarUrl}
                size="xl"
              />
              <Stack className="d-flex flex-column" space={3}>
                <span
                  className="fs-4 fw-semi-bold lh-sm text-center"
                  data-test="user-name"
                >
                  <UserNameQuery userId={userId} />
                </span>
                <Link
                  className="fs-2 text-center text-link w-100"
                  data-test="avatar-link"
                  to="/user/avatar"
                >
                  {formatMessage(messages.updateYourProfilePicture)}
                </Link>
              </Stack>
              <div className="mt-4">
                {ergsEnabled && groups.length > 0 && (
                  <UserAccordionInfo
                    dataTestPrefix="groups"
                    expandTitle={formatMessage(messages.toggleGroupDetails)}
                    key="groups"
                    links={groups.map((group) => ({
                      id: group.id,
                      label: group.userGroupChapter
                        ? `${group.name} - ${group.userGroupChapter.name}`
                        : group.name,
                      url: getGroupUrl({
                        chapterIdentifier: group.userGroupChapter?.slug,
                        groupIdentifier: group.slug,
                      }),
                    }))}
                    title={formatMessage(messages.yourBrandInsterestGroups, {
                      brandErgName: brandConfig.friendlyErgNameWithDefault,
                    })}
                  />
                )}
                {user.teams.length > 0 && (
                  <UserAccordionInfo
                    dataTestPrefix="teams"
                    expandTitle={formatMessage(messages.toggleTeamDetails)}
                    key="teams"
                    links={user.teams.map((team) => ({
                      id: user.id,
                      label: team.name,
                      url: team.pagePath,
                    }))}
                    title={formatMessage(messages.yourCompanyTeams)}
                  />
                )}
              </div>
            </Stack>
          )
        }}
      </WeQuery>
    </section>
  )
}

UserProfileBasicInfo.propTypes = {
  userId: PropTypes.string.isRequired,
}
