// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { isHtml } from 'utilities/html'
import WysiwygHtmlContainer from 'components/application/wysiwyg_html_container'

/** Container that can render either HTML or plain text with user-generated
    content. Sometimes a db attribute can contain one or other, so we need to be
    ablet to handle both. If the content is HTML it will be wrapped in a
    WYSIWYG-ready container. Otherwise, we expect a plain string that will be
    wrapped in a <p> preserving newlines. */
const WysiwygContent = ({ children, ...other }) =>
  isHtml(children) ? (
    <WysiwygHtmlContainer {...other}>{children}</WysiwygHtmlContainer>
  ) : (
    <p {...other} className={cx('ws-pre', other.className)}>
      {children}
    </p>
  )

WysiwygContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default WysiwygContent
