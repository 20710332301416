export const COUNTRY = 'countryCode'
export const POSTAL_CODE = 'postalCode'
export const HOUSEHOLD_SIZE = 'householdSize'
export const HOUSING_TYPE = 'housingType'
export const PERCENTAGE_RENEWABLE_ENERGY = 'percentRenewableEnergy'
export const ANIMAL_PRODUCTS_CONSUMED = 'animalProductsConsumed'
export const SHORT_BUSINESS_FLIGHT = 'shortBusinessFlightCount'
export const MEDIUM_BUSINESS_FLIGHT = 'mediumBusinessFlightCount'
export const LONG_BUSINESS_FLIGHT = 'longBusinessFlightCount'
export const EXTENDED_BUSINESS_FLIGHT = 'extendedBusinessFlightCount'
export const SHORT_PERSONAL_FLIGHT = 'shortPersonalFlightCount'
export const MEDIUM_PERSONAL_FLIGHT = 'mediumPersonalFlightCount'
export const LONG_PERSONAL_FLIGHT = 'longPersonalFlightCount'
export const EXTENDED_PERSONAL_FLIGHT = 'extendedPersonalFlightCount'
// gas is fuel type diesel fuel type 2
export const VEHICLE_TYPE = 'vehicleFuelType1'
// no car equals vehical distance total 0
export const APPROXIMATE_DISTANCE_TRAVELED_CAR = 'vehicleDistanceTotal1'
export const VEHICLE_FUEL_EFFICIENCY = 'vehicleFuelEfficiency1'
export const APPROXIMATE_DISTANCE_TRAVELED_EACH_YEAR =
  'publicTransportationDistanceTotal'
export const TRANSPORT_TYPE = 'commuteTransportType'
export const ROUND_TRIP_DISTANCE = 'commuteRoundTripDistanceDaily'
export const REMOTE_NUMBER_OF_DAYS = 'remoteDaysWeekly'
