// Vendor
import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import Picker from 'emoji-picker-react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import v from 'voca'
import { defineMessages } from 'react-intl'

// Wespire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import Icon from 'components/ui/icon'
import SubmitButton from 'components/ui/submit_button'
import TextField from '@material-ui/core/TextField'
import { NewsFeedAnalytics } from 'utilities/analytics'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { formatMessages } from 'components/shared/translations'

const messages = defineMessages({
  post: {
    defaultMessage: 'post',
    id: 'commentForm.post',
  },
})
const commentTranslations = formatMessages(messages)

const { formatMessage } = intl

const CommentForm = ({
  commentableId,
  disabled,
  onChange,
  onSubmit,
  setCommentFormRef,
  setValue,
  user,
  value,
  ...other
}) => {
  const [showPicker, setShowPicker] = useState(false)

  function submitDisabled() {
    return disabled || v.isBlank(value)
  }

  const onEmojiClick = (event, emojiObject) => {
    setValue((prevInput) => prevInput + emojiObject.emoji)
    setShowPicker(false)
  }

  const handleCommentFocus = () => {
    NewsFeedAnalytics.commentReplyBoxFocused(commentableId)
  }

  const uniqueId = `comment-${commentableId}-${Math.random()}`

  return (
    <form
      {...other}
      className={cx('d-flex align-items-center', other.className)}
      onSubmit={onSubmit}
    >
      <Avatar image={user.avatarUrl} size={AvatarSizes.small} />
      <>
        <TextField
          autoComplete="off"
          className="ml-3 w-100"
          data-test="comment-field"
          disabled={disabled}
          fullWidth
          id={uniqueId}
          inputProps={{
            'aria-required': true,
            className: 'fs-2 lh-md',
          }}
          InputProps={{
            classes: {
              notchedOutline: 'rounded-left-right',
              root: 'py-2',
            },
            'data-test': 'comment-message',
            endAdornment: (
              <>
                <div>
                  <IconButton
                    className={cx('d-none d-lg-inline fs-2', {
                      'cursor-not-allowed pointer-events-none text-black-5':
                        disabled,
                    })}
                    data-test="icon-selector"
                    onClick={() => setShowPicker((val) => !val)}
                  >
                    <Icon
                      iconName="smiley"
                      title={sharedTranslations.emojiSelector}
                    />
                  </IconButton>
                  {showPicker && (
                    <Picker
                      onEmojiClick={onEmojiClick}
                      pickerStyle={{ position: 'absolute', 'z-index': '1000' }}
                    />
                  )}
                </div>
                <SubmitButton
                  className="flex-shrink-none mt-1"
                  color="secondary"
                  data-test="post-comment"
                  disabled={submitDisabled()}
                  isSubmitting={false}
                  type="submit"
                >
                  {formatMessage(messages.post)}
                </SubmitButton>
              </>
            ),
            onFocus: handleCommentFocus,
          }}
          inputRef={setCommentFormRef}
          label={v.capitalize(sharedTranslations.comment)}
          multiline
          name="message"
          onChange={onChange}
          value={value}
          variant="outlined"
        />
      </>
    </form>
  )
}

CommentForm.defaultProps = {
  className: null,
  disabled: false,
  value: '',
}

// PropType definitions
CommentForm.propTypes = {
  className: PropTypes.string,
  commentableId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCommentFormRef: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
}

export { CommentForm, commentTranslations }
