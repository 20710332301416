// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'
import { LeaderBoardFilters } from './filters'
import Stack from 'components/ui/stack'
import { LeaderBoardSection } from './leaderboard_section'
import {
  LEADERBOARD_QUERY_NAME,
  LEADERBOARD_COMPETITOR_FRAGMENT,
} from './utils/queries'
import { leaderBoardTranslations } from './utils/shared_translations'
import { LeaderboardCompetitors } from './leaderboard_competitors'
import { Heading } from 'components/ui/heading'
import { campaignTranslations } from 'components/campaign/shared_translations'

export const GET_TEAMS = gql`
  query getTeams {
    currentUser {
      id
      teams {
        id
        name
      }
    }
  }
`

const GET_COMPETITION_LEADERBOARD = gql`
  ${LEADERBOARD_COMPETITOR_FRAGMENT}
  fragment UserCompetitorAttributes on RankedCompetitorInterface {
    id
    firstName
    lastName
  }

  query ${LEADERBOARD_QUERY_NAME}($campaignId: ID!, $teamId: ID) {
    campaign(id: $campaignId) {
      id
      leaderboard(teamId: $teamId) {
        ...CompetitorAttributes
        ...UserCompetitorAttributes
      }
      leaderboardForUser(teamId: $teamId) {
        ...CompetitorAttributes
        ...UserCompetitorAttributes
      }
    }
  }
`

const LeaderboardUsers = ({ campaignId }) => {
  const [filterId, setFilterId] = useState(null)

  return (
    <Stack as="section" space={{ _: 3, sm: 0 }}>
      <div className="d-sm-flex justify-content-sm-between">
        <Heading className="mt-2 p-3" level={2}>
          {campaignTranslations.competitionLeaderboard}
        </Heading>

        <WeQuery
          error={
            <p className="m-0 px-3 py-4">
              {leaderBoardTranslations.problemLoadingTeamFilters}
            </p>
          }
          loader={<SkeletonLoading height={52} />}
          query={GET_TEAMS}
        >
          {({ data: { currentUser } }) => (
            <LeaderBoardFilters
              activityId={campaignId}
              filterType="team"
              onChange={setFilterId}
              options={currentUser.teams}
            />
          )}
        </WeQuery>
      </div>

      <div className="row">
        <WeQuery
          error={
            <p className="m-0 px-3">
              {leaderBoardTranslations.problemLoadingLeaderboard}
            </p>
          }
          loader={
            <LeaderBoardSection heading={leaderBoardTranslations.leaders}>
              <SkeletonLoading height={258} />
            </LeaderBoardSection>
          }
          query={GET_COMPETITION_LEADERBOARD}
          variables={{ campaignId: campaignId, teamId: filterId }}
        >
          {({
            data: {
              campaign: { leaderboard, leaderboardForUser },
            },
          }) => (
            <LeaderboardCompetitors
              competitors={leaderboard}
              competitorsForUser={leaderboardForUser}
            />
          )}
        </WeQuery>
      </div>
    </Stack>
  )
}

LeaderboardUsers.propTypes = {
  campaignId: PropTypes.string.isRequired,
}

export { LeaderboardUsers }
