// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { CampaignProgressBar } from 'components/campaign/progress_bar'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { RelativeTime } from 'components/shared/relative_time'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TimeRange } from 'components/ui/time'

const messages = defineMessages({
  percentComplete: {
    defaultMessage: '{progressPercent}% complete',
    id: 'activityCard.percentComplete',
  },
})

const ActivityCard = ({
  achievement,
  actionUrl,
  children,
  'data-test': dataTest,
  endsAt,
  name,
  progressPercent,
  startsAt,
  type,
}) => {
  const achievementEarned = achievement && achievement.awardedTo
  const { formatMessage } = intl

  return (
    <Stack
      as={Paper}
      className="[ d-inline-flex flex-column justify-content-between ] [ breakout ][ pb-3 w-100 ]"
      data-test={dataTest}
    >
      <Stack className="h-100 pt-3 px-3" space={4}>
        <Stack className="[ d-flex flex-column ] [ h-100 ]" space={2}>
          <span className="d-inline-block fs-1 fw-semi-bold lh-md text-black-3 text-uppercase">
            {sharedTranslations[type]}
            {achievementEarned && (
              <>
                <BulletSpacer />
                <span
                  className="text-green text-uppercase"
                  data-test="complete"
                >
                  {sharedTranslations.completed}
                </span>
              </>
            )}
          </span>
          <Link
            aria-describedby={actionUrl}
            className="breakout__item d-block fs-2 fw-semi-bold lh-md text-black-1"
            data-test="activity-name"
            to={actionUrl}
          >
            {name}
          </Link>
          <Stack className="[ d-flex flex-column justify-content-end ] [ h-100 ]">
            <div className="fs-1 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase">
              <TimeRange endsAt={endsAt} startsAt={startsAt} />
              {endsAt && (
                <>
                  <BulletSpacer /> <RelativeTime date={endsAt} showPrefix />
                </>
              )}
            </div>
            {(progressPercent || progressPercent === 0) && (
              <CampaignProgressBar
                achievement={achievement}
                progressPercent={progressPercent}
              >
                <span className="d-inline-block fs-1 fw-semi-bold text-green">
                  {formatMessage(messages.percentComplete, {
                    progressPercent: progressPercent,
                  })}
                </span>
              </CampaignProgressBar>
            )}
          </Stack>
        </Stack>
      </Stack>
      {children && (
        <div className="border-top mt-3 pb-0 pt-3 px-3">{children}</div>
      )}
    </Stack>
  )
}

ActivityCard.propTypes = {
  achievement: PropTypes.shape({
    awardedTo: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  actionUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  'data-test': PropTypes.string,
  endsAt: PropTypes.string,
  name: PropTypes.string.isRequired,
  progressPercent: PropTypes.number,
  startsAt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

ActivityCard.defaultProps = {
  achievement: null,
  children: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'activity',
  endsAt: null,
  progressPercent: null,
}

export { ActivityCard }
