// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'
import { LeaderBoardFilters } from './filters'
import Stack from 'components/ui/stack'
import { LeaderBoardSection } from './leaderboard_section'
import {
  LEADERBOARD_QUERY_NAME,
  LEADERBOARD_COMPETITOR_FRAGMENT,
} from './utils/queries'
import { leaderBoardTranslations } from './utils/shared_translations'
import { LeaderboardCompetitors } from './leaderboard_competitors'
import { Heading } from 'components/ui/heading'
import { campaignTranslations } from 'components/campaign/shared_translations'

const GET_COMPETITION_TEAM_LEADERBOARD = gql`
  ${LEADERBOARD_COMPETITOR_FRAGMENT}

  query ${LEADERBOARD_QUERY_NAME}($campaignId: ID!, $teamSetId: ID) {
    campaign(id: $campaignId) {
      id
      teamLeaderboard(teamSetId: $teamSetId) {
        ...CompetitorAttributes
      }
      teamLeaderboardForUser(teamSetId: $teamSetId) {
        ...CompetitorAttributes
      }
    }
  }
`

const LeaderboardTeams = ({ campaignId, teamSets }) => {
  const [filterId, setFilterId] = useState(null)

  return (
    <Stack as="section" space={{ _: 3, sm: 0 }}>
      <div className="d-sm-flex justify-content-sm-between">
        <Heading className="mt-2 p-3" level={2}>
          {campaignTranslations.competitionLeaderboard}
        </Heading>

        <LeaderBoardFilters
          activityId={campaignId}
          filterType="teamset"
          onChange={setFilterId}
          options={teamSets}
        />
      </div>

      <div className="row">
        <WeQuery
          error={
            <p className="m-0 px-3 py-4">
              {leaderBoardTranslations.problemLoadingLeaderboard}
            </p>
          }
          loader={
            <LeaderBoardSection heading={leaderBoardTranslations.leaders}>
              <SkeletonLoading height={258} />
            </LeaderBoardSection>
          }
          query={GET_COMPETITION_TEAM_LEADERBOARD}
          variables={{ campaignId: campaignId, teamSetId: filterId }}
        >
          {({
            data: {
              campaign: { teamLeaderboard, teamLeaderboardForUser },
            },
          }) => (
            <LeaderboardCompetitors
              competitors={teamLeaderboard}
              competitorsForUser={teamLeaderboardForUser}
              forTeams
            />
          )}
        </WeQuery>
      </div>
    </Stack>
  )
}

LeaderboardTeams.propTypes = {
  campaignId: PropTypes.string.isRequired,
  teamSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export { LeaderboardTeams }
