// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Avatar from 'components/application/avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const AutocompleteSuggestion = ({
  imageUrl,
  isSelected,
  itemProps,
  primaryText,
  secondaryText,
}) => (
  <ListItem
    {...itemProps}
    button
    data-test="autocomplete-suggestion"
    selected={isSelected}
  >
    {imageUrl && <Avatar className="mr-3" image={imageUrl} />}
    <ListItemText primary={primaryText} secondary={secondaryText} />
  </ListItem>
)

AutocompleteSuggestion.propTypes = {
  imageUrl: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  itemProps: PropTypes.object.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
}

AutocompleteSuggestion.defaultProps = {
  imageUrl: null,
  secondaryText: null,
}

export default AutocompleteSuggestion
