// Vendor
import React from 'react'
import PropTypes from 'prop-types'

const UserChangeRow = ({ userChange }) => (
  <>
    <div className="row py-3 fs-2">
      <span className="col-1">{userChange.user?.firstName}</span>
      <span className="col-1">{userChange.user?.lastName}</span>
      <span className="col-2">{userChange.user?.email}</span>
      <span className="col-1">{userChange.status}</span>
      <span className="col-1">{userChange.action}</span>
      <span className="col-4">{userChange.message}</span>
    </div>
  </>
)

UserChangeRow.propTypes = {
  userChange: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    action: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export { UserChangeRow }
