import React, { Fragment } from 'react'
import { displayBanner } from 'redux/dispatchers'
import BannerLink from 'components/application/banner/link'
import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'

// WeSpire
import Link from 'components/shared/link'

// Sub-components
import { eventPath, waiverPath } from 'components/events/routes'

const messages = defineMessages({
  agreeToWaiver: {
    defaultMessage:
      'I have read and agree to the <waiverLink>Event waiver</waiverLink>',
    description: 'Checkbox label to mark waiver agreeded',
    id: 'agreeToWaiverMessage.agreeToWaiver',
  },
  registrationClosed: {
    defaultMessage:
      'Registration has closed for this timeframe. Head back to {eventLink} to register for any available timeframes.',
    description:
      'Message to display in a banner indicating registration has closed',
    id: 'showRegistrationClosedNotice.registrationClosed',
  },
})

export const showRegistrationClosedNotice = (event) => {
  displayBanner({
    content: (
      <Fragment>
        {intl.formatMessage(messages.registrationClosed, {
          eventLink: (
            <BannerLink to={eventPath(event.id)}>{event.name}</BannerLink>
          ),
        })}
      </Fragment>
    ),
    variant: 'info',
  })
}

export const agreeToWaiverMessage = (event) => {
  const waiverLink = (...linkText) => (
    <Link className="text-link" to={waiverPath(event.id)}>
      {linkText}
    </Link>
  )

  return intl.formatMessage(messages.agreeToWaiver, {
    waiverLink,
  })
}
