// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { GET_IDEA_BOARD_BY_ID } from 'graphql/queries'
import IdeaBoard from 'components/idea_board/idea_board'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SharedNavBreadcrumb } from 'components/shared/nav_breadcrumb'
import WeQuery from 'components/application/we_query'
import { DocumentTitle } from 'components/shared/document_title'
import { sharedTranslations } from 'components/shared/translations'
import { maybeDisplayUserTour } from 'redux/dispatchers'

const LoadableIdeaBoard = ({ displayNotification, isPreview, match }) => {
  const {
    params: { id },
  } = match
  const isEmpty = (obj) =>
    // https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
    Object.keys(obj).length === 0 && obj.constructor === Object

  return (
    <WeQuery
      error={
        <MissingResourcePage resourceName={sharedTranslations.ideaboard} />
      }
      loader={<CenteredPaddedLoadingIndicator />}
      query={GET_IDEA_BOARD_BY_ID}
      variables={{ ideaBoardID: id }}
    >
      {({ data: { cloudinaryConfig: cloudinary, currentUser, ideaBoard } }) => {
        const props = {
          cloudinary,
          currentUser,
          ...ideaBoard,
        }

        return !isEmpty(ideaBoard) ? (
          <>
            {!isPreview && (
              <SharedNavBreadcrumb currentLinkName={ideaBoard.name} />
            )}
            <DocumentTitle
              title={`${ideaBoard.name} • ${sharedTranslations.ideaboard}`}
            >
              {maybeDisplayUserTour('ideaBoardPage')}

              <IdeaBoard
                displayNotification={displayNotification}
                isPreview={isPreview}
                {...props}
              />
            </DocumentTitle>
          </>
        ) : null
      }}
    </WeQuery>
  )
}

LoadableIdeaBoard.propTypes = {
  displayNotification: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
}

LoadableIdeaBoard.defaultProps = {
  isPreview: false,
}

export const undecorated = LoadableIdeaBoard

export default LoadableIdeaBoard
