import React from 'react'
import { useQuery } from '@apollo/client'

import ActivitiesCardList from 'components/activities/card_list'
import { translations } from 'components/carbon_footprint/utilities/messages'
import { CARBONIMPACAMPAIGNSQUERY } from '../utilities/queries'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { sharedTranslations } from 'components/shared/translations'

export const Campaigns = () => {
  const { data, error, loading } = useQuery(CARBONIMPACAMPAIGNSQUERY)

  if (error) {
    return <MissingResourceSection />
  }
  if (loading) {
    return <SkeletonLoading height={400} />
  }

  let carbonImpactCampaigns = data.carbonImpactCampaigns.nodes

  return carbonImpactCampaigns.length ? (
    <ActivitiesCardList
      activities={carbonImpactCampaigns}
      breadcrumbLink={{
        href: '/carbon_footprint',
        text: sharedTranslations.carbonFootprint,
      }}
      condensed
    />
  ) : (
    <p className="py-5">{translations.activityEmptyState}</p>
  )
}
