// Setup
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { RelativeTime } from 'components/shared/relative_time'
import Stack from 'components/ui/stack'
import { TimeRange } from 'components/ui/time'
import VerticalScrollBox from 'components/ui/vertical_scroll_box'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  heading: {
    defaultMessage: 'about this Idea Board',
    id: 'aboutContent.heading',
  },
})

const AboutContent = ({ className, description, endsAt, startsAt }) => (
  <Stack className={cx('d-flex flex-column', className)} space={3}>
    <Heading className="text-sentence" level={2}>
      {intl.formatMessage(messages.heading)}
    </Heading>
    <div
      className="fs-1 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase"
      data-test="idea-board-date-range"
    >
      <TimeRange endsAt={endsAt} startsAt={startsAt} />
      {endsAt && (
        <>
          <BulletSpacer />,
          <RelativeTime date={endsAt} showPrefix />
        </>
      )}
    </div>
    <VerticalScrollBox
      ariaLabel={sharedTranslations.details}
      as={WysiwygContent}
      className="mb-auto"
      data-test="about"
    >
      {description}
    </VerticalScrollBox>
  </Stack>
)

AboutContent.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  endsAt: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
}

AboutContent.defaultProps = {
  className: null,
}

export default AboutContent
