import gql from 'graphql-tag'

import { FacepileSizes } from 'components/application/avatar'

export const ACTIVITY_CARD_FRAGMENT = gql`
  fragment ActivityAttributes on ActivityInterface {
    actionUrl
    endsAt
    id
    imageUrl
    name
    participants(limit: ${FacepileSizes.small}) {
      avatarUrl
      id
    }
    participantsCount
    startsAt
    type
  }
`
