// Vendor
import React from 'react'

// WeSpire
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import CenteredContent from 'components/application/centered_content'
import Stack from 'components/ui/stack'
import { OurImpactStats } from 'components/our_impact/stats'
import { sharedTranslations } from 'components/shared/translations'
import { ImpactByProgram } from 'components/our_impact/impact_by_program'
import { Heading } from 'components/ui/heading'
import { FeaturedActivities } from 'components/our_impact/featured_activities'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { DocumentTitle } from 'components/shared/document_title'

export const OurImpact = () => (
  <GraphQLFeatureFlag featureName="ourImpact">
    <DocumentTitle title={sharedTranslations.ourImpact}>
      {maybeDisplayUserTour('ourImpactPage')}

      <div className="px-3">
        <CenteredContent className="mt-5">
          <Heading className="sr-only" level={1}>
            {sharedTranslations.ourImpact}
          </Heading>
          <Stack space={5}>
            <OurImpactStats />
            <ImpactByProgram />
            <FeaturedActivities />
          </Stack>
        </CenteredContent>
      </div>
    </DocumentTitle>
  </GraphQLFeatureFlag>
)
