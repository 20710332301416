import React from 'react'
import PropTypes from 'prop-types'
import AutoComplete from 'src/common/AutoComplete'
import Input from 'src/common/Input'

export default function CharitySearchForm({
  intl,
  values,
  handleChange,
  handleBlur,
  enabledCountries,
}) {
  const countryOptions = enabledCountries.map((country) => ({
    label: country.name,
    value: country.alpha3,
  }))
  const inputClass =
    'w-full bg-white border border-practical-gray rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-smart-blue focus:border-transparent'
  return (
    <>
      <Input
        placeholder={intl.formatMessage({
          id: 'charities.helperTextForName',
        })}
        name="charityName"
        onBlur={handleBlur}
        onChange={handleChange}
        type="search"
        value={values.charityName}
        className={inputClass}
      />
      <div>
        <AutoComplete
          placeholder={intl.formatMessage({
            id: 'charities.helperTextForCountry',
          })}
          value={values.country.map((alpha3) => {
            const country = enabledCountries.find((c) => c.alpha3 === alpha3)
            return { label: country.name, value: country.alpha3 }
          })}
          onChange={(selectedCountries) => {
            const countryValues = selectedCountries.map(
              (country) => country.value
            )
            handleChange({
              target: { name: 'country', value: countryValues },
            })
          }}
          options={countryOptions}
          name="country"
        />
      </div>
      <Input
        placeholder={intl.formatMessage({
          id: 'charities.helperTextForCity',
        })}
        name="city"
        onBlur={handleBlur}
        onChange={handleChange}
        type="search"
        className={inputClass}
        value={values.city}
      />
      <Input
        placeholder={intl.formatMessage({
          id: 'charities.helperTextForEin',
        })}
        name="eins"
        onBlur={handleBlur}
        onChange={handleChange}
        className={inputClass}
        type="search"
        value={values.eins}
      />
    </>
  )
}

CharitySearchForm.propTypes = {
  enabledCountries: PropTypes.array.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
}
