// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { withFormsy } from 'formsy-react'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import { FormHelperText, FormLabel } from '@material-ui/core'
import cx from 'classnames'
import 'styles/vendor/react-draft-wysiwyg.css'
import { defineMessages } from 'react-intl'

// WeSpire
import Stack from 'components/ui/stack'
import TextFieldValidations from 'components/form/text_field_validations'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const setEditorStateFromValue = (value) => {
  if (value) {
    return EditorState.createWithContent(stateFromHTML(value))
  } else {
    return EditorState.createEmpty()
  }
}

const messages = defineMessages({
  openLink: {
    defaultMessage:
      'Open WeSpire link in a new tab (external links will always launch in a new tab)',
    description: 'Label text for link checkbox',
    id: 'wysiwygEditor.openLink',
  },
})

/**
 * This functionality allows us to change the wording on the editor toolbar
 * specifically for the link dropdown.
 *
 * Inspired by: https://stackoverflow.com/a/55430875/2811126
 */

const editorLabels = {
  'components.controls.link.linkTargetOption': formatMessage(messages.openLink),
}

/**
 * WYSIWYG rich text editor wrapped with Formsy.
 *
 * Intended to be used in Formsy-backed forms, where this input's value can be
 * easily extracted on submission via onValidSubmit. Not all Formsy validations
 * are supported though, since this input's value is a string containing HTML,
 * not plain text. Make sure to sanitize the HTML generated by this input on the
 * server, and to safely render it as HTML elsewhere using <WysiwygContent>.
 *
 * Full documentation: https://jpuri.github.io/react-draft-wysiwyg/
 * Inspired by: https://github.com/michalpierzchlewicz/draftjs-formsy-input/
 */
const WysiwygEditorBase = ({
  className,
  'data-test': dataTest,
  errorMessage,
  helperText,
  isFormSubmitted,
  isPristine,
  isRequired,
  isValid,
  label,
  name,
  readOnly,
  setUserText,
  setValue,
  showRequired,
  value,
  ...other
}) => {
  const [editorState, setEditorState] = useState(setEditorStateFromValue(value))
  const [isFocused, setIsFocused] = useState(false)

  const handleOnEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)

    const editorHasText = newEditorState.getCurrentContent().hasText()

    setUserText && setMarkdown()

    if (editorHasText) {
      setValue(stateToHTML(newEditorState.getCurrentContent()))
    } else if (!isPristine) {
      setValue(undefined)
    }
  }

  const setMarkdown = () => {
    setUserText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const textValidationsProps = {
    errorMessage,
    isFormSubmitted,
    isPristine,
    isRequired,
    isValid,
    name,
    setValue,
    showRequired,
    textFieldProps: {
      helperText,
      label,
    },
    value,
    ...other,
  }

  return (
    <TextFieldValidations {...textValidationsProps}>
      {(error, computedHelperText, computedLabel) => (
        <Stack className={className} space={2}>
          <FormLabel
            className={cx(
              'd-block fs-2 fw-semi-bold px-3 text-black-1 transition-cubic-bezier',
              { 'text-orange': isFocused && !error },
              { 'text-red': error }
            )}
            id={`${name}-label`}
          >
            {computedLabel}
          </FormLabel>
          <Editor
            {...other}
            editorClassName={cx('fs-2 lh-md px-2', other.editorClassName)}
            editorState={editorState}
            localization={{ locale: 'en', translations: editorLabels }} // custom editor labels
            name={name}
            onBlur={() => setIsFocused(false)}
            onEditorStateChange={handleOnEditorStateChange}
            onFocus={() => setIsFocused(true)}
            readOnly={readOnly}
            toolbar={{
              blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2'],
              },
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
              link: { defaultTargetOption: '_blank' }, // default link checkbox to checked
              list: {
                options: ['unordered', 'ordered'],
              },
              options: ['blockType', 'inline', 'link', 'list'],
            }}
            toolbarClassName={cx('border-0', other.toolbarClassName)}
            toolbarHidden={readOnly}
            webDriverTestID={dataTest}
            wrapperClassName={cx(
              'border p-2 rounded-1 transition-cubic-bezier',
              { 'bg-black-6': readOnly },
              { 'bg-white': !readOnly },
              other.wrapperClassName,
              { 'border-orange': isFocused && !error },
              { 'border-red': error }
            )}
          />
          <FormHelperText className={cx('px-3', { 'text-red': error })}>
            {computedHelperText}
          </FormHelperText>
        </Stack>
      )}
    </TextFieldValidations>
  )
}

WysiwygEditorBase.propTypes = {
  className: PropTypes.string,
  'data-test': PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  isFormSubmitted: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  setUserText: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  showRequired: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

WysiwygEditorBase.defaultProps = {
  className: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'wysiwyg',
  errorMessage: null,
  helperText: null,
  readOnly: false,
  required: false,
  setUserText: null,
  value: '',
}

export const WysiwygEditor = withFormsy(WysiwygEditorBase)
