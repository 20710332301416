// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { editContentModulePath } from './routes'
import { FormDetail, FormDetails } from 'components/form/form_details'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'

/**
 * Renders one Content Module and provides the ability to configure
 * related settings by navigating to a different form. Designed to be rendered
 * as a member of a list of modules. See <ContentModuleList>.
 */
const ContentModule = ({ channel, id, name, skipAssociatedChannel }) => (
  <li
    className="[ d-flex align-items-center ] [ px-2 ]"
    data-test="content-module"
  >
    <Stack space={1}>
      <p className="fs-4">{name}</p>
      {skipAssociatedChannel ? null : (
        <FormDetails>
          <FormDetail label="Associated Channel">
            {channel ? channel.name : ''}
          </FormDetail>
        </FormDetails>
      )}
    </Stack>
    <LinkButton
      className="ml-auto fs-3"
      to={editContentModulePath({ id })}
      variant="text"
    >
      Edit
    </LinkButton>
  </li>
)

ContentModule.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  skipAssociatedChannel: PropTypes.bool.isRequired,
}

ContentModule.defaultProps = {
  channel: null,
}

export { ContentModule }
