import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

import { intl } from 'utilities/localization'

import { donationReceiptMessages as messages } from './utilities/messages'

const amountContent = (isRecurring, amount, currency, charity) => {
  return isRecurring ? (
    <p className="pt-2 fs-2 text-left">
      {intl.formatMessage(messages.yourMonthlyDonationOf)}{' '}
      <span className="fw-bold">
        {' '}
        <FormattedNumber currency={currency} style="currency" value={amount} />
      </span>{' '}
      {intl.formatMessage(messages.benefits)}{' '}
      <span className="fw-bold">{charity}</span>
    </p>
  ) : (
    <p className="pt-2 fs-2 text-left">
      {intl.formatMessage(messages.yourDonationOf)}{' '}
      <span className="fw-bold">
        {' '}
        <FormattedNumber currency={currency} style="currency" value={amount} />
      </span>{' '}
      {intl.formatMessage(messages.benefits)}{' '}
      <span className="fw-bold">{charity}</span>
    </p>
  )
}

const DonationReceipt = (props) => {
  const { charity, amount, currency, isRecurring } = props

  const content = amountContent(isRecurring, amount, currency, charity)

  return (
    <div>
      <h2>{intl.formatMessage(messages.yourDonationReceipt)}</h2>
      {content}
      <p className="pt-2 fs-2 text-left">
        {intl.formatMessage(messages.noGoodsOrService)}
      </p>
      <p className="pt-2 fs-2 text-left">
        {intl.formatMessage(messages.byMakingThisDonation)}
      </p>
      <p className="pt-2 fs-2 text-left">
        {intl.formatMessage(messages.pleasePrint)}
      </p>
    </div>
  )
}

DonationReceipt.propTypes = {
  amount: PropTypes.number.isRequired,
  charity: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  isRecurring: PropTypes.bool.isRequired,
}

export default DonationReceipt
