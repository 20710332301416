import React, { useContext } from 'react'
import { Redirect } from 'react-router'
import SessionContext from 'src/contexts/SessionContext'

const Landing = () => {
  const { brand, signedIn } = useContext(SessionContext)
  if (signedIn) {
    return <Redirect to="/take_action" />
  }
  return !brand ? (
    <div>Loading...</div>
  ) : (
    <Redirect to={`/app/landing/${brand?.authScheme}`} />
  )
}

export default Landing
