// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { editChapterRoute, indexLeadershipRoles } from './routes'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { ListOrdering } from 'components/shared/list_ordering/list_ordering'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  leadershipRoles: {
    defaultMessage: 'Leadership Roles',
    description: 'Button to add Leaderships',
    id: 'chapterRow.leadershipRoles',
  },
})

const { formatMessage } = intl

const ChapterRow = ({
  chapterId,
  chapterSlug,
  groupSlug,
  name,
  onUpdatePositionClick,
}) => {
  return (
    <li className="list-none lh-md" data-test="chapter-row">
      <div className="row align-items-center">
        <div className="d-inline-flex col-2 justify-content-center">
          <ListOrdering
            onUpdatePositionClick={onUpdatePositionClick}
            recordId={chapterId}
            recordName={name}
          />
        </div>
        <span
          className={'[ fs-3 fw-semi-bold ] [ col-6 ]'}
          data-test="chapter-name"
        >
          {name}
        </span>
        <span className="[ col-2 ] [ fs-3 ]" data-test="chapter-leaders">
          <LinkButton
            className="fs-3"
            data-test="chapter-index-roles"
            to={indexLeadershipRoles({
              chapterSlug,
              groupSlug,
            })}
            variant="text"
          >
            <Icon
              className="fs-6"
              iconName="account"
              title={formatMessage(messages.leadershipRoles)}
            />
          </LinkButton>
        </span>
        <div className="d-inline-flex col-1 justify-content-center">
          <LinkButton
            className="fs-3"
            data-test="chapter-edit"
            to={editChapterRoute({
              chapterSlug,
              groupSlug,
            })}
            variant="text"
          >
            {sharedTranslations.edit}
          </LinkButton>
        </div>
      </div>
    </li>
  )
}

ChapterRow.propTypes = {
  chapterId: PropTypes.string.isRequired,
  chapterSlug: PropTypes.string.isRequired,
  groupSlug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
}

export { ChapterRow }
