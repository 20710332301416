// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const messages = defineMessages({
  manage: {
    defaultMessage: 'Manage',
    id: 'groupManagementGroupRow.manage',
  },
  members: {
    defaultMessage: '{members, number} total members',
    id: 'groupManagementGroupRow.members',
  },
})

const GroupRow = ({ group }) => (
  <li className="[ d-flex align-items-center ] [ px-2 ]" data-test="group-row">
    <Stack className="d-flex w-100" space={0}>
      <p className="fs-4 fw-semi-bold">{group.labelName}</p>
      {/*todo: bring back members*/}
      {/*<p className="ml-auto fs-3 text-black-2">*/}
      {/*  {formatMessage(messages.members, { members: group.membersCount })}*/}
      {/*</p>*/}
    </Stack>
    <LinkButton
      className="ml-4 fs-3"
      to={`/management_panel/groups_management/${group.id}`}
      variant="text"
    >
      {formatMessage(messages.manage)}
    </LinkButton>
  </li>
)

GroupRow.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    membersCount: PropTypes.number.isRequired,
  }).isRequired,
}

export { GroupRow }
