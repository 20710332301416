// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import LinkButton from 'components/ui/link_button'
import { ListOrdering } from 'components/shared/list_ordering/list_ordering'

// Sub-Components
import { editEventCategoryPath } from './routes'

/**
 * Renders one Event Category and provides the ability to edit it by navigating
 * to a different form or to change its order in the greater list of Event
 * Categories. Designed to be rendered as a member of a list of categories.
 * See <EventCategoryList>.
 */
const EventCategory = ({ categoryId, categoryName, onUpdatePositionClick }) => {
  return (
    <li
      className="[ d-flex align-items-center ] [ px-2 ]"
      data-test="event-category"
    >
      <div>
        <ListOrdering
          onUpdatePositionClick={onUpdatePositionClick}
          recordId={categoryId}
          recordName={categoryName}
        />
        <span className="fs-4 ml-2">{categoryName}</span>
      </div>
      <LinkButton
        className="ml-auto fs-3"
        to={editEventCategoryPath({ categoryId })}
        variant="text"
      >
        Edit
      </LinkButton>
    </li>
  )
}

EventCategory.propTypes = {
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
}

export { EventCategory }
