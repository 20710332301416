// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const LabelSizes = ['small', 'medium', 'large']

/**
 * Label that corresponds to `<lavel``. Adheres design system scale of
 * allowed style variations.
 */
const Label = ({ children, className, size, uppercase, ...other }) => {
  return (
    <label
      {...other}
      className={cx(
        `label-react label-react--${size}`,
        { 'text-uppercase': uppercase },
        className
      )}
    >
      {children}
    </label>
  )
}

Label.propTypes = {
  /** render label content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** renders a small, medium or large label with default system style  */
  size: PropTypes.oneOf(LabelSizes),
  /** will determain if entire text should be uppercased */
  uppercase: PropTypes.bool,
}

Label.defaultProps = {
  className: null,
  size: 'medium',
  uppercase: false,
}

export { Label, LabelSizes }
