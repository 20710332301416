import React from 'react'
import Breadcrumbs from 'src/common/Breadcrumbs'

const HeaderBreadcrumb = ({ className, crumbs }) => (
  <div
    className={`flex flex-row justify-start sm:px-6 px-4 py-2 shadow-[0px_1px_2px_#C4D1DB] items-center text-base bg-white text-black-2 ${className}`}
  >
    <Breadcrumbs />
  </div>
)

export default HeaderBreadcrumb
