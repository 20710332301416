// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Sub-Components
import CommentsList from './comments_list'
import CommentsSummary from './comments_summary'

const CommentsDisplay = ({
  comments,
  commentsListProps,
  commentsSummaryProps,
  displayCommentSummary,
  onSummaryClick,
  scrollContainerId,
  shouldAlwaysRender,
}) => {
  if (shouldAlwaysRender || comments.length > 0) {
    return (
      <>
        {displayCommentSummary && (
          <CommentsSummary
            {...commentsSummaryProps}
            comments={comments}
            onSummaryClick={onSummaryClick}
          />
        )}
        {comments.length > 0 && (
          <CommentsList
            {...commentsListProps}
            comments={comments}
            scrollContainerId={scrollContainerId}
          />
        )}
      </>
    )
  } else {
    return null
  }
}

CommentsDisplay.propTypes = {
  comments: PropTypes.array.isRequired,
  commentsListProps: PropTypes.object,
  commentsSummaryProps: PropTypes.object,
  displayCommentSummary: PropTypes.bool,
  onSummaryClick: PropTypes.func.isRequired,
  scrollContainerId: PropTypes.string.isRequired,
  shouldAlwaysRender: PropTypes.bool,
}

CommentsDisplay.defaultProps = {
  commentsListProps: {
    className: null,
  },
  commentsSummaryProps: {
    className: null,
  },
  displayCommentSummary: true,
  shouldAlwaysRender: false,
}

export default CommentsDisplay
