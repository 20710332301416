// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { LIST_TYPES } from 'components/setup/channel_manager/constants'
import Stack from 'components/ui/stack'

const SortableCollectionWrapper = ({
  children,
  isEmptyState,
  showHighlight,
  listId,
  ...other
}) => {
  const title = {
    [LIST_TYPES.unpublished]: 'hidden Channels',
    [LIST_TYPES.published]: 'live Channels',
  }[listId]

  return (
    <Stack
      {...other}
      className={cx({ 'bg-black-5': showHighlight }, other.className)}
      space={3}
    >
      <h2 className="fs-3 fw-regular mt-0 text-black-3 text-uppercase">
        {title}
      </h2>
      {isEmptyState ? (
        <p className="py-3 text-center w-100">There are no {title}.</p>
      ) : (
        <div>{children}</div>
      )}
    </Stack>
  )
}

SortableCollectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isEmptyState: PropTypes.bool,
  listId: PropTypes.string.isRequired,
  showHighlight: PropTypes.bool,
}

SortableCollectionWrapper.defaultProps = {
  isEmptyState: false,
  showHighlight: false,
}

export { SortableCollectionWrapper }
