// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

// WeSpire
import LinkButton from 'components/ui/link_button'

const SideNavSecondaryItem = ({ isActive, link, onClick }) => (
  <ListItem component={LinkButton} onClick={onClick} to={link.href}>
    <ListItemText
      primary={
        <span
          className={cx('fs-1 text-capitalize text-black-2', {
            'text-blue': isActive,
          })}
        >
          {link.id}
        </span>
      }
    />
  </ListItem>
)

SideNavSecondaryItem.propTypes = {
  isActive: PropTypes.bool,
  link: PropTypes.shape({
    'data-method': PropTypes.string,
    href: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  }).isRequired,
  onClick: PropTypes.func,
}

SideNavSecondaryItem.defaultProps = {
  isActive: false,
  onClick: () => {},
}

export default SideNavSecondaryItem
