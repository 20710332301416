// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import Link from 'components/shared/link'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import WeQuery from 'components/application/we_query'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { intl } from 'utilities/localization'
import { eventPath } from '/components/events/routes'
import { maybeDisplayUserTour } from 'redux/dispatchers'

const eventWaiverQuery = gql`
  query eventWaiverQuery($id: ID!) {
    participationEvent(id: $id) {
      id
      name
      requireWaiver
      waiverText
    }
  }
`

const messages = defineMessages({
  description: {
    defaultMessage:
      'You are required to agree to the terms of this waiver in order to register for {eventLink}.',
    id: 'eventWaiver.description',
  },
  missingResource: {
    defaultMessage: 'no Event waiver',
    id: 'eventWaiver.missingResource',
  },
  missingResourceDescription: {
    defaultMessage: 'No waiver has been specified for {eventLink}.',
    id: 'eventWaiver.missingResourceDescription',
  },
  resource: {
    defaultMessage: 'Event waiver',
    id: 'eventWaiver.resource',
  },
})

/**
 * Displays the Waiver for an Event in an HTML-safe container, since the Waiver
 * is expected to have HTML. If the Event does not require a waiver, then we
 * will not display one and inform the User. We will also inform the User if we
 * are unable to find the Event represened by id.
 */
const EventWaiver = ({
  history: { goBack },
  match: {
    params: { id },
  },
}) => (
  <WeQuery
    error={
      <DocumentTitle
        title={intl.formatMessage(sharedMessages.resourceNotFound, {
          resource: intl.formatMessage(messages.resource),
        })}
      >
        <MissingResourcePage
          resourceName={intl.formatMessage(messages.resource)}
        />
      </DocumentTitle>
    }
    loader={<CenteredPaddedLoadingIndicator />}
    query={eventWaiverQuery}
    variables={{ id }}
  >
    {({
      data: {
        participationEvent: { name, requireWaiver, waiverText },
      },
    }) => {
      const eventLink = <Link to={eventPath(id)}>{name}</Link>

      if (!requireWaiver) {
        return (
          <DocumentTitle
            title={intl.formatMessage(sharedMessages.resourceNotFound, {
              resource: intl.formatMessage(messages.resource),
            })}
          >
            <MissingResourcePage
              data-test="event-waiver-zero-state"
              title={intl.formatMessage(messages.missingResource)}
            >
              <p>
                {intl.formatMessage(messages.missingResourceDescription, {
                  eventLink: eventLink,
                })}
              </p>
            </MissingResourcePage>
          </DocumentTitle>
        )
      }

      return (
        <DocumentTitle title={intl.formatMessage(messages.resource)}>

          <CenteredContent className="mxw-measure">
            <div className="[ d-flex align-items-center justify-content-between ] [ my-0 mt-5 px-3 ]">
              <Heading level={1}>
                {intl.formatMessage(messages.resource)}
              </Heading>
              <Button onClick={() => goBack()}>
                {'< ' + sharedTranslations.back}
              </Button>
            </div>
            <p className="my-3 px-3">
              {intl.formatMessage(messages.description, {
                eventLink: eventLink,
              })}
            </p>
            <WysiwygContent
              className="mt-4 ml-3 p-4 | p-md-5"
              component={Paper}
              data-test="event-waiver"
            >
              {waiverText}
            </WysiwygContent>
          </CenteredContent>
        </DocumentTitle>
      )
    }}
  </WeQuery>
)

EventWaiver.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
}

export { EventWaiver }
