// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { parseDate } from 'utilities/date_utils'
import Stack from 'components/ui/stack'


// Vendor
import DownloadIcon from '@mui/icons-material/Download'
import PaymentIcon from '@mui/icons-material/Payment'

const { formatMessage, formatDate } = intl

const messages = defineMessages({
  editFunds: {
    defaultMessage: 'Edit Funds',
    id: 'UserRow.editFunds',
  },
})

export const InvoiceRow = ({
  id,
  status,
  invoicePdf,
  hostedInvoiceUrl,
  ...props
}) => {
  return (
    <TableRow
      data-test="invoice-row"
      hover
      key={id}
      tabIndex={-1}
      {...props}
    >
      <TableCell align="left">
        {id}
      </TableCell>
      <TableCell align="right">
        {status}
      </TableCell>
      <TableCell align="right">
      {  hostedInvoiceUrl &&
          <Link to={hostedInvoiceUrl}>
            {<PaymentIcon className="text-md text-blue" />}
          </Link>
      }
      </TableCell>
      <TableCell align="right">
        { invoicePdf &&
          <Link to={invoicePdf}>
            {<DownloadIcon className="text-md text-blue" />}
          </Link>
  }
      </TableCell>
    </TableRow>
  )
}

InvoiceRow.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  invoicePdf: PropTypes.string,
  hostedInvoiceUrl: PropTypes.string,
}
