// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import Paper from '@material-ui/core/Paper'

// WeSpire
import MgmtPanelPage from 'setup/application/page'
import { sharedTranslations } from 'components/shared/translations'

/**
 * Extends <MgmtPanelPage> functionality for a resource that needs both a
 * #new and #edit page in React, where each page renders a form as children.
 * Includes form banner and styled form wrapper.
 */
const MgmtPanelFormPage = ({
  children,
  isEdit,
  paperProps,
  resourceName,
  space,
  ...other
}) => (
  <MgmtPanelPage
    {...other}
    space={space}
    title={`${
      isEdit ? sharedTranslations.edit : sharedTranslations.new
    } ${resourceName}`}
  >
    {({ displayNotification }) => (
      <Fragment>
        <Paper
          {...paperProps}
          className={cx(`p-${space} m-${space}`, paperProps.className)}
        >
          {children({
            displayNotification,
          })}
        </Paper>
      </Fragment>
    )}
  </MgmtPanelPage>
)

MgmtPanelFormPage.propTypes = {
  /** pass in a form using render props */
  children: PropTypes.func.isRequired,
  /** boolean prop to render different UI for #edit vs #new form states */
  isEdit: PropTypes.bool,
  /** props passed to <Paper> component */
  paperProps: PropTypes.object,
  /** text appended in <ContentHeader> after 'New' or 'Edit' */
  resourceName: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
  /** specify spacing surrounding the form wrapper and its contents */
  space: PropTypes.number,
}

MgmtPanelFormPage.defaultProps = {
  isEdit: false,
  paperProps: {},
  showBackButton: false,
  space: 5,
}

export default MgmtPanelFormPage
