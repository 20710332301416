// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import { List } from '@material-ui/core'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import Divider from 'components/ui/divider'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import Stack from 'components/ui/stack'

import { GroupResource } from './group_resource'

const { formatMessage } = intl
const messages = defineMessages({
  dateAddedHeaderTitle: {
    defaultMessage: 'Date Added',
    id: 'groupResources.dateAddedHeaderTitle',
  },
  emptyMessage: {
    defaultMessage: 'No Files or Resources added to this group yet.',
    id: 'groupResources.emptyMessage',
  },
  fileNameHeaderTitle: {
    defaultMessage: 'File Name',
    id: 'groupResources.fileNameHeaderTitle',
  },
  groupResourcesHeading: {
    defaultMessage: 'Resources & Files ({count,number})',
    id: 'groupResources.groupResourcesHeading',
  },
  resources: {
    defaultMessage: 'Resources',
    id: 'groupResources.resources',
  },
})

const RESOURCES_QUERY = gql`
  query group($id: ID!, $first: Int, $after: String) {
    group(id: $id) {
      id
      resources(first: $first, after: $after) {
        nodes {
          id
          description
          title
          resourceType
          resourceUrl
          createdAt
          createdBy {
            id
            name
          }
          updatedAt
          updatedBy {
            id
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`
const PAGE_SIZE = 20
export const GroupResources = ({ groupId }) => {
  const { data, error, fetchMore, loading, variables } = useQuery(
    RESOURCES_QUERY,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_SIZE,
        id: groupId,
      },
    }
  )

  if (error) {
    return (
      <MissingResourceSection
        errorDetails={formatMessage(messages.resources)}
      />
    )
  }

  if (loading || !data) {
    return <CenteredPaddedLoadingIndicator />
  }

  const group = data?.group

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.group.resources.nodes = [
          ...previousResult?.group?.resources?.nodes,
          ...fetchMoreResult?.group?.resources?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: group?.resources?.pageInfo?.endCursor,
        first: PAGE_SIZE,
      },
    })
  }
  const isLoadingMore = false
  return (
    <>
      <Heading
        className="fw-semi-bold px-3 py-2"
        data-test="group-resources-title"
        level={2}
      >
        {formatMessage(messages.groupResourcesHeading, {
          count: group.resources.totalCount,
        })}
      </Heading>
      <Stack as={Paper}>
        {group.resources.nodes.length ? (
          <List className="p-0">
            {group.resources.nodes.map((resource) => (
              <React.Fragment key={resource.id}>
                <GroupResource key={resource.id} resource={resource} />
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <p
            className="p-3 fs-3 fw-semi-bold"
            data-test="group-resources-empty-label"
          >
            {formatMessage(messages.emptyMessage)}
          </p>
        )}
      </Stack>
      {group.resources.pageInfo.hasNextPage && (
        <div className="[ d-flex ] [ px-3 ]">
          <LoadMoreButton
            loading={isLoadingMore}
            newsfeedItemsCount={group.resources.nodes.length}
            onClick={onLoadMore}
            pageSize={PAGE_SIZE}
            totalCount={group.resources.totalCount}
            type={messages.resources}
          />
        </div>
      )}
    </>
  )
}

GroupResources.propTypes = {
  groupId: PropTypes.string.isRequired,
}
