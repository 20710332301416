// Vendor
import { client } from 'utilities/we_apollo'

// Wespire
import { CHANNEL_BREADCRUMB_QUERY, GROUP_BREADCRUMB_QUERY } from './queries'

export const fetch = async (
  fromChannelId,
  fromGroupId,
  setFetchStatus,
  setGroupLink
) => {
  setFetchStatus('loading')
  try {
    let QUERY, variables
    if (fromGroupId) {
      QUERY = GROUP_BREADCRUMB_QUERY
      variables = { id: +fromGroupId }
    }
    if (fromChannelId) {
      QUERY = CHANNEL_BREADCRUMB_QUERY
      variables = { id: +fromChannelId }
    }

    const { data } = await client.query({
      query: QUERY,
      variables,
    })

    if (fromGroupId) {
      const {
        group: { name },
      } = data
      setGroupLink([
        {
          href: `/groups/${fromGroupId}`,
          text: name,
        },
      ])
    }

    if (fromChannelId && data.brandConfig.channelNavbarEnabled) {
      const {
        channel: { name },
      } = data
      setGroupLink([
        {
          href: `/take_action/${fromChannelId}`,
          text: name,
        },
      ])
    }

    setFetchStatus('success')
  } catch {
    setFetchStatus('error')
  }
}
