import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

const VOLUNTEERING_QUERY = gql`
  query volunteeringQuery {
    recurringDonationsEnabled
    brandConfig {
      id
      isPointsDashboardEnabled
      d4dConfiguration {
        grantDonationsEnabled
        d4dGrantAmount
        d4dMaxDonationAmountPerYear
        d4dProgramType
        d4dHoursUntilDonationEligible
      }
    }
    givingEnabled
    brand {
      logoUrl
    }
    currentUser {
      id
      paidVolunteerHoursBalance
      volunteeredIndividualHoursAllTime
      volunteeredOrganizationsAllTime
      volunteeredOrganizationsThisYear
      volunteeredIndividualHoursThisYear
      givingaUser {
        availableDonationFunds
        grantDollarsEarnedThisYear
        grantDollarsDonatedThisYear
        remainingFundsThisYear
      }
      groupedVolunteerLogs {
        allVolunteerLogs {
          scheduled {
            ein
            grantEligible
            organizationName
            totalHours
            logs {
              date
              eventName
              eventUrl
              hours
              id
              isComplete
              isForPlatformEvent
            }
          }
          completed {
            ein
            grantEligible
            organizationName
            totalHours
            logs {
              date
              eventName
              eventUrl
              hours
              id
              isComplete
              isForPlatformEvent
            }
          }
        }
        volunteerLogsThisYear {
          scheduled {
            ein
            grantEligible
            organizationName
            totalHours
            logs {
              date
              eventName
              eventUrl
              hours
              id
              isComplete
              isForPlatformEvent
            }
          }
          completed {
            ein
            grantEligible
            organizationName
            totalHours
            logs {
              date
              eventName
              eventUrl
              hours
              id
              isComplete
              isForPlatformEvent
            }
          }
        }
      }
    }
  }
`

export default function useVolunteeringQuery() {
  const result = useQuery(VOLUNTEERING_QUERY)
  const [loading, setLoading] = useState(true)
  const [sortedVolunteerLogsAllTime, setSortedVolunteerLogsAllTime] = useState(
    []
  )
  const [sortedVolunteerLogsThisYear, setSortedVolunteerLogsThisYear] =
    useState([])
  useEffect(() => {
    if (result?.data?.currentUser?.groupedVolunteerLogs) {
      const { allVolunteerLogs, volunteerLogsThisYear } =
        result?.data?.currentUser?.groupedVolunteerLogs

      setSortedVolunteerLogsAllTime(allVolunteerLogs)
      setSortedVolunteerLogsThisYear(volunteerLogsThisYear)
      setLoading(false)
    }
  }, [result?.data?.currentUser?.groupedVolunteerLogs])

  return {
    loading,
    data: result.data,
    error: result.error,
    sortedVolunteerLogsAllTime,
    sortedVolunteerLogsThisYear,
  }
}
