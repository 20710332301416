/**
 * Return the full name of the user, first and last name separated by a space
 *
 * @param {Object} user
 * @return {String}
 */
export const fullName = (user) =>
  user ? `${user.firstName} ${user.lastName}` : 'User Name'

/**
 * Return a string with the first name and the first letter of the last name
 *
 * @param {Object} user
 * @return {String}
 */
export const abbreviatedName = (user) =>
  user ? `${user.firstName} ${user.lastName.charAt(0)}.` : 'User N.'
