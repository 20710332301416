import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { FormattedNumber } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'

// WeSpire
import Divider from 'src/common/Divider'
import GivingActivityName from 'src/pages/DonationSuccessPage/GivingActivityName'
import InternalLink from 'src/common/InternalLink'
import { numericDate } from 'utilities/date_formatter'

const Body = ({ donationMatchingEnabled, name, transaction }) => {
  const activityId = transaction.activityId
  const intl = useIntl()

  return (
    <>
      <div>
        <span className="label-all-caps text-black-3">
          {`${intl.formatMessage({
            id: 'shared.date',
          })}: `}
        </span>
        <time className="font-['Nunito_Sans'] not-italic text-black-2 font-bold text-base">
          {numericDate(transaction.donationDate)}
        </time>
      </div>
      <div>
        <span className="label-all-caps text-black-3">
          {`${intl.formatMessage({
            id: 'donationSuccess.donor',
          })}: `}
        </span>
        <span className="font-bold text-base">{name}</span>
      </div>
      <div>
        <span className="label-all-caps text-black-3">{`${intl.formatMessage({
          id: 'shared.charity',
        })}: `}</span>
        <span className="font-bold text-base">{transaction.charityName}</span>
      </div>
      <div>
        <span className="label-all-caps text-black-3">{`${intl.formatMessage({
          id: 'donationSuccess.amount',
        })}: `}</span>
        <span className="font-bold text-base">
          <FormattedNumber
            currency={transaction.currency || 'USD'}
            style="currency"
            value={transaction.donationAmount}
          />
        </span>
      </div>
      {donationMatchingEnabled && (
        <div>
          <span className="label-all-caps text-black-3">
            {`${intl.formatMessage({
              id: 'donationSuccess.donationMatchRequest',
            })}: `}
          </span>
          <span className="font-bold text-base">
            {`${intl.formatMessage({
              id: transaction.matchedTransaction ? 'shared.yes' : 'shared.no',
            })}`}
          </span>
        </div>
      )}
      {activityId && <GivingActivityName activityId={activityId} />}
      <p className="font-base mt-7">
        <FormattedMessage
          id="donationSuccess.pleaseNoteText"
          defaultMessage="All donation transactions will appear on your bank/credit card statements as GIVINGA FOUNDATION, our 501(c)(3) partner registered in the United States. Your donation will be processed and will be included in a weekly payment to the charity you’ve selected. Most charities receive the payment within 10 business days, either electronically or by check. {link} has the up-to-date status on the payment status."
          values={{
            link: (
              <InternalLink href="/users/me/donation_history">
                Your Donation History
              </InternalLink>
            ),
          }}
        />
      </p>
      {transaction.matchedTransaction && (
        <>
          <h2 className="font-medium mt-7 uppercase text-sm">
            <FormattedMessage id="donationSuccess.matchRequest" />
          </h2>
          <p>
            <FormattedMessage id="donationSuccess.matchingText" />
          </p>
        </>
      )}
      <Divider className="my-7 text-grayscale-1" />
      <h2 className="font-medium mb-7 uppercase text-sm">
        <FormattedMessage id="donationSuccess.donationReceipt" />
      </h2>
      <span className="font-bold text-base text-black-1">
        <FormattedMessage
          id="donationReceipt.yourDonationOfDonationAmount"
          defaultMessage="Your donation of {donationAmount} benefits {charityName}."
          values={{
            donationAmount: (
              <CurrencyFormat
                className="body-medium-regular"
                displayType={'text'}
                value={transaction.donationAmount}
                prefix={'$'}
                fixedDecimalScale
                decimalScale={2}
              />
            ),
            charityName: transaction.charityName,
          }}
        />
      </span>
      <p className="font-base">
        <FormattedMessage id="donationReceipt.noGoodsOrService" />
      </p>
      <p className="font-base mt-7">
        <FormattedMessage id="donationTerms.terms" />
      </p>
      <p className="font-base mt-7">
        <FormattedMessage id="donationReceipt.pleasePrint" />
      </p>
    </>
  )
}

export default Body
