import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

const BackButton = ({ handleBackQuestion }) => (
  <Button
    sx={{ color: '#767373' }}
    data-test="back-button-test"
    onClick={handleBackQuestion}
    startIcon={<ArrowBackIosNewIcon />}
    variant="text"
  >
    Back
  </Button>
)

BackButton.propTypes = {
  handleBackQuestion: PropTypes.func.isRequired,
}

export default BackButton
