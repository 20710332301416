import { defineMessages } from 'react-intl'

export const groupAnnouncementsFormMessages = defineMessages({
  announcementBody: {
    defaultMessage: 'Body',
    id: 'groupAnnouncementsForm.announcementBody',
  },
  announcementBodyHint: {
    defaultMessage:
      '*The Body is required only if the Announcement is going to be published.',
    id: 'groupAnnouncementsForm.announcementBodyHint',
  },
  errorMessage: {
    defaultMessage:
      'Please review the form and fill in all the required fields.',
    id: 'groupAnnouncementsForm.errorMessage',
  },
  formTitle: {
    defaultMessage: '{groupName} Announcement',
    id: 'groupAnnouncementsForm.formTitle',
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'groupAnnouncementsForm.preview',
  },
  saveDraft: {
    defaultMessage: 'Save As Draft',
    id: 'groupAnnouncementsForm.saveDraft',
  },
})

export const previewModal = defineMessages({
  previewOfAnnouncement: {
    defaultMessage: 'Preview of Announcement',
    id: 'previewModal.previewOfAnnouncement',
  },
})
