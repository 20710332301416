import { createTheme } from '@material-ui/core/styles'
import {
  borderRadius,
  color,
  fontWeight,
  lineHeight,
  spacing,
} from 'styles/variables'

// Add more comments here about what each override does, maybe a link to where to view API for each component.
// Also what not to include in here, e.g. values that need to be in REM may not be supported.

// 1 TODO: Remove these MUI button overrides when our UI version of <Button> is
// implemented everywhere in the employee app.
const buttonPadding = `${spacing[2]} ${spacing[3]}`
const boxShadow = '1px 1px 3px rgba(0,0,0,0.2)'
const muiButtonThemeOverrides = {
  MuiButton: {
    contained: {
      boxShadow: boxShadow,
    },
    containedPrimary: {
      color: color.white,
    },
    outlined: {
      backgroundColor: color.white,
      borderColor: color['black-4'],
      boxShadow: boxShadow,
      padding: buttonPadding,
    },
    root: {
      borderRadius: borderRadius,
      fontWeight: fontWeight.semiBold,
      lineHeight: lineHeight.md,
      minWidth: '4.25rem',
      padding: buttonPadding,
      // eslint-disable-next-line sort-keys
      '&$disabled': {
        boxShadow: 'none',
      },
    },
    text: {
      padding: buttonPadding,
    },
    textPrimary: {
      color: color.orange,
      minWidth: 0,
    },
    textSecondary: {
      color: color.blue,
    },
  },
}

const muiThemeOverrides = {
  overrides: {
    ...muiButtonThemeOverrides, // 1
    MuiAccordion: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        '&$expanded': {
          margin: `${spacing[3]} 0`,
        },
        margin: `${spacing[3]} 0`,
      },
      root: {
        '&$expanded': {
          minHeight: 0,
        },
        minHeight: 0,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.8)',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: color.white,
      },
    },
    MuiInputLabel: {
      outlined: {
        fontWeight: fontWeight.semiBold,
      },
      // When outlined label is focused or filled.
      shrink: {
        color: color['black-1'],
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: `${boxShadow}, inset 0 0 0 1px rgba(0,0,0,0.1)`,
      },
    },
    MuiTab: {
      root: {
        minHeight: '5rem',
      },
      wrapper: {
        textAlign: 'left',
      },
    },
    MuiTabs: {
      indicator: {
        height: '0.25rem', // Height of selected tab indicator
      },
      root: {
        position: 'relative',
      },
      scrollButtons: {
        '& svg': {
          marginTop: '0.25rem',
        },
        '&:first-of-type': {
          left: 0,
        },
        '&:last-of-type': {
          right: 0,
        },
        backgroundColor: 'white',
        height: 'calc(100% - 0.125rem)',
        opacity: '100%',
        position: 'absolute',
        top: '0.0625rem',
        zIndex: 1,
      },
    },
  },
  palette: {
    primary: {
      main: color.orange, // color="primary" (other variations calculated based on 'main')
    },
    secondary: {
      main: color.blue, // color="secondary" (other variations calculated based on 'main')
    },
  },
  typography: {
    fontFamily: '"Open Sans","Helvetica Neue",Arial,sans-serif',
    fontWeightBold: fontWeight.bold,
    fontWeightLight: fontWeight.light,
    fontWeightMedium: fontWeight.semiBold,
    fontWeightRegular: fontWeight.normal,
    useNextVariants: true,
  },
}

const muiTheme = createTheme(muiThemeOverrides)

export { muiTheme, muiThemeOverrides }
