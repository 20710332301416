// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { Image } from 'components/ui/image'

export const AchievementImage = ({
  achievementName,
  className,
  height,
  imageSrc,
  width,
  ...other
}) => (
  <Image
    {...other}
    alt={achievementName}
    backupUrl="https://res.cloudinary.com/hyetmyubn/image/upload/v1574277846/static/achievement_placeholder.png"
    className={cx('rounded-circle', className)}
    crop="fill"
    dpr="2.0"
    gravity="center"
    height={height}
    src={imageSrc}
    width={width}
  />
)

AchievementImage.defaultProps = {
  className: '',
  height: '48',
  width: '48',
}

AchievementImage.propTypes = {
  achievementName: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  width: PropTypes.string,
}
