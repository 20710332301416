// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

// WeSpire
import { achievementsCsvData } from 'utilities/csv_data/achievements_data'
import AchievementsFilters from './components/achievements_filters'
import ResultManagementDescription from '../utils/results_management_description'
import ContentHeader from 'components/setup/application/content_header'
import { dateTimeDate } from 'utilities/date_formatter'
import { ExportButton } from '../utils/export_button'
import { intl } from 'utilities/localization'
import Layout from 'setup/application/layout'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import withNotifications from 'components/helpers/with_notifications'
import { client } from 'utilities/we_apollo'
import { sharedTranslations } from 'components/shared/translations'
import { ACHIEVEMENTS_LIST_QUERY } from './utilities/queries'
import { indexMessages as messages } from './utilities/messages'

const { formatMessage } = intl

const AchievementsIndexPage = ({
  brandId,
  brandName,
  displayNotification,
  notification,
}) => {
  const [selectedChannelsOptions, setSelectedChannels] = useState([])
  const [selectedYearOption, setSelectedYear] = useState('all')

  const fileName = `${brandName} ${
    sharedTranslations.achievements
  } ${dateTimeDate(new Date())}`

  const handleFilters = (values) => {
    const { selectedChannels, selectedYear } = values
    setSelectedChannels(selectedChannels ? selectedChannels : [])
    setSelectedYear(selectedYear)
  }

  const { loading, error, data } = useQuery(ACHIEVEMENTS_LIST_QUERY, {
    client,
    variables: {
      brandId,
      selectedChannels: selectedChannelsOptions,
      selectedYear:
        selectedYearOption !== 'all' ? parseInt(selectedYearOption) : null,
    },
  })

  if (error) {
    return <MissingResourcePage />
  }

  if (loading && !data) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  const { achievements, brandConfig } = data

  const dataDictionaryArray = [
    formatMessage(messages.dataDictionaryA),
    formatMessage(messages.dataDictionaryB),
    formatMessage(messages.dataDictionaryC),
    formatMessage(messages.dataDictionaryD),
    formatMessage(messages.dataDictionaryE),
    formatMessage(messages.dataDictionaryF, {
      wordForScore: brandConfig.wordForScorePlural,
    }),
    formatMessage(messages.dataDictionaryG),
    formatMessage(messages.dataDictionaryH, {
      wordForScore: brandConfig.wordForScorePlural,
    }),
    formatMessage(messages.dataDictionaryI, {
      wordForScore: brandConfig.wordForScorePlural,
    }),
    formatMessage(messages.dataDictionaryJ),
    formatMessage(messages.dataDictionaryK),
    formatMessage(messages.dataDictionaryL),
  ]

  return (
    <Layout
      displayNotification={displayNotification}
      notification={notification}
    >
      <div>
        <ContentHeader
          text={formatMessage(messages.exportAchievements)}
        ></ContentHeader>
        <Stack className="p-4" space={3}>
          <p className="text-black-2 text-left text-uppercase fs-3">
            {formatMessage(messages.allAchievements, {
              achievementsCount: achievements.length,
            })}
          </p>
          <ResultManagementDescription
            arrayHeaderText={formatMessage(messages.dataDictionary)}
            descriptionsArray={dataDictionaryArray}
            descriptionText={formatMessage(messages.description)}
            instructionsText={formatMessage(messages.instructions)}
          />
          <Stack space={6}>
            <AchievementsFilters
              handleFilters={handleFilters}
              selectedChannels={selectedChannelsOptions}
              selectedYear={selectedYearOption}
            />
            <div className="d-flex flex-row-reverse mt-2">
              <ExportButton
                csvData={achievementsCsvData(achievements)}
                dataTest="achievement-filter-button"
                fileName={fileName}
                loading={loading}
              />
            </div>
          </Stack>
        </Stack>
      </div>
    </Layout>
  )
}

AchievementsIndexPage.propTypes = {
  brandId: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  displayNotification: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired,
}

export default withNotifications(AchievementsIndexPage)
