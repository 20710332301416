// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, FormControl, IconButton } from '@material-ui/core'
import { defineMessages } from 'react-intl'
import Formsy from 'formsy-react'

// Wespire
import { getActionUrlForActivity } from 'components/activities/utils'
import { Button } from 'components/ui/button'
import { groupTranslations } from 'components/group/shared_translations'
import { Heading } from 'components/ui/heading'
import { InfoCallout } from 'components/ui/info_callout'
import Stack from 'components/ui/stack'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { intl } from 'utilities/localization'
import { FacepileSizes } from 'components/application/avatar'
import FormSelect from 'components/form/select'
import FormCheckbox from 'components/form/checkbox'
import { sharedTranslations } from 'components/shared/translations'
import SubmitButton from 'components/ui/submit_button'
import { ErrorList } from 'components/form/error_list'
import Icon from 'components/ui/icon'
import { joinGroupMutation } from 'graphql/mutations/joinGroupMutation'
import { usePush } from 'utilities/routing'

const getGroupName = (group, groupId) => {
  if (group.id === groupId) {
    return group.name
  }

  return group.groupChapters.find((chp) => chp.id === groupId).name
}

const groupHasChapters = (group) => group.groupChapters.length > 0

const messages = defineMessages({
  chapterSelectDesc: {
    defaultMessage:
      'Would you like to also join a Group Chapter to find members to connect with?',
    id: 'groupsModal.chapterSelectDesc',
  },
  checkBoxLabel: {
    defaultMessage: 'Make my membership to this Group private.',
    id: 'groupsModal.checkBoxLabel',
  },
  chooseChapter: {
    defaultMessage: 'Required: Choose your group chapter',
    id: 'groupsModal.chooseChapter',
  },
  iconCheckTitle: {
    defaultMessage: 'Not Checked',
    id: 'groupsModal.iconCheckTitle',
  },
  joinGroup: {
    defaultMessage: 'Join',
    description: 'button text for joining a group',
    id: 'groupsModal.joinGroup',
  },
  joinGroupForActivity: {
    defaultMessage:
      'You must be a member to view {activityName}. Would like you to join {groupName}?',
    id: 'groupsModal.joinGroupForActivity',
  },
  privacyDescription: {
    defaultMessage:
      'Your profile will be hidden from the list of members of this Group and only visible to you and your Group leaders',
    id: 'groupsModal.privacyDescription',
  },
  privacyTitle: {
    defaultMessage: 'Membership Privacy (optional)',
    id: 'groupsModal.privacyTitle',
  },
  selectBlankValue: {
    defaultMessage: "I'll skip joining a Chapter at this time",
    id: 'groupsModal.selectBlankValue',
  },
  successMessage: {
    defaultMessage:
      "Welcome! You are now {membership} of {name} and we can't wait for you to participate.",
    id: 'groupsModal.successMessage',
  },
  title: {
    defaultMessage: 'Join {groupName}',
    id: 'groupsModal.title',
  },
})

const { formatMessage } = intl

const GroupModal = ({
  activity,
  group,
  initialGroup,
  isOpen,
  showModal,
  setActivity,
}) => {
  const push = usePush()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const closeModal = () => {
    showModal(false)
    setActivity(null)
  }

  const handleJoinButton = ({ chapterId, hidden }) => {
    const groupId = chapterId || group.id
    setIsSubmitting(true)
    joinGroupMutation({
      groupId,
      limit: FacepileSizes.large,
      visible: !hidden,
    })
      .then(
        ({
          data: {
            joinGroup: { errors },
          },
        }) => {
          displayBanner({
            as: 'div',
            content: errors.length ? (
              <ErrorList errors={errors} />
            ) : (
              formatMessage(groupTranslations.joinSuccessMessage, {
                membership: hidden
                  ? formatMessage(groupTranslations.anAnonymousMember)
                  : formatMessage(groupTranslations.aMember),
                name: getGroupName(group, groupId),
              })
            ),
            variant: errors.length ? 'error' : 'success',
          })

          if (activity) {
            setTimeout(
              () =>
                push({
                  pathname: getActionUrlForActivity({
                    actionUrl: activity.actionUrl,
                  }),
                  state: {
                    fromGroupId: group.id,
                  },
                }),
              1000
            )
          }
        }
      )
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(groupTranslations.joinOperation, {
            groupOrChapter: chapterId
              ? sharedTranslations.chapter
              : sharedTranslations.group,
          }),
        })
      })
      .finally(() => {
        setIsSubmitting(false)
        closeModal()
      })
  }

  return (
    <Dialog
      classes={{ paper: 'p-3 | p-md-4' }}
      fullWidth
      maxWidth="sm"
      onClose={() => closeModal()}
      open={isOpen}
    >
      <Stack divider="default" space={3}>
        <div className="[ d-flex align-items-center justify-content-between ] [ pl-3 ]">
          <Heading data-test="about-group" level={1} variant={2}>
            {formatMessage(messages.title, { groupName: group.name })}
          </Heading>
          <IconButton data-test="close-modal" onClick={closeModal}>
            <Icon
              className="fs-4 text-black-2"
              iconName="close"
              title={sharedTranslations.cancel}
            />
          </IconButton>
        </div>
        <Formsy onValidSubmit={handleJoinButton}>
          <Stack divider="default" space={3}>
            <Stack className="p-3 | p-md-4" space={3}>
              {/* Group message about activity user is trying to join */}
              {activity && (
                <InfoCallout data-test="activity-message">
                  {formatMessage(messages.joinGroupForActivity, {
                    activityName: <b key="activityName">{activity.name}</b>,
                    groupName: <b key="activityName">{group.name}</b>,
                  })}
                </InfoCallout>
              )}

              {/* Group chapters selector if group has chapters */}
              {groupHasChapters(group) && (
                <>
                  <p className="fs-2">
                    {formatMessage(messages.chapterSelectDesc)}
                  </p>
                  <FormControl fullWidth>
                    <FormSelect
                      blankOptionLabel={formatMessage(
                        messages.selectBlankValue
                      )}
                      inputLabelProps={{
                        'aria-label': 'chapter-selection',
                      }}
                      name="chapterId"
                      options={group.groupChapters.map((chp) => ({
                        label: chp.name,
                        value: chp.id,
                      }))}
                      value={initialGroup}
                    />
                  </FormControl>
                </>
              )}

              {/* Privacy check box and info */}
              <FormCheckbox
                helperText={formatMessage(messages.privacyDescription)}
                labelProps={{
                  label: formatMessage(messages.checkBoxLabel),
                }}
                name="hidden"
                value={false}
              />
            </Stack>
            {/* Action buttons */}
            <div className="d-flex justify-content-end p-3 | p-md-3">
              <Button
                className="mx-2 fs-2"
                data-test="join"
                onClick={closeModal}
                variant="neutral"
              >
                {sharedTranslations.cancel.toUpperCase()}
              </Button>
              <SubmitButton
                color="secondary"
                data-test="join-modal-button"
                isSubmitting={isSubmitting}
                variant="outlined"
              >
                {formatMessage(messages.joinGroup)}
              </SubmitButton>
            </div>
          </Stack>
        </Formsy>
      </Stack>
    </Dialog>
  )
}

GroupModal.propTypes = {
  activity: PropTypes.shape({
    actionUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  group: PropTypes.shape({
    groupChapters: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired })
    ),
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  initialGroup: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setActivity: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
}

GroupModal.defaultProps = {
  activity: null,
}

export { GroupModal }
