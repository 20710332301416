// Setup
import React from 'react'

// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { ErrorPage } from 'components/shared/pages/error'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  android: {
    defaultMessage: `Android`,
    id: 'deviceLinkingFallback.android',
  },
  ios: {
    defaultMessage: `iOS`,
    id: 'deviceLinkingFallback.ios',
  },
  missingDevice: {
    defaultMessage: `Please use our { deviceType } app to access the functionality you've requested. You are now being redirected to the app installer.`,
    id: 'deviceLinkingFallback.missingDevice',
  },
})

const DeviceLinkingFallbackPage = () => {
  const appKey = location.pathname.split('/')[2]
  const deviceMap = {
    apple_health: formatMessage(messages.ios),
    google_fit: formatMessage(messages.android),
  }

  const appInstallLinks = {
    apple_health: 'https://apps.apple.com/us/app/wespire/id1452441034',
    google_fit: 'https://play.google.com/store/apps/details?id=com.wespire',
  }

  const redirectToAppInstallLink = (link) => {
    const timeout = setTimeout(() => {
      window.location.href = link
      window.clearTimeout(timeout)
    }, 2000)
  }

  if (appInstallLinks[appKey]) {
    redirectToAppInstallLink(appInstallLinks[appKey])
  }

  return (
    <ErrorPage
      body={formatMessage(messages.missingDevice, {
        deviceType: deviceMap[appKey],
      })}
    />
  )
}

export default DeviceLinkingFallbackPage
