import React, { useContext } from 'react'
import { Formik, Form } from 'formik'

import Button from 'src/common/button/Button'
import TextHero from 'src/common/TextHero'
import Input from 'src/common/Input'
import { useCsrft } from 'src/hooks/useCsrft'
import SessionContext from 'src/contexts/SessionContext'
import WeSpireAPI from 'utilities/wespire_api'

import * as Yup from 'yup'
import { FormattedMessage, useIntl } from 'react-intl'

const MagicLinkForm = () => {
  const { brand } = useContext(SessionContext)
  const { tokenName, tokenValue } = useCsrft()

  const handleSubmit = (values) => {
    const { email } = values
    WeSpireAPI()
      .post('/magic_links', {
        email,
      })
      .then(() => {
        alert('Email sent with magic link')
      })
      .catch((error) => {
        const errors = error.response.data.errors
        console.error(errors)
        alert('An error occurred. Please try again.')
      })
  }
  const intl = useIntl()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'loginMessages.invalidEmail',
          defaultMessage: 'Invalid email address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'loginMessages.emailRequired',
          defaultMessage: 'Email is required',
        })
      ),
  })

  return (
    <main className="grid justify-items-center mt-20 pb-8">
      <TextHero
        subtitle={brand?.heroSubtitle}
        title={brand?.heroTitle || brand?.name}
      />
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form className="max-w-[37.5rem] w-11/12 px-4">
            <section className="flex flex-col mt-12">
              <div className="flex flex-col">
                <Input name={tokenName} type="hidden" value={tokenValue} />
                <Input
                  className="h-14 black-body border-[1px] border-gray-2 rounded-lg px-4 py-3 text-base font-extralight mt-4 font-body"
                  name="email"
                  placeholder={intl.formatMessage({
                    id: 'landingMessages.enterYourEmailAddress',
                    defaultMessage: 'Enter your email address',
                  })}
                />
                <Button className="h-14 mt-4 email" type="submit">
                  <FormattedMessage
                    id="loginMessages.magicLinkEmail"
                    defaultMessage={'Email me a login link'}
                  />
                </Button>
              </div>
            </section>
          </Form>
        )}
      </Formik>
    </main>
  )
}

export default MagicLinkForm
