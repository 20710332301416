import React from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'

// WeSpire
import { GIVING_ACTIVITY } from './queries/queries'
import SkeletonLoading from 'src/common/SkeletonLoading'

const GivingActivityName = ({ activityId }) => {
  const { data, loading, error } = useQuery(GIVING_ACTIVITY, {
    variables: { id: activityId },
  })
  const intl = useIntl()

  if (loading) {
    return <SkeletonLoading />
  }

  if (error) {
    // Handle error state
    return null
  }

  if (data && data.name) {
    return (
      <div>
        <span className="label-all-caps text-black-3">{`${intl.formatMessage({
          id: 'donationSuccess.givingCampaign',
        })}: `}</span>
        <span className="font-bold text-base">{data.name}</span>
      </div>
    )
  }

  return null
}

export default GivingActivityName
