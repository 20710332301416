import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { FormattedMessage, useIntl, FormattedNumber } from 'react-intl'
import { Formik, Form } from 'formik'

// WeSpire
import { filterAllCharitiesByParams } from 'components/charities/utils'
import useEnabledCountries from 'src/hooks/useEnabledCountries'
import Button from 'src/common/button/Button'
import PageContainer from 'src/common/PageContainer'
import Checkbox from 'src/common/Checkbox'
import useDonationMatchingEnabled from './queries/useDonationMatchingEnabled'
import CharitySearchForm from './components/CharitySearchForm'
import CharitySearchResults from './components/CharitySearchResults'

const CharitySearch = ({ managementPanel }) => {
  const intl = useIntl()
  const { source } = useParams()
  const [charities, setCharities] = useState([])
  const [displayedCharities, setDisplayedCharities] = useState([])
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState('pending')
  const [loading, setLoading] = useState(false)

  const donationMatchingEnabled = useDonationMatchingEnabled()
  const { enabledCountries, hasEnabledCountries } =
    useEnabledCountries(setStatus)

  const handleSearch = async (values) => {
    setStatus('loading')
    setLoading(true)
    try {
      const { charities, count } = await filterAllCharitiesByParams(
        !hasEnabledCountries && !managementPanel
          ? {
              ...values,
              ['country']: 'USA',
            }
          : values
      )
      setCharities(charities)
      setDisplayedCharities(charities.slice(0, 10))
      setCount(count)
    } catch (e) {
      setStatus('error')
    } finally {
      setStatus('success')
      setLoading(false)
    }
  }

  const loadMore = () => {
    setDisplayedCharities(charities.slice(0, displayedCharities.length + 90))
  }

  const handleReset = (resetForm) => {
    resetForm()
    setCharities([])
    setDisplayedCharities([])
    setCount(0)
  }

  const searchStatusText = () => {
    let text

    if (status === 'pending') {
      text = intl.formatMessage({
        id: 'charities.pending',
        defaultMessage: 'Pending',
      })
    } else if (status === 'loading') {
      text = intl.formatMessage({
        id: 'charities.searching',
        defaultMessage: 'Searching',
      })
    } else if (status === 'error') {
      text = intl.formatMessage({
        id: 'charities.errorDetails',
        defaultMessage: 'Error',
      })
    } else {
      text = intl.formatMessage(
        { id: 'charities.charitiesMatch', defaultMessage: '{count} Charities' },
        {
          count: count,
          stringifiedCount: <FormattedNumber value={count} />,
        }
      )
    }
    return text
  }

  return (
    <PageContainer title="Search for a Charity">
      <div className="container mx-auto px-4">
        <Formik
          initialValues={{
            charityName: '',
            city: '',
            country: [],
            eins: '',
          }}
          onSubmit={handleSearch}
        >
          {({ values, handleChange, handleBlur, resetForm }) => (
            <Form className="space-y-4">
              <CharitySearchForm
                intl={intl}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                enabledCountries={enabledCountries}
              />
              <span className="fs-1 pl-3">
                <FormattedMessage
                  id="charities.hintTextForEin"
                  defaultMessage="For US 501(c)(3) organizations, this is often a nine-digit EIN or tax ID number."
                />
              </span>
              {donationMatchingEnabled && (
                <Checkbox
                  label={intl.formatMessage({
                    id: 'charities.isMatchEligibleLabel',
                    defaultMessage:
                      'Show only Matching Gift eligible charities',
                  })}
                  name="isMatchEligible"
                  value={values.isMatchEligible}
                  setValue={(value) =>
                    handleChange({ target: { name: 'isMatchEligible', value } })
                  }
                />
              )}
              <div className="flex justify-end">
                <Button
                  onClick={() => handleReset(resetForm)}
                  type="button"
                  variant="outlined"
                  className="mr-3"
                >
                  <FormattedMessage
                    id="charities.reset"
                    defaultMessage={'Reset'}
                  />
                </Button>
                <Button type="submit">
                  <FormattedMessage
                    id="charities.search"
                    defaultMessage={'Search'}
                  />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <CharitySearchResults
          status={status}
          searchStatusText={searchStatusText}
          charities={charities}
          loading={loading}
          count={count}
          loadMore={loadMore}
          displayedCharities={displayedCharities}
          source={source}
        />
      </div>
    </PageContainer>
  )
}

CharitySearch.propTypes = {
  managementPanel: PropTypes.bool,
  onCharitySearch: PropTypes.func,
  source: PropTypes.string.isRequired,
}

CharitySearch.defaultProps = {
  managementPanel: false,
}

export default CharitySearch
