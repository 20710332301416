import gql from 'graphql-tag'

export const LEADERBOARD_COMPETITOR_FRAGMENT = gql`
  fragment CompetitorAttributes on RankedCompetitorInterface {
    id
    avatarUrl
    highlight
    name
    profilePath
    points
    rank
  }
`

export const LEADERBOARD_QUERY_NAME = 'getCompetitionLeaderBoardById'
