// Setup
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

// WeSpire
import { client } from 'utilities/we_apollo'

import { GET_POINTS_DASHBOARD_ENABLED } from './queries'

export const useIsPointsDashboardEnabled = () => {
  // eslint-disable-next-line no-unused-vars
  const [isPointsDashboardEnabled, setIsPointsDashboardEnabled] =
    useState(undefined)

  const { data, loading, error } = useQuery(GET_POINTS_DASHBOARD_ENABLED, {
    client,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: {
            brandConfig: { isPointsDashboardEnabled },
          },
        } = await client.query({
          query: GET_POINTS_DASHBOARD_ENABLED,
        })
        setIsPointsDashboardEnabled(isPointsDashboardEnabled)
      } catch {
        return
      }
    }
    fetch()
  }, [])

  return {
    error,
    isPointsDashboardEnabled: data?.brandConfig?.isPointsDashboardEnabled,
    loading,
  }
}
