// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import Autocomplete from 'components/form/autocomplete'
import FormBooleanRadioReveal from 'components/form/boolean_radio_reveal'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  allGroupsButtonLabel: {
    defaultMessage: 'All Groups',
    id: 'additionalFormFields.allGroupsButtonLabel',
  },
  groupSelecLabel: {
    defaultMessage: 'Groups with Access',
    id: 'additionalFormFields.groupSelectLabel',
  },
  groupSelectHelperText: {
    defaultMessage: 'Select the Groups that should have access.',
    id: 'additionalFormFields.groupSelectHelperText',
  },
  groupVisibilityLabel: {
    defaultMessage: 'Group Visibilty*',
    id: 'additionalFormFields.groupVisibilityLabel',
  },
  specificGroupsButtonLabel: {
    defaultMessage: 'Only specific Groups',
    id: 'additionalFormFields.specificGroupsButtonLabel',
  },
})

const { formatMessage } = intl

const FormGroupVisibilityField = ({
  allGroups,
  name,
  selectedGroups,
  hideLabel,
  ...other
}) => (
  <FormBooleanRadioReveal
    {...other}
    emptyStates={[{ name: name, value: [] }]}
    hideLabel={hideLabel}
    label={formatMessage(messages.groupVisibilityLabel)}
    radioHideLabel={formatMessage(messages.allGroupsButtonLabel)}
    radioRevealLabel={formatMessage(messages.specificGroupsButtonLabel)}
    shouldReveal={selectedGroups.length > 0}
  >
    <Autocomplete
      multiple
      name={name}
      required
      selections={selectedGroups}
      suggestions={allGroups}
      textFieldProps={{
        helperText: formatMessage(messages.groupSelectHelperText),
        label: formatMessage(messages.groupSelecLabel),
      }}
    />
  </FormBooleanRadioReveal>
)

FormGroupVisibilityField.propTypes = {
  allGroups: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  selectedGroups: PropTypes.array,
}

FormGroupVisibilityField.defaultProps = {
  hideLabel: false,
  name: 'groupIds',
  selectedGroups: [],
}

export default FormGroupVisibilityField
