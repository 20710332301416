import React from 'react'
import { UserLegalDocumentsContext } from 'utilities/context/UserLegalDocumentsContext'

const useIsMissingLegalDocuments = () => {
  const isMissingAcceptance = React.useContext(UserLegalDocumentsContext)

  return isMissingAcceptance
}

export { useIsMissingLegalDocuments }
