// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Paper from '@material-ui/core/Paper'
import { NetworkStatus } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import Stack from 'components/ui/stack'
import { client } from 'utilities/we_apollo'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { LoadMoreButton } from 'components/shared/load_more_button'

import { GroupResourceRow } from './row'
import { GROUP_RESOURCE_QUERY as QUERY } from './utils'

const { formatMessage } = intl

const messages = defineMessages({
  activeResources: {
    defaultMessage: 'Active Resources & Files ({ totalCount })',
    id: 'groupResourcesPanel.activeResources',
  },
  activeResourcesDescription: {
    defaultMessage:
      'Active Resources & Files are visible to users on {groupName}.',
    id: 'groupResourcesPanel.activeResourcesDescription',
  },
  archivedResources: {
    defaultMessage: 'Archived Resources & Files ({ totalCount })',
    id: 'groupResourcesPanel.archivedResources',
  },
  archivedResourcesDescription: {
    defaultMessage:
      'Archived Resources & Files are hidden from users on {groupName}, and can be restored to Active by editing the Resource or File.',
    id: 'groupResourcesPanel.archivedResourcesDescription',
  },
  emptyStateActive: {
    defaultMessage: 'Group has no resources uploaded. You can { createLink }.',
    id: 'groupResourcesPanel.emptyStateActive',
  },
  emptyStateAddLink: {
    defaultMessage: 'add new resources',
    id: 'groupResourcesPanel.emptyStateAddLink',
  },
  emptyStateArchived: {
    defaultMessage: 'Group has no resources archived.',
    id: 'groupResourcesPanel.emptyStateArchived',
  },
  helperTextForSearch: {
    defaultMessage: 'Filter by name or email address',
    id: 'groupResourcesPanel.helperTextForSearch',
  },
  labelDisplayedRows: {
    defaultMessage: '{ from }-{ to } of { count }',
    id: 'groupResourcesPanel.labelDisplayedRows',
  },
  labelRowsPerPage: {
    defaultMessage: 'Rows per page',
    id: 'groupResourcesPanel.labelRowsPerPage',
  },
  resources: {
    defaultMessage: 'Resources',
    id: 'groupResourcesPanel.resources',
  },
})

const tableHeadMessages = defineMessages({
  archivedBy: {
    defaultMessage: 'Archived by',
    id: 'groupResourcesPanelsHead.archivedBy',
  },
  lastUpdatedAt: {
    defaultMessage: 'Date',
    id: 'groupResourcesPanelsHead.lastUpdatedAt',
  },
  name: {
    defaultMessage: 'Name',
    id: 'groupResourcesPanelsHead.name',
  },
  uploadedBy: {
    defaultMessage: 'Uploaded by',
    id: 'groupResourcesPanelsHead.uploadedBy',
  },
})

const buttonMessages = defineMessages({
  addNewResource: {
    defaultMessage: 'Add Resource',
    id: 'groupResourcesPanels.addNewResource',
  },
})

const PAGE_SIZE = 10
export const GroupResourcesPanel = ({ groupId, groupName, type }) => {
  const { data, error, fetchMore, loading, networkStatus, variables } =
    useQuery(QUERY, {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        archived: type === 'archived',
        first: PAGE_SIZE,
        groupId: groupId,
      },
    })

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.group.resources.nodes = [
          ...previousResult?.group?.resources?.nodes,
          ...fetchMoreResult?.group?.resources?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: data?.group?.resources?.pageInfo?.endCursor,
        first: PAGE_SIZE,
      },
    })
  }

  const groupData = data?.group
  const groupResources = groupData?.resources?.nodes

  if (error) {
    return <MissingResourcePage />
  }

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)

  let archivedByHeader
  let uploadedByHeader
  if (type === 'archived') {
    archivedByHeader = formatMessage(tableHeadMessages.archivedBy)
  } else {
    uploadedByHeader = formatMessage(tableHeadMessages.uploadedBy)
  }

  const headCells = [
    {
      id: 'name',
      label: formatMessage(tableHeadMessages.name),
      width: 5,
    },
    {
      id: 'lastUpdatedAt',
      label: formatMessage(tableHeadMessages.lastUpdatedAt),
      width: 2,
    },
    {
      id: 'uploadedBy',
      label: archivedByHeader || uploadedByHeader,
      width: 3,
    },
    {
      id: 'actions',
      invisible: true,
      width: 2,
    },
  ]

  const panelHeaderText = (totalCount) => {
    return formatMessage(messages[`${type}Resources`], { totalCount })
  }

  const emptyStateText = () => {
    if (type === 'archived') return formatMessage(messages.emptyStateArchived)

    return formatMessage(messages.emptyStateActive, {
      createLink: (
        <LinkButton
          className="text-blue fs-2 [ pl-1 pr-0 py-0 ]"
          to={`resources/new`}
          variant="text"
        >
          {formatMessage(messages.emptyStateAddLink)}
        </LinkButton>
      ),
    })
  }

  return (
    <Stack
      as={Paper}
      className="border border-black-5"
      data-test={`${type}-resources-panel`}
      space={0}
    >
      <Stack className="px-4 py-3 bg-black-6" space={1}>
        <Heading
          className="fs-2 text-uppercase"
          data-test={`all-${type}-resources-label`}
          level={3}
        >
          {panelHeaderText(groupData?.resources.totalCount || 0)}
        </Heading>
        <p className="mt-1 fs-1 text-black-3">
          {formatMessage(messages[`${type}ResourcesDescription`], {
            groupName,
          })}
        </p>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        {type === 'active' && (
          <Stack className="d-flex">
            <LinkButton
              className="text-blue fs-2 ml-auto"
              to={`resources/new`}
              variant="text"
            >
              <Icon
                className="fs-4 mr-1"
                iconName="file_upload_outline"
                title={formatMessage(buttonMessages.addNewResource)}
              />
              {formatMessage(buttonMessages.addNewResource)}
            </LinkButton>
          </Stack>
        )}

        <Stack>
          <TableContainer>
            <Table aria-label="enhanced table" aria-labelledby="tableTitle">
              <TableHead>
                <TableRow className="row">
                  {headCells.map((headCell, index) => (
                    <TableCell
                      align={index > 0 ? 'right' : 'inherit'}
                      className={`col-${headCell.width}`}
                      key={headCell.id}
                    >
                      {headCell.invisible ? null : headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!refetching &&
                  groupResources?.map((resource) => {
                    return (
                      <GroupResourceRow
                        groupId={groupId}
                        key={resource.id}
                        tabIndex={-1}
                        {...resource}
                      />
                    )
                  })}
                {groupResources?.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={4}>
                      <p
                        className="fs-2 fw-semi-bold d-flex align-items-center justify-content-center"
                        data-test="group-resources-empty-state"
                      >
                        {emptyStateText()}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={4}>
                      <CenteredPaddedLoadingIndicator
                        padding={null}
                        size={LoadingIndicatorSize.LARGE}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {groupData?.resources?.pageInfo.hasNextPage && (
            <div className="[ d-flex justify-content-center ] [ pt-2 ]">
              <LoadMoreButton
                className="flex-grow mxw-4"
                loading={loading}
                newsfeedItemsCount={groupResources.length}
                onClick={onLoadMore}
                pageSize={PAGE_SIZE}
                totalCount={groupData.resources.totalCount}
                type={messages.resources}
              />
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

GroupResourcesPanel.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
