// Setup
import React from 'react'

// WeSpire
import { ProgressBar } from './progress_bar'
import ProgressBarTop from 'components/ui/progress_bar/progress_bar_top'

export default {
  component: ProgressBar,
  title: 'UI/ProgressBar(old)',
}

const Template = (args) => <ProgressBar {...args} />
const TemplateWithTopComponent = (args) => (
  <ProgressBar
    {...args}
    top={
      <ProgressBarTop goal="26,000 STEPS TAKEN" progress="50,000 STEPS GOAL" />
    }
  />
)
const TemplateWithCenterComponent = (args) => (
  <ProgressBar
    {...args}
    center={<button className="absolute-right mr-2"> MyButton </button>}
  />
)

export const Default = Template.bind({})
Default.args = {
  progressPercent: 50,
}

export const WithTopComponent = TemplateWithTopComponent.bind({})
WithTopComponent.args = {
  ...Default.args,
}

export const WithCenterComponent = TemplateWithCenterComponent.bind({})
WithCenterComponent.args = {
  ...Default.args,
}
