import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  errorMessage: {
    defaultMessage: 'There was a problem loading the list of Broadcasts.',
    id: 'broadcastMessages.errorMessage',
  },
  olderPosts: {
    defaultMessage: 'Older Posts',
    id: 'broadcastMessages.olderPosts',
  },
  viewArchive: {
    defaultMessage: 'View Archive',
    id: 'broadcastMessages.viewArchive',
  },
})

export const broadcastMessages = formatMessages(messages)
