// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import { abbreviatedDateWithDay } from 'utilities/date_formatter'
import { intl } from 'utilities/localization'
import ExternalLink from 'components/ui/external_link'
import { parseDate } from 'utilities/date_utils'
import { WordForScore } from 'components/queries/word_for_score'

const messages = defineMessages({
  activity: {
    defaultMessage: 'Activity',
    id: 'pointsDashbaordScoreLog.activity',
  },
  dateColumnLabel: {
    defaultMessage: 'Date',
    id: 'pointsDashbaordScoreLog.dateColumnLabel',
  },
  pointsLabel: {
    defaultMessage: '{ wordForScore } Earned',
    id: 'pointsDashbaordScoreLog.pointsLabel',
  },
})

const DataColumn = ({ className, label, value, url }) => {
  return (
    <span className={cx('fs-2 fw-semi-bold mb-1 | mb-md-0', className)}>
      <span className="text-black-2 text-uppercase">{label}: </span>
      {url ? (
        <ExternalLink className="text-blue" hideIcon text={value} to={url} />
      ) : (
        <span className="text-black">{value}</span>
      )}
    </span>
  )
}

export const ScoreLog = ({ userScoreLog }) => {
  const formatMessage = intl.formatMessage

  return (
    <>
      {userScoreLog.points ? (
        <li className="score-log p-4" data-test="points-dashboard-score-log">
          <span className="row" data-test="date">
            <DataColumn
              className="col-md-3"
              label={formatMessage(messages.dateColumnLabel)}
              value={abbreviatedDateWithDay(parseDate(userScoreLog.date))}
            />

            <DataColumn
              className="col-md-3"
              label={formatMessage(messages.pointsLabel, {
                wordForScore: <WordForScore score={2} wordOnly />,
              })}
              value={userScoreLog.points}
            />

            <DataColumn
              className="col-md-6"
              label={userScoreLog.actionType}
              url={userScoreLog.url}
              value={
                userScoreLog.actionName || formatMessage(messages.activity)
              }
            />
          </span>
        </li>
      ) : (
        <></>
      )}
    </>
  )
}

DataColumn.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

DataColumn.defaultProps = {
  className: null,
  url: null,
}

ScoreLog.propTypes = {
  userScoreLog: PropTypes.shape({
    actionName: PropTypes.string,
    actionType: PropTypes.string,
    date: PropTypes.string,
    points: PropTypes.number,
    url: PropTypes.string,
  }).isRequired,
}
