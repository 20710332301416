import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CalculatorContext } from 'components/CarbonCalculator/contexts/CalculatorContext'
import { COUNTRY } from 'components/CarbonCalculator/contexts/constants'
import OutlinedInput from '@mui/material/OutlinedInput'
import './LocationCodeInputComponent.css'

const LocationCodeInputComponent = ({ questionKey }) => {
  const { answerState, dispatchAnswer } = useContext(CalculatorContext)
  const [placeholderText, setPlaceholderText] = useState('Zip Code')
  const [inputType, setInputType] = useState('number')
  const [inputData, setInputData] = useState('')

  const setAutoCompleteText = () => {
    if (answerState[COUNTRY] === 'CA') {
      setPlaceholderText('Six-Character Postal Code')
      setInputType('')
    } else {
      setPlaceholderText('5 Digit Zip Code')
      setInputType('number')
    }
  }

  const handleTextFieldChange = (value) => {
    let validation = value.length < 6
    if (answerState[COUNTRY] !== 'US') {
      validation = value.length < 7
    }
    if (value.length >= 0 && validation) {
      setInputData(value)
    }
  }

  const handleBlur = (e) => {
    dispatchAnswer({
      type: questionKey,
      payload: { questionKey, answer: e.target.value },
    })
  }

  useEffect(() => {
    setAutoCompleteText()
  }, [answerState[COUNTRY]])
  return (
    <div className="my-6">
      <OutlinedInput
        onChange={(e) => handleTextFieldChange(e.target.value)}
        data-test="location-code-input-test"
        type={inputType}
        placeholder={placeholderText}
        autoComplete="off"
        color="primary"
        fullWidth
        value={inputData}
        onBlur={handleBlur}
      />
    </div>
  )
}

LocationCodeInputComponent.propTypes = {
  questionKey: PropTypes.string.isRequired,
}

export default LocationCodeInputComponent
