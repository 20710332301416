// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import { useQuery, NetworkStatus } from '@apollo/client'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { undecorated as Select } from 'components/form/select'
import { statusFilterOptions } from './utils'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { LoadMoreButton } from 'components/shared/load_more_button'

import { HrisImportRow } from './row'

import { LIST_HRIS_IMPORTS_QUERY } from '../utilities/queries'

const { formatMessage } = intl

const messages = defineMessages({
  noImports: {
    defaultMessage: 'No HRIS Imports available.',
    id: 'hrisImportList.noImports',
  },
  filePath: {
    defaultMessage: 'File Path',
    id: 'hrisImportList.filePath',
  },
  status: {
    defaultMessage: 'Status',
    id: 'hrisImportList.status',
  },
  result: {
    defaultMessage: 'Result',
    id: 'hrisImportList.result',
  },
  usersCreated: {
    defaultMessage: 'Users Created',
    id: 'hrisImportList.usersCreated',
  },
  usersUpdated: {
    defaultMessage: 'Users Updated',
    id: 'hrisImportList.usersUpdated',
  },
  usersDeactivated: {
    defaultMessage: 'Users Deactivated',
    id: 'hrisImportList.usersDeactivated',
  },
  usersFailed: {
    defaultMessage: 'Users Failed',
    id: 'hrisImportList.usersFailed',
  },
  usersProcessed: {
    defaultMessage: 'Users Processed',
    id: 'hrisImportList.usersProcessed',
  },
  usersUnchanged: {
    defaultMessage: 'Users Unchanged',
    id: 'hrisImportList.usersUnchanged',
  },
  date: {
    defaultMessage: 'Date',
    id: 'hrisImportList.date',
  },
  statusFilterLabel: {
    defaultMessage: 'Filter by status',
    id: 'hrisImportList.statusFilterLabel',
  },
  hrisImports: {
    defaultMessage:
      '{count, plural, zero {HRIS Imports} one {HRIS Import} two {HRIS Imports} few {HRIS Imports} many {HRIS Imports} other {HRIS Imports}}',
    id: 'hrisImportList.hrisImports',
  },
})

const PAGE_SIZE = 25

const HrisImportList = () => {
  const { data, error, fetchMore, loading, networkStatus, refetch, variables } =
    useQuery(LIST_HRIS_IMPORTS_QUERY , {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_SIZE,
        status: '',
      },
  })
  const hrisImports = data?.hrisImports

  if (error) {
    return <MissingResourcePage />
  }

  const onStatusFilterChange = (status) => {
    refetch({ ...variables, status })
  }

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.hrisImports.nodes = [
          ...previousResult?.hrisImports?.nodes,
          ...fetchMoreResult?.hrisImports?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: data?.hrisImports?.pageInfo?.endCursor,
        first: PAGE_SIZE,
      },
    })
  }

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)

  return (
    hrisImports && (
      <Stack as={Paper} className="p-5 m-5" space={5}>
        <Stack className="col-3" data-test="status-filter" space={1}>
          <InputLabel
            className="fw-semi-bold text-black-1 text-uppercase fs-1"
            htmlFor="statusFilter"
          >
            {formatMessage(messages.statusFilterLabel)}
          </InputLabel>
          <Select
            hideBlankOption
            name="statusFilter"
            optionProps={{ 'data-test': 'status-filter-option' }}
            options={statusFilterOptions}
            selectProps={{ inputProps: { className: 'p-2 text-capitalize' } }}
            setValue={onStatusFilterChange}
            value={variables.status}
          />
        </Stack>
        <Stack className="[ flex-column ] [ bg-off-white pt-2 ]" space={2}>
          {!refetching && (
            <>
              {hrisImports?.nodes?.length > 0 ? (
                <div>
                  <div className="bg-black-6 fs-1 fw-semi-bold ls-3 py-3 text-uppercase">
                    <div className="row">
                      <span className="col-1">{formatMessage(messages.filePath)}</span>
                      <span className="col-1">{formatMessage(messages.date)}</span>
                      <span className="col-1">{formatMessage(messages.status)}</span>
                      <span className="col-1">{formatMessage(messages.usersProcessed)}</span>
                      <span className="col-1">{formatMessage(messages.usersCreated)}</span>
                      <span className="col-1">{formatMessage(messages.usersUpdated)}</span>
                      <span className="col-1">{formatMessage(messages.usersDeactivated)}</span>
                      <span className="col-1">{formatMessage(messages.usersUnchanged)}</span>
                      <span className="col-1">{formatMessage(messages.usersFailed)}</span>
                      <span className="col-1">{formatMessage(messages.result)}</span>
                    </div>
                  </div>
                  <Stack as="ol" className="p-0" divider="default" space={1}>
                    {hrisImports?.nodes?.map((hrisImport) => <HrisImportRow hrisImport={hrisImport} key={hrisImport.id} />)}
                  </Stack>
                </div>
              ) : (
                <p className="fw-semi-bold" data-test="empty-state-message">
                  {formatMessage(messages.noImports)}
                </p>
              )}
            </>
          )}

          {loading && (
            <CenteredPaddedLoadingIndicator
              padding={null}
              size={LoadingIndicatorSize.LARGE}
            />
          )}
        </Stack>

        {hrisImports?.pageInfo.hasNextPage && (
          <div className="[ d-flex justify-content-center ] [ pt-2 ]">
            <LoadMoreButton
              className="flex-grow mxw-4"
              loading={loading}
              newsfeedItemsCount={hrisImports?.nodes?.length}
              onClick={onLoadMore}
              pageSize={PAGE_SIZE}
              totalCount={hrisImports?.totalCount}
              type={messages.hrisImports}
            />
          </div>
        )}
      </Stack>
    )
  )
}

export { HrisImportList }
