// Setup
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import { useField } from 'formik'

// WeSpire
import Icon from 'components/ui/icon'
import { getFieldValidationAttrs } from './utils'

const FormTextFieldFormik = ({
  autoComplete,
  className,
  mask,
  name,
  label,
  textFieldProps,
  isRequired,
}) => {
  const [{ onChange }, meta, helpers] = useField(name)
  const [uniqueId] = useState(() => `${name}-${Math.random()}`)

  const getLabel = () => {
    return (
      label && (
        <Fragment>
          {label}
          {isRequired && <span aria-hidden>*</span>}
        </Fragment>
      )
    )
  }

  const getHelperText = () => {
    if (meta.error && meta.touched) {
      const validationTextProps = getFieldValidationAttrs(name, meta.error)

      return (
        <span className="d-flex | lh-md" {...validationTextProps}>
          {meta.error && (
            <Icon
              className="fs-1 mr-1"
              data-test="text-field-validation-error-icon"
              iconName="warning"
              style={{ marginTop: '3px' }}
              title=""
            />
          )}
          {meta.error}
        </span>
      )
    }

    if (textFieldProps.helperText) {
      return <span className="d-flex | lh-md">{textFieldProps.helperText}</span>
    }

    return null
  }

  const handleChange = (e) => {
    if (mask) {
      const value = e.currentTarget.value
      helpers.setValue(value)
    } else {
      onChange(e)
    }
  }

  const { InputLabelProps, inputProps, style } = textFieldProps
  const inputClassName = inputProps && inputProps.className
  const inputAriaDescribedBy = inputProps && inputProps['aria-describedby']
  const InputLabelClassName = InputLabelProps && InputLabelProps.className
  const InputLabelClasses = InputLabelProps && InputLabelProps.classes
  const InputLabelShrinkClasses = InputLabelClasses && InputLabelClasses.shrink

  const textField = (
    <TextField
      {...textFieldProps}
      autoComplete={autoComplete}
      className={className}
      error={meta.touched && Boolean(meta.error)}
      fullWidth
      helperText={getHelperText()}
      id={uniqueId}
      InputLabelProps={{
        ...InputLabelProps,
        // By default, visually truncate the text field label to 1 line only.
        // When it's focused or filled, display up to 2 lines of the label and
        // visually hide the rest of the overflow, so it doesn't overlap and
        // interfere with user inputted text.
        classes: {
          ...InputLabelClasses,
          shrink: cx('max-lines-2-sm ws-normal', InputLabelShrinkClasses),
        },
        className: cx(
          'hyphens lh-sm text-sentence text-truncate ',
          InputLabelClassName
        ),
        htmlFor: uniqueId,
        id: `${name}-label`,
        style: {
          ...style,
          maxWidth: 'calc(100% - 1.5rem)',
        },
      }}
      inputProps={{
        ...textFieldProps.inputProps,
        'aria-describedby': `${uniqueId}-helper-text ${inputAriaDescribedBy}`,
        'aria-required': isRequired,
        className: cx('fs-2 lh-md', inputClassName),
        'data-test': name,
        id: uniqueId,
      }}
      label={getLabel()}
      name={name}
      onChange={handleChange}
      value={meta.value}
      variant="outlined"
    />
  )

  // Wrap our TextField in an InputMask only when we have a mask to apply.
  if (mask) {
    return (
      <InputMask
        alwaysShowMask
        mask={mask}
        onChange={handleChange}
        value={meta.value}
      >
        {() => textField}
      </InputMask>
    )
  } else {
    return textField
  }
}

FormTextFieldFormik.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  textFieldProps: PropTypes.shape({
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.shape({
      'aria-describedby': PropTypes.string,
      className: PropTypes.string,
    }),
    style: PropTypes.object,
  }),
}

FormTextFieldFormik.defaultProps = {
  autoComplete: 'off',
  className: null,
  isRequired: false,
  mask: null,
  setValue: () => {},
  textFieldProps: {
    inputProps: {
      className: null,
    },
  },
}

export { FormTextFieldFormik }
