// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  addedOrganization: {
    defaultMessage: 'Added Organization',
    id: 'ineligibleForPvh.addedOrganization',
  },
  ineligible: {
    defaultMessage: 'Ineligible for PVH',
    id: 'ineligibleForPvh.ineligible',
  },
  nonVolunteeringEvent: {
    defaultMessage:
      'Non-volunteering Events are not eligible for PVH. If you think this Event should be eligible, {contactLink}.',
    id: 'ineligibleForPvh.nonVolunteeringEvent',
  },
  organizationNotEligible: {
    defaultMessage: '{organizationName} is not eligible',
    id: 'ineligibleForPvh.organizationNotEligible',
  },
  requestOrganizationEligible: {
    defaultMessage:
      'Added Organizations are not eligible for PVH. If you would like this Organization to be eligible, or feel that it should already be, {contactLink}.',
    id: 'ineligibleForPvh.requestOrganizationEligible',
  },
})

/**
 * Badge to indicate why Organizations are not eligible for PVH.
 */
export const IneligibleForPvh = ({
  className,
  organizationName,
  orgAssociatedToVolunteerEvent,
}) => {
  const modalContent = orgAssociatedToVolunteerEvent
    ? messages.requestOrganizationEligible
    : messages.nonVolunteeringEvent
  const modalTitle = orgAssociatedToVolunteerEvent
    ? intl.formatMessage(messages.organizationNotEligible, {
        organizationName: organizationName,
      })
    : intl.formatMessage(messages.ineligible)

  return (
    <TextWithHelpIconModal
      className={cx(className, 'fs-1')}
      helpIconModalProps={{
        'data-test': 'pvh-charity-ineligible',
      }}
      modalContent={
        <p>
          {intl.formatMessage(modalContent, {
            contactLink: (
              <Link to="mailto:support@wespire.com?subject=PVH%20Organization%20Eligibility%20Request">
                {sharedTranslations.contactSupport}
              </Link>
            ),
          })}
        </p>
      }
      text={intl.formatMessage(messages.ineligible)}
      title={modalTitle}
    />
  )
}

IneligibleForPvh.propTypes = {
  /** class attribute applied to parent node */
  className: PropTypes.string,
  /** name of organization that is ineligible for PVH */
  organizationName: PropTypes.string,
  /** whether the event the organization is associated with is a volunteering event */
  orgAssociatedToVolunteerEvent: PropTypes.bool.isRequired,
}

IneligibleForPvh.defaultProps = {
  className: undefined,
  organizationName: intl.formatMessage(messages.addedOrganization),
}
