import React from 'react'

const VolunteeringForm = () => {
  return (
    <div>
      <h2>VolunteeringForm</h2>
      <p>This is a sample functional component for VolunteeringForm.</p>
    </div>
  )
}

export default VolunteeringForm