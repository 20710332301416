// Vendor
import React from 'react'

// Wespire
import { Auth } from './Auth'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { Login } from 'components/login/Login'

export const EmailAuth = () => {
  //const { enableNewSignInView } = useFlags()
  const enableNewSignInView = true

  if (enableNewSignInView) {
    return <Login />
  } else if (enableNewSignInView === false) {
    return <Auth />
  } else {
    ;<CenteredPaddedLoadingIndicator />
  }
}
