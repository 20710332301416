// Vendor
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'

// Wespire
import CenteredContent from 'components/application/centered_content'

const Drawer = ({ children, className, dataTest, ...other }) => {
  return (
    <Paper
      {...other}
      className={cx('[ px-8 py-4 ] [ fixed-bottom ]', className)}
      data-test={dataTest}
    >
      <CenteredContent className="row">{children}</CenteredContent>
    </Paper>
  )
}

Drawer.propTypes = {
  /** render drawer custom content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** for identification of draw in testing */
  dataTest: PropTypes.string,
}

Drawer.defaultProps = {
  className: null,
  dataTest: 'drawer-data-test',
}

export { Drawer }
