// Vendor
import React from 'react'

// WeSpire
import { Paragraph } from 'components/ui/Typography/Paragraph/Paragraph'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'Write a paragraph and you will get one.',
    },
  },
  component: Paragraph,
  title: 'UI/Typography/Paragraph',
}

const Template = (args) => <Paragraph {...args} />

export const StringParagraph = Template.bind({})
