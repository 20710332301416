import React from 'react'
import PropTypes from 'prop-types'

import HereAddressAutocomplete from './here_address_autocomplete'
import GoogleAddressAutocomplete from './google_address_autocomplete'

export const LOCATION_PROVIDER = {
  google: "google",
  here: "here"
}

const AddressAutocomplete = (props) => {
  const { type } = props

  if (type === LOCATION_PROVIDER.here) {
    return <HereAddressAutocomplete {...props} />
  } else if (type === LOCATION_PROVIDER.google) {
    return <GoogleAddressAutocomplete {...props} />
  } else {
    throw new Error("No type property provied in AddressAutocomplete")
  }
}

export const getLocationProvider = (flag) => {
  if (flag === undefined || flag === null) {
    return LOCATION_PROVIDER.google
  }
  return flag ? LOCATION_PROVIDER.google : LOCATION_PROVIDER.here
}

AddressAutocomplete.defaultProps = {
  type: "google"
}

AddressAutocomplete.propTypes = {
 type: PropTypes.string
}
export default AddressAutocomplete
