// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

const MarkdownTextSizes = ['small', 'medium', 'large']

/**
 * MarkdownText that corresponds to `<lavel``. Adheres design system scale of
 * allowed style variations.
 */
const MarkdownText = ({ children, className, size, ...other }) => {
  return (
    <ReactMarkdown
      {...other}
      className={cx('react-styles', className)}
      remarkPlugins={[gfm]}
      size={size}
    >
      {children}
    </ReactMarkdown>
  )
}

MarkdownText.propTypes = {
  /** render markdown as html content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** renders a small, medium or large markdown versions */
  size: PropTypes.oneOf(MarkdownTextSizes),
}

MarkdownText.defaultProps = {
  className: null,
  size: 'medium',
}

export { MarkdownText, MarkdownTextSizes }
