import PropTypes from 'prop-types'
import React, { createContext, useState, useReducer } from 'react'
import {
  footprintReducer,
  initialFootprintState,
} from '../reducers/FootprintReducer'
import { answerReducer, initialAnswerState } from '../reducers/AnswersReducer'

export const CalculatorContext = createContext()
export const CalculatorContextProvider = ({ children }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answerState, dispatchAnswer] = useReducer(
    answerReducer,
    initialAnswerState
  )
  const [footprintState, dispatchFootprint] = useReducer(
    footprintReducer,
    initialFootprintState
  )
  const value = {
    currentQuestionIndex,
    setCurrentQuestionIndex,
    answerState,
    dispatchAnswer,
    footprintState,
    dispatchFootprint,
  }

  return (
    <CalculatorContext.Provider
      data-test="calulator-context-test"
      value={value}
    >
      {children}
    </CalculatorContext.Provider>
  )
}

CalculatorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CalculatorContextProvider
