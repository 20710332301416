// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// WeSpire
import ActionCompletionForm from './action_completion_form'
import { ActionInfo } from './action_info'
import { ActionStatus } from './action_status'
import { ActionTimeframeModel } from './action_timeframe_model'
import { CampaignModel } from './campaign_model'

const ActionBody = ({
  action,
  actionTimeframe,
  campaign,
  hasCustomTimeframe,
  isSubmitting,
  onActionSubmit,
  isSubmitDisabled,
}) => {
  const [cooldownEnded, setCooldownEnded] = useState(false)
  const {
    cooldownEndsAt,
    customFields,
    name,
    requirement,
    status,
    userCompleted,
  } = action

  useEffect(() => {
    if (cooldownEndsAt) {
      const localizedEndTime = localizeCooldownEndTime(cooldownEndsAt)
      const timeUntilCooldownEnd = localizedEndTime - new Date().getTime()
      const cooldownEnded = timeUntilCooldownEnd < 0
      const oneDayInMs = 24 * 60 * 60 * 1000
      // return if timeUntilCooldownEnd is greater than 1 day OR if the cooldown has ended
      if (timeUntilCooldownEnd > oneDayInMs || cooldownEnded) return

      setTimeout(() => {
        setCooldownEnded(true)
      }, timeUntilCooldownEnd)
    }
  }, [cooldownEndsAt])

  const localizeCooldownEndTime = (cooldownEndsAt) => {
    let localCooldownEndTime = new Date(cooldownEndsAt)
    localCooldownEndTime.setMinutes(
      localCooldownEndTime.getMinutes() +
        localCooldownEndTime.getTimezoneOffset()
    )
    return localCooldownEndTime
  }

  const actionStillOnCoolDown = status === 'COOLDOWN' && !cooldownEnded
  const oneTimeActionCompleted = status === 'FINISHED'

  const currentlyNotCompletable =
    oneTimeActionCompleted || actionStillOnCoolDown

  const canTakeActions = hasCustomTimeframe
    ? campaign.canTakeActions && actionTimeframe.running
    : campaign.canTakeActions

  if (!canTakeActions) {
    return (
      <ActionInfo
        actionName={name}
        actionTimeframe={actionTimeframe}
        campaign={campaign}
        hasCustomTimeframe={hasCustomTimeframe}
      />
    )
  } else if (currentlyNotCompletable) {
    return (
      <ActionStatus
        className="ml-auto"
        cooldownEndsAt={cooldownEndsAt}
        status={status}
        userCompleted={userCompleted}
      />
    )
  } else {
    return (
      <ActionCompletionForm
        customFields={customFields}
        isSubmitDisabled={isSubmitDisabled}
        isSubmitting={isSubmitting}
        onValidSubmit={onActionSubmit}
        requirement={requirement}
      />
    )
  }
}

ActionBody.propTypes = {
  action: PropTypes.shape({
    cooldownEndsAt: PropTypes.string,
    customFields: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    requirement: PropTypes.object.isRequired,
    status: PropTypes.oneOf([
      'AVAILABLE',
      'COOLDOWN',
      'FINISHED',
      'UNAVAILABLE',
    ]).isRequired,
    userCompleted: PropTypes.bool.isRequired,
  }).isRequired,
  actionTimeframe: PropTypes.instanceOf(ActionTimeframeModel).isRequired,
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  hasCustomTimeframe: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onActionSubmit: PropTypes.func.isRequired,
}

ActionBody.defaultProps = {
  isSubmitDisabled: false,
}

export { ActionBody }
