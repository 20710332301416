// Setup
import React from 'react'
import { defineMessages, FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'

//Vendor
import cx from 'classnames'

// WeSpire
import { AchievementImage } from 'components/achievement'
import { intl } from 'utilities/localization'
import ProgressBar from 'components/ui/progress_bar'
import { WordForScore } from 'components/queries/word_for_score'

const messages = defineMessages({
  completed: {
    defaultMessage:
      '{completedStringifiedCount} / {totalStringifiedCount} activities completed',
    description: 'Number of activities completed / total',
    id: 'activitySeriesProgressBarDetails.completed',
  },
})

export const ActivitySeriesProgressBarDetails = ({
  achievement,
  className,
  completionPoints,
  progressPercent,
  progressPointsForUser,
}) => {
  const { formatMessage } = intl

  return (
    <ProgressBar
      bottom={
        <span className="d-inline-block fs-1 fw-semi-bold pr-6 text-black-3 text-lowercase">
          {formatMessage(messages.completed, {
            completedStringifiedCount: (
              <FormattedNumber value={progressPointsForUser} />
            ),
            totalStringifiedCount: <FormattedNumber value={completionPoints} />,
            wordForScore: <WordForScore wordOnly />,
          })}
        </span>
      }
      center={
        achievement && (
          <div className="absolute-right w-100">
            <AchievementImage
              achievementName={achievement.name}
              className={cx(
                'absolute-top-right elevation-1 mr-2 translate-up-50',
                {
                  'op-80': !achievement.awardedTo,
                }
              )}
              data-test={`achievement-${
                achievement.awardedTo ? 'earned' : 'unearned'
              }`}
              effect={!achievement.awardedTo ? 'grayscale' : undefined}
              imageSrc={achievement.imageUrl}
            />
          </div>
        )
      }
      className={className}
      data-test="activity-progress-bar"
      progressPercent={progressPercent}
    />
  )
}

ActivitySeriesProgressBarDetails.propTypes = {
  achievement: PropTypes.any.isRequired,
  className: PropTypes.string,
  completionPoints: PropTypes.number.isRequired,
  progressPercent: PropTypes.number.isRequired,
  progressPointsForUser: PropTypes.number.isRequired,
}

ActivitySeriesProgressBarDetails.defaultProps = {
  className: '',
}
