import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

const NextButton = ({ handleNextQuestion, textString, isDisabled }) => (
  <Button
    className="mx-2 text-xs md:text-sm"
    color="primary"
    data-test="next-button-test"
    endIcon={<ArrowForwardIcon />}
    onClick={handleNextQuestion}
    variant="text"
    disabled={isDisabled}
  >
    {textString}
  </Button>
)

NextButton.propTypes = {
  handleNextQuestion: PropTypes.func.isRequired,
  textString: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
}

NextButton.defaultProps = {
  isDisabled: false,
}
export default NextButton
