// Setup
import React, { Fragment } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// Vendor
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

// WeSpire
import { abbreviatedDateOrRange } from 'utilities/date_formatter'
import { getActionUrlForActivity } from 'components/activities/utils'
import AvatarList from 'components/application/avatar_list'
import { Button } from 'components/ui/button'
import { Image } from 'components/ui/image'
import LinkButton from 'components/ui/link_button'
import ParticipantsSummary from 'components/application/participants_summary'
import ScreenReader from 'components/ui/screen_reader_text'
import { sharedTranslations } from 'components/shared/translations'
import { isNil } from 'utilities/is_nil'

const ActivitiesCard = ({
  activity,
  breadcrumbLink,
  condensed,
  fromChannelId,
  fromGroupId,
  onClick,
}) => {
  const isVisible = isNil(activity.visible) ? true : activity.visible

  const actionUrl = getActionUrlForActivity({
    actionUrl: activity.actionUrl,
  })

  return (
    <div
      className={cx('col-6 | mb-2 mb-sm-3 pl-0 pr-2 pr-sm-3', {
        'col-lg-4': !condensed,
      })}
      data-test="activity-card"
    >
      <Card className="[ card ] [ d-flex flex-column ] [ h-100 p-0 ] [ breakout ]">
        <div className="d-block position-relative">
          <div className="aspect-ratio aspect-ratio--16-9 | bg-black-2">
            <Image
              alt=""
              aspectRatio="16:9"
              className="absolute-top-left"
              responsive
              src={activity.imageUrl}
            />
          </div>
          <span className="absolute-top-left bg-black-3 fs-2 px-3 py-2 text-uppercase text-white">
            {sharedTranslations[activity.type]}
          </span>
        </div>
        <CardContent className="card__content | p-md-3">
          <span className="card__title">{activity.name}</span>
          <div className="card__label | m-0 mt-md-2" data-test="card-label">
            {abbreviatedDateOrRange(activity.startsAt, activity.endsAt)}
          </div>
        </CardContent>
        <CardActions className="[ align-items-center ] [ mt-auto | px-md-3 ]">
          <ParticipantsSummary
            avatarImages={activity.participants.map((user) => user.avatarUrl)}
            className="show-md-up"
            participantsCount={activity.participantsCount}
          />
          <AvatarList
            className="horizontal-list--squeezed-dense | hide-md-up"
            images={activity.participants.map((user) => user.avatarUrl)}
          />
          {isVisible ? (
            <LinkButton
              className="breakout__item ml-auto"
              data-test="link-button-to-activity"
              disableRipple
              onClick={onClick}
              to={{
                pathname: actionUrl,
                state: {
                  breadcrumbLink,
                  fromChannelId,
                  fromGroupId,
                },
              }}
              variant="text"
            >
              <LinkButtonChildren name={activity.name} type={activity.type} />
            </LinkButton>
          ) : (
            <Button
              className="breakout__item ml-auto"
              data-test="button-to-group-join"
              disableRipple
              onClick={onClick}
              variant="text"
            >
              <LinkButtonChildren name={activity.name} type={activity.type} />
            </Button>
          )}
        </CardActions>
      </Card>
    </div>
  )
}

const LinkButtonChildren = ({ type, name }) => (
  <>
    {sharedTranslations.view}
    <ScreenReader text={`${sharedTranslations[type]}: ${name}`} />
  </>
)

LinkButtonChildren.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

ActivitiesCard.propTypes = {
  activity: PropTypes.shape({
    actionUrl: PropTypes.string.isRequired,
    endsAt: PropTypes.string,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(
      PropTypes.shape({ avatarUrl: PropTypes.string.isRequired }).isRequired
    ).isRequired,
    participantsCount: PropTypes.number.isRequired,
    startsAt: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    visible: PropTypes.bool,
  }).isRequired,
  breadcrumbLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  condensed: PropTypes.bool,
  fromChannelId: PropTypes.string,
  fromGroupId: PropTypes.string,
  onClick: PropTypes.func,
}

ActivitiesCard.defaultProps = {
  breadcrumbLink: null,
  condensed: false,
  fromChannelId: null,
  fromGroupId: null,
  onClick: null,
}

export { getActionUrlForActivity, LinkButtonChildren }
export default ActivitiesCard
