import { numericDate } from 'utilities/date_formatter'
import { sortByAlpha } from 'utilities/sort'

// Converts a flat array of volunteer logs into an array
// with all logs for a given organization grouped together,
// with the sum of the volunteer hours and entries sorted by (date, eventName).
export const groupByOrganization = (volunteerLogs) => {
  let grouped = volunteerLogs.reduce((groups, volunteerLog) => {
    const organizationName = volunteerLog.organizationName
    if (!groups[organizationName]) {
      groups[organizationName] = {
        // The ein is expected to be the same for all logs, so it doesn't matter
        // which log we grab it from.
        ein: volunteerLog.ein,
        organizationName: organizationName,
        scheduledVolunteerHours: 0,
        totalPaidVolunteerHours: 0,
        totalVolunteerHours: 0,
        volunteerLogs: [],
      }
    }

    if (volunteerLog.isComplete) {
      groups[organizationName].totalPaidVolunteerHours +=
        volunteerLog.claimedPaidVolunteerHours
      groups[organizationName].totalVolunteerHours += volunteerLog.hours
    } else {
      groups[organizationName].scheduledVolunteerHours += volunteerLog.hours
    }

    const dateISOString = new Date(volunteerLog.date + 'T00:00').toISOString()

    groups[organizationName].volunteerLogs.push({
      claimedPaidVolunteerHours: volunteerLog.claimedPaidVolunteerHours,
      date: {
        dateTime: dateISOString,
        label: numericDate(dateISOString),
      },
      eventName: volunteerLog.eventName,
      eventUrl: volunteerLog.eventUrl,
      hours: volunteerLog.hours,
      id: volunteerLog.id,
      isComplete: volunteerLog.isComplete,
      isForPlatformEvent: volunteerLog.isForPlatformEvent,
    })
    return groups
  }, {})

  const groupsWithSortedLogs = Object.values(grouped).map((group) => {
    group.volunteerLogs = group.volunteerLogs.sort((a, b) => {
      if (new Date(a.date.label) < new Date(b.date.label)) return 1 // Date higher (future), move up
      if (new Date(a.date.label) > new Date(b.date.label)) return -1 // Date lower (past), move down

      // Sort equal event names the same.
      if (a.eventName === b.eventName) return 0

      // Move null event names to the bottom of the list.
      if (a.eventName === null) return 1
      if (b.eventName === null) return -1

      // Sort non-null event names in ascending order (a, b, c).
      if (a.eventName.toLowerCase() > b.eventName.toLowerCase()) return 1
      if (a.eventName.toLowerCase() < b.eventName.toLowerCase()) return -1
    })
    return group
  })

  const sortedGroups = sortByAlpha(groupsWithSortedLogs, 'organizationName')

  return sortedGroups
}
