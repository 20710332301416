// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Button from '@material-ui/core/Button'

// WeSpire
import SubmitButton from 'components/ui/submit_button'

const ChannelManagerListSortableActions = ({
  isSubmitting,
  onCancel,
  onSave,
}) => (
  <div>
    <Button disabled={isSubmitting} onClick={onCancel}>
      Cancel
    </Button>
    <SubmitButton
      className="ml-2"
      color="primary"
      isSubmitting={isSubmitting}
      onClick={onSave}
      variant="contained"
    >
      Save Layout
    </SubmitButton>
  </div>
)

ChannelManagerListSortableActions.propTypes = {
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

ChannelManagerListSortableActions.defaultProps = {
  isSubmitting: false,
}

export default ChannelManagerListSortableActions
