// Setup
import PropTypes from 'prop-types'
import React from 'react'

// Vendor
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import EventRegistrationOpportunities from 'components/events/registration_opportunities'
import WeQuery from 'components/application/we_query'

const registrationOpportunities = gql`
  query registrationOpportunities($channelId: ID!) {
    registrationOpportunities(channelId: $channelId) {
      availableSpotsCount
      endsAt
      eventName
      eventUrl
      id
      startsAt
    }
  }
`
const EventRegistrationOpportunitiesLoadable = ({ channelId }) => (
  <WeQuery
    loader={<CenteredPaddedLoadingIndicator />}
    query={registrationOpportunities}
    variables={{ channelId: channelId }}
  >
    {({ data }) => (
      <EventRegistrationOpportunities
        opportunities={data.registrationOpportunities}
      />
    )}
  </WeQuery>
)

EventRegistrationOpportunitiesLoadable.propTypes = {
  channelId: PropTypes.string.isRequired,
}

export default EventRegistrationOpportunitiesLoadable
