// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'

// WeSpire
import store, { history } from 'redux/store'
import { updateBannerAction } from 'redux/reducers/banner'
import { updateModalAction } from 'redux/reducers/modal'
import { intl } from 'utilities/localization'
import { updateUserTourAction } from 'redux/reducers/user_tour'
import { getCookie } from 'utilities/cookies'

// Hide our banner & modal if:
// 1. The actual pathname changed
//    (guards against just hash changes e.g. error summary links).
// 2. The banner or modal is open (prevents un-needed redux actions EVERY page change).
let prevPathname = window.location.pathname

history.listen((location) => {
  const { banner, modal } = store.getState()

  // Ensure sticky banner is always visible.
  if (banner.isSticky) {
    if (!banner.isOpen) {
      // If dismissed, re-open it.
      displayExceptionBanner()
    } else {
      // If already open, don't override it.
      return
    }
  }

  if (location.pathname !== prevPathname) {
    prevPathname = location.pathname

    if (banner.isOpen) {
      hideBanner()
    }
    if (modal.options.open) {
      hideModal()
    }
  }
})

// Displays the banner, see Banner.propTypes for list of available options.
export function displayBanner(options) {
  const { banner } = store.getState()

  // If sticky banner is already open, don't override it.
  if (banner.isSticky) return

  // The setTimeout here allows us to use displayBanner inside the render() method
  // of stateless components without getting a 'Cannot update during an existing
  // state transition (such as within `render`)' error.
  setTimeout(() => {
    store.dispatch({
      data: { options: { isOpen: true, ...options } },
      type: updateBannerAction,
    })
  }, 0)
}

const exceptionMessages = defineMessages({
  genericProblem: {
    defaultMessage: 'We encountered a problem.',
    description: 'A generic error message when something fails to load.',
    id: 'dispatchers.genericProblem',
  },
  operationError: {
    defaultMessage: 'We encountered a problem and could not {operation}.',
    description:
      'Error message when an operation fails, e.g. "We encountered a problem and could not submit the form."',
    id: 'dispatchers.operationError',
  },
  sessionTimeOutMessage: {
    defaultMessage:
      'You have been logged out after {count, number} {count, plural, zero {minutes} one {minute} two {minutes} few {minutes} many {minutes} other {minutes}} of inactivity. <link>Sign in to continue participating.</link>',
    id: 'dispatchers.sessionTimeOutMessage',
  },
})

// Displays an "error" variant banner with an explanation appended describing
// the operation being performed when the error occurred.
// e.g. 'We encountered a problem and could not save this Event.'
export function displayExceptionBanner(options = {}) {
  const { operation } = options
  let content = intl.formatMessage(exceptionMessages.genericProblem)
  let sticky = false

  if (operation) {
    content = intl.formatMessage(exceptionMessages.operationError, {
      operation,
    })
  }

  if (window.sessionExpired) {
    sticky = true
    content = intl.formatMessage(exceptionMessages.sessionTimeOutMessage, {
      count: getCookie('session_length_minutes'),
      // Ensure link generates full page refresh (not client-side nav) so the server
      // can decide where to send the un-authenticated user and then redirect them
      // back to the current URL.
      link: (str) => (
        <a
          className="text-link text-link--black-1"
          href={location.pathname}
          key="sessionTimeOutMessage"
        >
          {str}
        </a>
      ),
    })
  }

  displayBanner({ ...options, content, sticky, variant: 'error' })
}

export function hideBanner() {
  // We pass in the current options as well so that things like
  // variant and content do not change while we collapse our banner.
  const options = store.getState().banner.options
  store.dispatch({
    data: { options: { ...options, isOpen: false } },
    type: updateBannerAction,
  })
}

// Displays the modal, see Modal.propTypes for list of available options.
export function displayModal(data) {
  // The setTimeout here allows us to use displayModal inside the render() method
  // of stateless components without getting a 'Cannot update during an existing
  // state transition (such as within `render`)' error.
  setTimeout(() => {
    store.dispatch({
      data: {
        ...data,
        options: { ...data.options, open: true },
      },
      type: updateModalAction,
    })
  }, 0)
}

// This forces the modal to call the renderContent function again.
export function refreshModal() {
  const data = store.getState().modal
  data.refreshCounter++
  store.dispatch({
    data: { ...data },
    type: updateModalAction,
  })
}

export function hideModal() {
  const data = store.getState().modal
  store.dispatch({
    data: { ...data, options: { ...data.options, open: false } },
    type: updateModalAction,
  })
}

export function maybeDisplayUserTour(messageKey) {
  // The setTimeout here allows us to use displayUserTour inside the render() method
  // of stateless components without getting a 'Cannot update during an existing
  // state transition (such as within `render`)' error.
  setTimeout(() => {
    store.dispatch({
      data: { maybeOpen: true, messageKey },
      type: updateUserTourAction,
    })
  }, 0)
}

export function displayUserTour() {
  // The setTimeout here allows us to use displayUserTour inside the render() method
  // of stateless components without getting a 'Cannot update during an existing
  // state transition (such as within `render`)' error.
  setTimeout(() => {
    store.dispatch({
      data: { forceOpen: true },
      type: updateUserTourAction,
    })
  }, 0)
}

export function hideUserTour() {
  const data = store.getState().userTour
  store.dispatch({
    data: { ...data, forceOpen: false, maybeOpen: false },
    type: updateUserTourAction,
  })
}
