// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import EventCategoryForm from 'setup/event_categories/event_category_form'
import MgmtPanelFormPage from 'setup/application/form_page'

/**
 * The edit page containing the form for editing an event category.
 */
const EventCategoriesEditPage = ({ eventCategory }) => (
  <MgmtPanelFormPage isEdit resourceName="Volunteer Event Category">
    {() => <EventCategoryForm eventCategory={eventCategory} isEdit />}
  </MgmtPanelFormPage>
)

EventCategoriesEditPage.propTypes = {
  eventCategory: PropTypes.object.isRequired,
}

export default EventCategoriesEditPage
