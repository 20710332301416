// Vendor
import React from 'react'
import { Dialog, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'

// Wespire
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { previewModal as messages } from './utilities/messages'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import WysiwygContent from 'components/ui/wysiwyg_content'

const { formatMessage } = intl

const PreviewModal = ({ isOpen, showModal, userText }) => {
  const closeModal = () => {
    showModal(false)
  }

  return (
    <Dialog
      classes={{ paper: 'p-3 | p-md-4' }}
      fullWidth
      maxWidth="sm"
      onClose={() => closeModal()}
      open={isOpen}
    >
      <Stack divider="default" space={3}>
        <div className="[ d-flex align-items-center justify-content-between ] [ pl-3 ]">
          <Heading data-test="about-group" level={1} variant={2}>
            {formatMessage(messages.previewOfAnnouncement)}
          </Heading>
          <IconButton data-test="close-modal" onClick={closeModal}>
            <Icon
              className="fs-4 text-black-2"
              iconName="close"
              title={sharedTranslations.cancel}
            />
          </IconButton>
        </div>
        <WysiwygContent className="fs-2 m-0 mt-2" data-test="announcement-text">
          {userText}
        </WysiwygContent>
      </Stack>
    </Dialog>
  )
}

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  userText: PropTypes.string,
}

PreviewModal.defaultProps = {
  userText: '',
}

export { PreviewModal }
