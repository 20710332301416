// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { parseUrl, stringify } from 'query-string'

// Vendor
import { defineMessages } from 'react-intl'
import cx from 'classnames'
import { Paper } from '@material-ui/core'
import camelCase from 'lodash/camelCase'

// WeSpire
import { client } from 'utilities/we_apollo'
import ExternalLink from 'components/ui/external_link'
import Icon from 'components/ui/icon'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import { LOG_VALIDIC_DEVICE_LINKING_FLOW } from 'graphql/mutations'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'
import { useBrandConfig } from 'utilities/hooks/useBrandConfig'

const { formatMessage } = intl

const messages = defineMessages({
  connected: {
    defaultMessage: 'Connected',
    id: 'connectionInfo.connected',
  },
  connectionHelp: {
    defaultMessage: 'Help',
    id: 'connectionInfo.connectionHelp',
  },
  connectNow: {
    defaultMessage: 'Connect Now',
    id: 'connectionInfo.connectNow',
  },
  disconnect: {
    defaultMessage: 'Disconnect',
    id: 'connectionInfo.disconnect',
  },
})

const ConnectionInfo = ({
  connected,
  connectMessageDisclaimer,
  connectUrl,
  disconnectUrl,
  displayName,
  enabled,
  returnUrl,
  logoUrl,
  hasOtherConnectedDevices,
  isCustomDevice,
  noDeviceMessage,
  cloudName,
  infoLink,
  type,
}) => {
  const { url, query } = parseUrl(returnUrl)
  const connectLink = `${connectUrl}&redirect_uri=${url}?${encodeURIComponent(
    stringify({
      ...query,
      connection_result: 'connect',
      device: displayName,
    })
  )}`

  const disconnectLink = `${disconnectUrl}&redirect_uri=${url}?${encodeURIComponent(
    stringify({
      ...query,
      connection_result: 'disconnect',
      device: displayName,
    })
  )}`

  const getMutation = (query, variables) => ({
    awaitRefetchQueries: true,
    mutation: query,
    variables,
  })
  const handleConnections = (link, flow) => {
    const logData = {
      attempt: true,
      device: displayName,
      flow,
      link,
    }
    client.mutate(getMutation(LOG_VALIDIC_DEVICE_LINKING_FLOW, logData))
  }

  /*
    enabled is an optional prop provided by the backend that can sometimes be
    used instead of requesting a live value of the brand config.
  */
  if (enabled === null) {
    const { isConfigEnabled } = useBrandConfig({
      feature: camelCase(`health_data_integrations_${type}`),
    })

    if (!isConfigEnabled) {
      return null
    }
  } else if (!enabled) {
    return null
  }

  return (
    <Paper
      className="p-3 border | px-sm-4"
      component="li"
      data-test="connection-info"
    >
      <div className="d-flex">
        <div className="[ col ] [ border text-center ]">
          <Image
            alt=""
            cloudName={cloudName || null}
            crop="fit"
            height="120"
            src={`https:${logoUrl}`}
            width="120"
          />
        </div>
        <Stack
          className={cx(
            'col d-flex flex-column align-items-center justify-content-center'
          )}
          space={4}
        >
          <span className="fw-semi-bold ls-2 text-black-3">{displayName}</span>
          {isCustomDevice ? (
            <>
              {(connectUrl || disconnectUrl) && (
                <>
                  {connected ? (
                    <>
                      <span className="d-flex bg-green-2 rounded-3 px-3 py-2">
                        <Icon
                          className="fs-4 mx-1 text-green"
                          iconName="check"
                          title={formatMessage(messages.connected)}
                        />
                        <span
                          className="fs-2 fw-semi-bold mx-1 text-green"
                          data-test="connection-status"
                        >
                          {formatMessage(messages.connected)}
                        </span>
                      </span>
                      <TextLink
                        className="fs-2"
                        data-test="action-link"
                        onClick={() =>
                          handleConnections(disconnectUrl, 'disconnect')
                        }
                        to={disconnectUrl}
                      >
                        {formatMessage(messages.disconnect)}
                      </TextLink>
                    </>
                  ) : (
                    <>
                      {connectMessageDisclaimer && (
                        <span
                          className="text-center fs-3 lh-sm"
                          data-test="ios-apple-steps"
                        >
                          {connectMessageDisclaimer}
                        </span>
                      )}
                      {!hasOtherConnectedDevices && (
                        <TextLink
                          className="fs-2"
                          data-test="action-link"
                          onClick={() =>
                            handleConnections(connectUrl, 'connect')
                          }
                          to={connectUrl}
                        >
                          {formatMessage(messages.connectNow)}
                        </TextLink>
                      )}
                    </>
                  )}
                </>
              )}
              {(!connectUrl || !disconnectUrl) && (
                <p className="px-3 text-center" data-test="no-device-message">
                  {noDeviceMessage}
                </p>
              )}
            </>
          ) : (
            <>
              {connected ? (
                <>
                  <span className="d-flex bg-green-2 rounded-3 px-3 py-2">
                    <Icon
                      className="fs-4 mx-1 text-green"
                      iconName="check"
                      title={formatMessage(messages.connected)}
                    />
                    <span
                      className="fs-2 fw-semi-bold mx-1 text-green"
                      data-test="connection-status"
                    >
                      {formatMessage(messages.connected)}
                    </span>
                  </span>
                  <TextLink
                    className="fs-2"
                    data-test="action-link"
                    onClick={() =>
                      handleConnections(disconnectLink, 'disconnect')
                    }
                    to={disconnectLink}
                  >
                    {formatMessage(messages.disconnect)}
                  </TextLink>
                </>
              ) : (
                <>
                  {!hasOtherConnectedDevices && (
                    <TextLink
                      className="fs-2"
                      data-test="action-link"
                      onClick={() => handleConnections(connectLink, 'connect')}
                      to={connectLink}
                    >
                      {formatMessage(messages.connectNow)}
                    </TextLink>
                  )}
                </>
              )}
            </>
          )}
          {!isCustomDevice && !connected && infoLink && (
            <ExternalLink
              className="fs-2"
              hideIcon
              text={formatMessage(messages.connectionHelp)}
              to={infoLink}
            />
          )}
        </Stack>
      </div>
    </Paper>
  )
}

ConnectionInfo.propTypes = {
  cloudName: PropTypes.string,
  connected: PropTypes.bool.isRequired,
  connectMessageDisclaimer: PropTypes.node,
  connectUrl: PropTypes.string,
  disconnectUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  hasOtherConnectedDevices: PropTypes.bool,
  infoLink: PropTypes.string,
  isCustomDevice: PropTypes.bool,
  logoUrl: PropTypes.string.isRequired,
  noDeviceMessage: PropTypes.string,
  returnUrl: PropTypes.string,
  type: PropTypes.string.isRequired,
}

ConnectionInfo.defaultProps = {
  cloudName: null,
  connectMessageDisclaimer: null,
  connectUrl: null,
  disconnectUrl: null,
  enabled: null,
  hasOtherConnectedDevices: false,
  infoLink: null,
  isCustomDevice: false,
  noDeviceMessage: null,
  returnUrl: window.location.href.split('?')[0],
}

export default ConnectionInfo
