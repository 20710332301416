// Setup
import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import MgmtPanelFormPage from 'setup/application/form_page'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { newGroupRoute } from './routes'
import { GroupsList } from './groups_list'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import useUpdatePosition from 'utilities/hooks/use_update_position'
import WeApollo from 'utilities/we_apollo'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl

const messages = defineMessages({
  groupsHeading: {
    defaultMessage: '{brandName} Groups',
    id: 'groups.groupsHeading',
  },
  groupsTitle: {
    defaultMessage: 'Ordered Groups',
    id: 'groups.groupsTitle',
  },
  newGroup: {
    defaultMessage: 'New Group',
    id: 'groups.newGroup',
  },
  orderingDescription: {
    defaultMessage:
      'This is the list of groups in the order that they will appear on employee-facing Group pages. You can change the order of the list using the ordering buttons on each group.',
    id: 'groups.orderingDescription',
  },
})

const groupsListQuery = gql`
  query groupsListQuery($groupFilter: String, $objectId: ID!) {
    currentUser {
      can {
        manageGroups
      }
    }
    groups(forManagementPanel: true, groupFilter: $groupFilter) {
      id
      name
      position
      slug
    }
    brand(id: $objectId) {
      id
      name
    }
  }
`

const GroupAdministration = ({ objectId }) => {
  const [ariaLiveText, executeMutation] = useUpdatePosition()

  const handleUpdatePosition = ({ operator, recordId, recordName }) => {
    const refetchQueries = [
      {
        query: groupsListQuery,
        variables: { groupFilter: 'all', objectId },
      },
    ]
    const variables = {
      klass: 'Group',
      operator,
      recordId,
    }

    executeMutation(recordName, refetchQueries, variables)
  }

  return (
    <WeApollo>
      <WeQuery
        error={<MissingResourcePage />}
        loader={
          <Stack space={4}>
            <SkeletonLoading height={75} />
            <SkeletonLoading className="px-4" height={300} />
          </Stack>
        }
        query={groupsListQuery}
        variables={{ groupFilter: 'all', objectId }}
      >
        {({
          data: {
            currentUser: { can },
            brand: { name },
            groups,
          },
        }) => {
          return (
            <MgmtPanelFormPage
              contentHeaderChildren={
                can.manageGroups && (
                  <Button
                    className="flex-shrink-none ml-2"
                    color="primary"
                    data-test="create-group"
                    href={newGroupRoute()}
                    variant="contained"
                  >
                    {formatMessage(messages.newGroup)}
                  </Button>
                )
              }
              data-test="group-index-heading"
              isEdit
              resourceName={formatMessage(messages.groupsHeading, {
                brandName: name,
              })}
            >
              {() => (
                <Stack space={3}>
                  <Heading level={2} variant={2}>
                    {formatMessage(messages.groupsTitle)}
                  </Heading>
                  <p>{formatMessage(messages.orderingDescription)}</p>
                  <GroupsList
                    ariaLiveText={ariaLiveText}
                    groups={groups}
                    onUpdatePositionClick={handleUpdatePosition}
                  />
                </Stack>
              )}
            </MgmtPanelFormPage>
          )
        }}
      </WeQuery>
    </WeApollo>
  )
}

GroupAdministration.propTypes = {
  objectId: PropTypes.string.isRequired,
}

export default GroupAdministration
