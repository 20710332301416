// Setup
import React from 'react'

// WeSpire
import { Button } from 'components/ui/Form/Button'

export default {
  argTypes: {
    as: { control: { type: 'text' } },
    children: {
      control: { type: 'text' },
      defaultValue: 'nice button',
    },
  },
  component: Button,
  title: 'UI/Form/Button',
}

const Template = (args) => <Button {...args} />

export const Primary = Template.bind({})
Primary.args = {
  variant: 'primary',
}

Primary.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/file/MZejAYYRc0hOCRhuKJSEug/Logos?node-id=1%3A2',
  },
}

export const Secondary = Template.bind({})
Secondary.args = {
  variant: 'secondary',
}

Secondary.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/file/MZejAYYRc0hOCRhuKJSEug/Logos?node-id=1%3A2',
  },
}

export const Neutral = Template.bind({})
Neutral.args = {
  variant: 'neutral',
}

Neutral.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/file/MZejAYYRc0hOCRhuKJSEug/Logos?node-id=1%3A2',
  },
}

export const Text = Template.bind({})
Text.args = {
  variant: 'text',
}

Text.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/file/MZejAYYRc0hOCRhuKJSEug/Logos?node-id=1%3A2',
  },
}
