// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Dialog from '@material-ui/core/Dialog'
import { defineMessages } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'

// WeSpire
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import WysiwygContent from 'components/ui/wysiwyg_content'

// Sub-Components
import { AchievementImage } from './image'

/**
 * Modal intended to display when the current user earns an Achievement. Since
 * it's only meant to be shown once, simply render the component to open it. It
 * also manages its own closed state.
 */
const AchievementModal = ({ description, id, imageUrl, name }) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClose = () => setIsOpen(false)

  const { formatMessage } = intl

  const messages = defineMessages({
    closeHelpText: {
      defaultMessage: 'Close Help Dialog',
      description: 'Helper text diplayed on hover of the close button',
      id: 'achievementModal.closeHelpText',
    },
    fallbackDescription: {
      defaultMessage: 'Congratulations on earning this Achievement!',
      description:
        'The fallback description displayed on the modal if a description is not passed to the component.',
      id: 'achievementModal.fallbackDescription',
    },
    header: {
      defaultMessage: 'Achievement Earned',
      description: 'Header displayed on the modal',
      id: 'achievementModal.header',
    },
  })

  return (
    <Dialog
      aria-labelledby={id}
      classes={{ paper: 'p-3 | p-md-4' }}
      data-test="achievement-modal"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={isOpen}
    >
      <Stack divider="default" space={3}>
        <div className="[ d-flex align-items-center justify-content-between ] [ pl-3 ]">
          <Heading id={id} level={1} variant={2}>
            {formatMessage(messages.header)}
          </Heading>
          <IconButton data-test="close-modal" onClick={handleClose}>
            <Icon
              className="fs-4 text-black-2"
              iconName="close"
              title={formatMessage(messages.closeHelpText)}
            />
          </IconButton>
        </div>
        <div className="[ d-flex flex-column align-items-center | flex-md-row align-items-md-start ] [ px-3 text-center | text-md-left ]">
          <AchievementImage
            achievementName={name}
            className="elevation-1 mb-3 | mr-md-5"
            height="96"
            imageSrc={imageUrl}
            width="96"
          />
          <Stack space={2}>
            <span className="fs-4 fw-semi-bold lh-md | fs-md-5 ">{name}</span>
            <WysiwygContent className="fs-3">
              {description || formatMessage(messages.fallbackDescription)}
            </WysiwygContent>
          </Stack>
        </div>
      </Stack>
    </Dialog>
  )
}

AchievementModal.propTypes = {
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
}

AchievementModal.defaultProps = {
  description: null,
  imageUrl: null,
}

export { AchievementModal }
