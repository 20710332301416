// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Paper } from '@material-ui/core'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import { WordForScore } from 'components/queries/word_for_score'
import Stack from 'components/ui/stack'
import { FormattedNumber } from 'react-intl'
import ScreenReader from 'components/ui/screen_reader_text'
import { leaderBoardTranslations } from '../utils/shared_translations'
import { TextLink } from 'components/shared/text_link'

const LeaderCard = ({
  avatarUrl,
  className,
  forTeams,
  highlightAsYou,
  name,
  profilePath,
  score,
  rank,
}) => (
  <li
    className={cx('[ col-4 d-flex mxw-3 | col-md ]', className)}
    data-test="leader-card"
  >
    <Stack
      as={Paper}
      className={cx(
        '[ d-inline-flex flex-column justify-content-between ] [ p-3 text-center w-100 ]',
        {
          'bg-blue-2': highlightAsYou,
        }
      )}
      space={3}
      square
    >
      <Stack space={3}>
        <Avatar
          alt={`${name.shortName}'s profile photo`}
          className="border border-orange border-width-2 rounded-circle"
          image={avatarUrl}
          size={AvatarSizes.xlarge}
        />
        <div>
          <b
            className={cx('fs-4 lh-md', {
              'text-black-1': highlightAsYou,
              'text-orange': !highlightAsYou,
            })}
            data-test="competitor-rank"
          >
            <FormattedNumber value={rank} />
          </b>
          <div className="fs-1 fw-semi-bold">
            <TextLink
              className="lh-md d-flex flex-column align-items-center"
              data-test="competitor-name"
              to={profilePath}
            >
              {!forTeams && (
                <span className="d-inline-fllex">{name.firstName}</span>
              )}
              {!forTeams && (
                <span className="d-inline-fllex">{name.lastName}</span>
              )}
              {forTeams && name.shortName}
            </TextLink>
            {highlightAsYou && forTeams && (
              <div
                className="mt-2 lh-sm text-black-2"
                data-test="competitor-your-team"
              >
                {leaderBoardTranslations.yourTeam}
              </div>
            )}
            {highlightAsYou && !forTeams && (
              <ScreenReader text={` (${leaderBoardTranslations.yourRank})`} />
            )}
          </div>
        </div>
      </Stack>
      <div className="fs-1" data-test="competitor-points">
        <WordForScore score={score} />
      </div>
    </Stack>
  </li>
)

LeaderCard.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  forTeams: PropTypes.bool,
  highlightAsYou: PropTypes.bool,
  name: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
  }).isRequired,
  profilePath: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
}

LeaderCard.defaultProps = {
  className: null,
  forTeams: false,
  highlightAsYou: false,
}

export { LeaderCard }
