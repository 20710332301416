// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { GroupResourcesPanel } from './panel'

export const GroupResourcesPanels = ({ groupId, groupName }) => {
  return (
    <>
      <GroupResourcesPanel
        data-test="active-resources-panel"
        groupId={groupId}
        groupName={groupName}
        type="active"
      />

      <GroupResourcesPanel
        data-test="archived-resources-panel"
        groupId={groupId}
        groupName={groupName}
        type="archived"
      />
    </>
  )
}

GroupResourcesPanels.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
}
