// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Formsy from 'formsy-react'
import gql from 'graphql-tag'
import { useQueryParam, StringParam } from 'use-query-params'
import v from 'voca'

// WeSpire
import { channelOptions } from './utils'
import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'
import FormActionBar from 'components/form/action_bar'
import FormCheckbox from 'components/form/checkbox'
import { FormDetail, FormDetails } from 'components/form/form_details'
import FormErrorSummary from 'components/form/error_summary'
import FormFieldset from 'components/form/fieldset'
import FormSelect from 'components/form/select'
import FormTextField from 'components/form/text_field'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { CONTENT_MODULES } from 'utilities/content_module'
import { intl } from 'utilities/localization'
import { contentModuleMessages, messages } from './messages'

const { formatMessage } = intl

// Sub-Components
import { contentModulesPath } from './routes'

const UPDATE_CONTENT_MODULE = gql`
  mutation updateContentModule(
    $channelId: ID
    $contentModuleId: ID!
    $whyItMatters: String
  ) {
    updateContentModule(
      channelId: $channelId
      contentModuleId: $contentModuleId
      whyItMatters: $whyItMatters
    ) {
      errors
    }
  }
`

const UPDATE_SUSTAINABILITY_CONTENT_MODULE = gql`
  mutation updateSustainabilityContentModule(
    $channelId: ID
    $emissions: String!
    $energy: String!
    $fuel: String!
    $trees: String!
    $waste: String!
    $water: String!
  ) {
    updateSustainabilityContentModule(
      channelId: $channelId
      emissions: $emissions
      energy: $energy
      fuel: $fuel
      trees: $trees
      waste: $waste
      water: $water
    ) {
      errors
    }
  }
`

const UPDATE_CARBON_IMPACT_CONTENT_MODULE = gql`
  mutation updateCarbonImpactContentModule(
    $carbonReductionMarketplaceEnabled: Boolean!
    $channelId: ID
    $contentModuleId: ID!
    $whyItMatters: String
  ) {
    updateCarbonReductionMarketplaceToggle(
      carbonReductionMarketplaceEnabled: $carbonReductionMarketplaceEnabled
      contentModuleId: $contentModuleId
    ) {
      errors
    }

    updateContentModule(
      channelId: $channelId
      contentModuleId: $contentModuleId
      whyItMatters: $whyItMatters
    ) {
      errors
    }
  }
`

function getContentModuelHelperText(contentModule) {
  switch (contentModule) {
    case CONTENT_MODULES.CARBON_IMPACT:
      return messages.carbonImpactHelperText
    default:
      return messages.whyItMattersHelperText
  }
}

const ContentModuleForm = ({
  carbonImpactMarketplaceToggle,
  contentModule: { id: contentModuleId, name: moduleName, type },
  channelList,
  channelId,
  hasWhyItMatters,
  skipAssociatedChannel,
  sustainabilityImpactSettings,
  whyItMatters,
  whyItMattersIsAUrl,
  whyItMattersIsOptional,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reditectTo] = useQueryParam('redirect_to', StringParam)

  const handleSubmit = ({
    carbonReductionMarketplaceEnabled,
    channel: channelId,
    emissions,
    energy,
    fuel,
    trees,
    waste,
    water,
    whyItMatters,
  }) => {
    hideBanner()
    setIsSubmitting(true)

    let dataKey
    let mutation
    let variables = { channelId }

    if (moduleName === 'Sustainability') {
      dataKey = 'updateSustainabilityContentModule'
      mutation = UPDATE_SUSTAINABILITY_CONTENT_MODULE
      variables = { emissions, energy, fuel, trees, waste, water, ...variables }
    } else {
      if (moduleName === 'Carbon Impact') {
        mutation = UPDATE_CARBON_IMPACT_CONTENT_MODULE
        variables = {
          carbonReductionMarketplaceEnabled,
          contentModuleId,
          whyItMatters,
          ...variables,
        }
      } else {
        mutation = UPDATE_CONTENT_MODULE
        variables = {
          contentModuleId,
          whyItMatters,
          ...variables,
        }
      }

      dataKey = 'updateContentModule'
    }

    client
      .mutate({
        mutation: mutation,
        variables: variables,
      })
      .then(
        ({
          data: {
            [dataKey]: { errors },
          },
        }) => {
          setIsSubmitting(false)

          if (!errors) {
            displayBanner({
              content: contentModuleMessages.moduleSaved,
              variant: 'success',
            })
          } else {
            displayBanner({
              as: 'div',
              content: <FormErrorSummary errors={errors} />,
              fullWidth: true,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)

        displayExceptionBanner({
          fullWidth: true,
          operation: 'update Module',
        })
      })
  }

  const urlValidator = whyItMattersIsAUrl
    ? {
        validationError: contentModuleMessages.invalidURL,
        validations: 'isUrl',
      }
    : {}

  return (
    <Formsy noValidate onValidSubmit={handleSubmit}>
      <Stack space={5}>
        <FormFieldset
          hint={contentModuleMessages.settingsHint}
          label={sharedTranslations.settings}
        >
          <FormDetails>
            <FormDetail label={sharedTranslations.module}>
              {moduleName}
            </FormDetail>
          </FormDetails>

          {skipAssociatedChannel ? null : (
            <FormSelect
              name="channel"
              options={channelOptions(channelList, moduleName)}
              textFieldProps={{
                helperText: contentModuleMessages.channelHelperText,
                label: contentModuleMessages.channelLabel,
              }}
              value={channelId}
            />
          )}

          {hasWhyItMatters && (
            <FormTextField
              name="whyItMatters"
              required={!whyItMattersIsOptional}
              textFieldProps={{
                helperText: formatMessage(getContentModuelHelperText(type), {
                  moduleName,
                }),
                label: contentModuleMessages.whyItMattersLabel,
                multiline: true,
              }}
              value={whyItMatters}
              {...urlValidator}
            />
          )}

          {carbonImpactMarketplaceToggle && (
            <FormCheckbox
              helperText={
                contentModuleMessages.carbonReductionMarketplaceToggleHelperText
              }
              labelProps={{
                label:
                  contentModuleMessages.carbonReductionMarketplaceToggleLabel,
              }}
              name={carbonImpactMarketplaceToggle.name}
              value={carbonImpactMarketplaceToggle.value}
            />
          )}

          {sustainabilityImpactSettings && (
            <FormFieldset
              hint={contentModuleMessages.sustainabilityImpactHint}
              label={contentModuleMessages.whyItMattersLabel}
            >
              {sustainabilityImpactSettings.map(({ name, whyItMatters }) => (
                <FormTextField
                  key={name}
                  name={name}
                  required
                  textFieldProps={{
                    helperText: formatMessage(
                      messages.sustainabilityImpactHelperText,
                      {
                        name: v.capitalize(name),
                      }
                    ),
                    label: v.capitalize(name),
                    multiline: true,
                  }}
                  value={whyItMatters}
                />
              ))}
            </FormFieldset>
          )}
        </FormFieldset>

        <FormActionBar
          backPath={reditectTo || contentModulesPath}
          isSubmitting={isSubmitting}
        />
      </Stack>
    </Formsy>
  )
}

ContentModuleForm.propTypes = {
  carbonImpactMarketplaceToggle: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }),
  channelId: PropTypes.string,
  channelList: PropTypes.array.isRequired,
  contentModule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  hasWhyItMatters: PropTypes.bool,
  skipAssociatedChannel: PropTypes.bool,
  sustainabilityImpactSettings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf([
        'emissions',
        'energy',
        'fuel',
        'trees',
        'waste',
        'water',
      ]).isRequired,
      whyItMatters: PropTypes.string,
    }).isRequired
  ),
  whyItMatters: PropTypes.string,
  whyItMattersIsAUrl: PropTypes.bool,
  whyItMattersIsOptional: PropTypes.bool,
}

ContentModuleForm.defaultProps = {
  carbonImpactMarketplaceToggle: null,
  channelId: null,
  contentModule: {},
  hasWhyItMatters: false,
  skipAssociatedChannel: false,
  sustainabilityImpactSettings: null,
  whyItMatters: null,
  whyItMattersIsAUrl: false,
  whyItMattersIsOptional: false,
}

export { ContentModuleForm }
