// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { sharedTranslations } from 'components/shared/translations'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { volunteerHistoryLogMessages as messages } from '../utilities/messages'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'

/**
 * Renders a single entry (log) from a user's volunteer history.
 */
export const VolunteerHistoryLog = ({
  claimedPaidVolunteerHours,
  date,
  hours,
  ein,
  enablePaidVolunteerHours,
  eventName,
  eventUrl,
  isComplete,
  isForPlatformEvent,
  logId,
  ...other
}) => {
  const { formatMessage } = intl
  const { enableEditDestroyVolunteerLogs } = useFlags()

  return (
    <div
      {...other}
      className={cx('fw-semi-bold lh-md', other.className)}
      data-test="volunteer-history-log"
    >
      <dl className="[ row flex-column | flex-sm-row ] [ fs-1 my-0 ]">
        <div className="[ d-flex col-sm-4 col-md-3 col-lg-2 ] [ mt-2 mt-sm-0 ]">
          <dt className="ls-2 text-black-3 text-uppercase">
            {sharedTranslations.date}
            {': '}
          </dt>
          <dd className="ml-1">
            <time dateTime={date.dateTime}>{date.label}</time>
          </dd>
        </div>

        <div
          className="[ d-flex | col-sm-4 col-md-2 ] [ mt-2 | mt-sm-0 ]"
          data-test="volunteer-hours"
        >
          <dt className="ls-2 text-black-3 text-uppercase">
            {sharedTranslations.hours}
            {': '}
          </dt>
          <dd className="ml-1">
            <FormattedNumber value={hours} />
          </dd>
        </div>
        {enablePaidVolunteerHours && (
          <div className="[ d-flex | col-sm-4 col-md-2 ] [ mt-2 | mt-sm-0 ]">
            <dt className="ls-2 text-black-3 text-uppercase">
              {formatMessage(messages.paidVolunteerHoursInitials)}
              {': '}
            </dt>
            <dd
              className="ml-1 text-sentence"
              data-test="claimed-volunteer-hours"
            >
              {!ein ? (
                sharedTranslations.notEligible
              ) : claimedPaidVolunteerHours === null ? (
                sharedTranslations.notApplicableAbbreviation
              ) : (
                <FormattedNumber value={claimedPaidVolunteerHours} />
              )}
            </dd>
          </div>
        )}
        {isForPlatformEvent && (
          <div className="[ d-flex col-md ] [ mt-2 mt-sm-0 ]">
            <dt className="ls-2 text-black-3 text-uppercase">
              {sharedTranslations.event}
              {': '}
            </dt>
            <dd className="ml-1">
              <Link className="text-link" to={eventUrl}>
                {eventName}
              </Link>
            </dd>
          </div>
        )}
      </dl>
      <dl className="[ row flex-column | flex-sm-row ] [ fs-1 my-0 ]">
        <div className="[ d-flex | col-sm-4 col-md-3 col-lg-2 ] [ mt-2 | mt-sm-0 ]">
          <p className="fs-1 mt-2 my-0 text-black-3">
            <span
              className={cx('d-inline-block mr-1 p-1 rounded-circle', {
                'bg-green-1': isComplete,
                'bg-orange-1': !isComplete,
              })}
            />
            {isComplete
              ? sharedTranslations.completed
              : formatMessage(messages.scheduledToVolunteer)}
          </p>
        </div>
        {!isForPlatformEvent && enableEditDestroyVolunteerLogs && (
          <div className="[ d-flex | col-sm-4 col-md-3 col-lg-2 ] [ mt-2 | mt-sm-0 ]">
            <dt>
              {claimedPaidVolunteerHours ? (
                <TextWithHelpIconModal
                  className="mt-2"
                  data-test="cannot-edit-volunteer-log-modal-button"
                  modalContent={
                    <p data-test="cannot-edit-volunteer-log-content">
                      {formatMessage(messages.cannotEdit, {
                        contactSupport: (
                          <a
                            className="text-sentence"
                            href="mailto:support@wespire.com?subject=Cannot%20Edit%20PVH"
                          >
                            {sharedTranslations.contactSupport}
                          </a>
                        ),
                      })}
                    </p>
                  }
                  size="small"
                  text={
                    <p
                      className="fs-1 w-50 m-0 p-0  text-black-4 "
                      data-test="cannot-edit-volunteering-log"
                      to={`/users/me/volunteer_hours_form/${+logId}`}
                    >
                      {formatMessage(messages.edit)}
                    </p>
                  }
                  title={formatMessage(messages.cannotEditTitle)}
                />
              ) : (
                <Link
                  className="fs-1 mt-2 text-black-1 text-underline"
                  data-test="volunteering-log-edit"
                  to={`/users/me/volunteer_hours_form/${+logId}`}
                >
                  {formatMessage(messages.edit)}
                </Link>
              )}
            </dt>
          </div>
        )}
      </dl>
    </div>
  )
}

VolunteerHistoryLog.propTypes = {
  /** number of hours claimed for the organization */
  claimedPaidVolunteerHours: PropTypes.number,
  /** date of the volunteer event */
  date: PropTypes.shape({
    /** formatted for <time> element */
    dateTime: PropTypes.string,
    /** formatted for display to users */
    label: PropTypes.string,
  }).isRequired,
  /** ein of organization associated with volunteer event */
  ein: PropTypes.string,
  /** boolean to show or not show paid volunteer hours */
  enablePaidVolunteerHours: PropTypes.bool.isRequired,
  /** name of the volunteer event, only present/displayed for platform events */
  eventName: PropTypes.string,
  /** url of the volunteer event, only displayed for platform events */
  eventUrl: PropTypes.string,
  /** duration in hours of the volunteer event */
  hours: PropTypes.number.isRequired,
  /** status of the volunteer event */
  isComplete: PropTypes.bool.isRequired,
  /** determines if volunteering was for a platform or user-submitted event  */
  isForPlatformEvent: PropTypes.bool.isRequired,
  /** log id to navigate to edit or destroy form */
  logId: PropTypes.string.isRequired,
}

VolunteerHistoryLog.defaultProps = {
  claimedPaidVolunteerHours: null,
  ein: null,
  eventName: null,
  eventUrl: null,
}
