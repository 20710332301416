// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  next: {
    defaultMessage: 'next image',
    id: 'activityGalleryModalNav.next',
  },
  previous: {
    defaultMessage: 'previous image',
    id: 'activityGalleryModalNav.previous',
  },
})

const ActivityGalleryModalNav = ({ hasPrevious, onModalNavClick }) => {
  const { formatMessage } = intl
  const navLinks = [
    {
      dataTest: 'next-image',
      direction: 'next',
      iconName: 'arrow_right',
      translationKey: messages.next,
    },
    {
      dataTest: 'previous-image',
      direction: 'previous',
      disabled: !hasPrevious,
      iconName: 'arrow_left',
      translationKey: messages.previous,
    },
  ]

  return (
    <Stack
      as="nav"
      className="[ d-flex align-items-center ] [ absolute-top-left fw-semi-bold h-100 text-uppercase w-100"
    >
      <ul className="[ d-flex flex-row-reverse justify-content-between ] [ list-none p-0 w-100 ]">
        {navLinks
          .filter((link) => !link.disabled)
          .map(({ dataTest, direction, iconName, translationKey }) => (
            <li className="bg-black-1 position-relative z-1" key={dataTest}>
              <Button
                className="px-2 py-3 text-white | p-md-4"
                onClick={onModalNavClick(direction)}
              >
                <Icon
                  className="fs-5 text-white | fs-md-6"
                  data-test={dataTest}
                  iconName={iconName}
                  title={formatMessage(translationKey)}
                />
              </Button>
            </li>
          ))}
      </ul>
    </Stack>
  )
}

ActivityGalleryModalNav.propTypes = {
  hasPrevious: PropTypes.bool.isRequired,
  onModalNavClick: PropTypes.func.isRequired,
}

export { ActivityGalleryModalNav }
