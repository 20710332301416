// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { ContentModule } from './content_module'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sortByAlpha } from 'utilities/sort'
import Stack from 'components/ui/stack'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl

const messages = defineMessages({
  missingResource: {
    defaultMessage: 'the list of enabled Modules',
    id: 'contentModuleList.missingResource',
  },
})

const contentModuleListQuery = gql`
  query contentModuleListQuery($brandId: ID!) {
    brand(id: $brandId) {
      id
      contentModules {
        channel {
          id
          name
        }
        id
        name
        skipAssociatedChannel
      }
    }
  }
`

/**
 * Renders a list of <ContentModule> components. Powered by a GraphQL query to
 * fetch a Brand's Content Modules.
 */
const ContentModuleList = ({ brandId }) => (
  <WeQuery
    error={
      <MissingResourceSection
        errorDetails={formatMessage(messages.missingResource)}
      />
    }
    loader={<CenteredPaddedLoadingIndicator color="primary" />}
    query={contentModuleListQuery}
    variables={{ brandId: brandId }}
  >
    {({
      data: {
        brand: { contentModules },
      },
    }) => (
      <Stack as="ul" className="p-0" divider="default" space={3}>
        {contentModules.length > 0 ? (
          sortByAlpha(contentModules, 'name').map(
            ({ channel, id, name, skipAssociatedChannel }) => (
              <ContentModule
                channel={channel}
                id={id}
                key={id}
                name={name}
                skipAssociatedChannel={skipAssociatedChannel}
              />
            )
          )
        ) : (
          <p className="fw-semi-bold text-black-3">No Modules are enabled.</p>
        )}
      </Stack>
    )}
  </WeQuery>
)

ContentModuleList.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export { ContentModuleList }
