// Vendor
import { sharedTranslations } from 'components/shared/translations'

export const yearFilterOptions = (startYear = 2016) => {
  const yearOptions = [{ id: 'all', label: sharedTranslations.all }]
  let currentYear = new Date().getFullYear()

  while (currentYear >= startYear) {
    yearOptions.push({ id: currentYear, label: currentYear })
    currentYear--
  }

  return yearOptions
}
