// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Autocomplete, {
  Autocomplete as UndecoratedAutocomplete,
} from 'components/form/autocomplete'

const CountryAutocomplete = ({
  enabledCountries,
  isUndecorated,
  onCountryChange,
  valueField,
  ...props
}) => {
  const onChange = (selectedId) => {
    if (onCountryChange) {
      const selectedCountry = enabledCountries.find(
        ({ id }) => selectedId === id
      )
      onCountryChange(selectedCountry)
    }
  }

  const Component = isUndecorated ? UndecoratedAutocomplete : Autocomplete

  return (
    <Component
      multiple
      onChange={onChange}
      suggestions={enabledCountries.map((country) => ({
        id: country[valueField],
        suggestionLabel: country.name,
      }))}
      {...props}
    />
  )
}

CountryAutocomplete.propTypes = {
  enabledCountries: PropTypes.arrayOf(
    PropTypes.shape({
      alpha3: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  isUndecorated: PropTypes.bool,
  onCountryChange: PropTypes.func,
  valueField: PropTypes.string,
}

CountryAutocomplete.defaultProps = {
  isUndecorated: false,
  onCountryChange: null,
  valueField: 'id',
}

export default CountryAutocomplete
