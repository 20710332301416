// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// Wespire
import { client } from 'utilities/we_apollo'
import { useQuery } from '@apollo/client'

const UserLegalDocumentsContext = React.createContext()

const UserLegalDocumentsQuery = gql`
  query userLegalDocuments {
    currentUser {
      id
      missingTermsOfServiceAcceptance
      missingPrivacyPolicyAcceptance
    }
  }
`

const UserLegalDocumentsProvider = ({ children }) => {
  const { data, loading } = useQuery(UserLegalDocumentsQuery, { client })

  const missingLegalDocuments =
    data?.currentUser?.missingTermsOfServiceAcceptance ||
    data?.currentUser?.missingPrivacyPolicyAcceptance

  return (
    <UserLegalDocumentsContext.Provider value={missingLegalDocuments}>
      {loading ? null : children}
    </UserLegalDocumentsContext.Provider>
  )
}

UserLegalDocumentsProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export { UserLegalDocumentsContext, UserLegalDocumentsProvider }
