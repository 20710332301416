import { defineMessages } from 'react-intl'

export const indexMessages = defineMessages({
  availableActions: {
    defaultMessage: 'Available Actions ({count})',
    id: 'actionsIndexPage.availableActions',
  },
  dataDictionary: {
    defaultMessage: 'Data Dictionary',
    id: 'actionsIndexPage.dataDictionary',
  },
  dataDictionaryA: {
    defaultMessage:
      'Action ID, the unique Action identifier in the WeSpire application',
    id: 'actionsIndexPage.dataDictionaryA',
  },
  dataDictionaryB: {
    defaultMessage: 'Action Name, the configured name for the Action',
    id: 'actionsIndexPage.dataDictionaryB',
  },
  dataDictionaryC: {
    defaultMessage:
      "Action Description, the Action's text description displayed to users",
    id: 'actionsIndexPage.dataDictionaryC',
  },
  dataDictionaryD: {
    defaultMessage:
      'Points on Granting Action, the number of {wordForScore} users receive when completing the Action',
    id: 'actionsIndexPage.dataDictionaryD',
  },
  dataDictionaryE: {
    defaultMessage:
      'Points Type, either Standard or Preferred, if available for the Campaign',
    id: 'actionsIndexPage.dataDictionaryE',
  },
  dataDictionaryF: {
    defaultMessage:
      'Campaign ID, the unique identifier of the Campaign or Competition where users can complete the Action',
    id: 'actionsIndexPage.dataDictionaryF',
  },
  dataDictionaryG: {
    defaultMessage:
      'Campaign Name, the name of the Campaign or Competition where the user can earn the Achievement',
    id: 'actionsIndexPage.dataDictionaryG',
  },
  dataDictionaryH: {
    defaultMessage:
      'Activity Link, the URL to copy into your web browser to make edits to the Action (in Campaign Builder inside Management Panel)',
    id: 'actionsIndexPage.dataDictionaryH',
  },
  dataDictionaryI: {
    defaultMessage: 'Start Date, the start date for the Action, if configured',
    id: 'actionsIndexPage.dataDictionaryI',
  },
  dataDictionaryJ: {
    defaultMessage: 'End Date, the end date for the Action, if configured',
    id: 'actionsIndexPage.dataDictionaryJ',
  },
  dataDictionaryK: {
    defaultMessage:
      'Campaign Start Date, the start date for the Campaign or Competition where users can earn the Achievement',
    id: 'actionsIndexPage.dataDictionaryK',
  },
  dataDictionaryL: {
    defaultMessage:
      'Campaign End Date, the end date for the Campaign or Competition where users can earn the Achievement',
    id: 'actionsIndexPage.dataDictionaryL',
  },
  dataDictionaryM: {
    defaultMessage:
      'Channel, the Channel in with the Campaign or Competition appears',
    id: 'actionsIndexPage.dataDictionaryM',
  },
  dataDictionaryN: {
    defaultMessage:
      'Cooldown Period, the required delay before users can complete the Action again, if configured',
    id: 'actionsIndexPage.dataDictionaryN',
  },
  dataDictionaryO: {
    defaultMessage:
      'Action Requirements, if users are required to submit a Comment or Answer a question to complete the Action',
    id: 'actionsIndexPage.dataDictionaryO',
  },
  dataDictionaryP: {
    defaultMessage: 'Action Type, either Standard or Accumulation',
    id: 'actionsIndexPage.dataDictionaryP',
  },
  description: {
    defaultMessage:
      'Users complete Actions in Campaigns and Competitions on the WeSpire platform. A full accounting of Actions can be found in the Success Center.',
    id: 'actionsIndexPage.description',
  },
  exportActions: {
    defaultMessage: 'Export Actions',
    id: 'actionsIndexPage.exportActions',
  },
  instructions: {
    defaultMessage:
      "This page will allow you to export all Actions from Campaigns and Competitions available to your users. You can apply filters and then download the resulting file to your computer to better understand the array of Actions available to your users. There's also a link in the resulting download file (as Microsoft Excel) to allow you to edit the Actions here in Management Panel, without having to search through Campaigns and Competitions.",
    id: 'actionsIndexPage.instructions',
  },
})

export const actionsFiltersMessages = defineMessages({
  active: {
    defaultMessage: 'Active',
    id: 'actionsFilters.active',
  },
  campaignLabel: {
    defaultMessage: 'Campaign',
    id: 'actionsFilters.campaignLabel',
  },
  filtersLabel: {
    defaultMessage: 'Filter Actions to Export',
    id: 'actionsFilters.filtersLabel',
  },
  scheduled: {
    defaultMessage: 'Scheduled',
    id: 'actionsFilters.scheduled',
  },
})
