import React from 'react'
import InternalLink from 'src/common/InternalLink'

const MainMenu = ({ mainLinks }) => (
  <div className="sm:flex flex-row justify-start hidden sm:px-6 py-4 items-center text-base bg-white text-black-2 border-b border-[#C4D1DB]">
    {mainLinks.map((link) => (
      <InternalLink
        className="hover:no-underline pr-8"
        href={link.href}
        key={link.title}
        react={link.react}
      >
        {link.title}
      </InternalLink>
    ))}
  </div>
)

export default MainMenu
