// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import { LeaderCard } from './leader_card'
import { LeaderCardPlaceholder } from './placeholder'
import { LeaderBoardPlaceholders } from '../placeholders'

const LeaderBoardLeaders = ({ className, forTeams, leaders, displayCount }) => {
  const leadersToDisplay = leaders.slice(0, displayCount)

  return (
    <ol
      className={cx('[ flex-grow row vertical-gutters ] [ p-0 ]', className)}
      data-test="leaderboard-leaders"
    >
      {leadersToDisplay.map((competitor) => {
        const competitorName = {
          firstName: competitor.firstName,
          lastName: competitor.lastName,
          shortName: competitor.name,
        }
        return (
          <LeaderCard
            avatarUrl={competitor.avatarUrl}
            forTeams={forTeams}
            highlightAsYou={competitor.highlight}
            key={competitor.id}
            name={competitorName}
            profilePath={competitor.profilePath}
            rank={competitor.rank}
            score={competitor.points}
          />
        )
      })}
      {!forTeams && (
        <LeaderBoardPlaceholders
          as={LeaderCardPlaceholder}
          count={displayCount - leadersToDisplay.length}
          rankStart={leadersToDisplay.length + 1}
        />
      )}
    </ol>
  )
}

LeaderBoardLeaders.propTypes = {
  className: PropTypes.string,
  displayCount: PropTypes.number.isRequired,
  forTeams: PropTypes.bool,
  leaders: PropTypes.array.isRequired,
}

LeaderBoardLeaders.defaultProps = {
  className: null,
  forTeams: false,
}

export { LeaderCard, LeaderBoardLeaders, LeaderCardPlaceholder }
