import { defineMessages } from 'react-intl'

export const indexMessages = defineMessages({
  allAchievements: {
    defaultMessage: 'Available Achievements ({achievementsCount})',
    id: 'achievementsIndexPage.allAchievements',
  },
  dataDictionary: {
    defaultMessage: 'Data Dictionary',
    id: 'achievementsIndexPage.dataDictionary',
  },
  dataDictionaryA: {
    defaultMessage:
      'Achievement ID, the unique Achievement identifier in the WeSpire application',
    id: 'achievementsIndexPage.dataDictionaryA',
  },
  dataDictionaryB: {
    defaultMessage: 'Achievement Name, the configured name for the Achievement',
    id: 'achievementsIndexPage.dataDictionaryB',
  },
  dataDictionaryC: {
    defaultMessage:
      "Achievement Description, the Achievement's text description displayed to users",
    id: 'achievementsIndexPage.dataDictionaryC',
  },
  dataDictionaryD: {
    defaultMessage:
      'Campaign ID, the unique identifier of the Campaign or Competition where users can earn the Achievement',
    id: 'achievementsIndexPage.dataDictionaryD',
  },
  dataDictionaryE: {
    defaultMessage:
      'Campaign Name, the name of the Campaign or Competition where the user can earn the Achievement',
    id: 'achievementsIndexPage.dataDictionaryE',
  },
  dataDictionaryF: {
    defaultMessage:
      'Campaign Points, the {wordForScore} configuration (standard or preferred) if enabled on the Campaign or Competition where the user can earn the Achievement',
    id: 'achievementsIndexPage.dataDictionaryF',
  },
  dataDictionaryG: {
    defaultMessage:
      'Activity Link, the URL to copy into your web browser to make edits to the Achievement (in Campaign Builder inside Management Panel)',
    id: 'achievementsIndexPage.dataDictionaryG',
  },
  dataDictionaryH: {
    defaultMessage:
      'Points on Granting Achievement, the number of {wordForScore} users receive when earning the Achievement',
    id: 'achievementsIndexPage.dataDictionaryH',
  },
  dataDictionaryI: {
    defaultMessage:
      'Points Required to Earn Achievement, the minimum number of {wordForScore} users must earn in the Campaign or Competition to earn the Achievement',
    id: 'achievementsIndexPage.dataDictionaryI',
  },
  dataDictionaryJ: {
    defaultMessage:
      'Campaign Start Date, the start date for the Campaign or Competition where users can earn the Achievement',
    id: 'achievementsIndexPage.dataDictionaryJ',
  },
  dataDictionaryK: {
    defaultMessage:
      'Campaign End Date, the end date for the Campaign or Competition where users can earn the Achievement',
    id: 'achievementsIndexPage.dataDictionaryK',
  },
  dataDictionaryL: {
    defaultMessage:
      'Channel, the Channel in with the Campaign or Competition appears',
    id: 'achievementsIndexPage.dataDictionaryL',
  },
  description: {
    defaultMessage:
      'Users earn Achievements for completing Campaigns, Competitions, or other actions on the WeSpire platform. A full accounting of Actions can be found in the Success Center.',
    id: 'achievementsIndexPage.description',
  },
  exportAchievements: {
    defaultMessage: 'Export Achievements',
    id: 'achievementsIndexPage.exportAchievements',
  },
  instructions: {
    defaultMessage:
      "This page will allow you to export all Campaign and Competition Achievements available to your users. You can apply filters and then download the resulting file to your computer to better understand the array of Achievements available to your users. There's also a link in the resulting download file (as Microsoft Excel) to allow you to edit the Achievement here in Management Panel, without having to search through Campaigns and Competitions.",
    id: 'achievementsIndexPage.instructions',
  },
})

export const achievementsFiltersMessages = defineMessages({
  filtersLabel: {
    defaultMessage: 'Filter Achievements to Export',
    id: 'achievementsFilters.filtersLabel',
  },
})
