import cloneDeep from 'lodash/cloneDeep'

export const sortByAlpha = (array, key) => {
  const arrayCopy = cloneDeep(array)
  return arrayCopy.sort((a, b) => {
    // If key provided, sort by that. Otherwise, sort directly by array items.
    if (key) {
      a = a[key]
      b = b[key]
    }

    // Sort equal items the same.
    if (a === b) return 0

    // Move null items to the bottom of the list.
    if (a === null) return 1
    if (b === null) return -1

    // Sort non-null items in ascending order (a, b, c).
    if (a.toLowerCase() > b.toLowerCase()) return 1
    if (a.toLowerCase() < b.toLowerCase()) return -1
  })
}

export const sortByNewest = (array, dateKey) => {
  const arrayCopy = cloneDeep(array)
  return arrayCopy.sort((a, b) => {
    // Move null dates to the bottom of the list
    const nullSort = (a[dateKey] === null) - (b[dateKey] === null)
    const dateSort = new Date(b[dateKey]) - new Date(a[dateKey])

    return nullSort || dateSort
  })
}
export const sortByNumber = (array, key) => {
  const arrayCopy = cloneDeep(array)
  return arrayCopy.sort((a, b) => {
    // If key provided, sort by that. Otherwise, sort directly by array items.
    if (key) {
      a = a[key]
      b = b[key]
    }

    // Sort equal items the same.
    if (a === b) return 0

    // Move null items to the bottom of the list.
    if (a === null) return 1
    if (b === null) return -1

    // Sort non-null items in ascending order (a, b, c).
    if (a > b) return 1
    if (a < b) return -1
  })
}
export const sortByOldest = (array, dateKey) => {
  const arrayCopy = cloneDeep(array)
  return arrayCopy.sort((a, b) => {
    // Move null dates to the bottom of the list
    const nullSort = (a[dateKey] === null) - (b[dateKey] === null)
    const dateSort = new Date(a[dateKey]) - new Date(b[dateKey])

    return nullSort || dateSort
  })
}
