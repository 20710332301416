// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedNumber } from 'react-intl'
import Button from '@material-ui/core/Button'
import { useQuery } from '@apollo/client'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// WeSpire
import CountryAutocomplete from 'components/form/country_autocomplete'
import DisableButton from 'components/ui/disable_button'
import { filterAllCharitiesByParams } from 'components/charities/utils'
import { filterAllGlobalGivingProjectsByParams } from 'components/charities/utils'
import { undecorated as Checkbox } from 'components/form/checkbox'
import FormFieldset from 'components/form/fieldset'
import { undecorated as TextField } from 'components/form/text_field'
import { intl } from 'utilities/localization'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import gql from 'graphql-tag'
import { client } from 'utilities/we_apollo'

const messages = defineMessages({
  charitiesMatch: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {charities match} one {charity matches} two {charities match} few {charities match} many {charities match} other {charities match}}.',
    id: 'charities.charitiesMatch',
  },
  charityNameLabel: {
    defaultMessage: 'Charity Name',
    id: 'charities.charityNameLabel',
  },
  charitySearchTitle: {
    defaultMessage: 'Search For Charities:',
    id: 'charities.charitySearchTitle',
  },
  cityLabel: {
    defaultMessage: 'City',
    id: 'charities.cityLabel',
  },
  countryLabel: {
    defaultMessage: 'Country',
    id: 'charities.countryLabel',
  },
  einLabel: {
    defaultMessage: 'Charity ID',
    id: 'charities.einLabel',
  },
  errorDetails: {
    defaultMessage: 'There was a problem loading the charities.',
    id: 'charities.errorDetails',
  },
  helperTextForCity: {
    defaultMessage: 'Refine by city',
    id: 'charities.helperTextForCity',
  },
  helperTextForCountry: {
    defaultMessage: 'Refine by country',
    id: 'charities.helperTextForCountry',
  },
  helperTextForEin: {
    defaultMessage: 'Refine by Charity ID',
    id: 'charities.helperTextForEin',
  },
  helperTextForName: {
    defaultMessage: 'Search by name',
    id: 'charities.helperTextForName',
  },
  hintTextForEin: {
    defaultMessage:
      'For US 501(c)(3) organizations, this is often a nine-digit EIN or tax ID number.',
    id: 'charities.hintTextForEin',
  },
  isMatchEligibleLabel: {
    defaultMessage: 'Show only Matching Gift eligible charities',
    id: 'charities.isMatchEligibleLabel',
  },
  pending: {
    defaultMessage: 'Please enter criteria to search for charities.',
    id: 'charities.pending',
  },
  refineResults: {
    defaultMessage: `{count, number} results were found, Please refine your search. A sampling is listed below.`,
    id: 'charities.refineResults',
  },
  reset: {
    defaultMessage: 'Reset',
    id: 'charities.reset',
  },
  search: {
    defaultMessage: 'Search',
    id: 'charities.search',
  },
  searching: {
    defaultMessage: 'Searching for charities matching set parameters.',
    id: 'charities.searching',
  },
  searchResult: {
    defaultMessage: `{count, number} {count, plural, zero {results found} one {result found} two {results found} few {results found} many {results found} other {results found}}.`,
    id: 'charities.searchResult',
  },
})

// Utilities
import { DONATION_MATCHING_ENABLED_QUERY } from './utilities/queries'
import useGlobalGivingEnabled from './utilities/queries'

const ENABLED_COUNTRIES_QUERY = gql`
  query enabledCountries {
    brand {
      id
      enabledCountries {
        id
        alpha3
        name
      }
      hasEnabledCountries
    }
  }
`

const fetchEnabledCountries = async (
  setEnabledCountries,
  setHasEnabledCountries,
  setStatus
) => {
  try {
    const {
      data: {
        brand: { enabledCountries, hasEnabledCountries },
      },
    } = await client.query({
      query: ENABLED_COUNTRIES_QUERY,
    })
    setEnabledCountries(enabledCountries)
    setHasEnabledCountries(hasEnabledCountries)
    setStatus('success')
  } catch {
    setStatus('error')
  }
}

const useEnabledCountries = (setStatus) => {
  const [enabledCountries, setEnabledCountries] = useState([])
  const [hasEnabledCountries, setHasEnabledCountries] = useState(true)
  useEffect(() => {
    fetchEnabledCountries(
      setEnabledCountries,
      setHasEnabledCountries,
      setStatus
    )
  }, [])
  return {
    enabledCountries,
    hasEnabledCountries,
  }
}

const CharitySearch = ({ managementPanel, onCharitySearch }) => {
  const [charities, setCharities] = useState([])
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState('pending')
  const [filterValues, setFilterValues] = useState({})
  const [searchType, setSearchType] = useState('charity')

  const handleOptionChange = (event) => {
    setSearchType(event.target.value)
  }

  const { data } = useQuery(DONATION_MATCHING_ENABLED_QUERY, {})
  const { donationMatchingEnabled = true } = data || {}
  const globalGivingEnabled = useGlobalGivingEnabled()
  const { enabledCountries, hasEnabledCountries } =
    useEnabledCountries(setStatus)

  useEffect(() => {
    onCharitySearch({
      charities,
      count,
      status,
      searchType
    })
  }, [charities, count, status, searchType])

  const ariaLiveText = () => {
    let text

    if (status === 'pending') {
      text = intl.formatMessage(messages.pending)
    } else if (status === 'loading') {
      text = intl.formatMessage(messages.searching)
    } else if (status === 'error') {
      text = intl.formatMessage(messages.errorDetails)
    } else {
      text = intl.formatMessage(messages.charitiesMatch, {
        count: count,
        stringifiedCount: <FormattedNumber value={count} />,
      })
    }
    return text
  }

  const searchFunctions = {
    charity: filterAllCharitiesByParams,
    globalGiving: filterAllGlobalGivingProjectsByParams
  }

  const searchCharities = async () => {
    setStatus('loading')
    try {
      const { charities, count } = await searchFunctions[searchType](
        !hasEnabledCountries && !managementPanel
          ? {
              ...filterValues,
              ['country']: 'USA',
            }
          : filterValues
      )
      setCharities(charities)
      setCount(count)
    } catch (e) {
      setStatus('error')
    } finally {
      setStatus('success')
    }
  }

  const setValue = (name) => (value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    })
  }

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault()
      searchCharities()
    }
  }

  const handleReset = () => {
    setCharities([])
    setCount(0)
    setStatus('pending')
    setFilterValues({})
    setSearchType('charity')
  }

  return (
    <>
      <FormFieldset
        data-test="charity-search"
        label={intl.formatMessage(messages.charitySearchTitle)}
      >
        <Stack space={3}>
          {globalGivingEnabled && (
            <>
              <label htmlFor={"charity-gg-filter"}>
                Filter by:
                <RadioGroup
                  id="charity-gg-filter"
                  aria-label="options"
                  name="options"
                  value={searchType}
                  onChange={handleOptionChange}
                  style={{ display: 'inline-block', margin: "5px" }}
                >
                  <FormControlLabel value="charity" control={<Radio />} label="Charity" />
                  <FormControlLabel value="globalGiving" control={<Radio />} label="Global Giving Project" />
                </RadioGroup>
              </label>
            </>

          )}
          <TextField
            name="charityName"
            setValue={setValue('charityName')}
            textFieldProps={{
              label: intl.formatMessage(messages.helperTextForName),
              onKeyDown: handleKeyDown,
              type: 'search',
            }}
            value={filterValues.charityName}
          />

          <Stack className="row" space={3}>
            <div className="col-12">
              {(managementPanel ||
                (status !== 'pending' && hasEnabledCountries)) && (
                <CountryAutocomplete
                  enabledCountries={enabledCountries}
                  isUndecorated
                  name="country"
                  setValue={setValue('country')}
                  textFieldProps={{
                    label: intl.formatMessage(messages.helperTextForCountry),
                    onKeyDown: handleKeyDown,
                  }}
                  value={filterValues.country}
                  valueField="alpha3"
                />
              )}
            </div>
            <div className="col-md-6">
              <TextField
                name="city"
                setValue={setValue('city')}
                textFieldProps={{
                  label: intl.formatMessage(messages.helperTextForCity),
                  onKeyDown: handleKeyDown,
                  type: 'search',
                }}
                value={filterValues.city}
              />
            </div>
            <div className="col-12">
              <TextField
                name="eins"
                setValue={setValue('eins')}
                textFieldProps={{
                  label: intl.formatMessage(messages.helperTextForEin),
                  onKeyDown: handleKeyDown,
                  type: 'search',
                }}
                value={filterValues.eins}
              />
            </div>
            <span className="fs-1 pl-3">
              {intl.formatMessage(messages.hintTextForEin)}
            </span>
            {donationMatchingEnabled && (
              <Checkbox
                className="col-md-12"
                labelProps={{
                  label: intl.formatMessage(messages.isMatchEligibleLabel),
                }}
                name="isMatchEligible"
                setValue={setValue('isMatchEligible')}
                value={filterValues.isMatchEligible}
              />
            )}
          </Stack>

          <Stack className="row" space={2}>
            <div className="col-md-12 d-flex justify-content-end">
              {['error', 'success'].includes(status) && (
                <Button
                  className="mt-3 mt-sm-0"
                  data-test="reset-search"
                  onClick={handleReset}
                  type="reset"
                >
                  {intl.formatMessage(messages.reset)}
                </Button>
              )}
              <DisableButton
                className="fs-2 mt-3 ml-sm-2 mt-sm-0 py-sm-3"
                color="secondary"
                data-test="search-charities"
                onClick={searchCharities}
                size="large"
                type="button"
                variant="contained"
              >
                {intl.formatMessage(messages.search)}
              </DisableButton>
            </div>
          </Stack>
        </Stack>
      </FormFieldset>

      {count > 0 && count <= 100 && (
        <div className="fs-2 fs-sm-3 fw-semi-bold mt-2">
          {intl.formatMessage(messages.searchResult, {
            count,
          })}
        </div>
      )}

      {count > 100 && (
        <div className="fs-1 fs-sm-2 fw-semi-bold mt-2">
          {intl.formatMessage(messages.refineResults, {
            count,
          })}
        </div>
      )}

      <ScreenReader aria-live="polite" text={ariaLiveText()} />
    </>
  )
}

CharitySearch.propTypes = {
  managementPanel: PropTypes.bool,
  // this functions exposes charity and charity fetch status to the parent component
  onCharitySearch: PropTypes.func,
}

CharitySearch.defaultProps = {
  managementPanel: false,
  onCharitySearch: null,
}

export default CharitySearch
