// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Icon from 'components/ui/icon'

const HeroIcon = ({ iconName }) => (
  <Icon
    className="[ fs-6 text-white ] [ show-sm-up ]"
    iconName={iconName}
    title=""
  />
)

HeroIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
}

export default HeroIcon
