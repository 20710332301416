// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'

// WeSpire
import Channel from './channel'
import { compositeChannelIndex, enhanceDnDTarget } from './utils'
import { ITEM_TYPES } from '../constants'

class DraggableChannel extends React.Component {
  render() {
    const { connectDragSource, connectDropTarget, ...props } = this.props
    const showHighlight =
      props.isOver && (props.isDragging || props.isDraggingActivity)

    return connectDragSource(
      connectDropTarget(
        <div
          className={cx({ 'bg-black-5': showHighlight })}
          ref={(ref) => (this.node = ref)}
        >
          <Channel {...props} />
        </div>
      )
    )
  }
}

DraggableChannel.propTypes = {
  channel: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isDraggingActivity: PropTypes.bool,
  isOver: PropTypes.func.isRequired,
  listId: PropTypes.string.isRequired,
  renderActivity: PropTypes.func.isRequired,
}

DraggableChannel.defaultProps = {
  isDraggingActivity: false,
}

const source = {
  beginDrag(props) {
    return {
      channel: props.channel,
      index: props.index,
      listId: props.listId,
    }
  },

  endDrag(props, monitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()
    const sourceListId = item.listId

    if (dropResult && dropResult.listId !== sourceListId) {
      props.removeChannel(item.index, sourceListId)
    }
  },
}

const targetSpec = {
  drop(props, monitor) {
    const sourceActivity = monitor.getItem()
    const nextChannelIndex = props.index
    const nextChannelListId = props.listId
    const sourceCompositeIndex = compositeChannelIndex(
      sourceActivity.listId,
      sourceActivity.channelIndex
    )
    const nextCompositeIndex = compositeChannelIndex(
      nextChannelListId,
      nextChannelIndex
    )

    // If activity is dropped on differrent channel, put it there
    if (monitor.getItemType() === ITEM_TYPES.activity) {
      // Do not push if it's the same channel
      if (sourceCompositeIndex !== nextCompositeIndex) {
        props.pushActivity(sourceActivity, nextChannelIndex, nextChannelListId)
      }

      return {
        channelIndex: nextChannelIndex,
        listId: nextChannelListId,
      }
    }
  },

  hover: enhanceDnDTarget(({ props, monitor, dragIndex, hoverIndex }) => {
    const itemType = monitor.getItemType()
    const sourceListId = monitor.getItem().listId

    // Moving channels in the current list
    if (props.listId === sourceListId && itemType === ITEM_TYPES.channel) {
      props.moveChannel(dragIndex, hoverIndex, sourceListId)

      monitor.getItem().index = hoverIndex
    }
  }),
}

const dropTarget = DropTarget(
  [ITEM_TYPES.channel, ITEM_TYPES.activity],
  targetSpec,
  (connect, monitor) => ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    // Returns true when cursor dragging a Channel is 'draggingOutsideTarget'
    // eslint-disable-next-line no-unused-vars
    isOver: enhanceDnDTarget(({ props, monitor, dragIndex, hoverIndex }) => {}),
  })
)

const dragSource = DragSource(
  ITEM_TYPES.channel,
  source,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    isDraggingActivity: monitor.getItemType() === ITEM_TYPES.activity,
  })
)

export const undecorated = DraggableChannel

export default dropTarget(dragSource(DraggableChannel))
