// Vendor
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import { Menu, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { Button } from 'components/ui/button'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { GIVING_ENABLED_QUERY } from './utilities/queries'
import { useFlags } from 'launchdarkly-react-client-sdk'

const { formatMessage } = intl

const messages = defineMessages({
  createActivity: {
    defaultMessage: 'Create Activity',
    id: 'createActivitiesMenu.createActivity',
  },
  createCampaign: {
    defaultMessage: 'Create Campaign',
    id: 'createActivitiesMenu.createCampaign',
  },
  createEvent: {
    defaultMessage: 'Create Event',
    id: 'createActivitiesMenu.createEvent',
  },
  createGivingActivity: {
    defaultMessage: 'Create Giving Activity',
    id: 'createActivitiesMenu.createGivingActivity',
  },
  createIdeaBoard: {
    defaultMessage: 'Create Idea Board',
    id: 'createActivitiesMenu.createIdeaBoard',
  },
})

const createActivityMenuItems = [
  {
    key: 'create-campaign',
    label: formatMessage(messages.createCampaign),
    to: '/management_panel/campaign_library?with_redirect_back=true',
  },
  {
    key: 'create-event',
    label: formatMessage(messages.createEvent),
    to: '/management_panel/participation_events/new?with_redirect_back=true',
  },
  {
    key: 'create-idea-board',
    label: formatMessage(messages.createIdeaBoard),
    to: '/management_panel/idea_boards/new?with_redirect_back=true',
  },
]

const givingActivity = {
  key: 'create-giving-activity',
  label: formatMessage(messages.createGivingActivity),
  to: '/management_panel/giving_activities/new?with_redirect_back=true',
}

const CreateActivitiesMenu = ({ className }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const { data } = useQuery(GIVING_ENABLED_QUERY)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelection = () => {
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { givingEnabled = false } = data?.brandConfig || {}
  const { enableGivingActivityForGroups } = useFlags()

  return (
    <div className={className}>
      <Button
        aria-controls="create-activity"
        aria-haspopup="true"
        className="[ border-blue-2 ] [ fs-3 ] [ mnx-3 ]"
        data-test="create-activity"
        onClick={handleClick}
        variant="neutral"
      >
        {formatMessage(messages.createActivity)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: 'ml-1 mt-6' }}
        id="create-activity"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {createActivityMenuItems.map((menuItemData) => (
          <MenuItem
            component={Link}
            data-test={menuItemData.key}
            key={menuItemData.key}
            onClick={handleSelection}
            to={menuItemData.to}
          >
            {menuItemData.label}
          </MenuItem>
        ))}

        {enableGivingActivityForGroups && givingEnabled && (
          <MenuItem
            component={Link}
            data-test={givingActivity.key}
            onClick={handleSelection}
            to={givingActivity.to}
          >
            {givingActivity.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

CreateActivitiesMenu.propTypes = {
  className: PropTypes.string,
}

CreateActivitiesMenu.defaultProps = {
  className: '',
}

export { CreateActivitiesMenu }
