// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, IconButton } from '@material-ui/core'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'

// WeSpire
import { Button } from 'components/ui/button'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import { groupTranslations } from 'components/group/shared_translations'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { leaveGroupMutation } from 'graphql/mutations/leaveGroupMutation'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import SubmitButton from 'components/ui/submit_button'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'

const messages = defineMessages({
  areYouSure: {
    defaultMessage: 'Are you sure?',
    id: 'leaveGroupDialog.areYouSure',
  },
  leaveChapterButton: {
    defaultMessage: 'Leave chapter',
    id: 'leaveGroupDialog.leaveChapterButton',
  },
  leaveChapterOrGroupText: {
    defaultMessage:
      'Would you like to leave {chapter} or leave {group} entirely?',
    id: 'leaveGroupDialog.leaveChapterOrGroupText',
  },
  leaveGroupAndChapterButton: {
    defaultMessage: 'Leave group and chapter',
    id: 'leaveGroupDialog.leaveGroupAndChapterButton',
  },
  leaveGroupAndChapterSuccessMessage: {
    defaultMessage: 'You have successfully left {chapter} and {group}',
    id: 'leaveGroupDialog.leaveGroupAndChapterSuccessMessage',
  },
  leaveGroupButton: {
    defaultMessage: 'Leave group',
    id: 'leaveGroupDialog.leaveGroupButton',
  },
  leaveGroupSuccessMessage: {
    defaultMessage: 'You have successfully left {group}',
    id: 'leaveGroupDialog.leaveGroupSuccessMessage',
  },
  leaveGroupText: {
    defaultMessage: 'Would you like to leave {group}?',
    id: 'leaveGroupDialog.leaveGroupText',
  },
  operation: {
    defaultMessage: 'leave this group',
    id: 'leaveGroupDialog.operation',
  },
  updateYourMembership: {
    defaultMessage: 'Update Your Membership',
    id: 'leaveGroupDialog.updateYourMembership',
  },
})

const getSuccessMessage = (operationType, groupName, chapterName) => {
  if (operationType === 'leaveGroup')
    return formatMessage(messages.leaveGroupSuccessMessage, {
      group: groupName,
    })
  if (operationType === 'leaveChapter')
    return formatMessage(messages.leaveGroupSuccessMessage, {
      group: chapterName,
    })
  else
    return formatMessage(messages.leaveGroupAndChapterSuccessMessage, {
      chapter: chapterName,
      group: groupName,
    })
}

export const GET_MEMBERSHIP_QUERY = gql`
  query getMembership($groupId: ID!) {
    userGroupMembership(groupId: $groupId) {
      id
      group {
        id
        name
      }
      groupChapter {
        id
        name
      }
    }
  }
`

const { formatMessage } = intl

const LeaveGroupDialog = ({
  group,
  setshowLeaveGroupModal,
  showLeaveGroupModal,
}) => {
  const history = useHistory()
  const [loadingLeavingGroup, setLoadingLeavingGroup] = useState(false)
  const [loadingLeavingChapterAndGroup, setLoadingLeavingChapterAndGroup] =
    useState(false)

  const isChapter = group.isChapter
  const groupId = isChapter ? group.parentGroup.id : group.id
  const groupSlug = isChapter ? group.parentGroup.slug : group.slug
  const groupName = isChapter ? group.parentGroup.name : group.name

  const closeModal = () => {
    setshowLeaveGroupModal(false)
  }

  const handleLeaveButton = (groupId, operationType, chapterName) => {
    operationType === 'leaveGroupandChapter'
      ? setLoadingLeavingChapterAndGroup(true)
      : setLoadingLeavingGroup(true)

    leaveGroupMutation({
      groupId,
    })
      .then(
        ({
          data: {
            leaveGroup: { errors },
          },
        }) => {
          if (errors.length) {
            displayBanner({
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          } else {
            displayBanner({
              content: getSuccessMessage(operationType, groupName, chapterName),
              variant: 'success',
            })
            history.push(`/groups/${groupSlug}`)
          }
        }
      )
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(messages.operation),
        })
      })
      .finally(() => {
        operationType === 'leaveGroupAndChapter'
          ? setLoadingLeavingChapterAndGroup(false)
          : setLoadingLeavingGroup(false)

        closeModal()
      })
  }

  return (
    <Dialog classes={{ paper: 'p-3 | p-md-4' }} open={showLeaveGroupModal}>
      <Stack divider="default" space={3}>
        <div className="[ d-flex align-items-center justify-content-between ] [ pl-3 ]">
          <Heading level={1} variant={2}>
            {formatMessage(messages.updateYourMembership)}
          </Heading>
          <IconButton onClick={closeModal}>
            <Icon
              className="fs-4 text-black-2"
              data-test="cancel-icon"
              iconName="close"
              title={sharedTranslations.cancel}
            />
          </IconButton>
        </div>

        <WeQuery
          error={
            <MissingResourceSection
              data-test="error-membership-not-found"
              errorDetails={formatMessage(groupTranslations.yourMembership)}
            />
          }
          loader={<SkeletonLoading height={50} />}
          query={GET_MEMBERSHIP_QUERY}
          variables={{ groupId }}
        >
          {({ data: { userGroupMembership } }) => {
            const modalText = userGroupMembership.groupChapter
              ? formatMessage(messages.leaveChapterOrGroupText, {
                  chapter: userGroupMembership.groupChapter.name,
                  group: userGroupMembership.group.name,
                })
              : formatMessage(messages.leaveGroupText, {
                  group: userGroupMembership.group.name,
                })

            return (
              <div>
                <p className="px-3" data-test="modal-description">
                  {modalText}
                </p>
                <div className="[ d-flex justify-content-end ]  [ p-3 | p-md-3 ]">
                  <Button
                    className="mx-2 text-uppercase fs-2"
                    data-test="cancel-button"
                    onClick={closeModal}
                    variant="neutral"
                  >
                    {sharedTranslations.cancel}
                  </Button>
                  {userGroupMembership.groupChapter ? (
                    <>
                      <SubmitButton
                        color="secondary"
                        data-test="leave-chapter-button"
                        isSubmitting={loadingLeavingGroup}
                        onClick={() =>
                          handleLeaveButton(
                            userGroupMembership.groupChapter.id,
                            'leaveChapter',
                            userGroupMembership.groupChapter.name
                          )
                        }
                        variant="outlined"
                      >
                        {formatMessage(messages.leaveChapterButton)}
                      </SubmitButton>
                      <SubmitButton
                        className="mx-2"
                        color="secondary"
                        data-test="leave-group-and-chapter-button"
                        isSubmitting={loadingLeavingChapterAndGroup}
                        onClick={() =>
                          handleLeaveButton(
                            groupId,
                            'leaveGroupandChapter',
                            userGroupMembership.groupChapter.name
                          )
                        }
                        variant="outlined"
                      >
                        {formatMessage(messages.leaveGroupAndChapterButton)}
                      </SubmitButton>
                    </>
                  ) : (
                    <SubmitButton
                      className="mx-2"
                      color="secondary"
                      data-test="leave-group-button"
                      isSubmitting={loadingLeavingGroup}
                      onClick={() => handleLeaveButton(groupId, 'leaveGroup')}
                      variant="outlined"
                    >
                      {formatMessage(messages.leaveGroupButton)}
                    </SubmitButton>
                  )}
                </div>
              </div>
            )
          }}
        </WeQuery>
      </Stack>
    </Dialog>
  )
}

LeaveGroupDialog.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isChapter: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    parentGroup: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    slug: PropTypes.string.isRequired,
  }).isRequired,
  setshowLeaveGroupModal: PropTypes.func.isRequired,
  showLeaveGroupModal: PropTypes.bool.isRequired,
}

export { LeaveGroupDialog }
