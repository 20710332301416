// Setup
import React from 'react'

// WeSpire
import MgmtPanelPage from 'setup/application/page'
import { HrisImportList } from './hris_import_list/index'

const HrisImport = () => {
  return (
    <MgmtPanelPage
      contentHeaderProps={{
        'data-test': 'hris-import-index-title',
      }}
      title='HRIS Imports'
    >
      {() => (
        <HrisImportList />
      )}
    </MgmtPanelPage>
  )
}

export default HrisImport
