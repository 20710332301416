// Setup
import React, { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

// WeSpire
import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  maybeDisplayUserTour,
} from 'redux/dispatchers'
import FormActionBar from 'components/form/action_bar'
import FormErrorSummary from 'components/form/error_summary'
import { FormPage } from 'components/form/form_page'
import FormTextField from 'components/form/text_field'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { DocumentTitle } from 'components/shared/document_title'
import { sharedTranslations } from 'components/shared/translations'
import { messages } from './utilities/messages'
import { CREATE_ORGANIZATION_MUTATION } from './utilities/mutations'

// This is a helper that builds a link to this form.
// The link will carry state for redirection AND auto form updating upon submit.
export const linkToOrganizationForm = (text, fieldName) => (
  <Link
    key={fieldName}
    to={{
      pathname: '/organizations/new',
      state: {
        fieldName: fieldName,
        redirectUrl: window.location.pathname,
      },
    }}
  >
    {text}
  </Link>
)

export const OrganizationForm = () => {
  const location = useLocation()
  const history = useHistory()
  const form = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { formatMessage } = intl

  const handleSubmit = (model) => {
    setIsSubmitting(true)
    client
      .mutate({
        mutation: CREATE_ORGANIZATION_MUTATION,
        variables: {
          name: model.name,
        },
      })
      .then(
        ({
          data: {
            createOrganization: { errors },
          },
        }) => {
          if (errors !== null) {
            displayBanner({
              as: 'div',
              content: <FormErrorSummary errors={errors} />,
              variant: 'error',
            })
            setIsSubmitting(false)
          } else {
            displayBanner({
              content: formatMessage(
                messages.newOrganizationWasSuccessfullyAdded
              ),
              variant: 'success',
            })

            const { redirectUrl } = location.state || {}
            setIsSubmitting(false)
            if (redirectUrl) {
              return history.push(redirectUrl)
            } else {
              form.current.reset()
            }
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: formatMessage(messages.saveThisOrganization),
        })
      })
  }

  return (
    <DocumentTitle title={formatMessage(messages.pageTitle)}>
      {maybeDisplayUserTour('organizationFormPage')}
      <FormPage
        backLinkSubject={formatMessage(messages.volunteerHistoryForm)}
        backLinkTo="/users/me/volunteer_hours_form"
        heading={formatMessage(messages.pageTitle)}
        onValidSubmit={(model) => handleSubmit(model)}
        ref={form}
      >
        <FormTextField
          name="name"
          required
          textFieldProps={{
            helperText: formatMessage(messages.nameOfOrganizationHelpText),
            inputProps: {
              autoFocus: true,
            },
            label: sharedTranslations.organizationName,
          }}
          validationErrors={{
            isNotBlank: formatMessage(messages.nameCannotBeBlank),
          }}
          validations="isNotBlank"
        />
        <FormActionBar hideBack isSubmitting={isSubmitting} />
      </FormPage>
    </DocumentTitle>
  )
}
