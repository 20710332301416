// Vendor
import React, { useState, useEffect } from 'react'

// WeSpire
import EmailAuthForm from './form'
import WeSpireAPI from 'utilities/wespire_api'
import LoginLayout from '../login_layout'

const Auth = () => {
  const [heroSubtitle, setHeroSubtitle] = useState('')
  const [heroTitle, setHeroTitle] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [multiAuth, setMultiAuth] = useState(false)
  const [signUpPath, setSignUpPath] = useState('')
  const [ssoUrl, setSsoUrl] = useState('')

  useEffect(() => {
    WeSpireAPI()
      .get('brands/landing')
      .then((response) => {
        const {
          hero_title,
          hero_subtitle,
          logo_url,
          multi_auth,
          sign_up_path,
          sso_url,
        } = response.data

        setHeroSubtitle(hero_subtitle)
        setHeroTitle(hero_title)
        setLogoUrl(logo_url)
        setMultiAuth(multi_auth)
        setSignUpPath(sign_up_path)
        setSsoUrl(sso_url)
      })
  })

  return (
    <LoginLayout
      heroSubtitle={heroSubtitle}
      heroTitle={heroTitle}
      logoUrl={logoUrl}
    >
      <div className="col d-flex flex-column align-items-center justify-content-center">
        <EmailAuthForm
          multiAuth={multiAuth}
          signUpPath={signUpPath}
          ssoUrl={ssoUrl}
        />
      </div>
    </LoginLayout>
  )
}

export { Auth }
