import { defineMessages } from 'react-intl'

export const volunteerHistoryMessages = defineMessages({
  changeVolunteerHours: {
    defaultMessage:
      'Need to make any changes to your personal volunteer hours? {requestACorrection}',
    id: 'volunteerHours.changeVolunteerHours',
  },
  noVolunteerHours: {
    defaultMessage:
      'There are no volunteer hours to display. Visit the {takeAction} to find volunteer opportunities!',
    id: 'volunteerHistory.noVolunteerHours',
  },
  pageTitle: {
    defaultMessage: 'Volunteer History',
    id: 'volunteerHistory.pageTitle',
  },
  paidVolunteerHoursWithInitials: {
    defaultMessage: 'Paid Volunteer Hours (PVH)',
    id: 'volunteerHistory.paidVolunteerHoursWithInitials',
  },
  requestACorrection: {
    defaultMessage: 'Request a Correction',
    id: 'volunteerHistory.requestACorrection',
  },
})

export const volunteerHistoryLogMessages = defineMessages({
  cannotEdit: {
    defaultMessage:
      'Editing of this entry has been disabled because it was marked for Paid Volunteering Time. Please {contactSupport} for more information',
    id: 'volunteerHistoryLogMessages.cannotEdit',
  },
  cannotEditTitle: {
    defaultMessage: 'Cannot Edit Paid Volunteer Hours',
    id: 'volunteerHistoryLogMessages.cannotEditTitle',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'volunteerHistoryLogs.edit',
  },
  paidVolunteerHoursInitials: {
    defaultMessage: 'PVH',
    id: 'volunteerHistoryLogs.paidVolunteerHoursInitials',
  },
  scheduledToVolunteer: {
    defaultMessage: 'Scheduled to volunteer',
    description:
      'label for a badge indicating the volunteer event is scheduled',
    id: 'volunteerHistoryLog.scheduledToVolunteer',
  },
})

export const volunteerHistoryLogGroupMessages = defineMessages({
  completedHours: {
    defaultMessage:
      '{stringifiedCount} completed {count, plural, zero {hours} one {hour} two {hours} few {hours} many {hours} other {hours}}',
    id: 'volunteerHistoryLogGroup.completedHours',
  },
  paidVolunteerHours: {
    defaultMessage:
      '{stringifiedCount} paid volunteer {count, plural, zero {hours} one {hour} two {hours} few {hours} many {hours} other {hours}}',
    id: 'volunteerHistoryLogGroup.paidVolunteerHours',
  },
  scheduledHours: {
    defaultMessage:
      '{stringifiedCount} scheduled {count, plural, zero {hours} one {hour} two {hours} few {hours} many {hours} other {hours}}',
    id: 'volunteerHistoryLogGroup.scheduledHours',
  },
})

export const grantEligibilityCheckerMessages = defineMessages({
  availableFunds: {
    defaultMessage:
      'You have {availableDonationFunds} in company donation funds remaining of the {donationFundsCap} annual limit per employee.',
    id: 'grantEligibilityChecker.availableFunds',
  },
  availableFundsModel: {
    defaultMessage:
      'You have <strong>$250</strong> available in company donation funds.',
    id: 'grantEligibilityChecker.availableFundsModel',
  },
  cannotRequestGrantsForOrg: {
    defaultMessage:
      'Cannot request a Dollars for Doers donation {organizationName}',
    id: 'grantEligibilityChecker.cannontRequesGrantsForOrg',
  },
  hoursLoggedForOrgModel: {
    defaultMessage:
      'You have logged <strong>25</strong> eligible volunteer hours for {organizationName}.',
    id: 'grantEligibilityChecker.hoursLoggedForOrgModel',
  },
  hoursVolunteeredAndTheirUseModel: {
    defaultMessage:
      'You have volunteered a total of {volunteerHoursThisYear} this year for {organizationName} and we have already used {alreadyUsedHours} to fulfill previous grant donations. Therefore, you have {grantEligibleVolunteerHours} eligible for this Dollars for Doers donation.',
    id: 'grantEligibilityChecker.hoursVolunteeredAndTheirUseModel',
  },
  ineligibleForGrants: {
    defaultMessage: 'Ineligible for Dollars for Doers',
    id: 'grantEligibilityChecker.ineligibleForGrants',
  },
  noDonationAccount: {
    defaultMessage: 'No Donation Account',
    id: 'grantEligibilityChecker.noDonationAccount',
  },
  notEligibleWithNoDonationAccount: {
    defaultMessage:
      'You are not eligible to request a Dollars for Doers donation at this time because you do not have a donation account. {contactSupport} to enable your donation account.',
    id: 'grantEligibilityChecker.notEligibleWithNoDonationAccount',
  },
  orgIsNotEligible: {
    defaultMessage: '{organizationName} Is Not Eligible',
    id: 'grantEligibilityChecker.orgIsNotEligible',
  },
  orgIsNotEligibleContact: {
    defaultMessage:
      'This organization is not eligible for a Dollars for Doers donation. If you would like this organization to be eligible, or feel that it should already be, {contactSupport}.',
    id: 'grantEligibilityChecker.orgIsNotEligibleContact',
  },
  requestAGrantDonation: {
    defaultMessage: 'Request a Dollars for Doers donation',
    id: 'grantEligibilityChecker.requestAGrantDonation',
  },
  willBeEligibleModel: {
    defaultMessage:
      'You will be eligible to request a Dollars for Doers donation for {organizationName} when',
    id: 'grantEligibilityChecker.willBeEligibleModel',
  },
})
