import React from 'react'
import PropTypes from 'prop-types'
import { VictoryPie } from 'victory-pie'

import { translations } from 'components/carbon_footprint/utilities/messages'
import { GraphLegend } from 'components/carbon_footprint/components/GraphLegend'

export const FootprintVisual = ({ food, goods, housing, transport }) => {
  const graphData = {
    colorScale: ['#E16C63', '#74AAEA', '#FCA266', '#A3CCAF'],
    data: [
      { name: translations.food, y: food },
      { name: translations.goods, y: goods },
      { name: translations.housing, y: housing },
      { name: translations.travel, y: transport },
    ],
  }

  return (
    <div className="d-flex flex-column">
      <div className="[ d-flex justify-content-center ] [ px-md-10 ]">
        <div className="h-10" data-test="pie">
          <VictoryPie
            colorScale={graphData.colorScale}
            data={graphData.data}
            padding={{ bottom: 50, top: 0 }}
            style={{ labels: { display: 'none' } }}
            x="name"
          />
          <GraphLegend graphData={graphData} />
        </div>
      </div>
    </div>
  )
}

FootprintVisual.propTypes = {
  food: PropTypes.number,
  goods: PropTypes.number,
  housing: PropTypes.number,
  transport: PropTypes.number,
}

FootprintVisual.defaultProps = {
  food: null,
  goods: null,
  housing: null,
  transport: null,
}
