// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedNumber } from 'react-intl'

// WeSpire
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  main: {
    defaultMessage:
      'You can optionally choose to claim some or all of your volunteer hours ' +
      'for {brandName}’s Paid Volunteer Hours (PVH). Claim requirements',
    id: 'pvhDetails.main',
  },
  requirement1: {
    defaultMessage:
      'You must have a minimum of {stringifiedCount} days of employment with {brandName}.',
    id: 'pvhDetails.requirement1',
  },
  requirement2: {
    defaultMessage:
      'A maximum of {stringifiedCount} hours of PVH can be claimed per team member per calendar year.',
    id: 'pvhDetails.requirement2',
  },
})

const PvhDetails = ({ brandName }) => (
  <Stack data-test="pvh-details" space={2}>
    <p>{intl.formatMessage(messages.main, { brandName })}:</p>
    <Stack as="ul" className="fs-2 fw-semi-bold lh-sm" space={2}>
      <li>
        {intl.formatMessage(messages.requirement1, {
          brandName,
          stringifiedCount: <FormattedNumber value={90} />,
        })}
      </li>
      <li>
        {intl.formatMessage(messages.requirement2, {
          stringifiedCount: <FormattedNumber value={4} />,
        })}
      </li>
    </Stack>
  </Stack>
)

PvhDetails.propTypes = {
  brandName: PropTypes.string.isRequired,
}

export { PvhDetails }
