export const ACTIVITY_STATUS = {
  active: 'active',
  draft: 'draft',
  ended: 'ended',
  justEnded: 'just_ended',
  scheduled: 'scheduled',
}

export const translateActivityType = (type) => {
  switch (type) {
    case 'Campaign':
      return 'campaign'
    case 'Competition':
      return 'competition'
    case 'Giving Activity':
      return 'givingactivity'
    case 'Idea Board':
      return 'ideaboard'
    case 'Event':
      return 'event'
    default:
      return type
  }
}
