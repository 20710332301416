// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

/** A component informing the user that they were unable to find what they were looking for. Designed to be rendered as a section of a page. */
export const MissingResourceSection = ({
  as,
  'data-test': dataTest,
  errorDetails,
  ...other
}) => {
  const Component = as
  const { formatMessage } = intl

  const messages = defineMessages({
    genericDetails: {
      defaultMessage: 'this resource',
      description:
        'Generic resource details when none provided, e.g. "We were unable to find this resource at this time".',
      id: 'missingResourceSection.genericDetails',
    },
    resourceNotFound: {
      defaultMessage: 'We were unable to find {errorDetails} at this time.',
      id: 'missingResourceSection.resourceNotFound',
    },
  })

  errorDetails = errorDetails || formatMessage(messages.genericDetails)

  return (
    <Component
      {...other}
      className={cx(
        'content-center content-center--y | px-3 py-4',
        other.className
      )}
      data-test={dataTest}
    >
      <div className="d-flex align-items-center">
        <Icon
          className="fs-5 mr-3 text-black-3"
          iconName="warning"
          title="warning"
        />
        <p className="m-0 mxw-measure">
          {formatMessage(messages.resourceNotFound, { errorDetails })}
        </p>
      </div>
    </Component>
  )
}

MissingResourceSection.propTypes = {
  /** Render the coment as any kind of custom element */
  as: PropTypes.any,
  'data-test': PropTypes.string,
  /** Details about the missing resource, rendered inside the error message */
  errorDetails: PropTypes.string,
}

MissingResourceSection.defaultProps = {
  as: 'div',
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'missing-resource-section',
  errorDetails: null,
}
