// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { parseDate } from 'utilities/date_utils'
import Stack from 'components/ui/stack'

import { RemoveMemberButton } from './remove_member_button'

const { formatMessage, formatDate } = intl

const messages = defineMessages({
  member: {
    defaultMessage: 'Member',
    id: 'GroupMembersRow.member',
  },
})

export const GroupMemberRow = ({
  groupId,
  groupName,
  id,
  name,
  avatarUrl,
  email,
  profilePath,
  role,
  userId,
  userJoinedAt,
  ...props
}) => {
  return (
    <TableRow
      data-test="group-member-row"
      hover
      key={id}
      tabIndex={-1}
      {...props}
    >
      <TableCell component="th" scope="row">
        <Stack className="d-flex align-items-center justify-content-start">
          <Link to={profilePath}>
            <Avatar
              className="mr-2"
              image={avatarUrl}
              size={AvatarSizes.small}
            />
          </Link>
          <Stack className="d-flex flex-column" space={0}>
            <Link className="fw-semi-bold text-blue fs-2" to={profilePath}>
              {name}
            </Link>
            <Link className="fs-1 text-black-3" to={`mailto:${email}`}>
              {email}
            </Link>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {role || formatMessage(messages.member)}
      </TableCell>
      <TableCell align="right">
        {formatDate(parseDate(userJoinedAt), {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </TableCell>
      <TableCell align="right">
        <Stack className="d-flex justify-content-end align-items-center">
          <RemoveMemberButton
            group={{ id: groupId, name: groupName }}
            member={{ name, userId }}
          />
        </Stack>
      </TableCell>
    </TableRow>
  )
}

GroupMemberRow.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profilePath: PropTypes.string.isRequired,
  role: PropTypes.string,
  userId: PropTypes.string.isRequired,
  userJoinedAt: PropTypes.string.isRequired,
}

GroupMemberRow.defaultProps = {
  role: null,
}
