import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'

export const groupByAnnouncementsData = (announcementsData) => {
  let sortedData = {}
  if (announcementsData.length > 0) {
    sortedData = groupBy(
      orderBy(announcementsData, 'updatedAt', 'desc'),
      'status'
    )
  }
  return sortedData
}
