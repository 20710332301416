// Vendor
import PropTypes from 'prop-types'
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  submission: {
    defaultMessage: 'this Idea submission',
    description:
      'Part of an error message, e.g. "We were unable to find {this Idea submission} at this time."',
    id: 'ideaBoardSubmissionDetailsQuery.submission',
  },
})

const IdeaBoardSubmissionDetailsQuery = ({ submissionId, children }) => {
  const ideaBoardSubmissionDetails = gql`
    query ideaBoardSubmissionDetails($submissionId: ID!) {
      ideaBoardSubmissionDetails(id: $submissionId) {
        commentsList {
          createdAt
          currentUserLikeId
          id
          likeCount
          message
          user {
            avatarUrl
            email
            id
            profilePath
          }
        }
        customFieldResponses {
          id
          name
          response
        }
        description
        id
        ideaUrl
        imageUrl
      }
    }
  `
  const { formatMessage } = intl

  return (
    <WeQuery
      error={
        <MissingResourceSection
          errorDetails={formatMessage(messages.submission)}
        />
      }
      loader={
        <CenteredPaddedLoadingIndicator
          size={40}
          wrapperProps={{ className: 'px-4' }}
        />
      }
      query={ideaBoardSubmissionDetails}
      variables={{ submissionId: submissionId }}
    >
      {({ data }) => children(data.ideaBoardSubmissionDetails)}
    </WeQuery>
  )
}

IdeaBoardSubmissionDetailsQuery.propTypes = {
  children: PropTypes.func.isRequired,
  submissionId: PropTypes.string.isRequired,
}

export default IdeaBoardSubmissionDetailsQuery
