import gql from 'graphql-tag'

export const GET_CATEGORIES_AND_PVH_BALANCE = gql`
  query getCategoriesAndPvhBalance {
    brand {
      id
      eventCategories {
        category
        id
        name
      }
    }
    currentUser {
      id
      paidVolunteerHoursBalance
    }
    brandConfig {
      id
      enablePaidVolunteerHours
    }
  }
`

export const GET_VOLUNTEER_LOG = gql`
  query getVolunteerLog($id: ID!) {
    volunteerLog(id: $id) {
      claimedPaidVolunteerHours
      date
      ein
      eventName
      eventUrl
      hours
      id
      isComplete
      isForPlatformEvent
      organizationName
      address {
        city
        state
        country
        postalCode
        county
        street
      }
      addressLabel
      category
      organizationId
      description
      image
      taggedUsers {
        name
        id
      }
    }
  }
`
