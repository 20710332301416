// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import cx from 'classnames'
import { defineMessages, FormattedNumber } from 'react-intl'

// WeSpire
import CountIndicator from 'components/application/count_indicator'
import Icon from 'components/ui/icon'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  commentsWithCount: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {comments} one {comment} two {comments} few {comments} many {comments} other {comments}}',
    id: 'commentsSummary.commentsWithCount',
  },
})

const commentSummaryIcon = (comments) => {
  let title = sharedTranslations.comments

  if (comments) {
    title = formatMessage(messages.commentsWithCount, {
      count: comments.length,
      stringifiedCount: <FormattedNumber value={comments.length} />,
    })
  }

  return <Icon className="fs-3 text-black-3" iconName="message" title={title} />
}

const CommentsSummary = ({ comments, onSummaryClick, ...other }) => {
  return (
    <div
      {...other}
      className={cx(
        'd-flex align-items-center justify-content-end | text-black-3',
        other.className
      )}
    >
      <Button
        className="fs-1 mr-2"
        color="secondary"
        onClick={onSummaryClick}
        size="small"
      >
        {sharedTranslations.comment}
      </Button>
      {comments === null ? (
        commentSummaryIcon(comments)
      ) : (
        <CountIndicator
          icon={commentSummaryIcon(comments)}
          isNumberAriaHidden
          number={comments.length}
        />
      )}
    </div>
  )
}

// PropType definitions
CommentsSummary.propTypes = {
  comments: PropTypes.array,
  onSummaryClick: PropTypes.func.isRequired,
}

CommentsSummary.defaultProps = {
  comments: null,
}

export default CommentsSummary
