// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
  LoadingIndicator,
  LoadingIndicatorSize,
} from 'components/ui/loading_indicator'

// WeSpire
import WeQuery from 'components/application/we_query'

const AutocompleteSuggestionsQuery = ({
  children,
  query,
  queryName,
  variables,
}) => (
  <WeQuery
    loader={
      <ListItem>
        <LoadingIndicator size={LoadingIndicatorSize.SMALL} />
      </ListItem>
    }
    query={query}
    variables={variables}
  >
    {({ data }) => {
      const suggestions = data[queryName]

      if (suggestions.length > 0) {
        return children(suggestions)
      } else {
        return (
          <ListItem>
            <ListItemText primary="No results" />
          </ListItem>
        )
      }
    }}
  </WeQuery>
)

AutocompleteSuggestionsQuery.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired,
  variables: PropTypes.object,
}

AutocompleteSuggestionsQuery.defaultProps = {
  variables: null,
}

export default AutocompleteSuggestionsQuery
