import gql from 'graphql-tag'
import { client } from 'utilities/we_apollo'
import { FacepileSizes } from '../../components/application/avatar'
import { moreGroupsQuery } from '../../components/groups/more_groups'
import { userGroupQuery } from '../../components/groups/user_groups'
import { writeCacheAddGroupsQuery } from 'graphql/utils/writeCacheAddGroupsQuery'
import { writeCacheRemoveGroupsQuery } from 'graphql/utils/writeCacheRemoveGroupsQuery'

const LEAVE_GROUP_FRAGMENT = gql`
  fragment groupFragment on GroupInterface {
    activities {
      id
      visible
    }
    announcements {
      id
    }
    currentUserIsMember
    id
    isChapter
    members(first: $limit, noHiddenMembers: true) {
      nodes {
        avatarUrl
        id
      }
    }
    membersCount
    name
    ... on Group {
      currentUserMembership {
        id
        group {
          id
        }
        groupChapter {
          id
        }
      }
      userGroupChapter {
        id
      }
    }
  }
`

const LEAVE_GROUP = gql`
  mutation leaveGroup($groupId: ID!, $limit: Int!) {
    leaveGroup(groupId: $groupId) {
      errors
      group {
        ...groupFragment
      }
      chapter {
        ...groupFragment
      }
    }
  }

  ${LEAVE_GROUP_FRAGMENT}
`
function leaveGroupMutation({ groupId }) {
  return client.mutate({
    mutation: LEAVE_GROUP,
    update(
      _,
      {
        data: {
          leaveGroup: { group: newGroupLeave },
        },
      }
    ) {
      writeCacheAddGroupsQuery(newGroupLeave, 'noUserGroups', moreGroupsQuery)
      writeCacheRemoveGroupsQuery(newGroupLeave, 'userGroups', userGroupQuery)
    },
    variables: {
      groupId,
      limit: FacepileSizes.large,
    },
  })
}

export { leaveGroupMutation }
