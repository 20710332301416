// Vendor
import React from 'react'
import cx from 'classnames'
import { Card } from '@material-ui/core'
import PropTypes from 'prop-types'

const AdditionalInformationCard = ({
  children,
  className,
  dataTest,
  ...other
}) => {
  return (
    <Card
      {...other}
      className={cx('[ p-4 ] [ bg-black-6 ]', className)}
      data-test={dataTest}
    >
      {children}
    </Card>
  )
}

AdditionalInformationCard.propTypes = {
  /** render additionalinformationcard content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** for identification of card in testing */
  dataTest: PropTypes.string,
}

AdditionalInformationCard.defaultProps = {
  className: null,
  dataTest: 'additional-information-card-data-test',
}

export { AdditionalInformationCard }
