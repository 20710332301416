// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Wespire
import { acceptMution } from '../utilities/queries'
import Link from 'components/shared/link'
import { sharedTranslations } from 'components/shared/translations'
import { Button } from 'components/ui/Form/Button'
import { Drawer } from 'components/ui/Drawer'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { intl } from 'utilities/localization'
import { client } from 'utilities/we_apollo'
import { messages } from '../utilities/messages'
import { Paragraph } from 'components/ui/Typography/Paragraph'

const AcceptTermsOfService = ({
  brand,
  hasAcceptedPreviousDocuments,
  privacyPolicyId,
  termsOfServiceId,
}) => {
  const { formatMessage } = intl
  const [loading, setLoading] = useState(false)
  let message = messages.message

  if (hasAcceptedPreviousDocuments) {
    if (privacyPolicyId) {
      message = messages.termsAndPrivacyUpdated
    } else if (termsOfServiceId) {
      message = messages.termsUpdated
    }
  }

  const accept = () => {
    setLoading(true)
    let documentIds = []
    if (privacyPolicyId) documentIds.push(privacyPolicyId)
    if (termsOfServiceId) documentIds.push(termsOfServiceId)

    client
      .mutate({
        mutation: acceptMution,
        variables: { documentIds },
      })
      .then(({ data: { acceptBrandLegalDocuments } }) => {
        setLoading(false)

        if (acceptBrandLegalDocuments.errors) {
          return displayBanner({
            content: acceptBrandLegalDocuments.errors[0],
            variant: 'error',
          })
        }

        let searchPath = window.location.search.replace('?', '')
        window.location.href = `${searchPath}`
      })
      .catch(() => {
        setLoading(false)
        displayExceptionBanner()
      })
  }

  if (!privacyPolicyId && !termsOfServiceId) return null

  return (
    <Drawer dataTest="accept-terms-of-use">
      <div className="col-xs-12 col-md-10">
        <Paragraph className="pb-2" data-test="terms-of-service-message">
          {formatMessage(message, {
            brand,
            privacy: (
              <Link className="text-blue" target="_blank" to="/privacy">
                {sharedTranslations.privacyPolicy}
              </Link>
            ),
          })}
        </Paragraph>
      </div>
      <div className="[ col-xs-12 col-md-2 ] [ d-flex flex-column justify-content-center ]">
        <Button
          data-test="trigger-accept-document"
          disabled={loading}
          onClick={accept}
          variant="neutral"
        >
          {formatMessage(messages.accept)}
        </Button>
      </div>
    </Drawer>
  )
}

AcceptTermsOfService.propTypes = {
  brand: PropTypes.string.isRequired,
  hasAcceptedPreviousDocuments: PropTypes.bool.isRequired,
  privacyPolicyId: PropTypes.string,
  termsOfServiceId: PropTypes.string,
}

AcceptTermsOfService.defaultProps = {
  privacyPolicyId: null,
  termsOfServiceId: null,
}

export { AcceptTermsOfService }
