// Setup
import { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { sendLike, sendUnlike } from './utils'
import { intl } from 'utilities/localization'
import { formatPluralMessages } from 'utilities/localization'

const messages = defineMessages({
  like: {
    defaultMessage: 'like',
    id: 'likeButtonManager.like',
  },
  unlike: {
    defaultMessage: 'unlike',
    id: 'likeButtonManager.unlike',
  },
})

const pluralMessages = defineMessages({
  likes: {
    defaultMessage:
      '{count, plural, zero {likes} one {like} two {likes} few {likes} many {likes} other {likes}}',
    id: 'likeButtonManager.likes',
  },
})
const likeButtonManagerPluralTranslations = formatPluralMessages(pluralMessages)

const { formatMessage } = intl

/**
 * Container meant to wrap a like button. Provides it with props to enable the
 * creation/deletion of likes and to manage the state of the button.
 */
const LikeButtonManager = ({
  children,
  likableId,
  likableType,
  likeCount: initialLikeCount,
  likeId: initialLikeId,
}) => {
  const [likeCount, setLikeCount] = useState(initialLikeCount)
  const [likeId, setLikeId] = useState(parseInt(initialLikeId))
  const [isLoading, setIsLoading] = useState(false)
  const isLiked = !!likeId
  const buttonLabelKey = isLiked ? `unlike` : 'like'
  const buttonLabel = formatMessage(messages[buttonLabelKey])

  const handleLike = () => {
    sendLike({
      likableId: likableId,
      likableType: likableType,
      onError: () => {
        setIsLoading(false)
      },
      onSuccess: ({ likeId: newLikeId }) => {
        setLikeId(newLikeId)
        setLikeCount(likeCount + 1)
        setIsLoading(false)
      },
    })
  }

  const handleToggleLike = () => {
    setIsLoading(true)
    isLiked ? handleUnlike() : handleLike()
  }

  const handleUnlike = () => {
    setLikeId(false)
    sendUnlike({
      likeId: likeId,
      onError: () => {
        setIsLoading(false)
      },
      onSuccess: () => {
        setLikeCount(likeCount - 1)
        setLikeId(null)
        setIsLoading(false)
      },
    })
  }

  return children({
    buttonLabel,
    handleToggleLike,
    isLiked,
    isLoading,
    likeCount,
  })
}

LikeButtonManager.propTypes = {
  /** like button that should receive props from this component */
  children: PropTypes.func.isRequired,
  /** id of likable record, if one exists (for creating likes) */
  likableId: PropTypes.string,
  /** type of like record, if one exists (for creating likes) */
  likableType: PropTypes.string,
  /** total number of likes for likable record */
  likeCount: PropTypes.number.isRequired,
  /** id of like record, if one exists (for deleting likes) */
  likeId: PropTypes.string,
}

LikeButtonManager.defaultProps = {
  likableId: null,
  likableType: null,
  likeId: null,
}

export { LikeButtonManager, likeButtonManagerPluralTranslations }
