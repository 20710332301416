// Vendor
import { createTheme } from '@material-ui/core/styles'

// WeSpire
import { muiThemeOverrides } from 'components/application/layout/mui_themes'

const svgIconTheme = {
  ...muiThemeOverrides,
  overrides: {
    ...muiThemeOverrides.overrides,
    MuiSvgIcon: {
      root: {
        fontSize: 'inherit',
        height: '1em',
        width: '1em',
      },
    },
  },
}

export const muiSvgIconTheme = createTheme(svgIconTheme)
