import React, { useContext, useEffect } from 'react'
import BreadcrumbContext from '../contexts/BreadcrumbContext'

export const useCrumbs = (crumbs) => {
  const { setCrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    setCrumbs(crumbs)
    return () => setCrumbs([])
  }, [])
}
