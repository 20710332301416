// Vendor
import { createTheme } from '@material-ui/core/styles'

// WeSpire

import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from 'styles/variables'
import { muiThemeOverrides } from 'components/application/layout/mui_themes'

// 1. Equivalent to 1 column in a 1080px 12-col grid w/ 24px gutters
const buttonPadding = `${spacing[2]} ${spacing[3]}`
const boxShadow = '1px 1px 3px rgba(0,0,0,0.2)'
const buttonTheme = {
  ...muiThemeOverrides,
  overrides: {
    ...muiThemeOverrides.overrides,
    MuiButton: {
      contained: {
        boxShadow: boxShadow,
      },
      containedPrimary: {
        color: color.white,
      },
      outlined: {
        backgroundColor: color.white,
        borderColor: color['black-4'],
        boxShadow: boxShadow,
        padding: buttonPadding,
      },
      root: {
        borderRadius: borderRadius,
        fontSize: fontSize[4],
        fontWeight: fontWeight.bold,
        lineHeight: lineHeight.md,
        minWidth: '4.25rem', // 1
        padding: buttonPadding,
        textTransform: 'none',
        // eslint-disable-next-line sort-keys
        '&$disabled': {
          boxShadow: 'none',
        },
      },
      text: {
        padding: buttonPadding,
      },
      textPrimary: {
        color: color.orange,
        minWidth: 0,
      },
      textSecondary: {
        color: color.blue,
      },
    },
  },
}

export const muiButtonTheme = createTheme(buttonTheme)
