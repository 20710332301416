import { gql, useQuery } from '@apollo/client'

const ELIGIBLE_VOLUNTEERING_HOURS_QUERY = gql`
  query eligibleVolunteeringHoursQuery($ein: String!) {
    currentUser {
      givingaUser {
        grantDollarsDonatedThisYearFor(ein: $ein)
      }
      dollarsForDoersEligibleVolunteerHours(ein: $ein)
      volunteerHoursThisYear(ein: $ein)
    }
  }
`

export default function useEligibleVolunteeringHoursQuery(ein) {
  const {
    data: {
      currentUser: {
        givingaUser: { grantDollarsDonatedThisYearFor = 0 } = {},
        volunteerHoursThisYear = 0,
        dollarsForDoersEligibleVolunteerHours = 0,
      } = {},
    } = {},
  } = useQuery(ELIGIBLE_VOLUNTEERING_HOURS_QUERY, {
    variables: { ein },
  })
  return {
    grantDollarsDonatedThisYearFor,
    volunteerHoursThisYear,
    dollarsForDoersEligibleVolunteerHours,
  }
}
