// Vendor
import React from 'react'
import cx from 'classnames'
import { Field } from 'formik'

import Button from 'src/common/button/Button'

const InputGroupButton = ({
  buttonText,
  buttonVariant,
  buttonOnClick,
  className,
  explainer,
  name,
  placeholder,
  type = 'text',
}) => {
  return (
    <>
      <div className={cx(className, 'flex flex-row gap-2')}>
        <div className="basis-11/12">
          <Field
            className="w-full h-14 text-black-headline placeholder-gray-1 placeholder-opacity-50 border-[0.0625rem] border-gray-2 px-4 py-3 font-normal rounded-lg text-base font-light font-body"
            name={name}
            type={type}
            placeholder={placeholder}
          />
        </div>
        <div className="basis-1/12">
          <Button
            onClick={buttonOnClick}
            text={buttonText}
            variant={buttonVariant}
          />
        </div>
      </div>
      {explainer && (
        <p className="ml-2 mt-2 text-sm leading-4 text-gray-body font-extralight">
          {explainer}
        </p>
      )}
    </>
  )
}

export default InputGroupButton
