// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import v from 'voca'
import cx from 'classnames'

// WeSpire
import GuestLeaderForm from '/components/setup/group_leadership/guest_leader_form'
import GroupLeadershipInputs from '/components/setup/group_leadership/rails_inputs_generator'
import UserAutocomplete from '/components/form/user_autocomplete/rails.jsx'

const GroupLeadershipUserAutoComplete = ({ className, value, ...other }) => {
  const onHandleSelection = ({ newValue, deleted }) => {
    // Only needed when deleted items corresponds to actual role
    // leaders stored in BE and should be marked for deletion
    if (!v.isBlank(deleted) && !v.isBlank(deleted.role_id)) {
      setToDelete([...toDelete, deleted])
    }
    setValues(newValue)
  }

  const [autoCompleteRef, setAutocompleteRef] = useState({})
  const [values, setValues] = useState(value)
  const [toDelete, setToDelete] = useState([])

  return (
    <div className={cx('react-user-autocomplete', className)}>
      <GroupLeadershipInputs toDelete={toDelete} values={values} />
      <GuestLeaderForm
        autoCompleteRef={autoCompleteRef}
        className="my-5"
        onHandleSelection={onHandleSelection}
      />
      <div className="react">
        <UserAutocomplete
          {...other}
          includeCurrentUser
          innerRef={setAutocompleteRef}
          onHandleSelection={onHandleSelection}
          required={false}
          value={value}
        />
      </div>
    </div>
  )
}

GroupLeadershipUserAutoComplete.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
}

GroupLeadershipUserAutoComplete.defaultProps = {
  className: '',
  value: [],
}

export default GroupLeadershipUserAutoComplete
