import React from 'react'
import gql from 'graphql-tag'

import { client } from 'utilities/we_apollo'

const UserAuthenticatedQuery = gql`
  query userAuthenticated {
    currentUser {
      avatarUrl
      firstName
      id
      managementDropdownNavigationLinks {
        href
        title
      }
      name
      roleId
    }
  }
`

const getCurrentUser = async (setUser) => {
  const response = await client.query({
    query: UserAuthenticatedQuery,
  })

  const {
    data: { currentUser },
  } = response

  setUser(currentUser)
}

export default getCurrentUser
