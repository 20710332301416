// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'

// WeSpire
import { ACTIVITIES, ActivityGallery } from 'components/shared/activity_gallery'
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { displayBanner, maybeDisplayUserTour } from 'redux/dispatchers'
import { DocumentTitle } from 'components/shared/document_title'
import { EventMissingResourcePage } from 'components/events/event_missing_resource_page'
import Hero from 'components/ui/hero'
import HeroIcon from 'components/ui/hero/icon'
import { FacepileSizes } from 'components/application/avatar'
import Paper from '@material-ui/core/Paper'
import Stack from 'components/ui/stack'
import { TakeActionLink } from 'components/activities/link'
import WeQuery from 'components/application/we_query'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { isAfter } from 'utilities/date_utils'
import { ColumnHeader } from './column_header'
import { EventInfoSection } from './event_info_section'
import { EventNewsFeed } from './event_news_feed'
import { EventNewsFeedForm } from './event_news_feed_form'
import { ShiftTimeframe } from './shift_timeframe'
import { Button } from '@material-ui/core'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'
import { SharedNavBreadcrumb } from 'components/shared/nav_breadcrumb'
import { WordForScore } from 'components/queries/word_for_score'

export const EVENT_QUERY = gql`
  query participationEvent($id: ID!) {
    brandConfig {
      id
      canEarnPointsOnEvents
    }
    participationEvent(id: $id) {
      id
      contactEmail
      contactName
      name
      description
      details
      imageUrl
      fullAddress
      joinVirtuallyUrl
      organizationName
      endsAt
      guestsAllowed
      participants(limit: ${FacepileSizes.large}) {
        id
        avatarUrl
      }
      participantsCount
      shiftTimeframes {
        id
        start
        end
        registeredShift {
          id
        }
        registeredShiftParticipant {
          id
          didNotShow
          totalScore
        }
        registrationEnded
        shifts {
          id
          availableSlotsCount
          shiftFunction
        }
        participants(limit: ${FacepileSizes.small}) {
          id
          avatarUrl
        }
        participantsCount
        points
        pointsAwarded
        pointsForAttendance
        waitlistedShift {
          id
        }
        waitlistsActive
      }
      waitlistsEnabled
    }
  }
`

const messages = defineMessages({
  eventEnded: {
    defaultMessage:
      'This Event has ended, you can view active Events on the {takeActionLink}.',
    description:
      'Message to display in a banner indicating the event has ended',
    id: 'eventRegistrationForm.eventEnded',
  },
  eventGallery: {
    defaultMessage: 'Event gallery',
    id: 'eventRegistrationForm.eventGallery',
  },
  eventInformation: {
    defaultMessage: 'Event information',
    id: 'eventRegistrationForm.eventInformation',
  },
  eventNewsFeed: {
    defaultMessage: 'Event activity & discussion',
    id: 'eventRegistrationForm.eventNewsFeed',
  },
  hidePastEvents: {
    defaultMessage: 'Hide Past Events',
    id: 'eventRegistrationForm.hidePastEvents',
  },
  showPastEvents: {
    defaultMessage: 'Show Past Events',
    id: 'eventRegistrationForm.showPastEvents',
  },
  timeframes: {
    defaultMessage: 'timeframes',
    id: 'eventRegistrationForm.timeframes',
  },
  userTotalScore: {
    defaultMessage:
      'Congratulations! You have earned { totalScore, number } { wordForScore } for participating! Please share your comments and any photos below.',
    id: 'eventRegistrationForm.userTotalScore',
  },
})

export const EventRegistrationForm = ({ displayNotification, match }) => {
  const eventId = match.params.event_id

  const [showPastEvents, setShowPastEvents] = useState(false)

  const showUserHasEarnedPoints = (event) => {
    const totalScore = event.shiftTimeframes?.reduce((sum, shift) => {
      return sum + (shift?.registeredShiftParticipant?.totalScore ?? 0)
    }, 0)
    if (totalScore > 0) {
      displayBanner({
        content: (
          <>
            {intl.formatMessage(messages.userTotalScore, {
              totalScore,
              wordForScore: <WordForScore wordOnly />,
            })}
          </>
        ),
        variant: 'success',
      })
    }
  }

  const showEventEndedNotice = () => {
    displayBanner({
      content: (
        <>
          {intl.formatMessage(messages.eventEnded, {
            takeActionLink: <TakeActionLink isBannerLink />,
          })}
        </>
      ),
      variant: 'info',
    })
  }

  return (
    <WeQuery
      error={<EventMissingResourcePage />}
      loader={<CenteredPaddedLoadingIndicator />}
      query={EVENT_QUERY}
      variables={{ id: eventId }}
    >
      {({
        data: {
          brandConfig: { canEarnPointsOnEvents },
          participationEvent: event,
        },
      }) => {
        const ended = isAfter(event.endsAt)

        if (ended) {
          showEventEndedNotice()
        } else {
          showUserHasEarnedPoints(event)
        }

        return (
          <DocumentTitle title={`${event.name} • ${sharedTranslations.event}`}>
            <SharedNavBreadcrumb currentLinkName={event.name} />
            {maybeDisplayUserTour('eventPage')}

            <Hero
              icon={<HeroIcon iconName="activity_event" />}
              imgUrl={event.imageUrl}
              label={sharedTranslations.event}
              title={event.name}
              titleProps={{
                className: 'mb-5',
              }}
            />

            <Stack
              as={CenteredContent}
              className="[ position-relative px-3 | px-xl-0 ]"
              pull
              space={4}
            >
              <EventInfoSection
                contactEmail={event.contactEmail}
                contactName={event.contactName}
                description={event.description}
                displayNotification={displayNotification}
                ended={ended}
                fullAddress={event.fullAddress}
                guestsAllowed={event.guestsAllowed}
                joinVirtuallyUrl={event.joinVirtuallyUrl}
                organizationName={event.organizationName}
                participants={event.participants}
                participantsCount={event.participantsCount}
              />

              <div className="[ d-flex flex-column-reverse row | flex-lg-row ]">
                <div
                  className="[ d-flex col-12 flex-column | col-lg-6 ]"
                  data-test="shift-timeframes"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <ColumnHeader
                      title={intl.formatMessage(messages.timeframes)}
                    />
                    {event.shiftTimeframes.some((t) => isAfter(t.end)) && (
                      <Button
                        className="lh-sm text-capitalize"
                        color="secondary"
                        onClick={() => setShowPastEvents(!showPastEvents)}
                        size="small"
                      >
                        {showPastEvents
                          ? '- ' + intl.formatMessage(messages.hidePastEvents)
                          : '+ ' + intl.formatMessage(messages.showPastEvents)}
                      </Button>
                    )}
                  </div>

                  <Stack space={3}>
                    {event.shiftTimeframes
                      .filter(
                        (shiftTimeframe) =>
                          showPastEvents || !isAfter(shiftTimeframe.end)
                      )
                      .map((shiftTimeframe) => (
                        <ShiftTimeframe
                          canEarnPointsOnEvents={canEarnPointsOnEvents}
                          contactEmail={event.contactEmail}
                          contactName={event.contactName}
                          eventId={event.id}
                          key={shiftTimeframe.id}
                          shiftTimeframe={shiftTimeframe}
                          waitlistsEnabled={
                            event.waitlistsEnabled &&
                            shiftTimeframe.waitlistsActive
                          }
                        />
                      ))}
                  </Stack>
                </div>

                <div className="[ d-flex col-12 flex-column | col-lg-6 ] [ mb-4 ]">
                  <Stack space={5}>
                    <div data-test="event-details">
                      <ColumnHeader
                        title={intl.formatMessage(messages.eventInformation)}
                      />
                      <Paper
                        className="[ d-flex flex-column ] [ p-3 ]"
                        component="section"
                      >
                        <WysiwygContent>{event.details}</WysiwygContent>
                      </Paper>
                    </div>
                    <Stack space={3}>
                      <ColumnHeader
                        title={intl.formatMessage(messages.eventGallery)}
                      />
                      <ActivityGallery
                        activityFieldName={ACTIVITIES.participationEvent}
                        activityId={eventId}
                      />
                    </Stack>
                    <Stack data-test="event-news-feed" space={3}>
                      <ColumnHeader
                        title={intl.formatMessage(messages.eventNewsFeed)}
                      />
                      <EventNewsFeedForm eventId={eventId} />
                      <EventNewsFeed eventId={eventId} />
                    </Stack>
                  </Stack>
                </div>
              </div>
            </Stack>
          </DocumentTitle>
        )
      }}
    </WeQuery>
  )
}

EventRegistrationForm.propTypes = {
  displayNotification: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ event_id: PropTypes.string }).isRequired,
  }).isRequired,
}
