import AnalyticsManager from './analytics_manager'

export default class CampaignAnalytics extends AnalyticsManager {
  leaderboardFilterClicked = ({
    activityId,
    activityType,
    filterId,
    filterName,
    filterType,
  }) =>
    this.track('Leaderboard filter click', {
      activityId,
      activityType,
      filterId,
      filterName,
      filterType,
    })
}

export const instance = new CampaignAnalytics()
