// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// WeSpire
import Divider from 'components/ui/divider'
import {
  getDonationHistoryLink,
  getPrimaryNavLinks,
  getSecondaryNavLinks,
  getVolunteerHistoryLink,
} from 'components/shared/navigator/utils'
import PrimaryItem from './primary_item'
import SecondaryItem from './secondary_item'
import { SignOutButton } from 'components/application/sign_out_button'

export const SideNavContent = ({
  className,
  closeSideNav,
  ergsEnabled,
  givingEnabled,
  isOpen,
  linkPaths,
  location,
  volunteerHistoryEnabled,
  ...other
}) => {
  const currentUrl = location.pathname
  return (
    <Drawer
      className={className}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: 'op-60',
          },
        },
      }}
      onClose={closeSideNav}
      open={isOpen}
      {...other}
    >
      <div
        className="side-nav | py-2"
        onClick={closeSideNav}
        onKeyDown={closeSideNav}
        role="button"
        tabIndex={0}
      >
        <Divider className="mt-6" />
        <List component="nav">
          {getPrimaryNavLinks(ergsEnabled, linkPaths).map((link) => (
            <Fragment key={link.id}>
              {link.shouldDisplay && (
                <PrimaryItem
                  isActive={currentUrl.includes(link.href)}
                  link={link}
                />
              )}
            </Fragment>
          ))}
        </List>
        <Divider />
        <List component="nav" dense>
          {givingEnabled && (
            <SecondaryItem
              isActive={currentUrl.includes(getDonationHistoryLink.href)}
              link={getDonationHistoryLink}
            />
          )}
          {volunteerHistoryEnabled && (
            <SecondaryItem
              isActive={currentUrl.includes(getVolunteerHistoryLink.href)}
              link={getVolunteerHistoryLink}
            />
          )}
          {getSecondaryNavLinks(linkPaths).map((link) => (
            <SecondaryItem
              isActive={currentUrl.includes(link.href)}
              key={link.id}
              link={link}
            />
          ))}
          <SignOutButton
            as={ListItem}
            className="fs-1 fw-light text-black-3 text-transform-none"
          />
        </List>
      </div>
    </Drawer>
  )
}

SideNavContent.propTypes = {
  className: PropTypes.string,
  closeSideNav: PropTypes.func.isRequired,
  ergsEnabled: PropTypes.bool.isRequired,
  givingEnabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  linkPaths: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  volunteerHistoryEnabled: PropTypes.bool,
}

SideNavContent.defaultProps = {
  className: null,
  givingEnabled: false,
  volunteerHistoryEnabled: false,
}
