// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { Button } from 'components/ui/button'
import { intl } from 'utilities/localization'
import {
  sharedTranslations,
  sharedMessages,
} from 'components/shared/translations'

const loadMoreNumber = (
  pageSize,
  totalNewsfeedItemCount,
  newsfeedItemsCount
) => {
  const remainingItemsCount = totalNewsfeedItemCount - newsfeedItemsCount
  return Math.min(remainingItemsCount, pageSize)
}

const LoadMoreButton = ({
  className,
  loading,
  newsfeedItemsCount,
  onClick,
  pageSize,
  totalCount,
  type,
}) => {
  const { formatMessage } = intl
  const postsToLoadQuantity = loadMoreNumber(
    pageSize,
    totalCount,
    newsfeedItemsCount
  )

  return (
    <Button
      className={className}
      data-test="load-more-button"
      disabledWithFocus={loading}
      onClick={onClick}
      variant="neutral"
    >
      {loading
        ? `${sharedTranslations.loading}...`
        : formatMessage(sharedMessages.loadMore, {
            number: postsToLoadQuantity,
            post: type
              ? formatMessage(type, {
                  count: postsToLoadQuantity,
                })
              : formatMessage(sharedMessages.posts, {
                  count: postsToLoadQuantity,
                }),
          })}
    </Button>
  )
}

LoadMoreButton.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  newsfeedItemsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  type: PropTypes.object,
}

LoadMoreButton.defaultProps = {
  className: 'flex-grow',
  type: null,
}

export { LoadMoreButton }
