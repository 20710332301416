import React from 'react'
import { FormattedMessage } from 'react-intl'

export const validateValues = (values, setIsValid, givingaUser) => {
  const errors = {}

  if (!values.donationAmount) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Value is required"
        id="donationForm.valueIsRequired"
      />
    )
  } else if (parseInt(values.donationAmount) < 10) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Value must be greater than 10"
        id="donationForm.valueMustBeGreaterThan"
      />
    )
  } else if (values.donationAmount.toString().split('.')[1]?.length > 2) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="Value must have at most two decimal places"
        id="donationForm.valueMustHaveAtMostTwo"
      />
    )
  } else if (
    values.donationAmount > givingaUser.availableDonationFunds &&
    values.paymentMethod === 'grant_account'
  ) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="To use your personal grant account you can only donate a max of {amount}"
        id="donationForm.toUseYOurPersonalGrantAccount"
        values={{
          amount: givingaUser.availableDonationFunds,
        }}
      />
    )
  } else if (
    values.donationAmount > givingaUser.availableDonationFunds &&
    values.matched
  ) {
    errors.donationAmount = (
      <FormattedMessage
        defaultMessage="To use your match account you must donate {amount}"
        id="donationForm.toUseYourMatchAccount"
        values={{
          amount: givingaUser.availableDonationFunds,
        }}
      />
    )
  } else if (
    values.paymentMethod === 'grant_account' &&
    values.donationFrequency !== 'day'
  ) {
    errors.submissionError = (
      <FormattedMessage
        defaultMessage="You can't use your personal grant account for a recurring donation."
        id="donationForm.youCannotUseGrantOnRecurring"
      />
    )
      
  } else if (values.paymentMethod === 'grant_account' && values.matched) {
    errors.submissionError = (
      <FormattedMessage
        defaultMessage="You can't use your personal grant account and also match your donation at the same time."
        id="donationForm.youCannotUseYourPersonalGran"
      />
    )
      
  } else if (values.matched && values.donationFrequency !== 'day') {
    errors.submissionError = (
      <FormattedMessage
        defaultMessage="You can't match your donation on recurring donations"
        id="donationForm.youCannotMatchYourDonationOnRecurring"
      />
    )
      
  }

  setIsValid(Object.keys(errors).length === 0)

  return errors
}
