// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import v from 'voca'

// WeSpire
import Stack from 'components/ui/stack'
import { FormattedNumber } from 'react-intl'

/**
 * Renders a "stat" related to Volunteer History with accompanying label.
 */
export const VolunteerHistoryStat = ({ amount, label, ...other }) => (
  <Stack
    {...other}
    as="p"
    className={cx('[ col-sm-6 d-flex flex-column ] [ lh-xs ]', other.className)}
    data-test={`stat-${v.kebabCase(label)}`}
    space={1}
  >
    <span className="fs-6 fs-sm-7 text-orange">
      {<FormattedNumber value={amount} />}
    </span>
    <span className="fs-2 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase">
      {label}
    </span>
  </Stack>
)

VolunteerHistoryStat.propTypes = {
  /** primary number displayed at the top of stat */
  amount: PropTypes.number.isRequired,
  /** text rendered in a heading that describes main purpose of stat */
  label: PropTypes.string.isRequired,
}
