import AnalyticsManager from './analytics_manager'

export default class NewsFeedAnalytics extends AnalyticsManager {
  // User focused the comment box to create a new story for the event.
  commentBoxFocused = (activityId, activityType) =>
    this.track('Story comment box focused', { activityId, activityType })

  // User clicked the photo icon to add a photo to their comment.
  addPhotoClicked = (activityId, activityType) =>
    this.track('Story add photo clicked', { activityId, activityType })

  // User clicked the tag people icon to add tags to their comment.
  addTagsClicked = (activityId, activityType) =>
    this.track('Story add tags clicked', { activityId, activityType })

  // User focused a comment reply box to create a reply to story.
  commentReplyBoxFocused = (storyId) =>
    this.track('Story reply box focused', { storyId })

  // User clicked on a photo within a story to view the image in a modal.
  storyPhotoClicked = (storyId) =>
    this.track('Story photo clicked', { storyId })
}

export const instance = new NewsFeedAnalytics()
