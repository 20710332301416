// Vendor
import React from 'react'

// WeSpire
import { FormSubmitButton } from 'components/ui/Form/FormSubmitButton'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'draw a circle and its the earth',
    },
  },
  component: FormSubmitButton,
  title: 'UI/Form/FormSubmitButton',
}

const Template = (args) => <FormSubmitButton {...args} />

export const NotDisabledFormButton = Template.bind({})
NotDisabledFormButton.args = {
  disabled: false,
}

export const DisabledFormButton = Template.bind({})
DisabledFormButton.args = {
  disabled: true,
}

export const IsNotSubmitting = Template.bind({})
IsNotSubmitting.args = {
  variant: false,
}

export const isSubmitting = Template.bind({})
isSubmitting.args = {
  isLoading: true,
}
