// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import ImageButton from 'components/ui/image_button'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  viewImage: {
    defaultMessage: 'Click to view fullscreen image',
    id: 'activityGalleryItem.viewImage',
  },
})

const ActivityGalleryItem = ({ imageUrl, onClick }) => {
  const { formatMessage } = intl

  return (
    <li
      className="[ col-6 | col-md-3 ] [ mb-3 ] [ list-none ]"
      data-test="gallery-recent-images"
    >
      <ImageButton
        alt=""
        aspectRatio="1:1"
        crop="fill"
        customImage
        onClick={onClick}
        src={imageUrl}
        srText={formatMessage(messages.viewImage)}
      />
    </li>
  )
}

ActivityGalleryItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export { ActivityGalleryItem }
