// Vendor
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import { client } from 'utilities/we_apollo'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { DocumentTitle } from 'components/shared/document_title'
import { ErrorList } from 'components/form/error_list'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormLimitedTextField from 'components/form/limited_text_field'
import { FormPage } from 'components/form/form_page'
import { intl } from 'utilities/localization'
import UserAutocomplete from 'components/form/user_autocomplete'

const messages = defineMessages({
  howStandout: {
    defaultMessage: 'What did they do to stand out?',
    id: 'kudosPage.howStandout',
  },
  kudoCreatedSuccessfully: {
    defaultMessage:
      'You’ve successfully recognized {receiver} and we’ll let them know.',
    id: 'kudoFormPage.kudoCreatedSuccessfully',
  },
  kudoForm: {
    defaultMessage: 'Kudo Form',
    id: 'kudoFormPage.kudoForm',
  },
  operation: {
    defaultMessage: 'create a kudo',
    id: 'kudoFormPage.operation',
  },
  recognizeSomeone: {
    defaultMessage: 'Recognize Someone',
    id: 'kudoFormPage.recognizeSomeone',
  },
  whoRecognize: {
    defaultMessage: 'Who would you like to recognize?',
    id: 'kudoFormPage.whoRecognize',
  },
  whyAwesome: {
    defaultMessage: 'Tell Them They’re Awesome!',
    id: 'kudoFormPage.whyAwesome',
  },
})

const KudoFormPage = () => {
  const { formatMessage } = intl

  const CREATE_KUDO = gql`
    mutation createKudo($targetId: ID!, $message: String!) {
      createKudo(targetId: $targetId, message: $message) {
        errors
      }
    }
  `
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [recognition, setRecognition] = useState('')
  const [recipient, setRecipient] = useState(null)

  const handleSubmitForm = (values) => {
    let { receiver, message } = values

    setIsSubmitting(true)
    setIsSubmitDisabled(true)
    executeSubmitMutation({
      // remove any spaces at the beginning or end of input
      message: message.trim(),
      receiver: receiver,
    })
  }

  const executeSubmitMutation = (attributes) => {
    const receiverId = parseInt(attributes.receiver[0].value)
    client
      .mutate({
        mutation: CREATE_KUDO,
        variables: {
          message: attributes.message,
          targetId: receiverId,
        },
      })
      .then(
        ({
          data: {
            createKudo: { errors },
          },
        }) => {
          setIsSubmitting(false)
          setIsSubmitDisabled(false)
          if (errors.length === 0) {
            displayBanner({
              content: formatMessage(messages.kudoCreatedSuccessfully, {
                receiver: attributes.receiver[0].label,
              }),
              variant: 'success',
            })
            // the use effect values do not update with user input unless manually set
            // in the code
            setRecipient(attributes.receiver)

            // includes as space so that the field does not turn red as it's
            // required
            setRecognition(' ')
            setRecipient([])
          } else {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        setIsSubmitDisabled(false)
        displayExceptionBanner({
          operation: formatMessage(messages.operation),
        })
      })
  }

  return (
    <DocumentTitle title={formatMessage(messages.kudoForm)}>
      <CenteredContent>
        <FormPage
          data-test="heading"
          heading={formatMessage(messages.recognizeSomeone)}
          onValidSubmit={handleSubmitForm}
        >
          <UserAutocomplete
            data-test="who-recognized"
            multiple={false}
            name="receiver"
            required
            setValue={setRecipient}
            textFieldProps={{
              label: formatMessage(messages.whoRecognize),
              margin: 'dense',
            }}
            value={recipient}
          />
          <FormLimitedTextField
            data-test="message"
            label={formatMessage(messages.howStandout)}
            maxLength={175}
            multiline
            name="message"
            required
            setValue={setRecognition}
            value={recognition}
          />
          <FormActionBar disabled={isSubmitDisabled}>
            <FormActionBarSubmitButton
              className="ml-auto"
              data-test="submit"
              isSubmitting={isSubmitting}
            >
              {formatMessage(messages.whyAwesome)}
            </FormActionBarSubmitButton>
          </FormActionBar>
        </FormPage>
      </CenteredContent>
    </DocumentTitle>
  )
}

export { KudoFormPage }
