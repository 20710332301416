import React from 'react'
import { FormattedMessage } from 'react-intl'

const PaymentSource = ({ transaction }) => {
  return (
    <>
      <div className="inline">
        <span className="body-medium-semibold text-black-3">
          <FormattedMessage
            id="donationHistory.paymentSource"
            defaultMessage="Payment source"
          />
          :
        </span>
      </div>
      <div className="inline">
        <span className="body-medium-semibold">
          {transaction.paymentMethod}
        </span>
      </div>
    </>
  )
}

export default PaymentSource
