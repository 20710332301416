// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { DisableButton } from 'components/ui/Form/DisableButton'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  buttonText: {
    defaultMessage: 'Submit',
    description: 'Default label for a button that submits a form',
    id: 'formSubmitButton.buttonText',
  },
})

const { formatMessage } = intl

/**
 * Use vs Button component
 * - This component may be combined with the ``<Button/>` in the future.
 * - The documentation for that component largely applies to this one as well.
 * The main difference is that this component has it's disabled and loading
 * state included.
 */
const FormSubmitButton = ({
  children,
  className,
  dataTest,
  disabled,
  isSubmitting,
  ...other
}) => (
  <DisableButton
    {...other}
    className={className}
    data-test={dataTest}
    disabled={disabled}
    isLoading={isSubmitting}
    type="submit"
  >
    {children}
  </DisableButton>
)

FormSubmitButton.propTypes = {
  /** render button content */
  children: PropTypes.node,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** for identification of button in testing */
  dataTest: PropTypes.string,
  /** disable the button — to temporarily disable it use `<DisableButton>` */
  disabled: PropTypes.bool,
  /** indicate if form input is in the process of being submitted */
  isSubmitting: PropTypes.bool,
}

FormSubmitButton.defaultProps = {
  children: formatMessage(messages.buttonText),
  className: null,
  dataTest: 'form-submit-button-test',
  disabled: false,
  isSubmitting: false,
}

export { FormSubmitButton }
