// Default State
const defaultState = {
  isNative: false,
  token: '',
}

// Action Creators
export function setNative(isNative) {
  return {
    isNative,
    type: 'wespire/SET_NATIVE',
  }
}

export function setToken(token) {
  return {
    token,
    type: 'wespire/SET_TOKEN',
  }
}

// Reducer
const session = (state = defaultState, action) => {
  switch (action.type) {
    case 'wespire/SET_NATIVE':
      return { ...state, isNative: action.isNative }
    case 'wespire/SET_TOKEN':
      return { ...state, token: action.token }
    default:
      return state
  }
}

export default session
