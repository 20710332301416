// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import { NewsFeedItem } from './item'

const NewsFeedItems = ({ className, items, showCommentContext }) => (
  <ul className={cx('list-none pl-0', className)}>
    {items.map((item) => (
      <NewsFeedItem
        actionTitle={item.title}
        as="li"
        className="mb-3"
        commentable={item.commentable}
        comments={item.comments}
        createdAt={item.createdAt}
        currentUserLikeId={item.currentUserLikeId}
        endsAt={item.endsAt}
        imageUrl={item.imageUrl}
        key={item.id}
        likableId={item.likableId}
        likableType={item.likableType}
        likeCount={item.likeCount}
        message={item.message}
        orgName={item.orgName}
        showCommentContext={showCommentContext}
        startsAt={item.startsAt}
        storyId={item.storyId}
        taggedUsers={item.taggedUsers}
        thumbnailUrl={item.thumbnailUrl}
        type={item.type}
        user={item.user}
        verb={item.verb}
      />
    ))}
  </ul>
)

NewsFeedItems.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  showCommentContext: PropTypes.bool,
}

NewsFeedItems.defaultProps = {
  className: null,
  showCommentContext: false,
}

export { NewsFeedItems }
