// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { FormDetail, FormDetails } from 'components/form/form_details'
import Paper from '@material-ui/core/Paper'
import ParticipantsSummary from 'components/application/participants_summary'
import ShareLink from 'components/ui/share_link'
import Stack from 'components/ui/stack'
import { EventContactFormDetail } from './event_contact_form_detail'
import { eventsTranslations } from 'components/events/shared_translations'
import ExternalLink from 'components/ui/external_link'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  copiedUrl: {
    defaultMessage: 'Event URL copied to clipboard!',
    id: 'eventInfoSection.copiedUrl',
  },
  shareEvent: {
    defaultMessage: 'share Event',
    id: 'eventInfoSection.shareEvent',
  },
})

export const EventInfoSection = ({
  contactEmail,
  contactName,
  description,
  displayNotification,
  ended,
  fullAddress,
  guestsAllowed,
  joinVirtuallyUrl,
  organizationName,
  participants,
  participantsCount,
}) => (
  <Paper
    className="d-flex flex-sm-row flex-column justify-content-center py-3"
    component="section"
    data-test="event-about-section"
  >
    <Stack
      className="[ d-flex flex-column flex-grow w-100 ] [ px-4 py-2 ]"
      space={5}
    >
      {description && <p>{description}</p>}

      <FormDetails>
        {organizationName && (
          <FormDetail label={sharedTranslations.organization}>
            {organizationName}
          </FormDetail>
        )}

        {fullAddress && (
          <FormDetail label={eventsTranslations.location}>
            {fullAddress}
          </FormDetail>
        )}

        {joinVirtuallyUrl && (
          <FormDetail label={eventsTranslations.joinVirtually}>
            <ExternalLink
              className="text-lowercase word-break-all"
              to={joinVirtuallyUrl}
            />
          </FormDetail>
        )}

        {(contactEmail || contactName) && (
          <EventContactFormDetail email={contactEmail} name={contactName} />
        )}

        <FormDetail
          data-test="guests-welcome"
          label={eventsTranslations.guestsWelcome}
        >
          {guestsAllowed ? sharedTranslations.yes : sharedTranslations.no}
        </FormDetail>
      </FormDetails>
    </Stack>
    <div className="[ border-left ] [ show-sm-up ]" />
    <Stack className="[ flex-shrink-none ] [ px-4 py-2 ]" space={3}>
      <ParticipantsSummary
        avatarImages={participants.map((user) => user.avatarUrl)}
        canParticipate={!ended}
        participantsCount={participantsCount}
        variant={'stacked'}
      />

      <ShareLink
        buttonLabel={intl.formatMessage(messages.shareEvent)}
        buttonProps={{ variant: 'outlined' }}
        className="mnw-2"
        displayNotification={displayNotification}
        notificationMessage={intl.formatMessage(messages.copiedUrl)}
        url={window.location.href}
      />
    </Stack>
  </Paper>
)

EventInfoSection.propTypes = {
  contactEmail: PropTypes.string,
  contactName: PropTypes.string,
  description: PropTypes.string.isRequired,
  displayNotification: PropTypes.func.isRequired,
  ended: PropTypes.bool.isRequired,
  fullAddress: PropTypes.string,
  guestsAllowed: PropTypes.bool.isRequired,
  joinVirtuallyUrl: PropTypes.string,
  organizationName: PropTypes.string,
  participants: PropTypes.array.isRequired,
  participantsCount: PropTypes.number.isRequired,
}

EventInfoSection.defaultProps = {
  contactEmail: null,
  contactName: null,
  fullAddress: null,
  joinVirtuallyUrl: null,
  organizationName: null,
}
