import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  chapter: {
    // eslint-disable-next-line formatjs/enforce-plural-rules
    defaultMessage: '{count, plural, one {Chapter} other {Chapters} }',
    id: 'groupManagementUtils.chapter',
  },
  group: {
    // eslint-disable-next-line formatjs/enforce-plural-rules
    defaultMessage: '{count, plural, one {Group} other {Groups} }',
    id: 'groupManagementUtils.group',
  },
})

export const groupOrChapterLabel = ({ isChapter }, isPlural = false) => {
  if (isChapter) {
    return formatMessage(messages.chapter, { count: isPlural ? 2 : 1 })
  }
  return formatMessage(messages.group, { count: isPlural ? 2 : 1 })
}
