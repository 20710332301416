// Setup
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'

const { formatMessage } = intl

const ListOrdering = ({ onUpdatePositionClick, recordId, recordName }) => (
  <>
    <IconButton
      data-test="move-item-up"
      onClick={() =>
        onUpdatePositionClick({ operator: 'MOVE_HIGHER', recordId, recordName })
      }
    >
      <Icon
        className="fs-6"
        iconName="triangle_up"
        title={formatMessage(sharedMessages.moveItemUp, {
          title: recordName,
        })}
      />
    </IconButton>
    <IconButton
      data-test="move-item-down"
      onClick={() =>
        onUpdatePositionClick({ operator: 'MOVE_LOWER', recordId, recordName })
      }
    >
      <Icon
        className="fs-6"
        iconName="triangle_down"
        title={formatMessage(sharedMessages.moveItemDown, {
          title: recordName,
        })}
      />
    </IconButton>
  </>
)

ListOrdering.propTypes = {
  onUpdatePositionClick: PropTypes.func.isRequired,
  recordId: PropTypes.string.isRequired,
  recordName: PropTypes.string.isRequired,
}

export { ListOrdering }
