// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { FormDetail } from 'components/form/form_details/form_detail'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  contact: {
    defaultMessage: 'contact',
    id: 'eventContactFormDetail.contact',
  },
})

/**
 * Renders details about the person that users registering for an Event should
 * contact if they have questions about the Event. Can render properly without
 * name or email. You should not try to render this component if you have
 * neither name nor email, because it will just render a <FormDetail> with an
 * empty space in it.
 */
export const EventContactFormDetail = ({ email, name, ...other }) => (
  <FormDetail
    {...other}
    data-test="registration-contact"
    label={intl.formatMessage(messages.contact)}
  >
    {email && (
      <a className="text-link" href={`mailto:${email}`}>
        {name ? <span className="text-capitalize">{name}</span> : email}
      </a>
    )}
  </FormDetail>
)

EventContactFormDetail.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
}

EventContactFormDetail.defaultProps = {
  email: null,
  name: null,
}
