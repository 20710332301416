// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { withFormsy } from 'formsy-react'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { labelToHintId } from 'components/form/fieldset'
import Stack from 'components/ui/stack'
import { getFieldValidationAttrs } from './utils'

const { formatMessage } = intl

const messages = defineMessages({
  required: {
    defaultMessage: 'This field is required',
    id: 'formRadioGroupValidations.required',
  },
})

/**
 * FormRadioGroup sets up a formsy radio button field.
 *
 * The two required props not provided by formsy are:
 *    name    -- The formsy model name for this field.
 *    options -- An array of {label, value} options. Each representing a single
 *               radio button.
 *
 * Other noteworthy properties are:
 *    onChange        -- Called when ever the value changes and passed the new value.
 *    radioGroupProps -- Properties passed to RadioGroup.
 *    value           -- Default value.
 */
export class FormRadioGroupUnwrapped extends Component {
  handleChangeValue = (event) => {
    this.props.setValue(event.currentTarget.value)
    if (this.props.onChange) {
      this.props.onChange(event.currentTarget.value)
    }
  }

  render() {
    let {
      className,
      errorMessage,
      isFormSubmitted,
      isPristine,
      isValid,
      label,
      labelProps,
      name,
      options,
      radioGroupProps,
      showRequired,
      value,
    } = this.props

    let helperText = this.props.helperText
    let error = false

    if (showRequired) {
      errorMessage = formatMessage(messages.required)
    }

    if ((!isPristine || isFormSubmitted) && !isValid) {
      error = true
    }

    if (error) {
      helperText = errorMessage
    }

    const validationTextProps = getFieldValidationAttrs(name, error)

    // Note: Defaulting to empty string is needed to prevent Material-UI
    // from complaining about switching from uncontrolled to controlled.

    const hintId = labelToHintId(label)
    const ariaProps = helperText ? { 'aria-describedby': hintId } : {}

    return (
      <FormControl {...ariaProps} className={className} component="fieldset">
        <Stack space={2}>
          <FormLabel
            {...labelProps}
            className="fs-2 fw-semi-bold text-black-1"
            component="legend"
          >
            {label}
          </FormLabel>
          {helperText && (
            <FormHelperText
              className="d-flex align-items-center | lh-md"
              error={error}
              id={hintId}
              {...validationTextProps}
            >
              {error && (
                <Icon className="fs-1 mr-1" iconName="warning" title="" />
              )}
              {helperText}
            </FormHelperText>
          )}
        </Stack>
        <RadioGroup
          {...radioGroupProps}
          name={name}
          onChange={this.handleChangeValue}
          value={value}
        >
          {options.map((option) => (
            <FormControlLabel
              control={<Radio color="primary" name={name} />}
              key={option.label}
              label={<span className="fs-2">{option.label}</span>}
              value={option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }
}

FormRadioGroupUnwrapped.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.node,
  isFormSubmitted: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  radioGroupProps: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  showRequired: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

FormRadioGroupUnwrapped.defaultProps = {
  className: null,
  errorMessage: null,
  helperText: null,
  labelProps: {},
  onChange: null,
  radioGroupProps: {},
  value: null,
}

export const FormRadioGroup = withFormsy(FormRadioGroupUnwrapped)
