// Vendor
import React from 'react'

// WeSpire
import { MarkdownText } from 'components/ui/Typography/MarkdownText/MarkdownText'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue:
        'Component support markdown and allow for **bold** text and _italics_, as well as [links](https://en.wikipedia.org/wiki/Markdown). `Inline` code snippets are also supported, as well as code blocks PROVIDED they start on their own line',
    },
  },
  component: MarkdownText,
  title: 'UI/Typography/MarkdownText',
}

const Template = (args) => <MarkdownText {...args} />

export const MarkdownMarkdown1 = Template.bind({})
