// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { CREATE_GROUP_STORY } from '../utilities/mutations'
import { NewsfeedForm } from 'components/form/newsfeed_form'

const GroupNewsfeedForm = ({ className, feedQuery, groupId }) => {
  const refetchQueries = [{ query: feedQuery, variables: { id: groupId } }]

  return (
    <NewsfeedForm
      className={className}
      createStoryMutation={CREATE_GROUP_STORY}
      mutationName="createGroupStory"
      refetchQueries={refetchQueries}
      storyableId={groupId}
    />
  )
}

GroupNewsfeedForm.propTypes = {
  className: PropTypes.string,
  feedQuery: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
}

GroupNewsfeedForm.defaultProps = {
  className: '',
}

export { GroupNewsfeedForm }
