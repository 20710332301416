// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

// WeSpire
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'
import { sharedTranslations } from 'components/shared/translations'
import { fetch } from './utils'

export const SharedNavBreadcrumb = ({ currentLinkName }) => {
  const location = useLocation()

  const { fromGroupId, fromChannelId, breadcrumbLink } = location?.state ?? {}

  const [fetchStatus, setFetchStatus] = useState('pending')
  const [groupLink, setGroupLink] = useState(null)
  useEffect(() => {
    if (breadcrumbLink) {
      setGroupLink([breadcrumbLink])
    } else {
      if (fromChannelId === 'currentUser') {
        setGroupLink([
          {
            href: `/take_action/${fromChannelId}`,
            text: sharedTranslations.myActivities,
          },
        ])
      } else {
        fetch(fromChannelId, fromGroupId, setFetchStatus, setGroupLink)
      }
    }
  }, [fromGroupId, fromChannelId, breadcrumbLink])

  const links = [
    ...(groupLink ?? [
      {
        href: '/take_action',
        text: intl.formatMessage(sharedMessages.takeAction),
      },
    ]),
    {
      href: location.pathname,
      text: currentLinkName,
    },
  ]

  return fetchStatus === 'loading' ? null : (
    <NavBreadcrumb
      className="nav-breadcrumbs bg-white lh-xs px-2 py-3"
      links={links}
    />
  )
}

SharedNavBreadcrumb.propTypes = {
  currentLinkName: PropTypes.string.isRequired,
}
