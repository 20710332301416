// Vendor
import React from 'react'
import PropTypes from 'prop-types'

const ActivitiesEmptyState = ({ testName, text }) => (
  <p className="fs-5 fw-semi-bold px-2 py-5 text-center" data-test={testName}>
    <span className="d-inline-block" style={{ maxWidth: '28rem' }}>
      {text}
    </span>
  </p>
)

ActivitiesEmptyState.propTypes = {
  testName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default ActivitiesEmptyState
