import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

export const GIVINGA_USER_QUERY = gql`
  query givingaUser {
    brandConfig {
      id
      enablePaidVolunteerHours
    }
    currentUser {
      givingaUser {
        allDonationsToDate
        availableDonationFunds
        companyDonationsToDate
        donatedOrganizationsCountThisYear
        totalDonationTransactions
        totalDonationTransactionsThisYear
        id
        companyDonationsThisYear
        employeeAccountBalance
        impactedCharities {
          address {
            city
            country {
              displayName
            }
            state
          }
          ein
          id
          name
          matched
          tagLine
          mission
        }
        completedTransactions {
          accountType
          activityId
          amountPaid
          charityName
          clearedDate
          donationDate
          donationAmount
          ein
          givingActivityName
          id
          matchedTransaction {
            matchedAmount
            requestedAmount
            status
          }
          offline
          paymentMethod
          paymentStatus
        }
        pendingTransactions {
          accountType
          activityId
          amountPaid
          charityName
          clearedDate
          donationDate
          donationAmount
          ein
          givingActivityName
          id
          matchedTransaction {
            matchedAmount
            requestedAmount
            status
          }
          offline
          paymentMethod
          paymentStatus
        }
        scheduledTransactions {
          accountType
          activityId
          amountPaid
          charityName
          clearedDate
          donationDate
          donationAmount
          ein
          givingActivityName
          id
          matchedTransaction {
            matchedAmount
            requestedAmount
            status
          }
          offline
          paymentMethod
          paymentStatus
        }
        transactions {
          accountType
          activityId
          amountPaid
          charityName
          clearedDate
          donationDate
          donationAmount
          ein
          givingActivityName
          id
          matchedTransaction {
            matchedAmount
            requestedAmount
            status
          }
          offline
          paymentMethod
          paymentStatus
        }
      }
      id
    }
    recurringDonationsEnabled
  }
`

const useGivingaUserQuery = () => {
  const [givingaUser, setGivingaUser] = useState({})

  const { loading, error, data } = useQuery(GIVINGA_USER_QUERY)

  useEffect(() => {
    if (!loading && !error) {
      const { givingaUser } = data.currentUser
      setGivingaUser(givingaUser)
    }
  }, [loading, error, data])

  return {
    data,
    givingaUser,
    error,
    loading,
  }
}

export default useGivingaUserQuery
