// Vendor
import { createStore } from 'redux'
import { createBrowserHistory } from 'history'

// WeSpire
import createRootReducer from 'redux/reducers/index'

export const history = createBrowserHistory()

const store = createStore(
  createRootReducer(history), // root reducer with router state
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
