const pageIndexZeroValidation = (answerState) => {
  let response = true
  if (answerState.country === '') {
    response = false
  }

  if (answerState.country === 'US' && answerState.postalCode.length < 5) {
    response = false
  }

  if (answerState.country === 'CA') {
    if (answerState.postalCode.length < 6) {
      response = false
    }
    if (answerState.postalCode.match(/[\W_]/)) {
      response = false
    }
  }
  return response
}

export default pageIndexZeroValidation
