// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Paper from '@material-ui/core/Paper'

// WeSpire
import AboutContent from './content'
import { IdeaBoardParticipation } from 'components/idea_board/participation'

const About = (props) => {
  return (
    <Paper
      className="[ d-flex flex-column | flex-md-row ] [ px-3 py-4 ]"
      component="section"
    >
      <AboutContent
        className="w-100 mxw-8"
        description={props.description}
        endsAt={props.endsAt}
        startsAt={props.startsAt}
      />
      <div className="[ border-left mx-4 ] [ show-md-up ]" />
      <IdeaBoardParticipation
        avatars={props.avatars}
        className="[ w-100 mxw-3 ] [ mt-4 mt-md-0 ]"
        participantsCount={props.participantsCount}
        totalIdeasSubmitted={props.totalIdeasSubmitted}
        totalVotes={props.totalVotes}
      />
    </Paper>
  )
}

About.propTypes = {
  avatars: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  endsAt: PropTypes.string.isRequired,
  participantsCount: PropTypes.number.isRequired,
  startsAt: PropTypes.string.isRequired,
  totalIdeasSubmitted: PropTypes.number.isRequired,
  totalVotes: PropTypes.number.isRequired,
}

export default About
