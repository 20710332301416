import { client } from 'utilities/we_apollo'

function writeCacheAddGroupsQuery(group, groupFilter, query) {
  try {
    const groupsQueryObject = {
      query: query,
      variables: { groupFilter: groupFilter },
    }

    const groups = client.readQuery(groupsQueryObject)

    client.writeQuery({
      data: {
        ...groups,
        groups: [...(groups?.groups || []), group],
      },
      ...groupsQueryObject,
    })

    return true
  } catch (error) {
    return false
  }
}

export { writeCacheAddGroupsQuery }
