import { client } from 'utilities/we_apollo'
import { GET_CAMPAIGN_NEWSFEED_ITEMS } from 'graphql/queries'
import { sortByNewest } from 'utilities/sort'

export const updateCacheActivityNewsfeedWithEvents = (
  campaignId,
  newsfeedItems = []
) => {
  try {
    const campaignConfig = {
      query: GET_CAMPAIGN_NEWSFEED_ITEMS,
      variables: {
        campaignId,
      },
    }

    const { campaign } = client.readQuery(campaignConfig)

    const data = {
      campaign: {
        ...campaign,
        newsfeed: {
          ...campaign.newsfeed,
          nodes: [
            ...sortByNewest(newsfeedItems, 'createdAt'),
            ...campaign.newsfeed.nodes,
          ],
          totalCount: campaign.newsfeed.totalCount + newsfeedItems.length,
        },
      },
    }

    client.writeQuery({
      data,
      ...campaignConfig,
    })

    return true
  } catch (error) {
    // No event to add
    return false
  }
}
