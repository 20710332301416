// Vendor
import React from 'react'

// WeSpire
import { Label } from 'components/ui/Typography/Label'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'this is a Label component',
    },
  },
  component: Label,
  title: 'UI/Typography/Label',
}

const Template = (args) => <Label {...args} />

export const Label1 = Template.bind({})
