// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import cx from 'classnames'
import Scroll from 'react-scroll'
import { Waypoint } from 'react-waypoint'

// WeSpire
import { ActionBody } from './action_body'
import { ActionLogProgressForm } from './action_log_progress_form'
import ActionProgressBar from './action_progress_bar'
import ActionSummary from './action_summary'
import { ActionTimeframeModel } from './action_timeframe_model'
import { ActionUserTargetForm } from './action_user_target_form'
import { CampaignModel } from './campaign_model'
import Divider from 'components/ui/divider'
import ExpandIcon from 'components/ui/expand_icon'
import { getUrlParams } from 'utilities/get_url_params'
import { ACTION_TRACKING_TYPE_DAILY, panelBorderClasses } from './utils'
import { scrollDurations } from 'styles/variables'
import Stack from 'components/ui/stack'
import { withActionMutation } from './with_action_mutation'
import WysiwygContent from 'components/ui/wysiwyg_content'

/** Action UI where details are toggled via Accordion */
export const ActionPanelBase = ({
  action,
  campaign,
  isSubmitDisabled,
  isSubmitting,
  onActionSubmit,
}) => {
  const {
    accumulationActionDailyTrackingTarget,
    accumulationActionTrackingType,
    allowUserToOverrideTargetAmount,
    availableCategories,
    conditionMetricName,
    cooldownEndsAt,
    dailyMaxLogging,
    description,
    endsAt,
    gracePeriodEndsAt,
    hasCarbonImpact,
    hasCustomTimeframe,
    hasOverrideTargetAmount,
    id: actionId,
    isAccumulationAction,
    name,
    points,
    progressCategory,
    progressMeasuredAs,
    progressTargetValue,
    required,
    startsAt,
    status,
    userCompleted,
    userOverrideTargetMaxAmount,
    userOverrideTargetMinAmount,
    userProgress,
    userProgressActivityLogs,
  } = action
  const anchor = /<a /g
  // Open links in new tab
  const descriptionNew = description.replace(
    anchor,
    `$& target="_blank" rel="noopener noreferrer "`
  )

  // Make sure the left border provided by Paper is visible, i.e. the blue
  // background does not cover it.
  const panelFooterStyle = { marginLeft: required ? undefined : '1px' }

  const actionTimeframe = new ActionTimeframeModel({
    endsAt,
    gracePeriodEndsAt,
    startsAt,
  })

  const componentScrollId = `action-panel-anchor-${actionId}`
  const queryParamId = getUrlParams().action
  const isDeepLinked = queryParamId === actionId
  const [didEnterViewport, setDidEnterViewport] = useState(false)
  const [didEnterViewportPreviously, setDidEnterViewportPreviously] =
    useState(false)

  if (isDeepLinked) {
    window.setTimeout(() => {
      Scroll.scroller.scrollTo(componentScrollId, {
        duration: scrollDurations.EXPAND,
        offset: scrollDurations.OFFSET, // To account for fixed nav bar
        smooth: 'easeInOutCubic',
      })
    }, scrollDurations.SCROLL_DELAY)
  }

  const handleEnter = () => {
    setDidEnterViewport(true)
    window.setTimeout(
      () => setDidEnterViewportPreviously(true),
      scrollDurations.FLASH_HIGHLIGHT
    )
  }

  const hasFlashHighlight =
    isDeepLinked && didEnterViewport && !didEnterViewportPreviously

  return (
    <Scroll.Element name={componentScrollId}>
      <Waypoint onEnter={handleEnter} />
      <Accordion
        classes={{
          expanded: cx({
            'flash-highlight': hasFlashHighlight,
          }),
        }}
        data-test="action-panel"
        defaultExpanded={isDeepLinked}
      >
        {/* Summary */}
        <AccordionSummary
          classes={{ content: 'flex-column stack stack--4' }}
          className={cx('px-3', ...panelBorderClasses(required))}
        >
          <div className="d-flex align-items-center">
            <ActionSummary
              actionTimeframe={actionTimeframe}
              className="mr-2"
              component="h3"
              cooldownEndsAt={cooldownEndsAt}
              hasCarbonImpact={hasCarbonImpact}
              hasCustomTimeframe={hasCustomTimeframe}
              isAccumulationAction={isAccumulationAction}
              name={name}
              points={points}
              required={required}
              status={status}
              userCompleted={userCompleted}
            />
            <ExpandIcon />
          </div>
          {isAccumulationAction && (
            <ActionProgressBar
              progressTargetValue={
                accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY
                  ? accumulationActionDailyTrackingTarget
                  : progressTargetValue
              }
              userProgress={userProgress}
            />
          )}
        </AccordionSummary>

        {/* Details */}
        <div data-test="action-panel-details">
          <AccordionDetails
            className={cx('flex-column p-3', ...panelBorderClasses(required))}
          >
            <WysiwygContent>{descriptionNew}</WysiwygContent>
          </AccordionDetails>

          {isAccumulationAction && allowUserToOverrideTargetAmount && (
            <div className="bg-blue-2" style={{ ...panelFooterStyle }}>
              <Divider />
              <ActionUserTargetForm
                actionId={actionId}
                campaignId={campaign.id}
                className={cx(
                  'd-flex align-items-center p-3',
                  ...panelBorderClasses(required, true)
                )}
                metricName={conditionMetricName || progressMeasuredAs}
                userOverrideTargetMaxAmount={userOverrideTargetMaxAmount}
                userOverrideTargetMinAmount={userOverrideTargetMinAmount}
                userProgress={userProgress}
              />
            </div>
          )}

          <Divider />

          <div style={{ ...panelFooterStyle }}>
            <Stack
              className={cx(
                '[ d-flex align-items-center ] [ bg-blue-2 px-3 py-4  ]',
                ...panelBorderClasses(required, true)
              )}
            >
              {isAccumulationAction ? (
                <ActionLogProgressForm
                  {...action}
                  accumulationActionDailyTrackingTarget={
                    accumulationActionDailyTrackingTarget
                  }
                  accumulationActionTrackingType={
                    accumulationActionTrackingType
                  }
                  actionId={actionId}
                  actionTimeframe={actionTimeframe}
                  availableCategories={availableCategories}
                  campaign={campaign}
                  conditionMetricName={conditionMetricName}
                  dailyMaxLogging={dailyMaxLogging}
                  hasOverrideTargetAmount={hasOverrideTargetAmount}
                  isSubmitDisabled={isSubmitDisabled}
                  progressCategory={progressCategory}
                  progressMeasuredAs={progressMeasuredAs}
                  progressTargetValue={progressTargetValue}
                  userProgress={userProgress}
                  userProgressActivityLogs={userProgressActivityLogs}
                />
              ) : (
                <ActionBody
                  action={action}
                  actionTimeframe={actionTimeframe}
                  campaign={campaign}
                  hasCustomTimeframe={hasCustomTimeframe}
                  isSubmitDisabled={isSubmitDisabled}
                  isSubmitting={isSubmitting}
                  onActionSubmit={onActionSubmit}
                />
              )}
            </Stack>
          </div>
        </div>
      </Accordion>
    </Scroll.Element>
  )
}

ActionPanelBase.propTypes = {
  /** action props object */
  action: PropTypes.shape({
    accumulationActionDailyTrackingTarget: PropTypes.number,
    accumulationActionTrackingType: PropTypes.string,
    /** true if user is allowed to override the target amount */
    allowUserToOverrideTargetAmount: PropTypes.bool.isRequired,
    /** array of available categories for current measured_as */
    availableCategories: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    /** optional, configurable label displayed when condition has custom metric */
    conditionMetricName: PropTypes.string,
    /** datetime when the action is no longer on cooldown */
    cooldownEndsAt: PropTypes.string,
    /** optional value of daily max logging */
    dailyMaxLogging: PropTypes.number,
    /** description of action */
    description: PropTypes.string.isRequired,
    /** last date of act.timeframe */
    endsAt: PropTypes.string,
    /** endsAt + grace period in days. It's only required for accumulation actions. */
    gracePeriodEndsAt: PropTypes.string,
    /** true if action has an emission token */
    hasCarbonImpact: PropTypes.bool.isRequired,
    /** true if action has a custom startsAt or endsAt set */
    hasCustomTimeframe: PropTypes.bool.isRequired,
    /** true if action has a custom override target amount */
    hasOverrideTargetAmount: PropTypes.bool.isRequired,
    /** id of action */
    id: PropTypes.string.isRequired,
    /** true if the action is accumulation action */
    isAccumulationAction: PropTypes.bool.isRequired,
    /** name of action */
    name: PropTypes.string.isRequired,
    /** points awarded for completing this action */
    points: PropTypes.number.isRequired,
    /** user_activity_log.progressCategory used by action with condition */
    progressCategory: PropTypes.oneOf(['custom']),
    /** user_activity_log.measured_as used by action with condition */
    progressMeasuredAs: PropTypes.oneOf(['duration', 'steps', 'custom']),
    /** Target value to complete action with condition */
    progressTargetValue: PropTypes.number,
    /** true if campaign requires this action to be completed to earn the achievement */
    required: PropTypes.bool.isRequired,
    /** first date of act.timeframe */
    startsAt: PropTypes.string.isRequired,
    /** the completable status of an action */
    status: PropTypes.oneOf([
      'AVAILABLE',
      'COOLDOWN',
      'FINISHED',
      'UNAVAILABLE',
    ]).isRequired,
    /** true if action has ever been completed by current user */
    userCompleted: PropTypes.bool.isRequired,
    /** optional max value for the user target override (required if allowUserToOverrideTargetAmount is true) */
    userOverrideTargetMaxAmount: PropTypes.number,
    /** optional min value for the user target override (required if allowUserToOverrideTargetAmount is true) */
    userOverrideTargetMinAmount: PropTypes.number,
    /** Total progress gained by user towards progressTargetValue */
    userProgress: PropTypes.number,
    /** current user activity logs for action, if it has a condition enabled  */
    userProgressActivityLogs: PropTypes.array.isRequired,
  }).isRequired,
  /** the Campaign model this act belongs to */
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  isSubmitDisabled: PropTypes.bool,
  /** whether this action is currently being submitted */
  isSubmitting: PropTypes.bool.isRequired,
  /** click handler for action submit button */
  onActionSubmit: PropTypes.func.isRequired,
}

ActionPanelBase.defaultProps = {
  action: {
    allowUserToOverrideTargetAmount: false,
  },
  isSubmitDisabled: false,
}

export const ActionPanel = withActionMutation(ActionPanelBase)
