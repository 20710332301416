// Setup
import React, { useEffect, useState } from 'react'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'

// WeSpire
import Autocomplete from 'components/form/autocomplete'
import { client } from 'utilities/we_apollo'
import FormFieldGroup from 'components/form/field_group'
import FormFieldsetGroup from 'components/form/fieldset_group'
import ResultsSelect from '../../utils/results_select'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { yearFilterOptions } from 'utilities/year_filter_options'
import { getAutocompleteSelectionsFromIds } from 'utilities/get_autocomplete_selections_from_ids'
import { CHANNELS_LIST_QUERY } from '../utilities/queries'
import { achievementsFiltersMessages as messages } from '../utilities/messages'

const { formatMessage } = intl

const channelOptions = (channels) =>
  channels.map(({ id, name }) => ({
    id: id,
    suggestionLabel: name,
  }))

const AchievementsFilters = ({
  handleFilters,
  selectedChannels,
  selectedYear,
}) => {
  const [queryChannels, setQueryChannels] = useState([])

  useEffect(() => {
    client
      .query({ query: CHANNELS_LIST_QUERY })
      .then(({ data: { channels } }) => {
        setQueryChannels(channels)
      })
  }, [])

  const channelSuggestions = channelOptions(queryChannels)

  return (
    <Formsy noValidate onChange={handleFilters}>
      <FormFieldsetGroup>
        <FormFieldGroup
          id="achievements-filters-label"
          label={`${formatMessage(messages.filtersLabel)}:`}
          labelclassname="fs-2"
          space={4}
        >
          <div className="row">
            <div className="col">
              <ResultsSelect
                className="w-100"
                name="selectedYear"
                optionProps={{ className: 'fw-semi-bold text-black-3' }}
                options={yearFilterOptions().map(({ label, id }) => ({
                  label: label,
                  value: id,
                }))}
                textFieldProps={{
                  label: sharedTranslations.yearLabel,
                }}
                value={selectedYear}
              />
            </div>
            <div className="col">
              <Autocomplete
                className="w-100 selected-channels"
                multiple
                name="selectedChannels"
                selections={getAutocompleteSelectionsFromIds(
                  channelSuggestions,
                  selectedChannels
                )}
                suggestions={channelSuggestions}
                textFieldProps={{
                  label: sharedTranslations.channelLabel,
                }}
              />
            </div>
          </div>
        </FormFieldGroup>
      </FormFieldsetGroup>
    </Formsy>
  )
}

AchievementsFilters.propTypes = {
  handleFilters: PropTypes.func.isRequired,
  selectedChannels: PropTypes.array.isRequired,
  selectedYear: PropTypes.string.isRequired,
}

export default AchievementsFilters
