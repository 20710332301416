// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import { intl } from 'utilities/localization'
import ExternalLink from 'components/ui/external_link'
import Icon from 'components/ui/icon'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const messages = defineMessages({
  editChapter: {
    defaultMessage: 'Edit { name }',
    id: 'groupChaptersRow.editChapter',
  },
  viewChapter: {
    defaultMessage: 'View { name }',
    id: 'groupChaptersRow.viewChapter',
  },
})

export const GroupChapterRow = ({ id, name, parentGroupSlug, slug }) => {
  return (
    <TableRow data-test="group-chapter-row" hover key={id} tabIndex={-1}>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>

      <TableCell align="right">
        <Stack className="d-flex justify-content-end">
          <ExternalLink
            className="align-items-center px-1 d-flex"
            data-test="view-chapter-button"
            hideIcon
            to={`/groups/${parentGroupSlug}/chapters/${slug}`}
          >
            <Icon
              className="fs-4 text-black-4"
              iconName="visibility"
              title={formatMessage(messages.viewChapter, { name })}
            />
          </ExternalLink>

          <ExternalLink
            className="align-items-center px-1 d-flex"
            data-test="edit-chapter-button"
            hideIcon
            to={`/management_panel/groups/${parentGroupSlug}/chapters/${slug}/edit`}
          >
            <Icon
              className="fs-4 text-black-4"
              iconName="edit"
              title={formatMessage(messages.editChapter, { name })}
            />
          </ExternalLink>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

GroupChapterRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentGroupSlug: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}
