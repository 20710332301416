import React from 'react'
import { UserAuthenticatedContext } from 'utilities/context/UserAuthenticatedContext'

const useIsAuthenticated = () => {
  const isAuthenticated = React.useContext(UserAuthenticatedContext)

  return isAuthenticated
}

export { useIsAuthenticated }
