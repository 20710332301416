// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { CampaignAdditionalLocalesForm } from 'setup/campaign_locales/campaign_additional_locales_form'
import { campaignTypeTranslation } from 'utilities/type_translation'
import { intl } from 'utilities/localization'
import MgmtPanelFormPage from 'setup/application/form_page'

const messages = defineMessages({
  unnamedCampaign: {
    defaultMessage: 'Unnamed {campaignType}',
    id: 'campaignLocalesEditPage.unnamedCampaign',
  },
})

const campaignLocalesEditPage = (props) => {
  const {
    achievementDescription,
    achievementName,
    acts,
    additionalLocale,
    campaignDefaultName,
    campaignDescription,
    campaignId,
    defaultLocale,
    isCompetition,
    locale,
    localeAchievementDescription,
    localeAchievementName,
    localeCampaignDescription,
    localeName,
  } = props
  const campaignType = campaignTypeTranslation(isCompetition)
  const { formatMessage } = intl
  const unnamedCampaign = formatMessage(messages.unnamedCampaign, {
    campaignType,
  })

  return (
    <MgmtPanelFormPage
      {...props}
      isEdit
      paperProps={{
        classes: { root: 'bg-transparent' },
        elevation: 0,
      }}
      resourceName={`${campaignType} - ${
        campaignDefaultName ? campaignDefaultName : unnamedCampaign
      } - ${additionalLocale}`}
      space={0}
    >
      {() => (
        <CampaignAdditionalLocalesForm
          achievementDescription={achievementDescription}
          achievementName={achievementName}
          acts={acts}
          additionalLocale={additionalLocale}
          campaignDefaultName={campaignDefaultName}
          campaignDescription={campaignDescription}
          campaignId={campaignId}
          campaignType={campaignType}
          defaultLocale={defaultLocale}
          locale={locale}
          localeAchievementDescription={localeAchievementDescription}
          localeAchievementName={localeAchievementName}
          localeCampaignDescription={localeCampaignDescription}
          localeName={localeName}
        />
      )}
    </MgmtPanelFormPage>
  )
}
campaignLocalesEditPage.propTypes = {
  achievementDescription: PropTypes.string,
  achievementName: PropTypes.string,
  acts: PropTypes.array.isRequired,
  additionalLocale: PropTypes.string.isRequired,
  campaignDefaultName: PropTypes.string,
  campaignDescription: PropTypes.string,
  campaignId: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
  isCompetition: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  localeAchievementDescription: PropTypes.string,
  localeAchievementName: PropTypes.string,
  localeCampaignDescription: PropTypes.string,
  localeName: PropTypes.string,
}

campaignLocalesEditPage.defaultProps = {
  achievementDescription: null,
  achievementName: null,
  campaignDefaultName: null,
  campaignDescription: null,
  localeAchievementDescription: null,
  localeAchievementName: null,
  localeCampaignDescription: null,
  localeName: '',
}

export default campaignLocalesEditPage
