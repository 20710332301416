// Vendor
import React from 'react'
import cx from 'classnames'

import Numbers from 'src/common/Numbers'

const Headline = ({ element, children, className, ...other }) => {
  let Component = element ? element : 'span'
  Component = Component === 'Numbers' ? Numbers : Component

  return (
    <Component
      className={cx(
        'font-sans font-semibold lg:text-[3rem] leading-[4rem] text-[2.25rem]',
        className
      )}
      {...other}
    >
      {children}
    </Component>
  )
}

export default Headline
