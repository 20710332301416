// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'
import v from 'voca'

// WeSpire
import Avatar from 'components/application/avatar'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { currentYear, currentYearUnformatted } from 'utilities/date_formatter'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { times } from 'utilities/times'
import WeQuery from 'components/application/we_query'
import { FormattedPluralWithCount } from 'components/shared/translations/formatted_plural_with_count'

import Link from 'components/shared/link'

const messages = defineMessages({
  heading: {
    defaultMessage: 'Your Impact By Program in {currentYear}',
    id: 'impactByProgramThisYear.heading',
  },
  queryErrorMessage: {
    defaultMessage: 'your impact data',
    id: 'impactByProgramThisYear.queryErrorMessage',
  },
})

const { formatMessage } = intl

const ImpactStatRow = ({ children, hasImpact, iconName }) => (
  <Stack as="li" className="d-flex align-items-center">
    <Icon
      className={cx(
        'fs-4 mr-3',
        { 'text-black-3': !hasImpact },
        { 'text-orange': hasImpact }
      )}
      iconName={iconName}
      title=""
    />
    <span className="fs-2 lh-xl">{children}</span>
  </Stack>
)
ImpactStatRow.propTypes = {
  children: PropTypes.node.isRequired,
  hasImpact: PropTypes.bool.isRequired,
  iconName: PropTypes.string.isRequired,
}

const ImpactStat = ({ count, translation }) => {
  return (
    <span data-test={`impact-stat-${v.slugify(translation)}`}>
      <FormattedPluralWithCount
        count={count}
        formatterProps={{ className: 'fw-semi-bold' }}
        messageId={translation}
      />
    </span>
  )
}
ImpactStat.propTypes = {
  count: PropTypes.number.isRequired,
  translation: PropTypes.string.isRequired,
}

const errorMessage = (
  <MissingResourceSection
    errorDetails={formatMessage(messages.queryErrorMessage)}
  />
)

const skeletonImpactStatCards = (
  <ul className="[ row vertical-gutters ] [ list-none p-0 ]">
    {times(4, (index) => (
      <li className="col-md-6" key={index}>
        <SkeletonLoading header height={245} />
      </li>
    ))}
  </ul>
)

const skeletonStatRows = (
  <Stack space={3}>
    {times(3, (index) => (
      <SkeletonLoading height={28} key={index} />
    ))}
  </Stack>
)

const UserProfileImpactByProgramThisYear = () => {
  return (
    <Stack as="section" data-test="impact-by-program-this-year" space={3}>
      <Heading className="px-3" level={3} variant={2}>
        {formatMessage(messages.heading, { currentYear })}
      </Heading>
      <WeQuery
        error={<Paper>{errorMessage}</Paper>}
        loader={skeletonImpactStatCards}
        query={gql`
          query impactByProgramThisYear {
            activeChannelsForUser(withMyActivities: false) {
              id
              logoImageUrl
              name
            }
          }
        `}
      >
        {({ data: { activeChannelsForUser: channels } }) => {
          return (
            <ul className="[ row vertical-gutters ] [ list-none p-0 ]">
              {channels.map((channel) => {
                const { id: channelId, logoImageUrl, name } = channel

                return (
                  <li
                    className="col-md-6"
                    data-test="channel-impact-stats"
                    key={channelId}
                  >
                    <Stack as={Paper} divider="default">
                      <Link to={`/take_action/${channelId}`}>
                        <div className="[ d-flex align-items-center ] [ px-4 py-3 ]">
                          {logoImageUrl && (
                            <Avatar
                              alt={`${name} logo`}
                              className="elevation-1 mr-2"
                              image={logoImageUrl}
                              size="md"
                            />
                          )}
                          <span className="fs-3 fw-semi-bold lh-md py-2 text-blue">
                            {name}
                          </span>
                        </div>
                      </Link>

                      <div className="p-4">
                        <WeQuery
                          error={errorMessage}
                          loader={skeletonStatRows}
                          query={gql`
                            query impactByProgramThisYear {
                              userImpactStats(channelId: ${channelId}, since: "${currentYearUnformatted}-01-01") {
                                id
                                totalActionsCompleted
                                totalCampaignsAndCompetitionsCompletedThisYear
                                totalEvents
                                totalHoursVolunteered
                                totalIdeaBoards
                                totalIdeaBoardsSubmissions
                                totalIdeaBoardsVotes
                              }
                            }
                          `}
                        >
                          {({
                            data: {
                              userImpactStats: {
                                totalActionsCompleted,
                                totalCampaignsAndCompetitionsCompletedThisYear,
                                totalEvents,
                                totalHoursVolunteered,
                                totalIdeaBoards,
                                totalIdeaBoardsSubmissions,
                                totalIdeaBoardsVotes,
                              },
                            },
                          }) => {
                            const hasEventImpact =
                              totalEvents + Number(totalHoursVolunteered) > 0
                            const hasCampaignImpact =
                              totalCampaignsAndCompetitionsCompletedThisYear > 0
                            const hasIdeaBoardImpact = totalIdeaBoards > 0

                            return (
                              <Stack
                                as="ul"
                                className="list-none pl-0"
                                space={3}
                              >
                                <ImpactStatRow
                                  hasImpact={hasCampaignImpact}
                                  iconName="activity_campaign"
                                >
                                  <ImpactStat
                                    count={
                                      totalCampaignsAndCompetitionsCompletedThisYear
                                    }
                                    translation="completedCampaignsAndCompetitionsWithCount"
                                  />
                                  <BulletSpacer />
                                  <ImpactStat
                                    count={totalActionsCompleted}
                                    translation="completedActionsWithCount"
                                  />
                                </ImpactStatRow>

                                <ImpactStatRow
                                  hasImpact={hasEventImpact}
                                  iconName="activity_event"
                                >
                                  <ImpactStat
                                    count={totalEvents}
                                    translation="eventsWithCount"
                                  />
                                  {totalHoursVolunteered !== null && (
                                    <>
                                      <BulletSpacer />
                                      <ImpactStat
                                        count={totalHoursVolunteered}
                                        translation="volunteerHoursWithCount"
                                      />
                                    </>
                                  )}
                                </ImpactStatRow>

                                <ImpactStatRow
                                  hasImpact={hasIdeaBoardImpact}
                                  iconName="activity_idea_board"
                                >
                                  <ImpactStat
                                    count={totalIdeaBoards}
                                    translation="ideaBoardsWithCount"
                                  />
                                  <BulletSpacer />
                                  <ImpactStat
                                    count={totalIdeaBoardsSubmissions}
                                    translation="ideasWithCount"
                                  />
                                  <BulletSpacer />
                                  <ImpactStat
                                    count={totalIdeaBoardsVotes}
                                    translation="votesWithCount"
                                  />
                                </ImpactStatRow>
                              </Stack>
                            )
                          }}
                        </WeQuery>
                      </div>
                    </Stack>
                  </li>
                )
              })}
            </ul>
          )
        }}
      </WeQuery>
    </Stack>
  )
}
export { UserProfileImpactByProgramThisYear }
