import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  commentCannotBeBlank: {
    defaultMessage: 'Comment cannot be blank.',
    id: 'volunteerHistoryForm.commentCannotBeBlank',
  },
  confirmDelete: {
    defaultMessage: 'This will delete the volunteering log.',
    id: 'volunteerHistoryForm.confirmDelete',
  },
  dataMigrationError: {
    defaultMessage:
      'We are currently performing a data migration. Please come back in a few minutes to log your volunteer hours.',
    id: 'volunteerHistoryForm.dataMigrationError',
  },
  date: {
    defaultMessage: 'Date (MM/DD/YYYY)',
    id: 'volunteerHistoryForm.date',
  },
  deleteVolunteerLog: {
    defaultMessage: 'Delete volunteer log',
    id: 'volunteerHistoryForm.deleteVolunteerLog',
  },
  describeImpact: {
    defaultMessage:
      'Describe the impact your efforts had on your community. Include the type of work, the tasks you performed, and anything you learned.',
    id: 'volunteerHistoryForm.describeImpact',
  },
  fixedIncrement: {
    defaultMessage: 'Must be in increments of 0.01',
    id: 'volunteerHistoryForm.fixedIncrement',
  },
  hoursLoggedSuccessfully: {
    defaultMessage: 'Your volunteer hours were successfully logged.',
    id: 'volunteerHistoryForm.hoursLoggedSuccessfully',
  },
  howManyHoursVolunteered: {
    defaultMessage: 'How many hours did you volunteer?',
    id: 'volunteerHistoryForm.howManyHoursVolunteered',
  },
  includeDetails: {
    defaultMessage:
      'Include details: the type of work, the tasks you performed, and anything you learned.',
    id: 'volunteerHistoryForm.includeDetails',
  },
  logFormPhotoSize: {
    defaultMessage:
      'Preview image is just a placeholder! If any portion is cut off in the preview it will not be cut off in your social post.',
    id: 'volunteerHistoryForm.logFormPhotoSize',
  },
  loggingTemporarilyDisabled: {
    defaultMessage: 'Volunteer hour logging is temporarily disabled',
    id: 'volunteerHistoryForm.loggingTemporarilyDisabled',
  },
  logVolunteeringFromYourOwnTime: {
    defaultMessage: `Please log only the volunteer service you've done on your own time. The volunteer hours you've earned by registering for Events on this platform are automatically tracked in {volunteerHistoryLink}.`,
    id: 'volunteerHistoryForm.logVolunteeringFromYourOwnTime',
  },
  maxValue: {
    defaultMessage: 'Must be 24 or fewer',
    id: 'volunteerHistoryForm.maxValue',
  },
  minValue: {
    defaultMessage: 'Must be 0.25 or more',
    id: 'volunteerHistoryForm.minValue',
  },
  numberOfHours: {
    defaultMessage: 'Number of hours',
    id: 'volunteerHistoryForm.numberOfHours',
  },
  organizationsYouVolunteeredWith: {
    defaultMessage: 'Organization You Volunteered With',
    id: 'volunteerHistoryForm.organizationsYouVolunteeredWith',
  },
  shareYourExperience: {
    defaultMessage: 'Share Your Experience',
    id: 'volunteerHistoryForm.shareYourExperience',
  },
  tagOthersWhoParticipated: {
    defaultMessage: 'Tag others who participated!',
    id: 'volunteerHistoryForm.tagOthersWhoParticipated',
  },
  uploadSnapshotsFromEvent: {
    defaultMessage: 'Upload fun snapshots from the Event!',
    id: 'volunteerHistoryForm.uploadSnapshotsFromEvent',
  },
  volunteerEventType: {
    defaultMessage: 'Volunteer Event Type',
    id: 'volunteerHistoryForm.volunteerEventType',
  },
  volunteeringLogDeleted: {
    defaultMessage: 'Volunteering log has been deleted.',
    id: 'volunteerHistoryForm.volunteeringLogDeleted',
  },
  whatDateYouVolunteered: {
    defaultMessage: "What's the date you volunteered?",
    id: 'volunteerHistoryForm.whatDateYouVolunteered',
  },
  whatTypeOfVolunteering: {
    defaultMessage: 'What type of volunteering was this?',
    id: 'volunteerHistoryForm.whatTypeOfVolunteering',
  },
  whereDidYouVolunteer: {
    defaultMessage: 'Where did your volunteering take place?',
    id: 'volunteerHistoryForm.whereDidYouVolunteer',
  },
})
