// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const WysiwygHtmlContainer = ({ children, component, ...other }) => {
  const Component = component

  return (
    <Component
      {...other}
      className={cx('wysiwyg-html-container', other.className)}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

WysiwygHtmlContainer.propTypes = {
  children: PropTypes.node.isRequired,
  /** renders component as custom element  */
  component: PropTypes.any,
}

WysiwygHtmlContainer.defaultProps = {
  component: 'div',
}

export default WysiwygHtmlContainer
