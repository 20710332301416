// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { client } from 'utilities/we_apollo'
import gql from 'graphql-tag'

// WeSpire
import FormSelect from 'components/form/select'

const ENABLED_CURRENCIES_QUERY = gql`
  query enabledCurrencies {
    brand {
      id
      enabledCurrencies {
        id
        isoCode
        isZeroDecimal
        minimumDonationAmount
        name
        symbol
      }
    }
  }
`

const CURRENCY_QUERY = gql`
  query getCurrency($isoCode: String!) {
    currency(isoCode: $isoCode) {
      id
      isoCode
      isZeroDecimal
      minimumDonationAmount
      name
      symbol
    }
  }
`

const useUSDCurrency = () => {
  const [USD, setUSD] = useState([])

  useEffect(() => {
    const fetchUSDCurrency = async () => {
      try {
        const {
          data: { currency: usd },
        } = await client.query({
          query: CURRENCY_QUERY,
          variables: { isoCode: 'USD' },
        })

        setUSD(usd)
      } catch {
        return
      }
    }
    fetchUSDCurrency()
  }, [])

  return { setUSD, USD }
}

const useEnabledCurrencies = () => {
  const [fetchStatus, setFetchStatus] = useState('pending')
  const [enabledCurrencies, setEnabledCurrencies] = useState([])

  useEffect(() => {
    const fetchEnabledCurrencies = async () => {
      try {
        const {
          data: {
            brand: { enabledCurrencies },
          },
        } = await client.query({
          query: ENABLED_CURRENCIES_QUERY,
        })

        setEnabledCurrencies(enabledCurrencies)
        setFetchStatus('success')
      } catch {
        setFetchStatus('error')
        return
      }
    }
    fetchEnabledCurrencies()
  }, [])

  return {
    enabledCurrencies,
    fetchStatus,
    setEnabledCurrencies,
    setFetchStatus,
  }
}

const CurrencySelect = ({
  forceUSD,
  formControlProps,
  onCurrencyChange,
  value,
  ...props
}) => {
  /**
   * USD.id is necessary to force USD value in select, since it's not guaranteed to be
   * in the enabled_currencies object, we fetch it
   * */
  const { USD } = useUSDCurrency()
  const { enabledCurrencies, fetchStatus } = useEnabledCurrencies()

  useEffect(() => {
    if (forceUSD) {
      if (enabledCurrencies?.length > 0) {
        onCurrencyChange(USD)
      }
    }
  }, [enabledCurrencies, forceUSD])

  const onChange = (selectedId) => {
    if (onCurrencyChange) {
      const selectedCurrency = enabledCurrencies.find(
        ({ id }) => selectedId === id
      )
      onCurrencyChange(selectedCurrency)
    }
  }

  /**
   * current form select has an issue when value changes after render,
   * it won't set select as focused, to simulate the previous <WeQuery/> implementation
   * this uses status to hide select until is done loading
   */
  return fetchStatus === 'pending' ? null : (
    <FormSelect
      formControlProps={{
        ...formControlProps,
        disabled: enabledCurrencies?.length <= 1 || formControlProps?.disabled,
      }}
      hideBlankOption
      onChange={onChange}
      options={enabledCurrencies?.map(({ id, isoCode, symbol }) => ({
        label: `${isoCode} ${symbol || ''}`,
        value: id,
      }))}
      value={forceUSD ? USD?.id : value}
      {...props}
    />
  )
}

CurrencySelect.propTypes = {
  forceUSD: PropTypes.bool,
  formControlProps: PropTypes.object,
  onCurrencyChange: PropTypes.func,
  value: PropTypes.string,
}

CurrencySelect.defaultProps = {
  forceUSD: false,
  formControlProps: null,
  onCurrencyChange: null,
  value: null,
}

export default CurrencySelect
