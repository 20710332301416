// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@material-ui/core'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import { DocumentTitle } from 'components/shared/document_title'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MoreGroups } from './more_groups'
import { PageQuery } from 'components/queries/page_query'
import { sharedTranslations } from 'components/shared/translations'
import { UserGroups } from './user_groups'

const Groups = ({ match }) => {
  const messages = defineMessages({
    companyTeams: {
      defaultMessage: 'Company Teams',
      id: 'companyTeams.groups',
    },
  })
  const { formatMessage } = intl

  const page = match.url.replace(/^\/|\/$/g, '')

  return (
    <DocumentTitle title={sharedTranslations.groups}>
      <GraphQLFeatureFlag featureName="ergs">
        <PageQuery
          query={gql`
            query groupsForBrand {
              brandConfig {
                friendlyErgNameWithDefault
                id
              }
            }
          `}
        >
          {({
            data: {
              brandConfig: { friendlyErgNameWithDefault },
            },
          }) => {
            const handleTabChange = (_event, tabSlug) => {
              if (tabSlug !== page) {
                window.location = `${tabSlug}`
              }
            }

            const tabs = [
              {
                id: 'groups',
                name: friendlyErgNameWithDefault,
              },
              {
                id: 'teams',
                name: formatMessage(messages.companyTeams),
              },
            ]

            return (
              <DocumentTitle title={friendlyErgNameWithDefault}>
                <div className="bg-white border-bottom border-top position-relative z-1">
                  <CenteredContent>
                    <Tabs
                      onChange={handleTabChange}
                      scrollButtons="off"
                      textColor="secondary"
                      value={page}
                      variant="scrollable"
                    >
                      {tabs.map((tab) => (
                        <Tab
                          classes={{
                            root: 'fs-3 px-2 py-3 text-transform-none col-auto col-md-3 border-bottom mxw-none',
                            wrapper: 'flex-row',
                          }}
                          data-test={`tab-${tab.id}`}
                          key={tab.id}
                          label={tab.name}
                          value={tab.id}
                        />
                      ))}
                    </Tabs>
                  </CenteredContent>
                </div>
                <Heading className="sr-only" level={1}>
                  {friendlyErgNameWithDefault}
                </Heading>
                <CenteredContent className="my-5">
                  <UserGroups friendlyErgName={friendlyErgNameWithDefault} />
                  <MoreGroups friendlyErgName={friendlyErgNameWithDefault} />
                </CenteredContent>
              </DocumentTitle>
            )
          }}
        </PageQuery>
      </GraphQLFeatureFlag>
    </DocumentTitle>
  )
}

Groups.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export { Groups }
