// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cloneDeep from 'lodash/cloneDeep'
import { Paper } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'

// WeSpire
import { client } from 'utilities/we_apollo'
import { GET_CAMPAIGN_NEWSFEED_ITEMS } from 'graphql/queries'
import { intl } from 'utilities/localization'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { NewsFeedHelpBlurb } from 'components/news_feed/help_blurb'
import { NewsFeedItems } from 'components/news_feed/items'
import { RelativeTime } from 'components/shared/relative_time'
import { NewsFeedItemSkeleton } from 'components/news_feed/item'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import { pageSize } from 'components/news_feed/utils'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { updateCacheActivityGalleryQueryWithEvents } from 'graphql/utils/campaigns/updateCacheActivityGalleryQueryWithEvents'
import { times } from 'utilities/times'
import { CampaignModel } from './campaign_model'

const messages = defineMessages({
  newsFeedHelpBlurb: {
    defaultMessage:
      'Come back {timeFromNow} when the Competition starts to see what participants are doing and join the discussion!',
    description:
      'Message indicating to come back to the newsfeed after the competition has started',
    id: 'campaignNewsFeed.newsFeedHelpBlurb',
  },
})

const CAMPAIGN_NEWSFEED_ITEMS_FETCH_LIMIT = 10

const CampaignNewsFeed = ({
  actionId,
  campaign,
  isPreview,
  previewWithNoData,
}) => {
  const { formatMessage } = intl
  const { loading, error, data, fetchMore } = useQuery(
    GET_CAMPAIGN_NEWSFEED_ITEMS,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        campaignId: campaign.id,
        first: CAMPAIGN_NEWSFEED_ITEMS_FETCH_LIMIT,
      },
    }
  )

  const noComments = () => (
    <NewsFeedHelpBlurb dataTest="no-comments-message">
      {sharedTranslations.noCommentsMessage}
    </NewsFeedHelpBlurb>
  )

  const loadNextPage = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldNodes = previousResult.campaign.newsfeed.nodes
        const newNodes = fetchMoreResult.campaign.newsfeed.nodes

        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.campaign.newsfeed.nodes = [...oldNodes, ...newNodes]

        updateCacheActivityGalleryQueryWithEvents(
          data.campaign.id,
          result.campaign.newsfeed.nodes,
          false
        )

        return result
      },
      variables: {
        after: data.campaign.newsfeed.pageInfo.endCursor,
        first: CAMPAIGN_NEWSFEED_ITEMS_FETCH_LIMIT,
      },
    })
  }

  if (campaign.isCompetition && !campaign.hasStarted) {
    return (
      <NewsFeedHelpBlurb dataTest="not-started-message">
        {formatMessage(messages.newsFeedHelpBlurb, {
          timeFromNow: (
            <b>
              <RelativeTime date={campaign.startsAt} showPrefix={false} />
            </b>
          ),
        })}
      </NewsFeedHelpBlurb>
    )
  }

  if (loading && !data) {
    return (
      <ul className="list-none pl-0">
        {times(pageSize, (index) => (
          <NewsFeedItemSkeleton as="li" key={index} />
        ))}
      </ul>
    )
  }

  if (error) {
    return (
      <>
        <Stack as={Paper} className="p-4">
          <p>
            {formatMessage(sharedMessages.newsfeedError, {
              type: sharedTranslations.campaign,
            })}
          </p>
        </Stack>
        <ScreenReader
          role="status"
          text={formatMessage(sharedMessages.newsfeedError, {
            type: sharedTranslations.campaign,
          })}
        />
      </>
    )
  }

  const totalCount = data.campaign.newsfeed.totalCount
  const newsfeedItems = data.campaign.newsfeed.nodes
  // We are not using hasNextPage from graphql connections because there is a bug
  // in graphql spec that returns false when first variable is not used
  const hasNextPage = totalCount > newsfeedItems.length

  if (isPreview || previewWithNoData || newsfeedItems.length === 0) {
    return noComments()
  }
  // Show all items if actionId is null OR only the ones that have a
  // matching targetId.
  const items = newsfeedItems.filter(
    (item) => actionId === null || parseInt(item.targetId) === actionId
  )
  return (
    <div data-test="campaign-newsfeed-items">
      <NewsFeedItems className="mt-0" items={items} />
      {hasNextPage && (
        <div className="d-flex">
          <LoadMoreButton
            loading={loading}
            newsfeedItemsCount={newsfeedItems.length}
            onClick={loadNextPage}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        </div>
      )}
      {loading && (
        <ScreenReader role="status" text={sharedTranslations.newsfeedLoading} />
      )}
    </div>
  )
}

CampaignNewsFeed.propTypes = {
  actionId: PropTypes.string,
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  isPreview: PropTypes.bool,
  previewWithNoData: PropTypes.bool,
}

CampaignNewsFeed.defaultProps = {
  actionId: null,
  isPreview: false,
  previewWithNoData: false,
}

export { CampaignNewsFeed, CAMPAIGN_NEWSFEED_ITEMS_FETCH_LIMIT }
