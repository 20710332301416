// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { Button } from 'components/ui/button'
import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormErrorSummary from 'components/form/error_summary'
import FormFieldset from 'components/form/fieldset'
import FormFieldsetGroup from 'components/form/fieldset_group'
import FormTextField from 'components/form/text_field'
import Formsy from 'formsy-react'
import { intl } from 'utilities/localization'
import MgmtPanelFormPage from 'setup/application/form_page'
import { PreviewModal } from './preview_modal'
import { sharedMessages } from 'components/shared/translations'
import SubmitButton from 'components/ui/submit_button'
import { WysiwygEditor } from 'components/ui/wysiwyg_editor'

// sub-components, utils and routes
import { backPath } from './routes'
import {
  CREATE_GROUP_ANNOUNCEMENT,
  UPDATE_GROUP_ANNOUNCEMENT,
} from './utilities/queries'
import { groupAnnouncementsFormMessages as messages } from './utilities/messages'

const { formatMessage } = intl

const GroupAnnouncementsForm = (props) => {
  const [isOpen, showModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitOption, setSubmitOption] = useState('published')
  const { announcement, group, isEdit } = props
  const [userText, setUserText] = useState(announcement.announcement_html)

  const handleInvalidSubmit = () => {
    displayBanner({
      content: formatMessage(messages.errorMessage),
      fullWidth: true,
      variant: 'error',
    })
  }

  const handleSubmit = (values) => {
    hideBanner()
    executeMutation(values)
  }

  const executeMutation = (values) => {
    setIsSubmitting(true)
    const { title, announcement_html } = values
    const status = submitOption

    const variables = {
      announcementHtml: announcement_html,
      groupAnnouncementId: announcement.id,
      groupId: group.id,
      status,
      title,
    }

    const mutation = isEdit
      ? UPDATE_GROUP_ANNOUNCEMENT
      : CREATE_GROUP_ANNOUNCEMENT

    client
      .mutate({
        mutation: mutation,
        variables: variables,
      })
      .then(({ data: { errors } }) => {
        setIsSubmitting(false)
        if (!errors) {
          const action = isEdit ? 'updated' : 'created'
          window.location = `${backPath({
            groupId: group.id,
          })}?${action}`
        } else {
          displayBanner({
            as: 'div',
            content: <FormErrorSummary errors={errors} />,
            fullWidth: true,
            variant: 'error',
          })
        }
      })
      .catch(() => {
        setIsSubmitting(false)

        let operation = ''
        if (isEdit) {
          operation = formatMessage(sharedMessages.operationUpdate, {
            className: formatMessage(sharedMessages.announcement),
          })
        } else {
          operation = formatMessage(sharedMessages.operationCreate, {
            className: formatMessage(sharedMessages.announcement),
          })
        }

        displayExceptionBanner({
          fullWidth: true,
          operation: operation,
        })
      })
  }

  return (
    <MgmtPanelFormPage
      data-test="announcement-form"
      isEdit={isEdit}
      resourceName={formatMessage(messages.formTitle, {
        groupName: group.name_reporting,
      })}
    >
      {() => (
        <>
          <PreviewModal
            isOpen={isOpen}
            showModal={showModal}
            userText={userText}
          />
          <Formsy
            className="w-100"
            onInvalidSubmit={handleInvalidSubmit}
            onValidSubmit={handleSubmit}
          >
            <FormFieldsetGroup className="w-100">
              <FormFieldset label="">
                <FormTextField
                  className="w-100"
                  data-test="announcement-title"
                  name="title"
                  required
                  textFieldProps={{
                    label: formatMessage(sharedMessages.title),
                  }}
                  value={announcement.title}
                />
                <WysiwygEditor
                  data-test="announcement-html"
                  label={formatMessage(messages.announcementBody)}
                  name="announcement_html"
                  required={submitOption === 'published'}
                  setUserText={setUserText}
                  textFieldProps={{
                    helperText: formatMessage(messages.announcementBodyHint),
                    label: formatMessage(messages.announcementBody),
                  }}
                  userText={userText}
                  value={announcement.announcement_html}
                />
                <FormActionBar
                  backPath={backPath({
                    groupId: group.id,
                  })}
                >
                  <Button
                    className="mr-2"
                    data-test="preview-button"
                    onClick={() => showModal(true)}
                    variant="text"
                  >
                    {formatMessage(messages.preview)}
                  </Button>
                  {isEdit && (
                    <SubmitButton
                      className="mr-2"
                      color="primary"
                      data-test="archive-announcement-button"
                      isSubmitting={isSubmitting}
                      onClick={() => setSubmitOption('archived')}
                      variant="outlined"
                    >
                      {formatMessage(sharedMessages.archive)}
                    </SubmitButton>
                  )}
                  <SubmitButton
                    color="primary"
                    data-test="save-announcement-as-draft-button"
                    isSubmitting={isSubmitting}
                    onClick={() => setSubmitOption('drafted')}
                    variant="outlined"
                  >
                    {formatMessage(messages.saveDraft)}
                  </SubmitButton>
                  <FormActionBarSubmitButton
                    className="ml-2"
                    data-test="save-announcement-button"
                    isSubmitting={isSubmitting}
                    onClick={() => setSubmitOption('published')}
                  >
                    {formatMessage(sharedMessages.publish)}
                  </FormActionBarSubmitButton>
                </FormActionBar>
              </FormFieldset>
            </FormFieldsetGroup>
          </Formsy>
        </>
      )}
    </MgmtPanelFormPage>
  )
}

GroupAnnouncementsForm.propTypes = {
  announcement: PropTypes.shape({
    announcement_html: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name_reporting: PropTypes.string.isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
}

GroupAnnouncementsForm.defaultProps = {
  announcement: { title: '', value: '' },
  group: null,
}

export default GroupAnnouncementsForm
