// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import cx from 'classnames'

// WeSpire
import FormTextField from 'components/form/text_field'
import Stack from 'components/ui/stack'

// 1. If we don't delete shouldReveal from the other props, it's included in
// 'other' and spread onto the <Stack>, which then renders the prop directly to
// the DOM creating invalid HTML.

/**
 * Use this to toggle between two options: a) all/nothing of some resource or
 * b) some specific subset/instance of said resource. This is really just a
 * show-hide device that doesn't affect values submitted by Formsy, with one
 * exception: When the radio is hiding its children, it instead renders a
 * hidden text field for each emptyStates object passed to it. Formsy can then
 * access those values during submission, so empty values can persist in the db.
 */
class BooleanRadioReveal extends Component {
  state = {
    isRevealed: this.props.shouldReveal,
  }

  handleReveal = (event) => {
    this.setState({ isRevealed: event.target.value === 'true' })
  }

  render() {
    const {
      children,
      emptyStates,
      hideLabel,
      label,
      radioHideLabel,
      radioRevealLabel,
      space,
      ...other
    } = this.props

    const { isRevealed } = this.state
    delete other.shouldReveal // 1

    return (
      <Stack
        {...other}
        as={FormControl}
        className="w-100"
        component="fieldset"
        space={space}
      >
        <FormLabel className={cx({ 'sr-only': hideLabel })} component="legend">
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={label}
          className={cx({ 'mt-0': hideLabel })}
          onChange={this.handleReveal}
          value={isRevealed.toString()}
        >
          <FormControlLabel
            control={<Radio color="primary" name={label} />}
            label={radioHideLabel}
            value="false"
          />
          <FormControlLabel
            control={<Radio color="primary" name={label} />}
            label={radioRevealLabel}
            value="true"
          />
        </RadioGroup>
        {isRevealed
          ? children
          : emptyStates.map((state, index) => (
              <FormTextField
                className="d-none"
                key={`${name}-${index}`}
                name={state.name}
                value={state.value}
              />
            ))}
      </Stack>
    )
  }
}

BooleanRadioReveal.propTypes = {
  /** children rendered if boolean is set to true */
  children: PropTypes.node.isRequired,
  /** default name & value of hidden field(s) used when child is not revealed */
  emptyStates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }).isRequired
  ).isRequired,
  /** decide whether or not to hide the radio label */
  hideLabel: PropTypes.bool,
  /** describe the purpose of this radio group */
  label: PropTypes.string.isRequired,
  /** label for the radio option that hides the content */
  radioHideLabel: PropTypes.any.isRequired,
  /** label for the radio option that shows the content */
  radioRevealLabel: PropTypes.any.isRequired,
  /** decides whether or not to reveal the content by default */
  shouldReveal: PropTypes.bool,
  /** specify vertical space between nested items */
  space: PropTypes.number,
}

BooleanRadioReveal.defaultProps = {
  hideLabel: false,
  shouldReveal: false,
  space: 3,
}

export default BooleanRadioReveal
