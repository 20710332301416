// Vendor
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  aMember: {
    defaultMessage: 'a member',
    id: 'groupTranslations.aMember',
  },
  anAnonymousMember: {
    defaultMessage: 'an anonymous member',
    id: 'groupTranslations.anAnonymousMember',
  },
  chapterSelectorChapter: {
    defaultMessage: '{groupName} Chapter',
    id: 'groupTranslations.chapterSelectorChapter',
  },
  chapterSelectorHomepage: {
    defaultMessage: '{groupName} Homepage',
    id: 'groupTranslations.chapterSelectorHomepage',
  },
  joinOperation: {
    defaultMessage: 'join you to this {groupOrChapter}',
    id: 'groupsTranslations.joinOperation',
  },
  joinSuccessMessage: {
    defaultMessage:
      "Welcome! You are now {membership} of {name} and we can't wait for you to participate.",
    id: 'groupTranslations.joinSuccessMessage',
  },
  leave: {
    defaultMessage: 'Leave {type}',
    id: 'groupTranslations.leave',
  },
  manageMembership: {
    defaultMessage: 'Manage Membership',
    id: 'groupTranslations.manageMembership',
  },
  yourMembership: {
    defaultMessage: 'your Membership',
    id: 'groupTranslations.yourMembership',
  },
})

export const groupTranslations = messages
