import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  carbonImpactHelperText: {
    defaultMessage:
      'Insert a link to an intranet page or other resource that describes to your audience why carbon footprint management is important. If you leave this blank, it will not show up on the user-facing page.',
    id: 'contentModule.carbonImpactHelperText',
  },
  carbonReductionMarketplaceToggleHelperText: {
    defaultMessage:
      'Carbon Reduction Marketplace will be available to users by a link on the Carbon Footprint page',
    id: 'contentModule.carbonReductionMarketplaceToggleHelperText',
  },
  carbonReductionMarketplaceToggleLabel: {
    defaultMessage: 'Enable Carbon Reduction Marketplace',
    id: 'contentModule.carbonReductionMarketplaceToggleLabel',
  },
  channelHelperText: {
    defaultMessage:
      'Select the Channel this Module should be associated to. ' +
      'Leave it blank to remove an existing Module association.',
    id: 'contentModule.channelHelperText',
  },
  channelLabel: {
    defaultMessage: 'Associated Channel',
    id: 'contentModule.channelLabel',
  },
  invalidURL: {
    defaultMessage: 'Invalid URL',
    id: 'contentModule.invalidURL',
  },
  moduleSaved: {
    defaultMessage: 'Module was successfully updated.',
    id: 'contentModuleForm.moduleSaved',
  },
  settingsHint: {
    defaultMessage: 'Configure the settings related to this Module.',
    id: 'contentModule.settingsHint',
  },
  sustainabilityImpactHelperText: {
    defaultMessage: 'Describe why impacting {name} is important to your brand.',
    id: 'contentModule.sustainabilityImpactHelperText',
  },
  sustainabilityImpactHint: {
    defaultMessage:
      'Descriptions for why these sustainability categories are important to your brand. ' +
      'They will be displayed to employee users in the Our Impact primary navigation section.',
    id: 'contentModule.sustainabilityImpactHint',
  },
  whyItMattersHelperText: {
    defaultMessage: 'Describe why {moduleName} is important to your brand.',
    id: 'contentModule.whyItMattersHelperText',
  },
  whyItMattersLabel: {
    defaultMessage: 'Why It Matters',
    id: 'contentModule.whyItMattersLabel',
  },
})

export const contentModuleMessages = formatMessages(messages)
