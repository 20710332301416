// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NetworkStatus } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useFlags } from 'launchdarkly-react-client-sdk'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { undecorated as TextField } from 'components/form/text_field'
import { useDebounce } from 'utilities/hooks/useDebounce'

import { UserRow } from './user_row'

const { formatMessage } = intl

const messages = defineMessages({
  allUsers: {
    defaultMessage: 'All users ({ totalCount })',
    id: 'usersTable.allUsers',
  },
  emptyState: {
    defaultMessage: 'No users found.',
    id: 'usersTable.emptyState',
  },
  helperTextForSearch: {
    defaultMessage: 'Filter by name or email address',
    id: 'usersTable.helperTextForSearch',
  },
  labelDisplayedRows: {
    defaultMessage: '{ from }-{ to } of { count }',
    id: 'usersTable.labelDisplayedRows',
  },
  labelRowsPerPage: {
    defaultMessage: 'Rows per page',
    id: 'usersTable.labelRowsPerPage',
  },
})

const tableHeadMessages = defineMessages({
  user: {
    defaultMessage: 'User',
    id: 'usersTableHead.user',
  },
  pga_account_number: {
    defaultMessage: 'PGA Account Number',
    id: 'usersTableHead.pga_account_number',
  },
  balance: {
    defaultMessage: 'Account Balance',
    id: 'usersTableHead.balance',
  },
})


const QUERY = gql`
	query UsersQuery(
    $page: Int
    $limit: Int
    $term: String
    $sort: String
    $sortBy: String
  ) {
    users(
      page: $page
      limit: $limit
      term: $term
      sort: $sort
      sortBy: $sortBy
    ) {
      users {
        avatarUrl
        id
        email
        name
        editProfilePath
        givingaUser {
          employeeAccountNumber
          employeeAccountBalance
        }
      }
      totalCount
    }
  }
`

const INITIAL_ROWS_PER_PAGE = 25

export const UsersTable = () => {
  const { data, error, loading, networkStatus, refetch, variables } = useQuery(
    QUERY,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: INITIAL_ROWS_PER_PAGE,
        page: 1,
        sort: 'desc',
        sortBy: 'id',
        term: '',
      },
    }
  )

  const usersData = data?.users
  const handleChangePage = (event, newPage) => {
    refetch({
      ...variables,
      page: newPage,
    })
  }
  const handleChangeRowsPerPage = (ev) => {
    refetch({
      ...variables,
      limit: +ev.target.value,
      page: 1,
    })
  }

  const handleOnSearch = (key) => {
    refetch({
      ...variables,
      page: 1,
      term: key,
    })
  }
  const [filterKey, setFilterKey] = useState('')
  const debouncedFilterKey = useDebounce(filterKey, 300)
  const handleFilterKeyOnChange = (value) => {
    setFilterKey(value)
  }
  useEffect(() => {
    handleOnSearch(debouncedFilterKey)
  }, [debouncedFilterKey])
  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault()
    }
  }

  if (error) {
    return <MissingResourcePage />
  }

  const headCells = [
    {
      id: 'user',
      label: formatMessage(tableHeadMessages.user),
    },
    {
      id: 'pga_account_number',
      label: formatMessage(tableHeadMessages.pga_account_number),
    },
    {
      id: 'balance',
      label: formatMessage(tableHeadMessages.balance),
    },
    {
      id: 'actions',
      invisible: true,
    },
  ]

  const sort = variables.sort
  const sortBy = variables.sortBy
  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)
  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase"
          data-test="all-users-label"
          level={3}
        >
          {formatMessage(messages.allUsers, {
            totalCount: usersData?.totalCount,
          })}
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack className="d-flex">
          <TextField
            className="col mxw-4"
            name="filterKey"
            setValue={handleFilterKeyOnChange}
            textFieldProps={{
              className: 'pr-0',
              inputProps: {
                'aria-label': 'search users',
                className: 'text-truncate',
              },
              InputProps: {
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      className="fs-4 text-black-3"
                      iconName="people"
                      title={intl.formatMessage(messages.helperTextForSearch)}
                    />
                  </InputAdornment>
                ),
              },
              onKeyDown: handleKeyDown,
              placeholder: intl.formatMessage(messages.helperTextForSearch),
              size: 'small',
              type: 'search',
            }}
            value={filterKey}
          />

          <div className="ml-auto" />
        </Stack>

        <Stack>
          <TableContainer>
            <Table aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell, index) => (
                    <TableCell
                      align={index > 0 ? 'right' : 'inherit'}
                      key={headCell.id}
                      sortDirection={sortBy === headCell.id ? sort : false}
                    >
                      { headCell.invisible ? null : headCell.label }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!refetching &&
                  usersData?.users?.map((user) => {
                    return (
                      <UserRow
                        key={user.id}
                        tabIndex={-1}
                        {...user}
                      />
                    )
                  })}
                {usersData?.users?.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <p
                        className="fs-2 fw-semi-bold d-flex align-items-center justify-content-center"
                        data-test="users-empty-state"
                      >
                        {intl.formatMessage(messages.emptyState)}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <CenteredPaddedLoadingIndicator
                        padding={null}
                        size={LoadingIndicatorSize.LARGE}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            ActionsComponent={({
              className,
              count,
              onChangePage,
              rowsPerPage,
            }) => {
              return (
                <Pagination
                  className={className}
                  count={Math.ceil(count / rowsPerPage)}
                  onChange={onChangePage}
                  page={variables.page}
                />
              )
            }}
            component="div"
            count={usersData?.totalCount}
            labelDisplayedRows={({ count, from, to }) => {
              return formatMessage(messages.labelDisplayedRows, {
                count,
                from,
                to,
              })
            }}
            labelRowsPerPage={formatMessage(messages.labelRowsPerPage)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={variables.page - 1}
            rowsPerPage={variables.limit}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
