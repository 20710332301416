/* eslint-disable react/prop-types */
import React from 'react'
import { Formik, Form } from 'formik'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'

import Divider from 'src/common/Divider'
import DonationAmountSection from './DonationAmountSection'
import DonationMessageSection from './DonationMessageSection'
import InfoContainer from 'src/common/InfoContainer'
import Pill from 'src/common/Pill'
import Button from 'src/common/button/Button'
import InternalLink from 'src/common/InternalLink'
import { validateValues } from '../utils/utils'

const DonationForm = ({
  anonymousDonation,
  availableD4dFunds,
  d4dConfiguration,
  errorsList,
  inputValue,
  matching,
  setMatching,
  handleSubmit,
  isSubmitting,
  charity,
  givingaUser,
  note,
  setNote,
  setAnonymousDonation,
  setInputValue,
  setIsValid,
  submissionError,
}) => {
  const strictProgram = d4dConfiguration.d4dProgramType === 'strict'
  /** if you have a strict program you can't change how much you donate through d4d and since we only match the
   * same amount as is done through d4d if d4d amount * 2 is greater than your funds you can't match */
  const insufficientFundsForMatching =
    (availableD4dFunds * 2 < givingaUser.availableDonationFunds &&
      strictProgram) ||
    !charity.matched
  const donationAmount = () => {
    if (isNaN(inputValue)) {
      return 0
    }
    if (matching && inputValue > 0) {
      return inputValue * 2
    }
    return inputValue
  }
  return (
    <Formik
      initialValues={{
        paymentMethod: 'grant_account',
        d4dProgramType: d4dConfiguration.d4dProgramType,
        donationAmount: availableD4dFunds,
        matched: false,
        anonymousDonation: false,
        donationNote: '',
      }}
      onSubmit={(model) => handleSubmit(model)}
      validate={(values) => {
        const errors = validateValues(values, setIsValid, availableD4dFunds)
        setIsValid(Object.keys(errors).length === 0)
        return errors
      }}
    >
      {({ isValid, errors }) => {
        return (
          <Form>
            <h2>{charity.name}</h2>
            <div>
              <Pill className="mr-2 my-2.5">
                <span className="body-small-semibold">
                  <FormattedMessage
                    defaultMessage="Dollars for Doers"
                    id="shared.dollarsForDoers"
                  />
                </span>
              </Pill>
              {d4dConfiguration.d4dAllowMatchingGifts && (
                <Pill className="my-2.5">
                  <span className="body-small-semibold">
                    <FormattedMessage
                      defaultMessage="Matching gift"
                      id="shared.matchingGift"
                    />
                  </span>
                </Pill>
              )}
            </div>
            <Divider className="mt-4 border-gray-2" />
            <DonationAmountSection
              insufficientFundsForMatching={insufficientFundsForMatching}
              availableD4dFunds={availableD4dFunds}
              d4dConfiguration={d4dConfiguration}
              errors={errors}
              inputValue={inputValue}
              matching={matching}
              setMatching={setMatching}
              givingaUser={givingaUser}
              setInputValue={setInputValue}
              strictProgram={strictProgram}
            />
            <DonationMessageSection
              anonymousDonation={anonymousDonation}
              setAnonymousDonation={setAnonymousDonation}
              note={note}
              setNote={setNote}
            />

            <div className="flex flex-col justify-center mt-8 items-center">
              <Divider className="mb-5 border-grayscale-2 w-full" />
              {submissionError && (
                <>
                  {errorsList.length ? (
                    <InfoContainer variant="error">
                      <ul>
                        {errorsList.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </InfoContainer>
                  ) : (
                    <InfoContainer variant="error">
                      <span>
                        There was an issue submitting your transaction
                      </span>
                    </InfoContainer>
                  )}
                </>
              )}
              <h2>
                <FormattedMessage
                  defaultMessage="Donate total: {amount}"
                  id="dollarsForDoersForm.donateTotal"
                  values={{
                    amount: (
                      <CurrencyFormat
                        className="headline-2"
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        value={donationAmount()}
                      />
                    ),
                  }}
                />
              </h2>
              <Button
                className="my-4 w-full"
                name="Donate now"
                type="submit"
                disabled={!isValid || isSubmitting || inputValue < 10}
              >
                <FormattedMessage
                  defaultMessage="Donate now"
                  id="dollarsForDoersForm.donateNow"
                />
              </Button>
              <p className="text-sm text-black-2">
                <FormattedMessage
                  defaultMessage="All donation transactions will appear as GIVINGA FOUNDATION, our nonprofit 501(c)(3) partner. By continuing, you are agreeing to the {link}."
                  id="donationForm.d4dFormBottomMessageTransaction"
                  values={{
                    link: (
                      <InternalLink
                        className="underline"
                        href="/donations/terms_and_conditions"
                      >
                        <FormattedMessage
                          defaultMessage="Terms of Service"
                          id="shared.termsOfService"
                        />
                      </InternalLink>
                    ),
                  }}
                />
              </p>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default DonationForm
