// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormLabel, InputLabel, Select } from '@material-ui/core'
import { defineMessages } from 'react-intl'

// WeSpire
import ScreenReader from 'components/ui/screen_reader_text'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { CampaignAnalytics } from 'utilities/analytics'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  filterBy: {
    defaultMessage: 'Filter By',
    description: 'Label for radio selection where users can filter results.',
    id: 'leaderBoardFilter.filterBy',
  },
  screenReaderAnnouncement: {
    defaultMessage: 'Leaders now filtered by {filter}',
    id: 'leaderBoardFilter.screenReaderAnnouncement',
  },
  screenReaderHint: {
    defaultMessage:
      'Choose an option to filter the list of Competition leaders',
    id: 'leaderBoardFilter.screenReaderHint',
  },
  teamLeaderboarFilterHint: {
    defaultMessage: 'Leaderboard Filter',
    description:
      'This is a hint for screen readers as to what a selection filters by',
    id: 'leaderBoardFilter.teamLeaderboarFilterHint',
  },
})

const LeaderBoardFilters = ({
  activityId,
  className,
  filterType,
  onChange,
  options,
}) => {
  const [filterId, setFilterId] = useState('all')

  const filters = [{ id: 'all', name: sharedTranslations.all }, ...options]

  const findFilter = (id) =>
    filters.find((filter) => String(filter.id) === String(id))

  const handleChangeFilter = (event) => {
    const filterId = event.target.value
    const filterName = findFilter(filterId).name
    CampaignAnalytics.leaderboardFilterClicked({
      activityId,
      activityType: 'Competition',
      filterId,
      filterName,
      filterType,
    })
    setFilterId(filterId)
    onChange(filterId === 'all' ? null : filterId)
  }

  return (
    <div className={className}>
      <FormControl aria-describedby="filter-leaders-hint" component="fieldset">
        <Stack
          className="d-sm-flex align-items-sm-center mt-3"
          space={{ _: 3, sm: 0 }}
        >
          <FormLabel
            className="[ flex-shrink-none ] [ fs-1 fw-semi-bold ls-2 px-3 text-black-1 text-uppercase ]"
            component="legend"
          >
            {intl.formatMessage(messages.filterBy)}
          </FormLabel>
          <ScreenReader
            id="filter-leaders-hint"
            text={intl.formatMessage(messages.screenReaderHint)}
          />

          <InputLabel className="sr-only" htmlFor="filter">
            {intl.formatMessage(messages.teamLeaderboarFilterHint)}
          </InputLabel>
          <Select
            autoWidth
            classes={{ root: 'bg-white' }}
            id="filter"
            inputProps={{
              'data-test': 'team-filter',
            }}
            native
            onChange={handleChangeFilter}
            value={filterId}
            variant="outlined"
          >
            {filters.map((filter) => (
              <option key={filter.id} value={filter.id}>
                {filter.name}
              </option>
            ))}
          </Select>
        </Stack>
      </FormControl>

      <ScreenReader
        aria-live="polite"
        text={intl.formatMessage(messages.screenReaderAnnouncement, {
          filter: findFilter(filterId).name,
        })}
      />
    </div>
  )
}

LeaderBoardFilters.propTypes = {
  activityId: PropTypes.string.isRequired,
  className: PropTypes.string,
  filterType: PropTypes.oneOf(['team', 'teamset']).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

LeaderBoardFilters.defaultProps = {
  className: null,
}

export { LeaderBoardFilters }
