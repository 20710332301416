import React, { useContext, useEffect, useState } from 'react'
import cx from 'classnames'
import SessionContext from 'src/contexts/SessionContext'

import Drawer from 'src/common/Drawer'
import {
  MainMobileMenu,
  MobileChannelMenu,
  OtherMenu,
} from 'src/common/Header/Menus'
import { mainLinks, otherLinks } from 'src/common/Header/Links'
import { useDropdownVisible } from 'src/hooks/useDropdownVisible'
import BreadcrumbContext from 'src/contexts/BreadcrumbContext'
import { gql, useQuery } from '@apollo/client'

import MenuToggleButton from 'src/common/Header/MenuToggleButton'
import NotificationsButton from 'src/common/Header/NotificationsButton'
import ProfileDropdown from 'src/common/Header/ProfileDropdown'
import MainMenu from 'src/common/Header/MainMenu'
import HeaderBreadcrumb from 'src/common/Header/HeaderBreadcrumb'
import Logo from 'src/common/Header/Logo'
import WeSpireAPI from 'utilities/wespire_api'
import useIsMobile from 'src/hooks/useIsMobile'

export const USER_NAV_QUERY = gql`
  query userNav {
    brandConfig {
      id
      isPointsDashboardEnabled
    }
    channels {
      id
      name
      logoImageUrl
    }
    carbonFootprintEnabled
    ergsEnabled
    givingEnabled
    ourImpactEnabled
    volunteerHistoryEnabled
    brand {
      logoUrl
    }
  }
`

const Header = ({ className }) => {
  const isMobile = useIsMobile()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [mobileNavigation, setMobileNavigation] = useState('mainLinks')
  const [logoUrl, setLogoUrl] = useState('')
  const { ref, dropdownOpen, setDropdownOpen } = useDropdownVisible(false)
  let { user, signedIn } = useContext(SessionContext)
  const { crumbs } = useContext(BreadcrumbContext)
  const { avatarUrl, firstName, managementDropdownNavigationLinks } = user || {}
  const {
    data: {
      carbonFootprintEnabled,
      ergsEnabled,
      givingEnabled,
      ourImpactEnabled,
      volunteerHistoryEnabled,
      channels,
      brandConfig: { isPointsDashboardEnabled } = {},
    } = {},
  } = useQuery(USER_NAV_QUERY)

  useEffect(() => {
    WeSpireAPI()
      .get('brands/landing')
      .then((response) => {
        const { logo_url } = response.data
        setLogoUrl(logo_url)
      })
  }, [])

  useEffect(() => {
    document.body.style.overflow = drawerOpen ? 'hidden' : ''
  }, [drawerOpen])

  let navLinks = (
    <MainMobileMenu
      avatarUrl={avatarUrl}
      ergsEnabled={ergsEnabled}
      firstName={firstName}
      mainLinks={mainLinks}
      ourImpactEnabled={ourImpactEnabled}
      setMobileNavigation={setMobileNavigation}
    />
  )

  if (mobileNavigation === 'channelLinks') {
    navLinks = (
      <MobileChannelMenu
        avatarUrl={avatarUrl}
        channels={channels}
        setMobileNavigation={setMobileNavigation}
      />
    )
  } else if (mobileNavigation === 'subLinks') {
    navLinks = (
      <OtherMenu
        carbonFootprintEnabled={carbonFootprintEnabled}
        givingEnabled={givingEnabled}
        isPointsDashboardEnabled={isPointsDashboardEnabled}
        managementDropdownNavigationLinks={managementDropdownNavigationLinks}
        volunteerHistoryEnabled={volunteerHistoryEnabled}
        otherLinks={otherLinks}
        setMobileNavigation={setMobileNavigation}
      />
    )
  } else {
    navLinks = (
      <MainMobileMenu
        avatarUrl={avatarUrl}
        ergsEnabled={ergsEnabled}
        mainLinks={mainLinks}
        firstName={firstName}
        ourImpactEnabled={ourImpactEnabled}
        setMobileNavigation={setMobileNavigation}
      />
    )
  }

  const onDrawerOpen = () => {
    setMobileNavigation('mainLinks')
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen)
  }

  return (
    <>
      {signedIn ? (
        <header className="w-full">
          <Drawer
            className="shadow-[0px_-1px_1px_#C4D1DB] sm:hidden"
            drawerOpen={drawerOpen}
            setDrawerOpen={onDrawerOpen}
          >
            {navLinks}
          </Drawer>
          <div
            className={cx(
              className,
              'flex flex-row justify-between border-b border-[#C4D1DB] sm:px-6 px-4 py-3 items-center text-base bg-white text-black-2'
            )}
          >
            <div className="flex flex-row">
              {isMobile && <MenuToggleButton onDrawerOpen={onDrawerOpen} />}
              <Logo logo={logoUrl} />
            </div>
            <div className="flex flex-row items-center">
              <NotificationsButton />
              <ProfileDropdown
                dropdownVisibleRef={ref}
                avatarUrl={avatarUrl}
                firstName={firstName}
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
                carbonFootprintEnabled={carbonFootprintEnabled}
                givingEnabled={givingEnabled}
                isPointsDashboardEnabled={isPointsDashboardEnabled}
                managementDropdownNavigationLinks={
                  managementDropdownNavigationLinks
                }
                volunteerHistoryEnabled={volunteerHistoryEnabled}
                otherLinks={otherLinks}
                setMobileNavigation={setMobileNavigation}
              />
            </div>
          </div>
          <MainMenu mainLinks={mainLinks(ergsEnabled, ourImpactEnabled)} />
          {!!crumbs.length && (
            <HeaderBreadcrumb className={className} crumbs={crumbs} />
          )}
        </header>
      ) : (
        <header className="w-full">
          <div
            className={cx(
              className,
              'flex flex-row drop-shadow w-full sm:px-6 p-4 items-center text-base bg-white text-black-2 shadow-[0_0_1px_#C4D1DB]'
            )}
          >
            <Logo logo={logoUrl} />
          </div>
        </header>
      )}
    </>
  )
}

export default Header
