// Setup
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Vendor
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

// WeSpire
import Stack from 'components/ui/stack'

/**
 * Used to wrap groups of form fields that need a group label and consistent
 * vertical spacing.
 */
const FormFieldGroup = ({ children, label, ...other }) => (
  <Stack {...other} className={cx('w-100', other.className)}>
    <FormLabel className={other.labelClassName}>{label}</FormLabel>
    {children}
  </Stack>
)

FormFieldGroup.propTypes = {
  /** render the field group as a custom component type */
  as: PropTypes.any,
  /** pass in children to render below the label */
  children: PropTypes.node.isRequired,
  /** render a label for the group */
  label: PropTypes.string.isRequired,
  /** specify vertical spacing between form fields */
  space: PropTypes.number,
}

FormFieldGroup.defaultProps = {
  as: FormControl,
  space: 3,
}

export default FormFieldGroup
