import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  emailLabel: {
    defaultMessage: 'email',
    id: 'loginMessages.emailLabel',
  },
  forgotPassword: {
    defaultMessage: 'Forgot Your Password?',
    id: 'loginMessages.forgotPassword',
  },
  needAccount: {
    defaultMessage: 'Need to create an account?',
    id: 'loginMessages.needAccount',
  },
  passwordLabel: {
    defaultMessage: 'password',
    id: 'loginMessages.passwordLabel',
  },
  signInButton: {
    defaultMessage: 'Sign In',
    id: 'loginMessages.signInInButton',
  },
  signInTo: {
    defaultMessage: 'Sign In to { brandName }',
    id: 'loginMessages.signInTo',
  },
  signInWithSSO: {
    defaultMessage: 'Sign In With Your Work Credentials',
    id: 'loginMessages.signInWithSSO',
  },
  validEmail: {
    defaultMessage: 'Must be a valid email',
    id: 'loginMessages.validEmail',
  },
})

export const loginMessages = formatMessages(messages)
