import React from 'react'
import PropTypes from 'prop-types'

// Vendors
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'

// WeSpire
import Icon from 'components/ui/icon'
import { Button } from 'components/ui/button'
import { donationHistoryMessages, messages } from '../utilities/messages'
import { intl } from 'utilities/localization'

const CancelRecurringDonationsDialog = ({ handleClose, children, open }) => {
  return (
    <Dialog data-test="cancel-recurring-dialog" maxWidth="xs" open={open}>
      <DialogTitle className=" d-flex justify-content-end p-0">
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon
            className="fs-5 text-black-2"
            iconName="close"
            title="close-recurring-donation"
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

CancelRecurringDonationsDialog.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export const CancelRecurringDonationsPopUp = ({
  handleCancelRecurringDonations,
  charityName,
  open,
  toggleDialogOpenClose,
}) => {
  return (
    <CancelRecurringDonationsDialog
      handleClose={toggleDialogOpenClose}
      open={open}
    >
      <DialogContent className="pt-0">
        <Typography data-test="cancel-recurring-charity-name">
          {intl.formatMessage(messages.stopDialogText, {
            charityName,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className="fs-2 | text-black-3 text-underline"
          onClick={toggleDialogOpenClose}
        >
          {donationHistoryMessages.goBack}
        </Button>
        <Button
          className="fs-2"
          data-test="cancel-recurring-button"
          onClick={handleCancelRecurringDonations}
        >
          {donationHistoryMessages.stopDonations}
        </Button>
      </DialogActions>
    </CancelRecurringDonationsDialog>
  )
}

CancelRecurringDonationsPopUp.propTypes = {
  charityName: PropTypes.string.isRequired,
  handleCancelRecurringDonations: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  toggleDialogOpenClose: PropTypes.func.isRequired,
}
