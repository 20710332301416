// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'

const { formatMessage } = intl

const SimpleBackButton = ({ to, 'data-test': dataTest }) => {
  return (
    <Link
      className="d-flex align-items-center p-0 m-0 text-black-3 fw-semi-bold mxw-1"
      data-test={dataTest}
      to={to}
    >
      <Icon className="fs-4 ml-1" iconName="arrow_left" title="Back" />
      <span className="fs-2">{formatMessage(sharedMessages.goBack)}</span>
    </Link>
  )
}

SimpleBackButton.propTypes = {
  'data-test': PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

SimpleBackButton.defaultProps = {
  'data-test': null,
}

export default SimpleBackButton
