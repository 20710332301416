// Setup
import gql from 'graphql-tag'

export const GET_POINTS_DASHBOARD_ENABLED = gql`
  query userScore {
    brandConfig {
      id
      isPointsDashboardEnabled
    }
  }
`

export const GET_POINTS_DASHBOARD_INFO = gql`
  query pointsDashboard(
    $first: Int
    $after: String
    $sort: String
    $type: String
    $year: String
  ) {
    currentUser {
      id
      benefitGroup {
        id
        currentYearPointsObjective
        name
        description
        pointsDashboardDescription
        slug
      }
      scores(
        first: $first
        after: $after
        sort: $sort
        type: $type
        year: $year
      ) {
        totalCount
        nodes {
          id
          date
          actionName
          actionType
          points
          url
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_SCORE_TYPES = gql`
  query scoreTypes {
    pointsDashboard {
      scoreTypes {
        label
        value
      }
    }
  }
`
