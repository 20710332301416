import React, { useContext } from 'react'
import Button from 'src/common/button/Button'
import InfoContainer from 'src/common/InfoContainer'
import TextHero from 'src/common/TextHero'
import { useCsrft } from 'src/hooks/useCsrft'
import SessionContext from 'src/contexts/SessionContext'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'

const SsoAuthForm = ({ hideTitle }) => {
  const { brand, signedIn } = useContext(SessionContext)
  const { tokenName, tokenValue } = useCsrft()

  const handleSubmit = () => {
    document.getElementById('hidden-sso-submit').click()
  }
  if (signedIn) {
    return <Redirect to="/take_action" />
  }
  if (brand?.authScheme === 'email') {
    return <Redirect to="/app/landing/email" />
  }
  if (brand?.authScheme === 'multiauth') {
    return <Redirect to="/app/landing/multiauth" />
  }
  return (
    <main className={`grid justify-items-center ${!hideTitle && 'mt-20'}`}>
      {!hideTitle && (
        <TextHero
          subtitle={brand?.heroSubtitle}
          title={brand?.heroTitle || brand?.name}
        />
      )}{' '}
      <form className="max-w-[37.5rem] w-11/12 px-4">
        <section className="flex flex-col my-8">
          <Button className="h-14 sso-sign-in" onClick={handleSubmit}>
            <FormattedMessage
              id="landingMessages.continueWithWorkCredentials"
              defaultMessage={'Continue with Your Work Credentials'}
            />
          </Button>
          {brand?.ssoDescription && (
            <InfoContainer className="max-w-150 mt-4 p-4" useIcon={false}>
              <div
                className="login-auth-description"
                dangerouslySetInnerHTML={{
                  __html: brand.ssoDescription,
                }}
              />
            </InfoContainer>
          )}
        </section>
      </form>
      <form action={brand?.ssoUrl} className="hidden" method="POST">
        <input name={tokenName} type="hidden" value={tokenValue} />
        <button
          id="hidden-sso-submit"
          type="submit"
          // DO NOT REMOVE THIS CLASSNAME, IT IS USED IN IOS FOR AKAMAI CERT STUFF
          className="class-for-ios-sign-in-get-path"
        />
      </form>
    </main>
  )
}

SsoAuthForm.propTypes = {
  hideTitle: PropTypes.bool,
}

SsoAuthForm.defaultProps = {
  hideTitle: false,
}

export default SsoAuthForm
