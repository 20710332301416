// Vendor
import React from 'react'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'

const Numbers = ({ children, className, element, ...other }) => {
  const Component = element ? element : 'span'

  return (
    <Component className={cx(className)}>
      <FormattedNumber value={children} {...other} />
    </Component>
  )
}

export default Numbers
