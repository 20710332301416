import gql from 'graphql-tag'

export const LIST_RETROACTIVE_MATCH_REQUESTS_QUERY = gql`
  query getRetroactiveMatchRequests($first: Int, $after: String, $status: String, $order: String!) {
    retroactiveMatchRequests(first: $first, after: $after, status: $status, order: $order) {
      totalCount
      nodes {
        request{
          amount
          status
        }
        originalTransaction{
          id
          activityId
          date
          created
        }
        charity{
          ein
          name
        }
        user{
          accountNumber
          email
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    brand {
      corporateAccountBalance
    }
  }
`
