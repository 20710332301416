// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'

/**
 * A simple bulleted list of errors designed to be displayed in a form error
 * banner. This is ideal to use when the errors for the form will not
 * correspond to the inputs in the form, so that there is no way to link to
 * individual fields that can be modified in order to address the errors.
 * If you expect errors to correspond to fields in the form, you should
 * consider using <FormErrorSummary> instead.
 */
const ErrorList = ({ errors, ...other }) => (
  <ul {...other}>
    <Stack space={1}>
      {errors.map((error) => (
        <li key={error}>{error}</li>
      ))}
    </Stack>
  </ul>
)

ErrorList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export { ErrorList }
