import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  accountType: {
    defaultMessage: 'type:',
    description: 'Label for the account type',
    id: 'donationHistory.accountType',
  },
  annualBudget: {
    defaultMessage:
      'Out of {amount} total annual budget for this calendar year',
    description:
      'label to display annual donation budget for this calendar year',
    id: 'donationHistory.annualBudget',
  },
  approved: {
    defaultMessage: 'Approved',
    description:
      "The status message we display to the user when the user's donation is accepted.",
    id: 'donationHistory.approved',
  },
  availableDonationFunds: {
    defaultMessage: 'available Donation funds',
    description: 'Label for a stat displaying available donation funds',
    id: 'donationHistory.availableDonationFunds',
  },
  cancelSuccess: {
    defaultMessage: 'Recurring donation successfully cancelled.',
    description: 'Success message when a recurring donation is cancelled.',
    id: 'donationHistory.cancelSuccess',
  },
  charityMatchingQuestion: {
    defaultMessage:
      "Is a charity that you're passionate about currently not eligible for a company Matching Gift?",
    description:
      'Label to ask if a charity is not eligible for a company Matching Gift',
    id: 'donationHistory.charityMatchingQuestion',
  },
  charityMatchingRequest: {
    defaultMessage: 'Request that a charity be eligible for matching.',
    description: 'Label to request a charity be eligible for matching',
    id: 'donationHistory.charityMatchingRequest',
  },
  charityReceivedFunds: {
    defaultMessage: 'Charity Received Funds',
    description: 'Date when a charity received donations',
    id: 'donationHistory.charityReceivedFunds',
  },
  companyContributions: {
    defaultMessage: '{amount} in company contributions',
    description: 'Label to display company contributions amount',
    id: 'donationHistory.companyContributions',
  },
  currencyShownInUsd: {
    defaultMessage: 'All donations are converted to and made in USD',
    description: 'Label that informs users of USD currency conversion',
    id: 'donationHistory.currencyShownInUsd',
  },
  denied: {
    defaultMessage: 'Denied',
    description:
      "The status message we display to the user when the user's donation is denied.",
    id: 'donationHistory.denied',
  },
  donatedToDate: {
    defaultMessage: '{amount} donated to date',
    description: 'Label to display charity total donations amount',
    id: 'donationHistory.donatedToDate',
  },
  donationAmount: {
    defaultMessage: 'amount:',
    description: 'Label for the donation amount',
    id: 'donationHistory.donationAmount',
  },
  donationDate: {
    defaultMessage: 'donated on',
    description: 'Label for the donation date',
    id: 'donationHistory.donationDate',
  },
  donationsEmptyState: {
    defaultMessage:
      'There are no donation transactions to display. Visit the <takeActionLink></takeActionLink> to find ways to donate!',
    description:
      'Message to encourage users to find other ways to donate when there are no donations to display',
    id: 'donationHistory.donationsEmptyState',
  },
  giveAgain: {
    defaultMessage: 'Give again',
    description: 'Label for make another donation',
    id: 'donationHistory.giveAgain',
  },
  goBack: {
    defaultMessage: 'Go back',
    id: 'donationHistoryMessages.goBack',
  },
  in: {
    defaultMessage: 'in',
    description: 'Preposition to indicate inclusion',
    id: 'donationHistory.in',
  },
  loadFailed: {
    defaultMessage: 'stat could not be loaded',
    description: 'Error message when data could not be displayed',
    id: 'donationHistory.loadFailed',
  },
  matchedAmount: {
    defaultMessage: 'matched:',
    description: 'Label for the matched donation amount',
    id: 'donationHistory.matchedAmount',
  },
  matchedTransaction: {
    defaultMessage: 'matched transaction',
    description: 'Label for the matched transaction section',
    id: 'donationHistory.matchedTransaction',
  },
  missingDonationAccount: {
    defaultMessage:
      'We cannot display your donation history because we could not find your donation account.',
    id: 'donationHistory.missingDonationAccount',
  },
  noScheduledUpcomingDonations: {
    defaultMessage: 'any scheduled donations',
    id: 'donationHistory.noScheduledUpcomingDonations',
  },
  offlineTransaction: {
    defaultMessage: 'Manually Donated',
    id: 'donationHistory.offlineTransaction',
  },
  paymentStatus: {
    defaultMessage: 'Status:',
    description:
      'Condition with respect to charity receiving a sent donation or matched donation',
    id: 'donationHistory.paymentStatus',
  },
  pending: {
    defaultMessage: 'Pending',
    description:
      "The status message we display to the user when the user's donation is pending.",
    id: 'donationHistory.pending',
  },
  personalContributions: {
    defaultMessage: '{amount} in personal contributions',
    description: 'Label to display personal contributions amount',
    id: 'donationHistory.personalContributions',
  },
  requestedAmount: {
    defaultMessage: 'requested:',
    description: 'Label for the requested match amount',
    id: 'donationHistory.requestedAmount',
  },
  scheduledDate: {
    defaultMessage: 'scheduled date:',
    description: 'The date when a recurring donation is made',
    id: 'donationHistory.scheduledDate',
  },
  stopDialogText: {
    defaultMessage:
      'Stopping donations will cancel this and all future scheduled donations to {charityName} charity. Are you sure?',
    id: 'donationHistory.stopDialogText',
  },
  stopDonations: {
    defaultMessage: 'Stop all donations',
    id: 'donationHistory.stopDonations',
  },
  stopMonthlyDonations: {
    defaultMessage: 'Stop monthly donations',
    id: 'donationHistory.stopMonthlyDonations',
  },
  totalAmountDonated: {
    defaultMessage: 'total amount donated',
    description: 'Label for a stat displaying total amount donated',
    id: 'donationHistory.totalAmountDonated',
  },
  upcomingDonationPaymentMethod: {
    defaultMessage: 'payment method:',
    description: 'The mode of payment used to make a recurring donation',
    id: 'donationHistory.upcomingDonationPaymentMethod',
  },
  usdCurrency: {
    defaultMessage: 'usd',
    description: 'US Dollars abbreviation',
    id: 'donationHistory.usdCurrency',
  },
  yourPastDonations: {
    defaultMessage: 'your past donations',
    description: 'Label for past donations made to charities',
    id: 'donationHistory.yourPastDonations',
  },
  yourUpcomingDonations: {
    defaultMessage: 'your upcoming scheduled donations',
    description: 'Label for upcoming scheduled donations',
    id: 'donationHistory.yourUpcomingDonations',
  },
})

export const donationHistoryMessages = formatMessages(messages)
