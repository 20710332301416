// Setup
import React from 'react'
import cx from 'classnames'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { abbreviatedDate } from 'utilities/date_formatter'
import { Heading } from 'components/ui/heading'
import { Image } from 'components/ui/image'
import Stack from 'components/ui/stack'
import WysiwygContent from 'components/ui/wysiwyg_content'

const BroadcastContent = ({ broadcast, className }) => {
  return (
    <Paper className={className}>
      {broadcast.image && (
        <Image
          alt={broadcast.headline}
          aspectRatio="1:1"
          className="border rounded-0"
          crop="fill"
          height="200"
          shape="square"
          src={broadcast.image}
          width="200"
        />
      )}
      <Stack
        className={cx({ 'ml-3': broadcast.image }, 'w-100')}
        divider="default"
        space={3}
      >
        <div>
          <Heading data-test="broadcast-headline" level={1}>
            {broadcast.headline}
          </Heading>
          <span className="fs-2" data-test="broadcast-date">
            {abbreviatedDate(broadcast.publishedAt)}
          </span>
        </div>
        <WysiwygContent data-test="broadcast-message">
          {broadcast.message}
        </WysiwygContent>
      </Stack>
    </Paper>
  )
}

BroadcastContent.propTypes = {
  broadcast: PropTypes.shape({
    headline: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    message: PropTypes.string,
    publishedAt: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
}

BroadcastContent.defaultProps = {
  className: null,
}

export { BroadcastContent }
