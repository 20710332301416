import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PropTypes } from 'prop-types'
import {
  ToggleButtonGroup,
  ToggleButton,
} from 'src/common/ToggleButton/ToggleButton'

const Heading = ({ handleStatsChange }) => {

  return (
    <div className="flex flex-col md:flex-row w-full">
      <h1 className="px-2">
        <FormattedMessage
          id="volunteering.volunteering"
          defaultMessage="Volunteering"
        />
      </h1>
      <ToggleButtonGroup
        exclusive
        defaultValue={'aYear'}
        onChange={handleStatsChange}
      >
        <ToggleButton value="aYear">
          <FormattedMessage
            id="pointsStats.thisYear"
            defaultMessage="This Year"
          />
        </ToggleButton>
        <ToggleButton value="allTime">
          <FormattedMessage
            id="pointsStats.allTime"
            defaultMessage="All Time"
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}

Heading.propTypes = {
  handleStatsChange: PropTypes.func.isRequired
}

export default Heading
