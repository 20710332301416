import gql from 'graphql-tag'

export const DONATE_MUTATION = gql`
  mutation donate(
    $activityId: ID
    $amount: Int!
    $anonymity: String!
    $charityId: ID!
    $charityName: String!
    $currencyId: ID!
    $ein: String
    $matched: Boolean!
    $notes: String
    $interval: String!
  ) {
    donate(
      activityId: $activityId
      amount: $amount
      anonymity: $anonymity
      charityId: $charityId
      charityName: $charityName
      currencyId: $currencyId
      ein: $ein
      matched: $matched
      notes: $notes
      interval: $interval
    ) {
      errors
      stripeAccount
      stripeSessionId
      stripePublishableKey
    }
  }
`

export const REQUEST_FUNDED_DONATION_MUTATION = gql`
  mutation requestFundedDonation(
    $activityId: ID
    $amount: Float!
    $anonymity: String!
    $ein: String!
    $matched: Boolean!
    $notes: String
  ) {
    requestFundedDonation(
      activityId: $activityId
      amount: $amount
      anonymity: $anonymity
      ein: $ein
      matched: $matched
      notes: $notes
    ) {
      errors
    }
  }
`
