import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const ENABLED_COUNTRIES_QUERY = gql`
  query enabledCountries {
    brand {
      id
      enabledCountries {
        id
        alpha3
        name
      }
      hasEnabledCountries
    }
  }
`

const useEnabledCountries = () => {
  const [enabledCountries, setEnabledCountries] = useState([])
  const [hasEnabledCountries, setHasEnabledCountries] = useState(true)

  const { loading, error, data } = useQuery(ENABLED_COUNTRIES_QUERY)

  useEffect(() => {
    if (!loading && !error) {
      const { enabledCountries, hasEnabledCountries } = data.brand
      setEnabledCountries(enabledCountries)
      setHasEnabledCountries(hasEnabledCountries)
    }
  }, [loading, error, data])

  return {
    enabledCountries,
    error,
    hasEnabledCountries,
    loading,
  }
}

export default useEnabledCountries
