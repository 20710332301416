// Setup
import React from 'react'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// Wespire components
import Stack from 'components/ui/stack'
import { messages } from 'components/carbon_footprint/utilities/messages'
import { Stat } from 'components/ui/stat'
import { CarbonImpactStat } from './CarbonImpactStat'
import { intl } from 'utilities/localization'
import { SkeletonLoading } from 'components/ui/skeleton_loading'

export const ImpactStats = ({
  actionsReducedText,
  carbonActionsCompleted,
  carbonImpact,
  loading,
}) => {
  const { formatMessage } = intl

  if (loading) return <SkeletonLoading height={200} />

  return (
    <Stack
      as={Paper}
      className="px-3 py-4"
      data-test="company-carbon-foot-impact-stat"
      divider="default"
      space={3}
    >
      <>
        <Stat
          amount={carbonActionsCompleted}
          label={formatMessage(messages.completedActions, {
            count: carbonActionsCompleted,
          })}
          size={7}
        />
        {carbonImpact && (
          <CarbonImpactStat
            actionsReducedText={actionsReducedText}
            equivalent={carbonImpact.equivalent}
            impactValue={carbonImpact.impactValue}
            key={carbonImpact.name}
            name={carbonImpact.name}
            units={carbonImpact.units}
          />
        )}
      </>
    </Stack>
  )
}

ImpactStats.defaultProps = {
  carbonActionsCompleted: 0,
  carbonImpact: null,
  loading: false,
}

ImpactStats.propTypes = {
  actionsReducedText: PropTypes.string.isRequired,
  carbonActionsCompleted: PropTypes.number,
  carbonImpact: PropTypes.shape({
    equivalent: PropTypes.string.isRequired,
    impactValue: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
}
