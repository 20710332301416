// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'

const EditButton = ({ buttonText, ideaBoardId, ideaId, ...other }) => (
  <LinkButton
    className={cx('fs-1 text-uppercase', other.className)}
    to={`/idea_boards/${ideaBoardId}/ideas/${ideaId}`}
    variant="text"
  >
    {buttonText}
    <Icon className="fs-3 ml-2 text-black-3" iconName="edit" title="" />
  </LinkButton>
)

EditButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  ideaBoardId: PropTypes.string.isRequired,
  ideaId: PropTypes.string.isRequired,
}

export { EditButton }
