import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

// westyle
import Body from 'src/pages/DonationSuccessPage/Body'
import Button from 'src/common/button/Button'
import Divider from 'src/common/Divider'
import { DONATION_RECEIPT_QUERY } from 'src/pages/DonationSuccessPage/queries/queries'
import Header from 'src/pages/DonationSuccessPage/Header'
import SkeletonLoading from 'src/common/SkeletonLoading'

const DonationSuccessPage = () => {
  const intl = useIntl()
  const { id } = useParams()
  const { data, error, isLoading } = useQuery(DONATION_RECEIPT_QUERY, {
    variables: { transactionId: id },
  })

  if (error)
    return (
      <div>
        {`${intl.formatMessage({
          id: 'dollarsForDoersForm.paymentMethod',
          defaultMessage: 'Payment Method',
        })}: ${error.message}`}
      </div>
    )

  if (!data || isLoading) return <SkeletonLoading />

  if (data) {
    const transaction = data.currentUser.givingaUser.transaction

    return (
      <main className="grid justify-items-center">
        <div className="lg:max-w-[50.5rem] rounded w-4xl bg-white border-[1px] border-grayscale-2 mb-20 mt-10 mx-4 sm:p-10 p-6">
          <Header />
          <Divider className="my-7 text-grayscale-1" />
          <Body
            donationMatchingEnabled={data.donationMatchingEnabled}
            name={data.currentUser.name}
            transaction={transaction}
          />
          <Divider className="my-7 text-grayscale-1" />
          <Button
            className="py-3 w-full"
            to="/users/me/donation_history"
            variant="neutral"
          >
            <FormattedMessage
              id="donationSuccess.seeYourDonationHistory"
              defaultMessage={'See your donation history'}
            />
          </Button>
        </div>
      </main>
    )
  }
}

export default DonationSuccessPage
