import gql from 'graphql-tag'

export const LIST_USER_CHANGES_QUERY = gql`
  query getUserChanges($first: Int, $after: String, $status: String, $action: String, $hrisImportId: String, $query: String) {
    userChanges(first: $first, after: $after, status: $status, action: $action, hrisImportId: $hrisImportId, query: $query) {
      totalCount
      nodes {
        user {
          firstName
          lastName
          email
        }
        status
        action
        message
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
