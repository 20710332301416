// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { Heading } from 'components/ui/heading'

const HeroTitle = ({ text, ...other }) => (
  <Heading
    {...other}
    aria-describedby="hero-label"
    className={cx(
      'fs-4 fs-sm-5 fs-md-6 fs-lg-7 ff-serif text-white',
      other.className
    )}
    data-test="hero-title"
    level={1}
  >
    {text}
  </Heading>
)

HeroTitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default HeroTitle
