// Vendor
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'

// Wespire
import { Button } from 'components/ui/button'
import CenteredContent from 'components/application/centered_content'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { groupTranslations } from 'components/group/shared_translations'
import { ErrorList } from 'components/form/error_list'
import { Heading } from 'components/ui/heading'
import Hero from 'components/ui/hero'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import { joinGroupMutation } from 'graphql/mutations/joinGroupMutation'
import { MembershipManagementMenu } from './manage_membership/membership_management_menu'
import ParticipantsSummary from 'components/application/participants_summary'
import Stack from 'components/ui/stack'
import SubmitButton from 'components/ui/submit_button'
import VerticalScrollBox from 'components/ui/vertical_scroll_box'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  aboutThisGroup: {
    defaultMessage: 'About {groupName}',
    id: 'groupDetails.aboutThisGroup',
  },
  altLogoText: {
    defaultMessage: '{groupName} logo',
    id: 'groupDetails.altLogoText',
  },
  ariaLabel: {
    defaultMessage: 'Group description',
    id: 'groupDetails.ariaLabel',
  },
  inviteMembers: {
    defaultMessage: 'Invite New Members',
    description: 'button text for inviting new members to the group',
    id: 'groupDetails.inviteMembers',
  },
  joinChapter: {
    defaultMessage: 'Join Chapter',
    description: 'button text for joining a chapter',
    id: 'groupDetails.joinChapter',
  },
  joinGroup: {
    defaultMessage: 'Join Group',
    description: 'button text for joining a group',
    id: 'groupDetails.joinGroup',
  },
  of: {
    defaultMessage: 'of',
    description: 'member of a group connection word',
    id: 'groupDetails.of',
  },
  youAreAMember: {
    defaultMessage: 'You are {membership} {groupNameComplement}',
    id: 'groupDetails.youAreAMember',
  },
})

const { formatMessage } = intl

const GroupDetails = ({ group, showModal }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isGroupMember = group.isChapter
    ? group.parentGroup.currentUserIsMember
    : group.currentUserIsMember
  const youAreAMemberText = formatMessage(messages.youAreAMember, {
    groupNameComplement:
      group.isChapter && !group.currentUserIsMember
        ? `${formatMessage(messages.of)} ${group.parentGroup.name}`
        : '',
    membership:
      group.currentUserMembershipVisible === false
        ? formatMessage(groupTranslations.anAnonymousMember)
        : formatMessage(groupTranslations.aMember),
  })

  const joinChapterButtonOrMembershipMenu = () => {
    if (group.currentUserIsMember) {
      return <MembershipManagementMenu group={group} />
    }
    if (isGroupMember) {
      return (
        <SubmitButton
          color="default"
          data-test="join-chapter-button"
          isSubmitting={isSubmitting}
          onClick={handleJoinChapter}
          variant="outlined"
        >
          {formatMessage(messages.joinChapter)}
        </SubmitButton>
      )
    }
  }

  const handleJoinChapter = () => {
    setIsSubmitting(true)
    joinGroupMutation({
      groupId: group.id,
      visible: null,
    })
      .then(
        ({
          data: {
            joinGroup: { errors },
          },
        }) => {
          displayBanner({
            as: 'div',
            content: errors.length ? (
              <ErrorList errors={errors} />
            ) : (
              formatMessage(groupTranslations.joinSuccessMessage, {
                membership: formatMessage(groupTranslations.aMember),
                name: group.name,
              })
            ),
            variant: errors.length ? 'error' : 'success',
          })
        }
      )
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(groupTranslations.joinOperation, {
            groupOrChapter: sharedTranslations.chapter,
          }),
        })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <>
      <Hero
        imgUrl={group.heroImage}
        label={group.isChapter ? group.name : null}
        title={group.isChapter ? group.parentGroup.name : group.name}
      />
      <Stack
        as={CenteredContent}
        className="position-relative px-3 px-sm-0"
        pull
        space={4}
      >
        <Paper className="py-3" component="section">
          <div className="row">
            <Stack
              className="[ d-flex col flex-column flex-grow w-100 ] [ py-2 ]"
              space={5}
            >
              <Heading
                className="px-3"
                data-test="about-group"
                level={2}
                variant={2}
              >
                {formatMessage(messages.aboutThisGroup, {
                  groupName: group.name,
                })}
              </Heading>
              <div className="[ row flex-nowrap d-flex align-items-center ] [ mt-3 px-3 ]">
                {group.logoImage && (
                  <div className="col-3">
                    <Image
                      alt={formatMessage(messages.altLogoText, {
                        groupName: group.name,
                      })}
                      crop="fit"
                      data-test="group-logo"
                      gravity="center"
                      onImageError={(img) => (img.style.display = 'none')}
                      responsive
                      src={group.logoImage}
                    />
                  </div>
                )}
                <VerticalScrollBox
                  ariaLabel={formatMessage(messages.ariaLabel)}
                  className="[ w-100 ] [ px-2 ]"
                  role="region"
                >
                  <WysiwygContent
                    className="w-100 my-0 px-1"
                    data-test="group-description"
                  >
                    {group.description || ''}
                  </WysiwygContent>
                </VerticalScrollBox>
              </div>
            </Stack>
            <div className=" [ col-auto ] [ border-left px-0 ] [ show-sm-up ]" />
            <Stack className="[ col-sm-4 ] [ py-2 | pl-sm-0 ]" space={3}>
              <div className="[ d-flex flex-column flex-shrink-none justify-content-between ] [ h-100 ][ px-4 ]">
                <ParticipantsSummary
                  avatarImages={group.members.nodes.map(
                    (user) => user.avatarUrl
                  )}
                  className="pb-2"
                  participantsCount={group.membersCount}
                  type={group.isChapter ? 'chapter_member' : 'group_member'}
                  variant={'stacked'}
                />
                {isGroupMember ? (
                  <div>
                    <p
                      className="fw-semi-bold fs-3 text-black"
                      data-test="member-indicator"
                    >
                      {youAreAMemberText}
                    </p>
                    {joinChapterButtonOrMembershipMenu()}
                  </div>
                ) : (
                  <Button
                    data-test="trigger-join-modal"
                    onClick={() => showModal(true)}
                    variant="neutral"
                  >
                    {formatMessage(messages.joinGroup)}
                  </Button>
                )}
              </div>
            </Stack>
          </div>
        </Paper>
      </Stack>
    </>
  )
}

GroupDetails.propTypes = {
  group: PropTypes.shape({
    currentUserIsMember: PropTypes.bool.isRequired,
    currentUserMembershipVisible: PropTypes.bool,
    description: PropTypes.string,
    heroImage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isChapter: PropTypes.bool.isRequired,
    logoImage: PropTypes.string,
    members: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    membersCount: PropTypes.number,
    name: PropTypes.string.isRequired,
    parentGroup: PropTypes.shape({
      currentUserIsMember: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userGroupChapter: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  showModal: PropTypes.func.isRequired,
}

export { GroupDetails }
