import React, { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'

// Vendor
import cx from 'classnames'

const FileUpload = ({ canClose = true, className, open = true, text }) => {
  const [isOpen, setIsOpen] = useState(open)

  const closeContainer = () => setIsOpen(false)

  return (
    <div
      className={cx(
        `bg-gallery rounded-lg flex flex-col m-4 mb-0 border-[1px] border-gray-2`,
        { hidden: !isOpen },
        className
      )}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row w-full py-4">
          <div className="flex flex-col justify-center text-2xl pl-4 text-green-success">
            <CheckCircleIcon />
          </div>
          <div className="flex flex-col w-full justify-center">
            <span className="pl-2 font-medium font-sans text-sm leading-4 text-black-body">
              {text}
            </span>
          </div>
          {canClose && (
            <button className="flex flex-row max-h-px pr-4">
              <CloseIcon className="text-gray-body" onClick={closeContainer} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileUpload
