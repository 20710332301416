// Setup
import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { CampaignAdditionalLocalesList } from 'setup/campaign_locales/campaign_additional_locales_list'
import { CampaignDefaultLocale } from 'setup/campaign_locales/campaign_default_locale'
import { intl } from 'utilities/localization'
import MgmtPanelPage from 'setup/application/page'

const messages = defineMessages({
  heading: {
    defaultMessage: 'Locales',
    id: 'campaignLocalesIndexPage.heading',
  },
})

/**
 * The index page for displaying all of a Campaign's Locales.
 */
const CampaignLocalesIndexPage = ({
  additionalLocales,
  campaignId,
  campaignName,
  defaultLocale,
}) => {
  const { formatMessage } = intl

  return (
    <MgmtPanelPage
      title={`${campaignName} - ${formatMessage(messages.heading)}`}
    >
      {() => (
        <div className="p-4">
          <div className="row vertical-gutters">
            <div className="col-8">
              <Paper>
                <CampaignAdditionalLocalesList
                  additionalLocales={additionalLocales}
                  campaignId={campaignId}
                />
              </Paper>
            </div>
            <div className="col-4">
              <Paper>
                <CampaignDefaultLocale
                  campaignId={campaignId}
                  defaultLocale={defaultLocale}
                />
              </Paper>
            </div>
          </div>
        </div>
      )}
    </MgmtPanelPage>
  )
}

CampaignLocalesIndexPage.propTypes = {
  additionalLocales: PropTypes.array.isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
}

export default CampaignLocalesIndexPage
