import gql from 'graphql-tag'

export const ACHIEVEMENTS_LIST_QUERY = gql`
  query ACHIEVEMENTS_LIST_QUERY(
    $brandId: ID!
    $selectedChannels: [ID!]
    $selectedYear: Int
  ) {
    achievements(
      brandId: $brandId
      selectedChannels: $selectedChannels
      selectedYear: $selectedYear
    ) {
      campaignChannelName
      campaignCompletionPoints
      campaignEndDate
      campaignId
      campaignName
      campaignPointsType
      campaignStartDate
      description
      id
      name
      points
    }
    brandConfig {
      id
      wordForScorePlural
    }
  }
`
export const CHANNELS_LIST_QUERY = gql`
  query CHANNELS_LIST_QUERY {
    channels {
      id
      name
    }
  }
`
