import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      light: '#319d41',
      main: '#1772DC',
    },
    secondary: {
      main: '#FA6400',
    },
  },
})
export default theme
