// Vendor
import React from 'react'

// WeSpire
import { AdditionalInformationCard } from 'components/ui/AdditionalInformationCard'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'this is a AdditionalInformationCard component',
    },
  },
  component: AdditionalInformationCard,
  title: 'UI/AdditionalInformationCard',
}

const Template = (args) => <AdditionalInformationCard {...args} />

export const AdditionalInformationCard1 = Template.bind({})
AdditionalInformationCard1.args = {
  dataTest: 'test-additional-information-card',
}
