// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import { WordForScore } from 'components/queries/word_for_score'

const messages = defineMessages({
  pointsEarned: {
    defaultMessage: '{wordForScore} Earned',
    id: 'shiftTimeframe.pointsEarned',
  },
  pointsForAttendance: {
    defaultMessage: '{wordForScore} For Attendance',
    id: 'shiftTimeframe.pointsForAttendance',
  },
})

export const getEventPointsLabel = (pointsAwarded) => {
  return intl.formatMessage(
    pointsAwarded === 'processed'
      ? messages.pointsEarned
      : messages.pointsForAttendance,
    {
      wordForScore: <WordForScore wordOnly />,
    }
  )
}

export const ShiftTimeframePoints = ({ points, pointsAwarded }) =>
  points ? (
    <p className="m-0 fs-2 fw-semi-bold text-uppercase text-black-2">
      {getEventPointsLabel(pointsAwarded)}
      {': '}
      <strong className="text-black-1">{points}</strong>
    </p>
  ) : null

ShiftTimeframePoints.propTypes = {
  points: PropTypes.number,
  pointsAwarded: PropTypes.bool,
}

ShiftTimeframePoints.defaultProps = {
  points: null,
  pointsAwarded: false,
}
