// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedNumber } from 'react-intl'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import BannerLink from 'components/application/banner/link'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import CloudinaryQuery from 'components/queries/cloudinary_query'
import {
  CustomField,
  getCustomFieldResponsesAttributes,
} from 'components/form/custom_field'
import {
  displayBanner,
  displayExceptionBanner,
  maybeDisplayUserTour,
} from 'redux/dispatchers'
import { DocumentTitle } from 'components/shared/document_title'
import EditableImageAttachment from 'components/application/editable_image_attachment'
import { ErrorList } from 'components/form/error_list'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSecondaryButton from 'components/form/action_bar/secondary_button'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormFieldset from 'components/form/fieldset'
import FormLimitedTextField from 'components/form/limited_text_field'
import { FormPage } from 'components/form/form_page'
import FormSelect from 'components/form/select'
import { GET_IDEA_BOARD_BY_ID, GET_IDEA_BOARD_WITH_IDEA } from 'graphql/queries'
import { IdeaBoardAnalytics } from 'utilities/analytics/'
import { Image } from 'components/ui/image'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { DELETE_IDEA, SUBMIT_IDEA, UPDATE_IDEA } from 'graphql/mutations'
import { TakeActionLink } from 'components/activities/link'
import WeQuery from 'components/application/we_query'
import { WordForScore } from 'components/queries/word_for_score'
import { RelativeTime } from 'components/shared/relative_time'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'
import { useUserScore } from 'utilities/hooks/useUserScore'

const messages = defineMessages({
  addedBanner: {
    defaultMessage: 'Idea successfully added.',
    description: 'Success banner when user submits an idea.',
    id: 'ideaFormPage.addedBanner',
  },
  addedBannerWithPoints: {
    defaultMessage:
      'Idea successfully added, and {stringifiedCount} {wordForScore} earned.',
    description:
      'Success banner when user submits an idea and points are earned.',
    id: 'ideaFormPage.addedBannerWithPoints',
  },
  closeToScorableSubmissionsLimitMessage: {
    defaultMessage:
      'Thanks for your submissions! Please note that this is your last {wordForScore} eligible Idea on this Idea Board.',
    description:
      'Success banner when user submits an idea and points are earned.',
    id: 'ideaFormPage.closeToScorableSubmissionsLimitMessage',
  },
  deleteButton: {
    defaultMessage: 'Delete',
    description: 'Text used on the delete button',
    id: 'editIdeaFormPage.deleteButton',
  },
  deleteConfirm: {
    defaultMessage:
      'Are you sure you want to delete your Idea? All associated votes and comments will be also deleted. This cannot be undone.',
    description: 'Confirmation message when user attempts to delete an Idea',
    id: 'ideaFormPage.deleteConfirm',
  },
  deleteConfirmWithPoints: {
    defaultMessage:
      'Are you sure you want to delete your Idea? {stringifiedCount} {wordForScore} will be removed from your account. All associated votes and comments will be also deleted. This cannot be undone.',
    description:
      'Confirmation message when user attempts to delete an scored Idea',
    id: 'ideaFormPage.deleteConfirmWithPoints',
  },
  deletedBanner: {
    defaultMessage: 'Idea successfully deleted.',
    description: 'Banner displayed when user successfully deletes an Idea',
    id: 'ideaFormPage.deletedBanner',
  },
  deleteOperation: {
    defaultMessage: 'delete your Idea',
    description:
      'Used to display a descriptive error, e.g. "failed to {delete your Idea}".',
    id: 'ideaFormPage.deleteOperation',
  },
  editHeading: {
    defaultMessage: 'Update Idea to {name}',
    description:
      'Heading of form where users can submit ideas to specific Idea Boards.',
    id: 'ideaFormPage.editHeading',
  },
  endedInfoBanner: {
    defaultMessage:
      'Submissions {relativeEndedTime}. Head back to {ideaBoardLink} to ' +
      'comment on submitted Ideas or visit the {takeActionLink} to find ' +
      'more opportunities to contribute!',
    description:
      'An info banner informing the user that Idea submissions are closed',
    id: 'ideaFormPage.endedInfoBanner',
  },
  heading: {
    defaultMessage: 'Submit Idea to {name}',
    description:
      'Heading of form where users can submit ideas to specific Idea Boards.',
    id: 'ideaFormPage.heading',
  },
  operation: {
    defaultMessage: 'submit your Idea',
    description:
      'Used to display a descriptive error, e.g. "failed to {submit your Idea}".',
    id: 'ideaFormPage.operation',
  },
  pointsLabel: {
    defaultMessage: '{wordForScore} for idea submission',
    description:
      'Used to describe the amount of points that will be earned by submitting an idea',
    id: 'ideaFormPage.pointsLabel',
  },
  titleLabel: {
    defaultMessage: 'Submission Title',
    description: 'Form label for Idea title input field.',
    id: 'ideaFormPage.titleLabel',
  },
  updatedBanner: {
    defaultMessage: 'Idea successfully updated.',
    description: 'Success banner when user updates an idea.',
    id: 'ideaFormPage.updatedBanner',
  },
  updateOperation: {
    defaultMessage: 'update your Idea',
    description:
      'Used to display a descriptive error, e.g. "failed to {update your Idea}".',
    id: 'ideaFormPage.updateOperation',
  },
})

/**
 * Top-level page where users can add new Ideas to an associated Idea Board.
 */
const IdeaFormPage = ({
  isPreview,
  history,
  match: {
    params: { id: ideaBoardID, idea_id: ideaID },
  },
}) => {
  const { incrementUserScore, reduceUserScore } = useUserScore()

  const [isImageUploading, setIsImageUploading] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [savedImage, setSavedImage] = useState(null)
  const { formatMessage } = intl

  const handleCreateIdea = (values) => {
    const { category, description, image, title } = values

    let attributes = {
      categoryId: parseInt(category),
      description,
      id: ideaID,
      ideaBoardId: ideaBoardID,
      image,
      title,
    }

    const responsesAttributes = getCustomFieldResponsesAttributes(values)
    if (responsesAttributes.length > 0) {
      // If custom field responses are submitted, include them.
      // customFieldResponsesAttributes is a rails-friendly name formatted
      // to ensure its GraphQL :argument counterpart can pass the nested
      // attributes directly to the IdeaBoard::Submission using the
      // accepts_nested_attributes_for naming convention.
      attributes.customFieldResponsesAttributes = responsesAttributes
    }

    setIsSubmitting(true)
    setIsSubmitDisabled(true)

    executeMutation(attributes)
  }

  const handleImageUploading = (isUploading) => setIsImageUploading(isUploading)

  const liveIdeaBoardUrl = `/idea_boards/${ideaBoardID}`
  const previewIdeaBoardUrl = `/management_panel${liveIdeaBoardUrl}/preview`

  const isFormSubmissionDisabled =
    isSubmitDisabled || isImageUploading || isPreview

  const showSubmissionsClosedNotice = (ideaBoard) => {
    const { endsAt, name } = ideaBoard

    displayBanner({
      content: formatMessage(messages.endedInfoBanner, {
        ideaBoardLink: <BannerLink to={liveIdeaBoardUrl}>{name}</BannerLink>,
        relativeEndedTime: (
          <b>
            <RelativeTime date={endsAt} showPrefix />
          </b>
        ),
        takeActionLink: <TakeActionLink isBannerLink />,
      }),
      variant: 'info',
    })
  }

  const mutationHash = (mutation, attributes) => {
    return {
      awaitRefetchQueries: true,
      mutation: mutation,
      refetchQueries: [
        {
          query: GET_IDEA_BOARD_BY_ID,
          variables: {
            ideaBoardID,
          },
        },
      ],
      variables: {
        attributes: attributes,
      },
    }
  }

  const mutationSuccessResponse = (idea, errors) => {
    setIsSubmitDisabled(false)
    // If we receive idea data back, then we are successful. If we don't,
    // that means that some kind of error occurred on the server. For
    // now, we are not providing any specific error feedback when that
    // happens, so we just display a generic error notification.
    if (idea) {
      let bannerContent

      if (ideaID) {
        IdeaBoardAnalytics.ideaUpdated(idea.id)
        bannerContent = formatMessage(messages.updatedBanner)
      } else {
        IdeaBoardAnalytics.ideaSubmitted(idea.id)

        if (idea.scored && idea.points) {
          bannerContent = formatMessage(messages.addedBannerWithPoints, {
            stringifiedCount: <FormattedNumber value={idea.points} />,
            wordForScore: <WordForScore score={idea.points} wordOnly />,
          })
          incrementUserScore(idea.points)
        } else {
          bannerContent = formatMessage(messages.addedBanner)
        }
      }

      history.push(`/idea_boards/${ideaBoardID}/?idea=${idea.id}`)

      displayBanner({
        content: bannerContent,
        variant: 'success',
      })
    } else {
      setIsSubmitting(false)
      displayBanner({
        as: 'div',
        content: <ErrorList errors={errors} />,
        variant: 'error',
      })
    }
  }

  const executeUpdateMutation = (attributes) => {
    client
      .mutate(mutationHash(UPDATE_IDEA, attributes))
      .then(
        ({
          data: {
            updateIdea: { errors, idea },
          },
        }) => {
          mutationSuccessResponse(idea, errors)
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        setIsSubmitDisabled(false)
        displayExceptionBanner({
          operation: formatMessage(messages.updateOperation),
        })
      })
  }
  const executeSubmitMutation = (attributes) => {
    client
      .mutate(mutationHash(SUBMIT_IDEA, attributes))
      .then(
        ({
          data: {
            submitIdea: { errors, idea },
          },
        }) => {
          mutationSuccessResponse(idea, errors)
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        setIsSubmitDisabled(false)
        displayExceptionBanner({
          operation: formatMessage(messages.operation),
        })
      })
  }

  const handleDeleteIdea = ({
    displayPointsLabel,
    pointsPerIdeaBoardSubmission,
  }) => {
    setIsSubmitting(true)
    client
      .mutate({
        awaitRefetchQueries: true,
        mutation: DELETE_IDEA,
        refetchQueries: [
          {
            query: GET_IDEA_BOARD_BY_ID,
            variables: {
              ideaBoardID,
            },
          },
        ],
        variables: {
          ideaId: ideaID,
        },
      })
      .then(
        ({
          data: {
            deleteIdea: { errors },
          },
        }) => {
          if (errors.length) {
            displayBanner({
              as: 'div',
              content: <ErrorList errors={errors} />,
              variant: 'error',
            })
          } else {
            history.push(`/idea_boards/${ideaBoardID}`)
            displayBanner({
              content: formatMessage(messages.deletedBanner),
              variant: 'success',
            })
            displayPointsLabel && reduceUserScore(pointsPerIdeaBoardSubmission)
          }
        }
      )
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(messages.deleteOperation),
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const executeMutation = (attributes) => {
    return ideaID
      ? executeUpdateMutation(attributes)
      : executeSubmitMutation(attributes)
  }

  const getQuery = () => {
    return ideaID ? GET_IDEA_BOARD_WITH_IDEA : GET_IDEA_BOARD_BY_ID
  }

  const getVariables = () => {
    return ideaID ? { ideaBoardID, ideaID } : { ideaBoardID }
  }

  const headingText = () => {
    return ideaID ? messages.editHeading : messages.heading
  }

  const pointsLabel = (pointsPerIdeaBoardSubmission) => {
    return formatMessage(messages.pointsLabel, {
      wordForScore: (
        <WordForScore score={pointsPerIdeaBoardSubmission} wordOnly />
      ),
    })
  }

  return (
    <WeQuery
      error={
        <MissingResourcePage resourceName={sharedTranslations.ideaboard} />
      }
      loader={<CenteredPaddedLoadingIndicator />}
      query={getQuery()}
      variables={getVariables()}
    >
      {({ data: { brandConfig, currentUser, ideaBoard } }) => {
        const {
          categories,
          canEarnPoints,
          closeToScorableSubmissionsLimit,
          customFields,
          ended,
          ideaDescriptionMax,
          ideaTitleMax,
          imageUrl,
          name,
          idea,
          pointsPerIdeaBoardSubmission,
        } = ideaBoard

        // have to copy since Apollo array/objects are not extensible
        const customFieldsCopy = cloneDeep(customFields)
        const { wordForScorePlural } = brandConfig

        const currentUserID = currentUser.id
        if (ended) {
          showSubmissionsClosedNotice(ideaBoard)
        }
        const ideaBoardUrl = isPreview ? previewIdeaBoardUrl : liveIdeaBoardUrl
        let ideaTitle
        let ideaCategory
        let ideaDescription
        let ideaUserID
        let displayPointsLabel

        if (idea !== undefined) {
          ideaTitle = idea.title
          ideaCategory = idea.category ? idea.category.id : null
          ideaDescription = idea.description
          ideaUserID = idea.user.id
          displayPointsLabel = idea.scored && pointsPerIdeaBoardSubmission
          customFieldsCopy.forEach((customField) => {
            let customFieldResponse = idea.customFieldResponses.find(
              (element) => element.customFieldId === customField.id
            )
            customField['response'] = customFieldResponse.response
          })
          setSavedImage(idea.imageUrl)
        } else {
          displayPointsLabel = canEarnPoints && pointsPerIdeaBoardSubmission
        }

        const handleSubmitForm = (values) => {
          if (closeToScorableSubmissionsLimit) {
            window.confirm(
              formatMessage(messages.closeToScorableSubmissionsLimitMessage, {
                wordForScore: wordForScorePlural,
              })
            ) && handleCreateIdea(values)
          } else {
            handleCreateIdea(values)
          }
        }

        const handleDeleteLink = () => {
          let confirmMessage
          if (displayPointsLabel) {
            confirmMessage = formatMessage(messages.deleteConfirmWithPoints, {
              stringifiedCount: pointsPerIdeaBoardSubmission,
              wordForScore: wordForScorePlural,
            })
          } else {
            confirmMessage = formatMessage(messages.deleteConfirm)
          }
          window.confirm(confirmMessage) &&
            handleDeleteIdea({
              displayPointsLabel,
              pointsPerIdeaBoardSubmission,
            })
        }

        return (
          <DocumentTitle
            title={formatMessage(headingText(), {
              name: name,
            })}
          >
            {maybeDisplayUserTour('ideaBoardIdeaFormPage')}

            <FormPage
              backLinkSubject={sharedTranslations.ideaboard}
              backLinkTo={ideaBoardUrl}
              description={
                imageUrl && (
                  <Image
                    alt=""
                    className="elevation-2 rounded"
                    data-object-fit
                    height="144"
                    responsive
                    src={imageUrl}
                  />
                )
              }
              heading={formatMessage(messages.heading, {
                name: <b>{name}</b>,
              })}
              onValidSubmit={handleSubmitForm}
              topInfoBox={
                displayPointsLabel && (
                  <p className="m-0" data-test="points-earning-banner">
                    <span className="fs-1 text-black-2 text-uppercase fw-semi-bold ]">
                      {pointsLabel(pointsPerIdeaBoardSubmission)}:
                    </span>
                    <span className="fs-2 text-capitalize fw-semi-bold | ml-sm-1">
                      {pointsPerIdeaBoardSubmission}
                    </span>
                  </p>
                )
              }
            >
              <FormFieldset label={sharedTranslations.details}>
                <FormLimitedTextField
                  label={formatMessage(messages.titleLabel)}
                  maxLength={ideaTitleMax}
                  name="title"
                  required
                  textFieldProps={{
                    disabled: ended,
                  }}
                  value={ideaTitle}
                />

                {customFieldsCopy.length === 0 && (
                  <FormLimitedTextField
                    label={sharedTranslations.description}
                    maxLength={ideaDescriptionMax}
                    multiline
                    name="description"
                    required
                    textFieldProps={{
                      disabled: ended,
                    }}
                    value={ideaDescription}
                  />
                )}

                {categories.length > 0 && (
                  <FormSelect
                    formControlProps={{
                      disabled: ended,
                    }}
                    name="category"
                    options={categories.map((category) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                    required
                    textFieldProps={{
                      label: sharedTranslations.category,
                    }}
                    value={ideaCategory}
                  />
                )}

                {customFieldsCopy.map((field) => (
                  <CustomField disabled={ended} field={field} key={field.id} />
                ))}

                <div className="mb-4 mt-3">
                  <CloudinaryQuery>
                    {(cloudinaryConfig) => (
                      <EditableImageAttachment
                        cloudinaryConfig={cloudinaryConfig}
                        disabled={isPreview || ended}
                        name="image"
                        onImageUploading={handleImageUploading}
                        // TODO figure out a better way to deal with async fields.
                        // Need to pass in savedImage explicitly to deal with
                        // this component getting rendered asynchronously due
                        // to the CloudinaryQuery wrapper.
                        shape="square"
                        value={savedImage}
                      />
                    )}
                  </CloudinaryQuery>
                </div>
              </FormFieldset>

              <FormActionBar
                disabled={isFormSubmissionDisabled || ended}
                hideBack
              >
                {ideaUserID === currentUserID && (
                  <>
                    <FormActionBarSecondaryButton
                      data-test="delete-button"
                      disabled={isFormSubmissionDisabled || ended}
                      onClick={handleDeleteLink}
                    >
                      {formatMessage(messages.deleteButton)}
                    </FormActionBarSecondaryButton>

                    <FormActionBarSubmitButton
                      className="ml-auto"
                      disabled={isFormSubmissionDisabled || ended}
                      isSubmitting={isSubmitting}
                    />
                  </>
                )}
              </FormActionBar>
            </FormPage>
          </DocumentTitle>
        )
      }}
    </WeQuery>
  )
}

IdeaFormPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isPreview: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      idea_id: PropTypes.string,
    }),
  }).isRequired,
}

IdeaFormPage.defaultProps = {
  isPreview: false,
}

export { IdeaFormPage }
