// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import MUIDivider from '@material-ui/core/Divider'

// 1. MUI defaults to an <hr> component which has semantic meaning (thematic
// break) that we don't want so we use a generic <div> instead.

/**
 * Horizontal line that acts as a purely visual content separator. Good for
 * one-off uses. If you're mapping through items and need to add a border in
 * between them all, use <Stack divider="default" /> instead.
 */
const Divider = (props) => (
  <MUIDivider
    {...props}
    className={cx('bg-black-5', props.className)}
    component="div" // 1
  />
)

Divider.propTypes = {
  className: PropTypes.string,
}

Divider.defaultProps = {
  className: null,
}

export default Divider
