// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import cx from 'classnames'

// WeSpire
import { numericDate } from 'utilities/date_formatter'
import { sharedTranslations } from 'components/shared/translations'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import LinkButton from 'components/ui/link_button'
import { TextLink } from 'components/shared/text_link'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import WeQuery from 'components/application/we_query'
import { MatchedTransaction } from './matched_transaction'

// sub-components
import { matchStatus } from '../utilities/transaction_utils'
import { donationHistoryMessages } from '../utilities/messages'
import { givingActivity } from '../utilities/queries'

/**
 * Renders a donation history transaction.
 */
export const DonationHistoryTransaction = ({
  accountType,
  activityId,
  charityName,
  donationAmount,
  donationDate,
  ein,
  matchedTransaction,
  offline,
  paymentStatus,
  organizationType,
  recurringEnabled,
}) => {
  const type = {
    direct: 'Personal',
    funded: 'Company',
    grant: 'Grant',
  }[accountType]

  const notOfflineStatus = !offline && paymentStatus

  const formatDate = (date) => (
    <time dateTime={donationDate}>{numericDate(date)}</time>
  )

  const Component = recurringEnabled ? 'div' : 'li'

  return (
    <Component
      className="fw-semi-bold lh-md"
      data-test="donation-history-transaction"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="col-8 d-md-inline-flex fs-2 p-0"
          data-test="donation-history-charity"
        >
          {!recurringEnabled && charityName}
          <div className="ml-3 row-md">
            <div className="row">
              <BulletSpacer
                className={cx('d-none fs-3 px-0', {
                  'd-sm-block': !recurringEnabled,
                })}
              />
              <div className="text-black-3 text-sentence">
                {donationHistoryMessages.donationDate}
              </div>
              <div
                className="ml-1"
                style={{
                  marginRight: '1.7rem',
                }}
              >
                {formatDate(donationDate)}
              </div>
            </div>
            {activityId && (
              <WeQuery
                loader={<CenteredPaddedLoadingIndicator />}
                query={givingActivity}
                variables={{ id: activityId }}
              >
                {({ data: { givingActivity } }) =>
                  givingActivity && (
                    <div className="row">
                      <div className="text-black-3 mr-1">
                        {donationHistoryMessages.in}
                      </div>
                      <TextLink
                        data-test="giving-activity-link"
                        to={`/giving_activities/${activityId}`}
                      >
                        {givingActivity.name}
                      </TextLink>
                    </div>
                  )
                }
              </WeQuery>
            )}
          </div>
        </div>
        {activityId && !recurringEnabled && (
          <LinkButton
            className="col-4 col-md-auto fs-1 fs-md-2 lh-xs"
            data-test="donation-history-give-again"
            to={`/donations/new/${ein}?search_type=charity`} // TODO check for GG project
            variant="neutral"
          >
            {donationHistoryMessages.giveAgain}
          </LinkButton>
        )}
      </div>
      <div className="row mt-3 mt-md-0">
        <div className="col-6 col-md-12">
          <div
            className={cx('[ row flex-column | flex-sm-row ] [ fs-1 my-0 ]', {
              'pl-1': recurringEnabled,
            })}
          >
            <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ]">
              <div className="text-black-3 text-uppercase">
                {donationHistoryMessages.donationAmount}
              </div>
              <div className="ml-1">
                {
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={donationAmount}
                  />
                }
              </div>
            </div>
            {type && (
              <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ]">
                <div className="text-black-3 text-uppercase">
                  {donationHistoryMessages.accountType}
                </div>
                <div className="text-capitalize ml-1 ws-nowrap">{type}</div>
              </div>
            )}
            {offline && (
              <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ]">
                <div
                  className="text-capitalize ml-0"
                  data-test="offline-donation"
                >
                  {donationHistoryMessages.offlineTransaction}
                </div>
              </div>
            )}
            {notOfflineStatus && (
              <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ml-0 ml-md-5 ml-lg-0 ]">
                <div className="text-black-3 text-uppercase">
                  {sharedTranslations.statusLabel}:
                </div>
                <div
                  className="text-capitalize text-center ml-1 ws-nowrap"
                  data-test="payment-status"
                >
                  {paymentStatus}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={cx('col-6 col-md-12', { 'ml-1': recurringEnabled })}>
          {matchedTransaction && (
            <MatchedTransaction
              matchedAmount={matchedTransaction.matchedAmount}
              requestedAmount={matchedTransaction.requestedAmount}
              status={matchStatus(matchedTransaction.status)}
            />
          )}
        </div>
      </div>
    </Component>
  )
}

DonationHistoryTransaction.propTypes = {
  /** type of givinga account this transaction is associated with */
  accountType: PropTypes.oneOf(['direct', 'funded', 'grant']).isRequired,
  /** the activity id associated with the donation */
  activityId: PropTypes.string,
  /** name of charity associated with donation */
  charityName: PropTypes.string.isRequired,
  /** amount donated to associated charity */
  donationAmount: PropTypes.number.isRequired,
  /** date donation was made */
  donationDate: PropTypes.string.isRequired,
  /** ein of the charity */
  ein: PropTypes.string.isRequired,
  /** for eligible charities, matching data for the transaction */
  matchedTransaction: PropTypes.shape({
    /** amount disbursed to charity if approved */
    matchedAmount: PropTypes.number.isRequired,
    /** Requested donation matching amount */
    requestedAmount: PropTypes.number.isRequired,
    /** State of matching request */
    status: PropTypes.string.isRequired,
  }),
  /** transaction was done through givinga or not */
  offline: PropTypes.bool.isRequired,
  /** payment status of the donation*/
  paymentStatus: PropTypes.string,
  /** display transactions depending on feature flag recurring*/
  recurringEnabled: PropTypes.bool,
  /** is the transaction associated with a charity or gg project */
  organizationType: PropTypes.string.isRequired
}

DonationHistoryTransaction.defaultProps = {
  activityId: null,
  matchedTransaction: null,
  paymentStatus: null,
  recurringEnabled: false,
}
