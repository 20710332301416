// Vendor
import React from 'react'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import WeQuery from 'components/application/we_query'
import { DocumentTitle } from 'components/shared/document_title'
import { Label } from 'components/ui/Typography/Label'
import { sharedTranslations } from 'components/shared/translations'
import { Heading } from 'components/ui/Typography/Heading'
import { MarkdownText } from 'components/ui/Typography/MarkdownText'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { privacyPolicyQuery } from './utilities/queries'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { Surface } from 'components/ui/Surface'
import { abbreviatedDate } from 'utilities/date_formatter'

const PrivacyPolicy = () => {
  return (
    <DocumentTitle title={sharedTranslations.privacyPolicy}>
      <CenteredContent>
        <WeQuery
          error={
            <MissingResourceSection
              errorDetails={sharedTranslations.privacyPolicy}
            />
          }
          loader={<SkeletonLoading height={245} />}
          query={privacyPolicyQuery}
        >
          {({
            data: {
              brand: { name },
              currentPrivacyPolicy,
            },
          }) => {
            return (
              <Surface className="my-3" dataTest="policy-details">
                <Heading data-test="page-name" level={1}>
                  {`${name} ${sharedTranslations.privacyPolicy}`}
                </Heading>
                <Label
                  className="text-black-1"
                  data-test="last-update"
                  uppercase
                >
                  {`${sharedTranslations.lastUpdated} ${abbreviatedDate(
                    currentPrivacyPolicy.createdAt
                  )}`}
                </Label>
                <MarkdownText className="py-4">
                  {currentPrivacyPolicy.text}
                </MarkdownText>
              </Surface>
            )
          }}
        </WeQuery>
      </CenteredContent>
    </DocumentTitle>
  )
}

export { PrivacyPolicy }
