// Setup
import React from 'react'
import PropTypes from 'prop-types'

// A parent class for all form components in our app. It is not intended to be
// rendered on its own; it extends from `React.Component` so that children can
// extend from WeSpireForm and still be components.

class WeSpireForm extends React.Component {
  handleChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  parseErrors(errorData) {
    const errors = errorData.errors

    let errorState = {}

    // eslint-disable-next-line no-unused-vars
    for (let attribute in errors) {
      errorState[attribute] = errors[attribute].join(', ')
    }

    return errorState
  }
}

// This doesn't cause React to check whether we are always passing these props
// to children of this class, so this is really just here to inform ourselves
// that children of this class are expected to have these props. You have to
// to add these propType declerations for these props in children of this class
// as well.
WeSpireForm.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  onSuccess: PropTypes.func.isRequired,
}

export default WeSpireForm
