// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'
import Paper from '@material-ui/core/Paper'

// WeSpire
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { IneligibleForPvh } from 'components/pvh'
import { volunteerHistoryLogGroupMessages as messages } from '../utilities/messages'
import { GrantEligibilityChecker } from './volunteer_history_grant_eligibility_checker'
import { VolunteerHistoryLog } from './volunteer_history_log'

/**
 * Renders a list of Volunteer logs grouped by their shared organization.
 */
export const VolunteerHistoryLogGroup = ({
  ein,
  enablePaidVolunteerHours,
  organizationName,
  scheduledVolunteerHours,
  totalPaidVolunteerHours,
  totalVolunteerHours,
  volunteerLogs,
  ...other
}) => {
  const { formatMessage } = intl

  return (
    <Paper
      {...other}
      className={cx('overflow-hidden', other.className)}
      data-test="volunteer-history-log-group"
    >
      <div className="[ d-flex align-items-center justify-content-between ] [ bg-blue-2 p-3 ]">
        <h3 className="fs-3 fw-semi-bold lh-sm my-0">
          {organizationName}
          <p
            className="fs-1 fw-regular ls-2 m-0 text-uppercase"
            data-test="organization-stats"
          >
            {formatMessage(messages.completedHours, {
              count: totalVolunteerHours,
              stringifiedCount: <FormattedNumber value={totalVolunteerHours} />,
            })}
            {', '}
            {formatMessage(messages.scheduledHours, {
              count: scheduledVolunteerHours,
              stringifiedCount: (
                <FormattedNumber value={scheduledVolunteerHours} />
              ),
            })}
            {ein && enablePaidVolunteerHours && (
              <>
                {', '}
                {formatMessage(messages.paidVolunteerHours, {
                  count: totalPaidVolunteerHours,
                  stringifiedCount: (
                    <FormattedNumber value={totalPaidVolunteerHours} />
                  ),
                })}
              </>
            )}
          </p>
        </h3>
        <div data-test="organization-elegibility">
          <GraphQLFeatureFlag
            error={null}
            featureDisabledError={null}
            featureName="grantDonation"
            loader={null}
          >
            <GrantEligibilityChecker
              ein={ein}
              organizationName={organizationName}
            />
          </GraphQLFeatureFlag>
          {!ein && enablePaidVolunteerHours && (
            <IneligibleForPvh
              className="pl-4"
              organizationName={organizationName}
              orgAssociatedToVolunteerEvent
            />
          )}
        </div>
      </div>
      <Stack className="p-3" divider="decorative" space={3}>
        {volunteerLogs.map((log) => (
          <VolunteerHistoryLog
            claimedPaidVolunteerHours={log.claimedPaidVolunteerHours}
            date={log.date}
            ein={ein}
            enablePaidVolunteerHours={enablePaidVolunteerHours}
            eventName={log.eventName}
            eventUrl={log.eventUrl}
            hours={log.hours}
            isComplete={log.isComplete}
            isForPlatformEvent={log.isForPlatformEvent}
            key={log.id}
            logId={log.id}
          />
        ))}
      </Stack>
    </Paper>
  )
}

VolunteerHistoryLogGroup.propTypes = {
  /** ein of organization associated with volunteer event */
  ein: PropTypes.string,
  /** boolean indicating if paid volunteer hours is enabled */
  enablePaidVolunteerHours: PropTypes.bool,
  /** name of organization associated with volunteer event */
  organizationName: PropTypes.string.isRequired,
  /** total hours scheduled to volunteer with this organization  */
  scheduledVolunteerHours: PropTypes.number.isRequired,
  /** total hours to submitted for pvh for this organization  */
  totalPaidVolunteerHours: PropTypes.number.isRequired,
  /** total hours to date volunteered with this organization  */
  totalVolunteerHours: PropTypes.number.isRequired,
  /** array of volunteer logs */
  volunteerLogs: PropTypes.arrayOf(
    PropTypes.shape({
      claimedPaidVolunteerHours: PropTypes.number,
      date: PropTypes.object.isRequired,
      eventName: PropTypes.string,
      eventUrl: PropTypes.string,
      hours: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      isComplete: PropTypes.bool.isRequired,
      isForPlatformEvent: PropTypes.bool.isRequired,
    })
  ).isRequired,
}

VolunteerHistoryLogGroup.defaultProps = {
  ein: null,
  enablePaidVolunteerHours: false,
}
