// Vendor
import React from 'react'
import { defineMessages, FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { participationIndicatorText } from './utils'
import { ActivityCard } from './activity_card'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  participatedByEveryone: {
    defaultMessage: '{text} by everyone',
    id: 'endedActivityCard.participatedByEveryone',
  },
  participatedByYou: {
    defaultMessage: '{text} by you',
    id: 'endedActivityCard.participatedByYou',
  },
})

const EndedActivityCard = ({
  achievement,
  actionUrl,
  endsAt,
  name,
  progressPercent,
  startsAt,
  totalParticipationIndicators,
  totalParticipationIndicatorsForUser,
  type,
  ...other
}) => {
  const { formatMessage } = intl

  const text = participationIndicatorText(type)

  return (
    <ActivityCard
      {...other}
      achievement={achievement}
      actionUrl={actionUrl}
      data-test="ended-activity"
      endsAt={endsAt}
      name={name}
      progressPercent={progressPercent}
      startsAt={startsAt}
      type={type}
    >
      {text && (
        <Stack space={1}>
          <p data-test="your-stat">
            <span className="fw-semi-bold">
              <FormattedNumber value={totalParticipationIndicatorsForUser} />
            </span>
            &nbsp;
            <span>
              {formatMessage(messages.participatedByYou, {
                text,
              })}
            </span>
          </p>
          <p data-test="everyone-stat">
            <span className="fw-semi-bold">
              <FormattedNumber value={totalParticipationIndicators} />
            </span>
            &nbsp;
            <span>
              {formatMessage(messages.participatedByEveryone, {
                text: text,
              })}
            </span>
          </p>
        </Stack>
      )}
    </ActivityCard>
  )
}

EndedActivityCard.propTypes = {
  achievement: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  endsAt: PropTypes.string,
  name: PropTypes.string.isRequired,
  progressPercent: PropTypes.number,
  startsAt: PropTypes.string.isRequired,
  totalParticipationIndicators: PropTypes.number,
  totalParticipationIndicatorsForUser: PropTypes.number,
  type: PropTypes.string.isRequired,
}

EndedActivityCard.defaultProps = {
  achievement: null,
  className: null,
  endsAt: null,
  progressPercent: null,
  totalParticipationIndicators: null,
  totalParticipationIndicatorsForUser: null,
}

export { EndedActivityCard }
