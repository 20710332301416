// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// WeSpire
import { displayBanner } from 'redux/dispatchers'
import FormErrorSummary from 'components/form/error_summary'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  resourceAddedSuccessfully: {
    defaultMessage: 'Resource successfully added to { groupName }',
    id: 'groupResourceForm.resourceAddedSuccessfully',
  },
  resourceArchivedSuccessfully: {
    defaultMessage: 'Resource successfully archived.',
    id: 'groupResourceForm.resourceArchivedSuccessfully',
  },
  resourceRestoredSuccessfully: {
    defaultMessage: 'Resource successfully restored.',
    id: 'groupResourceForm.resourceRestoredSuccessfully',
  },
  resourceUpdatedSuccessfully: {
    defaultMessage: 'Resource updated successfully',
    id: 'groupResourceForm.resourceUpdatedSuccessfully',
  },
})

export const getBannerMessage = (action, groupName) => {
  let message
  switch (action) {
    case 'archived':
      message = formatMessage(messages.resourceArchivedSuccessfully)
      break
    case 'restored':
      message = formatMessage(messages.resourceRestoredSuccessfully)
      break
    case 'updated':
      message = formatMessage(messages.resourceUpdatedSuccessfully)
      break
    case 'created':
    default:
      message = formatMessage(messages.resourceAddedSuccessfully, {
        groupName,
      })
      break
  }
  return message
}

const CREATE_MUTATION = gql`
  mutation createGroupResource(
    $groupId: ID!
    $title: String!
    $description: String!
    $resourceType: String!
    $resourceUrl: String!
    $visibility: String!
  ) {
    createGroupResource(
      groupId: $groupId
      title: $title
      description: $description
      resourceType: $resourceType
      resourceUrl: $resourceUrl
      visibility: $visibility
    ) {
      errors
    }
  }
`

export const useAddResourceMutation = ({ backPath, groupId, isEdit }) => {
  if (isEdit) {
    return {}
  }
  const [
    addResourceToGroup,
    // , { loading }
  ] = useMutation(CREATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['groupMembersQuery'],
  })

  const handleOnAddResource = async (variables) => {
    try {
      const {
        data: {
          createGroupResource: { errors },
        },
      } = await addResourceToGroup({
        variables: {
          groupId,
          ...variables,
        },
      })
      if (errors?.length > 0) {
        displayBanner({
          as: 'div',
          content: <FormErrorSummary errors={errors} />,
          variant: 'error',
        })
      } else {
        window.location = `${backPath}?action=created`
      }
    } catch {
      return
    }
  }
  return {
    handleOnAddResource,
  }
}

const UPDATE_MUTATION = gql`
  mutation updateGroupResource(
    $groupResourceId: ID!
    $title: String!
    $description: String!
    $resourceType: String!
    $resourceUrl: String!
    $visibility: String!
  ) {
    updateGroupResource(
      groupResourceId: $groupResourceId
      title: $title
      description: $description
      resourceType: $resourceType
      resourceUrl: $resourceUrl
      visibility: $visibility
    ) {
      errors
    }
  }
`

export const useUpdateResourceMutation = ({
  backPath,
  groupResourceId,
  isEdit,
}) => {
  if (!isEdit) {
    return {}
  }
  const [
    updateGroupResource,
    // , { loading }
  ] = useMutation(UPDATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['groupMembersQuery'],
  })

  const handleOnUpdateResource = async (variables) => {
    try {
      const {
        data: {
          updateGroupResource: { errors },
        },
      } = await updateGroupResource({
        variables: {
          groupResourceId,
          ...variables,
        },
      })
      if (errors?.length > 0) {
        displayBanner({
          as: 'div',
          content: <FormErrorSummary errors={errors} />,
          variant: 'error',
        })
      } else {
        window.location = `${backPath}?action=updated`
      }
    } catch {
      return
    }
  }
  return {
    handleOnUpdateResource,
  }
}

export const GROUP_RESOURCE_QUERY = gql`
  query groupResourcesQuery(
    $groupId: ID!
    $archived: Boolean
    $first: Int
    $after: String
  ) {
    currentUser {
      id
      email
    }
    group(id: $groupId) {
      id
      resources(
        forManagementPanel: true
        archived: $archived
        first: $first
        after: $after
      ) {
        nodes {
          createdAt
          createdBy {
            id
            name
          }
          deletedAt
          id
          readonly
          resourceUrl
          title
          updatedAt
          updatedBy {
            id
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`

const ARCHIVE_GROUP_RESOURCES_MUTATION = gql`
  mutation archiveGroupResource($id: ID!, $groupId: ID!) {
    archiveGroupResource(id: $id, groupId: $groupId) {
      errors
    }
  }
`
export const useArchiveResourceMutation = ({ backPath, id, groupId }) => {
  const [
    archiveGroupResource,
    // , { loading }
  ] = useMutation(ARCHIVE_GROUP_RESOURCES_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['groupResourcesQuery'],
  })

  const handleOnArchiveResource = async () => {
    try {
      const {
        data: {
          archiveGroupResource: { errors },
        },
      } = await archiveGroupResource({ variables: { groupId, id } })
      if (errors?.length > 0) {
        displayBanner({
          as: 'div',
          content: <FormErrorSummary errors={errors} />,
          variant: 'error',
        })
      } else {
        displayBanner({
          content: formatMessage(messages.resourceArchivedSuccessfully),
          variant: 'success',
        })
        if (backPath) {
          window.location = `${backPath}?action=archived`
        }
      }
    } catch {
      return
    }
  }
  return {
    handleOnArchiveResource,
  }
}

const RESTORE_GROUP_RESOURCES_MUTATION = gql`
  mutation restoreGroupResource($id: ID!, $groupId: ID!) {
    restoreGroupResource(id: $id, groupId: $groupId) {
      errors
    }
  }
`
export const useRestoreResourceMutation = ({ backPath, id, groupId }) => {
  const [
    restoreGroupResource,
    // , { loading }
  ] = useMutation(RESTORE_GROUP_RESOURCES_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['groupResourcesQuery'],
  })

  const handleOnRestoreResource = async () => {
    try {
      const {
        data: {
          restoreGroupResource: { errors },
        },
      } = await restoreGroupResource({ variables: { groupId, id } })
      if (errors?.length > 0) {
        displayBanner({
          as: 'div',
          content: <FormErrorSummary errors={errors} />,
          variant: 'error',
        })
      } else {
        displayBanner({
          content: formatMessage(messages.resourceRestoredSuccessfully),
          variant: 'success',
        })
        if (backPath) {
          window.location = `${backPath}?action=restored`
        }
      }
    } catch {
      return
    }
  }
  return {
    handleOnRestoreResource,
  }
}
