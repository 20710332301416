// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import { dayAndMonth } from 'utilities/date_formatter'
import {
  DescriptionList,
  DescriptionListItem,
} from 'components/ui/description_list'
import DisableButton from 'components/ui/disable_button'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import { intl } from 'utilities/localization'
import { GET_CAMPAIGN_BY_ID } from 'graphql/queries'
import { campaignTranslations } from './shared_translations'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TimeRange } from 'components/ui/time'

// Sub-Components
import { CampaignModel, CampaignUserStatus } from './campaign_model'

const JOIN_COMPETITION = gql`
  mutation joinCompetition($campaignId: ID!) {
    joinCompetition(campaignId: $campaignId) {
      errors
    }
  }
`

const LEAVE_COMPETITION = gql`
  mutation leaveCompetition($campaignId: ID!) {
    leaveCompetition(campaignId: $campaignId) {
      errors
    }
  }
`

/** Extra details for a competition */
export default class CompetitionDetails extends Component {
  static propTypes = {
    campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  }

  state = {
    isSubmitting: false,
  }

  handleJoin = () => {
    this.setState({ isSubmitting: true })
    client
      .mutate(this.getMutation(JOIN_COMPETITION))
      .then(
        ({
          data: {
            joinCompetition: { errors },
          },
        }) => {
          this.setState({ isSubmitting: false })
          this.onComplete(errors, campaignTranslations.registrationSuccessful)
        }
      )
      .catch(() => {
        this.setState({ isSubmitting: false })
        displayExceptionBanner({
          operation: campaignTranslations.saveYourRegistration,
        })
      })
  }

  handleLeave = () => {
    this.setState({ isSubmitting: true })
    client
      .mutate(this.getMutation(LEAVE_COMPETITION))
      .then(
        ({
          data: {
            leaveCompetition: { errors },
          },
        }) => {
          this.setState({ isSubmitting: false })
          this.onComplete(
            errors,
            campaignTranslations.deleteYourRegistrationSuccess
          )
        }
      )
      .catch(() => {
        this.setState({ isSubmitting: false })
        displayExceptionBanner({
          operation: campaignTranslations.deleteYourRegistrationError,
        })
      })
  }

  getMutation = (query) => ({
    awaitRefetchQueries: true,
    mutation: query,
    refetchQueries: [
      {
        query: GET_CAMPAIGN_BY_ID,
        variables: { campaignId: this.props.campaign.id },
      },
    ],
    variables: {
      campaignId: this.props.campaign.id,
    },
  })

  messages = defineMessages({
    registerToCompete: {
      defaultMessage: 'register to compete!',
      description: 'Register for the competition',
      id: 'competitionDetails.registerToCompete',
    },
    registrationClosed: {
      defaultMessage: 'registration closed',
      description: 'User can no longer register',
      id: 'competitionDetails.registrationClosed',
    },
    registrationDetails: {
      defaultMessage: `You're registered! Get ready for the competition to start {startsAt}!`,
      description: 'Details about the user registration',
      id: 'competitionDetails.registrationDetails',
    },
    registrationPeriod: {
      defaultMessage: 'registration period',
      description: 'Term of the registration',
      id: 'competitionDetails.registrationPeriod',
    },
    unregisterButton: {
      defaultMessage: 'unregister',
      description: 'Choose to unregister from the competition',
      id: 'competitionDetails.unregisterButton',
    },
  })

  onComplete = (errors, successMessage) => {
    if (errors) {
      displayBanner({
        as: 'div',
        content: <ErrorList errors={errors} />,
        variant: 'error',
      })
    } else {
      displayBanner({ content: successMessage, variant: 'success' })
    }
  }

  render() {
    const { campaign } = this.props
    const { formatMessage } = intl

    let callToAction
    switch (campaign.status) {
      case CampaignUserStatus.NOT_REGISTERED:
      case CampaignUserStatus.CAN_STILL_REGISTER:
        callToAction = (
          <DisableButton
            className="[ mt-3 mxw-3 | mt-lg-0 ]"
            color="primary"
            data-test="register-button"
            isLoading={this.state.isSubmitting}
            onClick={this.handleJoin}
            variant="contained"
          >
            {formatMessage(this.messages.registerToCompete)}
          </DisableButton>
        )
        break

      case CampaignUserStatus.WAITING_TO_COMPETE:
        if (campaign.isRegistrationOpen) {
          callToAction = (
            <DisableButton
              className="[ mt-3 mxw-3 | mt-lg-0 ]"
              data-test="unregister-button"
              isLoading={this.state.isSubmitting}
              onClick={this.handleLeave}
              size="small"
              variant="outlined"
            >
              {formatMessage(this.messages.unregisterButton)}
            </DisableButton>
          )
        }
        break

      case CampaignUserStatus.PARTICIPATING:
        // No call to action
        break

      case CampaignUserStatus.MISSED_REGISTRATION:
        callToAction = (
          <span
            className="bg-red-2 fs-1 fw-semi-bold ls-2 mt-3 px-3 py-2 rounded text-uppercase | mt-lg-0"
            data-test="registration-closed"
          >
            {formatMessage(this.messages.registrationClosed)}
          </span>
        )
        break
    }

    return (
      <div className="[ d-flex flex-column justify-content-between | align-items-lg-end flex-lg-row ]">
        <Stack className="fs-2" space={3}>
          <DescriptionList>
            <DescriptionListItem
              data-test="registration-period"
              label={formatMessage(this.messages.registrationPeriod)}
            >
              <TimeRange
                endsAt={campaign.registrationEnd}
                startsAt={campaign.registrationStart}
              />
            </DescriptionListItem>
            <DescriptionListItem
              data-test="competition-period"
              label={sharedTranslations.competitionPeriod}
            >
              <TimeRange
                endsAt={campaign.endsAt}
                startsAt={campaign.startsAt}
              />
            </DescriptionListItem>
          </DescriptionList>
          {campaign.status === CampaignUserStatus.WAITING_TO_COMPETE && (
            <p
              className="text-green fw-semi-bold"
              data-test="registration-details"
            >
              {formatMessage(this.messages.registrationDetails, {
                startsAt: dayAndMonth(campaign.startsAt),
              })}
            </p>
          )}
        </Stack>
        {callToAction}
      </div>
    )
  }
}
