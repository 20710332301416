export const sortByMostVotes = (ideas) =>
  // Sort By: most votes > oldest voted > oldest created
  // ---------------------------------------
  [...ideas].sort((a, b) => {
    if (b.voteCount > a.voteCount) return 1 // Vote count higher, move up
    if (b.voteCount < a.voteCount) return -1 // Vote count lower, move down

    if (a.currentVoteCreatedAt > b.currentVoteCreatedAt) return 1 // Last voted more recently, move up
    if (a.currentVoteCreatedAt < b.currentVoteCreatedAt) return -1 // Last voted less recently, move down

    if (a.createdAt > b.createdAt) return 1 // Idea created more recently, move up
    if (a.createdAt < b.createdAt) return -1 // Idea created less recently, move down
  })
