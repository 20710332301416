// Vendor
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { useParams } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel'

// Wespire
import { undecorated as Select } from 'components/form/select'
import { getGroupUrl } from 'components/group/utils'
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'
import { usePush } from 'utilities/routing'
import { groupTranslations } from 'components/group/shared_translations'

const messages = defineMessages({
  browseGroupByChapter: {
    defaultMessage: 'browse {currentUserGroup} and Group Chapters',
    description: 'Label for chapter selector',
    id: 'groupChapterSelector.browseGroupByChapter',
  },
  noChapterHomepage: {
    defaultMessage: '{currentUserGroup} homepage',
    description: '{currentUserGroup} homepage text',
    id: 'groupChapterSelector.noChapterHomepage',
  },
  yourChapter: {
    defaultMessage: 'your group chapter',
    description: 'Text suffix for current user chapter',
    id: 'groupChapterSelector.yourChapter',
  },
})

const GroupChapterSelector = ({
  chapters,
  className,
  currentUserChapter,
  currentUserGroup,
}) => {
  const push = usePush()
  const { id, group_id, sub_page: subPage } = useParams()
  const { formatMessage } = intl

  const groupId = group_id || id
  const chapterId = group_id
    ? chapters.find((chapter) => chapter.id === id || chapter.slug === id)?.slug
    : ''

  const selectOptions = useMemo(() => {
    let options = chapters.map((chapter) => ({
      label: formatMessage(groupTranslations.chapterSelectorChapter, {
        groupName: chapter.name,
      }),
      value: chapter.slug,
    }))

    options.forEach((option, i) => {
      if (option.value === currentUserChapter?.slug) {
        options.splice(i, 1)
        options.unshift({
          label: option.label + ` (${formatMessage(messages.yourChapter)})`,
          value: option.value,
        })
      }
    })

    return options
  }, [currentUserChapter?.id])

  const changeChapter = useCallback(
    (chapterId) =>
      push(
        getGroupUrl({
          chapterIdentifier: chapterId,
          groupIdentifier: groupId,
          subPage,
        })
      ),
    [groupId, subPage]
  )

  return (
    <Stack className={className} data-test="group-chapter-selector">
      <InputLabel
        className="fw-semi-bold pb-2 pl-2 text-black-1 text-uppercase"
        htmlFor="chapterSelector"
      >
        {formatMessage(messages.browseGroupByChapter, {
          currentUserGroup: currentUserGroup,
        })}
      </InputLabel>
      <Select
        blankOptionLabel={formatMessage(messages.noChapterHomepage, {
          currentUserGroup: currentUserGroup,
        })}
        name="chapterSelector"
        optionProps={{ 'data-test': 'group-chapter-option' }}
        options={selectOptions}
        selectProps={{ inputProps: { className: 'text-capitalize' } }}
        setValue={changeChapter}
        value={chapterId}
      />
    </Stack>
  )
}

GroupChapterSelector.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  currentUserChapter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  currentUserGroup: PropTypes.string.isRequired,
}

GroupChapterSelector.defaultProps = {
  className: null,
  currentUserChapter: null,
}

export { GroupChapterSelector }
