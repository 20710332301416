// Vendor
import React from 'react'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import { SpeechBubble } from 'components/campaign/leaderboard/speech_bubble'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  body: {
    defaultMessage:
      'Once participants start to complete Competition Actions, this is where you’ll be able to see who’s winning!',
    id: 'leaderBoardEmptyState.body',
  },
  heading: {
    defaultMessage: 'There are no Competition leaders to display.',
    id: 'leaderBoardEmptyState.heading',
  },
})

const LeaderBoardEmptyState = () => (
  <Paper
    className="[ p-4 ] [ text-center ] [ d-flex flex-col align-items-center ]"
    data-test="leaderboard-empty"
  >
    <SpeechBubble />
    <Stack className="fs-2 lh-xl" space={2}>
      <p className="fw-semi-bold">{intl.formatMessage(messages.heading)}</p>
      <p>{intl.formatMessage(messages.body)}</p>
    </Stack>
  </Paper>
)

export { LeaderBoardEmptyState }
