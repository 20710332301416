// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// WeSpire
import { Header } from 'components/shared/header'
import { isRoutePreview } from './utils'
import NavigatorProps from 'components/helpers/navigator_props'
import PreviewHeader from 'components/shared/header/preview'
import SideNav from 'components/shared/side_nav'
import WeSpireAPI from 'utilities/wespire_api'
import { useIsAuthenticated } from 'utilities/hooks/use_is_authenticated'

const mapStateToProps = (state) => {
  return {
    routes: state.routes,
  }
}

const Navigator = ({ location, routes }) => {
  const [avatarUrl, setAvatarUrl] = useState(NavigatorProps.avatarUrl)
  const [brandLogoUrl, setBrandLogoUrl] = useState(NavigatorProps.brandLogoUrl)
  const [sideNavIsOpen, setSideNavIsOpen] = useState(false)
  const { linkPaths } = NavigatorProps
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    const fetch = () =>
      WeSpireAPI()
        .get(`/navigator_props`)
        .then(({ data }) => {
          setAvatarUrl(data.avatarUrl)
          setBrandLogoUrl(data.brandLogoUrl)
        })
        .catch(() => {})

    fetch()
  }, [])

  // State
  const toggleSideNav = () =>
    setSideNavIsOpen((sideNavIsOpen) => !sideNavIsOpen)

  if (isRoutePreview(location.pathname, routes)) {
    return <PreviewHeader />
  }

  return (
    <>
      <Header
        avatarUrl={avatarUrl}
        brandLogoUrl={brandLogoUrl}
        linkPaths={linkPaths}
        sideNavIsOpen={sideNavIsOpen}
        toggleSideNav={toggleSideNav}
      />
      {isAuthenticated && (
        <SideNav
          closeSideNav={toggleSideNav}
          isOpen={sideNavIsOpen}
          linkPaths={linkPaths}
        />
      )}
    </>
  )
}

Navigator.propTypes = {
  location: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
}

export const undecorated = Navigator

export default connect(mapStateToProps)(Navigator)
