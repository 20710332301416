// Vendor
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { withFormsy } from 'formsy-react'

// WeSpire
import FormCheckbox from 'components/form/checkbox'
import FormTextField from 'components/form/text_field'
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'
import { validationTranslations } from 'components/form/text_field_validations'
import { maximumPvhClaim, PvhAvailableHours } from 'components/pvh'

const messages = defineMessages({
  claimHelpText: {
    defaultMessage:
      'You must volunteer a minimum of one hour and have PVH available.',
    id: 'pvhFields.claimHelpText',
  },
  claimLabel: {
    defaultMessage: 'I want to claim my Paid Volunteer Hours',
    id: 'pvhFields.claimLabel',
  },
  hoursHelpText: {
    defaultMessage:
      'Hours claimed must be in 15 minute increments (0.25 hours).',
    id: 'pvhFields.hoursHelpText',
  },
  hoursLabel: {
    defaultMessage: 'Number of hours you want to claim',
    id: 'pvhFields.hoursLabel',
  },
  hoursValidation: {
    defaultMessage:
      'Claimed hours must be less than or equal to available ' +
      'balance ({balance} hours) and hours volunteered ({hoursVolunteered} hours) ' +
      'rounded to the nearest 15 minutes ({maximumPvhClaim} hours)',
    id: 'pvhFields.hoursValidation',
  },
})

const claimChecked = (canClaimHours, isClaiming) => {
  return canClaimHours && isClaiming
}

export const PvhFieldsBase = ({
  balance,
  hoursVolunteered,
  isClaiming,
  pvhHours,
}) => {
  const canClaimHours = balance >= 1
  // Unless we explicitly say whether the user is claiming, we default the
  // state of the claim checkbox in this way because we are assuming that, if a
  // user has a remaining PVH balance, that they will want to use it as soon as
  // possible.
  const [isClaimChecked, setIsClaimChecked] = useState(
    claimChecked(canClaimHours, isClaiming)
  )

  useEffect(() => {
    setIsClaimChecked(claimChecked(canClaimHours, isClaiming))
  }, [canClaimHours, isClaiming])

  const onPvhClaimChange = (value) => {
    setIsClaimChecked(value)
  }

  const validations = isClaimChecked
    ? {
        divisibleBy: 0.25,
        maxValue: maximumPvhClaim({ balance, hoursVolunteered }),
        minValue: 1,
      }
    : null
  const hoursToClaim = isClaimChecked ? pvhHours : ''

  return (
    <Stack data-test="pvh-fields" space={2}>
      <PvhAvailableHours balance={balance} />
      <Stack space={4}>
        <FormCheckbox
          checkboxProps={{ disabled: !canClaimHours }}
          helperText={intl.formatMessage(messages.claimHelpText)}
          labelProps={{ label: intl.formatMessage(messages.claimLabel) }}
          name="pvhClaim"
          onChange={onPvhClaimChange}
          value={isClaimChecked}
        />
        <FormTextField
          name="pvhHours"
          required={isClaimChecked}
          textFieldProps={{
            disabled: !isClaimChecked,
            helperText: intl.formatMessage(messages.hoursHelpText),
            inputProps: {
              step: 0.25,
            },
            label: intl.formatMessage(messages.hoursLabel),
            type: 'number',
          }}
          validationErrors={{
            divisibleBy: intl.formatMessage(
              validationTranslations.pvhIncrements
            ),
            maxValue: intl.formatMessage(messages.hoursValidation, {
              balance,
              hoursVolunteered,
              maximumPvhClaim: maximumPvhClaim({ balance, hoursVolunteered }),
            }),
            minValue: intl.formatMessage(validationTranslations.minValue, {
              min: 1,
            }),
          }}
          validations={validations}
          value={hoursToClaim}
        />
      </Stack>
    </Stack>
  )
}

PvhFieldsBase.propTypes = {
  /*
   * The remaining number of hours for a user that can be used for PVH claims.
   */
  balance: PropTypes.number.isRequired,
  /*
   * The number of hours of the volunteering activity corresponding to the PVH
   * Claim that is being submitted via this component. The user can claim up to
   * the number of hoursVolunteered. This can be a number if it comes from
   * GraphQL, or a string if it comes from Formsy state.
   */
  hoursVolunteered: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /*
   * Whether the user is claiming PVH hours or not.
   */
  isClaiming: PropTypes.bool,
  /*
   * The number of hours that a user will claim for PVH. This prop exists so
   * that we can default that number of hours in certain rendering contexts.
   */
  pvhHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

PvhFieldsBase.defaultProps = {
  hoursVolunteered: 0,
  isClaiming: null,
  pvhHours: null,
}

export const PvhFields = withFormsy(PvhFieldsBase)
