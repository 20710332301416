import { defineMessages } from 'react-intl'

export const donationsSuccessMessages = defineMessages({
  errorLoadinDonatonSuccess: {
    defaultMessage: 'Donation Success',
    id: 'donationSuccess.errorLoadinDonatonSuccess',
  },
  estimation: {
    defaultMessage: 'Estimated time is 10 business days',
    id: 'donationSuccess.estimation',
  },
  gotoDonationHistory: {
    defaultMessage: 'Go To Your Donation History',
    id: 'donationSuccess.gotoDonationHistory',
  },
  matchingGiftTitle: {
    defaultMessage: 'MATCHING GIFT',
    id: 'donationSuccess.matchingGiftTitle',
  },
  matchingText: {
    defaultMessage:
      'In most cases, the charity you’ve selected will receive the Matching Gift at the same time as the donation that you’ve funded. Occasionally the charity may receive the Matching Gift as a separate payment, generally no more than a week after the first payment has been received.',
    id: 'donationSuccess.matchingText',
  },
  pageTitle: {
    defaultMessage: 'Thank you',
    id: 'donationSuccess.pageTitle',
  },
  pleaseNote: {
    defaultMessage: 'PLEASE NOTE',
    id: 'donationSuccess.pleaseNote',
  },
  pleaseNoteText: {
    defaultMessage:
      // eslint-disable-next-line formatjs/enforce-plural-rules
      'All donation transactions will appear on your bank/credit card statements as GIVINGA FOUNDATION, our 501(c)(3) partner registered in the United States. Your donation will be processed and will be included in a weekly payment to the charity you’ve selected. Most charities receive the payment within 10 business days, either electronically or by check. <link>Your Donation History</link> has the up-to-date status on the payment status.',
    id: 'donationSuccess.pleaseNoteText',
  },
  yourDonationWasMade: {
    defaultMessage: 'Your donation was made.',
    id: 'donationSuccess.yourDonationWasMade',
  },
  yourMonhlyDonationSchedule: {
    defaultMessage: 'Your monthly donations are scheduled',
    id: 'donationSuccess.yourMonhlyDonationSchedule',
  },
})

export const donationReceiptMessages = defineMessages({
  benefits: {
    defaultMessage: 'benefits',
    id: 'donationReceipt.benefits',
  },
  byMakingThisDonation: {
    defaultMessage:
      'By making this donation, you are making a contribution to a donor-advised fund at the Giving Foundation. The Giving Foundation is a nonprofit 501(c)(3) partner of WeSpire and facilitates the granting of funds to the specific nonprofi this donation is being processed for.',
    id: 'donationReceipt.byMakingThisDonation',
  },
  noGoodsOrService: {
    defaultMessage:
      'No goods or service were provide in exchange for these contribution. The Givinga Foundation is an exempt organization as described in Section 501(c)(3) of the Internal Revenue Code with an EIN # 47-4172718.',
    id: 'donationReceipt.noGoodsOrService',
  },
  pleasePrint: {
    defaultMessage:
      'Please print a copy of this page for record keeping and tax purpose.',
    id: 'donationReceipt.pleasePrint',
  },
  understandForTaxes: {
    defaultMessage:
      'Understand for tax benefit purpose; all transaction are processed by the Givinga Foundation; as such, your bank/credit card statments will reflect payments being processed by the Givinga Foundation.',
    id: 'donationReceipt.understandForTaxes',
  },
  yourDonationOf: {
    defaultMessage: 'Your donaton of',
    id: 'donationReceipt.yourDonationOf',
  },
  yourDonationReceipt: {
    defaultMessage: 'Your Donation Receipt',
    id: 'donationReceipt.yourDonationReceipt',
  },
  yourMonthlyDonationOf: {
    defaultMessage: 'Your Monthly donations of',
    id: 'donationReceipt.yourMonthlyDonationOf',
  },
})

export const transactionInfoMessages = defineMessages({
  charity: {
    defaultMessage: 'Charity',
    id: 'transactionInfo.charity',
  },
  donationAmount: {
    defaultMessage: 'Donation Amount',
    id: 'transactionInfo.donationAmount',
  },
  matchingGiftRequested: {
    defaultMessage: 'Matching Gift Requested',
    id: 'transactionInfo.matchingGiftRequested',
  },
  matchingGiftRequestedNo: {
    defaultMessage: 'No',
    id: 'transactionInfo.matchingGiftRequestedNo',
  },
  matchingGiftRequestedYes: {
    defaultMessage: 'Yes',
    id: 'transactionInfo.matchingGiftRequestedYes',
  },
  monthlyDonationAmount: {
    defaultMessage: 'Monthly Donation Amount',
    id: 'transactionInfo.monthlyDonationAmount',
  },
  nextDonationDate: {
    defaultMessage: 'Next Donation Will Occur',
    id: 'transactionInfo.nextDonationDate',
  },
  paymentMethod: {
    defaultMessage: 'Payment Method',
    id: 'transactionInfo.paymentMethod',
  },
})

export const legacyDonationSuccess = defineMessages({
  donationHistory: {
    defaultMessage:
      "We'll send you an email with your donation receipt shortly. In the meantime you can go to your <link>Donation History</link> to track your donation.",
    id: 'legacyDonationSuccess.donationHistory',
  },
  pageTitle: {
    defaultMessage: 'Thank you for donating',
    id: 'legacyDonationSuccess.pageTitle',
  },
})
