// Vendor
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { FormControlLabel, Radio } from '@material-ui/core'

const RadioPill = ({ label, value, selected, ...other }) => (
  <FormControlLabel
    {...other}
    classes={{
      label: 'fs-2 fw-semi-bold mr-1 pr-2',
      root: cx(
        'border border-width-2 mt-2 rounded-3',
        { 'bg-blue border-blue text-white': selected },
        { 'border-black-4 bg-white text-black-2': !selected }
      ),
    }}
    control={
      <Radio
        classes={{
          checked: cx({ 'text-white': selected }),
          root: 'p-2 text-black-4',
        }}
      />
    }
    label={label}
    value={value}
  />
)

RadioPill.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
}

export { RadioPill }
