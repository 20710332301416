/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

// westyle
import D4dEarnedMarker from 'src/pages/VolunteeringHistory/components/D4dEarnedMarker'
import InternalLink from 'src/common/InternalLink'
import VolunteerDetails from 'src/pages/VolunteeringHistory/components/Tables/VolunteerDetails'
import VolunteerHours from 'src/pages/VolunteeringHistory/components/Tables/VolunteerHours'
import HoursToGoBar from 'src/pages/VolunteeringHistory/components/HoursToGoBar'
import { FormattedMessage } from 'react-intl'
import useEligibleVolunteeringHoursQuery from '../../queries/useEligibleVolunteeringHoursQuery'

const VolunteerPreview = ({
  d4dConfiguration,
  data,
  enableMultiCharitiesVolunteer,
  index,
  isFlexible,
  isAllTime,
  status,
  volunteerLogs,
}) => {
  const [expandRow, setExpandRow] = useState(false)
  const [availableD4dFunds, setAvailableD4dFunds] = useState(0)
  const [calculatedFunds, setCalculatedFunds] = useState(0)
  const d4dHoursUntilDonationEligible =
    d4dConfiguration?.d4dHoursUntilDonationEligible
  const d4dGrantAmount = d4dConfiguration?.d4dGrantAmount
  const updateRowState = () =>
    setExpandRow((prevExpandRowOpen) => !prevExpandRowOpen)
  const ein = volunteerLogs.ein
  const {
    grantDollarsDonatedThisYearFor,
    volunteerHoursThisYear,
    dollarsForDoersEligibleVolunteerHours,
  } = useEligibleVolunteeringHoursQuery(ein)

  const hoursBeforeNextGrant =
    volunteerHoursThisYear % d4dHoursUntilDonationEligible === 0
      ? d4dHoursUntilDonationEligible
      : d4dHoursUntilDonationEligible -
        (volunteerHoursThisYear % d4dHoursUntilDonationEligible)

  const remainingFundsThisYear =
    data?.currentUser?.givingaUser?.remainingFundsThisYear

  useEffect(() => {
    let calculatedFunds =
      Math.floor(volunteerHoursThisYear / d4dHoursUntilDonationEligible) *
      d4dGrantAmount

    calculatedFunds = calculatedFunds - grantDollarsDonatedThisYearFor
    calculatedFunds =
      calculatedFunds < remainingFundsThisYear
        ? calculatedFunds
        : remainingFundsThisYear
    if (calculatedFunds < 0) {
      calculatedFunds = 0
    }
    setAvailableD4dFunds(calculatedFunds)
    setCalculatedFunds(availableD4dFunds || 0)
  }, [
    availableD4dFunds,
    volunteerHoursThisYear,
    d4dHoursUntilDonationEligible,
    d4dGrantAmount,
  ])

  console.log({
    grantDollarsDonatedThisYearFor,
    d4dConfiguration,
    volunteerHoursThisYear,
    isFlexible,
    ein,
    isAllTime,
    hoursBeforeNextGrant,
    remainingFundsThisYear,
    calculatedFunds,
    grantEligible: volunteerLogs?.grantEligible,
    dollarsForDoersEligibleVolunteerHours,
    d4dHoursUntilDonationEligible,
  })

  const showHoursToGoBar =
    !isFlexible &&
    ein &&
    !isAllTime &&
    hoursBeforeNextGrant > 0 &&
    remainingFundsThisYear - calculatedFunds > 0 &&
    volunteerLogs?.grantEligible

  const showDonateNowButton =
    dollarsForDoersEligibleVolunteerHours >= d4dHoursUntilDonationEligible &&
    ein &&
    !isFlexible &&
    calculatedFunds > 0 &&
    volunteerLogs?.grantEligible
  return (
    <div
      className="border-grayscale-3 md:grid gap-1 md:pt-4 md:px-4 mb-0 w-4xl border-[1px]"
      onClick={updateRowState}
      key={index}
    >
      <div className="flex">
        <div className="hidden md:inline w-10 flex md:p-0 px-4 py-1">
          <div className="hidden md:inline-block">
            <button className="self-start material-symbols-rounded">
              {expandRow ? 'expand_more' : 'chevron_right'}
            </button>
          </div>
        </div>
        <div className="flex justify-between col-span-10 w-full">
          <div className="w-full">
            <div className="bg-black-3 md:bg-white grid grid-cols-6 md:px-0 py-1 md:py-0 md:pt-0">
              <div className="col-span-5">
                <span className="body-large-semibold pl-4 md:pl-0 pr-4 text-white md:text-black-2">
                  {volunteerLogs.organizationName}
                </span>
              </div>
              <div className="pr-4 col-span-1 text-end">
                <VolunteerHours charity={volunteerLogs} status={status} />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              {status !== 'scheduled' && (
                <>
                  {showHoursToGoBar ? (
                    <div className="flex flex-col my-4">
                      <span className="body-medium-regular text-black-3">
                        <FormattedMessage
                          defaultMessage="Dollars for Doers"
                          id="shared.dollarsForDoers"
                        />
                      </span>
                      <HoursToGoBar
                        className="mb-4 mt-2 px-4 md:px-0"
                        d4dGrantAmount={d4dGrantAmount}
                        d4dHoursUntilDonationEligible={
                          d4dHoursUntilDonationEligible
                        }
                        hoursBeforeNextGrant={hoursBeforeNextGrant}
                        isFlexible={isFlexible}
                      />
                    </div>
                  ) : (
                    <div className="invisible"></div>
                  )}
                </>
              )}
              <div className="flex flex-col md:mb-4 mx-4 md:mx-0 md:w-1/3">
                {volunteerHoursThisYear >= d4dHoursUntilDonationEligible &&
                  !isFlexible &&
                  ein &&
                  volunteerLogs.grantEligible &&
                  calculatedFunds > 0 && (
                    <D4dEarnedMarker
                      earnedThisYear={calculatedFunds}
                      className={`${!isFlexible && 'justify-end'}`}
                    />
                  )}
                {volunteerLogs.grantEligible && (
                  <p className="text-sm md:mb-0 text-end text-success-green">
                    <span className="bg-success-green inline-block p-1 rounded-full" />{' '}
                    <FormattedMessage
                      id="dollarsForDoers.d4dEligible"
                      defaultMessage={'D4D Eligible'}
                    />
                  </p>
                )}
                {showDonateNowButton && (
                  <InternalLink
                    className="link-text-underlined mb-4 md:mb-0 text-end"
                    href={`/app/dollars_for_doers_form/${ein}`}
                  >
                    <FormattedMessage
                      id="dollarsForDoers.donateDollarsNow"
                      defaultMessage="Donate your Dollar for Doers now"
                    />
                  </InternalLink>
                )}
              </div>
            </div>

            <VolunteerDetails
              enableMultiCharitiesVolunteer={enableMultiCharitiesVolunteer}
              status={status}
              expandRow={expandRow}
              charity={volunteerLogs}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VolunteerPreview
