export const getDeviceConstants = (type) => {
  let device
  switch (type) {
    case 'fitbit':
      device = { appName: 'Fitbit', deviceName: 'Fitbit' }
      break
    case 'garmin':
      device = { appName: 'Garmin Connect', deviceName: 'Garmin' }
      break
    case 'misfit':
      device = { appName: 'Misfit', deviceName: 'Misfit' }
      break
    case 'withings':
      device = { appName: 'HealthMate', deviceName: 'Withings' }
      break
    case 'google_fit_sdk':
      device = {
        appName: 'Google Fit',
        settingsPath: 'Google Fit > Profile > Privacy > Apps',
      }
      break
    case 'apple_health':
      device = {
        appName: 'Apple Health',
        settingsPath: 'Apple Health > Profile > Privacy > Apps',
      }
      break
    default:
      device = { appName: 'tracking', deviceName: '' }
      break
  }
  return { ...device, type }
}
