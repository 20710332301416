// Vendor
import React from 'react'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { UserProfileDonationStats as DonationStat } from './donation_stat'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sharedTranslations } from 'components/shared/translations'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import { TakeActionLink } from 'components/activities/link'
import WeQuery from 'components/application/we_query'
import { UserProfileVolunteerStat as VolunteerStat } from './volunteer_stat'
import { useIsPointsDashboardEnabled } from 'components/points_dashboard/utils'
import { getPointsDashboardLink } from 'components/shared/navigator/utils'

const { formatMessage } = intl
const messages = defineMessages({
  startTakingAction: {
    defaultMessage: 'start taking action',
    id: 'UserProfileStatsSummary.startTakingAction',
  },
  takeAction: {
    defaultMessage:
      'Once you {takeActionLink}, this is where you’ll be able to see your personal impact!',
    id: 'UserProfileStatsSummary.takeAction',
  },
  thereIsNoSummaryToDisplay: {
    defaultMessage: 'There is no summary to display',
    id: 'UserProfileStatsSummary.thereIsNoSummaryToDisplay',
  },
  totalPointsEarned: {
    defaultMessage: 'total points earned',
    id: 'UserProfileStatsSummary.totalPointsEarned',
  },
  yourStats: {
    defaultMessage: 'your stats',
    id: 'UserProfileStatsSummary.yourStats',
  },
})

export const UserProfileStatsSummary = ({ heading, layout, userId }) => {
  const isColumns = layout === 'columns'
  const isRows = layout === 'rows'
  const { isPointsDashboardEnabled } = useIsPointsDashboardEnabled()
  return (
    <Stack as="section" space={3}>
      <Heading
        className={cx({ 'px-2': isColumns }, { 'px-3': isRows })}
        level={3}
        variant={2}
      >
        {heading}
      </Heading>
      <WeQuery
        error={
          <MissingResourceSection
            as={Paper}
            errorDetails={formatMessage(messages.yourStats)}
          />
        }
        loader={<SkeletonLoading height={200} />}
        query={gql`
          query userStatsSummaryQuery($id: ID!) {
            user(id: $id) {
              id
              scoreAllTime
            }
            userImpactStats {
              id
              totalActionsCompleted
            }
            givingEnabled
            volunteerHistoryEnabled
          }
        `}
        variables={{ id: userId }}
      >
        {({
          data: {
            user: { scoreAllTime },
            userImpactStats: { totalActionsCompleted },
            givingEnabled,
            volunteerHistoryEnabled,
          },
        }) => {
          return (
            <div
              className={cx(
                { 'row vertical-gutters': isColumns },
                { 'd-flex flex-column stack stack--2': isRows }
              )}
            >
              {(scoreAllTime || totalActionsCompleted) > 0 ? (
                <>
                  <div
                    className={cx({
                      'col-sm-6 | col-lg-3': isColumns,
                    })}
                  >
                    <Paper className="h-100 p-4">
                      <Stat
                        amount={totalActionsCompleted}
                        dataTest="acts-completed-count"
                        label={sharedTranslations.totalActionsCompleted}
                      />
                    </Paper>
                  </div>
                  {volunteerHistoryEnabled && (
                    <div
                      className={cx({
                        'col-sm-6 | col-lg-3': isColumns,
                      })}
                    >
                      <Paper className="h-100 p-4">
                        <VolunteerStat userId={userId} />
                      </Paper>
                    </div>
                  )}
                  <div
                    className={cx({
                      'col-sm-6 | col-lg-3': isColumns,
                    })}
                  >
                    <Paper className="h-100 p-4">
                      <Stat
                        amount={scoreAllTime}
                        dataTest="personal-score"
                        label={
                          isPointsDashboardEnabled ? (
                            <p className="my-0">
                              <Link to={getPointsDashboardLink.href}>
                                {formatMessage(messages.totalPointsEarned)}
                              </Link>
                            </p>
                          ) : (
                            formatMessage(messages.totalPointsEarned)
                          )
                        }
                      />
                    </Paper>
                  </div>
                  {givingEnabled && (
                    <div
                      className={cx({
                        'col-sm-6 | col-lg-3': isColumns,
                      })}
                    >
                      <Paper className="h-100 p-4">
                        <DonationStat userId={userId} />
                      </Paper>
                    </div>
                  )}
                </>
              ) : (
                <Stack
                  as={Paper}
                  className="p-3 text-center w-100"
                  data-test="no-stats-message"
                >
                  <p className="fw-semi-bold lh-xl">
                    {formatMessage(messages.thereIsNoSummaryToDisplay)}
                  </p>
                  <p className="lh-xl">
                    {formatMessage(messages.takeAction, {
                      takeActionLink: (
                        <TakeActionLink
                          data-test="take-action-link"
                          key={formatMessage(messages.startTakingAction)}
                        >
                          {formatMessage(messages.startTakingAction)}
                        </TakeActionLink>
                      ),
                    })}
                  </p>
                </Stack>
              )}
            </div>
          )
        }}
      </WeQuery>
    </Stack>
  )
}

UserProfileStatsSummary.propTypes = {
  heading: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(['columns', 'rows']).isRequired,
  userId: PropTypes.string.isRequired,
}
