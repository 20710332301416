// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { abbreviatedDateOrRange } from 'utilities/date_formatter'

/** Renders a <time> element with start & end date formatted like Jan 1, 2000 - Jan 2, 2000 */
const TimeRange = ({ startsAt, endsAt, ...other }) => (
  <time {...other} dateTime={startsAt}>
    {abbreviatedDateOrRange(startsAt, endsAt)}
  </time>
)

TimeRange.propTypes = {
  /** The end date of the time range */
  endsAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /** The start date of the time range */
  startsAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
}

TimeRange.defaultProps = {
  endsAt: null,
}

export { TimeRange }
