import React, { useContext } from 'react'
import DividerCaption from 'src/common/DividerCaption'
import TextHero from 'src/common/TextHero'
import SessionContext from 'src/contexts/SessionContext'
import EmailAuthForm from './EmailAuthForm'
import SsoAuthForm from './SsoAuthForm'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router'

const MultiAuthForm = () => {
  const { brand, signedIn } = useContext(SessionContext)
  if (signedIn) {
    return <Redirect to="/take_action" />
  }
  if (brand?.authScheme === 'sso') {
    return <Redirect to="/app/landing/sso" />
  }
  if (brand?.authScheme === 'email') {
    return <Redirect to="/app/landing/email" />
  }
  return (
    <main className="grid justify-items-center mt-4 pb-8">
      <TextHero
        subtitle={brand?.heroSubtitle}
        title={brand?.heroTitle || brand?.name}
      />
      <section className="flex flex-col">
        <SsoAuthForm hideTitle />
        <DividerCaption className="my-10 uppercase">
          <FormattedMessage id="landingMessages.or" defaultMessage={'or'} />
        </DividerCaption>
        <EmailAuthForm hideTitle />
      </section>
    </main>
  )
}

export default MultiAuthForm
