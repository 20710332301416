// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import CloudinaryQuery from 'components/queries/cloudinary_query'
import cx from 'classnames'
import { Image as CloudinaryImage } from 'cloudinary-react'

// Sub-Component
import { cloudinaryIdFromUrl } from './utils'

/**
 * All-purpose Image component. If given a src URL generated by cloudinary, it
 * will render it with the cloudinary image component, making available all of
 * Cloudinary's transformation options and its responsive image javascript.
 * Otherwise it will render a normal <img> tag with only relevant HTML props.
 * Cloudinary images require a valid cloudName, which can be provided either
 * directly to the component or via GraphQL <CloudinaryQuery>.
 */

// 1. View all Cloudinary image transformation options:
//    => https://cloudinary.com/documentation/image_transformation_reference
// 2. View more about react-specific props:
//    => https://cloudinary.com/documentation/react_integration
// 3. Images should always have meaningful alt text, or blank string if decorative.
// 4. Images are served by default at auto quality and auto pixel density.
// 5. Manually specify all in-use cloudinary component props, to avoid rendering
// them via  {...other} on the native <img> element.
// 6. Automatically add the appropriate CSS when data-object-fit is used, since
// we need both the CSS and JS polyfill for IE11 support.
// 7. Specify a fallback image URL that will be rendered if the image errors,
// e.g. the provided `src` URL returns a broken image.

export const Image = ({
  alt,
  aspectRatio,
  backupUrl,
  cloudinaryId,
  cloudName,
  crop,
  'data-object-fit': dataObjectFit,
  dpr,
  gravity,
  height,
  onImageError,
  quality,
  responsive,
  src,
  width,
  ...other
}) => {
  const publicId = cloudinaryId || cloudinaryIdFromUrl(src)
  // If the image is responsive, use this variant by default...
  const propWithResponsiveVariant = (prop, responsiveProp) =>
    responsive ? responsiveProp : prop

  const setBackupUrl = (img) => {
    if (backupUrl) {
      // event.target.onerror is set to null as to prevent an infinite loop
      img.onerror = null
      img.src = backupUrl
    }
  }

  // Allow broken images to appear the height they were intended.
  // 1. Remove h-100 util classes set manually or automatically when
  //    data-object-fit is true.
  // 2. Set the explicitly given height via CSS so it wins over the default img
  //    style of height: auto.
  const unsetImageHeight = (img) => {
    img.classList.remove('h-100') // 1
    img.style.height = `${height}px` // 2
  }

  const handleImageError = (event) => {
    const img = event.target

    setBackupUrl(img)
    unsetImageHeight(img)
    onImageError && onImageError(img)
  }

  const htmlImg = (
    <img
      {...other} // 5
      alt={alt}
      className={cx(
        { 'h-100 object-fit-cover w-100': dataObjectFit },
        other.className
      )} // 6
      data-object-fit={dataObjectFit} // 6
      height={height}
      onError={handleImageError}
      src={src}
      width={width}
    />
  )

  // If cloudName is provided pass it directly to the component children instead
  //  of querying GraphQL for it.
  const Wrapper = ({ children }) => {
    if (cloudName) {
      return children({ cloudName })
    } else {
      return (
        <CloudinaryQuery error={htmlImg} loader={null}>
          {({ cloudName }) => children({ cloudName })}
        </CloudinaryQuery>
      )
    }
  }
  Wrapper.propTypes = {
    children: PropTypes.any.isRequired,
  }

  if (publicId) {
    return (
      <Wrapper>
        {({ cloudName }) => (
          <CloudinaryImage
            {...other}
            alt={alt} // 3
            aspectRatio={aspectRatio} // 1
            className={cx(
              { 'h-100 object-fit-cover w-100': dataObjectFit },
              other.className
            )} // 6
            cloudName={cloudName}
            crop={propWithResponsiveVariant(crop, 'fill')} // 1
            data-object-fit={dataObjectFit} // 6
            dpr={dpr} // 1
            gravity={gravity || propWithResponsiveVariant(gravity, 'auto')} // 1
            height={height} // 1
            onError={handleImageError}
            publicId={publicId} // 2
            quality={quality} // 1
            responsive={responsive} // 1
            width={propWithResponsiveVariant(width, 'auto')} // 1
          />
        )}
      </Wrapper>
    )
  } else {
    return htmlImg
  }
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  aspectRatio: PropTypes.oneOf(['1:1', '1:5', '16:9']),
  backupUrl: PropTypes.string,
  cloudinaryId: PropTypes.string,
  crop: PropTypes.oneOf(['fill', 'fit', 'scale']),
  'data-object-fit': PropTypes.bool,
  dpr: PropTypes.oneOf(['auto', '2.0']),
  gravity: PropTypes.oneOf(['auto', 'center', 'face:auto']),
  height: PropTypes.string,
  quality: PropTypes.oneOf(['auto', 'auto:best']),
  responsive: PropTypes.bool,
  src: PropTypes.string,
  width: PropTypes.string,
}

Image.defaultProps = {
  aspectRatio: null,
  backupUrl: null, // 7
  cloudinaryId: null,
  crop: 'scale',
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-object-fit': undefined,
  dpr: 'auto', // 4
  gravity: 'center',
  height: null,
  quality: 'auto', // 4
  responsive: false,
  src: '',
  width: null,
}
