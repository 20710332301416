import React, { useMemo }  from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

// westyle
import NumberWithTooltip from 'src/common/NumberWithTooltip'
import Numbers from 'src/common/Numbers'
import {
  ToggleButtonGroup,
  ToggleButton,
} from 'src/common/ToggleButton/ToggleButton'

const YourImpact = ({
  allTimeHours,
  allTimeOrganizations,
  isStrict,
  setStats,
  stats,
  thisYearHours,
  thisYearOrganizations,
}) => {
  const allTime = useMemo(
    () => ({
      allTime: true,
      charityCount: allTimeOrganizations,
      totalVolunteerHours: allTimeHours,
    }),
    [allTimeHours, allTimeOrganizations]
  )

  const aYear = useMemo(
    () => ({
      allTime: false,
      charityCount: thisYearOrganizations,
      totalVolunteerHours: thisYearHours,
    }),
    [thisYearHours, thisYearOrganizations]
  )

  const intl = useIntl()
  const handleStatsChange = (newSelection) => {
    if (newSelection[0] === 'aYear') {
      setStats(aYear)
    } else {
      setStats(allTime)
    }
  }

  return (
    <div className="xl:col-span-2">
      <div className="flex content-center">
        <h2 className="pr-4 px-2">
          <FormattedMessage
            id="volunteering.yourImpact"
            defaultMessage="Your impact"
          />
        </h2>
        <ToggleButtonGroup
          exclusive
          defaultValue={'allTime'}
          onChange={handleStatsChange}
        >
          <ToggleButton value="aYear">This Year</ToggleButton>
          <ToggleButton value="allTime">All Time</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div
        className={`rounded w-4xl bg-white border-[1px] border-grayscale-3 mt-4 p-6 flex flex-col justify-between ${
          isStrict ? 'xl:min-h-[9rem]' : 'xl:min-h-[17.25rem]'
        }`}
      >
        <div className="lg:grid grid-cols-4 gap-x-6">
          <div className="grid col-span-2">
            <Numbers className="headline-1" currency="USD" style="currency">
              {stats.allDonations}
            </Numbers>
            <span className="label-xsmall-caps text-black-1">Donated</span>
            <div className="mb-4 mt-2">
              <NumberWithTooltip
                title={intl.formatMessage({
                  defaultMessage: 'Company contributions',
                  id: 'donationHistory.companyContribution',
                })}
                tooltip="This is a tooltip. This copy explains what this item is."
                value={stats.personalDonations}
              />
              <NumberWithTooltip
                title={intl.formatMessage({
                  defaultMessage: 'Personal contributions',
                  id: 'donationHistory.personalContribution',
                })}
                tooltip="Hello"
                value={stats.companyDonations}
              />
            </div>
          </div>
          <div className="flex flex-col my-6 lg:my-0">
            <Numbers className="headline-1">
              {stats.totalDonationTransactions}
            </Numbers>
            <span className="label-xsmall-caps text-black-1">
              <FormattedMessage
                id="donationHistory.donationsMade"
                defaultMessage="Donations made"
              />
            </span>
          </div>
          <div className="flex flex-col">
            <Numbers className="headline-1">{stats.charityCount}</Numbers>
            <span className="label-xsmall-caps text-black-1">
              <FormattedMessage
                id="volunteering.charitiesImpacted"
                defaultMessage="Charities impacted"
              />
            </span>
          </div>
        </div>
        <span className="body-medium-regular">
          <FormattedMessage
            id="donationHistory.allDonationsMade"
            defaultMessage="PLEASE NOTE: All donations made are converted to and made in USD."
          />
        </span>
      </div>
    </div>
  )
}

YourImpact.propTypes = {
  allDonationsToDate: PropTypes.number,
  allTimeHours: PropTypes.number,
  allTimeOrganizations: PropTypes.number,
  thisYearHours: PropTypes.number,
  thisYearOrganizations: PropTypes.number,
  isStrict: PropTypes.bool,
  setStats: PropTypes.func,
  stats: PropTypes.object
}

export default YourImpact
