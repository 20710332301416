// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  hidePassword: {
    defaultMessage: 'Hide password',
    id: 'inputPasswordToggleButton.hidePassword',
  },
  showPassword: {
    defaultMessage: 'Show password',
    id: 'inputPasswordToggleButton.showPassword',
  },
})

const InputPasswordToggleButton = ({ isPasswordVisible, onClick }) => (
  <InputAdornment aria-hidden position="end">
    <IconButton onClick={onClick}>
      {isPasswordVisible ? (
        <Icon
          className="fs-3"
          iconName="visibility"
          title={formatMessage(messages.showPassword)}
        />
      ) : (
        <Icon
          className="fs-3"
          iconName="visibility_off"
          title={formatMessage(messages.hidePassword)}
        />
      )}
    </IconButton>
  </InputAdornment>
)

InputPasswordToggleButton.propTypes = {
  isPasswordVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default InputPasswordToggleButton
