// Vendor
import React from 'react'

// WeSpire
import { Heading } from 'components/ui/heading'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'this is a Heading component',
    },
  },
  component: Heading,
  title: 'UI/Typography/Heading(old)',
}

const Template = (args) => <Heading {...args} />

export const Heading1 = Template.bind({})
Heading1.args = {
  level: 1,
}

export const Heading2 = Template.bind({})
Heading2.args = {
  level: 2,
}

export const Heading3 = Template.bind({})
Heading3.args = {
  level: 3,
}
