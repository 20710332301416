import gql from 'graphql-tag'

export const PREVIEW_CAMPAIGN_TEMPLATE_QUERY = gql`
  query campaignTemplate($campaignId: ID!) {
    campaignTemplate(id: $campaignId) {
      achievement {
        description
        id
        imageUrl
        name
        points
      }
      acts {
        conditionMetricName
        customFields {
          id
          isRequired
          name
          options
          responseType
        }
        dailyMaxLogging
        description
        hasCarbonImpact
        hasCustomTimeframe
        hasOverrideTargetAmount
        id
        isAccumulationAction
        name
        points
        progressCategory
        progressMeasuredAs
        progressTargetValue
        required
        requirement {
          comment
          photo
          tag
        }
      }
      id
      image
      description
      name
      type
    }
  }
`

export const PREVIEW_CAMPAIGN_QUERY = gql`
  query campaignPreview($campaignId: ID!) {
    campaignPreview(campaignId: $campaignId) {
      previousStepUrl
      progressionButtonText
      progressionState
    }
  }
`
