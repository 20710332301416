import React, { useState, useEffect } from 'react'

const DatePicker = ({ value, onChange }) => {
  const [date, setDate] = useState(value || new Date())

  useEffect(() => {
    if (value) {
      setDate(value)
    }
  }, [value])

  const handleChange = (event) => {
    const value = event.target.value
    if (value === '') {
      setDate(null)
      if (onChange) {
        onChange(null)
      }
    } else {
      const newDate = new Date(value)
      setDate(newDate)
      if (onChange) {
        onChange(newDate)
      }
    }
  }

  return (
    <div className="relative w-full">
      <input
        type="date"
        data-test="date-input"
        value={date.toISOString().substr(0, 10)}
        onChange={handleChange}
        className="w-full h-14 p-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
      />
    </div>
  )
}

export default DatePicker
