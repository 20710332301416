import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import CurrencyFormat from 'react-currency-format'

// westyle
import Button from 'src/common/button/Button'
import Divider from 'src/common/Divider'
import InfoContainer from 'src/common/InfoContainer'
import Input from 'src/common/Input'
import InputGroupButton from 'src/common/InputGroupButton'
import FileUploadContainer from 'src/common/FileUploadContainer'
import FileUpload from 'src/common/FileUpload'
import SectionContainer from 'src/common/SectionContainer'
import DatePicker from '../../common/DatePicker'

export const PageContainer = ({ title, children }) => {
  return (
    <section className="flex flex-col max-w-[50.5rem] lg:w-full w-11/12 mb-28">
      <>
        <div className="flex justify-between pt-8 pb-6 px-1 items-center flex-wrap">
          <h1 className="w-full sm:text-start sm:w-auto pb-4 md:pb-0">
            {title}
          </h1>
          <Link className="text-black-body font-medium" to={'#'}>
            <ArrowBackIcon className="text-sm" />
            Back
          </Link>
        </div>
        <div className="rounded-2xl w-4xl bg-white border-[1px] border-gray-2 lg:p-10 p-6">
          {children}
        </div>
      </>
    </section>
  )
}

const payload = {
  charity: {
    name: 'Surfers for Strays',
    ein: 842865698,
    location: 'San Diego, CA',
    matchRatio: 1.0,
  },
  funds: {
    localCurrency: 'eur',
    grantAccount: 78.09,
    matchAccount: 80.0,
    matchAccountLimit: 200.0,
    minimumDonation: 10.0,
  },
}

const RequestDonationMatchForm = () => {
  const { charity, funds } = payload
  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
  }

  const handleSubmit = (values) => console.log(values)

  return (
    <>
      <PageContainer title="Request a donation match">
        <p>
          You can request donation match by filling out this form and attaching
          a donation receipt. It will typically take only a fea business days to
          review your match once you submit the form. If you have any questions
          about this form, please contact support@wespire.com.
        </p>
        <Divider className="mt-9 mb-1 border-gray-2" />
        <Formik
          initialValues={{
            paymentMethod: 'grant_account',
            donationFrequency: 'day',
            donationAmount: 0,
            match: false,
            anonymous: false,
            note: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <SectionContainer title="Donation details">
                <div className="px-4">
                  <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  <InputGroupButton
                    buttonText="Verify"
                    buttonOnClick={() => console.log('verify')}
                    buttonVariant="gallery"
                    className="mt-4"
                    explainer="For US 501(c)(3) organizations, this is often a nine-digit number."
                    name="einNumber"
                    placeholder="Enter EIN number"
                  />
                  <Divider className="my-5 border-gray-2" />
                  <div className="bg-pampas w-full rounded flex flex-col p-2 text-center font-medium">
                    <p>{charity.name}</p>
                    <p>{charity.ein}</p>
                    <p>{charity.location}</p>
                  </div>
                  <InfoContainer className="mt-4">
                    <span>
                      This charity is not currently on the list of your
                      company's match-eligible charities. Please continue to
                      complete this form and an administrator will review your
                      match request. You will be informed of the decision
                      shortly.
                    </span>
                  </InfoContainer>
                </div>
              </SectionContainer>

              <SectionContainer title="Attach donation receipt">
                <FileUploadContainer title="Browse files" />
                <FileUpload text="donation-receipt-02-15-2023.pdf" />
              </SectionContainer>

              <SectionContainer title="Match request">
                <div className="px-4">
                  <Input
                    className="w-full"
                    explainer="Minimum must equal to $10.00 USD"
                    name="email"
                    placeholder="Match amount*"
                  />

                  <InfoContainer className="mt-4">
                    <ul>
                      <li>
                        You have{' '}
                        <CurrencyFormat
                          className="font-semibold"
                          displayType={'text'}
                          value={funds.matchAccount}
                          prefix={'€'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />{' '}
                        remaining of your company's{' '}
                        <CurrencyFormat
                          displayType={'text'}
                          value={funds.matchAccountLimit}
                          prefix={'€'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />{' '}
                        annual limit per employee.
                      </li>
                      <li>
                        The minimum match amount is{' '}
                        <CurrencyFormat
                          displayType={'text'}
                          value={funds.minimumDonation}
                          prefix={'€'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </li>
                      <li>
                        The maximum match amount is{' '}
                        <CurrencyFormat
                          displayType={'text'}
                          value={funds.matchAccountLimit}
                          prefix={'€'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </li>
                      <li>
                        The match ratio for this charity is {charity.matchRatio}
                        :1
                      </li>
                    </ul>
                  </InfoContainer>
                </div>
              </SectionContainer>

              <div className="flex flex-col justify-center mt-8 items-center">
                <Button
                  className="mb-4 w-full"
                  name="Submit match request"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit match request
                </Button>
                <p className="text-sm text-black-body">
                  You will be redirected to Stripe. All donation transactions
                  will appear on your bank/credit card statements as GIVINGA
                  FOUNDATION, our nonprofit 501(c)(3) partner. By continuing,
                  you are agreeing to the Terms & Conditions.
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </PageContainer>
    </>
  )
}

export default RequestDonationMatchForm
