// Returns a new function that invokes the logic within its body after a
// specified time interval. Use this utility by wrapping an existing function
// that should not execute immediately.
//
// A classic use case for debouncing is to wait to hit a search endpoint until a
// user has finished typing within an input. Here is an example adapted from
// <CharityAutocomplete>. In the debounced version of this function,
// this.setState will be invoked after 500 miliseconds:
//
// Not Debounced:
// updateSearch = query => {
//  this.setState({ searchValue: query })
// }
//
// Debounced:
// updateSearch = debounce(query => {
//  this.setState({ searchValue: query })
// }, 500)
export function debounce(func, delay) {
  let isDebouncing
  return function () {
    clearTimeout(isDebouncing)
    isDebouncing = setTimeout(() => func.apply(this, arguments), delay)
  }
}
