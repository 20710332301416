const getGlobalFormData = () =>
  JSON.parse(localStorage.getItem('formData')) || {}

const setGlobalFormData = (formData) => {
  localStorage.setItem('formData', JSON.stringify(formData))
}

export const setData = (key, value) => localStorage.setItem(key, value)
export const getData = (key) =>
  localStorage.getItem(key) ? localStorage.getItem(key) : ''
export const removeData = (key) => localStorage.removeItem(key)

export const clearFormDataForCurrentPath = () => {
  let formData = getGlobalFormData()
  delete formData[window.location.pathname]
  setGlobalFormData(formData)
}

export const getFormDataForCurrentPath = () => {
  let data = getGlobalFormData()[window.location.pathname] || {}

  // Delete any null or undefined values.
  // This way we don't set form fields to these empty values which
  // would make formsy think they are dirty and in need of displaying errors.
  // E.g.  without this, any empty values would trigger "is required" errors.
  Object.keys(data).forEach((key) => {
    if (data[key] === null || data[key] === undefined) {
      delete data[key]
    }
  })
  return data
}

export const setFormDataForCurrentPath = (data) => {
  let formData = getGlobalFormData()
  formData[window.location.pathname] = data
  setGlobalFormData(formData)
}

export const updateFormDataForPath = (pathname, key, value) => {
  let formData = getGlobalFormData()
  if (formData[pathname] === null) {
    formData[pathname] = {}
  }
  formData[pathname][key] = value
  setGlobalFormData(formData)
}
