import React, { useContext, useState, useEffect } from 'react'
import { CalculatorContext } from 'components/CarbonCalculator/contexts/CalculatorContext'
import PropTypes from 'prop-types'
import Slider from '@mui/material/Slider'
import {
  HOUSEHOLD_SIZE,
  PERCENTAGE_RENEWABLE_ENERGY,
} from 'components/CarbonCalculator/contexts/constants'

const SliderComponent = ({
  questionKey,
  answers,
  min,
  max,
  steps,
  helperDescriptionText,
  valueLabelDisplay,
}) => {
  const { dispatchAnswer, currentQuestionIndex } = useContext(CalculatorContext)
  const [currentValue, setCurrentValue] = useState(0)
  const [currentOption, setCurrentOption] = useState({})

  const handleCurrentAnswer = (value) => {
    if (currentValue !== value) {
      const currentAnswerOption = answers.filter(
        (obj) => obj.value === value
      )[0]

      dispatchAnswer({
        type: questionKey,
        payload: { questionKey, answer: value },
      })

      setCurrentOption(currentAnswerOption)
      setCurrentValue(value)
    }
  }

  const numberWithCommas = (numberToUpdate) => {
    let withCommas = ''
    if (numberToUpdate) {
      withCommas = numberToUpdate
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return withCommas
  }

  useEffect(() => {
    setCurrentValue(null)
    setCurrentOption({})

    if (questionKey === PERCENTAGE_RENEWABLE_ENERGY) {
      setCurrentValue('0')
      setCurrentOption({
        descriptionText: 'If you are unsure, please choose 0%.',
      })
    }
    if (questionKey === HOUSEHOLD_SIZE) {
      setCurrentValue(1)
    }
  }, [currentQuestionIndex])

  return (
    <>
      <div>
        <Slider
          key={questionKey}
          color="primary"
          data-test="slider-test"
          min={min}
          max={max}
          step={steps}
          onChange={(event) => handleCurrentAnswer(event.target.value)}
          marks={answers}
          defaultValue={currentValue}
          value={currentValue}
          valueLabelDisplay={valueLabelDisplay}
          sx={{
            '.MuiSlider-markLabel': { display: 'none' },
            '.MuiSlider-rail': { background: '#1772DC' },
            '.MuiSlider-thumb': { background: '#1772DC' },
            '.MuiSlider-track': { background: '#1772DC' },
            '.MuiSlider-valueLabelLabel': { color: '#FFFFFF' },
          }}
        />
      </div>
      <div className="flex justify-between -mt-6">
        <span
          data-test="slider-first-label-test"
          className="uppercase text-xs md:text-sm"
        >
          {answers[0].label}
        </span>
        <span
          data-test="slider-last-label-test"
          className="uppercase text-xs md:text-sm"
        >
          {answers[answers.length - 1].label}
        </span>
      </div>
      <div
        data-test="slider-description-holder-test"
        className="flex justify-center"
      >
        <div
          data-test="slider-current-value-label-test"
          className="text-center pt-2"
        >
          {currentOption && currentOption.label ? (
            <div className="capitalize">
              {numberWithCommas(currentOption.label)} {helperDescriptionText}
            </div>
          ) : (
            <div className="capitalize">
              {numberWithCommas(currentValue)} {helperDescriptionText}
            </div>
          )}
          {currentOption && currentOption.descriptionText && (
            <p data-test="slider-current-descrption-text-test">
              {currentOption.descriptionText}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

SliderComponent.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      descriptionText: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  min: PropTypes.number,
  max: PropTypes.number,
  steps: PropTypes.number,
  helperDescriptionText: PropTypes.string,
  questionKey: PropTypes.string,
  valueLabelDisplay: PropTypes.string,
}

SliderComponent.defaultProps = {
  answers: [{ descriptionText: '', label: '', value: null }],
  min: 0,
  max: 1000,
  steps: null,
  helperDescriptionText: null,
  questionKey: 0,
  valueLabelDisplay: 'auto',
}

export default SliderComponent
