// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import { WordForScore } from 'components/queries/word_for_score'

const messages = defineMessages({
  title: {
    defaultMessage: '{ wordForScore } History',
    id: 'pointsDashboard.title',
  },
})

export const getPointsDashboardTitle = () => {
  return intl.formatMessage(messages.title, {
    wordForScore: <WordForScore wordOnly />,
  })
}
