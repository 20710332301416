// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import { ActionCompletedMessage } from 'components/campaign/action_completed_message'
import Stack from 'components/ui/stack'

const messages = defineMessages({
  complete: {
    defaultMessage: 'completed',
    description: 'The action has been completed message',
    id: 'actionStatus.complete',
  },
})

export const ActionStatus = ({
  cooldownEndsAt,
  status,
  userCompleted,
  ...other
}) => {
  const { formatMessage } = intl

  return (
    <Stack
      {...other}
      className={cx(
        'd-flex align-items-end flex-column | text-uppercase w-100',
        other.className
      )}
      data-test="action-status"
      space={2}
    >
      <span className="fs-3 fw-bold text-green">
        {formatMessage(messages.complete)}
      </span>
      {userCompleted && (
        <ActionCompletedMessage
          className="fs-1 text-black-3"
          cooldownEndsAt={cooldownEndsAt}
          status={status}
        />
      )}
    </Stack>
  )
}

ActionStatus.propTypes = {
  /** datetime when the action is no longer on cooldown */
  cooldownEndsAt: PropTypes.string,
  /** the completable status of an action */
  status: PropTypes.oneOf(['AVAILABLE', 'COOLDOWN', 'FINISHED', 'UNAVAILABLE'])
    .isRequired,
  /** true if action has ever been completed by current user */
  userCompleted: PropTypes.bool.isRequired,
}

ActionStatus.defaultProps = {
  cooldownEndsAt: null,
}
