// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import Stack from 'components/ui/stack'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { maybeDisplayUserTour } from 'redux/dispatchers'

const messages = defineMessages({
  agreeingToCertify: {
    defaultMessage:
      'By making this donation, you are agreeing to certify that:',
    id: 'donationTerms.agreeingToCertify',
  },
  conditions: {
    defaultMessage:
      'Neither you nor anyone else will receive any impermissible benefit (e.g. tuition, membership fees with more than incidental benefits, dues, admission to charitable or other events, goods bought at auction, etc.) from the recommended charitable organization from this donation. Examples of permissible benefits include benefits that are not more than incidental, such as free or discounted admission, free or discounted parking, token logo-bearing key chains, caps, t-shirts, or calendars.',
    id: 'donationTerms.conditions',
  },
  contact: {
    defaultMessage:
      'Please contact the Givinga Foundation by mail at 396 Washington St. Suite 307 Wellesley, MA 02481, by phone at <phoneLink>(339) 200-9064</phoneLink>, or by email at <emailLink>support@givingafoundation.org</emailLink> with any questions.',
    id: 'donationTerms.contact',
  },
  donationNotFulfill: {
    defaultMessage:
      'This donation will not fulfill all or a portion of a pledge to the charitable organization.',
    id: 'donationTerms.donationNotFulfill',
  },
  pageTitle: {
    defaultMessage: 'Donation terms & conditions',
    description:
      'header for a page describing the terms and conditions for donating on our platform',
    id: 'donationTerms.pageTitle',
  },
  terms: {
    defaultMessage:
      'By making this donation, you are making a contribution to a donor-advised fund at the Givinga Foundation. The Givinga Foundation is a nonprofit 501(c)(3) partner of WeSpire and facilitates the granting of funds to the specific nonprofit for which your donation is being processed. All tax receipts, if warranted, will be provided by the Givinga Foundation. All donations made through the Site are final, irrevocable, non-refundable, and are subject to these terms.',
    id: 'donationTerms.terms',
  },
})

const DonationTerms = () => {
  const { formatMessage } = intl

  return (
    <GraphQLFeatureFlag featureName="giving">
      <DocumentTitle title={formatMessage(messages.pageTitle)}>
        {maybeDisplayUserTour('donationTermsAndConditionsPage')}

        <CenteredContent
          className="mxw-measure"
          data-test="donation-terms-and-conditions"
        >
          <Heading className="mt-5 px-3" level={1}>
            {formatMessage(messages.pageTitle)}
          </Heading>
          <Stack as={Paper} className="m-3 p-4 | p-md-5" space={3}>
            <p>{formatMessage(messages.agreeingToCertify)}</p>
            <Stack as="ul" className="fs-2 lh-md" space={2}>
              <li>{formatMessage(messages.conditions)}</li>
              <li>{formatMessage(messages.donationNotFulfill)}</li>
            </Stack>
            <p>{formatMessage(messages.terms)}</p>
            <p>
              {formatMessage(messages.contact, {
                emailLink: (...linkText) => (
                  <a href="mailto:support@givingafoundation.org">{linkText}</a>
                ),
                phoneLink: (...linkText) => (
                  <a href="tel:(339)200-9064">{linkText}</a>
                ),
              })}
            </p>
          </Stack>
        </CenteredContent>
      </DocumentTitle>
    </GraphQLFeatureFlag>
  )
}

export default DonationTerms
