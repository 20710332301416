// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { GET_USER_SUGGESTIONS } from 'graphql/queries'
import QueryAutocomplete from '/components/form/query_autocomplete'
import WeApollo from 'utilities/we_apollo'
import Formsy from 'formsy-react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'components/application/layout/mui_themes'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  helper_text: {
    defaultMessage: 'Select user(s) to assign to this Group Role',
    id: 'UserAutocomplete.helper_text',
  },
  label: {
    defaultMessage: 'Users',
    id: 'UserAutocomplete.label',
  },
})

const { formatMessage } = intl

const UserAutocomplete = ({ includeCurrentUser, ...other }) => (
  <MuiThemeProvider theme={muiTheme}>
    <WeApollo>
      <Formsy>
        <QueryAutocomplete
          {...other}
          extraVariables={{ includeCurrentUser }}
          multiple
          name="userSelections"
          query={GET_USER_SUGGESTIONS}
          queryName="userSuggestions"
          queryVariableMapping={{
            omitted: 'omittedUserIds',
            query: 'query',
          }}
          searchOnFocus
          suggestionMapping={{
            id: 'id',
            imageUrl: 'avatarUrl',
            label: 'name',
            secondaryLabel: 'email',
          }}
        />
      </Formsy>
    </WeApollo>
  </MuiThemeProvider>
)

UserAutocomplete.propTypes = {
  includeCurrentUser: PropTypes.bool,
  textFieldProps: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
  }),
}

UserAutocomplete.defaultProps = {
  includeCurrentUser: false,
  textFieldProps: {
    helperText: formatMessage(messages.helper_text),
    label: formatMessage(messages.label),
  },
}

export default UserAutocomplete
