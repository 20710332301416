// Vendor
import cx from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import LinkButton from 'components/ui/link_button'

const MainNavLink = ({ href, isActive, title }) => (
  <LinkButton
    {...(isActive && { 'aria-current': 'page' })}
    classes={{
      focusVisible: 'outline-auto',
    }}
    className={cx(
      '[ nav-link ] [ fs-2 mx-2 p-2 text-uppercase ] [ show-lg-up ]',
      { 'text-black-2': !isActive },
      { 'text-blue': isActive }
    )}
    disableRipple
    size="small"
    to={href}
    variant="text"
  >
    {title}
  </LinkButton>
)

MainNavLink.propTypes = {
  href: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

MainNavLink.defaultProps = {
  isActive: false,
}

export default MainNavLink
