// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

// WeSpire
import { useClientRouting } from 'utilities/routing'

// Diabaled to prevent es-lint error. For more info visit: https://github.com/yannickcr/eslint-plugin-react/issues/597
// eslint-disable-next-line
const Link = React.forwardRef(
  ({ children, forceReload, to, ...props }, ref) => {
    const isClient = useClientRouting(typeof to === 'object' ? to.pathname : to)

    return isClient && !forceReload ? (
      <RouterLink {...props} ref={ref} to={to}>
        {children}
      </RouterLink>
    ) : (
      <a {...props} href={to} ref={ref}>
        {children}
      </a>
    )
  }
)

Link.propTypes = {
  children: PropTypes.any,
  forceReload: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.object,
    }),
    PropTypes.string,
  ]).isRequired,
}

Link.defaultProps = {
  children: null,
  forceReload: false,
}

export default Link
