class CloudinaryImageApiSlug {
  constructor(cloudinaryData) {
    this.data = cloudinaryData
  }

  create() {
    const imageSlug = /(image|raw).*/.exec(this.data.url)[0]

    return `${imageSlug}#${this.data.signature}`
  }
}

export default CloudinaryImageApiSlug
