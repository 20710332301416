import AnalyticsManager from './analytics_manager'

export default class MultiverseAnalytics extends AnalyticsManager {
  activityClicked = (id, isFeatured) =>
    this.track('Activity clicked', { id, isFeatured })

  availableActivitiesCount = (count) =>
    this.track('Count of available activities', { count })

  channelActivitiesCount = (count, channelId) =>
    this.track('Count of activities in channel', { channelId, count })

  channelClicked = (id, position, title) =>
    this.track('Channel clicked', { id, position, title })
}

export const instance = new MultiverseAnalytics()
