import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'

import WeSpireAPI from 'utilities/wespire_api'
import { client } from 'utilities/we_apollo'
import { setToken } from 'redux/reducers/session'
import SessionContext from 'src/contexts/SessionContext'

import TextHero from 'src/common/TextHero'
import Button from 'src/common/button/Button'
import Input from 'src/common/Input'
import { useCsrft } from 'src/hooks/useCsrft'

const SudoPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState()
  const { tokenName, tokenValue } = useCsrft()
  let { brand, refreshUser, user } = useContext(SessionContext)

  const handleSubmit = ({ email, password, uid }) => {
    setIsSubmitting(true)

    WeSpireAPI()
      .post('/session', {
        session: {
          email,
          password,
          uid,
        },
      })
      .then((response) => {
        setToken(response.data.token)
        setIsSubmitting(false)

        if (response.data.sso) {
          this.ssoformElement.submit()
        } else {
          window.location = response.data.redirect_path
        }

        refreshUser()

        window.location = '/'
      })
      .catch((error) => {
        const errors = error.response.data.errors
        setIsSubmitting(false)

        setMessage(errors.base)
      })
  }

  const disconnectSession = () => {
    setIsSubmitting(true)

    WeSpireAPI()
      .put('/session/disconnect', {})
      .then((res) => {
        setIsSubmitting(false)
        refreshUser()
        window.location = '/'
      })
      .catch((error) => {
        const errors = error.response.data.errors
        setIsSubmitting(false)

        setMessage(errors.base)
      })
  }

  const signedIn = !!user

  return (
    <main className="grid justify-items-center mt-20 pb-8">
      <TextHero subtitle={'Switch User'} title={brand?.name} />
      {message && <div>{message}</div>}
      {user && <div>{user?.email}</div>}
      {!signedIn && (
        <Formik
          initialValues={{
            email: null,
            password: null,
            uid: null,
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="max-w-[37.5rem] w-11/12 px-4">
              <section className="flex flex-col mt-20">
                <div className="flex flex-col">
                  <Input name={tokenName} type="hidden" value={tokenValue} />
                  <Input
                    className="black-2 border-[1px] border-grayscale-2 rounded-lg px-4 py-3 text-base font-extralight mt-4 font-body"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <Input
                    className="black-2 border-[1px] border-grayscale-2 rounded-lg px-4 py-3 text-base font-extralight mt-4 font-body"
                    name="password"
                    type="password"
                    placeholder="Your password"
                  />
                  <Input
                    className="black-2 border-[1px] border-grayscale-2 rounded-lg px-4 py-3 text-base font-extralight mt-4 font-body"
                    name="uid"
                    placeholder="User ID to assume"
                  />
                  <Button className="mt-4 rounded-lg" type="submit">
                    Continue
                  </Button>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      )}
      {signedIn && (
        <Button
          className="mt-4 rounded-lg"
          type="submit"
          onClick={disconnectSession}
        >
          Disconnect
        </Button>
      )}
    </main>
  )
}

export default SudoPage
