// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import Link from 'components/shared/link'

export const TextLink = ({ children, className, to, ...props }) => (
  <Link {...props} className={cx('text-link', className)} to={to}>
    {children}
  </Link>
)

TextLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.object,
    }),
    PropTypes.string,
  ]).isRequired,
}

TextLink.defaultProps = {
  children: null,
  className: '',
}
