import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Tooltip = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleTooltip = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <div className={cx('group inline-block', className)}>
      <div
        className="group flex relative"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <span
          className="material-symbols-rounded peer text-sm"
          onClick={toggleTooltip}
        >
          info
        </span>
        <span
          className={cx(
            'absolute bg-pampas bottom-1/2 -translate-x-1/2 mb-4 p-2 rounded w-40 shadow-[0px_1px_2px_#C4D1DB] body-medium-regular',
            {
              hidden: !isOpen && !document.querySelector('.peer:hover'),
              block: isOpen || document.querySelector('.peer:hover'),
            }
          )}
        >
          {children}
        </span>
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  className: PropTypes.string,
}

export default Tooltip
