import { defineMessages } from 'react-intl'

import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { stringCleanup } from '../string_cleanup'

const { formatMessage } = intl

export const messages = defineMessages({
  actionCooldownPeriod: {
    defaultMessage: 'Cooldown Period',
    id: 'actionsCsvData.actionCooldownPeriod',
  },
  actionDescription: {
    defaultMessage: 'Action Description',
    id: 'actionsCsvData.actionDescription',
  },
  actionEndDate: {
    defaultMessage: 'End Date',
    id: 'actionsCsvData.actionEndDate',
  },
  actionId: {
    defaultMessage: 'Action ID',
    id: 'actionsCsvData.actionId',
  },
  actionName: {
    defaultMessage: 'Action Name',
    id: 'actionsCsvData.actionName',
  },
  actionRequirements: {
    defaultMessage: 'Action Requirements',
    id: 'actionsCsvData.actionRequirements',
  },
  actionStartDate: {
    defaultMessage: 'Start Date',
    id: 'actionsCsvData.actionStartDate',
  },
  actionType: {
    defaultMessage: 'Action Type',
    id: 'actionsCsvData.actionType',
  },
  activityLink: {
    defaultMessage: 'Activity Link',
    id: 'actionsCsvData.activityLink',
  },
  campaignEndDate: {
    defaultMessage: 'Campaign End Date',
    id: 'actionsCsvData.campaignEndDate',
  },
  campaignId: {
    defaultMessage: 'Campaign ID',
    id: 'actionsCsvData.campaignId',
  },
  campaignName: {
    defaultMessage: 'Campaign Name',
    id: 'actionsCsvData.campaignName',
  },
  campaignPointsType: {
    defaultMessage: 'Points Type',
    id: 'actionsCsvData.campaignPointsType',
  },
  campaignStartDate: {
    defaultMessage: 'Campaign Start Date',
    id: 'actionsCsvData.campaignStartDate',
  },
  pointsOnGrantingAction: {
    defaultMessage: 'Points on Granting Action',
    id: 'actionsCsvData.pointsOnGrantingAction',
  },
})

export const actionsCsvData = (actions) =>
  actions.map((action) => ({
    [formatMessage(messages.actionId)]: action.id,
    [formatMessage(messages.actionName)]: action.name,
    [formatMessage(messages.actionDescription)]: stringCleanup(
      action.description
    ),
    [formatMessage(messages.pointsOnGrantingAction)]: action.points,
    [formatMessage(messages.campaignPointsType)]: action.campaignPointsType,
    [formatMessage(messages.campaignId)]: action.campaignId,
    [formatMessage(messages.campaignName)]: action.campaignName,
    [formatMessage(
      messages.activityLink
    )]: `${window.location.origin}/management_panel/campaigns/${action.campaignId}/edit?step=acts`,
    [formatMessage(messages.actionStartDate)]: action.actionStartDate,
    [formatMessage(messages.actionEndDate)]: action.actionEndDate,
    [formatMessage(messages.campaignStartDate)]: action.campaignStartDate,
    [formatMessage(messages.campaignEndDate)]: action.campaignEndDate,
    [sharedTranslations.channelLabel]: action.campaignChannelName,
    [formatMessage(messages.actionCooldownPeriod)]: action.cooldownHours,
    [formatMessage(messages.actionRequirements)]: action.actionRequirements,
    [formatMessage(messages.actionType)]: action.actionType,
  }))
