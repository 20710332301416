import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

// WeSpire
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import Button from 'src/common/button/Button'
import {
  CharityCardContainer,
  CharityCardMission,
  formatAddress,
} from './CharityCardUtils'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const RETROACTIVE_MATCH_ENABLED_QUERY = gql`
  query retroactiveMatchEnabled {
    brandConfig {
      retroactiveMatchEnabled
    }
  }
`

const CharityCard = ({
  charity: { address, ein, matched, name, tagLine, mission }, source
}) => {
  const intl = useIntl()
  const { data: { brandConfig: { retroactiveMatchEnabled } = {} } = {} } =
    useQuery(RETROACTIVE_MATCH_ENABLED_QUERY)

  return (
    <CharityCardContainer>
      <div className="flex items-center">
        <h3 className="text-2xl my-0 leading-snug">{name}</h3>
        <div className="ml-auto">
          {retroactiveMatchEnabled && source === 'donation_history' ? (
            <Button
              to={`/app/retroactive_match_form/${ein}/${name}`}
              variant="outlined"
            >
              Submit Match Request
            </Button>
          ) : (
            <Button to={`/app/dollars_for_doers_form/${ein}`} variant="outlined">
              {intl.formatMessage({
                id: 'charities.donate',
                defaultMessage: 'Donate',
              })}
            </Button>
          )}
        </div>
      </div>
      {ein && (
        <span className="mt-1 text-sm text-practical-gray">{`${ein}`}</span>
      )}
      <span className="mt-1 text-sm text-practical-gray">
        {formatAddress(address)}
      </span>
      {tagLine && <p className="text-sm mt-2 my-0 italic">{tagLine}</p>}
      <GraphQLFeatureFlag
        error={null}
        featureDisabledError={null}
        featureName="donationMatching"
        loader={null}
      >
        {matched && (
          <p className="text-sm mt-2 my-0 text-success-green">
            <span className="bg-success-green inline-block p-1 rounded-full" />{' '}
            <FormattedMessage
              id="charity.matchingGiftAvailable"
              defaultMessage={'Matching gift available'}
            />
          </p>
        )}
      </GraphQLFeatureFlag>
      <CharityCardMission mission={mission} />
    </CharityCardContainer>
  )
}

CharityCard.propTypes = {
  charity: PropTypes.object.isRequired,
  renderAction: PropTypes.object,
  source: PropTypes.string.isRequired,
}

CharityCard.defaultProps = {
  renderAction: null,
}

export default CharityCard
