// Vendor
import { useCallback } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

// WeSpire
import store from 'redux/store'

const isStoreRouteMatch = (url) => {
  return store.getState().routes.some((route) => {
    const match = matchPath(url, {
      exact: route.exact,
      path: route.path,
    })

    return match && match.isExact
  })
}

export const useClientRouting = (url) => {
  return isStoreRouteMatch(url)
}

export const usePush = () => {
  const history = useHistory()

  return useCallback((to) => {
    if (isStoreRouteMatch(to?.pathname || to)) return history.push(to)

    window.location.href = to
  }, [])
}
