// Vendor
import React, { Fragment  } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import AddressAutocomplete, { getLocationProvider } from 'components/form/address_autocomplete'
import { CustomField } from 'components/form/custom_field'
import FormTextField from 'components/form/text_field'
import { intl } from 'utilities/localization'
import { useFlags } from 'launchdarkly-react-client-sdk'

const messages = defineMessages({
  address: {
    defaultMessage: 'Address',
    id: 'additionalFormFields.address',
  },
  addressHelpText: {
    defaultMessage:
      'Search for the correct address by typing street name first, followed by City.',
    description: 'Help text explaining how to enter an address',
    id: 'additionalFormFields.addressHelpText',
  },
  telephone: {
    defaultMessage: 'telephone number',
    id: 'additionalFormFields.telephone',
  },
  telephoneErrorMessage: {
    defaultMessage: 'Must be a valid phone number (XXX) XXX-XXXX',
    description: 'Error message indicating a telephone number format',
    id: 'additionalFormFields.telephoneErrorMessage',
  },
  telephoneGuestHelpText: {
    defaultMessage: 'The best phone number that your guest can be reached at.',
    description: 'Help text for the telephone number field',
    id: 'additionalFormFields.telephoneGuestHelpText',
  },
  telephoneHelpText: {
    defaultMessage: 'The best phone number that you can be reached at.',
    description: 'Help text for the telephone number field',
    id: 'additionalFormFields.telephoneHelpText',
  },
})

export const AdditionalFormFields = ({
  address,
  customFields,
  guest,
  telephone,
}) => {
  const { enableGooglePlacesApiParticipationEvents } = useFlags()

  return (<Fragment>
    {address && (
      <AddressAutocomplete
        fullAddress
        name="address"
        required
        textFieldProps={{
          helperText: intl.formatMessage(messages.addressHelpText),
          label: intl.formatMessage(messages.address),
        }}
        type={'google'}
      />
    )}

    {telephone && (
      <FormTextField
        mask="(999) 999-9999"
        name="telephone"
        required
        textFieldProps={{
          helperText: intl.formatMessage(
            guest ? messages.telephoneGuestHelpText : messages.telephoneHelpText
          ),
          inputProps: { type: 'tel' },
          label: intl.formatMessage(messages.telephone),
        }}
        validationErrors={{
          isLength: intl.formatMessage(messages.telephoneErrorMessage),
        }}
        validations="isLength:10"
      />
    )}

    {customFields.map((field) => (
      <CustomField field={field} key={field.id} />
    ))}
  </Fragment>)
}

AdditionalFormFields.propTypes = {
  address: PropTypes.bool,
  customFields: PropTypes.array,
  guest: PropTypes.bool,
  telephone: PropTypes.bool,
}

AdditionalFormFields.defaultProps = {
  address: false,
  customFields: [],
  guest: false,
  telephone: false,
}
