// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { NetworkStatus } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import ExternalLink from 'components/ui/external_link'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import Stack from 'components/ui/stack'

import { GroupChapterRow } from './row'

const { formatMessage } = intl

const messages = defineMessages({
  allChapters: {
    defaultMessage: 'All Chapters ({ totalCount })',
    id: 'groupChaptersTable.allChapters',
  },
  createChapter: {
    defaultMessage: 'Create new Chapter',
    id: 'groupChaptersTable.createChapter',
  },
  emptyState: {
    defaultMessage: 'Group has no available chapters.',
    id: 'groupChaptersTable.emptyState',
  },
})

const tableHeadMessages = defineMessages({
  name: {
    defaultMessage: 'Chapter Name',
    id: 'groupChaptersTableHead.name',
  },
})

const QUERY = gql`
  query groupChaptersQuery($groupId: ID!) {
    currentUser {
      can {
        manageGroups
      }
    }
    group(id: $groupId) {
      id
      slug
      manageableGroups {
        id
        name
        isChapter
        slug
      }
    }
  }
`
export const GroupChaptersTable = ({ groupId }) => {
  const { data, error, loading, networkStatus } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      groupId: groupId,
    },
  })

  const groupData = data?.group

  if (error) {
    return <MissingResourcePage />
  }

  const headCells = [
    {
      id: 'name',
      label: formatMessage(tableHeadMessages.name),
    },
    {
      id: 'actions',
      invisible: true,
    },
  ]

  const filteredChapters = groupData?.manageableGroups?.filter(
    (chapter) => chapter.isChapter
  )

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)
  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase"
          data-test="all-chapters-label"
          level={3}
        >
          {formatMessage(messages.allChapters, {
            totalCount: filteredChapters?.length,
          })}
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack className="d-flex">
          <div className="ml-auto" />

          {data?.currentUser?.can?.manageGroups && (
            <ExternalLink
              className="text-blue fs-2 d-flex align-items-center text-decoration-none"
              data-test="create-chapter-button"
              hideIcon
              to={`/management_panel/groups/${groupData?.slug}/chapters/new`}
            >
              <Icon
                className="fs-4 mr-1"
                iconName="people"
                title={formatMessage(messages.createChapter)}
              />
              {formatMessage(messages.createChapter)}
            </ExternalLink>
          )}
        </Stack>

        <Stack>
          <TableContainer>
            <Table aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell, index) => (
                    <TableCell
                      align={index > 0 ? 'right' : 'inherit'}
                      key={headCell.id}
                    >
                      {headCell.invisible ? null : headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!refetching &&
                  filteredChapters?.map((chapter) => {
                    return (
                      <GroupChapterRow
                        key={chapter.id}
                        parentGroupId={groupData.id}
                        parentGroupSlug={groupData.slug}
                        {...chapter}
                      />
                    )
                  })}
                {filteredChapters?.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <p
                        className="fs-2 fw-semi-bold d-flex align-items-center justify-content-center"
                        data-test="group-chapters-empty-state"
                      >
                        {intl.formatMessage(messages.emptyState)}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <CenteredPaddedLoadingIndicator
                        padding={null}
                        size={LoadingIndicatorSize.LARGE}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Stack>
  )
}

GroupChaptersTable.propTypes = {
  groupId: PropTypes.string.isRequired,
}
