import gql from 'graphql-tag'

export const CHARITY_QUERY = gql`
  query charity($ein: String!) {
    brandConfig {
      id
      donorCoveringCreditCardFee
      donorCoveringProcessingFee
    }
    charity(ein: $ein) {
      id
      matched
      name
    }
    currentUser {
      brand {
        enabledCurrencies {
          id
          isoCode
          name
          symbol
        }
        hasEnabledCountries
        id
        name
      }
      currency {
        id
        isoCode
        isZeroDecimal
        minimumDonationAmount
        name
        symbol
      }
      givingaUser {
        availableDonationFunds
        donationFundsCap
        employeeAccountBalance
        id
      }
      id
    }
    donationMatchingEnabled
    donationEmployeeAccountEnabled
    recurringDonationsEnabled
  }
`

export const DONATION_FORM_BALANCES_QUERY = gql`
  query donationFormBalances($currencyId: ID!) {
    currentUser {
      givingaUser {
        availableDonationFunds(currencyId: $currencyId)
        availableDonationCurrency(currencyId: $currencyId)
        donationFundsCap(currencyId: $currencyId)
        employeeAccountBalance
        id
      }
      id
    }
  }
`
export const RECURRING_DONATIONS_ENABLED = gql`
  query recurringDonationsEnabled {
    recurringDonationsEnabled
  }
`