import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { sortByNewest } from 'utilities/sort'
import { TakeActionLink } from 'components/activities/link'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sharedTranslations } from 'components/shared/translations'
import { messages } from 'components/donation_history/utilities/messages'
import { paymentStatus } from 'components/donation_history/utilities/transaction_utils'
import { DonationHistoryTransaction } from './transaction'
import { TransactionsGroupedByCharity } from './transactions_grouped_by_charity'

/**
 * GraphQL-backed donation history transactions retrieved from Givinga
 * transactions API endpoint.
 */
export const DonationHistoryTransactions = ({
  account,
  transactions,
}) => {
  if (!account || !transactions) {
    return (
      <MissingResourceSection
        data-test="missing-donation-transactions"
        errorDetails={sharedTranslations.yourDonations}
      />
    )
  } else if (transactions && transactions.length === 0) {
    return (
      <p className="fs-3 fw-semi-bold text-black-3">
        {intl.formatMessage(messages.donationsEmptyState, {
          takeActionLink: () => <TakeActionLink />,
        })}
      </p>
    )
  } else {
    const sortedTransactions = sortByNewest(transactions, 'donationDate')
    return (
      <TransactionsGroupedByCharity transactions={sortedTransactions} />
    )
  }
}

DonationHistoryTransactions.propTypes = {
  account: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object])
    .isRequired,
  transactions: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
}
