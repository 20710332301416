// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { List } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import Divider from 'components/ui/divider'
import { GroupMember } from './group_member'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'

const leadersQuery = gql`
  query leadershipData($id: ID!) {
    brand {
      id
      name
    }
    group(id: $id) {
      id
      roles {
        id
        title
        leaders {
          avatarUrl
          id
          name
          email
          teams {
            id
            name
          }
        }
      }
    }
  }
`

const messages = defineMessages({
  error: {
    defaultMessage: 'There was a problem loading the leaders section',
    id: 'leadersSection.error',
  },
  leadersHeading: {
    defaultMessage: 'Leadership ({leadersCount})',
    id: 'leadersSection.leadersHeading',
  },
})

const LeadersSection = ({ groupId }) => {
  const { formatMessage } = intl

  const errorMessage = (
    <>
      <p className="p-3">{formatMessage(messages.error)}</p>
      <Divider className="mx-3" />
    </>
  )

  return (
    <WeQuery
      error={errorMessage}
      loader={<SkeletonLoading />}
      query={leadersQuery}
      variables={{ id: groupId }}
    >
      {({ data: { brand, group } }) => {
        const roles = group.roles.filter((role) => role.leaders.length > 0)
        const leadersCount = roles.reduce(
          (count, role) => count + role.leaders.length,
          0
        )
        return (
          <>
            {roles.length > 0 && (
              <div className="pb-3">
                <Heading
                  className="pb-2 pt-4 px-3"
                  data-test="group-leadership"
                  level={3}
                >
                  {formatMessage(messages.leadersHeading, {
                    leadersCount: leadersCount,
                  })}
                </Heading>
                {leadersCount > 0 ? (
                  <List className="p-0">
                    {roles.map((role) => (
                      <React.Fragment key={role.id}>
                        {role.leaders.map((leader) => (
                          <GroupMember
                            avatarUrl={leader.avatarUrl}
                            brandName={brand.name}
                            data-test="leader"
                            email={leader.email}
                            groupName={group.name}
                            id={leader.id}
                            isLeader
                            key={leader.id}
                            name={leader.name}
                            teams={leader.teams}
                            title={role.title}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <p className="px-3">
                    {formatMessage(messages.leadersEmptyState)}
                  </p>
                )}
              </div>
            )}
            <Divider className="mx-3" />
          </>
        )
      }}
    </WeQuery>
  )
}

LeadersSection.propTypes = {
  groupId: PropTypes.string.isRequired,
}

export { LeadersSection }
