// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { fromNowUntil } from 'utilities/date_formatter'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { Heading } from 'components/ui/heading'
import ScreenReader from 'components/ui/screen_reader_text'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'

const Publish = ({
  activitiesCount,
  channelSlugUrl,
  isEdit,
  lastUpdated,
  teams,
}) => {
  return (
    <>
      <Heading
        className="bg-black-6 | fs-1 | ls-2 | px-4 | py-3 | text-uppercase"
        level={2}
      >
        {sharedTranslations.overview}
      </Heading>
      <div className="px-4">
        <Stack className="py-3">
          {
            <Stack space={3}>
              <div className="fs-2" data-test="channel-last-updated">
                {sharedTranslations.lastUpdated}:
                <span className="fw-semi-bold | text-capitalize">
                  {' '}
                  <time dateTime={lastUpdated}>
                    {fromNowUntil(lastUpdated)}
                  </time>
                </span>
              </div>
              <div className="fs-2" data-test="channel-activity-count">
                {sharedTranslations.totalActivities}:
                <span className="fw-semi-bold"> {activitiesCount}</span>
              </div>
              <div className="fs-2">
                {sharedTranslations.visibility}:
                <span className="fw-semi-bold">
                  {' '}
                  {intl.formatMessage(sharedMessages.publishTeams, {
                    count: teams,
                  })}
                </span>
              </div>
              <LinkButton
                className="fs-md-1 | text-transform-none"
                disabled={!isEdit}
                size="small"
                to={channelSlugUrl}
                variant="neutral"
              >
                {sharedTranslations.viewChannel}
                <ScreenReader text={sharedTranslations.viewChannel} />
              </LinkButton>
            </Stack>
          }
        </Stack>
      </div>
    </>
  )
}

Publish.propTypes = {
  activitiesCount: PropTypes.number.isRequired,
  channelSlugUrl: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  teams: PropTypes.number,
}

Publish.defaultProps = {
  lastUpdated: Date.now(),
  teams: 0,
}

export { Publish }
