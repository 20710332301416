export const updateBannerAction = 'wespire/SET_BANNER_STATE'

// See Banner.propTypes for list of available options.
const defaultState = {
  options: {},
}

// Reducer
const banner = (state = defaultState, action) => {
  switch (action.type) {
    case updateBannerAction:
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default banner
