// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import cx from 'classnames'

// WeSpire
import Icon from 'components/ui/icon'
import ScreenReader from 'components/ui/screen_reader_text'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { ActionCompletedMessage } from 'components/campaign/action_completed_message'
import { ActionTimeframe } from 'components/campaign/action_timeframe'
import { ActionTimeframeModel } from './action_timeframe_model'
import { sharedTranslations } from 'components/shared/translations'
import { ScoreBadge } from 'components/shared/score_badge'

const messages = defineMessages({
  actionRequired: {
    defaultMessage: 'Required for achievement',
    id: 'actionSummary.isRequired',
  },
  requirementMet: {
    defaultMessage: 'requirement has been met',
    id: 'actionSummary.requirementMet',
  },
  requirementNotMet: {
    defaultMessage: 'requirement has not been met',
    id: 'actionSummary.requirementNotMet',
  },
})

/**
 * Heading containing an Action's name, status of its campaign requirement, and
 * completion point value. Example screen reader announcement, on action focus:
 * "[Action name], required for achievement, requirement has been met, complete
 * again in an hour, [n] points, collapsed, button.
 */
const ActionSummary = ({
  actionTimeframe,
  component,
  className,
  cooldownEndsAt,
  hasCarbonImpact,
  hasCustomTimeframe,
  isAccumulationAction,
  name,
  points,
  required,
  status,
  userCompleted,
}) => {
  const { formatMessage } = intl
  const Component = component
  const iconSize = '2.375rem' // 38px - same size as <Chip /> with points label
  const iconMargin = '-0.0625rem' // -1px - force horizontal alignment
  const ScreenReaderPause = () => <ScreenReader text="," />

  return (
    <div
      className={cx(
        'd-flex align-items-center justify-content-between | lh-md pr-0 w-100',
        className
      )}
      data-test="action-summary"
    >
      <Stack space={1}>
        <Component className="my-0 fs-3 fw-semi-bold overflow-hidden pr-3 text-blue">
          {name}
        </Component>
        {hasCarbonImpact && (
          <span
            className="px-1 | bg-green-2 fs-1 fw-semi-bold text-green-0 text-uppercase"
            data-test="carbon-indicator"
          >
            {sharedTranslations.carbonImpact}
          </span>
        )}
        {hasCustomTimeframe && (
          <ActionTimeframe
            actionTimeframe={actionTimeframe}
            isAccumulationAction={isAccumulationAction}
          />
        )}

        <Stack className="[ d-flex flex-wrap align-items-center ] [ mr-3 text-black-2 ]">
          {required && (
            <>
              <p className="[ d-flex align-items-center flex-shrink-none ] [ fs-1 ]">
                <Icon className="mr-1" iconName="ribbon" title="" />
                <ScreenReaderPause />
                {formatMessage(messages.actionRequired)}
              </p>
              <ScreenReaderPause />
              <ScreenReader>
                {formatMessage(
                  userCompleted
                    ? messages.requirementMet
                    : messages.requirementNotMet
                )}
              </ScreenReader>
              {userCompleted && !isAccumulationAction && (
                <BulletSpacer className="fs-1 px-0" />
              )}
            </>
          )}
          {userCompleted && !isAccumulationAction && (
            <Stack className="d-flex align-items-center flex-shrink-none">
              <ScreenReaderPause />
              <ActionCompletedMessage
                className="fs-1"
                cooldownEndsAt={cooldownEndsAt}
                status={status}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      <ScreenReaderPause />

      <ScoreBadge
        iconMargin={iconMargin}
        iconSize={iconSize}
        isCompleted={userCompleted}
        points={points}
      />
    </div>
  )
}

ActionSummary.propTypes = {
  actionTimeframe: PropTypes.instanceOf(ActionTimeframeModel).isRequired,
  /** class applied to the parent node */
  className: PropTypes.string,
  /** specify different components based on usage context */
  component: PropTypes.string.isRequired,
  /** datetime when the action is no longer on cooldown */
  cooldownEndsAt: PropTypes.string,
  /** true if action has an emission token */
  hasCarbonImpact: PropTypes.bool.isRequired,
  /** true if act has custom startsAt or endsAt dates */
  hasCustomTimeframe: PropTypes.bool.isRequired,
  /** true if the action has condition enabled */
  isAccumulationAction: PropTypes.bool.isRequired,
  /** name of action */
  name: PropTypes.string.isRequired,
  /** points awarded for completing this action */
  points: PropTypes.number.isRequired,
  /** true if campaign requires this action to be completed to earn the achievement */
  required: PropTypes.bool,
  /** the completable status of an action */
  status: PropTypes.oneOf(['AVAILABLE', 'COOLDOWN', 'FINISHED', 'UNAVAILABLE'])
    .isRequired,
  /** true if action has ever been completed by current user */
  userCompleted: PropTypes.bool,
}

ActionSummary.defaultProps = {
  className: null,
  cooldownEndsAt: null,
  required: false,
  userCompleted: false,
}

export default ActionSummary
