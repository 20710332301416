// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import WeQuery from 'components/application/we_query'
import { ErrorPage } from 'components/shared/pages/error'
import { sharedTranslations } from '../shared/translations'

/** GraphQL backed component that queries the server to check if a feature is
 * enabled and then renders its children when true and an error message if false.
 * There is an optional way to force the children content to always render, e.g.
 * when rendering this page in preview mode. This component expects a top-level
 * graphql field to exist in a format like [featureName]Enabled. */
const GraphQLFeatureFlag = ({
  children,
  error,
  featureName,
  featureDisabledError,
  loader,
  shouldSkipCheck,
  ...other
}) => {
  const fieldName = `${featureName}Enabled`
  if (shouldSkipCheck) {
    return children
  }

  return (
    <WeQuery
      {...other}
      error={error}
      loader={loader}
      query={gql`
        query ${fieldName} {
          ${fieldName}
        }
      `}
    >
      {({ data: { [fieldName]: isFeatureEnabled } }) =>
        isFeatureEnabled ? children : featureDisabledError
      }
    </WeQuery>
  )
}

GraphQLFeatureFlag.defaultProps = {
  error: <ErrorPage />,
  featureDisabledError: (
    <ErrorPage body={sharedTranslations.featureDisabledPageBody} />
  ),
  loader: <CenteredPaddedLoadingIndicator />,
  shouldSkipCheck: false,
}

GraphQLFeatureFlag.propTypes = {
  /** render this content if the feature is enabled */
  children: PropTypes.node.isRequired,
  /** element rendered when the query returns an error */
  error: PropTypes.element,
  /** render this content if the feature is not enabled */
  featureDisabledError: PropTypes.element,
  /** name of graphql field that determines if feature is enabled */
  featureName: PropTypes.string.isRequired,
  /** element rendered while query is in progress */
  loader: PropTypes.element,
  /** skip feature flag check and render content no matter what */
  shouldSkipCheck: PropTypes.bool,
}

export default GraphQLFeatureFlag
