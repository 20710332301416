// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import LinearProgress from '@material-ui/core/LinearProgress'

// WeSpire
import Stack from 'components/ui/stack'

const ProgressBar = ({
  bottom,
  center,
  className,
  progressPercent,
  space,
  top,
  ...other
}) => (
  <Stack {...other} className={className} space={space}>
    {top}
    <div className="[ d-flex align-items-center ] [ position-relative ]">
      <LinearProgress
        aria-label={progressPercent + className + 'progressbar'}
        aria-valuemax="100"
        aria-valuemin="0"
        classes={{
          bar1Determinate: 'bg-green',
          determinate: 'bg-green-2 rounded',
        }}
        className="w-100"
        value={progressPercent}
        variant="determinate"
      />
      {center}
    </div>
    {bottom}
  </Stack>
)

ProgressBar.propTypes = {
  bottom: PropTypes.node,
  center: PropTypes.node,
  className: PropTypes.string,
  progressPercent: PropTypes.number.isRequired,
  space: PropTypes.number,
  top: PropTypes.node,
}

ProgressBar.defaultProps = {
  bottom: null,
  center: null,
  className: '',
  space: 2,
  top: null,
}

export { ProgressBar }
