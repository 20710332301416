// Default State
const defaultState = {
  userId: null,
}

// Action Creator
export function setCurrentUser(userId) {
  return {
    data: { userId },
    type: 'wespire/SET_CURRENT_USER_STATE',
  }
}

// Reducer
const currentUser = (state = defaultState, action) => {
  switch (action.type) {
    case 'wespire/SET_CURRENT_USER_STATE':
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default currentUser
