import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Vendors
import { FormattedNumber } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { useMutation } from '@apollo/client'
import { displayBanner } from 'redux/dispatchers'

// WeSpire
import Stack from 'components/ui/stack'
import { donationHistoryMessages } from 'components/donation_history/utilities/messages'
import { sortByNewest } from 'utilities/sort'
import { Button } from 'components/ui/button'
import { CANCEL_GIVING_SUBSCRIPTION_MUTATION } from '../utilities/mutations'
import { upcomingScheduledDonations } from '../utilities/queries'
import { CancelRecurringDonationsPopUp } from './cancel_recurring_donations_dialog'
import { numericDate } from 'utilities/date_formatter'
import { ErrorList } from 'components/form/error_list'

export const ScheduledDonations = ({ donations }) => {
  const [open, setOpen] = useState({})
  const [donationId, setDonationId] = useState(null)

  const formatDate = (date) => <time dateTime={date}>{numericDate(date)}</time>

  const toggleDialogOpenClose = (id) => {
    setDonationId(id)
    const newOpen = {
      ...open,
      [id]: !open[id],
    }
    setOpen(newOpen)
  }

  const [cancelRecurringDonation] = useMutation(
    CANCEL_GIVING_SUBSCRIPTION_MUTATION,
    { refetchQueries: [{ query: upcomingScheduledDonations }] }
  )

  const handleCancelRecurringDonations = async () => {
    const {
      data: {
        cancelGivingSubscription: { errors },
      },
    } = await cancelRecurringDonation({
      skip: donationId === null,
      variables: { id: donationId },
    })
    toggleDialogOpenClose(donationId)
    if (errors.length === 0) {
      displayBanner({
        content: donationHistoryMessages.cancelSuccess,
        variant: 'success',
      })
    } else {
      displayBanner({
        as: 'div',
        content: <ErrorList errors={errors} />,
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Stack space={4}>
        {sortByNewest(donations, 'scheduledDate').map((donation) => (
          <div key={donation.id}>
            <CancelRecurringDonationsPopUp
              charityName={donation.charityName}
              handleCancelRecurringDonations={handleCancelRecurringDonations}
              open={!!open[donation.id]}
              toggleDialogOpenClose={() => toggleDialogOpenClose(donation.id)}
            />
            <Paper
              className="fw-semi-bold lh-md pb-4"
              data-test="donation-history-upcoming-donation"
              key={donation.charityName}
            >
              <div className="[ d-flex align-items-center justify-content-between ] [ p-3 pb-1]">
                <div>
                  <h3
                    className="fs-3 fw-semi-bold lh-sm my-0"
                    data-test="upcoming-charity-name"
                  >
                    {donation.charityName}
                  </h3>
                </div>
              </div>
              <div className="row mt-3 mt-md-0 p-3">
                <div className="col-6 col-md-12">
                  <div className="[ row flex-column | flex-sm-row ] [ fs-1 my-0 ]">
                    <div className="[ d-flex col-sm-2 col-lg-3 mr-2] [ mt-2 ]">
                      <div className="text-black-3 text-uppercase ws-nowrap">
                        {donationHistoryMessages.scheduledDate}
                      </div>
                      <div
                        className="ml-1 ws-nowrap"
                        data-test="scheduled-date"
                      >
                        {formatDate(donation.scheduledDate)}
                      </div>
                    </div>

                    <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ]">
                      <div className="text-black-3 text-uppercase  ws-nowrap">
                        {donationHistoryMessages.donationAmount}
                      </div>
                      <div
                        className="text-capitalize ml-1 ws-nowrap"
                        data-test="donation-amount"
                      >
                        <FormattedNumber
                          currency="USD"
                          style="currency"
                          value={donation.amount / 100} // we have amount in cents
                        />{' '}
                      </div>
                    </div>
                    <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ml-0 ml-md-5 ml-lg-0 ]">
                      <div className="text-black-3 text-uppercase ws-nowrap">
                        {donationHistoryMessages.upcomingDonationPaymentMethod}
                      </div>
                      <div
                        className="text-capitalize text-center ml-1 ws-nowrap"
                        data-test="payment-method"
                      >
                        {donation.paymentMethod}
                      </div>
                    </div>
                    <div className="[ d-flex col-sm-2 col-lg-4 justify-content-end ] [ ml-0 ml-md-8 ml-lg-6 ]">
                      <Button
                        className="fs-2 pt-1 | text-blue-1 text-underline ws-nowrap"
                        data-test="cancel-recurring-donations"
                        disableRipple
                        onClick={() => toggleDialogOpenClose(donation.id)}
                      >
                        {donationHistoryMessages.stopMonthlyDonations}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        ))}
      </Stack>
    </>
  )
}

ScheduledDonations.propTypes = {
  donations: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      charityName: PropTypes.string,
      givingaSubscriptionId: PropTypes.string,
      paymentMethod: PropTypes.string,
      scheduledDate: PropTypes.string,
    })
  ).isRequired,
}
