// Vendor
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

// WeSpire
import { AchievementModal } from 'components/achievement'
import { displayBanner, maybeDisplayUserTour } from 'redux/dispatchers'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { RelativeTime } from 'components/shared/relative_time'
import { TakeActionLink } from 'components/activities/link'
import { DocumentTitle } from 'components/shared/document_title'
import { intl } from 'utilities/localization'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import CampaignLayoutDesktop from './layout_desktop'
import { CampaignLayoutMobile } from './layout_mobile'
import { CampaignModel, CampaignUserStatus } from './campaign_model'
import { SharedNavBreadcrumb } from 'components/shared/nav_breadcrumb'
import CampaignShowQuery from './show_query'
import { campaignTypeTranslation } from 'utilities/type_translation'
import { useIsMobile } from 'utilities/hooks/is_mobile'
import { getUrlParams } from 'utilities/get_url_params'

const messages = defineMessages({
  campaignEndedBanner: {
    defaultMessage:
      'Your Admin has disabled all Actions that are not in an active {type}. Please visit the {takeActionLink} to participate.',
    description:
      'Banner text for when the actions have been disabled at the end of a campaign',
    id: 'campaign.actionsDisabledBanner',
  },
  registrationClosedBanner: {
    defaultMessage:
      'Registration for {name} ended {relativeTime}. Join the discussion to cheer on other participants or visit the {takeActionLink} to find more opportunities to compete!',
    description:
      'Banner text for when registration has closed and the user had not joined',
    id: 'campaign.registrationClosedBanner',
  },
})

const Campaign = ({ isPreview }) => {
  const isMobile = useIsMobile()
  const [hasEarnedAchievement, setHasEarnedAchievement] = useState(false)

  const campaignId = useParams().id

  const { formatMessage } = intl

  const displayEndedCampaignBanner = (campaign) =>
    displayBanner({
      content: (
        <>
          {formatMessage(messages.campaignEndedBanner, {
            takeActionLink: <TakeActionLink isBannerLink />,
            type: campaignTypeTranslation(campaign.isCompetition),
          })}
        </>
      ),
      variant: 'info',
    })

  const displayRegistrationClosedBanner = (campaign) =>
    displayBanner({
      content: (
        <>
          {formatMessage(messages.registrationClosedBanner, {
            name: <b>{campaign.name}</b>,
            relativeTime: (
              <b>
                <RelativeTime date={campaign.registrationEnd} />
              </b>
            ),
            takeActionLink: <TakeActionLink isBannerLink />,
          })}
        </>
      ),
      variant: 'info',
    })

  return (
    <CampaignShowQuery
      campaignId={campaignId}
      error={
        <DocumentTitle
          title={formatMessage(sharedMessages.resourceNotFound, {
            resource: sharedTranslations.campaign,
          })}
        >
          <MissingResourcePage resourceName={sharedTranslations.campaign} />
        </DocumentTitle>
      }
      loader={<CenteredPaddedLoadingIndicator />}
    >
      {(campaignData) => {
        const campaign = new CampaignModel(campaignData)

        const handleAchievementEarn = () => setHasEarnedAchievement(true)

        if (campaign.hasEnded) {
          displayEndedCampaignBanner(campaign)
        } else if (campaign.status === CampaignUserStatus.MISSED_REGISTRATION) {
          displayRegistrationClosedBanner(campaign)
        }

        if (campaign.type === 'competition') {
          maybeDisplayUserTour('competitionPage')
        } else {
          maybeDisplayUserTour('campaignPage')
        }

        return (
          <DocumentTitle title={`${campaign.name} • ${campaign.type}`}>
            <>
              {!isPreview && (
                <SharedNavBreadcrumb currentLinkName={campaign.name} />
              )}
              {!isPreview &&
                (hasEarnedAchievement || getUrlParams().achievement) && (
                  <AchievementModal
                    description={campaign.achievement.description}
                    id={campaign.achievement.id}
                    imageUrl={campaign.achievement.imageUrl}
                    name={campaign.achievement.name}
                  />
                )}
              {!isPreview && isMobile ? (
                <CampaignLayoutMobile
                  campaign={campaign}
                  onAchievementEarn={handleAchievementEarn}
                />
              ) : (
                <CampaignLayoutDesktop
                  campaign={campaign}
                  isPreview={isPreview}
                  onAchievementEarn={handleAchievementEarn}
                />
              )}
            </>
          </DocumentTitle>
        )
      }}
    </CampaignShowQuery>
  )
}

Campaign.propTypes = {
  isPreview: PropTypes.bool,
}
Campaign.defaultProps = {
  isPreview: false,
}

export default Campaign
