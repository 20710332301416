import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import { Link } from 'react-router-dom'

// westyle
import InternalLink from 'src/common/InternalLink'
import Numbers from 'src/common/Numbers'
import HoursToGoBar from './HoursToGoBar'
import NumberWithTooltip from 'src/common/NumberWithTooltip'

const DollarsForDoers = ({
  d4dHoursUntilDonationEligible,
  d4dGrantAmount,
  d4dMaxDonationAmountPerYear,
  isFlexible,
  grantDollarsEarned,
  grantDollarsDonated,
  remainingFundsThisYear,
  volunteerHoursThisYear,
}) => {
  const hoursBeforeNextGrant =
    volunteerHoursThisYear % d4dHoursUntilDonationEligible === 0
      ? d4dHoursUntilDonationEligible
      : d4dHoursUntilDonationEligible -
        (volunteerHoursThisYear % d4dHoursUntilDonationEligible)

  const earnedAndNotUsed = grantDollarsEarned - grantDollarsDonated
  const availableToDonate =
    earnedAndNotUsed < remainingFundsThisYear
      ? earnedAndNotUsed
      : remainingFundsThisYear

  const earnedBasedOnMax =
    grantDollarsEarned < d4dMaxDonationAmountPerYear
      ? grantDollarsEarned
      : d4dMaxDonationAmountPerYear

  const canEarnAnotherGrant =
    earnedBasedOnMax + d4dGrantAmount <= d4dMaxDonationAmountPerYear

  return (
    <div>
      <h2 className="px-2">
        <FormattedMessage
          id="shared.dollarsForDoers"
          defaultMessage={'Dollars for Doers'}
        />
      </h2>
      <div
        className={`rounded w-4xl bg-white border-[1px] border-grayscale-3 mt-4 p-6 ${
          isFlexible ? 'xl:min-h-[19rem]' : 'xl:min-h-[11rem]'
        }`}
      >
        {isFlexible ? (
          <div className="grid col-span-2">
            <Numbers className="headline-1" currency="USD" style="currency">
              {availableToDonate > 0 ? availableToDonate : 0}
            </Numbers>
            <span className="label-xsmall-caps text-black-1">
              <FormattedMessage
                id="volunteering.availableToDonate"
                defaultMessage={'Available to Donate'}
              />
            </span>
          </div>
        ) : (
          <p className="mb-2">
            <FormattedMessage
              id="volunteering.dollarsForDoersIsDesignedToEncourage"
              values={{
                d4dGrantAmount,
                d4dHoursUntilDonationEligible,
              }}
              defaultMessage={
                'For every {d4dHoursUntilDonationEligible} hours you volunteer, {d4dGrantAmount} will be donated to the charity of your choice.'
              }
            />
          </p>
        )}
        <div className="grid col-span-2 mb-2 mt-1">
          <NumberWithTooltip
            title={
              <FormattedMessage
                id="volunteering.grantDollarsEarnedThisYear"
                defaultMessage="Grant dollars earned this year"
              />
            }
            tooltip={
              <FormattedMessage
                id="volunteering.volunteerWithAnyQualifying"
                defaultMessage="Grant dollars are earned by volunteering with qualifying charities. Please note that not all charities {qualifyingCharitiesLink} for Dollars for Doers."
                values={{
                  qualifyingCharitiesLink: (
                    <Link
                      to="/app/charity_search/dollars_for_doers"
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      qualify
                    </Link>
                  ),
                }}
              />
            }
            value={earnedBasedOnMax}
          />
          <NumberWithTooltip
            title={
              <FormattedMessage
                id="volunteering.grantDollarsDonatedThisYear"
                defaultMessage={'Grant dollars donated this year'}
              />
            }
            tooltip={
              <FormattedMessage
                id="volunteering.youCanSeeYourDonationHistory"
                defaultMessage="You can see your donation history on your Donation History page."
              />
            }
            value={grantDollarsDonated}
          />
        </div>
        {isFlexible && (
          <>
            {earnedBasedOnMax > 0 && canEarnAnotherGrant && (
              <InternalLink
                className="link-text-underlined text-smart-blue"
                href={`/app/charity_search/dollars_for_doers`}
              >
                <FormattedMessage
                  id="dollarsForDoers.donateYourGrantDollars"
                  defaultMessage="Donate your grant dollars"
                />
              </InternalLink>
            )}
            {canEarnAnotherGrant ? (
              <div className="bg-grayscale-4 mt-2 p-4">
                <span className="body-medium-regular">
                  <FormattedMessage
                    id="dollarsForDoers.earnGrantDollarsPer"
                    defaultMessage="Earn {amount} grant dollars per {hours} volunteer hours"
                    values={{
                      amount: (
                        <CurrencyFormat
                          className="body-medium-regular"
                          displayType={'text'}
                          value={d4dGrantAmount}
                          prefix={'$'}
                          fixedDecimalScale
                          decimalScale={2}
                        />
                      ),
                      hours: d4dHoursUntilDonationEligible,
                    }}
                  />
                </span>
                {hoursBeforeNextGrant && (
                  <HoursToGoBar
                    hoursBeforeNextGrant={hoursBeforeNextGrant}
                    d4dHoursUntilDonationEligible={
                      d4dHoursUntilDonationEligible
                    }
                    d4dGrantAmount={d4dGrantAmount}
                    isFlexible={isFlexible}
                  />
                )}
              </div>
            ) : (
              <div className="bg-grayscale-3 p-3">
                <span className="body-medium-regular">
                  <FormattedMessage
                    id="volunteering.flexibleMaxReached"
                    defaultMessage={
                      'Thank you for volunteering your time and donating your Dollars for Doers grants. You have reached the program maximum number of dollars donated for this calendar year.'
                    }
                  />
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

DollarsForDoers.propTypes = {
  availableToDonate: PropTypes.number,
  d4dGrantAmount: PropTypes.number,
  d4dHoursUntilDonationEligible: PropTypes.number,
  d4dMaxDonationAmountPerYear: PropTypes.number,
  isFlexible: PropTypes.bool,
  grantDollarsDonated: PropTypes.number,
  grantDollarsEarned: PropTypes.number,
  remainingFundsThisYear: PropTypes.number,
  volunteerHoursThisYear: PropTypes.number,
}

export default DollarsForDoers
