/* eslint-disable react/prop-types */
import React from 'react'

// westyle
import Numbers from 'src/common/Numbers'
import { FormattedMessage } from 'react-intl'

const VolunteerHours = ({ charity }) => {
  return (
    <span className="font-sans font-semibold text-base leading-6 tracking-normal text-white md:text-black-2">
      <FormattedMessage
        defaultMessage="{hours} hours "
        id="volunteering.hours"
        values={{
          hours: (
            <Numbers className="numbers-small text-white md:text-black-2">
              {charity.totalHours}
            </Numbers>
          ),
        }}
      />
    </span>
  )
}

export default VolunteerHours
