// Setup
import React, { forwardRef, useState } from 'react'
import { defineMessages } from 'react-intl'

// WeSpire
import DisableButton from 'components/ui/disable_button'
import { displayExceptionBanner } from 'redux/dispatchers'
import WeSpireAPI from 'utilities/wespire_api'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  signOut: {
    defaultMessage: 'sign out',
    id: 'signOutButton.signOut',
  },
})

/**
 * Button that will sign out the current user when clicked. If the sign out
 * request fails, it should display an error banner. While the request is
 * happening the button is disabled and display a loading indicator.
 */

// eslint-disable-next-line
const SignOutButton = forwardRef((props, ref) => {
  const { formatMessage } = intl
  const [isLoading, setIsLoading] = useState(false)

  const onSignOut = () => {
    setIsLoading(true)

    WeSpireAPI()
      .delete('/session')
      .then(({ data: { redirectUrl } }) => (window.location = redirectUrl))
      .catch(() => {
        setIsLoading(false)
        displayExceptionBanner({ operation: 'sign out' })
      })
  }

  return (
    <DisableButton
      {...props}
      data-test="logout"
      isLoading={isLoading}
      onClick={onSignOut}
      ref={ref}
      textCapitalized
    >
      {formatMessage(messages.signOut)}
    </DisableButton>
  )
})

export { SignOutButton }
