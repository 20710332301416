import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

// westyle
import DonationDetails from 'src/pages/DonationHistoryPage/components/DonationDetails'
import Numbers from 'src/common/Numbers'
import Pill from 'src/common/Pill'

const HeaderRow = () => {
  return (
    <div className="grid  grid-cols-12 xl:grid-cols-8 gap-1 hidden md:grid px-4 py-2 border-[1px] border-grayscale-3">
      <div className="col-span-2 xl:col-span-1 ml-15 pl-10">
        <span className="label-all-caps text-black-1">
          <FormattedMessage id="shared.date" defaultMessage={'Date'} />
        </span>
      </div>
      <div className="col-span-9 xl:col-span-6">
        <span className="label-all-caps text-black-1">
          <FormattedMessage
            id="donationForm.details"
            defaultMessage={'Donation details'}
          />
        </span>
      </div>
      <div className="col-span-1 text-end">
        <span className="label-all-caps text-black-1 pr-4">
          <FormattedMessage
            id="donationSuccess.amount"
            defaultMessage={'Amount'}
          />
        </span>
      </div>
    </div>
  )
}

const ExpandAndDate = ({ expandRow, status, transaction }) => {
  const dateInfo = () => {
    if (status === 'processing') {
      return 'PENDING'
    } else if (status === 'scheduled') {
      return new Date(transaction.scheduledDate).toLocaleDateString('en-US')
    }
    return new Date(transaction.donationDate).toLocaleDateString('en-US')
  }

  return (
    <>
      <div className="hidden md:inline-block">
        <button className="self-start material-symbols-rounded">
          {expandRow ? 'expand_more' : 'chevron_right'}
        </button>
      </div>
      <span className="body-large-semibold text-white md:text-black-2 md:px-4">
        {dateInfo()}
      </span>
    </>
  )
}

const CharityName = ({ charityName, matchedTransaction }) => {
  return (
    <>
      <span className="body-large-semibold pr-4">{charityName}</span>
      {matchedTransaction && matchedTransaction.status !== 'denied' && (
        <Pill>
          <span className="body-small-semibold">
            <FormattedMessage
              id="shared.matchGift"
              defaultMessage={'Match gift'}
            />
          </span>
        </Pill>
      )}
    </>
  )
}

const DonationPreview = ({ status, transaction }) => {
  const [expandRow, setExpandRow] = useState(false)

  const updateRowState = () =>
    setExpandRow((prevExpandRowOpen) => !prevExpandRowOpen)

  return (
    <div
      className="border-grayscale-3 md:grid grid-cols-12 xl:grid-cols-8 gap-1 md:pt-4 md:px-4 mb-0 w-4xl border-[1px]"
      onClick={updateRowState}
      key={transaction.id}
    >
      <div className="bg-black-3 md:bg-white col-span-2 xl:col-span-1 flex md:p-0 px-4 py-1">
        <ExpandAndDate
          expandRow={expandRow}
          status={status}
          transaction={transaction}
        />
      </div>
      <div className="flex justify-between col-span-10 xl:col-span-7 md:p-0 p-4">
        <div className="w-full">
          <div className="grid grid-cols-6 pb-4">
            <div className="col-span-5">
              <CharityName
                charityName={transaction.charityName}
                matchedTransaction={transaction.matchedTransaction}
              />
            </div>
            <div className="px-4 col-span-1 text-end">
              <DonationAmount donationAmount={transaction.donationAmount} />
            </div>
          </div>
          <DonationDetails
            status={status}
            expandRow={expandRow}
            transaction={transaction}
          />
        </div>
      </div>
    </div>
  )
}

const DonationAmount = ({ donationAmount }) => {
  return (
    <Numbers className="numbers-small" currency="USD" style="currency">
      {donationAmount}
    </Numbers>
  )
}

const DonationsTable = ({ status, transactions }) => {
  return (
    <div
      className="grid w-full rounded bg-white border-[1px] border-grayscale-3 mb-2"
      key={status}
    >
      <HeaderRow />
      {transactions.map((transaction) => (
        <DonationPreview status={status} transaction={transaction} />
      ))}
    </div>
  )
}

export default DonationsTable
