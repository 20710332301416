// Setup
import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import MgmtPanelFormPage from 'setup/application/form_page'
import { newRoleRoute } from './routes'
import { RoleList } from 'components/setup/group_leadership_roles/roles_list'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import useUpdatePosition from 'utilities/hooks/use_update_position'
import WeApollo from 'utilities/we_apollo'
import WeQuery from 'components/application/we_query'
import { getUrlParams } from 'utilities/get_url_params'
import { manageGroupRoute } from 'components/setup/shared/routes'

const { formatMessage } = intl

const messages = defineMessages({
  groupRolesHeading: {
    defaultMessage: '{group} Leadership Roles',
    id: 'groupLeadershipRoles.groupRolesHeading',
  },
  newLeadershipRole: {
    defaultMessage: 'New Leadership Role',
    id: 'groupLeadershipRoles.newLeadershipRole',
  },
  orderingDescription: {
    defaultMessage:
      'This is the list of leadership roles in the order that they will appear on employee-facing Group pages. You can change the order of the list using the ordering buttons on each leadership role.',
    id: 'groupLeadershipRoles.orderingDescription',
  },
  roleAdded: {
    defaultMessage: 'Role was successfully added.',
    id: 'groupLeadershipRoles.roleAdded',
  },
  rolesHeading: {
    defaultMessage: 'Ordered Leadership Roles',
    id: 'groupLeadershipRoles.rolesHeading',
  },
  rolesIndex: {
    defaultMessage: 'Role Index',
    id: 'groupLeadershipRoles.roleIndex',
  },
})

const groupsForBrands = gql`
  query groupForBrand($groupId: ID!) {
    group(id: $groupId) {
      id
      isChapter
      name
      slug
      parentGroup {
        id
        name
      }
    }
  }
`

const roleListQuery = gql`
  query roleListQuery($groupId: ID!) {
    group(id: $groupId) {
      id
      groupLeadershipRoles {
        title
        id
        leaders {
          id
          name
        }
        position
      }
    }
  }
`

const GroupLeadershipRolesIndex = ({ objectId }) => {
  const [ariaLiveText, executeMutation] = useUpdatePosition()

  const fromManagementPanel = getUrlParams().fromManagementPanel

  const handleUpdatePosition = ({ operator, recordId, recordName }) => {
    const refetchQueries = [
      {
        query: roleListQuery,
        variables: { groupId: objectId },
      },
    ]
    const variables = {
      klass: 'GroupLeadershipRole',
      operator,
      recordId,
    }

    executeMutation(recordName, refetchQueries, variables)
  }

  return (
    <WeApollo>
      <WeQuery
        error={<MissingResourcePage />}
        loader={
          <Stack space={4}>
            <SkeletonLoading height={75} />
            <SkeletonLoading className="px-4" height={300} />
          </Stack>
        }
        query={groupsForBrands}
        variables={{ groupId: objectId }}
      >
        {({
          data: {
            group: { isChapter, name, parentGroup },
          },
        }) => {
          const parentGroupId = parentGroup ? parentGroup.id : null

          return (
            <MgmtPanelFormPage
              contentHeaderChildren={
                <Button
                  className="flex-shrink-none ml-2"
                  color="primary"
                  data-test="create-role"
                  href={newRoleRoute({
                    groupId: objectId,
                    isChapter,
                    parentGroupId,
                    urlParams: { fromManagementPanel },
                  })}
                  variant="contained"
                >
                  {formatMessage(messages.newLeadershipRole)}
                </Button>
              }
              data-test="role-index-heading"
              isEdit
              resourceName={formatMessage(messages.groupRolesHeading, {
                group: isChapter ? `${parentGroup.name} - ${name}` : name,
              })}
              returnURL={manageGroupRoute({ groupId: objectId })}
              showBackButton
            >
              {() => (
                <Stack space={3}>
                  <Heading level={2} variant={2}>
                    {formatMessage(messages.rolesHeading)}
                  </Heading>
                  <p>{formatMessage(messages.orderingDescription)}</p>
                  <RoleList
                    ariaLiveText={ariaLiveText}
                    fromManagementPanel={fromManagementPanel}
                    groupId={objectId}
                    isChapter={isChapter}
                    onUpdatePositionClick={handleUpdatePosition}
                    parentGroupId={parentGroupId}
                    roleListQuery={roleListQuery}
                  />
                </Stack>
              )}
            </MgmtPanelFormPage>
          )
        }}
      </WeQuery>
    </WeApollo>
  )
}

GroupLeadershipRolesIndex.propTypes = {
  objectId: PropTypes.string.isRequired,
}

export default GroupLeadershipRolesIndex
