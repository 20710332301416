// Returns the maximum number of hours that a user could claim for PVH based on
// how many hours that they have left in their PVH balance (balance) and the
// number of hours of the volunteer event in question (hoursVolunteered). We
// round down to the nearest 15 Min interval.
// For example, if a user has a balance of 2 hours, and hoursVolunteered is 3,
// then this function will return 2.
// Another scenario, if a user has a balance of 2 hours, and hoursVounteered is 1.68
// it will return 1.75
const maximumPvhClaim = ({ balance, hoursVolunteered }) => {
  const numberToWorkWith = Math.min(balance, hoursVolunteered)

  if (numberToWorkWith === parseFloat(balance)) return numberToWorkWith

  const decimalPart = Number(
    (numberToWorkWith - Math.trunc(numberToWorkWith)).toFixed(2).substring(2)
  )
  // Round the decimal part to nearest 15 min interval (0.25 hours)
  return Math.trunc(numberToWorkWith) + Math.round(decimalPart / 25) * 0.25
}

export { maximumPvhClaim }
