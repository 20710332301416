// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Avatar, { AvatarSizes } from './avatar'

const AvatarList = ({ avatarSize, className, images }) =>
  images.length > 0 && (
    <ul
      className={`d-flex horizontal-list horizontal-list--squeezed | mb-0 | ${className}`}
    >
      {images.map((image, index) => (
        <li
          className="horizontal-list__item"
          data-test="user-avatar"
          key={`${index}-${image}`}
        >
          <Avatar image={image} size={avatarSize} withBorder />
        </li>
      ))}
    </ul>
  )

AvatarList.defaultProps = {
  avatarSize: 'md',
  className: '',
}

AvatarList.propTypes = {
  avatarSize: PropTypes.oneOf(Object.values(AvatarSizes)),
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default AvatarList
