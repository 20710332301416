// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { Heading } from 'components/ui/heading'
import { TextLink } from 'components/shared/text_link'

const FormWrapper = ({
  backLinkText,
  backLinkTo,
  children,
  description,
  heading,
  ...other
}) => (
  <div className="[ mt-5 mx-3 mxw-6 | mt-sm-6 mx-sm-auto ]" {...other}>
    <div className="mb-4">
      <div className="[ d-flex align-items-center flex-wrap justify-content-between ] [ px-3 | px-sm-4 ]">
        <Heading level={1}>{heading}</Heading>
        <TextLink
          className="fs-2 ml-3 py-2"
          data-test="form-wrapper-back-link"
          to={backLinkTo}
        >
          {backLinkText}
        </TextLink>
      </div>
      {description && <p className="my-3 px-3 | px-sm-4">{description}</p>}
    </div>
    {children}
  </div>
)

const { node, string } = PropTypes

FormWrapper.propTypes = {
  backLinkText: string.isRequired,
  backLinkTo: string.isRequired,
  children: node.isRequired,
  description: string,
  heading: string.isRequired,
}

FormWrapper.defaultProps = {
  description: undefined,
}

export { FormWrapper }
