// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

// WeSpire
import Divider from 'components/ui/divider'
import { GroupMember } from './group_member'
import { intl } from 'utilities/localization'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'

const messages = defineMessages({
  error: {
    defaultMessage: 'There was a problem loading your user details',
    id: 'currentUserSection.error',
  },
})

const CurrentUserSection = ({ visibleUser }) => {
  const { formatMessage } = intl

  const errorMessage = (
    <>
      <p className="p-3">{formatMessage(messages.error)}</p>
      <Divider className="mx-3" />
    </>
  )

  return (
    <WeQuery
      error={errorMessage}
      loader={<SkeletonLoading />}
      query={gql`
        query userData {
          currentUser {
            avatarUrl
            id
            name
            teams {
              id
              name
            }
          }
        }
      `}
    >
      {({ data: { currentUser } }) => {
        return (
          <>
            <GroupMember
              avatarUrl={currentUser.avatarUrl}
              data-test="user-details"
              id={currentUser.id}
              key={currentUser.id}
              listItemComponent="div"
              name={currentUser.name}
              teams={currentUser.teams}
              visibleUser={visibleUser}
            />
            <Divider className="mx-3" />
          </>
        )
      }}
    </WeQuery>
  )
}

CurrentUserSection.propTypes = {
  visibleUser: PropTypes.bool.isRequired,
}

export { CurrentUserSection }
