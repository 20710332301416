// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import Formsy from 'formsy-react'

// WeSpire
import { displayBanner } from 'redux/dispatchers'
import { displayModal } from 'redux/dispatchers'
import { EditableFileAttachment } from 'components/application/editable_image_attachment'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import MgmtPanelPage from 'setup/application/page'
import Stack from 'components/ui/stack'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormActionBarSecondaryButton from 'components/form/action_bar/secondary_button'
import FormFieldset from 'components/form/fieldset'
import FormFieldsetGroup from 'components/form/fieldset_group'
import FormLimitedTextField from 'components/form/limited_text_field'
import FormSelect from 'components/form/select'
import FormTextField from 'components/form/text_field'
import { sharedMessages } from 'components/shared/translations'

import {
  useAddResourceMutation,
  useArchiveResourceMutation,
  useRestoreResourceMutation,
  useUpdateResourceMutation,
} from './utils'
import { ConfirmationModal } from './confirmation_modal'

const { formatMessage } = intl

const messages = defineMessages({
  archiveButton: {
    defaultMessage: 'Archive',
    id: 'groupResourceForm.archiveButton',
  },
  backTo: {
    defaultMessage: 'Back to Resources & Files',
    id: 'groupResourceForm.backTo',
  },
  createTitle: {
    defaultMessage: 'Create New Resource - { groupName }',
    id: 'groupResourceForm.createTitle',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete this file?',
    id: 'groupResourceForm.deleteConfirmation',
  },
  editTitle: {
    defaultMessage: 'Edit Resource - { groupName }',
    id: 'groupResourceForm.editTitle',
  },
  errorNotification: {
    defaultMessage: 'Please fix the errors in the form.',
    id: 'groupResourceForm.errorNotification',
  },
  formTitleHeader: {
    defaultMessage: 'Add Resource or File',
    id: 'groupResourceForm.formTitleHeader',
  },
  isUrlError: {
    defaultMessage: 'Must be a valid URL',
    id: 'groupResourceForm.isUrlError',
  },
  oneResourceTypeError: {
    defaultMessage: 'You must only either add a link or upload a file',
    id: 'groupResourceForm.oneResourceTypeError',
  },
  OR: {
    defaultMessage: 'Or',
    id: 'groupResourceForm.OR',
  },
  resourceDescriptionField: {
    defaultMessage: 'Description',
    id: 'groupResourceForm.resourceDescriptionField',
  },
  resourceDescriptionHelperText: {
    defaultMessage:
      'A short description that will explain what this Resource or File is about, displayed to users on {groupName}.',
    id: 'groupResourceForm.resourceDescriptionHelperText',
  },
  resourceDetailsHeader: {
    defaultMessage: 'Details',
    id: 'groupResourceForm.resourceDetailsHeader',
  },
  resourceDocumentLabel: {
    defaultMessage: 'Document',
    id: 'groupResourceForm.resourceDocumentLabel',
  },
  resourceLinkField: {
    defaultMessage: 'Link URL',
    id: 'groupResourceForm.resourceLinkField',
  },
  resourceTitleField: {
    defaultMessage: 'Name',
    id: 'groupResourceForm.resourceTitleField',
  },
  resourceTitleHelperText: {
    defaultMessage:
      'The name of a Resource or File can be up to 256 characters.',
    id: 'groupResourceForm.resourceTitleHelperText',
  },
  resourceUploadHeader: {
    defaultMessage: 'Add URL or select file to upload',
    id: 'groupResourceForm.resourceUploadHeader',
  },
  resourceVisibilityField: {
    defaultMessage: 'Visibility',
    id: 'groupResourceForm.resourceVisibilityField',
  },
  resourceVisibilityHelperText: {
    defaultMessage:
      'Visibility determines which users are able to view this Resource or File on {groupName}.',
    id: 'groupResourceForm.resourceVisibilityHelperText',
  },
  restoreButton: {
    defaultMessage: 'Restore',
    id: 'groupResourceForm.restoreButton',
  },
  restoreConfirmation: {
    defaultMessage: 'Are you sure you want to restore this file?',
    id: 'groupResourceForm.restoreConfirmation',
  },
})

const visibilityMessages = defineMessages({
  allUsers: {
    defaultMessage: 'All {brandName} Users',
    id: 'groupResourceFormVisibilityOptions.allUsers',
  },
  leadersAndMembers: {
    defaultMessage: 'Group Leaders and Members of {groupName}',
    id: 'groupResourceFormVisibilityOptions.leadersAndMembers',
  },
  leadersAndMembersWithChapters: {
    defaultMessage: 'Group Leaders and Members of {groupName} and Chapters',
    id: 'groupResourceFormVisibilityOptions.leadersAndMembersWithChapters',
  },
  leadersOnly: {
    defaultMessage: 'Group Leaders',
    id: 'groupResourceFormVisibilityOptions.leadersOnly',
  },
})
const VISIBILITY_OPTIONS = ({ brandName, groupName, groupHasChapters }) => [
  {
    label: formatMessage(visibilityMessages.leadersOnly),
    value: 'leaders_only',
  },
  {
    label: formatMessage(
      groupHasChapters
        ? visibilityMessages.leadersAndMembersWithChapters
        : visibilityMessages.leadersAndMembers,
      { groupName }
    ),
    value: 'leaders_and_members',
  },
  {
    label: formatMessage(visibilityMessages.allUsers, { brandName }),
    value: 'all_users',
  },
]

const GroupResourceForm = ({
  brandName,
  cloudinary: cloudinaryConfig,
  group: { id: groupId, name_reporting: groupName },
  groupHasChapters,
  isEdit,
  resource,
}) => {
  const backPath = `/management_panel/groups_management/${groupId}/resources`
  const { handleOnAddResource, createLoading } = useAddResourceMutation({
    backPath,
    groupId,
    groupName,
    isEdit,
  })
  const { handleOnUpdateResource, updateLoading } = useUpdateResourceMutation({
    backPath,
    groupResourceId: resource?.id,
    isEdit,
  })
  const { handleOnArchiveResource, archiveLoading } =
    useArchiveResourceMutation({
      backPath,
      groupId,
      id: resource?.id,
    })

  const { handleOnRestoreResource, restoreLoading } =
    useRestoreResourceMutation({
      backPath,
      groupId,
      id: resource?.id,
    })

  const isSubmitting = createLoading || updateLoading

  const [
    ,
    //deleteDocument
    setDeleteDocument,
  ] = useState(false)
  const [isDocumentUploading, setIsDocumentUploading] = useState(false)

  const handleOnConfirm = (values) => {
    const reqParams = {
      description: values.description,
      resourceType: values.document ? 'document' : 'link',
      resourceUrl: values.document || values.link,
      title: values.title,
      visibility: values.visibility,
    }
    if (isEdit) {
      handleOnUpdateResource(reqParams)
    } else {
      handleOnAddResource(reqParams)
    }
  }

  const handleSubmit = (values) => {
    if (
      (values.document && values.link) ||
      (!values.document && !values.link)
    ) {
      displayBanner({
        content: formatMessage(messages.oneResourceTypeError),
        variant: 'error',
      })
      return
    }

    displayModal({
      options: {
        'data-test': 'resources-confirmation-modal',
        fullWidth: true,
        maxWidth: 'sm',
      },
      renderContent: () => (
        <ConfirmationModal
          onConfirm={() => {
            handleOnConfirm(values)
          }}
        />
      ),
    })
  }

  const handleInvalidSubmit = () => {
    displayBanner({
      content: formatMessage(messages.errorNotification),
      fullWidth: true,
      variant: 'error',
    })
  }

  const handleDelete = () => {
    const deleteMessage = formatMessage(messages.deleteConfirmation)
    const didNotConfirmDelete = !window.confirm(deleteMessage)

    if (archiveLoading || didNotConfirmDelete) {
      return
    }
    handleOnArchiveResource()
  }

  const handleRestore = () => {
    const restoreMessage = formatMessage(messages.restoreConfirmation)
    const didNotConfirmRestore = !window.confirm(restoreMessage)

    if (restoreLoading || didNotConfirmRestore) {
      return
    }
    handleOnRestoreResource()
  }

  const onFileUpload = () => {
    setDeleteDocument(false)
  }

  const onRemoveFile = () => {
    setDeleteDocument(true)
  }

  const onFileUploading = (isUploading) => {
    setIsDocumentUploading(isUploading)
  }

  const submitDisabled = isSubmitting || isDocumentUploading

  return (
    <Stack className="p-3" space={0}>
      <Link
        className="d-flex align-items-center mt-2 mb-4 text-black-3 fw-semi-bold"
        to={backPath}
      >
        <Icon className="fs-4 ml-1" iconName="arrow_left" title="Back" />
        <span className="fs-2">{formatMessage(messages.backTo)}</span>
      </Link>

      <Stack as={Paper} className="border border-black-5" space={0}>
        <Stack className="d-flex px-4 py-3 bg-black-6">
          <Heading
            className="fs-2 text-uppercase"
            data-test="all-members-label"
            level={3}
          >
            {formatMessage(messages.formTitleHeader)}
          </Heading>
        </Stack>

        <Formsy
          noValidate
          onInvalidSubmit={handleInvalidSubmit}
          onValidSubmit={handleSubmit}
        >
          <FormFieldsetGroup>
            <FormFieldset
              label={formatMessage(messages.resourceDetailsHeader)}
              variant="shaded"
            >
              <div className="row">
                <div className="col">
                  <FormLimitedTextField
                    label={formatMessage(messages.resourceTitleField)}
                    maxLength={256}
                    name="title"
                    required
                    textFieldProps={{
                      inputProps: {
                        'aria-describedby': 'title-helper',
                      },
                    }}
                    value={resource?.title || ''}
                  />
                </div>
                <Stack className="col">
                  <p id="description-helper">
                    {formatMessage(messages.resourceTitleHelperText)}
                  </p>
                </Stack>
              </div>
              <div className="row">
                <div className="col">
                  <FormLimitedTextField
                    label={formatMessage(messages.resourceDescriptionField)}
                    maxLength={1000}
                    multiline
                    name="description"
                    required
                    textFieldProps={{
                      inputProps: {
                        'aria-describedby': 'description-helper',
                      },
                    }}
                    value={resource?.description || ''}
                  />
                </div>
                <Stack className="col">
                  <p id="description-helper">
                    {formatMessage(messages.resourceDescriptionHelperText, {
                      groupName,
                    })}
                  </p>
                </Stack>
              </div>

              <div className="row">
                <div className="col">
                  <FormSelect
                    hideBlankOption
                    name="visibility"
                    options={VISIBILITY_OPTIONS({
                      brandName,
                      groupHasChapters,
                      groupName,
                    })}
                    required
                    selectProps={{
                      'aria-describedby': 'visibility-helper',
                    }}
                    textFieldProps={{
                      label: formatMessage(messages.resourceVisibilityField),
                    }}
                    value={resource?.visibility}
                  />
                </div>
                <Stack className="col">
                  <p id="description-helper">
                    {formatMessage(messages.resourceVisibilityHelperText, {
                      groupName,
                    })}
                  </p>
                </Stack>
              </div>
            </FormFieldset>

            <FormFieldset
              label={formatMessage(messages.resourceUploadHeader)}
              variant="shaded"
            >
              <Stack space={2}>
                <div className="row">
                  <Stack className="col" space={1}>
                    <label className="fs-2 fw-semi-bold mb-1" id="link-label">
                      {formatMessage(messages.resourceLinkField)}
                    </label>
                    <FormTextField
                      name="link"
                      textFieldProps={{
                        placeholder: 'https://',
                      }}
                      validationErrors={{
                        isUrl: formatMessage(messages.isUrlError),
                      }}
                      validations={{ isUrl: true }}
                      value={resource?.link || ''}
                    />
                  </Stack>
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    <p className="fs-3 text-black-2">
                      {formatMessage(messages.OR)}
                    </p>
                  </div>
                  <Stack className="col" space={1}>
                    <label
                      className="fs-2 fw-semi-bold mb-1"
                      id="document-label"
                    >
                      {formatMessage(messages.resourceDocumentLabel)}
                    </label>
                    <EditableFileAttachment
                      cloudinaryConfig={{
                        ...cloudinaryConfig,
                        folder: 'group_resources',
                        resourceType: 'raw',
                      }}
                      customAccept=".xls,.xlsx,.pdf,.png,.gif,.doc,.docx,.ppt,.pptx,.jpg,.jpeg"
                      customHeight="54px"
                      imageClassName="w-100"
                      imageHeight="54"
                      imageWidth="400"
                      name="document"
                      onFileUpload={onFileUpload}
                      onFileUploading={onFileUploading}
                      onRemoveFile={onRemoveFile}
                      value={resource?.document?.url || ''}
                    />
                  </Stack>
                </div>
              </Stack>
            </FormFieldset>

            <FormActionBar backPath={backPath} className="p-5">
              {isEdit && !resource?.deleted_at && (
                <FormActionBarSecondaryButton
                  data-test="archive-resource-button"
                  disabled={submitDisabled}
                  onClick={handleDelete}
                >
                  {formatMessage(messages.archiveButton)}
                </FormActionBarSecondaryButton>
              )}
              {isEdit && resource?.deleted_at && (
                <FormActionBarSecondaryButton
                  data-test="restore-resource-button"
                  disabled={submitDisabled}
                  onClick={handleRestore}
                >
                  {formatMessage(messages.restoreButton)}
                </FormActionBarSecondaryButton>
              )}
              <FormActionBarSubmitButton
                disabled={submitDisabled}
                isSubmitting={isSubmitting}
              >
                {isEdit
                  ? intl.formatMessage(sharedMessages.save)
                  : intl.formatMessage(sharedMessages.create)}
              </FormActionBarSubmitButton>
            </FormActionBar>
          </FormFieldsetGroup>
        </Formsy>
      </Stack>
    </Stack>
  )
}

const GroupResourceFormPage = (props) => {
  const isEdit = Boolean(props?.resource?.id)
  const groupName = props?.group?.name_reporting
  return (
    <MgmtPanelPage
      data-test="group-management-index-heading"
      title={formatMessage(messages[isEdit ? 'editTitle' : 'createTitle'], {
        groupName,
      })}
    >
      {() => <GroupResourceForm {...props} />}
    </MgmtPanelPage>
  )
}

const propTypes = {
  brandName: PropTypes.string.isRequired,
  cloudinary: PropTypes.shape({
    cloudName: PropTypes.string.isRequired,
    uploadPreset: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.number,
    name_reporting: PropTypes.string,
  }).isRequired,
  groupHasChapters: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  resource: PropTypes.shape({
    deleted_at: PropTypes.string,
    description: PropTypes.string,
    document: PropTypes.object,
    id: PropTypes.number,
    link: PropTypes.string,
    title: PropTypes.string,
    visibility: PropTypes.string,
  }),
}
const defaultProps = {
  isEdit: false,
  resource: null,
}

GroupResourceForm.propTypes = propTypes
GroupResourceFormPage.propTypes = propTypes

GroupResourceForm.defaultProps = defaultProps
GroupResourceFormPage.defaultProps = defaultProps

export default GroupResourceFormPage
