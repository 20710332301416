// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Button from '@material-ui/core/Button'
import cx from 'classnames'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { channelSlugUrlForLocale } from 'components/activities/utils'
import ExpandIcon from 'components/ui/expand_icon'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { LIST_TYPES } from 'components/setup/channel_manager/constants'
import { sharedMessages } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'

const Channel = ({
  channel,
  isDragging,
  isSortable,
  listId,
  onChannelExpand,
  renderActivity,
  updateChannelActivities,
}) => {
  const handleChannelExpandChange = () => {
    onChannelExpand(channel.id)
  }

  const handleStopPropagation = (event) => {
    event.stopPropagation()
  }

  const handleSortByDate = (activities) => {
    return activities.sort((a, b) => {
      if (a.startsAt > b.startsAt) return 1 // Starts sooner, move up
      if (a.startsAt < b.startsAt) return -1 // Starts later, move down

      if (b.endsAt === null) return -1 // End is ongoing, move down

      if (a.endsAt > b.endsAt) return 1 // Ends sooner, move up
      if (a.endsAt < b.endsAt) return -1 // Ended less recently, move down
    })
  }

  const handleToggleSortByDate = () => {
    updateChannelActivities(channel.id, handleSortByDate)
  }

  const channelExpanded = !!channel.expanded
  const inPublishedList = listId === LIST_TYPES.published
  const summaryClasses = cx(
    `${isSortable ? 'cursor-grab' : 'cursor-auto'}`,
    'border-left-thick-black-4-hover',
    `border-left-thick-${channelExpanded ? 'black-4' : 'transparent'}`
  )
  const expandIconText = `${channelExpanded ? 'Hide' : 'View'} Activities`
  const viewPath = channelSlugUrlForLocale(channel, channel.defaultLocale)
  const hasActivities = channel.activities.length > 0

  return (
    <div className={cx({ 'op-30': isDragging })} data-test="channel">
      <Accordion
        className={cx('rounded-0', { 'cursor-grab': isSortable })}
        expanded={channelExpanded}
        onChange={handleChannelExpandChange}
      >
        <AccordionSummary
          className={summaryClasses}
          expandIcon={<ExpandIcon title={expandIconText} />}
        >
          <div className="d-flex align-items-center justify-content-between | py-1 w-100">
            <div className="d-flex align-items-center">
              {isSortable && (
                <Icon
                  className="cursor-grab fs-5 mr-3 text-black-3"
                  iconName="drag_handle"
                  title="Move Channel"
                />
              )}
              <h3 className="fs-3 fw-semi-bold lh-md m-0">
                {channel.title} <BulletSpacer className="pr-0 text-black-3" />
                <span
                  className="fs-2 fw-regular m-1 text-black-3"
                  data-test={`channel-${channel.id}-activity-count`}
                >
                  {intl.formatMessage(sharedMessages.activitiesWithCount, {
                    count: channel.activities.length,
                  })}
                </span>
                <GraphQLFeatureFlag
                  error={null}
                  featureDisabledError={null}
                  featureName="translation"
                  loader={null}
                >
                  <BulletSpacer className="pr-0 text-black-3" />
                  <span
                    className="fs-2 fw-regular m-1 text-black-3"
                    data-test={`channel-${channel.id}-locale-count`}
                  >
                    {channel.localesLabel}
                  </span>
                </GraphQLFeatureFlag>
              </h3>
            </div>
            <div>
              {!isSortable && inPublishedList && (
                <Link
                  className="pr-3"
                  data-test={`channel-${channel.id}-preview-link`}
                  onClick={handleStopPropagation}
                  to={viewPath}
                >
                  <Icon
                    className={Channel.actionButtonClassName}
                    iconName="visibility"
                    title="View Channel"
                  />
                </Link>
              )}
              {!isSortable && (
                <Link
                  data-test={`channel-${channel.id}-edit-link`}
                  onClick={handleStopPropagation}
                  to={channel.editPath}
                >
                  <Icon
                    className={Channel.actionButtonClassName}
                    iconName="edit"
                    title="Edit Channel"
                  />
                </Link>
              )}
            </div>
          </div>
        </AccordionSummary>
        {channelExpanded && (
          <Stack
            as={AccordionDetails}
            className="[ flex-column ] [ bg-off-white border-left-thick-black-4 border-bottom border-top py-3 ]"
            space={2}
          >
            {isSortable && hasActivities && (
              <Button className="ml-auto" onClick={handleToggleSortByDate}>
                Sort By Date
              </Button>
            )}
            <div>
              {hasActivities ? (
                <>{channel.activities.map(renderActivity)}</>
              ) : (
                <p className="m-0 text-center">
                  There are no running or scheduled Activities in this Channel.
                </p>
              )}
            </div>
          </Stack>
        )}
      </Accordion>
    </div>
  )
}

Channel.propTypes = {
  channel: PropTypes.shape({
    activities: PropTypes.array.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    editPath: PropTypes.string.isRequired,
    enabledLocales: PropTypes.array.isRequired,
    expanded: PropTypes.bool,
    id: PropTypes.number.isRequired,
    localesLabel: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isDragging: PropTypes.bool,
  isSortable: PropTypes.bool,
  listId: PropTypes.string.isRequired,
  onChannelExpand: PropTypes.func.isRequired,
  renderActivity: PropTypes.func.isRequired,
  updateChannelActivities: PropTypes.func.isRequired,
}

Channel.defaultProps = {
  isDragging: false,
  isSortable: false,
}

Channel.actionButtonClassName = 'fs-4 text-black-3'

export default Channel
