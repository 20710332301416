// Vendor
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'

import { donationHistoryMessages } from 'components/donation_history/utilities/messages'
import { sharedTranslations } from 'components/shared/translations'

export const MatchedTransaction = ({
  matchedAmount,
  requestedAmount,
  status,
}) => (
  <>
    <div className="d-sm-flex fs-1 mt-md-3 text-capitalize">
      {donationHistoryMessages.matchedTransaction}
    </div>
    <div className="[ row flex-column | flex-sm-row ] [ fs-1 my-0 ]">
      <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ]">
        <div className="text-black-3 text-uppercase">
          {donationHistoryMessages.requestedAmount}
        </div>
        <div className="ml-1" data-test="match-requested-amount">
          <FormattedNumber
            currency="USD"
            style="currency"
            value={requestedAmount}
          />
        </div>
      </div>
      <div className="[ d-flex col-sm-2 col-lg-2 ] [ mt-2 ]">
        <div className="text-black-3 text-uppercase">
          {donationHistoryMessages.matchedAmount}
        </div>
        <div className="ml-1" data-test="match-approved-amount">
          <FormattedNumber
            currency="USD"
            style="currency"
            value={matchedAmount}
          />
        </div>
      </div>
      <div className="[ d-flex col-sm-2 ] [ mt-2 ]" data-test="match-status">
        <div className="text-black-3 text-uppercase">
          {sharedTranslations.statusLabel}:
        </div>
        <div className="ml-1">{status}</div>
      </div>
    </div>
  </>
)

MatchedTransaction.propTypes = {
  matchedAmount: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
}
