// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import gql from 'graphql-tag'
import {
  LoadingIndicator,
  LoadingIndicatorSize,
} from 'components/ui/loading_indicator'
import Paper from '@material-ui/core/Paper'

// WeSpire
import WeQuery from 'components/application/we_query'

const query = gql`
  query cloudinaryConfig {
    cloudinaryConfig {
      cloudName
      uploadPreset
    }
  }
`

const CloudinaryQuery = ({ children, error, loader }) => (
  <WeQuery error={error} loader={loader} query={query}>
    {({ data }) => children(data.cloudinaryConfig)}
  </WeQuery>
)

CloudinaryQuery.propTypes = {
  children: PropTypes.func.isRequired,
  error: PropTypes.element,
  loader: PropTypes.element,
}

CloudinaryQuery.defaultProps = {
  error: null,
  loader: (
    <Paper className="content-center size-avatar-xl">
      <LoadingIndicator size={LoadingIndicatorSize.SMALL} />
    </Paper>
  ),
}

export default CloudinaryQuery
