// Vendor
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs } from '@material-ui/core'
import cx from 'classnames'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import { ActionModal } from './action_modal'
import CampaignAbout from './about'
import CampaignHero from './hero'
import { CampaignLeaderboard } from './leaderboard'
import { CampaignModel } from './campaign_model'
import { sharedTranslations } from 'components/shared/translations'
import { getUrlParams } from 'utilities/get_url_params'
import { Heading } from 'components/ui/heading'
import { hideModal } from 'redux/dispatchers'
import LinkedDevices from './linked_devices'

const tabs = [
  { label: sharedTranslations.details, value: 'Details' },
  { label: sharedTranslations.actions, value: 'Actions' },
]

export class CampaignLayoutMobile extends Component {
  state = {
    activeTab: 'Details',
  }

  componentDidMount() {
    if (getUrlParams().action) {
      this.setState({ activeTab: 'Actions' })
    }
  }

  handleChangeCampaignTab = (_event, activeTab) => this.setState({ activeTab })
  handleOnActionComplete = () => {
    hideModal()
    this.setState({ activeTab: 'Details' })
  }

  render() {
    const { activeTab } = this.state
    const { campaign, onAchievementEarn } = this.props

    return (
      <>
        <div
          className="position-relative"
          data-test="campaign-layout-mobile"
          id="campaign-layout-mobile"
        >
          <Tabs
            className="bg-white elevation-3"
            onChange={this.handleChangeCampaignTab}
            textColor="secondary"
            value={activeTab}
            variant="fullWidth"
          >
            {tabs.map((tab) => (
              <Tab
                data-test="campaign-tab"
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <>
            <div className={cx({ 'd-none': activeTab !== 'Details' })}>
              <CampaignHero campaign={campaign} />
              <CenteredContent className="position-relative px-3" pull>
                <CampaignAbout campaign={campaign} />
                {campaign.isCompetition && campaign.hasStarted && (
                  <CampaignLeaderboard
                    campaignId={campaign.id}
                    teamSets={campaign.leaderboardTeamSets}
                  />
                )}
                {campaign.isDeviceCompatible && (
                  <LinkedDevices
                    campaignType={campaign.type}
                    hasActionWithDisabledManualLogging={
                      campaign.hasActionWithDisabledManualLogging
                    }
                  />
                )}
              </CenteredContent>
            </div>
            <div className={cx({ 'd-none': activeTab !== 'Actions' })}>
              {campaign.acts.map((action) => (
                <ActionModal
                  action={action}
                  campaign={campaign}
                  key={action.id}
                  onAchievementEarn={onAchievementEarn}
                  onActionComplete={this.handleOnActionComplete}
                />
              ))}
            </div>
          </>
        </div>
      </>
    )
  }
}

CampaignLayoutMobile.propTypes = {
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  onAchievementEarn: PropTypes.func.isRequired,
}
