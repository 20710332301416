// Setup
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

//Vendor
import { withFormsy } from 'formsy-react'
import { defineMessages } from 'react-intl'
import cx from 'classnames'
import voca from 'voca'
import Chip from '@material-ui/core/Chip'

// WeSpire
import TextField from '@material-ui/core/TextField'
import GoogleAutocompleteAddressField from 'components/form/google_autocomplete_address_field'
import TextFieldValidations from 'components/form/text_field_validations'
import { intl } from 'utilities/localization'

const MIN_CHARS = 3
const SEARCH_DEBOUNCE = 400
const DEFAULT_ADDRESS_FIELDS = [
  'houseNumber',
  'street',
  'city',
  'state',
  'postalCode',
  'country',
]

const messages = defineMessages({
  cityRequired: {
    defaultMessage: 'City is required',
    id: 'GoogleAddressAutocomplete.cityRequired',
  },
  countryRequired: {
    defaultMessage: 'Country is required',
    id: 'GoogleAddressAutocomplete.countryRequired',
  },
  editAddress: {
    defaultMessage: 'Edit address',
    id: 'GoogleAddressAutocomplete.editAddress',
  },
  houseNumberRequired: {
    defaultMessage: 'House number is required',
    id: 'GoogleAddressAutocomplete.houseNumberRequired',
  },
  postalCodeRequired: {
    defaultMessage: 'Postal code is required',
    id: 'GoogleAddressAutocomplete.postalCodeRequired',
  },
  stateRequired: {
    defaultMessage: 'State is required',
    id: 'GoogleAddressAutocomplete.stateRequired',
  },
  streetRequired: {
    defaultMessage: 'Street is required',
    id: 'GoogleAddressAutocomplete.streetRequired',
  },
})

const isNotEmpty = (value) => !voca.isEmpty(voca.trim(value))

const getAddressValues = (address, fields) => {
  const newAddress = {}
  fields.forEach((field) => {
    newAddress[field] = address[field]
  })
  return newAddress
}

function combineAddress(address) {
  if (address) {
    return [
      `${address.houseNumber ? address.houseNumber : ''} ${
        address.street ? address.street : ''
      }`,
      address.city,
      address.state,
      address.postalCode,
      address.country,
    ]
      .filter(isNotEmpty)
      .join(', ')
  } else {
    return false
  }
}

function isValidAddress(address, fields) {
  const errors = []

  if (address) {
    fields.forEach((field) => {
      if (voca.isEmpty(voca.trim(address[field]))) {
        errors.push(intl.formatMessage(messages[`${field}Required`]))
      }
    })
  }

  return errors
}

const GoogleAddressAutocomplete = (props) => {
  const {
    className,
    name,
    setValue,
    textFieldProps,
    value,
    validateAddressFields,
    autocompletionRequest,
  } = props

  const [addressSelected, setAddressSelected] = useState('')
  const [editAddress, setEditAddress] = useState(false)
  let textFieldRef = useRef()

  const handleSelection = (address) => {
    setAddressSelected(address)
    setValue({
      address: address.parts,
      addressLabel: address.label,
      suggestionLabel: address.label,
    })
    setEditAddress(false)
    textFieldRef.current.focus()
  }

  const handleClearAddress = () => {
    setValue(null)
    setEditAddress(true)
  }

  const addressFormatted = combineAddress(
    value && getAddressValues(value['address'], validateAddressFields)
  )
  const addressErrors = isValidAddress(
    value && value['address'],
    validateAddressFields
  )

  const textField = (
    <TextField
      fullWidth
      InputLabelProps={{
        className: 'text-sentence',
      }}
      inputProps={{
        className: 'd-inline-flex col',
      }}
      InputProps={{
        autoComplete: 'off',
        className: 'flex-wrap p-2',
        'data-test': 'address-formatted',
        readOnly: true,
        startAdornment: (
          <Chip
            className="m-1"
            key={addressFormatted}
            label={addressFormatted}
            onDelete={handleClearAddress}
          />
        ),
      }}
      inputRef={(el) => {
        textFieldRef.current = el
      }}
      label={textFieldProps.label}
      name={name}
      variant="outlined"
    />
  )

  if (addressFormatted && !editAddress && addressErrors.length === 0) {
    return textField
  } else {
    const textValidationProps = { ...props, value: addressFormatted }

    return (
      <TextFieldValidations {...textValidationProps}>
        {(error, helperText, label) => (
          <div
            className={cx({
              'address-autocomplete-error': error || addressErrors.length > 0,
            })}
          >
            <GoogleAutocompleteAddressField
              autocompletionRequest={autocompletionRequest}
              className={className}
              debounce={SEARCH_DEBOUNCE}
              label={label}
              minLengthAutocomplete={MIN_CHARS}
              name={name}
              onSelection={handleSelection}
              selectedItem={addressSelected}
            />
            {error && <p className="MuiFormHelperText-root">{helperText}</p>}
            {addressErrors && addressErrors.length > 0 && (
              <ul className="address-autocomplete-error-list">
                {addressErrors.map((addressError) => (
                  <li key={addressError}>{addressError}</li>
                ))}
              </ul>
            )}
            <p className="MuiFormHelperText-root MuiFormHelperText-contained">
              <span className="d-flex | lh-md">
                {textFieldProps.helperText}
              </span>
            </p>
          </div>
        )}
      </TextFieldValidations>
    )
  }
}

GoogleAddressAutocomplete.defaultProps = {
  className: null,
  fullAddress: false,
  required: false,
  textFieldProps: {},
  validateAddressFields: DEFAULT_ADDRESS_FIELDS,
  value: '',
}

GoogleAddressAutocomplete.propTypes = {
  className: PropTypes.string,
  fullAddress: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  textFieldProps: PropTypes.object,
  validateAddressFields: PropTypes.array,
  value: PropTypes.any,
}

export { GoogleAddressAutocomplete }
export default withFormsy(GoogleAddressAutocomplete)
