// Vendor
import React from 'react'
import Paper from '@material-ui/core/Paper'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'

// WeSpire
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import WeQuery from 'components/application/we_query'
import { ourImpactTranslations } from 'components/our_impact/shared_translations'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'

const brandStatsQuery = gql`
  query brandStatsQuery {
    brand {
      id
      totalActionsCompleted
      totalHoursVolunteered
      totalParticipants
      totalParticipatingTeams
    }
  }
`

const messages = defineMessages({
  companyImpact: {
    defaultMessage: 'All Time Company Impact',
    description:
      'Heading of area showing different company wide statistics, like # of actions completed and hours volunteered.',
    id: 'ourImpactStats.companyImpact',
  },
})

export const OurImpactStats = () => {
  const formatMessage = intl.formatMessage

  const statMessages = {
    totalActionsCompleted: sharedTranslations.totalActionsCompleted,
    totalHoursVolunteered: sharedTranslations.totalHoursVolunteered,
    totalParticipants: sharedTranslations.participants,
    totalParticipatingTeams: ourImpactTranslations.participatingTeams,
  }

  return (
    <Stack as="section" space={3}>
      <Heading className="px-3" level={2}>
        {formatMessage(messages.companyImpact)}
      </Heading>
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={formatMessage(messages.companyImpact)}
          />
        }
        loader={<SkeletonLoading height={116} />}
        query={brandStatsQuery}
      >
        {({ data: { brand } }) => (
          <>
            <Paper>
              <Stack
                className="[ d-flex flex-column | flex-md-row ] [ p-4 row ]"
                space={{ _: 4, md: 0 }}
              >
                {Object.entries(statMessages).map(
                  ([stat, label]) =>
                    brand[stat] !== null && (
                      <Stat
                        amount={Math.floor(brand[stat])}
                        className="[ col-md-3 ]"
                        key={stat}
                        label={label}
                        size={7}
                      />
                    )
                )}
              </Stack>
            </Paper>
          </>
        )}
      </WeQuery>
    </Stack>
  )
}
