import { defineMessages } from 'react-intl'

import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { stringCleanup } from '../string_cleanup'

const { formatMessage } = intl

export const messages = defineMessages({
  achievementDescription: {
    defaultMessage: 'Achievement Description',
    id: 'achievementsCsvData.achievementDescription',
  },
  achievementId: {
    defaultMessage: 'Achievement ID',
    id: 'achievementsCsvData.achievementId',
  },
  achievementName: {
    defaultMessage: 'Achievement Name',
    id: 'achievementsCsvData.achievementName',
  },
  activityLink: {
    defaultMessage: 'Activity Link',
    id: 'achievementsCsvData.activityLink',
  },
  campaignEndDate: {
    defaultMessage: 'Campaign End Date',
    id: 'achievementsCsvData.campaignEndDate',
  },
  campaignId: {
    defaultMessage: 'Campaign ID',
    id: 'achievementsCsvData.campaignId',
  },
  campaignName: {
    defaultMessage: 'Campaign Name',
    id: 'achievementsCsvData.campaignName',
  },
  campaignPointsType: {
    defaultMessage: 'Campaign Points',
    id: 'achievementsCsvData.campaignPointsType',
  },
  campaignStartDate: {
    defaultMessage: 'Campaign Start Date',
    id: 'achievementsCsvData.campaignStartDate',
  },
  pointsOnGrantingAchievement: {
    defaultMessage: 'Points on Granting Achievement',
    id: 'achievementsCsvData.pointsOnGrantingAchievement',
  },
  pointsRequiredToEarnAchievement: {
    defaultMessage: 'Points Required to Earn Achievement',
    id: 'achievementsCsvData.pointsRequiredToEarnAchievement',
  },
})

export const achievementsCsvData = (achievements) =>
  achievements.map((achievement) => ({
    [formatMessage(messages.achievementId)]: achievement.id,
    [formatMessage(messages.achievementName)]: achievement.name,
    [formatMessage(messages.achievementDescription)]: stringCleanup(
      achievement.description
    ),
    [formatMessage(messages.campaignId)]: achievement.campaignId,
    [formatMessage(messages.campaignName)]: achievement.campaignName,
    [formatMessage(messages.campaignPointsType)]:
      achievement.campaignPointsType || '',
    [formatMessage(
      messages.activityLink
    )]: `${window.location.origin}/management_panel/campaigns/${achievement.campaignId}/edit?step=achievements`,
    [formatMessage(messages.pointsOnGrantingAchievement)]: achievement.points,
    [formatMessage(messages.pointsRequiredToEarnAchievement)]:
      achievement.campaignCompletionPoints,
    [formatMessage(messages.campaignStartDate)]: achievement.campaignStartDate,
    [formatMessage(messages.campaignEndDate)]: achievement.campaignEndDate,
    [sharedTranslations.channelLabel]: achievement.campaignChannelName,
  }))
