// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import ActivitiesEmptyState from 'components/activities/empty_state'
import EventRegistrationMonthGroup from 'components/events/registration_opportunities/month_group'
import { getTimeZoneLabel } from 'utilities/date_formatter'
import {
  groupByMonth,
  sortByDate,
} from 'components/events/registration_opportunities/utils'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  noEvents: {
    defaultMessage: 'There are currently no Events available in this Channel.',
    id: 'registrationOpportunities.noEvents',
  },
  timeZoneExplanation: {
    defaultMessage: 'All times displayed in {timeZoneLabel}',
    id: 'registrationOpportunities.timeZoneExplanation',
  },
})

const EventRegistrationOpportunities = ({ opportunities }) => {
  const sorted = sortByDate(opportunities)
  const months = groupByMonth(sorted)
  const { formatMessage } = intl
  const timeZoneExplanation = formatMessage(messages.timeZoneExplanation, {
    timeZoneLabel: getTimeZoneLabel(),
  })

  return months.length > 0 ? (
    <div className="position-relative">
      <p className="absolute-top-right my-0 | show-sm-up">
        {timeZoneExplanation}
      </p>
      <p className="px-3 | hide-sm-up">{timeZoneExplanation}</p>

      {months.map(({ dateTime, days, label }) => (
        <EventRegistrationMonthGroup
          dateTime={dateTime}
          days={days}
          key={dateTime}
          label={label}
        />
      ))}
    </div>
  ) : (
    <ActivitiesEmptyState
      testName="events-list-empty-state"
      text={formatMessage(messages.noEvents)}
    />
  )
}

EventRegistrationOpportunities.propTypes = {
  opportunities: PropTypes.arrayOf(
    PropTypes.shape({
      availableSpotsCount: PropTypes.number,
      endsAt: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      eventUrl: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      startsAt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default EventRegistrationOpportunities
