import React from 'react'
import { FormattedMessage } from 'react-intl'
import CharityCard from './CharityCard'
import Button from 'src/common/button/Button'
import PropTypes from 'prop-types'

export default function CharitySearchResults({
  charities,
  loading,
  count,
  loadMore,
  displayedCharities,
  searchStatusText,
  source
}) {
  return (
    <>
      <div className="mt-4">
        {count > 100 && (
          <div className="fs-1 fs-sm-2 fw-semi-bold mt-2">
            <FormattedMessage
              id="charities.refineResults"
              values={{ count }}
              defaultMessage="Refine your reuslts"
            />
          </div>
        )}
        <span>{searchStatusText()}</span>
        {!loading &&
          displayedCharities &&
          displayedCharities.map((charity) => {
            const isGrantEligible = charity.grantEligible && source === "dollars_for_doers"

            return (
              isGrantEligible || source !== "dollars_for_doers" ? (
                <CharityCard key={charity.ein} charity={charity} source={source} />
              ) : null
            )
          })}
      </div>
      <div>
        {!loading &&
          charities &&
          displayedCharities.length < charities.length && (
            <Button
              className="mt-4 px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-smart-blue hover:bg-smart-blue"
              onClick={loadMore}
              type="button"
            >
              <FormattedMessage
                id="charities.loadMore"
                defaultMessage={'Load more'}
              />
            </Button>
          )}
      </div>
    </>
  )
}

CharitySearchResults.propTypes = {
  charities: PropTypes.arrayOf(PropTypes.object).isRequired,
  count: PropTypes.number.isRequired,
  displayedCharities: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  searchStatusText: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired
}
