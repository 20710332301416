import gql from 'graphql-tag'

export const DONATION_RECEIPT_QUERY = gql`
  query donationReceiptQuery($transactionId: ID!) {
    currentUser {
      givingaUser {
        id
        transaction(transactionId: $transactionId) {
          activityId
          charityName
          currency
          donationDate
          donationAmount
          ein
          id
          matchedTransaction {
            matchedAmount
            requestedAmount
          }
        }
      }
      id
      name
    }
    donationMatchingEnabled
  }
`

export const GIVING_ACTIVITY = gql`
  query givingActivity($id: ID!) {
    givingActivity(id: $id) {
      id
      name
    }
  }
`
