// Setup
import React from 'react'
import { defineMessages, FormattedNumber } from 'react-intl'
import { useQuery, NetworkStatus } from '@apollo/client'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { undecorated as Select } from 'components/form/select'
import { statusFilterOptions, orderFilterLabelOptions } from './utils'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { LoadMoreButton } from 'components/shared/load_more_button'

import { RetroactiveMatchRequestRow } from './row'

import { LIST_RETROACTIVE_MATCH_REQUESTS_QUERY } from '../utilities/queries'

const { formatMessage } = intl

const messages = defineMessages({
  amount: {
    defaultMessage: 'Amount',
    id: 'retroactiveMatchRequestList.amount',
  },
  details: {
    defaultMessage: 'Details',
    id: 'retroactiveMatchRequestList.details',
  },
  noImports: {
    defaultMessage: 'No Retroactive Match Requests available.',
    id: 'retroactiveMatchRequestList.noImports',
  },
  filePath: {
    defaultMessage: 'File Path',
    id: 'retroactiveMatchRequestList.filePath',
  },
  status: {
    defaultMessage: 'Status',
    id: 'retroactiveMatchRequestList.status',
  },
  createdAt: {
    defaultMessage: 'Created At',
    id: 'retroactiveMatchRequestList.createdAt',
  },
  requestedBy: {
    defaultMessage: 'Requested By',
    id: 'retroactiveMatchRequestList.requestedBy',
  },
  donationDate: {
    defaultMessage: 'Donation Date',
    id: 'retroactiveMatchRequestList.donationDate',
  },
  statusFilterLabel: {
    defaultMessage: 'Filter by status',
    id: 'retroactiveMatchRequestList.statusFilterLabel',
  },
  orderFilterLabel: {
    defaultMessage: 'Sort by date',
    id: 'retroactiveMatchRequestList.orderFilterLabel',
  },
  charity: {
    defaultMessage: 'Charity',
    id: 'retroactiveMatchRequestList.charity',
  },
  retroactiveMatchRequests: {
    defaultMessage:
      '{count, plural, zero {Retroactive Match Requests} one {Retroactive Match Request} two {Retroactive Match Requests} few {Retroactive Match Requests} many {Retroactive Match Requests} other {Retroactive Match Requests}}',
    id: 'retroactiveMatchRequestList.retroactiveMatchRequests',
  },
  transactionId: {
    defaultMessage:
      'Transaction ID',
    id: 'retroactiveMatchRequestList.transactionId',
  },
})

const PAGE_SIZE = 25

const RetroactiveMatchRequestList = () => {
  const { data, error, fetchMore, loading, networkStatus, refetch, variables } =
    useQuery(LIST_RETROACTIVE_MATCH_REQUESTS_QUERY , {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_SIZE,
        status: 'pending',
        order: 'desc'
      },
  })
  const retroactiveMatchRequests = data?.retroactiveMatchRequests
  const corporateAccountBalance = data?.brand?.corporateAccountBalance

  if (error) {
    return <MissingResourcePage />
  }

  const onStatusFilterChange = (status) => {
    refetch({ ...variables, status })
  }

  const onOrderFilterChange = (order) => {
    refetch({ ...variables, order })
  }

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.retroactiveMatchRequests.nodes = [
          ...previousResult?.retroactiveMatchRequests?.nodes,
          ...fetchMoreResult?.retroactiveMatchRequests?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: data?.retroactiveMatchRequests?.pageInfo?.endCursor,
        first: PAGE_SIZE,
      },
    })
  }

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)

  return (
    retroactiveMatchRequests && (
      <Stack as={Paper} className="p-5 m-5" space={5}>
        <div className="row">
          <Stack className="col-3" data-test="status-filter" space={1}>
            <InputLabel
              className="fw-semi-bold text-black-1 text-uppercase fs-1"
              htmlFor="statusFilter"
            >
              {formatMessage(messages.statusFilterLabel)}
            </InputLabel>
            <Select
              hideBlankOption
              name="statusFilter"
              optionProps={{ 'data-test': 'status-filter-option' }}
              options={statusFilterOptions}
              selectProps={{ inputProps: { className: 'p-2 text-capitalize' } }}
              setValue={onStatusFilterChange}
              value={variables.status}
            />
          </Stack>
          <Stack className="col-3" data-test="order-filter" space={1}>
            <InputLabel
              className="fw-semi-bold text-black-1 text-uppercase fs-1"
              htmlFor="orderFilter"
            >
              {formatMessage(messages.orderFilterLabel)}
            </InputLabel>
            <Select
              hideBlankOption
              name="orderFilterLabel"
              optionProps={{ 'data-test': 'order-filter-option' }}
              options={orderFilterLabelOptions}
              selectProps={{ inputProps: { className: 'p-2 text-capitalize' } }}
              setValue={onOrderFilterChange}
              value={variables.order}
            />
          </Stack>
          <Stack className="col-3" data-test="account-balance" space={1}>
            <h5>Corporate Account Balance:</h5>
            <h4><FormattedNumber currency="USD" style="currency" value={corporateAccountBalance} /></h4>
          </Stack>
        </div>
        <Stack className="[ flex-column ] [ bg-off-white pt-2 ]" space={2}>
          {!refetching && (
            <>
              {retroactiveMatchRequests?.nodes?.length > 0 ? (
                <div>
                  <div className="bg-black-6 fs-1 pl-1 fw-semi-bold ls-3 py-3 text-uppercase">
                    <div className="row">
                      <span className="col-1">{formatMessage(messages.createdAt)}</span>
                      <span className="col-2">{formatMessage(messages.requestedBy)}</span>
                      <span className="col-1">{formatMessage(messages.charity)}</span>
                      <span className="col-1">{formatMessage(messages.transactionId)}</span>
                      <span className="col-1">{formatMessage(messages.donationDate)}</span>
                      <span className="col-1">{formatMessage(messages.amount)}</span>
                      <span className="col-2">{formatMessage(messages.details)}</span>
                    </div>
                  </div>
                  <Stack as="ol" className="p-0" divider="default" space={1}>
                    {retroactiveMatchRequests?.nodes?.map((retroactiveMatchRequest) => <RetroactiveMatchRequestRow retroactiveMatchRequest={retroactiveMatchRequest} key={retroactiveMatchRequest.originalTransaction?.id} refetch={refetch} />)}
                  </Stack>
                </div>
              ) : (
                <p className="fw-semi-bold" data-test="empty-state-message">
                  {formatMessage(messages.noImports)}
                </p>
              )}
            </>
          )}

          {loading && (
            <CenteredPaddedLoadingIndicator
              padding={null}
              size={LoadingIndicatorSize.LARGE}
            />
          )}
        </Stack>

        {retroactiveMatchRequests?.pageInfo.hasNextPage && (
          <div className="[ d-flex justify-content-center ] [ pt-2 ]">
            <LoadMoreButton
              className="flex-grow mxw-4"
              loading={loading}
              newsfeedItemsCount={retroactiveMatchRequests?.nodes?.length}
              onClick={onLoadMore}
              pageSize={PAGE_SIZE}
              totalCount={retroactiveMatchRequests?.totalCount}
              type={messages.retroactiveMatchRequests}
            />
          </div>
        )}
      </Stack>
    )
  )
}

export { RetroactiveMatchRequestList }
