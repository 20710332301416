import React from 'react'

import { Campaigns } from 'components/carbon_footprint/components/Campaigns'
import { translations } from 'components/carbon_footprint/utilities/messages'
import CenteredContent from 'components/application/centered_content'
import { DocumentTitle } from 'components/shared/document_title'
import { Footprint } from 'components/carbon_footprint/components/Footprint'
import { Heading } from 'components/ui/heading'
import { Impact } from 'components/carbon_footprint/components/Impact'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import Stack from 'components/ui/stack'

export const CarbonFootprintPage = () => {
  return (
    <DocumentTitle title={translations.carbonFootprint}>
      {maybeDisplayUserTour('carbonFootprintPage')}
      <CenteredContent>
        <Stack space={5}>
          <div className="col-md-12">
            <Heading
              className="mt-5"
              data-test="carbon-footprint-headline"
              level={1}
            >
              {translations.carbonFootprint}
            </Heading>
            <Footprint className="mt-3" />
          </div>
          <div
            className="[ d-flex flex-column | flex-md-row ] [ col-md-12 ] [ mt-3 ]"
            space={5}
          >
            <section className="[ col-md-8 ] [ pt-3 px-0 ]">
              <Stack space={2}>
                <Heading level={2}>{translations.carbonProjects}</Heading>
                <div className="mt-3 | mr-md-3" data-test="campaigns-section">
                  <Campaigns />
                </div>
              </Stack>
            </section>
            <section className="[ pt-3 mx-1 px-0 ][ col-md-4 ]">
              <Impact />
            </section>
          </div>
        </Stack>
      </CenteredContent>
    </DocumentTitle>
  )
}
