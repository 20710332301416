// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'
import WeQuery from 'components/application/we_query'

const { formatMessage } = intl
const messages = defineMessages({
  contactSupport: {
    defaultMessage: 'Contact support',
    id: 'UserProfileDonationStats.contactSupport',
  },
  toEnableAccount: {
    defaultMessage: 'to enable your donation account',
    id: 'UserProfileDonationStats.toEnableAccount',
  },
  totalDonationsMade: {
    defaultMessage: 'total donations made',
    id: 'UserProfileDonationStats.totalDonationsMade',
  },
  yourDonationStats: {
    defaultMessage: 'your donation stats',
    id: 'UserProfileDonationStats.yourDonationStats',
  },
})

export const UserProfileDonationStats = ({ className, userId }) => (
  <>
    <WeQuery
      error={
        <div className={className}>
          <MissingResourceSection
            errorDetails={formatMessage(messages.yourDonationStats)}
          />
        </div>
      }
      loader={<SkeletonLoading height={50} />}
      query={gql`
        query userDonationStatQuery($id: ID!) {
          user(id: $id) {
            givingaUser {
              totalDonationTransactions
              id
            }
            id
          }
        }
      `}
      variables={{ id: userId }}
    >
      {({
        data: {
          user: { givingaUser },
        },
      }) => {
        return (
          <>
            {givingaUser ? (
              <Stat
                amount={givingaUser.totalDonationTransactions}
                className={className}
                dataTest="donations-count"
                label={formatMessage(messages.totalDonationsMade)}
              />
            ) : (
              <div className={className}>
                <Stack className="[ d-flex align-items-center ] [ bg-blue-2 border-left-thick-blue p-3 ]">
                  <Icon
                    className="fs-5 mr-3 text-blue"
                    iconName="info"
                    title=""
                  />
                  <p>
                    <Link
                      className="text-link text-link--black-1"
                      to="mailto:support@wespire.com?subject=Giving%20Account%20Error"
                    >
                      {formatMessage(messages.contactSupport)}
                    </Link>{' '}
                    {formatMessage(messages.toEnableAccount)}
                  </p>
                </Stack>
              </div>
            )}
          </>
        )
      }}
    </WeQuery>
  </>
)

UserProfileDonationStats.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
}

UserProfileDonationStats.defaultProps = {
  className: null,
}
