import React from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

const ProgressVisual = ({ match, status }) => {
  return (
    <div className="flex md:inline">
      <div className="content-center md:inline">
        {match && (
          <>
            <span className="leading-4 material-symbols-rounded text-success-green text-[1.2rem] align-middle">
              {status === 'in progress'
                ? 'check_circle'
                : 'radio_button_checked'}
            </span>
            <span className="body-medium-bold align-middle">
              <FormattedMessage
                id="donationHistory.requestPending"
                defaultMessage="Request pending"
              />
            </span>
            <span className="px-2">–</span>
          </>
        )}
        <span className="leading-4 material-symbols-rounded text-success-green text-[1.2rem] align-middle">
          {status === 'in progress'
            ? 'radio_button_checked'
            : 'radio_button_unchecked'}
        </span>
        <span
          className={cx('body-medium-bold align-middle', {
            'text-black-3': status !== 'in progress',
          })}
        >
          <FormattedMessage
            id="donationHistory.paymentProcess"
            defaultMessage="Payment processing"
          />
        </span>
        <span className="px-2">–</span>
        <span className="leading-4 material-symbols-rounded text-[#D9D9D9] text-[1.2rem] align-middle">
          radio_button_unchecked
        </span>
        <span className="body-medium-bold pr-2 align-middle text-black-3">
          <FormattedMessage
            id="donationHistory.paidToCharity"
            defaultMessage="Paid to charity"
          />
        </span>
      </div>
    </div>
  )
}

export default ProgressVisual
