// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NetworkStatus } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useFlags } from 'launchdarkly-react-client-sdk'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'
import { undecorated as TextField } from 'components/form/text_field'
import { useDebounce } from 'utilities/hooks/useDebounce'

import { GroupMemberRow } from './row'
import { groupOrChapterLabel } from 'components/setup/groups_management/utils'

const { formatMessage } = intl

const messages = defineMessages({
  allMembers: {
    defaultMessage: 'All members ({ totalCount })',
    id: 'groupMembersTable.allMembers',
  },
  emptyState: {
    defaultMessage: '{type} has no active members.',
    id: 'groupMembersTable.emptyState',
  },
  helperTextForSearch: {
    defaultMessage: 'Filter by name or email address',
    id: 'groupMembersTable.helperTextForSearch',
  },
  labelDisplayedRows: {
    defaultMessage: '{ from }-{ to } of { count }',
    id: 'groupMembersTable.labelDisplayedRows',
  },
  labelRowsPerPage: {
    defaultMessage: 'Rows per page',
    id: 'groupMembersTable.labelRowsPerPage',
  },
})

const tableHeadMessages = defineMessages({
  contact: {
    defaultMessage: 'Contact',
    id: 'groupMembersTableHead.contact',
  },
  joined_at: {
    defaultMessage: 'Date Joined',
    id: 'groupMembersTableHead.joined_at',
  },
  membership: {
    defaultMessage: 'Member Type',
    id: 'groupMembersTableHead.membership',
  },
})

const buttonMessages = defineMessages({
  emailMembers: {
    defaultMessage: 'Email Members',
    id: 'groupMembersTable.emailMembers',
  },
  exportMembers: {
    defaultMessage: 'Export Member List',
    id: 'groupMembersTable.exportMembers',
  },
})

const QUERY = gql`
  query groupMembersQuery(
    $groupId: ID!
    $page: Int
    $limit: Int
    $term: String
    $sort: String
    $sortBy: String
  ) {
    currentUser {
      id
      email
    }
    group(id: $groupId) {
      id
      name
      isChapter
      memberships(
        page: $page
        limit: $limit
        term: $term
        sort: $sort
        sortBy: $sortBy
      ) {
        avatarUrl
        id
        email
        name
        profilePath
        role
        userId
        userJoinedAt
      }
      membersCount
    }
  }
`
const INITIAL_ROWS_PER_PAGE = 25
export const GroupMembersTable = ({ groupId }) => {
  const { enableGroupMembersSendEmailButton } = useFlags()

  const { data, error, loading, networkStatus, refetch, variables } = useQuery(
    QUERY,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        groupId: groupId,
        limit: INITIAL_ROWS_PER_PAGE,
        page: 1,
        sort: 'desc',
        sortBy: 'joined_at',
      },
    }
  )

  const groupData = data?.group

  const handleChangePage = (event, newPage) => {
    refetch({
      ...variables,
      page: newPage,
    })
  }
  const handleChangeRowsPerPage = (ev) => {
    refetch({
      ...variables,
      limit: +ev.target.value,
      page: 1,
    })
  }
  const handleOnSortClick = (columnId) => () => {
    const isAsc = variables.sortBy === columnId && variables.sort === 'asc'
    refetch({
      ...variables,
      page: 1,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: columnId,
    })
  }

  const handleOnSearch = (key) => {
    refetch({
      ...variables,
      page: 1,
      term: key,
    })
  }
  const [filterKey, setFilterKey] = useState('')
  const debouncedFilterKey = useDebounce(filterKey, 300)
  const handleFilterKeyOnChange = (value) => {
    setFilterKey(value)
  }
  useEffect(() => {
    handleOnSearch(debouncedFilterKey)
  }, [debouncedFilterKey])
  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault()
    }
  }

  if (error) {
    return <MissingResourcePage />
  }

  const headCells = [
    {
      id: 'contact',
      label: formatMessage(tableHeadMessages.contact),
    },
    {
      id: 'membership',
      label: formatMessage(tableHeadMessages.membership),
    },
    {
      id: 'joined_at',
      label: formatMessage(tableHeadMessages.joined_at),
    },
    {
      id: 'actions',
      invisible: true,
    },
  ]

  const sort = variables.sort
  const sortBy = variables.sortBy
  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)
  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase"
          data-test="all-members-label"
          level={3}
        >
          {formatMessage(messages.allMembers, {
            totalCount: groupData?.membersCount,
          })}
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack className="d-flex">
          <TextField
            className="col mxw-4"
            name="filterKey"
            setValue={handleFilterKeyOnChange}
            textFieldProps={{
              className: 'pr-0',
              inputProps: {
                'aria-label': 'search members',
                className: 'text-truncate',
              },
              InputProps: {
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      className="fs-4 text-black-3"
                      iconName="people"
                      title={intl.formatMessage(messages.helperTextForSearch)}
                    />
                  </InputAdornment>
                ),
              },
              onKeyDown: handleKeyDown,
              placeholder: intl.formatMessage(messages.helperTextForSearch),
              size: 'small',
              type: 'search',
            }}
            value={filterKey}
          />

          <div className="ml-auto" />

          {enableGroupMembersSendEmailButton && (
            <LinkButton
              className="text-blue fs-2"
              data-test="email-members"
              to="/management_panel/blogs/new"
              variant="text"
            >
              <Icon
                className="fs-4 mr-1"
                iconName="mail-outline"
                title={formatMessage(buttonMessages.emailMembers)}
              />
              {formatMessage(buttonMessages.emailMembers)}
            </LinkButton>
          )}

          <LinkButton
            className="text-blue fs-2"
            to={`/management_panel/groups_management/${groupId}/download_members`}
            variant="text"
          >
            <Icon
              className="fs-4 mr-1"
              iconName="export"
              title={formatMessage(buttonMessages.emailMembers)}
            />
            {formatMessage(buttonMessages.exportMembers)}
          </LinkButton>
        </Stack>

        <Stack>
          <TableContainer>
            <Table aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell, index) => (
                    <TableCell
                      align={index > 0 ? 'right' : 'inherit'}
                      key={headCell.id}
                      sortDirection={sortBy === headCell.id ? sort : false}
                    >
                      {headCell.invisible ? null : (
                        <TableSortLabel
                          active={sortBy === headCell.id}
                          direction={sortBy === headCell.id ? sort : 'asc'}
                          onClick={handleOnSortClick(headCell.id)}
                        >
                          {headCell.label}
                          {sortBy === headCell.id && (
                            <ScreenReader
                              role="status"
                              text={
                                sort === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'
                              }
                            />
                          )}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!refetching &&
                  groupData?.memberships?.map((member) => {
                    return (
                      <GroupMemberRow
                        groupId={groupData.id}
                        groupName={groupData.name}
                        key={member.id}
                        tabIndex={-1}
                        {...member}
                      />
                    )
                  })}
                {groupData?.membersCount === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <p
                        className="fs-2 fw-semi-bold d-flex align-items-center justify-content-center"
                        data-test="group-members-empty-state"
                      >
                        {intl.formatMessage(messages.emptyState, {
                          type: groupOrChapterLabel(groupData),
                        })}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <CenteredPaddedLoadingIndicator
                        padding={null}
                        size={LoadingIndicatorSize.LARGE}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            ActionsComponent={({
              className,
              count,
              onChangePage,
              rowsPerPage,
            }) => {
              return (
                <Pagination
                  className={className}
                  count={Math.ceil(count / rowsPerPage)}
                  onChange={onChangePage}
                  page={variables.page}
                />
              )
            }}
            component="div"
            count={groupData?.membersCount || 0}
            labelDisplayedRows={({ count, from, to }) => {
              return formatMessage(messages.labelDisplayedRows, {
                count,
                from,
                to,
              })
            }}
            labelRowsPerPage={formatMessage(messages.labelRowsPerPage)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={variables.page - 1}
            rowsPerPage={variables.limit}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

GroupMembersTable.propTypes = {
  groupId: PropTypes.string.isRequired,
}
