// Vendor
import { Breadcrumbs } from '@material-ui/core'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'

// Wespire
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'breadcrumb',
    id: 'breadcrum.ariaLabel',
  },
})

const { formatMessage } = intl
export default function NavBreadcrumb({ links, ariaLabel, ...props }) {
  const lastLinkIndex = links.length - 1

  const linksElements = links.map((link, idx) => {
    const isLastElement = idx === lastLinkIndex

    let baseProps = {
      className: cx(
        'fs-2 py-1 px-2 fw-semi-bold',
        { 'text-black-2': isLastElement },
        { 'text-blue': !isLastElement }
      ),
      'data-test': `nav-link-${link.text.toLowerCase().replace(/\W/g, '-')}`,
      to: link.href,
    }
    if (isLastElement) {
      baseProps['aria-current'] = 'page'
    }

    return (
      <Link key={idx} {...baseProps}>
        {link.text}
      </Link>
    )
  })

  return (
    <Breadcrumbs
      {...props}
      aria-label={ariaLabel}
      className={cx(props.className)}
      data-test="breadcrumbs"
      separator=">"
    >
      {linksElements}
    </Breadcrumbs>
  )
}

NavBreadcrumb.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ),
}

NavBreadcrumb.defaultProps = {
  ariaLabel: formatMessage(messages.ariaLabel),
  className: 'bg-white lh-xs px-2 py-3',
  links: [],
}
