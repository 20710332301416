// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { viewHrisImportRoute } from '../routes.js'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const messages = defineMessages({
  details: {
    defaultMessage: 'Details',
    id: 'hrisImportRow.details',
  },
})

const HrisImportRow = ({ hrisImport }) => (
  <>
    <div className="row fs-2">
      <span
        className="col-1"
        style={{ overflowWrap: 'break-word' }}
      >
        {hrisImport.filePath}
      </span>
      <span className="col-1">{new Date(hrisImport.createdAt).toLocaleDateString('en-US')}</span>
      <span className="col-1">{hrisImport.status}</span>
      <span className="col-1">{hrisImport.usersProcessed}</span>
      <span className="col-1">{hrisImport.usersCreated}</span>
      <span className="col-1">{hrisImport.usersUpdated}</span>
      <span className="col-1">{hrisImport.usersDeactivated}</span>
      <span className="col-1">{hrisImport.usersUnchanged}</span>
      <span className="col-1">{hrisImport.usersFailed}</span>
      <span className="col-1">{hrisImport.result}</span>
      <LinkButton
        className="ml-4 fs-2"
        to={viewHrisImportRoute({hrisImportId: hrisImport.id})}
        variant="text"
      >
        {formatMessage(messages.details)}
      </LinkButton>
    </div>
  </>
)

HrisImportRow.propTypes = {
  hrisImport: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    filePath: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    result: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    usersCreated: PropTypes.number.isRequired,
    usersUpdated: PropTypes.number.isRequired,
    usersDeactivated: PropTypes.number.isRequired,
    usersFailed: PropTypes.number.isRequired,
    usersProcessed: PropTypes.number.isRequired,
    usersUnchanged: PropTypes.number.isRequired,
  }).isRequired,
}

export { HrisImportRow }
