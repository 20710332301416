export const formatTeamsNamesAsString = (teams) => {
  return teams.map((team) => team.name).join(', ')
}

export const getGroupUrl = ({
  groupIdentifier,
  chapterIdentifier,
  subPage,
}) => {
  let baseUrl = chapterIdentifier
    ? `/groups/${groupIdentifier}/chapters/${chapterIdentifier}`
    : `/groups/${groupIdentifier}`

  if (subPage) {
    baseUrl += `/${subPage}`
  }

  return baseUrl
}
