// Vendor
import cx from 'classnames'

// Setup
import React from 'react'
import PropTypes from 'prop-types'
import ScreenReaderText from 'components/ui/screen_reader_text'

/** Adds a " • " spacer that's only visible to sighted users.
 * Screen reader users get a comma instead that acts as a pause in the
 * announcement, instead of reading the word "bullet"
 * Defaults to px-2 for the horizontal whitespace. */
export const BulletSpacer = ({ className }) => (
  <>
    <span aria-hidden className={cx(className, 'px-2')}>
      •
    </span>
    <ScreenReaderText text=", " />
  </>
)

BulletSpacer.propTypes = {
  className: PropTypes.string,
}

BulletSpacer.defaultProps = {
  className: null,
}
