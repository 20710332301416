// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { FormattedNumber } from 'react-intl'

// WeSpire
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'
import { messages } from 'components/shared/sustainability_impact_stat'

export const CarbonImpactStat = ({
  actionsReducedText,
  equivalent,
  name,
  units,
  impactValue,
}) => {
  const { formatMessage } = intl

  const getUnitTranslation = () => {
    // according to this unit list https://formatjs.io/docs/polyfills/intl-numberformat/#simple-units
    // we need to pass the correct unit to <FormattedNumber /> otherwise if the
    // unit is not included in the list, we use the default value localized but include
    // the unit in the default message as in the case of energy where kWh is hardcoded
    // to the message to be translated.
    const impactUnits = {
      gal: 'gallon',
      kg: 'kilogram',
      l: 'liter',
      lbs: 'pound',
    }

    const getFormattedNumber = (impactValue, impactUnit = null) => {
      if (impactUnit) {
        return (
          <FormattedNumber
            style="unit"
            unit={impactUnit}
            unitDisplay="long"
            value={impactValue}
          />
        )
      } else {
        return <FormattedNumber value={impactValue} />
      }
    }

    switch (name) {
      case 'Emissions':
        return formatMessage(messages.emissionsResult, {
          value: getFormattedNumber(impactValue, impactUnits[units]),
        })
      case 'Energy':
        return formatMessage(messages.energyResult, {
          value: getFormattedNumber(impactValue),
        })
      case 'Fuel':
        return formatMessage(messages.fuelResult, {
          result: getFormattedNumber(impactValue, impactUnits[units]),
        })
      case 'Trees':
        return formatMessage(messages.treesResult, {
          value: getFormattedNumber(impactValue),
        })
      case 'Waste':
        return formatMessage(messages.wasteResult, {
          result: getFormattedNumber(impactValue, impactUnits[units]),
        })
      case 'Water':
        return formatMessage(messages.waterResult, {
          result: getFormattedNumber(impactValue, impactUnits[units]),
        })
      default:
        break
    }
  }

  return (
    <Stack data-test="carbon-impact-stat" space={1}>
      <span
        className="fs-2 fw-semi-bold ls-2 text-black-3"
        data-test="actions-reduced-text"
      >
        {actionsReducedText}
      </span>
      <Stack space={1}>
        <p
          className="fs-2 fw-semi-bold text-green"
          data-test="carbon-impact-equivalent"
        >
          {equivalent}
        </p>
        <p className="fs-1 fw-semi-bold" data-test="carbon-impact-unit">
          {getUnitTranslation()}
        </p>
      </Stack>
    </Stack>
  )
}

CarbonImpactStat.propTypes = {
  actionsReducedText: PropTypes.string.isRequired,
  equivalent: PropTypes.string.isRequired,
  impactValue: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
}
