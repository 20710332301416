const editChapterRoute = ({ groupSlug, chapterSlug }) =>
  `/management_panel/groups/${groupSlug}/chapters/${chapterSlug}/edit`

const indexLeadershipRoles = ({ groupSlug, chapterSlug }) =>
  `/management_panel/groups/${groupSlug}/chapters/${chapterSlug}/roles`

const newChapterRoute = ({ groupSlug }) =>
  `/management_panel/groups/${groupSlug}/chapters/new`

export { editChapterRoute, indexLeadershipRoles, newChapterRoute }
