// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Formsy from 'formsy-react'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'
import FormActionBar from 'components/form/action_bar'
import FormErrorSummary from 'components/form/error_summary'
import FormFieldset from 'components/form/fieldset'
import FormTextField from 'components/form/text_field'
import Stack from 'components/ui/stack'

// Sub-Components
import { eventCategoriesPath } from './routes'

const handleInvalidSubmit = () => {
  displayBanner({
    content: 'Please fix the errors in the form.',
    fullWidth: true,
    variant: 'error',
  })
}

const CREATE_EVENT_CATEGORY = gql`
  mutation createEventCategory(
    $brandId: ID!
    $name: String!
    $whyItMatters: String!
  ) {
    createEventCategory(
      brandId: $brandId
      name: $name
      whyItMatters: $whyItMatters
    ) {
      errors
    }
  }
`

const UPDATE_EVENT_CATEGORY = gql`
  mutation updateEventCategory(
    $categoryId: ID!
    $name: String!
    $whyItMatters: String!
  ) {
    updateEventCategory(
      eventCategoryId: $categoryId
      name: $name
      whyItMatters: $whyItMatters
    ) {
      errors
    }
  }
`

const EventCategoryForm = ({
  brandId,
  eventCategory: { id, name, whyItMatters },
  isEdit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = ({ category: name, whyItMatters }) => {
    hideBanner()
    setIsSubmitting(true)

    const mutation = isEdit ? UPDATE_EVENT_CATEGORY : CREATE_EVENT_CATEGORY
    const mutationName = isEdit ? 'updateEventCategory' : 'createEventCategory'
    const variables = isEdit
      ? { categoryId: id, name, whyItMatters }
      : { brandId, name, whyItMatters }

    client
      .mutate({
        mutation,
        variables,
      })
      .then(
        ({
          data: {
            [mutationName]: { errors },
          },
        }) => {
          setIsSubmitting(false)

          if (!errors) {
            if (isEdit) {
              displayBanner({
                content: 'Event Category was successfully updated.',
                variant: 'success',
              })
            } else {
              // Redirect to /index view and display successful create banner.
              window.location.href = `${eventCategoriesPath}?created`
            }
          } else {
            displayBanner({
              as: 'div',
              content: <FormErrorSummary errors={errors} />,
              fullWidth: true,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)

        displayExceptionBanner({
          fullWidth: true,
          operation: isEdit ? 'update Event Category' : 'create Event Category',
        })
      })
  }

  return (
    <Formsy
      noValidate
      onInvalidSubmit={handleInvalidSubmit}
      onValidSubmit={handleSubmit}
    >
      <Stack space={5}>
        <FormFieldset
          hint="The basic information about this category."
          label="Details"
        >
          <FormTextField
            name="category"
            required
            textFieldProps={{
              helperText: isEdit
                ? 'The label for this Event Category, as it will display when a User associates the category to new events or volunteer logs. Editing this category will not update any events and volunteer logs associated with it; they will remain linked to the previous name.'
                : 'The label for this Event Category, as it will display when a User associates the category to new events or volunteer logs.',
              label: 'Category',
            }}
            value={name}
          />

          <FormTextField
            name="whyItMatters"
            required
            textFieldProps={{
              helperText:
                'Please describe why impacting this category is important to your brand. It will be displayed to employee users in the Our Impact primary navigation section.',
              label: 'Why it matters',
              multiline: true,
            }}
            value={whyItMatters}
          />
        </FormFieldset>

        <FormActionBar
          backPath={eventCategoriesPath}
          isSubmitting={isSubmitting}
        />
      </Stack>
    </Formsy>
  )
}

EventCategoryForm.propTypes = {
  brandId: PropTypes.string,
  eventCategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    whyItMatters: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
}

EventCategoryForm.defaultProps = {
  brandId: null,
  eventCategory: {},
  isEdit: false,
}

export default EventCategoryForm
