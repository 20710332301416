// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import { NetworkStatus } from '@apollo/client'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'
import { DocumentTitle } from 'components/shared/document_title'

import { ErrorPage } from 'components/shared/pages/error'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { WordForScore } from 'components/queries/word_for_score'

import PointsDescription from './description'
import { PointsStats } from './stats'
import { PointsHistory } from './history'
import { useIsPointsDashboardEnabled } from './utils'
import { GET_POINTS_DASHBOARD_INFO } from './queries'
import { getPointsDashboardTitle } from './translations'

const messages = defineMessages({
  heading: {
    defaultMessage: 'Your { wordForScore } History',
    description: 'Heading of points dashboard page.',
    id: 'pointsDashboard.heading',
  },
  pageTitle: {
    defaultMessage: 'Points History',
    id: 'pointsDashboard.pageTitle',
  },
})

const INITIAL_SCORE_LOGS_FETCH_LIMIT = 20
const SCORE_LOGS_FETCH_LIMIT = 50

export const PointsDashboard = () => {
  const { isPointsDashboardEnabled, loading: isLoadingFlag } =
    useIsPointsDashboardEnabled()

  const { data, error, fetchMore, loading, networkStatus, refetch, variables } =
    useQuery(GET_POINTS_DASHBOARD_INFO, {
      notifyOnNetworkStatusChange: true,
      variables: { first: INITIAL_SCORE_LOGS_FETCH_LIMIT },
    })

  const scoresData = data?.currentUser?.scores
  const userScoreLogs = scoresData?.nodes
  const hasNextPage = scoresData?.pageInfo?.hasNextPage
  const currentYearPointsObjective =
    data?.currentUser?.benefitGroup?.currentYearPointsObjective
  const pointsDashboardDescription =
    data?.currentUser?.benefitGroup?.pointsDashboardDescription

  const onLoadMoreClick = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.currentUser.scores.nodes = [
          ...previousResult?.currentUser?.scores?.nodes,
          ...fetchMoreResult?.currentUser?.scores?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: scoresData?.pageInfo?.endCursor,
        first: SCORE_LOGS_FETCH_LIMIT,
      },
    })
  }

  const onFilterParamsChange = (filterParams) => {
    refetch({
      first: INITIAL_SCORE_LOGS_FETCH_LIMIT,
      ...filterParams,
    })
  }

  if (error) {
    return <MissingResourceSection errorDetails={getPointsDashboardTitle()} />
  }

  if (isLoadingFlag || !data) {
    return <CenteredPaddedLoadingIndicator />
  }

  if (!isPointsDashboardEnabled) {
    return <ErrorPage body={sharedTranslations.featureDisabledPageBody} />
  }

  return (
    <DocumentTitle title={intl.formatMessage(messages.pageTitle)}>
      <div className="px-3">
        <CenteredContent className="mt-5">
          <Heading className="mb-4 text-capitalize" level={1}>
            {intl.formatMessage(messages.heading, {
              wordForScore: <WordForScore wordOnly />,
            })}
          </Heading>
          <Stack space={5}>
            <Stack space={3}>
              <PointsStats pointsObjective={currentYearPointsObjective} />
              <PointsDescription description={pointsDashboardDescription} />
            </Stack>
            <PointsHistory
              hasNextPage={hasNextPage}
              loading={loading}
              onFilterParamsChange={onFilterParamsChange}
              onLoadMoreClick={onLoadMoreClick}
              refetching={[
                NetworkStatus.refetch,
                NetworkStatus.setVariables,
              ].includes(networkStatus)}
              userScoreLogs={userScoreLogs}
            />
          </Stack>
        </CenteredContent>
      </div>
    </DocumentTitle>
  )
}
