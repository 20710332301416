import React, { useState } from 'react'
import Button from 'src/common/button/Button'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

// Vendor
import cx from 'classnames'

const settingsForVariant = (variant) => {
  if (variant === 'success') {
    return {
      backgroundColor: 'bg-success-green',
      icon: <TaskAltIcon className="text-success-green" />,
      textColor: 'text-success-green',
    }
  } else if (variant === 'error') {
    return {
      backgroundColor: 'bg-opacity-5 bg-error-red',
      icon: <WarningAmberIcon className="text-error-red" />,
      textColor: 'text-error-red',
    }
  } else if (variant === 'warning') {
    return {
      backgroundColor: 'bg-never-preachy-peach',
      icon: <WarningAmberIcon className="helpful-orange" />,
      textColor: 'helpful-orange',
    }
  } else {
    return {
      backgroundColor: 'bg-pampas',
      icon: <InfoOutlinedIcon />,
      textColor: 'text-black-2',
    }
  }
}

const InfoContainer = ({
  buttonText,
  canClose,
  title,
  body,
  children,
  className,
  open = true,
  useIcon = true,
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(open)

  const { backgroundColor, icon, textColor } = settingsForVariant(variant)

  const closeContainer = () => setIsOpen(false)

  return (
    <div
      className={cx(
        `${backgroundColor} rounded w-full flex flex-col p-2`,
        { hidden: !isOpen },
        className
      )}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row sm:w-full">
          {useIcon && (
            <div className="w-9 flex flex-row justify-center pr-2 text-2xl">
              {icon}
            </div>
          )}
          <div>
            {title && (
              <span className={`font-medium font-sans text-base ${textColor}`}>
                {title}
              </span>
            )}
            <div className={cx({ 'pt-2': title })}>{body || children}</div>
          </div>
        </div>
        <div>
          {buttonText && (
            <Button className="px-4 py-3" variant="outlined">{buttonText}</Button>
          )}
        </div>
        {canClose && (
          <button className="max-h-px">
            <CloseIcon className="text-black-3" onClick={closeContainer} />
          </button>
        )}
      </div>
    </div>
  )
}

export default InfoContainer
