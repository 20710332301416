import { encodeQueryParams, StringParam } from 'use-query-params'
import { stringify } from 'query-string'

export const channelSlugUrlForLocale = (channel, locale) => {
  const url = channel.slug
    ? `/take_action/${channel.slug}`
    : channelUrl(channel)
  return `${url}?locale=${locale}`
}

export const channelUrl = (channel) => `/take_action/${channel.id || ''}`

export const getChannel = (channels = [], id) =>
  channels.find((channel) => channel.id === id)

export const getCurrentChannelId = (slugCandidate, channels) => {
  if (channels.length === 0) {
    return ''
  }

  const channelMatch = channels.find(
    (channel) => channel.id === slugCandidate || channel.slug === slugCandidate
  )

  return channelMatch ? channelMatch.id : channels[0].id
}

export const getAvailableActivitiesCount = (channels = []) =>
  channels.reduce((count, channel = {}) => {
    // Excluding My Activities
    if (channel.id === 'currentUser') {
      return count
    }

    const currLength = channel.activities && channel.activities.length

    return count + currLength
  }, 0)

export const getActionUrlForActivity = ({
  actionUrl,
  fromChannelId,
  fromGroupId,
}) => {
  const urlParams = stringify(
    encodeQueryParams(
      { from_channel_id: StringParam, from_group_id: StringParam },
      { from_channel_id: fromChannelId, from_group_id: fromGroupId }
    )
  )
  return `${actionUrl}${urlParams ? `?${urlParams}` : ''}`
}
