// Setup
import React from 'react'
import PropTypes from 'prop-types'

//Vendor
import MUIDivider from '@material-ui/core/Divider'

// WeSpire
import Stack from 'components/ui/stack'
import { dateTimeHourMinute } from 'utilities/date_formatter'
import { GroupAnnouncementsDropdown } from './dropdown'
import { groupAnnouncementsListingMessages as messages } from '../utilities/messages'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

export const GroupAnnouncementsListing = ({
  announcements,
  executeMutation,
  groupId,
  type,
}) => {
  return (
    <Stack
      className="[ d-flex flex-column ] [ lh-xs ] [ pb-2 ]"
      data-test={`group-announcements-list-${type}`}
      space={1}
    >
      <div className="border">
        <div className="bg-black-6">
          <div className="row">
            <span
              className="[ col-12 ] [ mx-4 py-3 ] [ fs-1 fw-semi-bold ls-3 text-uppercase ]"
              data-test="group-announcement-listing-title"
            >
              {formatMessage(messages.announcementsText, {
                type: type,
              })}
            </span>
          </div>
        </div>
        <div
          className="ml-2 py-1"
          data-test={`group-announcements-rows-holder-${type}`}
        >
          {announcements.map((announcement, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between">
                <div className="px-5">
                  <div className="my-3">
                    <div
                      className="[ my-3 ] [ setup-text-blue fs-2 fw-semi-bold ]"
                      data-test="announcement-listing-title"
                    >
                      {announcement.title}
                    </div>
                    <div className="[ fs-1 ] [ d-block ]">
                      <span data-test="text-created-by">
                        {formatMessage(messages.createdByText)}{' '}
                      </span>
                      <span
                        className="fw-semi-bold"
                        data-test="text-author-name"
                      >
                        {announcement.author.name}
                      </span>
                      <span data-test="text-on">
                        {' '}
                        {formatMessage(messages.onText)}{' '}
                      </span>
                      <span
                        className="fw-semi-bold"
                        data-test="data-created-at"
                      >
                        {dateTimeHourMinute(announcement.createdAt)}
                      </span>
                      <span className="fw-semi-bold"> &bull; </span>
                      <span data-test="text-last-edited">
                        {formatMessage(messages.lastEditedText)}{' '}
                      </span>
                      <span
                        className="fw-semi-bold"
                        data-test="data-updated-at"
                      >
                        {dateTimeHourMinute(announcement.updatedAt)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pr-5">
                  <GroupAnnouncementsDropdown
                    announcement={announcement}
                    executeMutation={executeMutation}
                    groupId={groupId}
                    type={type}
                  />
                </div>
              </div>
              {index + 1 < announcements.length && (
                <MUIDivider className="bg-black-5 mx-3" component="div" />
              )}
            </div>
          ))}
        </div>
      </div>
    </Stack>
  )
}

GroupAnnouncementsListing.propTypes = {
  announcements: PropTypes.array.isRequired,
  executeMutation: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
