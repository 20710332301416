// Vendor
import React from 'react'
import gql from 'graphql-tag'
import { defineMessages } from 'react-intl'
import { Tabs, Tab } from '@material-ui/core'
import { useQueryParam, StringParam } from 'use-query-params'
import cx from 'classnames'

// WeSpire
import Stack from 'components/ui/stack'
import Avatar from 'components/application/avatar'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { SocialImpact } from 'components/our_impact/social_impact'
import { intl } from 'utilities/localization'
import { ChannelStats } from 'components/our_impact/channel_stats'
import { SustainabilityImpact } from 'components/our_impact/sustainability_impact'
import { ActivityImpact } from 'components/our_impact/activity_impact'
import { Heading } from 'components/ui/heading'

const messages = defineMessages({
  heading: {
    defaultMessage: 'Impact by Program',
    description: 'Heading of area showing company-wide impact',
    id: 'impactByProgram.heading',
  },
  programs: {
    defaultMessage: 'your programs',
    description:
      'Reference to different programs or initiatives, used when ' +
      'failing to load them, e.g. "We were unable to find {your programs} ' +
      'at this time."',
    id: 'impactByProgram.programs',
  },
})

const channelsQuery = gql`
  query activeChannelsForUser {
    activeChannelsForUser {
      id
      name
      slug
      logoImageUrl
      brandContentModule {
        id
        name
      }
    }
  }
`

export const ImpactByProgram = () => {
  const { formatMessage } = intl
  const [activeChannelSlug, setChannelSlug] = useQueryParam(
    'channel',
    StringParam
  )

  const handleChannelChange = (_event, channelSlug) => {
    if (channelSlug !== activeChannelSlug) {
      setChannelSlug(channelSlug)
    }
  }

  const getSelectedChannelSlug = (channels) => {
    if (activeChannelSlug) {
      // Guard against the initial tab from the URL being wrong.
      // In that case, we set our tab to the first channel and call
      // handleChannelChange to update the URL.
      // Also, return slug version of the url if the user provides a valid slug
      if (channels.some((channel) => channel.id === activeChannelSlug)) {
        return activeChannelSlug
      } else {
        const channelSlug = channels.find(
          (channel) => channel.slug === activeChannelSlug
        )
        if (channelSlug) {
          return channelSlug.id
        } else {
          handleChannelChange(null, channels[0].id)
        }
      }
    }
    return channels[0].id
  }

  // Grab the module associated with our tab if there is one, otherwise with
  // the first channel.
  const getSelectedModule = (channels) => {
    const channelSlugToModuleName = (channels, slug) => {
      const channel = channels.find((channel) => channel.id === slug)
      return channel ? channel.brandContentModule.name : null
    }

    const moduleName = channelSlugToModuleName(channels, activeChannelSlug)
    return moduleName
      ? moduleName
      : channelSlugToModuleName(channels, channels[0].id)
  }

  return (
    <Stack as="section" space={3}>
      <Heading className="px-3" level={2}>
        {formatMessage(messages.heading)}
      </Heading>
      <WeQuery
        error={
          <MissingResourceSection
            errorDetails={formatMessage(messages.programs)}
          />
        }
        loader={<SkeletonLoading height={87} />}
        query={channelsQuery}
      >
        {({ data: { activeChannelsForUser } }) => {
          // Filter our channels to just those with modules.
          const moduleChannels = activeChannelsForUser.filter(
            (channel) => channel.brandContentModule
          )

          // Don't show anything if we have no module channels!
          if (moduleChannels.length === 0) {
            return null
          }

          const currentChannel = activeChannelsForUser.find(
            (channel) =>
              channel.id === getSelectedChannelSlug(activeChannelsForUser)
          )
          const selectedModule = getSelectedModule(moduleChannels)

          return (
            <>
              <Tabs
                onChange={handleChannelChange}
                scrollButtons="off"
                textColor="secondary"
                value={getSelectedChannelSlug(moduleChannels)}
                variant="scrollable"
              >
                {moduleChannels.map((channel) => (
                  <Tab
                    classes={{
                      root: cx(
                        'fs-3 px-2 py-3 text-transform-none col-auto border-bottom mxw-none',
                        { 'col-md-3': moduleChannels.length < 5 }
                      ),
                      wrapper: 'flex-row',
                    }}
                    data-test="channel-tab"
                    icon={
                      !!channel.logoImageUrl && (
                        <Avatar
                          className="elevation-1 mr-2"
                          image={channel.logoImageUrl}
                        />
                      )
                    }
                    key={channel.id}
                    label={channel.name}
                    value={channel.id}
                  />
                ))}
              </Tabs>

              <div className="pt-3">
                <Stack className="row" space={{ _: 3, md: 0 }}>
                  <Stack className="col-md-9" space={3}>
                    {selectedModule === 'Social Impact' && <SocialImpact />}
                    {selectedModule === 'Sustainability' && (
                      <SustainabilityImpact />
                    )}
                    {['Culture', 'Wellbeing', 'Inclusive Culture'].includes(
                      selectedModule
                    ) && <ActivityImpact channelId={currentChannel.id} />}
                  </Stack>
                  <Stack className="col-md-3" space={3}>
                    {currentChannel && (
                      <ChannelStats channelId={currentChannel.id} />
                    )}
                  </Stack>
                </Stack>
              </div>
            </>
          )
        }}
      </WeQuery>
    </Stack>
  )
}

ImpactByProgram.propTypes = {}
