// Vendor
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'
import cx from 'classnames'

// WeSpire
import { muiButtonTheme } from './utils'

// Style Variations
// -----------------------------------------------------------------------------
// V1. Primary = orange background + white text
// V2. Secondary = white background + orange border + orange text
// V3. Neutral = white background + gray outline + black text
// V4. Text = blue text
// V5. Secondary Action = white background + blue border + blue text

// Props API Notes
// -----------------------------------------------------------------------------
// P1. The focus ripple is an A11Y enhancement, so it should not be disabled.
// P2. If you need a link that looks like a button, you can use <LinkButton>.
// P3. All design system variations should be encapsulated in variantProps.
// P4. Sometimes we render buttons "as" other elements, mostly for styling.
// P5. For when you want to retain focus on a button but still disable it, most
// often for some kind of form submission button.

const ButtonVariants = [
  'primary',
  'secondary',
  'neutral',
  'text',
  'secondaryAction',
]

/**
 * It's a `<button>`! According to MDN, "Buttons are used to submit data or
 * perform an on-screen action which does not shift keyboard focus."
 *
 * - Does activating the control perform an action on the current page? Use a `<Button>`.
 * - Does activating the control navigate the user to a different page? Use a `<LinkButton>`.
 * - Specify 1 of 4 predefined design system variations with the `variant` prop.
 * - Custom `<Button>` styles used in one component do not need an official variant.
 * - Management Panel `<Button>` styles are totally separate.
 */
const Button = forwardRef(
  ({ as, children, className, disabledWithFocus, variant, ...other }, ref) => {
    /* eslint-disable sort-keys */
    const variantProps =
      {
        primary: { color: 'primary', variant: 'contained' }, // V1
        secondary: { color: 'primary', variant: 'outlined' }, // V2
        neutral: { color: 'default', variant: 'outlined' }, // V3
        text: { color: 'secondary', variant: 'text' }, // V4
        secondaryAction: {
          color: 'secondary',
          variant: 'outlined',
          size: 'small',
        }, // V5
      }[variant] || {}
    /* eslint-enable sort-keys */

    delete other.disableFocusRipple // P1
    delete other.href // P2
    delete other.size // P3
    const buttonProps = {
      component: as, // P4
      ...other,
      ref,
      ...variantProps,
    }

    return (
      <MuiThemeProvider theme={muiButtonTheme}>
        <MuiButton
          {...buttonProps}
          aria-disabled={disabledWithFocus} // P5
          className={cx(className, {
            'cursor-not-allowed pointer-events-none Mui-disabled':
              disabledWithFocus, // P5
          })}
          onClick={disabledWithFocus ? null : buttonProps.onClick} // P5
          tabIndex={disabledWithFocus ? -1 : buttonProps.tabIndex} // P5
        >
          {children}
        </MuiButton>
      </MuiThemeProvider>
    )
  }
)

Button.propTypes = {
  /** render the `<Button>` as a custom component type - use with caution! */
  as: PropTypes.any,
  /** render primary button content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** disable the button — to temporarily disable it use `<DisableButton>` */
  disabled: PropTypes.bool,
  /** style the button as if it were disabled, but allow it to retain focus */
  disabledWithFocus: PropTypes.bool,
  /** execute a function when the button is clicked  */
  onClick: PropTypes.func,
  /** choose a button variant style  */
  variant: PropTypes.oneOf(ButtonVariants).isRequired,
}

Button.defaultProps = {
  as: 'button',
  className: null,
  disabled: false,
  disabledWithFocus: false,
  onClick: null,
}

export { Button, ButtonVariants }
