// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import v from 'voca'
import { FormattedNumber } from 'react-intl'

// WeSpire
import Stack from './stack'
import ScreenReaderText from 'components/ui/screen_reader_text'

/**
 * Component to emphasise a number, could be a KPI, with a label
 */
const Stat = ({ amount, className, dataTest, label, size }) => (
  <Stack
    className={cx('d-flex flex-column lh-xs', className)}
    data-test={dataTest || `stat-${v.kebabCase(label)}`}
    space={2}
  >
    <span className={`fs-${size} text-orange`}>
      {amount !== null ? (
        <FormattedNumber value={amount} />
      ) : (
        <>
          <span aria-hidden>—</span>
          <ScreenReaderText text="stat could not be loaded" />
        </>
      )}
    </span>
    <span className="fs-1 fw-semi-bold ls-2 text-black-3 text-uppercase">
      {label}
    </span>
  </Stack>
)

Stat.propTypes = {
  /** renders amount  */
  amount: PropTypes.number,
  /** css classes for container */
  className: PropTypes.string,
  /** only for test purposes */
  dataTest: PropTypes.string,
  /** renders label */
  label: PropTypes.string.isRequired,
  /** font-size value for the amount based on $font-sizes scale in _3_variables.scss */
  size: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7]),
}

Stat.defaultProps = {
  amount: null,
  className: '',
  dataTest: null,
  size: 6,
}

export { Stat }
