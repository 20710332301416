// Setup
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import Divider from 'components/ui/divider'
import { Heading } from 'components/ui/heading'
import HelpIconModal from 'components/ui/help_icon_modal'

class ContentHeader extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    'data-test': PropTypes.string,
    helpIcon: PropTypes.shape({
      content: PropTypes.string,
      title: PropTypes.string,
    }),
    text: PropTypes.string.isRequired,
  }

  static defaultProps = {
    children: null,
    // eslint-disable-next-line react/jsx-sort-default-props
    'data-test': 'content-header',
    helpIcon: {
      content: null,
      title: null,
    },
  }

  state = {
    helpDialogIsOpen: false,
  }

  handleToggleHelpDialog = () => {
    this.setState((prevState) => ({
      helpDialogIsOpen: !prevState.helpDialogIsOpen,
    }))
  }

  render() {
    const { children, helpIcon, text, ...other } = this.props

    return (
      <Fragment>
        <div
          {...other}
          className={cx(
            'd-flex align-items-center justify-content-between | bg-white p-4',
            other.className
          )}
        >
          <Heading className="fs-5 fw-regular" level={1}>
            {text}

            {helpIcon.content && helpIcon.title && (
              <HelpIconModal title={helpIcon.title}>
                {helpIcon.content}
              </HelpIconModal>
            )}
          </Heading>

          {children}
        </div>
        <Divider className="elevation-1" />
      </Fragment>
    )
  }
}

export default ContentHeader
