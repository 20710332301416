/* eslint-disable react/prop-types */
import React from 'react'
import { useFormikContext } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import CurrencyFormat from 'react-currency-format'

import Divider from 'src/common/Divider'
import InfoContainer from 'src/common/InfoContainer'
import Input from 'src/common/Input'
import InputChoice from 'src/common/InputChoice'
import SectionContainer from 'src/common/SectionContainer'

const DonationAmountSection = ({
  availableD4dFunds,
  d4dConfiguration,
  errors,
  inputValue,
  matching,
  setMatching,
  givingaUser,
  setInputValue,
  strictProgram,
  insufficientFundsForMatching,
}) => {
  const intl = useIntl()
  const formik = useFormikContext()

  const convertToRawValue = (formattedValue) => {
    return parseFloat(formattedValue.replace(/[$,]/g, ''))
  }

  const handleInputChange = (event) => {
    let newValue = convertToRawValue(event.target.value)

    setInputValue(newValue)
    formik.setFieldValue('donationAmount', newValue)
  }

  const displayValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(inputValue)

  return (
    <SectionContainer title="Donation amount">
      <div className="px-4">
        {strictProgram ? (
          <Input
            className="w-full disabled:text-grayscale-2"
            disabled={strictProgram}
            step="0.01"
            max={availableD4dFunds}
            min={10.0}
            name="donationAmount"
            onChange={handleInputChange}
            type="text"
            value={displayValue}
          />
        ) : (
          <Input
            className="w-full disabled:text-grayscale-2"
            disabled={availableD4dFunds < 10}
            explainer={intl.formatMessage({
              defaultMessage: 'Minimum must equal to $10.00 USD',
              id: 'dollarsForDoersForm.minimumDonationAmount',
            })}
            step="0.01"
            max={availableD4dFunds}
            min={10.0}
            name="donationAmount"
            onChange={handleInputChange}
            type="number"
            value={inputValue}
          />
        )}

        {errors.donationAmount && inputValue !== '' && (
          <div className="pl-2 text-error-red">{errors.donationAmount}</div>
        )}
        {availableD4dFunds < 10 ? (
          <InfoContainer className="mt-4" variant="error">
            <span>
              <FormattedMessage
                defaultMessage="You currently do not have enough grant dollars to donate"
                id="dollarsForDoersForm.youCurrentlyDoNotHaveEnough"
              />
            </span>
          </InfoContainer>
        ) : (
          <InfoContainer className="mt-4">
            <span>
              {intl.formatMessage(
                {
                  defaultMessage:
                    'You have {amount} Dollars for Doers available.',
                  id: 'dollarsForDoersForm.dollarsForDoersBalance',
                },
                {
                  amount: (
                    <CurrencyFormat
                      className="font-semibold"
                      displayType={'text'}
                      value={availableD4dFunds}
                      prefix={'$'}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  ),
                }
              )}
            </span>
          </InfoContainer>
        )}
        {d4dConfiguration.d4dAllowMatchingGifts && (
          <>
            <Divider className="my-5 border-grayscale-2" />

            <h4 className="text-base font-semibold mb-4">
              <FormattedMessage
                defaultMessage="Match gift"
                id="shared.matchGift"
              />
            </h4>

            <InputChoice
              disabled={insufficientFundsForMatching}
              label={intl.formatMessage({
                defaultMessage: 'Match my donation',
                id: 'shared.matchMyDonation',
              })}
              onChange={() => setMatching((prevMatching) => !prevMatching)}
              name="matched"
              type="checkbox"
              checked={matching}
            />
            <InfoContainer className="mt-4">
              <span>
                {intl.formatMessage(
                  {
                    defaultMessage: 'You have {amount} available for matching.',
                    id: 'dollarsForDoersForm.matchingBalance',
                  },
                  {
                    amount: (
                      <CurrencyFormat
                        className="font-semibold"
                        decimalScale={2}
                        displayType={'text'}
                        fixedDecimalScale
                        value={availableD4dFunds}
                        prefix={'$'}
                      />
                    ),
                  }
                )}
              </span>
            </InfoContainer>
            <p className="mt-4 pl-2">
              {intl.formatMessage(
                {
                  defaultMessage:
                    'PLEASE NOTE: Your company has a {amount} USD annual limit per employee. This includes both Dollars for Doers grants you can earn and Donation Match requests.',
                  id: 'dollarsForDoersForm.yourCompanyHas',
                },
                {
                  amount: (
                    <CurrencyFormat
                      decimalScale={2}
                      displayType={'text'}
                      fixedDecimalScale
                      prefix={'$'}
                      value={givingaUser.donationFundsCap}
                    />
                  ),
                }
              )}
            </p>
          </>
        )}
      </div>
    </SectionContainer>
  )
}

export default DonationAmountSection
