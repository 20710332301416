// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { formatMessages } from 'components/shared/translations'

const messages = defineMessages({
  errorDetails: {
    defaultMessage: 'stats for this Channel',
    description:
      'Name of resource to be displayed in an error message when failing to fetch the data',
    id: 'ourImpactSharedTranslations.errorDetails',
  },
  participatingTeams: {
    defaultMessage: 'participating teams',
    id: 'ourImpactSharedTranslations.participatingTeams',
  },
  whyItMatters: {
    defaultMessage: 'why it matters',
    id: 'ourImpactSharedTranslations.whyItMatters',
  },
})

export const ourImpactTranslations = formatMessages(messages)
