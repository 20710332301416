import banner from './banner'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import currentUser from './current_user'
import modal from './modal'
import notification from './notification'
import routes from './routes'
import session from './session'
import userTour from './user_tour'

const createRootReducer = (history) =>
  combineReducers({
    banner,
    currentUser,
    modal,
    notification,
    router: connectRouter(history),
    routes,
    session,
    userTour,
  })

export default createRootReducer
