// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { Button, Menu, MenuItem } from '@material-ui/core'
import cx from 'classnames'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import Divider from 'components/ui/divider'
import { DropdownLink } from './link'
import {
  getCarbonFootprintLink,
  getDonationHistoryLink,
  getVolunteerHistoryLink,
} from 'components/shared/navigator/utils'
import Icon from 'components/ui/icon'
import { sharedTranslations } from 'components/shared/translations'
import { SignOutButton } from 'components/application/sign_out_button'
import Stack from 'components/ui/stack'
import { useUserScore } from 'utilities/hooks/useUserScore'
import { useIsPointsDashboardEnabled } from 'components/points_dashboard/utils'
import { getPointsDashboardTitle } from 'components/points_dashboard/translations'
import { getPointsDashboardLink } from 'components/shared/navigator/utils'

export const Dropdown = ({
  avatarUrl,
  carbonFootprintEnabled,
  className,
  givingEnabled,
  managementDropdownNavigationLinks,
  name,
  volunteerHistoryEnabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isUserProfileActive = window.location.pathname.includes('/profile')
  const { isPointsDashboardEnabled } = useIsPointsDashboardEnabled()

  const { scoreLabel } = useUserScore()

  const userDropdownLinks = [
    { href: '/achievements', title: sharedTranslations.achievements },
    { href: '/blogs', title: sharedTranslations.broadcasts },
    { href: '/user/edit', title: sharedTranslations.settings },
    { href: 'mailto:support@wespire.com', title: sharedTranslations.support },
  ]

  return (
    <>
      <Button
        aria-controls="user-menu-dropdown"
        aria-haspopup="true"
        className={cx('user-menu-dropdown [ p-0 mxw-3 ]', className)}
        data-test="user-menu-dropdown-toggle"
        onClick={handleClick}
      >
        <Avatar className="mr-1" image={avatarUrl} size={AvatarSizes.small} />
        <Stack
          className="[ d-flex flex-column align-items-start mnw-0 ]"
          space={0}
        >
          <span className="[ w-100 text-left text-transform-none text-truncate text-blue fs-2 ]">
            {name}
          </span>
          <span
            className="[ w-100 text-left text-transform-none text-truncate fs-1 lh-xs text-black-1 ]"
            data-test="nav-score-label"
          >
            {scoreLabel}
          </span>
        </Stack>

        <Icon
          className="collapsible__toggle | fs-5 text-black-2 mr-5"
          iconName="chevron_down"
          title={sharedTranslations.toggleDetails}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: 'ml-1 mt-5' }}
        data-test="user-menu-dropdown"
        id="user-menu-dropdown"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {managementDropdownNavigationLinks.map((link) => (
          <DropdownLink
            data-test={
              'user-menu-dropdown-' +
              link.title.split(' ').join('-').toLowerCase()
            }
            key={link.href}
            onClick={handleClose}
            to={link.href}
          >
            {link.title === 'Admin'
              ? sharedTranslations.admin
              : sharedTranslations.managementPanel}
          </DropdownLink>
        ))}

        {managementDropdownNavigationLinks.length > 0 && (
          <Divider className="my-2" />
        )}

        {carbonFootprintEnabled && (
          <DropdownLink onClick={handleClose} to={getCarbonFootprintLink.href}>
            {getCarbonFootprintLink.id}
          </DropdownLink>
        )}

        {givingEnabled && (
          <DropdownLink onClick={handleClose} to={getDonationHistoryLink.href}>
            {sharedTranslations.donationHistory}
          </DropdownLink>
        )}

        {volunteerHistoryEnabled && (
          <DropdownLink onClick={handleClose} to={getVolunteerHistoryLink.href}>
            {sharedTranslations.volunteerHistory}
          </DropdownLink>
        )}

        {isPointsDashboardEnabled && (
          <DropdownLink onClick={handleClose} to={getPointsDashboardLink.href}>
            {getPointsDashboardTitle()}
          </DropdownLink>
        )}

        {/** This is a temporary setup for the link to My Profile until the old
        user profile is retired. With the standard DropdownLink component we use
        client side routing to direct users to pages however this bypasses the
        checks we have on the user controller to ensure that users without
        access to the new user profile do not land on the new page. Instead, for
        the time being with this code, we force our link to use traditional
        server-side navigation, so it hits the users controller that contains
        the new user profile redirect logic. **/}
        <MenuItem
          className={cx(
            'text-capitalize',
            { 'text-black-1': !isUserProfileActive },
            { 'text-blue': isUserProfileActive }
          )}
          component="a"
          dense
          href="/profile"
          onClick={handleClose}
        >
          {sharedTranslations.profile}
        </MenuItem>
        {userDropdownLinks.map((link) => (
          <DropdownLink key={link.href} onClick={handleClose} to={link.href}>
            {link.title}
          </DropdownLink>
        ))}

        <SignOutButton
          as={MenuItem}
          className="text-transform-none w-100"
          dense
        />
      </Menu>
    </>
  )
}

Dropdown.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  carbonFootprintEnabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  givingEnabled: PropTypes.bool.isRequired,
  managementDropdownNavigationLinks: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  volunteerHistoryEnabled: PropTypes.bool.isRequired,
}

Dropdown.defaultProps = {
  className: null,
}
