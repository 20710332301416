import React from 'react'
import { WeSpireLogo } from 'components/shared/wespire_logo'
import PropTypes from 'prop-types'

const Logo = ({ logo }) =>
  logo ? (
    <img className="h-10" alt="logo-img" src={logo} />
  ) : (
    <WeSpireLogo height={40} />
  )

Logo.propTypes = {
  logo: PropTypes.string,
}

export default Logo
