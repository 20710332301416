// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import { YourRankingListItem } from './your_ranking_list_item'
import { YourRankingListItemPlaceholder } from './placeholder'
import { LeaderBoardPlaceholders } from '../placeholders'
import Stack from 'components/ui/stack'

const YourRankingList = ({ className, competitors, forTeams }) => (
  <Stack
    as="ol"
    className={cx(
      '[ d-flex flex-column flex-grow ] [ border list-none p-0 m-0 ]',
      className
    )}
    data-test="leaderboard-your-ranking"
    divider="default"
  >
    {competitors.map((competitor) => (
      <YourRankingListItem
        avatarUrl={competitor.avatarUrl}
        forTeams={forTeams}
        highlightAsYou={competitor.highlight}
        key={competitor.id}
        name={competitor.name}
        rank={competitor.rank}
        score={competitor.points}
      />
    ))}
    <LeaderBoardPlaceholders
      as={YourRankingListItemPlaceholder}
      count={5 - competitors.length}
      rankStart={competitors.length + 1}
    />
  </Stack>
)

YourRankingList.propTypes = {
  className: PropTypes.string,
  competitors: PropTypes.array.isRequired,
  forTeams: PropTypes.bool,
}

YourRankingList.defaultProps = {
  className: null,
  forTeams: false,
}

export { YourRankingList, YourRankingListItem, YourRankingListItemPlaceholder }
