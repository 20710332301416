// Setup
import React from 'react'

// WeSpire
import { DocumentTitle } from 'components/shared/document_title'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'

/**
 * This page should be displayed when we cannot find the Charity that a user is
 * requesting (i.e. a GraphQL request returned with no charity).
 **/
const CharityNotFoundPage = () => (
  <DocumentTitle
    title={intl.formatMessage(sharedMessages.resourceNotFound, {
      resource: sharedTranslations.charity,
    })}
  >
    <MissingResourcePage resourceName={sharedTranslations.charity} />
  </DocumentTitle>
)

export { CharityNotFoundPage }
