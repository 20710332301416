// Vendor
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  DialogContent,
  Accordion,
  AccordionSummary,
  IconButton,
  Toolbar,
} from '@material-ui/core/'
import cx from 'classnames'
import { stringify } from 'query-string'

// WeSpire
import { ActionTimeframeModel } from './action_timeframe_model'
import { ActionBody } from './action_body'
import ActionProgressBar from './action_progress_bar'
import ActionSummary from './action_summary'
import { ActionUserTargetForm } from './action_user_target_form'
import { CampaignModel } from './campaign_model'
import { displayModal, hideModal, refreshModal } from 'redux/dispatchers'
import { getUrlParams } from 'utilities/get_url_params'
import Icon from 'components/ui/icon'
import { ACTION_TRACKING_TYPE_DAILY, panelBorderClasses } from './utils'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { withActionMutation } from './with_action_mutation'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { ActionLogProgressForm } from './action_log_progress_form'

/** Action UI where details are toggled via Modal. */
// 1. Allow keyboard navigation of overflowing content
export class ActionModalBase extends Component {
  state = {
    isDetailsVisible: true,
  }

  componentDidMount() {
    const {
      action: { id },
    } = this.props
    const queryParamId = getUrlParams().action
    const isDeepLinked = queryParamId === id

    if (isDeepLinked) {
      this.handleToggleDetails()
    }
  }

  componentDidUpdate(prevProps) {
    // Manually tell our modal to refresh when isSubmitting changes.
    if (prevProps.isSubmitting !== this.props.isSubmitting) {
      refreshModal()
    }
  }

  componentWillUnmount() {
    hideModal()
  }

  handleToggleDetails = () => {
    const previousIsDetailsVisible = this.state.isDetailsVisible
    const isDetailsVisible = !previousIsDetailsVisible

    this.setState({ isDetailsVisible })

    const {
      action: { id },
    } = this.props

    const urlParams = getUrlParams()
    if (isDetailsVisible) {
      hideModal()

      // when closing an action update query params to remove  action=[ID]
      delete urlParams['action']
      const params = stringify({
        ...urlParams,
      })
      window.history.replaceState(null, null, `?${params}`)
    } else {
      displayModal({
        options: {
          'aria-labelledby': `action-${id}`,
          'data-test': 'action-modal-details',
          fullScreen: true,
        },
        renderContent: this.modalContent,
      })

      // when toggling open an action update query params to have action=[ID] so it deep links
      const params = stringify({
        ...urlParams,
        action: id,
      })
      window.history.replaceState(null, null, `?${params}`)
    }
  }

  modalContent = () => {
    const { action, campaign, isSubmitting, onActionSubmit } = this.props
    const {
      accumulationActionDailyTrackingTarget,
      accumulationActionTrackingType,
      allowUserToOverrideTargetAmount,
      availableCategories,
      conditionMetricName,
      cooldownEndsAt,
      dailyMaxLogging,
      description,
      endsAt,
      gracePeriodEndsAt,
      hasCarbonImpact,
      hasCustomTimeframe,
      hasOverrideTargetAmount,
      id: actionId,
      isAccumulationAction,
      name,
      points,
      progressCategory,
      progressMeasuredAs,
      progressTargetValue,
      required,
      startsAt,
      status,
      userCompleted,
      userOverrideTargetMaxAmount,
      userOverrideTargetMinAmount,
      userProgress,
      userProgressActivityLogs,
    } = action

    const actionTimeframe = new ActionTimeframeModel({
      endsAt,
      gracePeriodEndsAt,
      startsAt,
    })

    const anchor = /<a /g
    // Open links in new tab
    const descriptionNew = description.replace(
      anchor,
      `$& target="_blank" rel="noopener noreferrer "`
    )

    return (
      <>
        <AppBar className="m-0" color="inherit" position="static">
          <Toolbar
            className={cx(
              '[ align-items-center ] [ pl-2 pr-3 py-3 ]',
              ...panelBorderClasses(required)
            )}
          >
            <IconButton
              className="mr-2"
              data-test="toggle-details-button"
              onClick={this.handleToggleDetails}
            >
              <Icon className="fs-4" iconName="arrow_left" title="Back" />
            </IconButton>

            <Stack className="d-flex flex-column w-100" space={3}>
              <ActionSummary
                actionTimeframe={actionTimeframe}
                component="h1"
                cooldownEndsAt={cooldownEndsAt}
                hasCarbonImpact={hasCarbonImpact}
                hasCustomTimeframe={hasCustomTimeframe}
                isAccumulationAction={isAccumulationAction}
                name={name}
                points={points}
                required={required}
                status={status}
                userCompleted={userCompleted}
              />
              {isAccumulationAction && (
                <ActionProgressBar
                  progressTargetValue={
                    accumulationActionTrackingType ===
                    ACTION_TRACKING_TYPE_DAILY
                      ? accumulationActionDailyTrackingTarget
                      : progressTargetValue
                  }
                  userProgress={userProgress}
                />
              )}
            </Stack>
          </Toolbar>
        </AppBar>

        <DialogContent
          aria-label={sharedTranslations.details} // 1
          className="bg-off-white d-flex flex-column m-1 p-0"
          id="action-dialog-content"
          tabIndex={0} // 1
        >
          <div className="bg-white">
            <WysiwygContent className="m-0 p-4" data-test="action-description">
              {descriptionNew}
            </WysiwygContent>
          </div>

          {isAccumulationAction && allowUserToOverrideTargetAmount && (
            <div className="bg-white">
              <ActionUserTargetForm
                actionId={actionId}
                campaignId={campaign.id}
                className={cx(
                  'd-flex align-items-center p-3',
                  ...panelBorderClasses(required, true)
                )}
                metricName={conditionMetricName || progressMeasuredAs}
                userOverrideTargetMaxAmount={userOverrideTargetMaxAmount}
                userOverrideTargetMinAmount={userOverrideTargetMinAmount}
                userProgress={userProgress}
              />
            </div>
          )}

          <Stack className="[ d-flex align-items-center ] [ px-3 py-4  ]">
            {isAccumulationAction ? (
              <ActionLogProgressForm
                {...action}
                accumulationActionDailyTrackingTarget={
                  accumulationActionDailyTrackingTarget
                }
                accumulationActionTrackingType={accumulationActionTrackingType}
                actionId={actionId}
                actionTimeframe={actionTimeframe}
                availableCategories={availableCategories}
                campaign={campaign}
                conditionMetricName={conditionMetricName}
                dailyMaxLogging={dailyMaxLogging}
                hasOverrideTargetAmount={hasOverrideTargetAmount}
                progressCategory={progressCategory}
                progressMeasuredAs={progressMeasuredAs}
                progressTargetValue={progressTargetValue}
                userProgress={userProgress}
                userProgressActivityLogs={userProgressActivityLogs}
              />
            ) : (
              <ActionBody
                action={action}
                actionTimeframe={actionTimeframe}
                campaign={campaign}
                hasCustomTimeframe={hasCustomTimeframe}
                isSubmitting={isSubmitting}
                onActionSubmit={onActionSubmit}
              />
            )}
          </Stack>
        </DialogContent>
      </>
    )
  }

  render() {
    const { action } = this.props
    const {
      accumulationActionTrackingType,
      accumulationActionDailyTrackingTarget,
      cooldownEndsAt,
      endsAt,
      gracePeriodEndsAt,
      hasCarbonImpact,
      hasCustomTimeframe,
      isAccumulationAction,
      name,
      progressTargetValue,
      points,
      required,
      startsAt,
      status,
      userCompleted,
      userProgress,
    } = action

    const actionTimeframe = new ActionTimeframeModel({
      endsAt,
      gracePeriodEndsAt,
      startsAt,
    })

    return (
      <Accordion
        className="pr-0"
        data-test="action-modal"
        expanded={false}
        onChange={this.handleToggleDetails}
      >
        {/* Summary */}
        <AccordionSummary
          classes={{ content: 'flex-column stack stack--4' }}
          className={cx('px-3', ...panelBorderClasses(required))}
        >
          <ActionSummary
            actionTimeframe={actionTimeframe}
            component="h3"
            cooldownEndsAt={cooldownEndsAt}
            hasCarbonImpact={hasCarbonImpact}
            hasCustomTimeframe={hasCustomTimeframe}
            isAccumulationAction={isAccumulationAction}
            name={name}
            points={points}
            required={required}
            status={status}
            userCompleted={userCompleted}
          />
          {isAccumulationAction && (
            <ActionProgressBar
              progressTargetValue={
                accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY
                  ? accumulationActionDailyTrackingTarget
                  : progressTargetValue
              }
              userProgress={userProgress}
            />
          )}
        </AccordionSummary>
      </Accordion>
    )
  }
}

ActionModalBase.propTypes = {
  /** action props object */
  action: PropTypes.shape({
    accumulationActionDailyTrackingTarget: PropTypes.number,
    accumulationActionTrackingType: PropTypes.string,
    /** type of activity measured by the action condition for user to gain progress */
    activityType: PropTypes.string,
    /** true if user is allowed to override the target amount */
    allowUserToOverrideTargetAmount: PropTypes.bool.isRequired,
    /** array of available categories for current measured_as */
    availableCategories: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    /** optional, configurable label displayed when condition has custom metric */
    conditionMetricName: PropTypes.string,
    /** datetime when the action is no longer on cooldown */
    cooldownEndsAt: PropTypes.string,
    /** optional value of daily max logging */
    dailyMaxLogging: PropTypes.number,
    /** action description */
    description: PropTypes.string.isRequired,
    /** optional end date of action */
    endsAt: PropTypes.string,
    /** endsAt + grace period in days. It's only required for accumulation actions. */
    gracePeriodEndsAt: PropTypes.string,
    /** true if action has an emission token */
    hasCarbonImpact: PropTypes.bool.isRequired,
    /** true if action has a custom startsAt or endsAt dates */
    hasCustomTimeframe: PropTypes.bool.isRequired,
    /** true if action has a custom override target amount */
    hasOverrideTargetAmount: PropTypes.bool.isRequired,
    /** id of action */
    id: PropTypes.string.isRequired,
    /** true if the action is an accumulation action */
    isAccumulationAction: PropTypes.bool.isRequired,
    /** name of action */
    name: PropTypes.string.isRequired,
    /** points awarded for completing this action */
    points: PropTypes.number.isRequired,
    /** user_activity_log.progressCategory used by action with condition */
    progressCategory: PropTypes.oneOf(['custom']),
    /** user_activity_log.measured_as used by action with condition */
    progressMeasuredAs: PropTypes.oneOf(['duration', 'steps', 'custom']),
    /** Target value to complete action with condition */
    progressTargetValue: PropTypes.number,
    /** true if campaign requires this action to be completed to earn the achievement */
    required: PropTypes.bool.isRequired,
    /** optional start date of action */
    startsAt: PropTypes.string,
    /** the completable status of an action */
    status: PropTypes.oneOf([
      'AVAILABLE',
      'COOLDOWN',
      'FINISHED',
      'UNAVAILABLE',
    ]).isRequired,
    /** true if action has ever been completed by current user */
    userCompleted: PropTypes.bool.isRequired,
    /** optional max value for the user target override (required if allowUserToOverrideTargetAmount is true) */
    userOverrideTargetMaxAmount: PropTypes.number,
    /** optional min value for the user target override (required if allowUserToOverrideTargetAmount is true) */
    userOverrideTargetMinAmount: PropTypes.number,
    /** Total progress gained by user towards progressTargetValue */
    userProgress: PropTypes.number,
    /** current user activity logs for action, if it has a condition enabled  */
    userProgressActivityLogs: PropTypes.array.isRequired,
  }).isRequired,
  /** the Campaign model this act belongs to */
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  /** whether this action is currently being submitted */
  isSubmitting: PropTypes.bool.isRequired,
  /** click handler for action submit button */
  onActionSubmit: PropTypes.func.isRequired,
}

export const ActionModal = withActionMutation(ActionModalBase)
