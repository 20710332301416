// Setup
import React from 'react'

export const desktopBreakpoint = 768

// NOTE: isDesktop is deprecated, you should aim to use the useIsMobile hook instead.
const isDesktop = (WrappedComponent) =>
  class IsDesktop extends React.Component {
    state = {
      isDesktop: window.innerWidth >= desktopBreakpoint,
    }

    componentDidMount() {
      this.handleWindowSizeChange()
      window.addEventListener('resize', this.handleWindowSizeChange)
    }

    handleWindowSizeChange = () => {
      this.setState({ isDesktop: window.innerWidth > desktopBreakpoint })
    }

    render = () => (
      <WrappedComponent {...this.props} isDesktop={this.state.isDesktop} />
    )
  }

export default isDesktop
