// Vendor
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { addValidationRule } from 'formsy-react'
import v from 'voca'

// WeSpire
import Counter from 'components/ui/counter'
import Icon from 'components/ui/icon'
import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'
import { getFieldValidationAttrs } from './utils'

addValidationRule('isMoney', (_, value) =>
  RegExp(/^[\d]+(\.\d\d)?$/).test(value)
)
addValidationRule('isNotBlank', (_, value) => !v.isBlank(value))
addValidationRule('fixed', (_, value, digits) => {
  const valueWithLeadingZero = `0${value}`

  return RegExp(`^[\\d]+(\\.\\d{0,${digits}})?$`).test(valueWithLeadingZero)
})
addValidationRule('divisibleBy', (_, value, digit) => value % digit === 0)
addValidationRule('minValue', (_, value, minValue) => value >= minValue)
addValidationRule('maxValue', (_, value, maxValue) => value <= maxValue)

export const validationTranslations = defineMessages({
  invalid: {
    defaultMessage: 'This field is invalid',
    id: 'textFieldValidations.invalid',
  },
  maxValue: {
    defaultMessage: 'Must be {max} or fewer',
    id: 'textFieldValidations.maxValue',
  },
  minValue: {
    defaultMessage: 'Must be {min} or more',
    id: 'textFieldValidations.minValue',
  },
  pvhIncrements: {
    defaultMessage: 'Must be in 15 minute increments (0.25 hours).',
    id: 'textFieldValidations.pvhIncrements',
  },
  required: {
    defaultMessage: 'This field is required',
    id: 'textFieldValidations.required',
  },
})

const { formatMessage } = intl

const TextFieldValidations = ({
  children,
  errorMessage,
  isFormSubmitted,
  isPristine,
  isRequired,
  isValid,
  name,
  showRequired,
  textFieldProps,
  value,
}) => {
  value = value ?? ''
  var label = textFieldProps.label
  var helperText = textFieldProps.helperText
  var error = false
  const maxLength = textFieldProps.maxLength

  if (showRequired) {
    errorMessage = formatMessage(validationTranslations.required)
  }

  label = label && (
    <Fragment>
      {label}
      {isRequired && <span aria-hidden>*</span>}
    </Fragment>
  )

  if ((!isPristine || isFormSubmitted) && !isValid) {
    error = true
  }

  if (error) {
    helperText = errorMessage
  }

  const validationTextProps = getFieldValidationAttrs(name, error)

  helperText = (
    <span className="d-flex | lh-md" {...validationTextProps}>
      {error && (
        <Icon
          className="fs-1 mr-1"
          data-test="text-field-validation-error-icon"
          iconName="warning"
          style={{ marginTop: '3px' }}
          title=""
        />
      )}
      {helperText}

      {!!maxLength && (
        <Counter
          className="fs-1 ml-auto pl-2 text-black-3"
          maxValue={maxLength}
          value={value.length}
        />
      )}
    </span>
  )

  return children(error, helperText, label, isRequired)
}

TextFieldValidations.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isFormSubmitted: PropTypes.bool,
  isPristine: PropTypes.bool,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showRequired: PropTypes.bool,

  textFieldProps: PropTypes.shape({
    disabled: PropTypes.bool,
    helperText: PropTypes.node,
    inputProps: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
}

TextFieldValidations.defaultProps = {
  className: null,
  errorMessage: null,
  inputProps: {},
  isFormSubmitted: false,
  isPristine: true,
  isRequired: false,
  isValid: null,
  showRequired: false,
  textFieldProps: {},
}

export default TextFieldValidations
