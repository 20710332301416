// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

const ScreenReaderText = ({ as, children, text, ...props }) => {
  const Component = as

  return (
    <Component {...props} className={cx('sr-only', props.className)}>
      {text}
      {children}
    </Component>
  )
}

ScreenReaderText.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

ScreenReaderText.defaultProps = {
  as: 'span',
  children: null,
  className: '',
  text: null,
}

export default ScreenReaderText
