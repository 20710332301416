// Default State
const defaultState = {
  text: '',
}

// Action Creator
export function setNotification(notification) {
  return {
    data: { text: notification },
    type: 'wespire/SET_NOTIFICATION_STATE',
  }
}

// Reducer
const notification = (state = defaultState, action) => {
  switch (action.type) {
    case 'wespire/SET_NOTIFICATION_STATE':
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default notification
