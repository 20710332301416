import React from 'react'
import PropTypes from 'prop-types'
import { VictoryPie } from 'victory'
import GraphLegend from '../../components/GraphLegend'

const FootprintResults = ({
  food,
  goods,
  grandTotal,
  housing,
  size,
  transport,
}) => {
  const graphData = {
    colorScale: ['#649BF5', '#65B4F4', '#002C61', '#004B8F', '#006BC9'],
    data: [
      { name: 'Food', y: food },
      { name: 'Goods', y: goods },
      { name: 'Housing', y: housing },
      { name: 'Travel', y: transport },
    ],
  }

  return (
    <div className="flex-col" data-test="footprint-results">
      {grandTotal && (
        <div className="flex justify-center">
          <div className="flex-col text-center pb-8 font-bold">
            <div className="mb-3">Your estimated carbon footprint is</div>
            <span className="mr-2 text-6xl">
              {Math.round(grandTotal * 10) / 10}
            </span>
            <span>tonnes CO2 per year</span>
          </div>
        </div>
      )}
      <div className="flex justify-center px-10">
        <div className={`w-${size} h-${size}`}>
          <VictoryPie
            x="name"
            colorScale={graphData.colorScale}
            data={graphData.data}
            labelComponent={<span />}
          />
          <GraphLegend graphData={graphData} />
        </div>
      </div>
    </div>
  )
}

FootprintResults.propTypes = {
  food: PropTypes.number,
  goods: PropTypes.number,
  grandTotal: PropTypes.number,
  housing: PropTypes.number,
  size: PropTypes.number,
  transport: PropTypes.number,
}

FootprintResults.defaultProps = {
  food: null,
  goods: null,
  grandTotal: null,
  housing: null,
  size: 80,
  transport: null,
}

export default FootprintResults
