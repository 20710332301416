// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const HeadingLevels = [1, 2, 3, 4, 5, 6]
const HeadingVariants = [1, 2, 3]

// Default variants are assigned based on the heading level, so setting
// `level={1}` will default to `variant={1}` unless a specific variant is
// given. Levels 4 to 6 default to the level 3 variant.
const variantClass = ({ level, variant }) => {
  const selectedVariant = variant || Math.min(level, 3)
  const variants = { 1: 'heading--1', 2: 'heading--2', 3: 'heading--3' }

  return variants[selectedVariant]
}

/**
 * Heading that corresponds to `<h1>` - `<h6>`. Adheres design system scale of
 * allowed style variations.
 *
 * - Every page must have a level 1 heading that matches the document title.
 * - Do not skip heading levels (e.g. from h1 to h3).
 * - Use headings to introduce major sections of content.
 * - Consider appropriate heading level first, then override variant style if necessary.
 * - If necessary for good page heading hierarchy, render only for screen readers.
 * - Custom heading styles used in one component do not need an official variant.
 * - Management Panel heading styles are totally separate.
 */
const Heading = ({
  children,
  className,
  level,
  screenReaderOnly,
  variant,
  ...other
}) => {
  const HeadingComponent = 'h' + level

  return (
    <HeadingComponent
      {...other}
      className={cx(
        'heading',
        variantClass({ level, variant }),
        { 'sr-only': screenReaderOnly },
        className
      )}
    >
      {children}
    </HeadingComponent>
  )
}

Heading.propTypes = {
  /** render primary button content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** renders h1, h2, or h3 with default system style  */
  level: PropTypes.oneOf(HeadingLevels).isRequired,
  /** display only for screen readers */
  screenReaderOnly: PropTypes.bool,
  /** optionally override default system style with a specific variant  */
  variant: PropTypes.oneOf(HeadingVariants),
}

Heading.defaultProps = {
  className: null,
  screenReaderOnly: false,
  variant: null,
}

export { Heading, HeadingLevels, HeadingVariants, variantClass }
