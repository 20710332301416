// Vendor
import React from 'react'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'

const messages = defineMessages({
  discussion: {
    defaultMessage: 'Giving Activity Discussion',
    id: 'givingActivityDiscussion.discussion',
  },
})

const GivingActivityDiscussion = () => (
  <Paper className="px-3 py-2" data-test="giving-discussion">
    {intl.formatMessage(messages.discussion)}
  </Paper>
)

export default GivingActivityDiscussion
