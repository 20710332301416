// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Stack from 'components/ui/stack'

const ResultManagementDescription = ({
  arrayHeaderText,
  descriptionsArray,
  descriptionText,
  instructionsText,
}) => {
  return (
    <Stack className="fs-1" space={1}>
      {descriptionText && <div className="mb-3 lh-md">{descriptionText}</div>}
      {instructionsText && <div className="mb-3 lh-md">{instructionsText}</div>}
      {arrayHeaderText && (
        <div className="mb-3">
          <b>{arrayHeaderText}</b>
        </div>
      )}
      {descriptionsArray && (
        <div className="mb-3">
          <ol style={{ listStyleType: 'upper-latin' }}>
            {descriptionsArray.map((message, index) => (
              <li className="mb-1" key={index}>
                {message}
              </li>
            ))}
          </ol>
        </div>
      )}
    </Stack>
  )
}

ResultManagementDescription.propTypes = {
  arrayHeaderText: PropTypes.string,
  descriptionsArray: PropTypes.array,
  descriptionText: PropTypes.string,
  instructionsText: PropTypes.string,
}

ResultManagementDescription.defaultProps = {
  arrayHeaderText: '',
  descriptionsArray: [],
  descriptionText: '',
  instructionsText: '',
}

export default ResultManagementDescription
