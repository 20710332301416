import React, { useContext } from 'react'
import cx from 'classnames'

import BreadcrumbContext from 'src/contexts/BreadcrumbContext'
import InternalLink from 'src/common/InternalLink'

const Breadcrumbs = () => {
  const { crumbs } = useContext(BreadcrumbContext)
  const lastLinkIndex = crumbs.length - 1

  return crumbs.map((link, idx) => {
    const isLastElement = idx === lastLinkIndex

    return (
      <div key={idx}>
        <InternalLink
          className={cx('text-sm leading-6 font-medium text-black-2', {
            underline: !isLastElement,
          })}
          href={link.href}
          key={idx}
        >
          {link.text}
        </InternalLink>
        {!isLastElement && (
          <span className="material-symbols-rounded leading-6 font-medium text-black-2 px-2 text-lg">
            chevron_right
          </span>
        )}
      </div>
    )
  })
}

export default Breadcrumbs
