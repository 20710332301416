// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import MgmtPanelPage from 'setup/application/page'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import Paper from '@material-ui/core/Paper'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'

import { GroupList } from './group_list'

const { formatMessage } = intl

const messages = defineMessages({
  heading: {
    defaultMessage: 'Manage { brandName } Groups',
    id: 'groupsManagement.heading',
  },
})

const QUERY = gql`
  query groupsManagementQuery($objectId: ID!) {
    currentUser {
      can {
        manageGroups
      }
    }
    brand(id: $objectId) {
      id
      name
    }
  }
`

const GroupsManagement = ({ objectId }) => {
  const { data, error, loading } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      objectId,
    },
  })

  if (error) {
    return <MissingResourcePage />
  }

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  return (
    <MgmtPanelPage
      contentHeaderProps={{
        'data-test': 'groups-management-index-title',
      }}
      title={formatMessage(messages.heading, {
        brandName: data?.brand?.name,
      })}
    >
      {() => (
        <Stack as={Paper} className="p-5 m-5" space={5}>
          <GroupList brandId={objectId} />
        </Stack>
      )}
    </MgmtPanelPage>
  )
}

GroupsManagement.propTypes = {
  objectId: PropTypes.string.isRequired,
}

export default GroupsManagement
