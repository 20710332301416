// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const ParagraphSizes = ['small', 'medium', 'large']

/**
 * Paragraphs can be defined by either a string or can be passed as markdown content that is then parsed.
 *
 * - Paragraphs should be used for block elements.
 * - Generally paragraphs will be for phrases that are a sentence or longer.
 */
const Paragraph = ({ children, className, size, ...other }) => (
  <p
    {...other}
    className={cx(`paragraph-react paragraph-react--${size}`, className)}
    size={size}
  >
    {children}
  </p>
)

Paragraph.propTypes = {
  /** render paragraph content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** determine the size for the paragraph */
  size: PropTypes.oneOf(ParagraphSizes),
}

Paragraph.defaultProps = {
  className: null,
  size: 'medium',
}

export { Paragraph }
