import React from 'react'
import cx from 'classnames'

import InternalLink from 'src/common/InternalLink'
import { WeSpireLogo } from 'components/shared/wespire_logo'

const LogoCell = ({ className }) => (
  <div className={cx(className, 'flex flex-row items-center')}>
    <span className="text-sm">Powered by</span>
    <WeSpireLogo className="mx-2" height={34} />
  </div>
)

const LinkList = ({ children }) => (
  <div className="flex flex-row md:px-4 space-x-2 lg:leading-[3rem] leading-10 self-center place-items-center text-xs">
    {children}
  </div>
)

const LinksCell = ({ className }) => (
  <div className={cx(className, 'flex flex-row')}>
    <LogoCell className="md:flex hidden" />
    <LinkList>
      <a href="https://www.wespire.com/why-wespire" className="underline">
        About
      </a>
      <span>|</span>
      <InternalLink href={'mailto:support@wespire.com'} className="underline">
        Contact
      </InternalLink>
      <span>|</span>
      <InternalLink href={'/terms_of_use'} className="underline" react={false}>
        Terms
      </InternalLink>
      <span>|</span>
      <InternalLink href={'/privacy'} className="underline" react={false}>
        Privacy
      </InternalLink>
    </LinkList>
  </div>
)

const CopyCell = ({ className }) => (
  <div
    className={cx(
      className,
      'flex flex-row lg:justify-end md:leading-[3rem] leading-10'
    )}
  >
    <span className="leading-4 self-center text-sm">
      © 2010-{new Date().getFullYear()} WeSpire, Inc. All rights reserved
    </span>
  </div>
)

const Footer = ({ className }) => (
  <footer
    className={cx(
      className,
      'w-full bg-white text-xs text-black-2 md:px-6 px-4 shadow-[0_-1px_2px_#C4D1DB] leading-[3rem]',
      'lg:py-2 py-3 grid lg:grid-cols-12 md:grid-cols-6 grid-cols-4'
    )}
  >
    <LogoCell className="col-span-4 md:hidden" />
    <LinksCell className="md:col-span-8 col-span-4" />
    <CopyCell className="col-span-4" />
  </footer>
)

export default Footer
