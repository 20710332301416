// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import axios from 'axios'
import { withFormsy } from 'formsy-react'

// WeSpire
import AutocompleteTextField from 'components/form/autocomplete_text_field'
import { debounce } from 'utilities/debounce'
import { displayExceptionBanner } from 'redux/dispatchers'
import { emptyValue } from 'components/form/autocomplete_utils'
import {
  LoadingIndicator,
  LoadingIndicatorSize,
} from 'components/ui/loading_indicator'
import TextFieldValidations from 'components/form/text_field_validations'

const API_URL = 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json'
const APP_CODE = 'qIR4rvLZS8YfY8Q9fZzLJw'
const APP_ID = 'bu92ACO8vtJJ3vliFOUA'
const MAX_RESULTS = 5
const MIN_CHARS = 3
const SEARCH_DEBOUNCE = 400

/**
 * A HERE.COM backed address autocomplete.
 * FullAddress true -- Only show suggestions containing a houseNumber. This way
 *                     only a full address can be selected.
 * FullAddress false -- Only show suggestions containing a city. This way only
 *                      an address with at least a city specified can be selected.
 *
 * Value is of the form {address: {}, addressLabel: ""} where address is a JSON
 * object with the different address components (i.e. city, state, country, etc)
 * and addressLabel is a formatted string of the address.
 *
 * Leverages debounce to ensure that we do not hit our API until the user
 * has finished typing.
 *
 * Heavily inspired by https://material-ui.com/demos/autocomplete/#downshift
 */
class AddressAutocomplete extends Component {
  static propTypes = {
    className: PropTypes.string,
    fullAddress: PropTypes.bool,
    name: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    textFieldProps: PropTypes.object,
    value: PropTypes.any,
  }

  static defaultProps = {
    className: null,
    fullAddress: false,
    textFieldProps: {},
    value: '',
  }

  state = {
    inputValue: '',
    loading: false,
    suggestions: [],
  }

  inputRef = null

  reset = () => {
    this.props.setValue(emptyValue)
    this.inputRef.focus()
  }

  // Since we are single select, backspace and deleting is the same
  // as resetting our field.
  handleBackspace = () => {
    this.reset()
  }
  handleDelete = () => () => {
    this.reset()
  }

  handleInputChange = (event) => {
    const query = event.target.value

    this.setState({ inputValue: query })
    if (query.length >= MIN_CHARS) {
      this.fetchSuggestions(query)
      this.setState({ loading: true })
    }
  }

  handleSelection = ({ address, suggestionLabel: addressLabel }) => {
    // Set the selection, clear the input and empty the suggestions (to hide dropdown).
    this.setState({ inputValue: '', suggestions: [] })
    this.props.setValue({ address, addressLabel })
  }

  fetchSuggestions = debounce((query) => {
    const language = navigator.language
      ? navigator.language.split('-')[0] || 'en'
      : 'en'
    axios
      .get(API_URL, {
        params: {
          app_code: APP_CODE,
          app_id: APP_ID,
          language,
          maxresults: MAX_RESULTS,
          query: query,
        },
      })
      .then((response) => {
        // Filter suggestions down to those that have a houseNumber if fullAddress
        // is specified or those that have at least a city if fullAddress is not specified.
        // Then map them to our SuggestionPropType.
        this.setState({
          loading: false,
          suggestions: response.data.suggestions
            .filter((suggestion) => {
              if (this.props.fullAddress) {
                return suggestion.address.houseNumber
              } else {
                return suggestion.address.city
              }
            })
            .map((data) => {
              data.address.countryAlpha3 = data.countryCode
              return this.valueToSuggestionPropType({
                address: data.address,
                addressLabel: data.label,
              })
            }),
        })
      })
      .catch(() => {
        this.setState({ loading: false })
        displayExceptionBanner({ operation: 'validate this address' })
      })
  }, SEARCH_DEBOUNCE)

  // Converts here.com suggestions and existing values to suggestionPropType.
  // Additionally includes the address object so handleSelection() can
  // set it on the saved value.
  valueToSuggestionPropType = ({ address, addressLabel }) => {
    // The default label provided by here.com is long and in reverse order
    // (country first, street last). Instead we build our own. Every suggestion
    // must have a city and therefor a state (here.com uses 'state' for 'province'
    // and the like). We then prepend street information if there is any.
    let selectionLabel = `${address.city}, ${address.state || address.district}`
    if (address.street) {
      selectionLabel = `${address.houseNumber || ''} ${
        address.street
      }, ${selectionLabel}`
    }

    return {
      address,
      id: addressLabel,
      selectionLabel,
      suggestionLabel: selectionLabel,
    }
  }

  render() {
    const { inputValue, loading, suggestions } = this.state
    const { className, name, textFieldProps, value } = this.props
    const selectedItem = value ? this.valueToSuggestionPropType(value) : null

    return (
      <TextFieldValidations {...this.props}>
        {(error, helperText, label) => (
          <AutocompleteTextField
            className={className}
            error={error}
            helperText={helperText}
            inputValue={inputValue}
            label={label}
            name={name}
            onBackspace={this.handleBackspace}
            onDelete={this.handleDelete}
            onInputChange={this.handleInputChange}
            onSelection={this.handleSelection}
            renderSuggestions={(renderSuggestion) =>
              suggestions.map((suggestion, index) =>
                renderSuggestion(index, suggestion)
              )
            }
            selectedItems={selectedItem ? [selectedItem] : []}
            setInputRef={(ref) => (this.inputRef = ref)}
            textFieldProps={{
              ...textFieldProps,
              InputProps: {
                endAdornment: loading && (
                  <LoadingIndicator size={LoadingIndicatorSize.SMALL} />
                ),
              },
            }}
          />
        )}
      </TextFieldValidations>
    )
  }
}

export { AddressAutocomplete }
export default withFormsy(AddressAutocomplete)
