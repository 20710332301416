// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// WeSpire
import { displayBanner } from 'redux/dispatchers'
import FormErrorSummary from 'components/form/error_summary'
import { intl } from 'utilities/localization'
import { displaySimpleConfirmModal } from 'components/shared/simple_confirm_modal'

const { formatMessage } = intl

const messages = defineMessages({
  confirmation: {
    defaultMessage:
      ' Are you sure that you would like to remove {memberName} from {groupName}?',
    id: 'groupMembers.confirmation',
  },
  memberRemovedSuccessfully: {
    defaultMessage:
      'You have successfully removed {memberName} from {groupName}.',
    id: 'groupMembers.memberRemovedSuccessfully',
  },
})

const REMOVE_MEMBER_MUTATION = gql`
  mutation removeMemberFromGroup($groupId: ID!, $userId: ID!) {
    leaveGroup(groupId: $groupId, userId: $userId) {
      errors
    }
  }
`
export const useRemoveMemberMutation = ({ member, group }) => {
  const [removeMemberFromGroup] = useMutation(REMOVE_MEMBER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['groupMembersQuery'],
  })

  const removeMemberRequest = async () => {
    try {
      const {
        data: {
          leaveGroup: { errors },
        },
      } = await removeMemberFromGroup({
        variables: {
          groupId: group.id,
          userId: member.userId,
        },
      })
      if (errors?.length > 0) {
        displayBanner({
          as: 'div',
          content: <FormErrorSummary errors={errors} />,
          variant: 'error',
        })
      } else {
        displayBanner({
          content: formatMessage(messages.memberRemovedSuccessfully, {
            groupName: group.name,
            memberName: member.name,
          }),
          variant: 'success',
        })
      }
    } catch {
      return
    }
  }

  const handleOnRemoveMember = () => {
    displaySimpleConfirmModal({
      body: formatMessage(messages.confirmation, {
        groupName: group.name,
        memberName: member.name,
      }),
      onConfirm: removeMemberRequest,
    })
  }
  return {
    handleOnRemoveMember,
  }
}
