import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

export const DONATION_MATCHING_ENABLED_QUERY = gql`
  query donationMatchingEnabled {
    donationMatchingEnabled
  }
`

export default function useDonationMatchingEnabled() {
  const { data } = useQuery(DONATION_MATCHING_ENABLED_QUERY, {})
  const { donationMatchingEnabled = true } = data || {}

  return donationMatchingEnabled
}
