// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { editRoleRoute } from './routes.js'
import LinkButton from 'components/ui/link_button'
import { ListOrdering } from 'components/shared/list_ordering/list_ordering'
import { sharedTranslations } from 'components/shared/translations'
import { sortByAlpha } from 'utilities/sort'

const RoleRow = ({
  fromManagementPanel,
  groupId,
  isChapter,
  roleId,
  leaders,
  onUpdatePositionClick,
  parentGroupId,
  title,
}) => {
  const leadersString = sortByAlpha(leaders.map((leader) => leader.name)).join(
    ', '
  )

  return (
    <li className="list-none lh-md" data-test="role-row">
      <div className="row align-items-center">
        <div className="d-inline-flex col-2 justify-content-center">
          <ListOrdering
            onUpdatePositionClick={onUpdatePositionClick}
            recordId={roleId}
            recordName={title}
          />
        </div>
        <span
          className="[ col-3 ] [ fs-3 fw-semi-bold ]"
          data-test="role-title"
        >
          {title}
        </span>
        <span className="[ col-6 ] [ fs-3 ]" data-test="role-leaders">
          {leadersString}
        </span>
        <div className="d-inline-flex col-1 justify-content-center">
          <LinkButton
            className="fs-3"
            data-test="role-edit"
            to={editRoleRoute({
              groupId,
              isChapter,
              parentGroupId,
              roleId,
              urlParams: { fromManagementPanel },
            })}
            variant="text"
          >
            {sharedTranslations.edit}
          </LinkButton>
        </div>
      </div>
    </li>
  )
}

RoleRow.propTypes = {
  fromManagementPanel: PropTypes.any,
  groupId: PropTypes.string.isRequired,
  isChapter: PropTypes.bool.isRequired,
  leaders: PropTypes.array.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
  parentGroupId: PropTypes.string,
  roleId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

RoleRow.defaultProps = {
  fromManagementPanel: undefined,
  parentGroupId: null,
}

export { RoleRow }
