// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { RelativeTime } from 'components/shared/relative_time'
import Stack from 'components/ui/stack'
import { TimeRange } from 'components/ui/time'
import VerticalScrollBox from 'components/ui/vertical_scroll_box'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'details',
    description: 'Screenreader text that describes the description container',
    id: 'activityDetails.ariaLabel',
  },
})

/** Section to show the details of an activity in the about card */
export const ActivityDetails = ({
  className,
  description,
  endsAt,
  hasStarted,
  progressBarDetailsComponent: ProgressBarDetailsComponent,
  startsAt,
  userIsParticipating,
}) => {
  const { formatMessage } = intl

  return (
    <Stack className={cx('d-flex flex-column', className)} space={3}>
      <div
        className="fs-1 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase"
        data-test="activity-date-range"
      >
        <TimeRange endsAt={endsAt} startsAt={startsAt} />
        <BulletSpacer />
        <RelativeTime date={endsAt} showPrefix />
      </div>

      <VerticalScrollBox
        ariaLabel={formatMessage(messages.ariaLabel)}
        as={WysiwygContent}
        className="mb-auto"
        data-test="activity-description"
      >
        {description}
      </VerticalScrollBox>

      <Stack space={3}>
        {userIsParticipating && hasStarted && ProgressBarDetailsComponent}
      </Stack>
    </Stack>
  )
}

ActivityDetails.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  endsAt: PropTypes.string,
  hasStarted: PropTypes.bool,
  progressBarDetailsComponent: PropTypes.node,
  startsAt: PropTypes.string,
  userIsParticipating: PropTypes.bool,
}

ActivityDetails.defaultProps = {
  className: null,
  description: null,
  endsAt: null,
  hasStarted: false,
  progressBarDetailsComponent: null,
  startsAt: null,
  userIsParticipating: false,
}
