import React from 'react'
import PropTypes from 'prop-types'

const Sidebar = ({ className }) => (
  <div className={className} data-test="sidebar-test">
    <div className="border-b-2">
      <h2 className="font-bold mx-3 text-gray text-lg">
        Your Estimated Carbon Footprint is
      </h2>
      <p className="my-10 text-lg text-gray">
        <span className="font-medium text-7xl" data-test="carbon-stat-test">
          5200
        </span>
        Tons CO2 a Year
      </p>
    </div>
    <div className="my-10">
      <span
        className="font-extrabold text-gray"
        data-test="comparison-stat-test"
      >
        17%
      </span>
      <p className="text-gray">Better than average.</p>
    </div>
  </div>
)

Sidebar.propTypes = {
  className: PropTypes.string,
}

Sidebar.defaultProps = {
  className: '',
}

export default Sidebar
