// Vendor
import React, { useRef, useState } from 'react'
import { defineMessages } from 'react-intl'
import Formsy from 'formsy-react'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

// WeSpire
import { client } from 'utilities/we_apollo'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import FormErrorSummary from 'components/form/error_summary'
import { intl } from 'utilities/localization'
import { NewsFeedItemFormInputs } from 'components/form/news_feed_item_form_inputs'
import { selectionValues } from 'components/form/query_autocomplete'
import { sharedMessages } from 'components/shared/translations'

const messages = defineMessages({
  addTheComment: {
    defaultMessage: 'add the comment',
    id: 'newsfeedForm.addTheComment',
  },
})

const { formatMessage } = intl

const NewsfeedForm = ({
  className,
  createStoryMutation,
  handleCommentFocus,
  handleOpenImage,
  handleOpenMentions,
  mutationName,
  refetchQueries,
  storyableId,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  let closeImageAndMentionsFields
  const closeImageAndMentionsFieldsHook = (hook) =>
    (closeImageAndMentionsFields = hook)
  let formRef = useRef()

  // Clear any potential error.
  const handleChange = () => {
    formRef.current && formRef.current.updateInputsWithError({ note: null })
  }

  const handleSubmit = (model, resetForm, invalidateForm) => {
    // Show an error if nothing was added
    if (
      !model.note &&
      !model.image &&
      (!model.taggedUserSelections || model.taggedUserSelections.length === 0)
    ) {
      invalidateForm({
        note: formatMessage(sharedMessages.formErrorValidationText),
      })
      return
    }

    setIsSubmitting(true)

    client
      .mutate({
        awaitRefetchQueries: true,
        mutation: createStoryMutation,
        refetchQueries: refetchQueries,
        variables: {
          id: storyableId,
          storyAttributes: {
            image: model.image,
            note: model.note,
            taggedUserIds: selectionValues(model.taggedUserSelections),
          },
        },
      })
      .then(
        ({
          data: {
            [mutationName]: { errors },
          },
        }) => {
          setIsSubmitting(false)
          if (errors.length) {
            displayBanner({
              as: 'div',
              content: <FormErrorSummary errors={errors} />,
              variant: 'error',
            })
          } else {
            // Reset our form and close any opened fields.
            formRef.current.reset()
            closeImageAndMentionsFields()
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: formatMessage(messages.addTheComment),
        })
      })
  }

  return (
    <Paper
      className={className}
      component={Formsy}
      onChange={handleChange}
      onValidSubmit={handleSubmit}
      ref={formRef}
    >
      <NewsFeedItemFormInputs
        closeImageAndMentionsFieldsHook={closeImageAndMentionsFieldsHook}
        handleCommentFocus={handleCommentFocus}
        handleOpenImage={handleOpenImage}
        handleOpenMentions={handleOpenMentions}
        isSubmitting={isSubmitting}
        withSubmitButton
      />
    </Paper>
  )
}

NewsfeedForm.propTypes = {
  className: PropTypes.string,
  createStoryMutation: PropTypes.object.isRequired,
  handleCommentFocus: PropTypes.func,
  handleOpenImage: PropTypes.func,
  handleOpenMentions: PropTypes.func,
  mutationName: PropTypes.string.isRequired,
  refetchQueries: PropTypes.array.isRequired,
  storyableId: PropTypes.string.isRequired,
}

NewsfeedForm.defaultProps = {
  className: '',
  handleCommentFocus: null,
  handleOpenImage: null,
  handleOpenMentions: null,
}

export { NewsfeedForm }
