import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import { addValidationRule } from 'formsy-react'
import FormFieldset from 'components/form/fieldset'
import Stack from 'components/ui/stack'
import { sharedTranslations } from 'components/shared/translations'
import CurrencySelect from 'components/form/currency_select'
import FormTextField from 'components/form/text_field'
import FormLimitedTextField from 'components/form/limited_text_field'
import FormCheckbox from 'components/form/checkbox'
import ScreenReaderText from 'components/ui/screen_reader_text'
import Link from 'components/shared/link'
import { donationFormMessages as messages } from '../utilities/messages'
import { intl } from 'utilities/localization'
import InputAdornment from '@material-ui/core/InputAdornment'

import { GlobalGivingProjectAutocomplete } from './project_autocomplete'

export const DonationSection = ({
  brandHasEnabledCountries,
  defaultCurrency,
  donationNotesLength,
  donationMatchingEnabled,
  givingaUser,
  matched,
  selectedCurrency,
  isEmployerFunded,
  donationAmount,
  handleDonationCurrency,
  selectedCurrencyMatchingFunds,
  showProjects,
}) => {
  addValidationRule(
    'insufficientFunds',
    (_, value) => !(!sufficientFunds && value)
  )

  const { availableDonationFunds, employeeAccountBalance } = givingaUser
  const hasFunds = availableDonationFunds > 0
  const shouldSelect =
    !isEmployerFunded &&
    matched &&
    hasFunds &&
    donationAmount <= (selectedCurrencyMatchingFunds || availableDonationFunds)

  const [sufficientFunds, setSufficientFunds] = useState(true)
  const [matchingFundsChecked, setMatchingFundsChecked] = useState(shouldSelect)
  useEffect(() => {
    if (
      donationAmount &&
      parseFloat(donationAmount) >
        (selectedCurrencyMatchingFunds || availableDonationFunds)
    ) {
      return setSufficientFunds(false)
    } else {
      return setSufficientFunds(true)
    }
  }, [donationAmount, selectedCurrencyMatchingFunds, matchingFundsChecked])
  const currencyId = selectedCurrency?.id || defaultCurrency?.id
  const isoCode = selectedCurrency?.isoCode || defaultCurrency?.isoCode

  const minimumDonationAmount =
    selectedCurrency?.minimumDonationAmount ||
    defaultCurrency?.minimumDonationAmount

  const symbol = selectedCurrency?.symbol || defaultCurrency?.symbol

  const currencyName = selectedCurrency?.name || defaultCurrency?.name

  return (
    <FormFieldset label={sharedTranslations.yourDonation}>
      <Stack className="row" space={{ _: 3, md: 0 }}>
        <div className="col-md-6">
          <CurrencySelect
            defaultCurrency={defaultCurrency}
            forceUSD={isEmployerFunded || !brandHasEnabledCountries}
            formControlProps={{
              disabled: isEmployerFunded || !brandHasEnabledCountries,
            }}
            name="donationCurrency"
            onCurrencyChange={handleDonationCurrency}
            required={!isEmployerFunded}
            textFieldProps={{
              helperText: intl.formatMessage(messages.helperText, {
                minimumAmount: (
                  <FormattedNumber
                    currency={isoCode}
                    style="currency"
                    value={minimumDonationAmount}
                  />
                ),
              }),
              label: 'Choose Currency',
            }}
            value={currencyId}
          />
        </div>
        <div className="col-md-6">
          <FormTextField
            name="donationAmount"
            required
            textFieldProps={{
              helperText: '',
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">{symbol}</InputAdornment>
                ),
              },
              label: (
                <>
                  {intl.formatMessage(messages.donationAmount)}
                  <ScreenReaderText text={`in ${currencyName}`} />
                </>
              ),
              type: 'number',
            }}
            validationErrors={{
              isMoney: intl.formatMessage(messages.amountValidation, {
                amount: (
                  <FormattedNumber
                    currency={isoCode}
                    style="currency"
                    value={minimumDonationAmount}
                  />
                ),
                currency: currencyName,
              }),
              maxValue: intl.formatMessage(messages.maximumValidation, {
                maximumAmount: employeeAccountBalance,
              }),
              minValue: intl.formatMessage(messages.minimumValidation, {
                minimumAmount: (
                  <FormattedNumber
                    currency={isoCode}
                    style="currency"
                    value={minimumDonationAmount}
                  />
                ),
              }),
            }}
            validations={
              isEmployerFunded
                ? `isMoney,maxValue:${employeeAccountBalance},minValue:10`
                : `isMoney,minValue:${minimumDonationAmount}`
            }
            value={donationAmount}
          />
        </div>
      </Stack>

      {showProjects ? (
        <GlobalGivingProjectAutocomplete
          name="projectName"
          searchOnFocus
          required
          textFieldProps={{
            helperText: intl.formatMessage(messages.projectNameHelper),
            label: intl.formatMessage(messages.projectName),
          }}
          validationErrors={{
            isNotBlank: intl.formatMessage(messages.projectNameNotBlank),
          }}
          validations="isNotBlank"
        />
        ) : (
        <FormLimitedTextField
          helperText={intl.formatMessage(messages.donationNoteHelpText)}
          label={intl.formatMessage(messages.donationNote)}
          maxLength={parseInt(donationNotesLength)}
          multiline
          name="donationNote"
        />
        )
      }

      <FormCheckbox
        helperText={intl.formatMessage(messages.anonymousDonationHelpText)}
        labelProps={{
          label: intl.formatMessage(messages.anonymousDonation),
        }}
        name="anonymousDonation"
        value={false}
      />
      {donationMatchingEnabled && hasFunds && !isEmployerFunded && (
        <>
          <FormCheckbox
            checkboxProps={{
              disabled: !matched,
            }}
            className="mt-2"
            helperText={
              sufficientFunds
                ? matched
                  ? intl.formatMessage(messages.sufficientFunds)
                  : intl.formatMessage(messages.contactSupport, {
                      link: (...linkText) => (
                        <Link
                          style={{ display: 'contents' }}
                          to="mailto:support@wespire.com?subject=Charity%20Match%20Ineligible"
                        >
                          {linkText}
                        </Link>
                      ),
                    })
                : ''
            }
            isValid={sufficientFunds}
            labelProps={{
              label: intl.formatMessage(messages.requestMatchingGift),
            }}
            name="matched"
            onChange={() => setMatchingFundsChecked(!matchingFundsChecked)}
            validationError={intl.formatMessage(
              messages.aboveMaxMatchedDonation
            )}
            validations="insufficientFunds"
            value={matchingFundsChecked}
          />
        </>
      )}
    </FormFieldset>
  )
}

DonationSection.propTypes = {
  brandHasEnabledCountries: PropTypes.bool.isRequired,
  defaultCurrency: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isoCode: PropTypes.string.isRequired,
    minimumDonationAmount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
  }).isRequired,
  donationAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  donationMatchingEnabled: PropTypes.bool.isRequired,
  donationNotesLength: PropTypes.string.isRequired,
  givingaUser: PropTypes.shape({
    availableDonationFunds: PropTypes.number,
    employeeAccountBalance: PropTypes.number,
  }).isRequired,
  handleDonationCurrency: PropTypes.func.isRequired,
  isEmployerFunded: PropTypes.bool.isRequired,
  matched: PropTypes.bool.isRequired,
  selectedCurrency: PropTypes.shape({
    id: PropTypes.string,
    isoCode: PropTypes.string,
    minimumDonationAmount: PropTypes.number,
    name: PropTypes.string,
    symbol: PropTypes.string,
  }),
  selectedCurrencyMatchingFunds: PropTypes.number.isRequired,
  showProjects: PropTypes.bool.isRequired,
}

DonationSection.defaultProps = {
  selectedCurrency: null,
}
