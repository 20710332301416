// Vendor
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages, FormattedRelativeTime } from 'react-intl'
import Formsy from 'formsy-react'
import { Paper } from '@material-ui/core'

// WeSpire
import { abbreviatedDateWithDay } from 'utilities/date_formatter'
import { ActionInfoBuilder } from './action_info'
import { ACTION_TRACKING_TYPE_DAILY, activityGoalText } from './utils'
import { ActionTimeframeModel } from './action_timeframe_model'
import { CampaignModel } from './campaign_model'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'
import { isAfter, now, parseDate } from 'utilities/date_utils'
import { ErrorList } from 'components/form/error_list'
import { FormDetail, FormDetails } from 'components/form/form_details'
import FormFieldset from 'components/form/fieldset'
import FormSelect from 'components/form/select'
import FormTextField from 'components/form/text_field'
import { intl } from 'utilities/localization'
import { LEADERBOARD_QUERY_NAME } from 'components/campaign/leaderboard/utils/queries'
import { FormDateField } from 'components/form/date_field'
import { NewsFeedItemFormInputs } from 'components/form/news_feed_item_form_inputs'
import { selectionValues } from 'components/form/query_autocomplete'
import { setNotification } from 'redux/reducers/notification'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import Stack from 'components/ui/stack'
import store from 'redux/store'
import SubmitButton from 'components/ui/submit_button'
import TextWithHelpIconModal from 'components/ui/text_with_help_icon_modal'
import { logProgressOnAccumulationActionMutation } from 'graphql/mutations/logProgressOnAccumulationActionMutation'
import { shareProgressOnAccumulationActionMutation } from 'graphql/mutations/shareProgressOnAccumulationActionMutation'
import UserActivityLogs from './user_activity_logs'
import { ConnectedDevicesHelpInfo } from './action_log_connected_device_help'

const messages = defineMessages({
  actionTimeFrameEnded: {
    defaultMessage:
      'The timeframe for logging your progress in this Action ended {relativeTime}.',
    description:
      'Empty state text displayed instead of the log progress form when the action has a custom timeframe that has ended.',
    id: 'actionLogProgressForm.actionTimeFrameEnded',
  },
  actionTimeFrameNotStarted: {
    defaultMessage:
      'Come back {relativeTime} to start logging progress on this Action!',
    description:
      'Empty state text displayed instead of the log progress form when the action has a custom timeframe that has not started yet.',
    id: 'actionLogProgressForm.actionTimeFrameNotStarted',
  },
  activityGoalLabel: {
    defaultMessage: 'Activity Goal',
    description:
      'Label for form detail describing the user activity goal for making progress on this Action, e.g. 1000 Steps.',
    id: 'actionLogProgressForm.activityGoalLabel',
  },
  activityGoalLabelDailyTracking: {
    defaultMessage: 'Daily Goal',
    description:
      'Label for form detail describing the user activity goal for making progress on this Action, e.g. 1000 Steps. (used when action is a daily goal tracking)',
    id: 'actionLogProgressForm.activityGoalLabelDailyTracking',
  },
  activityGoalLabelUserTarget: {
    defaultMessage: 'Your Activity Goal',
    description:
      'Label for form detail describing the user activity goal for making progress on this Action, e.g. 1000 Steps. (used when user has configured a personal goal)',
    id: 'actionLogProgressForm.activityGoalLabelUserTarget',
  },
  activityTimeFrameLabel: {
    defaultMessage: 'Activity Time Frame',
    description:
      'Label for form detail describing the user activity time frame for when this Action can be completed, e.g. Nov 20 - Nov 25.',
    id: 'actionLogProgressForm.activityTimeFrameLabel',
  },
  activityTypeLabel: {
    defaultMessage: 'Activity Type',
    description:
      'Label for form detail describing the user activity type being measured for this Action, e.g. Running.',
    id: 'actionLogProgressForm.activityTypeLabel',
  },
  categoryHelperText: {
    defaultMessage: 'Which type of activity did you do?',
    description: 'Helper text for the category select field.',
    id: 'actionLogProgressForm.categoryHint',
  },
  categoryLabel: {
    defaultMessage: 'Activity Type',
    description:
      'Label for a select field where users can choose what category of activity they are manually logging.',
    id: 'actionLogProgressForm.categoryLabel',
  },
  dailyMaximumTrackingLabel: {
    defaultMessage: 'Daily Maximum',
    description:
      'Label for form detail describing the user activity daily max limit. (used when action is daily goal tracking)',
    id: 'actionLogProgressForm.dailyMaximumTrackingLabel',
  },
  dailyMaxLoggingContent: {
    defaultMessage:
      "Your total reflects the max daily amount of <bold>{metricName}</bold> for each day, and any {metricName} logged beyond that limit won't be counted towards your progress.",
    description:
      'Content for modal with detailed description of the user activity daily max limit.',
    id: 'actionLogProgressForm.dailyMaxLoggingContent',
  },
  dailyMaxLoggingLabel: {
    defaultMessage: 'Activity Daily Max Limit',
    description:
      'Label for form detail describing the user activity daily max limit.',
    id: 'actionLogProgressForm.dailyMaxLoggingLabel',
  },
  formHint: {
    defaultMessage: `View and update your progress towards this Action's goal.`,
    description: `Helper text explaining the purpose of this form for Action's that let users log and measure progress.`,
    id: 'actionLogProgressForm.formHint',
  },
  formHintManualDisabled: {
    defaultMessage: `View your progress towards this Action's goal.`,
    description: `Helper text explaining the purpose of this form for Action's that don't let users manually log and measure progress.`,
    id: 'actionLogProgressForm.formHintManualDisabled',
  },
  formLabel: {
    defaultMessage: `Track Your Progress`,
    description: `Label for the log progress form.`,
    id: 'actionLogProgressForm.formLabel',
  },
  manualLogDisabledInfo: {
    defaultMessage:
      'A connected device is logging your progress in this Action.',
    description: `Helper text reminding the user that log is being made from a connected device`,
    id: 'actionLogProgressForm.manualLogDisabledInfo',
  },
  manualLogDisabledInfoNoLogs: {
    defaultMessage: 'A connected device will log your progress in this Action.',
    description: `Helper text reminding the user that log is being made from a connected device`,
    id: 'actionLogProgressForm.manualLogDisabledInfoNoLogs',
  },
  occurredOnHelperText: {
    defaultMessage: 'When did you log this activity?',
    description: 'Helper text for the occurred on date picker field.',
    id: 'actionLogProgressForm.occurredOnHint',
  },
  occurredOnLabel: {
    defaultMessage: 'Date',
    description:
      'Label for a date picker field where users can choose when they performed the activity they are manually logging.',
    id: 'actionLogProgressForm.occurredOnLabel',
  },
  occurredOnValidationError: {
    defaultMessage: 'Must be a past date',
    description:
      'Inline error message displayed occurredOn date is in the future',
    id: 'actionLogProgressForm.occurredOnValidationError',
  },
  operation: {
    defaultMessage: 'log your progress',
    id: 'actionLogProgressForm.operation',
  },
  submitButton: {
    defaultMessage: 'Log Your Progress',
    description: 'Label for button that submits the form',
    id: 'actionLogProgressForm.submitButton',
  },
  submitButtonForShare: {
    defaultMessage: 'Share',
    description:
      'Label for button that submits the form when manual logging is disabled, expected to only share news',
    id: 'actionLogProgressForm.submitButtonForShare',
  },
  successMessage: {
    defaultMessage:
      "We've recorded your {metricText}. Let's keep up the great work!",
    description:
      'Text for the success notification displayed when logging action progress',
    id: 'actionLogProgressForm.successMessage',
  },
  successMessageDailyTracking: {
    defaultMessage:
      "We've recorded your {metricText}. Way to go! Daily goal met for {date}.",
    description:
      'Text for the success notification displayed when tracking type is daily but daily goal is not met',
    id: 'actionLogProgressForm.successMessageDailyTracking',
  },
  successMessageNoManualLogging: {
    defaultMessage: "Thanks for sharing - and let's keep up the great work!",
    description:
      'Text for the success notification displayed when logging action progress on an action with a daily max value',
    id: 'actionLogProgressForm.successMessageNoManualLogging',
  },
  successMessageWithDailyMax: {
    defaultMessage:
      "We've recorded your {metricText} but will track it up to the daily maximum of {maxMetricText}.",
    description:
      'Text for the success notification displayed when logging action progress on an action with a daily max value',
    id: 'actionLogProgressForm.successMessageWithDailyMax',
  },
  totalInstancesHelperText: {
    defaultMessage: 'How many times did you log this activity on this date?',
    description: 'Helper text for the value number field.',
    id: 'actionLogProgressForm.totalInstancesHelperText',
  },
  totalLabel: {
    defaultMessage: 'Total',
    description:
      'Label for a select field where users can choose what how much of or how many times they performed the activity they are manually logging.',
    id: 'actionLogProgressForm.totalLabel',
  },
  totalSumHelperText: {
    defaultMessage: 'What amount of activity did you log on this date?',
    description: 'Helper text for the value number field.',
    id: 'actionLogProgressForm.totalSumHelperText',
  },
  valueValidationError: {
    defaultMessage: 'Total must be a whole number greater than zero',
    description:
      'Inline error message displayed when the form value is a negative or fractional value',
    id: 'actionLogProgressForm.valueValidationError',
  },
})

const { formatMessage } = intl

/**
 * Form intended to be used on "Accumulation Actions" aka Actions with enabled
 * Conditions. It provides a set of form fields that allow users to manually log
 * progress related to the progress metric of the act's condition, like logging
 * 10000 steps within the Action time frame. */
const ActionLogProgressForm = ({
  accumulationActionTrackingType,
  actionId,
  actionTimeframe,
  availableCategories,
  campaign,
  className,
  conditionMetricName,
  dailyMaxLogging,
  dataTest,
  hasOverrideTargetAmount,
  isManualLoggingDisabled,
  isSubmitDisabled,
  progressCategory,
  progressMeasuredAs,
  progressTargetValue,
  userProgressActivityLogs,
}) => {
  let formRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const hasCustomProgressMetric = progressCategory === 'custom'
  const isInstances = hasCustomProgressMetric

  let closeImageAndMentionsFields
  const closeImageAndMentionsFieldsHook = (hook) =>
    (closeImageAndMentionsFields = hook)

  const successMessage = ({ date, dailyGoalMet, overDailyMax, value }) => {
    const goalTextVariables = (count) => ({
      conditionMetricName,
      count,
      progressCategory,
      progressMeasuredAs,
    })
    if (isManualLoggingDisabled) {
      return formatMessage(messages.successMessageNoManualLogging)
    }
    if (
      accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY &&
      dailyGoalMet
    ) {
      return formatMessage(messages.successMessageDailyTracking, {
        date: abbreviatedDateWithDay(new Date(date)),
        metricText: activityGoalText(goalTextVariables(value)),
      })
    } else if (overDailyMax) {
      return formatMessage(messages.successMessageWithDailyMax, {
        maxMetricText: activityGoalText(goalTextVariables(dailyMaxLogging)),
        metricText: activityGoalText(goalTextVariables(value)),
      })
    } else {
      return formatMessage(messages.successMessage, {
        metricText: activityGoalText(goalTextVariables(value)),
      })
    }
  }

  const activityTypeText = (progressCategory) => {
    const label = {
      running: sharedTranslations.running,
      walking: sharedTranslations.walking,
    }[progressCategory]

    return label
  }

  const getStoryAttributes = ({ image, note, taggedUserSelections }) => {
    return {
      image,
      note,
      taggedUserIds: selectionValues(taggedUserSelections),
    }
  }

  const handleSubmit = ({
    image,
    note,
    occurredOn,
    progressCategory,
    taggedUserSelections,
    value,
  }) => {
    const { id: campaignId, isCompetition } = campaign

    let refetchQueries = []

    if (isCompetition) {
      refetchQueries.push(LEADERBOARD_QUERY_NAME)
    }

    const variables = {
      actionId,
      category: progressCategory,
      measuredAs: progressMeasuredAs,
      occurredOn,
      storyAttributes: getStoryAttributes({
        image,
        note,
        taggedUserSelections,
      }),
      value: parseInt(value),
    }

    setIsSubmitting(true)
    const mutation = isManualLoggingDisabled
      ? shareProgressOnAccumulationActionMutation
      : logProgressOnAccumulationActionMutation
    mutation({
      campaignId,
      refetchQueries,
      variables,
    })
      .then(({ data }) => {
        const { errors, dailyGoalMet, overDailyMax } = isManualLoggingDisabled
          ? data?.shareProgressOnAccumulationAction
          : data?.logProgressOnAccumulationAction

        onComplete(
          errors,
          successMessage({
            dailyGoalMet,
            date: occurredOn,
            overDailyMax,
            value,
          })
        )
      })
      .catch(() => {
        displayExceptionBanner({
          operation: formatMessage(messages.operation),
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const maxDate = () => {
    let dates = [parseDate(actionTimeframe.endsAt), now()]
    return new Date(Math.min.apply(null, dates))
  }

  const onComplete = (errors, successMessage) => {
    if (errors) {
      displayBanner({
        as: 'div',
        content: <ErrorList errors={errors} />,
        variant: 'error',
      })
    } else {
      formRef.current.reset()
      closeImageAndMentionsFields()
      store.dispatch(setNotification(successMessage))
    }
  }

  const activityGoalLabel = () => {
    if (hasOverrideTargetAmount) {
      return formatMessage(messages.activityGoalLabelUserTarget)
    } else if (accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY) {
      return formatMessage(messages.activityGoalLabelDailyTracking)
    } else {
      return formatMessage(messages.activityGoalLabel)
    }
  }

  const activityMaxLoggingLabel = () => {
    if (accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY) {
      return formatMessage(messages.dailyMaximumTrackingLabel)
    }
    return formatMessage(messages.dailyMaxLoggingLabel)
  }

  const validateOcurredOnDate = (values, value) => {
    // Formsy validations are chained, we return true when
    // value === 'Invalid date' because there was a validation error
    // before this one on the validation chain and we want it to take preference
    // over this one.
    if (value === 'Invalid date' || isAfter(value)) {
      return true
    }

    return formatMessage(messages.occurredOnValidationError)
  }

  const formHint = () => {
    return campaign.hasActionWithDisabledManualLogging
      ? formatMessage(messages.formHintManualDisabled)
      : formatMessage(messages.formHint)
  }

  let notRunningBannerText
  if (actionTimeframe.notStarted) {
    notRunningBannerText = formatMessage(messages.actionTimeFrameNotStarted, {
      relativeTime: (
        <b>
          <FormattedRelativeTime
            numeric="auto"
            unit={actionTimeframe.relativeTimeParams.unit}
            value={actionTimeframe.relativeTimeParams.value}
          />
        </b>
      ),
    })
  } else if (actionTimeframe.ended) {
    notRunningBannerText = formatMessage(messages.actionTimeFrameEnded, {
      relativeTime: (
        <b>
          <FormattedRelativeTime
            numeric="auto"
            unit={actionTimeframe.relativeTimeParams.unit}
            value={actionTimeframe.relativeTimeParams.value}
          />
        </b>
      ),
    })
  }

  return (
    <Stack
      as={Formsy}
      className={cx('w-100', className)}
      data-test={dataTest}
      onValidSubmit={handleSubmit}
      ref={formRef}
      space={3}
    >
      <FormFieldset hint={formHint()} label={formatMessage(messages.formLabel)}>
        <FormDetails>
          <FormDetail data-test="activity-goal" label={activityGoalLabel()}>
            {activityGoalText({
              accumulationActionTrackingType,
              conditionMetricName,
              count: progressTargetValue,
              progressCategory,
              progressMeasuredAs,
            })}
          </FormDetail>
          {progressCategory && !hasCustomProgressMetric && (
            <FormDetail label={formatMessage(messages.activityTypeLabel)}>
              {activityTypeText(progressCategory)}
            </FormDetail>
          )}
          {dailyMaxLogging && (
            <FormDetail
              data-test="activity-max-logging"
              label={activityMaxLoggingLabel()}
            >
              <TextWithHelpIconModal
                modalContent={
                  <p>
                    {formatMessage(messages.dailyMaxLoggingContent, {
                      bold: (str) => <b>{str}</b>,
                      metricName: conditionMetricName || progressMeasuredAs,
                    })}
                  </p>
                }
                text={activityGoalText({
                  conditionMetricName,
                  count: dailyMaxLogging,
                  progressCategory,
                  progressMeasuredAs,
                })}
                title={formatMessage(messages.dailyMaxLoggingLabel)}
              />
            </FormDetail>
          )}
        </FormDetails>

        {userProgressActivityLogs?.length > 0 && (
          <UserActivityLogs
            accumulationActionTrackingType={accumulationActionTrackingType}
            userActivityLogs={userProgressActivityLogs}
          />
        )}

        {notRunningBannerText ? (
          <Stack className="d-flex align-items-center">
            <ActionInfoBuilder>{notRunningBannerText}</ActionInfoBuilder>
          </Stack>
        ) : (
          <>
            {(isManualLoggingDisabled || progressCategory !== 'custom') && (
              <ConnectedDevicesHelpInfo
                lastDeviceLogSyncedAt={campaign.lastDeviceLogSyncedAt}
              />
            )}

            {!isManualLoggingDisabled && (
              <>
                <FormDateField
                  datePickerProps={{
                    maxDate: maxDate(),
                    minDate: parseDate(actionTimeframe.startsAt),
                  }}
                  helperText={formatMessage(messages.occurredOnHelperText)}
                  label={formatMessage(messages.occurredOnLabel)}
                  name="occurredOn"
                  required
                  validations={{ validateOcurredOnDate }}
                  value={null}
                />

                <FormSelect
                  // If category is specified hide this select and always send it with form submission.
                  hidden={!!progressCategory}
                  name="progressCategory"
                  options={availableCategories.map(({ label, value }) => ({
                    label,
                    value,
                  }))}
                  required
                  textFieldProps={{
                    helperText: formatMessage(messages.categoryHelperText),
                    label: formatMessage(messages.categoryLabel),
                  }}
                  value={progressCategory || ''}
                />

                <FormTextField
                  name="value"
                  required
                  textFieldProps={{
                    helperText: formatMessage(
                      messages[
                        isInstances
                          ? 'totalInstancesHelperText'
                          : 'totalSumHelperText'
                      ]
                    ),
                    label: formatMessage(messages.totalLabel),
                    type: 'number',
                  }}
                  validationErrors={{
                    isInt: formatMessage(messages.valueValidationError),
                    minValue: formatMessage(messages.valueValidationError),
                  }}
                  validations="isInt,minValue:1"
                />
              </>
            )}
          </>
        )}
      </FormFieldset>

      {!notRunningBannerText && (
        <>
          <FormFieldset
            hint={formatMessage(
              sharedMessages.formFieldShareWithOthersHelperText
            )}
            label={sharedTranslations.shareWithOthers}
          >
            <Paper>
              <NewsFeedItemFormInputs
                closeImageAndMentionsFieldsHook={
                  closeImageAndMentionsFieldsHook
                }
                isRequired={isManualLoggingDisabled}
              />
            </Paper>
          </FormFieldset>
          <div className="d-flex">
            <SubmitButton
              className="ml-auto"
              color="primary"
              data-test="action-log-progress-submit"
              disabled={isSubmitDisabled}
              isSubmitting={isSubmitting}
              variant="contained"
            >
              {formatMessage(
                isManualLoggingDisabled
                  ? messages.submitButtonForShare
                  : messages.submitButton
              )}
            </SubmitButton>
          </div>
        </>
      )}
    </Stack>
  )
}

ActionLogProgressForm.propTypes = {
  /** type of accumulation action: progres over time | daily */
  accumulationActionTrackingType: PropTypes.string,
  /** id of action for which user is logging progress */
  actionId: PropTypes.string.isRequired,
  /** model that corresponds to act.timeframe */
  actionTimeframe: PropTypes.instanceOf(ActionTimeframeModel).isRequired,
  availableCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  /** optional classes appended to parent node */
  className: PropTypes.string,
  /** optional, configurable label displayed when condition has custom metric */
  conditionMetricName: PropTypes.string,
  /** optional, value of action daily max logging */
  dailyMaxLogging: PropTypes.number,
  /** optional data-test attribute applied to parent node for test querying */
  dataTest: PropTypes.string,
  /** optional end date in the act.timeframe date range */
  endsAt: PropTypes.string,
  /** true if action has a custom override target amount */
  hasOverrideTargetAmount: PropTypes.bool,
  isManualLoggingDisabled: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool,
  /** user_activity_log.progressCategory used by action with condition */
  progressCategory: PropTypes.oneOf(['custom']),
  /** user_activity_log.measured_as used by action with condition */
  progressMeasuredAs: PropTypes.oneOf(['duration', 'steps', 'custom']),
  /** goal number for meeting 100% of action condition */
  progressTargetValue: PropTypes.number.isRequired,
  /** optional start date in the act.timeframe date range */
  startsAt: PropTypes.string,
  /** current user activity logs for action, if it has a condition enabled  */
  userProgressActivityLogs: PropTypes.array.isRequired,
}

ActionLogProgressForm.defaultProps = {
  accumulationActionTrackingType: null,
  availableCategories: [],
  className: null,
  conditionMetricName: null,
  dailyMaxLogging: null,
  dataTest: 'action-log-progress-form',
  endsAt: null,
  hasOverrideTargetAmount: false,
  isSubmitDisabled: false,
  progressCategory: null,
  progressMeasuredAs: null,
  startsAt: null,
}

export { ActionLogProgressForm }
