import React, { useContext } from 'react'
import { CalculatorContext } from 'components/CarbonCalculator/contexts/CalculatorContext'
import AutocompleteComponent from 'components/CarbonCalculator/components/AutocompleteComponent'
import PropTypes from 'prop-types'
import ToggleButtonGroupComponent from 'components/CarbonCalculator/components/ToggleButtonGroupComponent'
import SliderComponent from 'components/CarbonCalculator/components/SliderComponent'
import LocationCodeInputComponent from 'components/CarbonCalculator/components/LocationCodeInputComponent'
import QuestionSubHeader from 'components/CarbonCalculator/components/QuestionSubHeader'
import { VEHICLE_FUEL_EFFICIENCY } from 'components/CarbonCalculator/contexts/constants'

const AnswerComponentSelector = ({
  answers,
  min,
  max,
  steps,
  helperDescriptionText,
  componentSelector,
  questionKey,
  subheader,
}) => {
  const {
    answerState: { countryCode, vehicleFuelType1 },
  } = useContext(CalculatorContext)
  const isElectricSelected =
    vehicleFuelType1 === 3 && questionKey === VEHICLE_FUEL_EFFICIENCY
  return (
    <>
      {subheader && !isElectricSelected && (
        <QuestionSubHeader subHeader={subheader} />
      )}
      <div data-test="answer-component-selector-test">
        {/* hide the slider if electric vehicle is selected */}
        {componentSelector === 'Slider' && !isElectricSelected && (
          <SliderComponent
            questionKey={questionKey}
            answers={answers}
            min={min}
            max={max}
            steps={steps}
            helperDescriptionText={helperDescriptionText}
          />
        )}
        {componentSelector === 'ToggleButtonGroup' && (
          <ToggleButtonGroupComponent
            questionKey={questionKey}
            answers={answers}
          />
        )}
        {componentSelector === 'Autocomplete' && (
          <AutocompleteComponent questionKey={questionKey} answers={answers} />
        )}
        {componentSelector === 'LocationCode' &&
          (countryCode === 'US' || countryCode === 'CA') && (
            <LocationCodeInputComponent questionKey={questionKey} />
          )}
      </div>
    </>
  )
}

AnswerComponentSelector.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      descriptionText: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  min: PropTypes.number,
  max: PropTypes.number,
  steps: PropTypes.number,
  helperDescriptionText: PropTypes.string,
  componentSelector: PropTypes.string.isRequired,
  questionKey: PropTypes.string,
  subheader: PropTypes.string,
}

AnswerComponentSelector.defaultProps = {
  answers: [{ descriptionText: '', label: '', value: null }],
  min: null,
  max: null,
  steps: null,
  helperDescriptionText: '',
  questionKey: '',
  subheader: '',
}

export default AnswerComponentSelector
