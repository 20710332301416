import { defineMessages } from 'react-intl'
import { formatMessages } from 'utilities/localization'

export const messages = defineMessages({
  emptyState: {
    defaultMessage: 'There are no Broadcasts yet.',
    id: 'broadcastsMessages.emptyState',
  },
  published: {
    defaultMessage: 'Published',
    id: 'broadcastsMessages.published',
  },
})

export const broadcastsMessages = formatMessages(messages)
