const NavigatorProps = {
  avatarUrl: '/assets/avatar_default.png',
  brandLogoUrl: '',
  linkPaths: {
    achievementsPath: '/achievements',
    activitiesPath: '/take_action',
    backPath: '/take_action',
    dashboardPath: '/dashboard',
    donatePath: '/app/donation_form',
    groupsPath: '/groups',
    invitePath: '/invites/new',
    notificationsPath: '/notifications',
    ourImpactPath: '/our_impact',
    profilePath: '/profile',
    recognizePath: '/recognize/new',
    rootPath: '/',
    searchPath: '/search',
    settingsPath: '/user/edit',
    supportPath: 'mailto:support@wespire.com',
    teamsPath: '/teams',
  },
}

export default NavigatorProps
