import React from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

// westyle
import Input from 'src/common/Input'
import InputChoice from 'src/common/InputChoice'
import SectionContainer from 'src/common/SectionContainer'

const MessageSection = ({
  note,
  setNote,
  anonymousDonation,
  setAnonymousDonation,
}) => {
  const intl = useIntl()

  const { setFieldValue } = useFormikContext()

  const handleNoteChange = (event) => {
    setNote(event.target.value)
    setFieldValue('note', event.target.value)
  }

  const handleAnonymousChange = (event) => {
    setAnonymousDonation(event.target.checked)
    setFieldValue('anonymous', event.target.checked)
  }

  return (
    <SectionContainer
      title={intl.formatMessage({
        defaultMessage: 'Your message to the charity (optional)',
        id: 'dollarsForDoersForm.yourMessageToTheCharity',
      })}
    >
      <div className="px-4">
        <Input
          className="w-full"
          explainer={intl.formatMessage({
            defaultMessage: "Use this section to share additional information, such as an honoree, purpose, or another friendly note why you're donating that will be visible to the employees of the charity who receive your donation.",
            id: 'donationForm.donationNoteHelpText',
          })}
          name="note"
          placeholder={intl.formatMessage({
            defaultMessage: 'Share an optional note with the charity',
            id: 'donationForm.donationNote',
          })}
          value={note}
          onChange={handleNoteChange}
        />
        <InputChoice
          className="mt-4"
          explainer={intl.formatMessage({
            defaultMessage: 'If checked, your name and email will be withheld from the charity.',
            id: 'donationForm.withholdEmailFromCharity',
          })}
          label={intl.formatMessage({
            defaultMessage: 'Share an optional note with the charity',
            id: 'donationForm.donationNote',
          })}
          name="anonymous"
          type="checkbox"
          checked={anonymousDonation}
          onChange={handleAnonymousChange}
        />
      </div>
    </SectionContainer>
  )
}

export default MessageSection
