// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { defineMessages, FormattedNumber } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import Stack from 'components/ui/stack'
import { intl } from 'utilities/localization'

export const messages = defineMessages({
  Emissions: {
    defaultMessage: 'emissions',
    id: 'sustainabilityImpactStat.emissions',
  },
  emissionsResult: {
    defaultMessage: 'or {value} CO2',
    id: 'sustainabilityImpactStat.emissionsResult',
  },
  Energy: {
    defaultMessage: 'energy',
    id: 'sustainabilityImpactStat.energy',
  },
  energyResult: {
    defaultMessage: 'or {value} kWh energy',
    id: 'sustainabilityImpactStat.energyResult',
  },
  Fuel: {
    defaultMessage: 'fuel',
    id: 'sustainabilityImpactStat.fuel',
  },
  fuelResult: {
    defaultMessage: 'or {result} fuel',
    id: 'sustainabilityImpactStat.fuelResult',
  },
  Trees: {
    defaultMessage: 'trees',
    id: 'sustainabilityImpactStat.trees',
  },
  treesResult: {
    defaultMessage: 'or {value} trees',
    id: 'sustainabilityImpactStat.treesResult',
  },
  Waste: {
    defaultMessage: 'waste',
    id: 'sustainabilityImpactStat.waste',
  },
  wasteResult: {
    defaultMessage: 'or {result} waste',
    id: 'sustainabilityImpactStat.wasteResult',
  },
  Water: {
    defaultMessage: 'water',
    id: 'sustainabilityImpactStat.water',
  },
  waterResult: {
    defaultMessage: 'or {result} water',
    id: 'sustainabilityImpactStat.waterResult',
  },
})

export const SustainabilityImpactStat = ({
  equivalent,
  name,
  units,
  impactValue,
  ...other
}) => {
  const { formatMessage } = intl

  const getUnitTranslation = () => {
    // according to this unit list https://formatjs.io/docs/polyfills/intl-numberformat/#simple-units
    // we need to pass the correct unit to <FormattedNumber /> otherwise if the
    // unit is not included in the list, we use the default value localized but include
    // the unit in the default message as in the case of energy where kWh is hardcoded
    // to the message to be translated.
    const impactUnits = {
      gal: 'gallon',
      kg: 'kilogram',
      l: 'liter',
      lbs: 'pound',
    }

    const getFormattedNumber = (impactValue, impactUnit = null) => {
      if (impactUnit) {
        return (
          <FormattedNumber
            style="unit"
            unit={impactUnit}
            unitDisplay="long"
            value={impactValue}
          />
        )
      } else {
        return <FormattedNumber value={impactValue} />
      }
    }

    switch (name) {
      case 'Emissions':
        return formatMessage(messages.emissionsResult, {
          value: getFormattedNumber(impactValue, impactUnits[units]),
        })
      case 'Energy':
        return formatMessage(messages.energyResult, {
          value: getFormattedNumber(impactValue),
        })
      case 'Fuel':
        return formatMessage(messages.fuelResult, {
          result: getFormattedNumber(impactValue, impactUnits[units]),
        })
      case 'Trees':
        return formatMessage(messages.treesResult, {
          value: getFormattedNumber(impactValue),
        })
      case 'Waste':
        return formatMessage(messages.wasteResult, {
          result: getFormattedNumber(impactValue, impactUnits[units]),
        })
      case 'Water':
        return formatMessage(messages.waterResult, {
          result: getFormattedNumber(impactValue, impactUnits[units]),
        })
      default:
        break
    }
  }

  return (
    <div
      {...other}
      className={cx('d-flex align-items-center', other.className)}
      data-test="sustainability-impact-stat"
      key={name}
    >
      <Icon
        className="fs-7 mr-3 text-green"
        iconName={`susty_${name.toLowerCase()}`}
        title={name}
      />
      <Stack space={1}>
        <p className="fs-2 text-black-3 text-sentence">
          {formatMessage(messages[name])}
        </p>
        <p className="fs-2 fw-semi-bold text-green">{equivalent}</p>
        <p className="fs-1 fw-semi-bold">{getUnitTranslation()}</p>
      </Stack>
    </div>
  )
}

SustainabilityImpactStat.propTypes = {
  equivalent: PropTypes.string.isRequired,
  impactValue: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
}
