import React, { useEffect, useState, useContext } from 'react'

// WeSpire
import store from 'redux/store' // FML
import WeSpireAPI from 'utilities/wespire_api'
import SessionContext from 'src/contexts/SessionContext'

import TextHero from 'src/common/TextHero'

const SessionDebugForm = ({ userId }) => {
  let { brand, user } = useContext(SessionContext)
  const [cookies, setCookies] = useState({})
  const [debugData, setDebugData] = useState({})

  useEffect(() => {
    const url = !!userId ? `/admin/users/${userId}/debug.json` : '/api/session/debug.json'
    WeSpireAPI({ baseUrl: '/'})
    .get(url)
    .then((response) => {
      setDebugData(response.data)
    })
  },[userId])

  function parseCookieString(cookieString) {
    const keyValuePairs = cookieString.split('; ')

    const parsedCookies = {}

    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split('=')

      parsedCookies[decodeURIComponent(key)] = decodeURIComponent(value)
    })

    return parsedCookies
  }

  useEffect(() => {
    setCookies(parseCookieString(document.cookie))
  }, [document.cookie])

  const formatValue = (value) => {
    if (value === true) {
      return '✅';
    }

    if (value === false) {
      return '❌';
    }

    return JSON.stringify(value)
  }

  return (
    <main className="grid justify-items-center mt-20 pb-8">
      <TextHero subtitle={'Session Debugger'} title={brand?.name} />
      <div className="max-w-[37.5rem] w-11/12 px-4">
        <section className="flex flex-col mt-20">
          <div className="flex flex-col">
            <table>
              <tbody>
                { !userId && <>
                  <tr>
                    <td colSpan="2">
                      <h2>Redux</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>Redux token</td>
                    <td>{store.getState().session.token || 'null'}</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h2>Cookies</h2>
                    </td>
                  </tr>
                  {Object.entries(cookies).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="2">
                      <h2>Session</h2>
                    </td>
                  </tr>
                  {Object.entries(user || {}).map(([key, value]) => (
                    <tr key={key}>
                      <td>{JSON.stringify(key)}</td>
                      <td>{formatValue(value)}</td>
                    </tr>
                  ))}
                </>}
                <tr>
                  <td colSpan="2">
                    <h2>Debug</h2>
                  </td>
                </tr>
                {Object.entries(debugData).map(([key, value]) => (
                  <tr key={key}>
                    <td>{JSON.stringify(key)}</td>
                    <td>{formatValue(value)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </main>
  )
}

const SessionDebug = ({ match }) => {
  const userId = match.params.user_id;
  return (
    <>
      <SessionDebugForm userId={userId} />
    </>
  );
};

export default SessionDebug
