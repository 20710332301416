import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

import { intl } from 'utilities/localization'
import { transactionInfoMessages as messages } from './utilities/messages'

const TransactionInfo = (props) => {
  const {
    amount,
    charity,
    currency,
    nextDonationDate,
    isRecurring,
    matchingEnabled,
    matchingGiftRequested,
  } = props

  const content = isRecurring ? (
    <>
      <div className="text-left">
        <div className="d-inline-block pt-2 mr-2 text-black-4 fw-bold">
          {intl.formatMessage(messages.monthlyDonationAmount)}:
        </div>
        <span className="fs-2 fw-bold">
          <FormattedNumber
            currency={currency}
            style="currency"
            value={amount}
          />
        </span>
      </div>
      <div className="text-left">
        <div className="d-inline-block pt-2 mr-2 text-black-4 fw-bold">
          {intl.formatMessage(messages.nextDonationDate)}:
        </div>
        <span className="fs-2 fw-bold">{nextDonationDate}</span>
      </div>
    </>
  ) : (
    <>
      <div className="text-left">
        <div className="d-inline-block pt-2 mr-2 text-black-4 fw-bold">
          {intl.formatMessage(messages.donationAmount)}:
        </div>
        <span className="fs-2 fw-bold">
          <FormattedNumber
            currency={currency}
            style="currency"
            value={amount}
          />
        </span>
      </div>
      {matchingEnabled && (
        <div className="text-left" data-test="matching">
          <div className="d-inline-block pt-2 mr-2 text-black-4 fw-bold">
            {intl.formatMessage(messages.matchingGiftRequested)}:
          </div>
          <span className="fs-2 fw-bold">
            {matchingGiftRequested
              ? intl.formatMessage(messages.matchingGiftRequestedYes)
              : intl.formatMessage(messages.matchingGiftRequestedNo)}
          </span>
        </div>
      )}
    </>
  )

  return (
    <div>
      <div className="text-left">
        <div className="d-inline-block pt-2 mr-2 text-black-4 fw-bold">
          {intl.formatMessage(messages.charity)}:
        </div>
        <span className="fs-2 fw-bold">{charity}</span>
      </div>
      {content}
    </div>
  )
}

TransactionInfo.defaultProps = {
  matchingGiftRequested: false,
  nextDonationDate: null,
}

TransactionInfo.propTypes = {
  amount: PropTypes.number.isRequired,
  charity: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  isRecurring: PropTypes.bool.isRequired,
  matchingEnabled: PropTypes.bool.isRequired,
  matchingGiftRequested: PropTypes.bool,
  nextDonationDate: PropTypes.string,
}

export default TransactionInfo
