import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Vendors
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

// WeSpire
import { intl } from 'utilities/localization'
import { oneMonthFromToday } from 'utilities/date_utils'
import FormFieldset from 'components/form/fieldset'
import { donationFormMessages as messages } from '../utilities/messages'

export const RecurringDonationsSection = ({
  handleDonationRecurrence,
  isRecurring,
}) => {
  const nextMonthDate = oneMonthFromToday()
  return (
    <FormFieldset
      hint={intl.formatMessage(messages.recurringDonationsHelperText)}
      label={intl.formatMessage(messages.recurringDonationsLabel)}
    >
      <RadioGroup
        aria-label="donationRecurrence"
        data-test="donation-recurrence"
        onChange={handleDonationRecurrence}
        required
        value={isRecurring.toString()}
      >
        <FormControlLabel
          control={
            <Radio
              color="primary"
              inputProps={{ 'data-test': 'donation-recurrence-one-time' }}
              name="one-time"
            />
          }
          label={intl.formatMessage(messages.oneTime)}
          value="false"
        />
        <FormControlLabel
          control={
            <Radio
              color="primary"
              inputProps={{ 'data-test': 'donation-recurrence-monthly' }}
              name="monthly"
            />
          }
          label={intl.formatMessage(messages.monthly)}
          value="true"
        />
      </RadioGroup>
      {isRecurring && (
        <FormHelperText className="m-0">
          {intl.formatMessage(messages.yourRecurringDonations, {
            donationHistory: (
              <Link to="/users/me/donation_history">
                {intl.formatMessage(messages.myDonations)}
              </Link>
            ),
            nextMonthDate,
          })}
        </FormHelperText>
      )}
    </FormFieldset>
  )
}

RecurringDonationsSection.propTypes = {
  handleDonationRecurrence: PropTypes.func.isRequired,
  isRecurring: PropTypes.bool.isRequired,
}
