// Setup
import React, { Fragment } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { defineMessages } from 'react-intl'

// Vendor
import { Dropdown } from './dropdown'
import { MobileAvatar } from './mobile_avatar'
import MainNavLink from './main_nav_link'
import BurgerIconButton from 'components/application/burger/button'
import CenteredContent from 'components/application/centered_content'
import Link from 'components/shared/link'
import Icon from 'components/ui/icon'
import ScreenReader from 'components/ui/screen_reader_text'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import { displayUserTour } from 'redux/dispatchers'
import { useIsMissingLegalDocuments } from 'utilities/hooks/use_is_missing_legal_documents'

const messages = defineMessages({
  displayPageHints: {
    defaultMessage: 'show page hints for this page',
    id: 'header.displayPageHints',
  },
  notificationsWithCount: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {notifications} one {notifications} two {notifications} few {notifications} many {notifications} other {notifications}}',
    id: 'header.notificationsWithCount',
  },
})

const Header = ({
  avatarUrl,
  brandLogoUrl,
  carbonFootprintEnabled,
  className,
  enableUserTour,
  firstName,
  givingEnabled,
  isAuthenticated,
  linkPaths,
  mainLinks,
  managementDropdownNavigationLinks,
  notificationsCount,
  sideNavIsOpen,
  toggleSideNav,
  unreadNotificationsCount,
  volunteerHistoryEnabled,
}) => {
  const { formatMessage } = intl
  const isMissingLegalDocumentAcceptance = useIsMissingLegalDocuments()
  const showButtons = isAuthenticated && !isMissingLegalDocumentAcceptance

  return (
    <header
      className={cx(
        'bg-white border-bottom position-fixed z-2 w-100 px-3 py-2 legacyStyles',
        className
      )}
      data-test="primary-nav"
      id="primary-nav"
    >
      <CenteredContent fullWidth>
        <div className="row flex-nowrap | align-items-center">
          <div
            className={cx('d-flex align-items-center', {
              'z-5': sideNavIsOpen,
            })}
          >
            {showButtons && (
              <BurgerIconButton
                className="[ fs-4 ml-2 p-2 ] [ hide-lg-up ]"
                data-test="side-nav-toggle"
                isActive={sideNavIsOpen}
                onClick={toggleSideNav}
              />
            )}
            <Link
              className="[ nav-link ] [ col-auto ] [ px-lg-2 ]"
              forceReload={!showButtons}
              to={showButtons ? linkPaths.backPath : linkPaths.rootPath}
            >
              <Image
                alt=""
                className="logo logo--sm | pr-2"
                crop="fit"
                dpr="2.0" // TODO: Figure out why this image doesn't auto-detect DPR
                height="40"
                src={brandLogoUrl}
                width="120"
              />
              <ScreenReader text="home" />
            </Link>
          </div>
          {showButtons &&
            mainLinks.map((link) => (
              <Fragment key={link.id}>
                {link.shouldDisplay && (
                  <MainNavLink
                    href={link.href}
                    isActive={location.pathname.includes(link.href)}
                    title={link.id}
                  />
                )}
              </Fragment>
            ))}
          <div className="flex-grow" />
          {showButtons && (
            <>
              {enableUserTour && (
                <IconButton
                  className="px-1 px-lg-2 mx-lg-1"
                  data-test="user-tour-icon"
                  onClick={displayUserTour}
                >
                  <Icon
                    iconName="help_outline"
                    title={formatMessage(messages.displayPageHints)}
                  />
                </IconButton>
              )}
              <Link
                className="[ nav-link nav-link--icon ] [ d-flex ] [ fs-5 ml-1 text-black-3 ] [ show-lg-up ]"
                to={linkPaths.searchPath}
              >
                <Icon iconName="magnify" title="Search" />
              </Link>
              <Link
                className="[ nav-link nav-link--icon ] [ d-flex ] [ fs-5 ml-1 text-black-3 ] [ pl-1 pl-lg-2 ml-lg-1 ]"
                to={linkPaths.notificationsPath}
              >
                <Badge
                  badgeContent={<span aria-hidden>{notificationsCount}</span>}
                  color="primary"
                  invisible={notificationsCount === 0}
                >
                  <Icon
                    iconName="bell"
                    title={formatMessage(messages.notificationsWithCount, {
                      count: unreadNotificationsCount,
                      stringifiedCount: notificationsCount,
                    })}
                  />
                </Badge>
              </Link>
              <Dropdown
                avatarUrl={avatarUrl}
                carbonFootprintEnabled={carbonFootprintEnabled}
                className="[ ml-1 ] [ show-lg-up ]"
                givingEnabled={givingEnabled}
                managementDropdownNavigationLinks={
                  managementDropdownNavigationLinks
                }
                name={firstName}
                volunteerHistoryEnabled={volunteerHistoryEnabled}
              />
              <Link
                className="[ ml-1 ] [ hide-lg-up ]"
                to={linkPaths.profilePath}
              >
                <MobileAvatar avatarUrl={avatarUrl} />
              </Link>
            </>
          )}
        </div>
      </CenteredContent>
    </header>
  )
}

Header.propTypes = {
  avatarUrl: PropTypes.string,
  brandLogoUrl: PropTypes.string.isRequired,
  carbonFootprintEnabled: PropTypes.bool,
  className: PropTypes.string,
  enableUserTour: PropTypes.bool,
  firstName: PropTypes.string,
  givingEnabled: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  linkPaths: PropTypes.shape({
    achievementsPath: PropTypes.string.isRequired,
    activitiesPath: PropTypes.string.isRequired,
    backPath: PropTypes.string.isRequired,
    dashboardPath: PropTypes.string.isRequired,
    invitePath: PropTypes.string.isRequired,
    notificationsPath: PropTypes.string.isRequired,
    profilePath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
    searchPath: PropTypes.string.isRequired,
    teamsPath: PropTypes.string.isRequired,
  }).isRequired,
  mainLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  managementDropdownNavigationLinks: PropTypes.array.isRequired,
  notificationsCount: PropTypes.number,
  sideNavIsOpen: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  unreadNotificationsCount: PropTypes.number,
  volunteerHistoryEnabled: PropTypes.bool,
}

Header.defaultProps = {
  avatarUrl: null,
  carbonFootprintEnabled: null,
  className: '',
  enableUserTour: null,
  firstName: null,
  givingEnabled: null,
  isAuthenticated: false,
  notificationsCount: null,
  unreadNotificationsCount: null,
  volunteerHistoryEnabled: null,
}

export { Header }
