// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { LeaderBoardLeaders } from './leaders'
import { YourRankingList } from './your_ranking_list'
import { LeaderBoardEmptyState } from './empty_state'
import { LeaderBoardSection } from './leaderboard_section'
import { leaderBoardTranslations } from './utils/shared_translations'

const LeaderboardCompetitors = ({
  competitors,
  competitorsForUser,
  forTeams,
}) => {
  if (competitors.length === 0) {
    return (
      <LeaderBoardSection heading={leaderBoardTranslations.leaders}>
        <LeaderBoardEmptyState />
      </LeaderBoardSection>
    )
  }

  const notOnLeaderBoard = !competitors.some(
    (competitor) => competitor.highlight
  )
  const showUserRanking = competitorsForUser.length > 0 && notOnLeaderBoard

  return (
    <>
      <LeaderBoardSection heading={leaderBoardTranslations.leaders}>
        <LeaderBoardLeaders
          displayCount={showUserRanking ? 3 : 6}
          forTeams={forTeams}
          leaders={competitors}
        />
      </LeaderBoardSection>
      {showUserRanking && (
        <LeaderBoardSection
          heading={leaderBoardTranslations.yourRanking}
          headingClassName="[ mt-3 | mt-md-0 ]"
        >
          <YourRankingList
            competitors={competitorsForUser}
            forTeams={forTeams}
          />
        </LeaderBoardSection>
      )}
    </>
  )
}

LeaderboardCompetitors.propTypes = {
  competitors: PropTypes.array.isRequired,
  competitorsForUser: PropTypes.array.isRequired,
  forTeams: PropTypes.bool,
}

LeaderboardCompetitors.defaultProps = {
  forTeams: false,
}

export { LeaderboardCompetitors }
