import React from 'react'

const NotificationsButton = () => (
  <a href={'/notifications'}>
    <span className="material-symbols-rounded border-[0.06rem] bg-gallery border-grayscale-2 text-2xl h-10 px-2 py-[0.4rem] rounded-full sm:mx-2 w-10">
      notifications
    </span>
  </a>
)

export default NotificationsButton
