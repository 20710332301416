// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import ExternalLink from 'components/ui/external_link'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { parseDate } from 'utilities/date_utils'
import Stack from 'components/ui/stack'
import { useArchiveResourceMutation, useRestoreResourceMutation } from './utils'

const { formatMessage, formatDate } = intl

const messages = defineMessages({
  archiveResource: {
    defaultMessage: 'Archive Resource',
    id: 'GroupResourcesRow.archiveResource',
  },
  editResource: {
    defaultMessage: 'Edit Resource',
    id: 'GroupResourcesRow.editResource',
  },
  unarchiveResource: {
    defaultMessage: 'Restore Resource',
    id: 'GroupResourcesRow.unarchiveResource',
  },
  viewResource: {
    defaultMessage: 'View Resource',
    id: 'GroupResourcesRow.viewResource',
  },
})

export const GroupResourceRow = ({
  createdAt,
  createdBy,
  deletedAt,
  groupId,
  id,
  readonly,
  resourceUrl,
  title,
  updatedAt,
  updatedBy,
  ...props
}) => {
  const { handleOnArchiveResource, archiveLoading } =
    useArchiveResourceMutation({
      groupId,
      id,
    })

  const { handleOnRestoreResource, restoreLoading } =
    useRestoreResourceMutation({
      groupId,
      id,
    })

  const handleToggleArchiveUnarchive = () => {
    if (deletedAt && !restoreLoading) {
      handleOnRestoreResource()
    } else if (!deletedAt && !archiveLoading) {
      handleOnArchiveResource()
    }
  }

  const archiveUnarchiveButtonText = () => {
    if (deletedAt) {
      return formatMessage(messages.unarchiveResource)
    } else {
      return formatMessage(messages.archiveResource)
    }
  }

  const archiveUnarchiveButtonIcon = () => {
    if (deletedAt) {
      return 'upload'
    } else {
      return 'delete'
    }
  }

  return (
    <TableRow
      className="row"
      data-test="group-resource-row"
      hover
      key={id}
      tabIndex={-1}
      {...props}
    >
      <TableCell className="col-5" component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="right" className="col-2">
        {formatDate(parseDate(updatedAt || createdAt), {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </TableCell>
      <TableCell align="right" className="col-3">
        {(updatedBy || createdBy)?.name}
      </TableCell>
      <TableCell align="right" className="col-2">
        <Stack className="d-flex justify-content-end align-items-center">
          <ExternalLink
            className="px-1 d-flex"
            data-test="view-resource-button"
            hideIcon
            to={resourceUrl}
          >
            <Icon
              className="fs-4 text-black-4"
              iconName="visibility"
              title={formatMessage(messages.viewResource)}
            />
          </ExternalLink>

          {!readonly && (
            <LinkButton
              className="mnw-0 py-0 px-1"
              data-test="edit-resource-button"
              to={`resources/${id}/edit`}
              variant="text"
            >
              <Icon
                className="fs-4 text-black-4"
                iconName="edit"
                title={formatMessage(messages.editResource)}
              />
            </LinkButton>
          )}

          {!readonly && (
            <Button
              className="mnw-0 py-0 px-1"
              data-test="toggle-archive-unarchive-resource-button"
              onClick={handleToggleArchiveUnarchive}
              variant="text"
            >
              <Icon
                className="fs-4 text-black-4"
                iconName={archiveUnarchiveButtonIcon()}
                title={archiveUnarchiveButtonText()}
              />
            </Button>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  )
}

GroupResourceRow.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.shape({
    name: PropTypes.string,
  }),
  deletedAt: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  resourceUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.shape({
    name: PropTypes.string,
  }),
}

GroupResourceRow.defaultProps = {
  createdAt: null,
  createdBy: null,
  deletedAt: null,
  readonly: null,
  updatedAt: null,
  updatedBy: null,
}
