// Vendor
import React from 'react'
import cx from 'classnames'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import v from 'voca'

// WeSpire
import Avatar from 'components/application/avatar'
import Link from 'components/shared/link'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { formatTeamsNamesAsString } from '../utils'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { sharedTranslations } from 'components/shared/translations'

const messages = defineMessages({
  anonymousMember: {
    defaultMessage: 'Anonymous member',
    id: 'groupMember.anonymousMember',
  },
  leadershipEmailSubject: {
    defaultMessage: '{groupName} - {brandName} question',
    description: 'Email subject when user is asking questions to a leadership',
    id: 'groupMember.leadershipEmailSubject',
  },
})

const { formatMessage } = intl

const emailHrefAttribute = ({ brandName, email, groupName }) =>
  `mailto:${email}?Subject=${formatMessage(messages.leadershipEmailSubject, {
    brandName,
    groupName,
  })}`

const groupMemberNameId = (name, title = null) => {
  return `group-${title ? title : 'member'}-named-${v.kebabCase(name)}`
}

function getRoute(id) {
  return `/users/${id}`
}

function getRoleUserRoute(roleId) {
  const [klass, id] = roleId.split('-')
  if (klass === 'User') return getRoute(id)
}

const GroupMember = ({
  avatarUrl,
  brandName,
  className,
  'data-test': dataTest,
  groupName,
  id,
  isLeader,
  listItemComponent,
  name,
  teams,
  title,
  email,
  visibleUser,
}) => {
  const redirectUrl = isLeader ? getRoleUserRoute(id) : getRoute(id)

  return (
    <ListItem
      className={cx('px-3', className)}
      component={listItemComponent}
      data-test={dataTest}
    >
      {redirectUrl ? (
        <Link data-test="user-avatar-link-profile" to={redirectUrl}>
          <UserAvatar avatarUrl={avatarUrl} />
        </Link>
      ) : (
        <UserAvatar avatarUrl={avatarUrl} />
      )}
      <ListItemText
        className="px-3"
        primary={
          <>
            {redirectUrl ? (
              <Link data-test="user-name-link-profile" to={redirectUrl}>
                <Name name={name} title={title} />
              </Link>
            ) : (
              <Name name={name} title={title} />
            )}
            {title && (
              <>
                <BulletSpacer />
                <span className="fw-semi-bold">{title}</span>
              </>
            )}
            {visibleUser === false && (
              <>
                <BulletSpacer />
                <span
                  aria-label={`${groupMemberNameId(name, title)}}-leader`}
                  className="fw-semi-bold"
                >
                  {formatMessage(messages.anonymousMember)}
                </span>
              </>
            )}
          </>
        }
        secondary={
          teams.length > 0 && (
            <span data-test="teams">{formatTeamsNamesAsString(teams)}</span>
          )
        }
      />
      {email && (
        <LinkButton
          aria-describedby={groupMemberNameId(name, title)}
          className="fs-1 mr-2"
          data-test="leader-email-button"
          to={emailHrefAttribute({ brandName, email, groupName })}
          variant="neutral"
        >
          {sharedTranslations.email}
        </LinkButton>
      )}
    </ListItem>
  )
}

const Name = ({ name, title }) => {
  return (
    <span
      className="fw-semi-bold text-black-1"
      id={groupMemberNameId(name, title)}
    >
      {name}
    </span>
  )
}

const UserAvatar = ({ avatarUrl }) => (
  <ListItemAvatar>
    <Avatar
      aria-label="avatar"
      className="border border-blue-2 d-block m-auto rounded-circle"
      data-test="user-icon"
      image={avatarUrl}
      size="lg"
    />
  </ListItemAvatar>
)

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
}

Name.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
}

Name.defaultProps = {
  title: null,
}

GroupMember.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  brandName: PropTypes.string,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  email: PropTypes.string,
  groupName: PropTypes.string,
  id: PropTypes.string.isRequired,
  isLeader: PropTypes.bool,
  listItemComponent: PropTypes.string,
  name: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  visibleUser: PropTypes.bool,
}

GroupMember.defaultProps = {
  brandName: null,
  className: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': null,
  email: null,
  groupName: null,
  isLeader: false,
  listItemComponent: 'li',
  title: null,
  visibleUser: null,
}

export { GroupMember }
