import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import Stack from 'components/ui/stack'
import { Stat } from 'components/ui/stat'

import { groupOrChapterLabel } from 'components/setup/groups_management/utils'

const { formatMessage } = intl

const QUERY = gql`
  query groupsListQuery($groupId: ID!) {
    group(id: $groupId) {
      id
      membersCount
      newMembersMonthCount
      newMembersYearCount
      recentActivities {
        email
        name
        userJoinedAt
        userLeftAt
        visible
      }
      isChapter
    }
  }
`

const messages = defineMessages({
  anonymousUser: {
    defaultMessage: 'Private Member',
    id: 'groupDetailsTab.anonymousUser',
  },
  emptyLogsLabel: {
    defaultMessage: 'No recent activity logs were found',
    id: 'groupDetailsTab.emptyLogsLabel',
  },
  heading: {
    defaultMessage: '{type} Summary',
    id: 'groupDetailsTab.heading',
  },
  membersKPI: {
    defaultMessage: 'Members',
    id: 'groupDetailsTab.membersKPI',
  },
  newMembersKPI: {
    defaultMessage: 'New Members This Month',
    id: 'groupDetailsTab.newMembersKPI',
  },
  recentActivity: {
    defaultMessage: 'Recent Membership Activity',
    id: 'groupDetailsTab.recentActivity',
  },
  recentActivityJoinMessage: {
    defaultMessage: 'joined on { dateText }',
    id: 'groupDetailsTab.recentActivityJoinMessage',
  },
  recentActivityLeftMessage: {
    defaultMessage: 'left on { dateText }',
    id: 'groupDetailsTab.recentActivityLeftMessage',
  },
  welcomeSubject: {
    defaultMessage: 'Welcome to { groupName }',
    id: 'groupDetailsTab.welcomeSubject',
  },
})

export const GroupSummary = ({ groupId, groupName }) => {
  const { data, loading } = useQuery(QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      groupId,
    },
  })
  const group = data?.group

  if (loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  const getMessage = (activity) => {
    let message = null

    if (activity.userJoinedAt) {
      message = (
        <>
          {' '}
          {formatMessage(messages.recentActivityJoinMessage, {
            dateText: intl.formatDate(activity.userJoinedAt, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          })}
        </>
      )
    }

    if (activity.userLeftAt) {
      message = (
        <>
          {' '}
          {formatMessage(messages.recentActivityLeftMessage, {
            dateText: intl.formatDate(activity.userLeftAt, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          })}
        </>
      )
    }

    return message
  }

  const stats = [
    {
      amount: group?.membersCount,
      id: 'membersCount',
      label: formatMessage(messages.membersKPI),
    },
    {
      amount: group?.newMembersMonthCount,
      id: 'newMembersMonthCount',
      label: formatMessage(messages.newMembersKPI),
    },
  ]

  return (
    <Stack as="section" className="mt-2" space={4}>
      <Heading className="fs-4 text-uppercase " level={2}>
        {formatMessage(messages.heading, { type: groupOrChapterLabel(group) })}
      </Heading>

      <Stack className="row mx-0" space={0}>
        {stats?.map((stat, index) => (
          <Paper
            className={cx('col-6 col-md-3 p-3', { 'ml-4': index > 0 })}
            data-test={`stat-${stat.id}`}
            key={stat.id}
          >
            <Stat size={6} {...stat} />
          </Paper>
        ))}
      </Stack>

      <Paper className="w-100 py-3 px-4">
        <Heading
          className="fs-2 fw-semi-bold text-uppercase text-black-3"
          level={3}
        >
          {formatMessage(messages.recentActivity)}
        </Heading>

        <Stack as="ol" className="p-0 mb-0 list-none" space={3}>
          {group?.recentActivities.map((activity) => {
            return (
              <li
                className="fs-1"
                data-test="recent-activity-log"
                key={activity.name}
              >
                {!activity.visible && (
                  <span className="fw-bold text-black-3">
                    [{formatMessage(messages.anonymousUser)}]{' '}
                  </span>
                )}
                <span className="fw-bold">{activity.name}</span> (
                <Link
                  className="text-blue"
                  to={`mailto:${activity.email}?subject=${formatMessage(
                    messages.welcomeSubject,
                    { groupName }
                  )}`}
                >
                  {activity.email}
                </Link>
                ){getMessage(activity)}
              </li>
            )
          })}
          {group?.recentActivities.length === 0 && (
            <li className="fs-2 fw-semi-bold" data-test="empty-activity-log">
              {formatMessage(messages.emptyLogsLabel)}
            </li>
          )}
        </Stack>
      </Paper>
    </Stack>
  )
}

GroupSummary.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
}
