// Setup
import React from 'react'

/**
 A helper component that wraps our speech bubble SVG into a react component.
 This allows us to inline the SVG.
 */
export const SpeechBubble = (props) => (
  <svg aria-hidden height={128} viewBox="0 0 160 128" width={160} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h160v128H0z" fill="#FFF" />
      <path
        d="M87.04 40.96a4.48 4.48 0 1 1 0 8.96H128a4.48 4.48 0 1 1 0 8.96h14.08a4.48 4.48 0 1 1 0 8.96h-12.16a4.48 4.48 0 1 0 0 8.96h3.84a4.48 4.48 0 1 1 0 8.96h-33.28c-.33 0-.65-.036-.96-.103-.31.067-.63.103-.96.103H40.32a4.48 4.48 0 1 1 0-8.96H15.36a4.48 4.48 0 1 1 0-8.96h25.6a4.48 4.48 0 1 0 0-8.96h-16a4.48 4.48 0 1 1 0-8.96h25.6a4.48 4.48 0 1 1 0-8.96h36.48zm57.6 35.84a4.48 4.48 0 1 1 0 8.96 4.48 4.48 0 0 1 0-8.96z"
        fill="#E7F0FB"
      />
      <path
        d="M107.89 80.688a34.935 34.935 0 0 1-5.078 3.391l.106 9.014a1.28 1.28 0 0 1-2.06 1.029l-8.105-6.235A42.594 42.594 0 0 1 83.2 88.96c-19.44 0-35.2-12.894-35.2-28.8s15.76-28.8 35.2-28.8c19.44 0 35.2 12.894 35.2 28.8 0 4.402-1.207 8.574-3.366 12.306a27.33 27.33 0 0 1-1.29 2.018m-1.62 2.095a30.376 30.376 0 0 1-1.86 1.997"
        fill="#FFF"
        stroke="#8BB8ED"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
      <path
        d="M70.4 56.32a3.84 3.84 0 1 1 0 7.68 3.84 3.84 0 0 1 0-7.68zm12.16 0a3.84 3.84 0 1 1 0 7.68 3.84 3.84 0 0 1 0-7.68zm12.16 0a3.84 3.84 0 1 1 0 7.68 3.84 3.84 0 0 1 0-7.68z"
        fill="#F6F9FD"
        stroke="#8BB8ED"
        strokeWidth="2.5"
      />
      <path
        d="M57.384 50.76a21.987 21.987 0 0 0-1.504 3.046M69.134 40.367C64.94 42.368 61.4 45.2 58.841 48.589"
        stroke="#8BB8ED"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
    </g>
  </svg>
)
