// Vendor
import React from 'react'

// WeSpire
import { DocumentTitle } from 'components/shared/document_title'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { intl } from 'utilities/localization'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'

/**
 * A component informing the user that they were unable to find an Event.
 * Designed to be rendered as a full page beneath a header.
 */

const EventMissingResourcePage = () => (
  <DocumentTitle
    title={intl.formatMessage(sharedMessages.resourceNotFound, {
      resource: sharedTranslations.event,
    })}
  >
    <MissingResourcePage resourceName={sharedTranslations.event} />
  </DocumentTitle>
)

export { EventMissingResourcePage }
