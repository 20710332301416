import gql from 'graphql-tag'

export const GET_GROUPS_ANNOUNCEMENTS_DATA = gql`
  query groupForBrand($groupId: ID!) {
    group(id: $groupId) {
      id
      isChapter
      description
      name
      announcementsForAdmin {
        announcementHtml
        createdAt
        id
        publishedAt
        selectedLeaderTitle
        status
        title
        updatedAt
        author {
          id
          avatarUrl
          name
        }
      }
    }
  }
`

export const UPDATE_GROUP_ANNOUNCEMENT = gql`
  mutation updateGroupAnnouncementMutation(
    $announcementHtml: String
    $groupAnnouncementId: ID!
    $title: String
    $status: GroupAnnouncementStatus
  ) {
    updateGroupAnnouncement(
      announcementHtml: $announcementHtml
      groupAnnouncementId: $groupAnnouncementId
      title: $title
      status: $status
    ) {
      errors
    }
  }
`
