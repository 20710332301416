import React from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

// westyle
import InternalLink from 'src/common/InternalLink'
import MatchTransactionDetails from 'src/pages/DonationHistoryPage/components/MatchDonationDetails'
import Numbers from 'src/common/Numbers'
import PaymentSource from 'src/pages/DonationHistoryPage/components/PaymentSource'
import ProgressVisual from 'src/pages/DonationHistoryPage/components/ProgressVisual'
import ScheduledPayment from 'src/pages/DonationHistoryPage/components/ScheduledPayment'

const DonationAssistance = ({ id }) => {
  return (
    <>
      <div>
        <InternalLink
          className="link-text-small-underlined"
          href={`app/donation_success_page/${id}`}
        >
          <FormattedMessage
            id="donationHistory.seeDonationReceipt"
            defaultMessage={'See donation receipt'}
          />
        </InternalLink>
        <span className="px-2 self-center">|</span>
        <InternalLink className="link-text-small-underlined">
          <FormattedMessage
            id="shared.reportAProblem"
            defaultMessage={'Report a problem'}
          />
        </InternalLink>
      </div>
      <p className="pb-4 body-medium-regular">
        <FormattedMessage
          id="donationHistory.yourAmountDonatedMightBeDifferent"
          defaultMessage={
            'PLEASE NOTE: Your amount donated might be different from amount paid to charity due to transaction fees.'
          }
        />
      </p>
    </>
  )
}

const DonationDetails = ({ expandRow, status, transaction }) => {
  const transactionDate = new Date(
    new Date(transaction.clearedDate).toLocaleDateString('en-US')
  ).toLocaleDateString('en-US')

  return (
    <div className={cx('gap-y-4 grid', { hidden: !expandRow })}>
      <div className="grid">
        <div
          className={cx('gap-y-0.5 grid mt-1 grid', {
            'grid-cols-[1fr,1fr] sm:grid-cols-[1fr,2fr] md:grid-cols-[1fr,3fr] lg:grid-cols-[1fr,5fr] xl:grid-cols-[1fr,6fr]':
              status !== 'scheduled',
          })}
        >
          {status === 'scheduled' ? (
            <ScheduledPayment transaction={transaction} />
          ) : (
            <>
              {transaction.paymentMethod !== 'unknown' && (
                <PaymentSource transaction={transaction} />
              )}
              <div className="inline">
                <span className="body-medium-semibold text-black-3">
                  <FormattedMessage
                    id="donationForm.details"
                    defaultMessage={'Donation details'}
                  />
                  :
                </span>
              </div>
              <div>
                {/* recurring currnetly needs figuring out */}
                {transaction.recurring && (
                  <span className="font-semibold leading-4">
                    <FormattedMessage
                      id="donationHistory.monthlyDonation"
                      defaultMessage={'Monthly Donation '}
                    />
                  </span>
                )}
                <span className="font-semibold leading-4">
                  {status === 'pending' ? (
                    <FormattedMessage
                      id="donationHistory.'paidToCharityOn"
                      defaultMessage={`paid to charity on {date}`}
                      values={{
                        date: transactionDate,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="donationHistory.donatedOn"
                      defaultMessage={`donated on {date}`}
                      values={{
                        date: transactionDate,
                      }}
                    />
                  )}
                </span>
                {transaction.givingActivityName && (
                  <span className="body-medium-semibold">
                    {' '}
                    <FormattedMessage
                      id="donationHistory.in"
                      defaultMessage={'in'}
                    />{' '}
                    <InternalLink
                      className="hover:text-smart-blue pr-2 link-text-small-underlined"
                      href={`/giving_activities/${transaction.activityId}`}
                    >
                      {transaction.givingActivityName}
                    </InternalLink>
                  </span>
                )}
              </div>
              <div>
                <span className="body-medium-semibold text-black-3">
                  <FormattedMessage
                    id="donationHistory.donationStatus"
                    defaultMessage={'Donation status'}
                  />
                  :
                </span>
              </div>
              <div className="flex-inline flex-col xl:flex-row content-center">
                <div className="inline content-center">
                  <Numbers
                    className="body-medium-semibold pr-2 align-middle"
                    currency="USD"
                    style="currency"
                  >
                    {transaction.amountPaid}
                  </Numbers>
                </div>
                {transaction.paymentStatus === 'paid' ? (
                  <span className="body-medium-semibold align-middle inline">
                    <FormattedMessage
                      id="donationHistory.paidToCharityOn"
                      defaultMessage={'paid to charity on '}
                      values={{
                        date: transactionDate,
                      }}
                    />
                  </span>
                ) : (
                  <ProgressVisual match={false} status="in progress" />
                )}
              </div>

              {transaction.matchedTransaction && (
                <MatchTransactionDetails transaction={transaction} />
              )}
            </>
          )}
        </div>
      </div>
      {status !== 'scheduled' && <DonationAssistance id={transaction.id} />}
    </div>
  )
}

export default DonationDetails
