// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import WeQuery from 'components/application/we_query'
import { intl } from 'utilities/localization'

export const wordForScoreQuery = gql`
  query brandConfig {
    brandConfig {
      id
      wordForScore
      wordForScorePlural
      wordForScoreShort
    }
  }
`

/** Fetches the wordForScore from the brand's config and returns "{points} {word}".
 *  Word will either be:
 *    1. wordForScore when score === 1
 *    2. wordForScorePlural when score !== 1
 *    3. wordForScoreShort when short === true
 */
export const WordForScore = ({ score, short, wordOnly }) => {
  const { formatMessage } = intl
  const messages = defineMessages({
    scoreWithWord: {
      defaultMessage: '{score} {word}',
      description:
        'Total number of units followed by the word for the unit of measurement (e.g. "10 points")',
      id: 'wordForScore.scoreWithWord',
    },
    wordOnly: {
      defaultMessage: 'points',
      description: 'Word for the unit of measurement',
      id: 'wordForScore.wordOnly',
    },
  })

  return (
    <>
      <WeQuery
        error={<>{formatMessage(messages.wordOnly)}</>}
        loader={
          <span className="invisible">{formatMessage(messages.wordOnly)}</span>
        }
        query={wordForScoreQuery}
      >
        {({
          data: {
            brandConfig: {
              wordForScore,
              wordForScorePlural,
              wordForScoreShort,
            },
          },
        }) => {
          const word = short
            ? wordForScoreShort
            : score === 1
            ? wordForScore
            : wordForScorePlural
          return wordOnly
            ? word
            : formatMessage(messages.scoreWithWord, { score, word })
        }}
      </WeQuery>
    </>
  )
}

WordForScore.propTypes = {
  /** The score to return */
  score: PropTypes.number,
  /** Whether to show wordForScoreShort or not */
  short: PropTypes.bool,
  /** Whether to return just the word without the score or not */
  wordOnly: PropTypes.bool,
}

WordForScore.defaultProps = {
  score: null,
  short: false,
  wordOnly: false,
}
