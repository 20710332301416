import gql from 'graphql-tag'

export const acceptMution = gql`
  mutation AcceptTermsOfService($documentIds: [ID!]!) {
    acceptBrandLegalDocuments(documentIds: $documentIds) {
      errors
    }
  }
`

export const termsOfServiceQuery = gql`
  query termsOfServiceQuery {
    brand {
      name
      id
    }
    currentTermsOfService {
      createdAt
      id
      text
    }
    currentPrivacyPolicy {
      id
    }
    currentUser {
      brandLegalAcceptances {
        totalCount
      }
      id
      missingTermsOfServiceAcceptance
      missingPrivacyPolicyAcceptance
    }
  }
`
