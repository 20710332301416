// Vendor
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { List } from '@material-ui/core'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import Divider from 'components/ui/divider'
import { GroupMember } from './group_member'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { LoadMoreButton } from 'components/shared/load_more_button'
import WeQuery from 'components/application/we_query'

const PAGE_SIZE = 100

const messages = defineMessages({
  members: {
    defaultMessage:
      '{count, plural, zero {Members} one {Member} two {Members} few {Members} many {Members} other {Members}}',
    id: 'membersSection.members',
  },
  membersHeading: {
    defaultMessage: '{title} ({count})',
    id: 'membersSection.groupMembersHeading',
  },
})

const membersQuery = ({
  excludeUser,
  noHiddenMembers,
  noLeaders,
  queryKey,
  withTeamsInCommon,
}) => gql`
  query ${queryKey}Data($id: ID!, $cursor: String) {
    group(id: $id) {
      id
      members(first: ${PAGE_SIZE}, after: $cursor, noLeaders: ${noLeaders}, excludeUser: ${excludeUser}, noHiddenMembers: ${noHiddenMembers}, withTeamsInCommon: ${withTeamsInCommon}) {
        nodes {
          avatarUrl
          id
          name
          teams {
            id
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
      name
      ${queryKey}Count: membersCount(excludeUser: ${excludeUser}, noLeaders: ${noLeaders}, withTeamsInCommon: ${withTeamsInCommon})
      roles {
        id
      }
    }
  }
`

const MembersSection = ({
  emptyMessage,
  errorMessage,
  excludeUser,
  groupId,
  headingMessage,
  headingDataTest,
  memberDataTest,
  noHiddenMembers,
  noLeaders,
  queryKey,
  withTeamsInCommon,
}) => {
  const { formatMessage } = intl
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const errorMessageComponent = errorMessage ? (
    <>
      <p className="p-3">{errorMessage}</p>
      <Divider className="mx-3" />
    </>
  ) : null

  return (
    <WeQuery
      error={errorMessageComponent}
      loader={<SkeletonLoading />}
      query={membersQuery({
        excludeUser,
        noHiddenMembers,
        noLeaders,
        queryKey,
        withTeamsInCommon,
      })}
      variables={{ id: groupId }}
    >
      {({
        data: {
          group: { members, ...group },
        },
        fetchMore,
      }) => {
        const loadMore = (fetchFunction, after) => () => {
          if (isLoadingMore) return

          setIsLoadingMore(true)
          return fetchFunction({
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const oldNodes = previousResult.group.members.nodes
              const newNodes = fetchMoreResult.group.members.nodes

              setIsLoadingMore(false)

              if (newNodes.length) {
                const result = cloneDeep(fetchMoreResult)
                result.group.members.nodes = [...oldNodes, ...newNodes]
                return result
              }

              return previousResult
            },
            variables: {
              cursor: after,
              id: groupId,
            },
          })
        }

        const totalCount = group[queryKey + 'Count']

        if (!emptyMessage && !members.nodes.length) return null

        return (
          <div className="pb-3">
            <Heading
              className="pb-2 pt-4 px-3"
              data-test={headingDataTest}
              level={3}
            >
              {formatMessage(messages.membersHeading, {
                count: totalCount,
                title: headingMessage,
              })}
            </Heading>
            {members.nodes.length ? (
              <List className="p-0">
                {members.nodes.map((member) => (
                  <GroupMember
                    avatarUrl={member.avatarUrl}
                    data-test={memberDataTest}
                    id={member.id}
                    key={member.id}
                    name={member.name}
                    teams={member.teams}
                  />
                ))}
                {members.pageInfo.hasNextPage && (
                  <div className="[ d-flex ] [ px-3 ]">
                    <LoadMoreButton
                      loading={isLoadingMore}
                      newsfeedItemsCount={members.nodes.length}
                      onClick={loadMore(fetchMore, members.pageInfo.endCursor)}
                      pageSize={PAGE_SIZE}
                      totalCount={members.totalCount}
                      type={messages.members}
                    />
                  </div>
                )}
              </List>
            ) : (
              <p className="pb-3 px-3">{emptyMessage}</p>
            )}
          </div>
        )
      }}
    </WeQuery>
  )
}

MembersSection.propTypes = {
  emptyMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  excludeUser: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  headingDataTest: PropTypes.string.isRequired,
  headingMessage: PropTypes.string.isRequired,
  memberDataTest: PropTypes.string.isRequired,
  noHiddenMembers: PropTypes.bool,
  noLeaders: PropTypes.bool,
  queryKey: PropTypes.string.isRequired,
  withTeamsInCommon: PropTypes.bool,
}

MembersSection.defaultProps = {
  emptyMessage: null,
  errorMessage: null,
  excludeUser: null,
  noHiddenMembers: null,
  noLeaders: null,
  withTeamsInCommon: null,
}

export { MembersSection }
