// Setup
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// WeSpire

import Avatar, { AvatarSizes } from 'components/application/avatar'
import { Button as BaseButton } from 'components/ui/button'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import { displayBanner } from 'redux/dispatchers'
import ExternalLink from 'components/ui/external_link'
import { FETCH_STATUS } from 'utilities/fetch_statuses'
import FormErrorSummary from 'components/form/error_summary'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Stack from 'components/ui/stack'
import { undecorated as TextField } from 'components/form/text_field'

const { formatMessage } = intl

const messages = defineMessages({
  add: {
    defaultMessage: 'Add',
    id: 'groupMembersAdd.add',
  },
  addMember: {
    defaultMessage: 'Add members to { groupName }',
    id: 'groupMembersAdd.addMember',
  },
  helperTextForSearch: {
    defaultMessage: 'Search member by email address',
    id: 'groupMembersAdd.helperTextForSearch',
  },
  memberAddedSuccessfully: {
    defaultMessage: '{memberName} successfully added to { groupName }.',
    id: 'groupMembersAdd.memberAddedSuccessfully',
  },
  memberNotFound: {
    defaultMessage: 'No user found with that email address',
    id: 'groupMembersAdd.memberNotFound',
  },
  search: {
    defaultMessage: 'Search',
    id: 'groupMembersAdd.search',
  },
})

const QUERY = gql`
  query brandUserQuery($brandId: ID, $email: String) {
    brand(id: $brandId) {
      id
      users(email: $email) {
        avatarUrl
        id
        name
        profilePath
      }
    }
  }
`

const MUTATION = gql`
  mutation addUserToGroup($groupId: ID!, $userId: ID!, $visible: Boolean!) {
    joinGroup(groupId: $groupId, userId: $userId, visible: $visible) {
      errors
    }
  }
`

export const GroupMembersAdd = ({ brandId, groupId, groupName }) => {
  const [addMemberToGroup, { loading }] = useMutation(MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['groupMembersQuery'],
  })

  const [memberEmail, setMemberEmail] = useState('')
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.PENDING)
  const [member, setMember] = useState(null)

  const handleOnSearchMember = () => {
    const fetch = async () => {
      setFetchStatus(FETCH_STATUS.LOADING)

      try {
        const { data } = await client.query({
          query: QUERY,
          variables: {
            brandId,
            email: memberEmail,
          },
        })
        setMember(data?.brand?.users?.[0])
        setFetchStatus(FETCH_STATUS.SUCCESS)
      } catch {
        setFetchStatus(FETCH_STATUS.ERROR)
      }
    }
    fetch()
  }

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault()
      handleOnSearchMember()
    }
  }

  const handleOnAddMember = async () => {
    try {
      const {
        data: {
          joinGroup: { errors },
        },
      } = await addMemberToGroup({
        variables: {
          groupId,
          userId: member?.id,
          visible: true,
        },
      })
      if (errors?.length > 0) {
        displayBanner({
          as: 'div',
          content: <FormErrorSummary errors={errors} />,
          variant: 'error',
        })
      } else {
        displayBanner({
          content: formatMessage(messages.memberAddedSuccessfully, {
            groupName,
            memberName: member?.name,
          }),
          variant: 'success',
        })
        setMemberEmail('')
        setFetchStatus(FETCH_STATUS.PENDING)
        setMember(null)
      }
    } catch {
      return
    }
  }
  const handleOnAddMemberClick = () => {
    handleOnAddMember()
  }

  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase"
          data-test="all-members-label"
          level={3}
        >
          {formatMessage(messages.addMember, { groupName })}
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack className="d-flex align-items-center" space={0}>
          <TextField
            className="flex-grow mxw-5"
            name="userEmail"
            setValue={setMemberEmail}
            textFieldProps={{
              className: 'pr-0',
              inputProps: {
                'aria-label': 'add member',
                className: 'text-truncate',
              },
              onKeyDown: handleKeyDown,
              placeholder: intl.formatMessage(messages.helperTextForSearch),
              size: 'small',
              type: 'search',
            }}
            value={memberEmail}
          />

          <BaseButton
            className="border border-blue text-blue fs-2 ml-2 mb-1 p-1"
            data-test="search-for-user"
            onClick={handleOnSearchMember}
            variant="text"
          >
            {formatMessage(messages.search)}
            <Icon
              className="fs-4 ml-1"
              iconName="person_search"
              title={formatMessage(messages.search)}
            />
          </BaseButton>
        </Stack>

        {[FETCH_STATUS.LOADING].includes(fetchStatus) && (
          <CenteredPaddedLoadingIndicator />
        )}

        {[FETCH_STATUS.SUCCESS].includes(fetchStatus) && !member && (
          <p className="mxw-measure text-center" data-test="no-user-message">
            {formatMessage(messages.memberNotFound)}
          </p>
        )}

        {[FETCH_STATUS.SUCCESS].includes(fetchStatus) && member && (
          <Stack
            className="d-flex align-items-center justify-content-start"
            data-test="member-search-result"
          >
            <ExternalLink hideIcon to={member.profilePath}>
              <Avatar
                className="mr-2"
                image={member.avatarUrl}
                size={AvatarSizes.small}
              />
            </ExternalLink>
            <Stack className="d-flex flex-column" space={0}>
              <ExternalLink
                className="fw-semi-bold text-blue fs-2"
                hideIcon
                text={member.name}
                to={member.profilePath}
              />
            </Stack>

            <BaseButton
              className="border border-blue text-blue fs-2 ml-auto p-1"
              data-test="member-add-button"
              disabledWithFocus={loading}
              onClick={handleOnAddMemberClick}
              variant="text"
            >
              {formatMessage(messages.add)}
              <Icon
                className="fs-4 ml-1"
                iconName="person_add"
                title={formatMessage(messages.add)}
              />
            </BaseButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

GroupMembersAdd.propTypes = {
  brandId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
}
