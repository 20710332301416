import React from 'react'
import { FormattedMessage } from 'react-intl'

const Header = () => {
    return (
      <header className="grid justify-items-center">
        <span
          className="material-symbols-rounded text-thriving-green"
          style={{ fontSize: '5rem' }}
        >
          task_alt
        </span>
        <span className="font-sans font-semibold leading-10 sm:leading-[4rem] text-4xl sm:text-5xl text-black-1">
          <FormattedMessage id="shared.thankYou" />
        </span>
        <h1 className="leading-6 text-lg sm:leading-10 sm:text-[2rem]">
          <FormattedMessage id="donationSuccess.yourDonationHasBeenMade" />
        </h1>
      </header>
    )
}

export default Header
