import gql from 'graphql-tag'

export const ACTIONS_LIST_QUERY = gql`
  query ACTIONS_LIST_QUERY(
    $brandId: ID!
    $campaignIds: [ID!]
    $channelIds: [ID!]
    $statuses: [CampaignStatus!]
    $year: Int
  ) {
    actions(
      brandId: $brandId
      campaignIds: $campaignIds
      channelIds: $channelIds
      statuses: $statuses
      year: $year
    ) {
      actionEndDate
      actionRequirements
      actionStartDate
      actionType
      campaignChannelName
      campaignEndDate
      campaignId
      campaignName
      campaignPointsType
      campaignStartDate
      cooldownHours
      description
      id
      name
      points
    }
    brandConfig {
      id
      wordForScorePlural
    }
  }
`

export const SUGGESTIONS_LIST_QUERY = gql`
  query SUGGESTIONS_LIST_QUERY {
    channels {
      id
      name
    }
    campaigns {
      nodes {
        id
        name
      }
    }
    campaignStatuses
  }
`
