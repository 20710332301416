import gql from 'graphql-tag'

import { ACTIVITY_CARD_FRAGMENT } from 'components/activities/fragments'

export const CARBON_FOOTPRINT_FOR_MANAGEMENT_QUERY = gql`
  query carbonFootprintForManagementQuery($slug: ID!) {
    contentModule(slug: $slug) {
      id
    }
    carbonImpactCampaigns {
      nodes {
        ...ActivityAttributes
        editPath: editUrl
        status
      }
    }
  }

  ${ACTIVITY_CARD_FRAGMENT}
`
