// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'

// WeSpire
import EventRegistrationOpportunity from 'components/events/registration_opportunities/opportunity'

const EventRegistrationDayGroup = ({ dateTime, label, opportunities }) => (
  <Fragment>
    <h4 className="fs-1 my-3 px-3 text-uppercase">
      <time dateTime={dateTime}>{label}</time>
    </h4>
    <Paper className="fs-2 mb-4 mt-3 px-3 py-2">
      <List className="py-0" dense>
        {opportunities.map(
          (
            { availableSpotsCount, endsAt, eventName, eventUrl, id, startsAt },
            index
          ) => (
            <EventRegistrationOpportunity
              availableSpotsCount={availableSpotsCount}
              className={cx({ 'border-top pt-2 ': index > 0 })}
              dateTime={dateTime}
              endsAt={endsAt}
              eventName={eventName}
              eventUrl={eventUrl}
              key={id}
              startsAt={startsAt}
            />
          )
        )}
      </List>
    </Paper>
  </Fragment>
)

EventRegistrationDayGroup.propTypes = {
  dateTime: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  opportunities: PropTypes.arrayOf(
    PropTypes.shape({
      availableSpotsCount: PropTypes.number,
      endsAt: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      eventUrl: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      startsAt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default EventRegistrationDayGroup
