import { useEffect, useRef, useState } from 'react'

export const useDropdownVisible = ({ initialIsVisible }) => {
  const [dropdownOpen, setDropdownOpen] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref, dropdownOpen, setDropdownOpen }
}
