import { createIntl, createIntlCache } from 'react-intl'
import { getCookie } from 'utilities/cookies'

const backupLocale = 'en-US'

// This code grabs the language that the user has specified in their browser.
// We fallback to several different strategies for accessing that language to
// try to cover all possible browsers that our app supports.
// Check cookie first as this is set in each Rails layout via #set_user_locale
export const locale =
  getCookie('locale') ||
  // eslint-disable-next-line compat/compat
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  backupLocale

const localeLookup = (base) => {
  // Keys correspond to I18n.available_locales
  const langs = {
    bg: 'bg-BG',
    de: 'de-DE',
    el: 'el-GR',
    en: 'en-US',
    es: 'es-LA',
    fr: 'fr-FR',
    id: 'id-ID',
    it: 'it-IT',
    ja: 'ja-JP',
    ko: 'ko-KR',
    nl: 'nl-NL',
    pl: 'pl-PL',
    pt: 'pt-PT',
    ru: 'ru-RU',
    sv: 'sv-SE',
    th: 'th-TH',
    tr: 'tr-TR',
    zh: 'zh-CN',
  }

  return langs[base]
}

const loadLocaleData = (locale, backupLocale) => {
  let data

  // note to the next developer to read this:
  // had to add ${__dirname}/.. to the requires below
  // in order to make tests pass
  // a regular require(`localization/locales/${locale}.json`)
  // works just fine when tested in browser
  // but something about running mocha tests failed in mocha_setup.js
  // at `import messages from 'localization/locales/en-US.json'`
  // and this is the only thing I could figure out to fix it

  try {
    // attempt to load the locale the user wants
    data = require(`${__dirname}/../localization/locales/${locale}.json`)
  } catch (e) {
    try {
      // if the locale could not be loaded, then attempt
      // to load a similar locale
      const altLocale = localeLookup(locale.split('-')[0])
      data = require(`${__dirname}/../localization/locales/${altLocale}.json`)
    } catch (e) {
      // if all else fails, load the backup local
      data = require(`${__dirname}/../localization/locales/${backupLocale}.json`)
    }
  }

  return data
}

// It is unclear if this is needed but the docs recommend it, saying:
// "This is optional but highly recommended since it prevents memory leak".
// https://github.com/formatjs/react-intl/issues/1441#issuecomment-523512846
// mentions it is mostly beneficial for SSR which we don't do, so the memory
// leak may not be relevant for us.
const cache = createIntlCache()
const localeData = loadLocaleData(locale, backupLocale)
export const intl = createIntl(
  {
    locale: locale,
    messages: localeData,
  },
  cache
)

// Helper that will take defined messages (WITHOUT VARIABLES!) and format
// all of them.
export const formatMessages = (messages) => {
  let translations = {}
  Object.entries(messages).forEach(([key, message]) => {
    translations[key] = intl.formatMessage(message)
  })
  return translations
}

// The default argument of 100 allows us to display the translation for a large
// number by default, which is useful for using the translation in UI elements
// like headings and tabs.
export const formatPluralMessage = ({ count = 100, message }) =>
  intl.formatMessage(message, { count })

// Takes a collection of messages with plural templates, and for each message,
// creates a function that will format the message correctly for a given count
// using formatPluralMessage(). This way, in our components, we can access the
// translation for a given key and provide it the right count for that context.
export const formatPluralMessages = (messages) => {
  let translations = {}
  Object.entries(messages).forEach(([key, message]) => {
    translations[key] = function (count) {
      return formatPluralMessage({ count, message })
    }
  })
  return translations
}

export const pluralTranslations = (messages) => {
  let translations = {}
  Object.entries(messages).forEach(([key, message]) => {
    translations[key] = formatPluralMessage({ message })
  })
  return translations
}
