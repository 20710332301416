// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import Hero from 'components/ui/hero'
import HeroIcon from 'components/ui/hero/icon'

// Sub-Components
import { CampaignModel } from './campaign_model'
import { campaignTypeTranslation } from 'utilities/type_translation'

const CampaignHero = ({ campaign }) => (
  <Hero
    icon={<HeroIcon iconName={`activity_${campaign.type.toLowerCase()}`} />}
    imgUrl={campaign.imageUrl}
    label={campaignTypeTranslation(campaign.isCompetition)}
    title={campaign.name}
    titleProps={{
      className: 'mb-5',
    }}
  />
)

CampaignHero.propTypes = {
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
}

export default CampaignHero
