import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
// Vendors
import Paper from '@material-ui/core/Paper'
import { useQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import { TakeActionLink } from 'components/activities/link'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import { PvhAvailableHours } from 'components/pvh'
import { maybeDisplayUserTour } from 'redux/dispatchers'

// sub-components and utils
import { groupByOrganization } from './utilities/utils'
import { VolunteerHistoryLogGroup } from './components/volunteer_history_log_group'
import { VolunteerHistoryStat } from './components/volunteer_history_stat'
import { volunteerHistoryMessages as messages } from './utilities/messages'
import { GET_VOLUNTEER_HISTORY } from './utilities/queries'
import { useBrandConfig } from 'utilities/hooks/useBrandConfig'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { ErrorPage } from 'components/shared/pages/error'

export const VolunteerHistory = () => {
  const [groups, setGroupedLogs] = useState({})
  const { isConfigEnabled: isVolunteerHistoryEnabled } = useBrandConfig({
    feature: 'volunteerHistory',
  })
  const { enableDollarsForDoersVolunteerHistory } = useFlags()

  const {
    data: {
      currentUser: {
        paidVolunteerHoursBalance,
        volunteeredIndividualHoursAllTime,
        volunteeredOrganizationsAllTime,
        volunteerLogs,
      } = {},

      brandConfig: { enablePaidVolunteerHours } = {},
    } = {},
    loading,
  } = useQuery(GET_VOLUNTEER_HISTORY)
  const { formatMessage } = intl

  useEffect(() => {
    volunteerLogs?.length > 0 &&
      setGroupedLogs(groupByOrganization(volunteerLogs))
  }, [volunteerLogs])
  if (enableDollarsForDoersVolunteerHistory === true) {
    return <Redirect to="/app/volunteering" />
  }
  if (loading) {
    return <CenteredPaddedLoadingIndicator />
  }
  if (!isVolunteerHistoryEnabled) {
    return <ErrorPage body={sharedTranslations.featureDisabledPageBody} />
  }

  return (
    <DocumentTitle title={formatMessage(messages.pageTitle)}>
      {maybeDisplayUserTour('volunteerHistoryPage')}

      <CenteredContent
        className="px-3 | px-sm-5 | px-xl-0"
        data-test="volunteer-history"
      >
        {/* Title + CTA */}
        <div className="d-sm-flex align-items-start justify-content-between mt-6 px-3">
          <Heading level={1}>{formatMessage(messages.pageTitle)}</Heading>
          <LinkButton
            className="mt-2 mt-sm-0"
            data-test="volunteer-hours-form-link-button"
            to="/users/me/volunteer_hours_form"
            variant="primary"
          >
            {sharedTranslations.logYourVolunteerHours}
          </LinkButton>
        </div>

        {/* Impact Stats */}
        <Heading className="mb-3 mt-4 px-3" level={2}>
          {sharedTranslations.yourImpact}
        </Heading>
        <Paper className="px-3 py-4">
          <Stack
            className="row flex-column align-items-start justify-content-between | flex-sm-row"
            space={{ _: 3, sm: 0 }}
          >
            <VolunteerHistoryStat
              amount={volunteeredIndividualHoursAllTime}
              label={sharedTranslations.totalHoursVolunteered}
            />
            <VolunteerHistoryStat
              amount={volunteeredOrganizationsAllTime}
              label={sharedTranslations.organizations}
            />
          </Stack>
        </Paper>
        {enablePaidVolunteerHours && (
          <>
            <Heading className="mb-3 mt-5 px-3" level={2}>
              {formatMessage(messages.paidVolunteerHoursWithInitials)}
            </Heading>
            <PvhAvailableHours balance={paidVolunteerHoursBalance} />
          </>
        )}

        {/* Volunteer Logs */}
        <Heading className="mb-3 mt-5 px-3" level={2}>
          {sharedTranslations.yourVolunteerHours}
        </Heading>
        <Stack space={4}>
          {groups.length > 0 ? (
            groups.map((group) => (
              <VolunteerHistoryLogGroup
                ein={group.ein}
                enablePaidVolunteerHours={enablePaidVolunteerHours}
                key={group.organizationName}
                organizationName={group.organizationName}
                scheduledVolunteerHours={group.scheduledVolunteerHours}
                totalPaidVolunteerHours={group.totalPaidVolunteerHours}
                totalVolunteerHours={group.totalVolunteerHours}
                volunteerLogs={group.volunteerLogs}
              />
            ))
          ) : (
            <Paper className="px-3 py-4">
              <p
                className="fs-3 fw-semi-bold my-0 text-black-3"
                data-test="no-volunteer-hours"
              >
                {formatMessage(messages.noVolunteerHours, {
                  takeAction: <TakeActionLink />,
                })}
              </p>
            </Paper>
          )}
        </Stack>

        {/* Correction Link */}
        <p className="px-3 mt-4" data-test="request-correction">
          {formatMessage(messages.changeVolunteerHours, {
            requestACorrection: (
              <a
                href="mailto:support@wespire.com?subject=Volunteer%20Hours%20Correction"
                key="requestACorrection"
              >
                {formatMessage(messages.requestACorrection)}
              </a>
            ),
          })}
        </p>
      </CenteredContent>
    </DocumentTitle>
  )
}
