// Vendor
import React from 'react'

// WeSpire
import { FormSubmitButton } from 'components/ui/Form/FormSubmitButton'

export default {
  argTypes: {
    children: {
      control: { type: 'text' },
      defaultValue: 'draw a circle and its the earth',
    },
  },
  component: FormSubmitButton,
  title: 'UI/Form/FormSubmitButton',
}

const Template = (args) => <FormSubmitButton {...args} />

export const SubmitButton = Template.bind({})
SubmitButton.args = {
  disabled: false,
  isSubmitting: false,
}

export const DisabledSubmitButton = Template.bind({})
DisabledSubmitButton.args = {
  disabled: true,
  isSubmitting: false,
}

export const SubmitStateForButton = Template.bind({})
SubmitStateForButton.args = {
  disabled: false,
  isSubmitting: true,
}
