// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { abbreviatedDateWithDay } from 'utilities/date_formatter'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { CONNECTED_DEVICES_GQL } from 'graphql/queries'
import { useQuery } from '@apollo/client'
import { getDeviceConstants } from 'components/shared/action_log_devices/constants'
import { useIsMobile } from 'utilities/hooks/is_mobile'

const messages = defineMessages({
  checkDeviceApp: {
    defaultMessage:
      'Please check syncing status of your {deviceName} device with the {appName} app.',
    id: 'connectedDevicesHelpInfo.checkDeviceApp',
  },
  checkDeviceMessage: {
    defaultMessage: 'Not seeing the latest activity?',
    id: 'connectedDevicesHelpInfo.checkDeviceMessage',
  },
  checkWespireApp: {
    defaultMessage:
      'Please check WeSpire app permissions under {settingsPath} to confirm that we can receive your latest Activity.',
    id: 'connectedDevicesHelpInfo.checkWespireApp',
  },
  deviceHasLogged: {
    defaultMessage:
      'We last received data from your connected {deviceName} on <bold>{ date } at { time }</bold>. ',
    id: 'connectedDevicesHelpInfo.deviceHasLogged',
  },
  noDeviceConnected: {
    defaultMessage:
      'We have no records of device connections yet. Please check your <link>device connections</link>',
    id: 'connectedDevicesHelpInfo.noDeviceConnected',
  },
  noDeviceHasLogged: {
    defaultMessage:
      'We have not yet received data. Please check your devices to confirm that they are syncing properly with the app, or try refreshing this screen.',
    id: 'connectedDevicesHelpInfo.noDeviceHasLogged',
  },
  reconnectTooltip: {
    defaultMessage:
      'Still having trouble? Please try <link>Reconnecting Your Device</link>.',
    id: 'connectedDevicesHelpInfo.reconnectTooltip',
  },
  refreshTooltip: {
    defaultMessage: 'Please try refreshing this window.',
    id: 'connectedDevicesHelpInfo.refreshTooltip',
  },
  refreshTooltipMobile: {
    defaultMessage: 'Please pull this screen down to refresh.',
    id: 'connectedDevicesHelpInfo.refreshTooltipMobile',
  },
})

export const ConnectedDevicesHelpInfo = ({ lastDeviceLogSyncedAt }) => {
  const { formatMessage } = intl
  const { data, loading } = useQuery(CONNECTED_DEVICES_GQL)
  const validicUser = data?.currentUser?.validicUser
  const linkedDevices = validicUser?.linkedDevices
  const connectedDevice = getDeviceConstants(linkedDevices?.[0]?.type)

  const isMobile = useIsMobile()

  if (loading || !data || !linkedDevices) {
    return null
  }

  let message
  if (linkedDevices?.length === 0) {
    message = (
      <p className="fs-1 lh-md">
        {formatMessage(messages.noDeviceConnected, {
          link: (str) => (
            <Link to={`/connected_devices?return_url=${window.location.href}`}>
              {str}
            </Link>
          ),
        })}
      </p>
    )
  } else {
    if (lastDeviceLogSyncedAt) {
      if (!connectedDevice) {
        return null
      }
      message = (
        <>
          <p className="fs-1 lh-md">
            {formatMessage(messages.checkDeviceMessage)}
            &nbsp;
            {isMobile
              ? formatMessage(messages.refreshTooltipMobile)
              : formatMessage(messages.refreshTooltip)}
            &nbsp;
            {formatMessage(
              ['google_fit_sdk', 'apple_health'].includes(connectedDevice.type)
                ? messages.checkWespireApp
                : messages.checkDeviceApp,
              {
                ...connectedDevice,
              }
            )}
            &nbsp;
            {formatMessage(messages.deviceHasLogged, {
              bold: (str) => <span className="fw-semi-bold">{str}</span>,
              date: abbreviatedDateWithDay(lastDeviceLogSyncedAt),
              deviceName: connectedDevice.deviceName,
              time: intl.formatDate(lastDeviceLogSyncedAt, {
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'long',
              }),
            })}
          </p>
          <p className="fs-1 lh-md mt-2 mb-0">
            {formatMessage(messages.reconnectTooltip, {
              link: (str) => (
                <Link
                  to={`/connected_devices?return_url=${window.location.href}`}
                >
                  {str}
                </Link>
              ),
            })}
          </p>
        </>
      )
    } else {
      message = (
        <p className="fs-1 lh-md">
          {formatMessage(messages.noDeviceHasLogged)}
        </p>
      )
    }
  }

  return <div className="mt-2">{message}</div>
}

ConnectedDevicesHelpInfo.propTypes = {
  lastDeviceLogSyncedAt: PropTypes.string,
}

ConnectedDevicesHelpInfo.defaultProps = {
  lastDeviceLogSyncedAt: null,
}
