import { defineMessages } from 'react-intl'

export const groupAnnouncementsMessages = defineMessages({
  backTo: {
    defaultMessage: 'Back to {type}s',
    id: 'groupAnnouncements.backTo',
  },
  createButton: {
    defaultMessage: 'create {type} {groupAnnouncements}',
    id: 'groupAnnouncements.createButton',
  },
  groupAnnouncements: {
    defaultMessage: 'Announcements',
    id: 'groupAnnouncements.groupAnnouncements',
  },
  nothingToDisplay: {
    defaultMessage: 'Nothing to Display',
    id: 'groupAnnouncements.nothingToDisplay',
  },
})

export const groupAnnouncementsListingMessages = defineMessages({
  announcementsText: {
    defaultMessage: '{type} Announcements',
    id: 'groupAnnouncementsListing.announcementsText',
  },
  createdByText: {
    defaultMessage: 'Created by',
    id: 'groupAnnouncementsListing.createdByText',
  },
  lastEditedText: {
    defaultMessage: 'Last edited',
    id: 'groupAnnouncementsListing.lastEditedText',
  },
  onText: {
    defaultMessage: 'on',
    id: 'groupAnnouncementsListing.onText',
  },
})

export const groupAnnouncementsDropdownMessages = defineMessages({
  archiveText: {
    defaultMessage: 'Archive',
    id: 'groupAnnouncementsDropdown.archiveText',
  },
  deleteDraftText: {
    defaultMessage: 'Delete Draft',
    id: 'groupAnnouncementsDropdown.deleteDraftText',
  },
  editText: {
    defaultMessage: 'Edit',
    id: 'groupAnnouncementsDropdown.editText',
  },
  manageText: {
    defaultMessage: 'Manage',
    id: 'groupAnnouncementsDropdown.manageText',
  },
  permanentlyDeleteText: {
    defaultMessage: 'Permanently Delete',
    id: 'groupAnnouncementsDropdown.permanentlyDeleteText',
  },
  publishText: {
    defaultMessage: 'Publish',
    id: 'groupAnnouncementsDropdown.publishText',
  },
  republishText: {
    defaultMessage: 'Republish',
    id: 'groupAnnouncementsDropdown.republishText',
  },
  viewText: {
    defaultMessage: 'View',
    id: 'groupAnnouncementsDropdown.viewText',
  },
})
