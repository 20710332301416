export const eventPath = (eventId) => `/participation_events/${eventId}`

export const joinWaitlistPath = ({ eventId, shiftId }) =>
  `${eventPath(eventId)}/timeframes/${shiftId}/join_waitlist`

export const selfRegistrationPath = ({ eventId, shiftId }) =>
  `${eventPath(eventId)}/timeframes/${shiftId}`

export const newGuestPath = ({ eventId, shiftId }) =>
  `${selfRegistrationPath({ eventId, shiftId })}/guests/new`

export const editGuestPath = ({ eventId, guestId, shiftId }) =>
  `${selfRegistrationPath({ eventId, shiftId })}/guests/${guestId}`

export const waiverPath = (eventId) => `${eventPath(eventId)}/waiver`
