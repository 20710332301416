// Vendor
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { getUrlParams } from 'utilities/get_url_params'
import Scroll from 'react-scroll'
import { Waypoint } from 'react-waypoint'
import { defineMessages } from 'react-intl'

// WeSpire
import {
  DescriptionList,
  DescriptionListItem,
} from 'components/ui/description_list'
import ExpandIcon from 'components/ui/expand_icon'
import { IdeaBoardAnalytics } from 'utilities/analytics/'
import IdeaDetailsContainer from 'components/idea_board/idea/details_container'
import { IdeaFooter } from './footer'
import { Heading } from 'components/ui/heading'
import WeSpireAPI from 'utilities/wespire_api'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'
import { UserAttribution } from 'components/user/user_attribution'
import { ScoreBadge } from 'components/shared/score_badge'
import { scrollDurations } from 'styles/variables'

const messages = defineMessages({
  hideIdea: {
    defaultMessage: 'Hide Idea',
    id: 'idea.hideIdea',
  },
  viewIdea: {
    defaultMessage: 'View Idea',
    id: 'idea.viewIdea',
  },
})

const Idea = ({
  amOwner,
  displayNotification,
  formRequirements,
  idea,
  ideaBoardID,
  isPreview,
  onVote,
  points,
  voteDisabled: voteDisabledFromProps,
}) => {
  const commentFormEl = useRef(null)
  const setCommentFormRef = (form) => (commentFormEl.current = form)

  const [didCollapse, setDidCollapse] = useState(true)
  const [didCommentButtonFire, setDidCommentButtonFire] = useState(false)
  const [didEnterViewport, setDidEnterViewport] = useState(false)
  const [didEnterViewportPreviously, setDidEnterViewportPreviously] =
    useState(false)
  const [didExpand, setDidExpand] = useState(true)
  const [isDeepLinked, setIsDeepLinked] = useState(false)
  const [shouldExpand, setShouldExpand] = useState(false)
  const [voteDisabled, setVoteDisabled] = useState(voteDisabledFromProps)
  const [voteId, setVoteId] = useState(idea.currentUserVoteId)

  useEffect(() => {
    const { id } = idea
    const queryParamId = getUrlParams().idea
    const isDeepLinked = queryParamId === id
    setIsDeepLinked(isDeepLinked)

    if (isDeepLinked) {
      handleExpand()

      window.setTimeout(() => {
        Scroll.scroller.scrollTo(scrollContainerId(), {
          duration: Idea.DURATIONS.EXPAND,
          offset: Idea.DURATIONS.OFFSET,
          smooth: 'easeInOutCubic',
        })
      }, Idea.DURATIONS.SCROLL_DELAY)
    }
  }, [])

  useEffect(() => {
    setVoteDisabled(voteDisabledFromProps)
  }, [voteDisabledFromProps])

  const handleDisableButtonOnClick = (event, onIcon = false) => {
    event.preventDefault()

    setVoteDisabled(true)
    userHasVoted() ? sendUnvote(onIcon) : sendVote(onIcon)
  }

  const handleEnter = () => {
    setDidEnterViewport(true)
    window.setTimeout(
      () => setDidEnterViewportPreviously(true),
      Idea.DURATIONS.FLASH_HIGHLIGHT
    )
  }

  const handleExpand = () => {
    handleToggleExpand(null, true)
  }

  const handleExpandAndFocusComment = () => {
    handleExpand()
    commentFormEl.current?.focus()
    setDidCommentButtonFire(true)
  }

  const handleIdeaDetailsMount = () => {
    if (didCommentButtonFire && commentFormEl.current) {
      window.setTimeout(
        () => {
          Scroll.scroller.scrollTo(scrollDetailsId(), {
            smooth: 'easeInOutCubic',
          })
          commentFormEl.current?.focus()
          setDidCommentButtonFire(false)
        },
        didExpand ? 0 : Idea.DURATIONS.EXPAND
      )
    }
  }

  const handleToggleExpand = (_event, expand) => {
    const { id } = idea

    if (expand) {
      setDidCollapse(false)
      setShouldExpand(true)
      window.setTimeout(() => setDidExpand(true), Idea.DURATIONS.EXPAND)

      IdeaBoardAnalytics.ideaAboutOpened(id)
    } else {
      setDidExpand(false)
      setShouldExpand(false)
      window.setTimeout(() => setDidCollapse(true), Idea.DURATIONS.EXPAND)

      IdeaBoardAnalytics.ideaAboutClosed(id)
    }
  }

  const isVotingDisabled = () => {
    return voteDisabled || isPreview
  }

  const scrollDetailsId = () => {
    return `details-${idea.id}`
  }

  function decrementVotes() {
    onVote(idea.id, -1)
  }

  function incrementVotes() {
    onVote(idea.id, 1)
  }

  const renderCategoryElement = () => {
    const category = idea.category

    return (
      category && (
        <DescriptionList className="mb-0 mt-1">
          <DescriptionListItem label={sharedTranslations.category}>
            {category.name}
          </DescriptionListItem>
        </DescriptionList>
      )
    )
  }

  function renderIdeaHeader() {
    const displayBadge = idea.scored && points && amOwner

    return (
      <div className="d-flex align-items-center justify-content-between w-100 mr-2">
        <div className="pr-2">
          <UserAttribution
            avatarUrl={idea.user.avatarUrl}
            createdAt={idea.createdAt}
            profilePath={idea.user.profilePath}
            userId={idea.user.id}
          />
          <Heading className="mt-2 text-blue" data-test="idea-title" level={3}>
            {idea.title}
          </Heading>
          {renderCategoryElement()}
        </div>
        {displayBadge && <ScoreBadge isCompleted points={points} />}
      </div>
    )
  }

  const scrollContainerId = () => `idea-${idea.id}`

  function sendVote(onIcon) {
    incrementVotes()

    WeSpireAPI()
      .post('/likes', {
        likable_id: idea.id,
        likable_type: 'IdeaBoard::Submission',
      })
      .then(({ data }) => {
        IdeaBoardAnalytics.ideaVoted(idea.id, data.like.id, onIcon)

        setVoteDisabled(false)
        setVoteId(data.like.id)
      })
      .catch(() => {
        // TODO: Should show flash message
        setVoteDisabled(false)
        decrementVotes()
      })
  }

  function sendUnvote(onIcon) {
    decrementVotes()

    WeSpireAPI()
      .delete('/likes/' + voteId)
      .then(() => {
        IdeaBoardAnalytics.ideaUnvoted(idea.id, voteId, onIcon)

        setVoteDisabled(false)
        setVoteId(null)
      })
      .catch(() => {
        // TODO: Should show flash message
        setVoteDisabled(false)
        incrementVotes()
      })
  }

  function userHasVoted() {
    return Boolean(voteId)
  }

  const expandIconText = intl.formatMessage(
    shouldExpand ? messages.hideIdea : messages.viewIdea
  )
  const hasFlashHighlight =
    isDeepLinked && didEnterViewport && !didEnterViewportPreviously
  const hasIdeaDetails = shouldExpand || (!shouldExpand && !didCollapse)

  return (
    <Scroll.Element data-test="idea" name={scrollContainerId()}>
      <Waypoint onEnter={handleEnter} />
      <Accordion
        classes={{
          expanded: cx('bg-white border-bottom m-0 mt-3', {
            'flash-highlight': hasFlashHighlight,
          }),
        }}
        expanded={shouldExpand}
        onChange={handleToggleExpand}
        TransitionProps={{
          timeout: Idea.DURATIONS.EXPAND,
        }}
      >
        {/* Summary */}
        <AccordionSummary
          className="px-3 py-1"
          expandIcon={<ExpandIcon title={expandIconText} />}
        >
          {renderIdeaHeader()}
        </AccordionSummary>
        {/* Details */}
        <div className={cx({ 'd-none': didCollapse })}>
          <Scroll.Element name={scrollDetailsId()}>
            <AccordionDetails className="px-3 py-0">
              {hasIdeaDetails && (
                <IdeaDetailsContainer
                  displayNotification={displayNotification}
                  formRequirements={formRequirements}
                  ideaId={idea.id}
                  ideaTitle={idea.title}
                  onIdeaDetailsMount={handleIdeaDetailsMount}
                  onVoteButtonClick={handleDisableButtonOnClick}
                  setCommentFormRef={setCommentFormRef}
                  userHasVoted={userHasVoted()}
                  votingDisabled={isVotingDisabled()}
                />
              )}
            </AccordionDetails>
          </Scroll.Element>
        </div>
      </Accordion>

      <IdeaFooter
        className="[ d-flex align-items-center ] [ mb-3 p-2 position-relative ]"
        currentUserId={formRequirements.currentUser.id}
        idea={idea}
        ideaBoardId={ideaBoardID}
        onCommentButtonClick={handleExpandAndFocusComment}
        onVoteButtonClick={handleDisableButtonOnClick}
        userHasVoted={userHasVoted()}
        voteCount={idea.voteCount}
        votingDisabled={isVotingDisabled()}
      />
    </Scroll.Element>
  )
}

Idea.propTypes = {
  amOwner: PropTypes.bool,
  displayNotification: PropTypes.func.isRequired,
  formRequirements: PropTypes.shape({
    currentUser: PropTypes.shape({
      avatarUrl: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  idea: PropTypes.shape({
    category: PropTypes.object,
    createdAt: PropTypes.string,
    currentUserVoteId: PropTypes.string,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    scored: PropTypes.bool.isRequired,
    title: PropTypes.string,
    user: PropTypes.shape({
      avatarUrl: PropTypes.string,
      id: PropTypes.string.isRequired,
      profilePath: PropTypes.string.isRequired,
    }),
    voteCount: PropTypes.number.isRequired,
  }).isRequired,
  ideaBoardID: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  onVote: PropTypes.func.isRequired,
  points: PropTypes.number,
  voteDisabled: PropTypes.bool,
}

Idea.defaultProps = {
  amOwner: false,
  isPreview: false,
  points: null,
  voteDisabled: false,
}

Idea.DURATIONS = {
  EXPAND: scrollDurations.EXPAND,
  FLASH_HIGHLIGHT: scrollDurations.FLASH_HIGHLIGHT, // defined in .flash-highlight class
  OFFSET: scrollDurations.OFFSET,
  SCROLL_DELAY: scrollDurations.SCROLL_DELAY,
}

export default Idea
