/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */

export const getUrlParams = (url) => {
  var params = {}

  const parser = document.createElement('a')
  parser.href = url || window.location.href
  const query = parser.search.substring(1)
  const paramStrings = query.split('&')

  if (paramStrings[0] === '') return {}

  paramStrings.forEach((_, i) => {
    const pair = paramStrings[i].split('=')
    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1])

    params[key] = value
  })

  return params
}
