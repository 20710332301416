// Vendor
import React, { useState, useEffect } from 'react'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { intl } from 'utilities/localization'
import LoginForm from './components/LoginForm'
import LoggedOutLayout from '/components/application/layout/LoggedOutLayout'
import { messages } from './utilities/messages'
import WeSpireAPI from 'utilities/wespire_api'

const Login = () => {
  const { formatMessage } = intl

  const [brandName, setBrandName] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [multiAuth, setMultiAuth] = useState(false)
  const [signUpPath, setSignUpPath] = useState('')
  const [ssoUrl, setSsoUrl] = useState('')

  useEffect(() => {
    WeSpireAPI()
      .get('brands/landing')
      .then((response) => {
        const { brand_name, logo_url, multi_auth, sign_up_path, sso_url } =
          response.data

        setBrandName(brand_name)
        setLogoUrl(logo_url)
        setMultiAuth(multi_auth)
        setSignUpPath(sign_up_path)
        setSsoUrl(sso_url)
      })
  })

  return (
    <>
      {brandName ? (
        <LoggedOutLayout
          logoUrl={logoUrl}
          title={formatMessage(messages.signInTo, { brandName })}
        >
          <div className="d-flex flex-column align-items-center justify-content-center px-3">
            <LoginForm
              multiAuth={multiAuth}
              signUpPath={signUpPath}
              ssoUrl={ssoUrl}
            />
          </div>
        </LoggedOutLayout>
      ) : (
        <CenteredPaddedLoadingIndicator />
      )}
    </>
  )
}

export { Login }
