import React, { useContext, useState } from 'react'
import { useFormik, Form, ErrorMessage, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'

import Button from 'src/common/button/Button'
import Modal from 'src/common/Modal'
import TextHero from 'src/common/TextHero'
import InfoContainer from 'src/common/InfoContainer'
import Input from 'src/common/Input'
import { useCsrft } from 'src/hooks/useCsrft'
import SessionContext from 'src/contexts/SessionContext'
import WeSpireAPI from 'utilities/wespire_api'
import { FormattedMessage, useIntl } from 'react-intl'

// eslint-disable-next-line react/prop-types
const EmailAuthForm = ({ hideTitle }) => {
  const { brand, signedIn } = useContext(SessionContext)
  const { tokenName, tokenValue } = useCsrft()
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState({})
  const intl = useIntl()

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    const { email, employee_number } = values
    WeSpireAPI()
      .post('/registration', {
        session: {
          email,
          employee_number
        },
      })
      .then((response) => {
        if(response.data?.confirm_email) {
          setShowModal(true)
          setModalMessage({title: 'Check Your Email', body: response.data.info})
        } else if(response.data?.invalid_employee_number){
          setShowModal(true)
          setModalMessage({title: 'Error', body: response.data.info})
        } else {
          window.location = response.data.redirect_to
        }
      })
      .catch((error) => {
        const status = error?.response?.status
        const serverError = {
          title: intl.formatMessage({
            id: 'loginMessages.serverErrorTitle',
            defaultMessage: 'Error',
          }),
          description: intl.formatMessage({
            id: 'loginMessages.serverErrorDescription',
            defaultMessage: 'Please try again later or contact support.',
          }),
        }

        setStatus(status && serverError)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const validationSchemaWithEmployeeNumber = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'loginMessages.invalidEmail',
          defaultMessage: 'Invalid email address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'loginMessages.emailRequired',
          defaultMessage: 'Email is required',
        })
      ),
    employee_number: Yup.string()
      .required(
        intl.formatMessage({
          id: 'loginMessages.employeeNumberRequired',
          defaultMessage: 'Employee number is required',
        })
      )
  })

  const validationSchemaEmailOnly = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'loginMessages.invalidEmail',
          defaultMessage: 'Invalid email address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'loginMessages.emailRequired',
          defaultMessage: 'Email is required',
        })
      ),
  })

  const validationSchema = brand?.brandValidatesEmployeeNumber ? validationSchemaWithEmployeeNumber : validationSchemaEmailOnly
  let initialValues = { email: ''}

  if(brand?.brandValidatesEmployeeNumber){
    initialValues.employee_number = ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })
  if (signedIn) {
    return <Redirect to="/take_action" />
  }
  if (brand?.authScheme === 'sso' && brand?.authScheme !== 'multiAuth') {
    return <Redirect to="/app/landing/sso" />
  }

  return (
    <main className={`grid justify-items-center ${!hideTitle && 'mt-20'} pb-8`}>
      {!hideTitle && (
        <TextHero
          subtitle={brand?.heroSubtitle}
          title={brand?.heroTitle || brand?.name}
        />
      )}
      <FormikProvider value={formik}>
        <Form className="max-w-[37.5rem] w-11/12 px-4">
          <section className={`flex flex-col ${!hideTitle && 'mt-12'}`}>
            <div className="flex flex-col">
              <Input name={tokenName} type="hidden" value={tokenValue} />
              {formik.status && (
                <InfoContainer
                  canClose
                  className="mb-4"
                  title={formik.status.title}
                  variant="error"
                >
                  <span>{formik.status.description}</span>
                </InfoContainer>
              )}
              <Input
                className="h-14 black-body border-[1px] border-gray-2 rounded-lg px-4 py-3 text-base font-extralight mt-4 font-body"
                name="email"
                data-test="email"
                placeholder={intl.formatMessage({
                  id: 'landingMessages.enterYourEmailAddress',
                  defaultMessage: 'Enter your email address',
                })}
              />

              <ErrorMessage
                className="text-error-red"
                component="div"
                name="email"
              />
              { brand?.brandValidatesEmployeeNumber &&
                <>
                  <Input
                    className="h-14 black-body border-[1px] border-gray-2 rounded-lg px-4 py-3 text-base font-extralight mt-4 font-body"
                    name="employee_number"
                    placeholder={intl.formatMessage({
                      id: 'landingMessages.enterYourEmployeeNumber',
                      defaultMessage: 'Enter your employee number',
                    })}
                  />
                  <ErrorMessage
                    className="text-error-red"
                    component="div"
                    name="employee_number"
                  />
                </>
              }
              <Button className="h-14 mt-4 email" type="submit">
                <FormattedMessage
                  id="landingMessages.continueWithEmail"
                  defaultMessage={'Continue with email'}
                />
              </Button>
            </div>
            <div>
              <p className="text-center mt-4">
                <FormattedMessage
                  id="landingMessages.alreadyHaveAnAccount"
                  defaultMessage={'Already have an account?'}
                />{' '}
                <Link
                  to="/app/login"
                  className="text-smart-blue font-sans text-sm leading-6"
                >
                  <FormattedMessage
                    id="landingMessages.signIn"
                    defaultMessage={'Sign In'}
                  />
                </Link>
              </p>
            </div>
            {brand?.passwordDescription && (
              <InfoContainer className="max-w-150 mt-4 p-4" useIcon={false}>
                <div
                  className="login-auth-description"
                  dangerouslySetInnerHTML={{
                    __html: brand.passwordDescription,
                  }}
                />
              </InfoContainer>
            )}
          </section>
        </Form>
      </FormikProvider>
      {showModal && (
        <Modal
          body={modalMessage.body}
          setShowModal={() => setShowModal(false)}
          title={modalMessage.title}
        />
      )}
    </main>
  )
}

export default EmailAuthForm
