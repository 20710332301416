// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { color as systemColor } from 'styles/variables'
import { Heading } from 'components/ui/heading'
import { Image } from 'components/ui/image'
import Stack from 'components/ui/stack'

/**
 * Hero intended to render Channel details. All content stacks in one column.
 */
const ChannelHeroStackedLayout = ({
  description,
  imgUrl,
  imgUrlAltText,
  name,
  secondaryLogoUrl,
  secondaryLogoUrlAltText,
  themeColor,
  ...other
}) => {
  const textColor = themeColor || systemColor['black-1']

  return (
    <div {...other} data-test="channel-hero-stacked">
      <Stack className="bg-white p-3" space={3}>
        <Heading
          className="ff-serif ls-3"
          data-test="channel-name"
          level={1}
          style={{ color: textColor }}
        >
          {name}
        </Heading>
        {description && <p className="fs-3">{description}</p>}
        {secondaryLogoUrl && (
          <Image
            alt={secondaryLogoUrlAltText}
            height="32"
            src={secondaryLogoUrl}
          />
        )}
      </Stack>
      {imgUrl && (
        <Image
          alt={imgUrlAltText}
          aspectRatio="16:9"
          onImageError={(img) => (img.style.display = 'none')}
          responsive
          src={imgUrl}
        />
      )}
    </div>
  )
}

ChannelHeroStackedLayout.propTypes = {
  /** class attribute applied to parent node */
  className: PropTypes.string,
  /** channel description */
  description: PropTypes.string,
  /** channel hero image url */
  imgUrl: PropTypes.string,
  /** channel hero image alt text */
  imgUrlAltText: PropTypes.string,
  /** channel name */
  name: PropTypes.string.isRequired,
  /** channel secondary logo url */
  secondaryLogoUrl: PropTypes.string,
  /** channel secondary logo alt text */
  secondaryLogoUrlAltText: PropTypes.string,
  /** optional admin-configured CSS color value */
  themeColor: PropTypes.string,
}

ChannelHeroStackedLayout.defaultProps = {
  className: null,
  description: null,
  imgUrl: null,
  imgUrlAltText: '',
  secondaryLogoUrl: null,
  secondaryLogoUrlAltText: '',
  themeColor: null,
}

export { ChannelHeroStackedLayout }
