export const stringCleanup = (string = '') => {
  if (string) {
    string = string
      .replace(/_[^_]*_?/gm, '')
      .replace(/<[^>]*>?/gm, '')
      .replace('\t', '')
      .trim()
  }

  return string
}
