import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

// Vendor
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import { displayBanner, displayExceptionBanner } from 'redux/dispatchers'

// WeSpire
import { getUrlParams } from 'utilities/get_url_params'
import { useBrandConfig } from 'utilities/hooks/useBrandConfig'
import { ErrorList } from 'components/form/error_list'
import SubmitButton from 'components/ui/submit_button'
import { FormPage } from 'components/form/form_page'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { DocumentTitle } from 'components/shared/document_title'
import { CharityNotFoundPage } from 'components/donations/pages/charity_not_found'
import { DonationAccountNotFoundPage } from 'components/donations/pages/donation_account_not_found'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { currencyDonationAmount } from 'utilities/currency_utils'
import { donationFormMessages as messages } from './utilities/messages'
import {
  DONATE_MUTATION,
  REQUEST_FUNDED_DONATION_MUTATION,
} from './utilities/mutations'
import {
  CHARITY_QUERY,
  GOBAL_GIVING_PROJECT_QUERY,
  DONATION_FORM_BALANCES_QUERY,
  RECURRING_DONATIONS_ENABLED,
} from './utilities/queries'
import {
  DetailsSection,
  PaymentSection,
  DonationSection,
  RecurringDonationsSection,
} from './components'

export const DonationForm = () => {
  const { push } = useHistory()
  const { ein } = useParams()
  const searchType = getUrlParams().search_type
  const charityOrGivingProjectQuery = searchType === 'charity' ? CHARITY_QUERY : GOBAL_GIVING_PROJECT_QUERY
  const [createDonation] = useMutation(DONATE_MUTATION)
  const [requestFundedDonationMutation] = useMutation(
    REQUEST_FUNDED_DONATION_MUTATION,
    {
      refetchQueries: [{ query: DONATION_FORM_BALANCES_QUERY }],
    }
  )
  const {
    loading,
    data: {
      brandConfig: {
        donorCoveringCreditCardFee,
        donorCoveringProcessingFee,
      } = {},
      [searchType === 'charity' ? 'charity' : 'globalGivingProject']: charity,
      currentUser: {
        brand: {
          hasEnabledCountries: brandHasEnabledCountries,
          name: brandName,
        } = {},
        currency,
        givingaUser,
        donationNotesLength,
      } = {},
      donationMatchingEnabled,
      donationEmployeeAccountEnabled,
    } = {},
  } = useQuery(charityOrGivingProjectQuery, {
    variables: { ein },
  })
  const { data: { recurringDonationsEnabled } = {} } = useQuery(
    RECURRING_DONATIONS_ENABLED
  )

  const [donationAmount, setDonationAmount] = useState(0)
  const [isEmployerFunded, setIsEmployerFunded] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [loadingCurrencyConversion, setLoadingCurrencyConversion] =
    useState(false)
  const [selectedCurrencyFundsCap, setSelectedCurrencyFundsCap] = useState(0)
  const [selectedCurrencyIsoCode, setSeletectedCurrencyIsoCode] =
    useState('USD')
  const [selectedCurrencyMatchingFunds, setSelectedCurrencyMatchingFunds] =
    useState(0)
  const [isRecurring, setIsRecurring] = useState(false)

  const donationForm = createRef()
  const activityId = () => getUrlParams().activity_id
  const { formatMessage } = intl
  const givingActivityUrl = `/giving_activities/${activityId()}`
  const donationInterval = isRecurring ? 'month' : 'day'

  const { error: isGivingEnabledError, isConfigEnabled: isGivingEnabled } =
    useBrandConfig({
      feature: 'giving',
    })

  const cardDonation = async ({
    anonymousDonation,
    charityId,
    charityName,
    donationCurrency,
    donationInterval,
    notes,
    isZeroDecimalCurrency,
    matched,
    searchType
  }) => {
    const mutationVariables = {
      activityId: activityId(),
      amount: currencyDonationAmount(donationAmount, isZeroDecimalCurrency),
      anonymity: anonymousDonation ? 'Anonymous' : 'Full Transparency',
      charityId: searchType === 'charity' ? charityId : null,
      charityName: charityName,
      currencyId: donationCurrency,
      ein,
      interval: donationInterval,
      matched: matched || false,
      notes,
      projectId: searchType === 'globalGiving' ? charityId : null
    }
    try {
      const {
        data: {
          donate: {
            errors,
            stripeAccount,
            stripeSessionId,
            stripePublishableKey,
          },
        },
      } = await createDonation({
        variables: mutationVariables,
      })
      if (stripeSessionId) {
        // eslint-disable-next-line no-undef
        Stripe(stripePublishableKey, {
          stripeAccount: stripeAccount,
        })
          .redirectToCheckout({
            sessionId: stripeSessionId,
          })
          .then(function () {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
      } else {
        let show_this_error_now = errors.join('. ')
        setIsSubmitting(false)
        displayExceptionBanner({
          operation: show_this_error_now,
        })
      }
    } catch {
      setIsSubmitting(false)
      displayExceptionBanner({
        operation: intl.formatMessage(messages.operation),
      })
    }
  }

  const fundedAccountDonation = async ({
    anonymousDonation,
    notes,
    matched,
  }) => {
    try {
      const {
        data: {
          requestFundedDonation: { errors },
        },
      } = await requestFundedDonationMutation({
        variables: {
          activityId: activityId(),
          amount: parseFloat(donationAmount),
          anonymity: anonymousDonation ? 'Anonymous' : 'Full Transparency',
          ein,
          matched: matched || false,
          notes
        },
      })

      setIsSubmitting(false)
      if (errors.length === 0) {
        displayBanner({
          content: intl.formatMessage(messages.requestSuccess),
          variant: 'success',
        })
        push('/users/me/donation_history')
      } else {
        displayBanner({
          as: 'div',
          content: <ErrorList errors={errors} />,
          variant: 'error',
        })
      }
    } catch {
      setIsSubmitting(false)
      displayExceptionBanner({
        operation: intl.formatMessage(messages.operation),
      })
    }
  }

  const getDonorFeeHelpText = (
    donorCoveringCreditCardFee,
    donorCoveringProcessingFee
  ) => {
    if (donorCoveringCreditCardFee && donorCoveringProcessingFee) {
      // donor covering both credit card and donation processing fees
      return ' ' + intl.formatMessage(messages.coveringAllFees)
    } else if (donorCoveringProcessingFee) {
      // donor only covering donation processing fees
      return ' ' + intl.formatMessage(messages.coveringProcessingFee)
    } else if (donorCoveringCreditCardFee) {
      // donor only covering credit card fees
      return ' ' + intl.formatMessage(messages.coveringCreditFee)
    } else {
      return ''
    }
  }

  const handleEmployerFunded = (event) => {
    setIsEmployerFunded(event.target.value === 'true')
  }

  const handleDonationRecurrence = (event) => {
    setIsRecurring(event.target.value === 'true')
  }

  const { refetch } = useQuery(DONATION_FORM_BALANCES_QUERY, {
    skip: !selectedCurrency,
  })

  const handleDonationCurrency = async (selectedCurrency) => {
    setLoadingCurrencyConversion(true)
    const {
      data: {
        currentUser: {
          givingaUser: {
            availableDonationFunds,
            availableDonationCurrency,
            donationFundsCap,
          },
        },
      },
    } = await refetch({
      currencyId: selectedCurrency.id,
    })
    setSelectedCurrency(selectedCurrency)
    setSelectedCurrencyFundsCap(donationFundsCap)
    setSeletectedCurrencyIsoCode(availableDonationCurrency)
    setSelectedCurrencyMatchingFunds(availableDonationFunds)
    setLoadingCurrencyConversion(false)
  }

  const handleFormChange = () => {
    if (donationForm.current) {
      const { donationAmount } = donationForm.current.getCurrentValues()
      setDonationAmount(donationAmount)
    }
  }

  const handleInvalidForm = () => {
    setSubmitDisabled(true)
  }

  const handleValidForm = () => {
    setSubmitDisabled(false)
  }

  const handleSubmit = ({ charityId, charityName, defaultCurrency, model }) => {
    const { anonymousDonation, donationCurrency, donationNote, matched, projectName } = model
    setIsSubmitting(true)

    const notes = projectName?.length ? `Project ID => ${projectName[0].value}` : donationNote

    const isZeroDecimalCurrency =
      selectedCurrency?.isZeroDecimal || defaultCurrency.isZeroDecimal

    if (isEmployerFunded) {
      fundedAccountDonation({
        anonymousDonation,
        notes,
        matched,
      })
    } else {
      cardDonation({
        anonymousDonation,
        charityId,
        charityName,
        donationCurrency,
        donationInterval,
        notes,
        isZeroDecimalCurrency,
        matched,
        searchType
      })
    }
  }

  const renderFooter = () => (
    <>
      {intl.formatMessage(messages.footer, {
        link: (...linkText) => (
          <Link to="/donations/terms_and_conditions">{linkText}</Link>
        ),
        span: (str) => <span className="fw-semi-bold">{str}</span>,
      })}
    </>
  )

  const { enableDonationForm } = useFlags()

  if (enableDonationForm === true) {
    return <Redirect to={`/app/donation_form/${ein}`} />
  }

  if (!givingaUser || !isGivingEnabled) {
    return loading ? (
      <CenteredPaddedLoadingIndicator />
    ) : (
      <DonationAccountNotFoundPage />
    )
  }
  if (!charity) {
    return loading ? (
      <CenteredPaddedLoadingIndicator />
    ) : isGivingEnabled ? (
      <CharityNotFoundPage />
    ) : (
      isGivingEnabledError
    )
  }

  if (document.flag_initial_currency_cap_load === undefined) {
    document.flag_initial_currency_cap_load = false
    handleDonationCurrency(currency)
  }

  return isGivingEnabled ? (
    <DocumentTitle title={formatMessage(messages.pageTitle)}>
      <FormPage
        backLinkSubject="Giving Activity"
        backLinkTo={activityId() ? givingActivityUrl : null}
        footer={renderFooter()}
        heading={formatMessage(messages.pageTitle)}
        onChange={handleFormChange}
        onInvalid={handleInvalidForm}
        onValid={handleValidForm}
        onValidSubmit={(model) =>
          handleSubmit({
            charityId: charity.id,
            charityName: charity.name,
            defaultCurrency: currency,
            model: model,
          })
        }
        ref={donationForm}
      >
        <DetailsSection
          brandName={brandName}
          charity={charity}
          donationEmployeeAccountEnabled={donationEmployeeAccountEnabled}
          donationMatchingEnabled={donationMatchingEnabled}
          givingaUser={givingaUser}
          loadingCurrencyConversion={loadingCurrencyConversion}
          selectedCurrencyFundsCap={selectedCurrencyFundsCap}
          selectedCurrencyIsoCode={selectedCurrencyIsoCode}
          selectedCurrencyMatchingFunds={selectedCurrencyMatchingFunds}
        />
        {recurringDonationsEnabled && searchType !== 'globalGiving' && (
          <RecurringDonationsSection
            handleDonationRecurrence={handleDonationRecurrence}
            isRecurring={isRecurring}
          />
        )}
        <DonationSection
          brandHasEnabledCountries={brandHasEnabledCountries}
          defaultCurrency={currency}
          donationAmount={donationAmount}
          donationMatchingEnabled={donationMatchingEnabled}
          donationNotesLength={donationNotesLength}
          givingaUser={givingaUser}
          handleDonationCurrency={handleDonationCurrency}
          isEmployerFunded={isEmployerFunded}
          matched={charity.matched}
          selectedCurrency={selectedCurrency}
          selectedCurrencyMatchingFunds={selectedCurrencyMatchingFunds}
          showProjects={charity.showProjects}
        />
        <PaymentSection
          balance={givingaUser.employeeAccountBalance}
          donationEmployeeAccountEnabled={donationEmployeeAccountEnabled}
          donorCoveringCreditCardFee={donorCoveringCreditCardFee}
          donorCoveringProcessingFee={donorCoveringProcessingFee}
          getDonorFeeHelpText={getDonorFeeHelpText}
          handleEmployerFunded={handleEmployerFunded}
          isEmployerFunded={isEmployerFunded}
          isRecurring={isRecurring}
        />
        <SubmitButton
          className="ml-auto w-100"
          color="primary"
          data-test="submit"
          disabled={submitDisabled}
          isSubmitting={isSubmitting}
          variant="contained"
        >
          {formatMessage(messages.continueDonate)}
        </SubmitButton>
      </FormPage>
    </DocumentTitle>
  ) : (
    isGivingEnabledError
  )
}

DonationForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}
