// Vendor
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { AddToCalendar } from 'components/ui/add_to_calendar'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { eventsTranslations } from 'components/events/shared_translations'
import { FormDetail } from 'components/form/form_details/form_detail'
import { FormDetails } from 'components/form/form_details/form_details'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { sharedTranslations } from 'components/shared/translations'
import { TextLink } from 'components/shared/text_link'
import WeQuery from 'components/application/we_query'

// Sub-Components
import { EventContactFormDetail } from './event_contact_form_detail'
import { eventPath } from 'components/events/routes'
import { ShiftTimeframeTime } from './shift_timeframe_time'
import { getEventPointsLabel } from './shift_timeframe_points'
import ExternalLink from 'components/ui/external_link'

const messages = defineMessages({
  errorDetails: {
    defaultMessage: 'this Event',
    id: 'eventFormDetails.errorDetails',
  },
  eventDetails: {
    defaultMessage: 'Event details',
    id: 'eventFormDetails.eventDetails',
  },
})

const SHIFT_TIMEFRAME_PARTICIPATION_EVENT = gql`
  query shiftTimeframe($id: ID!) {
    shiftTimeframe(id: $id) {
      icsCalendarUrl
      id
      googleCalendarUrl
      start
      end

      participationEvent {
        id
        contactEmail
        contactName
        fullAddress
        guestsAllowed
        joinVirtuallyUrl
        name
        organizationName
      }
    }
  }
`

export const EventFormDetails = ({
  canEarnPointsOnEvents,
  points,
  pointsAwarded,
  showCalendar,
  shiftId,
}) => (
  <div className="bg-blue-2 px-5 py-3">
    <WeQuery
      error={
        <MissingResourceSection
          errorDetails={intl.formatMessage(messages.errorDetails)}
        />
      }
      loader={<CenteredPaddedLoadingIndicator />}
      query={SHIFT_TIMEFRAME_PARTICIPATION_EVENT}
      variables={{ id: shiftId }}
    >
      {({ data: { shiftTimeframe } }) => {
        const event = shiftTimeframe.participationEvent

        return (
          <Fragment>
            <FormDetails>
              <h3 className="sr-only">
                {intl.formatMessage(messages.eventDetails)}
              </h3>
              <FormDetail
                data-test="registration-event-name"
                label={sharedTranslations.event}
              >
                <TextLink to={eventPath(event.id)}>{event.name}</TextLink>
              </FormDetail>

              <FormDetail
                data-test="registration-date"
                label={eventsTranslations.dateAndTime}
              >
                <ShiftTimeframeTime
                  end={shiftTimeframe.end}
                  start={shiftTimeframe.start}
                />
              </FormDetail>

              {event.organizationName && (
                <FormDetail
                  data-test="registration-organization"
                  label={sharedTranslations.organization}
                >
                  {event.organizationName}
                </FormDetail>
              )}

              {event.fullAddress && (
                <FormDetail
                  data-test="registration-location"
                  label={eventsTranslations.location}
                >
                  {event.fullAddress}
                </FormDetail>
              )}

              {event.joinVirtuallyUrl && (
                <FormDetail label={eventsTranslations.joinVirtually}>
                  <ExternalLink
                    className="text-lowercase word-break-all"
                    to={event.joinVirtuallyUrl}
                  />
                </FormDetail>
              )}

              {(event.contactEmail || event.contactName) && (
                <EventContactFormDetail
                  email={event.contactEmail}
                  name={event.contactName}
                />
              )}

              <FormDetail
                data-test="guests-welcome"
                label={eventsTranslations.guestsWelcome}
              >
                {event.guestsAllowed
                  ? sharedTranslations.yes
                  : sharedTranslations.no}
              </FormDetail>

              {canEarnPointsOnEvents && points && (
                <FormDetail
                  data-test="points-for-event"
                  label={getEventPointsLabel(pointsAwarded)}
                >
                  {points}
                </FormDetail>
              )}
            </FormDetails>

            {showCalendar && (
              <AddToCalendar
                googleUrl={shiftTimeframe.googleCalendarUrl}
                icsUrl={shiftTimeframe.icsCalendarUrl}
              />
            )}
          </Fragment>
        )
      }}
    </WeQuery>
  </div>
)

EventFormDetails.propTypes = {
  canEarnPointsOnEvents: PropTypes.bool,
  points: PropTypes.number,
  pointsAwarded: PropTypes.string,
  shiftId: PropTypes.string.isRequired,
  showCalendar: PropTypes.bool,
}

EventFormDetails.defaultProps = {
  canEarnPointsOnEvents: false,
  points: null,
  pointsAwarded: null,
  showCalendar: false,
}
