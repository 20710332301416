// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const HeadingLevels = [1, 2, 3, 4, 5, 6]

/**
 * Heading that corresponds to `<h1>` - `<h6>`. Adheres design system scale of
 * allowed style variations.
 *
 * - Every page must have a level 1 heading that matches the document title.
 * - There should only be one level 1 per page.
 * - Do not skip heading levels (e.g. from h1 to h3).
 * - Use headings to introduce major sections of content.
 * - Consider appropriate heading level first, then override variant style if necessary.
 * - If necessary for good page heading hierarchy, render only for screen readers.
 * - Custom heading styles used in one component do not need an official variant.
 * - Management Panel heading styles are totally separate.
 */
const Heading = ({
  children,
  className,
  level,
  screenReaderOnly,
  ...other
}) => {
  const HeadingComponent = 'h' + level

  return (
    <HeadingComponent
      {...other}
      className={cx(
        `( heading heading--${level} heading-react ) ( heading-react--${level} )`,
        { 'sr-only': screenReaderOnly },
        className
      )}
    >
      {children}
    </HeadingComponent>
  )
}

Heading.propTypes = {
  /** render heading content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** renders h1, h2, or h3 with default system style  */
  level: PropTypes.oneOf(HeadingLevels).isRequired,
  /** display only for screen readers */
  screenReaderOnly: PropTypes.bool,
}

Heading.defaultProps = {
  className: null,
  screenReaderOnly: false,
}

export { Heading, HeadingLevels }
