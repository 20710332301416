// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { ACTIVITIES, ActivityGallery } from 'components/shared/activity_gallery'
import CenteredContent from 'components/application/centered_content'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'
import LinkedDevices from './linked_devices'

// Sub-Components
import { ActionPanel } from 'components/campaign/action_panel'
import CampaignAbout from './about'
import CampaignHero from './hero'
import { CampaignModel } from './campaign_model'
import { CampaignNewsFeed } from './campaign_news_feed'
import { campaignTranslations } from './shared_translations'
import { CampaignLeaderboard } from './leaderboard'
import { Heading } from 'components/ui/heading'

const CampaignLayoutDesktop = ({
  campaign,
  onAchievementEarn,
  isPreview,
  previewWithNoData,
}) => (
  // 1. Add bottom padding so user tags are not cut off at the bottom of the
  // window by the parent .page-wrapper, which is set to overflow: hidden.
  <>
    <div
      className="[ pb-7 ]" // 1
      data-test="campaign-layout-desktop"
    >
      <CampaignHero campaign={campaign} />

      <CenteredContent className="position-relative" pull>
        <CampaignAbout campaign={campaign} />

        {!isPreview && campaign.isCompetition && campaign.hasStarted && (
          <CampaignLeaderboard
            campaignId={campaign.id}
            teamSets={campaign.leaderboardTeamSets}
          />
        )}
        <div className="row">
          <div className="col-sm-6">
            {!isPreview && campaign.isDeviceCompatible && (
              <LinkedDevices
                campaignType={campaign.type}
                hasActionWithDisabledManualLogging={
                  campaign.hasActionWithDisabledManualLogging
                }
              />
            )}
            <Heading className="mt-2 p-3" level={2}>
              {sharedTranslations.actions}
            </Heading>
            <Stack space={2}>
              {campaign.acts.map((action) => (
                <ActionPanel
                  action={action}
                  campaign={campaign}
                  isSubmitDisabled={isPreview}
                  key={action.id}
                  onAchievementEarn={onAchievementEarn}
                />
              ))}
            </Stack>
          </div>

          <div className="col-sm-6">
            <Heading className="mt-2 p-3" level={2}>
              {campaignTranslations.gallery}
            </Heading>
            <ActivityGallery
              activityFieldName={ACTIVITIES.campaign}
              activityId={campaign.id}
              previewWithNoData={previewWithNoData}
            />

            <Heading className="mt-2 p-3" level={2}>
              {campaignTranslations.recentActivities}
            </Heading>
            <CampaignNewsFeed
              campaign={campaign}
              isPreview={isPreview}
              previewWithNoData={previewWithNoData}
            />
          </div>
        </div>
      </CenteredContent>
    </div>
  </>
)

CampaignLayoutDesktop.propTypes = {
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  isPreview: PropTypes.bool,
  onAchievementEarn: PropTypes.func.isRequired,
  previewWithNoData: PropTypes.bool,
}
CampaignLayoutDesktop.defaultProps = {
  isPreview: false,
  previewWithNoData: false,
}

export default CampaignLayoutDesktop
