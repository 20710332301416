// Vendor
import React, { useState } from 'react'
import { FormattedNumber, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

// WeSpire
import { intl } from 'utilities/localization'
import { RetroactiveMatchModal } from './modal'
import { Link } from '@material-ui/core'

const { formatMessage } = intl


const messages = defineMessages({
  approve: {
    defaultMessage: 'Approve',
    id: 'retroactiveMatchRequestList.approve',
  },
  details: {
    defaultMessage: 'View Receipt',
    id: 'retroactiveMatchRequestRow.details',
  },
  receiptNotFound: {
    defaultMessage: 'No Receipt Available',
    id: 'retroactiveMatchRequestList.receiptNotFound',
  },
  reject: {
    defaultMessage: 'Reject',
    id: 'retroactiveMatchRequestList.reject',
  },
})

const RetroactiveMatchRequestRow = ({ retroactiveMatchRequest, refetch }) => {
  const [showModal, setShowModal] = useState(false)
  const [action, setAction] = useState('')

  const closeModal = () => {
    setShowModal(false)
    setAction('')
  }

  const setShowAcceptModal = () => {
    setShowModal(true)
    setAction('approved')
  }

  const setShowRejectModal = () => {
    setShowModal(true)
    setAction('rejected')
  }

  const setShowDetailsModal = () => {
    setShowModal(true)
    setAction('details')
  }

  const convertTimestamp = (timestamp, dateOnly) => {
    if(!timestamp){ return }
    const date = new Date(parseInt(timestamp))
    if(dateOnly){
      return date.toLocaleString(undefined, {timeZoneName: "short"})
    } else {
      return date.toLocaleDateString()
    }
  }

  return (
    <>
      <div className="row fs-2">
        <span className="col-1 contained">
          {convertTimestamp(retroactiveMatchRequest.originalTransaction?.created, true)}
        </span>
        <span className="col-2 contained">
          <span>{retroactiveMatchRequest.user.name}</span><br/>
          <span>{retroactiveMatchRequest.user.email}</span><br/>
        </span>
        <span className="col-1 contained">
          <span>{retroactiveMatchRequest.charity.name}</span><br/>
          <span>{retroactiveMatchRequest.charity.ein}</span>
        </span>
        <span className="col-1 contained">
          <span>{retroactiveMatchRequest.originalTransaction?.id}</span>
        </span>
        <span className="col-1 contained">
          {retroactiveMatchRequest.originalTransaction?.date}
        </span>
        <span className="col-1 contained">
          <FormattedNumber currency="USD" style="currency" value={retroactiveMatchRequest.request.amount} />
        </span>
        <span className="col-2 contained">
          <Link
            className="flex-shrink-none ml-2"
            onClick={ setShowDetailsModal }
            size="small"
          >
            { retroactiveMatchRequest?.originalTransaction?.activityId?.includes("cloudinary") ?
              formatMessage(messages.details)
            :
              formatMessage(messages.receiptNotFound)
            }
          </Link>
          <br></br>
          { retroactiveMatchRequest.request.status === 'Pending' &&
            <>
              <Button
                className="flex-shrink-none ml-2 mt-2 bg-green-1"
                style={{color: '#fffffff'}}
                onClick={ setShowAcceptModal }
                size="small"
              >
                {formatMessage(messages.approve)}
              </Button>
              <br></br>
              <Button
                className="flex-shrink-none ml-2 mt-2 bg-red-1"
                style={{color: '#fffffff'}}
                onClick={ setShowRejectModal }
                size="small"
              >
                {formatMessage(messages.reject)}
              </Button>
            </>
          }
        </span>
      </div>
      {showModal && (
        <RetroactiveMatchModal
          retroactiveMatchRequest={retroactiveMatchRequest}
          closeModal={closeModal}
          action={action}
          refetch={refetch}
        />
      )}
    </>
  )
}

RetroactiveMatchRequestRow.propTypes = {
  refetch: PropTypes.func.isRequired,
  retroactiveMatchRequest: PropTypes.shape({
    originalTransaction: PropTypes.shape({
      id: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      activityId: PropTypes.string.isRequired,
    }),
    request: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired
    }),
    charity: PropTypes.shape({
      ein: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      accountNumber: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export { RetroactiveMatchRequestRow }
