// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import v from 'voca'
import { withFormsy } from 'formsy-react'

// WeSpire
import {
  CloudinaryImageThumbnailUrl,
  slugToSecureUrl,
} from 'utilities/cloudinary_utils'
import EditableImageButton from './editable_image_button'
import EditableImageIcon from './editable_image_icon'
import HiddenFileInput from 'components/form/hidden_file_input'
import uploadToCloudinary from 'utilities/upload_to_cloudinary'

export class EditableImageAttachmentBase extends React.Component {
  static propTypes = {
    bottomHelperText: PropTypes.string,

    className: PropTypes.string,
    cloudinaryConfig: PropTypes.shape({
      cloudName: PropTypes.string.isRequired,
      folder: PropTypes.string,
      resourceType: PropTypes.string,
      uploadPreset: PropTypes.string.isRequired,
    }).isRequired,
    customAccept: PropTypes.string,
    customHeight: PropTypes.string,
    customIconName: PropTypes.string,
    customWidth: PropTypes.string,
    disabled: PropTypes.bool,
    helperText: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object,
      PropTypes.string,
    ]),
    imageClassName: PropTypes.string,
    imageHeight: PropTypes.string,
    imageWidth: PropTypes.string,
    isDocument: PropTypes.bool,
    isPristine: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onImageUpload: PropTypes.func,
    onImageUploading: PropTypes.func,
    onRemoveImage: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    shape: PropTypes.oneOf(['circle', 'rectangle', 'square']),
    value: PropTypes.string,
  }

  static defaultProps = {
    bottomHelperText: '',
    className: null,
    customAccept: undefined,
    customHeight: undefined,

    customIconName: 'camera',
    customWidth: undefined,
    disabled: false,
    helperText: null,
    imageClassName: 'size-editable-image-attachment',
    imageHeight: null,
    imageWidth: null,
    isDocument: false,
    label: null,
    onImageUpload: () => {},
    onImageUploading: () => {},
    onRemoveImage: () => {},
    shape: null,
    value: null,
  }

  state = {
    fileName: null,
    imageUploadProgress: null,
  }

  handleFileInputClick = () => {
    if (this.props.disabled) {
      return false
    }

    this.fileInput.click()
  }

  handleOnChange = (file) => {
    const { cloudinaryConfig, onImageUpload, onImageUploading, setValue } =
      this.props
    const { cloudName, uploadPreset, resourceType, folder } = cloudinaryConfig
    const clearUploadProgress = () => {
      this.setState({ imageUploadProgress: null })
      onImageUploading(false)
    }
    const onUploadProgress = (event) =>
      this.setState({
        imageUploadProgress: Math.floor((event.loaded * 100) / event.total),
      })

    setValue('')
    onImageUploading(true)
    this.setState({
      fileName: file.name,
    })

    uploadToCloudinary(file, {
      cloudName,
      folder,
      onUploadProgress,
      resourceType,
      uploadPreset,
    })
      .then(({ imageSlug }) => {
        setValue(imageSlug)
        clearUploadProgress()
        onImageUpload()
      })
      .catch(() => {
        clearUploadProgress()
      })
  }

  handleRemoveImage = () => {
    const { disabled, onRemoveImage, setValue } = this.props

    if (disabled) {
      return false
    }

    setValue('')
    onRemoveImage()
    this.fileInput.value = null
  }

  setFileInputRef = (input) => (this.fileInput = input)

  thumbnailUrl = () => {
    const { cloudinaryConfig, imageHeight, imageWidth, value } = this.props

    // Value could be the "secureUrl" which is a full URL
    // OR it could be the slug  in which case we need to convert it.
    let imageUrl =
      value && !value.startsWith('http')
        ? slugToSecureUrl(cloudinaryConfig, value)
        : value

    return new CloudinaryImageThumbnailUrl(imageUrl, {
      height: imageHeight,
      width: imageWidth,
    }).create()
  }

  render() {
    const {
      bottomHelperText,
      className,
      customAccept,
      customHeight,
      customIconName,
      customWidth,
      disabled,
      helperText,
      imageClassName,
      isDocument,
      isPristine,
      isRequired,
      label,
      name,
      shape,
    } = this.props
    const { imageUploadProgress } = this.state
    const isLoading = imageUploadProgress && imageUploadProgress > 0

    return (
      <div className={className}>
        {label && (
          <FormLabel
            className="fs-2 fw-semi-bold text-black-1"
            id={`${name}-label`}
          >
            {isRequired ? label + '*' : label}
          </FormLabel>
        )}
        {helperText && (
          <FormHelperText className="mb-3">{helperText}</FormHelperText>
        )}

        <HiddenFileInput
          customAccept={customAccept}
          onChange={this.handleOnChange}
          setRef={this.setFileInputRef}
        />

        <div className={imageClassName}>
          {!v.isBlank(this.props.value) ? (
            <EditableImageButton
              customHeight={customHeight}
              customWidth={customWidth}
              fileName={this.state.fileName}
              imageURL={this.thumbnailUrl()}
              isDocument={isDocument}
              name={name}
              onClick={this.handleFileInputClick}
              onClickDelete={this.handleRemoveImage}
              shape={shape}
            />
          ) : (
            <EditableImageIcon
              customHeight={customHeight}
              customIconName={customIconName}
              customWidth={customWidth}
              disabled={disabled}
              isDocument={isDocument}
              isLoading={isLoading}
              isPristine={isPristine}
              name={name}
              onClick={this.handleFileInputClick}
              required={isRequired}
              shape={shape}
            />
          )}
        </div>
        {bottomHelperText && (
          <p className="fs-1 text-black-4 mt-1">{bottomHelperText}</p>
        )}
      </div>
    )
  }
}

const EditableFileAttachmentBase = ({
  onFileUpload,
  onFileUploading,
  onRemoveFile,
  ...props
}) => {
  return (
    <EditableImageAttachmentBase
      customIconName="file_upload_outline"
      isDocument
      onImageUpload={onFileUpload}
      onImageUploading={onFileUploading}
      onRemoveImage={onRemoveFile}
      {...props}
    />
  )
}
EditableFileAttachmentBase.propTypes = {
  onFileUpload: PropTypes.func,
  onFileUploading: PropTypes.func,
  onRemoveFile: PropTypes.func,
}
EditableFileAttachmentBase.defaultProps = {
  onFileUpload: () => {},
  onFileUploading: () => {},
  onRemoveFile: () => {},
}

export const EditableFileAttachment = withFormsy(EditableFileAttachmentBase)

export default withFormsy(EditableImageAttachmentBase)
