// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'

// WeSpire
import { Header } from './header'
import WeQuery from 'components/application/we_query'
import { getPrimaryNavLinks } from 'components/shared/navigator/utils'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { useIsAuthenticated } from 'utilities/hooks/use_is_authenticated'
import formatNotificationsCount from 'utilities/format_notifications_count'

// Sub-Components
import { MainHeaderContent as Content } from './content'

export const userNavGql = gql`
  query userNav {
    brandConfig {
      enableUserTour
      id
    }
    currentUser {
      firstName
      lastName
      id
      name
      unreadNotificationsCount
      managementDropdownNavigationLinks {
        href
        title
      }
    }
    carbonFootprintEnabled
    ergsEnabled
    givingEnabled
    ourImpactEnabled
    volunteerHistoryEnabled
  }
`

const HeaderContainer = ({
  avatarUrl,
  brandLogoUrl,
  className,
  linkPaths,
  location,
  sideNavIsOpen,
  toggleSideNav,
}) => {
  const isAuthenticated = useIsAuthenticated()

  if (!isAuthenticated) {
    return (
      <Header
        avatarUrl={avatarUrl}
        brandLogoUrl={brandLogoUrl}
        className={className}
        linkPaths={linkPaths}
        mainLinks={[]}
        managementDropdownNavigationLinks={[]}
        notificationsCount={0}
        sideNavIsOpen={sideNavIsOpen}
        toggleSideNav={toggleSideNav}
        unreadNotificationsCount={0}
      />
    )
  }

  return (
    <WeQuery
      error={
        <Content
          avatarUrl={avatarUrl}
          brandLogoUrl={brandLogoUrl}
          className={className}
          linkPaths={linkPaths}
          location={location}
          sideNavIsOpen={sideNavIsOpen}
          toggleSideNav={toggleSideNav}
        />
      }
      loader={<CenteredPaddedLoadingIndicator padding="py-1" />}
      query={userNavGql}
    >
      {({
        data: {
          carbonFootprintEnabled,
          currentUser: {
            managementDropdownNavigationLinks,
            firstName,
            unreadNotificationsCount,
          },
          brandConfig: { enableUserTour },
          ergsEnabled,
          givingEnabled,
          ourImpactEnabled,
          volunteerHistoryEnabled,
        },
      }) => {
        const mainLinks = getPrimaryNavLinks(
          ergsEnabled,
          linkPaths,
          ourImpactEnabled
        )
        const notificationsCount = formatNotificationsCount(
          unreadNotificationsCount
        )

        return (
          <Header
            avatarUrl={avatarUrl}
            brandLogoUrl={brandLogoUrl}
            carbonFootprintEnabled={carbonFootprintEnabled}
            className={className}
            enableUserTour={enableUserTour}
            firstName={firstName}
            givingEnabled={givingEnabled}
            isAuthenticated
            linkPaths={linkPaths}
            mainLinks={mainLinks}
            managementDropdownNavigationLinks={
              managementDropdownNavigationLinks || []
            }
            notificationsCount={notificationsCount}
            sideNavIsOpen={sideNavIsOpen}
            toggleSideNav={toggleSideNav}
            unreadNotificationsCount={unreadNotificationsCount}
            volunteerHistoryEnabled={volunteerHistoryEnabled}
          />
        )
      }}
    </WeQuery>
  )
}

HeaderContainer.propTypes = {
  avatarUrl: PropTypes.string,
  brandLogoUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  linkPaths: PropTypes.shape({
    achievementsPath: PropTypes.string.isRequired,
    activitiesPath: PropTypes.string.isRequired,
    backPath: PropTypes.string.isRequired,
    dashboardPath: PropTypes.string.isRequired,
    invitePath: PropTypes.string.isRequired,
    notificationsPath: PropTypes.string.isRequired,
    profilePath: PropTypes.string.isRequired,
    searchPath: PropTypes.string.isRequired,
    teamsPath: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  sideNavIsOpen: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
}

HeaderContainer.defaultProps = {
  avatarUrl: null,
  className: null,
}

export const undecorated = HeaderContainer

export default withRouter(HeaderContainer)
