// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import { withFormsy } from 'formsy-react'

// WeSpire
import TextFieldValidations from 'components/form/text_field_validations'
import { unmask } from 'utilities/masking'

class FormTextField extends React.Component {
  handleChangeValue = (event) => {
    const mask = this.props.mask
    const value = event.currentTarget.value
    this.props.setValue(mask ? unmask(mask, value) : value)
  }

  getInputComponent = (error, helperText, label, required) => {
    const { autoComplete, className, mask, name, textFieldProps, value } =
      this.props
    // As a controlled component, this should always have a default value.
    // If it starts as undefined, it will be switched from uncontrolled to
    // controlled as you type, which angers React.
    const { InputLabelProps, inputProps, style } = textFieldProps
    const inputClassName = inputProps && inputProps.className
    const inputAriaDescribedBy = inputProps && inputProps['aria-describedby']
    const InputLabelClassName = InputLabelProps && InputLabelProps.className
    const InputLabelClasses = InputLabelProps && InputLabelProps.classes
    const InputLabelShrinkClasses =
      InputLabelClasses && InputLabelClasses.shrink
    const uniqueId = `${name}-${Math.random()}`

    const textField = (
      <TextField
        {...textFieldProps}
        autoComplete={autoComplete}
        className={className}
        error={error}
        fullWidth
        helperText={helperText}
        id={uniqueId}
        InputLabelProps={{
          ...InputLabelProps,
          // By default, visually truncate the text field label to 1 line only.
          // When it's focused or filled, display up to 2 lines of the label and
          // visually hide the rest of the overflow, so it doesn't overlap and
          // interfere with user inputted text.
          classes: {
            ...InputLabelClasses,
            shrink: cx('max-lines-2-sm ws-normal', InputLabelShrinkClasses),
          },
          className: cx(
            'hyphens lh-sm text-sentence text-truncate ',
            InputLabelClassName
          ),
          htmlFor: uniqueId,
          id: `${name}-label`,
          style: {
            ...style,
            maxWidth: 'calc(100% - 1.5rem)',
          },
        }}
        inputProps={{
          ...textFieldProps.inputProps,
          'aria-describedby': `${uniqueId}-helper-text ${inputAriaDescribedBy}`,
          'aria-required': required,
          className: cx('fs-2 lh-md', inputClassName),
          'data-test': name,
          id: uniqueId,
        }}
        label={label}
        name={name}
        onChange={this.handleChangeValue}
        value={value}
        variant="outlined"
      />
    )

    // Wrap our TextField in an InputMask only when we have a mask to apply.
    if (mask) {
      return (
        <InputMask
          alwaysShowMask
          mask={mask}
          onChange={this.handleChangeValue}
          value={value}
        >
          {() => textField}
        </InputMask>
      )
    } else {
      return textField
    }
  }

  render() {
    return (
      <TextFieldValidations {...this.props}>
        {this.getInputComponent}
      </TextFieldValidations>
    )
  }
}

FormTextField.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  textFieldProps: PropTypes.shape({
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.shape({
      'aria-describedby': PropTypes.string,
      className: PropTypes.string,
    }),
    style: PropTypes.object,
  }),
  value: PropTypes.any,
}

FormTextField.defaultProps = {
  autoComplete: 'off',
  className: null,
  mask: null,
  setValue: () => {},
  textFieldProps: {
    inputProps: {
      className: null,
    },
  },
  value: '',
}

export const undecorated = FormTextField

export default withFormsy(FormTextField)
