import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import { useFormikContext } from 'formik'

// westyle
import Button from 'src/common/button/Button'
import InfoContainer from 'src/common/InfoContainer'


const SubmissionSection = ({
  donationAmount,
  isSubmitting,
  isValid,
  selectedCurrency,
}) => {
  const intl = useIntl()
  const formik = useFormikContext()
  const { errors } = formik

  return (
    <>
      {errors.submissionError && (
        <InfoContainer body={errors.submissionError} variant="error" />
      )}
      <div className="flex flex-col justify-center mt-9 items-center">
        <h2>
          <FormattedMessage
            defaultMessage="Total due {amount}"
            id="donationForm.totalDue"
            values={{
              amount: (
                <CurrencyFormat
                  className="text-2xl font-medium font-sans tracking-normal text-black-1"
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale
                  prefix={selectedCurrency.symbol}
                  value={donationAmount || 0}
                />
              ),
            }}
          />
        </h2>
        <Button
          className="my-4 w-full"
          disabled={donationAmount < 10 || !isValid || isSubmitting}
          text={intl.formatMessage({
            defaultMessage: 'Continue to payment',
            id: 'donationForm.continueToPayment',
          })}
          type="submit"
        />
        <p className="text-sm text-black-2">
          <FormattedMessage 
            defaultMessage="You will be redirected to Stripe. All donation transactions will appear on your bank/credit card statements as GIVINGA FOUNDATION, our nonprofit 501(c)(3) partner. By continuing, you are agreeing to the Terms & Conditions." 
            id="donationForm.youWillBeRedirectedToStripe" 
          />
        </p>
      </div>
    </>
  )
}

export default SubmissionSection
