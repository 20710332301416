// Vendor
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import v from 'voca'
import { defineMessages } from 'react-intl'

// WeSpire
import CommentsManager from 'components/application/comments/comments_manager'
import Divider from 'components/ui/divider'
import { Heading } from 'components/ui/heading'
import IdeaImageButton from 'components/idea_board/idea/image_button'
import { sharedTranslations } from 'components/shared/translations'
import ShareLink from 'components/ui/share_link'
import Stack from 'components/ui/stack'
import withImageDialog from 'components/helpers/with_image_dialog'
import { intl } from 'utilities/localization'

const messages = defineMessages({
  copiedUrl: {
    defaultMessage: 'Idea URL copied to clipboard!',
    id: 'ideaDetails.copiedUrl',
  },
  shareIdea: {
    defaultMessage: 'share Idea',
    id: 'ideaDetails.shareIdea',
  },
})

export const IdeaDetailsBase = ({
  commentsList,
  customFieldResponses,
  description,
  displayNotification,
  formRequirements,
  handleImageClick,
  id,
  ideaUrl,
  imageUrl,
  onIdeaDetailsMount,
  setCommentFormRef,
  title,
}) => {
  const imageExists = !v.isBlank(imageUrl)
  const { formatMessage } = intl

  useEffect(() => {
    onIdeaDetailsMount()
  }, [])

  return (
    <div className="border-top m-0 pt-2 w-100" data-test="idea-details">
      {customFieldResponses.length > 0 ? (
        <Stack as="ul" className="list-none px-0" space={3}>
          {customFieldResponses.map((field) => (
            <li key={field.id}>
              <Heading className="fs-2 text-black-3" level={4}>
                {field.name}
              </Heading>
              <p className="fs-2 mt-2">{field.response}</p>
            </li>
          ))}
        </Stack>
      ) : (
        <>
          <Heading className="fs-2 text-black-3" level={4}>
            {sharedTranslations.description}
          </Heading>
          <p className="my-0 py-3 ws-pre">{description}</p>
        </>
      )}

      <div className="[ d-flex align-items-end ] [ mb-3 mt-4 ]">
        {imageExists && (
          <IdeaImageButton
            onClick={handleImageClick}
            title={title}
            url={imageUrl}
          />
        )}
        <ShareLink
          buttonLabel={formatMessage(messages.shareIdea)}
          className="ml-auto text-underline"
          displayNotification={displayNotification}
          notificationMessage={formatMessage(messages.copiedUrl)}
          url={ideaUrl}
        />
      </div>
      <Divider />
      <CommentsManager
        commentable={{
          id: id,
          type: 'IdeaBoard::Submission',
        }}
        commentCreatorProps={{
          className: 'py-3',
        }}
        comments={commentsList}
        commentsDisplayProps={{
          commentsListProps: {
            className: 'pb-2 pt-3',
          },
          commentsSummaryProps: {
            className: 'py-3',
          },
        }}
        displayCommentSummary={false}
        formRequirements={formRequirements}
        setCommentFormRef={setCommentFormRef}
      />
    </div>
  )
}

IdeaDetailsBase.propTypes = {
  commentsList: PropTypes.array.isRequired,
  customFieldResponses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      response: PropTypes.string,
    })
  ).isRequired,
  description: PropTypes.string,
  displayNotification: PropTypes.func.isRequired,
  formRequirements: PropTypes.shape({
    currentUser: PropTypes.shape({
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  handleImageClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  ideaUrl: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onIdeaDetailsMount: PropTypes.func,
  setCommentFormRef: PropTypes.func,
  title: PropTypes.string.isRequired,
}

IdeaDetailsBase.defaultProps = {
  description: null,
  imageUrl: null,
  onIdeaDetailsMount: () => {},
  setCommentFormRef: null,
}

export default withImageDialog(IdeaDetailsBase)
