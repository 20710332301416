import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { withRouter } from 'react-router-dom'

// WeSpire
import Charity from 'components/charities/charity'
import CharitySearch from 'components/charities/charity_search'
import CharitySearchResults from 'components/charities/search_results'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'

export const messages = defineMessages({
  donate: {
    defaultMessage: 'Donate',
    id: 'charities.donate',
  },
})

const Charities = ({
  charities: initialCharities,
  disableDonation,
  hideCharitySearch,
  match,
}) => {
  const [charities, setCharities] = useState(initialCharities)
  const [status, setStatus] = useState('pending')
  const [searchType, setSearchType] = useState('charity')

  const handleCharitySearch = ({ charities, status, searchType}) => {
    setCharities(charities)
    setStatus(status)
    setSearchType(searchType)
  }

  return (
    <>
      {!hideCharitySearch && (
        <CharitySearch onCharitySearch={handleCharitySearch} />
      )}
      <Stack
        as="ol"
        className="p-0 m-0 mt-2"
        divider="default"
        id="search-results"
      >
        <CharitySearchResults
          charities={charities}
          renderCharity={({ charity, ...props }) => (
            <Charity
              {...props}
              charity={charity}
              renderAction={
                <LinkButton
                  className="flex-shrink-none align-self-end | m-2 fs-4"
                  disabled={disableDonation}
                  to={`/donations/new/${charity?.ein}?activity_id=${match.params.id}&search_type=${searchType}`}
                  variant="secondaryAction"
                >
                  {intl.formatMessage(messages.donate)}
                  <ScreenReader text={`to ${name}`} />
                </LinkButton>
              }
            />
          )}
          status={status}
        />
      </Stack>
    </>
  )
}

Charities.propTypes = {
  charities: PropTypes.array,
  disableDonation: PropTypes.bool,
  hideCharitySearch: PropTypes.bool,
  match: PropTypes.object.isRequired,
}

export default withRouter(Charities)
