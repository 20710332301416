// Vendor
import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse, IconButton } from '@material-ui/core'
import { defineMessages } from 'react-intl'
import v from 'voca'

// WeSpire
import Avatar, { AvatarSizes } from 'components/application/avatar'
import CloudinaryQuery from 'components/queries/cloudinary_query'
import { commentTranslations } from 'components/application/comments/comment_form'
import {
  currentUserPropType,
  withCurrentUser,
} from 'components/helpers/with_current_user'
import EditableImageAttachment from 'components/application/editable_image_attachment'
import FormTextField from 'components/form/text_field'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import SubmitButton from 'components/ui/submit_button'
import UserAutocomplete from 'components/form/user_autocomplete'

const { formatMessage } = intl
const messages = defineMessages({
  commentFieldHelperText: {
    defaultMessage:
      'Ask a question, start a discussion, upload a photo, or tag other people.',
    id: 'newsFeedItemFormInputs.commentFieldHelperText',
  },
  photoIconButtonLabel: {
    defaultMessage: 'Add photo',
    id: 'NewsFeedItemFormInputs.photoIconButtonLabel',
  },
  userTagsFieldHelperText: {
    defaultMessage: 'Tag other people',
    id: 'NewsFeedItemFormInputs.userTagsFieldHelperText',
  },
  userTagsIconButtonLabel: {
    defaultMessage: 'Choose people to tag',
    id: 'NewsFeedItemFormInputs.userTagsIconButtonLabel',
  },
})

const NewsFeedItemFormInputsBase = ({
  currentUser: { avatarUrl },
  closeImageAndMentionsFieldsHook,
  handleCommentFocus,
  handleOpenImage,
  handleOpenMentions,
  isSubmitting,
  isRequired,
  withSubmitButton,
}) => {
  const [hasImageField, setHasImageField] = useState(false)
  const [hasMentionsField, setHasMentionsField] = useState(false)

  let imageInputRef = createRef()
  let mentionsInput

  const handleImageFocus = () =>
    imageInputRef && imageInputRef.handleFileInputClick()

  const handleMentionsFocus = () => mentionsInput && mentionsInput.focus()

  const closeImageAndMentionsFields = () => {
    setHasImageField(false)
    setHasMentionsField(false)
  }

  closeImageAndMentionsFieldsHook &&
    closeImageAndMentionsFieldsHook(closeImageAndMentionsFields)

  const commentFocus = () => handleCommentFocus && handleCommentFocus()

  const openImage = () => {
    handleOpenImage && handleOpenImage()
    setHasImageField(true)
  }

  const openMentions = () => {
    handleOpenMentions && handleOpenMentions()
    setHasMentionsField(true)
  }

  return (
    <>
      <Stack className="p-3" space={3}>
        <div className="d-flex align-items-start">
          <Avatar className="mt-1" image={avatarUrl} size={AvatarSizes.small} />
          <FormTextField
            className="m-0 ml-3"
            name="note"
            required={isRequired}
            textFieldProps={{
              helperText: formatMessage(messages.commentFieldHelperText),
              InputProps: {
                classes: {
                  notchedOutline: 'rounded-left-right',
                },
                onFocus: commentFocus,
              },
              label: v.capitalize(sharedTranslations.comment),
              margin: 'dense',
              multiline: true,
              variant: 'outlined',
            }}
          />
        </div>

        <Collapse
          classes={{
            hidden: 'sr-only',
            wrapperInner: 'stack stack--3',
          }}
          in={hasImageField || hasMentionsField}
        >
          <Collapse
            classes={{ hidden: 'sr-only' }}
            in={hasImageField}
            onEntered={handleImageFocus}
          >
            <CloudinaryQuery>
              {(cloudinaryConfig) => (
                <EditableImageAttachment
                  cloudinaryConfig={cloudinaryConfig}
                  imageClassName="w-100"
                  imageHeight="450"
                  imageWidth="800"
                  innerRef={(c) => (imageInputRef = c)}
                  name="image"
                  shape="rectangle"
                />
              )}
            </CloudinaryQuery>
          </Collapse>

          <Collapse
            classes={{ hidden: 'sr-only' }}
            className="flex-grow"
            in={hasMentionsField}
            onEntered={handleMentionsFocus}
          >
            <UserAutocomplete
              name="taggedUserSelections"
              setInputRef={(input) => (mentionsInput = input)}
              textFieldProps={{
                helperText: formatMessage(messages.userTagsFieldHelperText),
                label: sharedTranslations.tags,
                margin: 'dense',
              }}
            />
          </Collapse>
        </Collapse>
      </Stack>

      <div className="[ d-flex align-items-center justify-content-between ] [ border-top ]">
        <div className="fs-5 text-black-4">
          <IconButton className="border-right rounded-0" onClick={openImage}>
            <Icon
              iconName="camera"
              title={formatMessage(messages.photoIconButtonLabel)}
            />
          </IconButton>
          <IconButton className="border-right rounded-0" onClick={openMentions}>
            <Icon
              iconName="people"
              title={formatMessage(messages.userTagsIconButtonLabel)}
            />
          </IconButton>
        </div>
        {withSubmitButton && (
          <SubmitButton
            className="mr-1"
            color="secondary"
            data-test="post"
            isSubmitting={isSubmitting}
            type="submit"
          >
            {commentTranslations.post}
          </SubmitButton>
        )}
      </div>
    </>
  )
}

NewsFeedItemFormInputsBase.propTypes = {
  closeImageAndMentionsFieldsHook: PropTypes.func.isRequired,
  currentUser: currentUserPropType.isRequired,
  handleCommentFocus: PropTypes.func,
  handleOpenImage: PropTypes.func,
  handleOpenMentions: PropTypes.func,
  isRequired: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  withSubmitButton: PropTypes.bool,
}

NewsFeedItemFormInputsBase.defaultProps = {
  handleCommentFocus: null,
  handleOpenImage: null,
  handleOpenMentions: null,
  isRequired: false,
  isSubmitting: false,
  withSubmitButton: false,
}

const NewsFeedItemFormInputs = withCurrentUser(
  NewsFeedItemFormInputsBase,
  <SkeletonLoading />
)

export { NewsFeedItemFormInputs }
