import React from 'react'

// WeSpire
import { Footer } from './index'

// Small wrapper that makes this easier to use in rails.
// We export default to support the rails react_component helper.
export default () => (
  <div className="react">
    <div className="absolute-bottom-left w-100">
      <Footer />
    </div>
  </div>
)
