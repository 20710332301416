import React from 'react'
import PropTypes from 'prop-types'

/**
 * Using dangerouslySetInnerHTML to render the capitalize from the question title data
 * should be ok to use this since its not really accepting external parameters and its a plain text, no risk of XSS
 */
const BigQuestionText = ({ question }) => (
  <h1
    className="flex font-bold my-5 text-lg md:text-3xl text-gray"
    data-test="main-question-test"
  >
    <span
      /* eslint-disable react/no-danger */
      dangerouslySetInnerHTML={{
        __html: question,
      }}
    />
  </h1>
)

BigQuestionText.propTypes = {
  question: PropTypes.string.isRequired,
}

export default BigQuestionText
