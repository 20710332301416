/* eslint-disable react/prop-types */
import React, { useState } from 'react'

export const ToggleButton = ({
  children,
  value,
  selected,
  onChange,
  isFirst,
  isLast,
}) => {
  const handleClick = () => {
    onChange(value)
  }

  const baseStyles =
    'py-2 px-4 border border-gray-300 text-black focus:outline-none'
  const selectedStyles = 'bg-grayscale-3'
  const nonSelectedStyles = 'bg-white'

  let additionalStyles = ''
  if (isFirst) {
    additionalStyles = 'rounded-l-md rounded-r-none'
  } else if (isLast) {
    additionalStyles = 'rounded-r-md rounded-l-none'
  } else {
    additionalStyles = 'border-l-0 border-r-0 rounded-none'
  }

  return (
    <button
      className={`${baseStyles} ${
        selected ? selectedStyles : nonSelectedStyles
      } ${additionalStyles}`}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export const ToggleButtonGroup = ({
  children,
  exclusive,
  onChange,
  defaultValue,
}) => {
  const [selected, setSelected] = useState(defaultValue)

  const handleChange = (value) => {
    if (exclusive) {
      setSelected([value])
      if (onChange) {
        onChange([value])
      }
    } else {
      setSelected((prevSelected) => {
        let newSelected
        if (prevSelected.includes(value)) {
          newSelected = prevSelected.filter((item) => item !== value)
        } else {
          newSelected = [...prevSelected, value]
        }
        if (onChange) {
          onChange(newSelected)
        }
        return newSelected
      })
    }
  }

  const buttons = React.Children.map(children, (child, index) => {
    const isFirst = index === 0
    const isLast = index === children.length - 1
    return React.cloneElement(child, {
      selected: selected.includes(child.props.value),
      onChange: handleChange,
      isFirst,
      isLast,
    })
  })

  return <div className="flex">{buttons}</div>
}
