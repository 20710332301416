// Vendor
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { intl } from 'utilities/localization'

export const groupByMonth = (opportunities) =>
  // Group flat array of opportunities into array of nested objects:
  // 1. Group opportunities by day
  // 2. Group days by month
  // 3. Return an array of month groups
  // Format all ISO datestrings into human-readable and HTML-ready labels.
  cloneDeep(opportunities).reduce((grouped, opportunity) => {
    // Primary datetime values used across all data
    const startsAtDate = new Date(opportunity.startsAt)
    const endsAtDate = new Date(opportunity.endsAt)

    // Datetime substrings used across different labels
    const day = intl.formatDate(startsAtDate, { day: '2-digit' })
    const month = intl.formatDate(startsAtDate, { month: '2-digit' })
    const year = intl.formatDate(startsAtDate, { year: 'numeric' })

    // Set up `opportunity` data
    const startsAtDateTime = intl.formatDate(startsAtDate, {
      hour: 'numeric',
      minute: 'numeric',
    })
    opportunity.startsAt = startsAtDateTime
    const endsAtTimeDateTime = intl.formatDate(endsAtDate, {
      hour: 'numeric',
      minute: 'numeric',
    })
    opportunity.endsAt = endsAtTimeDateTime

    // Set up `day` data
    const dayDateTime = `${month}–${day}`
    const dayLabel = intl.formatDate(startsAtDate, {
      day: '2-digit',
      month: 'long',
      weekday: 'long',
    })
    const newDayGroup = {
      dateTime: dayDateTime,
      label: dayLabel,
      opportunities: [opportunity],
    }

    // Set up `month` data
    const monthDateTime = `${year}–${month}`
    const monthLabel = intl.formatDate(startsAtDate, {
      month: 'long',
      year: 'numeric',
    })
    const newMonthGroup = {
      dateTime: monthDateTime,
      days: [newDayGroup],
      label: monthLabel,
    }

    // If month matches existing group
    const monthGroup = grouped.find((m) => m.label === monthLabel)
    if (monthGroup) {
      // ... and day matches existing group
      const dayGroup = monthGroup.days.find((d) => d.label === dayLabel)
      if (dayGroup) {
        // ... push opportunity into matching month and day groups.
        dayGroup.opportunities.push(opportunity)
        // ... and day does not match existing group
      } else {
        // ... push new day group + opportunity into matching month group.
        monthGroup.days.push(newDayGroup)
      }
      // If month does not match existing group
    } else {
      // ... push new month group + day group + opportunity into parent array.
      grouped.push(newMonthGroup)
    }

    return grouped
  }, [])

export const sortByDate = (opportunities) =>
  // Sort opportunities by:
  //  - start time (chronological, current to future)
  //  - end time (chronological, current to future)
  //  - eventName (alpha)
  cloneDeep(opportunities).sort((a, b) => {
    if (a.startsAt > b.startsAt) return 1 // Start date lower (past), move up
    if (a.startsAt < b.startsAt) return -1 // Start date higher (future), move down

    if (a.endsAt > b.endsAt) return 1 // End date lower (past), move up
    if (a.endsAt < b.endsAt) return -1 // End date higher (future), move down

    if (a.eventName > b.eventName) return 1 // Event name comes earlier in alphabet, move up
    if (a.eventName < b.eventName) return -1 // Event name comes later in alphabet, move down
  })
