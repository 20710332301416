// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import Paper from '@material-ui/core/Paper'

// WeSpire
import BannerLink from 'components/application/banner/link'
import CenteredContent from 'components/application/centered_content'
import { dateTimeDateLabel, dateTimeHourLabel } from 'utilities/date_formatter'
import { displayBanner, maybeDisplayUserTour } from 'redux/dispatchers'
import { DonationAccountNotFoundPage } from 'components/donations/pages/donation_account_not_found'
import { FacepileSizes } from 'components/application/avatar'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import ScreenReader from 'components/ui/screen_reader_text'
import { SharedNavBreadcrumb } from 'components/shared/nav_breadcrumb'
import Stack from 'components/ui/stack'
import { DocumentTitle } from 'components/shared/document_title'
import { PageQuery } from 'components/queries/page_query'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'
import GivingActivityCharities from './charities'
import GivingActivityDetails from './details'
import GivingActivityHero from './hero'

const pageQuery = gql`
  query givingActivity($id: ID!) {
    givingActivity(id: $id) {
      description
      endsAt
      id
      imageUrl
      name
      participants(limit: ${FacepileSizes.large}) {
        avatarUrl
        id
      }
      participantsCount
      searchAllCharities
      startsAt
    }
    currentUser {
      givingaUser {
        id
      }
      id
    }
  }
`

const messages = defineMessages({
  charities: {
    defaultMessage: 'charities',
    id: 'givingActivity.charities',
  },
  endOfGivingActivity: {
    defaultMessage:
      'This Giving Activity ended on {dateTimeDateLabel} at {dateTimeHourLabel}.',
    id: 'givingActivity.endOfGivingActivity',
  },
})

const GivingActivity = ({
  match: {
    params: { id },
  },
  isPreview,
}) => {
  return (
    <GraphQLFeatureFlag
      featureDisabledError={<DonationAccountNotFoundPage />}
      featureName="giving"
    >
      <PageQuery query={pageQuery} variables={{ id: id }}>
        {({
          data: {
            givingActivity,
            currentUser: { givingaUser },
          },
        }) => {
          let givingActivityDescription = givingActivity?.description
          let givingActivityImageUrl = givingActivity?.imageUrl
          if (!givingaUser) {
            displayBanner({
              content: (
                <>
                  {intl.formatMessage(
                    sharedMessages.contactSupportDonationAccount,
                    {
                      link: (...linkText) => (
                        <BannerLink
                          key="contactSupport"
                          to="mailto:support@wespire.com?subject=Giving%20Account%20Error"
                        >
                          {linkText}
                        </BannerLink>
                      ),
                    }
                  )}
                </>
              ),
              variant: 'info',
            })
          }
          const ended =
            givingActivity &&
            givingActivity.endsAt &&
            new Date(givingActivity.endsAt) < new Date()
          if (ended) {
            displayBanner({
              content: intl.formatMessage(messages.endOfGivingActivity, {
                dateTimeDateLabel: dateTimeDateLabel(givingActivity.endsAt),
                dateTimeHourLabel: dateTimeHourLabel(givingActivity.endsAt),
              }),
              variant: 'info',
            })
          }
          return givingActivity ? (
            <DocumentTitle
              title={`${givingActivity.name} • ${sharedTranslations.giving}`}
            >
              {!isPreview && (
                <SharedNavBreadcrumb currentLinkName={givingActivity.name} />
              )}
              {maybeDisplayUserTour('givingActivityPage')}

              <GivingActivityHero
                imageUrl={givingActivityImageUrl}
                name={givingActivity.name}
              />

              <Stack
                as={CenteredContent}
                className="position-relative px-3 px-sm-0"
                pull
                space={4}
              >
                <Paper
                  className="d-flex justify-content-center | px-3 py-5 px-sm-5"
                  component="section"
                >
                  <div className="mxw-measure w-100">
                    <ScreenReader as="h2" text="Details" />
                    <GivingActivityDetails
                      avatars={givingActivity.participants.map(
                        (participant) => participant.avatarUrl
                      )}
                      description={givingActivityDescription}
                      endsAt={givingActivity.endsAt}
                      participantsCount={givingActivity.participantsCount}
                      startsAt={givingActivity.startsAt}
                    />
                  </div>
                </Paper>
                <Paper
                  className="d-flex justify-content-center | px-3 py-5 px-sm-5"
                  component="section"
                >
                  <div className="mxw-measure w-100" data-test="charities">
                    <GivingActivityCharities
                      disableDonation={ended}
                      givingActivityId={givingActivity.id}
                      searchAllCharities={givingActivity.searchAllCharities}
                    />
                  </div>
                </Paper>
              </Stack>
            </DocumentTitle>
          ) : (
            <DocumentTitle
              title={intl.formatMessage(sharedMessages.resourceNotFound, {
                resource: sharedTranslations.givingactivity,
              })}
            >
              <MissingResourcePage
                resourceName={sharedTranslations.givingactivity}
              />
            </DocumentTitle>
          )
        }}
      </PageQuery>
    </GraphQLFeatureFlag>
  )
}

GivingActivity.defaultProps = {
  isPreview: false,
}

GivingActivity.propTypes = {
  isPreview: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
}

export default GivingActivity
