// Setup
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import { withFormsy } from 'formsy-react'

// WeSpire
import TextFieldValidations from 'components/form/text_field_validations'

class FormSelect extends React.Component {
  constructor(props) {
    super(props)
    this.labelRef = React.createRef()
  }

  state = {
    labelWidth: 0,
  }

  componentDidMount() {
    if (this.props.hideLabel) return

    this.setState({
      // eslint-disable-next-line react/no-find-dom-node
      labelWidth: ReactDOM.findDOMNode(this.labelRef.current).offsetWidth,
    })
  }

  handleChangeValue = (event) => {
    this.props.setValue(event.target.value)
    this.props.onChange && this.props.onChange(event.target.value)
  }

  render() {
    const {
      autoComplete,
      blankOptionLabel,
      className,
      formControlProps,
      formHelperTextProps,
      hidden,
      hideBlankOption,
      hideLabel,
      inputLabelProps,
      name,
      optionProps,
      options,
      selectProps,
      value,
    } = this.props
    // As a controlled component, this should always have a default value.
    // If it starts as undefined, it will be switched from uncontrolled to
    // controlled as you type, which angers React. This also ensures the select
    // field label stays floated on blur when an option is selected.
    const { inputProps } = selectProps
    const inputClassName = inputProps && inputProps.className

    return (
      <TextFieldValidations {...this.props}>
        {(error, helperText, label, required) => (
          <FormControl
            {...formControlProps}
            className={cx('w-100', className, { 'd-none': hidden })}
            error={error}
            variant="outlined"
          >
            <InputLabel
              {...inputLabelProps}
              classes={{
                root: cx({ 'sr-only': hideLabel }),
              }}
              htmlFor={name}
              id={`${name}-label`}
              ref={this.labelRef}
            >
              {label}
            </InputLabel>
            <Select
              {...selectProps}
              input={
                <OutlinedInput
                  className="w-100"
                  id={name}
                  labelWidth={this.state.labelWidth}
                  name={name}
                />
              }
              inputProps={{
                ...selectProps.inputProps,
                'aria-required': required,
                autoComplete,
                className: cx('fs-2 h-100', inputClassName),
                'data-test': name,
                id: name,
              }}
              native
              onChange={this.handleChangeValue}
              value={value}
            >
              {!hideBlankOption && (
                <option {...optionProps} value="">
                  {blankOptionLabel}
                </option>
              )}
              {options.map((option) => (
                <option
                  {...optionProps}
                  key={option.label}
                  value={option.value || option.label}
                >
                  {option.label}
                </option>
              ))}
            </Select>
            <FormHelperText {...formHelperTextProps}>
              {helperText}
            </FormHelperText>
          </FormControl>
        )}
      </TextFieldValidations>
    )
  }
}

FormSelect.propTypes = {
  autoComplete: PropTypes.string,
  blankOptionLabel: PropTypes.string,
  className: PropTypes.string,
  formControlProps: PropTypes.object,
  formHelperTextProps: PropTypes.object,
  hidden: PropTypes.bool,
  hideBlankOption: PropTypes.bool,
  hideLabel: PropTypes.bool,
  inputLabelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  optionProps: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.any,
    }).isRequired
  ).isRequired,
  selectProps: PropTypes.shape({
    inputProps: PropTypes.shape({
      className: PropTypes.string,
    }),
  }),
  setValue: PropTypes.func.isRequired,
  textFieldProps: PropTypes.object,
  value: PropTypes.any,
}

FormSelect.defaultProps = {
  autoComplete: 'off',
  blankOptionLabel: null,
  className: null,
  formControlProps: {},
  formHelperTextProps: {},
  hidden: false,
  hideBlankOption: false,
  hideLabel: false,
  inputLabelProps: {},
  onChange: null,
  optionProps: {},
  selectProps: {
    inputProps: {
      className: null,
    },
  },
  textFieldProps: {},
  value: '',
}

export const undecorated = FormSelect

export default withFormsy(FormSelect)
