// Vendor
import { defineMessages } from 'react-intl'

// WeSpire
import { formatMessages } from 'components/shared/translations'

const messages = defineMessages({
  actionsTaken: {
    defaultMessage: 'Actions taken',
    id: 'userProfileSharedTranslations.actionsTaken',
  },
  campaignsAndCompetitions: {
    defaultMessage: 'Campaigns & Competitions',
    id: 'userProfileSharedTranslations.campaignsAndCompetitions',
  },
  donationToOtherOrganizations: {
    defaultMessage: 'Donate to Other Organizations',
    id: 'userProfileSharedTranslations.donationToOtherOrganizations',
  },
  givingActivities: {
    defaultMessage: 'Giving Activities',
    id: 'userProfileSharedTranslations.givingActivities',
  },
  hoursParticipated: {
    defaultMessage: 'Hours participated',
    id: 'userProfileSharedTranslations.hoursParticipated',
  },
  ideasSubmitted: {
    defaultMessage: 'Ideas submitted',
    id: 'userProfileSharedTranslations.ideasSubmitted',
  },
  keepOnCompeting: {
    defaultMessage: 'Keep on Competing',
    id: 'userProfileSharedTranslations.keepOnCompeting',
  },
  takeActionToComplete: {
    defaultMessage: 'Take Action to Complete',
    id: 'userProfileSharedTranslations.takeActionToComplete',
  },
  voteOnNewIdeas: {
    defaultMessage: 'Vote on New Ideas',
    id: 'userProfileSharedTranslations.voteOnNewIdeas',
  },
})

export const userProfileTranslations = formatMessages(messages)
