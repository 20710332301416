import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import axios from 'axios'
import Button from 'src/common/button/Button'
import TextHero from 'src/common/TextHero'
import Modal from 'src/common/Modal'
import Input from 'src/common/Input'
import { useCsrft } from 'src/hooks/useCsrft'
import SessionContext from 'src/contexts/SessionContext'

import { FormattedMessage } from 'react-intl'

const EmailActivateLanding = () => {
  const { brand } = useContext(SessionContext)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState({})
  const { tokenName, tokenValue } = useCsrft()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search) // eslint-disable-line compat/compat

  const handleSubmit = () => {
    let emailParam = encodeURI(queryParams.get('email'))
    const tokenParam = encodeURI(queryParams.get('token'))

    if(emailParam && tokenParam){

      axios
        .get('/session/create_from_email_token', { params: {email: emailParam, email_token: tokenParam},
          paramsSerializer: (params) => {
            let result = ''
            Object.keys(params).forEach(key => {
              result += `${key}=${encodeURIComponent(params[key])}&`
            })
            return result.substring(0, result.length - 1)
          }
        })
        .then((data) => {
          window.location = data.redirect_url
        })
        .catch((data) =>{
          setShowModal(true)
          setModalMessage({title: 'Error', body: 'Please contact support'})
          console.log(data)
        })
    } else {
      setShowModal(true)
      setModalMessage({title: 'Error', body: 'Please contact support'})
    }
  }

  return (
    <main className="grid justify-items-center mt-20 pb-8">
      <TextHero
        subtitle={brand?.heroSubtitle}
        title={brand?.heroTitle || brand?.name}
      />
      <Formik
        initialValues={{
          email: '',
          token: ''
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="max-w-[37.5rem] w-11/12 px-4">
            <section className="flex flex-col mt-12">
              <div className="flex flex-col">
                <Input name={tokenName} type="hidden" value={tokenValue} />
                <Button className="h-14 mt-4 email" type="submit">
                  <FormattedMessage
                    id="loginMessages.activateMyAccount"
                    defaultMessage={'Activate My Account'}
                  />
                </Button>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      {showModal && (
        <Modal
          body={modalMessage.body}
          setShowModal={() => setShowModal(false)}
          title={modalMessage.title}
        />
      )}
    </main>
  )
}

export default EmailActivateLanding
