// Setup
import PropTypes from 'prop-types'
import React from 'react'

// WeSpire
import { GET_CAMPAIGN_BY_ID } from 'graphql/queries'
import { LoadingIndicator } from 'components/ui/loading_indicator'
import WeQuery from 'components/application/we_query'

const CampaignShowQuery = ({
  campaignId,
  children,
  error,
  loader,
  ...other
}) => {
  return (
    <WeQuery
      {...other}
      error={error}
      loader={loader}
      query={GET_CAMPAIGN_BY_ID}
      variables={{ campaignId: campaignId }}
    >
      {({ data }) => children(data.campaign)}
    </WeQuery>
  )
}

CampaignShowQuery.propTypes = {
  campaignId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  error: PropTypes.node,
  loader: PropTypes.node,
}

CampaignShowQuery.defaultProps = {
  error: null,
  loader: <LoadingIndicator />,
}

export default CampaignShowQuery
