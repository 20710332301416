// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import Formsy from 'formsy-react'
import v from 'voca'

// WeSpire
import { displayBanner, hideBanner } from 'redux/dispatchers'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormFieldset from 'components/form/fieldset'
import FormFieldsetGroup from 'components/form/fieldset_group'
import FormTextField from 'components/form/text_field'
import { intl } from 'utilities/localization'
import WeSpireAPI from 'utilities/wespire_api'

const { formatMessage } = intl

const messages = defineMessages({
  credentialsNotSaved: {
    defaultMessage: 'Could not save credentials',
    id: 'achieversConfigEditPage.credentialsNotSaved',
  },
  credentialsSaved: {
    defaultMessage: 'Your credentials have been saved',
    id: 'achieversConfigEditPage.credentialsSaved',
  },
  inValidCredentials: {
    defaultMessage: 'Credentials are invalid',
    id: 'achieversConfigEditPage.inValidCredentials',
  },
  validCredentials: {
    defaultMessage: 'Credentials are valid',
    id: 'achieversConfigEditPage.validCredentials',
  },
})

export class AchieversConfigEditPage extends Component {
  static propTypes = {
    achieversConfig: PropTypes.shape({
      clientId: PropTypes.string,
      clientSecret: PropTypes.string,
      programUrl: PropTypes.string,
    }),
    displayNotification: PropTypes.func.isRequired,
    formRequirements: PropTypes.shape({
      brandId: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    achieversConfig: {
      clientId: '',
      clientSecret: '',
      programUrl: 'https://',
    },
  }

  state = {
    isSubmitting: false,
  }

  componentDidMount() {
    if (!v.isEmpty(this.props.achieversConfig.clientId)) {
      this.apiValidate()
    }
  }

  apiValidate = () => {
    const {
      formRequirements: { brandId },
    } = this.props
    hideBanner()

    WeSpireAPI()
      .get(`brands/${brandId}/achievers_config/validate`)
      .then(() => {
        displayBanner({
          content: formatMessage(messages.validCredentials),
          fullWidth: true,
          variant: 'success',
        })
      })
      .catch(() => {
        displayBanner({
          content: formatMessage(messages.inValidCredentials),
          fullWidth: true,
          variant: 'error',
        })
      })
  }

  handleSubmit = (values) => {
    const { client_id, client_secret, program_url } = values
    const { displayNotification } = this.props
    const { brandId } = this.props.formRequirements

    this.setState({ isSubmitting: true })

    WeSpireAPI()
      .post(`brands/${brandId}/achievers_config`, {
        achievers_config: {
          client_id,
          client_secret,
          program_url,
        },
      })
      .then(() => {
        this.setState({ isSubmitting: false })
        displayNotification(formatMessage(messages.credentialsSaved))

        this.apiValidate()
      })
      .catch(() => {
        this.setState({ isSubmitting: false })
        displayNotification(formatMessage(messages.credentialsNotSaved))
      })
  }

  render() {
    const { achieversConfig } = this.props
    const { clientId, clientSecret, programUrl } = achieversConfig
    const { isSubmitting } = this.state

    return (
      <Formsy noValidate onValidSubmit={this.handleSubmit}>
        <FormFieldsetGroup>
          <FormFieldset
            hint="Enter the following settings to set up an Achievers API integration."
            label="Configuration Details"
          >
            <FormTextField
              name="program_url"
              required
              textFieldProps={{
                label: 'Program URL',
                placeholder: 'https://',
              }}
              validationErrors={{ isUrl: 'Must be a valid URL' }}
              validations={{ isUrl: true }}
              value={programUrl}
            />

            <FormTextField
              name="client_id"
              required
              textFieldProps={{
                label: 'Client ID',
              }}
              value={clientId}
            />

            <FormTextField
              name="client_secret"
              required
              textFieldProps={{
                label: 'Client Secret',
              }}
              value={clientSecret}
            />

            <FormActionBar backPath="/management_panel/">
              <FormActionBarSubmitButton isSubmitting={isSubmitting} />
            </FormActionBar>
          </FormFieldset>
        </FormFieldsetGroup>
      </Formsy>
    )
  }
}

export default AchieversConfigEditPage
