// Vendor
import Moment from 'moment'

// WeSpire
import { isDatePast, sameDate } from './date_utils'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'

// returns date formatted like Jan 1, 2000
export const abbreviatedDate = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

// returns date formatted like Mon, Jan 1, 2000
export const abbreviatedDateWithDay = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  })

// See TimeRange in ui/time.jsx for JSX wrapper.
export const abbreviatedDateOrRange = (startDate, endDate) => {
  if (sameDate(endDate, startDate)) {
    return abbreviatedDate(startDate)
  } else {
    return abbreviatedDateRange(startDate, endDate)
  }
}

// returns date formatted like `Jan 1, 2000 - Jan 2, 2000
export const abbreviatedDateRange = (startDate, endDate) => {
  const start = abbreviatedDate(startDate)
  const end = endDate ? abbreviatedDate(endDate) : sharedTranslations.ongoing

  return `${start} - ${end}`
}

// returns current year formatted like `2020`
export const currentYear = intl.formatDate(new Date(), {
  year: 'numeric',
})

// returns current year as raw integer like 2020
export const currentYearUnformatted = new Date().getFullYear()

// returns date formatted like July 17
export const dayAndMonth = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'long',
  })

// returns date formatted like Jun 17
export const dayAndMonthShort = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'short',
  })

// returns date formatted like 19-02-14
export const dateTimeDate = (date) => Moment(date).format('YY-MM-DD')

// returns date formatted like 02/14/2019
export const dateTimeDateLabel = (date) =>
  intl.formatDate(date, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

// returns time formatted like 16:30
export const dateTimeHour = (date) =>
  intl.formatDate(date, { hour: 'numeric', hour12: false, minute: 'numeric' })

// returns time formatted like 3:30 PM
export const dateTimeHourLabel = (date) =>
  intl.formatDate(date, { hour: 'numeric', minute: 'numeric' })

// returns time formatted like 2/14/2019 3:30 PM
export const dateTimeHourMinute = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    year: 'numeric',
  })

// returns humanized duration
export const humanizedDuration = (firstDate, secondDate) =>
  Moment.duration(Moment(firstDate).diff(Moment(secondDate))).humanize()

// returns date formatted like July 2025
export const monthYear = (date) =>
  intl.formatDate(date, {
    month: 'long',
    year: 'numeric',
  })

// returns date formatted like 2/14/2019
export const numericDate = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })

// returns distance between now and date in words.
export const timeFromNow = (date) => Moment(new Date()).to(date, true)

// returns distance between now and date in words with string "remaining" or "ago"
export const fromNowUntil = (date) => {
  if (date === null) {
    return sharedTranslations.ongoing
  }

  let distance = timeFromNow(date)
  let distanceWord = isDatePast(date)
    ? sharedTranslations.ago
    : sharedTranslations.remaining

  return `${distance} ${distanceWord}`
}

export const getTimeZoneLabel = () =>
  // e.g. "Eastern Daylight Time (EDT)"
  `${timeZoneLong()} (${timeZoneShort()})`

// returns timezone formatted like "EDT"
export const timeZoneShort = () => timeZoneName('short')

// returns timezone formatted like "Eastern Daylight Time"
export const timeZoneLong = () => timeZoneName('long')

const timeZoneName = (format) => {
  // There does not seem to be a native method for displaying only timezone
  // name. So, we have to append it to a datetime string and then grab it from
  // there in a way that will work for any locale format.
  // In "en-US", the 'long' format returns "2019, Eastern Daylight Time" and we
  // only include the text after the comma.
  //
  // Adapted from https://stackoverflow.com/a/56490104

  const today = new Date()
  const date = intl.formatDate(today)
  const dateWithTimeZone = intl.formatDate(today, { timeZoneName: format })

  // Find the date within the formatted date string and remove it.
  const extractZoneName = (dateWithTimeZone) =>
    dateWithTimeZone.substring(0, shortIndex) +
    dateWithTimeZone.substring(shortIndex + date.length)
  // We need to remove extra punctuation after or before the time zone name that
  // is included as part of `dateWithTimeZone`.
  const stripExtraPunctuation = (zoneName) =>
    zoneName.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '')

  const shortIndex = dateWithTimeZone.indexOf(date)
  if (shortIndex >= 0) {
    const name = extractZoneName(dateWithTimeZone)
    return stripExtraPunctuation(name)
  } else {
    // If somehow the date can't be found and thus we can't remove it, just
    // return time zone name in en-US locale.
    return timeZoneName('en-US')
  }
}
