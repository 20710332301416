// Setup
import React from 'react'
import PropTypes from 'prop-types'
// Vendor
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

// WeSpire
import AuthenticatedRoute from './authenticated_route'
import Layout from 'components/application/layout'
import { nativeRoutes, webRoutes } from './routes'
import { setNative } from 'redux/reducers/session'
import { setRoutes } from 'redux/reducers/routes'
import store, { history } from 'redux/store'
import withNotifications from 'components/helpers/with_notifications'
import withRouteProps from 'components/helpers/with_route_props'
import WeApollo from 'utilities/we_apollo'
import { LocalizationProvider } from 'components/localization_provider'
import { miscInits } from 'components/router/misc_inits'
import { UserScoreProvider } from 'utilities/context/UserScoreContext'
import { UserAuthenticatedProvider } from 'utilities/context/UserAuthenticatedContext'
import { UserLegalDocumentsProvider } from 'utilities/context/UserLegalDocumentsContext'
import usePendo from '../../utilities/analytics/usePendo'

const Router = ({ displayNotification, isNative, notification }) => {
  const routes = isNative ? nativeRoutes : webRoutes

  store.dispatch(setNative(isNative))
  store.dispatch(setRoutes(routes))
  miscInits()
  usePendo()

  return (
    <WeApollo>
      <LocalizationProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <UserAuthenticatedProvider>
                <UserLegalDocumentsProvider>
                  <UserScoreProvider>
                    <Layout
                      displayNotification={displayNotification}
                      notification={notification}
                    >
                      <Switch>
                        {routes.public.map(
                          ({ component, exact, path, ...routeSpec }) => (
                            <Route
                              exact={exact}
                              key={path}
                              path={path}
                              render={withRouteProps(component, {
                                ...routeSpec,
                                displayNotification,
                              })}
                            />
                          )
                        )}
                        {routes.authenticated.map(
                          ({ component, exact, path, ...routeSpec }) => (
                            <AuthenticatedRoute
                              exact={exact}
                              isNative={isNative}
                              key={`${path}+${component}`}
                              path={path}
                              render={withRouteProps(component, {
                                ...routeSpec,
                                displayNotification,
                              })}
                            />
                          )
                        )}
                      </Switch>
                    </Layout>
                  </UserScoreProvider>
                </UserLegalDocumentsProvider>
              </UserAuthenticatedProvider>
            </QueryParamProvider>
          </ConnectedRouter>
        </Provider>
      </LocalizationProvider>
    </WeApollo>
  )
}

Router.propTypes = {
  displayNotification: PropTypes.func,
  isNative: PropTypes.bool,
  notification: PropTypes.string,
}

Router.defaultProps = {
  displayNotification: () => {},
  isNative: false,
  notification: '',
}

export const undecorated = Router
const routerWithNotifications = withNotifications(Router)
const host = window.location.host
const subdomain = host.split('.')[0]

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  user: {
    key: subdomain,
    name: subdomain,
  },
})(routerWithNotifications)
