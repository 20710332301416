// Vendor
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const DividerWithLabel = ({ children, className, dataTest, ...other }) => {
  return (
    <div
      {...other}
      className={cx('[ with-separator with-separator--centered ]', className)}
      data-test={dataTest}
    >
      <span className="text-center ws-nowrap">{children}</span>
    </div>
  )
}

DividerWithLabel.propTypes = {
  /** render the divider with custom content */
  children: PropTypes.node.isRequired,
  /** append classes to the parent node */
  className: PropTypes.string,
  /** for identification of draw in testing */
  dataTest: PropTypes.string,
}

DividerWithLabel.defaultProps = {
  className: null,
  dataTest: 'divider-with-label-data-test',
}

export { DividerWithLabel }
