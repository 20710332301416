import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  all: {
    defaultMessage: 'All',
    id: 'hrisStatusesUtils.all',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'hrisStatusesUtils.pending',
  },
  processing: {
    defaultMessage: 'Processing',
    id: 'hrisStatusesUtils.processing',
  },
  completed: {
    defaultMessage: 'Completed',
    id: 'hrisStatusesUtils.completed',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'hrisStatusesUtils.failed',
  },
})

export const statusFilterOptions = [
  { label: formatMessage(messages.all), value: '' },
  { label: formatMessage(messages.pending), value: 'pending' },
  { label: formatMessage(messages.processing), value: 'processing' },
  { label: formatMessage(messages.completed), value: 'completed' },
  { label: formatMessage(messages.failed), value: 'failed' },
]
