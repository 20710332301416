// Vendor
import React from 'react'
import PropTypes from 'prop-types'

export const LeaderBoardPlaceholders = ({ as, count, rankStart }) => {
  const Component = as
  const placeholders = []
  for (let i = 0; i < count; i++) {
    placeholders.push(
      <Component key={`placeholder_${i}`} rank={i + rankStart} />
    )
  }

  return <>{placeholders}</>
}

LeaderBoardPlaceholders.propTypes = {
  as: PropTypes.any.isRequired,
  count: PropTypes.number.isRequired,
  rankStart: PropTypes.number.isRequired,
}
