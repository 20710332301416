// Setup
import React from 'react'

// WeSpire
import { Button } from 'components/ui/Form/Button'
import { DisableButton } from 'components/ui/Form/DisableButton'

export default {
  argTypes: {
    as: { control: { type: 'text' } },
    children: {
      control: { type: 'text' },
      defaultValue: 'Disable Button',
    },
  },
  component: DisableButton,
  title: 'UI/Form/DisableButton',
}

const Template = (args) => <DisableButton {...args} />

export const NotDisabledButton = Template.bind({})
NotDisabledButton.args = {
  disabled: false,
}

export const DisabledButton = Template.bind({})
DisabledButton.args = {
  disabled: true,
}

export const IsNotLoading = Template.bind({})
IsNotLoading.args = {
  isLoading: false,
}

export const IsLoading = Template.bind({})
IsLoading.args = {
  isLoading: true,
}

export const AsOtherComponent = Template.bind({})
AsOtherComponent.args = {
  as: Button,
}
