import gql from 'graphql-tag'

export const GET_DONATION_SUCCESS = gql`
  query donationSuccessFields($callbackParamsEncoded: String!) {
    donationSuccess(callbackParamsEncoded: $callbackParamsEncoded) {
      amount
      charityName
      currency
      paymentMethod
      recurring
      nextDonationDate
      matched
    }
    donationMatchingEnabled
    recurringDonationsEnabled
  }
`
