// Vendor
import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { useMutation, useQuery } from '@apollo/client'

// WeSpire
import Icon from 'components/ui/icon'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import { intl } from 'utilities/localization'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import { maybeDisplayUserTour } from 'redux/dispatchers'
import { getUrlParams } from 'utilities/get_url_params'
import { UPDATE_GIVING_SUBSCRIPTION } from './utilities/mutations'
import { GET_DONATION_SUCCESS } from './utilities/queries'
import TransactionInfo from './transaction_info'
import DonationReceipt from './donation_receipt'
import { donationsSuccessMessages as messages } from './utilities/messages'
import LegacyDonationSuccess from './legacy_donation_success'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import LinkButton from 'components/ui/link_button'
import Link from 'components/shared/link'

const DonationSuccess = () => {
  const { callback_params_encoded } = getUrlParams()
  const [updateGivingSubscription] = useMutation(UPDATE_GIVING_SUBSCRIPTION)
  const { data, loading, error } = useQuery(GET_DONATION_SUCCESS, {
    variables: {
      callbackParamsEncoded: callback_params_encoded,
    },
  })

  useEffect(() => {
    updateGivingSubscription({
      variables: {
        callbackParamsEncoded: callback_params_encoded,
      },
    })
  }, [])

  if (loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  if (error) {
    return (
      <MissingResourceSection
        errorDetails={intl.formatMessage(messages.errorLoadinDonatonSuccess)}
      />
    )
  }

  const {
    donationMatchingEnabled,
    donationSuccess,
    recurringDonationsEnabled,
  } = data

  if (!recurringDonationsEnabled) {
    return <LegacyDonationSuccess />
  }

  const isRecurring = donationSuccess.recurring
  const isMatching = donationSuccess.matched

  return (
    <GraphQLFeatureFlag featureName="giving">
      <DocumentTitle title={intl.formatMessage(messages.pageTitle)}>

        <Paper
          className="mxw-6 | mt-6 mx-3 mx-sm-auto py-6 px-3 px-sm-6 text-center"
          data-test="donation-success"
        >
          <Icon
            iconName="checked_circle"
            style={{ color: 'green', fontSize: 53 }}
            title=""
          />
          <Heading
            className="ff-serif fs-sm-6 mt-6 mt-sm-5 text-black-3"
            level={1}
          >
            {intl.formatMessage(messages.pageTitle)}
          </Heading>

          <Heading
            className="ff-serif fs-sm-5 mt-2 mt-sm-2 text-black-3"
            level={2}
          >
            {isRecurring
              ? intl.formatMessage(messages.yourMonhlyDonationSchedule)
              : intl.formatMessage(messages.yourDonationWasMade)}
          </Heading>

          <hr className="mt-3 mb-3" />

          <TransactionInfo
            amount={donationSuccess.amount}
            charity={donationSuccess.charityName}
            currency={donationSuccess.currency}
            isRecurring={isRecurring}
            matchingEnabled={donationMatchingEnabled}
            matchingGiftRequested={isMatching}
            nextDonationDate={donationSuccess.nextDonationDate}
          />

          <div className="mt-4">
            <div className="fs-2 text-left">
              {intl.formatMessage(messages.pleaseNote)}:
            </div>
            <div className="fs-2 text-left lh-lg">
              <span>
                {intl.formatMessage(messages.pleaseNoteText, {
                  link: (...linkText) => (
                    <Link to="/users/me/donation_history">{linkText}</Link>
                  ),
                })}
              </span>
            </div>
          </div>

          {isMatching && (
            <div className="mt-6">
              <div className="fs-2 text-left">
                {intl.formatMessage(messages.matchingGiftTitle)}:
              </div>
              <div className="fs-2 text-left lh-lg">
                {intl.formatMessage(messages.matchingText)}
              </div>
            </div>
          )}

          <hr className="mt-3 mb-3" />

          <LinkButton to="/users/me/donation_history" variant="primary">
            {intl.formatMessage(messages.gotoDonationHistory)}
          </LinkButton>

          <DonationReceipt
            amount={donationSuccess.amount}
            charity={donationSuccess.charityName}
            currency={donationSuccess.currency}
            isRecurring={isRecurring}
          />
        </Paper>
      </DocumentTitle>
    </GraphQLFeatureFlag>
  )
}

export default DonationSuccess
