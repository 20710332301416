import React from 'react'

// Vendor
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

// WeSpire
import { ErrorPage } from 'components/shared/pages/error'
import { sharedTranslations } from '../../components/shared/translations'

export const useBrandConfig = ({ feature, useNestedQuery = false }) => {
  const featureName = `${feature}Enabled`
  const ROOT_CONFIG_QUERY = gql`
    query rootBrandConfig {
      ${featureName}
    }
  `

  const NESTED_CONFIG_QUERY = gql`
    query nestedBrandConfig {
      brandConfig {
        id
        ${featureName}
      }
    }
  `

  const { data } = useQuery(
    useNestedQuery ? NESTED_CONFIG_QUERY : ROOT_CONFIG_QUERY,
    {
      skip: !feature,
    }
  )

  const isConfigEnabled = useNestedQuery
    ? data?.brandConfig?.[featureName]
    : data?.[featureName]

  return {
    error: <ErrorPage body={sharedTranslations.featureDisabledPageBody} />,
    isConfigEnabled,
  }
}
