import gql from 'graphql-tag'

export const LIST_HRIS_IMPORTS_QUERY = gql`
  query getHrisImports($first: Int, $after: String, $status: String) {
    hrisImports(first: $first, after: $after, status: $status) {
      totalCount
      nodes {
        createdAt
        filePath
        id
        result
        status
        usersCreated
        usersUpdated
        usersDeactivated
        usersFailed
        usersProcessed
        usersUnchanged
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
