// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import cx from 'classnames'
import v from 'voca'

// WeSpire
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'

export function labelToHintId(label) {
  return `${v.kebabCase(label)}-hint`
}

/**
 * Used to group major sections of forms with a label and hint describing the
 * purpose of the group. Also adds consistent vertical spacing between nested form
 * fields.
 */
const FormFieldset = ({
  children,
  headingProps,
  hint,
  label,
  space,
  variant,
  ...other
}) => {
  const hintId = labelToHintId(label)
  const ariaProps = hint ? { 'aria-describedby': hintId } : {}

  return (
    <Stack
      {...other}
      {...ariaProps}
      as={FormControl}
      className="w-100"
      component="fieldset"
      space={space}
    >
      <FormLabel
        classes={{ root: cx({ 'w-100': variant === 'shaded' }) }}
        component="legend"
      >
        <Heading
          {...headingProps}
          className={cx(
            {
              'bg-black-6 fs-1 ls-2 px-4 py-3 text-uppercase':
                variant === 'shaded',
            },
            headingProps.className
          )}
          level={2}
        >
          {label}
        </Heading>
      </FormLabel>

      {hint && (
        <p className="fw-semi-bold mt-2 text-black-3" id={hintId}>
          {hint}
        </p>
      )}
      <Stack className={cx({ 'px-4': variant === 'shaded' })} space={space}>
        {children}
      </Stack>
    </Stack>
  )
}

FormFieldset.propTypes = {
  /** children rendered in the fieldset */
  children: PropTypes.node.isRequired,
  /** custom props for the Heading component (but you cannot use this object to
   * override the level of the heading) */
  headingProps: PropTypes.object,
  /** describe the purpose of the group in more detail */
  hint: PropTypes.string,
  /** provide a concise group label */
  label: PropTypes.string.isRequired,
  /** specify vertical spacing between <FormFieldset> components */
  space: PropTypes.number,
  variant: PropTypes.oneOf(['shaded']),
}

FormFieldset.defaultProps = {
  headingProps: {},
  hint: null,
  space: 4,
  variant: null,
}

export default FormFieldset
