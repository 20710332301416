// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedNumber } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'

const messages = defineMessages({
  chapter_members: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {chapter members} one {chapter member} two {chapter members} few {chapter members} many {chapter members} other {chapter members}}',
    id: 'participantsMessage.chapter_members',
  },
  group_members: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {group members} one {group member} two {group members} few {group members} many {group members} other {group members}}',
    id: 'participantsMessage.group_members',
  },
  member: {
    defaultMessage: 'Be the first to join!',
    id: 'participantsMessage.member',
  },
  members: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {members} one {member} two {members} few {members} many {members} other {members}}',
    id: 'participantsMessage.members',
  },
  participants: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {participants} one {participant} two {participants} few {participants} many {participants} other {participants}}',
    id: 'participantsMessage.participants',
  },
  participate: {
    defaultMessage: 'Be the first to participate!',
    id: 'participantsMessage.participate',
  },
})

const { formatMessage } = intl

const ParticipantsMessage = ({
  canParticipate,
  className = '',
  count,
  type,
}) => {
  const messageContent =
    {
      chapter_member: messages.chapter_members,
      group_member: messages.group_members,
      member: messages.members,
    }[type] || messages.participants

  return (
    <span className={`fs-1 fw-semi-bold ${className}`} data-test="participants">
      {count > 0 ? (
        <Fragment>
          {formatMessage(messageContent, {
            count: count,
            stringifiedCount: (
              <b className="text-black-1">
                <FormattedNumber value={count} />
              </b>
            ),
          })}
        </Fragment>
      ) : (
        <span>
          {(type || '').match(/member/i)
            ? formatMessage(messages.member)
            : canParticipate
            ? formatMessage(messages.participate)
            : ''}
        </span>
      )}
    </span>
  )
}

ParticipantsMessage.propTypes = {
  canParticipate: PropTypes.bool,
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  type: PropTypes.string,
}

ParticipantsMessage.defaultProps = {
  canParticipate: true,
  className: null,
  type: null,
}

export default ParticipantsMessage
