// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import { GroupRow } from './group_row'
import ScreenReader from 'components/ui/screen_reader_text'
import Stack from 'components/ui/stack'

const { formatMessage } = intl

const messages = defineMessages({
  chaptersTitle: {
    defaultMessage: 'Chapters',
    description: 'Table title for chapters',
    id: 'groupsList.chaptersTitle',
  },
  groupName: {
    defaultMessage: 'Name',
    description: 'The name of the group',
    id: 'groupsList.groupName',
  },
  leadershipRolesTitle: {
    defaultMessage: 'Leadership Roles',
    description: 'Table title for Leadership Roles',
    id: 'groupsList.leadershipRolesTitle',
  },
  noGroups: {
    defaultMessage: 'There are no groups yet.',
    id: 'groupsList.noGroups',
  },
})

const GroupsList = ({ ariaLiveText, groups, onUpdatePositionClick }) => {
  return (
    <>
      {groups.length > 0 ? (
        <div>
          <div className="bg-black-6 fs-1 fw-semi-bold ls-3 py-3 text-uppercase">
            <div className="row">
              <span className="col-2" />
              <span className="col-5">{formatMessage(messages.groupName)}</span>
              <span className="col-2">
                {formatMessage(messages.chaptersTitle)}
              </span>
              <span className="col-2">
                {formatMessage(messages.leadershipRolesTitle)}
              </span>
            </div>
          </div>
          <Stack as="ol" className="p-0" divider="default" space={1}>
            {groups.map((group) => (
              <GroupRow
                groupId={group.id}
                groupName={group.name}
                groupSlug={group.slug}
                key={group.id}
                onUpdatePositionClick={onUpdatePositionClick}
              />
            ))}
          </Stack>
          <ScreenReader aria-live="polite" text={ariaLiveText} />
        </div>
      ) : (
        <p className="fw-semi-bold" data-test="empty-state-message">
          {formatMessage(messages.noGroups)}
        </p>
      )}
    </>
  )
}

GroupsList.propTypes = {
  ariaLiveText: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onUpdatePositionClick: PropTypes.func.isRequired,
}

export { GroupsList }
