// Setup
import { useContext } from 'react'

// WeSpire
import UserScoreContext from 'utilities/context/UserScoreContext'

export const useUserScore = () => {
  const context = useContext(UserScoreContext)

  return {
    ...context,
  }
}
