import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import Button from 'src/common/button/Button'

const LogYourHours = () => {

  const { push } = useHistory()

  return (
    <>
      <div className="bg-website-blue rounded w-full flex flex-col px-6 py-4">
        <div className="flex flex-col lg:flex-row grid xl:grid-cols-3 justify-between">
          <div className="xl:col-span-2">
            <span className="font-medium font-sans text-base">
              <FormattedMessage
                id="volunteering.personalVolunteerHours"
                defaultMessage="Ready to log your time spent volunteering? You can log and track your volunteer hours here."
              />
            </span>
            <div className="pt-2">
              <span className="leading-6">
                <FormattedMessage
                  id="volunteering.volunteerOnYourOwn"
                  defaultMessage="Volunteer on your own"
                />
              </span>
              <br />
              <span className="leading-6 italic mt-1 pr-2">
                <FormattedMessage
                  id="volunteering.volunteerProTip"
                  defaultMessage="PLEASE NOTE: If you registered for a volunteering event on this platform, you will see charity and event listed below. Those volunteer hours are automatically logged for you so there is no need to manually enter them."
                />
              </span>
            </div>
          </div>
          <div className="flex xl:col-span-1 items-center justify-center xl:justify-end">
            <Button
              className="lg:mt-0 w-40"
              variant="neutral"
              onClick={() => push('/users/me/volunteer_hours_form')}
            >
              <FormattedMessage
                id="volunteering.logYourHours"
                defaultMessage="Log your hours"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogYourHours
