// Vendor
import axios from 'axios'

// WeSpire
import CloudinaryImageApiSlug from './cloudinary_image_api_slug'

export default function uploadToCloudinary(
  file,
  { cloudName, onUploadProgress, uploadPreset, folder, resourceType = 'image' }
) {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('upload_preset', uploadPreset)
  formData.append('timestamp', (Date.now() / 1000) | 0)
  if (folder) {
    formData.append('folder', folder)
  }

  return axios
    .post(
      `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`,
      formData,
      {
        onUploadProgress,
      },
      {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }
    )
    .then(({ data }) => ({
      imageSlug: new CloudinaryImageApiSlug(data).create(),
      secureUrl: data.secure_url,
    }))
}
