// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { defineMessages } from 'react-intl'

// WeSpire
import { achievementsPath } from '/components/achievement/routes'
import { BrandNameQuery } from 'components/queries/brandname_query'
import { hideUserTour } from 'redux/dispatchers'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { UserTourBase } from 'components/shared/user_tour/base'
import WeSpireAPI from 'utilities/wespire_api'

const messages = defineMessages({
  achievementPage: {
    defaultMessage:
      'Achievements are recognition for completing actions and activities on {brand}. <link>Learn more about achievements you can earn</link>.',
    id: 'userTour.achievementPage',
  },

  achievementsIndex: {
    defaultMessage:
      'Achievements are recognition for completing actions and activities on {brand}. Select any one to find out how you can earn the achievement.',
    id: 'userTour.achievementsIndex',
  },

  actionPage: {
    defaultMessage:
      "Complete activities to track the progress you're making on {brand} - and be sure to share your story about the activity to help inspire others to take action.",
    id: 'userTour.actionPage',
  },

  broadcastPage: {
    defaultMessage:
      'Catch up on all the greatest news about {brand}. After reading this blog post, check out some of the older posts to learn more.',
    id: 'userTour.broadcastPage',
  },

  broadcastsIndex: {
    defaultMessage:
      'Catch up on all the greatest news about {brand}. Click on any post to learn more!',
    id: 'userTour.broadcastsIndex',
  },

  campaignPage: {
    defaultMessage:
      'Campaigns are organized to help us collectively complete actions that can improve our community, help build understanding, or drive us to individually grow and learn. Complete actions in Campaigns to earn points, and earn a shiny new achievement badge for completing the Campaign!',
    id: 'userTour.campaignPage',
  },

  carbonFootprintPage: {
    defaultMessage:
      'Information of your carbon footprint and and how to reduce it.',
    id: 'userTour.carbonFootprintPage',
  },

  competitionPage: {
    defaultMessage:
      "Want to see how your actions stack up with your teammates? Join the Competition to make an impact - and the more actions you take in the Competition, the higher you'll climb in the leaderboard. Let's get started today!",
    id: 'userTour.competitionPage',
  },

  connectedDevicesPage: {
    defaultMessage:
      'Link a wearable device to track your progress in a Campaign or Competion.',
    id: 'userTour.connectedDevicesPage',
  },

  dashboardPage: {
    defaultMessage:
      'The Dashboard is your go-to for the latest news in {brand}. Find out about the activities others are completing, and invite your friends to join in on the fun.',
    id: 'userTour.dashboardPage',
  },

  donationFormPage: {
    defaultMessage:
      'Learn more about the terms of making a charity donation through the {brand} platform.',
    id: 'userTour.donationFormPage',
  },

  donationHistoryPage: {
    defaultMessage:
      "Your Donation History page shows the summary of all the charity donations you've made on {brand}.",
    id: 'userTour.donationHistoryPage',
  },

  donationSuccessPage: {
    defaultMessage:
      'This message can be disabled, or we can re-use the message from Donation History Page.',
    id: 'userTour.donationSuccessPage',
  },

  donationTermsAndConditionsPage: {
    defaultMessage:
      'This message can be disabled, or we can re-use the message from Giving Activities.',
    id: 'userTour.donationTermsAndConditionsPage',
  },

  eventPage: {
    defaultMessage:
      'Events allow you to register to make an impact. Learn more about this event, and register for a time slot that fits your schedule.',
    id: 'userTour.eventPage',
  },

  eventRegistrationFormPage: {
    defaultMessage:
      "You're almost there! Just a few details please, and we'll confirm your spot at the event. You'll receive an email to confirm, and you can always navigate back to the event page for full details or to modify your reservation.",
    id: 'userTour.eventRegistrationFormPage',
  },

  eventRegistrationGuestFormPage: {
    defaultMessage:
      "Increase our collective impact by inviting guests to participate. We can't wait to see you and your guest(s)!",
    id: 'userTour.eventRegistrationGuestFormPage',
  },

  eventRegistrationWaitlistFormPage: {
    defaultMessage:
      "Wait lists allow you to reserve a spot at the event in case there's a cancellation. You'll get an email notification we're able to reserve a spot for you.",
    id: 'userTour.eventRegistrationWaitlistFormPage',
  },

  eventWaiverPage: {
    defaultMessage:
      "Let's re-used the text from the Event Registration Form Page here, or we can disable the user tour mode on the Event Waiver Page.",
    id: 'userTour.eventWaiverPage',
  },

  givingActivityPage: {
    defaultMessage:
      'Giving Activities allow you to give back to charities in need. Learn more about the charities available, and please consider making a donation today.',
    id: 'userTour.givingActivityPage',
  },

  grantDonationFormPage: {
    defaultMessage:
      "Congratulations! You've earned a Dollars for Doers donation! You'll need to complete the details on this page to complete the donation process.",
    id: 'userTour.grantDonationFormPage',
  },

  ideaBoardIdeaFormPage: {
    defaultMessage:
      'Share your idea and it will be available for employees to comment and vote on in {brand}.',
    id: 'userTour.ideaBoardIdeaFormPage',
  },

  ideaBoardPage: {
    defaultMessage:
      'Idea Boards allow everyone to share their best ideas in {brand}. Seeing ideas you like? Vote for ideas shared by others to help the best ideas come to life.',
    id: 'userTour.ideaBoardPage',
  },

  invitesPage: {
    defaultMessage:
      'Invite your friends to join in on the fun at {brand} and help create good in the world. The more people you invite, the more achievements you can earn!',
    id: 'userTour.invitesPage',
  },

  kudosPage: {
    defaultMessage:
      "Want to recognize someone? Whether it's kudos, a high five, or a job well done - you're in the right place to share the news about an outstanding performance.",
    id: 'userTour.kudosPage',
  },

  newsfeedEventPage: {
    defaultMessage:
      "Stories are created when you complete actions on the platform. They help others to know about the progress you're making. Complete an action today to create your first story, or add your comments to the stories created by others on {brand}.",
    id: 'userTour.newsfeedEventPage',
  },

  newsfeedStoryPage: {
    defaultMessage:
      "Stories are created when you complete actions on the platform. They help others to know about the progress you're making. Complete an action today to create your first story, or add your comments to the stories created by others on {brand}.",
    id: 'userTour.newsfeedStoryPage',
  },

  notificationsIndex: {
    defaultMessage:
      "Notifications will keep you posted on all the latest news about {brand}, including when others like and comment on the activities you've completed.",
    id: 'userTour.notificationsIndex',
  },

  organizationFormPage: {
    defaultMessage:
      "Not finding the organization you've volunteered with? Add it to {brand} on this page to log your hours for the organization.",
    id: 'userTour.organizationFormPage',
  },

  ourImpactPage: {
    defaultMessage:
      "Our Impact is a summary of all the impact our combined team can make on the world. You'll find featured activities at the bottom of the page to join today and help make the world a better place.",
    id: 'userTour.ourImpactPage',
  },

  privacyPolicyPage: {
    defaultMessage:
      'We take your privacy seriously, and promise not to sell your data for advertising purposes. Learn more about the steps we take to protect your privacy on this page.',
    id: 'userTour.privacyPolicyPage',
  },

  searchPage: {
    defaultMessage:
      "Trying to find something on {brand}? Please enter a search term and we'll help you find it.",
    id: 'userTour.searchPage',
  },

  takeActionPage: {
    defaultMessage:
      'Take Action is the summary of all activities you can join to make an impact. Browse the available channels to get started on your next activity.',
    id: 'userTour.takeActionPage',
  },

  teamPage: {
    defaultMessage:
      "Team pages show the progress we're making together on {brand}. Check out the team leaderboard to see who's completed the most actions, or view the recent activities your teammates have joined.",
    id: 'userTour.teamPage',
  },

  teamsIndex: {
    defaultMessage:
      'Find out about all the teams participating in {brand}, and how your team stacks up to the competition.',
    id: 'userTour.teamsIndex',
  },

  termsOfUsePage: {
    defaultMessage: 'Learn more about the terms of using the {brand} platform.',
    id: 'userTour.termsOfUsePage',
  },

  userAvatarPage: {
    defaultMessage:
      'Upload a photo so your teammates can recognize you, or select one of our platform icons. The photo or image you select will be displayed as you complete actions, create news stories, or comment on the actions of others.',
    id: 'userTour.userAvatarPage',
  },

  userInterestsPage: {
    defaultMessage:
      "Let's make it personal! Please help us to personalize {brand} for you by sharing your interests. We'll use the information to recommend activities that match up with your interests. Everything you share is covered by our Privacy Policy, and we'll never share or sell your information for any reason.",
    id: 'userTour.userInterestsPage',
  },

  userProfilePageCurrentUser: {
    defaultMessage:
      "My Profile is the summary for your personal impact and the summary of your in-progress and completed {brand} activities. In private mode, the activities you've completed, and posts you've commented on, will be visible only to yourself and not to other users.",
    id: 'userTour.userProfilePageCurrentUser',
  },

  userProfilePageOtherUser: {
    defaultMessage:
      'User profiles share the progress individual users are making in {brand}. Learn about the actions teammates have been completing and share words of encouragement to cheer teammates on!',
    id: 'userTour.userProfilePageOtherUser',
  },

  userSettingsPage: {
    defaultMessage:
      'The User Settings page allows you to update your profile image, update the preferred language for system emails, and manage your notifications and privacy on {brand}.',
    id: 'userTour.userSettingsPage',
  },

  volunteerHistoryPage: {
    defaultMessage:
      'View your volunteering history from this page and add new volunteering experiences using the Log Your Volunteer Hours link.',
    id: 'userTour.volunteerHistoryPage',
  },

  volunteerHoursFormPage: {
    defaultMessage:
      "Share your volunteering experiences on this page to let others know about the impact you're making. The volunteering shifts will appear on the Volunteer History page.",
    id: 'userTour.volunteerHoursFormPage',
  },
})

// UserTour -- Intended use within React is to use the maybeDisplayUserTour
// dispatcher, e.g. maybeDisplayUserTour('campaignPage').
export const UserTourWithoutStore = ({
  forceOpen,
  invisibleOnLoad,
  maybeOpen,
  messageKey,
  brandName,
}) => {
  const [isOpen, toggleIsOpen] = useState(false)
  const [showUserTour, setShowUserTour] = useState(true)

  // Check to see if we should open anytime one of our props changes.
  useEffect(() => {
    if (forceOpen) {
      return toggleIsOpen(true)
    }
    if (!maybeOpen || !messageKey) {
      toggleIsOpen(false)
    } else {
      // Only display our message if the user has not already dismissed it.
      WeSpireAPI()
        .get('/user/dismissed_user_tour_messages')
        .then((response) => {
          const { dismissedUserTourMessages, showUserTour } = response.data
          setShowUserTour(showUserTour)
          if (!showUserTour || dismissedUserTourMessages.includes(messageKey)) {
            toggleIsOpen(false)
          } else {
            toggleIsOpen(true)
          }
        })
        .catch(() => {
          // This handles case where brand does not have the user tour feature.
          toggleIsOpen(false)
        })
    }
  }, [forceOpen, maybeOpen, messageKey])

  // Hide it, update redux AND add to the user's dismissed user tour message list.
  const handleDismiss = () => {
    toggleIsOpen(false)
    hideUserTour()
    WeSpireAPI().patch('/user/dismissed_user_tour_messages', {
      userTourMessageKey: messageKey,
    })
  }

  // Update user.show_user_tour column.
  const handleGlobalDismiss = (newShowUserTour) => {
    setShowUserTour(newShowUserTour)
    WeSpireAPI().patch('/user/dismissed_user_tour_messages', {
      showUserTour: newShowUserTour,
    })
  }

  const achievementsLink = (str) => <Link to={achievementsPath}>{str}</Link>
  const sharedProps = {
    invisibleOnLoad,
    isOpen,
    messageKey,
    onDismiss: handleDismiss,
    onGlobalDismiss: handleGlobalDismiss,
    showUserTour,
  }

  {
    /* We don't want to use BrandNameQuery when the user is
    coming from a Rails page since the query will not
    work since GraphQL doesn't work with Rails.
    brandName will tell us if the user is coming from Rails
    or React. */
  }
  return brandName ? (
    <UserTourBase {...sharedProps}>
      {messageKey &&
        intl.formatMessage(messages[messageKey], {
          brand: brandName,
          link: achievementsLink,
        })}
    </UserTourBase>
  ) : (
    <BrandNameQuery>
      {(gqlBrandName) => (
        <UserTourBase {...sharedProps}>
          {messageKey &&
            intl.formatMessage(messages[messageKey], {
              brand: gqlBrandName,
              link: achievementsLink,
            })}
        </UserTourBase>
      )}
    </BrandNameQuery>
  )
}

UserTourWithoutStore.propTypes = {
  brandName: PropTypes.string,
  forceOpen: PropTypes.bool.isRequired,
  invisibleOnLoad: PropTypes.bool,
  maybeOpen: PropTypes.bool.isRequired,
  messageKey: PropTypes.oneOf(Object.keys(messages)),
}

UserTourWithoutStore.defaultProps = {
  brandName: null,
  invisibleOnLoad: false,
  messageKey: null,
}

export const UserTour = connect((state) => state.userTour)(UserTourWithoutStore)
