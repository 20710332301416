// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'
import { intl } from 'components/localization_provider'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { ourImpactTranslations } from 'components/our_impact/shared_translations'
import { sharedTranslations } from 'components/shared/translations'
import { Stat } from 'components/ui/stat'
import WeQuery from 'components/application/we_query'
import { SkeletonLoading } from 'components/ui/skeleton_loading'

const channelStatsQuery = gql`
  query channelStats($id: ID!) {
    channel(id: $id) {
      id
      totalActionsCompleted
      totalActivities
      totalParticipants
      totalParticipatingTeams
    }
  }
`

const messages = defineMessages({
  actionsCompleted: {
    defaultMessage: 'actions completed',
    id: 'channelStats.actionsCompleted',
  },
  programImpact: {
    defaultMessage: 'Program Impact',
    id: 'channelStats.programImpact',
  },
})

export const ChannelStats = ({ channelId }) => {
  const { formatMessage } = intl

  return (
    <>
      <Heading className="px-3" level={3}>
        {formatMessage(messages.programImpact)}
      </Heading>
      <div>
        <WeQuery
          error={
            <MissingResourceSection
              errorDetails={ourImpactTranslations.errorDetails}
            />
          }
          loader={<SkeletonLoading height={330} />}
          query={channelStatsQuery}
          variables={{ id: channelId }}
        >
          {({
            data: {
              channel: {
                totalActionsCompleted,
                totalActivities,
                totalParticipants,
                totalParticipatingTeams,
              },
            },
          }) => (
            <Stack
              as={Paper}
              className="px-3 py-4"
              data-test="side-metrics-panel"
              space={4}
            >
              <Stat
                amount={totalActionsCompleted}
                label={formatMessage(messages.actionsCompleted)}
                size={6}
              />
              <Stat
                amount={totalActivities}
                label={sharedTranslations.activities}
                size={6}
              />
              <Stat
                amount={totalParticipants}
                label={sharedTranslations.participants}
                size={6}
              />
              <Stat
                amount={totalParticipatingTeams}
                label={ourImpactTranslations.participatingTeams}
                size={6}
              />
            </Stack>
          )}
        </WeQuery>
      </div>
    </>
  )
}

ChannelStats.propTypes = {
  channelId: PropTypes.string.isRequired,
}
