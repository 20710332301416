import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Checkbox = ({ label, name, value, setValue, className, ...other }) => {
  const handleChange = (event) => {
    setValue(event.target.checked)
  }

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        onChange={handleChange}
        className={cx(
          className,
          'w-4 h-4 text-smart-blue rounded-md cursor-pointer'
        )}
        {...other}
      />
      <label htmlFor={name} className="ml-2 text-sm cursor-pointer">
        {label}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.bool,
}

Checkbox.defaultProps = {
  className: '',
  value: false,
}

export default Checkbox
