// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import { defineMessages } from 'react-intl'
import cx from 'classnames'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { intl } from 'utilities/localization'
import { TextLink } from 'components/shared/text_link'
import { WeSpireLogo } from 'components/shared/wespire_logo'

const messages = defineMessages({
  aboutUs: {
    defaultMessage: 'About',
    id: 'footer.aboutUs',
  },
  allRightsReserved: {
    defaultMessage: 'All rights reserved',
    id: 'footer.allRightsReserved',
  },
  contactUs: {
    defaultMessage: 'Contact',
    id: 'footer.contactUs',
  },
  poweredBy: {
    defaultMessage: 'Powered by',
    description: 'Phrase displays before a company logo',
    id: 'footer.poweredBy',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy',
    id: 'footer.privacyPolicy',
  },
  termsOfService: {
    defaultMessage: 'Terms',
    id: 'footer.termsOfService',
  },
})

const { formatMessage, formatDate } = intl
const year2010 = new Date('2010-01-01T00:00')
const now = new Date()

const Footer = ({ className }) => {
  return (
    <footer
      className={cx(
        '[ d-flex flex-wrap justify-content-between ] [ bg-blue-2 fs-1 lh-lg p-3 ]',
        className
      )}
      id="primary-footer"
    >
      <ul className="[ d-flex align-items-center flex-wrap ] [ list-none my-0 pl-0 ]">
        <li className="[ d-flex align-items-center ] [ mr-3 ]">
          <div className="w-100">{formatMessage(messages.poweredBy)}</div>
          <WeSpireLogo className="ml-2" height={24} />
        </li>

        <li>
          <TextLink
            className="text-link--black-1"
            to="https://www.wespire.com/why-wespire"
          >
            {formatMessage(messages.aboutUs)}
          </TextLink>
        </li>

        <li>
          <BulletSpacer />
          <TextLink
            className="text-link--black-1"
            to="mailto:support@wespire.com"
          >
            {formatMessage(messages.contactUs)}
          </TextLink>
        </li>

        <li>
          <BulletSpacer />
          <TextLink className="text-link--black-1" to="/terms_of_use">
            {formatMessage(messages.termsOfService)}
          </TextLink>
        </li>

        <li className="show-md-up">
          <BulletSpacer />
          <TextLink className="text-link--black-1" to="/privacy">
            {formatMessage(messages.privacyPolicy)}
          </TextLink>
        </li>
      </ul>

      <ul className="[ d-flex align-items-center flex-wrap ] [ list-none my-0 pl-0 ]">
        <li className="hide-md-up">
          <TextLink className="text-link--black-1" to="/privacy">
            {formatMessage(messages.privacyPolicy)}
          </TextLink>
        </li>

        <li>
          <BulletSpacer className="hide-md-up" />©
          {formatDate(year2010, { year: 'numeric' })}-
          {formatDate(now, { year: '2-digit' })}
          {` WeSpire, Inc. `}
          {formatMessage(messages.allRightsReserved)}
        </li>
      </ul>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
}

export { Footer }
