// Setup
import { assert } from 'chai'

// WeSpire
import * as utils from 'components/user_profile/utils'

const activities = [
  { id: 1, type: 'campaign' },
  { id: 2, type: 'ideaboard' },
  { id: 3, type: 'campaign' },
  { id: 4, type: 'event' },
  { id: 5, type: 'ideaboard' },
  { id: 6, type: 'competition' },
]

describe('User Profile utils', () => {
  describe('actionUrlText()', () => {
    it(`returns text associated to each activity type's call-to-action link`, () => {
      assert.equal(utils.actionUrlText('campaign'), 'Take Action to Complete')
      assert.equal(utils.actionUrlText('competition'), 'Keep on Competing')
      assert.equal(utils.actionUrlText('event'), 'Edit Your Registration')
      assert.equal(
        utils.actionUrlText('givingactivity'),
        'Donate to Other Organizations'
      )
      assert.equal(utils.actionUrlText('ideaboard'), 'Vote on New Ideas')
    })
  })

  describe('activitiesByType()', () => {
    it(`returns array of associated activities`, () => {
      assert.deepEqual(utils.activitiesByType(activities, 'campaign'), [
        { id: 1, type: 'campaign' },
        { id: 3, type: 'campaign' },
        { id: 6, type: 'competition' },
      ])
      assert.deepEqual(utils.activitiesByType(activities, 'competition'), [
        { id: 6, type: 'competition' },
      ])
      assert.deepEqual(utils.activitiesByType(activities, 'event'), [
        { id: 4, type: 'event' },
      ])
      assert.deepEqual(utils.activitiesByType(activities, 'givingactivity'), [])
      assert.deepEqual(utils.activitiesByType(activities, 'ideaboard'), [
        { id: 2, type: 'ideaboard' },
        { id: 5, type: 'ideaboard' },
      ])
    })
  })

  describe('participationIndicatorText()', () => {
    it(`returns text associated to each activity type's participation indicators`, () => {
      assert.equal(
        utils.participationIndicatorText('campaign'),
        'Actions taken'
      )
      assert.equal(
        utils.participationIndicatorText('competition'),
        'Actions taken'
      )
      assert.equal(
        utils.participationIndicatorText('event'),
        'Hours participated'
      )
      assert.equal(utils.participationIndicatorText('givingactivity'), null)
      assert.equal(
        utils.participationIndicatorText('ideaboard'),
        'Ideas submitted'
      )
    })
  })
})
