// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import MgmtPanelFormPage from 'setup/application/form_page'
import GivingActivitiesForm from './form'

const GivingActivitiesFormPage = (props) => (
  <MgmtPanelFormPage {...props} resourceName="Giving Activity">
    {({ displayNotification }) => (
      <GivingActivitiesForm
        {...props}
        displayNotification={displayNotification}
      />
    )}
  </MgmtPanelFormPage>
)

GivingActivitiesFormPage.propTypes = {
  backPath: PropTypes.string.isRequired,
  channelList: PropTypes.array.isRequired,
  displayArchive: PropTypes.bool.isRequired,
  displayPublish: PropTypes.bool.isRequired,
  displayRequestReview: PropTypes.bool.isRequired,
  formRequirements: PropTypes.object.isRequired,
  givingActivity: PropTypes.object.isRequired,
  groupsList: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  redirectBackTarget: PropTypes.string.isRequired,
  showGroupsList: PropTypes.bool.isRequired,
  teamsList: PropTypes.array.isRequired,
  timeZoneList: PropTypes.array.isRequired,
}

export default GivingActivitiesFormPage
