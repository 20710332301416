// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { defineMessages, FormattedNumber } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  likesWithCount: {
    defaultMessage:
      '{stringifiedCount} {count, plural, zero {likes} one {like} two {likes} few {likes} many {likes} other {likes}}',
    id: 'likeButtonIcon.likesWithCount',
  },
})

/**
 * Heart icon that indicates if the current user has liked it by turning red and
 * changing the accessible title attribute.
 */
const LikeButtonIcon = ({ isLiked, likeCount, ...other }) => (
  <Icon
    {...other}
    className={cx({ 'text-red': isLiked }, other.className)}
    iconName="heart"
    title={formatMessage(messages.likesWithCount, {
      count: likeCount,
      stringifiedCount: <FormattedNumber value={likeCount} />,
    })}
  />
)

LikeButtonIcon.propTypes = {
  /** boolean determining if likable is currently liked */
  isLiked: PropTypes.bool.isRequired,
  /** total number of likes for likable record */
  likeCount: PropTypes.number.isRequired,
}

export { LikeButtonIcon }
