// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { intl } from 'utilities/localization'
import { MembersSection } from './members_section'

const messages = defineMessages({
  error: {
    defaultMessage: 'There was a problem loading the all members list',
    id: 'otherMembersSection.error',
  },
  otherGroupMembersEmptyState: {
    defaultMessage: 'There are no other members to display.',
    id: 'otherMembersSection.otherGroupMembersEmptyState',
  },
  otherGroupMembersHeading: {
    defaultMessage: 'All members',
    id: 'otherMembersSection.otherGroupMembersHeading',
  },
})

const OtherMembersSection = ({ groupId }) => {
  const { formatMessage } = intl

  return (
    <MembersSection
      emptyMessage={formatMessage(messages.otherGroupMembersEmptyState)}
      errorMessage={formatMessage(messages.error)}
      excludeUser
      groupId={groupId}
      headingDataTest="other-group-members"
      headingMessage={formatMessage(messages.otherGroupMembersHeading)}
      memberDataTest="other-member"
      noHiddenMembers
      noLeaders
      queryKey="otherMembers"
      withTeamsInCommon={false}
    />
  )
}

OtherMembersSection.propTypes = {
  groupId: PropTypes.string.isRequired,
}

export { OtherMembersSection }
