// Setup
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

// WeSpire
import QueryAutocomplete from '/components/form/query_autocomplete'

const charitiesQuery = gql`
  query charities($filter: String, $omittedEins: [String!]) {
    charities(filter: $filter, omittedEins: $omittedEins) {
      cityAndState
      ein
      id
      name
    }
  }
`
const volunteerQuery = gql`
  query volunteerCharities($filter: String, $omittedEins: [String!]) {
    volunteerCharities(filter: $filter, omittedEins: $omittedEins) {
      cityAndState
      ein
      id
      name
    }
  }
`
const query = {
  charities: charitiesQuery,
  volunteerCharities: volunteerQuery,
}
const variableMapping = {
  omitted: 'omittedEins',
  query: 'filter',
}

const CharityAutocomplete = ({ queryName, secondaryLabel, ...other }) => {
  const suggestionMapping = {
    id: 'ein',
    label: 'name',
    secondaryLabel: secondaryLabel,
  }

  return (
    <QueryAutocomplete
      {...other}
      query={query[queryName]}
      queryName={queryName}
      queryVariableMapping={variableMapping}
      suggestionMapping={suggestionMapping}
    />
  )
}

CharityAutocomplete.propTypes = {
  /** The name of the GraphQL field that this autocomplete should use. */
  queryName: PropTypes.oneOf(['charities', 'volunteerCharities']),
  /** Which secondary label to display in each autocomplete suggestion */
  secondaryLabel: PropTypes.oneOf(['cityAndState', 'ein']),
}

CharityAutocomplete.defaultProps = {
  queryName: 'charities',
  secondaryLabel: 'ein',
}

export default CharityAutocomplete
