// Vendor
import { client } from 'utilities/we_apollo'
import gql from 'graphql-tag'

const allCharitiesByFilterQuery = gql`
  query allCharitiesByFilter($filter: String) {
    charities(filter: $filter) {
      address {
        city
        state
      }
      ein
      id
      name
      matched
      tagLine
      mission
    }
  }
`

const allCharitiesByParams = gql`
  query allCharitiesByParams(
    $charityName: String
    $city: String
    $country: [ID!]
    $eins: String
    $isMatchEligible: Boolean
  ) {
    charitySearch(
      charityName: $charityName
      city: $city
      eins: $eins
      country: $country
      matchEligible: $isMatchEligible
    ) {
      charities {
        address {
          city
          country {
            displayName
          }
          state
        }
        ein
        id
        name
        matched
        tagLine
        mission
        grantEligible
      }
      count
    }
  }
`

const allGlobalGivingProjectsByParams = gql`
  query allGlobalGivingProjectsByParams(
    $charityName: String
    $city: String
    $country: [ID!]
    $eins: String
    $isMatchEligible: Boolean
  ) {
    globalGivingSearch(
      charityName: $charityName
      city: $city
      ids: $eins
      country: $country
      matchEligible: $isMatchEligible
    ) {
      projects {
        ein
        id
        name
        tagLine
        address {
          city
          state
        }
      }
      count
    }
  }
`

export const filterAllCharitiesByParams = async (params) => {
  const cleanParams = Object.keys(params).reduce((reducedParams, key) => {
    const value = params[key]
    if (value && value !== '' && value !== []) {
      reducedParams[key] = value
    }
    return reducedParams
  }, {})

  const {
    data: {
      charitySearch: { charities, count },
    },
  } = await client.query({
    query: allCharitiesByParams,
    variables: { ...cleanParams },
  })
  return { charities, count }
}

export const filterAllGlobalGivingProjectsByParams = async (params) => {
  const cleanParams = Object.keys(params).reduce((reducedParams, key) => {
    const value = params[key]
    if (value && value !== '' && value !== []) {
      reducedParams[key] = value
    }
    return reducedParams
  }, {})

  const {
    data: {
      globalGivingSearch: { projects, count },
    },
  } = await client.query({
    query: allGlobalGivingProjectsByParams,
    variables: { ...cleanParams },
  })

  // rename projects so we can interact with them like charities
  const charities = projects

  return { charities, count }
}

export const filterAllCharities = async (search) => {
  if (search === '') return []
  const response = await client.query({
    query: allCharitiesByFilterQuery,
    variables: { filter: search },
  })
  return response.data.charities
}

export const filteredCharities = (charities, nameSearch) => {
  if (!nameSearch) return charities
  return charities.filter((charity) =>
    charity.name.toLowerCase().includes(nameSearch.toLowerCase())
  )
}
