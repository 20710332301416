// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'

import { useRemoveMemberMutation } from './utils'

const { formatMessage } = intl

const messages = defineMessages({
  removeMember: {
    defaultMessage: 'Remove Member',
    id: 'GroupMembersRowRemoveMember.removeMember',
  },
})

export const RemoveMemberButton = ({ group, member }) => {
  const { handleOnRemoveMember } = useRemoveMemberMutation({
    group,
    member,
  })

  return (
    <Button
      className="mnw-0 py-0 px-1"
      data-test="remove-member-button"
      onClick={handleOnRemoveMember}
      variant="text"
    >
      <Icon
        className="fs-4 text-black-4"
        iconName="person_remove"
        title={formatMessage(messages.removeMember)}
      />
    </Button>
  )
}

RemoveMemberButton.propTypes = {
  group: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
}
