// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import Paper from '@material-ui/core/Paper'

const NewsFeedHelpBlurb = ({ children, dataTest }) => (
  <Paper>
    <p className="m-0 px-3 py-4" data-test={dataTest}>
      {children}
    </p>
  </Paper>
)

NewsFeedHelpBlurb.propTypes = {
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string.isRequired,
}

export { NewsFeedHelpBlurb }
