// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
import { List, ListItem, Paper } from '@material-ui/core'
import v from 'voca'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { CloudinaryImageThumbnailUrl } from 'utilities/cloudinary_utils'
import { dateTimeHourMinute } from 'utilities/date_formatter'
import ExternalLink from 'components/ui/external_link'
import GraphQLFeatureFlag from 'components/application/graphql_feature_flag'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import { ACTIVITY_THUMB_SIZE } from '../constants'
import { FeatureActivityToggle } from './feature_activity_toggle'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { sharedTranslations } from 'components/shared/translations'
import { translateActivityType } from './utils'
import { ACTIVITY_STATUS } from 'components/setup/shared/activities/list/utils'

const messages = defineMessages({
  isLive: {
    defaultMessage: 'is live',
    id: 'activity.isLive',
  },
  lastEventDate: {
    defaultMessage: 'Last Event date',
    id: 'activity.lastEventDate',
  },
  nextEventDate: {
    defaultMessage: 'Next Event date',
    id: 'activity.nextEventDate',
  },
  wasLive: {
    defaultMessage: 'was live',
    id: 'activity.wasLive',
  },
})

const Activity = ({
  activity,
  className,
  elevation,
  'data-test': dataTest,
  hideFeaturedToggle,
  hideStatusLabel,
  isDragging,
  isSortable,
}) => {
  const activityThumbUrl = new CloudinaryImageThumbnailUrl(
    activity.imageUrl,
    ACTIVITY_THUMB_SIZE
  ).create()

  const { formatMessage } = intl

  const startsAt = dateTimeHourMinute(activity.startsAt)
  const endsAt = activity.endsAt
    ? dateTimeHourMinute(activity.endsAt)
    : `(${sharedTranslations.ongoing})`

  const isScheduled = [ACTIVITY_STATUS.scheduled, 'Scheduled'].includes(
    activity.status
  )
  const isRunning = [ACTIVITY_STATUS.active, 'Running'].includes(
    activity.status
  )
  const isJustEnded = [
    ACTIVITY_STATUS.ended,
    ACTIVITY_STATUS.justEnded,
    'Just Ended',
  ].includes(activity.status)
  const isDraft = [ACTIVITY_STATUS.draft, 'Draft'].includes(activity.status)

  const livePhrase = isJustEnded
    ? formatMessage(messages.wasLive)
    : formatMessage(messages.isLive)
  const startsAtLabel = {
    Campaign: `Campaign ${livePhrase}`,
    Competition: 'Competition period',
    Event: isJustEnded
      ? formatMessage(messages.lastEventDate)
      : formatMessage(messages.nextEventDate),
    'Giving Activity': `Giving Activity ${livePhrase}`,
    'Idea Board': `Idea Board ${livePhrase}`,
  }[activity.type]

  return (
    <Paper
      className={cx(
        'pl-3',
        { 'op-30': isDragging },
        { 'border-left-thick-blue': isScheduled },
        { 'border-left-thick-green': isRunning },
        { 'border-left-thick-orange': isJustEnded },
        { 'border-left-thick-black-4': isDraft },
        { 'cursor-grab': isSortable },
        className
      )}
      data-test={dataTest}
      elevation={elevation}
      square
    >
      <List dense disablePadding>
        <ListItem disableGutters>
          {isSortable && (
            <Icon
              className="cursor-grab fs-3 mr-3 text-black-3"
              iconName="drag_handle"
              title="Move Activity"
            />
          )}

          <img
            alt=""
            className="[ aspect-ratio aspect-ratio--16x9 size-chnl-mgr-thumb ] [ border mr-3 ]"
            src={activityThumbUrl}
          />

          <Stack className="my-2" space={1}>
            <div className="d-flex align-items-center | fs-3">
              {activity.icon && (
                <Icon
                  iconName={activity.icon}
                  title={
                    sharedTranslations[translateActivityType(activity.type)]
                  }
                />
              )}
              <span className={cx({ 'ml-2': activity.icon })}>
                {v.truncate(activity.name, 65)}
              </span>

              {!hideStatusLabel && (
                <>
                  <BulletSpacer className="text-black-3" />
                  <span
                    className={cx(
                      'fs-1 fw-semi-bold ls-1 text-uppercase',
                      { 'text-blue': isScheduled },
                      { 'text-green': isRunning },
                      { 'text-orange': isJustEnded }
                    )}
                  >
                    {activity.status}
                  </span>
                </>
              )}
              {activity.filteredByTeams && (
                <>
                  <BulletSpacer className="text-black-3 px-2" />
                  <span className="fs-1 fw-semi-bold ls-1 text-black-3 text-uppercase">
                    Team Visibility Limited
                  </span>
                </>
              )}
            </div>

            <div className="fs-2 text-black-3">
              {startsAtLabel && (
                <span className="d-inline-block fs-1 fw-semi-bold ls-1 mt-1 mr-1 text-uppercase">
                  {startsAtLabel}:
                </span>
              )}{' '}
              {startsAt} - {endsAt}
            </div>

            {activity.localesLabel && (
              <GraphQLFeatureFlag
                error={null}
                featureDisabledError={null}
                featureName="translation"
                loader={null}
              >
                <div className="fs-2 text-black-3">
                  <span className="d-inline-block fs-1 fw-semi-bold ls-1 mt-1 mr-1">
                    {activity.localesLabel}
                  </span>
                  <Link
                    className="ml-auto mr-4"
                    to={activity.manageLocalesPath}
                  >
                    <Icon
                      className="fs-3 mx-2 text-black-3"
                      iconName="edit"
                      title="Manage Locales"
                    />
                  </Link>
                </div>
              </GraphQLFeatureFlag>
            )}

            {!hideFeaturedToggle && (
              <FeatureActivityToggle
                activityId={activity.id}
                featured={activity.featured}
              />
            )}
          </Stack>

          <div className="ml-auto" />

          {(isRunning || isDraft) && activity.actionUrl && (
            <ExternalLink className="mr-4" hideIcon to={activity.actionUrl}>
              <Icon
                className="fs-3 mx-2 text-black-3"
                iconName="visibility"
                title="Edit Activity"
              />
            </ExternalLink>
          )}

          {!isSortable && (
            <Link className="mr-4" to={activity.editPath}>
              <Icon
                className="fs-3 mx-2 text-black-3"
                iconName="edit"
                title="Edit Activity"
              />
            </Link>
          )}
        </ListItem>
      </List>
    </Paper>
  )
}

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  elevation: PropTypes.number,
  hideFeaturedToggle: PropTypes.bool,
  hideStatusLabel: PropTypes.bool,
  isDragging: PropTypes.bool,
  isSortable: PropTypes.bool,
}

Activity.defaultProps = {
  className: null,
  // eslint-disable-next-line react/jsx-sort-default-props
  'data-test': 'activity',
  elevation: 1,
  hideFeaturedToggle: false,
  hideStatusLabel: false,
  isDragging: false,
  isSortable: false,
}

export default Activity
