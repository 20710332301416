// Setup
import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// Vendor
import Collapse from '@material-ui/core/Collapse'
import cx from 'classnames'
import Divider from 'components/ui/divider'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { getActionUrlForActivity } from 'components/activities/utils'
import { AchievementImage } from 'components/achievement'
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { Button } from 'components/ui/button'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { Image } from 'components/ui/image'
import Link from 'components/shared/link'
import LinkButton from 'components/ui/link_button'
import ParticipantsSummary from 'components/application/participants_summary'
import { sharedTranslations } from 'components/shared/translations'
import { intl } from 'utilities/localization'
import WysiwygContent from 'components/ui/wysiwyg_content'

const messages = defineMessages({
  inProgress: {
    defaultMessage: 'In Progress',
    id: 'currentActivities.inProgress',
  },
  locked: {
    defaultMessage: 'Locked',
    id: 'activitySeries.locked',
  },
})

const ActivitySeriesCard = ({ activity, isLocked }) => {
  const { formatMessage } = intl

  const actionUrl = getActionUrlForActivity({
    actionUrl: activity.actionUrl,
  })

  const [isExpanded, setIsExpanded] = useState(false)

  const {
    achievement: {
      awardedTo,
      imageUrl: awardedImageUrl,
      name: achievementName,
    },
    description,
    imageUrl,
    name,
    isParticipating,
    participants,
    participantsCount,
    progressPercentForUser,
    type,
  } = activity

  const isCompleted = isParticipating && awardedTo

  const statusText = useMemo(() => {
    if (isCompleted) {
      return sharedTranslations.completed
    }

    if (isLocked) {
      return formatMessage(messages.locked)
    }

    return formatMessage(messages.inProgress)
  })

  const LinkIfActive = isLocked ? 'div' : Link

  return (
    <Paper
      className="[ d-flex flex-row | flex-md-row ] [ p-0 ]"
      data-test="activity-series-card"
    >
      {imageUrl && (
        <div
          className={cx(
            '[ d-flex ] [ position-relative overflow-hidden ] [ aspect-ratio--16-9 ] [ position-relative ]',
            'transition-cubic-bezier'
          )}
          style={{
            flex: '0 0 30%',
          }}
        >
          <Image
            alt=""
            aspectRatio="16:9"
            className="aspect-ratio__content w-auto mxw-none"
            responsive
            src={imageUrl}
          />
          {isLocked && (
            <div className="[ d-flex | align-items-center justify-content-center ] [ position-absolute ] [ w-100 h-100 ] [ bg-black-4 ]">
              <Icon
                className="fs-7 text-white"
                iconName="lock"
                title={formatMessage(messages.locked)}
              />
            </div>
          )}
        </div>
      )}
      <div className="[ d-flex flex-column col ] [ p-4 ]">
        <LinkIfActive className="[ d-flex flex-row ]" to={actionUrl}>
          <div className="[ flex-grow ] [ mr-2 ]">
            <div
              className="fs-1 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase"
              data-test="activity-sub-title"
            >
              {sharedTranslations[type]}
              <BulletSpacer />
              {statusText}
            </div>
            <Heading className="mt-3" level={2}>
              {name}
            </Heading>
            <ParticipantsSummary
              avatarImages={participants.map((user) => user.avatarUrl)}
              avatarSize="sm"
              className="show-md-up [ mt-3 ]"
              participantsCount={participantsCount}
            />
          </div>
          <div>
            <div className="w-100 text-center">
              <AchievementImage
                achievementName={achievementName}
                className={cx('mb-3', {
                  'op-80': isLocked,
                })}
                data-test={`achievement-${isCompleted ? 'earned' : 'unearned'}`}
                effect={isCompleted ? undefined : 'grayscale'}
                height="96"
                imageSrc={awardedImageUrl}
                width="96"
              />
            </div>
            <div className="fs-1 fw-semi-bold ls-2 text-uppercase">
              {!isLocked &&
                `${progressPercentForUser} % ${sharedTranslations.complete}`}
            </div>
          </div>
        </LinkIfActive>
        <div className="[ mt-4 ]">
          <Divider />
          <Collapse in={isExpanded}>
            <WysiwygContent className="pt-4 pb-3">{description}</WysiwygContent>
          </Collapse>
          <div className="[ d-flex align-items-center justify-content-between ] [ mt-2 ]">
            <Button
              className="pl-0"
              color="secondary"
              onClick={() => setIsExpanded(!isExpanded)}
              size="small"
              variant="text"
            >
              {isExpanded
                ? sharedTranslations.readLess
                : sharedTranslations.readMore}
            </Button>
            {!isLocked && (
              <LinkButton
                color="secondary"
                data-test="activity-link"
                to={actionUrl}
              >
                {sharedTranslations.view}
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
}

ActivitySeriesCard.propTypes = {
  activity: PropTypes.shape({
    achievement: PropTypes.shape({
      awardedTo: PropTypes.bool.isRequired,
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    actionUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    isParticipating: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(
      PropTypes.shape({ avatarUrl: PropTypes.string.isRequired }).isRequired
    ).isRequired,
    participantsCount: PropTypes.number.isRequired,
    progressPercentForUser: PropTypes.number.isRequired,
    startsAt: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  isLocked: PropTypes.bool.isRequired,
}

export { getActionUrlForActivity }
export default ActivitySeriesCard
