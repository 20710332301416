// Setup
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import Banner from 'components/application/banner'
import ContentHeader from 'setup/application/content_header'
import Layout from 'setup/application/layout'
import Modal from 'components/application/modal'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import withNotifications from 'components/helpers/with_notifications'
import SimpleBackButton from 'components/shared/header/simple/back_button'

/**
 * Provides basic page layout and content header for top-level Mgmt Panel
 * components. Includes app notification functionality.
 */
const MgmtPanelPage = ({
  children,
  contentHeaderProps,
  contentHeaderChildren,
  displayNotification,
  notification,
  returnURL,
  showBackButton,
  space,
  title,
}) => (
  <Layout displayNotification={displayNotification} notification={notification}>
    <ContentHeader
      {...contentHeaderProps}
      className="position-relative"
      text={title}
    >
      {contentHeaderChildren}
    </ContentHeader>
    {showBackButton && (
      <div className={cx(`px-${space} mt-3`)} style={{ marginBottom: '-1rem' }}>
        <SimpleBackButton data-test="mgmt-page-back-button" to={returnURL} />
      </div>
    )}
    <Banner />
    <Modal />
    {children({ displayNotification })}
  </Layout>
)

MgmtPanelPage.propTypes = {
  /** pass in page content using render props */
  children: PropTypes.func.isRequired,
  /** children passed to <ContentHeader> component */
  contentHeaderChildren: PropTypes.node,
  /** props passed to <ContentHeader> component */
  contentHeaderProps: PropTypes.object,
  /** function to render app notification */
  displayNotification: PropTypes.func.isRequired,
  /** text rendered in app notification */
  notification: PropTypes.string.isRequired,
  returnURL: PropTypes.string,
  showBackButton: PropTypes.bool,
  space: PropTypes.number,
  /** text rendered in <ContentHeader> */
  title: PropTypes.string.isRequired,
}

MgmtPanelPage.defaultProps = {
  contentHeaderChildren: null,
  contentHeaderProps: {},
  returnURL: undefined,
  showBackButton: false,
  space: 5,
}

const MgmtPanelPageWithNotifications = withNotifications(MgmtPanelPage)

const host = window.location.host
const subdomain = host.split('.')[0]

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  user: {
    key: subdomain,
    name: subdomain,
  },
})(MgmtPanelPageWithNotifications)
