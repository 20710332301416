import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Modal from '@mui/material/Modal'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'
import gql from 'graphql-tag'
import { client } from 'utilities/we_apollo'
import FormErrorSummary from 'components/form/error_summary'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'

const { formatMessage } = intl

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  heigh: 450,
  bgcolor: 'background.paper',
  border: '1px solid gray',
  borderRadius: '3px',
  boxShadow: 24,
}

const messages = defineMessages({
  createInvoice: {
    defaultMessage: 'Create Invoice',
    id: 'invoiceModal.createInvoice',
  },
  createInvoiceItem: {
    defaultMessage: 'Create Invoice Line Item',
    id: 'invoiceModal.createInvoiceLineItem',
  },
  invoiceItems: {
    defaultMessage: 'Invoice Line Items',
    id: 'invoiceModal.invoiceLineItems',
  },
})

const createInvoiceLineItem = gql`
  mutation createInvoiceLineItem(
    $amountInCents: Int!,
    $invoiceCustomerId: String!,
    $lineItemDescription: String!
  ) {
    createInvoiceLineItem(
      amountInCents: $amountInCents,
      invoiceCustomerId: $invoiceCustomerId,
      lineItemDescription: $lineItemDescription
    ) {
      invoiceLineItemId
      errors
    }
  }
`

const createInvoice = gql`
  mutation createInvoice(
    $brandGivingaAccountUsername: String!,
    $invoiceCustomerId: String!
  ) {
    createInvoice(
      brandGivingaAccountUsername: $brandGivingaAccountUsername,
      invoiceCustomerId: $invoiceCustomerId
    ) {
      errors
    }
  }
`

const InvoiceModal = ({ brandGivingaAccountUsername, invoiceCustomerId, closeModal, refetch }) => {
  const [open, setOpen] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [items, setItems] = useState([])
  const [amount, setAmount] = useState('')
  const [lineItemDescription, setLineItemDescription] = useState('')

  const addItem = (invoiceLineItemId) => {
    setItems([...items, { invoiceLineItemId, amount, lineItemDescription }])
    setAmount('')
    setLineItemDescription('')
  }

  const handleAmountChange = (e) => setAmount(Number(e.target.value))
  const handleLineItemDescriptionChange = (e) => setLineItemDescription(e.target.value)

  const handleAddItem = (amount, invoiceCustomerId, lineItemDescription, ) => {
    const decimalPattern = /^\d+(\.\d{1,2})?$/
    if(!amount || !lineItemDescription || !decimalPattern.test(amount)) {
      return
    }
    const amountInCents = Math.round(parseFloat(amount) * 100)
    const mutation = createInvoiceLineItem
    const variables = { amountInCents, invoiceCustomerId, lineItemDescription }
    client.mutate({
      mutation,
      variables,
    })
    .then(
      ({
        data: {
          createInvoiceLineItem: { invoiceLineItemId, errors },
        },
      }) => {
        setIsSubmitting(false)
        if (!errors) {
          addItem(invoiceLineItemId)
        }
      }
    )
    .catch((errors) => {
      setIsSubmitting(false)
      displayExceptionBanner({
        fullWidth: true,
        operation: 'create this Invoice Item'
      })
    })
  }

  const submitInvoice = () => {
    const mutation = createInvoice
    const variables = { brandGivingaAccountUsername, invoiceCustomerId }
    client.mutate({
      mutation,
      variables,
    })
    .then(
      ({
        data: {
          createInvoice: { errors },
        },
      }) => {
        setIsSubmitting(false)
        if (!errors) {
          refetch()
          closeModal()
        }
      }
    )
    .catch((errors) => {
      setIsSubmitting(false)
      displayExceptionBanner({
        fullWidth: true,
        operation: 'create this Invoice'
      })
    })
  }

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack as={Paper} className="border border-black-5" space={0}>
            <Box sx={boxStyle}>
              <Stack className="d-flex px-4 py-3 bg-black-6">
                <Heading
                  className="fs-2 text-uppercase"
                  data-test="all-members-label"
                  level={3}
                >
                  {formatMessage(messages.createInvoice)}
                </Heading>
              </Stack>
              <div className="space-y-4 max-w-[37.5rem] w-11/12 px-4 p-2">
                <form onSubmit={(e) => e.preventDefault()}>
                  <section className="flex flex-col mt-10">
                    <div className="flex flex-col">
                      <label htmlFor="invoiceAmount">Invoice Amount</label>
                      <input
                        name="invoiceAmount"
                        placeholder="25.00"
                        type="number"
                        step=".01"
                        min="0"
                        required
                        value={amount}
                        onChange={handleAmountChange}
                        className="mb-5 d-block"
                      />
                      <label htmlFor="linteItemDescription">Line Item Description</label>
                      <input
                        name="linteItemDescription"
                        type="text"
                        required
                        value={lineItemDescription}
                        onChange={handleLineItemDescriptionChange}
                        className="mb-5 d-block"
                      />
                    </div>
                    <Button
                      type="submit"
                      onClick={() => handleAddItem(amount, invoiceCustomerId, lineItemDescription)}
                      className="flex-shrink-none mt-2 bg-blue hover:bg-blue-700"
                      style={{color: '#fff'}}
                      disabled={isSubmitting}
                    >
                      {formatMessage(messages.createInvoiceItem)}
                    </Button>
                  </section>
                </form>

                { items.length > 0 &&
                  <>
                    <Heading
                      className="fs-2 text-uppercase mt-3"
                      data-test="all-members-label"
                      level={4}
                    >
                      {formatMessage(messages.invoiceItems)}
                    </Heading>
                    <ul>
                      {items.map((item, index) => (
                        <li key={index}>
                          {`${item.invoiceLineItemId}:`}
                          <ul>
                            <li>{`$${item.amount} - ${item.lineItemDescription}`}</li>
                          </ul>
                        </li>
                      ))}
                    </ul>

                    <Button
                      type="submit"
                      className="flex-shrink-none mt-2 bg-blue hover:bg-blue-700"
                      onClick={() => submitInvoice()}
                      style={{color: '#fff'}}
                    >
                      {formatMessage(messages.createInvoice)}
                    </Button>
                  </>
                }
              </div>
            </Box>
          </Stack>
        </Modal>
      </div>
    </>
  )
}

InvoiceModal.propTypes = {
  brandGivingaAccountUsername: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  invoiceCustomerId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
}

export { InvoiceModal }