import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'

const { formatMessage } = intl
const messages = defineMessages({
  defaultSummaryText: {
    defaultMessage: 'Please address the following errors',
    id: 'errorSummary.defaultSummaryText',
  },
})

// We jump to the label, but we also want to pad the scroll position a bit so
// that the field & label don't get cutoff.
const LINK_JUMP_OFFSET = -32

/**
 * List of form errors where each error is a link to its associated field label.
 * Errors must be an array of strings formatted like "<field>|<message>" e.g.
 * "firstName|First name can't be blank". These links expect that a label will
 * exist on the page with a corresponding anchor, e.g. id="firstName", which is
 * scrolled into view on click.
 */
const FormErrorSummary = ({
  children,
  errors,
  itemProps,
  linkProps,
  listProps,
  space,
  summaryProps,
  ...other
}) => {
  // TODO We should instead refactor our errors by splitting them into
  //  formErrors and genericErrors, where formErrors have the pipe and refer
  //  to a field by name.
  // It's possible that we got a single error not associated with a field.
  // In that case, we want to display it with out linking.
  if (errors.length === 1 && !errors[0].includes('|')) {
    return <p className={cx('fs-3', summaryProps.className)}>{errors[0]}</p>
  }

  return (
    <Stack {...other} space={space}>
      <p className={cx('fs-3', summaryProps.className)}>{children}</p>
      <Stack {...listProps} as="ul" space={space}>
        {errors.map((error) => {
          const [fieldName, errorMessage] = error.split('|')
          return (
            <li {...itemProps} key={fieldName}>
              <a
                {...linkProps}
                className={cx(
                  '[ text-link text-link--black-1 ] [ fs-2 ]',
                  linkProps.className
                )}
                href={`#${fieldName}-label`}
                onClick={(event) => {
                  // Prevent default behavior as to not affect state or add to history.
                  event.preventDefault()

                  document.getElementById(`${fieldName}-label`).scrollIntoView()
                  window.scrollBy(0, LINK_JUMP_OFFSET)
                  document
                    .getElementById(fieldName)
                    .focus({ preventScroll: true })
                }}
              >
                {errorMessage}
              </a>
            </li>
          )
        })}
      </Stack>
    </Stack>
  )
}

FormErrorSummary.propTypes = {
  /** pass in children to replace the summary text */
  children: PropTypes.node,
  /** array of error strings formatted like "<field>|<message>" */
  errors: PropTypes.array.isRequired,
  /** props spread on error items */
  itemProps: PropTypes.object,
  /** props spread on error links */
  linkProps: PropTypes.object,
  /** props spread on list container */
  listProps: PropTypes.object,
  /** specify vertical spacing between form fields */
  space: PropTypes.number,
  /** props spread on the summary text paragraph */
  summaryProps: PropTypes.object,
}

FormErrorSummary.defaultProps = {
  children: `${formatMessage(messages.defaultSummaryText)}:`,
  itemProps: {},
  linkProps: {
    className: null,
  },
  listProps: {},
  space: 2,
  summaryProps: {
    className: null,
  },
}

export default FormErrorSummary
