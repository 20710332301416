// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Wespire
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'

const FormattedPluralWithCount = ({ count, formatterProps, messageId }) => {
  const Formatter = ({ as, ...other }) => {
    const Component = as

    return <Component {...other} />
  }

  Formatter.propTypes = {
    as: PropTypes.string,
  }

  Formatter.defaultProps = {
    as: 'span',
  }

  return (
    <>
      {intl.formatMessage(sharedMessages[messageId], {
        count,
        countStyle: (count) => (
          <Formatter {...formatterProps} key={Math.random.toString()}>
            {count}
          </Formatter>
        ),
      })}
    </>
  )
}

FormattedPluralWithCount.propTypes = {
  count: PropTypes.number.isRequired,
  formatterProps: PropTypes.object,
  messageId: PropTypes.string.isRequired,
}

FormattedPluralWithCount.defaultProps = {
  formatterProps: {},
}

export { FormattedPluralWithCount }
