/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import PropTypes from 'prop-types'

const Flash = ({ flashKey, message }) => {
  return (
    <div className={`flash flash-${flashKey}`}>
      <p>{message}</p>
      <a
        className="flash-dismiss"
        href="javascript:void(0)"
        title="Hide this message"
      >
        &times;
      </a>
    </div>
  )
}

Flash.propTypes = {
  flashKey: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default Flash
