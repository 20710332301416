// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { DropTarget } from 'react-dnd'

// WeSpire
import { ITEM_TYPES } from 'components/setup/channel_manager/constants'

// Sub-Components
import Activity from 'components/setup/shared/activities/list/activity.jsx'
import Channel from './channel'
import DraggableChannel from './draggable_channel'
import DraggableActivity from './draggable_activity'
import {
  moveActivity,
  moveChannel,
  pushActivity,
  pushChannel,
  removeActivity,
  removeChannel,
} from './utils'
import { SortableCollectionWrapper } from './sortable_collection_wrapper'

class SortableCollection extends Component {
  moveActivity = (dragIndex, hoverIndex, channelIndex, listId) =>
    this.props.updateChannels(
      moveActivity(dragIndex, hoverIndex, channelIndex, listId)
    )

  pushActivity = (actSpec, nextChanIndex, nextChanListId) =>
    this.props.updateChannels(
      pushActivity(actSpec, nextChanIndex, nextChanListId)
    )

  removeActivity = (activityIndex, channelIndex, sourceListId) =>
    this.props.updateChannels(
      removeActivity(activityIndex, channelIndex, sourceListId)
    )

  moveChannel = (dragIndex, hoverIndex, listId) =>
    this.props.updateChannels(moveChannel(dragIndex, hoverIndex, listId))

  pushChannel = (card, targetListId) =>
    this.props.updateChannels(pushChannel(card, targetListId))

  removeChannel = (index, sourceListId) =>
    this.props.updateChannels(removeChannel(index, sourceListId))

  render() {
    const {
      channels,
      className,
      connectDropTarget,
      draggedItem,
      id: listId,
      isOver,
      isSortable,
      onChannelExpand,
      updateChannelActivities,
    } = this.props

    const hasNoChannels = channels.length === 0
    // Show drop highlight only when dropping to different list
    const showHighlight = isOver && draggedItem && draggedItem.listId !== listId
    const commonProps = {
      isSortable,
      listId,
      moveActivity: this.moveActivity,
      pushActivity: this.pushActivity,
    }

    if (isSortable) {
      return connectDropTarget(
        <div>
          <SortableCollectionWrapper
            className={className}
            isEmptyState={hasNoChannels}
            listId={listId}
            showHighlight={showHighlight}
          >
            {channels.map((channel, index) => (
              <DraggableChannel
                {...commonProps}
                channel={channel}
                index={index}
                key={channel.id}
                moveChannel={this.moveChannel}
                onChannelExpand={onChannelExpand}
                removeChannel={this.removeChannel}
                renderActivity={(activity, actIndex) => (
                  <DraggableActivity
                    {...commonProps}
                    activity={activity}
                    channelIndex={index}
                    dataTest="activity"
                    id={activity.id}
                    index={actIndex}
                    key={activity.id}
                    removeActivity={this.removeActivity}
                  />
                )}
                updateChannelActivities={updateChannelActivities}
              />
            ))}
          </SortableCollectionWrapper>
        </div>
      )
    } else {
      return (
        <SortableCollectionWrapper
          className={className}
          isEmptyState={hasNoChannels}
          listId={listId}
          showHighlight={showHighlight}
        >
          {channels.map((channel) => (
            <Channel
              channel={channel}
              isSortable={isSortable}
              key={channel.id}
              listId={listId}
              onChannelExpand={onChannelExpand}
              renderActivity={(activity) => (
                <Activity
                  activity={activity}
                  dataTest="activity"
                  key={activity.id}
                />
              )}
              updateChannelActivities={updateChannelActivities}
            />
          ))}
        </SortableCollectionWrapper>
      )
    }
  }
}

SortableCollection.propTypes = {
  channels: PropTypes.array.isRequired,
  className: PropTypes.string,
  connectDropTarget: PropTypes.func.isRequired,
  draggedItem: PropTypes.object,
  id: PropTypes.string.isRequired,
  isOver: PropTypes.bool,
  isSortable: PropTypes.bool,
  onChannelExpand: PropTypes.func.isRequired,
  updateChannelActivities: PropTypes.func.isRequired,
  updateChannels: PropTypes.func.isRequired,
}

SortableCollection.defaultProps = {
  className: '',
  draggedItem: {},
  isOver: false,
  isSortable: false,
}

const targetSpec = {
  drop(props, monitor, component) {
    const sourceObj = monitor.getItem()
    const targetListId = props.id

    if (targetListId !== sourceObj.listId) {
      component.pushChannel(sourceObj.channel, targetListId)
    }

    return {
      listId: props.id,
    }
  },
}

export const undecorated = SortableCollection

export default DropTarget(
  ITEM_TYPES.channel,
  targetSpec,
  (connect, monitor) => ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    draggedItem: monitor.getItem(),
    isOver: monitor.isOver(),
  })
)(SortableCollection)
