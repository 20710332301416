import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import VolunteerTable from 'src/pages/VolunteeringHistory/components/Tables/VolunteerTable'
import InternalLink from 'src/common/InternalLink'
import EmptyStateBox from 'src/common/EmptyStateBox'

const NoVolunteerHours = () => (
  <EmptyStateBox title="No past volunteer hours to display">
    <p className="text-practical-gray-2 mt-2 mb-6">
      <FormattedMessage
        id="volunteering.youCanFindVolunteeringOpportunities"
        defaultMessage="You can find volunteering opportunities by visiting our Take Action page."
      />
    </p>
    <InternalLink className="link-text-underlined" href="/take_action">
      <FormattedMessage
        id="volunteering.exploreVolunteeringOpportunities"
        defaultMessage="Explore volunteering opportunities"
      />
    </InternalLink>
  </EmptyStateBox>
)

const Tables = ({
  d4dProgramType,
  data,
  sortedVolunteerLogsAllTime,
  sortedVolunteerLogsThisYear,
  stats,
}) => {
  const sortedVolunteerLogs = stats.allTime
    ? sortedVolunteerLogsAllTime
    : sortedVolunteerLogsThisYear
  if (!sortedVolunteerLogs) {
    return <NoVolunteerHours />
  }
  return (
    <>
      <div>
        {sortedVolunteerLogs?.scheduled?.length > 0 && (
          <>
            <h2 className="px-2 mb-4">
              <FormattedMessage
                id="volunteering.scheduledVolunteerHours"
                defaultMessage="Scheduled Volunteer Hours"
              />
            </h2>
            <VolunteerTable
              enablePaidVolunteerHours={
                data?.brandConfig?.enablePaidVolunteerHours
              }
              sortedVolunteerLogs={sortedVolunteerLogs}
              status="scheduled"
              charities={sortedVolunteerLogs}
              isFlexible={d4dProgramType === 'flexible'}
            />
          </>
        )}
      </div>
      <div>
        <h2 className="px-2 mb-4">
          <FormattedMessage
            id="volunteering.completeVolunteerHours"
            defaultMessage="Complete Volunteer Hours"
          />
        </h2>
        {sortedVolunteerLogs?.completed?.length > 0 ? (
          <VolunteerTable
            data={data}
            isAllTime={stats.allTime}
            sortedVolunteerLogs={sortedVolunteerLogs}
            d4dConfiguration={data?.brandConfig?.d4dConfiguration}
            isFlexible={d4dProgramType === 'flexible'}
            status="completed"
          />
        ) : (
          <NoVolunteerHours />
        )}
      </div>
    </>
  )
}

Tables.propTypes = {
  d4dProgramType: PropTypes.string,
  data: PropTypes.object,
  sortedVolunteerLogsAllTime: PropTypes.object,
  sortedVolunteerLogsThisYear: PropTypes.object,
  stats: PropTypes.object,
}

export default Tables
