import React, { useState } from 'react'
import gql from 'graphql-tag'

import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'
import { ErrorList } from 'components/form/error_list'
import { intl } from 'utilities/localization'
import {
  sharedMessages,
  sharedTranslations,
} from 'components/shared/translations'

const { formatMessage } = intl

const updatePositionMutation = gql`
  mutation updatePositionMutation(
    $klass: SortedResource!
    $operator: SortOperator!
    $recordId: ID!
  ) {
    updatePosition(klass: $klass, operator: $operator, recordId: $recordId) {
      errors
    }
  }
`

const getPositionUpdatedMessage = ({ operator, recordName }) => {
  const actionPerformed =
    operator === 'MOVE_LOWER'
      ? sharedTranslations.movedDown
      : sharedTranslations.movedUp
  return formatMessage(sharedMessages.objectDescriptionChange, {
    actionPerformed,
    recordName,
  })
}

const executeOrderingChangeCatch = (recordName) => {
  displayExceptionBanner({
    fullWidth: true,
    operation: formatMessage(sharedMessages.changePosition, {
      recordName,
    }),
  })
}

const executeOrderingChangeThen = (operator, recordName, errors) => {
  let ariaLiveText = ''
  if (errors.length === 0) {
    ariaLiveText = getPositionUpdatedMessage({ operator, recordName })
  } else {
    displayBanner({
      as: 'div',
      content: <ErrorList errors={errors} />,
      fullWidth: true,
      variant: 'error',
    })
  }
  return ariaLiveText
}

const useUpdatePosition = () => {
  const [ariaLiveText, setAriaLiveText] = useState('')

  const executeMutation = (recordName, refetchQueries, variables) => {
    const { operator } = variables
    hideBanner()
    client
      .mutate({
        awaitRefetchQueries: true,
        mutation: updatePositionMutation,
        refetchQueries,
        variables,
      })
      .then(({ data }) => {
        const errors = data.updatePosition.errors
        setAriaLiveText(executeOrderingChangeThen(operator, recordName, errors))
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('e: ', e)
        executeOrderingChangeCatch(recordName)
      })
  }

  return [ariaLiveText, executeMutation]
}

export default useUpdatePosition
