// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import CircularProgress from '@material-ui/core/CircularProgress'

export const LoadingIndicatorSize = {
  LARGE: 50,
  SMALL: 20,
}

export const LoadingIndicator = ({ color, size, title, ...props }) => (
  <CircularProgress {...props} color={color} size={size} title={title} />
)

LoadingIndicator.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  size: PropTypes.number,
  title: PropTypes.string,
}

LoadingIndicator.defaultProps = {
  color: 'secondary',
  size: LoadingIndicatorSize.LARGE,
  title: '',
}
