import React from 'react'
import { useQuery } from '@apollo/client'

import { translations } from 'components/carbon_footprint/utilities/messages'
import { Heading } from 'components/ui/heading'
import Stack from 'components/ui/stack'
import { carbonImpactQuery } from '../utilities/queries'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { ImpactStats } from './ImpactStats'
import { TOKEN_SLUGS, TOKEN_SLUG_IMPACT } from 'utilities/token_slug_constants'

export const Impact = () => {
  const {
    data: { currentUser, brand, userImpactStats } = {},
    error,
    loading,
  } = useQuery(carbonImpactQuery, {
    variables: {
      filter: TOKEN_SLUGS.EMISSIONS,
    },
  })

  if (error)
    return <MissingResourceSection errorDetails={translations.impactMetrics} />

  return (
    <Stack className="mr-lg-1" data-test="carbon-footprint-impact" space={3}>
      <Heading level={2}>{translations.userImpact}</Heading>
      <ImpactStats
        actionsReducedText={translations.yourActions}
        carbonActionsCompleted={userImpactStats?.totalActionsCompleted}
        carbonImpact={getSustainabilityImpactForEmissions(
          currentUser?.sustainabilityImpacts
        )}
        loading={loading}
      />
      <Heading className="pt-3 | pt-lg-0" level={2}>
        {translations.companyImpact}
      </Heading>
      <ImpactStats
        actionsReducedText={translations.ourActions}
        carbonActionsCompleted={brand?.totalActionsCompleted}
        carbonImpact={getSustainabilityImpactForEmissions(
          brand?.sustainabilityImpacts
        )}
        loading={loading}
      />
    </Stack>
  )
}

function getSustainabilityImpactForEmissions(sustainabilityImpacts = []) {
  return sustainabilityImpacts.find(
    (impact) => impact.slug === TOKEN_SLUG_IMPACT.EMISSIONS
  )
}
