import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'

import { ACTIVITY_STATUS } from 'components/setup/shared/activities/list/utils'

const { formatMessage } = intl

const messages = defineMessages({
  active: {
    defaultMessage: 'Live',
    id: 'groupActivitiesUtils.active',
  },
  draft: {
    defaultMessage: 'Drafted',
    id: 'groupActivitiesUtils.draft',
  },
  ended: {
    defaultMessage: 'Ended',
    id: 'groupActivitiesUtils.ended',
  },
  scheduled: {
    defaultMessage: 'Scheduled',
    id: 'groupActivitiesUtils.scheduled',
  },
})

export const statusFilterOptions = [
  { label: formatMessage(messages.active), value: ACTIVITY_STATUS.active },
  {
    label: formatMessage(messages.scheduled),
    value: ACTIVITY_STATUS.scheduled,
  },
  { label: formatMessage(messages.draft), value: ACTIVITY_STATUS.draft },
  { label: formatMessage(messages.ended), value: ACTIVITY_STATUS.ended },
]
