// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import { defineMessages } from 'react-intl'

// WeSpire
import { BulletSpacer } from 'components/ui/bullet_spacer'
import { RelativeTime } from 'components/shared/relative_time'
import Stack from 'components/ui/stack'
import { TimeRange } from 'components/ui/time'
import VerticalScrollBox from 'components/ui/vertical_scroll_box'
import WysiwygContent from 'components/ui/wysiwyg_content'
import { intl } from 'utilities/localization'

// Sub-Components
import { CampaignModel, CampaignUserStatus } from './campaign_model'
import { CampaignProgressBarDetails } from './progress_bar_details'
import CompetitionDetails from './competition_details'

const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'details',
    description:
      'Screenreader text that describes the campaign description container',
    id: 'CampaignDetails.ariaLabel',
  },
})

/** Section to show the details of a campaign in the about card */
const CampaignDetails = ({ campaign, className }) => {
  const { formatMessage } = intl

  return (
    <Stack className={cx('d-flex flex-column', className)} space={3}>
      <div
        className="fs-1 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase"
        data-test="campaign-date-range"
      >
        <TimeRange endsAt={campaign.endsAt} startsAt={campaign.startsAt} />
        {campaign.endsAt && (
          <>
            <BulletSpacer />
            <RelativeTime date={campaign.endsAt} showPrefix />
          </>
        )}
      </div>

      <VerticalScrollBox
        ariaLabel={formatMessage(messages.ariaLabel)}
        as={WysiwygContent}
        className="mb-auto"
        data-test="campaign-description"
      >
        {campaign.description}
      </VerticalScrollBox>

      <Stack space={3}>
        {campaign.isCompetition && <CompetitionDetails campaign={campaign} />}

        {campaign.status === CampaignUserStatus.PARTICIPATING && (
          <CampaignProgressBarDetails
            achievement={campaign.achievement}
            completionPoints={campaign.completionPoints}
            progressPercent={campaign.progressPercentForUser}
            progressPointsForUser={campaign.progressPointsForUser}
            totalParticipationIndicatorsForUser={
              campaign.totalParticipationIndicatorsForUser
            }
          />
        )}
      </Stack>
    </Stack>
  )
}

CampaignDetails.propTypes = {
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  className: PropTypes.string,
}

CampaignDetails.defaultProps = {
  className: null,
}

export { CampaignDetails }
