// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import MgmtPanelFormPage from 'setup/application/form_page'
import AchieversConfigForm from './form'

const AchieversConfigEditPage = (props) => (
  <MgmtPanelFormPage {...props} isEdit resourceName="Achievers Integration">
    {({ displayNotification }) => (
      <AchieversConfigForm
        {...props}
        displayNotification={displayNotification}
      />
    )}
  </MgmtPanelFormPage>
)

AchieversConfigEditPage.propTypes = {
  achieversConfig: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    programUrl: PropTypes.string,
  }).isRequired,
  formRequirements: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }).isRequired,
}

export default AchieversConfigEditPage
