import React from 'react'
import PropTypes from 'prop-types'
import ProgressBar from 'src/common/ProgressBar'
import { FormattedMessage } from 'react-intl'

export default function HoursToGoBar({
  className,
  hoursBeforeNextGrant,
  d4dHoursUntilDonationEligible,
  d4dGrantAmount,
  isFlexible,
}) {
  const hoursToGoVal = hoursBeforeNextGrant
  const hours = hoursToGoVal === 1 ? 'hour' : 'hours'

  const totalHoursForNextGrant = d4dHoursUntilDonationEligible
  const hoursWorkedForNextGrant = totalHoursForNextGrant - hoursBeforeNextGrant
  const progressPercentage =
    (hoursWorkedForNextGrant / totalHoursForNextGrant) * 100

  return (
    <div className={className}>
      <ProgressBar progress={progressPercentage}>
        {isFlexible ? (
          <FormattedMessage
            id="hoursToGoBar.nextGrantMessageFlexible"
            defaultMessage="{hoursToGoVal} {hours} needed to earn your next grant"
            values={{ hours, hoursToGoVal }}
          />
        ) : (
          <FormattedMessage
            id="hoursToGoBar.nextGrantMessage"
            defaultMessage="{hoursToGoVal} {hours} to go to earn your next ${d4dGrantAmount} grant!"
            values={{ d4dGrantAmount, hours, hoursToGoVal }}
          />
        )}
      </ProgressBar>
    </div>
  )
}

HoursToGoBar.propTypes = {
  className: PropTypes.string,
  d4dGrantAmount: PropTypes.number,
  d4dHoursUntilDonationEligible: PropTypes.number,
  hoursBeforeNextGrant: PropTypes.number,
  hoursLeftForNextGrant: PropTypes.number,
  isFlexible: PropTypes.bool,
}
