import gql from 'graphql-tag'

import { ANNOUNCEMENT_FRAGMENT } from '../../components/group/newsfeed_and_announcements/utilities/fragments'
import { client } from 'utilities/we_apollo'
import { FacepileSizes } from '../../components/application/avatar'
import { moreGroupsQuery } from '../../components/groups/more_groups'
import { userGroupQuery } from '../../components/groups/user_groups'
import { writeCacheAddGroupsQuery } from 'graphql/utils/writeCacheAddGroupsQuery'
import { writeCacheRemoveGroupsQuery } from 'graphql/utils/writeCacheRemoveGroupsQuery'

const joinGroupQql = gql`
  mutation joinGroup($groupId: ID!, $visible: Boolean, $limit: Int!) {
    joinGroup(groupId: $groupId, visible: $visible) {
      errors
      group {
        activities {
          id
          visible
        }
        announcements {
          ...AnnouncementAttributes
        }
        id
        currentUserIsMember
        currentUserMembershipVisible
        descriptionPlainText
        heroImage
        members(first: $limit, noHiddenMembers: true) {
          nodes {
            avatarUrl
            id
          }
        }
        membersCount
        name
        userGroupChapter {
          announcements {
            ...AnnouncementAttributes
          }
          currentUserIsMember
          currentUserMembershipVisible
          descriptionPlainText
          id
          members(first: $limit, noHiddenMembers: true) {
            nodes {
              avatarUrl
              id
            }
          }
          membersCount
          name
        }
        currentUserMembership {
          id
          group {
            id
          }
          groupChapter {
            id
          }
        }
      }
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`
function joinGroupMutation({ groupId, visible }) {
  return client.mutate({
    mutation: joinGroupQql,
    update(
      _,
      {
        data: {
          joinGroup: { group: newGroupJoin },
        },
      }
    ) {
      writeCacheAddGroupsQuery(newGroupJoin, 'userGroups', userGroupQuery)
      writeCacheRemoveGroupsQuery(newGroupJoin, 'noUserGroups', moreGroupsQuery)
    },
    variables: {
      groupId,
      limit: FacepileSizes.large,
      visible,
    },
  })
}

export { joinGroupMutation }
