// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { GET_USER_SUGGESTIONS } from 'graphql/queries'
import QueryAutocomplete from '/components/form/query_autocomplete'

const UserAutocomplete = ({ multiple, ...other }) => (
  <QueryAutocomplete
    {...other}
    multiple={multiple}
    query={GET_USER_SUGGESTIONS}
    queryName="userSuggestions"
    queryVariableMapping={{
      omitted: 'omittedUserIds',
      query: 'query',
    }}
    searchOnFocus
    suggestionMapping={{
      id: 'id',
      imageUrl: 'avatarUrl',
      label: 'name',
      secondaryLabel: 'email',
    }}
  />
)

UserAutocomplete.propTypes = {
  multiple: PropTypes.bool,
}

UserAutocomplete.defaultProps = {
  multiple: true,
}

export default UserAutocomplete
