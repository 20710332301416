// Setup
import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import FormFieldset from 'components/form/fieldset'
import FormTextField from 'components/form/text_field'
import FormLimitedTextField from 'components/form/limited_text_field'
import { intl } from 'utilities/localization'
import { isInputValueEmpty } from 'utilities/html'
import Stack from 'components/ui/stack'
import { sharedTranslations } from 'components/shared/translations'
import { WysiwygEditor } from 'components/ui/wysiwyg_editor'

const messages = defineMessages({
  achievementDescriptionHelpText: {
    defaultMessage:
      'A congratulatory message to those who earn this achievement.',
    id: 'nameAndDescriptionFields.achievementDescriptionHelpText',
  },
  achievementHelpText: {
    defaultMessage: 'Give the campaign achievement a fun, positive name.',
    id: 'nameAndDescriptionFields.achievementHelpText',
  },
  addACampaignDescription: {
    defaultMessage: 'add a description',
    id: 'nameAndDescriptionFields.addACampaignDescription',
  },
  addACampaignName: {
    defaultMessage: 'add a name',
    id: 'nameAndDescriptionFields.addACampaignName',
  },
  addAnAchievementDescription: {
    defaultMessage: 'add an achievement description',
    id: 'nameAndDescriptionFields.addAnAchievementDescription',
  },
  addAnAchievementName: {
    defaultMessage: 'add an achievement name',
    id: 'nameAndDescriptionFields.addAnAchievementName',
  },
  campaignAchievement: {
    defaultMessage: '{campaignType} Achievement',
    id: 'nameAndDescriptionFields.campaignAchievement',
  },
  campaignDescriptionHelpText: {
    defaultMessage:
      'Use casual, exciting language to let users know what this campaign is about and why it matters.',
    id: 'nameAndDescriptionFields.campaignDescriptionHelpText',
  },
  campaignDetails: {
    defaultMessage: '{campaignType} Details',
    id: 'nameAndDescriptionFields.campaignDetails',
  },
  disabledFieldHelpText: {
    defaultMessage:
      'To edit this field, {field} on the {campaignType} edit page and then come back to this form in order to translate the field.',
    id: 'nameAndDescriptionFields.disabledFieldHelpText',
  },
  titleHelpText: {
    defaultMessage: 'Give your campaign a fun, catchy title.',
    id: 'nameAndDescriptionFields.titleHelpText',
  },
})

export const NameAndDescriptionFields = ({
  achievementDescription,
  achievementName,
  campaignDefaultName,
  campaignDescription,
  campaignType,
  className,
  localeAchievementDescription,
  localeAchievementName,
  localeCampaignDescription,
  localeName,
}) => {
  const { formatMessage } = intl

  const hasNoDefaultAchievementDescription = isInputValueEmpty(
    achievementDescription
  )
  const hasNoDefaultCampaignDescription = isInputValueEmpty(campaignDescription)

  return (
    <Paper className={className}>
      <FormFieldset
        label={formatMessage(messages.campaignDetails, {
          campaignType,
        })}
        variant="shaded"
      >
        <div className="row">
          <div className="col">
            <FormTextField
              name="name"
              textFieldProps={{
                disabled: true,
                inputProps: {
                  className: 'bg-black-6 text-black-2',
                },
                label: sharedTranslations.name,
              }}
              value={campaignDefaultName}
            />
          </div>
          <div className="col" data-test="name-translation-section">
            {isInputValueEmpty(campaignDefaultName) ? (
              <FormTextField
                name="name_translation"
                textFieldProps={{
                  disabled: true,
                  helperText: formatMessage(messages.disabledFieldHelpText, {
                    campaignType,
                    field: formatMessage(messages.addACampaignName),
                  }),
                  inputProps: {
                    className: 'bg-black-6 text-black-2',
                  },
                  label: sharedTranslations.nameTranslation,
                }}
              />
            ) : (
              <FormLimitedTextField
                label={sharedTranslations.nameTranslation}
                maxLength={90}
                name="name_translation"
                value={localeName}
              />
            )}
          </div>
          <Stack className="col">
            <p id="name-translation-helper">
              {formatMessage(messages.titleHelpText)}
            </p>
          </Stack>
        </div>
        <div className="pb-3 row">
          <div className="col">
            <WysiwygEditor
              data-test="description"
              label={sharedTranslations.description}
              name="description"
              readOnly
              value={campaignDescription}
            />
          </div>
          <div className="col" data-test="description-translation-section">
            <WysiwygEditor
              data-test="description-translation"
              label={sharedTranslations.descriptionTranslation}
              name="description_translation"
              readOnly={hasNoDefaultCampaignDescription}
              textFieldProps={{
                disabled: hasNoDefaultCampaignDescription,
                helperText: hasNoDefaultCampaignDescription
                  ? formatMessage(messages.disabledFieldHelpText, {
                      campaignType,
                      field: formatMessage(messages.addACampaignDescription),
                    })
                  : null,
                label: sharedTranslations.descriptionTranslation,
              }}
              value={localeCampaignDescription}
            />
          </div>
          <Stack className="col">
            <p id="description-translation-helper">
              {formatMessage(messages.campaignDescriptionHelpText)}
            </p>
          </Stack>
        </div>
      </FormFieldset>
      <FormFieldset
        className="my-4"
        label={formatMessage(messages.campaignAchievement, {
          campaignType,
        })}
        variant="shaded"
      >
        <div className="row pb-6">
          <div className="col">
            <FormTextField
              name="achievement"
              textFieldProps={{
                disabled: true,
                inputProps: {
                  className: 'bg-black-6 text-black-2',
                },
                label: sharedTranslations.name,
              }}
              value={achievementName ? achievementName : ''}
            />
          </div>
          <div className="col" data-test="achievement-name-translation-section">
            {isInputValueEmpty(achievementName) ? (
              <FormTextField
                name="achievement_translation"
                textFieldProps={{
                  disabled: true,
                  helperText: formatMessage(messages.disabledFieldHelpText, {
                    campaignType,
                    field: formatMessage(messages.addAnAchievementName),
                  }),
                  inputProps: {
                    className: 'bg-black-6 text-black-2',
                  },
                  label: sharedTranslations.nameTranslation,
                }}
              />
            ) : (
              <FormLimitedTextField
                label={sharedTranslations.nameTranslation}
                maxLength={90}
                name="achievement_translation"
                value={localeAchievementName}
              />
            )}
          </div>
          <Stack className="col">
            <p id="achievement-translation-name-helper">
              {formatMessage(messages.achievementHelpText)}
            </p>
          </Stack>
        </div>
        <div className="pb-3 row">
          <div className="col">
            <WysiwygEditor
              data-test="achievement-description"
              label={sharedTranslations.description}
              name="achievement_description"
              readOnly
              value={achievementDescription}
            />
          </div>
          <div
            className="col"
            data-test="achievement-description-translation-section"
          >
            <WysiwygEditor
              data-test="achievement-description-translation"
              label={sharedTranslations.descriptionTranslation}
              name="achievement_description_translation"
              readOnly={hasNoDefaultAchievementDescription}
              textFieldProps={{
                disabled: hasNoDefaultAchievementDescription,
                helperText: hasNoDefaultAchievementDescription
                  ? formatMessage(messages.disabledFieldHelpText, {
                      campaignType,
                      field: formatMessage(
                        messages.addAnAchievementDescription
                      ),
                    })
                  : null,
                label: sharedTranslations.descriptionTranslation,
              }}
              value={localeAchievementDescription}
            />
          </div>
          <Stack className="col">
            <p id="achievement-translation-description-helper">
              {formatMessage(messages.achievementDescriptionHelpText)}
            </p>
          </Stack>
        </div>
      </FormFieldset>
    </Paper>
  )
}

NameAndDescriptionFields.propTypes = {
  achievementDescription: PropTypes.string,
  achievementName: PropTypes.string,
  campaignDefaultName: PropTypes.string,
  campaignDescription: PropTypes.string,
  campaignType: PropTypes.string.isRequired,
  className: PropTypes.string,
  localeAchievementDescription: PropTypes.string,
  localeAchievementName: PropTypes.string,
  localeCampaignDescription: PropTypes.string,
  localeName: PropTypes.string,
}

NameAndDescriptionFields.defaultProps = {
  achievementDescription: null,
  achievementName: null,
  campaignDefaultName: null,
  campaignDescription: null,
  className: null,
  localeAchievementDescription: null,
  localeAchievementName: null,
  localeCampaignDescription: null,
  localeName: '',
}
