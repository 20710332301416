// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import { AvatarSizes } from './avatar'
import AvatarList from './avatar_list'
import ParticipantsMessage from './participants_message'

const ParticipantsSummary = ({
  avatarImages,
  avatarSize,
  canParticipate,
  className,
  participantsCount,
  type,
  variant,
  ...other
}) => (
  <div
    {...other}
    className={
      variant === 'stacked'
        ? cx('d-flex flex-column align-items-start ', className)
        : cx('d-flex align-items-center', className)
    }
    data-test="participants-summary"
  >
    <AvatarList
      avatarSize={avatarSize}
      className="[ flex-shrink-none ] [ mr-2 ]"
      images={avatarImages}
    />
    <ParticipantsMessage
      canParticipate={canParticipate}
      className={
        variant === 'stacked' ? 'lh-md ls-2 text-black-3 text-uppercase' : ''
      }
      count={participantsCount}
      type={type}
    />
  </div>
)

ParticipantsSummary.defaultProps = {
  avatarSize: AvatarSizes.medium,
  canParticipate: true,
  className: null,
  type: null,
  variant: '',
}

ParticipantsSummary.propTypes = {
  avatarImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  avatarSize: PropTypes.string,
  canParticipate: PropTypes.bool,
  className: PropTypes.string,
  participantsCount: PropTypes.number.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
}

export default ParticipantsSummary
