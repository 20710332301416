// Vendor
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { client } from 'utilities/we_apollo'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import { useHistory, useParams } from 'react-router-dom'

// WeSpire
import DisableButton from 'components/ui/disable_button'
import {
  displayBanner,
  displayExceptionBanner,
  maybeDisplayUserTour,
} from 'redux/dispatchers'
import { DocumentTitle } from 'components/shared/document_title'
import { ErrorList } from 'components/form/error_list'
import { intl } from 'utilities/localization'
import { PageQuery } from 'components/queries/page_query'
import { sharedTranslations } from 'components/shared/translations'
import Stack from 'components/ui/stack'

// Sub-Components
import { EventFormDetails } from './event_form_details'
import { FormWrapper } from './form_wrapper'
import { GuestRegistrationForm } from './guest_registration_form'
import { PARTICIPATION_GUESTS_QUERY } from './event_guest_box'
import { selfRegistrationPath } from 'components/events/routes'
import { SHIFT_AVAILABLE_SLOTS } from './fragments'

const DELETE_GUEST_REGISTRATION = gql`
  mutation deleteGuestRegistration($guestId: ID!, $shiftTimeframeId: ID!) {
    deleteGuestRegistration(
      guestId: $guestId
      shiftTimeframeId: $shiftTimeframeId
    ) {
      errors
    }
  }
`

const messages = defineMessages({
  back: {
    defaultMessage: 'Back to your registration',
    id: 'guestRegistrationPageComponent.back',
  },
  delete: {
    defaultMessage: 'delete this guest',
    id: 'guestRegistrationPageComponent.delete',
  },
  editPageTitle: {
    defaultMessage: '{name} • Edit your guest registration',
    id: 'guestRegistrationPageComponent.editPageTitle',
  },
  heading: {
    defaultMessage: '{action} guest registration',
    id: 'guestRegistrationPageComponent.heading',
  },
  operation: {
    defaultMessage: "remove this guest's registration",
    id: 'guestRegistrationPageComponent.operation',
  },
  registerPageTitle: {
    defaultMessage: '{name} • Register a guest',
    id: 'guestRegistrationPageComponent.registerPageTitle',
  },
  removedSuccessfully: {
    defaultMessage: "Your guest's registration has been successfully removed.",
    id: 'guestRegistrationPageComponent.removedSuccessfully',
  },
  removeGuestConfirmation: {
    defaultMessage: 'Are you sure you want to remove this guest?',
    id: 'guestRegistrationPageComponent.removeGuestConfirmation',
  },
})

export const GuestRegistrationPage = () => {
  const history = useHistory()
  const { formatMessage } = intl
  const { event_id, guest_id, timeframe_id } = useParams()
  const [isDeleting, setIsDeleting] = useState(false)

  const handleUnregister = () => {
    if (confirm(formatMessage(messages.removeGuestConfirmation))) {
      setIsDeleting(true)
      client
        .mutate({
          awaitRefetchQueries: true, // Don't redirect until new data is ready.
          mutation: DELETE_GUEST_REGISTRATION,
          refetchQueries: [
            {
              query: PARTICIPATION_GUESTS_QUERY,
              variables: { id: timeframe_id },
            },
            {
              query: SHIFT_AVAILABLE_SLOTS,
              variables: { id: timeframe_id },
            },
          ],
          variables: {
            guestId: guest_id,
            shiftTimeframeId: timeframe_id,
          },
        })
        .then(
          ({
            data: {
              deleteGuestRegistration: { errors },
            },
          }) => {
            setIsDeleting(false)

            if (errors) {
              displayBanner({
                as: 'div',
                content: <ErrorList errors={errors} />,
                variant: 'error',
              })
            } else {
              displayBanner({
                content: formatMessage(messages.removedSuccessfully),
                variant: 'success',
              })
              history.push(
                selfRegistrationPath({
                  eventId: event_id,
                  shiftId: timeframe_id,
                })
              )
            }
          }
        )
        .catch(() => {
          setIsDeleting(false)
          displayExceptionBanner({
            operation: formatMessage(messages.removeGuestConfirmation),
          })
        })
    }
  }

  return (
    <PageQuery
      query={gql`
        query participationEvent($id: ID!) {
          participationEvent(id: $id) {
            name
          }
        }
      `}
      variables={{ id: event_id }}
    >
      {({
        data: {
          participationEvent: { name },
        },
      }) => (
        <DocumentTitle
          title={
            guest_id
              ? intl.formatMessage(messages.editPageTitle, { name: name })
              : intl.formatMessage(messages.registerPageTitle, { name: name })
          }
        >
          {maybeDisplayUserTour('eventRegistrationGuestFormPage')}

          <FormWrapper
            backLinkText={'< ' + formatMessage(messages.back)}
            backLinkTo={selfRegistrationPath({
              eventId: event_id,
              shiftId: timeframe_id,
            })}
            heading={formatMessage(messages.heading, {
              action: guest_id ? sharedTranslations.edit : '',
            })}
          >
            <Stack space={3}>
              <EventFormDetails shiftId={timeframe_id} />
              <GuestRegistrationForm
                eventId={event_id}
                guestId={guest_id}
                shiftId={timeframe_id}
              />
              {guest_id && (
                <DisableButton
                  isLoading={isDeleting}
                  onClick={handleUnregister}
                  size="small"
                >
                  {formatMessage(messages.delete)}
                </DisableButton>
              )}
            </Stack>
          </FormWrapper>
        </DocumentTitle>
      )}
    </PageQuery>
  )
}

GuestRegistrationPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      event_id: PropTypes.string,
      guest_id: PropTypes.string,
      timeframe_id: PropTypes.string,
    }).isRequired,
  }).isRequired,
}
