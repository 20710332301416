// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'

class ImageDialog extends Component {
  static propTypes = {
    altText: PropTypes.string,
    imageUrl: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    altText: '',
    imageUrl: '',
    isOpen: false,
  }

  state = {
    imageLoading: true,
  }

  handleImageLoad = () => this.setState({ imageLoading: false })

  render() {
    const { altText, imageUrl, isOpen, onClose } = this.props
    const { imageLoading } = this.state

    return (
      <Dialog
        aria-labelledby="image-dialog"
        classes={{
          paper: 'm-4 m-sm-6',
        }}
        maxWidth={false}
        onClose={onClose}
        open={isOpen}
      >
        <img
          alt={altText}
          onLoad={this.handleImageLoad}
          src={imageUrl}
          style={{ maxHeight: '90vh' }}
        />
        {imageLoading && (
          <div className="d-flex align-items-center justify-content-center | bg-white h-100 p-4 w-100">
            <CircularProgress />
          </div>
        )}
      </Dialog>
    )
  }
}

export default ImageDialog
