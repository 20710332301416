// Vendor
import React from 'react'
import { string } from 'prop-types'
import gfm from 'remark-gfm'
import Paper from '@material-ui/core/Paper'
import ReactMarkdown from 'react-markdown'
import { defineMessages } from 'react-intl'

// WeSpire
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import Stack from 'components/ui/stack'
import { WordForScore } from 'components/queries/word_for_score'

const messages = defineMessages({
  pointsDecriptionHeading: {
    defaultMessage: 'About Your { wordForScore } Annual Goal',
    id: 'description.pointsDecriptionHeading',
  },
})

const PointsDescription = ({ description }) => {
  const formatMessage = intl.formatMessage

  if (!description) return null
  return (
    <Stack as={Paper} square variant="outlined">
      <div className="pt-4 d-inline-flex align-items-center">
        <Icon className="fs-3 text-black-2 mx-3" iconName="info_outline" />
        <Heading
          className="fs-2"
          data-test="points-description-heading"
          level={3}
        >
          {formatMessage(messages.pointsDecriptionHeading, {
            wordForScore: <WordForScore wordsOnly />,
          })}
        </Heading>
      </div>

      <div>
        <ReactMarkdown className="pb-2 fs-2 mx-6" remarkPlugins={[gfm]}>
          {description}
        </ReactMarkdown>
      </div>
    </Stack>
  )
}

PointsDescription.propTypes = {
  description: string,
}

PointsDescription.defaultProps = {
  description: null,
}

export default PointsDescription
