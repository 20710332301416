// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// WeSpire
import { Heading } from 'components/ui/heading'

const LeaderBoardSection = ({ heading, headingClassName, children }) => (
  <div className="[ col-12 d-flex flex-column | col-md ]">
    <Heading
      className={cx('[ fs-1 p-3 pt-0 ls-2 text-uppercase ]', headingClassName)}
      level={3}
    >
      {heading}
    </Heading>
    {children}
  </div>
)

LeaderBoardSection.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  headingClassName: PropTypes.string,
}

LeaderBoardSection.defaultProps = {
  headingClassName: null,
}

export { LeaderBoardSection }
