// Inspired by Ruby's 3.times { # ... } and http://underscorejs.org/#times
// Create an array from the return value of a function executed n times in a row.
// e.g. times(2, index => <p>repetition {index}</p>)
// => [<p>repetition 0</p>, <p>repetition 1</p>]
const times = (n, result) => {
  if (isNaN(n)) throw `'${n}' must be a number`
  if (n < 0) throw `'${n}' must be a positive number`
  const isFunction = typeof result === 'function'
  const array = Array.from(Array(n))

  return isFunction
    ? array.map((_, index) => result(index))
    : array.map(() => result)
}

export { times }
