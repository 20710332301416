// Vendor
import gql from 'graphql-tag'
import {
  ACTION_FRAGMENT,
  CAMPAIGN_DETAILS_FRAGMENT,
  NEWSFEED_ITEM_FRAGMENT,
  USER_FRAGMENT,
} from 'graphql/fragments'
import { FacepileSizes } from 'components/application/avatar'

// NOTE: Adding and removing from here requires updating Campaign.js to keep
// our class sync'd with the data returned from this query.
export const GET_CAMPAIGN_BY_ID = gql`
  ${ACTION_FRAGMENT}

  query getCampaignById($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...CampaignDetailsAttributes
    }
  }
  ${CAMPAIGN_DETAILS_FRAGMENT}
`

export const GET_CAMPAIGN_NEWSFEED_ITEMS = gql`
  query getCampaignNewsfeedItems(
    $campaignId: ID!
    $after: String
    $first: Int
  ) {
    campaign(id: $campaignId) {
      id
      newsfeed(after: $after, first: $first) @connection(key: "newsfeed") {
        nodes {
          ...NewsfeedItem
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
  }
  ${NEWSFEED_ITEM_FRAGMENT}
`

export const GET_IDEA_BOARD_WITH_IDEA = gql`
  query getIdeaBoardById($ideaBoardID: ID!, $ideaID: ID) {
    brandConfig {
      id
      wordForScorePlural
    }

    currentUser {
      id
    }

    ideaBoard(id: $ideaBoardID) {
      canEarnPoints
      closeToScorableSubmissionsLimit
      idea(id: $ideaID) {
        id
        title
        imageUrl
        category {
          id
          name
        }
        customFieldResponses {
          id
          name
          response
          customFieldId
        }
        description
        scored
        user {
          id
        }
      }
      categories {
        id
        name
      }
      customFields {
        id
        isRequired
        name
        options
        responseType
      }
      description
      ended
      endsAt
      id
      ideaDescriptionMax
      ideaTitleMax
      imageUrl
      name
      pointsPerIdeaBoardSubmission
    }
  }
`

export const GET_IDEA_BOARD_BY_ID = gql`
  ${USER_FRAGMENT}

  query getIdeaBoardById(
    $ideaBoardID: ID!
  ) {
    brandConfig {
      id
      wordForScorePlural
    }

    currentUser {
      ...UserAttributes
    }

    ideaBoard(id: $ideaBoardID) {
      canEarnPoints
      categories {
        id
        name
      }
      closeToScorableSubmissionsLimit
      customFields {
        id
        isRequired
        name
        options
        responseType
      }
      description
      ended
      endsAt
      id
      ideaDescriptionMax
      ideas {
        category {
          id
          name
        }
        createdAt
        currentUserVoteId
        currentVoteCreatedAt
        id
        previousVoteCreatedAt
        title
        scored
        user {
          avatarUrl
          id
          profilePath
        }
        voteCount
      }
      ideaTitleMax
      imageUrl
      name
      participants(limit: ${FacepileSizes.large}) {
        avatarUrl
        id
      }
      participantsCount
      pointsPerIdeaBoardSubmission
      startsAt
      totalParticipationIndicators
      totalVotes
    }
  }
`

export const GET_IDEA_BY_ID = gql`
  ${USER_FRAGMENT}

  query getIdeaById($ideaID: ID!, $ideaBoardID: ID!) {
    currentUser {
      ...UserAttributes
    }

    ideaBoard(id: $ideaBoardID) {
      customFields {
        id
        isRequired
        name
        options
        responseType
      }
      id
      name
      ended
      endsAt
      ideaTitleMax

      idea(id: $ideaID) {
        id
        title
        imageUrl
        scored
        user {
          id
        }
      }
    }
  }
`

export const GET_USER_SUGGESTIONS = gql`
  query getUserSuggestions(
    $query: String!
    $omittedUserIds: [String!]
    $includeCurrentUser: Boolean
  ) {
    userSuggestions(
      query: $query
      omittedUserIds: $omittedUserIds
      includeCurrentUser: $includeCurrentUser
    ) {
      ...UserAttributes
    }
  }
  ${USER_FRAGMENT}
`

export const CAMPAIGN_ACTIVITY_GALLERY_QUERY = gql`
  query campaignActivityGalleryQuery(
    $cursor: String
    $activityId: ID!
    $first: Int
  ) {
    campaign(id: $activityId) {
      id
      newsfeed(after: $cursor, first: $first, withImages: true) {
        nodes {
          id
          imageUrl
          message
          storyId
          userId
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
  }
`

export const PARTICIPATION_EVENT_ACTIVITY_GALLERY_QUERY = gql`
  query participationEventActivityGalleryQuery(
    $cursor: String
    $activityId: ID!
    $first: Int
  ) {
    participationEvent(id: $activityId) {
      id
      newsfeed(after: $cursor, first: $first, withImages: true) {
        nodes {
          id
          imageUrl
          message
          storyId
          userId
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
  }
`

// export const ACTIVITY_GALLERY_QUERY = ({ activityFieldName }) => gql`
//   query activityGalleryQuery($cursor: String, $activityId: ID!, $first: Int) {
//     ${activityFieldName}(id: $activityId) {
//       id
//       newsfeed(after: $cursor, first: $first, withImages: true) {
//         nodes {
//           id
//           imageUrl
//           message
//           storyId
//           userId
//         }
//         pageInfo {
//           endCursor
//         }
//         totalCount
//       }
//     }
//   }
// `

export const CONNECTED_DEVICES_GQL = gql`
  query marketplaceInfoQuery {
    currentUser {
      validicUser {
        id
        marketplaceInfo {
          connected
          connectUrl
          disconnectUrl
          displayName
          enabled
          logoUrl
          type
        }
        linkedDevices {
          type
        }
      }
      id
    }
    brand {
      id
      enableMobileApp
    }
    deviceHelperLinks {
      appleHealthHelperLink
      googleFitHelperLink
      fitbitHelperLink
      garminHelperLink
      misfitHelperLink
      underArmourHelperLink
      withingsHelperLink
    }
    iosAppLink
    androidAppLink
    androidAppDisconnectLink
    iosAppDisconnectLink
  }
`
