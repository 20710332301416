// Setup
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Vendor
import { withFormsy } from 'formsy-react'

// WeSpire
import AutocompleteSuggestionsQuery from 'components/queries/autocomplete_suggestions_query'
import AutocompleteTextField from 'components/form/autocomplete_text_field'
import { debounce } from 'utilities/debounce'
import TextFieldValidations from 'components/form/text_field_validations'

const SEARCH_DEBOUNCE = 500
const MIN_QUERY_LENGTH = 3

// Converts our query autocomplete selections to just their values.
// E.g. [{value: 'foo', label: 'Foo'}, {value: 'bar', label: 'Bar'}] -> ['foo', 'bar']
export function selectionValues(selections) {
  return selections ? selections.map((val) => val.value.toString()) : null
}

/**
 * A GraphQL-backed autocomplete that allows us to specify a GraphQL query
 * that we should hit to asynchronously retrieve autocomplete suggestions.
 * Will accept existing selections as "value" prop and remove them from the
 * suggestion list.
 * Leverages debounce to ensure that we do not hit our API until the user
 * has finished typing.
 *
 * Heavily inspired by https://material-ui.com/demos/autocomplete/#downshift
 */
class QueryAutocomplete extends Component {
  static propTypes = {
    className: PropTypes.string,
    extraVariables: PropTypes.object,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    /* The GraphQL query this Autocomplete should execute */
    onHandleSelection: PropTypes.func,
    query: PropTypes.object.isRequired,
    /* The name of the top-level field of the JSON data object returned by the
    GraphQL query */
    queryName: PropTypes.string.isRequired,
    /* A mapping for the variable names of the GraphQL query */
    queryVariableMapping: PropTypes.object.isRequired,
    /* Show suggestions right away OR wait for input.length to be at least MIN_QUERY_LENGTH */
    searchOnFocus: PropTypes.bool,
    setInputRef: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    suggestionMapping: PropTypes.object.isRequired,
    /* A mapping between the prop names of the suggestionPropType and the
    corresponding attribute names on the GraphQL resources. */
    textFieldProps: PropTypes.object,
    value: PropTypes.any,
  }

  static defaultProps = {
    className: null,
    extraVariables: {},
    multiple: false,
    onHandleSelection: null,
    searchOnFocus: false,
    setInputRef: () => {},
    textFieldProps: {},
    value: '',
  }

  state = {
    inputValue: '',
    searchValue: '',
  }

  clearInput = () => {
    this.setState({ inputValue: '' })
  }

  handleBackspace = () => {
    this.clearInput()
    const { value } = this.props
    if (value && value.length > 0) {
      value.pop()
      this.props.setValue(value)
    }
  }

  handleDelete = (item) => () => {
    const deleted = this.props.value.find((val) => val.value === item.id)
    const newValue = this.props.value.filter((val) => val.value !== item.id)
    this.props.setValue(newValue)
    this.props.onHandleSelection &&
      this.props.onHandleSelection({ deleted, newValue })
    this.clearInput()
  }

  handleInputChange = (event) => {
    const query = event.target.value

    this.setState({ inputValue: query })
    this.updateSearch(query)
  }

  handleSelection = (item) => {
    const { value } = this.props
    const addedValue = this.selectionToValue(item)
    const newValue = value ? value.concat(addedValue) : [addedValue]

    this.props.onHandleSelection && this.props.onHandleSelection({ newValue })
    this.props.setValue(newValue)
    this.clearInput()
  }

  selectionToValue = (selection) => ({
    label: selection.suggestionLabel,
    value: selection.id.toString(),
  })
  valueToSelection = (value) => ({
    id: value.value,
    suggestionLabel: value.label,
  })

  updateSearch = debounce((query) => {
    this.setState({ searchValue: query })
  }, SEARCH_DEBOUNCE)

  render() {
    const { inputValue, searchValue } = this.state
    const {
      className,
      extraVariables,
      value,
      multiple,
      name,
      query,
      queryName,
      queryVariableMapping,
      searchOnFocus,
      setInputRef,
      suggestionMapping,
      textFieldProps,
    } = this.props

    const selectedItems = value ? value.map(this.valueToSelection) : []
    const omitted = value ? value.map((val) => val.value) : []

    return (
      <TextFieldValidations {...this.props}>
        {(error, helperText, label) => (
          <AutocompleteTextField
            className={className}
            error={error}
            helperText={helperText}
            inputValue={inputValue}
            label={label}
            multiple={multiple}
            name={name}
            onBackspace={this.handleBackspace}
            onDelete={this.handleDelete}
            onInputChange={this.handleInputChange}
            onSelection={this.handleSelection}
            renderSuggestions={(renderSuggestion) =>
              // Only show suggestions if one of the following is true:
              //   1. searchOnFocus is true.
              //   2. Our input.length is at least MIN_QUERY_LENGTH.
              (searchOnFocus || inputValue.length >= MIN_QUERY_LENGTH) && (
                <AutocompleteSuggestionsQuery
                  query={query}
                  queryName={queryName}
                  variables={{
                    [queryVariableMapping.query]: searchValue,
                    [queryVariableMapping.omitted]: omitted,
                    ...extraVariables,
                  }}
                >
                  {(searchResults) =>
                    searchResults.map((result, index) => {
                      const suggestion = {
                        id: result[suggestionMapping.id],
                        suggestionImageUrl: result[suggestionMapping.imageUrl],
                        suggestionLabel: result[suggestionMapping.label],
                        suggestionSecondaryLabel:
                          result[suggestionMapping.secondaryLabel],
                      }

                      return renderSuggestion(index, suggestion)
                    })
                  }
                </AutocompleteSuggestionsQuery>
              )
            }
            selectedItems={selectedItems}
            setInputRef={setInputRef}
            textFieldProps={textFieldProps}
          />
        )}
      </TextFieldValidations>
    )
  }
}

export { QueryAutocomplete }
export default withFormsy(QueryAutocomplete)
