
// Setup
import React from 'react'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import { intl } from 'utilities/localization'
import QueryAutocomplete from '/components/form/query_autocomplete'

const globalGivingProjectsQuery = gql`
  query globalGivingProjects($query: String) {
    globalGivingProjects(query: $query) {
      id
      givingaId
      givingaIdLabel
      name
    }
  }
`

/**
 * An autocomplete field for selecting a "custom" projects for Globel Giving charity.
 */
export const GlobalGivingProjectAutocomplete = ({ ...other }) => {
  const { formatMessage } = intl

  return (
    <QueryAutocomplete
      {...other}
      query={globalGivingProjectsQuery }
      queryName="globalGivingProjects"
      queryVariableMapping={{
        query: 'query',
      }}
      suggestionMapping={{
        id: 'givingaId',
        label: 'name',
        secondaryLabel: 'givingaIdLabel',
      }}
    />
  )
}
