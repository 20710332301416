import React from 'react'
import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'
import Icon from 'components/ui/icon'

const { formatMessage } = intl

const messages = defineMessages({
  activityGoalTextForCustomMetric: {
    defaultMessage: '{count, number} {conditionMetricName}',
    description: `Value of Activity Goal when metric is custom like 'Yoga Sessions' or 'Walking Meetings', which returns the count plus metric name (likely pluralized), e.g. '5 Yoga Sessions'.`,
    id: 'campaignUtils.activityGoalTextForCustomMetric',
  },
})

const textWithStar = (text) => (
  <span className="d-inline-flex align-items-center">
    {text}
    <Icon className="fs-4 text-green" iconName="star" title="star" />
  </span>
)

const activityGoalText = ({
  accumulationActionTrackingType,
  conditionMetricName,
  count,
  progressCategory,
  progressMeasuredAs,
}) => {
  if (progressCategory === 'custom') {
    const message = formatMessage(messages.activityGoalTextForCustomMetric, {
      conditionMetricName,
      count,
    })

    if (accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY) {
      return textWithStar(message)
    }

    return message
  }

  const key = {
    duration: 'minutesWithCount',
    instances: 'instancesWithCount',
    steps: 'stepsWithCount',
  }[progressMeasuredAs]

  if (!key) return

  const message = formatMessage(sharedMessages[key], { count })

  if (accumulationActionTrackingType === ACTION_TRACKING_TYPE_DAILY) {
    return textWithStar(message)
  }

  return message
}

const panelBorderClasses = (required, blueBackground) => [
  { 'border-left-thick-blue-2': !required && blueBackground },
  { 'border-left-thick-yellow': required },
  { 'border-left-thick-transparent': !required && !blueBackground },
]

const userProgressPercent = (userProgress, progressTargetValue) => {
  if (userProgress === 0 || progressTargetValue === 0) return 0
  if (userProgress === null || progressTargetValue === null) return null

  return Math.min(100, (userProgress * 100) / progressTargetValue)
}

export { activityGoalText, panelBorderClasses, userProgressPercent }

export const ACTION_TRACKING_TYPE_DAILY = 'daily'
export const ACTION_TRACKING_TYPE_OVER_TIME = 'over_time'

export const translatePreviewCampaignProps = (props) => {
  const previewCampaign = {
    ...props.campaign,
    achievement: props.achievement,
    acts: props.acts,
    completionPoints: 50,
    description: props.campaign.descriptionReporting,
    ended: false,
    endsAt: props.campaign.endDate,
    id: `${props.campaign.id}`,
    imageUrl: `${
      props.campaign?.image
        ? `https://res.cloudinary.com/hauyptxwi/image/upload/${props.campaign.image}`
        : null
    }`,
    isDeviceCompatible: false,
    name: props.campaign.nameReporting,
    participants: [],
    participantsCount: 0,
    progressPercentForUser: 50,
    progressPointsForUser: 50,
    startsAt: props.campaign.startDate,
    totalParticipationIndicators: 0,
    totalParticipationIndicatorsForUser: 0,
    type: props.campaign.competition ? 'competition' : 'campaign',
  }
  if (previewCampaign.achievement) {
    previewCampaign.achievement.name = previewCampaign.achievement.nameReporting
    previewCampaign.achievement.imageUrl = `${
      props.achievement?.image
        ? `https://res.cloudinary.com/hauyptxwi/image/upload/${props.achievement.image}`
        : null
    }`
  }

  if (previewCampaign.acts) {
    previewCampaign.acts = previewCampaign.acts?.map((act) => {
      act.id = `${act.id}`
      act.name = act.nameReporting
      act.description = act.descriptionHowReporting
      act.status = 'AVAILABLE'
      act.customFields = act.customFields || []
      act.hasCarbonImpact = false
      act.hasOverrideTargetAmount = act.allowUserToOverrideTargetAmount
      act.required = act.requiredForCampaign
      act.requirement = {
        comment: true,
        photo: true,
        tag: true,
      }
      act.userCompleted = false
      act.userProgress = 50

      act.conditionMetricName = act.conditionMetricNameReporting
      act.progressMeasuredAs = act.accumulationActionMeasuredAs
      act.progressTargetValue = act.accumulationActionTargetValue
      act.isManualLoggingDisabled = false
      act.userProgressActivityLogs = [
        {
          count: 0,
          dailyGoalMet: false,
          dailyMax: 100,
          date: '2022-01-01',
          id: '2022-03-14-85',
          measuredAs: act.progressMeasuredAs || act.conditionMetricName,
        },
        {
          count: 0,
          dailyGoalMet: false,
          dailyMax: 100,
          date: '2022-01-02',
          id: '2022-03-14-86',
          measuredAs: act.progressMeasuredAs || act.conditionMetricName,
        },
      ]
      return act
    })
  }
  return previewCampaign
}
