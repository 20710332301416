// Setup
import React, { useEffect, useState } from 'react'
import { defineMessages } from 'react-intl'
import { useQuery, NetworkStatus } from '@apollo/client'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import cloneDeep from 'lodash/cloneDeep'

// WeSpire
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { MissingResourcePage } from 'components/ui/missing_resource_page'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import Stack from 'components/ui/stack'
import { undecorated as Select } from 'components/form/select'
import { statusFilterOptions, actionFilterOptions } from './utils'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import { getUrlParams } from 'utilities/get_url_params'
import { LoadMoreButton } from 'components/shared/load_more_button'
import Icon from 'components/ui/icon'
import Link from 'components/shared/link'
import InputAdornment from '@material-ui/core/InputAdornment'
import { undecorated as TextField } from 'components/form/text_field'
import { useDebounce } from 'utilities/hooks/useDebounce'

import { UserChangeRow } from './row'

import { LIST_USER_CHANGES_QUERY } from '../utilities/queries'

const { formatMessage } = intl

const messages = defineMessages({
  noChanges: {
    defaultMessage: 'No User Changelog available.',
    id: 'userChangesList.noChanges',
  },
  firstName: {
    defaultMessage: 'First Name',
    id: 'userChangesList.firstName',
  },
  lastName: {
    defaultMessage: 'Last Name',
    id: 'userChangesList.lastName',
  },
  email: {
    defaultMessage: 'Email',
    id: 'userChangesList.email',
  },
  action: {
    defaultMessage: 'Action',
    id: 'userChangesList.action',
  },
  status: {
    defaultMessage: 'Status',
    id: 'userChangesList.status',
  },
  message: {
    defaultMessage: 'Message',
    id: 'userChangesList.message',
  },
  statusFilterLabel: {
    defaultMessage: 'Filter by status',
    id: 'userChangesList.statusFilterLabel',
  },
  actionFilterLabel: {
    defaultMessage: 'Filter by action',
    id: 'userChangesList.actionFilterLabel',
  },
  userFilterLabel: {
    defaultMessage: 'Filter by user',
    id: 'userChangesList.userFilterLabel',
  },
  userChanges: {
    defaultMessage:
      '{count, plural, zero {Users Changelog} one {User Changelog} two {Users Changelog} few {Users Changelog} many {Users Changelog} other {Users Changelog}}',
    id: 'userChangesList.userChanges',
  },
  backTo: {
    defaultMessage: 'Back to HRIS Imports',
    id: 'userChangesList.backTo',
  },
  helperTextForSearch: {
    defaultMessage: 'Filter by name or email address',
    id: 'userChangesList.helperTextForSearch',
  },
})

const PAGE_SIZE = 25

const UserChangesList = () => {
  const hrisImportId = getUrlParams().hris_import_id

  const { data, error, fetchMore, loading, networkStatus, refetch, variables } =
    useQuery(LIST_USER_CHANGES_QUERY , {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_SIZE,
        hrisImportId: hrisImportId,
        status: '',
        action: '',
        query: '',
      },
  })
  const userChanges = data?.userChanges

  const [filterKey, setFilterKey] = useState('')
  const debouncedFilterKey = useDebounce(filterKey, 300)
  const handleFilterKeyOnChange = (value) => {
    setFilterKey(value)
  }

  useEffect(() => {
    onSearch(debouncedFilterKey)
  }, [debouncedFilterKey])

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault()
    }
  }

  const onStatusFilterChange = (status) => {
    refetch({ ...variables, status })
  }

  const onActionFilterChange = (action) => {
    refetch({ ...variables, action })
  }

  const onSearch = (query) => {
    refetch({ ...variables, query: query })
  }

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const result = cloneDeep(fetchMoreResult)
        result.userChanges.nodes = [
          ...previousResult?.userChanges?.nodes,
          ...fetchMoreResult?.userChanges?.nodes,
        ]

        return result
      },
      variables: {
        ...variables,
        after: data?.userChanges?.pageInfo?.endCursor,
        first: PAGE_SIZE,
      },
    })
  }

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)

  if (loading) {
    return (
      <Stack space={4}>
        <SkeletonLoading height={75} />
        <SkeletonLoading className="px-4" height={300} />
      </Stack>
    )
  }

  if (error) {
    return <MissingResourcePage />
  }

  return (
    userChanges && (
      <>
        <Link
          className="d-flex align-items-center mx-4 mt-5 mb-2 text-black-3 fw-semi-bold"
          to="/management_panel/hris_imports"
        >
          <Icon className="fs-4 ml-1" iconName="arrow_left" title="Back" />
          <span className="fs-2">{formatMessage(messages.backTo)}</span>
        </Link>
        <Stack as={Paper} className="p-5 m-5" space={4}>
          <Stack className="row" data-test="filters" space={1}>
            <Stack className="col-3" data-test="status-filter" space={1}>
              <InputLabel
                className="fw-semi-bold text-black-1 text-uppercase fs-1"
                htmlFor="statusFilter"
              >
                {formatMessage(messages.statusFilterLabel)}
              </InputLabel>
              <Select
                hideBlankOption
                name="statusFilter"
                optionProps={{ 'data-test': 'status-filter-option' }}
                options={statusFilterOptions}
                selectProps={{ inputProps: { className: 'p-2 text-capitalize' } }}
                setValue={onStatusFilterChange}
                value={variables.status}
              />
            </Stack>
            <Stack className="col-3" data-test="action-filter" space={1}>
              <InputLabel
                className="fw-semi-bold text-black-1 text-uppercase fs-1"
                htmlFor="actionFilter"
              >
                {formatMessage(messages.actionFilterLabel)}
              </InputLabel>
              <Select
                hideBlankOption
                name="statusFilter"
                optionProps={{ 'data-test': 'status-filter-option' }}
                options={actionFilterOptions}
                selectProps={{ inputProps: { className: 'p-2 text-capitalize' } }}
                setValue={onActionFilterChange}
                value={variables.action}
              />
            </Stack>
            <Stack className="col-3" data-test="user-filter" space={1}>
              <InputLabel
                className="fw-semi-bold text-black-1 text-uppercase fs-1"
                htmlFor="actionFilter"
              >
                {formatMessage(messages.userFilterLabel)}
              </InputLabel>
              <TextField
                className="col mxw-4"
                name="filterKey"
                setValue={handleFilterKeyOnChange}
                textFieldProps={{
                  className: 'pr-0',
                  inputProps: {
                    'aria-label': 'search members',
                    className: 'text-truncate',
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          className="fs-4 text-black-3"
                          iconName="people"
                          title={intl.formatMessage(messages.helperTextForSearch)}
                        />
                      </InputAdornment>
                    ),
                  },
                  onKeyDown: handleKeyDown,
                  placeholder: intl.formatMessage(messages.helperTextForSearch),
                  size: 'small',
                  type: 'search',
                }}
                value={filterKey}
              />
            </Stack>
          </Stack>
          <Stack className="[ flex-column ] [ bg-off-white pt-2 ]" space={2}>
            {!refetching && (
              <>
                {userChanges?.nodes?.length > 0 ? (
                  <div>
                    <div className="bg-black-6 fs-1 fw-semi-bold ls-3 py-3 text-uppercase">
                      <div className="row">
                        <span className="col-1">{formatMessage(messages.firstName)}</span>
                        <span className="col-1">{formatMessage(messages.lastName)}</span>
                        <span className="col-2">{formatMessage(messages.email)}</span>
                        <span className="col-1">{formatMessage(messages.status)}</span>
                        <span className="col-1">{formatMessage(messages.action)}</span>
                        <span className="col-4">{formatMessage(messages.message)}</span>
                      </div>
                    </div>
                    <Stack as="ol" className="p-0" divider="default" space={1}>
                      {userChanges?.nodes?.map((userChange) => <UserChangeRow userChange={userChange} key={userChange.id} />)}
                    </Stack>
                  </div>
                ) : (
                  <p className="fw-semi-bold" data-test="empty-state-message">
                    {formatMessage(messages.noChanges)}
                  </p>
                )}
              </>
            )}

            {loading && (
              <CenteredPaddedLoadingIndicator
                padding={null}
                size={LoadingIndicatorSize.LARGE}
              />
            )}
          </Stack>

          {userChanges?.pageInfo.hasNextPage && (
            <div className="[ d-flex justify-content-center ] [ pt-2 ]">
              <LoadMoreButton
                className="flex-grow mxw-4"
                loading={loading}
                newsfeedItemsCount={userChanges?.nodes?.length}
                onClick={onLoadMore}
                pageSize={PAGE_SIZE}
                totalCount={userChanges?.totalCount}
                type={messages.userChanges}
              />
            </div>
          )}
        </Stack>
      </>
    )
  )
}

export { UserChangesList }
