// Setup
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// Sub-Components
import { editCampaignPath, showCampaignPath } from './routes'

// WeSpire
import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { sharedMessages } from '../../shared/translations'
import Stack from 'components/ui/stack'
import { TextLink } from 'components/shared/text_link'

const messages = defineMessages({
  heading: {
    defaultMessage: 'Primary Locale',
    id: 'campaignDefaultLocale.heading',
  },
  viewCampaign: {
    defaultMessage: 'View Campaign',
    id: 'campaignDefaultLocale.viewCampaign',
  },
})

const CampaignDefaultLocale = ({ campaignId, defaultLocale }) => {
  const { formatMessage } = intl

  return (
    <>
      <Heading
        className="bg-black-6 fs-1 ls-2 px-4 py-3 text-uppercase"
        level={2}
      >
        {formatMessage(messages.heading)}
      </Heading>
      <div className="px-3">
        <Stack className="py-3">
          <div className="[ d-flex justify-content-between ] [ px-2 ]">
            <span className="fs-2 fw-semi-bold">{defaultLocale}</span>
            <TextLink
              className="[ align-self-center ] [ fs-2 text-link--black-1 ]"
              to={editCampaignPath({ campaignId })}
            >
              {formatMessage(sharedMessages.edit)}
            </TextLink>
          </div>
          <div className="mt-3 px-2">
            <LinkButton
              className="fs-2 mt-2 mt-sm-0"
              color="black"
              to={showCampaignPath({ campaignId })}
              variant="neutral"
            >
              {formatMessage(messages.viewCampaign)}
            </LinkButton>
          </div>
        </Stack>
      </div>
    </>
  )
}

CampaignDefaultLocale.propTypes = {
  campaignId: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
}

export { CampaignDefaultLocale }
