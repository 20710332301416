import PropTypes from 'prop-types'

export const emptyValue = undefined

export const suggestionPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selectionLabel: PropTypes.string,
  suggestionImageUrl: PropTypes.string,
  suggestionLabel: PropTypes.string.isRequired,
  suggestionSecondaryLabel: PropTypes.string,
})
