import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

// westyle
import Divider from 'src/common/Divider'
import InfoContainer from 'src/common/InfoContainer'
import InputChoice from 'src/common/InputChoice'
import SectionContainer from 'src/common/SectionContainer'

const DonationFrequency = ({ isRecurring, setIsRecurring }) => {
  const intl = useIntl()
  const { setFieldValue } = useFormikContext()

  const handleDonationFrequencyChange = (event) => {
    setIsRecurring(event.target.value)
    setFieldValue('donationFrequency', event.target.value)
  }

  return (
    <SectionContainer title="Donation Frequency">
      <div className="px-4">
        <InputChoice
          checked={isRecurring === 'day'}
          label={intl.formatMessage({
            defaultMessage: 'One time',
            id: 'donationForm.oneTime',
          })}
          onChange={handleDonationFrequencyChange}
          name="donationFrequency"
          type="radio"
          value="day"
        />
      </div>
      <Divider className="my-5 border-grayscale-2" />
      <div className="px-4">
        <InputChoice
          checked={isRecurring === 'month'}
          explainer={intl.formatMessage({
            defaultMessage:
              'We can help schedule automatic donations to this charity. You can stop these at any time.',
            id: 'donationForm.weCanHelpScheduleAutimatic',
          })}
          label="Monthly"
          onChange={handleDonationFrequencyChange}
          name="donationFrequency"
          type="radio"
          value="month"
        />
        <InfoContainer className="mt-4">
          <span>
            <FormattedMessage
              defaultMessage="You will receive an email 5 days prior to each monthly donation and can choose to cancel the transaction. You can manage and stop your monthly donation at any time from {donationHistory}."
              id="donationForm.youWillreceiveAnEmail"
              values={{
                donationHistory: (
                  <span className="font-semibold">
                    <FormattedMessage defaultMessage="Donation History" id="shared.donationHistory" />
                  </span>
                ),
              }}
            />
          </span>
        </InfoContainer>
      </div>
    </SectionContainer>
  )
}

export default DonationFrequency
