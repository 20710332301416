// Setup
import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'

// WeSpire
import { carbonFootprintMessages } from 'components/setup/carbon_footprints/utilities/messages'
import { CARBON_FOOTPRINT_FOR_MANAGEMENT_QUERY } from 'components/setup/carbon_footprints/utilities/queries'
import Activity from 'components/setup/shared/activities/list/activity'
import DefaultActions from './components/default_actions'
import ContentHeader from 'components/setup/application/content_header'
import Layout from 'setup/application/layout'
import { CONTENT_MODULES } from 'utilities/content_module'
import { client } from 'utilities/we_apollo'

const CarbonFootprintsIndex = () => {
  const { loading, data } = useQuery(CARBON_FOOTPRINT_FOR_MANAGEMENT_QUERY, {
    client,
    variables: {
      slug: CONTENT_MODULES.CARBON_IMPACT,
    },
  })

  const activities = useMemo(() => {
    if (loading) return []
    return data.carbonImpactCampaigns.nodes || []
  }, [loading, data])

  return (
    <Layout displayNotification={null} notification={null}>
      <ContentHeader text={carbonFootprintMessages.carbonManagement}>
        {!loading ? <DefaultActions moduleId={data.contentModule.id} /> : null}
      </ContentHeader>
      <div className="p-3">
        {activities.length === 0 && !loading ? (
          <p
            className="py-5 text-center"
            data-test="carbon-footprint-empty-text"
          >
            {carbonFootprintMessages.activityEmptyStateManagement}
          </p>
        ) : null}

        {activities.map((activity) => (
          <Activity activity={activity} hideFeaturedToggle key={activity.id} />
        ))}
      </div>
    </Layout>
  )
}

export default CarbonFootprintsIndex
