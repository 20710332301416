// Setup
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Vendor
import { withFormsy } from 'formsy-react'
import { defineMessages } from 'react-intl'

// WeSpire
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Icon from 'components/ui/icon'
import { getFieldValidationAttrs } from './utils'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  required: {
    defaultMessage: 'This field is required',
    id: 'formCheckboxValidations.required',
  },
})

class FormCheckbox extends React.Component {
  handleChangeValue = (event) => {
    const { onChange, setValue } = this.props
    const value = event.currentTarget.checked

    setValue(value)
    onChange(value)
  }

  render() {
    let {
      checkboxProps,
      className,
      errorMessage,
      isFormSubmitted,
      isPristine,
      isValid,
      labelProps,
      name,
      showRequired,
      value,
    } = this.props

    let helperText = this.props.helperText
    let helperTextDefaultClasses =
      this.props.helperTextProps.disableDefaultClasses
    let error = false

    if (showRequired) {
      errorMessage = formatMessage(messages.required)
    }

    const normalCondition = (!isPristine || isFormSubmitted) && !isValid
    const preCheckedCondition = isValid !== null && !isValid

    if (normalCondition || preCheckedCondition) {
      error = true
    }

    if (error) {
      helperText = errorMessage
    }

    const validationTextProps = getFieldValidationAttrs(name, error)

    return (
      <FormGroup className={className}>
        <FormControlLabel
          {...labelProps}
          className="align-self-start"
          control={
            <Checkbox
              {...checkboxProps}
              checked={value}
              inputProps={{ 'data-test': name }}
              onChange={this.handleChangeValue}
            />
          }
        />
        <FormHelperText
          className={cx({ 'd-flex lh-md': !helperTextDefaultClasses })}
          error={error}
          style={{ margin: '-4px 0 0 36px' }}
          {...validationTextProps}
        >
          {error && (
            <Icon className="fs-1 mr-1 mt-1" iconName="warning" title="" />
          )}
          {helperText}
        </FormHelperText>
      </FormGroup>
    )
  }
}

FormCheckbox.propTypes = {
  checkboxProps: PropTypes.object,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  helperTextProps: PropTypes.object,
  isFormSubmitted: PropTypes.bool,
  isPristine: PropTypes.bool,
  isValid: PropTypes.bool,
  labelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  setValue: PropTypes.func,
  showRequired: PropTypes.bool,
  value: PropTypes.bool,
}

FormCheckbox.defaultProps = {
  checkboxProps: {},
  className: null,
  errorMessage: null,
  helperText: null,
  helperTextProps: {
    disableDefaultClasses: false,
  },
  isFormSubmitted: false,
  isPristine: true,
  isValid: null,
  labelProps: {},
  onChange: () => {},
  setValue: () => {},
  showRequired: false,
  value: false,
}

export const undecorated = FormCheckbox

export default withFormsy(FormCheckbox)
