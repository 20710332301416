// Setup
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NetworkStatus } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { defineMessages } from 'react-intl'
import gql from 'graphql-tag'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { client } from 'utilities/we_apollo'
import { intl } from 'utilities/localization'
import { Heading } from 'components/ui/heading'
import LinkButton from 'components/ui/link_button'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'
import Stack from 'components/ui/stack'
import { InvoiceRow } from './invoice_row'
import { InvoiceModal } from './invoice_modal'

const { formatMessage } = intl

const messages = defineMessages({
  invoiceHeading: {
    defaultMessage: 'Invoices',
    id: 'usersTable.invoiceHeading',
  },
  emptyState: {
    defaultMessage: '{type} has no active members.',
    id: 'usersTable.emptyState',
  },
  createInvoice: {
    defaultMessage: '+ New Invoice',
    id: 'usersTable.createInvoice',
  },
})

const tableHeadMessages = defineMessages({
  id: {
    defaultMessage: 'Invoice ID',
    id: 'invoicesTableHead.invoiceId',
  },
  status: {
    defaultMessage: 'Status',
    id: 'invoicesTableHead.status',
  },
  paymentLink: {
    defaultMessage: 'Payment Link',
    id: 'invoicesTableHead.paymentLink',
  },
  downloadPdf: {
    defaultMessage: 'Download',
    id: 'invoicesTableHead.downloadPdf',
  },
})


const INVOICES_QUERY = gql`
	query BrandGivingaInvoicesQuery(
    $invoiceCustomerId: String!
  ) {
    brandGivingaInvoices(
      invoiceCustomerId: $invoiceCustomerId
    ) {
      nodes {
        id
        status
        invoicePdf
        hostedInvoiceUrl
      }
    }
  }
`

export const InvoicesTable = ({invoiceCustomerId, brandGivingaAccountUsername}) => {
  const [showModal, setShowModal] = useState(false)
  const { data, error, loading, networkStatus, refetch, variables } = useQuery(
    INVOICES_QUERY,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        invoiceCustomerId: invoiceCustomerId,
      },
    }
  )

  const invoices = data?.brandGivingaInvoices?.nodes

  const closeModal = () => {
    setShowModal(false)
  }

  const headCells = [
    {
      id: 'id',
      label: formatMessage(tableHeadMessages.id),
    },
    {
      id: 'status',
      label: formatMessage(tableHeadMessages.status),
    },
    {
      id: 'paymentLink',
      label: formatMessage(tableHeadMessages.paymentLink),
    },
    {
      id: 'downloadPdf',
      label: formatMessage(tableHeadMessages.downloadPdf),
    },
  ]

  const refetching = [
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus)
  return (
    <Stack as={Paper} className="border border-black-5" space={0}>
      <Stack className="d-flex px-4 py-3 bg-black-6">
        <Heading
          className="fs-2 text-uppercase d-inline-flex w-100"
          data-test="all-members-label"
          level={3}
        >
          {formatMessage(messages.invoiceHeading)}
          <LinkButton className="text-blue fs-2 ml-auto" onClick={() => setShowModal(true)} variant="text">
            {formatMessage(messages.createInvoice)}
          </LinkButton>
        </Heading>
      </Stack>
      <Stack className="px-4 py-3" space={2}>
        <Stack>
          <TableContainer>
            <Table aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell, index) => (
                    <TableCell
                      align={index > 0 ? 'right' : 'inherit'}
                      key={headCell.id}
                      sortDirection={false}
                    >
                      { headCell.invisible ? null : headCell.label }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!refetching &&
                  invoices?.map((invoice) => {
                    return (
                      <InvoiceRow
                        key={invoice.id}
                        tabIndex={-1}
                        {...invoice}
                      />
                    )
                  })}
                {invoices?.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <p
                        className="fs-2 fw-semi-bold d-flex align-items-center justify-content-center"
                        data-test="invoice-empty-state"
                      >
                        { intl.formatMessage(messages.emptyState) }
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={3}>
                      <CenteredPaddedLoadingIndicator
                        padding={null}
                        size={LoadingIndicatorSize.LARGE}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      {showModal && (
        <InvoiceModal
          brandGivingaAccountUsername={brandGivingaAccountUsername}
          invoiceCustomerId={invoiceCustomerId}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}
    </Stack>
  )
}

InvoicesTable.propTypes = {
  id: PropTypes.shape({
    brandGivingaAccountUsername: PropTypes.string.isRequired,
    invoiceCustomerId: PropTypes.string.isRequired,
  })
}
