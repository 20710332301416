// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

// WeSpire
import { FormRadioGroup } from 'components/form/radio_group'
import { intl } from 'utilities/localization'
import { sharedMessages } from 'components/shared/translations'

const messages = defineMessages({
  role: {
    defaultMessage: 'Role',
    id: 'roleSelection.Role',
  },
})

export const RoleSelection = ({ shifts }) => {
  const options = shifts.map((shift) => {
    const slots = shift.availableSlotsCount
    let text = shift.shiftFunction
    if (slots !== null) {
      text += ` - ${intl.formatMessage(sharedMessages.spotsLeft, {
        count: slots,
      })}`
    }

    return { label: text, value: `${shift.id}` }
  })

  return (
    <FormRadioGroup
      label={intl.formatMessage(messages.role)}
      name="shiftId"
      options={options}
      required
    />
  )
}

RoleSelection.propTypes = {
  shifts: PropTypes.array.isRequired,
}
