// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import ActivitiesCard from '../card'

const ActivitiesCardList = ({
  activities,
  breadcrumbLink,
  condensed,
  fromChannelId,
  fromGroupId,
  onActivityClick,
}) => (
  <div className="row | pl-2" style={{ marginLeft: -8, marginRight: -8 }}>
    {activities.map((activity) => (
      <ActivitiesCard
        activity={activity}
        breadcrumbLink={breadcrumbLink}
        condensed={condensed}
        fromChannelId={fromChannelId}
        fromGroupId={fromGroupId}
        key={activity.type + activity.id}
        onClick={() => onActivityClick(activity.id, activity)}
      />
    ))}
  </div>
)

ActivitiesCardList.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  breadcrumbLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  condensed: PropTypes.bool,
  fromChannelId: PropTypes.string,
  fromGroupId: PropTypes.string,
  onActivityClick: PropTypes.func,
}

ActivitiesCardList.defaultProps = {
  breadcrumbLink: null,
  condensed: false,
  fromChannelId: null,
  fromGroupId: null,
  onActivityClick: () => {},
}

export default ActivitiesCardList
