// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import DisableButton from 'components/ui/disable_button'

/**
 * Secondary action button intended for use in <FormActionBar>. A form can
 * contain multiple of these button types used for secondary form actions.
 */
const FormAtionBarSecondaryButton = (props) => (
  <DisableButton variant="outlined" {...props} />
)

FormAtionBarSecondaryButton.propTypes = {
  /** classes applied to button */
  className: PropTypes.string,
}

FormAtionBarSecondaryButton.defaultProps = {
  className: 'mr-3',
}

export default FormAtionBarSecondaryButton
