// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import v from 'voca'

const attrName = 'group_leadership_roles_users_attributes'

const railsInput = (key, idx, name, value) => (
  <input
    key={key}
    name={`role[${attrName}][${idx}]${name}`}
    type="hidden"
    value={value}
  />
)

const buildRailsInputs = (values) =>
  values
    .reduce((inputs, val, idx) => {
      if (v.isBlank(val.first_name)) {
        // When user is already role leader
        if (!v.isBlank(val.role_id)) {
          inputs.push([idx, '[id]', val.role_id])
        }

        // When new leaders or picked with the autocomplete component
        inputs.push([idx, '[rolable_id]', val.value])
        inputs.push([idx, '[rolable_type]', val.rolable_type || 'User'])
      } else {
        // When adding a guest leader (non-registered user)
        const attrName = '[rolable_attributes]'
        inputs.push([idx, '[rolable_type]', 'GuestGroupLeader'])
        inputs.push([idx, `${attrName}[first_name]`, val.first_name])
        inputs.push([idx, `${attrName}[last_name]`, val.last_name])
        inputs.push([idx, `${attrName}[email]`, val.email])
      }

      return inputs
    }, [])
    .map((val, key) => railsInput(key, ...val))

const buildRailsInputsForDeletion = (toDelete) =>
  toDelete.map((val) => [
    railsInput(val.role_id, val.role_id + 100, 'id', val.role_id),
    railsInput(`${val.role_id}_d`, val.role_id + 100, '_destroy', 'true'),
  ])

/**
 *
 * GroupLeadershipInputs takes a list of elements that represents group leaders
 * and outputs that list as a set of inputs that follow rails conventions
 * to be included on the group leadership form and be sent along with
 * the leadership role form.
 */

const GroupLeadershipInputs = ({ values, toDelete }) => {
  return (
    <div id="leaders_inputs_container">
      <div id="leaders">{buildRailsInputs(values)}</div>
      <div id="to_delete">{buildRailsInputsForDeletion(toDelete)}</div>
    </div>
  )
}

const leaderElementDef = PropTypes.arrayOf(
  PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    rolable_type: PropTypes.string,
    role_id: PropTypes.string,
  })
)
GroupLeadershipInputs.propTypes = {
  toDelete: leaderElementDef,
  values: leaderElementDef,
}

GroupLeadershipInputs.defaultProps = {
  toDelete: [],
  values: [],
}

export default GroupLeadershipInputs
