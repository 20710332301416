// Vendor
import React from 'react'
import { Accordion, AccordionSummary } from '@material-ui/core'
import { defineMessages, FormattedNumber } from 'react-intl'
import gql from 'graphql-tag'

// WeSpire
import Stack from 'components/ui/stack'
import ExpandIcon from 'components/ui/expand_icon'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Heading } from 'components/ui/heading'
import { ourImpactTranslations } from 'components/our_impact/shared_translations'
import WeQuery from 'components/application/we_query'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { SkeletonLoading } from 'components/ui/skeleton_loading'
import { intl } from 'utilities/localization'
import { sharedTranslations } from 'components/shared/translations'
import WysiwygContent from 'components/ui/wysiwyg_content'
import {
  DescriptionList,
  DescriptionListItem,
} from 'components/ui/description_list'

const eventCategoryListQuery = gql`
  query eventCategoryListQuery {
    brand {
      id
      eventCategories {
        id
        name
        hoursVolunteered
        organizationMostImpacted
        whyItMatters
      }
    }
  }
`

export const SocialImpact = () => {
  const { formatMessage } = intl
  const messages = defineMessages({
    heading: {
      defaultMessage: 'Our collective volunteering impact',
      description: 'Heading of area showing company-wide volunteering hours',
      id: 'socialImpact.heading',
    },
    hoursVolunteered: {
      defaultMessage: '{hours} hours volunteered',
      description:
        'Total number of hours the company has volunteered, e.g. "{580} hours volunteered"',
      id: 'socialImpact.hoursVolunteered',
    },
    missingResource: {
      defaultMessage: 'Social Impact stats',
      description:
        'Name of resource to be displayed in an error message when failing to fetch the data.',
      id: 'socialImpact.missingResource',
    },
    mostImpacted: {
      defaultMessage: 'organization most impacted',
      description:
        'Label for the organization most impacted by company volunteering efforts.',
      id: 'socialImpact.mostImpacted',
    },
  })

  return (
    <Stack space={3}>
      <Heading className="px-3" level={3}>
        {formatMessage(messages.heading)}
      </Heading>
      <div>
        <WeQuery
          error={
            <MissingResourceSection
              errorDetails={formatMessage(messages.missingResource)}
            />
          }
          loader={<SkeletonLoading height={330} />}
          query={eventCategoryListQuery}
        >
          {({
            data: {
              brand: { eventCategories },
            },
          }) =>
            eventCategories.map(
              ({
                name,
                hoursVolunteered,
                organizationMostImpacted,
                whyItMatters,
              }) => (
                <Accordion data-test="social-impact-panel" key={name}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandIcon title={sharedTranslations.toggleDetails} />
                    }
                  >
                    <Stack className="fw-semi-bold" space={2}>
                      <div className="fs-2 text-black-3">{name}</div>
                      <div className="fs-1">
                        {formatMessage(messages.hoursVolunteered, {
                          hours: (
                            <FormattedNumber
                              key={name}
                              value={Math.floor(hoursVolunteered)}
                            />
                          ),
                        })}
                      </div>
                      {organizationMostImpacted && (
                        <DescriptionList>
                          <DescriptionListItem
                            label={formatMessage(messages.mostImpacted)}
                          >
                            {organizationMostImpacted}
                          </DescriptionListItem>
                        </DescriptionList>
                      )}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack className="fs-1" space={2}>
                      <div className="fw-semi-bold text-uppercase">
                        {ourImpactTranslations.whyItMatters}
                      </div>

                      <WysiwygContent>{whyItMatters}</WysiwygContent>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )
            )
          }
        </WeQuery>
      </div>
    </Stack>
  )
}
