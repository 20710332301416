// Vendor
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import * as FileSaver from 'file-saver'
import v from 'voca'

// WeSpire
import { sharedTranslations } from 'components/shared/translations'
import SubmitButton from 'components/ui/submit_button'

const ExportButton = ({
  csvData,
  dataTest,
  exportFlag,
  fileName,
  loading,
  setExportFlag,
}) => {
  useEffect(() => {
    if (exportFlag) {
      exportToExcel()
      setExportFlag(false)
    }
  }, [])

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToExcel = () => {
    // eslint-disable-next-line no-undef
    const data = XLSX.utils.json_to_sheet(csvData)
    const sheets = { SheetNames: ['data'], Sheets: { data: data } }
    // eslint-disable-next-line no-undef
    const excelBuffer = XLSX.write(sheets, {
      bookType: 'xlsx',
      type: 'array',
    })
    const result = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(result, v.slugify(fileName) + fileExtension)
  }

  return (
    <>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/xlsx@0.17.0/dist/xlsx.full.min.js"></script>
      </Helmet>
      <SubmitButton
        color="primary"
        data-test={dataTest}
        disabled={loading}
        onClick={setExportFlag ? null : exportToExcel}
        variant="contained"
      >
        {sharedTranslations.export}
      </SubmitButton>
    </>
  )
}

ExportButton.propTypes = {
  csvData: PropTypes.array.isRequired,
  dataTest: PropTypes.string.isRequired,
  exportFlag: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  setExportFlag: PropTypes.func,
}

ExportButton.defaultProps = {
  exportFlag: false,
  loading: null,
  setExportFlag: null,
}

export { ExportButton }
