// Setup
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import Formsy from 'formsy-react'
import gql from 'graphql-tag'

// WeSpire
import { client } from 'utilities/we_apollo'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'
import EditableImageAttachment from 'components/application/editable_image_attachment'
import EditableImageAttachmentHelperText from 'components/application/editable_image_attachment/helper_text'
import FormActionBar from 'components/form/action_bar'
import FormActionBarSubmitButton from 'components/form/action_bar/submit_button'
import FormActionBarSecondaryButton from 'components/form/action_bar/secondary_button'
import FormBooleanRadioReveal from 'components/form/boolean_radio_reveal'
import FormCharityCollectionField from 'components/form/charity_collection_field'
import FormErrorSummary from 'components/form/error_summary'
import FormSelect from 'components/form/select'
import FormFieldGroup from 'components/form/field_group'
import FormFieldset from 'components/form/fieldset'
import FormFieldsetGroup from 'components/form/fieldset_group'
import FormLimitedTextField from 'components/form/limited_text_field'
import FormTeamVisibilityField from 'components/form/team_visibility_field'
import FormGroupVisibilityField from 'components/form/group_visibility_field'
import FormTimeField from 'components/form/time_field'
import { getUrlParams } from 'utilities/get_url_params'
import { FormDateField } from 'components/form/date_field'
import { suggestionPropType } from 'components/form/autocomplete_utils'
import { WysiwygEditor } from 'components/ui/wysiwyg_editor'

const ARCHIVE_GIVING_ACTIVITY = gql`
  mutation archiveGivingActivity($id: ID!) {
    archiveGivingActivity(id: $id) {
      errors
    }
  }
`

const CREATE_GIVING_ACTIVITY = gql`
  mutation createGivingActivity(
    $attributes: GivingActivityAttributes!
    $shouldPublish: Boolean!
  ) {
    createGivingActivity(
      attributes: $attributes
      shouldPublish: $shouldPublish
    ) {
      errors
      givingActivity {
        id
        isPublished
      }
    }
  }
`

const REQUEST_GIVING_ACTIVITY_REVIEW = gql`
  mutation requestGivingActivityReview($id: ID!) {
    requestGivingActivityReview(id: $id) {
      errors
    }
  }
`

const UPDATE_GIVING_ACTIVITY = gql`
  mutation updateGivingActivity(
    $attributes: GivingActivityAttributes!
    $id: ID!
    $shouldPublish: Boolean!
  ) {
    updateGivingActivity(
      attributes: $attributes
      id: $id
      shouldPublish: $shouldPublish
    ) {
      errors
      givingActivity {
        id
        isPublished
      }
    }
  }
`

class GivingActivitiesForm extends Component {
  static propTypes = {
    backPath: PropTypes.string.isRequired,
    channelList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    displayArchive: PropTypes.bool.isRequired,
    displayNotification: PropTypes.func.isRequired,
    displayPublish: PropTypes.bool.isRequired,
    displayRequestReview: PropTypes.bool.isRequired,
    formRequirements: PropTypes.shape({
      cloudinaryConfig: PropTypes.shape({
        cloudName: PropTypes.string.isRequired,
        uploadPreset: PropTypes.string.isRequired,
      }).isRequired,
      validations: PropTypes.shape({
        descriptionMax: PropTypes.number.isRequired,
        nameMax: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    givingActivity: PropTypes.shape({
      channelId: PropTypes.number,
      charities: PropTypes.array,
      description: PropTypes.string,
      endsAt: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      groups: PropTypes.arrayOf(suggestionPropType).isRequired,
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      startsAt: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      teams: PropTypes.arrayOf(suggestionPropType).isRequired,
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
    groupsList: PropTypes.arrayOf(suggestionPropType).isRequired,
    isEdit: PropTypes.bool.isRequired,
    isPublished: PropTypes.bool.isRequired,
    redirectBackTarget: PropTypes.string.isRequired,
    showGroupsList: PropTypes.bool.isRequired,
    teamsList: PropTypes.arrayOf(suggestionPropType).isRequired,
    timeZoneList: PropTypes.array.isRequired,
  }

  state = {
    shouldDeleteImage: false,
  }

  componentDidMount() {
    const {
      givingActivity: { name },
      isEdit,
      isPublished,
    } = this.props

    this.handleNothingHappening()

    if (isEdit && getUrlParams().success) {
      const successVerb = isPublished ? 'published' : 'created as a draft'

      displayBanner({
        content: (
          <Fragment>
            <strong>{name}</strong> was successfully {successVerb}.
          </Fragment>
        ),
        fullWidth: true,
        variant: 'success',
      })
      // Only display the banner once, not on subsequent historical visits
      // (e.g. back button).
      history.replaceState({}, 'Edit Giving Activity', window.location.pathname)
    }
  }

  FORM_ACTIONS = {
    ARCHIVE: 'archive',
    IMAGE_UPLOAD: 'image_upload',
    NOTHING: 'nothing',
    PUBLISH: 'publish',
    REQUEST_REVIEW: 'request_review',
    SAVE: 'save',
    UPDATE: 'update',
  }

  handleArchive = () => {
    const {
      backPath,
      givingActivity: { id, name },
    } = this.props
    const archiveMessage = `Are you sure you want to archive "${name}"?`
    const didNotConfirmArchive = !window.confirm(archiveMessage)

    if (didNotConfirmArchive) {
      return
    }

    this.setState({ isHappening: this.FORM_ACTIONS.ARCHIVE })

    client
      .mutate({
        mutation: ARCHIVE_GIVING_ACTIVITY,
        variables: { id: id },
      })
      .then(
        ({
          data: {
            archiveGivingActivity: { errors },
          },
        }) => {
          if (errors.length === 0) {
            window.location.href = `${backPath}?archived_id=${id}`
          } else {
            this.handleServerErrors(errors)
          }
        }
      )
      .catch(() => {
        this.handleException({ verb: 'archive' })
      })
  }

  handleException = ({ verb }) => {
    this.handleNothingHappening()
    displayExceptionBanner({
      fullWidth: true,
      operation: `${verb} this Activity`,
    })
  }

  handleImageUploading = (isUploading) => {
    if (isUploading) {
      this.setState({ isHappening: this.FORM_ACTIONS.IMAGE_UPLOAD })
    } else {
      this.handleNothingHappening()
    }
  }

  handleInvalidSubmit = () => {
    displayBanner({
      content: 'Please fix the errors in the form.',
      fullWidth: true,
      variant: 'error',
    })
  }

  handleNothingHappening = () => this.setState({ isHappening: 'nothing' })

  handleRedirectWithSuccessBanner = (givingActivity) => {
    const { id } = givingActivity

    const redirectBack = this.props.redirectBackTarget || ''
    const successRedirect = `${this.props.backPath}/${id}/edit?success`

    // Redirect to /edit view and display success banner or back target.
    window.location.href = redirectBack ? redirectBack : successRedirect
  }

  handleRemoveImage = () => {
    this.setState({ shouldDeleteImage: true })
  }

  handleRequestReview = () => {
    const {
      givingActivity: { id },
    } = this.props
    const requestReviewMessage =
      "Are you sure you want to submit this Giving Activity for approval? This will send an email to the administrators of this Giving Activity's channel."
    const didNotConfirmRequestReview = !window.confirm(requestReviewMessage)

    if (didNotConfirmRequestReview) {
      return
    }

    this.setState({ isHappening: this.FORM_ACTIONS.REQUEST_REVIEW })

    client
      .mutate({
        mutation: REQUEST_GIVING_ACTIVITY_REVIEW,
        variables: { id: id },
      })
      .then(
        ({
          data: {
            requestGivingActivityReview: { errors },
          },
        }) => {
          if (errors.length === 0) {
            displayBanner({
              content:
                'You successfully submitted this Giving Activity for approval.',
              fullWidth: true,
              variant: 'success',
            })
          } else {
            this.handleServerErrors(errors)
          }
        }
      )
      .catch(() => {
        this.handleException({ verb: 'request approval for' })
      })
  }

  handleSaveDraft = () => {
    const values = this.formElement.getModel()
    this.handleSubmit(values, null, null, false)
  }

  handleSubmit = (
    values,
    _resetForm,
    _invalidateForm,
    shouldPublish = true
  ) => {
    const { displayNotification, isEdit, isPublished } = this.props
    const { shouldDeleteImage } = this.state
    values.eins = values.eins.map((val) => val.ein)

    var givingActivityAttributes = {
      ...values,
      shouldDeleteImage,
    }

    hideBanner()

    if (isEdit) {
      const {
        givingActivity: { id },
      } = this.props

      let isHappening

      if (isPublished) {
        // When editing published activity, form is "updating"
        isHappening = this.FORM_ACTIONS.UPDATE
      } else {
        if (shouldPublish) {
          // When editing draft activity to be published, form is "publishing"
          isHappening = this.FORM_ACTIONS.PUBLISH
        } else {
          // When editing draft activity as-is, form is "saving"
          isHappening = this.FORM_ACTIONS.SAVE
        }
      }

      this.setState({ isHappening })

      client
        .mutate({
          mutation: UPDATE_GIVING_ACTIVITY,
          variables: {
            attributes: givingActivityAttributes,
            id: id,
            shouldPublish: shouldPublish,
          },
        })
        .then(
          ({
            data: {
              updateGivingActivity: { errors, givingActivity },
            },
          }) => {
            const { isHappening } = this.state
            if (errors.length === 0) {
              if (isHappening === this.FORM_ACTIONS.PUBLISH) {
                this.handleRedirectWithSuccessBanner(givingActivity)
              } else {
                displayNotification('Changes successfully saved.')
                this.handleNothingHappening()

                const redirectBack = this.props.redirectBackTarget || ''
                if (redirectBack) {
                  window.location.href = redirectBack
                }
              }
            } else {
              this.handleServerErrors(errors)
            }
          }
        )
        .catch(() => this.handleException())
    } else {
      this.setState({
        isHappening: shouldPublish
          ? this.FORM_ACTIONS.PUBLISH
          : this.FORM_ACTIONS.SAVE,
      })

      client
        .mutate({
          mutation: CREATE_GIVING_ACTIVITY,
          variables: {
            attributes: givingActivityAttributes,
            shouldPublish: shouldPublish,
          },
        })
        .then(
          ({
            data: {
              createGivingActivity: { errors, givingActivity },
            },
          }) => {
            if (givingActivity) {
              this.handleRedirectWithSuccessBanner(givingActivity)
            } else {
              this.handleServerErrors(errors)
            }
          }
        )
        .catch(() => this.handleException())
    }
  }

  handleServerErrors = (errors) => {
    this.handleNothingHappening()
    const startsAtError = errors.find((e) => e.includes('startsAt'))
    // Replace startsAt with startsAtDate so our error summary link
    // will focus on that input field.
    errors = errors.map((e) => e.replace('startsAt|', 'startsAtDate|'))
    displayBanner({
      as: 'div',
      content: <FormErrorSummary errors={errors} />,
      fullWidth: true,
      variant: 'error',
    })
    this.formElement.updateInputsWithError({
      ...(startsAtError && {
        startsAtDate: startsAtError.split('|')[1],
        startsAtTime: startsAtError.split('|')[1],
      }),
    })
  }

  handleUploadImage = () => {
    this.setState({ shouldDeleteImage: false })
  }

  isNotHappening = (action, isHappening) =>
    isHappening &&
    isHappening !== this.FORM_ACTIONS.NOTHING &&
    isHappening !== action

  setFormRef = (formElement) => (this.formElement = formElement)

  render() {
    const {
      backPath,
      channelList,
      displayArchive,
      displayPublish,
      displayRequestReview,
      formRequirements,
      givingActivity,
      isEdit,
      isPublished,
      groupsList,
      showGroupsList,
      teamsList,
      timeZoneList,
    } = this.props
    const {
      channelId,
      charities,
      description,
      endsAt,
      imageUrl,
      groups,
      name,
      startsAt,
      teams,
      timeZone,
    } = givingActivity
    const { cloudinaryConfig, validations } = formRequirements
    const { nameMax } = validations
    const { isHappening } = this.state

    return (
      <Formsy
        noValidate
        onInvalidSubmit={this.handleInvalidSubmit}
        onValidSubmit={(values, _resetForm, _invalidateForm) => {
          this.handleSubmit(values, _resetForm, _invalidateForm)
        }}
        ref={this.setFormRef}
      >
        <FormFieldsetGroup>
          <FormFieldset
            hint="Provide participants with the basic information about this Giving Activity."
            label="Details"
          >
            <FormLimitedTextField
              helperText="Give this Activity a name to display to participants."
              label="Name"
              maxLength={nameMax}
              name="name"
              required
              value={name}
            />

            <WysiwygEditor
              data-test="giving-activity-description"
              label="Description"
              name="description"
              readOnly={false}
              required
              textFieldProps={{
                disabled: false,
                helperText:
                  'Let participants know what this Giving Activity is about and why it matters.',
                label: 'Description',
              }}
              value={description}
            />

            <EditableImageAttachment
              cloudinaryConfig={cloudinaryConfig}
              customHeight="288px"
              helperText={
                <EditableImageAttachmentHelperText>
                  This image appears in the banner at the top of the Giving
                  Activity page. Recommended size: 1600x900.
                </EditableImageAttachmentHelperText>
              }
              imageClassName="w-100"
              imageHeight="288"
              imageWidth="1024"
              label="Banner Image"
              name="image"
              onImageUpload={this.handleUploadImage}
              onImageUploading={this.handleImageUploading}
              onRemoveImage={this.handleRemoveImage}
              required
              value={imageUrl}
            />
          </FormFieldset>

          <FormFieldset
            hint="Specify which Charities should be available in this Giving Activity."
            label="Charities"
          >
            <FormCharityCollectionField value={charities} />
          </FormFieldset>

          <FormFieldset
            hint="Specify which participants should have access to this Giving Activity."
            label="Visibility"
          >
            {channelList.length > 0 && (
              <FormSelect
                name="channelId"
                options={channelList}
                required
                textFieldProps={{
                  helperText:
                    'Select the Channel this Giving Activity should belong to.',
                  label: 'Channel',
                }}
                // We have to ensure that a GivingActivity always has a channel associated because
                // we cannot display drafts without channels to channel managers or contributors.
                value={channelId || channelList[0].value}
              />
            )}

            {teamsList.length > 0 && (
              <FormTeamVisibilityField
                allTeams={teamsList}
                selectedTeams={teams}
              />
            )}
            {showGroupsList && groupsList.length > 0 && (
              <FormGroupVisibilityField
                allGroups={groupsList}
                selectedGroups={groups}
              />
            )}
          </FormFieldset>

          <FormFieldset
            hint="Select when this Giving Activity should go live to participants."
            label="Timeframe"
          >
            <FormSelect
              name="timeZone"
              options={timeZoneList}
              required
              textFieldProps={{
                helperText:
                  'Select the time zone in which this Giving Activity should be based.',
                label: 'Time Zone',
              }}
              value={timeZone}
            />

            <FormFieldGroup id="startsAt-label" label="Starts On*">
              <div className="d-flex">
                <FormDateField
                  label="Start Date"
                  name="startsAtDate"
                  required
                  value={startsAt.date}
                />

                <FormTimeField
                  className="ml-3"
                  label="Start Time"
                  name="startsAtTime"
                  required
                  value={startsAt.time}
                />
              </div>
            </FormFieldGroup>

            <FormBooleanRadioReveal
              emptyStates={[
                { name: 'endsAtDate', value: '' },
                { name: 'endsAtTime', value: '' },
              ]}
              label="Ends On*"
              radioHideLabel="Never"
              radioRevealLabel="A specific date"
              shouldReveal={!!endsAt.date}
            >
              <div className="d-flex">
                <FormDateField
                  label="End Date"
                  name="endsAtDate"
                  required
                  value={endsAt.date}
                />

                <FormTimeField
                  className="ml-3"
                  label="End Time"
                  name="endsAtTime"
                  required
                  value={endsAt.time}
                />
              </div>
            </FormBooleanRadioReveal>
          </FormFieldset>

          <FormActionBar backPath={backPath}>
            {isEdit && displayArchive && (
              <FormActionBarSecondaryButton
                data-test={`${this.FORM_ACTIONS.ARCHIVE}-giving-activity`}
                disabled={this.isNotHappening(this.FORM_ACTIONS.ARCHIVE)}
                isLoading={isHappening === this.FORM_ACTIONS.ARCHIVE}
                onClick={this.handleArchive}
              >
                Archive
              </FormActionBarSecondaryButton>
            )}

            {isPublished ? (
              <FormActionBarSubmitButton
                data-test={`${this.FORM_ACTIONS.SAVE}-giving-activity`}
                disabled={this.isNotHappening(this.FORM_ACTIONS.UPDATE)}
                isSubmitting={isHappening === this.FORM_ACTIONS.UPDATE}
              >
                Update
              </FormActionBarSubmitButton>
            ) : (
              <Fragment>
                <FormActionBarSecondaryButton
                  data-test={`${this.FORM_ACTIONS.SAVE}-giving-activity`}
                  disabled={this.isNotHappening(this.FORM_ACTIONS.SAVE)}
                  isLoading={isHappening === this.FORM_ACTIONS.SAVE}
                  onClick={this.handleSaveDraft}
                >
                  Save As Draft
                </FormActionBarSecondaryButton>

                {displayRequestReview && (
                  <FormActionBarSecondaryButton
                    color="primary"
                    data-test={`${this.FORM_ACTIONS.REQUEST_REVIEW}-giving-activity`}
                    disabled={this.isNotHappening(
                      this.FORM_ACTIONS.REQUEST_REVIEW
                    )}
                    isSubmitting={
                      isHappening === this.FORM_ACTIONS.REQUEST_REVIEW
                    }
                    onClick={this.handleRequestReview}
                    variant="contained"
                  >
                    Submit for Approval
                  </FormActionBarSecondaryButton>
                )}

                {displayPublish && (
                  <FormActionBarSubmitButton
                    data-test={`${this.FORM_ACTIONS.PUBLISH}-giving-activity`}
                    disabled={this.isNotHappening(this.FORM_ACTIONS.PUBLISH)}
                    isSubmitting={isHappening === this.FORM_ACTIONS.PUBLISH}
                  >
                    Publish
                  </FormActionBarSubmitButton>
                )}
              </Fragment>
            )}
          </FormActionBar>
        </FormFieldsetGroup>
      </Formsy>
    )
  }
}

export default GivingActivitiesForm
