import React, { useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Redirect } from 'react-router'

// westyle
import Button from 'src/common/button/Button'
import InfoContainer from 'src/common/InfoContainer'
import Input from 'src/common/Input'
import TextHero from 'src/common/TextHero'
import { useCsrft } from 'src/hooks/useCsrft'
import WeSpireAPI from 'utilities/wespire_api'
import SessionContext from 'src/contexts/SessionContext'
import getCurrentUser from '../../queries/getCurrentUser'
import { FormattedMessage, useIntl } from 'react-intl'
import { setToken } from 'redux/reducers/session'

const Login = () => {
  const ssoFormRef = useRef(null)
  const { setUser, signedIn } = useContext(SessionContext)
  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    const { email, password } = values
    WeSpireAPI()
      .post('/session', {
        session: {
          email,
          password,
        },
      })
      .then((response) => {
        setToken(response?.data?.token)
        getCurrentUser(setUser)
        if (response.data.sso) {
          ssoFormRef.current.submit()
        } else {
          window.location = response.data.redirect_to_path
        }
      })
      .catch((error) => {
        const status = error?.response?.status
        const serverError = {
          title: intl.formatMessage({
            id: 'loginMessages.serverErrorTitle',
            defaultMessage: 'Server Error',
          }),
          description: intl.formatMessage({
            id: 'loginMessages.serverErrorDescription',
            defaultMessage: 'Please try again later or contact support.',
          }),
        }
        const loginError = {
          title: intl.formatMessage({
            id: 'loginMessages.emailOrPasswordIncorrectTitle',
            defaultMessage: 'Email or Password is incorrect',
          }),
          description: intl.formatMessage({
            id: 'loginMessages.emailOrPasswordIncorrect',
            defaultMessage: 'Email or passwords is incorrect',
          }),
        }

        setStatus(status === 401 ? loginError : serverError)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const { brand } = useContext(SessionContext)

  const { tokenName, tokenValue } = useCsrft()
  const intl = useIntl()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'loginMessages.invalidEmail',
          defaultMessage: 'Invalid Email',
        })
      )
      .required(
        intl.formatMessage({
          id: 'loginMessages.emailRequired',
          defaultMessage: 'Email required',
        })
      ),
    password: Yup.string().required(
      intl.formatMessage({
        id: 'loginMessages.passwordRequired',
        defaultMessage: 'Password is required',
      })
    ),
  })

  if (signedIn) {
    return <Redirect to="/take_action" />
  }
  if (brand?.authScheme === 'sso') {
    return <Redirect to="/app/landing/sso" />
  }

  return (
    <main className="grid justify-items-center mt-20 pb-8">
      <TextHero subtitle={`Log in to ${brand?.name}`} title="Log in" />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          status,
        }) => (
          <Form className="max-w-[37.5rem] w-11/12 px-4">
            <section className="flex flex-col mt-20">
              <div className="flex flex-col">
                <Input name={tokenName} type="hidden" value={tokenValue} />
                {status && (
                  <InfoContainer
                    canClose
                    className="mb-4"
                    title={status.title}
                    variant="error"
                  >
                    <span>{status.description}</span>
                  </InfoContainer>
                )}
                <Input
                  error={errors.email && touched.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({
                    id: 'landingMessages.enterYourEmailAddress',
                    defaultMessage: 'Enter your email address',
                  })}
                  value={values.email}
                />
                <ErrorMessage
                  className="text-error-red text-sm mt-1"
                  component="div"
                  name="email"
                />
                <Input
                  className="mt-4"
                  error={errors.password && touched.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({
                    id: 'loginMessages.passwordInput',
                    defaultMessage: 'Password',
                  })}
                  type="password"
                  value={values.password}
                />
                <ErrorMessage
                  className="text-error-red text-sm mt-1"
                  component="div"
                  name="password"
                />
                <Button
                  aria-label="Log in"
                  className="mt-4"
                  disabled={isSubmitting || !values.email || !values.password}
                  type="submit"
                >
                  <FormattedMessage
                    id="loginMessages.loginInButton"
                    defaultMessage="Log in"
                  />
                </Button>

                <span className="black-2 leading-4 mt-4 text-center text-base pt-2">
                  <FormattedMessage
                    id="loginMessages.forgotPassword"
                    defaultMessage={''}
                  />{' '}
                  <Link
                    className="text-smart-blue font-sans text-sm leading-6"
                    to="/app/reset_password"
                  >
                    <FormattedMessage
                      id="loginMessages.forgotPasswordLink"
                      defaultMessage={'Forgot your password?'}
                    />
                  </Link>
                </span>
                <span className="black-2 leading-4 text-center text-base">
                  <FormattedMessage
                    id="loginMessages.needAccount"
                    defaultMessage={'Need to create an account?'}
                  />{' '}
                  <Link
                    className="text-smart-blue font-sans text-sm leading-6"
                    to="/app/landing"
                  >
                    <FormattedMessage
                      id="loginMessages.createAccount"
                      defaultMessage={'Need to create an account?'}
                    />
                  </Link>
                </span>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      <form
        action={brand?.sso_url}
        method="POST"
        ref={ssoFormRef}
        style={{ display: 'none' }}
      >
        <input name={tokenName} type="hidden" value={tokenValue} />
      </form>
    </main>
  )
}

export default Login
