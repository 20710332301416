export const initialFootprintState = {
  grandTotal: 0,
  food: 0,
  goods: 0,
  housing: 0,
  transport: 0,
  services: 0,
}

export const footprintReducer = (state, action) => {
  switch (action.type) {
    case 'calculate-footprint':
      return {
        ...state,
        grandTotal: action.payload.calculated_personal_total,
        food: action.payload.calculated_personal_food_total,
        goods: action.payload.calculated_personal_goods_total,
        housing: action.payload.calculated_personal_housing_total,
        transport: action.payload.result_transport_total,
        ...action.payload,
      }
    case 'reset':
      return initialFootprintState
    default:
      return state
  }
}
