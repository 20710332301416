// Setup
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import {
  abbreviatedDateWithDay,
  dateTimeHourLabel,
  timeZoneShort,
} from 'utilities/date_formatter'

export const ShiftTimeframeTime = ({ end, start, ...other }) => (
  <span {...other}>
    <time dateTime={start}>
      {abbreviatedDateWithDay(start)}
      {' • '}
      {dateTimeHourLabel(start)}
    </time>
    <span> - </span>
    <time dateTime={end}>{dateTimeHourLabel(end)}</time>
    <span> ({timeZoneShort()})</span>
  </span>
)

ShiftTimeframeTime.propTypes = {
  end: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
}
