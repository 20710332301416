import Moment from 'moment'

export const isAfter = (date) => Moment().isAfter(date)

export const isBefore = (date) => Moment().isBefore(date)

export const isBetween = (startDate, endDate) =>
  Moment().isBetween(startDate, endDate)

export const isDatePast = (date) => Moment(new Date()) > Moment(date)

export const sameDate = (startDate, endDate) =>
  Moment(startDate).dayOfYear() === Moment(endDate).dayOfYear()

/** Parses ISO_8601 date string, such as "2014-03-07" */
/** On https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#Parameters about monthIndex */
/** (...) Integer value representing the month, beginning with 0 for January to 11 for December. (...) */
export const parseDate = (dateString) => {
  if (!dateString) return null

  const dateParts = dateString.split(/\D/)
  const year = parseInt(dateParts[0])
  const monthIndex = parseInt(dateParts[1]) - 1
  const day = parseInt(dateParts[2])
  return new Date(year, monthIndex, day)
}

export const now = () => new Date()

export const startOfDay = (date) => {
  const parsedDate = date ? parseDate(date) : now()
  return new Date(parsedDate.setHours(0, 0, 1))
}

export const endOfDay = (date) => {
  const parsedDate = date ? parseDate(date) : now()
  return new Date(parsedDate.setHours(23, 59, 59))
}

export const oneMonthFromToday = () => Moment().add(1, 'months').calendar()
