// Setup
import React, { useState } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { Button } from 'components/ui/button'
import { intl } from 'utilities/localization'
import Stack from 'components/ui/stack'
import UserActivityLog from './user_activity_log'

const messages = defineMessages({
  hidePastLogs: {
    defaultMessage: 'Hide Past Activity',
    id: 'userActivityLogForm.hidePastLogs',
  },
  showPastLogs: {
    defaultMessage: 'Show Past Activity',
    id: 'userActivityLogForm.showPastLogs',
  },
  yourActivity: {
    defaultMessage: 'Your Activity Log',
    id: 'userActivityLogForm.title',
  },
})
const { formatMessage } = intl

const MINIMUM_SHOWN_LOGS = 7

const UserActivityLogs = ({
  accumulationActionTrackingType,
  className,
  userActivityLogs,
}) => {
  const [showPastLogs, setShowPastLogs] = useState(false)
  let logsShown = MINIMUM_SHOWN_LOGS
  if (showPastLogs) {
    logsShown = userActivityLogs.length
  }

  return (
    <Stack className={className} data-test="your-activity-logs" space={2}>
      <Stack className="d-flex align-items-center justify-content-between">
        <p className="fs-1 fw-semi-bold lh-sm ls-2 text-black-3 text-uppercase">
          {formatMessage(messages.yourActivity)}:
        </p>
        {userActivityLogs.length > MINIMUM_SHOWN_LOGS && (
          <Button
            className="fs-1"
            data-test="activity-logs-toggle"
            onClick={() => setShowPastLogs(!showPastLogs)}
            variant="neutral"
          >
            {showPastLogs
              ? '- ' + formatMessage(messages.hidePastLogs)
              : '+ ' + formatMessage(messages.showPastLogs)}
          </Button>
        )}
      </Stack>

      <Stack as="ol" className="list-none pl-0" divider="default" space={1}>
        {userActivityLogs.slice(0, logsShown).map((ual, index) => (
          <UserActivityLog
            accumulationActionTrackingType={accumulationActionTrackingType}
            key={index}
            userActivityLog={ual}
          />
        ))}
      </Stack>
    </Stack>
  )
}

UserActivityLogs.propTypes = {
  accumulationActionTrackingType: PropTypes.string,
  /** class attribute applied to parent node */
  className: PropTypes.string,
  /** current user activity logs for action, if it has a condition enabled  */
  userActivityLogs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      conditionMetricName: PropTypes.string,
      count: PropTypes.number.isRequired,
      dailyMax: PropTypes.number,
      date: PropTypes.string.isRequired,
      measuredAs: PropTypes.string,
    }).isRequired
  ).isRequired,
}

UserActivityLogs.defaultProps = {
  accumulationActionTrackingType: null,
  className: null,
}

export default UserActivityLogs
