import AnalyticsManager from './analytics_manager'

export default class IdeaBoardAnalytics extends AnalyticsManager {
  ideaBoardId = null

  ideaAboutClosed = (id) => this.trackIB('Idea about closed', { id })

  ideaAboutOpened = (id) => this.trackIB('Idea about opened', { id })

  ideaCommentPosted = (commentId, ideaId) =>
    this.trackIB('Idea comment posted', { commentId, ideaId })

  ideaSubmitted = (id) => this.trackIB('Idea submitted successfully.', { id })

  ideaUpdated = (id) => this.trackIB('Idea updated successfully.', { id })

  ideaVoted = (ideaId, voteId, onIcon = false) =>
    this.trackIB(`Idea voted - ${onIcon ? 'heart' : 'vote'} button`, {
      ideaId,
      voteId,
    })

  ideaUnvoted = (ideaId, voteId, onIcon = false) =>
    this.trackIB(`Idea unvoted - ${onIcon ? 'heart' : 'vote'} button`, {
      ideaId,
      voteId,
    })

  setIdeaBoardId(id) {
    this.ideaBoardId = id
  }

  trackIB = (eventName, properties) =>
    this.track(eventName, { ...properties, ideaBoardId: this.ideaBoardId })
}

export const instance = new IdeaBoardAnalytics()
