
import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'

const { formatMessage } = intl

const messages = defineMessages({
  all: {
    defaultMessage: 'All',
    id: 'userChangesStatusesUtils.all',
  },
  creation: {
    defaultMessage: 'Creation',
    id: 'userChangesActionUtils.creation',
  },
  updating: {
    defaultMessage: 'Updating',
    id: 'userChangesActionUtils.updating',
  },
  deactivation: {
    defaultMessage: 'Deactivation',
    id: 'userChangesActionUtils.deactivation',
  },
  noAction: {
    defaultMessage: 'No Action',
    id: 'userChangesActionUtils.noAction',
  },
  success: {
    defaultMessage: 'Success',
    id: 'userChangesStatusUtils.success',
  },
  error: {
    defaultMessage: 'Failed',
    id: 'userChangesStatusUtils.error',
  },
  unchanged: {
    defaultMessage: 'Unchanged',
    id: 'userChangesStatusUtils.unchanged',
  },
})

const statusFilterOptions = [
  { label: formatMessage(messages.all), value: '' },
  { label: formatMessage(messages.success), value: 'success' },
  { label: formatMessage(messages.error), value: 'error' },
  { label: formatMessage(messages.unchanged), value: 'unchanged' },
]

const actionFilterOptions = [
  { label: formatMessage(messages.all), value: '' },
  { label: formatMessage(messages.creation), value: 'creation' },
  { label: formatMessage(messages.updating), value: 'updating' },
  { label: formatMessage(messages.deactivation), value: 'deactivation' },
  { label: formatMessage(messages.noAction), value: 'no_action' },
]

export { statusFilterOptions, actionFilterOptions }
