// Vendor
import PropTypes from 'prop-types'
import React from 'react'
import gql from 'graphql-tag'

// WeSpire
import WeQuery from 'components/application/we_query'

const brandNameQuery = gql`
  query getCurrentBrandName {
    brand {
      id
      name
    }
  }
`

// eslint-disable-next-line no-unused-vars
const BrandNameQuery = ({ children, error, loader, ...other }) => (
  <WeQuery {...other} error={null} loader={null} query={brandNameQuery}>
    {({ data }) => (children ? children(data.brand.name) : data.brand.name)}
  </WeQuery>
)

BrandNameQuery.propTypes = {
  children: PropTypes.func,
  error: PropTypes.node,
  loader: PropTypes.node,
}

BrandNameQuery.defaultProps = {
  children: null,
  error: null,
  loader: null,
}

export { BrandNameQuery }
