const editGroupRoute = ({ groupSlug }) =>
  `/management_panel/groups/${groupSlug}/edit`

const indexChaptersRoute = ({ groupSlug }) =>
  `/management_panel/groups/${groupSlug}/chapters`

const indexLeadershipRolesRoute = ({ groupSlug }) =>
  `/management_panel/groups/${groupSlug}/roles`

const newGroupRoute = () => `/management_panel/groups/new`

export {
  editGroupRoute,
  indexChaptersRoute,
  indexLeadershipRolesRoute,
  newGroupRoute,
}
