// Setup
import React from 'react'
import { defineMessages, FormattedRelativeTime } from 'react-intl'
import PropTypes from 'prop-types'

// WeSpire
import { dayAndMonth } from 'utilities/date_formatter'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import { RelativeTime } from 'components/shared/relative_time'
import { TakeActionLink } from 'components/activities/link'
import { CampaignModel, CampaignUserStatus } from './campaign_model'
import { ActionTimeframeModel } from './action_timeframe_model'

export const ActionInfoBuilder = ({ children }) => (
  <>
    <Icon className="fs-5 mr-3 text-blue" iconName="info" title="" />
    <p className="text-semi-bold" data-test="action-info">
      {children}
    </p>
  </>
)

ActionInfoBuilder.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ActionInfo = ({
  actionName,
  actionTimeframe,
  campaign,
  hasCustomTimeframe,
}) => {
  const { formatMessage } = intl

  const messages = defineMessages({
    actionCanNoLongerBeCompleted: {
      defaultMessage:
        '{name} ended {relativeTime} so it can no longer be completed.',
      description:
        'Message when a user tries to complete an action after its custom running timeframe ended',
      id: 'actionInfo.actionCanNoLongerBeCompleted',
    },
    canNoLongerBeCompleted: {
      defaultMessage:
        'Registration for {name} ended {relativeTime}, so Actions can no longer be completed.',
      description:
        'Text indicating that the campaign has ended and the action can no longer be completed',
      id: 'actionInfo.canNoLongerBeCompleted',
    },
    canRegister: {
      defaultMessage:
        '{name} starts on {startsAt}. Register before {registrationEnd} to join the competition!',
      description:
        'Text indicating that the competition is open but you are not registered',
      id: 'actionInfo.canRegister',
    },
    missedRegistration: {
      defaultMessage:
        'Registration for {name} ended {relativeTime}. Join the discussion to cheer on other participants or visit the {takeAction} to find more opportunities to compete!',
      description: 'Encourage non-participants to cheer on their co-workers',
      id: 'actionInfo.missingResourcePage',
    },
    waitToCompete: {
      defaultMessage:
        '{name} starts on {startsAt}. Come back {relativeTime} to complete this Action!',
      description:
        'Message when a user tries to complete an action when registration is open',
      id: 'actionInfo.waitToCompete',
    },
    waitToCompleteAction: {
      defaultMessage:
        '{name} starts on {startsAt}. Come back {relativeTime} to complete it!',
      description:
        'Message when a user tries to complete an action before its custom running timeframe starts',
      id: 'actionInfo.waitToCompleteAction',
    },
  })

  if (campaign.hasEnded) {
    return (
      <ActionInfoBuilder>
        {formatMessage(messages.canNoLongerBeCompleted, {
          name: <b>{campaign.name}</b>,
          relativeTime: (
            <b>
              <RelativeTime date={campaign.endsAt} />
            </b>
          ),
        })}
      </ActionInfoBuilder>
    )
  } else {
    switch (campaign.status) {
      case CampaignUserStatus.NOT_REGISTERED:
        return (
          <ActionInfoBuilder>
            {formatMessage(messages.canRegister, {
              name: <b>{campaign.name}</b>,
              registrationEnd: <b>{dayAndMonth(campaign.registrationEnd)}</b>,
              startsAt: <b>{dayAndMonth(campaign.startsAt)}</b>,
            })}
          </ActionInfoBuilder>
        )
      case CampaignUserStatus.WAITING_TO_COMPETE:
        return (
          <ActionInfoBuilder>
            {formatMessage(messages.waitToCompete, {
              name: <b>{campaign.name}</b>,
              relativeTime: (
                <b>
                  <RelativeTime date={campaign.startsAt} />
                </b>
              ),
              startsAt: <b>{dayAndMonth(campaign.startsAt)}</b>,
            })}
          </ActionInfoBuilder>
        )
      case CampaignUserStatus.MISSED_REGISTRATION:
        return (
          <ActionInfoBuilder>
            {formatMessage(messages.missedRegistration, {
              name: <b>{campaign.name}</b>,
              relativeTime: (
                <b>
                  <RelativeTime date={campaign.registrationEnd} />
                </b>
              ),
              takeAction: <TakeActionLink />,
            })}
          </ActionInfoBuilder>
        )
      default:
        if (!hasCustomTimeframe) return
        if (actionTimeframe.ended) {
          return (
            <ActionInfoBuilder>
              {formatMessage(messages.actionCanNoLongerBeCompleted, {
                name: <b>{actionName}</b>,
                relativeTime: (
                  <FormattedRelativeTime
                    numeric="auto"
                    unit={actionTimeframe.relativeTimeParams.unit}
                    value={actionTimeframe.relativeTimeParams.value}
                  />
                ),
              })}
            </ActionInfoBuilder>
          )
        } else if (actionTimeframe.notStarted) {
          return (
            <ActionInfoBuilder>
              {formatMessage(messages.waitToCompleteAction, {
                name: <b>{actionName}</b>,
                relativeTime: (
                  <FormattedRelativeTime
                    numeric="auto"
                    unit={actionTimeframe.relativeTimeParams.unit}
                    value={actionTimeframe.relativeTimeParams.value}
                  />
                ),
                startsAt: <b>{dayAndMonth(actionTimeframe.parsedStart)}</b>,
              })}
            </ActionInfoBuilder>
          )
        }
    }
  }
}

ActionInfo.propTypes = {
  actionName: PropTypes.string.isRequired,
  actionTimeframe: PropTypes.instanceOf(ActionTimeframeModel).isRequired,
  campaign: PropTypes.instanceOf(CampaignModel).isRequired,
  hasCustomTimeframe: PropTypes.bool.isRequired,
}
