// Vendor
import React from 'react'
import PropTypes from 'prop-types'

// WeSpire
import { LeaderboardTeams } from './leaderboard_teams'
import { LeaderboardUsers } from './leaderboard_users'

const CampaignLeaderboard = ({ campaignId, className, teamSets }) => {
  if (teamSets.length > 0) {
    return (
      <LeaderboardTeams
        campaignId={campaignId}
        className={className}
        teamSets={teamSets}
      />
    )
  } else {
    return <LeaderboardUsers campaignId={campaignId} className={className} />
  }
}

CampaignLeaderboard.propTypes = {
  campaignId: PropTypes.string.isRequired,
  className: PropTypes.string,
  teamSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

CampaignLeaderboard.defaultProps = {
  className: null,
}

export { CampaignLeaderboard }
