// Setup
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'

// WeSpire
import BurgerIconButton from 'components/application/burger/button'
import { getPrimaryNavLinks } from 'components/shared/navigator/utils'
import { Dropdown } from './dropdown'
import Icon from 'components/ui/icon'
import { Image } from 'components/ui/image'
import Link from 'components/shared/link'
import ScreenReader from 'components/ui/screen_reader_text'

// Sub-Components
import MainNavLink from './main_nav_link'

export const MainHeaderContent = ({
  avatarUrl,
  brandLogoUrl,
  className,
  ergsEnabled,
  firstName,
  givingEnabled,
  linkPaths,
  location,
  sideNavIsOpen,
  toggleSideNav,
  volunteerHistoryEnabled,
  ...other
}) => {
  const mainLinks = getPrimaryNavLinks(ergsEnabled, linkPaths)

  return (
    <header
      className={cx(
        'container-fluid | bg-white border-bottom position-fixed w-100 z-1 px-3 py-2',
        className
      )}
      data-test="primary-nav"
      id="primary-nav"
      {...other}
    >
      <div className="row | align-items-center">
        <div
          className={cx('d-flex align-items-center', {
            'z-5': sideNavIsOpen,
          })}
        >
          <BurgerIconButton
            className="[ fs-4 ml-2 p-2 ] [ hide-md-up ]"
            data-test="side-nav-toggle"
            isActive={sideNavIsOpen}
            onClick={toggleSideNav}
          />
          <Link className="col-auto | px-2" to={linkPaths.backPath}>
            <Image
              alt=""
              className="logo logo--sm | pr-2"
              crop="fit"
              dpr="2.0"
              height="40"
              src={brandLogoUrl}
              width="120"
            />
            <ScreenReader text="home" />
          </Link>
        </div>
        {mainLinks.map((link) => (
          <Fragment key={link.id}>
            {link.shouldDisplay && (
              <MainNavLink
                href={link.href}
                isActive={location.pathname.includes(link.href)}
                title={link.id}
              />
            )}
          </Fragment>
        ))}
        <Link
          className="[ d-flex ] [ ml-auto px-2 ] [ show-md-up ]"
          to={linkPaths.searchPath}
        >
          <Icon
            className="fs-4 text-black-3"
            iconName="magnify"
            title="Search"
          />
        </Link>
        <Link className="d-flex | px-2" to={linkPaths.notificationsPath}>
          <Icon
            className="fs-4 text-black-3"
            iconName="bell"
            title="Notifications"
          />
        </Link>

        <Dropdown
          avatarUrl={avatarUrl}
          className="[ px-2 ] [ show-md-up ]"
          firstName={firstName}
          givingEnabled={givingEnabled}
          volunteerHistoryEnabled={volunteerHistoryEnabled}
        />
      </div>
    </header>
  )
}

MainHeaderContent.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  brandLogoUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  ergsEnabled: PropTypes.bool,
  firstName: PropTypes.string,
  givingEnabled: PropTypes.bool,
  linkPaths: PropTypes.shape({
    achievementsPath: PropTypes.string.isRequired,
    activitiesPath: PropTypes.string.isRequired,
    backPath: PropTypes.string.isRequired,
    dashboardPath: PropTypes.string.isRequired,
    groupsPath: PropTypes.string.isRequired,
    invitePath: PropTypes.string.isRequired,
    notificationsPath: PropTypes.string.isRequired,
    profilePath: PropTypes.string.isRequired,
    searchPath: PropTypes.string.isRequired,
    teamsPath: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  sideNavIsOpen: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  volunteerHistoryEnabled: PropTypes.bool,
}

MainHeaderContent.defaultProps = {
  className: '',
  ergsEnabled: false,
  firstName: null,
  givingEnabled: false,
  volunteerHistoryEnabled: false,
}
