// Setup
import React from 'react'
import PropTypes from 'prop-types'

// Vendor
import cx from 'classnames'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// WeSpire
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'

const SideNavPrimaryItem = ({ isActive, link, onClick }) => (
  <ListItem component={LinkButton} onClick={onClick} to={link.href}>
    <ListItemIcon>
      <Icon
        className={cx('fs-5', { 'text-blue': isActive })}
        iconName={link.icon}
        title=""
      />
    </ListItemIcon>
    <ListItemText
      primary={
        <span
          className={cx('fs-3 text-capitalize text-black-2', {
            'text-blue': isActive,
          })}
        >
          {link.id}
        </span>
      }
    />
  </ListItem>
)

SideNavPrimaryItem.propTypes = {
  isActive: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
}

SideNavPrimaryItem.defaultProps = {
  isActive: false,
  onClick: () => {},
}

export default SideNavPrimaryItem
