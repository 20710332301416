import gql from 'graphql-tag'

export const GRANT_DONATION_FORM_QUERY = gql`
  query grantDonationFormQuery($ein: String!) {
    brandConfig {
      d4dConfiguration {
        d4dAllowMatchingGifts
        d4dProgramType
        d4dGrantAmount
        d4dHoursUntilDonationEligible
      }
    }

    charity(ein: $ein) {
      id
      matched
      name
    }

    currentUser {
      givingaUser {
        grantDollarsEarnedThisYear
        grantDollarsDonatedThisYear
        grantDollarsDonatedThisYearFor(ein: $ein)
        donationFundsCap
        id
        remainingFundsThisYear
      }
      dollarsForDoersEligibleVolunteerHours(ein: $ein)
      volunteerHoursThisYear(ein: $ein)
      id
      donationNotesLength
    }
  }
`
