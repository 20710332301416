// Vendor
import gql from 'graphql-tag'

// WeSpire
import {
  CAMPAIGN_DETAILS_FRAGMENT,
  IDEA_BOARD_SUBMISSION_FRAGMENT,
  NEWSFEED_ITEM_FRAGMENT,
} from 'graphql/fragments'

export const LOG_VALIDIC_DEVICE_LINKING_FLOW = gql`
  mutation logValidicDeviceLinkingFlow(
    $attempt: Boolean!
    $link: String
    $flow: String
    $device: String
  ) {
    logValidicDeviceLinkingFlow(
      attempt: $attempt
      link: $link
      flow: $flow
      device: $device
    ) {
      errors
    }
  }
`

export const COMPLETE_ACTION = gql`
  mutation completeAction(
    $actionId: ID!
    $customFieldResponsesAttributes: [CustomFieldResponseAttributes!]!
    $image: String
    $message: String
    $taggedUserIds: [ID!]
  ) {
    completeAction(
      actionId: $actionId
      customFieldResponsesAttributes: $customFieldResponsesAttributes
      image: $image
      message: $message
      taggedUserIds: $taggedUserIds
    ) {
      errors
      score {
        isForCampaignAchievement
        points
      }
      newsfeedItems {
        ...NewsfeedItem
      }
      campaign {
        ...CampaignDetailsAttributes
      }
      totalPointsEarned
    }
  }
  ${NEWSFEED_ITEM_FRAGMENT}
  ${CAMPAIGN_DETAILS_FRAGMENT}
`

export const SUBMIT_IDEA = gql`
  ${IDEA_BOARD_SUBMISSION_FRAGMENT}

  mutation submitIdea($attributes: IdeaAttributes!) {
    submitIdea(attributes: $attributes) {
      errors
      idea {
        ...IdeaBoardSubmissionAttributes
      }
    }
  }
`

export const UPDATE_IDEA = gql`
  ${IDEA_BOARD_SUBMISSION_FRAGMENT}

  mutation updateIdea($attributes: IdeaAttributes!) {
    updateIdea(attributes: $attributes) {
      errors
      idea {
        ...IdeaBoardSubmissionAttributes
      }
    }
  }
`

export const DELETE_IDEA = gql`
  mutation deleteIdea($ideaId: ID!) {
    deleteIdea(ideaId: $ideaId) {
      errors
    }
  }
`

export const LOG_PROGRESS_ON_ACCUMULATION_ACTION = gql`
  mutation logProgressOnAccumulationAction(
    $actionId: ID!
    $category: String!
    $measuredAs: String
    $occurredOn: String!
    $storyAttributes: StoryAttributes
    $value: Int!
  ) {
    logProgressOnAccumulationAction(
      actionId: $actionId
      category: $category
      measuredAs: $measuredAs
      occurredOn: $occurredOn
      storyAttributes: $storyAttributes
      value: $value
    ) {
      campaign {
        ...CampaignDetailsAttributes
      }
      errors
      newsfeedItems {
        ...NewsfeedItem
      }
      dailyGoalMet
      overDailyMax
    }
  }

  ${NEWSFEED_ITEM_FRAGMENT}
  ${CAMPAIGN_DETAILS_FRAGMENT}
`

export const SHARE_PROGRESS_ON_ACCUMULATION_ACTION = gql`
  mutation shareProgressOnAccumulationAction(
    $actionId: ID!
    $storyAttributes: StoryAttributes!
  ) {
    shareProgressOnAccumulationAction(
      actionId: $actionId
      storyAttributes: $storyAttributes
    ) {
      campaign {
        ...CampaignDetailsAttributes
      }
      errors
      newsfeedItems {
        ...NewsfeedItem
      }
    }
  }

  ${NEWSFEED_ITEM_FRAGMENT}
  ${CAMPAIGN_DETAILS_FRAGMENT}
`
