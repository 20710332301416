// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'

// WeSpire
import { AvatarDimensions } from 'components/application/avatar'
import Icon from 'components/ui/icon'
import { leaderBoardTranslations } from '../utils/shared_translations'

const YourRankingListItemPlaceholder = ({ rank, ...other }) => (
  <li
    className={cx(
      '[ d-flex align-items-center flex-grow ] [ bg-white py-2 px-3 ]',
      other.className
    )}
  >
    <Icon
      className="bg-blue-2 border-decorative border-black-5 border-width-2 rounded-circle text-blue-1"
      iconName="default_avatar"
      style={{
        fontSize: `${AvatarDimensions.sm}px`,
        height: 'auto',
        maxWidth: '100%',
      }}
      title="default profile photo"
    />
    <p className="fs-1 fw-semi-bold my-0 ml-2 text-black-3">
      {leaderBoardTranslations.participantEmptyState}
    </p>
    <div className="fs-4 fw-bold ml-auto text-orange">
      <FormattedNumber value={rank} />
    </div>
  </li>
)

YourRankingListItemPlaceholder.propTypes = {
  rank: PropTypes.number.isRequired,
}

export { YourRankingListItemPlaceholder }
